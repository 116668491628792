import React, { useEffect } from 'react';
import { LayoutList } from '../../../components/LayoutList';
import ListEntryPopup from '../components/ListEntryPopup';
import { useLang } from '~/hooks/useLang';


export default function List () {
  const { translate } = useLang();

  useEffect(() => {
    document.title = translate('My Entries - Dispatch System')
  }, [])

  return(
      <LayoutList marginLayout={false}>
        <ListEntryPopup/>
      </LayoutList>
  );
}