import React from 'react';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { AppRouteProps } from '~/routes';

export default function OccupationRoutes(): readonly AppRouteProps[] {
    const routes: AppRouteProps[] = [
        { path: '/occupation', component: Index, isProtected: true, permissions: ['occupation_index'] },
        { path: '/occupation/create', component: Create, isProtected: true, permissions: ['occupation_create'] },
        { path: '/occupation/edit/:id', component: Edit, isProtected: true, permissions: ['occupation_edit'] },
    ];

    return routes;
}
