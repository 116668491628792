import { authFetch } from "~/services/fetch";

export async function getTypeDocuments() {
  try {
    const { data } = await authFetch({
      url: '/document-type',
      method: 'GET',
    })
    return data;
  } catch (error) {

  }
}