import { authFetch } from '~/services/fetch';

export async function getAnnouncementNoReadService() {
  try {
    const response = await authFetch({
      url: '/announcement/no-read',
      method: 'POST',
    })

    return response.data;

  } catch (error) {
    throw error
  }
}

export async function setAnnouncementReadService({announcementId}) {
  try {
    const response = await authFetch({
      url: '/announcement/read',
      method: 'POST',
      data: {
        announcement_id: announcementId
      }
    })

    return response.data;

  } catch (error) {
    throw error
  }
}
