import { authFetch } from "~/services/fetch";

export async function getStations(): Promise<readonly any[]> {
    try {
        const { data } = await authFetch({
            url: '/station',
            method: 'POST',
            data: {
                limit: 100000,
                filters: [],
                orders: [{ field: 'name', sort: 'asc' }]
            }
        })
        return data.data;
    } catch (error) {
        throw error;
    }
}