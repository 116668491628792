import React, { ChangeEvent, useCallback } from 'react';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Grid, TextField, Chip, Box, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useEntryState from '~/features/Entry/stores/EntryState';
import useSystemState from '~/features/System/stores/SystemState';
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState';
import { State, useHookstate } from '@hookstate/core';
import ConfirmDialog from '~/components/ConfirmDialog';
import CallerInputContainer from '~/features/Entry/components/CallerInput';
import useEntryCommentState from '~/features/Entry/stores/EntryCommentState';
import { authFetch } from '~/services/fetch';
import { getDispatchesForOccurrenceById } from '../../services';
import IDispatchOccurrence from '../../interfaces/IDispatchOccurrence';
import notify from '~/utils/notify';
interface OccurrenceRecallModalProps {
  readonly open: State<boolean>;
  readonly openRequester: State<boolean>;
  readonly occurrenceId: number;
  readonly redirectOccurrence: boolean;
  readonly dataOccurrence?: State<{
    readonly type_id: null | string | number;
    readonly subtype_id: null | string | number;
    readonly city_id: null | string | number;
    readonly latitude: number | null;
    readonly longitude: number | null;
    readonly location: string | null;
  }>;
}
interface dispatchDestinationProps {
  readonly id: number
  readonly code: string
}

export const dataOccurrenceDefault = {
  type_id: null,
  subtype_id: null,
  city_id: null,
  latitude: null,
  longitude: null,
  location: null,
}

// eslint-disable-next-line max-lines-per-function
export default function OccurrenceRecallModal({ open, openRequester, occurrenceId }: OccurrenceRecallModalProps) {
  const { translate } = useLang();
  const { getStatusColors } = useSystemState();
  const entryState = useEntryState();
  const { windowManager } = useSystemState();
  const { syncOccurrenceEditAttendent } = useOccurrenceState();
  const openRedirectOccurrence = useHookstate(false);
  const stationOrigin = useHookstate('');
  const newComment = useHookstate('');
  const { id } = entryState.entry();
  const { isInvalidRequest } = entryState.entryStatusActions();
  const { sendEntryComment } = useEntryCommentState();
  const nameRequester = useHookstate<string | null>('');
  const phoneRequester = useHookstate<string | null>('');
  const entryOriginIdRequester = useHookstate('')
  const dispatches = useHookstate<readonly IDispatchOccurrence[]>([]);
  const agency = useHookstate('');
  const dispatchGroup = useHookstate('');
  const typeInfoDispatch = useHookstate('');
  const subtypeInfoDispatch = useHookstate('');
  const status = useHookstate('');
  const openInfoDispatch = useHookstate(false);
  const codeInfoDispatch = useHookstate('');
  const dispatchDestination = useHookstate<dispatchDestinationProps | null>(null);

  const openOccurrence = (id) => {
    syncOccurrenceEditAttendent(id)
    windowManager().occurrence.open.set(true)
  }

  const handleClickInfoDispatchOpen = (code) => {
    authFetch({
      url: `/dispatch/get-by-code`,
      method: 'POST',
      data: {
        code: code
      }
    }).then((response) => {
      dispatchDestination.set(response?.data?.unified?.dispatch_destination ? response?.data?.unified?.dispatch_destination : null)
      codeInfoDispatch.set(code);
      agency.set(` - ${response.data.agency?.name}`);
      dispatchGroup.set(`${response.data.dispatch_group?.name}`);
      typeInfoDispatch.set(`${response.data.type?.name}`);
      subtypeInfoDispatch.set(response.data.subtype ? `- ${response.data.subtype?.name}` : '');
      status.set(`[${response.data.status?.acronym}]`)
      openInfoDispatch.set(true);
    })
  };

  const handleCloseInfoDispatch = () => {
    openInfoDispatch.set(false);
  };

  const handleClose = () => {
    open.set(false);
  };

  const handleCloseRequester = () => {
    openRequester.set(false)
    open.set(false)
  }

  const action = () => {
    if (id.get() === null) {
      openRequester.set(true)
      getDispatchesForOccurrenceById({ id: occurrenceId }).then((response) => dispatches.set(response))
    } else {
      entryState.recallEntry({ occurrenceId: occurrenceId }).then((response) => {
        if (response.status === 200) {
          notify({
            message: `${translate('Recall successfully created!')} OC: [${occurrenceId}]`,
            type: 'info',
            clickableMessage: {
              id: occurrenceId,
              action: () => openOccurrence(occurrenceId),
              urlRedirection: '/entry',
              hideButton: false,
            }
          });
        }
      })
      openRedirectOccurrence.set(true)
    }
  };

  const confirmDialog = () => {
    entryState.recallEntry({
      occurrenceId: occurrenceId,
      comment: newComment.get(),
      entryOriginId: entryOriginIdRequester.get(),
      name: nameRequester.get(),
      phone: phoneRequester.get()
    }).then((response) => {
      if (response.status === 200) {
        notify({
          message: `${translate('Recall successfully created!')} OC: [${occurrenceId}]`,
          type: 'info',
          clickableMessage: {
            id: occurrenceId,
            action: () => openOccurrence(occurrenceId),
            urlRedirection: '/entry',
            hideButton: false,
          }
        });
      }
    }).finally(() => {
      openRequester.set(false)
      openRedirectOccurrence.set(true)
    })
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    newComment.set(event.target.value)
  }

  const localSendComment = useCallback(() => {
    sendEntryComment({ entryId: id.get(), comment: newComment.get() });
    document.getElementById("newComment")?.focus();
  }, [id.get(), newComment, sendEntryComment]);

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.code === 'Enter' && !event.shiftKey) {
      localSendComment();
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.shiftKey && event.code === 'Enter') {
      event.preventDefault()
      newComment.merge('\n')
    }
  };

  const continueOccurrence = () => {
    openOccurrence(occurrenceId)
    open.set(false);
  }

  const closeRedirectOccurrence = () => {
    windowManager()['occurrence'].open.set(false)
    open.set(false)
  }

  return (
    <>
      <Dialog open={open.get()} onClose={handleClose}>
        <DialogContent>
          {translate('Do you want to use this occurrence for a recall?')}
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={handleClose}>
            {translate('Cancel')}
          </Button>
          <Button
            variant='contained'
            onClick={action}>
            {translate('Submit')}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={openRedirectOccurrence}
        content={translate('Wishing to continue to occurrence?', { code: ` ${occurrenceId}` })}
        handleClose={closeRedirectOccurrence}
        handleAgree={continueOccurrence}
        closeLabel={'Next entry'}
        AgreeLabel={'Continue'}
      />

      <Dialog
        open={openRequester.get()}
        onClose={handleCloseRequester}
        maxWidth='xs'
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Grid container direction={'column'} xs={12}>
            <Grid item>
              {translate('Add detail in recall')}
            </Grid>
            <Grid item fontSize={16}>
              {!dispatches.get().length ?
                <Box style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography>
                    {translate('No Dispatches')}
                  </Typography>
                </Box> :
                dispatches.map((dispatch) => (
                  <Chip
                    key={dispatch.get()?.id}
                    label={dispatch.get()?.code}
                    onClick={() => handleClickInfoDispatchOpen(dispatch.code.get())}
                    sx={{
                      margin: '8px',
                      backgroundColor: getStatusColors(dispatch.get()?.status?.id).main,
                      color: getStatusColors(dispatch.get()?.status?.id).contrastText,
                    }}
                  />
                ))
              }
            </Grid>

          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid sx={{ marginTop: '8px' }}>
            <CallerInputContainer
              readonly={false}
              isValid={true}
              isInvalidRequest={isInvalidRequest.get()}
              phone={phoneRequester}
              name={nameRequester}
              originId={entryOriginIdRequester}
              openModalLastCalls={windowManager()['EntryLogModal'].open}
              userStationOrigin={stationOrigin.get()
              }
            />
          </Grid>
          <Grid item>
            <TextField
              required={false}
              fullWidth
              maxRows={10}
              disabled={false}
              size="small"
              value={newComment.get() || ''}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              id="newComment"
              label={translate("Write your comment")}
              name="newComment"
              multiline
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => handleCloseRequester()}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={() => confirmDialog()}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth={true} open={openInfoDispatch.get()} onClose={handleCloseInfoDispatch}>
        <DialogTitle>
          {translate('Dispatch')} - {codeInfoDispatch.get()} - {status.get()}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {dispatchGroup.get()} {agency.get()}
          </Typography>
          <br />
          <Typography>{typeInfoDispatch.get()} {subtypeInfoDispatch.get()}</Typography>

          {dispatchDestination.get() ? <><br /><Typography>{translate('Unified')} {translate('With')}: <Chip label={dispatchDestination.get()?.code} /></Typography></> : false}
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={handleCloseInfoDispatch}>
            {translate('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}