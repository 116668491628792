import React from 'react'
import { IconButton, Tooltip, Grid } from '@mui/material'
import { useLang } from '~/hooks/useLang'
import MonochromePhotosIcon from '@mui/icons-material/MonochromePhotos'
import useSystemState from '~/features/System/stores/SystemState'
interface ReportingRecordButtonProps {
  readonly canAddReportingRecord: boolean
}

export default function VideoMonitoringButton({
  canAddReportingRecord,
}: ReportingRecordButtonProps) {
  const { translate } = useLang()
  const { windowManager } = useSystemState()

  const handleChange = () => {
    windowManager()['videoMonitoringCards'].open.set(true)
  }
  return (
    <Grid item>
      <IconButton
        disabled={!canAddReportingRecord}
        title={translate('See Videomonitoring narrations')}
        onClick={handleChange}
      >
        <MonochromePhotosIcon fontSize="small" />
      </IconButton>
    </Grid>
  )
}
