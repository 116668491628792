import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  LinearProgress,
  IconButton,
  DialogContentText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useHookstate } from '@hookstate/core';
import { useLang } from '~/hooks/useLang';
import { maskPhoneNumber, truncate } from '~/features/Dispatch/utils/stringsManipulations';
import { getCrewCompositionService } from '~/features/Device/services';
import IUser from '~/features/User/interfaces/IUser';
import { IPosition } from '~/components/DisplayDispatchVehicle';
import { elapsedTime } from '~/utils/dateTime';
import dayjs from 'dayjs';

export interface DeviceLog {
  readonly id?: number
  readonly device_id?: number
  readonly user_id?: number
  readonly km_initial?: string | null
  readonly phone?: string
  readonly notes?: string;
  readonly tracking_id?: string;
  readonly date_connected?: string
  readonly task?: string;
  readonly user?: {
      readonly registration: string,
      readonly representation_name: string
  },
  readonly device?: {
      readonly name: string
      readonly device_type : DeviceType
  },
  readonly station?: {
      readonly name: string
  },
  readonly dispatch_group?: {
      readonly id: number | null;
      readonly name: string | null;
  } | null,
  readonly city?:{
    readonly id: number;
    readonly name: string;
  } | null,
  readonly operation?:{
    readonly id: number;
    readonly name: string;
  } | null,
}

export interface CrewComposition {
  readonly id: number
  readonly device_log_id: number
  readonly user: IUser | null
  readonly position: IPosition
}

export interface DeviceType {
    readonly id?: number
    readonly name?: string 
    readonly label_commander?: string
    readonly label_driver?: string
    readonly label_patrolmans?: string
    readonly label_km_initial?: string

  }

export const sortedCrew = (gettedCrew) => {
  if (!gettedCrew) return null;

  return [...gettedCrew]
      ?.filter((item) => 'position' in item && item)
      ?.sort((a, b) => a.position.id - b.position.id);
}

export default function DeviceInfoDialog({open, deviceId, devicePlate, deviceIdentifier}){
  const { translate } = useLang();
  const isAwaiting = useHookstate(false);
  const crewState = useHookstate<readonly CrewComposition[] | null>(null);
  const deviceLogState = useHookstate<DeviceLog | null>(null);

  useEffect(() => {
    isAwaiting.set(true);
    getCrewCompositionService({ deviceId }).then((data) => {
      crewState.set(data.crewComposition);
      deviceLogState.set(data.deviceLog);
      isAwaiting.set(false);
    })

  }, [deviceId])

  const getPosition = {
    'Comandante': deviceLogState.get()?.device?.device_type?.label_commander ? deviceLogState.get()?.device?.device_type.label_commander : 'Comandante',
    'Motorista': deviceLogState.get()?.device?.device_type?.label_driver ? deviceLogState.get()?.device?.device_type.label_driver : 'Motorista',
    'Patrulheiro': deviceLogState.get()?.device?.device_type?.label_patrolmans ? deviceLogState.get()?.device?.device_type.label_patrolmans : 'Patrulheiro',
  }

  return (
    <Dialog open={open.get()} fullWidth maxWidth='sm'>
      <IconButton
        size="small"
        onClick={() => {
          open.set(false)
        }}
        title={translate('Close')}
        sx={{
            position: 'absolute',
            right: '10px',
            top: '15px',
            "&:hover": { color: "#6e6e6e", background: 'transparent' }
        }}
      >
        <CloseIcon />
      </IconButton  >
      {isAwaiting.get() && (<LinearProgress />)}
      <DialogTitle>
        <Typography variant='h5' sx={{ textAlign: 'center' }}>
            {`${translate('Crew Composition')} - ${truncate(deviceIdentifier ?? '', 10)}`}
        </Typography>
      </DialogTitle>
      {!isAwaiting.get() && 
      <DialogContent sx={{ minHeight: '200px', placeItems: 'center' }} dividers>
          <Typography>
              {translate('Composed on')}
              {' '}
              <b>
                  {deviceLogState.get()?.date_connected ? (dayjs(deviceLogState.get()?.date_connected).isValid() ? dayjs(deviceLogState.get()?.date_connected).format('DD/MM/YYYY') : '') : ''}
              </b>
              {' '}
              {translate('at')}
              {' '}
              <b>
                  {deviceLogState.get()?.date_connected ? (dayjs(deviceLogState.get()?.date_connected).isValid() ? dayjs(deviceLogState.get()?.date_connected).format('HH:mm') : '') : ''}
              </b>
          </Typography>
          <Typography>
              {translate('Dispatch Group')}
              {': '}
              <b>
                  {`${deviceLogState.get()?.dispatch_group?.name ? deviceLogState.get()?.dispatch_group?.name : translate('Uninformed')}`}
              </b>
          </Typography>
          <Typography>
              {translate('Task')}
              {': '}
              <b>
                  {`${deviceLogState.get()?.task ? deviceLogState.get()?.task : translate('Uninformed')}`}
              </b>
          </Typography>

          <Typography>
              {deviceLogState.get()?.device?.device_type?.label_km_initial ? deviceLogState.get()?.device?.device_type.label_km_initial : translate('Initial Kilometer')}
              {': '}
              <b>
                  {`${deviceLogState.get()?.km_initial ? deviceLogState.get()?.km_initial : translate('Uninformed')}`}
              </b>
          </Typography>

          <Typography>
              {translate('License plate')}
              {': '}
              <b>
                  {`${devicePlate?.length ? devicePlate : translate('Uninformed')}`}
              </b>
          </Typography>

          <Typography>
              {translate('Tracking')}
              {': '}
              <b>
                  {`${deviceLogState.get()?.tracking_id ? deviceLogState.get()?.tracking_id : translate('Uninformed')}`}
              </b>
          </Typography>

            {deviceLogState.get()?.city && (
                <Typography>
                {translate('City')}
                {': '}
                <b>
                {`${deviceLogState.get()?.city?.name}`}
            </b>
            </Typography>
            )}

            {deviceLogState.get()?.operation && (
                <Typography>
                {translate('Operation')}
                {': '}
                <b>
                    {`${deviceLogState.get()?.operation?.name}`}
                </b>
            </Typography>
            )}


          <Typography>
              {translate('Notes')}
              {': '}
              <b>
                  {`${deviceLogState.get()?.notes ? deviceLogState.get()?.notes : translate('Uninformed')}`}
              </b>
          </Typography>
          <Grid container>
              {!sortedCrew?.length ?
                  (<Grid item xs={12} key={0} >
                      {translate('Nothing to this vehicle')}
                  </Grid>
                  ) : (sortedCrew(crewState.get())?.map((crew, index) => {
                      if ('position' in crew) {
                          return (
                              <Grid item key={index} xs={12} marginTop={1} marginBottom={1}>
                                  <Typography sx={{ fontWeight: 'bold' }}>
                                      {`${getPosition[crew.position.name]}`}
                                      {' '}
                                  </Typography>
                                  <Typography>
                                      {crew.user?.representation_name ? `${crew.user?.registration ? `[${crew.user?.registration}]` : ''} ${crew.user?.representation_name}` : ''}
                                  </Typography>
                                  <Typography>
                                      {crew.position.id === 1 && `${translate('Contact')}:`}
                                      {' '}
                                      <b>
                                          {crew.position.id === 1 &&
                                              deviceLogState.get()?.phone ?
                                              `${maskPhoneNumber(deviceLogState.get()?.phone)}` :
                                              ''}
                                      </b>
                                  </Typography>
                              </Grid>
                          )
                      }
                      return false
                  }).filter((elem) => elem !== false)
                  )
              }
          </Grid>
          <DialogContentText align='center'>
              {translate('This crew has been connected to the device')}
              {' '}
              {deviceLogState.get()?.device?.name}
              {' '}
              <br />
              há
              {' '}
              {elapsedTime(deviceLogState.get()?.date_connected ?? '')}
              {' '}
              {translate('by')}
              {' '}
              {deviceLogState.get()?.station ? deviceLogState.get()?.user?.representation_name : crewState.get()?.find(crew => crew.position.id === 1)?.user?.representation_name}
              {' '}
              {deviceLogState.get()?.station ? `${translate('on station')} ${deviceLogState.get()?.station?.name}` : deviceLogState.get()?.device ? `${translate('by device')} ${deviceLogState.get()?.device?.name}` : ''}
          </DialogContentText>
      </DialogContent>}

    </Dialog>
  );
}