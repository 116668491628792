import React, { useEffect } from 'react'
import { useLang } from '~/hooks/useLang';
import ErrorFallback from '~/components/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';
import Layout from '~/components/Layout';
import { Button, Card, CardHeader, Collapse, Grid, IconButton, } from '@mui/material';
import { DispatchSearchBox } from '~/components/DispatchSearchBox';
import { useHookstate, useState } from '@hookstate/core';
import { downloadReportCSV, makeRequestToGetChronologyByOccurrence } from '../../services/report';
import notify from '~/utils/notify';
import useReportState from '../../stores/ReportState';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import GenerateReportButton from '../../components/GenerateReportButton';
import ExportButtonCSV from '../../components/ButtonExportCSV';

export default function ChronologyByOccurrence() {
  const expandFilterSearch = useHookstate<boolean>(true);
  const { translate } = useLang();
  const isLoading = useState(false);
  const { reportData, loadingReport } = useReportState();
  const isSearchDialog = useState(false);
  const reportName = 'chronology-occurrence'
  const IdDispatch = useHookstate<number | null>(null)
  const groupsId = useHookstate<readonly number[]>([]);
  const agencyIds = useHookstate<readonly number[]>([]);
  const labelButton = useHookstate('')
  const lockFields = useHookstate<boolean>(false);

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])


  useEffect(() => {
    IdDispatch.set(null)
  }, [])

  function generateReport() {
    if (IdDispatch.get()) {
      loadingReport().set(true)
      isLoading.set(true)
      makeRequestToGetChronologyByOccurrence({ 
        dispatchId: IdDispatch.get(),
        agencyIds: agencyIds.get(),
        dispatchGroupsId: groupsId.get(),
      })
        .then(response => {
          reportData().set(response)
            window.open(`/report/result/${reportName}`)
        }).finally(() => {
          loadingReport().set(false)
          isLoading.set(false)
        })
    } else {
      notify({ message: translate('Dispatch code cannot be empty'), type: 'error' })
    }
    isLoading.set(false)

  }

  const exportCSV = async () => {
    if (IdDispatch.get()) {
      loadingReport().set(true)
      isLoading.set(true)
      const dataCSV = await makeRequestToGetChronologyByOccurrence({
        dispatchId: IdDispatch.get(),
        exportCSV: true,
        agencyIds: agencyIds.get(),
        dispatchGroupsId: groupsId.get(),
      }).finally(() => {
        loadingReport().set(false)
        isLoading.set(false)
      })
      downloadReportCSV(`${translate(reportName)}-${new Date().getTime()}.csv`, dataCSV)
    } else {
      notify({ message: translate('Dispatch code cannot be empty'), type: 'error' })
    }
  }


  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true} >
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader title={`${translate('Report')}: ${translate('Occurrence by chronology')}`} />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader title={`${translate('Filters')}`}
            avatar={(
              <IconButton
                onClick={() => expandFilterSearch.set(!expandFilterSearch.get())}
                sx={{ display: 'flex', justifyContent: 'end', marginRight: 1, padding: 0 }}>
                {expandFilterSearch.value ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
              </IconButton>
            )}
          />
          <Collapse in={expandFilterSearch.get()}>
            <Grid container xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 2
              }}
            >
              <Grid container direction={'row'} rowGap={2}>
                <Grid item xs={3} sx={{ pl: 1, pt: 1 }}>
                  <DispatchSearchBox
                    isOpen={isSearchDialog}
                    isValid={true}
                    notOnlyVisibles={true}
                    dispatchId={IdDispatch}
                    isReport={true}
                    size='small'
                  />
                </Grid>
              <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: 2
              }}>
                <Button variant="outlined"
                    onClick={() => {
                      IdDispatch.set(null)
                      agencyIds.set([])
                    }}
                    sx={{ height: '40px' }}
                >
                    {translate('Clear Filters')}
                </Button>
              <ExportButtonCSV
                isLoading={isLoading.get()}
                dataToExport={exportCSV}
              />
              <GenerateReportButton
                isLoading={isLoading.get()}
                makeReport={generateReport}
              />
              </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout >
    </ErrorBoundary >
  )
}