import { authFetch } from "~/services/fetch";

export async function makeRequestToGetReportTotalEntryByUser({
    startDate,
    finishDate,
    contactCenterId,
    exportCSV = false,
    entryOriginId
}) {
    try {
        const response = await authFetch({
            url: '/report/total-entry-by-user',
            method: 'POST',
            data: {
                start_date: startDate,
                finish_date: finishDate,
                contact_center_id: contactCenterId,
                export_csv: exportCSV,
                entry_origin_id: entryOriginId
            }
        });

        return response.data;

    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}