import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Replay } from '@mui/icons-material';
import { useLang } from '../../../../hooks/useLang';
import ButtonDialog from '~/components/ButtonDialog';
import { useHookstate } from '@hookstate/core';

interface DispatchReopenButtonProps {

  readonly canReopen: boolean | undefined;
  readonly reopenDispatch: (loading) => Promise<void> | undefined;
}

export default function DispatchReopenButton(props: DispatchReopenButtonProps) {
  const { translate } = useLang()
  const loading = useHookstate<boolean>(false)

  const action = () => {
    if (props.reopenDispatch) {
      props.reopenDispatch(loading);
    }
  };

  return (
    <Grid item>
      <ButtonDialog
        loading={loading}
        titleButton={translate('Reabrir Despacho')}
        iconButton={<Replay />}
        sxButton={{ backgroundColor: 'primary.main' }}
        disabled={!props.canReopen}
        action={action}
        dialogTitle={translate('Reabrir Despacho')}
        dialogContent={
          <Typography>
            {translate('Do you wish to reopen this dispatch?')}
          </Typography>
        }
      />
    </Grid>);

}
