import React, { useEffect } from "react";
import axios, { AxiosError } from "axios";
import { useAuth } from "~/features/auth/hooks/useAuth";
import { useHistory } from "react-router-dom";
import useUserState from "~/features/User/stores/UserState";
import constants from "~/core/constants";

import Cookies from "universal-cookie";

const cookies = new Cookies();

const WithAxios = ({ children }) => {
    const { logout } = useAuth();
    const history = useHistory();
    const { resetUser } = useUserState();

    useEffect(() => {
      axios.interceptors.request.use(config => {
        return config;
      }, async (err) => {
        return new Promise((resolve, reject) => reject(err))
      });
      axios.interceptors.response.use(response => {
        // console.log('Response config', response)
        return response
      }, async (err: AxiosError) => {
        return new Promise((resolve, reject) => {
          // FIXME: Armengue necessário devido ao uso de interceptors.
          // if (err?.response?.status === 401  && err.response?.data != "Not Authorized - Invalid Token") {
            if (err?.response?.status === 401) {
              logout();
            }
          
          // FIXME: Armengue para desativar estação
          if (err.response?.status == 423 || err.code == '423' ) {
            cookies.remove(constants.keys.cookies.identificationKey);
            cookies.remove(constants.keys.cookies.anonymousIdentificationKey);
            cookies.remove(constants.keys.token);
            resetUser();
            // eslint-disable-next-line functional/immutable-data
            window.location.href = history.createHref({pathname: '/activation'});

            resolve(true);
          }

          return reject(err);
        })
      });
    }, [axios])
  
    return children;
  }

export default WithAxios;