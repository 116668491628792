import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import Layout from '~/components/Layout';
import IDispatchGroupSet from '../interfaces/IDispatchGroupSet';

export default function Create() {
  const { register, handleSubmit, control, setValue: setFormValue } = useForm<IDispatchGroupSet>();
  const fields = [
    { name: 'name', type: 'string', required: true },
    { name: 'dispatch_groups', type: 'transferList', options: { firstListUrl: '', secondListUrl: '/dispatch/group' }, placeholder: 'Dispatch Group' }
  ];

  return (
    <Layout marginLayout={true}>
      <CrudCreate model={'dispatch/group-set'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Dispatch Group Set successfully created!'}
        title={'Add Dispatch Group Set'}
        subheader={'Add a new Dispatch Group Set'}
        setFormValue={setFormValue} />
    </Layout>
  )
}