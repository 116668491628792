import React from 'react';
import { Container, Grid, Typography } from '@mui/material';

export default function Signature(){
  return (
    <Container fixed={false}>
      <Grid container xs={12} sx={{ marginTop: 10 }}>
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography sx={{borderTop: 1, width: 400, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Relatório Recebido por (nome e matricula):</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography sx={{borderTop: 1, width: 400, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Data e hora:</Typography>
        </Grid>
      </Grid>
      <Grid container xs={12} sx={{ marginTop: 10 }}>
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography sx={{borderTop: 1, width: 400, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Conduzido por (nome e matricula):</Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography sx={{borderTop: 1, width: 400, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Data e hora:</Typography>
        </Grid>
      </Grid>
    </Container>
  )
}