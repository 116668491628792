import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import Layout from '~/components/Layout';
import IBreed from '../interfaces/IBreed';

export default function Create() {     
  const {register, handleSubmit, control, setValue:setFormValue} = useForm<IBreed>();
    const fields = [
      { name: 'name', type: 'string', required: true},
      { name: 'specie_id', type: 'selectAutoComplete', model: 'specie', placeholder: 'Specie', method: 'GET', required: true},    
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate 
          model={'breed'} 
          fields={fields} 
          register={register} 
          handleSubmit={handleSubmit} 
          control={control} 
          messageSuccess={'Breed successfully created!'}  
          title={'Add breed'}  
          subheader={'Add a new breed'}
          setFormValue={setFormValue} />
      </Layout>
    )
}
