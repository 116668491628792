import { IOrganDTO, IRawOrgan } from "~/features/Dispatch/interfaces/IDispacthRegistrationOrgan"
import { removeMask } from "~/features/Dispatch/utils/stringsManipulations"

export function toDomain(
  object: IRawOrgan): IOrganDTO {
  return {
    id: object.id,
    occurrenceId: object.occurrence_id,
    actionTaken: object.action_taken,
    cpf: object?.cpf,
    name: object.name,
    notes: object.notes,
    auxiliaryOrgans: object.organization,
    registration: object.registration,
  }
}

export function toFetch(organs: IOrganDTO) {
  return {
    occurrence_id: organs.occurrenceId,
    organization_id: organs.auxiliaryOrgans?.id,
    action_taken_id: organs.actionTaken?.id,
    name: organs.name,
    cpf: removeMask(organs.cpf) || null,
    registration: organs?.registration || null,
    notes: organs?.notes || null,
  }
}
