import { Grid, IconButton } from "@mui/material"
import React from "react"
import { useLang } from "~/hooks/useLang"
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import ConfirmDialog from "../ConfirmDialog";
import { useHookstate } from "@hookstate/core";
import { markClerkOccurrence } from "~/features/Clerk/services";
import useOccurrenceState from "~/features/Occurrence/stores/OccurrenceState";
import useDispatchState from "~/features/Dispatch/stores/DispatchState";
import notify from "~/utils/notify";

interface AddClerkProps {
  readonly canClerk?: boolean | null;
}

export default function AddClerk({ canClerk }: AddClerkProps) {
  const { translate } = useLang()
  const openDialog = useHookstate(false);
  const { dispatch } = useDispatchState()
  const { occurrence } = dispatch()
  const loading = useHookstate(false)


  const handleDialogCloseFunction = () => {
    loading.set(true)
    markClerkOccurrence({ occurrenceId: occurrence.id.get() }).then((response) => {
      if (response.status === 200) {
        notify({ message: `${translate('Marked successfully')}`, type: 'success' });
      }
    }).finally(() => {
      occurrence.toClerk.set(true)
      handleDialogClose()
    })
  };

  const handleDialogOpen = () => {
    openDialog.set(true)
  };

  const handleDialogClose = () => {
    openDialog.set(false)
    loading.set(false)
  };

  return (
    <Grid item>
      <IconButton 
        title={translate('Mark for clerk')} 
        onClick={() => handleDialogOpen()}
        disabled={canClerk ? canClerk : false}
        >
        <RateReviewOutlinedIcon />
      </IconButton>
      <ConfirmDialog
        handleAgree={handleDialogCloseFunction}
        handleClose={handleDialogClose}
        open={openDialog}
        title={translate('Confirmation')}
        content={translate('Confirm marking of the occurrence for the writer?')}
        loading={loading}
        AgreeLabel={translate('Send')}
      />
    </Grid>

  )
}