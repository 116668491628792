import React, { useEffect } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { State, useHookstate } from '@hookstate/core';
import { getAssociatedObjectsFromOccurrenceService } from '~/features/Occurrence/services/index';

export interface objectsHistoryProps {
  readonly  id: number,
  readonly  occurrence_id: number,
  readonly  object_type_id: number,
  readonly  quantity: number,
  readonly  unit_measurement_id: number,
  readonly  circunstance_id: number,
  readonly  destination_id: number,
  readonly  notes: string,
  readonly  created_at: string,
  readonly  updated_at: string,
  readonly  deleted_at: string,
  readonly  object_type: {
    readonly id: number,
    readonly name: string
  },
  readonly  unit_measurement: {
    readonly id: number,
    readonly name: string,
    readonly symbol: string,
  },
  readonly  circunstance: {
    readonly id: number,
    readonly name: string
  },
  readonly  destination: {
    readonly id: number,
    readonly name: string
  },
} 

interface ObjectsRegisteredInTheOccurrencePrintProps {
  readonly ObjectsRegisteredInTheOccurrencePrintResponse: objectsHistoryProps[]
}

export default function ObjectsRegisteredInTheOccurrencePrint({ ObjectsRegisteredInTheOccurrencePrintResponse }: ObjectsRegisteredInTheOccurrencePrintProps){
  const { translate } = useLang();
  

  return (
    <Container fixed={false}>
      <Grid container xs={12} sx={{ marginBottom: 2 }}>
        { ObjectsRegisteredInTheOccurrencePrintResponse.length != 0 ? (
          ObjectsRegisteredInTheOccurrencePrintResponse.map((object) => (
            <Grid container key={object?.id} xs={12} sx={{ margin: 1, border: 1, borderStyle: 'dashed' }}>
              <Grid item xs={3} sx={{border: 1, borderStyle: 'dashed', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant='subtitle1' sx={{ alignItems: 'center' }}><b>{object?.object_type?.name}</b></Typography>
              </Grid>
              <Grid container xs={9} padding={2}>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'><b>{translate('Type')}:</b> {object?.object_type?.name ?? `* ${translate('Uninformed')} *`} </Typography>
                  <Typography variant='subtitle2'><b>{translate('Quantity')}:</b> {object?.quantity} {object?.unit_measurement?.symbol}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'><b>{translate('Circunstance')}:</b> {object?.circunstance?.name ?? `* ${translate('Uninformed')} *`}</Typography>
                  <Typography variant='subtitle2'><b>{translate('Destination')}:</b> {object?.destination?.name ?? `* ${translate('Uninformed')} *`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'><b>{translate('Notes')}:</b> {object?.notes ?? `* ${translate('Uninformed')} *`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : (
        <Grid item xs={12} textAlign={'center'}>
          <Typography variant='subtitle1'><b>{translate('NO INFORMATION REGISTERED')}</b></Typography>
        </Grid>
        )}
      </Grid>
    </Container>
  )
}