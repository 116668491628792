/* eslint-disable max-lines-per-function */
import React from 'react'
import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material'
import { useLang } from '~/hooks/useLang'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import { useHistory } from 'react-router-dom'
import useUserState from '~/features/User/stores/UserState'
import { MenuProps } from '~/components/SidebarMenu/interfaces'

export default function OrganizationItem({ handleMenuClick }: MenuProps) {
  const { translate } = useLang()
  const { verifyPermission } = useUserState()
  const [openOrganization, setOpenOrganization] = React.useState(false)

  const history = useHistory()

  const handleClickOrganization = () => {
    setOpenOrganization(!openOrganization)
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {verifyPermission([
        'agency_index',
        'area_plan_index',
        'station_index',
        'user_index',
        'dispatch_group_index',
      ]) && (
        <>
          <Tooltip title={translate('Organization options')} placement="right">
            <ListItemButton onClick={handleClickOrganization}>
              <ListItemText primary={translate('Organization')} />
              {openOrganization ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </Tooltip>
          <Collapse in={openOrganization} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {verifyPermission(['agency_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Agency:name')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/agency')
                    }}
                  >
                    <ListItemText primary={translate('Agency:name')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['group_set_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Dispatch Group Set')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/dispatch/group-set')
                    }}
                  >
                    <ListItemText primary={translate('Dispatch Group Set')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['contact_center_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Contact Center')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/contact-center')
                    }}
                  >
                    <ListItemText primary={translate('Contact Center')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['agency_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read and delete')}` +
                    ' ' +
                    `${translate('Area Configuration')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/area-config')
                    }}
                  >
                    <ListItemText primary={translate('Area Configuration')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['procedure_configs_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Procedures Configuration')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/procedure/config')
                    }}
                  >
                    <ListItemText
                      primary={translate('Procedures Configuration')}
                    />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['station_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Stations')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/station')
                    }}
                  >
                    <ListItemText primary={translate('Stations')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['dispatch_group_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Dispatch Group')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/dispatch/group')
                    }}
                  >
                    <ListItemText primary={translate('Dispatch Group')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['group_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Users group')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/group')
                    }}
                  >
                    <ListItemText primary={translate('Users group')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['area_plan_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Area Plans')}`
                  }
                  placement="right"
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText
                      primary={translate('Area Plans')}
                      onClick={() => {
                        handleMenuClick('administration')
                        history.push('/area-plan')
                      }}
                    />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['procedure_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Procedures')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/procedure')
                    }}
                  >
                    <ListItemText primary={translate('Procedures')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {
                // verifyPermission(['group_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Operation unit')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/operation-unit')
                    }}
                  >
                    <ListItemText primary={translate('Operation unit')} />
                  </ListItemButton>
                </Tooltip>
                // )
              }

              {verifyPermission(['user_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Users')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/user')
                    }}
                  >
                    <ListItemText primary={translate('Users')} />
                  </ListItemButton>
                </Tooltip>
              )}
            </List>
          </Collapse>
        </>
      )}
    </>
  )
}
