import { authFetch } from '~/services/fetch';
import { IParamsTable, IUpdateProps } from '../../../interfaces';
import IType from '../interfaces/ICamera';

export async function getCamerasById(id: number) {
    const response = await authFetch({
        url: `/camera/${id}`,
        method: 'GET'
    });
    return response;
}

export async function getCameras({
    filters = [],
    limit = 100000,
    orders = [
        { field: 'name', sort: 'asc' }
    ]
}: IParamsTable = {}) {
    const response = await authFetch({
        url: `/camera/`,
        method: 'POST',
        data: {
            limit,
            filters,
            orders
        },

    });
    return response.data.data;
}

export async function getAllCameras(): Promise<readonly IType[]> {
    const response = await authFetch({
        url: `/camera/`,
        method: 'GET',
    });
    return response.data;
}
