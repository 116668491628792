/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import {
  Button,
  Collapse,
  Grid,
  Card,
  List,
  ListItemButton,
  ListItemText,
  Box,
  Typography,
  Tooltip,
} from '@mui/material'
import IconAdministration from '~/components/IconsSvg/administration'
import { useLang } from '~/hooks/useLang'
import { ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material'

import { useHistory } from 'react-router-dom'
import useUserState from '~/features/User/stores/UserState'
import { MenuProps } from '~/components/SidebarMenu/interfaces'

export default function ClassificationItem({ handleMenuClick }: MenuProps) {
  const { translate } = useLang()
  const { verifyPermission } = useUserState()
  const [openClassification, setOpenClassification] = React.useState(false)

  const history = useHistory()

  const handleClickClassification = () => {
    setOpenClassification((old) => !old)
  }

  return (
    <>
      {(verifyPermission(['type_index']) ||
        verifyPermission(['subtype_index']) ||
        verifyPermission(['entry_origin_index']) ||
        verifyPermission(['entry_status_index']) ||
        verifyPermission(['dispatch_closed_status_index']) ||
        verifyPermission(['device_out_of_service_reason_index']) ||
        verifyPermission(['friendly_type_index']) ||
        verifyPermission(['device_type_index']) ||
        verifyPermission(['closed_status_class_index'])) && (
        <>
          <Tooltip
            title={translate('Classification options')}
            placement="right"
          >
            <ListItemButton onClick={handleClickClassification}>
              <ListItemText primary={translate('Classification')} />
              {openClassification ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </Tooltip>
          <Collapse in={openClassification} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {verifyPermission(['device_type_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Categoria de Tipo e subtipo')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/category')
                    }}
                  >
                    <ListItemText
                      primary={translate('Categoria de Tipo e subtipo')}
                    />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['closed_status_class_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Closed status class')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/closed-status-class')
                    }}
                  >
                    <ListItemText primary={translate('Closed status class')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {/* {verifyPermission(['device_on_mission_reason_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('On mission reason')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/on-mission-reason')
                    }}
                  >
                    <ListItemText
                      primary={translate('On mission reason')}
                    />
                  </ListItemButton>
                </Tooltip>
              )} */}

              {verifyPermission(['device_out_of_service_reason_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Out of service reason')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/device/out-of-service-reason')
                    }}
                  >
                    <ListItemText
                      primary={translate('Out of service reason')}
                    />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['entry_origin_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Origin')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/entry-origin')
                    }}
                  >
                    <ListItemText primary={translate('Origin')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['entry_status_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Entry Status')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/entry-status')
                    }}
                  >
                    <ListItemText primary={translate('Entry Status')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['dispatch_closed_status_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Closed status')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/dispatch/closed-status')
                    }}
                  >
                    <ListItemText primary={translate('Closed status')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['subtype_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Entry Subtype')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/subtype')
                    }}
                  >
                    <ListItemText primary={translate('Entry Subtype')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {/* {verifyPermission(['interest_point_subtype_index']) && ( */}
              <Tooltip
                title={
                  `${translate('Create, read, update and delete')}` +
                  ' ' +
                  `${translate('Interest point subtype')}`
                }
                placement="right"
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => {
                    handleMenuClick('administration')
                    history.push('/interest-point/subtype')
                  }}
                >
                  <ListItemText primary={translate('Interest point subtype')} />
                </ListItemButton>
              </Tooltip>
              {/* )}  */}

              {verifyPermission(['type_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Entry Type')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/type')
                    }}
                  >
                    <ListItemText primary={translate('Entry Type')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['friendly_type_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Type/SubType Friendly')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/friendly-type')
                    }}
                  >
                    <ListItemText
                      primary={translate('Type/SubType Friendly')}
                    />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['device_type_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Device Type')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/device-type')
                    }}
                  >
                    <ListItemText primary={translate('Device Type')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {/* {verifyPermission(['interest_point_type_index']) && ( */}
              <Tooltip
                title={
                  `${translate('Create, read, update and delete')}` +
                  ' ' +
                  `${translate('Interest point type')}`
                }
                placement="right"
              >
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={() => {
                    handleMenuClick('administration')
                    history.push('/interest-point/type')
                  }}
                >
                  <ListItemText primary={translate('Interest point type')} />
                </ListItemButton>
              </Tooltip>
              {/* )}  */}
            </List>
          </Collapse>
        </>
      )}
    </>
  )
}
