import React, { useEffect } from 'react';
import Layout from '~/components/Layout';
import Map from '../../Map/components/Map';
import MapMarkersEvents from '~/features/Map/components/MapMarkers/MapMarkersEvents';
import useSystemState from '~/features/System/stores/SystemState';
import MapHandlePopUp from '~/features/Map/components/MapHandlers';
import VehicleModalDraggable from '~/features/Entry/components/VehicleModalDraggable';
import useUserState from '~/features/User/stores/UserState';
import { useLang } from '~/hooks/useLang';

export default function Index() {
  const { windowDefault, loadDraggables, windowIsNotVisible } = useSystemState();
  const { verifyPermission } = useUserState();
  const { translate } = useLang();

  useEffect(() => {
    document.title = translate('Videomonitoring - Dispatch System')
  }, [])
  
  useEffect(() => {
    loadDraggables([
      'DispatchFormModalDraggable',
      'OccurrenceModalDraggable',
      'EntryFormModalDraggable',
      'EntryRecallModalDraggable',
      'EntryLogModalDraggable',
      'VehicleModalDraggable',
      'RegistrationModalDraggable',
      'DispatchUnificationModalDraggable',
      'DispatchUnification',
      'VehicleModalDraggable',
      'VideoMonitoringCardsDraggable',
      'MessageModalDraggable',
    ])
    windowDefault(['videoMonitoring']);
    windowIsNotVisible([
      'dispatch',
      'occurrence',
      'entry',
      'entryRecall',
      'EntryLogModal',
      'VehicleModal',
      'registration',
      'DispatchDisunification',
      'DispatchUnification',
      'VehicleModal',
      'videoMonitoringCards',
      'MessageModal',
      'entryProcedure',
      'dispatchProcedure',
      'dispatchesResultSearches',
      'searchWeapon',
      'searchStaff',
      'searchInvolvement'
    ]);
  }, [])

  return (
    <Layout mapViewPort marginLayout={false} draggableComponents={true}>
      <Map>
        <MapHandlePopUp readonly={verifyPermission(['entry_read_only'])} />
        <MapMarkersEvents viewGroups={['dispatch', 'camera']} checkedViewGroups={['camera', 'dispatch']} nearMarker={true} />
      </Map>
    </Layout>
  );
}