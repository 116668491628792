import React, { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { useHistory } from 'react-router-dom';
import { MenuProps } from '~/components/SidebarMenu/interfaces';
import { useLang } from '~/hooks/useLang';
import { Collapse, List } from '@mui/material';
import userUserState from '~/features/User/stores/UserState';

import {
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';

export default function ReportByEntry({ handleMenuClick }: MenuProps) {
  const { translate } = useLang();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { verifyPermission } = userUserState();

  const handleClickOpen = () => {
    setOpen((old) => !old);
  };

  return (
    <>
      <Tooltip title={translate('Generate report') + " " + translate('Entry')} placement="right">
        <ListItemButton onClick={handleClickOpen}>
          <ListItemText primary={translate('Entry')} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </Tooltip>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            verifyPermission(['reports_generator']) && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {verifyPermission(['report_entry_by_status']) && (

                  <Tooltip
                title={`${translate('Generate report')}` + ' ' + `${translate('Entry log by status and user')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/entry-by-status");
                      }}
                    >
                  <ListItemText primary={translate('Entry log by status and user')} />
                    </ListItemButton>
                  </Tooltip>
                )}

                {verifyPermission(['report_total_entry_by_user']) && (

                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Total entries by user')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/total-entry-by-user");
                      }}
                    >
                      <ListItemText primary={translate('Total entries by user')} />
                    </ListItemButton>
                  </Tooltip>
                )}

                {verifyPermission(['report_total_prank_calls']) && (

                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Total prank calls registered')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/total-prank-calls");
                      }}
                    >
                      <ListItemText primary={translate('Total prank calls registered')} />
                    </ListItemButton>
                  </Tooltip>
                )}
              </>
            )
          }
        </List>
      </Collapse>
    </>
  )
}