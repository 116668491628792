import React from 'react';
import { AppRouteProps } from '~/routes';
import Create from './pages/Create';
import Index from './pages/Index';
import Edit from '../DispatchGroupSet/pages/Edit';

export default function DispatchGroupSetRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    {path: '/dispatch/group-set', component: Index, isProtected: true, permissions: ['group_set_index']},
    {path: '/dispatch/group-set/create', component: Create, isProtected: true, permissions: ['group_set_create']},
    {path: '/dispatch/group-set/edit/:id', component: Edit, isProtected: true, permissions: ['group_set_edit']},

  ];

  return routes;
}