import { object } from "prop-types";
import IDispatch from "../interfaces/IDispatch";

export function parseDataDispatch(dataDispatch): IDispatch {

  return {
    id: dataDispatch.id ?? null,
    code: dataDispatch.code ?? null,
    inRouteAt: dataDispatch.in_route_at ?? null,
    inPlaceAt: dataDispatch.in_place_at ?? null,
    dispatchedAt: dataDispatch.dispatched_at ?? null,
    closedAt: dataDispatch.closed_at ?? null,
    priority: dataDispatch.priority ?? 0,
    agency: {
      id: dataDispatch.agency?.id ?? null,
      name: dataDispatch.agency?.name ?? null,
      acronym: dataDispatch.agency?.acronym ?? null,
    },
    closedStatus: {
      id: dataDispatch.closed_status?.id ?? null,
      name: dataDispatch.closed_status?.name ?? null,
      acronym: dataDispatch.closed_status?.acronym ?? null,
    },
    dispatchGroup: {
      id: dataDispatch.dispatch_group?.id ?? null,
      name: dataDispatch.dispatch_group?.name ?? null,
      acronym: dataDispatch.dispatch_group?.acronym ?? null,
    },
    type: {
      id: dataDispatch.type?.id ?? null,
      name: dataDispatch.type?.name ?? null,
      code: dataDispatch.type?.code ?? null,
      mustInformConsummate: dataDispatch.type?.must_inform_consummate ?? false,
    },
    subtype: {
      id: dataDispatch.subtype?.id ?? null,
      name: dataDispatch.subtype?.name ?? null,
      code: dataDispatch.subtype?.code ?? null,
    },
    status: {
      id: dataDispatch.status?.id ?? null,
      name: dataDispatch.status?.name ?? null,
      acronym: dataDispatch.status?.acronym ?? null,
    },
    occurrence: {
      id: dataDispatch.occurrence?.id ?? null,
      location: dataDispatch.occurrence?.location ?? null,
      name: dataDispatch.occurrence?.name ?? null,
      phone: dataDispatch.occurrence?.phone ?? null,
      address: dataDispatch.occurrence?.address ?? null,
      latitude: dataDispatch.occurrence?.latitude ?? null,
      longitude: dataDispatch.occurrence?.longitude ?? null,
      entryAt: dataDispatch.occurrence?.entry_at ?? null,
      createdAt: dataDispatch.occurrence?.created_at ?? null,
      calls: dataDispatch.occurrence?.calls ?? null,
      toClerk: dataDispatch.occurrence?.to_clerk ?? null,
      operation: {
        id: dataDispatch.occurrence?.operation?.id ?? null,
        name: dataDispatch.occurrence?.operation?.name ?? null,
      },
      state: {
        id: dataDispatch.occurrence?.state?.id ?? null,
        name: dataDispatch.occurrence?.state?.name ?? null,
        uf: dataDispatch.occurrence?.state?.uf ?? null,
      },
      city: {
        id: dataDispatch.occurrence?.city?.id ?? null,
        name: dataDispatch.occurrence?.city?.name ?? null,
      },
      district: {
        id: dataDispatch.occurrence?.district?.id ?? null,
        name: dataDispatch.occurrence?.district?.name ?? null,
      },
      entry_origin: {
        id: dataDispatch.occurrence?.entry_origin?.id ?? null,
        name: dataDispatch.occurrence?.entry_origin?.name ?? null,
      },
      entry_type: {
        id: dataDispatch.occurrence?.entry_type?.id ?? null,
        name: dataDispatch.occurrence?.entry_type?.name ?? null,
        code: dataDispatch.occurrence?.entry_type?.code ?? null,
      },
      entry_subtype: {
        id: dataDispatch.occurrence?.entry_subtype?.id ?? null,
        name: dataDispatch.occurrence?.entry_subtype?.name ?? null,
        code: dataDispatch.occurrence?.entry_subtype?.code ?? null,
      },
      reference: dataDispatch.occurrence?.address_reference ?? null,
      featured: (dataDispatch.occurrence?.featured ? true : false) ?? false,
    },
    hold: {
      id: dataDispatch.hold?.id ?? null,
      occurrence_id: dataDispatch.hold?.occurrence_id ?? null,
      dispatch_id: dataDispatch.hold?.dispatch_id ?? null,
      was_communicated: dataDispatch.hold?.was_communicated ?? null,
      holding_until_at: dataDispatch.hold?.holding_until_at ?? null,
      returned_at: dataDispatch.hold?.returned_at ?? null,
      created_user_id: dataDispatch.hold?.created_user_id ?? null,
      returned_user_id: dataDispatch.hold?.returned_user_id ?? null,
      occurrence_comment_id: dataDispatch.hold?.occurrence_comment_id ?? null,
      notes: dataDispatch.hold?.notes ?? null,
      device_id: dataDispatch.hold?.device_id ?? null,
      created_station_id: dataDispatch.hold?.created_station_id ?? null,
      returned_station_id: dataDispatch.hold?.returned_station_id ?? null,
      created_at: dataDispatch.hold?.created_at ?? null,
      updated_at: dataDispatch.hold?.updated_at ?? null,
      deleted_at: dataDispatch.hold?.deleted_at ?? null,
    },
    dispatchRetainHours: dataDispatch.dispatchRetainHours,
    dispatchRetainMinutes: dataDispatch.dispatchRetainMinutes,
    hasUnified: dataDispatch.hasUnified,
    totalSubtypes: dataDispatch?.totalSubtypes,
    totalDistricts: dataDispatch?.totalDistricts
  }
};

export function formatForSending(dispatchObject: IDispatch) {
  return {
    id: dispatchObject.id,
    code: dispatchObject.code || null,
    in_route_at: dispatchObject.inRouteAt || null,
    in_place_at: dispatchObject.inPlaceAt || null,
    dispatched_at: dispatchObject.dispatchedAt || null,
    closed_at: dispatchObject.closedAt || null,
    priority: dispatchObject.priority || 0,
    agency: {
      id: dispatchObject.agency.id || null,
      name: dispatchObject.agency.name || null,
      acronym: dispatchObject.agency.acronym || null,
    },
    closed_status: {
      id: dispatchObject.closedStatus?.id || null,
      name: dispatchObject.closedStatus?.name || null,
      acronym: dispatchObject.closedStatus?.acronym || null,
    },
    dispatch_group: {
      id: dispatchObject.dispatchGroup?.id || null,
      name: dispatchObject.dispatchGroup?.name || null,
      acronym: dispatchObject.dispatchGroup?.acronym || null,
    },
    type: {
      id: dispatchObject.type?.id || null,
      name: dispatchObject.type?.name || null,
      code: dispatchObject.type?.code || null,
    },
    subtype: {
      id: dispatchObject.subtype?.id || null,
      name: dispatchObject.subtype?.name || null,
      code: dispatchObject.subtype?.code || null,
    },
    status: {
      id: dispatchObject.status?.id || null,
      name: dispatchObject.status?.name || null,
      acronym: dispatchObject.status?.acronym || null,
    },
    occurrence: {
      id: dispatchObject.occurrence?.id || null,
      location: dispatchObject.occurrence?.location || null,
      name: dispatchObject.occurrence?.name || null,
      phone: dispatchObject.occurrence?.phone || null,
      address: dispatchObject.occurrence?.address || null,
      latitude: dispatchObject.occurrence?.latitude || null,
      longitude: dispatchObject.occurrence?.longitude || null,
      entry_at: dispatchObject.occurrence?.entryAt || null,
      created_at: dispatchObject.occurrence?.createdAt || null,
      calls: dispatchObject.occurrence?.calls || null,
      to_clerk: dispatchObject.occurrence.toClerk || null,
      operation: {
        id: dispatchObject.occurrence?.operation?.id ?? null,
        name: dispatchObject.occurrence?.operation?.name ?? null,
      },
      state: {
        id: dispatchObject.occurrence?.state?.id || null,
        name: dispatchObject.occurrence?.state?.name || null,
        uf: dispatchObject.occurrence?.state?.uf || null,
      },
      city: {
        id: dispatchObject.occurrence?.city?.id || null,
        name: dispatchObject.occurrence?.city?.name || null,
      },
      entry_type: {
        id: dispatchObject.occurrence?.entry_type?.id || null,
        name: dispatchObject.occurrence?.entry_type?.name || null,
        code: dispatchObject.occurrence?.entry_type?.code || null,
      },
      entry_subtype: {
        id: dispatchObject.occurrence?.entry_subtype?.id || null,
        name: dispatchObject.occurrence?.entry_subtype?.name || null,
        code: dispatchObject.occurrence?.entry_subtype?.code || null,
      },
      district: {
        id: dispatchObject.occurrence?.district?.id || null,
        name: dispatchObject.occurrence?.district?.name || null,
      },
      reference: dispatchObject.occurrence?.reference || null,
      featured: (dispatchObject.occurrence?.featured ? true : false) || false,
    },
    dispatchRetainHours: dispatchObject.dispatchRetainHours,
    dispatchRetainMinutes: dispatchObject.dispatchRetainMinutes,
  }
}
