/* eslint-disable max-lines-per-function */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
  Button,
  Card,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
} from '@mui/material'
import ErrorFallback from '~/components/ErrorFallback'
import Layout from '~/components/Layout'
import Loading from '~/components/Loading'
import { useLang } from '~/hooks/useLang'
import notify from '~/utils/notify'
import useReportState from '../../stores/ReportState'
import {
  downloadReportCSV,
  makeRequestToGetReportInfoDetailedByType,
} from '../../services/report'
import FilterAutocompleteType from '~/components/FilterAutocompleteType'
import { useHookstate } from '@hookstate/core'
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import FilterAutocompleteCategory from '~/components/FilterAutocompleteCategory'
import GenerateReportButton from '../../components/GenerateReportButton'
import FilterSwitchOtherDispatchsGroup from '~/components/FilterSwitchOtherDispatchsGroups'
import ExportButtonCSV from '../../components/ButtonExportCSV'
import FilterAutocompleteAgencies from '~/components/FilterAutocompleteAgencies'
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups'
import FilterAutocompleteCity from '~/components/FilterSelectAutocompleteCity'
import FilterAutocompleteEntryOrigin from '~/components/FilterAutocompleteEntryOrigin'
import FilterAutocompleteDispatchGroupSet from '~/components/FilterAutocompleteDispatchGroupSet'
import FilterAutocompleteOriginType from '~/components/FilterAutocompleteOriginType'

export interface DispatchClosedProps {
  readonly id: number
  readonly name: string
  readonly acronym: string
  readonly category_id?: number
}

export default function OccurrenceByType() {
  const { reportData, loadingReport } = useReportState()
  const { translate } = useLang()
  const expandFilterSearch = useHookstate<boolean>(true)
  const isLoading = useHookstate<boolean>(false)
  const typeId = useHookstate<number | null>(null)
  const categoryId = useHookstate<number | null>(null)
  const lockFields = useHookstate<boolean>(false)
  const startDate = useHookstate<string | null>(null)
  const finishDate = useHookstate<string | null>(null)
  const labelButton = useHookstate<string | null>(null)
  const withSolvedThirdParty = useHookstate<boolean>(false)
  const groupsId = useHookstate<readonly number[]>([])
  const agencyIds = useHookstate<readonly number[]>([])
  const period = useHookstate('')
  const reportName = 'occurrence-by-type'
  const cityId = useHookstate<number | null>(null)
  const entryOriginId = useHookstate<number | null>(null)
  const groupSetId = useHookstate<number | null>(null)
  const originTypeId = useHookstate<number | null>(null)

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])

  function generateReport() {
    if (startDate && finishDate) {
      loadingReport().set(true)
      isLoading.set(true)
      makeRequestToGetReportInfoDetailedByType({
        startDate: startDate.get(),
        finishDate: finishDate.get(),
        typeId: typeId.get(),
        categoryId: categoryId.get(),
        withSolvedThirdParty: withSolvedThirdParty.get(),
        agencyIds: agencyIds.get(),
        dispatchGroupsId: groupsId.get(),
        cityId: cityId.get(),
        entryOriginId: entryOriginId.get(),
        groupSetId: groupSetId.get(),
        originTypeId: originTypeId.get(),
      })
        .then((response) => {
          reportData().set(response)
          window.open(`/report/result/${reportName}`)
        })
        .finally(() => {
          loadingReport().set(false)
          isLoading.set(false)
        })
    } else {
      notify({
        message: translate('Initial and final date are mandatory'),
        type: 'error',
      })
    }
  }

  const exportCSV = async () => {
    if (startDate && finishDate) {
      loadingReport().set(true)
      const dataCSV = await makeRequestToGetReportInfoDetailedByType({
        startDate: startDate.get(),
        finishDate: finishDate.get(),
        typeId: typeId.get(),
        categoryId: categoryId.get(),
        withSolvedThirdParty: withSolvedThirdParty.get(),
        agencyIds: agencyIds.get(),
        dispatchGroupsId: groupsId.get(),
        exportCSV: true,
        cityId: cityId.get(),
        entryOriginId: entryOriginId.get(),
        groupSetId: groupSetId.get(),
        originTypeId: originTypeId.get(),
      }).finally(() => loadingReport().set(false))
      downloadReportCSV(
        `${translate(reportName)}-${new Date().getTime()}.csv`,
        dataCSV
      )
    } else {
      notify({
        message: translate('Initial and final date are mandatory'),
        type: 'error',
      })
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true}>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader
                title={`${translate('Report')}: ${translate(
                  `Occurrences by Type`
                )}`}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader
            title={`${translate('Filters')}`}
            avatar={
              <IconButton
                onClick={() =>
                  expandFilterSearch.set(!expandFilterSearch.get())
                }
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginRight: 1,
                  padding: 0,
                }}
              >
                {expandFilterSearch.value ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            }
          />
          <Collapse in={expandFilterSearch.get()}>
            <Grid
              container
              xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
              }}
            >
              <Grid container direction={'row'} rowGap={2}>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteAgencies
                    disabled={lockFields.get()}
                    agenciesId={agencyIds.get()}
                    onAgenciesChange={(agency) => {
                      labelButton.set(translate('Search'))
                      agencyIds.set(agency)
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchGroupSet
                    disabled={lockFields.get()}
                    dispatchGroupSetId={groupSetId?.get() ?? undefined}
                    onGroupDispatchSetChange={(group) => {
                      groupSetId?.set(group)
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchsGroups
                    disabled={lockFields.get()}
                    agencyId={agencyIds.get() ?? undefined}
                    dispatcheGroups={groupsId.get() ?? undefined}
                    onGroupDispatchChange={(group) => {
                      groupsId.set(group)
                      labelButton.set(translate('Search'))
                    }}
                    groupSetId={groupSetId.get()}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteCategory
                    disabled={lockFields.get()}
                    categoryId={categoryId.get()}
                    onCategoryChange={(category) => {
                      categoryId.set(category)
                      typeId.set(null)
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteType
                    disabled={lockFields.get()}
                    categoryId={categoryId.get() ?? undefined}
                    typeId={typeId.get()}
                    onTypeChange={(type) =>
                      typeId.set(Number(type?.id) ?? null)
                    }
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteCity
                    disabled={lockFields.get()}
                    onCityChange={(city) => {
                      cityId?.set(city)
                      labelButton.set(translate('Search'))
                    }}
                    cityId={cityId?.get()}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteEntryOrigin
                    disabled={lockFields.get()}
                    onEntryOriginChange={(entryOrigin) => {
                      entryOriginId?.set(entryOrigin)
                      labelButton.set(translate('Search'))
                    }}
                    originId={entryOriginId?.get()}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteOriginType
                    disabled={lockFields.get()}
                    typeId={originTypeId.get()}
                    onTypeChange={(originType) =>
                      originTypeId.set(Number(originType?.id) ?? null)
                    }
                  />
                </Grid>
                <Grid item xs={4} sx={{ pl: 1, pt: 1 }}>
                  <FilterSwitchOtherDispatchsGroup
                    onCheckedChange={() => {
                      labelButton.set(translate('Search'))
                      withSolvedThirdParty.set(!withSolvedThirdParty.value)
                    }}
                    disabled={lockFields.get()}
                    checked={withSolvedThirdParty.get()}
                  />
                </Grid>
                <Grid container direction={'row'}>
                  <FilterSelectPeriod
                    disabled={lockFields.get()}
                    onStartDateChange={(value) =>
                      startDate.set(value?.toJSON() ?? null)
                    }
                    onFinishDateChange={(value) =>
                      finishDate.set(value?.toJSON() ?? null)
                    }
                    onPeriodChange={(value) => {
                      period.set(value)
                    }}
                    period={period.get()}
                    required
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 2,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    agencyIds.set([])
                    groupSetId.set(null)
                    groupsId.set([])
                    categoryId.set(null)
                    typeId.set(null)
                    entryOriginId.set(null)
                    cityId.set(null)
                    period.set('')
                    withSolvedThirdParty.set(false)
                    originTypeId.set(null)
                  }}
                  sx={{ height: '40px' }}
                >
                  {translate('Clear Filters')}
                </Button>
                <ExportButtonCSV
                  isLoading={isLoading.get()}
                  dataToExport={exportCSV}
                />
                <GenerateReportButton
                  isLoading={isLoading.get()}
                  makeReport={generateReport}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary>
  )
}
