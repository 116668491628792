
import IOccurrence from '~/features/Occurrence/interfaces/IOccurrence';

export function parseDataOccurrence(data): IOccurrence {
  
  return {
    id: data.id ?? null,
    location: data.location ?? null,
    address_reference: data.address_reference ?? null,
    name: data.name ?? null,
    phone: data.phone ?? null,
    address: data.address ?? null,
    latitude: data.latitude ?? null,
    longitude : data.longitude ?? null,
    entry_at: data.entry_at ?? null,
    created_at: data.created_at ?? null,
    closed_at: data.closed_at ?? null,
    calls: data.calls ?? null,
    closure_requested: data.closure_requested ?? null,
    type: {
      id: data.type ? data.type.id : null,
      name: data.type ? data.type.name : null,
      code: data.type ? data.type.code : null,
    },
    subtype: {
      id: data.subtype ? data.subtype.id : null,
      name: data.subtype ? data.subtype.name : null,
      code: data.subtype ? data.subtype.code : null,
    },
    operation: {
      id: data.operation ? data.operation.id : null,
      name: data.operation ? data.operation.name : null,
    },
    state: {
      id: data.state ? data.state.id : null,
      name: data.state ? data.state.name : null,
      uf: data.state ? data.state.uf : null,
    },
    city: {
      id: data.city ? data.city.id : null,
      name: data.city ? data.city.name : null,
    },
    district: {
      id: data.district ? data.district.id : null,
      name: data.district ? data.district.name : null,
    },
    entry_origin: {
      id: data.district ? data.district.id : null,
      name: data.district ? data.district.name : null,
    },
    dispatches: [],
    totalSubtypes: 0,
    totalDistricts: 0,
  }
}