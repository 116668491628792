import React, { useState } from 'react'
import { useCallback, useEffect } from 'react'
import useSurveillanceReportingState from '../../stores/SurveillanceReportingState'
import {
  List,
  ListItem,
  Paper,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material'
import { toDomain } from '~/mappers/surveillance-reporting-mapper'
import {
  Downgraded,
  SetStateAction,
  State,
  useHookstate,
} from '@hookstate/core'
import { NoItemComponent } from '~/components/NoItem'
import { ReportComment } from './components/ReportComment'
import ReportMenuList from './components/ReportMenuList'
import { ISurveillanceReportingReponse } from '../../interfaces/ISurveillanceReporting'
import useUserState from '~/features/User/stores/UserState'
import useDispatchState from '~/features/Dispatch/stores/DispatchState'
import { IFilters } from '~/components/ListDispatchesSocket/services'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLang } from '~/hooks/useLang'
import { listSurveillanceReportingByDispatchId } from '../../services'

interface ISurveillanceReportingCards {
  readonly onlyCurrentOccurrence?: boolean
}

// eslint-disable-next-line max-lines-per-function
export default function SurveillanceReportingCards({
  onlyCurrentOccurrence = false,
}: ISurveillanceReportingCards) {
  const { user: userSession } = useUserState()
  const { translate } = useLang()
  const { dispatch } = useDispatchState()
  const { id: dispatchId } = dispatch()
  const {
    listAllSurveillanceReporting,
    surveillanceReportingStates,
    listSurveillanceReporting,
  } = useSurveillanceReportingState()
  const { loading } = surveillanceReportingStates()
  const {
    surveillanceReporting,
    save,
    showCheckbox,
    reports,
    switchCheck,
    newNarrations,
    filters,
    createOccurrence,
  } = surveillanceReportingStates()
  const { cameraId, notes, reportedAt, occurrenceId, id } =
    surveillanceReporting
  const reportsNoFiltered = useHookstate<
    readonly ISurveillanceReportingReponse[]
  >([])
  const rowCount = useHookstate(0)
  const reportId = useHookstate<number>(-1)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const idMenu = open ? 'menu-report' : ''
  const disabledAssociate = useHookstate<number | null>(null)
  const reportFiltered = showCheckbox.get()
    ? reports.filter(
        (reportFiltered) =>
          reportFiltered.userId.get() === (userSession().id.get() as number) &&
          !reportFiltered.occurrenceId.get()
      )
    : reports

  const allEqual = (arr: any[]) => {
    return arr.every((value: any) => value === arr[0])
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const findIndex = () => {
    const idReport = reportId.get()
    const findReport = reports.findIndex((rep) => rep.id.get() === idReport)
    return findReport
  }

  const handleEditReport = () => {
    save.set(true)
    cameraId.set(reports[findIndex()].cameraId.get())
    notes.set(reports[findIndex()].notes.get())
    reportedAt.set(reports[findIndex()].reportedAt.get())
    occurrenceId.set(reports[findIndex()].occurrenceId.get())
    id.set(reports[findIndex()].id.get())
    handleClose()
  }

  const handleAssociateReport = () => {
    const index = findIndex()
    reports[index].set((prev) => ({
      ...prev,
      checked: true,
    }))
    showCheckbox.set(true)
    handleClose()
  }

  const treatData = (
    reset: boolean,
    data: any[],
    rowsCount: SetStateAction<number>
  ) => {
    if (reset) {
      loading.set(true)
      const toDomainsReport = data.map((report: any) => toDomain(report))
      reports.set(toDomainsReport)
      reportsNoFiltered.set(toDomainsReport)
      rowCount.set(rowsCount)
    } else {
      loading.set(true)
      const toDomainsReport = data.map((report: any) => toDomain(report))
      reports.merge(toDomainsReport)
      reportsNoFiltered.set(toDomainsReport)
      rowCount.set(rowsCount)
    }

  }

  const nextdata = async (reset = false) => {
    const pageQuery = reset ? 0 : Math.floor(reports.get().length / 10)
    const pageQueryListByDispatch = reset
      ? 0
      : Math.floor(reports.get().length / 5)

    if (onlyCurrentOccurrence) {
      await listSurveillanceReportingByDispatchId({
        dispatchId: dispatchId.get(),
        page: pageQueryListByDispatch,
      }).then(({ data, rowsCount }) => {
        treatData(reset, data, rowsCount)
      })
    } else {
      await listSurveillanceReporting({
        page: pageQuery,
        limit: 10,
        filters: [
          {
            field: 'user_id',
            operator: 'equals',
            value: switchCheck.get()
              ? (userSession().id.get() as number)
              : null,
          },
        ],
      }).then(({ data, rowsCount }) => {
        treatData(reset, data, rowsCount)
      })
    }

    loading.set(false)
  }

  useEffect(() => {
    if (!loading.get()) {
      nextdata(true)
    }
  }, [switchCheck.get(), newNarrations.get()])

  return (
    <Paper
      variant="outlined"
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: !reports.get().length ? 'center' : 'flex-start',
        borderRadius: 1,
      }}
    >
      {loading.get() ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <List dense={true} sx={{ width: '100%' }}>
          <InfiniteScroll
            dataLength={reportFiltered.length}
            next={() => nextdata()}
            hasMore={
              reportFiltered.length != rowCount.get() &&
              reportFiltered.length != 0
            }
            height={400}
            loader={
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            }
            endMessage={
              reports.length == 0 && (
                <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%'
                }}
              >
                <Typography>
                  {translate('There are no reports')}
                </Typography>
              </Box>
              )}
            
          >
            {reportFiltered.map(
              (reportMap: State<ISurveillanceReportingReponse>) => (
                <ListItem sx={{ padding: '5px 8px' }} key={reportMap.id.get()}>
                  <ReportComment
                    report={reportMap}
                    showCheckbox={showCheckbox.get()}
                    handleClickMenu={(event) => {
                      setAnchorEl(event?.currentTarget)
                      reportId.set(reportMap.id.get())
                      disabledAssociate.set(
                        Number(reportMap.occurrenceId.get()) ?? null
                      )
                    }}
                  />
                </ListItem>
              )
            )}
          </InfiniteScroll>
        </List>
      )}
      <ReportMenuList
        handleEditReport={handleEditReport}
        handleAssociateReport={handleAssociateReport}
        idMenu={idMenu}
        handleClose={handleClose}
        anchorEl={anchorEl}
        disabledAssociate={disabledAssociate.get()}
      />
    </Paper>
  )
}
