import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IDevice from '../interfaces/IDevice';
import Layout from '~/components/Layout';
import { useHookstate } from '@hookstate/core';



export default function Create() {
  const { register, handleSubmit, control, setValue: setFormValue } = useForm<IDevice>();
  const agencyId = useHookstate<number | null>(null)
  const fields = [
    { name: 'name', type: 'string', required: true},
    { name: 'brand', type: 'string' },
    { name: 'model', type: 'string' },
    { name: 'device_type_id', type: 'selectAutoComplete', model: 'device-type', placeholder: 'Device Type', optionSelect: 'name', required: true },
    { name: 'operation_unit_id', type: 'selectAutoComplete', model: 'operation-unit', placeholder: 'Operation unit'},    
    { name: 'agency_id', type: 'selectAutoComplete', model: 'agency', placeholder: 'Agency:name', required: true, filter: agencyId, independent: true },
    { name: 'dispatch_group_id', type: 'selectAutoComplete', model: 'dispatch/group', placeholder: 'Dispatch Group', optionSelect: 'acronym', required: true, filter: agencyId, filterName: 'agency_id', dependent: true },
    { name: 'imei', type: 'string' },
    { name: 'tracking_id', type: 'string' },
    { name: 'lock_temporary_tracking', type: 'switch', checked: true},

  ];

  return (
    <Layout marginLayout={true}>
      <CrudCreate model={'device'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Device successfully created!'}
        title={'Add Device'}
        subheader={'Add new Device'}
        setFormValue={setFormValue} />
    </Layout>
  )
}