import { t  as translate } from 'i18next';
import React from "react";
import DispatchTimeFieldForm from "../DispatchTimeFieldForm";


export default function MinuteField({isValid, dispatchRetainMinutes, clear}){
  React.useEffect(() => {
    if(clear.get()) {
      dispatchRetainMinutes.set('')
    }
  }, []);

  return (
    <DispatchTimeFieldForm
      state={dispatchRetainMinutes}
      id="dispatchRetainMinutes"
      name="dispatchRetainMinutes"
      label={translate("Minutes")}
      autoComplete="dispatchRetainMinutes"
      required={false}
      disabled={!isValid}
    />
  );
}
