import React from 'react';
import { AppRouteProps } from '~/routes';
import Index from './pages/Index';


export default function TacticalRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    {path: '/tactical', component: Index, isProtected: true, permissions: ['tactical_index']},

  ];

  return routes;
}


