import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IDispatchGroupSet from '../interfaces/IDispatchGroupSet';
import { useParams } from 'react-router-dom';
import ICrudEditParams from '~/components/CrudEdit/interfaces/ICrudEditParams';

export default function Edit() {
    const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm<IDispatchGroupSet>();
    const fields = [
        { name: 'id', type: 'hidden' },
        { name: 'name', type: 'string', required: true },
        { name: 'dispatch_groups', type: 'transferList', options: { firstListUrl: `/dispatch/group-set/${useParams<ICrudEditParams>().id}/dispatch-groups`, secondListUrl: '/dispatch/group' }, placeholder: 'Associated Dispatch Group' }
    ];

    return (
        <CrudEdit
            model={'dispatch/group-set'}
            fields={fields}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            setFormValue={setFormValue}
            messageSuccess={'Dispatch Group Set successfully updated!'}
            title={'Edit Dispatch Group Set'}
            getValues={getValues}
        />
    )
}