import { createState, useState } from '@hookstate/core';
import { storeOccurrenceCommentService, getAllOccurrenceCommentService } from '~/features/Occurrence/services/index';
import { CommentDataProps, CommentProps, IActionCommentResponse, PostCommentResponse } from '~/features/Dispatch/stores/DispatchCommentState';
import { toDomain } from '~/mappers/comments-mapper';
import { authFetch } from '~/services/fetch';



export interface OccurrenceCommentProps {
  readonly newComment: null | string,
  readonly allCurrentComments: readonly PostCommentResponse['data'][]
  readonly loading: boolean
  readonly retryConnect: boolean
}

const OccurrenceCommentDefault = createState<OccurrenceCommentProps>({
  newComment: '',
  allCurrentComments: [],
  loading: false,
  retryConnect: false,
});


const useOccurrenceCommentState = () => {
  const { newComment, allCurrentComments } = useState( OccurrenceCommentDefault );

  const loading = useState(OccurrenceCommentDefault.loading)
  const retryConnect = useState(OccurrenceCommentDefault.retryConnect)

  function findEntryComment(commentId: number) {
    return allCurrentComments.find((comment) => comment.get().id == commentId);
  }

  function addComment(commentData) {
    if (!findEntryComment(commentData.id)) {
      allCurrentComments.merge([commentData]);
    }
  }

  async function editComment({ comment, commentId }: Omit<CommentProps, 'dispatchId'>): Promise<IActionCommentResponse> {

    const targetUrl = `/occurrence-comment/${commentId}`

    const { data } = await authFetch({
      url: targetUrl,
      method: 'PUT',
      data: {
        comment,
      }
    })
    return data;
  }

  async function deleteComment({ commentId }: CommentProps): Promise<IActionCommentResponse> {

    const targetUrl = `/occurrence-comment/${commentId}`
    const { data } = await authFetch({
      url: targetUrl,
      method: 'DELETE',

    })
    return data;
  }

  function findDispatchComment(commentId: number) {
    return allCurrentComments.findIndex((comment) => comment.get().id == commentId);
  }

  function editedComment({ comment, id, updatedAt, changedByUser, isEdited }: CommentDataProps) {
    const commentIndex = findDispatchComment(id);
    if (commentIndex > -1) {
      allCurrentComments[commentIndex].comment.set(comment)
      allCurrentComments[commentIndex].updatedAt.set(updatedAt)
      allCurrentComments[commentIndex].isEdited.set(isEdited)
      allCurrentComments[commentIndex].changedByUser.set(changedByUser)
    }
  }

  function deletedComment({ comment, id, updatedAt, changedByUser, isDeleted }: CommentDataProps) {
    const commentIndex = findDispatchComment(id);
    if (commentIndex > -1) {
      allCurrentComments[commentIndex].comment.set(comment)
      allCurrentComments[commentIndex].updatedAt.set(updatedAt)
      allCurrentComments[commentIndex].isDeleted.set(isDeleted)
      allCurrentComments[commentIndex].changedByUser.set(changedByUser)
    }
  }

  return ({
    newComment: () => newComment,
    allCurrentComments: () => allCurrentComments,
    loading: () => loading,
    retryConnect: () => retryConnect,
    syncPreviousComments: async (id: string | number | null) => {
      retryConnect.set(false)
      loading.set(true)
      allCurrentComments.set([]);
      newComment.set('')

      getAllOccurrenceCommentService({id: id})
      .then((data) => {
        return allCurrentComments.set(data.map(comment => toDomain(comment)));
      })
      .catch(() => retryConnect.set(true))
      .finally(() => loading.set(false))

    },
    sendComment: async ({id, comment}) => { 
        
      const isEmptyString = !comment?.length
      
      if(isEmptyString) {
        return false
      }


      const result = storeOccurrenceCommentService({
          id: id,
          comment: comment
        })
        .then((result) => {
          addComment(toDomain(result));
          newComment.set('');
        })
        .catch(err =>{
          console.error(err)
          return false
        })

      return true
    },
    sendEditOccurrenceComment: async ({ comment, commentId }: Omit<CommentProps, 'dispatchId'>) => {
      const commentEdited = await editComment({ commentId, comment });
      editedComment({
        comment: commentEdited.comment,
        mediaPath: commentEdited.media_path,
        createdAt: commentEdited.created_at,
        dispatchId: commentEdited.dispatch_id,
        occurrenceId: commentEdited.occurrence_id,
        id: commentEdited.id,
        user: commentEdited.user,
        changedByUser: commentEdited.changed_by_user,
        deletedAt: null,
        updatedAt: commentEdited.updated_at,
        isEdited: commentEdited.is_edited,
        closingComment: commentEdited.closingComment,
        device: commentEdited.device,
        station: commentEdited.station,
      });

      newComment.set('');
      return true
    },
    sendDeleteOccurrenceComment: async (commentId) => {
      const commentDeleted = await deleteComment(commentId);
      deletedComment({
        comment: commentDeleted.comment,
        mediaPath: commentDeleted.media_path,
        createdAt: commentDeleted.created_at,
        dispatchId: commentDeleted.dispatch_id,
        occurrenceId: commentDeleted.occurrence_id,
        id: commentDeleted.id,
        user: commentDeleted.user,
        changedByUser: commentDeleted.changed_by_user,
        deletedAt: null,
        updatedAt: commentDeleted.updated_at,
        isEdited: commentDeleted.is_edited,
        isDeleted: commentDeleted.is_deleted,
        closingComment: commentDeleted.closingComment,
        device: commentDeleted.device,
        station: commentDeleted.station,
      });

      newComment.set('');
      return true
    },
    addComment: addComment,
  })
}

export default useOccurrenceCommentState;