import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IOperation from '~/features/Operation/interfaces/IOperation';
import Layout from '~/components/Layout';


export default function Create() {
    const { register, handleSubmit, control, setValue: setFormValue } = useForm<any>();
    const fields = [
        { name: 'name', type: 'string' },
        { name: 'agency_id', type: 'selectAutoComplete', model: 'agency', placeholder: 'Agency:name' },
    ];

    return (
        <Layout marginLayout={true}>
            <CrudCreate model={'operation-unit'}
                fields={fields}
                register={register}
                handleSubmit={handleSubmit}
                control={control}
                messageSuccess={'Operation unit successfully created!'}
                title={'Add Operation unit'}
                subheader={'Add a new operation unit'}
                setFormValue={setFormValue} />
        </Layout>
    )
}
