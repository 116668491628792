import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';


export default function Edit() {
    const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm<any>();
    const fields = [
        { name: 'id', type: 'hidden' },
        { name: 'name', type: 'string' },
        { name: 'expiration_time_in_days', type: 'number', placeholder: 'Expiration time in days' },
        { name: 'review_time_in_days', type: 'number', placeholder: 'Review time in days' },
        { name: 'is_visible_to_dispatch', type: 'boolean', placeholder: 'Is visible to dispatch' },
        { name: 'is_visible_to_mobile', type: 'boolean', placeholder: 'Is visible to mobile' },
        { name: 'is_criminal', type: 'boolean', placeholder: 'Is criminal' },
    ];

    return (
        <CrudEdit model={'interest-point/type'}
            fields={fields}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            setFormValue={setFormValue}
            messageSuccess={'Interest point type successfully edited!'}
            title={'Edit Interest point type'}
            getValues={getValues}
        />
    )
}



