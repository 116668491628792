import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { getAllCategories } from './services';
import { CategoryResponse } from './interface';

interface SelectCategoryProps {
    readonly onCategoryChange: (value: number | null) => void;
    readonly categoryId?: number | null;
    readonly disabled?: boolean
}

export default function FilterAutocompleteCategory({ onCategoryChange, disabled, categoryId }: SelectCategoryProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly CategoryResponse[]>([]);
    const [value, setValue] = useState<CategoryResponse | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        getAllCategories().then(categories => {
            setOptions(categories)
        })
    }, [])

    useEffect(() => {
        if (!categoryId) {
            setValue(null)
        }
    }, [categoryId])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                id='filter-categories'
                options={options}
                disabled={disabled}
                value={value}
                noOptionsText={translate('No options')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={(_, category) => {
                    setValue(category)
                    onCategoryChange(category?.id ?? null)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Category')}
                    />
                )}
            />
        </FormControl>
    )
}