import React from 'react';
import { withRouter, matchPath } from "react-router-dom";
import NotFound404 from "~/features/System/pages/notFound404";
import routers from '../routers';

const ProviderHOC = (NotFoundRoute) => {
    const RouteProvider = (props) => {
        const currentLocation = props.location.pathname;
        const routeExists = routers.find((item) => matchPath(currentLocation, {
            path: item.path,
            exact: true,
            strict: false
        }));
        
        if(!routeExists && currentLocation != '/') {
           return <NotFoundRoute {...props}/>
        }
        return props.children;
    }
    return withRouter(RouteProvider)
}
  
// eslint-disable-next-line new-cap
export default ProviderHOC(NotFound404);