/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core'
import { CardHeader, Grid, IconButton, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ColumnChart from '~/components/ColumnChart'
import PieChart from '~/components/PieChart'
import { useLang } from '~/hooks/useLang'
import { useDispatchDashboardState } from '../../stores/DispatchDashboardState'
import { getDeviceByStatus, getDispatchByClosedStatus } from './services/index'
import { IDespacthStatisticGraphProps } from '../../interfaces/IDispacthStatistic'
import { getDispatchByDispatchGroup } from '../DispatchByLocationCharts/services'

type PayloadProps = {
  readonly withSolvedThirdParty?: boolean
  readonly exludeClosed?: boolean
}
type Payload2Props = {
  readonly excludeDisconnected?: boolean
}
type ResponseProps = ReadonlyArray<{
  readonly item: string
  readonly value: number
}>

export default function DispatchByTimeAndStatusCharts({
  loading,
  agencyId,
  deviceId,
  operationId,
  dispatchGroupId,
  category,
  type,
  subtype,
  dateStart,
  dateFinish,
  thirdParty,
  refresh,
  consummated,
  statusIds,
  cityIds,
  groupSetId,
  location,
  closedStatusClassId,
  closedStatusId,
  qualifierOptions,
  expandFilterSearch,
  operationUnitId
}: IDespacthStatisticGraphProps) {
  const { translate } = useLang()
  const closedStatusData = useHookstate<ResponseProps>([])
  const groupData = useHookstate<ResponseProps>([])

  const payload = useHookstate<PayloadProps>({
    withSolvedThirdParty: thirdParty,
    exludeClosed: false,
  })
  const closedStatusDataTotal = useHookstate<number>(0)
  const groupDataTotal = useHookstate<number>(0)

  useEffect(() => {
    closedStatusDataTotal.set(0)
    groupDataTotal.set(0)
    payload.set({ withSolvedThirdParty: thirdParty, exludeClosed: false })
    loading.set(true)
    getDispatchByClosedStatus(
      agencyId,
      deviceId,
      operationId,
      dispatchGroupId,
      category,
      type,
      subtype,
      dateStart,
      dateFinish,
      payload.get(),
      consummated,
      statusIds,
      cityIds,
      groupSetId,
      location,
      closedStatusClassId,
      closedStatusId,
      qualifierOptions,
      operationUnitId
    )
      .then((response) => {
        const result = response.map((responseData) => {
          closedStatusDataTotal.set(
            closedStatusDataTotal.get() + responseData.total
          )
          return { item: responseData.name, value: responseData.total }
        })
        return closedStatusData.set(result)
      })
      .finally(() => {
        loading.set(false)
      })

    getDispatchByDispatchGroup({
      agencyId: agencyId,
      deviceId: deviceId,
      operationId: operationId,
      dispatchGroupId: dispatchGroupId,
      categoryIds: category,
      typeIds: type,
      subtypeIds: subtype,
      startDate: dateStart,
      endDate: dateFinish,
      payload: payload.get(),
      consummated: consummated,
      statusIds: statusIds,
      cityIds: cityIds,
      location: location,
      closedStatusClassId: closedStatusClassId,
      closedStatusId: closedStatusId,
      qualifierOptions: qualifierOptions,
      groupSetId: groupSetId,
      operationUnitId: operationUnitId
    })
      .then((response) => {
        const result = response.map((responseData) => {
          groupDataTotal.set(groupDataTotal.get() + responseData.total)
          return { item: responseData.name, value: responseData.total }
        })
        return groupData.set(result)
      })
      .finally(() => {
        loading.set(false)
      })
  }, [refresh])

  return (
    <Grid
      container
      xs={12}
      sx={{
        display: 'flex',
        paddingX: 3,
        paddingTop: 3,
        justifyContent: 'space-between',
      }}
    >
      <Grid item xs={6} hidden={!expandFilterSearch}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
          {translate('Data by Status')}
        </Typography>
      </Grid>
      <Grid item xs={6} hidden={!expandFilterSearch}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
          {translate('Dispatch by Dispatch Group')}
        </Typography>
      </Grid>
      <Grid item xs={6} hidden={!expandFilterSearch}>
        <PieChart
          data={closedStatusData.attach(Downgraded).get()}
          title={'Dispatch x Closed Status'}
          subtitle={`Total: ${closedStatusDataTotal.get()}`}
        />
      </Grid>
      <Grid item xs={6} hidden={!expandFilterSearch}>
        <ColumnChart
          data={groupData.attach(Downgraded).get()}
          title={'Dispatch x Dispatch Group'}
          subtitle={`Total: ${groupDataTotal.get()}`}
          width={950}
        />
      </Grid>
    </Grid>
  )
}
