import React from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';

export default function OverviewContentPrint({overview}){
  const { translate } = useLang();

  return (
    <Container fixed={false}>
      <Grid container xs={12}>
        <Grid item xs={2.4}>
          <Typography variant='subtitle1'><b>{translate('Entry')}</b></Typography>
          <Typography variant='subtitle2'>
            {translate('Dispatch')}: {overview.get()?.time_dispatch ?? `0`}<br/>
            {translate('Displacement')}: {overview.get()?.time_displacement ?? `0`}<br/>
            {translate('Finalization')}: {overview.get()?.time_finished ?? `0`}<br/>
            {translate('Total')}: {overview.get()?.time_total ?? `0`}<br/>
          </Typography>
        </Grid>
        <Grid item xs={2.4}>
          <Typography variant='subtitle1'><b>{translate('Viaturas')}</b></Typography>
          <Typography variant='subtitle2'>{translate('Viaturas')}: {overview.get()?.total_devices ?? `0`}</Typography>
        </Grid>
        <Grid item xs={2.4}>
          <Typography variant='subtitle1'><b>{translate('Involved')}</b></Typography>
          <Typography variant='subtitle2'>{translate('Involved')}: {overview.get()?.total_peoples_involveds ?? `0`}</Typography>
        </Grid>
        <Grid item xs={2.4}>
          <Typography variant='subtitle1'><b>{translate('Qualifiers')}</b></Typography>
          <Typography variant='subtitle2'>
          {translate('Animals')}: {overview.get()?.total_animals_involveds ?? `0`}<br/>
          {translate('Guns')}: {overview.get()?.total_weapons_involveds ?? `0`}<br/>
          {translate('Drugs')}: {overview.get()?.total_drugs_involveds ?? `0`}<br/>
          {translate('Objects')}: {overview.get()?.total_objects_involveds ?? `0`}<br/>
          {translate('Vehicles')}: {overview.get()?.total_vehicles_involveds ?? `0`}<br/> 
          </Typography>
        </Grid>
        <Grid item xs={2.4}>
          <Typography variant='subtitle1'><b>{translate('Agencies Involved')}</b></Typography>
          <Typography variant='subtitle2'>{translate('Agencies Involved')}: {overview.get()?.total_agencies_involveds ?? `0`}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
}