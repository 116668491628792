import React, { useEffect } from 'react';
import Layout from '~/components/Layout';
import TabListVehicle from '~/components/TabListVehicle';
import { useLang } from '~/hooks/useLang';

export default function ListVehicles() {
  const { translate } = useLang();

  useEffect(() => {
    document.title = translate('Vehicles List - Dispatch System')
  }, [])
  
  return (
    <Layout
      marginLayout={false}
      sidebar={false}
    >
      <TabListVehicle/>
    </Layout>
  );
}