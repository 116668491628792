/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { FormControl, Grid, InputAdornment } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useDispatchRegistrationDrugState from '../../../stores/DispatchRegistrationDrugState';
import useDispatchRegistrationState from '../../../stores/DispatchRegistrationState';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import Buttons from '../../DispatchRegistrationTab/components/Buttons';
import notify from '~/utils/notify';
import FieldAutocompleteRegistration from '~/components/FieldAutocompleteRegistration';
import { useHookstate } from '@hookstate/core';

interface IFormDrug {
  readonly handleClickOpenConfirmAction: (action: string) => void
}

interface IErrosProps {
  readonly type: boolean;
  readonly circunstance: boolean;
  readonly destination: boolean;
  readonly unitMeasurement: boolean;
  readonly quantity: boolean;
}

export default function DrugForm({ handleClickOpenConfirmAction }: IFormDrug) {
  const loading = useHookstate<boolean>(false)
  const { dispatch } = useDispatchState();
  const { occurrence, status } = dispatch();
  const { id: occurrenceIdState, } = occurrence;
  const disableFields = status.value.id === 8
  const { registrationDrugDispatch, sendDrug, editDrug, getDrugTypes, } = useDispatchRegistrationDrugState();
  const { registrationDispatch, getCircunstances, getDestinations, getUnitMeasurement, } = useDispatchRegistrationState();
  const { addRegistration } = registrationDispatch();

  const { drug } = registrationDrugDispatch();

  const { translate } = useLang();

  const [error, setError] = useState<IErrosProps>({
    type: false,
    circunstance: false,
    destination: false,
    unitMeasurement: false,
    quantity: false,
  });

  const someInvalidInput = () => {
    if (!drug.drugType.get()) {
      setError({
        ...error,
        type: true
      })
      return true;
    }
    if (!drug.unitMeasurement.get()) {
      setError({
        ...error,
        unitMeasurement: true
      })
      return true;
    }
    if (drug.unitMeasurement.get() && !drug.quantity.get()) {
      setError({
        ...error,
        quantity: true
      })
      return true;
    }

    if (!drug.circunstance.get()) {
      setError({ ...error, circunstance: true })
      return true;
    }
    if (!drug.destination.get()) {
      setError({ ...error, destination: true })
      return true;
    }
    return false;
  }

  const handleSend = useCallback(async () => {
    loading.set(true)
    await sendDrug(drug.get()).finally(() => {
      loading.set(false)
    })
    notify({ message: translate('Successfully created'), type: 'success' })
    addRegistration.set(false)
  }, [addRegistration, drug, sendDrug, translate])

  const handleEdit = useCallback(async () => {
    loading.set(true)
    await editDrug(drug.get()).finally(() => {
      loading.set(false)
    })
    notify({ message: translate('Saved successfully'), type: 'success' })
    addRegistration.set(false)
  }, [addRegistration, drug, editDrug, translate])

  useEffect(() => {
    drug.set(prev => ({ ...prev, occurrenceId: occurrenceIdState.get() }))

  }, [])

  return (
    <FormControl fullWidth size='small' >
      <Grid container direction='row'>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={drug.drugType.get()?.id as string}
            onError={(message) => setError({ ...error, type: message })}
            isRequired
            error={error.type}
            label={translate('Drug Type')}
            onValueChange={(value) => {
              drug.set(prev => ({ ...prev, drugType: value }))
            }}
            optionsFetch={getDrugTypes}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={drug.unitMeasurement.get()?.id as string}
            onError={(message) => setError({ ...error, unitMeasurement: message })}
            isRequired
            error={error.unitMeasurement}
            label={translate('Unit Measurement')}
            onValueChange={(value) => {
              drug.unitMeasurement.set({
                symbol: value?.symbol ?? '',
                id: value?.id ?? '',
                name: value?.name ?? ''
              })
            }}
            optionsFetch={getUnitMeasurement}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={1.5} sx={{ padding: 1 }}>
          <TextField
            fullWidth
            size='small'
            id="outlined-quantity"
            error={!!error.quantity}
            onFocus={() => setError({ ...error, quantity: false })}
            label={translate('Quantity')}
            value={drug.quantity.get()}
            type="number"
            disabled={!drug.unitMeasurement.get() || disableFields}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { min: 0, step: 0.01 },
              endAdornment: <InputAdornment position="end">
                {drug.unitMeasurement.get()?.symbol}
              </InputAdornment>,
              onChange: (event) => {
                drug.quantity.set(parseFloat(event.target.value))
              }
            }}
          />
        </Grid>
        <Grid item xs={2.25} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={drug.circunstance.get()?.id as string}
            onError={(message) => setError({ ...error, circunstance: message })}
            isRequired
            error={error.circunstance}
            label={translate('Circunstance')}
            onValueChange={(value) => {
              drug.circunstance.set({
                id: value?.id ?? '',
                name: value?.name ?? ''
              })
            }}
            optionsFetch={getCircunstances}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={2.25} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={drug.destination.get()?.id as string}
            onError={(message) => setError({ ...error, destination: message })}
            isRequired
            error={error.destination}
            label={translate('Destination')}
            onValueChange={(value) => {
              drug.destination.set({
                id: value?.id ?? '',
                name: value?.name ?? ''
              })
            }}
            optionsFetch={getDestinations}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={12} sx={{ padding: 1, marginBottom: 1 }}>
          <TextField
            disabled={disableFields}
            id="outlined-multiline-flexible"
            label={translate('Note')}
            multiline
            fullWidth
            rows={4}
            size='small'
            value={drug.notes.get()}
            InputProps={{
              onChange: (event) => {
                const notes = event.target.value
                drug.notes.set(notes)
              },
            }}
            sx={{ height: 92 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Buttons
            loading={loading}
            isUpdate={!!drug.id.get()}
            handleClickOpenConfirmAction={handleClickOpenConfirmAction}
            handleEdit={handleEdit}
            handleSend={handleSend}
            someInvalidInput={someInvalidInput}
            disableFields={disableFields}
          />
        </Grid>
      </Grid>
    </FormControl >
  );
}