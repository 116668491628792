import React from "react";
import { Box, Typography } from "@mui/material";
import { useLang } from "~/hooks/useLang";

type INoItemComponent = {
  readonly title: string;
}

export function NoItemComponent({ title }: INoItemComponent) {
  const { translate } = useLang()
  return (
    <Box style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Typography>
        {translate(`${title}`)}
      </Typography>
    </Box>
  )
}