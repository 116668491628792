import React from 'react';
import Create from './pages/Create';
import Edit from './pages/Edit';
import Index from './pages/Index';
import { AppRouteProps } from '~/routes';

export default function ContactCenterRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    {path: '/contact-center', component: Index, isProtected: true, permissions: ['contact_center_index']},
    {path: '/contact-center/create', component: Create, isProtected: true, permissions: ['contact_center_create']},
    {path: '/contact-center/edit/:id', component: Edit, isProtected: true, permissions: ['contact_center_edit']},

  ];

  return routes;
}
