import React, { useState, ChangeEvent } from 'react';
import { State, useHookstate } from '@hookstate/core';
import { IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, Grid } from '@mui/material';
import { Validation as validation } from '@hookstate/validation';
import { LockClock } from '@mui/icons-material';
import { useLang } from '../../../../hooks/useLang';
import useSystemState from '../../../System/stores/SystemState';
import useDipatchState from '~/features/Dispatch/stores/DispatchState';
import DispatchTimeInput from '../DispatchTimeInput';
import { LoadingButton } from '@mui/lab';

interface DispatchHoldButtonProps {

  readonly canHold: boolean | undefined;
  readonly onHold: boolean | undefined;
  readonly holdDispatch: (minutes: number, commet: string | null, loading?: State<boolean>) => Promise<void> | undefined;
}

export default function DispatchHoldButton(props: DispatchHoldButtonProps) {
  const { translate } = useLang()
  const [open, setOpen] = useState(false);
  const { getStatusColors } = useSystemState();
  const dispatchState = useDipatchState();
  const { id, dispatchRetainHours, dispatchRetainMinutes, hold } = dispatchState.dispatch();
  const clear = useHookstate<boolean>(false);
  const loading = useHookstate<boolean>(false);

  const isValid = validation(id).valid();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clear.set(true);
  };

  const clickOutModalClose = () => {
    setOpen(false);
    clear.set(false);
  }

  const action = () => {
    if (props.holdDispatch) {
      props.holdDispatch(dispatchRetainMinutes.get(), hold.notes.get(), loading );
    }
    handleClose();
  };

  return (
    <Grid item>
      <IconButton title={translate('Retain dispatch')} disabled={!props.canHold} onClick={handleClickOpen}>
        <LockClock color={props.onHold ? 'warning' : getStatusColors(9).contrastText} />
      </IconButton>
      <Dialog 
      open={open} 
      onClose={clickOutModalClose}       
      maxWidth={'xs'}
      fullWidth
      >
        <DialogTitle minWidth={"320px"} variant='h5'>
          {`${props.onHold ? translate('Free') : translate('Retain')} ${translate('Dispatch')}`}
        </DialogTitle>
        <DialogContent>

          {props.onHold && 'Deseja liberar o despacho?'}
          <Grid item sx={{ marginTop: 1 }}>
            {!props.onHold && <DispatchTimeInput isValid={isValid} dispatchRetainHours={dispatchRetainHours} dispatchRetainMinutes={dispatchRetainMinutes} clear={clear} />}
          </Grid>
          {
            !props.onHold && (
              <Grid item >
                <TextField
                  multiline
                  rows={15}
                  // error={isInvalidRequest.get()}
                  required={true}
                  name={'comment'}
                  id='comment'
                  label={translate('Comment')}
                  fullWidth
                  size='small'
                  // value={!comment ? '' : comment}
                  sx={{ mt: 1 }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => { hold.notes.set(event.target.value) }}
                />
              </Grid>
            )
          }
        </DialogContent>
        <DialogActions sx={{px: 3, gap: 1}} >
          <Button
            variant='outlined'
            onClick={handleClose}>
            {translate('Cancel')}
          </Button>
          <LoadingButton
            loading={loading.get()}
            variant='contained'
            onClick={action}>
            {props.onHold ? translate('Free') : translate('Submit')}
          </LoadingButton>
        </DialogActions>
      </Dialog>

    </Grid>);

}
