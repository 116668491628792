import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import Layout from '~/components/Layout';
import IContactCenter from '../interfaces/IContactCenter';

export default function Create() {
  const { register, handleSubmit, control, setValue: setFormValue } = useForm<IContactCenter>();
  const fields = [
    { name: 'name', type: 'string' },
  ];

  return (
    <Layout marginLayout={true}>
      <CrudCreate model={'contact-center'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Contact Center successfully created!'}
        title={'Add Contact Center'}
        subheader={'Add new Contact Center'}
        setFormValue={setFormValue} />
    </Layout>
  )
}
