import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import {
  Alert,
  AlertColor,
  Badge,
  Box,
  Divider,
  IconButton,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useNotificationCenter } from 'react-toastify/addons/use-notification-center'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { toast } from 'react-toastify'
import { useLang } from '~/hooks/useLang'
import useNotificationState from './stores'

export default function NotificationsCentral() {
  const { translate } = useLang()
  const dismissAll = () => toast.dismiss()
  const [showUnreadOnly] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { clear, unreadCount, notifications } = useNotificationCenter()
  const { handleClearNotification, clearNotifications} = useNotificationState()

  const numberOFSuccess = notifications.filter(
    (getType) => getType.type === 'info' || getType.type === 'warning'
  ).length

  const clearAllNotifications = () => {
    handleClearNotification()
  }

  useEffect(() => {
    if(clearNotifications().get() != null){
      dismissAll()
      clear()
    }
  }, [clearNotifications().get()])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const toggleNotificationCenter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Box>
      <IconButton
        title={translate('Notification central')}
        size="large"
        onClick={toggleNotificationCenter}
      >
        <Badge color="info" badgeContent={numberOFSuccess}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ zIndex: '99999' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              padding: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              overflow: 'hide',
            }}
          >
            <Typography variant="h6">
              {translate('Notification central')}
            </Typography>
          </Box>
          <Divider variant="middle" flexItem sx={{ margin: '0px' }} />
          <Stack
            sx={{
              height: '400px',
              width: '300px',
              padding: '12px',
              borderRadius: '8px',
              overflowY: 'auto',
            }}
            spacing={2}
          >
            {(!notifications.length ||
              (unreadCount === 0 && showUnreadOnly)) && (
              <Typography>
                {translate('List of notifications empty')}
              </Typography>
            )}
            {notifications.filter(
                (getType) =>
                  getType.type === 'info' || getType.type === 'warning'
              )
              .map((notification) => {
                return (
                  <Alert
                    key={notification.id}
                    severity={(notification.type as AlertColor) || 'info'}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <>{notification.content}</>
                  </Alert>
                )
              })}
          </Stack>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingX: 1,
            }}
          >
            <Button
              variant="contained"
              fullWidth
              size="small"
              onClick={() => clearAllNotifications()}
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '10px',
              }}
            >
              {translate('Clear all notifications')}
            </Button>
          </Box>
        </Box>
      </Menu>
    </Box>
  )
}
