import React, { useEffect } from 'react'
import { useHookstate, useState } from '@hookstate/core';
import ReactDOM from 'react-dom'
import StandardModal from '../../../../components/StandardModal'
import ReportingRecord from '../VideoMonitoringForm'
import { useLang } from '../../../../hooks/useLang'
import MonochromePhotosIcon from '@mui/icons-material/MonochromePhotos';
import { authFetch } from '~/services/fetch';
import { useLocation } from 'react-router';
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable';
import useSystemState from '~/features/System/stores/SystemState';
import { storeScreenSettings } from '~/features/System/services';

const modal = document.getElementById('div-modal') as HTMLElement

export type ILocationRoute = {
  readonly surveillanceReporting?: boolean
}

export default function VideoMonitoringModalDraggable() {
  const { translate } = useLang();
  const { windowManager, loadingButton } = useSystemState();
  const location = useLocation()

  const handleStop = (event, dragElement) => {
    event.stopPropagation();

    windowManager()['videoMonitoring'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y
    })
  };

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'VideoMonitoringModalDraggable',
        isOpen: windowManager()['videoMonitoring'].open.get(),
        isMinimized: windowManager()['videoMonitoring'].minimized.get(),
        positionX: windowManager()['videoMonitoring'].positionX.get(),
        positionY: windowManager()['videoMonitoring'].positionY.get()
      })
    }
  }, [
    windowManager()['videoMonitoring'].open.get(),
    windowManager()['videoMonitoring'].minimized.get(),
    windowManager()['videoMonitoring'].positionY.get(),
    windowManager()['videoMonitoring'].positionX.get()
  ])

  if (windowManager()['videoMonitoring'].open.get() && location.pathname === '/video-monitoring') {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() &&
          <StandardModal
            sx={{ zIndex: 1002, position: 'fixed', display: 'inline-block' }}
            title={translate('Videomonitoring')}
            avatar={MonochromePhotosIcon}
            dimensions={{ width: 800 }}
            handleStop={handleStop}
            position={{
              x: windowManager()['videoMonitoring'].positionX?.get() ? Number(windowManager()['videoMonitoring'].positionX?.get()) : 1000,
              y: windowManager()['videoMonitoring'].positionY?.get() ? Number(windowManager()['videoMonitoring'].positionY?.get()) : 300
            }}
            isExpanded={windowManager()['videoMonitoring'].minimized}
          >
            <ReportingRecord />
          </StandardModal>
        }
      </>
      , modal
    )
  } else {
    return <></>
  }
}
