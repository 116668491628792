/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-lines-per-function */
import { Downgraded, State, StateMethods, useHookstate } from '@hookstate/core'
import {
    CircularProgress,
  Grid,
  ListItemText,
} from '@mui/material'
import React from 'react'
import { useLang } from '~/hooks/useLang'
import { useTheme } from '~/hooks/useTheme';
import ReactQuill from 'react-quill'
import InternalContent from '~/components/Layout/components/InternalContent'

export interface ConfigProcedureListProps {
  readonly id: number
  readonly procedure: {
    readonly name: string,
    readonly code: string,
    readonly description: string,
  }
}

// eslint-disable-next-line max-lines-per-function
export default function ProcedureList({configProcedureList}:{configProcedureList: State<readonly ConfigProcedureListProps[]>}) {
  const { translate } = useLang()
  const { darkMode } = useTheme();

  return (
    <Grid container spacing={2} sx={{ overflowY:'scroll'}}>
    <Grid container padding={1}>
        <Grid item xs={12} >
            {configProcedureList.attach(Downgraded).get()?.length > 0 ? (
            configProcedureList.attach(Downgraded).get()?.length == 1 ? (
                configProcedureList?.attach(Downgraded).get().map((items) => {
                    return (
                        <>
                        <ListItemText
                        primary={`[${items.procedure?.code}]: ${items.procedure?.name}`}
                        />
                        <ReactQuill
                            value={items?.procedure?.description ? items?.procedure?.description : translate('Uninformed')}
                            readOnly={true}
                            style={{
                                height: '90%',
                                color: darkMode ? '#fffe' : '#000'
                            }} 
                            modules={{
                                toolbar: false
                            }}
                        />
                        </>
                )

                })
            ) : (
                configProcedureList?.attach(Downgraded).get().map((items) => {
                    return (
                    <>
                    <InternalContent title={`[${items.procedure?.code}]: ${items.procedure?.name}`} alreadyExpand={false} >
                        <ReactQuill
                            value={items?.procedure?.description ? items?.procedure?.description : translate('Uninformed')}
                            readOnly={true}
                            style={{
                                height: '90%',
                                color: darkMode ? '#fffe' : '#000'
                            }} 
                            modules={{
                                toolbar: false
                            }}
                        />
                    </InternalContent>
                    </>
                )

                })
            )
            ) : (
            <Grid
                xs={12}
                style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </Grid>
            )}
        </Grid>
    </Grid>
    </Grid>
  )
}