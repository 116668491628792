import * as React from 'react';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import { authFetch } from '~/services/fetch';
import { Downgraded, State, useHookstate } from '@hookstate/core';
import { FormControlLabel, Grid, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { InputSearchWithClear } from '~/components/InputSearchWithClear';

interface RenderTree {
  readonly id: string;
  readonly name: string;
  readonly type_id?: number | null;
  active?: boolean;
  readonly children?: readonly RenderTree[];
}

interface CrudCrudTreeFieldProps {
  readonly register?: UseFormRegister<any>;
  readonly model: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly options: {
    readonly firstListUrl: string,
    readonly secondListUrl: string,
    readonly nameChildren: string,
  };
  readonly placeholder?: string;
  readonly setFormValue: UseFormSetValue<any>;
}

export default function CrudTreeView({ register, model, getValues, options, placeholder, setFormValue }: CrudCrudTreeFieldProps) {
  const { translate } = useLang();
  const listData = useHookstate<RenderTree[]>([])
  const listDataChecked = useHookstate<RenderTree[]>([])
  const search = useHookstate('');
  const resultSearch = useHookstate<RenderTree[]>([])


  const formatNodeForm = () => {
    const result = listData.get()?.filter((node) => {
      if (node.active == true) {
        return node
      }
    }
    ).map((item) => {
      if (item[options.nameChildren].length > 0) {
        const childrenObj = item[options.nameChildren].filter((children) => children.active == true)
        return childrenObj.map((children) => {
          return {
            type_id: item.id,
            subtype_id: children.id,
          }
        })
      } else {
        return {
          type_id: item.id
        }
      }
    })

    return result.reduce((list, sub) => list.concat(sub), [])
  };

  const checkedBox = (firstUrl: State<RenderTree[]>, secondUrl: State<RenderTree[]>) => {
    if (secondUrl.get()?.length > 0) {
      firstUrl.value.map((item, index) => {
        secondUrl.get()?.find((checked, indexSecond) => {
          if (item.id === checked.id) {
            firstUrl[index].active.set(true)
            if (firstUrl[index][options.nameChildren]?.length && checked[options.nameChildren]?.length) {
              firstUrl[index][options.nameChildren]?.get().map((children, indexChildren) => {
                checked[options.nameChildren].find((childrenChecked) => {
                  children.id === childrenChecked.id ? firstUrl[index][options.nameChildren][indexChildren].active.set(true) : false
                })
              })
            }
          } else {
            return false
          }
        })
      })
    }
  }

  React.useEffect(() => {
    if (options?.firstListUrl != '') {
      authFetch({
        url: options?.firstListUrl,
        method: 'GET',
      }).then((response) => {
        listData.set(response.data)
      }).then(() => {
        if (options?.secondListUrl != '') {
          authFetch({
            url: options?.secondListUrl,
            method: 'GET'
          }).then((response) => {
            listDataChecked.set(response.data)
          }).then(() => {
            checkedBox(listData, listDataChecked)
          })
        }
      })
    }
  }, [])


  const renderTree = (nodes: State<RenderTree[]>, indexFather?) => {
    return nodes.value?.map((node, index) => (
      <TreeItem
        key={node.id}
        itemId={node.id}
        label={node.id !== 'root' ? (<FormControlLabel
          label={node.name}
          control={
            <Checkbox
              checked={node.active ? node.active : false}
              onChange={(_, checked) => {
                nodes[index]?.active.set(!nodes[index].active.get())
                indexFather && nodes[index].active.get() == true ? listData[indexFather].active.set(true) : false
                indexFather && nodes[indexFather].active.get() == true ? listData[index].active.set(true) : false
                listData[index]?.active.get() == false ? listData[index][options.nameChildren].map((children) => children.active.set(false)) : false
                
                setFormValue(model,
                  formatNodeForm()
                )
              }}
            />
          }
        />) : node.children?.length ? node.name : translate('No records')
        }
      >
        {renderTree(nodes[index][options.nameChildren], index)}
      </TreeItem >
    ))
  };

  React.useEffect(() => {
    if (search.get()) {
      const typeOrSubtype = listData.attach(Downgraded).get()?.filter(item => item.name?.toLocaleUpperCase().includes(search.get().toLocaleUpperCase()))
      if (typeOrSubtype?.length) {
        resultSearch.set(typeOrSubtype)
      }
    }
  }, [search])

  return (
    <Grid container marginTop={2} marginLeft={2}>
      <SimpleTreeView
        defaultExpandedItems={['root']}
        aria-label="rich object"
        // defaultCollapseIcon={<ExpandMoreIcon />}
        // defaultExpandIcon={<ChevronRightIcon />}
        multiSelect
        sx={{ height: 516, flexGrow: 1, maxWidth: 600, overflowY: 'auto' }}
      >
        {<InputSearchWithClear
          fullWidth
          sx={{ mb: 2 }}
          placeholder={translate('Search')}
          search={search}
          />}
        {renderTree(resultSearch.length ? resultSearch : listData)}
      </SimpleTreeView>
      <Grid item xs={4}>
        <TextField
          fullWidth
          disabled
          id="outlined-multiline-flexible"
          multiline
          value={listData.get()
            .filter((item) => item.active)
            .map((element) => `${translate('Type')}: ${element.name}\n${element[options.nameChildren]?.length > 0
              ? translate('subtype') + ': ' + element[options.nameChildren]?.map(children => children.active ? children.name + ', ' : null).join('')
              : translate('No Subtypes')}`).join('\n\n')}
          rows={21}
          />
      </Grid>
    </Grid>

  );
}