import * as React from "react";
import { Divider, Grid, Tooltip } from '@mui/material'
import dayjs from 'dayjs';
import { useLang } from "~/hooks/useLang";
import useUserState from "~/features/User/stores/UserState";
import { capitalize, truncateText } from "~/utils/strings";
import { ISurveillanceReportingReponse } from "~/features/VideoMonitoring/interfaces/ISurveillanceReporting";
import { dateTimeFormat, elapsedTime } from "~/utils/dateTime";
import { truncate } from "~/features/Dispatch/utils/stringsManipulations";


interface CardFooterProps {
  readonly report: ISurveillanceReportingReponse
}

export function CardFooter({ report: {
  cameraName,
  reportedAt,
  station,
  userName,
  occurrenceId,
} }: CardFooterProps) {
  const { translate } = useLang();

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
      }}
    >
      <Grid item sx={{ p: 1 }}>
        <span style={{ fontSize: '12px', color:'gray' }}>
          <b>
            {`${translate('Camera')}: ${cameraName ?? translate('Not defined')}`}
          </b>
        </span>
      </Grid>
      <Grid item sx={{ p: 1 }}>
      <Tooltip title={`${translate('Reported by')}: ${userName} ${translate('on station')} ${station}`} placement='top'>
        <span style={{ fontSize: '12px', color:'gray' }}>
          <b>
            {`${translate('Reported by')}: ${truncate(userName, 10)} ${translate('on station')} ${truncate(station, 20)}`}
          </b>
        </span>
      </Tooltip>  
      </Grid>
      <Grid item sx={{ p: 1 }}>
        <span style={{ fontSize: '12px', color:'gray' }}>
          <b>
            {`${translate('Occurrence')}: ${occurrenceId ?? translate('No associated occurrences')}`}
          </b>
        </span>
      </Grid>
      <Grid item sx={{ p: 1 }}>
        <Tooltip title={`Relatado em: ${dateTimeFormat(reportedAt ?? '')}`} placement='top'>
          <span style={{ fontSize: '12px', textAlign: 'right', width: 'auto', color:'gray' }}>
            {' • '}
            {elapsedTime(reportedAt ?? '')}
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
