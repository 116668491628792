import React, { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { useHistory } from 'react-router-dom';
import { MenuProps } from '~/components/SidebarMenu/interfaces';
import { useLang } from '~/hooks/useLang';
import { Collapse, List } from '@mui/material';
import userUserState from '~/features/User/stores/UserState';

import {
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';

export default function ReportByInterestPoint({ handleMenuClick }: MenuProps) {
  const { translate } = useLang();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { verifyPermission } = userUserState();

  const handleClickOpen = () => {
    setOpen((old) => !old);
  };

  return (
    <>
      <Tooltip title={translate('Generate report') + " " + translate('Interest point')} placement="right">
        <ListItemButton onClick={handleClickOpen}>
          <ListItemText primary={translate('Interest point')} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </Tooltip>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            verifyPermission(['reports_generator']) && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {verifyPermission(['report_interest_point']) && (

                  <Tooltip
                title={`${translate('Generate report')}` + ' ' + `${translate('Interest points registered')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/interest-points-registered");
                      }}
                    >
                  <ListItemText primary={translate('Interest points registered')} />
                    </ListItemButton>
                  </Tooltip>
                )}

              </>
            )
          }
        </List>
      </Collapse>
    </>
  )
}