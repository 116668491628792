/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import {
  Button,
  Card,
  ListItemButton,
  ListItemText,
  Typography,
  Box,
  Tooltip
} from '@mui/material'
// import IconOperation from '~/components/IconsSvg/operation';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import { useLang } from '~/hooks/useLang'
import { useHistory } from 'react-router-dom'
import useUserState from '~/features/User/stores/UserState'
import { MenuProps } from '../../interfaces';


export default function OperationMenu({ activeMenu, handleMenuClick }: MenuProps) {
  const { translate } = useLang();
  const { verifyPermission } = useUserState();

  const history = useHistory();

  return (
    <>
      {(
        verifyPermission(['operation_index', 'operation_dashboard'])
      ) && (
          <Box>
            <Tooltip title={translate('Operation')} placement="bottom">
              <Button
                className={`main-menu ${activeMenu == 'operation' ? 'active' : ''}`}
                onClick={() => handleMenuClick('operation')}
              >
                <SafetyCheckIcon />
              </Button>
            </Tooltip>
            <Card
              className="submenu-box"
              sx={{
                borderRadius: 0,
                display: activeMenu == 'operation' ? 'block' : 'none',
              }}
            >
              <Typography variant="h4">
                <SafetyCheckIcon />
                {translate('Operation')}
              </Typography>

              {
                verifyPermission(['operation_index']) && (
                  <Tooltip title={translate('Create new operation screen')} placement="right">
                    <ListItemButton onClick={() => {
                      handleMenuClick('operation')
                      history.push('/operation')
                    }}>
                      <ListItemText primary={translate('Operations')} />
                    </ListItemButton>
                  </Tooltip>
                )
              }

              {
                verifyPermission(['operation_dashboard']) && (
                  <Tooltip title={translate('View Operation')} placement="right">
                    <ListItemButton onClick={() => {
                      handleMenuClick('operation')
                      history.push('/operation/dashboard')
                    }}>
                      <ListItemText primary={translate('View Operations')} />
                    </ListItemButton>
                  </Tooltip>
                )
              }

            </Card>
          </Box>
        )}
    </>
  )
}
