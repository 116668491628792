import React from "react";
import { Card, CardContent, CardActions, Button, Typography } from '@mui/material';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { useLang } from '~/hooks/useLang';

export default function ErrorFallback({ error, resetErrorBoundary }) {
  const { translate } = useLang();
  console.error(error);

  return (
    <Card>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
        <BrokenImageIcon fontSize="large" />
        <Typography gutterBottom variant="h5" component="div">
          {translate('Something went wrong')}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Error:
          {error.message}
        </Typography>
        <CardActions>
          <Button size="small" onClick={resetErrorBoundary}>
            {translate('Try again')}
          </Button>
        </CardActions>
      </CardContent>
    </Card>

  )
}