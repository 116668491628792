/* eslint-disable max-lines-per-function */
import React from 'react'
import { State } from '@hookstate/core'
import {
  Button,
  Collapse,
  Grid,
} from '@mui/material'
import { useLang } from '~/hooks/useLang'
import FilterAutocompleteAgency from '~/components/FilterAutocompleteAgency'
import { Dayjs } from 'dayjs'
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups'
import FilterAutocompleteDevice from '~/components/FilterAutocompleteDevice'
import { LoadingButton } from '@mui/lab'
import FilterAutocompleteDispatchGroupSet from '~/components/FilterAutocompleteDispatchGroupSet'
import FilterAutocompleteOperationUnit from '~/components/FilterAutocompleteOperationUnit'

interface DispatchFiltersDashboardProps {
  readonly loading: State<boolean>
  readonly deviceId: State<number | null>
  readonly groupId: State<readonly number[]>
  readonly agencyId: State<number | null>
  readonly hidden?: boolean
  readonly lockFields: boolean

  readonly searchButton: () => void
  readonly clearFilters: () => void

  readonly dispatchGroupSetId: State<number | null>
  readonly operationUnitId: State<number | null>
}

export default function DeviceFiltersDashboard({
  loading,
  lockFields,
  deviceId,
  groupId,
  agencyId,
  hidden,
  clearFilters,
  searchButton,
  dispatchGroupSetId,
  operationUnitId,
}: DispatchFiltersDashboardProps) {
  const { translate } = useLang()

  return (
    <Collapse in={hidden}>
      <Grid
        container
        xs={12}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          paddingX: 2,
          paddingBottom: 2,
        }}
      >
        <Grid container direction={'row'}>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteAgency
              disabled={lockFields}
              agencyId={agencyId.get()}
              onAgencyChange={(agency) => {
                agencyId.set(agency)
                groupId.set([])
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteDispatchGroupSet
              disabled={lockFields}
              agencyId={agencyId.get() ?? undefined}
              dispatchGroupSetId={dispatchGroupSetId.get() ?? undefined}
              onGroupDispatchSetChange={(group) => {
                dispatchGroupSetId.set(group)
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteDispatchsGroups
              disabled={lockFields}
              agencyId={agencyId.get() ?? undefined}
              dispatcheGroups={groupId.get() ?? undefined}
              onGroupDispatchChange={(group) => {
                groupId.set(group)
                deviceId.set(null)
              }}
              groupSetId={dispatchGroupSetId?.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteOperationUnit
              disabled={lockFields}
              onOperationUnitChange={(operationUnit) => {
                operationUnitId.set(operationUnit)
                deviceId.set(null)
              }}
              operationUnitId={operationUnitId.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteDevice
              disabled={lockFields}
              onDeviceChange={(device) => {
                deviceId.set(device)
              }}
              deviceId={deviceId.get()}
              operationUnitId={operationUnitId.get()}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            pt: 2,
            gap: 2,
          }}
        >
          <Button variant="outlined" onClick={clearFilters}>
            {translate('Clear Filters')}
          </Button>
          <LoadingButton
            loading={loading.get()}
            variant="contained"
            onClick={searchButton}
          >
            {translate('Search')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Collapse>
  )
}