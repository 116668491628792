import React, { useEffect, useState } from 'react';
import { Method } from "axios";
import { Controller, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { useLang } from "~/hooks/useLang";
import { authFetch } from '~/services/fetch';
import { Autocomplete, FormControl, Grid, MenuItem, TextField } from '@mui/material';
import { useHookstate } from '@hookstate/core';

type CrudAutoCompleteFieldProps = {
  readonly register: any;
  readonly name: string;
  readonly model: any;
  readonly control: any;
  readonly placeholder?: string;
  readonly optionSelect?: any;
  readonly method?: Method;
  readonly getValues?: UseFormGetValues<any>;
  readonly setFormValue: UseFormSetValue<any>;
}

export default function CrudSelectAutoCompleteGrouped(props: CrudAutoCompleteFieldProps) {
  const { translate } = useLang();
  const { register, name, model, control, placeholder = '', optionSelect, method = 'POST', getValues, setFormValue } = props;
  const items = useHookstate([]);
  const currentValue = useHookstate<any>(undefined)
  const currentGrouped = useHookstate<any>(getValues ? getValues(name) : 0)


  useEffect(() => {
    const fetchData = async () => {
      const targetUrl = `/${model}/`;
      await authFetch({
        url: targetUrl,
        method,
        data: {
          limit: 100000,
          filters: [],
          orders: [{ field: 'name', sort: 'asc' }]
        }
      }).then((response) => {
        const data = method === 'POST' ? response.data.data : response.data

        if (items.length <= 0 && data.length > 0) {
          items.set(data);
        }

      });
    }

    fetchData()
      .catch(console.error);
  }, [])

  const options = items.get().map((option: any) => {
    return {
      coordinator: option.id === currentGrouped.get() ? `${translate('Sugestion')}` : `${translate('Others coordinators')}`,
      ...option,
    }
  })

  return (
    <Grid item xs={4}>
      <FormControl fullWidth size='small'>
        <Autocomplete
          options={options.sort((a, b) => b.coordinator?.localeCompare(a.coordinator))}
          groupBy={(option) => option.coordinator}
          noOptionsText={translate('No options')}
          getOptionLabel={(option) => option.representation_name}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          clearText={translate('Clear')}
          onChange={(event, data) => { currentValue.set(data?.id), setFormValue(name, data?.id) }}
          value={currentValue.get()}
          renderInput={(params) =>
            <TextField
              {...params}
              label={translate(placeholder)}
            />
          }
          renderOption={(props, option) => (
            <MenuItem {...props} sx={{ mt: 1, overflowX: "auto" }} key={option.id} value={option.id}>
              {`${option.representation_name}`}
            </MenuItem>
          )}
        />
      </FormControl>
    </Grid>
  )
}