import React from 'react';
import { IconButton} from '@mui/material';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { useLang } from '~/hooks/useLang';
import { LatLng } from 'leaflet';
import { useMarkerState } from '~/features/Map/stores/MapState';

interface DispatchTravelInforProps {
  readonly title: string
  readonly latitude: number | null;
  readonly longitude: number | null;
}

export default function DispatchTravelInfo({title, latitude, longitude }: DispatchTravelInforProps) {
  const { forceViewMap } = useMarkerState();
  const { translate } = useLang();

  return (
    <IconButton
      disabled={!Boolean(latitude && longitude)}
      title={translate(title)}
      sx={{ margin: '0 !important' }}
      onClick={() => forceViewMap().position.set(new LatLng(Number(latitude ? latitude : 0), Number(longitude ? longitude : '')))}
    >
      {<TravelExploreIcon />}
    </IconButton>
  )
}