import React from 'react';
import { TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { State } from '@hookstate/core';
import { IErrorConectDispatchVehicle, IUserCrew } from '../..';

interface IRegistrarionFieldSearchMember {
  readonly stateName: string;
  readonly memberState: State<IUserCrew>;
  readonly error: State<IErrorConectDispatchVehicle>;
  readonly onKeyDown?: (event: unknown) => void;
}

export default function RegistrarionFieldSearchMember({ onKeyDown, stateName, memberState, error }: IRegistrarionFieldSearchMember) {
  const { translate } = useLang();
  return (
    <TextField fullWidth
      label={translate('Registration')}
      name="Registration"
      size='small'
      onKeyDown={onKeyDown}
      value={memberState.registration.get()}
      error={!!error[stateName].cpf.get() && !memberState.cpf.get()}
      onChange={(event) => {
        error.returnError.set('')
        error[stateName].cpf.set('')
        memberState.set({ ...memberState.get(), cpf: '', representation_name: '' })
        memberState.registration.set(event.target.value)
      }}
      required
    />
  )
}