import { AxiosError } from 'axios';
import { authFetch } from '~/services/fetch';
import { IParamsTable, IUpdateProps } from '../../../interfaces';
import ISubtype from '../interfaces/ISubtype';

export async function getSubtypeById(id: string | number | undefined | null) {

    const response = await authFetch({
        url: `/subtype/${id}`,
        method: 'GET'
    });
    return response.data;

}


export async function getSubTypes({
    filters = [],
    limit = 100000,
    orders = [
        { field: 'subtypes.name', sort: 'asc' }
    ]
}: IParamsTable = {}) {
    const response = await authFetch({
        url: `/subtype/`,
        method: 'POST',
        data: {
            limit,
            filters,
            orders
        },

    });
    return response.data.data;

}

export async function getAllSubTypes() {
    const response = await authFetch({
        url: `/subtype/`,
        method: 'GET',
    });
    return response.data;
}

export async function getAllSubTypesByType(id: number): Promise<readonly ISubtype[]> {
    const response = await authFetch({
        url: `/type/${id}/subtypes`,
        method: 'GET',
    });
    return response.data;
}