import { IObjectDTO, IRawObject } from "~/features/Dispatch/interfaces/IDispacthRegistrationObject"

export function toDomain(
  object: IRawObject): IObjectDTO {
  return {
    id: object.id,
    occurrenceId: object.occurrence_id,
    objectType: object.object_type,
    quantity: object.quantity,
    unitMeasurement: object.unit_measurement,
    notes: object.notes,
    circunstance: object.circunstance,
    destination: object.destination,
  }
}

export function toFetch(object: IObjectDTO) {
  return {
    object_type_id: object.objectType?.id,
    quantity: object.quantity,
    occurrence_id: object.occurrenceId,
    notes: object.notes,
    circunstance_id: object.circunstance?.id,
    destination_id: object.destination?.id,
    unit_measurement_id: object.unitMeasurement?.id,
  }
}
