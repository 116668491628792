import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';


export default function Create() {
  const { translate } = useLang();
  const { register, handleSubmit, control, setValue: setFormValue } = useForm<any>();

  const fields = [
    { name: 'name', type: 'string' },
    {
      name: 'type_subtypes', 
      type: 'checkTreeView', 
      options: {
        firstListUrl: '/type/with-subtype-info',
        secondListUrl: '',
        nameChildren: 'subtypes'
      }, 
      model: 'category', 
      placeholder: 'category',  },
  ];

  return (
    <Layout marginLayout={true}>
      <CrudCreate model={'Category'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Category successfully created!'}
        title={'Add category'}
        subheader={'Add a new category'}
        setFormValue={setFormValue} />
    </Layout>
  );
}
