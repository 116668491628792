import React, { useEffect, SyntheticEvent, useRef } from "react";
import { t as translate } from 'i18next';
import { LatLng } from "leaflet";
import { Downgraded, State, useHookstate, useState, createState } from '@hookstate/core';
import { getAutoCompleteOptions, getCityIdByName, getDistrictIdByName, getPlaceDetails, LocationPrediction } from '~/utils/location';
import useMapState, { useMarkerState } from '~/features/Map/stores/MapState';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import { Validation as validation } from '@hookstate/validation';
import { FetchAutoComplete, SetFuncOpts } from "../FetchAutoComplete";
import { debounce } from "lodash";
import { LocationOn } from "@mui/icons-material";
import { Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';

export type Type = 'dispatch' | 'entry';

export type LocationState = {
  readonly id: State<number | string | null>,
  readonly latitude: State<number | null>,
  readonly longitude: State<number | null>,
  readonly cityId: State<number | string | null>,
  readonly districtId?: State<number | string | null>,
  readonly locationValue: State<string | null>,
  readonly type: Type
};

interface LocalityTextFieldProps {
  readonly readonly?: boolean,
  readonly locationState: LocationState,
  readonly isInvalidRequest?: boolean,
  readonly isValid: boolean
}

interface IAddress {
  readonly city?: string
  readonly secondary?: string
  readonly town?: string
  readonly latitude: number
  readonly longitude: number
  readonly district?: string
}



export function LocalityTextField({ readonly=false, locationState, isInvalidRequest, isValid }: LocalityTextFieldProps) {
  const { id, latitude, longitude, cityId, locationValue, type, districtId } = locationState;
  const { boundCenterActual, createMarker, forceViewMap } = useMarkerState();

  const showError = useHookstate(true);
  const { translate } = useLang();


  const setDistrictState = (address: IAddress) => {
    if (!address) return
    const district = address.district;

    if (district) getDistrictIdByName(district).then((id) => {
      districtId?.set(id)
    });
  };

  const setCityStates = (address: IAddress) => {

    if (!address) return

    const city = address.city || address.town || null;

    if (city) getCityIdByName(city).then((id) => cityId.set(id));
  };

  const getAutoCompleteFunction = (newInput: string) => {
    const lat: number | null = boundCenterActual().lat.get() ? boundCenterActual().lat.get() : null;
    const lng: number | null = boundCenterActual().lng.get() ? boundCenterActual().lng.get() : null;

    return getAutoCompleteOptions(newInput,
      { latLng: (lat && lng) ? new LatLng(lat, lng) : undefined }
    );

  };


  useEffect(() => {
    validateLocation();

  }, [isInvalidRequest, locationValue]);


  const setLocationFunc = (option: LocationPrediction): Promise<{ readonly ended: boolean, readonly value: string }> => {
    return new Promise((resolve, reject) => {
      const idOrNull = id.get();

      if (option?.placeId && idOrNull) {
        getPlaceDetails(option?.placeId)
          .then((locationDetail) => {
            if (type == 'entry') {
              if (locationDetail.latitude && locationDetail.longitude) {
                try {

                  const params = {
                    id: Number(idOrNull),
                    isCurrent: true,
                    position: new LatLng(locationDetail.latitude, locationDetail.longitude),
                    location: option?.description ? option?.description : undefined,
                    status: 1,
                  }

                  createMarker({ markerType: 'entry', markerIdentifier: params.id, isCurrent: true, ...params as any });
                  forceViewMap().position.set(params.position);
                } catch (error) {
                  console.error(error)
                }
              }
            }

            latitude.set(locationDetail.latitude ? locationDetail.latitude : null);
            longitude.set(locationDetail.longitude ? locationDetail.longitude : null);

            setCityStates(locationDetail);
            setDistrictState(locationDetail);
            resolve({ ended: true, value: option?.description ? option?.description : option.main });
          })
          .catch((err) => { reject(err) });
      }
    });
  };

  const validateLocation = () => {
    if (locationValue.get()?.length !== 0) {
      showError.set(false);
    } else {
      showError.set(true)
    }
  }


  if (!readonly) {
    return (
        <FetchAutoComplete<LocationPrediction>
          fetchFunc={getAutoCompleteFunction}
          setFunc={setLocationFunc}
          noOptionsString={'No Locations, yet...'}
          optionKey={'main'}
          stateValue={locationValue}
          startIcon
          startIconElement={<LocationOn fontSize="small" />}
          autoCompleteProps={{
            getOptionLabel: option => {
              return option?.description ? option?.description : option.main;
            },
            placeholder: 'Location',
            size: 'small',
            errorMsg: false,
            filterOptions: (original) => original,
          }}
          isValid={isValid}
          error={isInvalidRequest && showError.get()}
        />
      )
    } else {
      return (
        <Typography>{translate('Location')}: {locationValue.get() ?? translate('No information')}</Typography>
      )
    }
}