/* eslint-disable max-lines-per-function */
import React from 'react'
import {
  createState,
  useState,
} from '@hookstate/core'
import { authFetch } from '~/services/fetch'
import { toDomain, toFetch } from '~/mappers/registration-organ-mapper'
import { IDispacthRegistrationOrgans, IOrganDTO } from '../interfaces/IDispacthRegistrationOrgan'
export const organDefaultState: IOrganDTO = {
  occurrenceId: null,
  auxiliaryOrgans: null,
  actionTaken: null,
  name: null,
  cpf: null,
  registration: null,
  notes: null,
}
const defaultRegistrationOrganProps: IDispacthRegistrationOrgans = {
  organs: [],
  organ: organDefaultState
}

const registrationDispatchOrganState = createState<IDispacthRegistrationOrgans>(defaultRegistrationOrganProps);

const useDispatchRegistrationOrganState = () => {
  const registrationOrganDispatch = useState(registrationDispatchOrganState);

  return {
    registrationOrganDispatch: () => registrationOrganDispatch,
    getAllAssociatedOrgans: async (occurrenceId: string | number | null) => {
      const targetUrl = '/occurrence/associated-organization/all-from-occurrence'
      const response = await authFetch({
        url: targetUrl,
        data: {
          occurrence_id: occurrenceId,
        }
      });

      const responseOrgans = response
        .data
        .map((organs): IOrganDTO => {
          return toDomain(organs)
        })

      registrationOrganDispatch
        .organs
        .set(responseOrgans);

      return responseOrgans;
    },
    getActionTaken: async () => {
      const targetUrl = '/action-taken'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;
    },
    getAuxiliaryOrgans: async () => {
      const targetUrl = '/organization'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;

    },
    sendOrgans: async (organs: IOrganDTO) => {
      const targetUrl = '/occurrence/associated-organization/store'
      return await authFetch({
        url: targetUrl,
        data: toFetch(organs)
      });
    },
    editOrgans: async ({ id, ...organs }: IOrganDTO) => {
      const targetUrl = `/occurrence/associated-organization/${id}`
      await authFetch({
        url: targetUrl,
        method: 'PUT',
        data: toFetch(organs)
      });
    },
    deleteOrgans: async (id: string) => {
      const targetUrl = `/occurrence/associated-organization/${id}`
      return await authFetch({
        url: targetUrl,
        method: 'DELETE',
      });
    },
  }
}

export default useDispatchRegistrationOrganState;