import React from "react";

export function parseAccessTokenInUrl(url, accessToken) {
  return url.replace('{accessToken}', accessToken);
}

export function parseHostname(url: string) {
  const parsedUrl = new URL(url);
  const subdomain = parsedUrl.hostname.split('.')[0];

  return subdomain === "www" ?
      `${parsedUrl.protocol}//${parsedUrl.hostname}` :
      `${parsedUrl.protocol}//${parsedUrl.hostname.replace(subdomain + '.', '')}`;
}

export function levelZoomMeters(zoom){
  const zoomMeters = {
    0: 156543,
    1: 78272,
    2: 39136,
    3: 19568,
    4: 9784,
    5: 4892,
    6: 2446,
    7: 1223,
    8: 611.496,
    9: 305.748,
    10: 152.874,
    11: 76.437,
    12: 38.219,
    13: 19.109,
    14: 9.555,
    15: 4.777,
    16: 2.389,
    17: 1.194,
    18: 0.597,
    19: 0.299,
    20: 0.149,
  }

  return zoomMeters[zoom]
}