import * as React from "react";
import { Grid, Tooltip } from '@mui/material'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import { State } from "@hookstate/core";
import { CommentDataProps } from "~/features/Dispatch/stores/DispatchCommentState";
import { useLang } from "~/hooks/useLang";
import useUserState from "~/features/User/stores/UserState";
import { truncateText } from "~/utils/strings";

interface ICardFooter {
  readonly commentData: State<CommentDataProps>
}

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    future: "em %s",
    past: "%s atrás",
    s: 'alguns segundos',
    m: "um minuto",
    mm: "%d minutos",
    h: "uma hora",
    hh: "%d horas",
    d: "um dia",
    dd: "%d dias",
    M: "um mês",
    MM: "%d meses",
    y: "um ano",
    yy: "%d anos"
  }
})



export function CardFooter({ commentData }: ICardFooter) {
  const { translate } = useLang();
  const { user: userSession } = useUserState();


  const sameUserColor = React.useCallback(() => {
    if (userSession().name.get() === commentData.user.name.get())
      return 'orange'
    else
      return 'default';
  }, [commentData.user.name.get(), userSession])

  return (
    <Grid
      direction="row"
      container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingLeft: 1,
        paddingRight: 1,
        marginTop: '5px',
      }}
    >
      <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Tooltip title={commentData.user.representation_name.get()} >
          <span style={{ fontSize: '12px', color: sameUserColor() }}>
            <b>
              {`${truncateText(commentData.user.get() ?  commentData.user.representation_name.get() : '', 20)}`}
            </b>
          </span>
        </Tooltip>
        <Tooltip title={
          !!commentData.isDeleted.get() ?
            dayjs(commentData.updatedAt.get() ?? commentData.deletedAt.get()).format('DD/MM/YYYY HH:mm:ss') :
            dayjs(commentData.updatedAt.get() ?? commentData.createdAt.get()).format('DD/MM/YYYY HH:mm:ss')
        }>
        <span style={{ fontSize: '12px', textAlign: 'right', width: 'auto' }}>
          {' • '}
          {!!commentData.isDeleted.get() ?
            dayjs(commentData.updatedAt.get() ?? commentData.deletedAt.get()).fromNow() :
            dayjs(commentData.updatedAt.get() ?? commentData.createdAt.get()).fromNow()
          }
        </span>
          </Tooltip>
      </Grid>
      <Grid item>
        {
          !!commentData.isEdited.get() &&
          !!commentData.isDeleted.get() === false && (
            <Tooltip title={`${translate('by')} ${commentData.changedByUser.get()?.representation_name}`} >
              <span style={{ fontSize: '12px' }}>
                {' • '}
                {translate('edited')}
              </span>
            </Tooltip>
          )
        }
        {
          !!commentData.isDeleted.get() && (
            <Tooltip title={`${translate('by')} ${commentData.changedByUser.get()?.representation_name}`} >
              <span style={{ fontSize: '12px' }}>
                {' • '}
                {translate('deleted')}
              </span>
            </Tooltip>
          )
        }

      </Grid>
      {/* {!!commentData.isEdited.get() && <span style={{ fontSize: '12px', color: sameUserColor() }}>
        {userSession().name.get() === commentData.user.name.get() ? `${translate('edited by')} ${translate('me')}` : `${translate('edited by')} ${commentData.changedByUser.get()?.name.split(' ')[0].toLocaleLowerCase()}`}
      </span>} */}
    </Grid >
  );
}
