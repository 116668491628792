/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { getCities, getCityById } from "~/features/City/services";
import { CityResponse } from "~/components/FilterSelectAutocompleteCity/interfaces";

interface CityProps {
    readonly onCitiesChange: (value: number[]) => void;
    readonly citiesId?: readonly number[];
    readonly disabled?: boolean
    readonly required?: boolean
    readonly error?: boolean
}

export default function FilterAutocompleteCities({ onCitiesChange, citiesId, required = false, error = false }: CityProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly CityResponse[]>([]);
    const [value, setValue] = useState<CityResponse[] | undefined>([]);
    const loading = useHookstate(false);

    useEffect(() => {
        getCities().then(cities => {
            setOptions(cities)
        })
    }, [])

    useEffect(() => {
        if (!citiesId?.length) {
            setValue([])
        }
    }, [citiesId?.length])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                filterSelectedOptions
                disableCloseOnSelect
                limitTags={2}
                sx={{ height: 1 }}
                ListboxProps={{ style: { maxHeight: '15rem' } }}
                id='filter-cities'
                multiple
                options={options}
                value={value}
                noOptionsText={translate('No options')}
                clearText={translate('Clear')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={(_, city) => {
                    setValue(city)
                    onCitiesChange(city.map(city => Number(city.id)) ?? [])
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Cities')}
                        required={required}
                        error={error}
                    />
                )}
            />
        </FormControl>
    )
}