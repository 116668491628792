import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IUser from '~/features/User/interfaces/IUser';
import Layout from '~/components/Layout';

export default function Create() {
  const { register, handleSubmit, control, setValue: setFormValue } = useForm<IUser>();

  const fields = [
    { name: 'login', type: 'string' , placeholder: 'User Login'},
    { name: 'password', type: 'password' },
    { name: 'repassword', type: 'password' },
    { name: 'name', type: 'string' },
    { name: 'email', type: 'string' },
    { name: 'cpf', type: 'string', mask: '999-999-999-99'},
    { name: 'registration', type: 'string'},
    { name: 'birth_date', type: 'date'},
    { name: 'phone', type: 'string', mask: '(99) 99999-9999' },
    { name: 'blood_type', type: 'select', options: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-']},
    { name: 'emergency_contact_name', type: 'string'},
    { name: 'emergency_contact_phone', type: 'string', mask: '(99) 99999-9999' },
    { name: 'agency_id', type: 'selectAutoComplete', model: 'agency', placeholder: 'Agency:name' },
    { name: 'rank_id', type: 'selectAutoComplete', model: 'rank', placeholder: 'Patent' },
    { name: 'nickname', type: 'string', placeholder: 'war_name' },
    { name: 'group', type: 'transferList', options: {firstListUrl: '', secondListUrl: '/group'}, placeholder: 'Permission Group' },
    { name: 'dispatch_groups', type: 'transferList', options: { firstListUrl: '', secondListUrl: '/dispatch/group' }, placeholder: 'Dispatch Group' }
  ];

  return (
    <Layout marginLayout={true}>
      <CrudCreate
        model={'user'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'User successfully created!'}
        title={'Add User'}
        subheader={'Add new user'}
        setFormValue={setFormValue} />
    </Layout>
  )
}
