import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core';
import { getEntryTimeByStation } from '../../services';
import ColumnChart from '~/components/ColumnChart';
import { IEntryStatisticGraphProps } from '../../interfaces/IEntryStatistic';

export default function EntryTimeByStationColumnChart({ agencyId, deviceId, typeId, subtypeId, startDate, endDate, exludeClosed = false, showTitle = false, refresh, loading, cityId }: IEntryStatisticGraphProps) {
  const data = useHookstate([]);

  useEffect(() => {
    loading.set(true)
    getEntryTimeByStation({ agencyId, deviceId, typeId, subtypeId, startDate, endDate, exludeClosed, cityId }).then((response) => {
      const result = response.map((data) => ({ item: data.name, value: data.averageTime }));
      return data.set(result);
    }).finally(() => {
      loading.set(false)
    })
  }, [refresh]);

  return (
    <ColumnChart data={data.attach(Downgraded).get()} title={`${showTitle ? 'Entry Time Station' : ''}`} />
  )
}