import React, { useEffect } from 'react';
import CrudIndex from '~/components/CrudIndex';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';

export default function Index() {   
  const { translate } = useLang();

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])     
    return(
      <Layout marginLayout={true}>
        <CrudIndex 
          model='dispatch/closed-status' 
          title='Dispatch Termination Code' 
          booleanField={['available_for_mobile', 'required_qualification', 'can_reopen_dispatch', 'is_solved_by_third_party']}
        />
      </Layout>
    )
}