import { useHookstate } from '@hookstate/core';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getAllTypesInterestPoint } from '~/features/InterestPointType/services';
import { useLang } from '~/hooks/useLang';

interface FilterAutocompleteTypeInterestPointProps {
    readonly onTypeInterestPointChange: (value: any | null) => void;
    readonly typeInterestPointId?: number | null;
    readonly disabled?: boolean
    readonly error?: boolean;
}


export default function FilterAutocompleteTypeInterestPoint({onTypeInterestPointChange, disabled, error, typeInterestPointId }: FilterAutocompleteTypeInterestPointProps) {
    const [options, setOptions] = useState<readonly any[]>([]);
    const [value, setValue] = useState<any | null>(null);
    const { translate } = useLang();
    const loading = useHookstate(false);


    useEffect(() => {
        loading.set(true)
        getAllTypesInterestPoint().then((typesInterestPoint) => {
            setOptions(typesInterestPoint)
        }).finally(() => {
            loading.set(false)
        })
    }, [])

    useEffect(() => {
        if (!typeInterestPointId) {
            setValue(null);
        } else {
            const option = options.find((option) => option.id == typeInterestPointId);
            setValue(option ?? null);
        }

    }, [options, typeInterestPointId])

    return (
        <FormControl fullWidth size='small'>
            <Autocomplete
                id='filter-types'
                disabled={disabled}
                options={options}
                value={value}
                noOptionsText={translate('No options')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => (option.displayName ?? option.name) ?? ''}
                onChange={(_, type) => {
                    setValue(type ?? null);
                    onTypeInterestPointChange(type ?? null);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={error ? error : false}
                        label={translate('Interest point type')}
                    />
                )}
            />
        </FormControl>
    )
}