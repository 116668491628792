import React from 'react';
import { Button, Collapse, Grid, Card, List, ListItemButton, ListItemText, Box, Typography, Tooltip } from '@mui/material';
import IconAdministration from '~/components/IconsSvg/administration';
import { useLang } from '~/hooks/useLang';
import {
  ExpandLess,
  ExpandMore,
  StarBorder,
} from '@mui/icons-material';

import { useHistory } from 'react-router-dom';
import useUserState from '~/features/User/stores/UserState';
import { MenuProps } from '~/components/SidebarMenu/interfaces';

export default function DrugItem({ handleMenuClick }: MenuProps) {
  const { translate } = useLang();
  const { verifyPermission } = useUserState();
  const [openDrugItem, setOpenDrugItem] = React.useState(false);

  const history = useHistory();

  const handleClickDrugItem = () => {
    setOpenDrugItem(!openDrugItem);
  }



  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {verifyPermission([
        'drug_type_index'
      ]) && (
          <>
            <Tooltip title={translate('Drugs options')} placement="right">
              <ListItemButton sx={{ pl: 4 }} onClick={handleClickDrugItem}>
                <ListItemText primary={translate('Drugs')} />
                {openDrugItem ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </Tooltip>
            <Collapse in={openDrugItem} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>

                {verifyPermission(['drug_type_index']) && (
                  <Tooltip title={`${translate('Create, read, update and delete')}` + ' ' + `${translate('Drug Type')}`} placement="right">
                    <ListItemButton sx={{ pl: 8 }} onClick={() => {
                      handleMenuClick('administration')
                      history.push("/drug-type")
                    }}>
                      <ListItemText primary={translate('Drug Type')} />
                    </ListItemButton>
                  </Tooltip>
                )}

              </List>
            </Collapse>
          </>
        )}
    </>
  );
}
