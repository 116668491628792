import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IType from '../interfaces/ICamera';


export default function Edit() {
  const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm<IType>();
  const fields = [
    { name: 'id', type: 'hidden' },
    { name: 'display_name', type: 'string' },
    { name: 'latitude', type: 'string' },
    { name: 'longitude', type: 'string' },
  ];

  return (
    <CrudEdit
      model={'camera'}
      fields={fields}
      register={register}
      handleSubmit={handleSubmit}
      control={control}
      setFormValue={setFormValue}
      messageSuccess={'Camera successfully updated!'}
      title={'Edit camera'}
      getValues={getValues}
    />
  )
}
