/* eslint-disable max-lines-per-function */
import React from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { random } from 'lodash';

export interface Totals {
  readonly name: string;
  readonly total_occurrences: number;
  readonly total_adults_arrested: number;
  readonly total_minors_seized: number;
  readonly total_weapons_seized: number;
  readonly total_ammunition_seized: number;
  readonly total_drugs_seized: number;
  readonly total_vehicles_seized: number;
  readonly total_vehicles_recovered: number;
}

export interface Data {
  readonly name: string;
  readonly total_occurrences: number;
  readonly total_adults_arrested: number;
  readonly total_minors_seized: number;
  readonly total_weapons_seized: number;
  readonly total_ammunition_seized: number;
  readonly total_drugs_seized: number;
  readonly total_vehicles_seized: number;
  readonly total_vehicles_recovered: number;
}

interface IndicatorsContentSummaryProps {
  readonly groupedBy: string
  readonly indicator?: {
    readonly totals: Totals;
    readonly data: readonly Data[]
  };
}

export default function IndicatorsContentSummary({ indicator, groupedBy }: IndicatorsContentSummaryProps) {
  const { translate } = useLang();

  return (
    <Container fixed={false}>
      <Grid container xs={12} sx={{ p: 1, border: 1 }}>
        <Grid item xs={4} >
          <Typography variant='subtitle1'>
            <b>
              {translate(groupedBy)}
            </b>
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1'>
            <b>
              OCS
            </b>
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1'>
            <b>
              ADA
            </b>
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1'>
            <b>
              MNA
            </b>
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1'>
            <b>
              AMA
            </b>
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1'>
            <b>
              MUA
            </b>
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1'>
            <b>
              DGA
            </b>
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1'>
            <b>
              VEA
            </b>
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1'>
            <b>
              VER
            </b>
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs={12} sx={{ display: 'flex' }}>
        {indicator?.data.map(row => (
          <>
            <Grid item xs={4} >
              <Typography variant='subtitle1' sx={{ pl: 1, borderLeft: 1, borderBottom: 1 }}>
                {row.name}
              </Typography>
            </Grid>
            <Grid item xs={1} >
              <Typography variant='subtitle1' sx={{ borderBottom: 1 }}>
                {row.total_occurrences}
              </Typography>
            </Grid>
            <Grid item xs={1} >
              <Typography variant='subtitle1' sx={{ borderBottom: 1 }}>
                {row.total_adults_arrested}
              </Typography>
            </Grid>
            <Grid item xs={1} >
              <Typography variant='subtitle1' sx={{ borderBottom: 1 }}>
                {row.total_minors_seized}
              </Typography>
            </Grid>
            <Grid item xs={1} >
              <Typography variant='subtitle1' sx={{ borderBottom: 1 }}>
                {row.total_weapons_seized}
              </Typography>
            </Grid>
            <Grid item xs={1} >
              <Typography variant='subtitle1' sx={{ borderBottom: 1 }}>
                {row.total_ammunition_seized}
              </Typography>
            </Grid>
            <Grid item xs={1} >
              <Typography variant='subtitle1' sx={{ borderBottom: 1 }}>
                {row.total_drugs_seized}
              </Typography>
            </Grid>
            <Grid item xs={1} >
              <Typography variant='subtitle1' sx={{ borderBottom: 1 }}>
                {row.total_vehicles_seized}
              </Typography>
            </Grid>
            <Grid item xs={1} >
              <Typography variant='subtitle1' sx={{ borderRight: 1, borderBottom: 1 }}>
                {row.total_vehicles_recovered}
              </Typography>
            </Grid>
          </>

        ))}
        <Grid item xs={4} >
          <Typography variant='subtitle1' sx={{ pl: 1, borderLeft: 1, borderBottom: 1 }}>
            {indicator?.totals.name}
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1' sx={{ borderBottom: 1 }}>
            {indicator?.totals.total_occurrences}
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1' sx={{ borderBottom: 1 }}>
            {indicator?.totals.total_adults_arrested}
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1' sx={{ borderBottom: 1 }}>
            {indicator?.totals.total_minors_seized}
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1' sx={{ borderBottom: 1 }}>
            {indicator?.totals.total_weapons_seized}
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1' sx={{ borderBottom: 1 }}>
            {indicator?.totals.total_ammunition_seized}
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1' sx={{ borderBottom: 1 }}>
            {indicator?.totals.total_drugs_seized}
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1' sx={{ borderBottom: 1 }}>
            {indicator?.totals.total_vehicles_seized}
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1' sx={{ borderRight: 1, borderBottom: 1 }}>
            {indicator?.totals.total_vehicles_recovered}
          </Typography>
        </Grid>
      </Grid>
    </Container >
  );
}