/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { DispatchGroupSetResponse } from './interface';
import { getDispatchGroupSet } from './service';

interface FilterAutocompleteDispatcheGroupsSetProps {
    readonly onGroupDispatchSetChange: (value: number | null) => void;
    readonly dispatchGroupSetId?: number;
    readonly disabled?: boolean;
    readonly agencyId?: number | null;
    readonly label?: string;
}

export default function FilterAutocompleteDispatchGroupSet({ agencyId, onGroupDispatchSetChange, dispatchGroupSetId, label = 'Dispatch Group Set', disabled = false }: FilterAutocompleteDispatcheGroupsSetProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly DispatchGroupSetResponse[]>([]);
    const [optionsFilter, setOptionsFilter] = useState<readonly DispatchGroupSetResponse[]>([]);
    const [value, setValue] = useState<DispatchGroupSetResponse | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        getDispatchGroupSet().then(dispatchGroup => {
            setOptions(dispatchGroup)
            setOptionsFilter(dispatchGroup)
            setValue(null)
        })
    }, [])

    useEffect(() => {
        if (!dispatchGroupSetId) {
            setValue(null)
        } else {
            setValue(optionsFilter.find(option => dispatchGroupSetId === option.id) ?? null)
        }
    }, [dispatchGroupSetId, optionsFilter])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                id='filter-dispatch-group-set'
                disabled={disabled}
                limitTags={2}
                sx={{ height: 2 }}
                options={optionsFilter}
                value={value}
                noOptionsText={translate('No options')}
                clearText={translate('Clear')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={(_, type) => {
                    setValue(type)
                    onGroupDispatchSetChange(type?.id ?? null)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate(label)}
                    />
                )}
            />
        </FormControl>
    )
}