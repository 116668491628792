import React, { useCallback, useEffect, useState } from 'react';
import useDispatchRegistrationState from '../../stores/DispatchRegistrationState';
import useDispatchState from '../../stores/DispatchState';
import ListRegistrations from '../DispatchRegistrationTab/components/ListRegistrations';
import { useLang } from '~/hooks/useLang';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { truncate } from '../../utils/stringsManipulations';
import useDispatchRegistrationWeaponState, { weaponDefaultState } from '../../stores/DispatchRegistrationWeaponState';
import WeaponForm from './components/WeaponForm';
import { Tooltip } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { saveLogVisualizeQualificators } from '../../services';
import notify from '~/utils/notify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IRawWeapon, IWeaponDTO } from '../../interfaces/IDispacthRegistrationWeapon';
import { toDomain } from '~/mappers/registration-weapon-mapper';
import useSocket from '~/hooks/useSocket';
import { useHookstate } from '@hookstate/core';

interface IWeapon {
  readonly handleClickOpenConfirmAction: (actionName: string) => void;
}
interface IMessageWeapon {
  readonly content: IRawWeapon
  readonly event_type: string
}

// eslint-disable-next-line max-lines-per-function
export default function Weapon({ handleClickOpenConfirmAction }: IWeapon) {
  const { translate } = useLang();
  const { socket } = useSocket();

  const { dispatch } = useDispatchState();
  const { occurrence, status, id: dispatchIdState } = dispatch();
  const { id: occurrenceIdState, } = occurrence;

  const { registrationDispatch, } = useDispatchRegistrationState();
  const { addRegistration, loadingRegistrations, registrationRowId, deleteRegistration } = registrationDispatch();

  const { registrationWeaponDispatch, getAllAssociatedWeapon, deleteWeapon } = useDispatchRegistrationWeaponState();
  const { weapons, weapon } = registrationWeaponDispatch();
  const [rows, setRows] = useState<readonly IWeaponDTO[]>([])

  const columns = ([
    {
      field: 'id',
    },
    {
      field: 'weaponType',
      headerName: translate('Weapon Type'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'circunstance',
      headerName: translate('Circunstance'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'destination',
      headerName: translate('Destination'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'notes',
      headerName: translate('Note'),
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return <p>
            ----
          </p>
        }
        return <p>
          {truncate(params.value, 49)}
        </p>
      }
    },
    {
      field: 'actions', type: 'actions', flex: 0.5,
      getActions: (params) => [
        status.value.id === 8 ? <Tooltip key={2} title={translate('View Record')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<RemoveRedEyeIcon />}
              onClick={() => {
                saveLogVisualizeQualificators(params.row.id, '/occurrence/associated-weapon/generate-view-timeline').then((response) => {
                  if (response.status === 200) {
                    weapon.set(params.row);
                    addRegistration.set(true)
                  }
                })
              }}
              label="Print" />
          </span>
        </Tooltip> : <></>,
        status.value.id !== 8 ? <Tooltip key={2} title={translate('Edit Record')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<EditIcon />}
              onClick={() => {
                weapon.set(params.row);
                addRegistration.set(true)
              }}
              label="Print" />
          </span>
        </Tooltip> : <></>,
        status.value.id !== 8 ? <Tooltip key={2} title={translate('Delete Record')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<DeleteIcon />}
              onClick={() => {
                registrationRowId.set(params.row.id);
                handleClickOpenConfirmAction('delete')
              }}
              label="Print" />
          </span>
        </Tooltip> : <></>,
      ]
    },
  ])

  const handleAdd = () => {
    addRegistration.set(true)
    weapon.set({
      ...weaponDefaultState,
      occurrenceId: occurrenceIdState.get(),
    })
  }

  const handleDelete = useCallback(async (id: string) => {
    loadingRegistrations.set(true)
    await deleteWeapon(id)
    notify({ message: translate('Successfully Deleted'), type: 'success' })
    loadingRegistrations.set(false)
    deleteRegistration.set(false)
  }, [deleteRegistration, deleteWeapon, loadingRegistrations, translate])

  useEffect(() => {
    if (deleteRegistration.get()) {
      handleDelete(registrationRowId.get())
    }
  }, [deleteRegistration, handleDelete, registrationRowId])

  useEffect(() => {
    loadingRegistrations.set(true);
    getAllAssociatedWeapon(occurrenceIdState.get())
      .then((res) => {
        setRows(res)
        weapons.set(res)
      }).finally(() => {
        loadingRegistrations.set(false)
      });
  }, []);

  const newMessage = useCallback((message: IMessageWeapon) => {
    if (message.event_type === 'OCCURRENCE_ASSOCIATED_WEAPON_CREATE') {
      if (message.content.dispatch_id !== dispatchIdState.get()) {
        return
      }
      setRows(prev => [...prev, toDomain(message.content)])
    }
    if (message.event_type === 'OCCURRENCE_ASSOCIATED_WEAPON_DELETE') {
      const filterRows = rows.filter(row => row.id !== message.content.id)
      setRows(filterRows);
    }
    if (message.event_type === 'OCCURRENCE_ASSOCIATED_WEAPON_CHANGE') {
      const updateRows = rows.map(row => {
        if (row.id === message.content.id) {
          return toDomain(message.content)
        } else {
          return row
        }
      })
      setRows(updateRows);
    }
  }, [rows])

  useEffect(() => {
    socket.on('occurrences', newMessage)
    return () => {
      socket.off('occurrences', newMessage)
    }
  }, [newMessage, socket])

  return (
    addRegistration.get() ? (
      <WeaponForm
        handleClickOpenConfirmAction={handleClickOpenConfirmAction}
      />
    ) : (
      <ListRegistrations
        handleAdd={handleAdd}
        rows={rows}
        columns={columns as GridColDef[]}
      />
    )
  )
};