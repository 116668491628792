import notify, { INotify } from '~/utils/notify'
import { CommentDataProps } from '~/features/Dispatch/stores/DispatchCommentState'
import { LatLng } from 'leaflet'
import { State } from '@hookstate/core'
import { Id } from 'react-toastify'
interface dispatchSocketProps {
  readonly verifyPermission?: any
  readonly message: any
  readonly translate?: any
  readonly dispatch?: any
  readonly updateStatusRelated?: any
  readonly updateRowsByDispatchId?: any
  readonly updateListAllVehicles?: any
  readonly updateListAllNoPaginationVehicles?: any
  readonly addDispatchComment?: any
  readonly rowsOptNP?: any
  readonly syncDispatchFromId?: any
  readonly socketUpdateLocation?: any
  readonly createMarker?: any
  readonly updateMarker?: any
  readonly addOccurrenceComment?: any
  readonly occurrenceObject?: any
  readonly getMarker?: any
  readonly syncOccurrenceEditAttendent?: any
  readonly windowManager?: any
  readonly user?: any
  readonly openModalResultMultipleCloseDispatches?: any
  readonly socketUpdateCallsDispatch?: any
  readonly socketUpdateCallsOccurrence?: any
  readonly updateDispatchRowsTable?: any;
  readonly socketUpdateStatusDispatchRelatedOccurrence?: any
  readonly reloadListOccurrenceDispatches?: any
  readonly socketUpdateListDispatchRelatedOccurrence?: any
  readonly updateListDispatch?: any
  readonly updateStatusOccurrenceDispatchesRelated?: any
  readonly dispatchesOccurrence?: any
  readonly updateListOccurrenceDispatches?: any
  readonly editedComment?: any
  readonly mountUrlRequest?: any
  readonly addDispatchLineTable?: any;
  readonly updateOccurrenceRowsTable?: any
  readonly updateCloseMultipleDispatchRowsTable?: any
  readonly actualNotification: () => State<number|null>;
}

// eslint-disable-next-line max-lines-per-function
export default function dispatchSocket({
  verifyPermission,
  message,
  translate,
  dispatch,
  updateStatusRelated,
  updateRowsByDispatchId,
  updateListAllVehicles,
  updateListAllNoPaginationVehicles,
  addDispatchComment,
  rowsOptNP,
  syncDispatchFromId,
  socketUpdateLocation,
  createMarker,
  updateMarker,
  addOccurrenceComment,
  occurrenceObject,
  getMarker,
  syncOccurrenceEditAttendent,
  windowManager,
  user,
  openModalResultMultipleCloseDispatches,
  socketUpdateCallsDispatch,
  socketUpdateCallsOccurrence,
  socketUpdateStatusDispatchRelatedOccurrence,
  reloadListOccurrenceDispatches,
  socketUpdateListDispatchRelatedOccurrence,
  updateListDispatch,
  updateStatusOccurrenceDispatchesRelated,
  dispatchesOccurrence,
  updateListOccurrenceDispatches,
  editedComment,
  mountUrlRequest,
  updateDispatchRowsTable,
  addDispatchLineTable,
  updateOccurrenceRowsTable,
  updateCloseMultipleDispatchRowsTable,
  actualNotification
}: dispatchSocketProps) {
  console.info(`Dispatch socket received: ${message.event_type}`)
  const {
    id: dispatchStateId,
    status,
    occurrence: dispatchOcurrence,
  } = dispatch()

  if (message.event_type === 'NEW_DISPATCH_CREATED') {
    try {
      socketUpdateListDispatchRelatedOccurrence({
        agency: message.content.agency,
        code: message.content.code,
        dispatchGroup: message.content.dispatch_group,
        id: message.content.id,
        status: message.content.status,
        occurrenceId: message.content.occurrence.id,
      })
      updateListOccurrenceDispatches({ dispatchesOccurrence })
    } catch (error) {
      console.error(
        `Erro atualizando lista de dispachos da ocorrência ${JSON.stringify(
          message
        )} - ${JSON.stringify(error)}`
      )
    }
  }

  if (message.event_type === 'DISPATCH_STATUS_CHANGED') {
    try {
      try {
        updateStatusRelated({
          dispatchId: message.content.dispatch,
          status: message.content.status,
        })
        socketUpdateStatusDispatchRelatedOccurrence({
          dispatchId: message.content.dispatch,
          status: message.content.status,
          occurrenceId: message.content.occurrence_id,
        })
        updateStatusOccurrenceDispatchesRelated({ dispatchesOccurrence })
      } catch (error) {
        console.error(
          `Erro atualizando socket de veículo contendo a seguinte mensagem ${JSON.stringify(
            message
          )} - ${JSON.stringify(error)}`
        )
      }

      try {
        updateListAllVehicles()
      } catch (error) {
        console.error(
          `Erro atualizando a lista de veículos com a mensagem ${JSON.stringify(
            message
          )} - ${JSON.stringify(error)}`
        )
      }

      try {
        updateListAllNoPaginationVehicles()
      } catch (error) {
        console.error(
          `Erro atualizando a lista de veículos com a mensagem ${JSON.stringify(
            message
          )} - ${JSON.stringify(error)}`
        )
      }

      try {
        const currentMarker = getMarker({
          markerType: 'dispatch',
          markerIdentifier: message.content.dispatch,
        })
        if (currentMarker) {
          updateMarker({
            markerType: 'dispatch',
            markerIdentifier: message.content.dispatch,
            attribute: 'status',
            value: message.content.status.id,
          })
        }
      } catch (error) {
        console.error(
          `Erro atualizando os pontos do mapa com a mensagem ${JSON.stringify(
            message
          )} - ${JSON.stringify(error)}`
        )
      }

      try {
        updateRowsByDispatchId({
          dispatchId: message.content.dispatch,
          status: message.content.status,
        })
      } catch (error) {
        console.error(
          `Erro atualizando as linhas da tabela com a mensagem ${JSON.stringify(
            message
          )} - ${JSON.stringify(error)}`
        )
      }

      try {
        updateDispatchRowsTable({
          dispatchId: message.content.dispatch,
          status: message.content.status,
          elapsedTime: message.content.status_updated_at
        })
      } catch (error) {
        console.error(error)
      }

      rowsOptNP.rowsOpt.updateRowsByDispatchId({
        dispatchId: message.content.dispatch,
        status: message.content.status,
      })
    } catch (err) {
      console.error(err)
    }
    if (dispatchStateId.get() == message.content.dispatch)
      status.set(message.content.status)
  }

  if (message.event_type === 'DISPATCH_CREATED') {
    const { occurrence, id, code, status, type, subtype } = message.content;
    const { latitude, longitude, location } = occurrence;

    // try {
      // Criar Aqui
      addDispatchLineTable({
        id: message.content.id,
        occurrence_id: message.content.occurrence.id,
        code: message.content.code,
        status: message.content.status.acronym,
        statusId: message.content.status.id,
        dispatch_group: message.content.dispatch_group.name,
        type: message.content.type.name,
        subtype: message.content.subtype ? message.content.subtype?.name : '',
        location: message.content.occurrence.location ? message.content.occurrence.location : '',
        city: message.content.occurrence.city.name,
        district: message.content.occurrence.district ? message.content.occurrence.district : '',
        notes: "-",
        priority: message.content.priority,
        elapsed_time: message.content.status_updated_at,
        status_updated_at: message.content.status_updated_at,
        highlight: 0,
        dispatched_at: message.content.dispatched_at
    })
  // } catch (error) {
  //   console.error(`Erro na criação de um novo despacho tela de suporte com mensagem ${JSON.stringify(message)} - ${JSON.stringify(error)}`);

  // };

    try {
      rowsOptNP.rowsOpt.updateRowsByDispatchId({
        dispatchId: id,
        status: status,
      })
      updateRowsByDispatchId({
        dispatchId: id,
        status: status,
      })

      if (latitude && longitude) {
        const mapMessage = {
          id,
          position: new LatLng(latitude, longitude),
          location: location ?? '',
          code: code,
          status: status.id,
          typeName: type.name,
          subtypeName: subtype?.name ? subtype?.name : null,
        }
        createMarker({
          markerType: 'dispatch',
          markerIdentifier: id,
          ...mapMessage,
        })
      }

    } catch (error) {
      console.error(
        `Erro ao atualizar a criação de um novo despacho com mensagem ${JSON.stringify(
          message
        )} - ${JSON.stringify(error)}`
      )
    }

    if (message.content.emergency) {
      if (verifyPermission(['dispatch_index'])) {
        notify({
          idMessage: message.content.id,
          message: `${translate('New Dispatch with Emergency Created!')} ID-${message.content.code
            } `,
          type: 'warning',
          priority: 3,
          clickableMessage: {
            id: message.content.id,
            urlRedirection: '/dispatch',
            action: () => syncDispatchFromId(message.content.id),
            hideButton: true,
          },
          actualNotification: actualNotification,
        })
      }
    } else {
      if (verifyPermission(['dispatch_index'])) {
        notify({
          idMessage: message.content.id,
          message: `${translate('New Dispatch Created!')} ${message.content.code
            }`,
          type: 'info',
          clickableMessage: {
            id: message.content.id,
            urlRedirection: '/dispatch',
            // action: syncDispatchFromId,
            action: () => syncDispatchFromId(message.content.id),
            hideButton: verifyPermission(['dispatch_index']) ? false : true,
          },
          autoCloseDuration: false,
          actualNotification: actualNotification
        })
      }
    }
  }

  if (message.event_type === 'DISPATCH_ADDRESS_CHANGED') {
    try {
      const {
        dispatch_id: dispatchId,
        occurrence_id: occurrenceId,
        geo: {
          location: location,
          latitude: latitude,
          longitude: longitude,
          city: city,
          district: district,
          address_reference: addressReference,
        },
      } = message.content
      socketUpdateLocation({
        dispatchId: dispatchId,
        geolocation: {
          location,
          latitude,
          longitude,
          cityId: city.id ,
          districtId: district ? district.id : null,
          addressReference,
        },
      })

      notify({
        idMessage: message.content.dispatch_id,
        message: `Endereço alterado no despacho - [${message.content.code}]`,
        type: 'info',
        clickableMessage: {
          id: message.content.dispatch_id,
          urlRedirection: '/dispatch',
          // action: syncDispatchFromId,
          action: () => syncDispatchFromId(message.content.dispatch_id),
          hideButton: verifyPermission(['dispatch_index']) ? false : true,
        },
        actualNotification: actualNotification
      })
      updateDispatchRowsTable({
        dispatchId: dispatchId,
        city: city.name,
        district: district ? district.name : '',
        location: location,
      })

    } catch (error) {
      console.error(
        `Erro ao alterar o endereco do despacho ${JSON.stringify(
          message
        )} - ${JSON.stringify(error)}`
      )
    }
  }
  if (message.event_type === 'OCCURRENCE_COMMENT_CREATED') {
    const openOccurrence = (id) => {
      syncOccurrenceEditAttendent(id)
      windowManager().occurrence.open.set(true)
    }
    try {
      const {
        id: commentId,
        user: senderUser,
        comment,
        media_path: mediaPath,
        created_at: createdAt,
        occurrence_id: occurrenceId,
        closing_comment: closingComment,
        device,
        station,
      } = message.content

      if (occurrenceId == occurrenceObject().id.get() || occurrenceId == dispatchOcurrence.id.get()) {
        const commentDataObj: CommentDataProps = {
          id: commentId,
          comment: comment,
          mediaPath,
          createdAt,
          occurrenceId: occurrenceId,
          dispatchId: occurrenceId,
          user: senderUser,
          deletedAt: null,
          updatedAt: null,
          closingComment: closingComment,
          device: device,
          station: station,
        }
        occurrenceId == occurrenceObject().id.get() && addOccurrenceComment(commentDataObj)
        occurrenceId == dispatchOcurrence.id.get() && addDispatchComment(commentDataObj)
      }

      if (
        'dispatch_receiver' in message.content &&
        verifyPermission(['dispatch_index'])
      ) {

        if (
          message.content.dispatch_id != message.content.dispatch_receiver.id
        ) {
          notify({
            idMessage: message.content.dispatch_receiver.id,
            message: `${translate('New comment created in dispatch:')} ${message.content.dispatch_receiver.code
              }`,
            type: 'info',
            clickableMessage: {
              id: message.content.dispatch_id,
              action: () =>{
                syncDispatchFromId(message.content.dispatch_receiver.id)
              },
              urlRedirection: '/dispatch',
              hideButton: false,
            },
            actualNotification: actualNotification
          })
        }
      }
      if (verifyPermission(['entry_index'])) {
        notify({
          idMessage: message.content.occurrence_id,
          message: `${translate('New comment created in occurrence:')} OC: ${message.content.occurrence_id
            }`,
          type: 'info',
          clickableMessage: {
            id: message.content.occurrence_id,
            action: () => openOccurrence(message.content.occurrence_id),
            hideButton: false,
            urlRedirection: '/entry',
          },
          actualNotification: actualNotification

        })
      }
    } catch (error) {
      console.error(
        `Erro ao criar um comentário em um despacho 02:  ${JSON.stringify(
          message
        )} - ${JSON.stringify(error)}`
      )
    }
  }

  if (message.event_type === 'DISPATCH_TRANSFER') {
    try {
      const {
        dispatch_id: dispatchId,
        new_group: { acronym: newAcronym, id: newId, name: newName },
        old_group: { acronym: oldAcronym, id: oldId, name: oldName },
        event_type: eventType,
      } = message.content

      if (dispatchId) {
        rowsOptNP.rowsOpt.updateRowsByDispatchId({
          dispatchId: dispatchId,
        })
      }

      updateDispatchRowsTable({
        dispatchId: dispatchId,
        dispatchGroup: newName
      })

    } catch (error) {
      console.error(
        `Erro ao transferir despacho:  ${JSON.stringify(
          message
        )} - ${JSON.stringify(error)}`
      )
    }
  }

  if (message.event_type === 'OCCURRENCE_RECALL') {
    try {
      const {
        calls,
        occurrence_id: occurrenceId,
        dispatch_id: dispatchId,
      } = message.content

      updateOccurrenceRowsTable({occurrenceId: occurrenceId, recallWasViewed: false})
      socketUpdateCallsDispatch({ callsNumber: calls, dispatchId: dispatchId })
      socketUpdateCallsOccurrence({
        callsNumber: calls,
        occurrenceId: occurrenceId,
      })
    } catch (error) {
      console.error(`${JSON.stringify(message)} - ${JSON.stringify(error)}`)
    }

    if (verifyPermission(['dispatch_index'])) {
      notify({
        idMessage: message.content.dispatch_id,
        message: `${translate('Recall created!')} [${message.content.code}]`,
        type: 'info',
        clickableMessage: {
          id: message.content.dispatch_id,
          action: () => syncDispatchFromId(message.content.dispatch_id),
          urlRedirection: '/dispatch',
          hideButton: false,
        },
        actualNotification: actualNotification
      })
    }
  }

  if (message.event_type === 'DISPATCH_MULTIPLE_CLOSED') {
      const verifyDispatch = message.content.dispatches.length
      if (verifyDispatch) {
        updateCloseMultipleDispatchRowsTable({ dispatches: message.content.dispatches})
        if (message.content.user_id != user) {
          notify({
            idMessage: message.content.user_id,
            message: `${translate(
              'Closed batch dispatch'
            )} [${message.content.dispatches.map(
              (element) => element.dispatch
            )}]`,
            type: 'info',
            actualNotification: actualNotification
          })
      }
    }
  }

  if (message.event_type === 'MULTIPLE_CLOSURE_COMPLETED') {
    notify({
      message: `${translate('Multiple closing executed successfully')}`,
      type: 'success',
    })
  }

  if (message.event_type === 'DISPATCH_TYPE_SUBTYPE_CHANGED') {
    if (verifyPermission(['dispatch_index'])) {
      notify({
        idMessage: message.content.dispatch_id,
        message: `${translate(
          'Type and/or Subtype changed in the dispatch'
        )} ID-[${message.content.code}]`,
        type: 'info',
        clickableMessage: {
          id: message.content.dispatch_id,
          action: () => syncDispatchFromId(message.content.dispatch_id),
          urlRedirection: '/dispatch',
          hideButton: false,
        },
        actualNotification: actualNotification
      })
    }
  }

  if (message.event_type === 'OCCURRENCE_TYPE_SUBTYPE_CHANGED') {
    const occurrenceID = message.content.occurrence_id
    syncOccurrenceEditAttendent(occurrenceID)
    const openOccurrence = (id) => {
      syncOccurrenceEditAttendent(id)
      windowManager().occurrence.open.set(true)
    }

    // if (message.content.occurrence_id !== occurrenceObject().id.get()) {

    notify({
      idMessage: message.content.occurrence_id,
      message: `${translate(
        'Type and/or Subtype changed in the occurrence'
      )} ID-[${message.content.occurrence_id}]`,
      type: 'info',
      clickableMessage: {
        id: message.content.occurrence_id,
        action: () => openOccurrence(message.content.occurrence_id),
        hideButton: false,
        urlRedirection: '/entry',
      },
      actualNotification: actualNotification

    })
    // }
  }

  if (message.event_type === 'DISPATCH_TYPE_SUBTYPE_CHANGED') {
    updateDispatchRowsTable({
      dispatchId: message.content.dispatch_id,
      type: `[${message.content.type.acronym}] ${message.content.type.name}`,
      subtype: message.content.subtype ? `[${message.content.subtype.acronym}] ${message.content.subtype.name}` : '-',
      priority: message.content.priority,
    })
  }
  if (message.event_type === 'OCCURRENCE_FEATURED' || message.event_type === 'OCCURRENCE_UNFEATURED') {
    try {
      updateDispatchRowsTable({
        dispatchId: message.content.dispatch_id,
        featured: message.content.featured
      })
    } catch (error) {
      console.error(error)
    }
  }

  if (message.event_type === 'DISPATCH_TYPE_SUBTYPE_CHANGED') {
    updateDispatchRowsTable({
      dispatchId: message.content.dispatch_id,
      type: `[${message.content.type.acronym}] ${message.content.type.name}`,
      subtype: message.content.subtype ? `[${message.content.subtype.acronym}] ${message.content.subtype.name}` : '-',
      priority: message.content.priority,
    })
  }

  if (message.event_type === 'OCCURRENCE_COMMENT_CHANGED') {
    const changedByUser = message.content.changed_by_user.id
    const openOccurrence = (id) => {
      syncOccurrenceEditAttendent(id)
      windowManager().occurrence.open.set(true)
    }

    if (Boolean(message.content.is_deleted) && changedByUser !== user){
      notify({
        idMessage: message.content.dispatch_id,
        message: `${translate('Comment deleted on occurrence')} ID-[${message.content.occurrence_id
          }]`,
        type: 'info',
        clickableMessage: {
          id: message.content.occurrence_id,
          action: () => openOccurrence(message.content.occurrence_id),
          hideButton: false,
          urlRedirection: '/entry',
        },
        actualNotification: actualNotification

      })
    }

    if (
      Boolean(message.content.is_edited) &&
      Boolean(message.content.is_deleted) == false &&
      changedByUser !== user
    ) {
      notify({
        idMessage: message.content.dispatch_id,
        message: `${translate('Comment edited on occurrence')} ID-[${message.content.occurrence_id
          }]`,
        type: 'info',
        clickableMessage: {
          id: message.content.occurrence_id,
          action: () => openOccurrence(message.content.occurrence_id),
          hideButton: false,
          urlRedirection: '/entry',
        },
        actualNotification: actualNotification
      })
    }
  }

  if (message.event_type === 'DISPATCH_CLOSURE_REQUESTED') {
    updateOccurrenceRowsTable({ occurrenceId: message.content.occurrence_id, closureRequested: message.content.occurrence.closure_requested })
  }

  if (message.event_type === 'OCCURRENCE_COMMENT_IMAGE_PATH') {
    editedComment({
      mediaPath: mountUrlRequest(
        `/occurrence-comment/get-media/${message.content.media_path}`
      ),
      id: message.content.id,
      fileType: message.content.file_type,
      fileName: message.content.file_name,
    })
  }

  if (message.event_type === 'DISPATCH_CLOSURE_REQUESTED') {
    if (verifyPermission(['dispatch_index'])) {
      notify({
        idMessage: message.content.id,
        message: `${translate('Requested closure for dispatch:')} ${message.content.code
          }`,
        type: 'warning',
        autoCloseDuration: 10000,
        clickableMessage: {
          id: message.content.id,
          action: () =>
            syncDispatchFromId(message.content.id),
          urlRedirection: '/dispatch',
          hideButton: false,
        },
        actualNotification: actualNotification
      })
    }
  }
}
