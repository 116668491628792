import { Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Logo from '~/components/Logo';
import { useLang } from '~/hooks/useLang';

interface RealoadButtonPrintProps {
  readonly message: string;
  readonly reloadInfo: () => void;
}

export default function RealoadButtonPrint({ message, reloadInfo }: RealoadButtonPrintProps) {
  const { translate } = useLang();

  const handleReloadPrint = () => {
    reloadInfo()
  }

  return (
    <Container >
      <Grid item xs={3} justifyContent="center" alignContent="center">
        <Grid container xs={12}>
          <Grid item xs={12} maxHeight={'95px'} maxWidth={'95px'}>
            <Logo />
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12} >
        <Grid
          item
          xs={12}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'calc(100vh - 80px)'}
        >
          <Typography variant='h4'>
            {translate(message)}
          </Typography>
          <Button
            variant='contained'
            onClick={() => handleReloadPrint()}
            sx={{ marginTop: '16px' }}
          >
            {translate('Reload page')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}