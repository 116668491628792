import React, { useEffect, useState } from 'react'
import { Card, CardHeader, IconButton } from '@mui/material'
import { useHookstate } from '@hookstate/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import dayjs, { Dayjs } from 'dayjs'
import { useLang } from '~/hooks/useLang'
import DeviceFiltersDashboard from '../DeviceFiltersDashboard'
import DeviceByTotalByStatusCards from '../DeviceByTotalByStatusCards'
import DeviceByTimeAndStatusCharts from '../DeviceByTimeAndStatusCharts'

export interface DispatchClosedProps {
  readonly id: number
  readonly name: string
  readonly acronym: string
  readonly category_id?: number
}

/* eslint-disable max-lines-per-function */
export default function DeviceCardDashboard() {
  const { translate } = useLang()
  const refresh = useHookstate<boolean>(false)
  const loading = useHookstate<boolean>(false)
  const expandFilterSearch = useHookstate<boolean>(true)
  const lockFields = useHookstate<boolean>(false)

  const withSolvedThirdParty = useHookstate<boolean>(false)

  const categoryIds = useHookstate<number[]>([])
  const agencyId = useHookstate<number | null>(null)
  const deviceId = useHookstate<number | null>(null)
  const groupId = useHookstate<readonly number[]>([])
  const operationId = useHookstate<number | null>(null)

  const typeIds = useHookstate<number[]>([])
  const subtypeIds = useHookstate<number[]>([])
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().subtract(24, 'hours')
  )
  const [finishDate, setFinishDate] = useState<Dayjs | null>(dayjs())

  const consummatedAndAttempted = useHookstate<string | null>(null)
  const statusIds = useHookstate<number[]>([])
  const cityIds = useHookstate<number[]>([])
  const dispatchGroupSetId = useHookstate<number | null>(null)
  const location = useHookstate<string>('')
  const operationUnitId = useHookstate<number | null>(null)
  const closedStatusClassId = useHookstate<number | null>(null)
  const closedStatusId = useHookstate<number | null>(null)
  const qualifiersSelected = useHookstate<number[]>([])
  const searched = useHookstate<boolean>(false)

  useEffect(() => {
    document.title = translate('Dispatch Dashboard - Dispatch System')
  }, [])

  return (
    <Card
      sx={{
        boxShadow: 4,
        marginTop: 2,
        paddingBottom: 2,
        height: expandFilterSearch.value ? 'auto' : 50,
      }}
    >
      <CardHeader
        title={`${translate('device')}`}
        avatar={
          <IconButton
            onClick={() => expandFilterSearch.set(!expandFilterSearch.get())}
            sx={{
              display: 'flex',
              justifyContent: 'end',
              marginRight: 1,
              padding: 0,
            }}
          >
            {expandFilterSearch.value ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        }
        titleTypographyProps={{ fontWeight: 'bold', fontSize: 18 }}
      />
      <DeviceFiltersDashboard
        loading={loading}
        deviceId={deviceId}
        groupId={groupId}
        agencyId={agencyId}
        hidden={expandFilterSearch.value}
        lockFields={lockFields.get()}
        clearFilters={() => {
          deviceId.set(null)
          groupId.set([])
          agencyId.set(null)
          dispatchGroupSetId.set(null)
          operationUnitId.set(null)
          refresh.set(!refresh.get())
        }}
        searchButton={() => {
          refresh.set(!refresh.get())
          searched.set(true)
        }}
        dispatchGroupSetId={dispatchGroupSetId}
        operationUnitId={operationUnitId}
      />
      {searched.get() && <>
        <DeviceByTotalByStatusCards
          loading={loading}
          agencyId={agencyId.get()}
          deviceId={deviceId.get()}
          dispatchGroupId={groupId.get()}
          category={categoryIds.get()}
          type={typeIds.get()}
          subtype={subtypeIds.get()}
          dateStart={startDate?.toDate() ?? null}
          dateFinish={finishDate?.toDate() ?? null}
          thirdParty={withSolvedThirdParty.get()}
          refresh={refresh.get()}
          groupSetId={dispatchGroupSetId.get()}
          operationUnitId={operationUnitId.get()}
          expandFilterSearch={expandFilterSearch.value}
        />

        <DeviceByTimeAndStatusCharts
          loading={loading}
          agencyId={agencyId.get()}
          deviceId={deviceId.get()}
          dispatchGroupId={groupId.get()}
          category={categoryIds.get()}
          type={typeIds.get()}
          subtype={subtypeIds.get()}
          dateStart={startDate?.toDate() ?? null}
          dateFinish={finishDate?.toDate() ?? null}
          thirdParty={withSolvedThirdParty.get()}
          operationId={operationId.get()}
          refresh={refresh.get()}
          consummated={consummatedAndAttempted.get()}
          statusIds={statusIds.get()}
          cityIds={cityIds.get()}
          groupSetId={dispatchGroupSetId.get()}
          location={location.get() ? location.get() : null}
          operationUnitId={operationUnitId.get()}
          closedStatusClassId={closedStatusClassId.get()}
          closedStatusId={closedStatusId.get()}
          qualifierOptions={qualifiersSelected.get()}
          expandFilterSearch={expandFilterSearch.value}
        />
      </>}
    </Card>
  )
}
