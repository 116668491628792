/* eslint-disable functional/prefer-readonly-type */
import * as React from 'react'
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view'
import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import { State, useHookstate } from '@hookstate/core'
import { useLang } from '~/hooks/useLang'
import LinearProgress from '@mui/material/LinearProgress'
import { useEffect, useState } from 'react'
import { getAllAgencies } from '~/features/Agency/services'
import { IDispatchGroupView } from '~/features/DispatchGroup/stores/DispatchGroupState'
export interface RenderTree {
  readonly id: string
  readonly name: string
  readonly is_visible: boolean
  readonly parent?: string
  readonly children: readonly RenderTree[]
}

interface ITreeView {
  agencyAndGroups: State<RenderTree>
  agencyOurGroupsRequested?: State<RenderTree>
  visibleGroup: State<IDispatchGroupView>
  changeAll?: boolean
}

// eslint-disable-next-line max-lines-per-function
export default function VisibleGroupTreeView({
  agencyAndGroups,
  agencyOurGroupsRequested,
  visibleGroup,
  changeAll,
}: ITreeView) {
  const { translate } = useLang()
  const [selected, setSelected] = useState<string[]>([])
  const loading = useHookstate<boolean>(true)
  const childrens = useHookstate<readonly RenderTree[]>([])

  const handleChange = (node: State<RenderTree>, checked: boolean) => {
    if (node.id.get() === 'root') {
      return
    }

    const childrenNode = node.children.get()
    if (childrenNode?.length) {
      node.merge({
        ...node.get(),
        is_visible: checked,
        children: node.children.get()?.map((childrens) => {
          visibleGroup[childrens.name].is_visible.set(checked ? 1 : 0)
          return ({
          ...childrens,
          is_visible: checked,
        })}),
      })
    } else {
      node.is_visible.set(checked)
      visibleGroup[node.name.get()].is_visible.set(checked ? 1 : 0)
    }
  }

  const renderTree = (nodes: State<RenderTree>) => (
    <TreeItem
      key={nodes.id.get()}
      itemId={nodes.id.get()}
      label={
        nodes.id.get() !== 'root' ? (
          <FormControlLabel
            label={nodes.name.get()}
            control={
              <Checkbox
                checked={
                  nodes.is_visible.get() ||
                  !!nodes.get().children?.filter((chi) => chi.is_visible).length
                }
                onChange={(_, checked) => {
                  handleChange(nodes, checked)
                }}
              />
            }
          />
        ) : nodes.children.get()?.length ? (
          nodes.name.get()
        ) : (
          translate('No records')
        )
      }
    >
      {Array.isArray(nodes.children) && nodes.children.length
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  )

  const handleSelect = (_: React.SyntheticEvent, itemIds: string[]) => {
    setSelected(itemIds)
  }

  const handleLoadData = async () => {
    const agencies = await getAllAgencies()
    const agenciesMap = agencies
      .filter((agency) =>
        Object.keys(visibleGroup.value).find(
          (item) => visibleGroup[item].agency_id.get() == agency.id
        )
      )
      .map((agency) => ({
        id: `${agency.id}`,
        name: agency.name,
        is_visible: false,
        children: Object.keys(visibleGroup.value)
          .filter((item) => visibleGroup[item].agency_id.get() === agency.id)
          .map((item) => ({
            id: `${visibleGroup[item].id.get()}-${visibleGroup[item].name.get()}`,
            name: visibleGroup[item].name.get(),
            parent: `${agency.id}`,
            is_visible: Boolean(visibleGroup[item].is_visible.get()),
          })),
      }))

    childrens.set(agenciesMap)
    agencyAndGroups.children.set(agenciesMap)
    agencyOurGroupsRequested?.children.set(agenciesMap)
    loading.set(false)
  }

  useEffect(() => {
    loading.set(true)
    handleLoadData()
  }, [])

  useEffect(() => {
    handleLoadData()
  }, [changeAll])

  return (
    <Grid container xs={12}>
      <SimpleTreeView
        selectedItems={selected}
        onSelectedItemsChange={handleSelect}
        multiSelect
        defaultExpandedItems={['root']}
        sx={{ height: 240, flexGrow: 1 }}
      >
        {loading.get() && <LinearProgress />}

        {!loading.get() && renderTree(agencyAndGroups)}
      </SimpleTreeView>
    </Grid>
  )
}
