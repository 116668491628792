import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IEntryOrigin from '../interfaces/IEntryOrigin';


export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue,getValues} = useForm<IEntryOrigin>();
  const fields = [
    { name: 'id', type: 'hidden' },
    { name: 'name', type: 'string' },
  ];
  
    return(
      <CrudEdit 
        model={'entry-origin'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        setFormValue={setFormValue}
        messageSuccess={'Origin successfully updated!'}  
        title={'Edit origin'} 
        getValues={getValues}
      />
    )
}
