import React, { useEffect } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { State, useHookstate } from '@hookstate/core';
import { getAllOccurrenceCommentService } from '~/features/Occurrence/services/index';
import { PostCommentResponse } from '~/features/Dispatch/stores/DispatchCommentState';
import useSystemState from '~/features/System/stores/SystemState';
import dayjs from 'dayjs';

interface HistoryCommentsEntryPrintProps {
  readonly occurrenceId: number | undefined;
  readonly HistoryCommentsEntryPrintResponse: PostCommentResponse['data'][];
}

export default function HistoryCommentsEntryPrint({ occurrenceId, HistoryCommentsEntryPrintResponse }: HistoryCommentsEntryPrintProps) {
  const { translate } = useLang();
  const existDeleted = useHookstate(false)


  useEffect(() => {
    HistoryCommentsEntryPrintResponse.find(element => !!element.isDeleted === true ? existDeleted.set(true) : existDeleted.set(false))
  }, [occurrenceId])

  return (
    <Container fixed={false}>
      <Grid container xs={12} sx={{ marginBottom: 2 }}>
        <Typography variant='subtitle1'>
          {!existDeleted.get() ? translate('This occurrence had edit and delete comments') : false}
        </Typography>
        {HistoryCommentsEntryPrintResponse.length != 0 ? (
          HistoryCommentsEntryPrintResponse.map((comment) => {
            return Boolean(comment?.isDeleted) === false ?
              <Grid container key={comment?.id} xs={12} sx={{ margin: 1 }}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                  <Typography variant='subtitle1'>

                    {!comment.comment ? `* ${translate('Uninformed')} *` :
                      !comment['media_path'] ? comment.comment :
                        comment['file_type'] === 'jpeg' || comment['file_type'] === 'jpg' || comment['file_type'] === 'png' ?
                          `* ${translate('An image has been attached.')}` : `* ${translate('A document has been attached.')}`}
                    {' '}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                  <Typography variant='caption' sx={{ alignItems: 'flex-start' }}>
                    <b>
                      {translate('User')}
                      :
                      {' '}
                      {comment?.user?.representation_name}
                    </b>
                    {' '}
                    -
                    {' '}
                    {comment?.createdAt && dayjs(comment?.createdAt).isValid() ? dayjs(comment?.createdAt).format('DD/MM/YYYY HH:mm:ss') : ''}
                  </Typography>
                </Grid>
              </Grid> : false
          })
        ) : (
          <Grid item xs={12} textAlign={'center'}>
            <Typography variant='subtitle1'>
              <b>
                {translate('NO INFORMATION REGISTERED')}
              </b>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}