import React from 'react'
import { AppRouteProps } from '~/routes'
import { useParams } from 'react-router-dom'
import Index from './Pages'


export default function InterestPointRoutes(): readonly AppRouteProps[] {
    const routes: readonly AppRouteProps[] = [
        {
            path: '/interest-point',
            component: Index,
            isProtected: true,
            permissions: ['interest_point_index'],
        },
    ]

    return routes
}
