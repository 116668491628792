import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import notify from '~/utils/notify';
import IAgency from '../interfaces/IAgency';

export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue,getValues} = useForm<IAgency>();
    const fields = [
      { name: 'id', type: 'hidden'},
      { name: 'name', type: 'string', required: true},
      { name: 'acronym', type: 'string', required: true},
      { name: 'area_plan_id', type: 'selectAutoComplete', model: 'area-plan', placeholder:'Mapa', required: true},
  ];
    
    return(
      <CrudEdit 
        model={'agency'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        setFormValue={setFormValue}
        messageSuccess={'Agency successfully updated!'}  
        title={'Edit agency'} 
        getValues={ getValues }
      />
    )
}
