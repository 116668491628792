import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useHookstate, useState } from '@hookstate/core'
import { AddTaskOutlined } from '@mui/icons-material'
import StandardModal from '~/components/StandardModal'
import { useLang } from '~/hooks/useLang'
import useDispatchState from '../../features/Dispatch/stores/DispatchState'
import RegistrationTab from '~/features/Dispatch/components/DispatchRegistrationTab'
import './styles.scss'
import { useLocation } from 'react-router'
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable'
import { authFetch } from '~/services/fetch'
import useSystemState from '~/features/System/stores/SystemState'
import { storeScreenSettings } from '~/features/System/services'
import { Typography } from '@mui/material'

const modal = document.getElementById('div-modal') as HTMLElement

export default function RegistrationModalDraggable() {
  const { translate } = useLang()
  const { dispatchStatusActions, dispatch } = useDispatchState()
  const { windowManager, loadingButton } = useSystemState()

  const handleStop = (event, dragElement) => {
    event.stopPropagation()

    windowManager()['registration'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y,
    })
  }

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'RegistrationModalDraggable',
        isOpen: windowManager()['registration'].open.get(),
        isMinimized: windowManager()['registration'].minimized.get(),
        positionX: windowManager()['registration'].positionX.get(),
        positionY: windowManager()['registration'].positionY.get(),
      })
    }
  }, [
    windowManager()['registration'].open.get(),
    windowManager()['registration'].minimized.get(),
    windowManager()['registration'].positionY.get(),
    windowManager()['registration'].positionX.get(),
  ])

  if (windowManager()['registration'].open.get()) {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() && (
          <StandardModal
            title={
              <Typography variant='h5'>{translate('Registration of Qualifiers')}</Typography>
            }
            dimensions={{
              width: 1000,
              
            }}
            handleStop={handleStop}
            position={{
              x: windowManager()['registration'].positionX.get()
                ? Number(windowManager()['registration'].positionX.get())
                : 300,
              y: windowManager()['registration'].positionY.get()
                ? Number(windowManager()['registration'].positionY.get())
                : 200,
            }}
            closeButton={() => windowManager()['registration'].open.set(false)}
            isExpanded={windowManager()['registration'].minimized}
            sxContent={{paddingX: 0}}
          >
            {windowManager()['registration'].minimized.get() && (
              <RegistrationTab />
            )}
          </StandardModal>
        )}
      </>,
      modal
    )
  } else {
    return <></>
  }
}
