import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { State, StateMethods } from '@hookstate/core'
import { LoadingButton } from '@mui/lab'

export interface ConfirmDialogProps {
  readonly open: StateMethods<boolean>
  readonly title?: string
  readonly content?: string
  readonly handleClose: () => void
  readonly handleAgree: () => void
  readonly closeLabel?: string
  readonly AgreeLabel?: string
  readonly loading?: State<boolean>
  readonly disabled?: boolean
}

export default function ConfirmDialog({ open, title, content, handleClose, handleAgree, closeLabel = 'Cancel', AgreeLabel = 'Ok', loading, disabled }: ConfirmDialogProps) {
  const translate = useTranslation().t
  return (
    <Dialog
      open={open.get()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'xs'}
      fullWidth
    >
      {title && (
        <DialogTitle id="alert-dialog-title"  minWidth={"320px"} variant='h5' >
          {title}
        </DialogTitle>
      )}

      {content && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
      )}

      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: 2,
          padding: '16px 24px'
        }}
        disableSpacing={true}
      >
        <Button
          variant="outlined"
          onClick={() => handleClose()}
        >
          {translate(closeLabel)}
        </Button>

        <LoadingButton
          loading={loading?.get()}
          variant="contained"
          onClick={() => handleAgree()}
          autoFocus
          disabled={disabled}
        >
          {translate(AgreeLabel)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
