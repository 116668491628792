import { useCallback, useState } from "react";
import Cookies, { CookieSetOptions } from "universal-cookie";
const cookies = new Cookies();

type P = readonly [
    any,
    (value: any) => void,
]

function useCookie(key: string, initialValue: any = false, _options: CookieSetOptions = {}): P {
    const defaultOptions: CookieSetOptions = {
        ...{
            sameSite: 'strict', 
            secure: false,
            maxAge: 5 * 365.25 * 24 * 60 * 60, // 5 anos
            path: '/',  
        },
        ..._options
    }

    const [state, setState] = useState(() => {
        try {
            const storedValue = cookies.get(key);
            return (storedValue != null || storedValue != undefined) ? storedValue : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = useCallback((value: any) => {
        try {
           setState(value); 
           cookies.set(key, value, defaultOptions);
        } catch (error) {
            console.error(error);
        }
    }, [key]);

    return [state, setValue];
}


export default useCookie;