import { authFetch } from "~/services/fetch";
import { SpecieResponse } from "../interface";

export async function getSpecies(): Promise<readonly SpecieResponse[]> {
  try {
    const { data } = await authFetch({
      url: '/specie',
      method: 'GET',
    })
    return data;
  } catch (error) {
    throw error;
  }
}