/* eslint-disable max-lines-per-function */
import React from 'react';
import { useLang } from '~/hooks/useLang';
import { DataGrid, GridColumnHeaderParams, GridColDef, gridClasses, GridSlots } from '@mui/x-data-grid';
import { Avatar, Button, Chip, Grid, LinearProgress, keyframes } from '@mui/material';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { elapsedTime } from '~/utils/dateTime';
import useSystemState from '~/features/System/stores/SystemState';
import useDispatchTableState from '~/features/Dispatch/components/TabListDispatch/stores/DispatchTableState';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import { Star } from '@mui/icons-material';
import { State, useHookstate } from '@hookstate/core';
import { useTheme } from '~/hooks/useTheme';
import { ptBR } from '@mui/x-data-grid/locales';
import NoRowsOverlay from '~/components/NoRowsOverlay';
import IListDispatchSocket from '~/components/ListDispatchesSocket/interfaces/IListDispatchesProps';

interface IDispatchTableUpdateSocket {
  readonly typeStatus: string,
  readonly loadingRows: State<boolean>,
  readonly fullList?: boolean,
  readonly enableCheckbox?: boolean,
  readonly rowsSelected?: State<readonly IListDispatchSocket[]>,
}

export default function DispatchTableUpdateSocket({ typeStatus, loadingRows, fullList = false, enableCheckbox = false, rowsSelected }: IDispatchTableUpdateSocket){
  const { darkMode } = useTheme();
  const { translate } = useLang();
  const { getStatusColors, getPriorityColors } = useSystemState();
  const { dispatchRowsTable, updateOccurrenceRowsTable } = useDispatchTableState();
  const { syncDispatchFromId } = useDispatchState();
  const loading = useHookstate(false);

  const handleClickAction = (params) => {
    const paramDispatchId: number | null = params.row.id ? params.row.id : null;
    loading.set(true)
    syncDispatchFromId(paramDispatchId).finally(() => {
      loading.set(false)
      try {
        updateOccurrenceRowsTable({
          occurrenceId: params.row.occurrence_id,
          recallWasViewed: true,
        })
      } catch (error) {
        console.error(error)
      }
    })
  }

  const onRowsSelectionHandler = (ids) => {
    if(enableCheckbox && rowsSelected){
      const selectedRowsData = ids.map((id) => JSON.parse(JSON.stringify(dispatchRowsTable().get()[typeStatus])).find((row) => row.id === id));
      rowsSelected ? rowsSelected?.set(selectedRowsData) : false
    }
  };

  const columns: readonly GridColDef[] = ([
    { field: 'id' },
    { field: 'occurrence_id' },
    { field: 'call' },
    { field: 'recall_was_viewed' },
    { field: 'closure_requested' },
    {
      field: "code",
      headerName: translate('Dispatch Code'),
      flex: 0.7,
      sortable: true,
      renderCell: (params) => {
        return <Button type="submit" variant="contained"
          onClick={() => handleClickAction(params)}
          sx={{
            backgroundColor: getStatusColors(params.row.statusId).main,
            color: getStatusColors(params.row.statusId).contrastText,
            '&:hover': {
              backgroundColor: getStatusColors(params.row.statusId).dark,
              color: getStatusColors(params.row.statusId).contrastText,
            },
          }}
          >
          {params.value}
        </Button>

      },
    },
    { field: 'status', headerName: translate('Status'), flex: 0.4, sortable: true, },
    { field: 'status_updated_at' },
    { field: 'dispatch_group', headerName: translate('Dispatch Group'), flex: 1, sortable: true,},
    { field: 'type', headerName: translate('Type'), flex: 1.5, sortable: true,},
    { field: 'subtype', headerName: translate('Subtype'), flex: 1.5, sortable: true,},
    { field: 'location', headerName: translate('Location'), flex: 1.5, sortable: true,},
    {
      field: 'city', headerName: translate('City'), flex: 0.5, sortable: true,
      renderCell: (params) => {
        return params.row.city + (params.row.district ? ` - ${params.row.district}` : '')
      }
    },
    { field: 'notes', headerName: translate('Comment'), flex: 1.5 },
    {
      field: 'priority', headerName: translate('Priority'), flex: 0.4, sortable: true, renderCell: (params) => {
        return <Chip sx={{  display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }} 
        avatar={<Avatar>
<Brightness1Icon sx={{ color: getPriorityColors(params.row.priority).main }}/>
</Avatar>}
        label={params.row.priority}/>
      }
    },
    {
      field: 'elapsed_time',
      headerName: translate('Elapsed Time'),
      flex: 0.4,
      align: 'center',
      sortable: true,
      renderCell: (params) => elapsedTime(params.row.elapsed_time)
    },
    {
      field: 'highlight',
      headerName: translate('Highlight'),
      flex: 0.3,
      align: 'center',
      sortable: true,
      renderCell: (params) => {
        return (params.row.highlight ? <Star color="warning" /> : <></>);
      },
    }
  ]);

  const flashes = keyframes`
    0% { background: ${darkMode ? '#121212' : '#FFF'}}
    100% {background: #ffa726}
  `;

  const flashesBK = keyframes`
    0% { background: ${darkMode ? '#121212' : '#FFF'}}
    100% {background: #BFBFBF}
  `;

  return ( 
    <DataGrid
        sx={{
          height: JSON.parse(JSON.stringify(dispatchRowsTable().get()[typeStatus])).length > 0 ? 'auto' : 300,
          [`.${gridClasses.columnHeaderTitle}`]: {
            fontWeight: 'bold !important',
          },
          cursor: 'pointer',
          '& .flashesRows': {
            background: '#ffa726',
            border: '1px solid #fff',
            animationIterationCount: '4',
            animationTimingFunction: 'ease',
            animationDuration: '2.5s',
            animationName: `${flashes}`,
          },
          '& .closureRequestedFlashesRows': {
            background: '#BFBFBF',
            border: '1px solid #fff',
            animationIterationCount: '4',
            animationTimingFunction: 'ease',
            animationDuration: '2.5s',
            animationName: `${flashesBK}`,
          },
        }}
        loading={loading.get() || loadingRows ? loadingRows.get() : false }
        slots={{ 
          loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
          noRowsOverlay: NoRowsOverlay
        }}
        getRowClassName={({ row }) => {
          return (typeStatus != 'done' ? row.call > 1 && !row.recall_was_viewed ? 'flashesRows' : row.closure_requested ? 'closureRequestedFlashesRows' : '' : '');
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        rows={JSON.parse(JSON.stringify(dispatchRowsTable().get()[typeStatus]))}
        paginationMode={'client'}
        initialState={{
          pagination: { paginationModel: { pageSize: fullList ? 60 : 5 } },
          sorting: {
            sortModel: [{ field: 'elapsed_time', sort: 'desc' }],
          },
        }}
        columnVisibilityModel={{
          id: false,
          occurrence_id: false,
          closure_requested: false,
          call: false,
          recall_was_viewed: false,
          notes: false,
          status_updated_at: false
      }}
        getRowId={(row) => Number(row.id)}
        checkboxSelection={enableCheckbox}
        onRowSelectionModelChange={(ids) => {
          onRowsSelectionHandler(ids)
        }}
        disableColumnSelector
        disableRowSelectionOnClick
        disableColumnMenu
        
      />
  )
}




