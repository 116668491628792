import { authFetch } from "~/services/fetch";
import notify from "~/utils/notify";

interface DeviceOutOfServiceProps {
    readonly deviceId: number;
    readonly outOfServiceReasonId: number;
    readonly comment: string;
}

export async function getOutServiceReason() {
    const response = await authFetch({
        url: '/device/out-of-service-reason',
        method: 'POST',
    })

    return response.data.data;
}

export async function deviceOutOfService({ deviceId, outOfServiceReasonId, comment }: DeviceOutOfServiceProps) {
    const response = authFetch({
        url: '/device/out-of-service',
        data: {
            "device_id": deviceId,
            "out_of_service_reason_id": outOfServiceReasonId,
            "comment": comment
        }
    })

    return response
}