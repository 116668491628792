import React, { useEffect } from 'react';
import CrudIndex from '~/components/CrudIndex';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';

export default function Index() {  
  const { translate } = useLang();

  useEffect(() => {
    document.title = translate('Operations - Dispatch System')
  }, [])
  return(
    <Layout marginLayout={true}>
      <CrudIndex 
        model='operation' 
        title='Operations'
        aliasLabel={[{ column: 'start_date', label: 'initial Date' }, { column: 'end_date', label: 'End Date' }]}
        booleanField={['active']}
        datesField={['start_date', 'end_date']}
      />
    </Layout>
  )
} 