import { authFetch } from "~/services/fetch";
import { DispatchGroupResponse } from "../interface";

export async function getDispatchGroup(): Promise<readonly DispatchGroupResponse[]> {
  try {
    const { data } = await authFetch({
      url: '/dispatch/group',
      method: 'GET',
    })
    return data;
  } catch (error) {
    throw error;
  }
}