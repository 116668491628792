/* eslint-disable max-lines-per-function */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
  Button,
  Card,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material'
import ErrorFallback from '~/components/ErrorFallback'
import Layout from '~/components/Layout'
import { useLang } from '~/hooks/useLang'
import notify from '~/utils/notify'
// import useReportState from '../../../stores/ReportState';
import { useHookstate } from '@hookstate/core'
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
// import GenerateReportButton from '../../../components/GenerateReportButton';
import ExportButtonCSV from '~/features/Report/components/ButtonExportCSV'
import { Cancel } from '@mui/icons-material'
import FilterAutocompleteTypeInterestPoint from '~/components/FilterAutocompleteTypeInterestPoint'
import FilterAutocompleteSubtypesByTypeInterestPoint from '~/components/FilterAutocompleteSubtypesByTypeInterestPoint'
import SelectBasic from '~/components/SelectBasic'
import { makeRequestToGetInterestPoints } from './services'
import useReportState from '../../stores/ReportState'
import GenerateReportButton from '../../components/GenerateReportButton'
import { downloadReportCSV } from '../../services/report'
import FilterAutocompleteAreas from '~/components/FilterAutocompleteAreas'

export default function ReportInterestPoints() {
  const { translate } = useLang()

  const isLoading = useHookstate<boolean>(false)
  const lockFields = useHookstate<boolean>(false)
  const name = useHookstate<string | null>(null)
  const notes = useHookstate<string | null>(null)
  const areaIds = useHookstate<number[]>([])
  const typeInterestPointId = useHookstate<number | null>(null)
  const subtypeInterestPointId = useHookstate<number | null>(null)
  const expandFilterSearch = useHookstate<boolean>(true)

  const isExpired = useHookstate<boolean>(false)
  const isReviewed = useHookstate<boolean>(false)
  const onlyValidated = useHookstate<boolean | null>(false)
  const notValidated = useHookstate<boolean | null>(false)

  const [startDateReview, setStartDateReview] = React.useState<string | null>(
    null
  )
  const [finishDateReview, setFinishDateReview] = React.useState<string | null>(
    null
  )
  const [startDateExpired, setStartDateExpired] = React.useState<string | null>(
    null
  )
  const [finishDateExpired, setFinishDateExpired] = React.useState<
    string | null
  >(null)
  const [startDateCreated, setStartDateCreated] = React.useState<string | null>(
    null
  )
  const [finishDateCreated, setFinishDateCreated] = React.useState<
    string | null
  >(null)

  const initialPeriodValidated = useHookstate<string>('')
  const initialPeriodExpired = useHookstate<string>('')
  const initialPeriodCreated = useHookstate<string>('')

  const reportName = 'interest-points-registered'
  const { reportData, loadingReport } = useReportState()

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])

  function onStartDateChangeValidated(value) {
    setStartDateReview(value)
  }

  function onFinishDateChangeValidated(value) {
    setFinishDateReview(value)
  }

  function onPeriodChangeValidated(value) {
    initialPeriodValidated.set(value)
  }

  function onStartDateChangeExpired(value) {
    setStartDateExpired(value)
  }

  function onFinishDateChangeExpired(value) {
    setFinishDateExpired(value)
  }

  function onPeriodChangeExpired(value) {
    initialPeriodExpired.set(value)
  }

  function onStartDateChangeCreated(value) {
    setStartDateCreated(value)
  }

  function onFinishDateChangeCreated(value) {
    setFinishDateCreated(value)
  }

  function onPeriodChangeCreated(value) {
    initialPeriodCreated.set(value)
  }

  const selectIsValidated = useHookstate<string | null>(null)
  const optionsSelect = useHookstate([
    { id: 1, name: 'Nenhum' },
    { id: 2, name: 'Validado' },
    { id: 3, name: 'Pendente validação' },
    { id: 4, name: 'Requer revisão' },
    { id: 5, name: 'Expirado' },
  ])

  useEffect(() => {
    switch (String(selectIsValidated.get())) {
      case '1':
        selectIsValidated.set(null)
        break
      case '2':
        onlyValidated.set(true)
        notValidated.set(false)
        isReviewed?.set(false)
        isExpired?.set(false)
        break
      case '3':
        notValidated.set(true)
        onlyValidated.set(false)
        isReviewed?.set(false)
        isExpired?.set(false)
        break
      case '4':
        isReviewed?.set(true)
        notValidated.set(false)
        onlyValidated.set(false)
        isExpired?.set(false)
        break
      case '5':
        isExpired?.set(true)
        isReviewed?.set(false)
        notValidated.set(false)
        onlyValidated.set(false)
        break
      default:
        isExpired?.set(false)
        isReviewed?.set(false)
        notValidated.set(false)
        onlyValidated.set(false)
        selectIsValidated.set(null)
    }
  }, [selectIsValidated.get()])

  async function generateReport(exportCSV = false) {
    if (
      !startDateCreated == !finishDateCreated ||
      !startDateReview == !finishDateReview ||
      !startDateExpired == !finishDateExpired
    ) {
      isLoading.set(true)
      loadingReport().set(true)
      const data = await makeRequestToGetInterestPoints({
        name: name.get(),
        areasId: areaIds.get(),
        notes: notes.get(),
        typeInterestPointId: typeInterestPointId.get(),
        subtypeInterestPointId: subtypeInterestPointId.get(),
        notValidated: notValidated.get(),
        onlyValidated: onlyValidated.get(),
        isReviewed: isReviewed.get(),
        isExpired: isExpired.get(),
        startDateCreated: startDateCreated,
        finishDateCreated: finishDateCreated,
        startDateReview: startDateReview,
        finishDateReview: finishDateReview,
        startDateExpired: startDateExpired,
        finishDateExpired: finishDateExpired,
        exportCSV: exportCSV,
      }).finally(() => {
        loadingReport().set(false)
        isLoading.set(false)
      })

      if (exportCSV) {
        downloadReportCSV(
          `${translate(reportName)}-${new Date().getTime()}.csv`,
          data
        )
      } else {
        setTimeout(() => {
          reportData().set(data)
        })
        window.open(`/report/result/${reportName}`)
      }
    } else {
      notify({
        message: translate('Initial and final date are mandatory'),
        type: 'error',
      })
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true}>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader
                title={`${translate('Report')}: ${translate(
                  `Interest points registered`
                )}`}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader
            title={`${translate('Filters')}`}
            avatar={
              <IconButton
                onClick={() =>
                  expandFilterSearch.set(!expandFilterSearch.get())
                }
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginRight: 1,
                  padding: 0,
                }}
              >
                {expandFilterSearch.value ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            }
          />
          <Collapse in={expandFilterSearch.get()}>
            <Grid
              container
              xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
              }}
            >
              <Grid container direction={'row'} rowGap={2}>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <OutlinedInput
                    fullWidth
                    id="outlined-search"
                    value={name?.get()}
                    size="small"
                    placeholder={translate('Name')}
                    onChange={(event) => {
                      name?.set(event.target.value)
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle clear name"
                          onClick={() => {
                            name?.set('')
                          }}
                          disabled={name?.get()?.length ? false : true}
                          edge="end"
                        >
                          <Cancel fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <OutlinedInput
                    fullWidth
                    id="outlined-search"
                    value={notes?.get()}
                    size="small"
                    placeholder={translate('Notes')}
                    onChange={(event) => {
                      notes?.set(event.target.value)
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle clear notes"
                          onClick={() => {
                            notes?.set('')
                          }}
                          disabled={notes?.get()?.length ? false : true}
                          edge="end"
                        >
                          <Cancel fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteAreas
                    onAreaChange={(area) => areaIds.set(area)}
                    areaId={areaIds.get()}
                    disabled={lockFields.get()}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteTypeInterestPoint
                    typeInterestPointId={typeInterestPointId?.get()}
                    disabled={false}
                    onTypeInterestPointChange={(typeInterestPoint) =>
                      typeInterestPointId?.set(
                        Number(typeInterestPoint?.id) ?? null
                      )
                    }
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteSubtypesByTypeInterestPoint
                    typeInterestPointId={typeInterestPointId.get()}
                    subtypesInterestPoint={
                      subtypeInterestPointId.get()
                        ? subtypeInterestPointId.get()
                        : null
                    }
                    disabled={!typeInterestPointId.get()}
                    onSubtypesInterestPointChange={(subtypeInterestPoint) =>
                      subtypeInterestPointId?.set(
                        subtypeInterestPoint?.id ?? null
                      )
                    }
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <SelectBasic
                    state={selectIsValidated}
                    options={optionsSelect.get()}
                    placeholder={translate('Status')}
                    size={'small'}
                    disabled={false}
                  />
                </Grid>
                <Grid
                  container
                  width={'100%'}
                  display={'flex'}
                  alignItems={'center'}
                  direction={'row'}
                  xs={12}
                >
                  <Grid
                    xs={4}
                    display={'flex'}
                    direction={'column'}
                    alignItems={'flex-start'}
                  >
                    <Typography paddingLeft={1}>{`${translate(
                      'Created date'
                    )}:`}</Typography>
                    <Grid
                      item
                      xs={12}
                      display={'flex'}
                      direction={'row'}
                      width={'100%'}
                    >
                      <FilterSelectPeriod
                        onStartDateChange={(value) =>
                          onStartDateChangeCreated(value?.toJSON() ?? null)
                        }
                        onFinishDateChange={(value) =>
                          onFinishDateChangeCreated(value?.toJSON() ?? null)
                        }
                        onPeriodChange={onPeriodChangeCreated}
                        period={initialPeriodCreated.get()}
                        xsDateBetween={6}
                        hidePeriod={true}
                        disableFuture={false}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    xs={4}
                    display={'flex'}
                    direction={'column'}
                    alignItems={'flex-start'}
                  >
                    <Typography paddingLeft={1}>{`${translate(
                      'Reviewed in'
                    )}:`}</Typography>
                    <Grid
                      item
                      xs={12}
                      display={'flex'}
                      direction={'row'}
                      width={'100%'}
                    >
                      <FilterSelectPeriod
                        onStartDateChange={(value) =>
                          onStartDateChangeValidated(value?.toJSON() ?? null)
                        }
                        onFinishDateChange={(value) =>
                          onFinishDateChangeValidated(value?.toJSON() ?? null)
                        }
                        onPeriodChange={onPeriodChangeValidated}
                        period={initialPeriodValidated.get()}
                        xsDateBetween={6}
                        hidePeriod={true}
                        disableFuture={false}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    xs={4}
                    display={'flex'}
                    direction={'column'}
                    alignItems={'flex-start'}
                  >
                    <Typography paddingLeft={1}>
                      {translate('Expired in')}
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      display={'flex'}
                      direction={'row'}
                      width={'100%'}
                    >
                      <FilterSelectPeriod
                        onStartDateChange={(value) =>
                          onStartDateChangeExpired(value?.toJSON() ?? null)
                        }
                        onFinishDateChange={(value) =>
                          onFinishDateChangeExpired(value?.toJSON() ?? null)
                        }
                        onPeriodChange={onPeriodChangeExpired}
                        period={initialPeriodExpired.get()}
                        xsDateBetween={6}
                        hidePeriod={true}
                        disableFuture={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 2,
                  pt: 2,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    name.set('')
                    notes.set('')
                    areaIds.set([])
                    typeInterestPointId.set(null)
                    subtypeInterestPointId.set(null)
                    selectIsValidated.set(null)
                    initialPeriodCreated.set('')
                    initialPeriodExpired.set('')
                    initialPeriodValidated.set('')
                  }}
                  sx={{ height: '40px' }}
                >
                  {translate('Clear Filters')}
                </Button>
                <ExportButtonCSV
                  isLoading={isLoading.get()}
                  dataToExport={() => generateReport(true)}
                />
                <GenerateReportButton
                  isLoading={isLoading.get()}
                  makeReport={generateReport}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary>
  )
}
