/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';
import { TextField, FormControl, Grid, IconButton } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useDispatchRegistrationVehicleState from '../../../stores/DispatchRegistrationVehicleState';
import useDispatchRegistrationState from '../../../stores/DispatchRegistrationState';
import { maskCPF, maskNames, maskPhoneNumber, maskPlate, validate } from '~/features/Dispatch/utils/stringsManipulations';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import Buttons from '../../DispatchRegistrationTab/components/Buttons';
import SearchIcon from '@mui/icons-material/Search';
import useSystemState from '~/features/System/stores/SystemState';
import { useSearchVehicle } from '~/features/Vehicle/components/SearchVehicle';
import notify from '~/utils/notify';
import FieldAutocompleteRegistration from '~/components/FieldAutocompleteRegistration';
import { State, useHookstate } from '@hookstate/core';
import { validPlate } from '~/utils/validators';
import { searchVehicleService } from '~/features/Vehicle/services';

interface IFormVehicle {
  readonly handleClickOpenConfirmAction: (action: string) => void
}

interface IErrosProps {
  readonly plate: boolean;
  readonly type: boolean;
  readonly cpf: boolean;
  readonly phone: boolean;
  readonly circunstance: boolean;
  readonly destination: boolean;
}

export default function VehicleForm({ handleClickOpenConfirmAction }: IFormVehicle) {
  const loading = useHookstate<boolean>(false)
  const { dispatch } = useDispatchState();
  const { occurrence, status } = dispatch();
  const { id: occurrenceIdState } = occurrence;

  const disableFields = status.value.id === 8

  const { sendVehicle, editVehicle, registrationVehicleDispatch, getVehicleModels, getVehicleBrands, getVehicleColors, getVehicleTypes } = useDispatchRegistrationVehicleState();
  const { registrationDispatch, getCircunstances, getDestinations, getStates } = useDispatchRegistrationState();
  const { addRegistration, } = registrationDispatch();
  const { vehicle } = registrationVehicleDispatch();
  const plateExternalSearch = useSearchVehicle().plate();
  const resetSearchVehicle = useHookstate(false)

  const { windowManager } = useSystemState();

  const { translate } = useLang();
  const [error, setError] = useState<IErrosProps>({
    plate: false,
    type: false,
    cpf: false,
    phone: false,
    circunstance: false,
    destination: false,
  });


  const someInvalidInput = () => {
    if (!vehicle.get().vehicleType) {
      setError({
        ...error,
        type: true
      })
      return true;
    }

    if (!vehicle.get().destination) {
      setError({
        ...error,
        destination: true
      })
      return true;
    }
    if (!vehicle.get().circunstance) {
      setError({
        ...error,
        circunstance: true
      })
      return true;
    }
    return false;
  }

  const handleSend = useCallback(async () => {
    loading.set(true)
    await sendVehicle(vehicle.get()).finally(() => {
      loading.set(false)
    })
    notify({ message: translate('Successfully created'), type: 'success' })
    addRegistration.set(false)
  }, [addRegistration, sendVehicle, translate, vehicle])

  const handleEdit = useCallback(async () => {
    loading.set(true)
    await editVehicle(vehicle.get()).finally(() => {
      loading.set(false)
    })
    notify({ message: translate('Saved successfully'), type: 'success' })
    addRegistration.set(false)
  }, [addRegistration, editVehicle, translate, vehicle])

  useEffect(() => {
    vehicle.set(prev => ({ ...prev, occurrenceId: occurrenceIdState.get() }))
  }, [])

  useEffect(() => {
    if(validPlate(vehicle.plate.get() ?? '')) {
      searchVehicleService({ plate: vehicle.plate.get(), page: 0, limit: 10 }).then(({ data }) => {
        if(data.length > 0){
          windowManager().searchVehicle.open.set(true)
          windowManager().searchVehicle.dataSearchVehicle.set(data)
          plateExternalSearch.set(vehicle.plate.get() ?? '')
        }else{
          windowManager().searchVehicle.open.set(false)
          windowManager().searchVehicle.dataSearchVehicle.set([])

        }
      })
    }else{
      windowManager().searchVehicle.open.set(false)
      windowManager().searchVehicle.dataSearchVehicle.set([])
    }

  }, [vehicle.get().plate, resetSearchVehicle.get()])

  return (
    <FormControl fullWidth size='small' >
      <Grid container direction='row'>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            error={!!error.plate}
            onFocus={() => setError({ ...error, plate: false })}
            fullWidth
            size='small'
            id="outlined-plate"
            label={translate('Plate')}
            value={vehicle.get().plate}
            InputProps={{
              onChange:
                (event) => {
                  const plate = maskPlate(event.target.value)
                  vehicle.set(prev => ({
                    ...prev,
                    plate,
                  }))
                  if (plate.length > 0 && plate.length < 8) {
                    setError({
                      ...error,
                      plate: true
                    })
                  } else {
                    setError({ ...error, plate: false })
                  }
                },
              endAdornment: (
                <IconButton disabled={disableFields} onClick={() => {
                  resetSearchVehicle.set(!resetSearchVehicle.get())
                }}>
                  <SearchIcon />
                </IconButton>
              )
            }}
            onKeyDown={(event) => {
              if (event.code === "NumpadEnter" || event.code === "Enter") {
                resetSearchVehicle.set(!resetSearchVehicle.get())
              }
            }}
          />

        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={vehicle.vehicleType.get()?.id as string}
            onError={(message) => setError({ ...error, type: message })}
            isRequired
            error={error.type}
            label={translate('Vehicle Type')}
            onValueChange={(value) => {
              vehicle.set(prev => ({ ...prev, vehicleType: value }))
            }}
            optionsFetch={getVehicleTypes}
            disabled={disableFields}
          />
        </Grid>

        <Grid item xs={2} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={vehicle.vehicleBrand.get()?.id as string}
            label={translate('Brands')}
            onValueChange={async (brand) => {
              vehicle.set(prev => ({
                ...prev,
                vehicleBrand: brand,
                vehicleModel: null,
              }))
            }}
            optionsFetch={getVehicleBrands}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={2} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={vehicle.vehicleModel.get()?.id as string}
            depends={vehicle.vehicleBrand.get()?.id}
            label={translate('Model')}
            onValueChange={(model) => {
              if (!model) {
                return
              }
              const brand = vehicle.get().vehicleBrand?.id ?? ''
              const vehicleModel = { ...model, brand_id: brand } ?? null
              vehicle.set(prev => ({
                ...prev,
                vehicleModel: vehicleModel
              }))
            }}
            optionsFetch={getVehicleModels}
            disabled={disableFields}
          />
        </Grid>

        <Grid item xs={2} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={vehicle.vehicleColor.get()?.id as string}
            label={translate('Color')}
            onValueChange={(color) => {
              vehicle.set(prev => ({ ...prev, vehicleColor: color }))
            }}
            optionsFetch={getVehicleColors}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={5} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-dono"
            label={translate('Owner')}
            value={vehicle.get().owner}
            InputProps={{
              onChange: (event) => vehicle.set(prev => ({
                ...prev,
                owner: maskNames(event.target.value)
              })),
            }}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            error={!!error.cpf}
            size='small'
            id="outlined-dono"
            label={translate('CPF')}
            value={vehicle.get().ownerCpf}
            InputProps={{
              onChange: (event) => {
                const cpf = maskCPF(event.target.value)
                vehicle.set(prev => ({
                  ...prev,
                  ownerCpf: cpf
                }))

                if (validate(cpf) || !cpf.length) {
                  setError({ ...error, cpf: false })
                } else {
                  setError({
                    ...error,
                    cpf: true
                  })
                }
              },
            }}
          />
        </Grid>
        <Grid item xs={2} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-phone-number"
            label={translate('Phone Number')}
            value={vehicle.get().ownerPhone}
            InputProps={{
              onChange:
                (event) => {
                  const phoneNumber = maskPhoneNumber(event.target.value)
                  vehicle.set(prev => ({
                    ...prev,
                    ownerPhone: phoneNumber
                  }))
                  if (phoneNumber.length > 0 && phoneNumber.length < 11) {
                    setError({
                      ...error,
                      phone: true
                    })
                  } else {
                    setError({
                      ...error,
                      phone: false
                    })
                  }
                },
            }}
          />
        </Grid>
        <Grid item xs={2} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={vehicle.state.get()?.id as string}
            label={translate('State')}
            onValueChange={(state) => {
              if (!state) {
                return
              }
              const vehicleUf = state.uf ?? ''
              vehicle.set(prev => ({
                ...prev,
                state: {
                  ...state,
                  uf: vehicleUf
                },
              }))
            }}
            optionsFetch={getStates}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={vehicle.destination.get()?.id as string}
            isRequired
            onError={(message) => setError({ ...error, destination: message })}
            error={error.destination}
            label={translate('Destination')}
            onValueChange={(destination) => {
              vehicle.set(prev => ({ ...prev, destination }))
            }}
            optionsFetch={getDestinations}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={vehicle.circunstance.get()?.id as string}
            isRequired
            onError={(message) => setError({ ...error, circunstance: message })}
            error={error.circunstance}
            label={translate('Circunstance')}
            onValueChange={(circunstance) => {
              vehicle.set(prev => ({ ...prev, circunstance }))
            }}
            optionsFetch={getCircunstances}
            disabled={disableFields}
          />
        </Grid>

        <Grid item xs={6} sx={{ padding: 1, marginBottom: 1 }}>
          <TextField
            disabled={disableFields}
            id="outlined-multiline-static"
            label={translate('Note')}
            multiline
            fullWidth
            size='small'
            rows={4}
            value={vehicle.get().notes}
            InputProps={{
              onChange: (event) => {
                const notes = event.target.value
                vehicle.set(prev => ({
                  ...prev, notes,
                }))
              }
            }}
            sx={{ height: 92 }}
          />
        </Grid>
      </Grid >
      <Grid item xs={12}>
        <Buttons
          loading={loading}
          isUpdate={!!vehicle.id.get()}
          handleClickOpenConfirmAction={handleClickOpenConfirmAction}
          handleEdit={handleEdit}
          handleSend={handleSend}
          someInvalidInput={someInvalidInput}
          disableFields={disableFields}
        />
      </Grid>
    </FormControl>
  );
}