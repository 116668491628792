/* eslint-disable max-lines-per-function */
import React from 'react';
import { IDespacthStatisticGraph } from '~/features/Dispatch/interfaces/IDispacthStatistic';
import { authFetch } from '~/services/fetch';

export async function getDispatchByType({ 
  agencyId, 
  operationId, 
  deviceId, 
  dispatchGroupIds, 
  categoryIds, 
  typeIds, 
  subtypeIds, 
  startDate, 
  finishDate, 
  payload,
  consummated,
  statusIds,
  cityIds,
  groupSetId,
  location,
  closedStatusClassId,
  closedStatusId,
  qualifierOptions,
  operationUnitId
}: IDespacthStatisticGraph) {
  try {
    const { data } = await authFetch({
      url: '/statistics/dispatch-by-type',
      method: 'POST',
      data: {
        agencyId,
        deviceId,
        dispatchGroupIds,
        categoryIds,
        typeIds,
        subtypeIds,
        startDate,
        finishDate,
        excludeClosed: payload.exludeClosed,
        withSolvedThirdParty: payload.withSolvedThirdParty,
        operationId: operationId,
        consummated: consummated,
        statusIds: statusIds,
        cityIds: cityIds,
        groupSetId: groupSetId,
        location: location,
        closedStatusClassId: closedStatusClassId,
        closedStatusId: closedStatusId,
        qualifierOptions: qualifierOptions,
        operationUnitId: operationUnitId
      }
    })

    return data;
  } catch (error) {
    throw error
  }
}

export async function getDispatchBySubtype({ 
  agencyId, 
  deviceId, 
  operationId, 
  dispatchGroupId, 
  categoryIds, 
  typeIds, 
  subtypeIds, 
  startDate, 
  endDate, 
  payload,
  consummated,
  statusIds,
  cityIds,
  groupSetId,
  location,
  closedStatusClassId,
  closedStatusId,
  qualifierOptions,
  operationUnitId
}) {
  try {
    const { data } = await authFetch({
      url: '/statistics/dispatch-by-type-subtype',
      method: 'POST',
      data: {
        agencyId: agencyId,
        deviceId: deviceId,
        dispatchGroupIds: dispatchGroupId,
        categoryIds: categoryIds,
        typeIds: typeIds,
        subtypeIds: subtypeIds,
        startDate: startDate,
        finishDate: endDate,
        excludeClosed: payload.exludeClosed,
        withSolvedThirdParty: payload.withSolvedThirdParty,
        operationId: operationId,
        consummated: consummated,
        statusIds: statusIds,
        cityIds: cityIds,
        groupSetId: groupSetId,
        location: location,
        closedStatusClassId: closedStatusClassId,
        closedStatusId: closedStatusId,
        qualifierOptions: qualifierOptions,
        operationUnitId: operationUnitId,
      }
    })

    return data;
  } catch (error) {
    throw error
  }
}