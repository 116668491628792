import { useHookstate } from '@hookstate/core'
import React, { useEffect, useMemo } from 'react'
import CrudIndex from '~/components/CrudIndex'
import { ActionButtonsProps } from '~/components/CrudIndex/interfaces/ICrudIndex'
import Layout from '~/components/Layout'
import { useLang } from '~/hooks/useLang'
import { authFetch } from '~/services/fetch'
import notify from '~/utils/notify'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined'
import ConfirmDialog from '~/components/ConfirmDialog'

export default function Index() {
  const { translate } = useLang()

  const openDialogConfirmDisabled = useHookstate(false)
  const typeName = useHookstate('')
  const typeActionId = useHookstate<number | null>(null)
  const loading = useHookstate<boolean>(false)
  const isEnabled = useHookstate(false)
  const forceRefresh = useHookstate(false)

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])

  const disabledType = (typeId) => {
    loading.set(true)
    authFetch({
      url: `/type/${typeId}/activate-or-deactivate`,
      method: 'POST',
    })
      .then((response) => {
        if (response.data.active) {
          notify({
            message: translate('activated with success!', {
              crudName: 'Tipo',
            }),
            type: 'success',
          })
        } else {
          notify({
            message: translate('deactivated with success!', {
              crudName: 'Tipo',
            }),
            type: 'success',
          })
        }
      })
      .finally(() => {
        openDialogConfirmDisabled.set(false)
        loading.set(false)
        forceRefresh.set(!forceRefresh.get())
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleDialogConfirmDisabled = () => {
    openDialogConfirmDisabled.set(false)
  }

  const handleDialogConfirmOpenDisabled = (typeId) => {
    openDialogConfirmDisabled.set(true)
    typeActionId.set(typeId)
  }

  const extraTypeActions = useMemo((): ActionButtonsProps[] => {
    return [
      {
        iconButton: (params) =>
          Boolean(params['active'] === 'Sim') ? (
            <ToggleOnIcon />
          ) : (
            <ToggleOffOutlinedIcon />
          ),
        label: (params) =>
          Boolean(params['active'] === 'Sim')
        ? translate('Deactivate', {crudName: 'tipo'})
        : translate('To active', {crudName: 'tipo'}),
        action: (params) => {
          handleDialogConfirmOpenDisabled(params.id)

          Boolean(params['active'] === 'Sim')
            ? isEnabled.set(true)
            : isEnabled.set(false)
          typeName.set(params['name'])
        },
        showInMenu: function () {
          return false
        },
      },
    ]
  }, [])

  return (
    <Layout marginLayout={true}>
      <CrudIndex
        model="type"
        title="Types"
        aliasLabel={[
          { column: 'must_inform_consummate', label: 'Must Inform Consummate' },
        ]}
        booleanField={['must_inform_consummate', 'ref_to_vehicle', 'active']}
        forceOrderColumns={[
          'name',
          'code',
          'priority',
          'ref_to_vehicle',
          'must_inform_consummate',
          'mandatory_vehicle',
          'mandatory_involved',
          'mandatory_weapon',
          'mandatory_organization',
          'mandatory_drug',
          'mandatory_object',
          'mandatory_animal',
          'active',
        ]}
        extraActions={extraTypeActions}
        forceRefresh={forceRefresh.get()}
        deleteable={false}
      />
      <ConfirmDialog
        handleAgree={() => disabledType(typeActionId.get())}
        handleClose={() => handleDialogConfirmDisabled()}
        open={openDialogConfirmDisabled}
        title={translate('Confirmation')}
        content={
          isEnabled.get()
            ? translate('Confirm the deactivation of the?', {
                crudName: 'tipo',
                name: typeName.get(),
              })
            : translate('Confirm the activation?', {
                crudName: 'tipo',
                name: typeName.get(),
              })
        }
        loading={loading}
      />
    </Layout>
  )
}
