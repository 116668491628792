

import { t as translate } from 'i18next';
import * as React from 'react';
import SelectAutoComplete from '~/components/SelectAutoComplete';
import { getTypes, getTypeById } from '~/features/Type/services/index';
import IType from '~/features/Type/interfaces/IType';
import { Validation as validation } from '@hookstate/validation';
import { State } from '@hookstate/core';
import FilterAutocompleteType from '~/components/FilterAutocompleteType';
import useEntryState from '../../stores/EntryState';
import { Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';

interface TypeSelectFieldProps {
  readonly readonly?:boolean;
  readonly isValid: boolean;
  readonly isInvalidRequest?: boolean;
  readonly typeId: State<number | null>;
  readonly typeName: State<string>;
  readonly showVehicleButton?: State<boolean> | null;
  readonly isActive?: boolean;
  readonly showVehicleModal?: boolean;
}

export default function TypeSelectField({readonly=false, isValid = false, isInvalidRequest = false, typeId, typeName, showVehicleButton = null, isActive, showVehicleModal=true }:TypeSelectFieldProps ) {
  const { translate } = useLang();
  const { windowManager } = useSystemState();
  
  function onChangeType(type: IType | null) {
    if (type) {
      typeId.set(Number(type?.id) ?? null);
      typeName.set(type.name ?? '');

      if(showVehicleButton != null){
        showVehicleButton.set(Boolean(type?.ref_to_vehicle))
      }

      if(Boolean(type?.ref_to_vehicle) && showVehicleModal){
        windowManager()['VehicleModal'].open.set(true)
      } else {
        windowManager()['VehicleModal'].open.set(false)
      }
      
    } else {
      typeId.set(null);
      typeName.set('');
      windowManager()['VehicleModal'].open.set(false)

      if(showVehicleButton){
        showVehicleButton.set(false);
      }
    }
  }

  if (!readonly) {
    return(
      <FilterAutocompleteType 
        typeId={typeId.get()} 
        onTypeChange={onChangeType}
        disabled={!isValid}
        error={isInvalidRequest && validation(typeId).invalid()}
        isActive={isActive}
      />
    );
  } else {
    return (
      <Typography>{translate('Type')}: {typeName.get() ?? translate('No information')}</Typography>
    )
  }
}