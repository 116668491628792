import { State, StateMethods, useHookstate } from "@hookstate/core";
import { Autocomplete, FormControl, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react"
import IDistrict from "~/features/District/interfaces/IDistrict";
import { getDistrictById, getDistricts } from "~/features/District/services";
import { useLang } from "~/hooks/useLang";
import { Validation as validation } from '@hookstate/validation';

interface SelectDistrictProps {
  readonly onDistrictChange: (value: any | null) => void;
  readonly districtId: StateMethods<number|null>;
  readonly disabled?: boolean;
  readonly readonly?: boolean;
  readonly cityId: number | null;
  readonly getRowsCount?: (value: number) => number;
  readonly isInvalidRequest?: boolean;
}

export default function FilterAutocompleteDistrict({ onDistrictChange, readonly = false, districtId, disabled, cityId, getRowsCount, isInvalidRequest = false }: SelectDistrictProps) {
  const { translate } = useLang();
  const [options, setOptions] = React.useState<readonly IDistrict[]>([])
  const [value, setValue] = useState<IDistrict | null>(null);
  const loading = useHookstate(false);
  const isFirstRender = React.useRef(true);

  const enabledField = cityId === null;

  const fecthDistrictByCity = (cityId) => {
    const response = getDistricts({
      filters: [
        { field: 'city.id', operator: 'equals', value: cityId }
      ],
    });
    return response;
  }

  useEffect(() => {
    setValue(null)
    if (cityId) {
      fecthDistrictByCity(cityId).then((response) => {
        setOptions(response)
        if (getRowsCount) {
          getRowsCount(response.length ?? 0);
      }

      })
    }
  }, [cityId])

  useEffect(() => {
    if (value) {
      if (districtId.get() != (value ? value['id'] : null)) {
        isFirstRender.current = true;
        setValue(null);
      }
    }

    if (isFirstRender.current) {
      if (districtId.get() != null) {
        getDistrictById(districtId.get())
          .then((result) => {
            setValue(result);
          })
        isFirstRender.current = false;
      }
    }
  }, [districtId.get()]);

  if (!readonly) {
    return (
      <FormControl fullWidth size='small'>
        <Autocomplete
          id='filter-district'
          options={options}
          disabled={enabledField}
          value={value}
          noOptionsText={translate('No options')}
          size='small'
          loading={loading.get()}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => option.name ?? ''}
          onChange={(_, district) => {
            setValue(district ?? null)
            onDistrictChange(district?.id)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={translate('District')}
              error={!enabledField && isInvalidRequest && validation(districtId).invalid()}
            />
          )}
        />
      </FormControl>
    )
  } else {
    return (
      <Typography>
        {translate('District')}: {value?.name ?? translate('No information')}
      </Typography>
    )
  }
}