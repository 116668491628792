import React, { ReactNode, useMemo } from 'react'
import { ThemeProvider } from '@emotion/react'
import 'leaflet/dist/leaflet.css'
import { createTheme, CssBaseline, Grid, PaletteMode } from '@mui/material'
import { useTheme } from '../../hooks/useTheme'
import { green } from '@mui/material/colors'
import useSystemState from '~/features/System/stores/SystemState'
import { useState } from '@hookstate/core'

type ThemeProviderProps = {
  readonly children: ReactNode
}

declare module '@mui/material/styles' {
  interface Palette {
    readonly status: {
      readonly online: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym?: string | null
      }
      readonly stopService: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym?: string | null
      }
      readonly offline: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym?: string | null
      }
      readonly open: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym?: string | null
      }
      readonly communicated: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym?: string | null
      }
      readonly received: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym?: string | null
      }
      readonly onRoute: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym?: string | null
      }
      readonly arrivalOnSite: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym?: string | null
      }
      readonly onCarriage: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym?: string | null
      }
      readonly arrivalInTransport: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym?: string | null
      }
      readonly closed: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym?: string | null
      }
      readonly hold: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym?: string | null
      }
      readonly unified: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym?: string | null
      }
      readonly tlia: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym?: string | null
      }
      readonly total: {
        readonly main: string
        readonly light: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym?: string | null
      }
    }
    readonly priority: {
      readonly low: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly medium: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly high: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
    }
    readonly vehicleLastUpdate: {
      readonly online: {
        readonly light: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym: string | null
      }
      readonly recent: {
        readonly light: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly offline: {
        readonly light: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym: string | null
      }
    }
    readonly entryStatus: {
      readonly prankcall: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly reCall: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly thank: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly transfer: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly abandonment: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly wrongCall: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly information: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
    }
  }
  interface PaletteOptions {
    readonly status: {
      readonly online: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly stopService: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly offline: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly open: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly communicated: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly received: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly onRoute: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly arrivalOnSite: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly onCarriage: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly arrivalInTransport: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly closed: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly hold: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly unified: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly tlia: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
    }
    readonly priority: {
      readonly low: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly medium: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly high: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
    }
    readonly vehicleLastUpdate: {
      readonly online: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym: string | null
      }
      readonly recent: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly offline: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
        readonly acronym: string | null
      }
    }
    readonly entryStatus: {
      readonly prankcall: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly reCall: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly thank: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly transfer: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly abandonment: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly wrongCall: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
      readonly information: {
        readonly main: string
        readonly dark: string
        readonly contrastText: string
      }
    }
  }
}

const colorsDefault = {
  primary: {
    light: '#e3f2fd',
    main: '#038C7F',
    dark: '#027368',
  },
  secondary: {
    light: '#F4AB6C',
    main: '#F28322',
    dark: '#733E10',
  },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
  },
  warning: {
    light: '#ffb74d',
    main: '#ffa726',
    dark: '#f57c00',
  },
  info: {
    light: '#4fc3f7',
    main: '#29b6f6',
    dark: '#0288d1',
  },
  success: {
    light: '#81c784',
    main: '#66bb6a',
    dark: '#388e3c',
  },
  status: {
    online: {
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: '#FFF',
      acronym: 'DI',
    },
    stopService: {
      main: '#795548',
      dark: '#5d4037',
      contrastText: '#FFF',
      acronym: 'FS',
    },
    offline: {
      main: '#9e9e9e',
      dark: '#616161',
      contrastText: '#FFF',
      acronym: 'DC',
    },
    open: {
      main: '#F51800',
      dark: '#4D0800',
      contrastText: '#FFF',
      acronym: 'AB',
    },
    communicated: {
      main: ' #E68900',
      dark: '#4D2E00',
      contrastText: '#FFF',
      acronym: 'CO',
    },
    received: {
      main: '#F07674',
      dark: '#4D2525',
      contrastText: '#FFF',
      acronym: 'RB',
    },
    onRoute: {
      main: '#f1c40f',
      dark: '#f9c40f',
      contrastText: '#000',
      acronym: 'RO',
    },
    arrivalOnSite: {
      main: '#673ab7',
      dark: '#512da8',
      contrastText: '#FFF',
      acronym: 'CH',
    },
    onCarriage: {
      main: '#0097a7',
      dark: '#006064',
      contrastText: '#FFF',
      acronym: 'TR',
    },
    arrivalInTransport: {
      main: '#00796b',
      dark: '#004d40',
      contrastText: '#FFF',
      acronym: 'CT',
    },
    closed: {
      main: '#9e9e9e',
      dark: '#616161',
      contrastText: '#FFF',
      acronym: 'FC',
    },
    hold: {
      main: '#7E1900',
      dark: '#3D0C00',
      contrastText: '#FFF',
      acronym: 'RT',
    },
    unified: {
      main: '#658569',
      dark: '#658500',
      contrastText: '#FFF',
      acronym: 'UN',
    },
    tlia: {
      main: '#795548',
      dark: '#5d4037',
      contrastText: '#FFF',
    },
    total: {
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: '#FFF',
    },
  },
  priority: {
    low: {
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: '#FFF',
    },
    medium: {
      main: '#ffc107',
      dark: '#ffa000',
      contrastText: '#000',
    },
    high: {
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#FFF',
    },
  },
  vehicleLastUpdate: {
    online: {
      main: green[500],
      dark: green[700],
      contrastText: '#FFF',
      acronym: 'DI',
    },
    recent: {
      main: '#ffc107',
      dark: '#ffa000',
      contrastText: '#000',
    },
    offline: {
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#FFF',
      acronym: 'DC',
    },
  },
  entryStatus: {
    prankcall: {
      main: '#7E1900',
      dark: '#3D0C00',
      contrastText: '#FFF',
    },
    reCall: {
      main: '#ff5722',
      dark: '#e64a19',
      contrastText: '#FFF',
    },
    thank: {
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: '#FFF',
    },
    transfer: {
      main: '#ffc107',
      dark: '#ffa000',
      contrastText: '#000',
    },
    abandonment: {
      main: '#9e9e9e',
      dark: '#616161',
      contrastText: '#FFF',
    },
    wrongCall: {
      main: '#673ab7',
      dark: '#512da8',
      contrastText: '#FFF',
    },
    information: {
      main: '#00796b',
      dark: '#004d40',
      contrastText: '#FFF',
    },
  },
}

export const configLeafletTheme = {
  bgBox: {
    light: '#f5f5f5',
    main: '#FFF',
    dark: '#272727',
  },
}
export const configTheme = (mode: PaletteMode, fontSize: number) =>
  createTheme({
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            ...colorsDefault,
            action: {
              active: '#292929',
              disabled: '#9E9E9E',
            },
            background: {
              default: '#f8f9fa',
            },
          }
        : {
            ...colorsDefault,
          }),
    },
    typography: {
      fontSize: fontSize,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: mode === 'light' ? 'rgba(33,33,33,255)' : 'rgba(255, 255, 255, 0.64)',
            ':hover': {
              backgroundColor:
                mode === 'light' ? '#038C7F25' : 'rgba(255, 255, 255, 0.08)',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&.Mui-disabled': {
              WebkitTextFillColor:
                mode == 'light'
                  ? 'rgba(0, 0, 0, 0.500)'
                  : 'rgba(255, 255, 255, 0.5)',
              
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          input: {
            '&.Mui-disabled': {
              WebkitTextFillColor:
                mode == 'light'
                  ? 'rgba(0, 0, 0, 0.500)'
                  : 'rgba(255, 255, 255, 0.5)',
              
            },
            color: mode === 'light' ? 'rgba(0,0,0)' : 'rgba(255, 255, 255, 0.64)',
          },
        },
      },
    },
  })

export function Theme({ children, ...props }: ThemeProviderProps) {
  const { darkMode } = useTheme()
  const { systemConfig } = useSystemState()
  const systemConfigState = useState(systemConfig())

  const theme = useMemo(() => {
    return darkMode
      ? configTheme('dark', systemConfigState.fontSize.get())
      : configTheme('light', systemConfigState.fontSize.get())
  }, [darkMode, systemConfigState.fontSize])
  const leafletTheme = useMemo(() => {
    return `
      .leaflet-popup-content-wrapper {
          text-align: center !important; 
      }
      .leaflet-popup-content-wrapper, .leaflet-popup-tip  {
          background: transparent !important;
          background-color: ${theme.palette.background['default']} !important;
      }
      .leaflet-top, .leaflet-left, .leaflet-right, leaflet-bottom {
        z-index: 619 !important;
      }
      .leaflet-control-zoom .leaflet-control-zoom-in, .leaflet-control-zoom-out{
        background-color: ${theme.palette.background['default']} !important;
        opacity: 0.6;
      }
      .leaflet-bar a {
        color: ${theme.palette.text.primary};
      }
      .leaflet-popup-content {
        margin: 12px 20px;
        display:inline-block !important;
        width: unset !important;
        min-width: 250px !important;
        max-width: 450px !important;
      }
      .leaflet-bar a.leaflet-disabled {
        color: #c6c6c6 !important;
        opacity: 0.4;
      }
      `
  }, [theme.palette.background, theme.palette.text.primary])

  return (
    <ThemeProvider theme={theme}>
      <style>{leafletTheme}</style>
      <Grid container component="main" xs={12}>
        <CssBaseline />
        {children}
      </Grid>
    </ThemeProvider>
  )
}
