import React from 'react';
import { Fab, SxProps, Theme, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


interface IButtonPatrolmans {
  readonly handleOnClick: () => void;
  readonly isAdd?: boolean
  readonly disabled?: boolean
  readonly sxProps?: SxProps<Theme>;
  readonly tooltipName: string;
}

export default function ButtonPatrolmans({ tooltipName, sxProps, isAdd = false, disabled = false, handleOnClick }: IButtonPatrolmans) {
  return (
    <Tooltip title={tooltipName}>

      <Fab
        size="small"
        onClick={handleOnClick}
        disabled={disabled}
        sx={sxProps}
      >
        {isAdd ? <AddIcon sx={{ fontSize: 30 }} /> : <RemoveIcon sx={{ fontSize: 30 }} />}
      </Fab>
    </Tooltip>
  )
}