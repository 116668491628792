import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IType from '../interfaces/IType';


export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue, getValues} = useForm<IType>();
  const fields = [
      { name: 'id', type: 'hidden'},
      { name: 'name', type: 'string'},
      { name: 'code', type: 'string' },
      { name: 'priority', type: 'select', options: [1, 2, 3, 4, 5, 6, 7, 8, 9] },
      { name: 'mandatory_vehicle', type: 'number' }, 
      { name: 'mandatory_involved', type: 'number' }, 
      { name: 'mandatory_weapon', type: 'number' }, 
      { name: 'mandatory_organization', type: 'number' }, 
      { name: 'mandatory_drug', type: 'number' }, 
      { name: 'mandatory_object', type: 'number' }, 
      { name: 'ref_to_vehicle', type: 'switch'}, 
      { name: 'must_inform_consummate', type: 'switch', placeholder: 'Must Inform Consummate' }, 
  ];
  
    return(
      <CrudEdit 
        model={'type'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        setFormValue={setFormValue}
        messageSuccess={'Type successfully updated!'}  
        title={'Edit type'}
        getValues={getValues}
      />
    )
}
