import React, { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { useHistory } from 'react-router-dom';
import { MenuProps } from '~/components/SidebarMenu/interfaces';
import { useLang } from '~/hooks/useLang';
import { Collapse, List } from '@mui/material';
import userUserState from '~/features/User/stores/UserState';

import {
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';

// eslint-disable-next-line max-lines-per-function
export default function ReportByType({ handleMenuClick }: MenuProps) {
  const { translate } = useLang();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { verifyPermission } = userUserState();


  const handleClickOpen = () => {
    setOpen((old) => !old);
  };

  return (
    <>
      <Tooltip title={translate('Generate report') + " " + translate('Occurrence')} placement="right">
        <ListItemButton onClick={handleClickOpen}>
          <ListItemText primary={translate('Occurrence')} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </Tooltip>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            verifyPermission(['reports_generator']) && (
              <>
                {verifyPermission(['report_occurrence_consultation']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Occurrence Consultation')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/occurrence-consultation");
                      }}
                    >
                      <ListItemText
                        primary={translate('Occurrence Consultation')} />
                    </ListItemButton>
                  </Tooltip>
                )}

                {verifyPermission(['report_dispatch_timeline']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Occurrence by chronology')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/chronology-occurrence");
                      }}
                    >
                      <ListItemText
                        primary={translate('Occurrence by chronology')} />
                    </ListItemButton>
                  </Tooltip>
                )}

                {verifyPermission(['report_occurrence_by_type']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Occurrences by Type')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/occurrence-by-type");
                      }}
                    >
                      <ListItemText
                        primary={translate('Occurrences by Type')} />
                    </ListItemButton>
                  </Tooltip>
                )}

                {verifyPermission(['report_dispatches_by_device']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Occurrences by device')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/employment-vehicles-occurrences");
                      }}
                    >
                      <ListItemText
                        primary={translate('Occurrences by device')} />
                    </ListItemButton>
                  </Tooltip>
                )}

                {verifyPermission(['report_detailed_occurrences']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Occurrences by dispatch group and type')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/types-occurrences-detailed");
                      }}
                    >
                      <ListItemText
                        primary={translate('Occurrences by dispatch group and type')} />
                    </ListItemButton>
                  </Tooltip>
                )}
                {verifyPermission(['report_occurrences_handled_by_group']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Occurrences Handled by Dispatch Group')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/occurrences-handled-by-group");
                      }}
                    >
                      <ListItemText
                        primary={translate('Occurrences Handled by Dispatch Group')} />
                    </ListItemButton>
                  </Tooltip>
                )}

                {verifyPermission(['report_dispatches_status_by_date']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Dispatches status by date')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/dispatches-status-by-date");
                      }}
                    >
                      <ListItemText
                        primary={translate('Dispatches status by date')} />
                    </ListItemButton>
                  </Tooltip>
                )}

                {verifyPermission(['report_search_comments']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Search multiple coments')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/search-multiple-coments");
                      }}
                    >
                      <ListItemText
                        primary={translate('Search multiple coments')} />
                    </ListItemButton>
                  </Tooltip>
                )}

                {verifyPermission(['report_dispatch_by_closed_status']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Total occurrences by closing status')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/dispatch-by-closed-status");
                      }}
                    >
                      <ListItemText
                        primary={translate('Total occurrences by closing status')} />
                    </ListItemButton>
                  </Tooltip>
                )}
                
              </>
            )
          }
        </List>
      </Collapse>
    </>
  )
}