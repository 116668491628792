import React from "react";
import { IconButton, Menu, ListItem, ListItemButton, ListItemText, Box, Tooltip } from '@mui/material';
import { useHookstate,Downgraded } from "@hookstate/core";
import { WhereToVoteRounded } from '@mui/icons-material';
import useMapState from "~/features/Map/stores/MapState";
import { useLang } from "~/hooks/useLang";
import { storeUserViewPort, getMapViewPort } from "~/features/Map/services/request";
import notify from "~/utils/notify";
import useUserState from "~/features/User/stores/UserState";
import { IUserViewPort } from '~/features/User/interfaces/IUserViewPort';


export default function MapViewportButton() {
    const { translate } = useLang();
    const { user, setUserCookie } = useUserState();
    const isAwaitingSave = useHookstate(false);
    const isNewLocate = useHookstate(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { map } = useMapState();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const saveFavoriteCurrentView = function(label = 'FavoriteViewPort'):Promise<boolean> {
        return new Promise((resolve,reject) => {
            const currentBounds = map().getBounds();
            const currentZoom = map().getZoom();
    
            const center = currentBounds.getCenter();
            isAwaitingSave.set(true);

            storeUserViewPort({
                latitude: center.lat,
                longitude: center.lng,
                favorite: true,
                zoom: currentZoom,
                label,
            }).then((response: IUserViewPort) => {
                let userModifier = user().attach(Downgraded).get()
                userModifier.map_viewports = [response];
                setUserCookie(userModifier);
                isNewLocate.set(true);

                notify({
                    message: `${translate('Successfully saved viewport')} !`,
                    type: 'success'
                });
                resolve(true);
            }).catch((err) => reject(err))
              .finally(() => isAwaitingSave.set(false) );
        })
    };

    function focusOnUserFavoriteView() {
        getMapViewPort().then((response) => {
            return map().setView( [response.at(-1).latitude, response.at(-1).longitude], response.at(-1).zoom, {animate: false})
        })
    };

    return (
        <Box>
            <Tooltip title={translate('Map Viewport')} placement="bottom">
                <IconButton
                    size="large"
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : ''}
                    aria-haspopup="true"
                    aria-expanded={open}
                    onClick={handleClick}
                >
                    <WhereToVoteRounded />
                </IconButton>
            </Tooltip>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <ListItem key={'addViewPort'} disablePadding>
                    <ListItemButton key={'addViewPort'}
                        disabled={isAwaitingSave.get()}
                        onClick={() => saveFavoriteCurrentView()}
                    >
                        <ListItemText key={'addViewPort'} id={'addViewPort'} primary={translate(`Save`)} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={'LocaleViewPort'} disablePadding>
                    <ListItemButton key={'LocaleViewPort'}
                        disabled={!Boolean(user().map_viewports.get().find((element) => element.favorite)?.favorite || isNewLocate.get())}
                        onClick={() => focusOnUserFavoriteView()}
                    >
                        <ListItemText key={'LocaleViewPort'} id={'LocaleViewPort'} primary={translate(`Locate`)} />
                    </ListItemButton>
                </ListItem>

            </Menu>
        </Box>
    )
}
