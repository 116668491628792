import * as React from 'react';
import SelectAutoComplete from '~/components/SelectAutoComplete';
import { getCircunstance, getCircunstanceById } from '~/features/Circunstance/services/index';
import ICircunstance from '~/features/Circunstance/interfaces/ICircunstance';
import { useLang } from '~/hooks/useLang';

export default function CircunstanceSelectField({isValid, circunstanceId}){
  const [valueState, setValueState] = React.useState(null)
  const isFirstRender = React.useRef(true);
  const { translate } = useLang();
  
  React.useEffect(() => {
    if(valueState) {
      if(circunstanceId?.get() != (valueState ? valueState['id'] : null)) {
        isFirstRender.current = true;
        setValueState(null);
      }
    }

    if(isFirstRender.current) {
      if(circunstanceId?.get()) {
        getCircunstanceById(circunstanceId.get())
          .then((result) => {
            setValueState(result);
          })
        isFirstRender.current = false;
      }
    }
  },[circunstanceId]);
  
  return(
    <SelectAutoComplete
      fetchData={(input) => {
        return getCircunstance({
          filters: [],
        })
      }}
      placeholder={translate("Circunstance")}
      setKey={(t: ICircunstance) => t ? t.id : ''}
      setOptionSelect={(t: ICircunstance) => t ? `${t.name}` : ''}
      showOptionLabel={(t: ICircunstance) => t ? `${t.name}` : ''}
      getValue={(value: ICircunstance) => circunstanceId.set(value ? value.id : null)}
      defaultValue={valueState}
      disabled={!isValid}
      size={'small'}   
    />
  );
}