/* eslint-disable functional/prefer-readonly-type */
import React from 'react';
import {
  createState,
  none as noneValue,
  State,
  useState,
} from '@hookstate/core';
import { useAuth } from '~/features/auth/hooks/useAuth';
import { authFetch } from '~/services/fetch';
import IDispacthVehicle from '../interfaces/IDispacthVehicle';
import IVehicleList from '../interfaces/IVehicleList';
import { elapsedTime } from '~/utils/dateTime';
import { Broadcasted as broadcasted } from '@hookstate/broadcasted';

interface defaultActions {
  loading: boolean,
  retryConnect: boolean,
}

const vehicleListInDispatchState = createState<Array<IDispacthVehicle>>([])
const vehicleListState = createState<Array<IVehicleList>>([])

vehicleListInDispatchState.attach(broadcasted('dispatch-vehicle-list'))
vehicleListState.attach(broadcasted('vehicle-list'))

const defaultActions: defaultActions = {
  loading: false,
  retryConnect: false,
}

const defaultActionsDefaultState = createState<defaultActions>(Object.create(defaultActions))

const useDispatchVehicleState = () => {
  const vehicleListInDispatch = useState(vehicleListInDispatchState)
  const vehicleList = useState(vehicleListState)
  const defaultActionsState = useState(defaultActionsDefaultState)
  const loading = useState(defaultActionsState.loading)
  const retryConnect = useState(defaultActionsState.retryConnect)

  function setDefaultvehicleListInDispatch() {
    vehicleListInDispatch.set([])
  }

  return {
    vehicleListInDispatch: () => vehicleListInDispatch,
    loading: () => loading,
    retryConnect: () => retryConnect,
    syncVehicleListInDispatch: async (dispatchId: string | number | null) => {
      retryConnect.set(false)
      loading.set(true)
      setDefaultvehicleListInDispatch()
      if (!dispatchId) {
        loading.set(false)
        return false;
      } else {
        await authFetch({
          url: `/dispatch/devices-associated`,
          method: 'POST',
          data: {
            dispatch_id: dispatchId,
          },
        }).then(({ data }) => {
          if (!data) {
            vehicleListInDispatch.set([])
            return false
          }
          vehicleListInDispatch.set(data)
          return true
        }).catch(() => retryConnect.set(true)).finally(() => loading.set(false));
      }
      
    },
    deleteVehicleFromList: (dispatchDeviceId: number) => {
      const targetDeviceStateArr = vehicleListInDispatch.filter(
        (deviceState) => {
          const device = deviceState.get()

          if (!device) return false

          if (device.id === dispatchDeviceId) return true

          return false
        }
      )
      const [targetDevice] = targetDeviceStateArr
      targetDevice.set(noneValue)
    },
    vehicleList: () => vehicleList,
    syncVehicleList: async (dispatchId: string | number | null) => {
      if (!dispatchId) return
      try{
        authFetch({
          url: `/dispatch/devices`,
          method: 'POST',
          data: {
            dispatch_id: parseInt(dispatchId.toString() ?? dispatchId),
          },
        }).then(({ data }) => {
          if (!data) return false
          vehicleList.set(data)
          
          return true
        })
      } catch( error){
        throw(error)
      }
    },
    updateStatusVehicleListInDispatch: ({ dispatchDeviceId, status, dateUpdated }) => {
      vehicleListInDispatch.keys.map((key) => {
        if (vehicleListInDispatch[key].id.get() == dispatchDeviceId) {
          vehicleListInDispatch[key]['status'].set(status)
          vehicleListInDispatch[key]['status_changed_at'].set(dateUpdated);
        }
      })
    },
    setDefaultvehicleListInDispatch: setDefaultvehicleListInDispatch,
  }
}

export default useDispatchVehicleState;