import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IAreaPlan from '../interfaces/IAreaPlan';

export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue,getValues} = useForm<IAreaPlan>();
  const fields = [
      { name: 'id', type: 'hidden' },
      { name: 'name', type: 'string' },
  ];
  
    return(
      <CrudEdit model={'area-plan'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        setFormValue={setFormValue}
        messageSuccess={'Area plan successfully updated!'}  
        title={'Edit area plan'} 
        getValues={getValues}
        />
    )
}
