import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, SxProps, TextField, Theme } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import ICamera from '~/features/Cameras/interfaces/ICamera';
import { getAllCameras } from '~/features/Cameras/services';

interface FilterAutocompleteCameraProps {
    readonly onCameraChange: (value: ICamera | null) => void;
    readonly cameraId?: number | null;
    readonly disabled?: boolean
    readonly error?: boolean;
    readonly sx?: SxProps<Theme>
}

export default function FilterAutocompleteCamera({ sx, disabled, cameraId, onCameraChange, error }: FilterAutocompleteCameraProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly ICamera[]>([]);

    const [value, setValue] = useState<ICamera | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        getAllCameras().then(cameras => {
            setOptions(cameras);
        })
    }, [])

    useEffect(() => {
        if (!cameraId) {
            setValue(null);
        } else {
            const option = options.find((option) => option.id == cameraId);
            setValue(option ?? null);
        }

    }, [options, cameraId])

    return (
        <FormControl fullWidth size='small'>
            <Autocomplete
                sx={sx}
                id='filter-cameras'
                disabled={disabled}
                options={options}
                value={value}
                noOptionsText={translate('No options')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => (option.name) ?? ''}
                onChange={(_, caemra) => {
                    setValue(caemra ?? null);
                    onCameraChange(caemra ?? null);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={error ? error : false}
                        label={translate('Camera')}
                    />
                )}
            />
        </FormControl>
    )
}