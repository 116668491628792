/* eslint-disable react/react-in-jsx-scope */

import { Container, Divider, Grid, Typography } from '@mui/material'
import IReportHeader from '../../interfaces/IReportHeader'
import Logo from '~/components/Logo'
import { useLang } from '~/hooks/useLang'
import { useEffect, useState } from 'react'
import IFilters from '../../interfaces/IFIlters'
import useUserState from '~/features/User/stores/UserState'
import dayjs from 'dayjs'
import useStationState from '~/features/Station/stores/StationState'
import { maskCPF } from '~/features/Dispatch/utils/stringsManipulations'

export default function HeaderReport({
  header,
}: {
  readonly header: IReportHeader
}) {
  const { translate } = useLang()
  const { user } = useUserState()
  const { station } = useStationState()
  const cpf = maskCPF(user().cpf.get() ?? '')

  const [filters, setFilters] = useState<readonly IFilters[]>([])

  function splitFirstOccurrence(str: string, separator: string) {
    const [first, ...rest] = str.split(separator)

    const remainder = rest.join(':')

    return { first, remainder }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const filtersSplittedWithNewLineChar = header.filters.split('\n')

      filtersSplittedWithNewLineChar.map((filter) => {
        const filterKeyValue = splitFirstOccurrence(filter, ':')
        setFilters((prevState) => [
          ...prevState,
          ...[{ key: filterKeyValue.first, value: filterKeyValue.remainder }],
        ])
      })
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <Container fixed={false}>
      <Grid container columns={24} flexDirection={'row'} sx={{gap: 1}}>
        <Grid item xs={5} justifyContent={'center'} alignContent={"flex-start"} sx={{ mr: 1 }}>
          <Grid container xs={12} >
            <Grid item maxHeight={'80px'} maxWidth={'80px'} sx={{pr: 2}}>
              <Logo height='70%'/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={13} flexDirection={'column'}>

          <Typography alignSelf={'center'} variant="subtitle1" fontWeight={'bold'}>
            {translate(header.title).toUpperCase()}
          </Typography>

          {header.subtitle && (
            <Typography alignSelf={'center'} variant="subtitle2">
              {translate(header.subtitle)}
            </Typography>
          )}

          {header.filters.length > 1 ? (
            <Typography variant="body2" fontWeight={'bold'}>
              {`${translate('Filtros')}:`}{' '}
            </Typography>
          ) : (
            <Typography variant="body2" fontWeight={'bold'}>
              {`${translate('No filters selected')}:`}{' '}
            </Typography>
          )}

          {header.filters.length > 1 &&
            filters.map((filtro) => {
              return (
                <Typography key={filtro.key} variant="subtitle2">
                  {filtro.key === 'Agency'
                    ? 'Agência: '
                    : `${translate(
                        filtro.key == 'Device' ? 'Device:name' : filtro.key
                      )}: `}
                  {filtro.value}
                </Typography>
              )
            })}
        </Grid>

        <Grid item xs={5} textAlign={'right'}>
          <Typography variant="caption" gutterBottom>
            {translate('Station')}: {station().get()?.name}
          </Typography>
          <br />
          <Typography variant="caption" gutterBottom>
            {translate('Document generated in')}
          </Typography>
          <br />
          <Typography variant="caption">
            {dayjs(Date.now()).format('DD/MM/YYYY HH:mm:ss')}
          </Typography>
          <br />
          <Typography variant="caption">
            {translate('User')}: {user().name?.get()}
          </Typography>
          <br />
          <Typography variant="caption">
            {translate('CPF')}: {cpf}
          </Typography>
          <br />
        </Grid>
      </Grid>
      <Divider sx={{ marginTop: 2, borderWidth: 2 }} />
    </Container>
  )
}
