import * as React from 'react'
import { useEffect } from "react"
// import './styles.scss';

import { FileProps } from '../ImportFileField';
import { Box, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { truncateText } from "~/utils/strings";
import { useHookstate } from '@hookstate/core';
import { getTypeFile } from '~/utils/media';

interface EntryImportFileFieldListProps {
  readonly file: FileProps
}

export function EntryImportFileFieldList({ file: { name, readableSize, type } }: EntryImportFileFieldListProps) {
  const { translate } = useLang();
  const typeDocument = useHookstate<string | null>(null)

  useEffect(() => {
    getTypeFile({ typeName: type, typeDocument: typeDocument })
  }, [name, readableSize, type,])

  return (
    <Box display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'} flexDirection={'column'}>
      <Typography variant='subtitle1'>
        {`${translate('Name')}: ${truncateText(name, 20)}`}
      </Typography>

      <Typography variant='subtitle1'>
        {`${translate('Size')}: ${readableSize}`}
      </Typography>

      <Typography variant='subtitle1'>
        {`${translate('Type')}: ${typeDocument.get()}`}
      </Typography>
    </Box>


  );
}
