import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IContactCenter from '../interfaces/IFriendlyType';

export default function Edit() {
  const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm<IContactCenter>();
  const fields = [
    { name: 'id', type: 'hidden' },
    { name: 'name', type: 'string', required: true },
    { name: 'description', type: 'string', required: false },
    { name: 'changeTypeSubtype', namePrimary: 'type_id', nameSecondary: 'subtype_id', type: 'selectAutoCompleteAgregate', modelPrimary: 'type', placeholderPrimary: 'Type', modelSecondary: 'subtype', placeholderSecondary: 'Subtype', required: true },
  ];

  return (
    <CrudEdit model={'friendly-type'}
      fields={fields}
      register={register}
      handleSubmit={handleSubmit}
      control={control}
      setFormValue={setFormValue}
      messageSuccess={'Type/Subtype Friendly successfully created!'}
      title={'Edit Type/SubType Friendly'}
      getValues={getValues}
    />
  )
}
