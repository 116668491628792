import React, { ChangeEvent, useEffect } from 'react';
import { useLang } from '~/hooks/useLang';
import { TextField, Grid, IconButton, Typography, Divider, CircularProgress, ListItem, ListItemText, Button, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Downgraded, useHookstate, createState, useState } from '@hookstate/core';
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '~/components/ErrorFallback';
import InfiniteScroll from 'react-infinite-scroll-component';
import { searchInvolvement } from '../../services';
import dayjs, { Dayjs } from 'dayjs';
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState';
import useSystemState from '~/features/System/stores/SystemState';
import useOccurrenceCommentState from '~/features/Occurrence/stores/OccurrenceCommentState';
import FilterAutocompleteTypeDocuments from '~/components/FilterAutocompleteTypeDocuments';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ptBR } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputMask from 'react-input-mask';
import { removeMask } from '~/features/Dispatch/utils/stringsManipulations';
import { Visibility } from '@mui/icons-material';

export interface OccurrencesCpfProps {
  readonly id: number | null;
  readonly name: string | null;
  readonly cpf: string | null;
  readonly birth_date: string | null;
  readonly mother_name: string | null;
  readonly occurrence_id: number | null;
  readonly created_at: Dayjs;
  readonly involvement_type: {
    readonly id: number | null;
    readonly name: string | null;
  }
  readonly people_circunstance: {
    readonly id: number;
    readonly name: string | null;
  }
  readonly destination: {
    readonly id: number | null;
    readonly name: string | null;
  }
  readonly occurrence: {
    readonly id: number | null;
    readonly created_at: Dayjs;
    readonly type: {
      readonly id: number;
      readonly name: string | null;
    };
    readonly subtype: {
      readonly id: number;
      readonly name: string | number;
    }
    readonly dispatches: ReadonlyArray<{
      readonly id: number,
      readonly code: string;
      readonly dispatch_group_id: number;
      readonly stationHasAccess: boolean;
    }>
  },
}

interface VoidField {
  readonly cpf: boolean;
  readonly name: boolean;
  readonly motherName: boolean;
  readonly birthDate: boolean;
  readonly otherDocuments: boolean;
}
const cpfState = createState('');

export function useSearchCPF() {
  const cpf = useState(cpfState);

  return ({
    cpf: () => cpf
  })
}

// eslint-disable-next-line max-lines-per-function
export default function SearchInvolvement(previousCpf, data) {
  const { translate } = useLang();
  const { windowManager } = useSystemState();

  const cpfExternal = useSearchCPF().cpf;
  const cpf = useHookstate('');
  const name = useHookstate<string | null>(null);
  const motherName = useHookstate<string | null>(null);
  const [birthDateValue, setBirthDateValue] = React.useState<Dayjs | null>(null);
  const otherDocument = useHookstate<string | null>(null);
  const otherDocumentTypeId = useHookstate<number | null>(null);
  const cpfSearch = useHookstate('');
  const result = useHookstate<readonly OccurrencesCpfProps[]>(windowManager()['searchInvolvement'].dataSearchInvolvement?.attach(Downgraded).get() ?? []);
  const numberInputsFilled = useHookstate<VoidField>({
    cpf: previousCpf?.length ? true : false,
    name: false,
    motherName: false,
    birthDate: false,
    otherDocuments: false
  })
  const totalFieldsFilled = useHookstate(0)
  const resultCount = useHookstate(0);
  const nameIsValid = useHookstate<boolean>(false)

  const error = useHookstate({
    name: '',
    otherDocument: '',
  });


  const { syncOccurrenceEditAttendent } = useOccurrenceState();
  const { syncPreviousComments } = useOccurrenceCommentState();

  useEffect(() => {
    cpf.set(cpfExternal().get());
    return () => {
      cpfExternal().set('');
    }
  }, []);

  const nextData = async (reset = false) => {
    const pageQuery = reset ? 0 : Math.floor(result.get().length / 10);
    // const listNames = name.get()?.split(' ')

    // if(name.get() && listNames && listNames?.length < 1){
    //   error.name.set(translate('Type, at least, three names.'))
    // } 
    // else 
    if (otherDocumentTypeId.get() && !otherDocument.get()) {
      error.otherDocument.set(translate('Type the document number.'))
    }
    else {
      searchInvolvement({
        cpf: removeMask(cpfSearch.get()),
        name: name.get(),
        motherName: motherName.get(),
        birthDate: birthDateValue?.format('YYYY-MM-DD'),
        otherDocument: otherDocument.get(),
        otherDocumentTypeId: otherDocumentTypeId.get(),
        page: pageQuery,
        limit: 10
      }).then(({ data, rowsCount }) => {
        if (reset) {
          result.set(data);
          resultCount.set(rowsCount);
        } else {
          result.merge(data);
          resultCount.set(rowsCount);
        }
      })
    }
  }
  const onClick = (id) => {
    syncOccurrenceEditAttendent(id);
    syncPreviousComments(id);
    window.location.pathname == '/dispatch' ? windowManager()['dispatchesResultSearches'].open.set(true) : windowManager().occurrence.open.set(true);
  }

  useEffect(() => {
    totalFieldsFilled.set(Object.values(numberInputsFilled.get()).filter(value => value === true).length);
  }, [name.value, motherName.value, birthDateValue, otherDocument.value,])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Grid container spacing={2}>
        <Grid container >
          <Grid item xs={12} >
            <Grid container xs={12} direction="row" spacing={1} padding={1} >
              <Grid item xs={6} spacing={1}>
                <InputMask
                  mask="999.999.999-99"
                  value={cpf.get()}
                  onChange={(event) => {
                    cpf.set(event.target.value)
                    if (cpf.get()?.length) {
                      numberInputsFilled.cpf.set(true)
                    } else {
                      numberInputsFilled.cpf.set(false)
                    }
                  }}
                >
                  <TextField
                    fullWidth
                    label={`${translate('CPF')}`}
                    size="small"
                  />
                </InputMask>
              </Grid>
              <Grid item xs={6} spacing={1}>
                <TextField
                  fullWidth
                  label={`${translate('Name')}`}
                  size="small"
                  value={name.get() ?? null}
                  inputProps={{
                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                      name.set(event.target.value)
                      if (name.get()?.length) {
                        numberInputsFilled.name.set(true)
                        // const listNames = name.get()?.split(' ')
                        // if (listNames && listNames.length >= 1){ 
                        error.name.set('')
                        nameIsValid.set(true)
                        // }

                      } else {
                        numberInputsFilled.name.set(false)
                        error.name.set('')
                        nameIsValid.set(false)
                      }
                    }
                  }}
                  error={error.name.get() ? !!error.name.get() : false}
                />
                <Typography variant='body2'>
                  {!!error.name.get() && <span style={{ color: 'red' }}>
                    {`${error.name.get()}`}
                  </span>}
                </Typography>
              </Grid>
              <Grid item xs={6} spacing={1}>
                <TextField
                  fullWidth
                  label={`${translate('Mother Name')}`}
                  size="small"
                  value={motherName.get() ?? null}
                  inputProps={{
                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                      motherName.set(event.target.value)
                      if (motherName.get()?.length) {
                        numberInputsFilled.motherName.set(true)
                      } else {
                        numberInputsFilled.motherName.set(false)
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6} spacing={1}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={'pt-br'}
                  localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                >
                  <DateTimePicker
                    // disabled={disableFields}
                    disableFuture
                    views={['day', 'month', 'year']}
                    format={'DD/MM/YYYY'}
                    minDate={dayjs().subtract(110, 'years')}
                    label={translate(`Birth Date`)}
                    value={birthDateValue}
                    onChange={(newValue: Dayjs | null) => {
                      setBirthDateValue(newValue)
                      if (String(birthDateValue).length) {
                        numberInputsFilled.birthDate.set(true)
                      } else {
                        numberInputsFilled.birthDate.set(false)
                      }
                    }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        inputProps: {
                          placeholder: translate(`dd/mm/yyyy`)
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} spacing={1}>
                <FilterAutocompleteTypeDocuments
                  typeDocumentsId={otherDocumentTypeId.get()}
                  onTypeDocumentsChange={(typedocument) => {
                    otherDocumentTypeId?.set(typedocument)
                    error.otherDocument.set('')
                  }}
                />
              </Grid>
              <Grid item xs={6} spacing={1}>
                <TextField
                  fullWidth
                  disabled={otherDocumentTypeId.get() === null}
                  label={`${translate('Document')}`}
                  size="small"
                  value={otherDocument.get() ?? null}
                  inputProps={{
                    onChange: (event: ChangeEvent<HTMLInputElement>) => {
                      otherDocument.set(event.target.value)
                      if (otherDocument.get()?.length) {
                        numberInputsFilled.otherDocuments.set(true)
                        error.otherDocument.set('')
                      } else {
                        numberInputsFilled.otherDocuments.set(false)
                      }
                    }
                  }}
                  error={error.otherDocument.get() ? !!error.otherDocument.get() : false}
                />
                <Typography variant='body2'>
                  {!!error.otherDocument.get() && <span style={{ color: 'red' }}>
                    {`${error.otherDocument.get()}`}
                  </span>}
                </Typography>
              </Grid>

              <Grid item xs={12} spacing={1} flexDirection={'row'} sx={{ display: 'flex', justifyContent: 'end', alignSelf: 'end', gap: 2 }}>
                <Button
                  variant="text"
                  onClick={() => {
                    cpf.set('')
                    name.set('')
                    motherName.set('')
                    setBirthDateValue(null)
                    otherDocument.set('')
                    otherDocumentTypeId.set(null)

                    numberInputsFilled.cpf.set(false)
                    numberInputsFilled.name.set(false)
                    numberInputsFilled.motherName.set(false)
                    numberInputsFilled.birthDate.set(false)
                    numberInputsFilled.otherDocuments.set(false)

                    totalFieldsFilled.set(0)
                    result.set([])
                    resultCount.set(0)

                    error.name.set('')
                    error.otherDocument.set('')
                  }}
                  sx={{
                    height: '40px',
                    textTransform: 'none',
                    textDecorationLine: 'underline',
                    color: 'text.primary',
                    ':hover': {
                      bgcolor: 'transparent',
                      color: 'primary.main'
                    }
                  }}
                >
                  {translate('Clear filters')}
                </Button>
                <Tooltip title={translate('Search')} placement="bottom">
                  <IconButton
                    onClick={() => {
                      cpfSearch.set(cpf.get());
                      nextData(true)
                    }}
                    disabled={removeMask(cpf.get()).length != 11 && (totalFieldsFilled.get() >= 2 || nameIsValid.get()) ? false : removeMask(cpf.get()).length != 11 ? true : false}
                  >
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Divider />

            <Grid container padding={1}>
              <Grid item xs={12}>
                <InfiniteScroll
                  dataLength={result.value.length}
                  next={() => nextData()}
                  hasMore={(result.value.length != resultCount.get()) && result.value.length != 0}
                  height={335}
                  loader={<CircularProgress />}
                >
                  {
                    result.value.length != 0 ? (
                      result.get().map((items) => (
                        <>
                          <ListItem key={items.occurrence.id}>
                            <ListItemText
                              primary={`OC: ${items.occurrence.id} | ${items.occurrence.dispatches.map((dispatch) => `\n\n ${dispatch.code}`)}`}
                              secondary={<>
                                <Typography
                                  variant={'subtitle2'}
                                >
                                  {`${items.occurrence.type.name ? items.occurrence.type.name : translate('Not informed')}` +
                                    " | " +
                                    `${items.occurrence.subtype?.name ? items.occurrence.subtype?.name : ''}`}
                                </Typography>
                                <Typography
                                  variant={'subtitle2'}
                                >
                                  {`${items.involvement_type.name ? items.involvement_type.name : translate('Not informed')}` +
                                    " | " +
                                    `${items.people_circunstance.name ? items.people_circunstance.name : translate('Not informed')}`}
                                </Typography>
                                <Typography
                                  variant={'subtitle2'}
                                >
                                  {`${translate('Final Destination') + ': '} ${items?.destination?.name ? items?.destination?.name : ''}`}
                                </Typography>
                                <Typography variant={'subtitle2'}>
                                Data da Ocorrência: {dayjs(items?.occurrence.created_at).format('DD/MM/YYYY HH:mm')}
                              </Typography>
                                <Typography variant={'subtitle2'}>
                                Data do Registro: {dayjs(items?.created_at).format('DD/MM/YYYY HH:mm')}
                              </Typography>
                                <Typography
                                  variant={'subtitle2'}
                                >
                                  {items.name ? items.name : ''}
                                </Typography>
                              </>} />
                            <IconButton aria-label="comment" onClick={() => onClick(items?.occurrence_id)} title='Visualizar'>
                              <Visibility />
                            </IconButton>
                          </ListItem>
                          <Divider sx={{ marginTop: 2 }} />
                        </>
                      ))
                    ) : <Grid xs={12} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography>
                        {translate('No Occurrences')}
                      </Typography>
                    </Grid>
                  }
                </InfiniteScroll>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </ErrorBoundary >
  )
}
