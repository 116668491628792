import React, { useState, useEffect } from 'react'
import { SubmitHandler, UseFormGetValues } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import notify from '~/utils/notify'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from '@mui/material'
import LoopIcon from '@mui/icons-material/Loop'
import { useLang } from '~/hooks/useLang'
import Layout from '~/components/Layout'
import ButtonForm from '~/components/ButtonForm'
import CrudGeneratorFields from '../CrudGeneratorFields'
import { AxiosError, Method } from 'axios'
import ICrudEditParams from './interfaces/ICrudEditParams'
import { authFetch } from '~/services/fetch'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { formatDate } from '~/utils/format'
import InternalHeader from '../Layout/components/InternalHeader'
import InternalContent from '../Layout/components/InternalContent'
import { objectToSnakeCase } from '~/utils/format'

export type EditField = {
  readonly name: string
  readonly type: string
  readonly model?: string
  readonly placeholder?: string
  readonly method?: string
}
interface CrudEditProps {
  readonly model: string
  readonly fields: readonly EditField[]
  readonly register: UseFormRegister<any>
  readonly handleSubmit: any
  readonly control: any
  readonly setFormValue: UseFormSetValue<any>
  readonly messageSuccess: string
  readonly title: string
  readonly getValues: UseFormGetValues<any>
  readonly method?: Method
}

export default function CrudEdit({
  method,
  model,
  fields,
  register,
  handleSubmit,
  control,
  setFormValue,
  messageSuccess,
  title,
  getValues,
}: CrudEditProps) {
  const { translate } = useLang()
  const { id } = useParams<ICrudEditParams>()
  const history = useHistory()
  const [processing, setProcessing] = useState<number>(0)

  useEffect(() => {
    authFetch({
      url: `/${model}/${id}`,
      method: 'GET',
    })
      .then((response) => {
        if (response.data) {
          fields.forEach((params) => {
            setFormValue(params.name, response.data[`${params.name}`])
          })
        }
      })
      .catch((err) => {
        setProcessing(0)
      })
  }, [])

  const onSubmit: SubmitHandler<any> = (params, event) => {
    setProcessing(1)
    authFetch({
      url: `/${model}/${params.id}`,
      method: 'PUT',
      data: objectToSnakeCase(params),
    })
      .then((response) => {
        if (response.data) {
          response.data.message
            ? notify({
                message: `${translate(response.data.message)}`,
                type: 'success',
              })
            : notify({
                message: `${translate(messageSuccess)}`,
                type: 'success',
              })
          setProcessing(0)
          setTimeout(handlePushToIndex, 2000)
        }
      })
      .finally(() => {
        setProcessing(0)
      })
  }

  const handlePushToIndex = () => {
    history.push(`/${model}`)
  }

  return (
    <Layout marginLayout>
      <InternalHeader title={translate(title)} />

      <InternalContent expandButton={false}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <CrudGeneratorFields
              getValues={getValues}
              fields={fields}
              register={register}
              control={control}
              setFormValue={setFormValue}
            />
          </Grid>

          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <ButtonForm type="cancel" onClick={handlePushToIndex}>
              {translate('Cancel')}
            </ButtonForm>
            <ButtonForm type="submit">
              <LoopIcon
                className="load-icon"
                sx={{ display: processing ? 'block' : 'none' }}
              />
              {processing ? translate('Saving') : translate('Save')}
            </ButtonForm>
          </CardActions>
        </form>
      </InternalContent>
    </Layout>
  )
}
