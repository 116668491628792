import React, { useEffect } from "react";
import { IconButton, Menu, Checkbox, ListItem, ListItemButton, ListItemText, ListItemIcon, Box, Tooltip } from '@mui/material';
import { GridViewRounded as WindowIcon } from '@mui/icons-material';
import useSystemState from '../../stores/SystemState';
import WindowDraggableList from '../WindowDraggableList';
import { useLang } from '~/hooks/useLang';

export default function WindowManagerButton() {
  const { translate } = useLang();
  const { windowManager } = useSystemState();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Tooltip title={translate('Manager Window')} placement="bottom">
        <IconButton
          size="large"
          id="basic-button"
          aria-controls={open ? 'basic-menu' : ''}
          aria-haspopup="true"
          aria-expanded={open}
          onClick={handleClick}
        >
          <WindowIcon />
        </IconButton>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ zIndex: 9999 }}
      >
        {Object.keys(windowManager()).map((key, index) =>
          (windowManager()[key].hide.get()) ? (
            <ListItem
              key={key}
              disablePadding
            >
              <ListItemButton
                key={key}
                role={''}
                disabled={windowManager()[key].disabled.get()}
                onClick={() => {
                  const currentManager = windowManager()[key].get();
                  windowManager()[key].open.set(!currentManager.open);
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    key={key}
                    edge="start"
                    checked={windowManager()[key].open.get()}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText key={key} id={index.toString()} primary={`${windowManager()[key].title.get()}`} />
              </ListItemButton>
            </ListItem>
          ) : false
        )}
      </Menu>
      <WindowDraggableList />
    </Box>
  )
}