import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IVehicle from '../interfaces/IVehicle'
import Layout from '~/components/Layout';


export default function Create() {     
  const {register, handleSubmit, control, setValue:setFormValue} = useForm<IVehicle>();
  const fields = [
      { name: 'plate', type: 'string'},
      { name: 'identification', type: 'string' },
      { name: 'agency_id', type: 'selectAutoComplete', model: 'agency', placeholder:'Agency:name' },
      { name: 'vehicle_type_id', type: 'selectAutoComplete', model: 'vehicle-type', placeholder: 'VehicleType:name'},
      { name: 'dispatch_group_id', type: 'selectAutoComplete', model: 'dispatch/group', placeholder: 'Dispatch Group', optionSelect: 'acronym'},
      { name: 'device_id', type: 'selectAutoComplete', model: 'device', placeholder: 'Device:name'}, 
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate model={'vehicle'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        messageSuccess={'Vehicle successfully created!'}  
        title={'Add Vehicle'}  
        subheader={'Add a new vehicle'}
        setFormValue={setFormValue} />
      </Layout>
    )
}
