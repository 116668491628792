import React, { ChangeEvent, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Grid, DialogActions, Box, Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import FilterAutocompleteType from '~/components/FilterAutocompleteType';
import FilterAutocompleteSubtypesByType from '~/components/FilterAutocompleteSubtypeByType';
import { createDispatchByDevice } from '../../services/request';

const defaultErros = {
  type: '',
  subType: '',
  comment: '',
}

export default function NewDispatchPopupDeviceDialog({ open, deviceId, latitude, longitude }) {
  const { translate } = useLang();
  const comment = useHookstate<string | null>('');
  const errors = useHookstate(defaultErros);
  const typeId = useHookstate<number | null>(null);
  const subtypeId = useHookstate<number | null>(null);
  const loading = useHookstate(false);
  const isDisabledSubTypeField = useHookstate(false)

  useEffect(() => {
    errors.type.set('')
    errors.subType.set('')
    errors.comment.set('')
  }, [])

  const handleCreateDispatch = () => {
    loading.set(true)

    if (!typeId.get()) {
      errors.type.set('Enter a type')
      loading.set(false)

      return false
    }

    if (isDisabledSubTypeField.get() && !subtypeId.get()) {
      errors.subType.set('Enter a subtype')

      loading.set(false)

      return false
    }

    if (!comment.get()) {
      errors.comment.set('Enter a comment with a minimum of 5 characters')

      loading.set(false)

      return false
    }

    createDispatchByDevice({
      typeId: typeId.get(),
      subtypeId: subtypeId.get(),
      comment: comment.get(),
      deviceId: deviceId,
      latitude: latitude,
      longitude: longitude
    }).finally(() => {
      open.set(false)
      loading.set(false)
    })
  }

  return (
    <Dialog fullWidth={true} open={open.get()} onClose={() => open.set(false)} disableEscapeKeyDown={true} maxWidth='md'>
      <DialogTitle>
        {translate('Create Occurrence')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6} mt={1}>
            <FilterAutocompleteType
              disabled={false}
              typeId={typeId?.get()}
              onTypeChange={(type) => {
                typeId?.set(Number(type?.id) ?? null)
                errors.type.set('')
              }}
              error={errors.type.get().length > 1}
            />
          </Grid>
          <Grid item xs={6} mt={1}>
            <FilterAutocompleteSubtypesByType
              hasType
              typeId={typeId?.get()}
              onSubtypeChange={(subtype) => {
                subtypeId?.set(subtype?.id ?? null)
                errors.subType.set('')
              }}
              isEnabled={isDisabledSubTypeField}
              error={errors.subType.get().length > 1}
            />
          </Grid>
        </Grid>
        <TextField
          multiline
          rows={10}
          error={!!errors.comment.get()}
          required={true}
          name={'comment'}
          id='comment'
          label={translate('Comment')}
          fullWidth
          size='small'
          value={!comment.get() ? '' : comment.get()}
          sx={{ mt: 1 }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            event.target.value.length >= 5 && errors.comment.set('')
            comment.set(event.target.value)
          }}
        />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingX: "24px" }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant='outlined'
            onClick={() => open.set(false)}>
            {translate('Cancel')}
          </Button>
          <LoadingButton
            loading={loading.get()}
            variant='contained'
            onClick={handleCreateDispatch}
          >
            {translate('Submit')}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
