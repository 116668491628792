import { AppRouteProps } from "~/routes";
import Index from "./Pages";
import Create from "./Pages/Create";
import Edit from "./Pages/Edit";


export default function userGroup(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    { path: '/group', component: Index, isProtected: true, permissions: ['group_index'] },
    { path: '/group/create', component: Create, isProtected: true, permissions: ['group_create'] },
    { path: '/group/edit/:id', component: Edit, isProtected: true, permissions: ['group_edit'] },
  ];
  return routes;
}