import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLang } from '~/hooks/useLang';
import ConfirmDialog from '../ConfirmDialog';
import type { State, StateMethods } from '@hookstate/core';

interface ReturnToServiceDispatchVehicleProps {
    readonly openState: StateMethods<boolean>;
    readonly handleAgree: () => void;
    readonly handleClose: () => void;
    readonly isLoading?: State<boolean>
}

export default function ReturnToServiceDispatchVehicle({ openState, handleAgree, handleClose, isLoading }: ReturnToServiceDispatchVehicleProps) {
    const { translate } = useLang();

    return (
        <ConfirmDialog
            open={openState}
            title={translate('Return to service')}
            content={translate('Confirm this device to return to service?')}
            handleClose={handleClose}
            handleAgree={handleAgree}
            loading={isLoading}
        />
    );
}
