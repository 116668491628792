/* eslint-disable max-lines-per-function */
import React from 'react';
import { createState, DevTools, State, useState } from '@hookstate/core';
import { Validation as validation } from '@hookstate/validation';
import useEntryCommentState from './EntryCommentState';
import useEntryDispatchState from './EntryDispatchState';
import { useLang } from '../../../hooks/useLang';
import notify from '~/utils/notify';
import { Broadcasted } from '@hookstate/broadcasted';
import IEntry from '../interfaces/IEntry';
import IEntryState from '../interfaces/IEntryState';
import IEntryStatusActions from '../interfaces/IEntryStatusActions';
import ITliaEntry from '../interfaces/ITliaEntry';
import IRecallEntry from '../interfaces/IRecallEntry'
import { LatLng } from 'leaflet';
import { authFetch } from '~/services/fetch';
import { parseDataEntry } from '../utils/parse';
import { formatForSending } from '../utils/format';
import { validPhone } from '~/utils/validators';
import { useMarkerState } from '~/features/Map/stores/MapState';
import useSystemState from '~/features/System/stores/SystemState';

type DispatchGroupProps = {
  readonly dispatchGroupId: number | string;
  readonly loading: State<boolean>
}

interface IEntryOpenCloseOccurrence {
  readonly comment: string;
}

const entryDefault: IEntry = {
  id: null,
  name: null,
  phone: null,
  address: null,
  location: '',
  reference: null,
  crossingStreet: null,
  coordinates: null,
  latitude: null,
  longitude: null,
  entryOriginId: '',
  stateId: null,
  cityId: null,
  districtId: null,
  typeId: null,
  subtypeId: null,
  typeName: null,
  subtypeName: null,
  createdAt: null,
  vehicle: {
    plate: null,
    circunstanceId: null,
    externalKeyTracking: null
  },
  totalSubtypes: 0,
  saved: false,
  totalDistricts: 0
}

const entryStatusActionsDefault: IEntryStatusActions = {
  canCreate: true,
  canSave: false,
  hasPrev: false,
  hasNext: false,
  isOpenRecall: false,
  isInvalidRequest: false,
  canSetDispatchGroup: false,
  isOpenModalLastCalls: false,
  isOpenModalVehicle: false,
  showVehicleButton: false,
}

const entryStore = Object.create(entryDefault)

const store = createState<IEntryState>({
  entry: entryStore,
  entryStatusActions: entryStatusActionsDefault,
})

const loadingsActions = createState({
  loadingSaveEntry: false,
  loadingDispatchEntry: false,
})
// eslint-disable-next-line new-cap
store.attach(Broadcasted('entry-events'));

// eslint-disable-next-line new-cap
DevTools(store).label('Entry')

export default function useEntryState() {
  const { translate } = useLang()
  const entryDefaultState = useState(store);
  const loadingsActionsEntry = useState(loadingsActions.loadingSaveEntry);
  const loadingsActionsDispatchEntry = useState(loadingsActions.loadingDispatchEntry);
  const { deleteMarker, markersState } = useMarkerState();
  const { loadingDispatchConfig } = useEntryDispatchState()
  const { windowManager } = useSystemState();
  const entryStatusActions = useState(entryDefaultState.entryStatusActions);
  const entry = useState(entryDefaultState.entry);
  const {
    id,
    phone,
    typeId,
    subtypeId,
    entryOriginId,
    location,
    cityId,
    vehicle,
    totalSubtypes,
    name,
    address,
    latitude,
    longitude,
    typeName,
    subtypeName,
    createdAt,
    saved,
    districtId,
    totalDistricts
  } = entry;
  const { canCreate, hasPrev, hasNext, canSave, isInvalidRequest, canSetDispatchGroup, isOpenModalVehicle, showVehicleButton } = entryStatusActions;

  const { syncPreviousComments, resetComment } = useEntryCommentState();
  const { getEntryDispatch, resetEntryDispatch } = useEntryDispatchState();

  id.attach(validation);
  phone.attach(validation);
  typeId.attach(validation);
  entryOriginId.attach(validation);
  cityId.attach(validation);
  vehicle.attach(validation);
  subtypeId.attach(validation);
  districtId.attach(validation);

  validation(id).validate(() => Boolean(id.get() != null), 'Se o ID está valido');
  validation(phone).validate(() => Boolean(validPhone(phone.get())), 'Telefone Completo');
  validation(typeId).validate(() => Boolean(typeId.get() != null), 'Se o Tipo está valido');
  validation(entryOriginId).validate(() => Boolean(entryOriginId.get() != ''), 'Se a Origem da Ligação está valida');
  validation(location).validate(() => Boolean(location.get() != '' && location.get() != null), 'Se a Localização está valida');
  validation(cityId).validate(() => Boolean(cityId.get() != null), 'Se a Cidade está valida');
  validation(vehicle).validate(() => !!(showVehicleButton.get() && (vehicle.circunstanceId.get())), 'Se exige uma circunstancia para o cadastro');


  validation(subtypeId).validate(() => (totalSubtypes.get() > 0 ? totalSubtypes.get() > 0 && subtypeId.get() != null : true), 'Se precisa informar um subtipo');
  validation(districtId).validate(() => (totalDistricts.get() > 0 ? totalDistricts.get() > 0 && districtId.get() != null : true), 'Se precisa informar um bairro');
  // validation(totalSubtypes).validate(() => !(totalSubtypes.get() > 0), 'Se precisa informar um subtipo');


  const resetEntry = () => {
    entry.set(Object.create(entryDefault));
    entryStatusActions.set(Object.create(entryStatusActionsDefault));
    resetComment();
    resetEntryDispatch(); getEntryFromId
  }

  const validateActionsEntry = () => {
    if ((validation(id).valid() &&
      validation(location).valid() &&
      validation(typeId).valid() &&
      validation(entryOriginId).valid() &&
      validation(cityId).valid() &&
      (validation(subtypeId).valid()) &&
      (validation(districtId).valid())
    )) {

      // #FIXME: Avaliar melhor forma de validar este formulário.
      // if (showVehicleButton.get()) {
      //   if (!validation(vehicle).valid()) {
      //     notify({message: `${translate('Need to inform a vehicle data to proceed!')}`, type: 'error'});
      //     isInvalidRequest.set(true)
      //     return false;
      //   }
      // }


      return true
    } else {
      notify({ message: `${translate('Please check the form information and try again!')}`, type: 'error' });
      isInvalidRequest.set(true)
      return false
    }
  }

  const getMyLastEntry = async () => {
    await authFetch({
      url: '/entry/get-my-last',
      method: 'POST'
    }).then(({ data }) => {
      if (data != '') {
        windowManager()['VehicleModal'].open.set(false);
        entry.set(parseDataEntry(data.entry));
        hasPrev.set(data.hasPrev);
        hasNext.set(data.hasNext);
        canCreate.set(true);
        canSave.set(true);
        canSetDispatchGroup.set(true);
        isInvalidRequest.set(false);
        const dataId = data.entry.id;
        syncPreviousComments(dataId).then();
        getEntryDispatch(dataId).then();
        parseDataEntry(data.entry).typeId && saved.set(true)
        return true;
      }
      resetEntry();
    })
      .catch(err => console.error(err));
  }

  const getEntryFromId = async (entryId) => {
    await authFetch({
      url: `/entry/${entryId}`,
      method: 'GET'
    }).then(({ data }) => {
      if (data != '') {
        entry.set(parseDataEntry(data.entry))
        hasPrev.set(data.hasPrev);
        hasNext.set(data.hasNext);
        canCreate.set(true);
        canSave.set(true);
        canSetDispatchGroup.set(true);
        isInvalidRequest.set(false);
        syncPreviousComments(entryId).then();
        getEntryDispatch(entryId).then();
        return true;
      }
      resetEntry();
    });
  }

  return ({
    entry: () => entry,
    resetEntry: () => resetEntry,
    entryStatusActions: () => entryStatusActions,
    getMyLastEntry: getMyLastEntry,
    getEntryFromId: getEntryFromId,
    loadingsActionsEntry: loadingsActionsEntry,
    loadingsActionsDispatchEntry: loadingsActionsDispatchEntry,
    createEntry: async () => {
      return authFetch({
        url: '/entry/store',
        method: 'POST'
      }).then(({ data }) => {
        entry.set(parseDataEntry(data.entry))
        hasPrev.set(data.hasPrev);
        hasNext.set(false);
        canSave.set(true);
        canSetDispatchGroup.set(true);
        const dataId = data.entry.id;
        syncPreviousComments(dataId).then()
        getEntryDispatch(dataId).then()

        return true
      });
    },
    previousEntry: async () => {
      authFetch({
        url: '/entry/get-my-previous',
        method: 'POST',
        data: {
          'idRef': id.get()
        }
      }).then(({ data }) => {
        const parsed = parseDataEntry(data.entry)
        windowManager()['VehicleModal'].open.set(false);
        entry.set(parsed)
        hasPrev.set(data.hasPrev);
        hasNext.set(data.hasNext);
        const dataId = data.entry.id;
        syncPreviousComments(dataId).then()
        getEntryDispatch(dataId).then()
      });
    },
    nextEntry: async () => {
      authFetch({
        url: '/entry/get-my-next',
        method: 'POST',
        data: {
          'idRef': id.get()
        }
      }).then(({ data }) => {
        windowManager()['VehicleModal'].open.set(false);
        entry.set(parseDataEntry(data.entry))
        hasPrev.set(data.hasPrev);
        hasNext.set(data.hasNext);
        const dataId = data.entry.id;
        syncPreviousComments(dataId).then()
        getEntryDispatch(dataId).then()
      });
    },
    saveEntry: async () => {
      if (validateActionsEntry()) {
        loadingDispatchConfig.set(true)
        authFetch({
          url: `/entry/${id.get()}`,
          method: 'PUT',
          data: formatForSending(entry.get())
        })
          .then((response) => {
            isInvalidRequest.set(false)
            notify({ message: `${translate('Saved successfully!')}`, type: 'success' });
            setTimeout(() => {
              getEntryDispatch(id.get());
            }, 1000);
            entry.saved.set(true)
            return response.status
          })
      }
    },
    handleUpdateMyEntries: async () => {
      const response = await authFetch({
        url: '/entry/get-my-entries',
      })

      return response.data

    },
    setDispatchGroup: async ({ dispatchGroupId, loading }: DispatchGroupProps) => {
      loading?.set(true)
      authFetch({
        url: `/entry/set-dispatch-group`,
        method: 'POST',
        data: {
          'entry_id': id.get(),
          'dispatch_group_id': dispatchGroupId
        }
      })
        .then(() => {
          isInvalidRequest.set(false)
          notify({ message: `${translate('Group successfully add')}`, type: 'success' });
          getEntryDispatch(id.get()).then();
          return true
        }).finally(() => {
          loading?.set(false)
        })
    },
    tliaEntry: async (param: ITliaEntry) => {
      authFetch({
        url: `/entry/discard`,
        method: 'POST',
        data: {
          'entrieId': id.get(),
          'comment': param.reason,
          'entryStatusId': param.entryStatusId,
          'name': param.name,
          'phone': param.phone,
          'address_reference': param.addressReference,
          'location': param.location,
          'latitude': param.latitude,
          'longitude': param.longitude,
          'entry_origin_id': param.entryOriginId,
          'city_id': param.cityId,
          'district_id': param.districtId,
          'type_id': param.typeId,
          'subtype_id': param.subtypeId

        }
      }).then(async () => {
        notify({ message: `${translate('Finished successfully!')}`, type: 'success' });
        markersState()['occurrence'].set({});
        deleteMarker({ markerType: 'entry', markerIdentifier: id.get() })
        getMyLastEntry().then();
      });
    },
    dispatchEntry: async () => {
      if (validateActionsEntry()) {
        loadingsActionsDispatchEntry.set(true)
        const response = await authFetch({
          url: `/entry/dispatch`,
          method: 'POST',
          data: {
            'entrieId': id.get()
          }
        })
        markersState()['occurrence'].set({});
        deleteMarker({ markerType: 'entry', markerIdentifier: id.get() })
        return response.data

      }
    },
    entryOpenCloseOccurrence: async ({ comment }: IEntryOpenCloseOccurrence) => {
      if (validateActionsEntry()) {
        const response = await authFetch({
          url: '/entry/create/closed-occurrence',
          method: 'POST',
          data: {
            entry_id: id.get(),
            comment: comment,
          }
        })
        markersState()['occurrence'].set({});
        deleteMarker({ markerType: 'entry', markerIdentifier: id.get() })
        return response
      }
    },
    recallEntry: async (param: IRecallEntry) => {
      const response = await authFetch({
        url: `/entry/recall`,
        method: 'POST',
        data: {
          entrieId: id.get(),
          entryStatusId: 2,
          occurrenceId: param.occurrenceId,
          name: param.name,
          phone: param.phone,
          comment: param.comment,
          entry_origin_id: param.entryOriginId
        }
      })
      if (response.status === 200) {
        markersState()['occurrence'].set({});
        deleteMarker({ markerType: 'entry', markerIdentifier: id.get() })
        getMyLastEntry().then();
      }

      return response
    },
    socketUpdateEntry: (entryParams) => {
      if (entryParams && entryParams.id == id.get()) {
        id.set(entryParams.id);
        name.set(entryParams.name);
        phone.set(entryParams.name);
        address.set(entryParams.address);
        location.set(entryParams.location);
        latitude.set(entryParams.latitude);
        longitude.set(entryParams.longitude);
        typeId.set(entryParams.typeId);
        subtypeId.set(entryParams.subtype_id);
        entryOriginId.set(entryParams.entry_origin_id);
        typeName.set(entryParams.typename);
        subtypeName.set(entryParams.subtypeName)
      }
    },
    validateActionsEntry: validateActionsEntry,
  });
}


