import React from "react";
import {IconButton, Grid} from '@mui/material';
import {SaveAs, EditOff} from '@mui/icons-material';
import { useLang } from '~/hooks/useLang';


export default function SaveButton({canSave, save}){
  const { translate } = useLang()
 
  return (
    <Grid item>
      <IconButton title={translate('Save')} disabled={!canSave} onClick={() => save ? save() : false}>
        <SaveAs/>
      </IconButton>
    </Grid>);

}