import React from 'react';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { AppRouteProps } from '~/routes';

export default function CityRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    {path: '/city', component: Index, isProtected: true, permissions: ['city_index']},
    {path: '/city/create', component: Create, isProtected: true, permissions: ['city_create']},
    {path: '/city/edit/:id', component: Edit, isProtected: true, permissions: ['city_edit']},

  ];

  return routes;
}
