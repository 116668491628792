/* eslint-disable react/react-in-jsx-scope */
import { Grid } from "@mui/material";
import ReportDataBasic from "../ReportDataBasic";
import GroupedReport from "../GroupedReport";
import IReport from "../../interfaces/IReport";
import ReportColumns from "../ReportColumns";
import { StateMethods } from '@hookstate/core';
import GroupedReportTwoLevels from '../GroupedReportTwoLevels';
import GroupedReportThreeLevels from "../GroupedReportThreeLevels";
import GroupedReportMultipleCharts from "../GroupedReportMultipleCharts";


export default function PagesReport({ className, report }: {
  readonly report: StateMethods<IReport>;
  readonly className: 'basic' | 'groups' | 'groups_two_levels' | 'groups_three_levels' | 'multiple_charts';
}) {


  return (

    <Grid container columns={24}>
      {className === 'basic' &&
        <>
          <ReportColumns columns={report.get().columns} />
          <ReportDataBasic data={report.get().data} columns={report.get().columns} />
        </>
      }
      {className === 'groups' &&
        <GroupedReport columns={report.get().columns} groups={report.get().groups} />
      }
      {className === 'groups_two_levels' &&
        <GroupedReportTwoLevels columns={report.get().columns} twoLevelsGroups={report.get().groups} />
      }

      {className === 'groups_three_levels' &&
        <GroupedReportThreeLevels columns={report.get().columns} twoLevelsGroups={report.get().groups} />
      }

      {className === 'multiple_charts' && 
      <GroupedReportMultipleCharts columns={report.get().columns} group={report.get().groups}/>
      }


    </Grid>
  )
}