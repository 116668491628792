import React, { ChangeEvent } from 'react';
import { StateMethods } from '@hookstate/core';
import { IconButton, InputAdornment, SxProps, TextField, TextFieldVariants, Theme } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

interface IInputSearchWithClear {
  readonly search: StateMethods<string>;
  readonly placeholder: string;
  readonly sx?: SxProps<Theme> | undefined;
  readonly fullWidth?: boolean | undefined;
  readonly disabled?: boolean;
  readonly variant?: TextFieldVariants;
}
export function InputSearchWithClear({ fullWidth, sx, search, placeholder, disabled = false, variant = 'outlined' }: IInputSearchWithClear) {
  return (
    <TextField
      id="outlined-search"
      variant={variant}
      sx={sx}
      fullWidth={fullWidth}
      value={search.get()}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        search.set(event.target.value)
      }}
      size='small'
      placeholder={placeholder}
      disabled={disabled}
      InputProps={{
        endAdornment: 
        <InputAdornment position="end">
            <IconButton
              aria-label="toggle clear search"
              onClick={() => {
                search.set('')
              }}
              edge="end"
            >
              {search.get()?.length ? <CancelIcon fontSize='small' /> : ''}
            </IconButton>
          </InputAdornment>
        }}
    />
  )
}