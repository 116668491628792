import React, { useEffect, useState } from 'react';
import { Grid, FormGroup, Typography, Switch, FormControlLabel } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { Controller } from 'react-hook-form';

export default function CrudCheckBoxField({ register, model, getValues, checked, control, placeholder, setFormValue}) {
  const { translate } = useLang()
  const [isCheck, setIsCheck] = useState(Boolean(checked) ? Boolean(checked) : false);

  useEffect(() => {
    setFormValue(model, Boolean(checked))
  }, [])

  return (
    <Grid item xs={4}>
        <Controller
          {...register(`${model}`)}
          name={model}
          control={control}
          defaultValue={isCheck}
          sx={{ mt: 0 }}
          render={(props) => (
            <FormGroup sx={{ paddingTop: '10px'}}>
              <FormControlLabel control={(
                <Switch
                {...props.field}
                checked={props.field.value}
                onChange={(event, value) => {
                  setIsCheck(Boolean(value));
                  return props.field.onChange(Boolean(value));
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              )} label={translate(`${placeholder ? placeholder.charAt(0).toUpperCase() + placeholder.slice(1) : model.charAt(0).toUpperCase() + model.slice(1)}`)}/>
            </FormGroup>
          )}
        />
    </Grid>
  )
}