import React, { useCallback, useEffect, useState } from 'react';
import { IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, Grid, ListSubheader, Autocomplete, TextField, OutlinedInput, Box } from '@mui/material';
import { FileCopy } from '@mui/icons-material';
import { useLang } from '../../../../hooks/useLang';
import { authFetch } from '~/services/fetch';
import useDispatchGroupState from '~/features/DispatchGroup/stores/DispatchGroupState';
import { element } from 'prop-types';
import { useHookstate } from '@hookstate/core';
import { LoadingButton } from '@mui/lab';
import useOccurrenceState from '../../stores/OccurrenceState';
import { getDispatchesForOccurrenceById } from '../../services';
import IDispatchOccurrence from '../../interfaces/IDispatchOccurrence';
import useSocket from '~/hooks/useSocket';

interface OccurrenceCopyButtonProps {
  readonly occurrenceId: number | null;
}

interface DispatchClosedProps {
  readonly id: number;
  readonly name: string;
  readonly acronym: string;
}

interface dispatchGroupResponse {
  readonly id: number;
  readonly name: string;
  readonly acronym: string;
  readonly priority: string;
}

interface occurrenceCopyButtonProps {
  readonly isDisabled?: boolean
}

export default function OccurrenceCopyButton({isDisabled = false}: occurrenceCopyButtonProps) {
  const { translate } = useLang()
  const loading = useHookstate<boolean>(false)
  const [dispatchGroupList, setDispatchGroupList] = useState<DispatchClosedProps[]>([]);
  const [dispatchGroup, setDispatchGroup] = useState<dispatchGroupResponse[] | []>([]);
  const { dispatchGroupView } = useDispatchGroupState();
  const { occurrence, copyOccurrence } = useOccurrenceState();
  const [open, setOpen] = useState(false);
  


  const getDispatchGroups = useCallback(() => {
    authFetch({
      url: '/dispatch/group',
      method: 'GET',
    }).then(({ data }) => {
      setDispatchGroupList(data);
    })
  }, [])


  useEffect(() => {
    getDispatchGroups();
  }, [])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    clearStates();
    setOpen(false);
  };

  const clickOutModalClose = () => {
    setOpen(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  

  const action = () => {
    const dispatchGroupsIds = dispatchGroup.map(item => item.id)

    if (dispatchGroupsIds.length) {
      copyOccurrence({
        dispatchGroupsIds: dispatchGroupsIds,
        occurrenceId: occurrence().id.get(),
        loading: loading
      });
      
    }

    clearStates();
    setOpen(false);
  };

  const clearStates = () => {
    setDispatchGroup([]);
  }
  const groupVisibles = Object.keys(dispatchGroupView().get()).filter(item => dispatchGroupView()[item].is_visible.get())
  const options = dispatchGroupList.map((option) => {
    return {
      priority: Boolean(groupVisibles.find((element) => element == option.name)) ? `${translate('Dispatch Groups visibles')}` : `${translate('Others')}`,
      ...option
    }
  })

  return (
    <Grid item>
      <IconButton title={translate('Copy dispatch of occurrence')} disabled={isDisabled} onClick={handleClickOpen}>
        <FileCopy />
      </IconButton>
      <Dialog open={open} onClose={clickOutModalClose} maxWidth={'xs'} fullWidth>
        <DialogTitle minWidth={"320px"}>
          {translate('Copy dispatch of occurrence')}
        </DialogTitle>
        <DialogContent>

          <FormControl fullWidth size='small' sx={{ mt: 1 }}>
            <Autocomplete
              filterSelectedOptions
              disableCloseOnSelect
              options={options.sort((a, b) => -b.priority.localeCompare(a.priority))}
              groupBy={(option) => option.priority}
              noOptionsText={translate('No options')}
              size='small'
              multiple
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              clearText={translate('Clear')}
              onChange={(event, value) => {
                // dispatchGroup.set({ id: value?.id ?? 0, name: value?.name ?? '', priority: value?.priority ?? '', acronym: value?.acronym ?? '' })
                setDispatchGroup(value)
              }}
              value={dispatchGroup}
              renderInput={(params) =>
                <TextField {...params} label={translate('Dispatch Group')}
                />
              }
              renderOption={(props, option) => (
                <MenuItem {...props} sx={{ mt: 1, overflowX: "auto" }} key={option.id} value={option.id}>
                  {`[${option.acronym}] ${option.name}`}
                </MenuItem>
              )}
            />
          </FormControl>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant='outlined'
          >
            {translate('Cancel')}
          </Button>
          <LoadingButton
            loading={loading.get()}
            onClick={action}
            variant='contained'
          >
            {translate('Submit')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Grid >);

}
