import React from 'react';

export default function IconDispatch() {
  return (
    <svg version="1.1" id="despacho-svg" width="35px" height="35px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 409.934 409.934">
        <path d="M113.897,113.891c3.516-3.513,9.215-3.511,12.729,0.006l0.53,0.531c3.513,3.516,3.51,9.215-0.006,12.728
            c-1.758,1.756-4.06,2.633-6.361,2.633c-2.305,0-4.609-0.88-6.367-2.639l-0.53-0.531
            C110.379,123.102,110.382,117.403,113.897,113.891z M196.217,85.171v0.75c0,4.971,4.029,9,9,9s9-4.029,9-9v-0.75
            c0-4.971-4.029-9-9-9S196.217,80.2,196.217,85.171z M324.014,195.717c-4.971,0-9,4.029-9,9s4.029,9,9,9h0.75c4.971,0,9-4.029,9-9
            s-4.029-9-9-9H324.014z M173.759,31.211c0-17.209,14-31.21,31.208-31.21c17.21,0,31.211,14,31.211,31.21
            c0,17.208-14.001,31.208-31.211,31.208C187.759,62.419,173.759,48.419,173.759,31.211z M191.759,31.211
            c0,7.283,5.925,13.208,13.208,13.208c7.284,0,13.211-5.925,13.211-13.208c0-7.284-5.927-13.21-13.211-13.21
            C197.684,18.001,191.759,23.927,191.759,31.211z M305.765,104.171c-5.896-5.895-9.142-13.732-9.142-22.068
            c0-8.336,3.246-16.174,9.141-22.068c12.17-12.169,31.967-12.169,44.137,0c12.167,12.169,12.167,31.968,0,44.136
            c-6.084,6.083-14.077,9.125-22.068,9.125C319.84,113.296,311.849,110.255,305.765,104.171z M314.623,82.103
            c0,3.528,1.374,6.845,3.868,9.34c5.151,5.15,13.53,5.149,18.682,0c5.149-5.15,5.149-13.53-0.001-18.681
            c-2.574-2.575-5.958-3.862-9.34-3.862c-3.383,0-6.765,1.287-9.34,3.862C315.997,75.258,314.623,78.575,314.623,82.103z
            M60.034,104.17C47.866,92,47.866,72.202,60.035,60.034c12.168-12.17,31.969-12.168,44.137,0c12.168,12.167,12.168,31.966,0,44.135
            c-5.896,5.895-13.732,9.142-22.069,9.142C73.767,113.312,65.93,110.065,60.034,104.17z M72.763,91.442
            c5.15,5.151,13.53,5.149,18.681,0c5.15-5.15,5.15-13.53,0-18.68c-5.15-5.15-13.53-5.151-18.681,0
            C67.612,77.912,67.612,86.292,72.763,91.442z M289.147,129.789c2.306,0,4.612-0.881,6.37-2.642l0.529-0.53
            c3.511-3.518,3.506-9.216-0.012-12.728c-3.519-3.511-9.216-3.507-12.729,0.012l-0.529,0.53c-3.511,3.518-3.506,9.216,0.012,12.728
            C284.547,128.913,286.847,129.789,289.147,129.789z M236.178,378.723c0,17.209-14.001,31.209-31.211,31.209
            c-17.208,0-31.208-14-31.208-31.209c0-17.208,14-31.209,31.208-31.209C222.177,347.514,236.178,361.515,236.178,378.723z
            M218.178,378.723c0-7.284-5.927-13.209-13.211-13.209c-7.283,0-13.208,5.925-13.208,13.209s5.925,13.209,13.208,13.209
            C212.251,391.933,218.178,386.007,218.178,378.723z M85.171,195.717c-4.971,0-9,4.029-9,9s4.029,9,9,9h0.75c4.971,0,9-4.029,9-9
            s-4.029-9-9-9H85.171z M349.899,305.762c12.168,12.168,12.169,31.968,0.001,44.137c-6.084,6.084-14.076,9.126-22.068,9.126
            s-15.983-3.042-22.067-9.125c-0.001,0-0.001,0-0.001,0c-5.895-5.895-9.141-13.733-9.141-22.069c0-8.336,3.246-16.174,9.142-22.069
            C317.933,293.595,337.731,293.596,349.899,305.762z M337.172,318.49c-5.148-5.148-13.528-5.149-18.681,0.001
            c-2.494,2.494-3.868,5.812-3.868,9.34s1.374,6.845,3.869,9.341l-0.001,0c5.15,5.15,13.531,5.15,18.682,0
            C342.322,332.021,342.322,323.641,337.172,318.49z M296.044,283.314l-0.529-0.53c-3.514-3.517-9.213-3.519-12.729-0.006
            c-3.516,3.513-3.519,9.211-0.006,12.728l0.529,0.53c1.758,1.759,4.063,2.639,6.367,2.639c2.302,0,4.604-0.877,6.361-2.633
            C299.554,292.529,299.557,286.83,296.044,283.314z M409.934,204.967c0,17.209-14,31.21-31.209,31.21s-31.21-14.001-31.21-31.21
            c0-17.208,14.001-31.209,31.21-31.209S409.934,187.758,409.934,204.967z M391.934,204.967c0-7.284-5.926-13.209-13.209-13.209
            c-7.284,0-13.21,5.925-13.21,13.209c0,7.284,5.926,13.21,13.21,13.21C386.008,218.177,391.934,212.251,391.934,204.967z
            M296.217,209.873v-19.948c0-8.283-7.239-14.943-18.381-17.265c-11.453-28.913-39.76-49.414-72.7-49.414
            c-32.981,0-61.512,20.557-72.938,49.527c-10.84,2.419-17.981,9-17.981,17.151v19.948c0,8.669,8.014,15.563,20.068,17.562
            c2.605,0.434,5.505-0.303,7.523-2.014c2.017-1.71,3.409-4.22,3.409-6.865v-17.224c0-33.132,26.838-60.087,59.97-60.087
            s60.027,26.955,60.027,60.087c0,25.728-16.208,48.037-40.028,56.434c-2.833-2.391-6.521-4.051-10.509-4.051h-19.216
            c-8.971,0-16.27,7.529-16.27,16.5s7.299,16.5,16.27,16.5h19.216c7.277,0,13.453-4.816,15.526-11.417
            c23.194-7.841,41.083-25.939,48.827-48.424C289.483,224.337,296.217,217.854,296.217,209.873z M104.172,305.763
            c12.168,12.169,12.167,31.969,0,44.136c-6.085,6.084-14.077,9.126-22.069,9.126c-7.991,0-15.983-3.042-22.067-9.125
            c-12.168-12.168-12.169-31.967-0.001-44.136c0,0,0,0,0.001,0C72.203,293.597,92.002,293.595,104.172,305.763z M91.443,318.491
            c-5.152-5.151-13.531-5.15-18.682,0l0.001,0c-5.15,5.15-5.15,13.531,0,18.681c5.149,5.15,13.531,5.149,18.681,0
            C96.594,332.022,96.594,323.642,91.443,318.491z M31.21,236.177c-17.209,0-31.21-14.001-31.21-31.21
            c0-17.208,14.001-31.209,31.21-31.209s31.21,14,31.21,31.209C62.42,222.176,48.419,236.177,31.21,236.177z M44.42,204.967
            c0-7.284-5.926-13.209-13.21-13.209S18,197.683,18,204.967c0,7.284,5.926,13.21,13.21,13.21S44.42,212.251,44.42,204.967z
            M114.425,282.782l-0.53,0.53c-3.515,3.515-3.515,9.213,0,12.728c1.758,1.757,4.061,2.636,6.364,2.636s4.606-0.879,6.364-2.636
            l0.53-0.53c3.515-3.515,3.515-9.213,0-12.728C123.638,279.267,117.94,279.267,114.425,282.782z M214.217,324.763v-0.75
            c0-4.971-4.029-9-9-9s-9,4.029-9,9v0.75c0,4.971,4.029,9,9,9S214.217,329.734,214.217,324.763z"/>
        </svg>
  );
}