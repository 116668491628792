import { authFetch } from "~/services/fetch";

export async function getEntryStatus(): Promise<readonly any[]> {
    try {
        const { data } = await authFetch({
            url: '/entry-status',
            method: 'GET',
        })
        return data;
    } catch (error) {
        throw error;
    }
}