import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Paper, Typography, Box, CircularProgress } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { Validation as validation } from '@hookstate/validation';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import useDispatchVehicleState from '~/features/Dispatch/stores/DispatchVehicleState';
import { DispatchDisplayComments } from '~/features/Dispatch/components/DispatchDisplayComments';
import { DispatchSendCommentField } from '~/features/Dispatch/components/DispatchSendCommentField';
import EventInputContainerDispatch from '~/features/Dispatch/components/EventInputContainerDispatch';
import ButtonActionsContainer from '~/components/ButtonActionsContainer';
import ButtonActionsVerticalContainer from '~/components/ButtonActionsVerticalContainer';
import DispatchTagList from '~/features/Dispatch/components/DispatchTagList';
import useUserState from '~/features/User/stores/UserState';
import { useHookstate } from '@hookstate/core';
import { ImportFileField } from '~/components/ImportFileField';
import useSystemState from '~/features/System/stores/SystemState';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '~/components/ErrorFallback';
import EventInputRequesterDispatch from '~/features/Dispatch/components/EventInputRequesterDispatch';
import { saveLogVisualizeDispatch } from '../../services';
import { useMarkerState, validShowArea } from '~/features/Map/stores/MapState';
import { LatLng } from 'leaflet';
import useDispatchRegistrationState from '../../stores/DispatchRegistrationState';

// eslint-disable-next-line max-lines-per-function
export default function DispatchForm({ readonly = false }) {
  const [isFile, setIsFile] = useState(false);
  const { translate } = useLang()
  const { windowManager } = useSystemState();
  const dispatchState = useDispatchState()
  const { id, type, subtype, occurrence, status, hasUnified, totalSubtypes, totalDistricts } = dispatchState.dispatch()
  const {
    canCopy,
    canPrint,
    canUnify,
    canClose,
    canMedia,
    canReport,
    canAddVehicles,
    hasPrev,
    hasNext,
    canChronology,
    canHighlight,
    canReopen,
    canHold,
    editingDispatch,
    editingDispatchRequester,
  } = dispatchState.dispatchStatusActions()
  const loadingDispatchForm = dispatchState.loadingDispatchForm()
  const isValid = validation(id).valid();
  const { syncVehicleListInDispatch } = useDispatchVehicleState();
  const [atualizar, setAtualizar] = useState(false);
  const [tickTime] = useState(60000);
  const { verifyPermission, commentIsCompressed } = useUserState()
  const { systemConfig } = useSystemState();
  const commentIsCompressedState = useHookstate<boolean>(commentIsCompressed);
  const moveScrolltoComment = useHookstate(false);
  const { createUniqueMarker, deleteAllUniqueMarker, getMarker, markersState, forceViewMap, boundsMapGlobal } = useMarkerState();
  const { registrationDispatch } = useDispatchRegistrationState();
  const {
    actionName,
    openConfirmAction,
    addRegistration,
    externalAction,
    externalActionName
  } = registrationDispatch();

  useEffect(() => {
    dispatchState.getMyLastDispatch()

    return () => {
      dispatchState.setDefaultDispatch()
    }
  }, [])

  useEffect(() => {
    if (id.get()) {
      saveLogVisualizeDispatch(id.get())
    }

    if (id.get() != null) {
      const dispatchMarkerActive = Object.entries(markersState()['dispatch'].get()).filter(([key, value]: any) => value?.isCurrent == true);
      dispatchMarkerActive.length > 0 ? markersState()['dispatch'][dispatchMarkerActive[0][0]] ? markersState()['dispatch'][dispatchMarkerActive[0][0]].isCurrent.set(false) : false : false;
      if (id.get()) {
        if (markersState()['dispatch'][id.get() ?? ''].get()) {
          markersState()['dispatch'][id.get() ?? ''].isCurrent.set(true);
          if (!validShowArea({ position: JSON.parse(JSON.stringify(markersState()['dispatch'][id.get() ?? '']?.position?.get())), boundsMap: boundsMapGlobal().get() })) {
            // if (markersState()['dispatch'][id.get() ?? '']?.position?.get()) {
            forceViewMap().zoom.set(15);
            forceViewMap().position.set(new LatLng(markersState()['dispatch'][id.get() ?? '']?.position?.get().lat, markersState()['dispatch'][id.get() ?? '']?.position?.get().lng));
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id.get()])

  useEffect(() => {
    const intervalSrc = setInterval(() => setAtualizar(true), tickTime)

    return () => clearInterval(intervalSrc)
  }, [tickTime])

  useEffect(() => {
    setAtualizar(false)
  }, [atualizar])

  const handleClickSend = () => {
    setIsFile(!isFile)
  }


  const printDispatch = (id) => {
    window.open(`/dispatch/print/${id}`)
  }

  useEffect(() => {
    if (status?.get()?.id == 8) {
      if (Boolean(getMarker({ markerType: 'dispatch', markerIdentifier: id.get() }))) {
        const params = {
          id: id.get(),
          isCurrent: true,
          position: new LatLng(dispatchState.dispatch()?.occurrence?.latitude.get() ?? 0, dispatchState.dispatch()?.occurrence?.longitude.get() ?? 0),
          location: dispatchState.dispatch()?.occurrence.location.get() ?? '',
          code: dispatchState.dispatch()?.code.get(),
          status: 8,
          subtypeName: dispatchState.dispatch().get().subtype?.name ? dispatchState.dispatch().get().subtype?.name : '',
          typeName: dispatchState.dispatch().get().type?.name ? dispatchState.dispatch().get().type?.name : '',
          dispatchedAt: dispatchState.dispatch().get().dispatchedAt,
          uniqueReference: true,
        }

        createUniqueMarker({ markerType: 'dispatch', markerIdentifier: id.get(), ...params as any })
      }
    } else {
      deleteAllUniqueMarker('dispatch')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id.get(), status?.get()?.id])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Grid container>
        <Grid item md={12}
          width={'100%'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}>
          <Grid container sx={{ margin: 0 }}>
            {loadingDispatchForm.get() ?
              <Grid item md={11}>
                <Paper
                  variant='outlined'
                  sx={{
                    height: '535.50px',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center'
                  }} >
                  <Box style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                  </Box>
                </Paper>
              </Grid>
              : !isValid ?
                <Grid item md={11}>
                  <Paper
                    variant='outlined'
                    sx={{
                      height: '535.50px',
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center'
                    }} >
                    <Box style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography>
                        {translate('No Dispatches')}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid> :
                <>
                  <Grid
                    item md={3.5}
                    width={'100%'}
                    display={'flex'}
                    direction={'column'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  // paddingLeft={1}
                  >
                    <EventInputContainerDispatch
                      readonly={readonly}
                      isValid={isValid}
                      isValidEdit={status.value?.id !== 8 && status.value?.id !== 10}
                      isInvalidRequest={false}
                      subTypeName={subtype?.name}
                      typeName={type?.name}
                      id={id}
                      lat={occurrence.latitude}
                      lon={occurrence.longitude}
                      typeId={type?.id}
                      typeCode={type?.code}
                      subtypeId={subtype?.id}
                      subtypeCode={subtype?.code}
                      cityId={occurrence?.city?.id}
                      districtId={occurrence?.district?.id}
                      type={'dispatch'}
                      location={occurrence?.location}
                      reference={occurrence?.reference}
                      totalSubtypes={totalSubtypes}
                      totalDistricts={totalDistricts}
                    />
                    <DispatchTagList />
                  </Grid>

                  <Grid item md={4} width={'100%'} display={'flex'} direction={'column'} alignItems={'center'} justifyContent={'space-between'} paddingLeft={1}>
                    <Grid
                      container
                      sx={{ height: '100%', width: '100%', margin: 0 }}
                      direction={'column'}
                      justifyContent={'space-between'}
                    >

                      <Grid item mb={1} md={1} xs={1}>
                        {!isFile &&
                          <DispatchSendCommentField
                            disabled={!isValid || status.value?.id === 10 || readonly || (status.value?.id == 8) && !(systemConfig().allowedCommentOnClosedOccurrence.get() === '1')}
                            id={id.get()}
                            name="comment"
                            label={translate('Comment')}
                            required={true}
                            handleClickSend={handleClickSend}
                            moveScrolltoComment={moveScrolltoComment}
                          />
                        }
                        {isFile &&
                          <ImportFileField
                            dispatchId={id.get()}
                            handleClickImport={handleClickSend}
                          />}
                      </Grid>
                      <Grid item md xs sx={{ overflowY: 'auto', width: '100%', height: '100%' }}
                      >
                        <DispatchDisplayComments
                          dispatchId={id.get()}
                          readonly={readonly}
                          commentIsCompressed={commentIsCompressedState}
                          moveScrolltoComment={moveScrolltoComment.get()}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <EventInputRequesterDispatch
                    dispatchId={id.get()}
                    readonly={readonly}
                    isValid={isValid}
                    isValidEdit={status.value?.id !== 8 && status.value?.id !== 10}
                  />
                </>
            }

            <Grid
              item
              md={1}
              width={'100%'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <ButtonActionsVerticalContainer
                readonly={readonly}
                isValid={isValid}
                canAddReportingRecord={canReport.get()}
                canChronology={isValid && canChronology.get()}
                chronology={occurrence.get()?.id}
                canAddVehicles={
                  isValid && canAddVehicles.get() && status.value?.id !== 9 && status.value?.id !== 10
                }
                registration={!!occurrence.get()?.id && status.value?.id !== 10}
                canCopy={isValid && canCopy.get() && status.value?.id !== 8 && status.value?.id !== 10}
                copy={async (params) => dispatchState.copyDispatch(params)}
                canMedia={isValid && canMedia.get() && status.value?.id !== 10}
                media={() => console.log('em Implementação')}
                canUnify={isValid && canUnify.get() && status.value?.id === 1}
                unify={(param) => dispatchState.unificationDispatch(param)}
                canDisunify={isValid && status.value?.id !== 10}
                showDisunify={hasUnified.get()}
                canHighlight={isValid && canHighlight.get() && status.value?.id !== 10}
                highlight={occurrence.get()?.featured}
                dispatchId={id?.get()}
                setHighlight={() =>
                  occurrence.featured.set(!occurrence.featured.get())
                }
                canClose={isValid && canClose.get() && status.value?.id !== 8 && status.value?.id !== 10}
                close={async (params) => dispatchState.closeDispatch(params)}
                canReopen={isValid && canReopen.get() && status.value?.id === 8}
                reopen={async (loading) => dispatchState.reopenDispatch(loading)}
                onHold={isValid && status.value?.id == 9}
                canHold={
                  isValid &&
                  canHold.get() &&
                  (status.value?.id === 1 || status.value?.id === 9)
                }
                hold={async (minutes, comment, loading) =>
                  status.value?.id === 9
                    ? dispatchState.freeDispatch()
                    : dispatchState.holdDispatch(minutes, comment, loading)
                }
                hasTransferGroupDispach={isValid}
                statusId={status.value?.id ?? undefined}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item columnSpacing={1} md={12} sx={{ mt: '10px' }}>
          <ButtonActionsContainer
            isValid={isValid}
            hasHistory={true}
            canSave={true}
            save={
              editingDispatch.get()
                ? () => dispatchState.updateDispatch()
                : editingDispatchRequester.get()
                  ? () => dispatchState.updateDispatchRequester()
                  : undefined
            }
            historyUrl="/window-support"
            listFullDispatchUrl="/dispatch/dispatches/list-full"
            listFullVehiclesUrl="/dispatch/vehicles/list-full"
            hasPrevious={hasPrev.get()}
            previous={() => {
              externalAction.set(true)
              if (addRegistration.get()) {
                actionName.set('cancel')
                openConfirmAction.set(true);
                externalActionName.set('previous')
              } else {
                dispatchState.previousDispatch()
                windowManager()['registration'].open.set(false)
                windowManager()['dispatchProcedure'].open.set(false)
                windowManager()['dispatchProcedure'].procedure.set([])
              }
            }}
            hasNext={hasNext.get()}
            next={() => {
              externalAction.set(true)
              if (addRegistration.get()) {
                actionName.set('cancel')
                openConfirmAction.set(true);
                externalActionName.set('next')
              } else {
                dispatchState.nextDispatch()
                windowManager()['registration'].open.set(false)
                windowManager()['dispatchProcedure'].open.set(false)
                windowManager()['dispatchProcedure'].procedure.set([])
              }
            }}
            canSearch={true}
            canSeeFullList={true}
            canSearchAdvanced={true}
            searchAdvancedUrl={'/dispatch/list'}
            canPrint={isValid && canPrint.get() && status.value?.id === 8}
            print={() => printDispatch(id.get())}
            cancelEdit={() => dispatchState.syncDispatchFromId(dispatchState.dispatch().id.get())}
            canClerk={occurrence?.toClerk?.get()}
            clerk={true}
            canShowDispatchProcedure={true}
          />
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}
