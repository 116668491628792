import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, MenuItem, Autocomplete, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { authFetch } from '~/services/fetch';
import { useHookstate } from '@hookstate/core';
import { LoadingButton } from '@mui/lab';

interface DispatchClosedProps {
  readonly id: number;
  readonly name: string;
  readonly acronym: string;
}

export default function EntrySetDispatchGroupDialog({open, setManuallyDispatchGroup}){
  const loading = useHookstate<boolean>(false);
  const { translate } = useLang();
  const [dispatchGroupList, setDispatchGroupList] = useState<ReadonlyArray<DispatchClosedProps>>([{ id: 0, name: '', acronym: ''}]);
  const [dispatchGroup, setDispatchGroup] = useState<{ id: number; name: string; acronym: string } | null>({ id: 0, name: '', acronym: '' });

  useEffect(() => {
    authFetch({
      url: '/dispatch/group',
      method: 'GET',
    }).then(({ data }) => {
      setDispatchGroupList(data);
    })
  }, [])

  const handleClose = () => {
    clearStates();
    open.set(false);
  };

  const clickOutModalClose = () => {
    open.set(false);
  };

  const action = () => {
    
    if (setManuallyDispatchGroup) 
      setManuallyDispatchGroup({dispatchGroupId: dispatchGroup?.id, loading})
    open.set(false);
    clearStates();
  };

  const clearStates = () => {
    setDispatchGroup(null);
  }
  return (
    <Dialog open={open} onClose={clickOutModalClose} maxWidth={'xs'} fullWidth>
        <DialogTitle>
          Adicionar Grupo de Despacho
        </DialogTitle>
        <DialogContent>

          <FormControl fullWidth size='small' sx={{ mt: 1 }} >
            <Autocomplete
              options={dispatchGroupList}
              noOptionsText={translate('No options')}
              size='small'
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              clearText={translate('Clear')}
              onChange={(event, value) => {
                setDispatchGroup({ id: value?.id ?? 0, name: value?.name ?? '', acronym: value?.acronym ?? '' })
              }}
              value={dispatchGroup}
              renderInput={(params) =>
                <TextField {...params} label={translate('Dispatch Group')}
                />
              }
              renderOption={(props, option) => (
                <MenuItem {...props} sx={{ mt: 1, overflowX: "auto" }} key={option.id} value={option.id}>
                  {`[${option.acronym}] ${option.name}`}
                </MenuItem>
              )}
            />
          </FormControl>

        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={handleClose}>
            {translate('Cancel')}
          </Button>
          <LoadingButton
            loading={loading.get()}
            variant='contained'
            onClick={action}>
            {translate('Submit')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
  )
}