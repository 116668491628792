import React from 'react';
import { IconButton} from '@mui/material';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { useLang } from '~/hooks/useLang';
import { LatLng } from 'leaflet';
import { useMarkerState } from '~/features/Map/stores/MapState';

interface EntryTravelInfoProps {
  readonly title: string
  readonly latitude: number | null;
  readonly longitude: number | null;
}

export default function EntryTravelInfo({title, latitude, longitude }: EntryTravelInfoProps) {
  const { forceViewMap } = useMarkerState();
  const { translate } = useLang();

  return (
    <IconButton 
      title={translate(title)} 
      sx={{ margin: '0 !important' }} 
      disabled={!Boolean(latitude && longitude)}
      onClick={() => forceViewMap().position.set(new LatLng(Number(latitude ? latitude : 0), Number(longitude ? longitude : 0)))} >
    {<TravelExploreIcon />}
  </IconButton>
  )
}