import React from 'react';
import { Card, Chip, Typography, CardContent, IconButton, Tooltip, Stack, Divider, Grid } from '@mui/material';
import { useLocation } from "react-router-dom";
import { createLiveOperation } from '~/features/Map/services/request';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useLang } from '~/hooks/useLang';
import useUserState from "~/features/User/stores/UserState";
import useSurveillanceReportingState from "~/features/VideoMonitoring/stores/SurveillanceReportingState";
import Close from '@mui/icons-material/Close';

export default function MarkerPopupContentCamera({ readonly, cameras, closeThisPopUp }){
  const { translate } = useLang();
  const { pathname } = useLocation();
  const isVideoMonitoringPage = pathname === '/video-monitoring'
  const { verifyPermission } = useUserState();

  const { surveillanceReportingStates } = useSurveillanceReportingState()
  const { surveillanceReporting: { cameraId } } = surveillanceReportingStates();

  const handleCameraClick = (cameraIndex: string) => async () => {
      return await createLiveOperation(cameraIndex);
    }

  return (
        <Card sx={{ backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: 'none' }}>
          <CardContent sx={{ padding: '1em 0 1em 0', textAlign: "left" }} >
            <Grid sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <IconButton size='small' onClick={() => closeThisPopUp()}>
                  <Close fontSize='small'/>
                </IconButton>
            </Grid>
            {cameras.map((camera) => (
              <>
                <Stack direction='row' alignItems='center' key={camera['key']}>
                  <CameraAltIcon sx={{ mr: '10px' }} fontSize="small" />
                  <Typography variant='subtitle1' sx={{ margin: '.6em 0' }}>
                    {camera['display_name'] ? camera['display_name'] : 'Não Informado'}
                  </Typography>
                  {!readonly && verifyPermission(['camera_show_stream']) ? (
                    <IconButton
                      title={translate('Show Camera Video')}
                      color='primary'
                      onClick={handleCameraClick(camera['key'])}
                      sx={{ float: 'right' }}
                    >
                      <PlayCircleIcon />
                    </IconButton>
                  ) : false}
                  {isVideoMonitoringPage && <Tooltip sx={{ ml: 1 }} title={translate('Associate to Report')}>
                    <Chip
                      onClick={() => {
                        cameraId.set(camera['id'])
                      }}
                      label={
                        <Typography variant="subtitle1" >
                          {translate('Use')}
                        </Typography>}
                    />
                  </Tooltip>}
                </Stack>
                <Divider />
              </>
            ))}
          </CardContent>
        </Card>
      );
}