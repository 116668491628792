import React, { useEffect } from 'react';
import { useLang } from '~/hooks/useLang';
import { Box } from '@mui/system';
import { Downgraded, none, useHookstate } from '@hookstate/core';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Badge, Button, Card, CardContent, CardHeader, Divider } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '~/components/ErrorFallback';
import { getVehiclesByStatus } from './services';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import useSocket from '~/hooks/useSocket';
import ListVehiclesSocket from './components/ListVehiclesSocket';
import { IListVehicleSocket, IMessageDispatch, IMessageVehicle, ListVehicleTab } from './interfaces/IListVehiclesProps';
import VehiclesFilters from './components/FiltersVehicle';
import { IFilters } from '../ListDispatchesSocket/services';
import NotificationsCentral from '../NotificationsCentral';
import { useLocation } from 'react-router-dom';
import useListVehiclesSocket from './stores/ListVehiclesSocketState';
import DialogCloseMuiltipleDispatch from '../ListDispatchesSocket/components/DialogCloseMultipleDispatch';
import DialogDisconnectMultipleVehicles from './components/DialogDisconnectMultipleVehicles';
import useUserState from '~/features/User/stores/UserState';
import DisconnectMultipleButton from './components/DisconnectMultipleButton';

export interface IVehicleResponse {
  readonly rows: readonly IListVehicleSocket[];
  readonly rowsCount: number
}

interface TabListVehiclesSocketProps {
  readonly readOnly?: boolean;
  readonly fullList?: boolean;
}

interface IVehiclesLists {
  readonly available: readonly IListVehicleSocket[],
  readonly dispatched: readonly IListVehicleSocket[],
  readonly disconnected: readonly IListVehicleSocket[],
  readonly 'not-in-service': readonly IListVehicleSocket[],
}

// eslint-disable-next-line max-lines-per-function
export default function TabListVehiclesSocket({ fullList = false, readOnly = false }: TabListVehiclesSocketProps) {
  const LIMIT_FULL_LIST = 50;
  const LIMIT = 15;

  const { translate } = useLang();
  const { dispatch } = useDispatchState()
  const { verifyPermission } = useUserState()
  // const { openModalResultMultipleDisconnectVehicle } = useListVehiclesSocket()
  const openModalResultMultipleDisconnectVehicle = useHookstate(false);
  const { id } = dispatch()
  const { socket } = useSocket();
  const tab = useHookstate<ListVehicleTab>('available');
  const vehicles = useHookstate<IVehiclesLists>({
    available: [],
    disconnected: [],
    "not-in-service": [],
    dispatched: []
  });
  const searchVehicle = useHookstate('');
  const searchTask = useHookstate('');
  const targetUrl = useHookstate('/device/available');
  const page = useHookstate(0);
  const limit = useHookstate(fullList ? LIMIT_FULL_LIST : LIMIT);
  const loading = useHookstate(false);
  const withoutPagination = useHookstate(fullList);
  const filters = useHookstate<readonly IFilters[]>([])
  const location = useLocation();
  const vehiclesQuantity = useHookstate({
    available: 0,
    dispatched: 0,
    disconnected: 0,
    'not-in-service': 0,
  })
  const enableButtonShowResume = useHookstate(false)
  const enableCheckbox = useHookstate(false)
  const rowsSelected = useHookstate<readonly IListVehicleSocket[]>([])
  const open = useHookstate(false);

  const handleTabVehicle = (event: React.SyntheticEvent, value: ListVehicleTab) => {
    tab.set(value);
    targetUrl.set(`/device/${value}`)
    vehicles[value].set([])

    page.set(0)
    if (value === 'disconnected' && fullList) {
      withoutPagination.set(false)
      limit.set(LIMIT_FULL_LIST)
    } else if (!fullList) {
      withoutPagination.set(false)
      limit.set(LIMIT)
    } else {
      withoutPagination.set(true)
      limit.set(LIMIT_FULL_LIST)
    }
    handleLoadDevices()
  };

  const loadVehicles = (dispatchesData, status) => {
    vehicles[status].set([])
    const uniquesValues = vehicles[status].get().concat(dispatchesData.data)
    vehicles[status].merge(uniquesValues);
    vehiclesQuantity[status].set(dispatchesData.rowsCount)
  }

  const handleLoadAllVehicles = async () => {
    loading.set(true)

    if (tab.get() == 'available') {
      await getVehiclesByStatus({
        dispatchId: id.get(),
        targetUrl: '/device/available'
      }).then((dispatchesData) => loadVehicles(dispatchesData, 'available'))
    }

    if (tab.get() == 'dispatched') {
      await getVehiclesByStatus({
        dispatchId: id.get(),
        targetUrl: '/device/dispatched'
      }).then((dispatchesData) => loadVehicles(dispatchesData, 'dispatched'))
    }

    if (tab.get() == 'disconnected') {
      await getVehiclesByStatus({
        dispatchId: id.get(),
        filters: [],
        limit: 50,
        targetUrl: '/device/disconnected'
      }).then((dispatchesData) => loadVehicles(dispatchesData, 'disconnected'))
    }

    if (tab.get() == 'not-in-service') {
      await getVehiclesByStatus({
        dispatchId: id.get(),
        targetUrl: '/device/not-in-service'
      }).then((dispatchesData) => loadVehicles(dispatchesData, 'not-in-service'))
    }

    loading.set(false)
  }

  const handleLoadDevices = async () => {
    loading.set(true)
    const devices = await getVehiclesByStatus({
      filters: tab.get() !== 'disconnected' ? filters.get() : [],
      page: page.get(),
      limit: limit.get(),
      withoutPagination: withoutPagination.get(),
      dispatchId: id.get(),
      targetUrl: targetUrl.get()
    })
    // BUSCAR NO ESTADO OS RESULTADOS DA BUSCA E ATUALIZAR
    vehicles[tab.get()].set([]);
    vehicles[tab.get()].set(devices.data);
    vehiclesQuantity[tab.get()].set(devices.rowsCount)
    loading.set(false)
  }

  const checkTabByStatus = {
    1: 'available',
    10: 'disconnected',
    11: 'not-in-service',
  }

  useEffect(() => {
    handleLoadDevices()
    socket.on('devices', (message: IMessageVehicle) => {
      if (message.event_type === 'DEVICE_STATUS_CHANGE') {
        // handleLoadDevices()
        // REMOVENDO DA LISTAGEM ANTERIOR
        const previousStatus = checkTabByStatus[message.content.previous_status.id] ?? 'dispatched'
        const vehicleFindIndex = vehicles[previousStatus].get().findIndex(vehicle => vehicle.id === message.content.device_id)
        const oldStatusVehicle = vehicles[previousStatus][vehicleFindIndex].attach(Downgraded).get()
        vehicles[previousStatus][vehicleFindIndex].set(none)
        const currentStatus = checkTabByStatus[message.content.current_status.id] ?? 'dispatched'
        vehiclesQuantity[previousStatus].set(vehicles[previousStatus].length)

        if (previousStatus === 'disconnected' || !oldStatusVehicle) {
          return
        }

        // INSERINDO NA LISTAGEM ATUAL
        vehicles[currentStatus].merge([{
          ...oldStatusVehicle,
          status: {
            ...message.content.current_status
          }
        }])
        vehiclesQuantity[currentStatus].set(vehicles[currentStatus].length)
      } else if (message.event_type === 'DEVICE_LOG_CONNECTION_DEVICE') {
        const existVehicle = vehicles.available.get().find((vehicle) => vehicle.id === message.content.device.id)

        if (!existVehicle) {
          vehicles.available.merge([{
            id: message.content.device.id,
            name: message.content.device.name,
            dispatch_group: message.content.device.dispatch_group,
            device_log: message.content.device_log,
            vehicle: message.content.device.vehicle,
            status: {
              id: 1,
              name: "DISPONÍVEL",
              acronym: "DI"
            }
          }])
        } else {
          vehicles.available.get().filter((vechicle, indexVehicle) => vechicle.id === message.content.device.id ? vehicles.available[indexVehicle].set({
            id: message.content.device.id,
            name: message.content.device.name,
            dispatch_group: message.content.device.dispatch_group,
            device_log: message.content.device_log,
            vehicle: message.content.device.vehicle,
            status: {
              id: 1,
              name: "DISPONÍVEL",
              acronym: "DI"
            }
          }) : '')
        }
        // handleLoadDevices()
        vehiclesQuantity.available.set(vehicles.available.length)
      } else if (message.event_type === 'DEVICE_LOG_CHANGE_COMPOSITION') {
        // handleLoadDevices()
        const vehicleFindIndex = vehicles[tab.get()].get().findIndex(vehicle => vehicle.id === message.content.device.id)

        if (vehicleFindIndex === -1) {
          return
        }
        vehicles.available[vehicleFindIndex].set(p => ({
          ...p,
          id: message.content.device.id,
          name: message.content.device.name,
          dispatch_group: message.content.device.dispatch_group,
          device_log: message.content.device_log,
          vehicle: message.content.device.vehicle,
        }))
      }
    });
    socket.on('occurrences', (message: IMessageDispatch) => {
      if (message.event_type === 'DISPATCH_TRANSFER') {
        // handleLoadDevices()
      }
      if (message.event_type === 'DISPATCH_STATUS_CHANGED') {
        // handleLoadDevices()
      }
    });
  }, []);

  const handleCheckBox = () => {
    handleLoadDevices()
    return enableCheckbox.set(!enableCheckbox.get())
  }

  const handleClickOpen = () => {
    open.set(true);

  };

  const clickOutModalClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      open.set(false);
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Card sx={{ width: '100%', typography: 'body1' }}>
        <Box sx={{
          display: 'flex', flexDirection: 'row', pt: 2, alignItems: 'center', justifyContent: 'space-between', mr: 2,
        }}>
          <CardHeader title={translate('Devices')} />
          {location.pathname === '/dispatch/vehicles/list-full' &&
            <NotificationsCentral />
          }
        </Box>
        <CardContent>
          <VehiclesFilters
            clearFilters={() => {
              searchTask.set('')
              searchVehicle.set('')
              filters.set([])
              handleLoadDevices()
            }}
            searchVehicle={searchVehicle}
            searchTask={searchTask}
            tab={tab.get()}
          />
          <DisconnectMultipleButton
            tab={tab.get()}
            rowsSelected={rowsSelected.get()}
            enableButtonShowResume={enableButtonShowResume.get()}
            enableCheckbox={enableCheckbox.get()}
            openModalResultMultipleDisconnectVehicle={openModalResultMultipleDisconnectVehicle}
            handleCheckBox={handleCheckBox}
            handleClickOpen={handleClickOpen}
          />
          <TabContext value={tab.get()}>
            <TabList onChange={handleTabVehicle}>
              <Tab
                label={translate('Online')}
                // icon={<Badge showZero badgeContent={vehiclesQuantity.available.get()} color="primary" sx={{ pl: 1.5 }} />}
                iconPosition="end"
                value='available' />
              <Tab
                label={translate('Dispatched')}
                // icon={<Badge showZero badgeContent={vehiclesQuantity.dispatched.get()} color="primary" sx={{ pl: 1.5 }} />}
                iconPosition="end"
                value='dispatched' />
              <Tab
                label={translate('Disconnected')}
                // icon={<Badge showZero badgeContent={vehiclesQuantity.disconnected.get()} color="primary" sx={{ pl: 1.5 }} />}
                iconPosition="end"
                value='disconnected' />
              <Tab
                label={translate('Not in Service')}
                // icon={<Badge showZero badgeContent={vehiclesQuantity['not-in-service'].get()} color="primary" sx={{ pl: 1.5 }} />}
                iconPosition="end"
                value='not-in-service' />
            </TabList>

            <TabPanel sx={{ padding: 0 }} value="available">
              <ListVehiclesSocket
                readOnly={readOnly}
                filters={filters}
                fullList={withoutPagination.get()}
                handleLoadDevices={handleLoadDevices}
                rowsCount={vehiclesQuantity.available.get()}
                limit={limit}
                page={page}
                loading={loading}
                searchVehicle={searchVehicle.get()}
                searchTask={searchTask.get()}
                exceptColumns={['dispatch', 'statusChangedAt', 'type', 'subtype', 'outOfServiceMotive', 'disconnectionDate', 'outOfServiceDate', 'location', 'comment', 'deviceStatusId']}
                statusId={[1]}
                state={vehicles.available}
                targetUrl={targetUrl.get()}
                enableCheckbox={enableCheckbox.get()}
                rowsSelected={rowsSelected}
                handleLoadAllVehicles={handleLoadAllVehicles} />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value="dispatched">
              <ListVehiclesSocket
                readOnly={readOnly}
                filters={filters}
                fullList={withoutPagination.get()}
                handleLoadDevices={handleLoadDevices}
                rowsCount={vehiclesQuantity.dispatched.get()}
                limit={limit}
                page={page}
                loading={loading}
                searchVehicle={searchVehicle.get()}
                searchTask={searchTask.get()}
                exceptColumns={['comment', 'outOfServiceMotive', 'outOfServiceDate', 'disconnectionDate', 'connectionDate']}
                statusId={[4, 5, 6, 7, 8, 9]}
                state={vehicles.dispatched}
                targetUrl={targetUrl.get()}
              />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value="disconnected">
              <ListVehiclesSocket
                readOnly={readOnly}
                filters={filters}
                fullList={tab.get() !== 'disconnected'}
                handleLoadDevices={handleLoadDevices}
                rowsCount={vehiclesQuantity.disconnected.get()}
                limit={limit}
                page={page}
                loading={loading}
                searchVehicle={searchVehicle.get()}
                searchTask={searchTask.get()}
                exceptColumns={['dispatch', 'type', 'subtype', 'statusChangedAt', 'outOfServiceMotive', 'outOfServiceDate', 'connectionDate', 'location', 'deviceStatusId', 'comment', 'task', 'commander', 'phone']}
                statusId={[10]}
                state={vehicles.disconnected}
                targetUrl={targetUrl.get()}
              />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value="not-in-service">
              <ListVehiclesSocket
                readOnly={readOnly}
                filters={filters}
                fullList={withoutPagination.get()}
                handleLoadDevices={handleLoadDevices}
                rowsCount={vehiclesQuantity['not-in-service'].get()}
                limit={limit}
                page={page}
                loading={loading}
                searchVehicle={searchVehicle.get()}
                searchTask={searchTask.get()}
                exceptColumns={['dispatch', 'location', 'type', 'statusChangedAt', 'subtype', 'deviceStatusId', 'connectionDate', 'disconnectionDate']}
                statusId={[11]}
                state={vehicles['not-in-service']}
                targetUrl={targetUrl.get()} />
            </TabPanel>
          </TabContext>
        </CardContent>
      </Card>
      <DialogDisconnectMultipleVehicles
        openDialog={open}
        onClose={clickOutModalClose}
        rowsSelected={rowsSelected}
        openModalResultMultipleDisconnectVehicle={openModalResultMultipleDisconnectVehicle}
        handleLoadDevices={handleLoadDevices}
        enableButtonShowResume={enableButtonShowResume}
        clearFilters={() => {
          searchTask.set('')
          searchVehicle.set('')
          filters.set([])
        }}
      />
    </ErrorBoundary>
  )
}
