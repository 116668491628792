import { useHookstate } from "@hookstate/core";
import { Autocomplete, FormControl, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLang } from "~/hooks/useLang";
import { StatusResponse } from "./interfaces";
import { getStatus } from "./services";

interface SelectOperationProps {
    readonly onStatusChange: (value: number | null) => void;
    readonly statusId?: number | null;
    readonly disabled?: boolean
}

export default function FilterAutocompleteStatus({ onStatusChange, statusId, disabled }: SelectOperationProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly StatusResponse[]>([]);
    const [value, setValue] = useState<StatusResponse | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        getStatus().then(operation => {
            setOptions(operation)
        })
    }, [])

    useEffect(() => {
        if (!statusId) {
            setValue(null)
        }
    }, [statusId])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                id='filter-categories'
                options={options}
                disabled={disabled}
                value={value}
                noOptionsText={translate('No options')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={(_, status) => {
                    setValue(status)
                    onStatusChange(status?.id ?? null)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Status')}
                    />
                )}
            />
        </FormControl>
    )
}