import React, { useEffect } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { State, useHookstate } from '@hookstate/core';
import { getAssociatedAnimalFromOccurrenceService } from '~/features/Occurrence/services/index';

export interface animalHistoryProps {
    readonly breed: {
        readonly id: number,
        readonly name: string
    },
    readonly breed_id: number,
    readonly circunstance: {
        readonly id: number, 
        readonly name: string
    },
    readonly circunstance_id: number,
    readonly created_at: string,
    readonly deleted_at: string,
    readonly destination: {
        readonly id: number, 
        readonly name: string
    },
    readonly destination_id: number,
    readonly id: number,
    readonly notes: string,
    readonly occurrence: {
        readonly id: number, 
        readonly name: string
    },
    readonly occurrence_id: number,
    readonly occurrence_origin_id: number
    readonly specie: {
        readonly id: number, 
        readonly name: string
    },
    readonly specie_id: number,
    readonly updated_at: string
} 

interface AnimalRegisteredInTheOccurrencePrintProps {
  readonly AnimalRegisteredInTheOccurrencePrintResponse: animalHistoryProps[]
}

export default function AnimalRegisteredInTheOccurrencePrint({ AnimalRegisteredInTheOccurrencePrintResponse }: AnimalRegisteredInTheOccurrencePrintProps){
  const { translate } = useLang();
  

  return (
    <Container fixed={false}>
      <Grid container xs={12} sx={{ marginBottom: 2 }}>
        { AnimalRegisteredInTheOccurrencePrintResponse.length != 0 ? (
          AnimalRegisteredInTheOccurrencePrintResponse.map((animal) => (
            <Grid container key={animal?.id} xs={12} sx={{ margin: 1, border: 1, borderStyle: 'dashed' }}>
              <Grid item xs={3} sx={{border: 1, borderStyle: 'dashed', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant='subtitle1' sx={{ alignItems: 'center' }}><b>{animal?.specie?.name}</b></Typography>
              </Grid>
              <Grid container xs={9} padding={2}>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'><b>{translate('Specie')}:</b> {animal?.specie?.name ?? `* ${translate('Uninformed')} *`} </Typography>
                  <Typography variant='subtitle2'><b>{translate('Breed')}:</b> {animal?.breed?.name ?? `* ${translate('Uninformed')} *`} </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'><b>{translate('Circunstance')}:</b> {animal?.circunstance?.name ?? `* ${translate('Uninformed')} *`}</Typography>
                  <Typography variant='subtitle2'><b>{translate('Destination')}:</b> {animal?.destination?.name ?? `* ${translate('Uninformed')} *`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'><b>{translate('Notes')}:</b> {animal?.notes.length ? animal.notes : `* ${translate('Uninformed')} *`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : (
        <Grid item xs={12} textAlign={'center'}>
          <Typography variant='subtitle1'><b>{translate('NO INFORMATION REGISTERED')}</b></Typography>
        </Grid>
        )}
      </Grid>
    </Container>
  )
}