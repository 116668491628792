import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import Layout from "~/components/Layout";
import InterestPointList from "../Components/InterestPointList/index.";
import { useLang } from "~/hooks/useLang";

export default function IntesrestPoint() {
  const { translate } = useLang();

  useEffect(() => {
    document.title = translate('Interest Point - Dispatch System')
  }, [])
  return (
    <Layout marginLayout={true}>
      <InterestPointList />
    </Layout>
  )
}