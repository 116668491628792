import { useHookstate } from '@hookstate/core';
import { Visibility } from '@mui/icons-material';
import React, { useEffect, useMemo } from 'react';
import CrudIndex from '~/components/CrudIndex';
import { ActionButtonsProps } from '~/components/CrudIndex/interfaces/ICrudIndex';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';
import ViewProcedure from '../components/ViewProcedure';

export default function Index() { 
  const { translate } = useLang();

  const idTemp = useHookstate(null);
  const lockFields = useHookstate<boolean>(false);
	const loading = useHookstate<boolean>(false);
  const procedureId = useHookstate(null);
  const forceRefresh = useHookstate<boolean>(false);

  const openDialogViewProcedure = useHookstate(false)

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])       

  const priorityActions = useMemo((): ActionButtonsProps[] => {
      return [
        {
          action: function (row) {
            openDialogViewProcedure.set(true)
            idTemp.set(row['id'])
          },
          label: translate('View'),
          iconButton: <Visibility />
        }
      ]
  }, [])

  const procedureFilters = [
    {
      type: 'procedure_id',
      variable: procedureId
    }
  ]

  return(
    <Layout marginLayout={true}>
      <CrudIndex 
      model='procedure' 
      title='Procedures'
      createTitleComplement="Procedure"
      forceOrderColumns={['name', 'code']}
      priorityActions={priorityActions}
      loading={loading}
      lockFields={lockFields.get()}
      clearFilters={() => {
        procedureId.set(null)
        forceRefresh.set(!forceRefresh.get())
      }} 
      filters={procedureFilters}
      searchButton={() => forceRefresh.set(!forceRefresh.get())}
      forceRefresh={forceRefresh.get()}
      disableColumnsFilters={true}
      />
      <ViewProcedure 
        open={openDialogViewProcedure} 
        handleClose={() => {
          idTemp.set(null)
          openDialogViewProcedure.set(false)
        }} 
        idProcedure={idTemp.get()}
      />
    </Layout>
  )
}