/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import { StateMethods, useHookstate } from '@hookstate/core'
import CloseIcon from '@mui/icons-material/Close';
import { useLang } from '~/hooks/useLang'
import dayjs from 'dayjs'
import { getById } from './services'

export interface ViewProcedureProps {
    readonly model: string
    readonly viewTitle: string
    readonly open: StateMethods<boolean>
    readonly infoId: number | null
    readonly handleClose: () => void
    readonly showInViewMore: string[]
    readonly booleanField?: readonly string[]
}

export default function CrudViewMore({ model, open, infoId, handleClose, showInViewMore, viewTitle, booleanField }: ViewProcedureProps) {
    const { translate } = useLang()
    const infos = useHookstate<any[]>([])
    const loading = useHookstate<boolean>(false)

    useEffect(() => {
        loading.set(true)
        if (infoId) {
            getById(model, infoId).then((data) => {
                infos.set(data)
            }).finally(() => loading.set(false))

        }

    }, [infoId])

    const getLabel = {
        'area_plan:name': 'Area Plan',
        'vehicle:identification': 'Vehicle Identification',
        'vehicle:plate': 'Plate',
        'operation_unit:name': 'Operation unit',
        'dispatch_groups': 'Dispatch Group',
        'specie:name': 'Specie',
    }


    const clickOutModalClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            open.set(false)
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={open.get()}
            onClose={clickOutModalClose}
            disableEscapeKeyDown={true}
        >
            <DialogTitle display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                {translate(viewTitle)}
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container xs={12} display={'flex'} flexDirection={'row'}>
                    {
                        loading.get() ?
                            <Grid item xs={12} display={'flex'} sx={{ justifyContent: 'center' }}>
                                <CircularProgress />
                            </Grid>
                            :
                            showInViewMore.map((item, index) => {
                                if (item == 'created_at') {
                                    return (
                                        <Grid item xs={3} key={index} sx={{ pr: 1 }}>
                                            <Typography variant='subtitle1' fontWeight={'bold'}>
                                                {getLabel[item] ? translate(getLabel[item]) : translate(item)}
                                                :
                                            </Typography>
                                            <Typography variant='subtitle2'>
                                                {infos[item]?.get() ? dayjs(infos[item]?.get()).format('DD/MM/YYYY') : translate('Uninformed')}
                                            </Typography>
                                        </Grid>
                                    )
                                } else if (Array.isArray(infos[item]?.get())) {
                                    return (
                                        <Grid item xs={3} key={index} sx={{ pr: 1 }}>
                                            <Typography variant='subtitle1' fontWeight={'bold'}>
                                                {getLabel[item] ? translate(getLabel[item]) : translate(item[0].toUpperCase() + item.slice(1))}
                                                :
                                            </Typography>
                                            <Typography variant='subtitle2'>
                                                {infos[item]?.get() ? infos[item]?.get().map(info => info.name).join(', ') : translate('Uninformed')}
                                            </Typography>
                                        </Grid>
                                    )
                                } else {
                                    const infoDependent = item.split(':')

                                    return (
                                        <Grid item xs={3} key={index} sx={{ pr: 1 }}>
                                            <Typography variant='subtitle1' fontWeight={'bold'}>
                                                {getLabel[item] ? translate(getLabel[item]) : translate(item[0].toUpperCase() + item.slice(1))}
                                                :
                                            </Typography>
                                            <Typography variant='subtitle2'>
                                                {infoDependent?.length > 1 ?
                                                    infos[infoDependent[0]]?.[infoDependent[1]]?.get() ?
                                                        infos[infoDependent[0]]?.[infoDependent[1]]?.get() : translate('Uninformed')
                                                    :
                                                    booleanField?.includes(item) ?
                                                        infos[item]?.get() ? 'Sim' : 'Não'
                                                        :
                                                        infos[item]?.get() ? infos[item]?.get() : translate('Uninformed')
                                                }
                                            </Typography>
                                        </Grid>
                                    )
                                }
                            })
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    )
}