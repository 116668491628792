/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useRef } from 'react';
import { useState, Downgraded, StateMethods, State } from '@hookstate/core';
import { SearchRounded } from "@mui/icons-material";
import Autocomplete, { AutocompleteProps, AutocompleteInputChangeReason, AutocompleteChangeReason, AutocompleteChangeDetails, } from '@mui/material/Autocomplete';
import { TextField, Icon, CircularProgress, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { debounce } from 'lodash';
import { CancelTokenSource } from 'axios';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../ErrorFallback';

export type subtitle = {
	readonly type?: 'default' | 'error' | 'success',
	readonly color?: string,
	readonly message?: string
}

interface FetchAutoCompleteProps<T> {
	readonly fetchFunc: (...params) => {
		readonly promise: Promise<readonly T[]>,
		readonly cancelToken?: CancelTokenSource
	};
	readonly setValueInput?: (value: any) => any;
	readonly optionKey?: keyof T;
	readonly setFunc?: (option: T, opts?: SetFuncOpts) => Promise<{ readonly ended: boolean, readonly value?: string }>;
	readonly isFetch?: boolean;
	readonly stateValue?: State<string | null>;
	readonly noOptionsString?: string;
	readonly dialogOpenState?: StateMethods<boolean>;
	readonly debounceDelay?: number;
	readonly subtitleDelay?: number;
	readonly minFetchLength?: number;
	readonly startIcon?: boolean;
	readonly startIconElement?: JSX.Element;
	readonly autoCompleteProps?: {
		readonly getOptionLabel?: (option: T) => string,
		readonly size?: 'small' | 'medium',
		readonly placeholder?: string,
		readonly errorMsg?: boolean,
		// eslint-disable-next-line functional/prefer-readonly-type
		filterOptions?: (options: Array<T>, state: object) => Array<T>
	}
	readonly isValid: boolean;
	readonly error?: boolean | undefined;
	readonly sx?: {
		readonly color?: string
	},
	readonly idValue?: number | null;
	readonly clearField?: boolean;
}
export type SetFuncOpts = {
	readonly inputValue?: StateMethods<string>;
}

// eslint-disable-next-line max-lines-per-function
export function FetchAutoComplete<T = any>({
	fetchFunc,
	setFunc,
	optionKey,
	stateValue,
	autoCompleteProps = {
		placeholder: 'Fetch',
		size: 'small',
		errorMsg: true
	},
	setValueInput,
	noOptionsString = 'Nothing',
	startIconElement = (<SearchRounded />),
	startIcon = false,
	dialogOpenState,
	isFetch = true,
	debounceDelay = 800,
	subtitleDelay = 1500,
	minFetchLength = 3,
	isValid,
	error,
	sx={},
	idValue,
	clearField=false
}: FetchAutoCompleteProps<T>) {
	const { translate } = useLang();
	const isFirstRender = useRef(true);
	const inputRef = useRef<HTMLDivElement | null>(null);
	const isAwaiting = useState<boolean>(false);
	const optionsState = useState<readonly T[]>([]);
	const subtitleState = useState<subtitle>({ type: undefined });
	const valueState = useState(stateValue?.get() ?? "");
	const currentReason = useRef<AutocompleteInputChangeReason | null>(null);
	valueState.attach(Downgraded);

	useEffect(() => {
		if(!idValue && clearField){
			valueState.set("")
		}
	
	}, [idValue])

	const checkIfCanSend = (value: string) => {
		if (value.length < minFetchLength) {
			return false;
		}

		return true;
	}

	const handleSearchFunc = (value: string) => {
		isAwaiting.set(true);

		const { promise, cancelToken } = fetchFunc(value);

		promise
			.then((genericArr) => {
				if (!genericArr.length) {
					return;
				}
				optionsState.set(genericArr);
			})
			.catch(err => {
				subtitleState.set({ type: 'error', message: err?.message ?? `${translate('Something went wrong! Try again')}!` });
			})
			.finally(() => isAwaiting.set(false));

		return cancelToken;
	}

	const handleSetFunc = (setFunc: (option: T, opts?: SetFuncOpts) => Promise<{ readonly ended: boolean, readonly value?: string }>, value: T) => {

		isAwaiting.set(true);
		setFunc(value)
			.then(data => {
				if (data.value) {
					valueState.set(data.value);
				}
				dialogOpenState?.set(!data.ended);
				isAwaiting.set(false);
			});

	}

	const debounceHandleSearchFunc = debounce(handleSearchFunc, debounceDelay);

	const onInputChangeFunc: (evt: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => void = (evt, value, reason) => {
		currentReason.current = reason;
		if (reason === 'reset') return;
		valueState.set(value);
	}
	const onChangeFunc = (event: React.SyntheticEvent<Element, Event>, value: T | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<T> | undefined) => {

		if (value) {
			if (setFunc) {
				if (reason === 'selectOption') {

					if (setValueInput) {
						valueState.set(value ? setValueInput(value) : value as T)
					}

					handleSetFunc(setFunc, value);
				}
			}
		} else {
			if (setValueInput) {
				setValueInput(null);
			}
		}
	}


	useEffect(() => {
		if (isFirstRender.current) return () => isFirstRender.current = false;
		// let active = true;
		let cancelTokenTemp: CancelTokenSource | undefined = undefined;

		const actualValue = valueState.get();

		if (checkIfCanSend(actualValue) && currentReason.current !== 'reset') {
			cancelTokenTemp = debounceHandleSearchFunc(actualValue);
		}

		return () => {
			if (cancelTokenTemp) cancelTokenTemp.cancel();
			debounceHandleSearchFunc.cancel();
		}


	}, [valueState.get()]);

	useEffect(() => {
		const actualValue = valueState.get();

		if (stateValue) stateValue.set(actualValue);

	}, [valueState.get()]);

	useEffect(() => {
		const value = stateValue?.get();

		if (value) {
			valueState.set(value);
		} else {
			valueState.set("");
		}


	}, [stateValue?.get()]);

	useEffect(() => {
		let active = true;
		const reason = subtitleState.type.get();

		const timeouToken = setTimeout(() => {
			if ((reason === 'error') && active) subtitleState.set({ type: undefined });
		}, subtitleDelay);

		return () => {
			active = false;
			clearTimeout(timeouToken);
		};

	}, [subtitleState.type.get()]);


	return (
		<Autocomplete<T>
			{...autoCompleteProps}
			sx={sx}
			noOptionsText={
				<Typography sx={{ textAlign: 'center' }} >
					{isAwaiting.get() ?
						(<>
							{<CircularProgress disableShrink size={30} />}
						</>)
						:
						(<>
							{translate(noOptionsString)}
						</>)
					}
				</Typography>
			}
			options={optionsState.get()}
			inputValue={valueState.get()}
			disabled={!isValid}
			onInputChange={onInputChangeFunc}
			onChange={onChangeFunc}
			renderInput={
				({ ...props }) => {
					return (
						<TextField
							{...props}
							fullWidth
							size={autoCompleteProps.size ?? 'small'}
							ref={inputRef}
							placeholder={autoCompleteProps.placeholder && translate(autoCompleteProps.placeholder)}
							error={error ? error : subtitleState.type.get() === 'error'}
							helperText={autoCompleteProps.errorMsg && subtitleState.message.get()}
							InputProps={{
								...props.InputProps,
								startAdornment: (
									<>
										{startIcon ?
											(<Icon fontSize={autoCompleteProps.size ?? 'small'}>
												{isAwaiting.get() ? (<CircularProgress disableShrink size={20} />) : (<>
													{startIconElement}
												</>)}
											</Icon>)
											:
											null
										}
									</>
								)
							}}
						/>
					);
				}
			}
		/>
	);
}