/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { getStations } from './services';
import IStation from '~/features/Station/interfaces/IStation';
import { StationsResponse } from './interfaces';

interface StationProps {
    readonly onStationsChange: (value: number[]) => void;
    readonly stationsId?: readonly number[];
    readonly disabled?: boolean
}

export default function FilterAutocompleteStations({ onStationsChange, stationsId }: StationProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly StationsResponse[]>([]);
    const [value, setValue] = useState<StationsResponse[] | undefined>([]);
    const loading = useHookstate(false);

    useEffect(() => {
        getStations().then(stations => {
            setOptions(stations)
            setValue([])
        })
    }, [])

    useEffect(() => {
        if (!stationsId?.length) {
            setValue([])
        }
    }, [stationsId])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                filterSelectedOptions
                disableCloseOnSelect
                limitTags={2}
                sx={{ height: 1 }}
                ListboxProps={{ style: { maxHeight: '15rem' } }}
                id='filter-stations'
                multiple
                options={options}
                value={value}
                noOptionsText={translate('No options')}
                clearText={translate('Clear')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={(_, type) => {
                    setValue(type)
                    onStationsChange(type.map(type => Number(type.id)) ?? [])
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Stations')}
                    />
                )}
            />
        </FormControl>
    )
}