import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core'
import { Grid, Typography } from '@mui/material'
import PieChart from '~/components/PieChart'
import { useLang } from '~/hooks/useLang'
import { getDispatchByType, getDispatchBySubtype } from './services/index'
import { IDespacthStatisticGraphProps } from '../../interfaces/IDispacthStatistic'

type PayloadProps = {
  readonly withSolvedThirdParty?: boolean
  readonly exludeClosed?: boolean
}
type ResponseProps = ReadonlyArray<{
  readonly item: string
  readonly value: number
}>

export default function DispatchByTypeAndSubtypeCharts({
  loading,
  agencyId,
  operationId,
  deviceId,
  dispatchGroupId,
  category,
  type,
  subtype,
  dateStart,
  dateFinish,
  thirdParty,
  refresh,
  consummated,
  statusIds,
  cityIds,
  groupSetId,
  location,
  closedStatusClassId,
  closedStatusId,
  qualifierOptions,
  expandFilterSearch,
  operationUnitId
}: IDespacthStatisticGraphProps) {
  const { translate } = useLang()
  const typeData = useHookstate<ResponseProps>([])
  const subtypeData = useHookstate<ResponseProps>([])
  const payload = useHookstate<PayloadProps>({
    withSolvedThirdParty: thirdParty,
    exludeClosed: false,
  })
  const typeTotal = useHookstate<number>(0)
  const subtypeTotal = useHookstate<number>(0)

  useEffect(() => {
    typeTotal.set(0)
    subtypeTotal.set(0)
    payload.set({ withSolvedThirdParty: thirdParty, exludeClosed: false })
    loading.set(true)
    getDispatchByType({
      agencyId,
      deviceId,
      dispatchGroupIds: dispatchGroupId,
      operationId: operationId,
      categoryIds: category,
      typeIds: type,
      subtypeIds: subtype,
      startDate: dateStart,
      finishDate: dateFinish,
      payload: payload.get(),
      consummated: consummated,
      statusIds: statusIds,
      cityIds: cityIds,
      groupSetId: groupSetId,
      location: location,
      closedStatusClassId: closedStatusClassId,
      closedStatusId: closedStatusId,
      qualifierOptions: qualifierOptions,
      operationUnitId: operationUnitId
    })
      .then((response) => {
        const result = response.map((responseData) => {
          typeTotal.set(typeTotal.get() + responseData.total)
          return { item: responseData.name, value: responseData.total }
        })
        return typeData.set(result)
      })
      .finally(() => {
        loading.set(false)
      })

    getDispatchBySubtype({
      agencyId,
      deviceId,
      dispatchGroupId: dispatchGroupId,
      categoryIds: category,
      typeIds: type,
      subtypeIds: subtype,
      startDate: dateStart,
      endDate: dateFinish,
      payload: payload.get(),
      operationId,
      consummated,
      statusIds,
      cityIds,
      groupSetId: groupSetId,
      location: location,
      closedStatusClassId: closedStatusClassId,
      closedStatusId: closedStatusId,
      qualifierOptions: qualifierOptions,
      operationUnitId: operationUnitId
    })
      .then((response) => {
        const result = response.map((responseData) => {
          subtypeTotal.set(subtypeTotal.get() + responseData.total)
          return { item: responseData.name, value: responseData.total }
        })
        return subtypeData.set(result)
      })
      .finally(() => {
        loading.set(false)
      })
  }, [refresh])

  return (
    <Grid container xs={12} sx={{ display: 'flex', paddingX: 3, paddingTop: 3, justifyContent: 'space-between'}}>
      <Grid item xs={12} hidden={!expandFilterSearch}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
          {translate('Dispatch by Type and Subtype')}
        </Typography>
      </Grid>
      <Grid item xs={6} hidden={!expandFilterSearch}>
        <PieChart
          data={typeData.attach(Downgraded).get()}
          title={`${translate('Type Chart')}`}
          subtitle={`Total: ${typeTotal.get()}`}
        />
      </Grid>
      <Grid item xs={6} hidden={!expandFilterSearch}>
        <PieChart
          data={subtypeData.attach(Downgraded).get()}
          title={'Subtype Chart'}
          subtitle={`Total: ${subtypeTotal.get()}`}
        />
      </Grid>
    </Grid>
  )
}
