import React from 'react';
import { createState, DevTools, State, useState } from '@hookstate/core';
import { IListDispatch } from '~/features/Dispatch/components/TabListDispatch/interfaces/IListDispatch';
import dayjs from 'dayjs';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import useDispatchRelatedState from '~/features/Dispatch/stores/DispatchRelatedState';

interface IDispatchRowsTable {
  'pending': IListDispatch[],
  'dispatched': IListDispatch[],
  'done': IListDispatch[],
  'on-hold': IListDispatch[]
}

const dispatchRowsTableDefault = {
  'pending': [],
  'dispatched': [],
  'done': [],
  'on-hold': []
}

const dispatchRowsTable = createState<IDispatchRowsTable>(dispatchRowsTableDefault)

export function sortDispatchTable(dispaches){
  // const result = dispaches.sort((dispatchA, dispatchB) => dayjs(dispatchA.elapsed_time).isAfter(dayjs(dispatchB.elapsed_time)) ).slice(0, 1000)
  return dispaches;
}

export default function useDispatchTableState() {
  const dispatchRowsTableState = useState(dispatchRowsTable);
  const {dispatch} = useDispatchState()
  const {syncRelatedDispatches} = useDispatchRelatedState()

  return {
    dispatchRowsTable: () => dispatchRowsTableState,
    // eslint-disable-next-line
    addDispatchLineTable: ({ id, occurrence_id, code, status, statusId, dispatch_group, type, subtype, location, city, district, notes, priority, elapsed_time, highlight, dispatched_at }) => {
      const allDispatch = dispatchRowsTableState.get()['pending'].concat(dispatchRowsTableState.get()['dispatched']).concat(dispatchRowsTableState.get()['done']).concat(dispatchRowsTableState.get()['on-hold'])      
      const dispatchIndexUpdate = allDispatch.findIndex((row) => row['id'] == id)
      const result = JSON.parse(JSON.stringify(allDispatch))

      try{
        if(!!dispatchIndexUpdate && dispatchIndexUpdate != 0){
          const dispatchResult = [{
            id: id,
            // eslint-disable-next-line
            closure_requested: 0, call: 1, recall_was_viewed: 0, occurrence_id: occurrence_id, elapsed_time: elapsed_time, status_updated_at: elapsed_time, dispatched_at: dispatched_at, dispatch_group: dispatch_group,
            code: code,
            status: status,
            statusId: statusId,
            type: type,
            subtype: subtype,
            location: location,
            city: city,
            district: district,
            notes: notes,
            priority: priority,
            highlight: highlight
            
          }, ...result];

          dispatchRowsTableState.set({
            pending: JSON.parse(JSON.stringify(sortDispatchTable(dispatchResult.filter((row) => row['statusId'] == 1)))),
            dispatched: JSON.parse(JSON.stringify(sortDispatchTable(dispatchResult.filter((row) => (row['statusId'] > 1 && row['statusId'] < 8))))),
            done: JSON.parse(JSON.stringify(sortDispatchTable(dispatchResult.filter((row) => row['statusId'] == 8)))),
            'on-hold': JSON.parse(JSON.stringify(sortDispatchTable(dispatchResult.filter((row) => row['statusId'] == 9)))),
          })
        }
      } catch (error){
        console.log(error) 
        throw error
      }

    },
    updateOccurrenceRowsTable: ({occurrenceId, closureRequested, recallWasViewed, elapsedTime}: any) => {
      const allDispatch = dispatchRowsTableState.get()['pending'].concat(dispatchRowsTableState.get()['dispatched']).concat(dispatchRowsTableState.get()['done']).concat(dispatchRowsTableState.get()['on-hold'])      
      const dispatchesUpdate = allDispatch.filter((row) => row['occurrence_id'] == occurrenceId)
      try {
        for(const dispatchUpdate of dispatchesUpdate){
          const dispatchIndexUpdate = allDispatch.findIndex((row) => row['id'] == dispatchUpdate['id'])
          if(dispatchIndexUpdate || dispatchIndexUpdate == 0 ){
            let currentDispatchUpdate = JSON.parse(JSON.stringify(allDispatch[dispatchIndexUpdate]))
            if(closureRequested){
              currentDispatchUpdate['closure_requested'] = closureRequested
            }
            if(recallWasViewed != undefined){
              currentDispatchUpdate['recall_was_viewed'] = recallWasViewed
            }
            if(elapsedTime){
              currentDispatchUpdate['status_updated_at'] = elapsedTime
              currentDispatchUpdate['elapsed_time'] = elapsedTime
            }

            allDispatch[dispatchIndexUpdate] = currentDispatchUpdate
          }
        }

        dispatchRowsTableState.set({
          pending: JSON.parse(JSON.stringify(sortDispatchTable(allDispatch.filter((row) => row['statusId'] == 1)))),
          dispatched: JSON.parse(JSON.stringify(sortDispatchTable(allDispatch.filter((row) => (row['statusId'] > 1 && row['statusId'] < 8))))),
          done: JSON.parse(JSON.stringify(sortDispatchTable(allDispatch.filter((row) => row['statusId'] == 8)))),
          'on-hold': JSON.parse(JSON.stringify(sortDispatchTable(allDispatch.filter((row) => row['statusId'] == 9)))),
        })
      } catch (error){
        throw error
      }
    },
    updateDispatchRowsTable: ({dispatchId, status, featured, city, district, location, type, subtype, priority, dispatchGroup, elapsedTime }: any) => {
      const allDispatch = dispatchRowsTableState.get()['pending'].concat(dispatchRowsTableState.get()['dispatched']).concat(dispatchRowsTableState.get()['done']).concat(dispatchRowsTableState.get()['on-hold'])
      const dispatchIndexUpdate = allDispatch.findIndex((row) =>  row['id'] == Number(dispatchId))
      
      if(dispatchIndexUpdate || dispatchIndexUpdate == 0 ){
        try {

        let currentDispatchUpdate = JSON.parse(JSON.stringify(allDispatch[dispatchIndexUpdate]))

        if(status){
          currentDispatchUpdate['statusId'] = status['id']
          currentDispatchUpdate['status'] = status['acronym']
        }
        if(featured != undefined && featured != null ){
          currentDispatchUpdate['highlight'] = featured
        }
        if(city){
          currentDispatchUpdate['city'] = city
        }
        if(district){
          currentDispatchUpdate['district'] = district
        }
        if(location){
          currentDispatchUpdate['location'] = location
        }
        if(type){
          currentDispatchUpdate['type'] = type
          currentDispatchUpdate['subtype'] = subtype
          currentDispatchUpdate['priority'] = priority
        }
        if(dispatchGroup){
          currentDispatchUpdate['dispatch_group'] = dispatchGroup
        }
        if(elapsedTime){
          currentDispatchUpdate['status_updated_at'] = elapsedTime
          currentDispatchUpdate['elapsed_time'] = elapsedTime
        }

        allDispatch[dispatchIndexUpdate] = currentDispatchUpdate

        dispatchRowsTableState.set({
          pending: JSON.parse(JSON.stringify(sortDispatchTable(allDispatch.filter((row) => row['statusId'] == 1)))),
          dispatched: JSON.parse(JSON.stringify(sortDispatchTable(allDispatch.filter((row) => (row['statusId'] > 1 && row['statusId'] < 8))))),
          done: JSON.parse(JSON.stringify(sortDispatchTable(allDispatch.filter((row) => row['statusId'] == 8)))),
          'on-hold': JSON.parse(JSON.stringify(sortDispatchTable(allDispatch.filter((row) => row['statusId'] == 9)))),
        })
      
        } catch (error){
          throw error
        }
      }
    },
    updateCloseMultipleDispatchRowsTable: ({dispatches}) => {
      const allDispatch = dispatchRowsTableState.get()['pending'].concat(dispatchRowsTableState.get()['dispatched']).concat(dispatchRowsTableState.get()['done']).concat(dispatchRowsTableState.get()['on-hold'])
      dispatches.map(({dispatch_id: dispatchId, status_updated_at: statusUpdatedAt}) => {
        if (dispatch().id.get() == dispatchId){
          dispatch().status.set({
            id: 8,
            name: 'FECHADA',
            acronym: 'FC',
          })
          dispatch().closedAt.set(new Date(statusUpdatedAt))
          syncRelatedDispatches(dispatchId)
        }
        const dispatchIndexUpdate = allDispatch.findIndex((row) =>  row['id'] == Number(dispatchId))
      
        if(dispatchIndexUpdate || dispatchIndexUpdate == 0 ){
          try {

            let currentDispatchUpdate = JSON.parse(JSON.stringify(allDispatch[dispatchIndexUpdate]))

              currentDispatchUpdate['statusId'] = 8
              currentDispatchUpdate['status'] = 'FC'
              currentDispatchUpdate['status_updated_at'] = new Date(statusUpdatedAt)
              currentDispatchUpdate['elapsed_time'] = new Date(statusUpdatedAt)


            allDispatch[dispatchIndexUpdate] = currentDispatchUpdate
          } catch (error){
            throw error
          }
        }
      })
      dispatchRowsTableState.set({
        pending: JSON.parse(JSON.stringify(sortDispatchTable(allDispatch.filter((row) => row['statusId'] == 1)))),
        dispatched: JSON.parse(JSON.stringify(sortDispatchTable(allDispatch.filter((row) => (row['statusId'] > 1 && row['statusId'] < 8))))),
        done: JSON.parse(JSON.stringify(sortDispatchTable(allDispatch.filter((row) => row['statusId'] == 8)))),
        'on-hold': JSON.parse(JSON.stringify(sortDispatchTable(allDispatch.filter((row) => row['statusId'] == 9)))),
      })
    }
  }
}