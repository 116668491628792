import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import Layout from '~/components/Layout';
import IFriendlyType from '../interfaces/IFriendlyType';

export default function Create() {
  const { register, handleSubmit, control, setValue: setFormValue } = useForm<IFriendlyType>();
  const fields = [
    { name: 'name', type: 'string', required: true },
    { name: 'description', type: 'string', required: false },
    {
      name: 'changeTypeSubtype',
      type: 'selectAutoCompleteAgregate',
      namePrimary: 'type_id',
      nameSecondary: 'subtype_id',
      modelPrimary: 'type',
      modelSecondary: 'subtype',
      placeholderPrimary: 'Type',
      placeholderSecondary: 'Subtype',
      required: true
    },
  ];

  return (
    <Layout marginLayout={true}>
      <CrudCreate model={'friendly-type'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Type/Subtype successfully created!'}
        title={'Add Type/SubType Friendly'}
        subheader={'Add new Type/Subtype Friendly'}
        setFormValue={setFormValue}
      />
    </Layout>
  )
}
