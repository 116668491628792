/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { FormControl, Grid } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useDispatchRegistratinOrganState from '../../../stores/DispatchRegistrationOrganState';
import useDispatchRegistrationState from '../../../stores/DispatchRegistrationState';
import { maskCPF, maskNames, validate } from '../../../utils/stringsManipulations';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import Buttons from '../../DispatchRegistrationTab/components/Buttons';
import notify from '~/utils/notify';
import FieldAutocompleteRegistration from '~/components/FieldAutocompleteRegistration';
import { useHookstate } from '@hookstate/core';

interface IFormorgans {
  readonly handleClickOpenConfirmAction: (action: string) => void
}

interface IErrosProps {
  readonly cpf: boolean;
  readonly name: boolean;
  readonly auxiliaryOrgans: boolean;
  readonly actionTaken: boolean;
  readonly registration: boolean;
}

export default function OrgansForm({ handleClickOpenConfirmAction }: IFormorgans) {
  const loading = useHookstate<boolean>(false)
  const { dispatch } = useDispatchState();
  const { occurrence, status } = dispatch();
  const { id: occurrenceIdState, } = occurrence;
  const disableFields = status.value.id === 8
  const { registrationOrganDispatch, sendOrgans, editOrgans, getAuxiliaryOrgans, getActionTaken, deleteOrgans } = useDispatchRegistratinOrganState();

  const { registrationDispatch } = useDispatchRegistrationState();
  const { addRegistration } = registrationDispatch();

  const { organ } = registrationOrganDispatch();


  const { translate } = useLang();

  const [error, setError] = useState<IErrosProps>({
    cpf: false,
    auxiliaryOrgans: false,
    actionTaken: false,
    name: false,
    registration: false,
  });

  const someInvalidInput = () => {
    if (!organ.auxiliaryOrgans) {
      setError({
        ...error,
        auxiliaryOrgans: true
      })
      return true;
    }
    if (!organ.actionTaken) {
      setError({ ...error, actionTaken: true })
      return true;
    }
    if (!organ.name) {
      setError({
        ...error,
        name: true
      })
      return true;
    }
    if (!organ.cpf && !organ.registration) {
      setError({
        ...error,
        cpf: true,
        registration: true
      })
      return true;
    }

    return false;
  }

  const handleSend = useCallback(async () => {
    loading.set(true)
    await sendOrgans(organ.get()).finally(() => {
      loading.set(false)
    })
    notify({ message: translate('Successfully created'), type: 'success' })
    addRegistration.set(false)
  }, [addRegistration, organ, sendOrgans, translate])

  const handleEdit = useCallback(async () => {
    loading.set(true)
    await editOrgans(organ.get()).finally(() => {
      loading.set(false)
    })
    notify({ message: translate('Saved successfully'), type: 'success' })
    addRegistration.set(false)
  }, [addRegistration, editOrgans, organ, translate])

  useEffect(() => {
    organ.set(prev => ({ ...prev, occurrenceId: occurrenceIdState.get() }))
  }, [])


  return (
    <FormControl fullWidth size='small'>
      <Grid container direction='row'>
        <Grid item xs={6} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={organ.auxiliaryOrgans.get()?.id ?? ''}
            onError={(message) => setError({ ...error, auxiliaryOrgans: message })}
            isRequired
            error={error.auxiliaryOrgans}
            label={translate('Auxiliary Organ')}
            onValueChange={(value) => {
              organ.auxiliaryOrgans.set({
                id: value?.id ?? '',
                name: value?.name ?? ''
              })
            }}
            optionsFetch={getAuxiliaryOrgans}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={organ.actionTaken.get()?.id ?? ''}
            onError={(message) => setError({ ...error, actionTaken: message })}
            isRequired
            error={error.actionTaken}
            label={translate('Action Taken')}
            onValueChange={(value) => {
              organ.actionTaken.set({
                id: value?.id ?? '',
                name: value?.name ?? ''
              })
            }}
            optionsFetch={getActionTaken}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: 1 }}>
          <TextField
            fullWidth
            disabled={disableFields}
            size='small'
            error={!!error.name}
            id="outlined-name"
            label={translate('Receiver name')}
            value={organ.name.get()}
            required
            InputProps={{
              onChange: (event) => {
                const name = maskNames(event.target.value)
                organ.name.set(name)
                if (!name.length) {
                  setError({
                    ...error,
                    name: true
                  })
                } else {
                  setError({ ...error, name: false })
                }
              },
            }}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            error={!!error.cpf}
            size='small'
            id="outlined-cpf"
            label={translate('Receiver CPF')}
            value={organ.cpf.get()}
            InputProps={{
              onChange: (event) => {
                const cpf = maskCPF(event.target.value)
                organ.cpf.set(cpf)
                if (validate(cpf) || !cpf.length) {
                  setError({ ...error, cpf: false, registration: false })
                } else {
                  setError({
                    ...error,
                    cpf: true
                  })
                }
              },
            }}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-registration"
            error={!!error.registration}
            label={translate('Receiver Registry')}
            value={organ.registration.get()}
            InputProps={{
              onChange: (event) => {
                const registration = event.target.value
                organ.registration.set(registration)
                if (registration.length) {
                  setError({ ...error, cpf: false, registration: false })
                }
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ padding: 1, marginBottom: 1 }}>
          <TextField
            disabled={disableFields}
            id="outlined-multiline-flexible"
            label={translate('Note')}
            multiline
            fullWidth
            rows={4}
            size='small'
            value={organ.notes.get()}
            InputProps={{
              onChange: (event) => {
                const notes = event.target.value
                organ.notes.set(notes)
              },
            }}
            sx={{ height: 92 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Buttons
          loading={loading}
            isUpdate={!!organ.id.get()}
            handleClickOpenConfirmAction={handleClickOpenConfirmAction}
            handleEdit={handleEdit}
            handleSend={handleSend}
            someInvalidInput={someInvalidInput}
            disableFields={disableFields}
          />
        </Grid>
      </Grid>
    </FormControl >

  );
}