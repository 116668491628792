import React from 'react';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { AppRouteProps } from '~/routes';

export default function UnitMeasurementTypeRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    {path: '/unit-measurement', component: Index, isProtected: true, permissions: ['unit_measurement_index']},
    {path: '/unit-measurement/create', component: Create, isProtected: true, permissions: ['unit_measurement_create']},
    {path: '/unit-measurement/edit/:id', component: Edit, isProtected: true, permissions: ['unit_measurement_edit']},

  ];

  return routes;
}
