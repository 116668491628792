/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { authFetch } from '~/services/fetch';
import IUser from '~/features/User/interfaces/IUser';
import { StateMethods } from '@hookstate/core';

interface FilterAutocompleteFetchProps {
  readonly onFetchChange?: (value: number | null) => void;
  readonly disabled?: boolean
  readonly urlFetch?: string;
  readonly label: string;
  readonly size?: 'medium' | 'small';
  readonly defaultFetch?: boolean;
  readonly userDefault?: IUser | null;
  readonly resetValues?: boolean;
  readonly setFormValue?: any;
  readonly register?: string;
  readonly setCheckedChange?: any;
  readonly dataRequest?: string;
  readonly focusChange?: StateMethods<boolean>;
}

export default function FilterAutocompleteFetch({ disabled, userDefault, urlFetch, resetValues, defaultFetch, label, dataRequest = 'user', size = 'medium', onFetchChange, setFormValue, register, setCheckedChange, focusChange }: FilterAutocompleteFetchProps) {
  const { translate } = useLang();
  const [option, setOption] = useState<readonly any[]>([]);
  const [value, setValue] = useState<IUser | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [term, setTerm] = useState<string | null>(null)
  const [focus, setFocus] = useState<boolean>(false)
  const data = {};
  data[dataRequest] = term;

  const fetchUser = React.useCallback(async () => {

    if (term && term != '' && term?.length >= 3) {
      setLoading(true)
      const response = await authFetch({
        url: urlFetch,
        method: 'POST',
        data: data
      })
      
      setOption(response.data)
      setLoading(false)

      return response.data;
    }

    return []
  }, [term])

  useEffect(() => {
    if (term && term != '' && term?.length >= 3) {
      setFocus(true)
      focusChange?.set(true)
      fetchUser();
    }
  }, [term, fetchUser])

  useEffect(() => {
    if (defaultFetch === true) {
      setValue(null);
      if (userDefault) {
        setTerm(userDefault.registration ? userDefault.registration : null);
        fetchUser();
      }
    } else {
      setFocus(false);
      focusChange?.set(false)
      setTerm(null);
      setValue(null);
    }

    setFocus(false)
    focusChange?.set(false)

  }, [defaultFetch])


  useEffect(() => {
    if (defaultFetch) {
      if (userDefault) {
        const currentValue = option.find((opt) => opt.id == userDefault.id);
        if (currentValue) {
          setValue(currentValue);
          onFetchChange ? onFetchChange(currentValue) : false;
          setFocus(false)
          focusChange?.set(false)
          if (setFormValue) {
            if (currentValue) {
              setFormValue(register, currentValue.id)
            }
          }
        }
      }
    }
  }, [option])


  useEffect(() => {
    if (!value && defaultFetch) {
      setCheckedChange && setCheckedChange(false)
    }
  }, [value])


  useEffect(() => {
    if (value && (focus === true || focusChange?.get() == true)) {
      setFocus(false)
      focusChange?.set(false)
    }
  }, [value, focus, focusChange?.get()])

  useEffect(() => {
    setValue(null)
    setOption([])
  }, [resetValues])

  return (
    // <Grid>
    <FormControl fullWidth size='small'>
      <Autocomplete
        id='filter-coordination'
        sx={{ height: 2 }}
        size={size}
        options={option}
        value={value}
        noOptionsText={translate('No options')}
        loading={loading}
        includeInputInList
        autoComplete
        clearOnEscape={true}
        open={focusChange ? focusChange.get() : focus}
        disabled={disabled}
        filterSelectedOptions
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => `${option?.registration ? `[${option?.registration}]` : option.name}${option?.representation_name ? ` - ${option?.representation_name}` : ''}`}
        onChange={(_, item) => {
          setValue(item ? item : null),
          onFetchChange ? onFetchChange(item ? item.id : null) : false;
          setFocus(false)
          focusChange?.set(false)
          if (setFormValue) {
            if (item) {
              setFormValue(register, item.id)
            }
          }
        }}
        inputValue={term ? term : ''}
        onInputChange={(event, newInputValue) => {
          setTerm(newInputValue == '' ? null : newInputValue);
          setFocus(false)
          focusChange?.set(false)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Insira 3 caracteres'
            label={translate(label)}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }} />
        )} />


    </FormControl>
    // </Grid>

  )
}