import { InputLabel, FormControl, Select, MenuItem, AutocompletePropsSizeOverrides, Theme, FormHelperText, InputAdornment, IconButton } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import { OverridableStringUnion } from '@mui/types';
import { SxProps } from '@mui/system';
import { State, useHookstate, useState } from '@hookstate/core';
import { Validation as validation } from '@hookstate/validation';
import { useLang } from '~/hooks/useLang';
import CancelIcon from '@mui/icons-material/Cancel';


interface ISelectBasic {
  readonly state: State<string | null>,
  readonly isInvalidRequest?: boolean,
  readonly placeholder: string;
  // eslint-disable-next-line functional/prefer-readonly-type
  readonly options: Array<{ id: number | string; name: string } | null>;
  readonly disabled?: boolean;
  readonly required?: boolean;
  readonly size?: OverridableStringUnion<'small' | 'medium', AutocompletePropsSizeOverrides>;
  readonly sx?: SxProps<Theme>;
  readonly handleChange?: (event: SelectChangeEvent) => void;
  readonly error?: State<string | null | undefined>;
}

export default function SelectBasic({
  state,
  placeholder,
  options,
  required = false,
  disabled = false,
  size = 'medium',
  sx,
  isInvalidRequest = false,
  handleChange,
  error,
}: ISelectBasic) {
  const { translate } = useLang();
  const stateField = state;
  const errorLocal = error;

  const localHandleChange = (event: SelectChangeEvent) => {
    stateField.set(event.target.value as string);
    errorLocal?.set('')
  };

  return (
    <FormControl
      required={required}
      error={!!error?.get()}
      disabled={disabled}
      sx={sx}
      size={size}
      fullWidth>
      <InputLabel
        id="select-basic">
        {placeholder}
      </InputLabel>
      <Select
        labelId="select-basic"
        id="select-basic"
        value={stateField.get() ?? ''}
        label={placeholder}
        onChange={handleChange ?? localHandleChange}
      >
        {options && options.map((props) => {
          return (
            <MenuItem key={props ? props?.id : ''} value={props ? props?.id : ''}>
              {props ? props?.name : ''}
            </MenuItem>
          )
        })}
      </Select>
      <FormHelperText>
        {translate(errorLocal?.get() ?? '')}
      </FormHelperText>
    </FormControl>
  )
}