/* eslint-disable max-lines-per-function */
import React from 'react'
import { useHookstate } from '@hookstate/core'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import { getCamerasById } from '~/features/Cameras/services'
import useDispatchState from '~/features/Dispatch/stores/DispatchState'
import useUserState from '~/features/User/stores/UserState'
import { useLang } from '~/hooks/useLang'
import notify from '~/utils/notify'
import useSurveillanceReportingState from '../../stores/SurveillanceReportingState'
import SurveillanceReportingCards from '../VideoMonitoringCards'
import FiltersVideoMonitoring from './components/FiltersVideoMonitoring'
import NewSurveillanceReporting from './components/NewSurveillanceReporting'

interface ICameraInfo {
  readonly id: number | null
  readonly latitude: number | null
  readonly longitude: number | null
}

export default function VideoMonitoringForm() {
  const { verifyPermission } = useUserState()
  const { translate } = useLang()
  const { dispatch } = useDispatchState()
  const { occurrence: occurrenceDispatch } = dispatch()
  const { id: relatedOccurrence } = occurrenceDispatch
  const { surveillanceReportingStates, associateOccurrence, verifyInputErros } =
    useSurveillanceReportingState()
  const {
    showCheckbox,
    switchCheck,
    reports,
    loading,
    surveillanceReporting,
    createOccurrence,
  } = surveillanceReportingStates()
  const { typeId, subtypeId } = surveillanceReporting
  const showFilters = useHookstate(false)
  const cameraInfo = useHookstate<ICameraInfo | null>(null)
  const camerasSelected = reports
    .get()
    .filter((camera) => camera.checked)
    .map((item) => item)
    .sort(sortCameras)
  const invalidRequest = useHookstate(false)

  function sortCameras(commentA, commentB) {
    const dateA = new Date(commentA.createdAt).getTime()
    const dateB = new Date(commentB.createdAt).getTime()
    return dateA - dateB
  }

  const checkLenght = () => {
    return reports.get().filter((report) => report.checked).length
  }

  const finishAssociate = () => {
    reports.forEach((report) =>
      report.set((prev) => ({ ...prev, checked: false }))
    )
    showCheckbox.set(false)
    invalidRequest.set(false)
  }

  const handleAssociate = () => {
    loading.set(true)
    getCamerasById(camerasSelected[0].id as number)
      .then((response) => {
        if (response.status === 200) {
          cameraInfo.set(response.data)
        }
      })
      .then(() => {
        associateOccurrence({
          occurrenceId: !createOccurrence.get()
            ? (relatedOccurrence.get() as string)
            : '',
          surveillanceReportIds: reports
            .get()
            .filter((rep) => rep.checked)
            .map((rep) => rep.id),
          cameraId: cameraInfo.get()?.id ?? null,
          toCreateOccurrence: createOccurrence.get(),
          typeId: typeId.get(),
          subtypeId: subtypeId.get(),
          latitude: cameraInfo.get()?.latitude ?? null,
          longitude: cameraInfo.get()?.longitude ?? null,
        }).finally(() => {
          notify({
            message: translate('Reports associated with success'),
            type: 'success',
          })
          finishAssociate()
          loading.set(false)
        })
      })
    loading.set(false)
  }

  return (
    <Grid
      container
      direction="row"
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        paddingBottom: 2,
      }}>
      <Grid
        item
        xs={12}
        direction="row"
        sx={{
          padding: '0px 8px',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">
          {showFilters.get()
            ? translate('Filter by')
            : translate('Report data')}
        </Typography>
        <IconButton
          onClick={() => {
            showFilters.set(!showFilters.get())
          }}
        >
          <Tooltip title={translate('View the filters')}>
            {showFilters.get() ? (
              <FilterAltIcon fontSize="small" />
            ) : (
              <FilterAltOutlinedIcon fontSize="small" />
            )}
          </Tooltip>
        </IconButton>
      </Grid>
      {verifyPermission(['video_surveillance_create']) && showFilters.get() ? (
        <Grid item xs={12} >
          <FiltersVideoMonitoring />
        </Grid>
      ) : (
          <Grid item xs={12} >
          <NewSurveillanceReporting invalidRequest={invalidRequest} />
        </Grid>
      )}
      <Grid item xs={12} sx={{ padding: 1 }}>
        <Typography variant="h6">
          {translate('Narrations')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ padding: '0px 8px' }}
      >
        <FormControlLabel
          sx={{ paddingLeft: '8px' }}
          control={
            <Switch
              checked={switchCheck.get()}
              onChange={() => switchCheck.set(!switchCheck.get())}
              disabled={
                createOccurrence.get() && showCheckbox.get() ? true : false
              }
            />
          }
          label={translate('only my narrations')}
        />
        <SurveillanceReportingCards />
      </Grid>
      {showCheckbox.get() && (
        <Grid item xs={12}>
          <Collapse in={showCheckbox.get()}>
            <Grid
              container
              direction="row"
              sx={{
                paddingTop: 2,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Grid
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Button variant="contained" onClick={finishAssociate}>
                  {`${translate('Cancel')} - ${checkLenght()} ${translate(
                    'Selected'
                  )}${checkLenght() > 1 ? 's' : ''}`}
                </Button>
              </Grid>
              <Grid item>
                <Tooltip
                  title={translate(
                    'Associate selected reports with this dispatch'
                  )}
                >
                  <LoadingButton
                    variant="contained"
                    disabled={
                      !reports.get().some((rep) => rep.checked) ||
                      !relatedOccurrence.get()
                    }
                    loading={loading.get()}
                    onClick={() => {
                      if (verifyInputErros()) {
                        handleAssociate()
                      } else {
                        invalidRequest.set(true)
                      }
                    }}
                  >
                    {translate('Associate selected reports with this dispatch')}
                  </LoadingButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      )}
    </Grid>
  )
}
