import React, { useEffect } from 'react';
import ReactDOM from 'react-dom'
import { DirectionsCar } from '@mui/icons-material';
import StandardModal from '~/components/StandardModal';
import { useLang } from '~/hooks/useLang';
import useEntryState from '~/features/Entry/stores/EntryState';
import { Grid, TextField } from '@mui/material';
import PlateField from '../PlateField';
import CircunstanceSelectField from '~/features/Entry/components/CircunstanceSelectField';
import { useHookstate } from '@hookstate/core';
import { useLocation } from 'react-router-dom';
import ScreenSettingsDraggableProps from '../../interfaces/IPositionDraggable';
import { authFetch } from '~/services/fetch';
import useSystemState from '~/features/System/stores/SystemState';
import { storeScreenSettings } from '~/features/System/services';

const modal = document.getElementById('div-modal') as HTMLElement;

export default function VehicleModalDraggable() {
  const { systemConfig } = useSystemState();
  const { translate } = useLang();
  const entryState = useEntryState();
  const { windowManager, loadingButton } = useSystemState();
  const { vehicle } = entryState.entry();

  const handleStop = (event, dragElement) => {
    event.stopPropagation();

    windowManager()['VehicleModal'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y
    })
  };

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'VehicleModalDraggable',
        isOpen: windowManager()['VehicleModal'].open.get(),
        isMinimized: windowManager()['VehicleModal'].minimized.get(),
        positionX: windowManager()['VehicleModal'].positionX.get(),
        positionY: windowManager()['VehicleModal'].positionY.get()
      })
    }
  }, [
    windowManager()['VehicleModal'].open.get(),
    windowManager()['VehicleModal'].minimized.get(),
    windowManager()['VehicleModal'].positionY.get(),
    windowManager()['VehicleModal'].positionX.get()
  ])


  if (windowManager()['VehicleModal'].open.get()) {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() &&
          <StandardModal
            title={`${translate("Add Vehicle")}`}
            avatar={DirectionsCar}
            dimensions={{
              width: 450
            }}
            isExpanded={windowManager()['VehicleModal'].minimized}
            handleStop={handleStop}
            position={{
              x: windowManager()['VehicleModal'].positionX?.get() ? Number(windowManager()['VehicleModal'].positionX.get()) : 550,
              y: windowManager()['VehicleModal'].positionY?.get() ? Number(windowManager()['VehicleModal'].positionY.get()) : 100
            }}
            closeButton={() => windowManager()['VehicleModal'].open.set(!windowManager()['VehicleModal'].open.get())}
          >
            <Grid container direction={'row'}>
              <Grid item xs={6} sx={{ padding: 1 }}>
                <PlateField plate={vehicle.plate} />
              </Grid>
              <Grid item xs={6} sx={{ padding: 1 }}>
                <CircunstanceSelectField isValid={true} circunstanceId={vehicle.circunstanceId} />
              </Grid>
              {
                systemConfig().showEntryFieldExternalKeyTracking.get() === '1' && (
                  <Grid item xs={12} sx={{ padding: 1 }}>
                    <TextField
                      fullWidth
                      size='small'
                      id="external-tracking"
                      label={translate('Chave de Rastreamento')}
                      value={vehicle.externalKeyTracking.get() ? vehicle.externalKeyTracking.get() : ''}
                      InputProps={{
                        onChange:
                          (event) => {

                            vehicle.externalKeyTracking.set(event.target.value ?? null);
                          },
                      }}
                    />
                  </Grid>
                )
              }

            </Grid>

          </StandardModal>
        }
      </>
      , modal);
  } else {
    return <></>
  }
}
