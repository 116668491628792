import React from 'react';
import { useLang } from '~/hooks/useLang';

import InternalHeader from '~/components/Layout/components/InternalHeader';
import ListAllPointInterest from '../ListAllInterestPoint';

export default function InterestPointList() {
    const { translate } = useLang();
    return (
        <>
            <InternalHeader title={translate('Points of interest')} />
            <ListAllPointInterest />
        </>


    )
}