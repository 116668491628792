/* eslint-disable max-lines-per-function */
import React from 'react';
import { State, StateMethods, useHookstate } from '@hookstate/core';
import { Card, CardHeader, CardContent, CardActions, Dialog, Button, Typography, Grid, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { lengthLimit } from '~/features/Dispatch/utils/stringsManipulations';
import { authFetch } from '~/services/fetch';
import { CurrentDeviceDialog } from '../../pages/Index';
import notify from '~/utils/notify';

interface DeviceEditFieldsDialogProps {
  readonly currentDevice: State<CurrentDeviceDialog>;
  readonly forceRefresh: StateMethods<boolean>;
  readonly isOpen: StateMethods<boolean | undefined>
}

export function DeviceEditFieldsDialog({ currentDevice, forceRefresh, isOpen }: DeviceEditFieldsDialogProps) {
  const { translate } = useLang();

  const handleSend = () => {
    authFetch({
      url: `/device/${currentDevice.currentDeviceId.get()}/change-identifications`,
      method: 'PUT',
      data: {
        imei: currentDevice.imei.get(),
        tracking_id: currentDevice.trackingId.get(),
      }
    }).then((response) => {
      if (response.data) {
        response.data.message ? notify({ message: `${translate(response.data.message)}`, type: 'success' }) : notify({ message: `${translate('Device was updated successfully')}`, type: 'success' });
        forceRefresh.set(!forceRefresh.get());
        isOpen.set(false)
      }
    });
  }
  return (
    <Dialog
      open={isOpen.get() ?? false}
      PaperProps={{ sx: { background: 'transparent' } }}>
      <Button
        size="small"
        onClick={() => isOpen.set(false)}
        sx={{
          position: 'absolute',
          right: '5px',
          top: '15px',
          color: '#BDBDBD',
          '&:hover': { backgroundColor: 'transparent', color: 'red' }
        }}
      >
        <CloseIcon />
      </Button>
      <Card
        sx={{ minWidth: '350px', padding: 2, }}>
        <CardHeader
          sx={{ textAlign: 'center' }}
          title={(
            <Typography variant='h5'>
              {`${translate('Edit')} ${currentDevice.currentDeviceName.get()}`}
            </Typography>
          )}
        />
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 1 }} >
          <Grid container xs={12}>
            <Grid item xs={4}>
              <TextField fullWidth
                id="tracking-id"
                label={translate('Tracking')}
                name="tracking-id"
                size='small'
                value={currentDevice.trackingId.get()}
                onChange={(event) => {
                  currentDevice.trackingId.set(lengthLimit(event.target.value, 10))
                }}
              />

            </Grid>
            <Grid item sx={{ pl: 1 }} xs={8}>
              <TextField fullWidth
                id="imei"
                label='imei'
                name="imei"
                size='small'
                value={currentDevice.imei.get()}
                onChange={(event) => {
                  currentDevice.imei.set(event.target.value)
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            size="small"
            variant='contained'
            title={translate('Save')}
            // endIcon={<SaveIcon />}
            onClick={() => {
              handleSend()
            }}>
            {translate("Save")}
          </Button>
        </CardActions>
      </Card>
    </Dialog>
  )
}