import clickableNotification from '~/components/ClickableNotification/index';
import { toast } from 'react-toastify';
import notificationSound from './notification.mp3';
import notificationSoundPriority2 from './notification.mp3';
import notificationSoundPriority3 from './notification3.mp3';
import useNotificationState from '~/components/NotificationsCentral/stores';
import { State } from '@hookstate/core';
export interface INotify {
  readonly message: string
  readonly idMessage?: number,
  readonly type: 'success' | 'warning' | 'error' | 'info'
  readonly priority?: 1 | 2 | 3
  readonly clickableMessage?: {
    readonly id?: number | null
    readonly action?: any
    readonly urlRedirection?: string
    readonly hideButton?: boolean
  } | null
  readonly autoCloseDuration?: false | number
  readonly actualNotification?: () => State<number|null>
  readonly forceShowButton?: boolean;
}

const sound = {
  1: notificationSound,
  2: notificationSoundPriority2,
  3: notificationSoundPriority3
}

export default function notify({ message, type, priority = 1, clickableMessage, autoCloseDuration = 5000, idMessage, actualNotification, forceShowButton=false  }: INotify) {
  const audio = new Audio(sound[priority]);
  audio.autoplay = true;

  return toast[type](clickableNotification({ message: message, clickableMessage: clickableMessage, forceShowButton: forceShowButton}), {
    hideProgressBar: priority == 3 ? false : true,
    autoClose: autoCloseDuration != undefined ? autoCloseDuration : priority == 3 ? false : 5000,
    onOpen: () => audio.play(),
    onClose: () => {
      audio.pause()
      actualNotification && actualNotification().set(idMessage ? idMessage : null)
    },
    toastId: idMessage,
  });
}
