import React, { useEffect } from 'react';
import { createState, useState } from '@hookstate/core';
import { Broadcasted as broadcasted } from '@hookstate/broadcasted';
import { authFetch } from '~/services/fetch';

export interface IDispatchGroupView {
  readonly [name: string]: {
    readonly id: number
    readonly name: string
    readonly is_visible: number | boolean
    readonly agency_id: number
  }
}

const dispatchGroupViewDefault = createState<IDispatchGroupView>({})
const applyOrderRefreshDefault = createState(false);
const loading = createState(false)

dispatchGroupViewDefault.attach(broadcasted('dispatch-groups-events'))
applyOrderRefreshDefault.attach(broadcasted('dispatch-groups-order-refresh-events'))

export default function useDispatchGroupState() {
  const dispatchGroupView = useState(dispatchGroupViewDefault);
  const firstRender = useState(true);
  const applyOrderRefresh = useState(applyOrderRefreshDefault);
  const loadingButton = useState(loading)

  useEffect(() => {
    if (firstRender.get() == false && applyOrderRefresh.get() == true) {
      window.location.reload()
      applyOrderRefresh.set(false)
    }
    firstRender.set(false);
  }, [dispatchGroupViewDefault.get(), applyOrderRefresh.get()]);


  return ({
    dispatchGroupView: () => dispatchGroupView,
    loadingButton: loadingButton,
    applyDispatchGroupView: () => {
      loadingButton.set(true)
      const response = authFetch({
        url: '/station/group/change-visibility',
        method: 'POST',
        data: {
          dispatch_groups: Object.keys(dispatchGroupView.get()).map((element) => dispatchGroupView[element].get())
        }
      }).finally(() => {
        applyOrderRefresh.set(true)
      })

      return response
    }
  })
}