import React, {useCallback, useEffect} from 'react';
import { DataGrid, GridActionsCellItem, GridFilterItem, GridPreferencePanelsValue, GridRowId, GridRowModel, GridColDef, GridRowParams, GridValueFormatter, MuiEvent, getDataGridUtilityClass, gridPreferencePanelStateSelector, useGridApiContext, useGridRootProps} from '@mui/x-data-grid';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Tooltip} from '@mui/material';
import { ptBR } from '@mui/x-data-grid/locales';
import { formatDateHour } from '../../../../utils/format';
import { MergeType } from '@mui/icons-material';
import { useLang } from '../../../../hooks/useLang';
import { useAuth } from '~/features/auth/hooks/useAuth';
import { authFetch } from '~/services/fetch';
import useDispatchState from '../../stores/DispatchState';
import { useState } from '@hookstate/core';
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from '~/components/ErrorFallback';
import { Badge, Box, IconButton, unstable_composeClasses as composeClasses } from "@mui/material";
import useSystemState from '~/features/System/stores/SystemState';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

type DispatchUnificationTable = {
  data: GridRowModel,
}

// eslint-disable-next-line max-lines-per-function
export default function DispatchUnificationTable() {
  const { translate } = useLang();
  const { windowManager } = useSystemState();
  const [rows, setRow] = React.useState<GridRowModel[]>([])
  const open = useState(false);
  const [rowCount, setRowCount] = React.useState(0)
  const [dispatchId, setDispatchId] = React.useState<number>(0);
  const [filters, setFilters] = React.useState<readonly GridFilterItem[]>([]);

  const dispatchState = useDispatchState();

  const [page, setPage] = React.useState<any>(0)
  const [loading, setLoading] = React.useState(true)
  // const refresh = useState(false);

  const limit = 5;

  const { status } = dispatchState.dispatch();

  const tableMode = 'server';
  
  const handleClose = () => {
    open.set(false);
    setFilters([])
  };

  const action = () => {
    dispatchState.unificationDispatch({ dispatchId: dispatchId });
    windowManager()['DispatchUnification'].open.set(false)
    open.set(false);    
  };

  const unification = React.useCallback(
    (row: any) => () => {
      setDispatchId(row.id);
      open.set(true);
    },[],
  );

  const columns = React.useMemo(
    () => [
    { field: 'id', headerName: translate('Id') },
    { field: 'code', headerName: translate('Code'), flex: 1 },
    { field: 'occurrence:location', headerName: translate('Location'), flex: 1.5,
      // valueFormatter: (params: GridValueFormatter) => {
      //   const occurrence = params.value;
      //   if(!occurrence || !('location' in occurrence) ) return null;

      //   return occurrence?.location;
      // },
    },
    { field: 'dispatched_at', headerName: 'Data', flex: 1.2 ,
      valueGetter: (value) => {
        return formatDateHour(value as string).toFormatedDate;
      },
    },
    { field:'type:name', headerName:translate('Type'), flex: 1.5,
      // valueFormatter: (params: GridValueFormatter) => {
      //   if(!params.value) return null;

      //   return params.value.name
      // },
    },
    { field:'subtype:name', headerName:translate('Subtype'), flex: 1 ,
      // valueFormatter: (params: GridValueFormatter) => {
      //   if(!params.value) return null;

      //   return params.value.name
      // },
    },
    {
      field: 'actions', type: 'actions',
      flex: 0.3,
      getActions: ({id, row}) => [
        <GridActionsCellItem
            disabled={status ? status.id.get() !== 1 : false }
            key={id}
            icon={
              <Tooltip title={translate('Unify')}>
                <MergeType />
              </Tooltip>
            }
            label='export'
            onClick={unification(row)}
          />,
      ]
    }
  ],
  [unification, dispatchState.dispatch().id],
);

  useEffect(() => {
    authFetch({
      url: `/dispatch/list-to-unify`, 
      method: 'POST',
      data: {
        dispatch_id: dispatchState.dispatch().id.get(),
        page: page,
        limit: limit,
        filters: filters,
      }
    })
      .then(({ data: { data, rowsCount } }) => {
        setLoading(false);
        setRow(data.map((element) => {
          element['occurrence:location'] = element.occurrence.location
          element['type:name'] = element.type.name
          element['subtype:name'] = element.subtype?.name
          return element
        }));
        setRowCount(rowsCount);
    })
    .catch(err => err);
  }, [dispatchState.dispatch().id.get(), page, filters]);

  const useUtilityClasses = (ownerState) => {
    const { classes } = ownerState;
  
    const slots = {
      icon: ["filterIcon"]
    };
  
    return composeClasses(slots, getDataGridUtilityClass, classes);
  };

  function CustomFilterIconButton(props) {
    const { counter, field, onClick } = props;
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const ownerState = { ...props, classes: rootProps.classes };
    const classes = useUtilityClasses(ownerState);
  
    const column = apiRef.current.getColumn(field);
    if (!column.filterable) {
      return null;
    }
  
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const toggleFilter = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
  
        const { open, openedPanelValue } = gridPreferencePanelStateSelector(
          apiRef.current.state
        );
  
        if (open && openedPanelValue === GridPreferencePanelsValue.filters) {
          apiRef.current.hideFilterPanel();
        } else {
          apiRef.current.showFilterPanel(field);
        }
  
        if (onClick) {
          onClick(apiRef.current.getColumnHeaderParams(field), event);
        } 
      },
      
      
      [apiRef, field, onClick]
      );
      
    const iconButton = (
      <IconButton
        onClick={toggleFilter}
        color="default"
        aria-label={apiRef.current.getLocaleText("columnHeaderFiltersLabel")}
        size="small"
        tabIndex={-1}
        title={translate('Filter')}
      >
        <FilterAltIcon fontSize="small"/>

      </IconButton>
    );
  
    return (
      <Tooltip title='' enterDelay={1000}>
        <Box sx={{ display: "flex" }}>
          {counter > 1 && (
            <Badge badgeContent={counter} color="default">
              {iconButton}
            </Badge>
          )}
          {(!counter || counter === 1) && iconButton}
        </Box>
      </Tooltip>
    );
  }


  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Grid item md>
      <DataGrid 
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns as GridColDef[]}
        columnVisibilityModel={{
          id: false,
        }}
        disableColumnSelector={true}
        initialState={{
          pagination: { paginationModel: { pageSize: limit } },
        }}
        autoHeight
        pagination
        pageSizeOptions={[limit]}
        rowCount={rowCount}
        paginationMode={tableMode}
        filterMode={tableMode}
        onFilterModelChange={(onFilterChange) => setFilters(onFilterChange['items'])}
        sortingMode={tableMode}
        loading={loading}
        onPaginationModelChange={(pageContent) => setPage(pageContent.page)}
        slots={{ columnHeaderFilterIconButton: CustomFilterIconButton }}
        sx={{
          height: 371,
          boxShadow: 2,
          '& .MuiDataGrid-cell:hover': {
            color: 'primary',
          },
        }}
      />
      <Dialog open={open.get()} onClose={handleClose}>
        <DialogContent>
          {translate('Do you want to use this dispatch to unify the current dispatch?')}
        </DialogContent>
        <DialogActions>
          <Button 
            variant='outlined'
            onClick={handleClose}>
            {translate('Cancel')}
          </Button>
          <Button
            variant='contained'
            onClick={action}>
            {translate('Submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
    </ErrorBoundary>
  );
}
