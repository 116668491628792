import React, { useEffect, useState } from 'react';
import { SxProps, TextField, Theme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

interface PeriodProps {
    readonly onDateTimeChange: (date: Dayjs | null) => void;
    readonly onOpen?: () => void;
    readonly label: string;
    readonly inputFormat: string;
    readonly placeholder: string;
    readonly dateTime?: Dayjs | null
    readonly disabled?: boolean
    readonly sx?: SxProps<Theme>;
    readonly error?: boolean;
    readonly disableFuture?: boolean;
    readonly required?: boolean;
    readonly dateMax?: Dayjs;
    readonly dateMin?: Dayjs;
}

export default function InputDateTime({ error = false, sx, disabled, onDateTimeChange, onOpen, label, dateTime, inputFormat, placeholder, disableFuture=true, required=false, dateMax, dateMin }: PeriodProps) {
    const [dateValue, setDateValue] = useState<Dayjs | null | undefined>(dateTime);

    useEffect(() => {
        setDateValue(dateTime)
    }, [dateTime])

    return (
        <DateTimePicker
            label={label}
            format={inputFormat}
            disabled={disabled}
            ampm={false}
            ampmInClock={false}
            value={dateValue}
            onChange={(newValue: Dayjs | null) => {
                setDateValue(newValue)
                onDateTimeChange(newValue)
            }}
            disableFuture={disableFuture}
            onOpen={onOpen}
            slotProps={{ textField: {
                size: 'small',
                sx: sx,
                error: error,
                inputProps: {
                    placeholder
                },
                required: required
            } }}
            minDateTime={dateMin}
            maxDateTime={dateMax}
        />
    )
}