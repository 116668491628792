import React from 'react';
import { Card, CardContent, CardHeader, Typography, Grid } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { PieChart as PieChartGraph } from 'bizcharts';
import { useTheme } from '~/hooks/useTheme';

export default function PieChart({ data, title, isPrint = false, height=400, width=0, subtitle='', sxFont={}  }) {
  const { translate } = useLang();
  const { darkMode } = useTheme();

  return (
    <Card>
      <CardContent>
        <Typography sx={sxFont}>
          {translate(title)}
        </Typography>
        {subtitle && 
        <Typography variant='subtitle2'>
          {translate(subtitle)}
        </Typography>
        }
        { data.length !== 0 ?  <PieChartGraph
            data={data}
            radius={0.5}
            angleField='value'
            colorField='item'
            label={{
              style: { fill: darkMode && !isPrint ? '#d8d8d8' : '#363636' },
              visible: true,
              type: 'spider',
              labelHeight: 28,
              content: (props) => `${props.item}\n${props.value}`
            }}
            autoFit
            padding='auto'
            width={width != 0 ? width : undefined}
            height={height}
            tooltip={{ visible: true }}
            legend={{
              text: { style: { fill: darkMode && !isPrint ? '#d8d8d8' : '#363636' } }
            }}
          /> : 
          <Grid item>
            <Card 
                sx={{
                  height: height,
                  textAlign: 'center',
                  alignItems: 'center'
                }}>
              <CardContent >
                <Typography>
                  {translate('No data available')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        }
      </CardContent>
    </Card>
  )
}