import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IAnnouncement from '../interfaces/IAnnouncement';

export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue,getValues} = useForm<IAnnouncement>();
    const fields = [
      { name: 'id', type: 'hidden'},
      { name: 'title', type: 'string'},
      { name: 'description', type: 'string'},
      { name: 'is_change_version', type: 'boolean', placeholder: 'Is Change Version?'},
      { name: 'announcement_date', type: 'datetime', placeholder: 'Announcement Date'},
      { name: 'announcement_end_date', type: 'datetime', placeholder: 'Announcement End Date'},
  ];
    
    return(
      <CrudEdit 
        model={'announcement'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        setFormValue={setFormValue}
        messageSuccess={'Announcement successfully updated!'}  
        title={'Edit announcement'} 
        getValues={ getValues }
      />
    )
}
