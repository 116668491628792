import React, {createContext, ReactNode, } from 'react';
import constants from '~/core/constants';
import useCookie from '~/hooks/useCookie';

type ThemeContextType = {
  readonly darkMode: boolean;
  readonly switchTheme: () => void;
}

type ThemeContextProviderProps = {
  readonly children: ReactNode;
}

export const ThemeContext = createContext<ThemeContextType>({} as ThemeContextType);

export function ThemeContextProvider({children}: ThemeContextProviderProps) {
  const [darkMode, setDarkMode] = useCookie(constants.keys.theme, '0');

  const switchTheme = () => setDarkMode(darkMode === '0' ? '1' : '0');

  return (
    <ThemeContext.Provider value={{darkMode: darkMode !== '0', switchTheme}}>
      {children}
    </ThemeContext.Provider>
  );
}