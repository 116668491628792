import { IParamsTable } from '~/interfaces';
import { authFetch } from '~/services/fetch';

export async function getVehicles<T = any>({
  filters = [],
  limit = 100000,
  orders = [
    { field: 'vehicles.id', sort: 'asc' }
  ]
}: IParamsTable = {}) {

  const response = await authFetch({
    url: `/vehicle`,
    method: 'POST',
    data: {
      limit,
      filters,
      orders
    },

  });
  return response.data.data as T;

}


export async function searchVehicleService({ plate, page, limit }) {
  try {
    const response = await authFetch({
      url: '/occurrence/associated-vehicle/find-vehicle',
      method: 'POST',
      data: {
        plate: plate,
        page: page,
        limit: limit
      }
    })

    return response.data;

  } catch (error) {
    throw error
  }
}
