import React from 'react'
import {
  Button,
  Collapse,
  Grid,
  Card,
  List,
  ListItemButton,
  ListItemText,
  Box,
  Typography,
  Tooltip,
} from '@mui/material'
import IconAdministration from '~/components/IconsSvg/administration'
import { useLang } from '~/hooks/useLang'
import { ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material'

import { useHistory } from 'react-router-dom'
import useUserState from '~/features/User/stores/UserState'
import { MenuProps } from '~/components/SidebarMenu/interfaces'

export default function VehicleItem({ handleMenuClick }: MenuProps) {
  const { translate } = useLang()
  const { verifyPermission } = useUserState()
  const [openVehicleItem, setOpenVehicleItem] = React.useState(false)

  const history = useHistory()

  const handleClickVehicleItem = () => {
    setOpenVehicleItem(!openVehicleItem)
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {verifyPermission([
        'vehicle_brand_index',
        'vehicle_type_index',
        'vehicle_model_index',
        'vehicle_color_index',
      ]) && (
        <>
          <Tooltip title={translate('Vehicles options')} placement="right">
            <ListItemButton sx={{ pl: 4 }} onClick={handleClickVehicleItem}>
              <ListItemText primary={translate('Vehicles')} />
              {openVehicleItem ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </Tooltip>
          <Collapse in={openVehicleItem} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {verifyPermission(['vehicle_color_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Vehicle Color')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 8 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/vehicle-color')
                    }}
                  >
                    <ListItemText primary={translate('Vehicle Color')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['vehicle_brand_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Vehicle Brand')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 8 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/vehicle-brand')
                    }}
                  >
                    <ListItemText primary={translate('Vehicle Brand')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['vehicle_model_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Vehicle Model')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 8 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/vehicle-model')
                    }}
                  >
                    <ListItemText primary={translate('Vehicle Model')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['vehicle_type_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Vehicle Type')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 8 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/vehicle-type')
                    }}
                  >
                    <ListItemText primary={translate('Vehicle Type')} />
                  </ListItemButton>
                </Tooltip>
              )}
            </List>
          </Collapse>
        </>
      )}
    </>
  )
}
