import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import { CardMedia, Fab } from '@mui/material';
import { CommentDataProps } from '~/features/Dispatch/stores/DispatchCommentState';
import { State } from '@hookstate/core';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  border: '1px solid #546e7a',
  boxShadow: 24,
  p: 4,
};

interface ShowImageProps {
  readonly handleOpen: () => void;
  readonly handleClose: () => void;
  readonly open: boolean;
  readonly commentData: State<CommentDataProps>;
}

export default function ShowImage({
  handleClose,
  open,
  commentData
}: ShowImageProps) {
  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >

        <Box sx={style}>
          <Fab
            size="small"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: '-18px',
              right: '-18px',
              transform: 'scale(0.6)',
              color: 'white',
              backgroundColor: 'red',
              '&:hover': { color: 'green' }
            }}
          >
            <ClearIcon />
          </Fab >
          <Typography id="keep-mounted-modal-title" variant="body2" component="h2">
            <CardMedia
              component="img"
              height="100%"
              image={commentData.mediaPath.get()}
              alt={commentData.comment.get()}
            />
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}