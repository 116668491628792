import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IType from '../interfaces/IType';
import Layout from '~/components/Layout';


export default function Create() {     
  const {register, handleSubmit, control, setValue:setFormValue} = useForm<IType>();
  const fields = [
      { name: 'name', type: 'string'},
      { name: 'code', type: 'string' },
      { name: 'priority', type: 'select', options: [1, 2, 3, 4, 5, 6, 7, 8, 9] }, 
      { name: 'mandatory_vehicle', type: 'number' }, 
      { name: 'mandatory_involved', type: 'number' }, 
      { name: 'mandatory_weapon', type: 'number' }, 
      { name: 'mandatory_organization', type: 'number' }, 
      { name: 'mandatory_drug', type: 'number' }, 
      { name: 'mandatory_object', type: 'number' }, 
      { name: 'ref_to_vehicle', type: 'switch'},
      { name: 'must_inform_consummate', type: 'switch', placeholder: 'Must Inform Consummate' }, 
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate model={'type'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        messageSuccess={'Type successfully created!'}  
        title={'Add type'}  
        subheader={'Add type'}
        setFormValue={setFormValue} />
      </Layout>
    )
}
