import React, { useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useLang } from '~/hooks/useLang';
import useDispatchRegistrationState from '../../stores/DispatchRegistrationState';
import { AppBar, Container, Tab, Tabs, useTheme } from '@mui/material';
import DraggableDelete from './components/ConfirmDelete';
import TabPanel from './components/TabPanel';
import Person from '../DispatchRegistrationPeople';
import Vehicle from '../DispatchRegistrationVehicle';
import Weapon from '../DispatchRegistrationWeapon';
import Drug from '../DispatchRegistrationDrug';
import Object from '../DispatchRegistrationObject';
import Organs from '../DispatchRegistrationOrgans';
import useSystemState from '~/features/System/stores/SystemState';
import useDispatchState from '../../stores/DispatchState';
import Animal from '../DispatchRegistrationAnimal';

export default function RegistrationTab() {
  const { translate } = useLang();
  const theme = useTheme();
  const { dispatchStatusActions, dispatch } = useDispatchState()
  const { id } = dispatch();
  const { windowManager } = useSystemState();
  const dispatchState = useDispatchState()


  const {
    getCircunstances,
    getDestinations,
    registrationDispatch,
    getUnitMeasurement,
  } = useDispatchRegistrationState();

  const {
    registrationActiveTab,
    registrationRowId,
    addRegistration,
    deleteRegistration,
    openConfirmAction,
    actionName,
    externalAction,
    externalActionName,
  } = registrationDispatch()

  const handleClickOpenConfirmAction = (action = 'cancel') => {
    actionName.set(action)
    openConfirmAction.set(true);
  };

  const handleCloseConfirmAction = () => {
    openConfirmAction.set(false);
    externalAction.set(false)
  };

  const handleConfirm = () => {
    if (actionName.get() === 'delete') {
      deleteRegistration.set(true)
      openConfirmAction.set(false);
      addRegistration.set(false)
      return
    }
    openConfirmAction.set(false);
    registrationRowId.set('')
    addRegistration.set(false)

    if (externalAction.get() && externalActionName.get() === 'previous') {
      dispatchState.previousDispatch()
      windowManager()['registration'].open.set(false)
      windowManager()['dispatchProcedure'].open.set(false)
      windowManager()['dispatchProcedure'].procedure.set([])
      externalAction.set(false)
    }

    if (externalAction.get() && externalActionName.get() === 'next') {
      dispatchState.nextDispatch()
      windowManager()['registration'].open.set(false)
      windowManager()['dispatchProcedure'].open.set(false)
      windowManager()['dispatchProcedure'].procedure.set([])
      externalAction.set(false)
    }
    return
  }

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (addRegistration.get()) {
      return
    }
    registrationActiveTab.set(newValue);
  };
  const handleChangeIndex = (index: number) => {
    registrationActiveTab.set(index);
  };


  return (
    <Container
      component="main">
      {openConfirmAction && <DraggableDelete
        actionName={actionName.get()}
        handleAbort={handleCloseConfirmAction}
        handleConfirm={handleConfirm}
        open={openConfirmAction.get()}
      />}
      <AppBar sx={{ height: '50px' }} position="static" color="transparent">
        <Tabs
          selectionFollowsFocus
          value={registrationActiveTab.get()}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="registrations"
        >
          <Tab label={translate('Person')} {...a11yProps(0)} />
          <Tab label={translate('Vehicle')} {...a11yProps(1)} />
          <Tab label={translate('Weapons')} {...a11yProps(2)} />
          <Tab label={translate('Drugs')} {...a11yProps(3)} />
          <Tab label={translate('Objects')} {...a11yProps(4)} />
          <Tab label={translate('Organs')} {...a11yProps(5)} />
          <Tab label={translate('Animals')} {...a11yProps(6)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={registrationActiveTab.get()}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={registrationActiveTab.get()} index={0} dir={theme.direction}>
          <Person
            handleClickOpenConfirmAction={handleClickOpenConfirmAction}
          />
        </TabPanel>
        <TabPanel value={registrationActiveTab.get()} index={1} dir={theme.direction}>
          <Vehicle
            handleClickOpenConfirmAction={handleClickOpenConfirmAction}
          />
        </TabPanel>
        <TabPanel value={registrationActiveTab.get()} index={2} dir={theme.direction}>
          <Weapon
            handleClickOpenConfirmAction={handleClickOpenConfirmAction}
          />
        </TabPanel>
        <TabPanel value={registrationActiveTab.get()} index={3} dir={theme.direction}>
          <Drug
            handleClickOpenConfirmAction={handleClickOpenConfirmAction}
          />
        </TabPanel>
        <TabPanel value={registrationActiveTab.get()} index={4} dir={theme.direction}>
          <Object
            handleClickOpenConfirmAction={handleClickOpenConfirmAction}
          />
        </TabPanel>
        <TabPanel value={registrationActiveTab.get()} index={5} dir={theme.direction}>
          <Organs
            handleClickOpenConfirmAction={handleClickOpenConfirmAction}
          />
        </TabPanel>
        <TabPanel value={registrationActiveTab.get()} index={6} dir={theme.direction}>
          <Animal
            handleClickOpenConfirmAction={handleClickOpenConfirmAction}
          />
        </TabPanel>
      </SwipeableViews>
    </Container>
  );
}