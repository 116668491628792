import { authFetch } from "~/services/fetch";
import { DisconnectMultipleVehiclesProps } from "../interfaces/IListVehiclesProps";

interface IAllVehiclesRequest {
    readonly dispatchId: string | number | null;
    readonly targetUrl?: string;
    readonly page?: number;
    readonly limit?: number;
    readonly withoutPagination?: boolean
    readonly filters?: readonly {
        readonly field: string;
        readonly operator: string;
        readonly value: string
    }[]
}


export async function getVehiclesByStatus({ page = 0, limit, filters = [], withoutPagination = false, dispatchId, targetUrl }: IAllVehiclesRequest) {
    const { data } = await authFetch({
        url: targetUrl,
        data: {
            dispatch_id: dispatchId,
            without_pagination: withoutPagination,
            filters,
            page,
            limit,
        }
    })

    return data;
}

export async function getVehiclesAvailables({ dispatchId }: IAllVehiclesRequest) {
    const { data } = await authFetch({
        url: '/device/available',
        data: {
            dispatch_id: dispatchId,
            without_pagination: true,
        }
    })

    return data;
}

export async function disconnectMultipleVehicles({ deviceIds }: DisconnectMultipleVehiclesProps ) {
    const response = await authFetch({
        url: '/device/disconnect-multiple',
        method: 'POST',
        data: {
            device_ids: deviceIds
        }
    });

    return response;    
}

