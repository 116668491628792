import { State, useHookstate } from "@hookstate/core";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, timelineOppositeContentClasses } from "@mui/lab"
import { Divider, Typography } from "@mui/material"
import dayjs from "dayjs"
import React, { useEffect } from "react"
import { TimeLineOccurrenceDispatchProps } from "~/features/Occurrence/interfaces/IDispatchOccurrence";
import { useLang } from "~/hooks/useLang";
import useSystemState from '~/features/System/stores/SystemState';
import { convertMsToTimeFriendly } from "~/utils/dateTime";


interface TimelineDispatchesOccurrenceProps {
  readonly timeLinePoint: State<TimeLineOccurrenceDispatchProps[]>
}

const elapsedTime = (initialDateTime?: string): string => {
  if (initialDateTime) {
    const dataInicial = new Date(initialDateTime)
    const dataAtual = new Date();
    const tempoDecorrido = dataAtual.getTime() - dataInicial.getTime();
    return convertMsToTimeFriendly(tempoDecorrido);
  }
  else
    return 'N/D';
}

export default function TimelineDispatchesOccurrence({ timeLinePoint }: TimelineDispatchesOccurrenceProps) {
  const { translate } = useLang();
  const { getStatusColors } = useSystemState();
  const lastPointIndex = useHookstate<number | null>(null)

  useEffect(() => {
    lastPointIndex.set(timeLinePoint.length - 1);
  }, [timeLinePoint])

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.1,
          paddingLeft: 6,
        },
        width: '100%',
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'center',
      }}
    >
      {
        timeLinePoint.get().map((pointStatus, index) => {
          return (
            <TimelineItem key={pointStatus.id} position="right" >
              <TimelineOppositeContent color="text.secondary" paddingLeft={0}>
                <Typography variant='body1'>
                  {dayjs(pointStatus.from).format('DD/MM/YYYY')}
                </Typography>
                <Typography variant='body1'>
                  {dayjs(pointStatus.from).format('HH:mm:ss')}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                  sx={{
                    backgroundColor: getStatusColors(pointStatus.id).main, color: getStatusColors(pointStatus.id).contrastText,
                    '&:hover': { backgroundColor: getStatusColors(pointStatus.id).dark, color: getStatusColors(pointStatus.id).contrastText }
                  }}
                />
                {index != lastPointIndex.get() &&
                  <TimelineConnector />
                }
              </TimelineSeparator>
              <TimelineContent width={'auto'}>
                <Typography variant='body1'>
                  {`${pointStatus.name}`}
                </Typography>
                <Typography variant='body1'>
                  {pointStatus.duration ? translate('Duration', { time: pointStatus.duration }) :
                    index === lastPointIndex.get() ? `${translate('Time elepsed')}: ${elapsedTime((pointStatus.from ? pointStatus.from?.toString() : ''))}` : false}
                </Typography>
              </TimelineContent>
              <Divider />
            </TimelineItem>
          )
        })
      }
    </Timeline >
  )
}