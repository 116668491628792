import React, { useEffect } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { getDetailsOccurrences } from '~/features/Dispatch/services';
import { useHookstate } from '@hookstate/core';
import dayjs from 'dayjs';
import { truncate } from '~/features/Dispatch/utils/stringsManipulations';

interface IDetailsOccurrences {
  readonly id: number;
  readonly code: string;
  readonly dispatched_at: string;
  readonly closed_at: string;
  readonly type: string;
  readonly subtype?: string;
  readonly occurrence: {
    readonly featured: boolean;
    readonly city: string;
    readonly district?: string;
  }
}

export default function DetailsOccurrences({ id }) {
  const { translate } = useLang();
  const detailsOccurrences = useHookstate<readonly IDetailsOccurrences[] | null>(null);


  useEffect(() => {
    getDetailsOccurrences(id).then((response) => {
      detailsOccurrences.set(response)
    })
  }, [])

  return (
    <Container fixed={false}>
      {detailsOccurrences.get()?.length ? (<Grid container xs={12} sx={{ p: 1, border: 1 }}>
        <Grid item xs={2} >
          <Typography variant='subtitle1'>
            <b>
              CÓDIGO
            </b>
          </Typography>
        </Grid>
        <Grid item xs={1.5} >
          <Typography variant='subtitle1'>
            <b>
              REGISTRO
            </b>
          </Typography>
        </Grid>
        <Grid item xs={2} >
          <Typography variant='subtitle1'>
            <b>
              FECHAMENTO
            </b>
          </Typography>
        </Grid>
        <Grid item xs={1.5} >
          <Typography variant='subtitle1'>
            <b>
              TIPO
            </b>
          </Typography>
        </Grid>
        <Grid item xs={1.5} >
          <Typography variant='subtitle1'>
            <b>
              SUBTIPO
            </b>
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1'>
            <b>
              CIDADE
            </b>
          </Typography>
        </Grid>
        <Grid item xs={1} >
          <Typography variant='subtitle1'>
            <b>
              BAIRRO
            </b>
          </Typography>
        </Grid>
        <Grid item xs={1.5} >
          <Typography variant='subtitle1'>
            <b>
              DESTAQUE
            </b>
          </Typography>
        </Grid>
      </Grid>) : (
        <Grid item xs={12} textAlign={'center'}>
          <Typography variant='subtitle1'>
            <b>
              {translate('NO INFORMATION REGISTERED')}
            </b>
          </Typography>
        </Grid>
      )}
      {detailsOccurrences.get()?.map(row => (
        <Grid container key={row.id} xs={12} sx={{ border: 1, borderTop: 0, display: 'flex', alignItems: 'center', justifyContent: "center"}}>
          <Grid item xs={2} sx={{paddingLeft:1}}>
            <Typography variant='subtitle1'  >
              {row.code}
            </Typography>
          </Grid>
          <Grid item xs={1.5} sx={{paddingLeft:0.5}}>
            <Typography variant='subtitle1' >
              {dayjs(row.dispatched_at).format('DD/MM/YYYY')}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{paddingLeft:0.5}}>
            <Typography variant='subtitle1' >
              {row.closed_at ? dayjs(row.closed_at).format('DD/MM/YYYY') : 'NI'}
            </Typography>
          </Grid>
          <Grid item xs={1.5} sx={{paddingLeft:0.5}}>
            <Typography variant='subtitle1' >
              {row.type}
            </Typography>
          </Grid>
          <Grid item xs={1.5} sx={{paddingLeft:0.5}}>
            <Typography variant='subtitle1' >
              {row?.subtype ?? 'NI'}
            </Typography>
          </Grid>
          <Grid item xs={1} >
            <Typography variant='subtitle1' >
              {row.occurrence.city ?? 'NI'}
            </Typography>
          </Grid>
          <Grid item xs={1} >
            <Typography variant='subtitle1' >
              {row.occurrence.district ?? 'NI'}
            </Typography>
          </Grid>
          <Grid item xs={1.5} sx={{paddingLeft:1}}>
            <Typography variant='subtitle1' >
              {row.occurrence.featured ? translate('Yes') : translate('No')}
            </Typography>
          </Grid>
        </Grid>
      ))}
      {!!detailsOccurrences.get()?.length && <Typography variant='caption'>
        NI - Não Informado
      </Typography>}

    </Container >
  );
}