import { authFetch } from "~/services/fetch";

export async function makeRequestToGetReportEntryByStatus({
  startDate,
  finishDate,
  entryStatusId,
  contactCenterId,
  groupByFilter,
  stationsId,
  exportCSV = false,
  entryOriginId
}) {
  try {
    const response = await authFetch({
      url: '/report/entry-by-status ',
      method: 'POST',
      data: {
        start_date: startDate,
        finish_date: finishDate,
        entry_status_id: entryStatusId,
        contact_center_id: contactCenterId,
        group_by: groupByFilter,
        station_ids: stationsId,
        export_csv: exportCSV,
        entry_origin_id: entryOriginId
      }
    });

    return response.data;

  } catch (error) {
    console.error('ERROR GENERATE REPORT', error)
    throw error
  }
}