import React from 'react';
import { createState, useState } from '@hookstate/core';
import { IDispatchRelated } from '../interfaces/IDispatchRelated';
import { authFetch } from '~/services/fetch';
import { Broadcasted } from '@hookstate/broadcasted';

const dispatchRelatedState = createState<IDispatchRelated | object>({});

// eslint-disable-next-line new-cap
dispatchRelatedState.attach(Broadcasted('dispatch-related-events'));

interface defaultActions {
  loading: boolean,
  retryConnect: boolean,
}

const defaultActions: defaultActions = {
  loading: false,
  retryConnect: false,
}

const defaultActionsDefaultState = createState<defaultActions>(Object.create(defaultActions))

const useDispatchRelatedState = () => {
  const dispatchRelated = useState(dispatchRelatedState);
  const defaultActionsState = useState(defaultActionsDefaultState)
  const loading = useState(defaultActionsState.loading)
  const retryConnect = useState(defaultActionsState.retryConnect)

  function setDefaultdispatchRelated() {
    dispatchRelated.set({})
  }

  return ({
    dispatchRelated: () => dispatchRelated,
    updateStatusRelated: ({ dispatchId, status }) => {
      try {
        if (dispatchRelated[dispatchId].get()) {
          dispatchRelated[dispatchId].status.set(status);
        }
        return true
      } catch (error) {
        console.error(`Despacho sem objeto de status: ${JSON.stringify(dispatchRelated[dispatchId])} `);
        return false
      }
    },
    loading: () => loading,
    retryConnect: () => retryConnect,
    syncRelatedDispatches: async (dispatchId: string | number | null) => {
      retryConnect.set(false)
      loading.set(true)
      setDefaultdispatchRelated();
      if (!dispatchId) {
        loading.set(false)
        return false;
      } else {
        await authFetch({
          url: '/dispatch/all-from-occurrence',
          method: 'POST',
          data: {
            'dispatch_id': dispatchId
          }
        }).then(({ data }) => {
          if (!data) {
            setDefaultdispatchRelated();
            return false
          }

          const dispatches = data.reduce(function (target, key) {
            target[key.id] = key;

            return target
          }, {})

          dispatchRelated.set(dispatches);
          return true
        }).catch(() => retryConnect.set(true)).finally(() => loading.set(false));
      }
    },
    setDefaultdispatchRelated: setDefaultdispatchRelated,
  })
}

export default useDispatchRelatedState;