import React from 'react';
import { useState } from '@hookstate/core';
import {IconButton, Grid} from '@mui/material';
import {MergeType} from '@mui/icons-material';
import { useLang } from '../../../../hooks/useLang';
import useDispatchState from '../../stores/DispatchState';
import useSystemState from '~/features/System/stores/SystemState';

interface UnifyButtonProps{
  readonly canUnify?: boolean;
  readonly unify?: (params) => Promise<void>;
}

export default function UnifyButton(props:UnifyButtonProps){
  const { windowManager } = useSystemState();
  const { translate } = useLang()
  
  const handleChange = () => {
    windowManager()['DispatchUnification'].open.set(!windowManager()['DispatchUnification'].open.get())
  };

  return (
    <Grid item>
      <IconButton title={translate('Unify Dispatch')} disabled={!props.canUnify} onClick={handleChange}>
        <MergeType/>
      </IconButton>
    </Grid>);

}
