import { LatLng } from "leaflet";
import notify from '~/utils/notify';

export default function deviceSocket(message, dispatch, updateStatusVehicleListInDispatch, updateListAllVehicle, updateListAllNoPaginationVehicles, updateMarker, syncVehicleListInDispatch, createMarker, getMarker, getListVehiclesDisconnects, translate, user, actualNotification) {
  if (message.event_type != 'DEVICE_POSITION_CHANGED') console.info(`Device socket received: ${message.event_type}`);

  if (message.event_type === 'DISPATCH_DEVICE_STATUS_CHANGE') {

    if (dispatch().id.get() == message.content.dispatch_id) {
      try {
        updateStatusVehicleListInDispatch({
          dispatchDeviceId: message.content.dispatch_device_id,
          status: message.content.current_status,
          dateUpdated: message.content.current_date
        });
      } catch (error) {
        console.error(`Erro atualizando lista dos veículos no despacho atual ${JSON.stringify(message)} - ${JSON.stringify(error)}`)
      }
    }

    try {
      updateListAllVehicle({
        deviceId: message.content.device_id,
        dispatchStatus: message.content.current_status
      });
    } catch (error) {
      console.error(`Erro atualizando lista dos veículos da tela de suporte ${JSON.stringify(message)} - ${JSON.stringify(error)}`)
    }

    try {
      updateListAllNoPaginationVehicles({
        deviceId: message.content.device_id,
        dispatchStatus: message.content.current_status
      });
    } catch (error) {
      console.error(`Erro atualizando lista dos veículos da tela de suporte ${JSON.stringify(message)} - ${JSON.stringify(error)}`)
    }

  }
  if (message.event_type === 'DEVICE_POSITION_CHANGED') {
    try {
      if (message.content.latitude && message.content.longitude) {
        const params = {
          id: message.content?.device_id,
          markerId: `device-${message.content?.device_id}`,
          code: message.content?.code,
          position: new LatLng(message.content.latitude, message.content.longitude),
          status: message.content?.device_status_id,
          identifier: message.content?.identifier,
          speed: message.content?.speed ? message.content?.speed : 0,
          plate: message.content?.plate ? message.content?.plate : '',
          date: message.content?.date ? message.content?.date : '',
          groupId: message.content?.group_id,
          isCurrent: false,
          deviceType: message.content?.device_type ?? null
        }

        createMarker({ markerType: 'device', markerIdentifier: message.content.device_id, ...params as any })
      }
    } catch (error) {
      console.error(`Erro atualizando posição das viaturas ${JSON.stringify(message)} - ${JSON.stringify(error)}`)
    }

  }
  if (message.event_type === 'DEVICE_STATUS_CHANGE') {
    if (message.content?.current_status?.id) {
      try {
        const currentMarker = getMarker({ markerType: 'device', markerIdentifier: message.content.device_id });
        if (currentMarker) {
          updateMarker({ markerType: 'device', markerIdentifier: message.content.device_id, attribute: 'status', value: message.content.current_status.id });
        }
      } catch (error) {
        console.error(`Erro atualizando marcador de veículo ${JSON.stringify(message)} - ${error}`)
      }
    }
    try {
      updateListAllVehicle({
        deviceId: message.content.device_id,
        dispatchStatus: message.content.current_status,
      });
    } catch (error) {
      console.error(`Erro atualizando lista de veículos ${JSON.stringify(message)} - ${JSON.stringify(error)}`)
    }

    try {
      updateListAllNoPaginationVehicles({
        deviceId: message.content.device_id,
        dispatchStatus: message.content.current_status,
      });
    } catch (error) {
      console.error(`Erro atualizando lista de veículos ${JSON.stringify(message)} - ${JSON.stringify(error)}`)
    }
  }
  if (message.event_type === 'DISPATCH_ASSOCIATE_DEVICE') {

    if (message.content.dispatch_id === dispatch().id.get()) {
      try {
        syncVehicleListInDispatch(message.content.dispatch_id)
      } catch (error) {
        console.error(`Erro atualizando lista de veículos ${JSON.stringify(message)} - ${JSON.stringify(error)}`)
      }
    }

  }

  if (message.event_type === 'MULTIPLE_DISCONNECTION_COMPLETED') {
    message.content.map((device) => {
      return (
        getListVehiclesDisconnects(device)
      )
    });

    notify({
      message: `${translate('Multiple disconnection executed successfully!')}`,
      type: 'success',
      actualNotification: actualNotification
    });
  }

  if (message.event_type === 'DEVICE_MULTIPLE_DISCONNECTED') {
    const userSocket = message.content.user_id;

    if (userSocket != user) {
      message.content.devices?.map((device) => (
        notify({
          type: 'info',
          message: `${translate('The vehicle was deconnected in batch', {
            vehicle: device.device
          })}`,
          actualNotification: actualNotification

        })
      ))
    }
  }
}