import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IEducation from '../interfaces/IGender';


export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue, getValues} = useForm<IEducation>();
  const fields = [
      { name: 'id', type: 'hidden'},
      { name: 'name', type: 'string'},
  ];
  
    return(
      <CrudEdit 
        model={'gender'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        setFormValue={setFormValue}
        messageSuccess={'Gender successfully updated!'}  
        title={'Edit gender'}
        getValues={getValues} 
      />
    )
}
