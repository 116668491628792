import React from 'react'
import { Container, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { useLang } from '~/hooks/useLang'

export interface UnifiedDispatchProps {
  readonly id: number
  readonly dispatch_origin: {
    readonly code: string
  }
}

interface UnifiedDispatchPrintProps {
  readonly UnifiedDispatchPrintResponse: UnifiedDispatchProps[]
}

export default function UnifiedDispatchPrint({
  UnifiedDispatchPrintResponse,
}: UnifiedDispatchPrintProps) {
  const { translate } = useLang()

  return (
    <Container fixed={false}>
      <Grid container xs={12} sx={{ marginBottom: 2 }}>
        {UnifiedDispatchPrintResponse.length != 0 ? (
          UnifiedDispatchPrintResponse.map((dispatch) => (
            <Grid container key={dispatch?.id} xs={12} sx={{ margin: 1 }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography variant="subtitle1">
                  {dispatch?.dispatch_origin.code ??
                    `* ${translate('Uninformed')} *`}{' '}
                </Typography>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid item xs={12} textAlign={'center'}>
            <Typography variant="subtitle1">
              <b>{translate('NO INFORMATION REGISTERED')}</b>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}
