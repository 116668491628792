/* eslint-disable functional/prefer-readonly-type */
import * as React from 'react';
import Box from '@mui/material/Box';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { getAllTypes } from '~/features/Type/services';
import { getAllSubTypes } from '~/features/Subtype/services';
import { Downgraded, State, useHookstate } from '@hookstate/core';
import { useLang } from '~/hooks/useLang';
import LinearProgress from '@mui/material/LinearProgress';
import { InputSearchWithClear } from '~/components/InputSearchWithClear';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
export interface RenderTree {
  readonly id: string;
  readonly code?: string;
  readonly name: string;
  readonly description?: string;
  readonly selected: boolean;
  readonly parent?: string;
  readonly randomId: string;
  readonly children: readonly RenderTree[];
}

interface ITreeView {
  typesAndSubtypes: State<RenderTree>;
  typeOurSubtypeRequest?: State<RenderTree>;
  error?: string | null;
  clearFilter?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export default function ControlledTreeView({ typesAndSubtypes, typeOurSubtypeRequest, error, clearFilter }: ITreeView) {
  const { translate } = useLang();
  const location = useLocation();
  const [selected, setSelected] = useState<string[]>([]);
  const loading = useHookstate<boolean>(true);
  const search = useHookstate('');
  const childrens = useHookstate<readonly RenderTree[]>([]);
  const descriptionText = useHookstate<string | undefined>('')
  const typesAndSubtypesDescription = useHookstate<RenderTree>({
    id: 'root',
    name: translate('ALL'),
    description: '',
    selected: false,
    parent: '',
    children: [],
    randomId: ''
  })

  useEffect(() => {
    typesAndSubtypes.children.map(node => {
      handleChange(node, false)
    })

    search.set('')
  }, [clearFilter])

  const handleChange = (node: State<RenderTree>, checked: boolean) => {
    if (node.id.get() === 'root') {
      return
    }

    const childrenNode = node.children.get();
    if (childrenNode?.length) {
      node.merge({
        ...node.get(),
        selected: checked,
        children: node.children.get()?.map(childrens => ({
          ...childrens,
          selected: checked,
        }))
      })
    } else {
      node.selected.set(checked)
    }

    setDescription()
  };

  const renderTree = (nodes: State<RenderTree>) => {
    return (
      <TreeItem
        key={nodes.randomId.get()}
        itemId={nodes.randomId.get()}
        label={nodes.id.get() !== 'root' ? nodes.name.get() : nodes.children.get()?.length ? nodes.name.get() : translate('No records')}
        // label={
        //   nodes.id.get() !== 'root' ? (<FormControlLabel
        //     label={nodes.name.get()}
        //     control={
        //       <Checkbox
        //         checked={nodes.selected.get() || !!nodes.get().children?.filter(chi => chi.selected).length}
        //         onChange={(_, checked) => {
        //           handleChange(nodes, checked)
        //         }}
        //       />
        //     }
        //   />) : nodes.children.get()?.length ? nodes.name.get() : translate('No records')
        // }
      >
        {Array.isArray(nodes.children) && nodes.children.length ? nodes.children.map((node) => renderTree(node)) : null}
      </TreeItem>
    )
  };

  const handleSelect = (_: React.SyntheticEvent, itemIds: string[]) => {
    setSelected(itemIds);
  };

  const handleLoadData = async () => {
    const types = await getAllTypes();
    const subtypes = await getAllSubTypes();
    const typesMap = types.map(type => ({
      id: `${type.id}`,
      name: type.name,
      code: type.code,
      description: type.description,
      selected: false,
      randomId: uuidv4(),
      children: subtypes.filter(sub => sub.type_id === type.id).map(sub => ({
        id: `${sub.id}`,
        name: sub.name,
        code: sub.code,
        description: sub.description,
        parent: `${type.id}`,
        selected: false,
        randomId: uuidv4(),
      }))
    }))

    childrens.set(typesMap)
    typesAndSubtypes.children.set(typesMap);
    typeOurSubtypeRequest?.children.set(typesMap)
    typesAndSubtypesDescription.children.set(typesMap)
    loading.set(false);
  }

  useEffect(() => {
    handleLoadData()
  }, [])

  useEffect(() => {
    setDescription()
  }, [typeOurSubtypeRequest?.get(), search.get()])

  const setDescription = () => {
    const text = typesAndSubtypesDescription?.children.get().filter(item => item.selected || item.children.filter(child => child.selected).length).map((element) => `${translate('Code')}: ${element.code}\n${translate('Type')}: ${element.name}\n${element.children?.length ? translate('Subtype') + ': ' + element.children.filter(children => children.selected).map(element => element.name) : translate('No Subtypes')}\n${translate('Description')}: ${element?.description ?? translate('No description')}\n`).join('\n')
    descriptionText.set(text)
  }

  useEffect(() => {
    if (search.get()) {

      const typeOrSubtype = childrens.attach(Downgraded).get()?.filter(type => type.name?.toLocaleUpperCase().includes(search.get().toLocaleUpperCase()))

      if (!typeOrSubtype?.length) {
        typesAndSubtypes.children.set([]);
      } else {
        typesAndSubtypes.children.set(typeOrSubtype);
      }
    } else {

      typesAndSubtypes.children.set(JSON.parse(JSON.stringify(childrens.value)) ?? undefined)
    }
  }, [search.get()])


  return (
    <Grid container xs={12}>
      {location.pathname === '/area-config/create' &&
        <Grid item xs={3} />
      }
      <Grid item xs={4.5} sx={{ pr: 3 }}>
        <Grid>
          <InputSearchWithClear
            fullWidth
            sx={{ mb: 2 }}
            placeholder={translate('Enter a type')}
            search={search} />
        </Grid>
        <Box sx={{
          height: '516px',
          flexGrow: 1,
          overflowY: 'auto',
          outline: error ? `1px solid red` : `1px solid slategrey`,
          borderRadius: '0.2em',
        }}>
          <SimpleTreeView
            selectedItems={selected}
            onSelectedItemsChange={handleSelect}
            multiSelect
            defaultExpandedItems={['root']}
            sx={{ height: 240, flexGrow: 1 }}
          >
            {loading.get() && <LinearProgress />}


            {!loading.get() && renderTree(typesAndSubtypes)}
          </SimpleTreeView>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          disabled
          id="outlined-multiline-flexible"
          multiline
          value={descriptionText.get()}
          rows={21}
          sx={{ marginTop: 7 }}
        />
      </Grid>
    </Grid>
  );
}