import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { pt } from './translations/pt';
import { en } from './translations/en';
import { es } from './translations/es';

const resources = {
  pt: pt,
  en: en,
  es: es,
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'pt',
  // fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  keySeparator: ":",
  
})

export default i18n
