import React, { useEffect, useState } from 'react';
import { ListItemButton, ListItemIcon, ListItemText, Paper, CircularProgress, Typography } from '@mui/material';
import { formatDateHour } from '../../../../../../utils/format';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import useMessageState from '../../../../stores/MessageState';
import { Circle as CircleIcon } from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { parseUserDataMessage } from '~/features/Message/utils/parse';
import { authFetch } from '~/services/fetch';
import { useHookstate } from '@hookstate/core';
import { useLang } from '~/hooks/useLang';

export default function TabListMessageContent({ value, listMessageState }) {
  const { currentMessage, isWritingMessage, readMessage } = useMessageState()
  const { translate } = useLang();


  const nextData = async () => {
    const response = await authFetch({
      url: value == 'received' ? '/message/user' : '/message/sent-by-user',
      data: {
        page: Math.floor(listMessageState.get().length / 10),
        limit: 10,
      }
    })
    const parsedMessage = response.data.data.map((message) => parseUserDataMessage(message))
    listMessageState.merge(parsedMessage)
  }

  return (
    <Paper variant='outlined' sx={{ padding: 0 }}>
      <InfiniteScroll
        dataLength={listMessageState.value.length}
        next={nextData}
        hasMore={(listMessageState.value.length % 10) == 0}
        height={335}
        loader={
          listMessageState.get().length > 0 ?
            <CircularProgress sx={{ position: 'absolute', bottom: 220, left: 150 }} /> :
            <Typography sx={{ position: 'absolute', bottom: 220, left: 120 }}>
              {translate('No records')}
            </Typography>
        }
      >
        {listMessageState.get().map((message) => {
          return (
            <ListItemButton selected={message.id === currentMessage().id.get() && !isWritingMessage().get()} key={message.id} onClick={() => {
              currentMessage().set({
                id: message.id,
                sender: message.sender,
                receiver: message.receiver,
                messageDate: message.messageDate,
                message: message.message,
                readAt: message.readAt,
                readUser: message.readUser,
              });
              isWritingMessage().set(false);
              value == 'received' ? message.readAt ? true : readMessage(message.id) : false;
            }}>
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary={value == 'received' ? message.sender.name : message.receiver.name} secondary={formatDateHour(message.messageDate as string).toFormatedDate} />
              <ListItemIcon>
                {value == 'received' && (!message.readAt && <CircleIcon fontSize="small" color="primary" />)}
              </ListItemIcon>
            </ListItemButton>
          )
        })}

      </InfiniteScroll>
    </Paper>
  )
}