import React, { useEffect, useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { getCoordination } from './service';
import { CurrentCoordination } from './interface';

interface FilterSwitchCoordinationProps {
    readonly onCoordinationChange: (value: CurrentCoordination | null) => void;
    readonly onCheckedChange: (value: boolean) => void;
    readonly checked: boolean;
}

export default function FilterSwitchCoordination({ onCheckedChange, onCoordinationChange, checked }: FilterSwitchCoordinationProps) {
    const { translate } = useLang();
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
        getCoordination().then(coordination => {
            if (!coordination) {
                setIsChecked(false)
                setDisabled(true)
            }
            onCoordinationChange(coordination)
        })
    }, [])

    useEffect(() => {
        if (!checked) {
            setIsChecked(false)
        }
    }, [checked])


    return (
        <FormControlLabel
            label={translate('Current coordination shift')}
            control={
                <Switch
                    disabled={disabled}
                    checked={isChecked}
                    onChange={(_, checked) => {
                        setIsChecked(checked)
                        onCheckedChange(checked)
                    }}
                />
            }
        />
    )
}