import React, { useEffect } from 'react';
import CrudIndex from '~/components/CrudIndex';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';

export default function Index() {
    const { translate } = useLang();

    useEffect(() => {
      document.title = translate('Administration - Dispatch System')
    }, [])
    return (
        <Layout marginLayout={true}>
            <CrudIndex
                model='interest-point/subtype'
                title='Interest point subtype'
                aliasLabel={[{ column: 'type:name' , label: 'Tipo do Ponto de Interesse'}]}
                exceptColumns={[
                    'is_visible_to_mobile',
                    'is_visible_to_dispatch',
                    'is_criminal',
                    'review_time_in_days',
                    'expiration_time_in_days',
                    'interest_point_type_id',
                ]}    
            />
        </Layout>
    )
} 