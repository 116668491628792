import React, { useEffect } from 'react';
import Layout from '~/components/Layout';
import TabListVehiclesSocket from '~/components/ListVehiclesSocket';
import useUserState from '~/features/User/stores/UserState';
import { useLang } from '~/hooks/useLang';

export default function ListVehiclesSocket() {
  const { verifyPermission } = useUserState();
  const { translate } = useLang();

  useEffect(() => {
    document.title = translate('Dispatch System')
  }, [])

  return (
    <Layout
      marginLayout={false}
      sidebar={false}
      topbar={false}
    >
      <TabListVehiclesSocket fullList readOnly={verifyPermission(['dispatch_read_only'])} />
    </Layout>
  );
}