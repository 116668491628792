import React from 'react';
import { useLang } from '~/hooks/useLang';
import ConfirmDialog from '../ConfirmDialog';

export default function DisconnectDispatchVehicle({ openState, identification, handleAgree, handleClose, isLoading, disabled }) {
    const { translate } = useLang();

    return (
        <ConfirmDialog
            open={openState}
            title={translate('Disconnect') + ' ' + translate('Device:name')}
            content={`${translate('Do you want to disconnect the device')}: ${identification}?`}
            handleClose={handleClose}
            handleAgree={handleAgree}
            loading={isLoading}
            disabled={disabled}
        />
    );
}
