import React from 'react'
import { useLang } from '~/hooks/useLang'
import { useTheme } from '~/hooks/useTheme'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { State, useHookstate } from '@hookstate/core'
import { LoadingButton } from '@mui/lab'
import { GridRowId } from '@mui/x-data-grid'
import { deleteMultipleAreaConfig } from '../../services'
import notify from '~/utils/notify'


interface DialogDisconnectMultipleDispatchProps {
  readonly openDialog: State<boolean>
  readonly onClose: any
  readonly rowsSelected: State<any>
  readonly configIds: GridRowId[]
  readonly clearFilters: () => void
}

// eslint-disable-next-line max-lines-per-function
export default function DialogDeleteMultipleConfigArea({
  onClose,
  openDialog,
  rowsSelected,
  configIds,
  clearFilters
}: DialogDisconnectMultipleDispatchProps) {
  const { translate } = useLang()
  const { darkMode } = useTheme()
  const openDialogErrorClosed = useHookstate(false)
  const loading = useHookstate(false)
  const dispatchErrorClosed = useHookstate<ReadonlyArray<any> | null>(null)


  const handleClose = () => {
    openDialog.set(false)
  }

  const clickOutModalErroClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      openDialogErrorClosed.set(false)
    }
  }


  const handleDisconnectMultipleVehicles = () => {
    loading.set(true)
    openDialog.set(false)

    deleteMultipleAreaConfig(configIds)
      .then((response) => {
        if (response.data.length > 0) {
          dispatchErrorClosed.set(response.data)
          openDialogErrorClosed.set(true)
        }

        if (response.status === 200) {
          notify({
            message: `${translate(
              'Config Areas deleted sucessfully!'
            )}`,
            type: 'success',
          })
          onClose
        }
      })
      .finally(() => {
        clearFilters()
        loading.set(false)
      })
  }

  return (
    <Dialog
      open={openDialog.get()}
      onClose={onClose}
      disableEscapeKeyDown={true}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{translate('Area Config Delete')}</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">
          {translate('Summary of the selected config areas')}
        </Typography>
        <List
          sx={{
            width: '100%',
            maxHeight: '500px',
            bgcolor: darkMode ? 'transparent' : '#FFF',
            marginTop: 2,
            overflowY: 'auto',
            border: 1,
            borderColor: 'grey.500',
            borderRadius: 1,
          }}
        >
          {rowsSelected?.get().map((item, index, { length }) => (
            <>
              <ListItem key={item.id}>
                <ListItemText
                  sx={{
                    display: 'inline',
                    maxHeight: '150px',
                    overflow: 'auto',
                    whiteSpace: 'pre-line',
                  }}
                  primary={`${translate('agency')}: ${item.agency}`}
                  secondary={
                    <Typography>
                      {`${translate('Area')}: ${
                        item.area
                      }\n${translate('Dispatch Group')}: ${
                        item.dispatch_group
                      }\n${translate('Type')}: ${
                        item.type
                      }\n${translate('Subtype')}: ${
                        item.subtype ? item.subtype : translate('Does not apply')
                      }`}
                    </Typography>
                  }
                />
              </ListItem>
              {index + 1 != length && <Divider />}
            </>
          ))}
        </List>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          paddingX: '24px',
        }}
      >
        <Button variant="outlined" onClick={handleClose}>
          {translate('Cancel')}
        </Button>
        <LoadingButton
          loading={loading.get()}
          variant="contained"
          onClick={() => {
            handleDisconnectMultipleVehicles()
            // enableButtonShowResume.set(true), onClose
          }}
        >
          {translate('Submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
