import React from 'react';
import { useState } from '@hookstate/core';
import {IconButton, Grid} from '@mui/material';
import {AltRoute} from '@mui/icons-material';
import { useLang } from '../../../../hooks/useLang';
import useDispatchState from '../../stores/DispatchState';
import useSystemState from '~/features/System/stores/SystemState';

interface UnifyButtonProps{
  readonly canDisunify?: boolean;
  readonly disunify?: (params) => Promise<void>;
}

export default function DisunifyButton(props:UnifyButtonProps){
  const { windowManager } = useSystemState();
  const { translate } = useLang()


  const handleChange = () => {
    windowManager()['DispatchDisunification'].open.set(!windowManager()['DispatchDisunification'].open.get())
  };

  return (
    <Grid item>
      <IconButton title={translate('Disunify Dispatch')} disabled={!props.canDisunify} onClick={handleChange}>
        <AltRoute/>
      </IconButton>
    </Grid>);

}
