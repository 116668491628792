import React from 'react';
import { AppRouteProps } from '~/routes';
import Index from './Pages';
import Create from './Pages/Create';
import Edit from './Pages/Edit';

export default function InterestPointSubtypeRoutes(): readonly AppRouteProps[] {
    const routes: AppRouteProps[] = [
        {
            path: '/interest-point/subtype', component: Index, isProtected: true,
            permissions: ['interest_point_subtype_index']
        },
        {
            path: '/interest-point/subtype/create', component: Create, isProtected: true,
            permissions: ['interest_point_subtype_create']
        },
        {
            path: '/interest-point/subtype/edit/:id', component: Edit, isProtected: true,
            permissions: ['interest_point_subtype_edit']
        },
    ];

    return routes;
}
