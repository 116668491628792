export const pt = {
  translation: {
    'No options': 'Sem opções',
    Save: 'Salvar',
    Saving: 'Salvando',
    Login: 'Entrar',
    'Incorrect login or password.': 'Login ou senha incorretos.',
    'User not found.': 'Usuário não encontrado.',
    Agency: {
      acronym: 'Sigla',
      name: 'Agência',
    },
    'Type friendly': 'Tipo amigável',
    'Subtype friendly': 'Subtipo amigável',
    'Type/SubType Friendly': 'Tipo e Subtipo Amigável',
    'Add Type/SubType Friendly': 'Adicionar Tipo e Subtipo amigável',
    'Add new Type/Subtype Friendly': 'Adicionar novo Tipoe e Subtipo amigável',
    'Type/Subtype Friendly successfully created!':
      'Tipo e Subtipo amigável criado com sucesso!',
    'Edit Type/SubType Friendly': 'Editar Tipo e Subtipo Amigável',
    'File already exist with this name': 'Um arquivo já existe com esse nome',
    'Select a label': 'Selecione uma etiqueta',
    Locate: 'Localizar',
    Viewport: 'Visualização',
    'Save current viewport as favorite':
      'Salve a visualização atual como favorita',
    'Successfully saved viewport': 'Área visualizada foi salva com sucesso',
    'Confirm to save the current viewport as your favorite':
      'Deseja usar a área atual de visualização como sua favorita',
    'Added successfully': 'Adicionado com sucesso',
    'File type must be': 'O formato do arquivo deveria ser',
    'Select a agency': 'Selecione uma agência',
    'Select a device': 'Selecione um dispositivo',
    'Management Dashboard: Dispatch': 'Painel de Gestão: Despacho',
    'Management Dashboard: Entry': 'Painel de Gestão: Atendimento',
    'Select a subtype': 'Selecione um subtipo',
    'Select a type': 'Selecione um tipo',
    Loading: 'Carregando',
    'No Device Id': 'Sem Id no Dispositivo',
    'Select a dispatch group': 'Selecione um Grupo de Despacho',
    'Remove dispatch group': 'Remover Grupo de Despacho',
    'Destiny Group': 'Grupo de Destino',
    'To Be Return At': 'Ser Retornado em',
    'Destiny ID': 'ID do Destino',
    'Destiny Name': 'Nome no Destino',
    'Destiny Group ID': 'ID do Grupo de destino',
    'Transfer Device': 'Transferir Dispositivo',
    Transfer: 'Transferência',
    Agencys: 'Agências',
    Statistics: 'Estatísticas',
    'Empty Csv': 'Csv Vazio',
    Download: 'Baixar',
    Downloading: 'Baixando',
    'Empty Dates': 'Datas vazias',
    Management: 'Gerenciamento',
    'Device connections': 'Conexões de dispositivos',
    'Please enter a valid device': 'Informe um dispositivo válido',
    Connections: 'Conexões',
    Connected: 'Conectado',
    'Connected on': 'Conectado no dia',
    Disconnected: 'Desconectado',
    'Disconnected on': 'Desconectado no dia',
    Uninformed: 'Não informado',
    Location: 'Localização',
    'Import Location': 'Importar dados de Localização',
    'Import all info': 'Importar todos os dados',
    'Connected by': 'Conectado por',
    'Without Dispatch': 'Sem Despacho',
    'Change Your Password': 'Mudar a sua senha',
    'Without login Parameter': 'Sem parâmetro de login',
    From: 'De',
    'From User': 'Do Usuário',
    'User not found': 'Usuário não encontrado',
    'Empty Fields': 'Campos vazios',
    'Empty Field': 'Campo vazio',
    Representation_name: 'Nome de Representação',
    'Password cannot be empty': 'A senha não pode estar vazia',
    'RePassword cannot be empty': 'A confirmação da senha não pode estar vazia',
    'Username cannot be empty': 'Nome não pode estar vazio',
    'The Passwords dont match': 'As senhas não são iguais',
    'New Password': 'Nova Senha',
    'Confirm new Password': 'Confirme a nova senha',
    'Change Password': 'Mudar a senha',
    'This device is not active right now': 'Dispositivo não ativado no momento',
    'Device already associated with another dispatch':
      'Dispositivo já esta associado a outro despacho',
    'Entry was dispatched with sucess': 'Atendimento despachado com sucesso',
    'Click to copy': 'Clique para copiar',
    Awaiting: 'Esperando',
    'No Locations, yet...': 'Sem Localização, por enquanto...',
    'Fetch Dispatches': 'Buscar por Despachos',
    'Not Found Dispatch with this code': 'Sem Despachos com esse código',
    Fetch: 'Buscar',
    'Trying to update status from a non-existing point':
      'Tentando atualizar o status de um point inexistente',
    Search: 'Buscar',
    'Entry without Latitude/Longitude': 'Atendimento sem Latitude/Longitude',
    'Nothing to this vehicle': 'Não consta informações sobre esse veículo',
    Nothing: 'Nada',
    'See Crew': 'Ver Guarnição',
    'Crew Composition': 'Composição da Guarnição',
    Composition: 'Composição',
    Tactical: 'Tático',
    'Device desconected Sucessfully!': 'Dispositivo desconectado com sucesso!',
    'Do you want to remove the device from this dispatch':
      'Deseja remover o dispositivo deste despacho',
    'Device was removed successfully !': 'Dispositivo Removido com Sucesso !',
    'New Dispatch Created!': 'Novo Despacho Criado!',
    'No Comments': 'Sem Comentários',
    'Not Found': 'Não Encontrado',
    'Use This Location As Is to Current Entry':
      'Usar Essa Localização para o Atendimento Atual',
    'Use Location': 'Usar Localização',
    'Use Only Geographical Coordinates': 'Usar Apenas Coordenadas Geográficas',
    'Write something': 'Escreva Algo',
    'No Receivers': 'Sem Destinatários',
    Agencies: 'Agências',
    'Agency successfully updated!': 'Agência atualizada com sucesso!',
    'Agency successfully created!': 'Agência criada com sucesso!',
    'Type successfully updated!': 'Tipo atualizado com sucesso!',
    'Type successfully created!': 'Tipo criado com sucesso!',
    'Subtype successfully updated!': 'Subtipo atualizado com sucesso!',
    'Subtype successfully created!': 'Subtipo criado com sucesso!',
    'Add agency': 'Adicionar Agência',
    'Edit agency': 'Editar Agência',
    Acronym: 'Sigla',
    Id: 'ID',
    Username: 'Nome',
    Phone: 'Telefone',
    Occurrences: 'Ocorrências',
    'Closing dispatch by station': 'Fechamento do despacho pela estação',
    'Closing dispatch by device': 'Fechamento pelo dispositivo',
    'Dispatch it': 'Despachar',
    Dispatch: 'Despacho',
    'Closed Status': 'Status de Fechamento',
    Administration: 'Administração',
    RegisterDate: 'Data de Registro',
    City: 'Cidade',
    District: 'Bairro',
    Districts: 'Bairros',
    'Add district': 'Adicionar bairro',
    'Add a new district': 'Adicionar um novo bairro',
    Address: 'Endereço',
    Coordinates: 'Cordenadas',
    Description: 'Descrição',
    Name: 'Nome',
    'Enter a name': 'Informe um nome',
    'Receiver name': 'Nome do recebedor',
    'Receiver CPF': 'CPF do recebedor',
    'Receiver Registry': 'Matrícula do receptor',
    FullName: 'Nome Completo',
    Confirmation: 'Confirmação',
    'Confirm agency removal?': 'Confirma remoção da agência?',
    Cancel: 'Cancelar',
    Ok: 'Ok',
    Password: 'Senha',
    'Entry Type': 'Tipo',
    Types: 'Tipos',
    'Entry Subtype': 'Subtipo',
    'Entry Origin': 'Origem do Chamado',
    Classification: 'Classificação',
    Origin: 'Origem',
    Organization: 'Organização',
    'Dispatch Group': 'Grupo de Despacho',
    Localization: 'Localização',
    Cities: 'Cidades',
    Neighborhoods: 'Vizinhos',
    Code: 'Código',
    'Add type': 'Adicionar tipo',
    'Edit type': 'Editar tipo',
    Accepted: 'Aceitar',
    Updated: 'Atualizar',
    TLIA: 'TLIA',
    Subtype: 'Subtipo',
    Subtypes: 'Subtipos',
    'Add subtype': 'Adicionar subtipo',
    'Add a new subtype': 'Adicionar um novo subtipo',
    RepeatedCall: 'Rechamada',
    Comment: 'Comentário',
    Place: 'Local',
    Telephone: 'Telefone',
    'Load Previous Addresses': 'Carregar endereços anteriores',
    Timeline: 'Linha do tempo',
    Settings: 'Configurações',
    Entry: 'Atendimento',
    Priority: 'Prioridade',
    'Select an option': 'Selecione uma opção',
    Type_id: 'Tipo',
    'Add city': 'Adicionar cidade',
    'Add a new city': 'Adicionar uma nova cidade',
    'City successfully created!': 'Cidade criada com sucesso!',
    'City successfully updated!': 'Cidade atualizada com sucesso!',
    'Edit city': 'Editar cidade',
    State: 'Estado',
    Type: 'Tipo',
    Origins: 'Origens',
    'Add origin': 'Adicionar origem',
    'Edit origin': 'Edit origem',
    'Origin successfully created!': 'Origem adicionada com sucesso!',
    'Origin successfully updated!': 'Origem atualizada com sucesso!',
    'Area Plans': 'Mapas',
    'Area Plan': 'Mapa',
    'Edit area plan': 'Editar mapa',
    'Add area plan': 'Adicionar mapa',
    'Area plan successfully created!': 'Mapa adicionado com sucesso!',
    'Area plan successfully updated!': 'Mapa atualizado com sucesso!',
    'Last Calls': 'Últimas chamadas',
    'No Calls': 'Sem chamadas anteriores',
    'My Calls': 'Meus Atendimentos',
    'Entry Log': 'Registro de entrada',
    'Entry Status': 'Status de atendimento',
    Is_recall: 'É re-chamada?',
    'Is recall ?': 'É re-chamada?',
    Is_prank_call: 'É trote?',
    'Is prank call ?': 'É trote?',
    'Number of pranks': 'Número de trotes',
    'Add status': 'Adicionar Status',
    'Prank call': 'Trote',
    'Entry Status successfully created!':
      'Status de atendimento criado com sucesso!',
    'Entry Status successfully updated!':
      'Status de atendimento atualizado com sucesso!',
    'Edit Entry Status': 'Editar Status de atendimento',
    'Follow-up': 'Acompanhamento',
    'Crossing Street': 'Cruzamento',
    'Forgot Password?': 'Esqueceu sua senha?',
    Stations: 'Estações',
    Plate: 'Placa',
    Circunstances: 'Situações',
    'Enter a circunstance': 'Informe uma situação',
    Destinations: 'Destinos',
    'Enter a destination': 'Informe um destino',
    Identification: 'Identificação',
    Coordination: 'Coordenação',
    Vehicle: 'Veículo',
    'Saved successfully': 'Salvo com sucesso',
    'Successfully created': 'Criado com sucesso',
    'Enter a weapon type': 'Informe um tipo de arma',
    'Inform details': 'Informe detalhes',
    'Vehicle created successfully': 'Veículo criado com sucesso ',
    Vehicles: 'Veículos',
    Device: {
      name: 'Dispositivo',
      status: 'Status',
      out_of_service: 'Fora de Serviço',
    },
    'Initial Kilometer': 'Quilômetro Inicial',
    Task: 'Atuação',
    Devices: 'Dispositivos',
    Brands: 'Marcas',
    Model: 'Modelo',
    'Enter a vehicle model': 'Insira um modelo de veículo',
    'Enter a vehicle color': 'Insira a cor do veículo',
    'Enter a vehicle brand': 'Insira uma marca de veículo',
    'Enter a country state': 'Insira um estado do país',
    'Add Vehicle': 'Adicionar Veículo',
    'Rows per page': 'Linhas por página',
    'Registration of Qualifiers': 'Cadastrar Qualificadores',
    Register: 'Cadastrar',
    'Registration of People': 'Cadastro de Pessoas',
    Registrations: 'Cadastros',
    'Enter a CPF or registration number': 'Informe um CPF ou matrícula',
    'Enter a auxiliary organ': 'Digite um órgão auxiliar',
    People: 'Pessoas',
    'No records': 'Sem Registros',
    Person: 'Pessoa',
    'Vehicle Registration': 'Cadastro de Veículos',
    'Weapons Registration': 'Cadastro de Armamento',
    Weapons: 'Armamento',
    'Drug Registry': 'Cadastro de Entorpecentes',
    Drug: 'Entorpecente',
    Quantity: 'Quantidade',
    'Enter a quantity': 'Informe uma quantidade',
    'Unit Of Measurement': 'Unidade de Medida',
    Add: 'Adicionar',
    Edit: 'Editar',
    Delete: 'Deletar',
    Reset: 'Reiniciar',
    'Reset Authentication': 'Resetar Autenticação',
    Back: 'Voltar',
    Next: 'Próximo',
    Finish: 'Finalizar',
    'Completed Registration': 'Cadastro Finalizado',
    "All steps completed - you' re finished":
      'Todas as etapas concluídas - você terminou',
    'Add a new vehicle': 'Adicionar um novo veículo',
    'Add Device': 'Adicionar Dispositivo',
    'Add a new device': 'Adicionar um novo dispositivo',
    VehicleType: {
      name: 'Tipo de Veiculo',
    },
    Occurrence: 'Ocorrência',
    Reason: 'Motivo',
    Submit: 'Enviar',
    'Dispatched successfully!': 'Despachado com sucesso!',
    'Finished successfully!': 'Finalizado com sucesso',
    'Last Occurrence': 'Últimas Ocorrências',
    'Create New Work Station': 'Criar Nova Estação de Trabalho',
    'Edit Work Station': 'Editar Estação de Trabalho',
    Station: 'Estação',
    'Station successfully created!': 'Estação criada com sucesso!',
    'Station successfully updated!': 'Estação atualizada com sucesso!',
    'Autentication Key': 'Chave de Autenticação',
    'Something went wrong! Try again': 'Algo deu errado! Tente novamente',
    'Something went wrong! Try again.': 'Algo deu errado! Tente novamente.',
    'Verification Key': 'Chave de Verificação',
    'Activation Key': 'Chave de Ativação',
    'Dispatch Termination Code': 'Código de Fechamento do Despacho',
    'Dispatch out of service reason': 'Motivo de Saída de serviço',
    'Device out of service reason successfully created':
      'Motivo de dispositivo fora de serviço criado com sucesso',
    'Device out of service reason': 'Motivo de dispositivo fora de serviço',
    'Add new Dispatch closed status':
      'Adicionar novo status de fechamento do Despacho',
    'Add Device out of service reason':
      'Adicionar motivo de dispositivo fora de serviço',
    'Add new Device out of service reason':
      'Adicionar novo motivo de dispositivo fora de serviço',
    'Add new Device': 'Adicionar novo Dispositivo',
    Icon: 'Icone',
    'Dispatch closed status successfully updated!':
      'Status de despacho fechado atualizado com sucesso!',
    'Edit Dispatch closed status': 'Editar status de despacho fechado',
    'Device out of service reason successfully updated!':
      'Motivo do dispositivo fora de serviço atualizado com sucesso!',
    'Edit out of service reason status':
      'Editar do dispositivo fora de serviço',
    'Closed status': 'Status de fechamento',
    'Out of service reason': 'Motivo fora de serviço',
    'Confirm delete?': 'Confirmar exclusão?',
    Activation: 'Ativação',
    Activate: 'Ativar',
    'Saved successfully!': 'Salvo com sucesso!',
    'Please check the information and try again!':
      'Verifique as informações e tente novamente!',
    'Dispatch copied successfully!': 'Despacho {{code}} aberto na ocorrencia!',
    'Add successfully!': 'Adicionado com sucesso!',
    'User alrealy connected': 'Usuário já está conectado',
    'Invalid Credentials': 'Credenciais Inválidas. Tente Novamente',
    'Invalid CPF': 'CPF inválido',
    'No Messages': 'Sem Mensagens',
    Close: 'Fechar',
    Bind: 'Vincular',
    'Authentication Key': 'Chave de Autenticação',
    'Authenticated Station': 'Estação Autenticada',
    Received: 'Recebido',
    Sent: 'Enviado',
    'This station is already active': 'Esta estação já está ativa.',
    Messages: 'Mensagens',
    Send: 'Enviar',
    'Message sent successfully!': 'Mensagem enviada com sucesso!',
    User: 'Usuário',
    Users: 'Usuários',
    'Add User': 'Adicionar Usuário',
    'Edit user': 'Editar Usuário',
    'Add new user': 'Adicionar novo usuário',
    'User successfully created!': 'Usuário criado com sucesso!',
    'User successfully updated!': 'Usuário editado com sucesso!',
    Category: 'Categoria',
    Receiver: 'Destinatário',
    Pending: 'Pendente',
    Dispatched: 'Despachado',
    Finished: 'Finalizado',
    'Verification Code': 'Código de Verificação',
    'Please write down your verification code: ':
      'Por favor anote seu código de verificação: ',
    Chronology: 'Cronologia',
    Repassword: 'Confirmação de Senha',
    'Birth Date': 'Data de Nascimento',
    'Blood Type': 'Tipo Sanguíneo',
    'Emergency Contact Name': 'Contato de Emergência',
    'Emergency Contact Phone': 'Telefone do Contato de Emergência',
    Resgistration: 'Registro',
    'Next status': 'Próximo status',
    Free: 'Liberar',
    'Add Dispatch Group': 'Adicionar Grupo de Despacho',
    'Add a new Dispatch Group': 'Adicionar novo Grupo de Despacho',
    'Edit Dispatch Group': 'Editar Grupo de Despacho',
    Reference: 'Referência',
    Speed: 'Velocidade',
    'Last update': 'Última atualização',
    'Elapsed Time': 'Tempo Transc.',
    'Edit Device': 'Editar Dispositivo',
    'Font Size': 'Tamanho da Fonte',
    Driver: 'Motorista',
    Commander: 'Comandante',
    Patrolmans: 'Patrulheiros',
    'No patrolmans': 'Sem patrulheiros informados',
    Patrolman: 'Patrulheiro',
    'Add Patrolman': 'Adicionar Patrulheiro',
    IdIbge: 'Código IBGE',
    Disconnect: 'Desconectar',
    Highlight: 'Destaque',
    'New Dispatch with Emergency Created!':
      'Novo Despacho com Emergência Criada!',
    'Reopened successfully!': 'Reaberto com sucesso!',
    'Hold successfully!': 'Retido com sucesso!',
    'Free successfully!': 'Liberado com sucesso!',
    Holded: 'Retido',
    Events: 'Eventos',
    Comments: 'Comentários',
    'Edit Comment': 'Editar comentário',
    'Deleted Comment': 'Comentário excluído',
    'You cannot write comments': 'Comentários',
    'Delete comment': 'Excluir comentário',
    Open: 'Abrir',
    'Out of service': 'Fora de serviço',
    'Alter Address': 'Alterar Endereço',
    Confirm: 'Confirmar',
    'Confirm Edit': 'Confirmar Edição',
    'Return to service': 'Retornar ao serviço',
    'Device returned to service sucessfully!':
      'Veículo retornou ao serviço com sucesso!',
    'Confirm this vehicle to return to service?':
      'Confirme a volta ao serviço deste veículo?',
    'The page you’re looking for doesn’t exist.':
      'A página que você está procurando não existe.',
    Chosen: 'Escolhido',
    Choices: 'Escolhas',
    Selected: 'Selecionado',
    Communicated: 'Comunicado',
    'On Route': 'Em rota',
    'Arrival On Site': 'Chegada no local',
    'On Carriage': 'Em transporte',
    'Arrival In Transport': 'Chegada em Transporte',
    Closed: 'Fechada',
    Hold: 'Retido',
    Online: 'Conectado',
    Offline: 'Desligada',
    'Stop Service': 'Fora de serviço',
    'Low Priority': 'Baixa Prioridade',
    'Medium Priority': 'Média Prioridade',
    'High Priority': 'Máxima Prioridade',
    'Device Status': 'Status do dispositivo',
    'Compressed View': 'Visão Comprimida',
    'Complete View': 'Visão Completa',
    DispatchGroup: {
      name: 'Grupo de Despacho',
    },
    Caliber: 'Calibre',
    'Serial Number': 'Número de Série',
    'Add a new agency': 'Adicionar nova Agência',
    'Permission Group': 'Grupo de permissão',
    'View Vehicles': 'Visualizar Veículos',
    'View Occurrences': 'Visualizar Ocorrências',
    'Import type/subtype': 'Importar dados de Tipo e Subtipo',
    'Map Settings': 'Configurações do Mapa',
    Requester: 'Solicitante',
    'No Requester': 'Sem Solicitante',
    'Requester Contact': 'Contato do Solicitante',
    'Import Requester': 'Importar dados do Solicitante',
    'Changed address successfully': 'Endereço alterado com sucesso',
    Dispatches: 'Despachos',
    'Dispatch List': 'Lista de Despacho',
    Filters: 'Filtros',
    Filter: 'Filtrar',
    'Initial Date': 'Data Inicial',
    'End Date': 'Data Final',
    'Management Dashboard': 'Painel de Gestão',
    'Type Chart': 'Gráfico de Tipo',
    'Subtype Chart': 'Gráfico de Subtipo',
    'Dispatch x Dispatch Group': 'Despacho x Grupos de Despacho',
    'Dispatch By Hours Chart': 'Gráfico de Despacho por Horas',
    'Dispatch x City': 'Despacho x Cidade',
    'Dispatch x District': 'Despacho x Distrito',
    'Status Chart': 'Gráfico de Status',
    'Dispatch x Device': 'Despacho x Dispositivo',
    'Dispatch x Closed Status': 'Despacho x Status de Fechamento',
    'Type and Subtype': 'Tipo e Subtipo',
    'Time and Status': 'Hora e Status',
    'Lasts Dispatchs': 'Últimos Despachos',
    'Dispatch unified successfully!': 'Despacho unificado com sucesso!',
    'Occurrence Number': 'Número da Ocorrência',
    'Not in Service': 'Fora de Serviço',
    Hours: 'Horas',
    Minutes: 'Minutos',
    minutes: 'minutos',
    minute: 'minuto',
    seconds: 'segundos',
    second: 'segundo',
    'Requester Name': 'Nome do Solicitante',
    'Do you wish to send this call to be handled by dispatchers?':
      'Deseja enviar esse atendimento para tratamento por despachantes?',
    'By Period': 'Por Período',
    'Enter a start and end date': 'Informe uma data incial e final',
    'Select a Period': 'Selecione um Período',
    'Last 24 hours': 'Últimas 24 horas',
    'Last 7 days': 'Útimos 7 dias',
    'Last month': 'Último mês',
    'Last year': 'Último ano',
    'Group successfully add!': 'Grupo adicionado com sucesso!',
    'Not informed': 'Não informado',
    'Do you want to use this occurrence for a recall?':
      'Deseja usar esta ocorrência para uma rechamada?',
    'try saving': 'Salve e tente novamente',
    Date: 'Data',
    Red: 'Vermelho',
    Orange: 'Laranja',
    Yellow: 'Amarelo',
    Green: 'Verde',
    Blue: 'Azul',
    Purple: 'Roxo',
    Black: 'Preto',
    White: 'Branco',
    Gray: 'Cinza',
    Note: 'Observação',
    Notes: 'Observações',
    'Note was removed successfully': 'Observação removida com sucesso!',
    'Inform the notes': 'Informe as anotações',
    'Delete notes': 'Excluir observação',
    'License plate already registered': 'Placa já cadastrada',
    'Please provide a valid license plate': 'Informe uma placa',
    'Enter a vehicle type': 'Informe um tipo de veículo',
    'Date Of Approach': 'Data de Abordagem',
    'Last data update': 'Última atualização de dados',
    'Data displayed is for the last 12 hours':
      'Os dados exibidos são das últimas 12 horas',
    Registration: 'Matrícula',
    'Approach Mode': 'Modo da Abordagem',
    Directly: 'Imediatamente',
    Robbery: 'Roubado',
    Feature: 'Por Característca',
    'Timeline Event': {
      name: 'Nome',
      action: 'Ação',
    },
    'No dispatch groups for this entry':
      'Sem Grupo de despachos associados a esse atendimento',
    Update: 'Atualizar',
    Birth_date: 'Data de Nascimento',
    Contact: 'Contato',
    Emergency_contact_name: 'Nome do Contato de Emergência',
    Emergency_contact_phone: 'Telefone do Contato de Emergência',
    Mobile: 'Dispositivo Móvel',
    'Do you want to use this dispatch to unify the current dispatch?':
      'Deseja usar esse despacho para unificar o despacho corrente?',
    'Cancel Send': 'Cancelar envio',
    'Drag and Drop Image Here': 'Arraste e Solte a Imagem Aqui',
    File: 'Arquivo',
    'Clear Filters': 'Limpar Filtros',
    Clear: 'Limpar',
    'Total by Status': 'Total por Status',
    'Try again': 'Tente novamente',
    'Something went wrong': 'Algo deu errado',
    'Management Entry': 'Painel de Gestão',
    'Management Entry: Entry': 'Painel de Gestão: Atendimento',
    Center: 'Central',
    'Entry User': 'Atendimento por Usuário',
    'Open Entry User': 'Atendimento Aberto por Usuário',
    'Entry Station': 'Atendimento por Estação',
    'Open Entry Station': 'Atendimento Aberto por Estação',
    'Entry Center': 'Atendimento por Central',
    'Open Entry Center': 'Atendimento Aberto por Central',
    'Entry Time User': 'Tempo de Atendimento por Usuário',
    'Entry Time Station': 'Tempo de Atendimento por Estação',
    'Entry Time Center': 'Tempo de Atendimento por Central',
    'Write your comment': 'Escreva seu comentário',
    'Automatic Insertion': 'Inserção Automática',
    'Enter a vehicle': 'Informe um veículo',
    'Enter a task': 'Informe uma atuação',
    'Out Of Service Reason': 'Motivo',
    Overview: 'Visão Geral',
    'End state': 'Status Final',
    'SERVICE AND DISPATCH OF OCCURRENCES':
      'ATENDIMENTO E DESPACHO DE OCORRÊNCIAS',
    Document: 'Documento',
    'Document Type': 'Tipo de Documento',
    'Phone Number': 'Telefone',
    'Mother Name': 'Nome da Mãe',
    'Father Name': 'Nome do Pai',
    Arrested: 'Prisão',
    'Was there arrest?': 'Houve prisão?',
    'Public Agent': 'Funcionário Público',
    'Mental Illness': 'Doença Mental',
    'Physical Illness': 'Doença Física',
    'Personal Data': 'Dados Pessoais',
    'Personal Data / Occurrence Data': 'Dados Pessoais / Dados da Ocorrência',
    Conditions: 'Condições',
    'Occurrence Data': 'Dados da Ocorrência',
    'Document generated in': 'Documento gerado em',
    Involved: 'Envolvidos',
    Guns: 'Armas',
    Drugs: 'Drogas',
    Displacement: 'Deslocamento',
    Finalization: 'Finalização',
    Seizures: 'Apreensões',
    'Active Agencies': 'Agências Atuantes',
    'NO INFORMATION REGISTERED': 'NENHUMA INFORMAÇÃO CADASTRADA',
    'Register number': 'Número do Registro',
    'Request Data': 'Dados da Solicitação',
    Objects: 'Objetos',
    Nickname: 'Apelido',
    Birthdate: 'Data de nascimento',
    Gender: 'Gênero',
    Age: 'Idade',
    Ethnicity: 'Etnia',
    Attendant: 'Atendente',
    Operators: 'Operadores(as)',
    'Dispatch Form': 'Ficha de Despacho',
    Owner: 'Proprietário(a)',
    Color: 'Cor',
    'Agencies Involved': 'Agências Envolvidas',
    'Final Destination': 'Destino Final',
    Motive: 'Motivo',
    Situation: 'Situação',
    'No Occurrences': 'Sem Ocorrências',
    'Empty Driver Return': 'Retorno Vazio de Motorista',
    'Empty Commander Return': 'Retorno Vazio de Comandante',
    'Empty Patrolmans Return': 'Retorno Vazio de Patrulheiros',
    'Unified Dispatches': 'Despachos Unificados',
    'Do you want to de-unify the dispatch from the current dispatch?':
      'Deseja desunificar o despacho do despacho atual?',
    'Dispatch disunified successfully!': 'Despacho desunificado com sucesso!',
    VehicleBrand: {
      name: 'Marca',
    },
    VehicleModel: {
      name: 'Modelo',
    },
    VehicleColor: {
      name: 'Cor',
    },
    'Vehicle Brand': 'Marca do Veículo',
    'Vehicle Model': 'Modelo do Veículo',
    'Vehicle Color': 'Cor do Veículo',
    'Vehicle Type': 'Tipo do Veículo',
    'Vehicle brand successfully updated!':
      'Marca do veículo atualizada com sucesso!',
    'Vehicle brand successfully created!':
      'Marca do veículo criada com sucesso!',
    'Vehicle model successfully updated!':
      'Modelo do veículo atualizado com sucesso!',
    'Vehicle model successfully created!':
      'Modelo do veículo criado com sucesso!',
    'Vehicle color successfully updated!':
      'Cor do veículo atualizado com sucesso!',
    'Vehicle color successfully created!': 'Cor do veículo criado com sucesso!',
    'Vehicle type successfully updated!':
      'Tipo do veículo atualizado com sucesso!',
    'Vehicle type successfully created!': 'Tipo do veículo criado com sucesso!',
    'Add vehicle brand': 'Adicionar marca',
    'Edit vehicle brand': 'Editar marca',
    'Add vehicle model': 'Adicionar modelo',
    'Edit vehicle model': 'Editar modelo',
    'Add vehicle color': 'Adicionar cor',
    'Edit vehicle color': 'Editar cor',
    'Add vehicle type': 'Adicionar marca',
    'Edit vehicle type': 'Editar marca',
    PublicAgency: {
      name: 'Órgão',
    },
    'Organization successfully updated!': 'Órgão atualizado com sucesso!',
    'Organization successfully created!': 'Órgão criado com sucesso!',
    'Add Organization': 'Adicionar órgão',
    'Edit Organization': 'Editar órgão',
    Is_public: 'É público?',
    'Weapon Type': 'Tipo da Arma',
    'Weapon Kind': 'Espécie da Arma',
    'Weapon Brand': 'Marca da Arma',
    'Firearms Calibre': 'Calibre',
    'Weapon type successfully updated!': 'Tipo de arma atualizado com sucesso!',
    'Weapon type successfully created!': 'Tipo de arma criado com sucesso!',
    'Add weapon type': 'Adicionar tipo de arma',
    'Edit weapon type': 'Editar tipo de arma',
    'Weapon kind successfully updated!':
      'Espécie de arma atualizado com sucesso!',
    'Weapon kind successfully created!': 'Espécie de arma criado com sucesso!',
    'Add weapon kind': 'Adicionar espécie de arma',
    'Edit weapon kind': 'Editar espécie de arma',
    'Weapon brand successfully updated!':
      'Marca de arma atualizado com sucesso!',
    'Weapon brand successfully created!': 'Marca de arma criado com sucesso!',
    'Add weapon brand': 'Adicionar marca de arma',
    'Edit weapon brand': 'Editar marca de arma',
    'Firearm Caliber': 'Calibre da Arma',
    'Firearm caliber successfully updated!':
      'Calibre da arma atualizado com sucesso!',
    'Firearm caliber successfully created!':
      'Calibre da arma criado com sucesso!',
    'Add firearm caliber': 'Adicionar calibre da arma',
    'Edit firearm caliber': 'Editar calibre da arma',
    'Drug Type': 'Tipo de Droga',
    'Enter a drug type': 'Digite um tipo de droga',
    'Drug type successfully updated!': 'Tipo de droga atualizado com sucesso!',
    'Drug type successfully created!': 'Tipo de droga criado com sucesso!',
    'Add drug type': 'Adicionar tipo de droga',
    'Edit drug type': 'Editar tipo de droga',
    'Involvement Type': 'Tipo de Envolvido',
    'Enter a involvement type': 'Informe um tipo de envolvido',
    'Involvement type successfully updated!':
      'Tipo de envolvido atualizado com sucesso!',
    'Involvement type successfully created!':
      'Tipo de envolvido criado com sucesso!',
    'Add involvement type': 'Adicionar tipo de envolvido',
    'Edit involvement type': 'Editar tipo de envolvido',
    'Object Type': 'Tipo de Objeto',
    'Enter a object type': 'Informe um tipo de objeto',
    'Object type successfully updated!':
      'Tipo de objeto atualizado com sucesso!',
    'Object type successfully created!': 'Tipo de objeto criado com sucesso!',
    'Add object type': 'Adicionar tipo de objeto',
    'Edit object type': 'Editar tipo de objeto',
    Circunstance: 'Situação',
    'Circunstance successfully updated!': 'Situação atualizada com sucesso!',
    'Circunstance successfully created!': 'Situação criada com sucesso!',
    'Add circunstance': 'Adicionar situação',
    'Edit circunstance': 'Editar situação',
    'People Circunstance': 'Situação de Pessoas',
    'Enter a people circunstance': 'Insira uma circunstância de pessoas',
    'People circunstance successfully updated!':
      'Situação de pessoas atualizada com sucesso!',
    'People circunstance successfully created!':
      'Situação de pessoas criada com sucesso!',
    'Add people circunstance': 'Adicionar situação de pessoas',
    'Edit people circunstance': 'Editar situação de pessoas',
    Destination: 'Destino Final',
    'Destination successfully updated!':
      'Destino final atualizado com sucesso!',
    'Destination successfully created!': 'Destino final criado com sucesso!',
    'Add destination': 'Adicionar destino final',
    'Edit destination': 'Editar destino final',
    'Unit Measurement': 'Unidade de Medida',
    'Enter a unit measurement': 'Informe uma unidade de medida',
    'Unit Measurement successfully updated!':
      'Unidade de medida atualizada com sucesso!',
    'Unit Measurement successfully created!':
      'Unidade de Medida criada com sucesso!',
    'Add unit measurement': 'Adicionar unidade de medida',
    'Edit unit measurement': 'Editar unidade de medida',
    Symbol: 'Símbolo',
    Involvements: 'Envolvidos',
    Involvement: 'Envolvido',
    'Action Taken': 'Procedimento',
    'Enter a action taken': 'Informe um procedimento',
    'Action Taken successfully updated!':
      'Procedimento atualizado com sucesso!',
    'Action Taken successfully created!': 'Procedimento criado com sucesso!',
    'Add action taken': 'Adicionar procedimento',
    'Edit action taken': 'Editar procedimento',
    'Document type successfully updated!':
      'Tipo de documento atualizado com sucesso!',
    'Document type successfully created!':
      'Tipo de documento criado com sucesso!',
    'Add document type': 'Adicionar tipo de documento',
    'Edit document type': 'Editar tipo de documento',
    Organizations: 'Organizações',
    Qualification: 'Qualificação',
    Qualifications: 'Qualificações',
    Organ: 'Orgão',
    Organs: 'Orgãos',
    'Auxiliary Organ': 'Orgão Auxiliar',
    Education: 'Escolaridade',
    'Education successfully updated!': 'Escolaridade atualizada com sucesso!',
    'Education successfully created!': 'Escolaridade criada com sucesso!',
    'Add education': 'Adicionar escolaridade',
    'Edit education': 'Editar escolaridade',
    Educations: 'Escolaridades',
    'Gender successfully updated!': 'Gênero atualizado com sucesso!',
    'Gender successfully created!': 'Gênero criado com sucesso!',
    'Add gender': 'Adicionar gênero',
    'Edit gender': 'Editar gênero',
    'Enter a gender': 'Informe um gênero',
    'Ethnicity successfully updated!': 'Etnia atualizada com sucesso!',
    'Ethnicity successfully created!': 'Etnia criada com sucesso!',
    'Add ethnicity': 'Adicionar etnia',
    'Edit ethnicity': 'Editar etnia',
    Print: 'Imprimir',
    'No actions at the moment': 'Sem ações no momento',
    Reports: 'Relatórios',
    'Filters reports': 'Relatórios com Filtros',
    'Report Devices': 'Relatórios de dispositivos',
    'Occurrence report': 'Relatório de Ocorrência',
    Controller: 'Controlador',
    Brand: 'Marca',
    'Added successfully!': 'Adicionado com sucesso!',
    'Do you really want to delete': 'Você realmente deseja excluir?',
    'Successfully Deleted': 'Deletado com sucesso',
    Unified: 'Unificado',
    Opened: 'Aberto',
    'Do you really want to cancel': 'Você realmente deseja cancelar?',
    Yes: 'Sim',
    No: 'Não',
    Device_log: {
      task: 'Tarefa',
    },
    Tracking: 'Rastreamento',
    Lock_temporary_tracking: 'Bloqueado Rastreamento Temporário',
    'Inform a tracking id': 'Informe um rastreamento',
    Is_connected: 'Conectado',
    Need_to_change_password: 'Precisa mudar a senha',
    Ref_to_vehicle: 'Referente a Veículo',
    Default: 'Padrão',
    Available_for_mobile: 'Disponível para Aplicativo',
    Required_qualification: 'Requer Qualificadores',
    Weapon_type_id: 'ID do tipo de arma',
    Brand_id: 'ID da marca',
    Mandatory_vehicle: 'Qtd Veículo Obrigatório',
    Mandatory_involved: 'Qtd Envolvido Obrigatório',
    Mandatory_weapon: 'Qtd Arma Obrigatória',
    Mandatory_organization: 'Qtd Organização Obrigatória',
    Mandatory_drug: 'Qtd Droga Obrigatória',
    Mandatory_object: 'Qtd Objeto Obrigatório',
    'Add a system logo': 'Adicione a logo do sistema',
    'Logo was successfully uploaded': 'Upload da logo realizado com sucesso',
    'My Panel': 'Meu Painel',
    'Add Coordination': 'Adicione Coordenação',
    Can_reopen_dispatch: 'Permite reabertura do despacho',
    'Ibge Code': 'Código do IBGE',
    Patent: 'Patente',
    War_name: 'Nome de Guerra',
    Is_solved_by_third_party: 'É resolvido por terceiros',
    Coordinator: 'Coordenador',
    Created_by_user: {
      name: 'Criador',
      registration: 'Registro do Criador',
    },
    'Coordination successfully created!': 'Coordenação criado com sucesso!',
    Creator: 'Criador',
    'Expected Closing Date': 'Data Final Prevista',
    'Confirm closure?': 'Confirmar fechamento?',
    'Select a category': 'Selecione uma categoria',
    'Color System': 'Sistema de Cores',
    Logout: 'Deslogar',
    'Dispatch Group successfully created!':
      'Grupo de Despacho criado com sucesso!',
    'Dispatch Group successfully updated!':
      'Grupo de Despacho atualizado com sucesso!',
    'District successfully created!': 'Distrito criado com sucesso!',
    'District successfully updated!': 'Distrito atualizado com sucesso!',
    'Edit District': 'Editar Distrito',
    'Access to entry screen': 'Acesso a tela de atendimento',
    'Create new entry screen': 'Tela para criar novo atentimento',
    'Information about entry': 'Informações sobre os atendimentos',
    'Carry out dispatch': 'Realizar despacho',
    'Search with filters': 'Busca com filtros',
    'Information about dispatch': 'Informações sobre os despachos',
    'Coordination list': 'Lista de coordenações',
    'Show statistics': 'Exibir estatísticas',
    'Search connections': 'Buscar conexões de disposítivos',
    'Create, read, update and delete': 'Criar, listar, atualizar e deletar',
    'Organization options': 'Opções para Organização',
    'Classification options': 'Opções para Classificação',
    'Localization options': 'Opções para Localização',
    'Qualification options': 'Opções para Qualificação',
    'Weapons options': 'Opções para Armamento',
    'Vehicles options': 'Opções para Veículos',
    'Involvement options': 'Opções para Envolvido',
    'Organs options': 'Opções para Orgãos',
    'Drugs options': 'Opções para ',
    'Objects options': 'Opções para ',
    'Apply View Dispatch Group': 'Aplicar Visualização do Grupo de Despacho',
    Operation: 'Operação',
    Operations: 'Operações',
    'Operation successfully created!': 'Operação criada com sucesso!',
    'Add Operation': 'Adicionar operação',
    'Add a new operation': 'Adicionar uma nova operação',
    'Device was transferred successfully!':
      'O dispositivo foi transferido com sucesso!',
    Active: 'Ativo',
    'Create new operation screen': 'Tela para criar nova operação',
    'Address Reference': 'Referência',
    Number: 'Número',
    'View Operation': 'Visualizar operação',
    'View Operations': 'Visualizar operações',
    more: 'mais',
    less: 'menos',
    'edited by': 'editado por',
    deleted: 'deletado',
    me: 'mim',
    'Actual Date': 'Data Atual',
    'Device Chart': 'Gráfico de Dispositivos',
    'Hours Chart': 'Gráfico por Hora',
    'dd/mm/yyyy': 'dd/mm/aaaa',
    'Graph of Occurrences by Closing Status':
      'Gráfico de Ocorrências por Status de Fechamento',
    'Graph of Occurrences by Device': 'Gráfico de Ocorrências por Dispositivo',
    'Graph of Occurrences by District': 'Gráfico de Ocorrências por Distrito',
    'Graph of Occurrences by Date and Time':
      'Gráfico de Ocorrências por Data e Hora',
    'Graph of Occurrences by Subtype': 'Gráfico de Ocorrências por Subtipo',
    'Graph of Occurrences by Type': 'Gráfico de Ocorrências por Tipo',
    'Graph of Occurrences by Category': 'Gráfico de Ocorrências por Categoria',
    'Manager Window': 'Janela de Gerenciamento',
    'Map Viewport': 'Mapa de Visualização',
    'Composed on': 'Composta em',
    at: 'às',
    'Replace Vehicle': 'Substituir Viatura',
    'Edit Crew': 'Editar Composição',
    'already logged in at': 'já logado em',
    'a station': 'uma estação',
    'Insert a patrolman': 'Insira um patrulheiro',
    'already listed': 'já listado',
    'Inform a commander': 'Informe um comandante',
    'Inform a phone': 'Informe um telefone',
    'Inform a new vehicle': 'Informe uma nova viatura',
    'Edit crew': 'Editar guarnição',
    'Inform a device': 'Informe uma viatura',
    'Inform a Km': 'Informe uma quilometragem',
    'Window Support': 'Tela de suporte',
    Previous: 'Anterior',
    'Search Advanced': 'Busca Avançada',
    'New Entry': 'Novo Atendimento',
    Call: 'Chamada',
    Recall: 'Rechamada',
    'Change Operation': 'Mudar Operação',
    'Your date is not valid': 'Sua data não é válida',
    'Enter a document': 'Informe um documento',
    'Please provide a valid phone number':
      'Por favor forneça um número de telefone válido',
    'Enter a people situation': 'Insira uma situação de pessoas',
    'Enter a organization': 'Insira uma organização',
    Start: 'Iniciar',
    'Device conected Sucessfully!': 'Dispositivo conectado com sucesso!',
    'Device moved to out of service Successfully!':
      'Dispositivo movido para fora de serviço com sucesso!',
    'Reset Authentication Key': 'Resetar Chave de Autenticação',
    'Confirm restart of that station?': 'Confirmar a reiniciação dessa estação?',
    'Do you want to generate a new authentication key for this station?':
      'Deseja gerar uma nova Chave de Autenticação para essa Estação?',
    'Add patrolman': 'Adicionar patrulheiro',
    'Remove patrolman': 'Remover patrulheiro',
    'Contact Center': 'Central de Contato',
    'Add Contact Center': 'Central de Contato adicionado',
    'Add new Contact Center': 'Adicionar nova Central de Contato',
    'Contact Center successfully created!':
      'Central de Contato criado com sucesso!',
    'Contact Center successfully updated!':
      'Cental de Contato atualizado com sucesso!',
    'Edit Contact Center': 'Editar Central de Contato',
    'New Comment Created!': 'Novo Comentário Criado',
    'You cannot delete coordination already started':
      'Você não pode excluir a coordenação já iniciada',
    'Delete a coordination': 'Deletar uma coordenação',
    'Inform a valid phone': 'Informe um telefone válido',
    'Inform a driver': 'Informe um motorista',
    'Inform a CPF or registration': 'Informe um CPF ou matrícula',
    'Commander cannot be a patrolman': 'Comandante não pode ser um patrulheiro',
    'Do you want to disconnect the vehicle': 'Deseja desconectar o veículo',
    Mother: 'Mãe',
    Father: 'Pai',
    'Schedule to device was canceled successfully!':
      'O agendamento para o dispositivo foi cancelado com sucesso!',
    'Press enter or click the search button to search for a component':
      'Aperte enter ou clique no botão de pesquisar para buscar um componente',
    'Cancel Appointment': 'Cancelar Agendamento',
    'Remove occurrence solved by other dispatch groups':
      'Remover ocorrências resolvidas por outros grupos de despacho',
    'Group successfully add': 'Grupo de despacho adicionado',
    edited: 'editado',
    by: 'por',
    'Graph of Occurrences by Entry Origin':
      'Gráfico de Ocorrências por Origem de Entrada',
    Peoples: 'Pessoas',
    'Report Type': 'Tipo de Relatório',
    Complete: 'Completo',
    Synthetic: 'Sintético',
    'Search Vehicles': 'Pesquisar Veículos',
    'Search Involvement': 'Pesquisar Envolvidos',
    'No Vehicles': 'Sem Veículos',
    'No Content to Download': 'Sem conteúdo para baixar',
    'User Login': 'Login do Usuário',
    'Missing login or registration or cpf parameter':
      'Informe uma matrícula ou CPF',
    'Show edits': 'Mostrar edições',
    'Hide edits': 'Ocultar edições',
    'Edit IMEI or tracking': 'Editar IMEI ou rastreamento',
    'Device was updated successfully':
      'O dispositivo foi atualizado com sucesso',
    'Area Configuration': 'Configuração de área',
    'Print coordination': 'Imprimir a coordenação',
    'Dispatch Statistic': 'Estatísticas de Despacho',
    'You cannot print an open coordination':
      'Você não pode imprimir uma coordenação aberta',
    'View Dispatch': 'Visualizar Despachos',
    'View Device': 'Visualizar Dispositivos',
    'View Entry': 'Visualizar Atendimentos',
    'Use this location?': 'Usar essa localização?',
    All: 'Todos',
    'Select a type or subtype': 'Selecione um tipo ou subtipo',
    'No Code': 'Sem código definido',
    'No Subtypes': 'Sem subtipos',
    'No description': 'Sem descrição',
    'Total Subtypes': 'Total de subtipos',
    'Area Information': 'Informações de Área',
    Select: 'Selecione',
    'Dispatch Group Information': 'Informações de Grupo de Despacho',
    'Type and Subtype Information': 'Informações de Tipo e Subtipo',
    'List Vehicles': 'Listar veículos',
    'List Dispatches': 'Listar despachos',
    'Device in back to service': 'Veículo retornou ao serviço',
    'Device disconnected successfully': 'Dispositivo desconectado',
    'Enter the dispatch': 'Informe um despacho',
    None: 'Nenhuma',
    'No Dispatches': 'Sem despachos',
    'View Camera': 'Visualizar Cameras',
    'Management Report Filters': 'Painel de filtros do relatório ',
    'Generate report': 'Gerar relatório',
    'List of Occurrences': 'Lista de ocorrências',
    'Initial and final date are mandatory':
      'Data inicial e final são obrigatórias',
    Title: 'Título',
    Subtitle: 'Subtítulo',
    Period: 'Período',
    'Do you want to continue the service?': 'Deseja continuar o Atendimento?',
    'Next entry': 'Próximo atendimento',
    Continue: 'Continuar',
    'No data available':
      'Não há dados disponíveis para serem exibidos, com base no filtro selecionado',
    'Reopen Occurrence': 'Reabrir ocorrência',
    'Do you want to reopen this occurrence?': 'Deseja reabrir essa ocorrência?',
    'Occurrence reopen Sucessfully!': 'Ocorrência reaberta com sucesso!',
    'Duplicate patrolman': 'Patrulheiro duplicado',
    'Driver cannot be a patrolman': 'Motorista não pode ser patrulheiro',
    ALL: 'TODOS',
    'Enter a type or subtype': 'Informe um tipo ou subtipo',
    'Filters reports by type': 'Relatórios com filtro por tipo',
    occurrence: 'Ocorrềncia',
    address: 'Endereço',
    created: 'Criado',
    dispatched: 'Despachado',
    devices: 'Dispositivos',
    communicated: 'Comunicado',
    received: 'Recebido',
    in_route: 'Em rota',
    arrival_on_site: 'Chegada no local',
    in_transport: 'Em transporte',
    arrival_in_transport: 'Chegada em transporte',
    closed: 'Fechada',
    device: 'Dispositivo',
    imei: 'Imei',
    model: 'Modelo',
    brand: 'Marca',
    tracking_id: 'Rastreamento',
    vehicle_type: 'Tipo Veículo',
    plate: 'Placa',
    status: 'Status',
    agency: 'Agência',
    'List of Devices': 'Lista de dispositivos',
    'Report by filters': 'Relatórios por filtros',
    'Filter by type': 'Filtro por tipo',
    'Dispatch group and agency': 'Grupo de despacho e Agência',
    'Connections Logs': 'Registros de conexões',
    'Connection Logs by Dispatch Group': 'Registros de conexões',
    task: 'Atuação',
    date_connected: 'Data de Conexão',
    date_disconnected: 'Data de Desconexão',
    duration: 'Duração',
    km_initial: 'Km Inicial',
    'No filters selected': 'Sem filtros selecionados',
    'You have not added a comment to this entry':
      'Você não adicionou comentário nesse atendimento',
    'Is consummated?': 'Foi consumado?',
    Resume: 'Resumo',
    'No information': 'Sem informações',
    'Current shift': 'Turno Atual',
    'The device is already on a scheduled transfer':
      'O dispositivo já está em uma transferência agendada',
    'Dispatch Close': 'Fechar Despacho',
    'Device x Status': 'Dispositivo x Status',
    'Enter start and end dates': 'Informe as datas inicial e final',
    'Cancel Edit': 'Cancelar edição',
    'Notification central': 'Central de notificações',
    'List of notifications empty': 'Lista de notificações vazia',
    'Clear all notifications': 'Limpar todas as notificações',
    Notifications: 'Notificações',
    'License plate': 'Placa',
    'All groups will be selected': 'Todos os grupos serão selecionados',
    'Link by Agencies': 'Vincular por agências',
    'Reopening dispatch': 'Reabrir despacho',
    'Do you wish to reopen this dispatch?': 'Deseja reabrir esse despacho?',
    'Transfer Dispatch Group': 'Transferir Grupo de Despacho',
    Unify: 'Unificar',
    'Date of unification': 'Data de unificação',
    Disunify: 'Desunificar',
    'Save draft': 'Salvar rascunho',
    'Draft saved successfully': 'Rascunho salvo com sucesso',
    'Need to inform a vehicle data to proceed':
      'É necessário informar os dados do veículo para prosseguir',
    'Occurrence by chronology': 'Cronologia de ocorrência',
    'Occurrence Timeline': 'Cronologia de Ocorrência',
    date_time: 'Data início',
    operator: 'Operador',
    origin: 'Origem',
    event_type: 'Tipo de evento',
    action: 'Ação',
    'Occurrences by Type': 'Ocorrências por tipo',
    'Dispatch code cannot be empty':
      'Campo Código de Despacho não pode estár vazio',
    'Device Type': 'Tipo de Dispositivo',
    'Add Device Type': 'Adicionar Tipo de Dispositivo',
    'Edit Device Type': 'Editar Tipo de Dispositivo',
    'Device Type successfully created!':
      'Tipo de Dispositivo Criado com Sucesso!',
    'Occurrences by dispatch group and type':
      'Ocorrências por grupo de despacho e tipo',
    code: 'Código',
    location: 'Localização',
    district: 'Bairro',
    city: 'Cidade',
    'Dispatchs Groups': 'Grupos de despacho',
    latitude: 'Latitude',
    longitude: 'Longitude',
    attempted: 'Tentado',
    consummated: 'Consumado',
    'Latest open or ongoing dispatches from the same Dispatch Group':
      'Últimos despachos em aberto ou em andamento do mesmo Grupo de Despacho',
    Car: 'Carro',
    Motorcycle: 'Motocicleta',
    Helicopter: 'Helicóptero',
    Bike: 'Bicicleta',
    Boat: 'Embarcação',
    Agent: 'Agente',
    Crew: 'Patrulha',
    Horse: 'Cavalo',
    Scooter: 'Patinete',
    Drone: 'Drone',
    'Heat Map': 'Mapa de Calor',
    synthetic: 'Sintético',
    complete: 'Completo',
    'By vehicle': 'Por veiculos',
    'By people': 'Por envolvidos',
    'By drugs': 'Por drogas',
    'By weapon': 'Por armas',
    'By object': 'Por objetos',
    'By organization': 'Por organização',
    Qualifiers: 'Qualificadores',
    Export: 'Exportar',
    'Looking for': 'Buscando',
    Exporting: 'Exportando',
    'Dispatch is not closed': 'O despacho não está fechada',
    'Print dispatch': 'Imprimir despacho',
    'Subtype does not belong to this type': 'Subtipo não pertence a este tipo',
    'Dispatch Groups visibles': 'Grupos de despacho visíveis',
    'Change View Dispatch Group': 'Modificar visualização do Grupo de Despacho',
    'No Dispatch Group visible': 'Nenhum Grupo de Despacho visível',
    'Check all': 'Marcar todos',
    'Uncheck all': 'Desmarcar todos',
    'Need to inform a vehicle data to proceed!':
      'É necessário informar os dados do veículo para prosseguir!',
    'Dispatchs group selected of': 'Grupos de Despacho selecionados de',
    'Total Dispatchs Groups': 'Total de Grupos Despachos:',
    'Total Dispatchs Group selected':
      'Total de Grupos de Despacho selecionados:',
    'Nº Occurrence': 'Nº Ocorrência',
    'Dispatch code': 'Código Despacho',
    'Locate on the map': 'Localizar no mapa',
    'Current coordination shift': 'Turno de coordenação atual',
    'Occurrences by device': 'Ocorrências por dispositivo',
    subtype: 'Subtipo',
    'View Record': 'Exibir registro',
    'Owner Phone': 'Telefone do proprietário',
    type: 'Tipo',
    created_at: 'Criado em',
    dispatch_closed_at: 'Despacho fechado em',
    removed_at: 'Removido em',
    closed_status: 'Status de fechamento',
    'System Settings': 'Configurações do sistema',
    Views: 'Visualizações',
    'Last occurrences hours': 'Ocorrências das últimas {{hours}} horas',
    'Copy dispatch': 'Copiar Despacho',
    'Priority Dispatch Groups': 'Grupos de despacho prioritários',
    Others: 'Outros',
    'Empty report': 'Relatório vazio',
    'Simplified occurrences by dispatch group and type':
      'Ocorrências por grupo de despacho e tipo simplificada',
    closed_at: 'Data término',
    Report: 'Relatório',
    'Missing device filter': 'Filtro de dispositivo vazio',
    'Missing date filter': 'Filtro de data vazio',
    'Show Camera Video': 'Exibir Video da Camera',
    Camera: 'Câmera',
    'Occurrence Consultation': 'Consulta de ocorrência',
    dispatched_at: 'Data despacho',
    notes: 'Observações',
    'Occurrences Handled by Dispatch Group':
      'Totalização de ocorrência por grupo de despacho',
    type_subtype: 'Tipo e Subtipo',
    total: 'Total',
    'List of devices by status on a date': 'Status histórico de dispositivo',
    commander: 'Comandante',
    'Out Of Service Date': 'Tempo Fora de Serviço',
    'Dispatch already copied to the dispatch group':
      'Despacho já copiado para o grupo de despacho',
    'Unable to dispatch to the same dispatch group':
      'Não é possível despachar para o mesmo grupo de despacho',
    'Moment of date': 'Data do momento',
    'Device Services': 'Atendimentos das viaturas',
    'Total Visible Dispatch Group': 'Total Grupo de despacho visíveis',
    'Selected dispatch group': 'Grupo de despacho selecionados',
    'Real-time tracking': 'Acompanhamento em tempo real',
    'Enter a file of the following types:': 'Informe um arquivo dos tipos:',
    'Recall successfully created!': 'Rechamada criada com sucesso',
    'Connection Date': 'Tempo Conectada',
    'Disconnection Date': 'Tempo Desconectado',
    'Never connected': 'Nunca Conectada',
    'This vehicle has never been connected': 'Essa viatura nunca foi conectada',
    Retain: 'Reter',
    'Retain dispatch': 'Reter despacho',
    'Latest status update': 'Última atualização',
    'This crew has been connected to the device':
      'Esta guarnição foi conectada ao dispositivo',
    'on station': 'na estação',
    Videomonitoring: 'Videomonitoramento',
    'Videomonitoring narrations': 'Relatos do Videomonitoramento',
    'Report date': 'Data de relato',
    Reported: 'Relatado',
    'Reported by': 'Relatado por',
    Associate: 'Associar',
    'Report associated': 'Ocorrência associada',
    'Occurrence associated with success': 'Ocorrência associada com sucesso',
    Tracking_id: 'Rastreamento',
    'by device': 'pelo dispositivo',
    'Dispatches status by date': 'Ocorrências por data específica',
    'File is larger than': 'Arquivo maior que',
    'Group Dispatch Name': 'Grupo de Despacho',
    'Max Dispatches Per Device': 'Máximo de despachos por dispositivos',
    'Emergency Type Id': 'Id do Tipo de Emergência',
    'Remove Device Closed Status Id':
      'Id para remover status de dispositivo fechado',
    'Allowed Comment On Closed Occurrence':
      'Permitir comentário para ocorrência fechada',
    'Display All Comments In Print': 'Exbir todos os comentários na impressão',
    'Display Requester Data In Print':
      'Exibir dados do solicitante na impressão',
    'Required Km': 'Km obrigatório',
    'Required Phone': 'Celular obrigatório',
    'Map Latitude Default': 'Padrão de latitude',
    'Map Longitude Default': 'Padrão de longitude',
    'Map Zoom Default': 'Padrão de zoom',
    'Bound Bottom Left Lat': 'Latitude do limite inferior a esquerda',
    'Bound Bottom Left Lng': 'Longitude do limite inferior a esquerda',
    'Bound Top Right Lat': 'Latitude do limite superior a direita',
    'Bound Top Right Lng': 'Longitude do limite superior a direita',
    'Emergency Button Type': 'Tipo de botão de emergência',
    'Emergency Button User': 'Usuário do botão de emergênicia',
    'Emergency Button Device': 'Dispositivo do botão de emergência',
    'Allowed Edit Entry Comment':
      'Permitir edição de comentário no atendimento',
    'Allowed Delete Entry Comment':
      'Permitir deletar comentários no atendimento',
    'Occurrence Search Period In Hours': 'Buscar ocorrências no período (h)',
    'Allowed Edit Occurrence Comment':
      'Permitir edição de comentário na ocorrência',
    'Allowed Delete Occurrence Comment':
      'Permitir deletar comentários na ocorrência',
    'Camera Url Integration': 'URL para câmeras',
    With: 'com',
    'Must Inform Consummate': 'Informar Tentado/Consumado',
    'Add Dispatch Code': 'Adicionar Código de Despacho',
    'Dispatch Code successfully created!':
      'Código de Despacho criado com sucesso',
    'Dispatch Code successfully updated!':
      'Código de Despacho atualizado com sucesso',
    'Edit Dispatch Code': 'Editar Código de Despacho',
    'Dispatch Code': 'Código de Despacho',
    'Dispatch Codes': 'Código de Despachos',
    dispatch: 'Despacho',
    'Video Surveillance Narration': 'Relato de Videomonitoramento',
    'No Reports': 'Sem relatos',
    'Associate selected reports with this dispatch':
      'Associar relatos selecionados a este despacho',
    'Reports associated with success': 'Relatos associados com sucesso',
    'Search multiple coments': 'Pesquisa por múltiplos comentários',
    'Contain all keywords': 'Conter todas as palavras chave',
    Keywords: 'Palavras chave',
    'Group by city': 'Agrupamento por Cidade',
    'Simple report': 'Relatório simples',
    'Use ; to separate the keywords':
      'Use ; (ponto e vírgula) para separar as palavras chave',
    'Keyword is mandatory': 'Palavra chave é obrigatória',
    'Branch Line': 'Nº do Ramal',
    'MAC Address': 'Endereço MAC',
    'Total occurrences by closing status':
      'Total de ocorrências por fechamento',
    'Closed status class': 'Classe de status de fechamento',
    'Add Closed status class': 'Adicionar nova Classe de status de fechamento',
    'Closed status class successfully updated!':
      'Classe status de fechamento atualizada com sucesso!',
    'Closed status class successfully created!':
      'Classe status de fechamento criada com sucesso!',
    'Add Dispatch closed status': 'Adicionar status de fechamento do Despacho',
    'Associate to Occurrence': 'Associar a Ocorrência',
    'Associate to Report': 'Associar a um relatório',
    'Confirm disconnect?': 'Confirma a desconexão do usuário {{userName}}?',
    Sugestion: 'Sugestão',
    'Others coordinators': 'Outros coordenadores',
    'Sort name': '{{code}}',
    Growing: 'Crescente',
    Decreasing: 'Decrescente',
    'Not defined': 'Não definida',
    'Device status x Pending occurrences':
      'Status das viaturas x Ocorrências pendentes',
    'User logged out successfully': 'Usuário desconectado com sucesso!',
    'Wishing to continue to occurrence?':
      'Deseja continuar na ocorrência OC:{{code}} despachada ?',
    'Want to open the occurrence window?': 'Deseja abrir janela de ocorrência?',
    'Search multiple comments': 'Pesquisa por múltiplos comentários',
    'The device must be disconnected': 'O dispositivo deve ser desconectado',
    'Delete Record': 'Deletar Registro',
    'Edit Record': 'Editar Registro',
    'Show deleted comment': 'Mostrar comentário excluído',
    'Hide deleted comment': 'Ocultar comentário deletado',
    'Enter Withtout Station': 'Entrar sem Estação',
    'Activate Station': 'Ativar Estação',
    'Show historic': 'Mostrar histórico',
    'Hide historic': 'Ocultar histórico',
    'This occurrence had edit and delete comments':
      'Está ocorrência teve comentário(s) editado(s) e/ou excluído(s)',
    'Total connected devices': 'Total de dispositivos conectados',
    device_type: 'Tipo de dispositivo',
    'Close service of the vehicle': 'Fechar atendimento do veículo',
    'Select me': 'Me definir como Coordenador',
    Duration: 'Duração: {{time}}',
    'Add detail in recall': 'Adicionar detalhes a rechamada',
    'Does not apply': 'Não se aplica',
    'Attempted/Consummated': 'Tentado/Consumado',
    'Send to tracking': 'Enviar para rastreamento',
    'Cancel trackking': 'Cancelar reastreamento',
    Link: 'Vincular',
    'Add Report': 'Adcionar Relatório',
    'only my narrations': 'Apenas meus relatos',
    'Report data': 'Dados do relato',
    'View the filters': 'Exibir os filtros',
    'Filter by': 'Filtrar por',
    Use: 'Usar',
    'Plate sent successfully': 'Placa envada com sucesso!',
    'Tracking canceled successfully': 'Rastreamento cancelado com sucesso',
    'Group by': 'Agrupamento por',
    station: 'Estação',
    detail: 'Detalhe',
    'Entry log by status and user': 'Teleatendimentos por status e usuário',
    'Users group': 'Grupo de usuários',
    'Add Users group': 'Adicionar grupo de usuários',
    'Users group successfully created!':
      'Grupo de usuários criado com sucesso!',
    'Add new Users group': 'Adicionar novo grupo de usuários',
    'Users group successfully updated!':
      'Grupo de usuários atualizado com sucesso!',
    'Edit Users group': 'Editar grupo de usuários',
    'Missing group name': 'Campo de nome do grupo está vázio',
    'Original type/subtype': 'Tipo e Subtipo de origem',
    Order: 'Ordenação',
    "Don't show again": 'Não Mostrar novamente',
    Announcement: 'Anúncio',
    'Add announcement': 'Adicionar Anúncio',
    'Is Change Version?': 'Mudança de Versão?',
    'Add category': 'Adicionar categoria',
    'Edit Category': 'Editar categoria',
    'Category successfully updated!': 'Categoria atualizada com sucesso!',
    'Category successfully created!': 'Categoria criada com sucesso!',
    'Add a new category': 'Adicionar uma nova categoria',
    'Announcement Date': 'Data do anúncio',
    'Announcement End Date': 'Data de término do anúncio',
    'Announcement successfully created!': 'Anúncio criado com sucesso!',
    'Add a new announcement': 'Adicionar um novo anúncio',
    'Edit announcement': 'Editar Anúncio',
    'Missing category name': 'Campo nome da categoria vázio',
    'A new comment was added!': 'Um novo comentário foi adicionado!',
    'Management Map': 'Mapa de Gestão',
    'Approaches by user': 'Abordagens por usuário',
    approach_type: 'Tipo de abordagem',
    number_people_approached: 'Número de pessoas abordadas',
    description: 'Descrição',
    'Only dispatch groups assigned to the station':
      'Somente grupos de despacho atribuidos à estação',
    Approach: 'Abordagem',
    'Summary of the selected dispatches': 'Resumo dos despachos selecionados',
    Error: 'Erro',
    'Need to inform if it was consummated':
      'É necessário informar se foi consumado',
    'Dispachs that have not been closed':
      'Despachos que não puderam ser fechados',
    'Order by date latest': 'Ordenar pela data mais recente',
    'Apply Filters': 'Aplicar filtros',
    'Camera not found': 'Câmera não encontrada',
    'Notes is required': 'Relato é obrigatório',
    'There are no reports': 'Não há relatos',
    'No associated occurrences': 'Não associada',
    'Order by the oldest date': 'Ordenar pela data mais antiga',
    'Show Resume close Multiple Dispatch':
      'Visualizar o último fechamento de despachos em lote',
    'Summary of the latest batch dispatch date':
      'Resumo do último fechamento de despachos em lote',
    'Dispatches closed with success': 'Despachos fechados com sucesso',
    'No Dispatch has been closed': 'Nenhum Despacho foi fechado',
    'Despachos that could not be closed':
      'Despachos que não puderam ser fechados',
    'There were no closing errors': 'Nao houveram erros de fechamento',
    'Need to inform the closing comment':
      'Necessário informar comentário no fechamento',
    'Need to inform the closed status':
      'Necessário informar o status de fechamento',
    'Station dont have access to this dispatch':
      'Estação não tem acesso a esse despacho',
    'Detailed report': 'Relatório detalhado',
    position: 'Posição',
    user: 'Usuário',
    'Deactivate user': 'Desativar usuário',
    'Active user': 'Ativar usuário',
    'User deactivated with success!': 'Usuário desativado com sucesso!',
    'User activated with success!': 'Usuário ativado com sucesso!',
    'Confirm the deactivation of the user?':
      'Confirma a desativação do usuário {{userName}}?',
    'Confirm the user activation?':
      'Confirma a ativação do usuário {{userName}}?',
    'Add Operation unit': 'Adicionar Unidade Operacional',
    'Add a new operation unit': 'Adicionar uma nova Unidade Operacional',
    'Operation unit successfully created!':
      'Unidade Operacional criada com sucesso!',
    'Connected Devices': 'Dispositivos Conectados',
    'Disconnected Devices': 'Dispositivos Desconectados',
    'View Areas': 'Visualizar Áreas',
    'Search staffs': 'Pesquisar Efetivos',
    'There are no more devices': 'Não há mais dispositivos',
    'Empty list': 'Lista vazia',
    'Operation unit successfully edited!':
      'Unidade Operacional editada com sucesso!',
    'Edit Operation unit': 'Editar Unidade Operacional',
    'Operation unit': 'Unidade operacional',
    number_of_components: 'Nº de componentes',
    'The operating unit must be from the same agency as the device':
      'A unidade de operação precisa ser da mesma agência que o dispositivo',
    connection_origin: 'Origem da conexão',
    'An error occurred while trying to generate the printout':
      'Ocorreu um erro ao tentar gerar a impreesão.',
    'Reload page': 'Recarregar página',
    'Comment edited on occurrence': 'Comentário editado na ocorrência:',
    'Comment deleted on occurrence': 'Comentário excluído na ocorrência:',
    'Address changed in the occurrence': 'Endereço alterado na ocorrencia:',
    'Type and/or Subtype changed in the occurrence':
      'Tipo e/ou Subtipo alterado na ocorrência:',
    'Closed batch dispatch': 'Despacho fechado em lote:',
    'A new comment has been added to the incident':
      'Um novo comentário foi adicionado a ocorrência:',
    'Interest point type': 'Tipo de Ponto de Interesse',
    'Interest point type successfully created!':
      'Tipo de Ponto de Interesse criado com sucesso!',
    'Add Interest point type': 'Adicionar Tipo de Ponto de Interesse',
    'Add a new interest point type':
      'Adicionar um novo Tipo de Ponto de Interesse',
    'Is visible to mobile': 'Visível para dispositivo móvel',
    'Show only occurrences marked for clerk': 'Mostrar apenas ocorrências marcadas para escrevente',
    'Mark for clerk': 'Marcar para Escrevente',
    'Confirm marking of the occurrence for the writer?': 'Confirmar marcação da ocorrência para o escrevente?',
    'Marked successfully': 'Marcado com sucesso!',
    'Disabled to clerk': 'Desabilitar para escrevente',
    'MarkOff successfully!': 'Desmarcado com sucesso',
    'MarkOff': 'Desmarcar',
    'Write occurrence': 'Redigir ocorrência',
    'Is visible to dispatch': 'Visível para despacho',
    'Is criminal': 'É criminal',
    'Review time in days': 'Tempo de revisão em dias',
    'Expiration time in days': 'Prazo de validade em dias',
    'Interest point type successfully edited!':
      'Tipo de Ponto de Interesse editado com sucesso',
    'Edit Interest point type': 'Editar Tipo de Ponto de Interesse ',
    'Interest point subtype successfully edited!':
      'Subtipo de Ponto de Interesse editado com sucesso',
    'Edit Interest point subtype': 'Editar Subipo de Ponto de Interesse ',
    'Add a new interest point subtype':
      'Adicionar um novo Subtipo de Ponto de Interesse',
    'Add Interest point subtype': 'Adicionar Subtipo de Ponto de Interesse',
    'Interest point subtype successfully created!':
      'Subtipo de Ponto de Interesse criado com sucesso!',
    'Interest point subtype': 'Subtipo de Ponto de Interesse',
    'User has service(s) open. Unable to log out of the system':
      'Usuário possui {{number}} atendimento(s) em aberto. Não é possível deslogar do sistema',
    'opened in': 'Aberto em',
    'Copy dispatch of occurrence': 'Copiar despacho da ocorrência',
    'Dispatch of occurrence copyed successfully!':
      'Despacho de ocorrência copiado com sucesso: {{code}}',
    'Enter a type': 'Informe um tipo',
    'Simple filter': 'Filtro simples',
    'Detailed filter': 'Filtro detalhado',
    'Points of interest': 'Pontos de Interesse',
    'Validated in': 'Validado em:',
    'Expired in': 'Expira em:',
    'Not validated': 'Não validado',
    'Name or observations': 'Nome ou observações',
    'Insert date': 'Insira uma data',
    Deactive: 'Desativar',
    Validated: 'Validado',
    'Expiration date': 'Data de expiração',
    'Revision date': 'Data de revisão',
    expired: 'Expirado',
    'requires review': 'Requer revisão',
    'Validated date': 'Data de validação',
    'Point of Interest successfully validated!':
      'Ponto de Interesse validado com sucesso!',
    'Point of Interest successfully deactivated!':
      'Ponto de Interesse desativado com sucesso!',
    'Interest point': 'Ponto de Interesse',
    'Created date': 'Data de criação',
    'Force Expire': 'Forçar expiração',
    'Reviewed in': 'Revisar em',
    View: 'Visualizar',
    'Confirm validation': 'Confirma validação?',
    'Confirm force expiration': 'Confirma a expiração forçada?',
    'Information by': 'Informado por',
    'Images not registered': 'Imagens não cadastradas',
    'Interest point was not validated yet':
      'O ponto de interesse ainda não foi validado',
    'Include operating unit information':
      'Incluir informação unidade operacional',
    'Recall created!': 'Rechamada criada!',
    operation_unit: 'Unidade Operacional',
    Create: 'Criar',
    'Work stations': 'Estações de Trabalho',
    'Chronology device': 'Cronologia Dispositivo',
    'Number of recalls': 'Número de rechamadas',
    'No recalls': 'Não há rechamadas',
    'Dispatch origin': 'Despacho de origem',
    'Recalls': 'Rechamadas',
    'Entry - Dispatch System': 'Atendimento - Sistema de Despacho',
    'My Entries - Dispatch System': 'Meus Atendimentos - Sistema de Despacho',
    'Entry Dashboard - Dispatch System': 'Painel de Gestão Atendimento - Sistema de Despacho',
    'Dispatch System': 'Sistema de Despacho',
    'Dispatch - Dispatch System': 'Despacho - Sistema de Despacho',
    'Dispatch List - Dispatch System': 'Lista de Despacho - Sistema de Despacho',
    'Dispatch Dashboard - Dispatch System': 'Painel de Gestão Despacho - Sistema de Despacho',
    'Dashboard Map - Dispatch System': 'Mapa de Gestão - Sistema de Despacho',
    'Coordination - Dispatch System': 'Coordenação - Sistema de Despacho',
    'Vehicles List - Dispatch System': 'Lista de Veículos - Sistema de Despacho',
    'Administration - Dispatch System': 'Administração - Sistema de Despacho',
    'Interest Point - Dispatch System': 'Ponto de Interesse - Sistema de Despacho',
    'Window Support - Dispatch System': 'Tela de Suporte - Sistema de Despacho',
    'System Settings - Dispatch System': 'Configurações do Sistema - Sistema de Despacho',
    'Operations - Dispatch System': 'Operações - Sistema de Despacho',
    'View Operations - Dispatch System': 'Visualizar Operações - Sistema de Despacho',
    'Tactical - Dispatch System': 'Tático - Sistema de Despacho',
    'Dispatch Statistics - Dispatch System': 'Estatísticas de Despacho - Sistema de Despacho',
    'Devices Connections - Dispatch System': 'Conexões de Dispositivos - Sistema de Despacho',
    'Reports - Dispatch System': 'Relatórios - Sistema de Despacho',
    'Videomonitoring - Dispatch System': 'Videomonitoramento - Sistema de Despacho',
    'Narrations': 'Relatos',
    'Total calls': 'Total de chamadas',
    'Occurrences created by me': 'Ocorrências criadas por mim',
    'Latest occurrences within a radius of 500m and hours': 'Últimas ocorrências em um raio de 500m e {{hours}} horas',
    'Permission Groups': 'Grupos de Permissão',
    'Latest device dispatches': 'Últimos despachos do dispositivo',
    'Clerk': 'Escrevente',
    'Confirm markoff remove of clerk?': 'Confirma a remoção da marcação do escrevente?',
    'Entry date': 'Data Atendimento:',
    'Created date Occurrence': 'Data da Criação da Ocorrência:',
    'Clerk mode': 'Modo Escrevente',
    'Vehicle Removed': 'Viatura Retirada',
    'Clerk - Dispatch System': 'Escrevente - Sistema de Despacho',
    'Code of dispatch': 'Código do despacho',
    'Time elepsed': 'Tempo Transcorrido',
    'Inform a comment': 'Informe um comentário',
    'Enter a comment with a minimum of 5 characters': 'Insira um comentário com no mínimo 5 caracteres',
    'Narration saved successfully': 'Relato salvo com sucesso',
    'New Dispatch': 'Novo Despacho',
    'Work station': 'Estação de Trabalho',
    'Keywords in observation, acting or tracking': 'Palavras-chave em observação, atuação ou rastreamento',
    'Total entries by user': 'Total de atendimentos por usuário',
    'Label_commander': 'Label para comandante',
    'Multiple closing executed successfully': 'Fechamento em lote executado com sucesso!',
    'Label_driver': 'Label para motorista',
    'Label_patrolmans': 'Label para patrulheiros',
    'Label_km_initial': 'Label para quilometragem',
    'Device Type successfully updated!': 'Tipo de Dispositivo atualizado com sucesso!',
    'Device successfully updated!': 'Dispositivo atualizado com sucesso!',
    'Device successfully created!': 'Dispositivo criado com sucesso!',
    'Notification sent successfully!': 'Notificação enviada com sucesso!',
    'Request closure': 'Solicitar Fechamento',
    'Do you want to inform that the incident can be closed? If yes, write a comment next to your request': 'Deseja informar que a ocorrência pode ser fechada? Se sim, escreva um comentário junto à sua solicitação.',
    'Generate closed occurrence': 'Gerar Ocorrência Fechada',
    'Occurrence created and closed with success': 'Ocorrência criada e fechada com sucesso!',
    'Generate occurrence closed': 'Gerar ocorrência fechada',
    'Type and/or Subtype changed in the dispatch': 'Tipo e/ou Subtipo alterado no dispacho:',
    'New comment created in dispatch:': 'Novo comentário criado no despacho:',
    'New comment created in occurrence:': 'Novo comentário criado na ocorrência:',
    'Send to dispatch': 'Enviar para Despacho',
    'Closed comment': 'Comentário de fechamento',
    'Summary of the selected devices': 'Resumo dos dispositivos selecionados',
    'Type not found': 'Tipo não encontrado',
    'Show resume disconnect multiple devices': 'Visualizar a última desconexão de dispositivos em lote',
    'The vehicle was deconnected in batch': 'O veículo {{vehicle}} foi desconectado em lote',
    'Multiple disconnection executed successfully!': 'Desconexão multipla executada com sucesso',
    'Summary of last batch devices disconnection': 'Resumo da última desconexão de dispositivos em lote',
    'The batch disconnection will be processed and you will receive a notice when the process is complete': 'A desconexão em lote será processada e você receberá um aviso quando o processo for concluído.',
    'Device not found': 'Dispositivo não encontrado',
    'Device is not active': 'Dispositivo não está ativo',
    'Failed to disconnect, try to set this device to available first': 'Falha ao desconectar, tente setar dispositivo como disponivel primeiro',
    'Device has a current dispatch': 'Dispositivo possui um despacho ativo',
    'NO TEMPORARY TRACKING': 'Sem rastreamento temporário',
    'Devices disconnected with success': 'Dispositivos desconectados com sucesso',
    'No devices has been disconnected': 'Nenhum veículo foi desconectado',
    'Create Occurrence': 'Criar Ocorrência',
    'Station Name': 'Nome da Estação',
    'Authenticated': 'Autenticada',
    'View Station': 'Visualizar Estação',
    'Serial HD': 'Número Serial',
    'Authenticated Stations': 'Estações Autenticadas',
    'Associated Dispatch Group': 'Grupos de Despacho Associados',
    'Codes generated': 'Códigos Gerados',
    'Copy code': 'Copiar código',
    'Must be 8 or more characters long': 'Deve ter 8 ou mais caracteres',
    'Must be 8 or more characters': 'Deve ter 8 ou mais caracteres',
    'Must have 1 capital letter': 'Deve ter 1 letra maiúscula',
    'Must have 1 lowercase letter': 'Deve ter 1 letra minúscula',
    'Must have 1 number': 'Deve ter 1 número',
    'Must have 1 special character': 'Deve ter 1 caractere especial (@$!%*?&)',
    'Passwords do not match': 'As senhas não coincidem',
    'Total prank calls registered': 'Total de trotes registrados',
    'Only entry': 'Somente atendimentos',
    'Only occurrence': 'Somente ocorrência',
    'phone': 'Telefone',
    'contact_center': 'Central de contato',
    'total_entries': 'Nº Atendimentos',
    'total_occurrences': 'Nº Ocorrências',
    'Search mode': 'Modo de consulta',
    'Synthetic report': 'Relatório sintético',
    'Both': 'Ambos',
    'View Interest Point': 'Visualizar Pontos de Interesse',
    'Expiration in': 'Expira em',
    'Procedure': 'Procedimento',
    'Procedures': 'Procedimentos',
    'Create New Procedure': 'Criar Novo Procedimento',
    'Procedure successfully created!': 'Procedimento criado com sucesso!',
    'Fill the description field.': 'Preencha o campo de descrição.',
    'Procedure successfully updated!': 'Procedimento atualizado com sucesso',
    'Edit Procedure': 'Editar Procedimento',
    'View Procedure': 'Visualizar Procedimento',
    'Procedure Configuration': 'Configuração de Procedimento',
    'Procedures Configuration': 'Configuração de Procedimentos',
    'Create New Procedure Configuration': 'Criar Nova Configuração de Procedimento',
    'Procedure Configuration successfully created!': 'Configuração de Procedimento criado com sucesso!',
    'Procedure Configuration successfully updated!': 'Configuração de Procedimento atualizado com sucesso',
    'Edit Procedure Configuration': 'Editar Configuração de Procedimento',
    'View Procedure Configuration': 'Visualizar Configuração de Procedimento',
    'Fill the configs field.': 'Preencha o campo de configuração.',
    'configs': 'Configuração',
    'You must select at least one visible group for each procedure.': 'É preciso selecionar ao menos um grupo visível para cada procedimento.',
    'Visible To': 'Visível para',
    'Procedure Code': 'Código de Procedimento',
    'Procedure Description': 'Descrição do Procedimento',
    'Total procedures': 'Total de procedimentos',
    'No Procedure': 'Sem Procedimento',
    'See Procedures': 'Ver Procedimentos',
    'Configure Procedure': 'Configurar Procedimento',
    'Edit Operation': 'Editar Operação',
    'Operation successfully updated!': 'Operação atualizada com sucesso!',
    'there is': 'há',
    'Requester Phone': 'Telefone do Solicitante',
    'Type, at least, three names.': 'Digite, ao menos, três nomes.',
    'Type the document number.': 'Digite o número do documento.',
    'Reported at': 'Reportado às',
    'Enable Select': 'Habilitar Seleção',
    'Disable Select': 'Desabilitar Seleção',
    'Last 6 hours': 'Últimas 6 horas',
    'Last 48 hours': 'Últimas 48 horas',
    'Clear filters': 'Limpar filtros',
    'Closed class': 'Classe de fechamento',
    'Search Weapons': 'Pesquisar Armamentos',
    'No Weapons': 'Sem Armamentos',
    'Please check the form information and try again!': 'Verifique as informações do formulário e tente novamente!',
    'Connection failed! Please try again': 'Falha na conexão! Tente novamente',
    'Animals': 'Animais',
    'Specie': 'Espécie',
    'Species': 'Espécies',
    'Breed': 'Raça',
    'Agency Acronym': 'Sigla de Agência',
    'Area': 'Área',
    'Areas': 'Áreas',
    'Area and agency does not belong to the same area plan': 'Área e agência não pertencem ao mesmo mapa',
    'Interest points': 'Pontos de Interesse',
    'name': 'Nome',
    'Interest Point Type': 'Tipo de Ponto de Interesse',
    'Interest points registered': 'Pontos de interesse registrados',
    'Interest Point Subtype': 'Subtipo de Ponto de Interesse',
    'Disconnect devices': 'Desconectar Dispositivos',
    'Deactivate device': 'Desativar dispositivo',
    "Size": "Tamanho",
    'Active device': 'Ativar dispositivo',
    'Confirm the deactivation of the?': 'Confirma a desativação do {{crudName}} {{name}}?',
    'Confirm the activation?': 'Confirma a ativação do {{crudName}} {{name}}?',
    'deactivated with success!': '{{crudName}} desativado com sucesso!',
    'activated with success!': '{{crudName}} ativado com sucesso!',
    Device_type: {
      name: 'Tipo de dispositivo'
    },
    Dispatch_group: {
      name: 'Grupo de Despacho'
    },
    'Actived Device': 'Dispositivo Ativo',
    'Associated Areas': 'Áreas Associadas',
    'Only read mode': 'Modo visualização',
    'Prediction Map': 'Mapa de Predição',
    'Prediction Map - Dispatch System': 'Mapa de Predição - Sistema de Despacho',
    'Dispatch Prediction Map': 'Mapa de Predição de Despachos',
    'It is necessary to inform the period and at least one city.': 'É necessário informar o período e, pelo menos, uma cidade.',
    'Download file': 'Baixar arquivo',
    'city_district': 'Cidade - Bairro',
    'An image has been attached.': 'Uma imagem foi anexada.',
    'A document has been attached.': 'Um documento foi anexado.',
    'Vehicle never engaged': 'Veículo nunca empenhado',
    'Dispatch Group Set': 'Agrupamento de Grupo de Despacho',
    'Destination dispatch group is the same as origin': 'O grupo de despacho de destino é igual ao de origem. Por favor verifique e tente novamente',
    'Dispatch Group Set successfully created!': 'Agrupamento de Grupo de Despacho criado com sucesso!',
    'Add Dispatch Group Set': 'Adicionar Agrupamento de Grupo de Despacho',
    'Add a new Dispatch Group Set': 'Adicionar novo Agrupamento de Grupo de Despacho',
    'Dispatch Group Set successfully updated!': 'Agrupamento de Grupo de Despacho atualizado com sucesso!',
    'Edit Dispatch Group Set': 'Editar Agrupamento de Grupo de Despacho',
    'Categories': 'Categorias',
    'Indicators by Status': 'Indicadores por Status',
    'Dispatch by Type and Subtype': 'Despacho por Tipo e Subtipo',
    'Data by Status': 'Dados por Status',
    'Dispatch by Location': 'Despacho por Localização',
    'Dispatch by Device': 'Despacho por Dispositivo',
    'Dispatch Heat Map': 'Mapa de Calor de Despacho',
    'Operation Unit': 'Unidade Operacional',
    'Device timeline': 'Cronologia do Dispositivo',
    'operation': 'Operação',
    'details': 'Detalhes',
    'start_date': 'Data inicial',
    'finish_date': 'Data final',
    'creator': 'Criador',
    'Animals options': 'Opções para Animais',
    'Specie successfully created!': 'Espécie criada com sucesso!',
    'Add specie': 'Adicionar Espécie',
    'Add a new specie': 'Adicionar nova espécie',
    'Specie successfully updated!': 'Espécie atualizada com sucesso!',
    'Edit Specie': 'Editar Espécie',
    'Breeds': 'Raças',
    'Breed successfully created!': 'Raça criada com sucesso!',
    'Add breed': 'Adicionar Raça',
    'Add a new breed': 'Adicionar nova raça',
    'Breed successfully updated!': 'Raça atualizada com sucesso!',
    'Edit Breed': 'Editar Raça',
    'Enter a comment with a minimum of 10 valid characters': 'Insira um comentário com no mínimo 10 caracteres válidos',
    'Replace device': 'Substituir dispositivo',
    'Enter a device': 'Informe um dispositivo',
    'Inform a new device': 'Informe um novo dispositivo',
    'Do you want to disconnect the device': 'Deseja desconectar o dispositivo',
    'Confirm this device to return to service?': 'Confirme a volta ao serviço deste dispositivo?',
    'Group set': 'Agrupamento de Grupo de Despacho',
    'Enter a subtype': 'Informe um subtipo',
    'Dispatch codes generated': 'Códigos de Despachos Gerados',
    'Do you wish to continue to occurrence': 'Deseja continuar na ocorrência',
    'dispatched?': 'despachada?',
    'Requested closure for dispatch:': 'Solicitação de fechamento do despacho',
    'By animal': 'Por animal',
    'Datas by period': 'Dados por periodo',
    'Datas by entry': 'Dados por atendimento',
    'Datas by dispatch': 'Dados por despacho',
    'Dispatch by Dispatch Group': 'Despacho por Grupo de Despacho',
    'average_entry_time': 'Tempo médio de atendimento',
    'Origin type': 'Tipo de Origem',
    'Deactivate': 'Desativar {{crudName}}',
    'To activate': 'Ativar {{crudName}}',
    'See Chronology': 'Ver Cronologia',
    'Transfer by Dispatch Group': 'Transferir de Grupo de Despacho',
    'Unify Dispatch': 'Unificar Despacho',
    'Disunify Dispatch': 'Desunificar Despacho',
    'See Videomonitoring narrations': 'Ver Relatos de Videomonitoramento',
    'See Window Support': 'Ver Tela de Suporte',
    'See List Dispatches': 'Ver Lista de Despachos',
    'See List Devices': 'Ver Lista de Dispositivos',
    'Search Dispatch': 'Buscar Despacho',
    'See Search Advanced': 'Ver Busca Avançada',
    'See Lat/Long': 'Ver Lat/Long',
    'Geographic Coordinates': 'Coordenadas Geográficas',
    'Open in Google Maps': 'Abrir no Google Maps',
    'See Resume': 'Ver Resumo',
    'Historic Map': 'Mapa de Histórico',
    'Historic Map - Dispatch System': 'Mapa de Histórico - Sistema de Despacho',
    'Historic Search': 'Busca Histórica',
    'Historic Date': 'Data Histórica',
    'hour': 'hora',
    'day': 'dia',
    'Add period': 'Adicionar período',
    'Subtract period': 'Diminuir período',
    'On mission reason': 'Motivo em missão',
    'Device on mission reason': 'Motivo de dispositivo em missão',
    'Device on mission reason successfully created': 'Motivo de dispositivo em missão criado com sucesso!',
    'Add Device on mission reason': 'Adicionar motivo de dispositivo em missão',
    'Add new Device on mission reason': 'Adicionar novo motivo de dispositivo em missão',
    'Device on mission reason successfully updated!': 'Motivo de dispositivo em missão atualizado com sucesso!',
    'Edit on mission reason status': 'Editar motivo de dispositivo em missão',
    'Select future period': 'Selecione período futuro',
    'Management Stations': 'Gestão de Estações',
    'Information about stations': 'Informações sobre estações',
    'Add Tag': 'Adicionar Tag',
    'New Tag': 'Nova Tag',
    'Stations position saved successfully!': 'Posicionamento de estações salvo com sucesso!',
    'Tags position saved successfully!': 'Posicionamento de tags salvo com sucesso!',
    'Confirm the delete of the tag': 'Confirma a exclusão da tag',
    'Tag delete successfully!': 'Tag deletada com sucesso!',
    'It is necessary to change the password!': 'Necessária a redefinição de sua senha.',
    'Types and Subtypes Selecteds': 'Tipos e Subtipos Selecionados',
    'When changing your password, you will be redirected to log in again.': 'Ao alterar sua senha, você será redirecionado para fazer login novamente.',
    'Password Changed Successfully': 'Senha alterada com sucesso',
    'Your password expired, it is necessary to create a new one!': 'Sua senha expirou, necessário criar uma nova.',
    'Area Config Delete': 'Excluir Configuração de Área',
    'Summary of the selected config areas': 'Resumo das configurações de área selecionadas',
    'Config Areas deleted sucessfully!': 'Configurações de área deletadas com sucesso!',
    'Create tags to categorize stations according to your work environment': 'Crie tags para categorizar as estações conforme seu ambiente de trabalho',
    'Inform a agency': 'Informe uma agência',
    'Inform a dispatch group': 'Informe um grupo de despacho',
    'Inform a area': 'Informe uma área',
    'Area configured successfully': 'Área configurada com sucesso',
    'Occupation': 'Ocupação',
    'Add occupation': 'Adicionar ocupação',
    'Edit occupation': 'Editar ocupação',
    'Occupation successfully updated!': 'Ocupação editada com sucesso!',
  },
}
