import React from 'react';
import { AppRouteProps } from '~/routes';
import WindowSupport from './pages/windowSupport';
import CreateLogoPage from './pages/CreateLogoPage';
import Home from '~/features/System/pages/Home';
import SystemSettings from './pages/SystemSettings';

export default function SystemRoutes(): readonly AppRouteProps[] {
  const routes: readonly AppRouteProps[] = [
    { path: '/home', component: Home, isProtected: true, permissions: [] },
    { path: '/window-support', component: WindowSupport, isProtected: true, permissions: ['dispatch_index'] },
    { path: '/system-settings', component: SystemSettings, isProtected: true, permissions: ['dispatch_index'] },
    // {path: '/system/logo',component: CreateLogoPage, isProtected: false}
  ];

  return routes;
}
