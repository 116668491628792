import React, { useEffect } from 'react';
import ReactDOM from 'react-dom'
import { InsertDriveFile } from '@mui/icons-material';
import StandardModal from '../../../../components/StandardModal';
import { useLang } from '../../../../hooks/useLang';
import useDispatchState from '../../stores/DispatchState';
import { useHookstate, useState } from '@hookstate/core';
import DispatchDisunification from '../DispatchDisunification';
import { useLocation } from 'react-router';
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable';
import { authFetch } from '~/services/fetch';
import useScreenSettingsState from '~/stores/ScreenSettingsState';
import useSystemState from '~/features/System/stores/SystemState';
import { storeScreenSettings } from '~/features/System/services';

const modal = document.getElementById('div-modal') as HTMLElement;

export default function DispatchDisunificationModalDraggable() {
  const { translate } = useLang()
  const { windowManager, loadingButton } = useSystemState();

  
  const handleStop = (event, dragElement) => {
    event.stopPropagation();

    windowManager()['DispatchDisunification'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y
    })
  };

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'DispatchDisunificationModalDraggable',
        isOpen: windowManager()['DispatchDisunification'].open.get(),
        isMinimized: windowManager()['DispatchDisunification'].minimized.get(),
        positionX: windowManager()['DispatchDisunification'].positionX.get(),
        positionY: windowManager()['DispatchDisunification'].positionY.get()
      })
    }
  }, [
    windowManager()['DispatchDisunification'].open.get(),
    windowManager()['DispatchDisunification'].minimized.get(),
    windowManager()['DispatchDisunification'].positionY.get(),
    windowManager()['DispatchDisunification'].positionX.get()
  ])

  if (windowManager()['DispatchDisunification'].open.get()) {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() &&
          <StandardModal
            title={`${translate('Unified Dispatches')}`}
            dimensions={{
              width: 900
            }}
            handleStop={handleStop}
            position={{
              x: windowManager()['DispatchDisunification'].positionX.get() ? Number(windowManager()['DispatchDisunification'].positionX.get()) : 250,
              y: windowManager()['DispatchDisunification'].positionY.get() ? Number(windowManager()['DispatchDisunification'].positionY.get()) : 100
            }}
            closeButton={() => windowManager()['DispatchDisunification'].open.set(false)}
            isExpanded={windowManager()['DispatchDisunification'].minimized}
            variant={'h5'}
          >
            {windowManager()['DispatchDisunification'].minimized.get() &&

              <DispatchDisunification />
            }

          </StandardModal>
        }
      </>
      , modal);
  } else {
    return <></>
  }
}
