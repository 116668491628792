/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { ProcedureResponse } from './interface';
import { getAllProcedures, getProcedures } from './service';

interface AgencyProps {
    readonly onProcedureChange: (value: number | null) => void;
    readonly procedureId?: number | null;
    readonly disabled?: boolean
    readonly size?: "small" | "medium"
}

export default function FilterAutocompleteProcedure({ disabled, onProcedureChange, procedureId, size='small'}: AgencyProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly ProcedureResponse[]>([]);
    const [value, setValue] = useState<ProcedureResponse | null>(null);
    const [search, setSearch] = useState<string | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        if(search && search.length > 3){
            getProcedures(search).then(procedure => {
                setOptions(procedure)
            })
        }
    }, [search])

    useEffect(() => {
        getAllProcedures().then(procedure => {
            setOptions(procedure)
        })
    }, [])

    useEffect(() => {
        if (!procedureId) {
            setValue(null)
        } else {
            setValue(options.find(opt => opt.id === procedureId) ?? null)
        }
    }, [procedureId])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                id='filter-procedure'
                limitTags={2}
                disabled={disabled}
                options={options}
                value={value}
                noOptionsText={translate('No options')}
                clearText={translate('Clear')}
                size={size}
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => `[${option.code}] ${option.name}`}
                onChange={(_, type) => {
                    setValue(type)
                    onProcedureChange(type?.id ?? null)
                }}
                onInputChange={(_, text) => setSearch(text)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Procedure')}
                    />
                )}
            />
        </FormControl>
    )
}