import React from 'react';
import { CircularProgress, Dialog } from '@mui/material';


type LoadingProps = {
    readonly visible: boolean;
    readonly size?:number;
}

export default function Loading({visible = false,size = 40}: LoadingProps) {
return (
    <Dialog
      open={visible}
      PaperProps={{
        sx: { background: 'transparent', display: 'flex', alignItems: 'center', overflow: 'visible' }
      }}
    >
       <CircularProgress disableShrink size={ size } />
    </Dialog>
)
}