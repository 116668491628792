import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '~/components/ErrorFallback';
import Layout from '~/components/Layout';
import InternalHeader from '~/components/Layout/components/InternalHeader';
import InternalContent from '~/components/Layout/components/InternalContent';
import { useLang } from '~/hooks/useLang';
import SlideShow from '~/components/SlideShow';
import CountQualifiers from '~/features/Operation/components/CountQualifiers';
import DispatchByTypeOperationPieChart from '~/features/Operation/components/DispatchByTypeOperationPieChart';
import DispatchBySubTypeOperationPieChart from '~/features/Operation/components/DispatchBySubTypeOperationPieChart';
import DispatchByClosedStatusPieChart from '~/features/Operation/components/DispatchByClosedStatusPieChart';
import DispatchByDistrictPieChart from '~/features/Operation/components/DispatchByDistrictPieChart';
import DispatchByEntryOriginPieChart from '~/features/Operation/components/DispatchByEntryOriginPieChart';
import DispatchByDeviceBarChart from '~/features/Operation/components/DispatchByDeviceBarChart';
import DispatchByHoursLineChart from '~/features/Operation/components/DispatchByHoursLineChart';
import DispatchByCategoryOperationPieChart from '~/features/Operation/components/DispatchByCategoryOperationPieChart';
import { Grid, TextField, FormControl, InputLabel, MenuItem, Button, Checkbox, FormControlLabel, Tooltip, IconButton} from '@mui/material';
import { useHookstate } from '@hookstate/core';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getOperationService } from '~/features/Operation/services/index';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';
import IOperation from '../interfaces/IOperation';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';

interface filtersProps {
  readonly operation: string,
  readonly initialDate: string | null,
}

const filtersDefault = {
  operation: '',
  initialDate: null
}


export default function Dashboard() {
  const { translate } = useLang();
  const loading = useHookstate<boolean>(false)
  const operations = useHookstate<readonly IOperation[]>([]);
  const operationSelect = useHookstate<string>('');
  const [initialDate, setInitialDate] = React.useState<string | null>(null);
  const refresh = useHookstate<boolean>(false);
  const filters = useHookstate<filtersProps>(filtersDefault);

  const CountQualifiersRefresh = useHookstate<boolean>(false);
  const DispatchByTypeOperationPieChartRefresh = useHookstate<boolean>(false);
  const DispatchBySubTypeOperationPieChartRefresh = useHookstate<boolean>(false);
  const DispatchByClosedStatusPieChartRefresh = useHookstate<boolean>(false);
  const DispatchByDeviceBarChartRefresh = useHookstate<boolean>(false);
  const DispatchByCategoryOperationPieChartRefresh = useHookstate<boolean>(false);
  const DispatchByDistrictPieChartRefresh = useHookstate<boolean>(false);
  const DispatchByEntryOriginRefresh = useHookstate<boolean>(false);
  
  useEffect(() => {
    document.title = translate('View Operations - Dispatch System')
  }, [])

  useEffect(() => {
    getOperationService().then((data) => {
      operations.set(data);
    })

  }, []);
  

  const tooltipButtonSearch = () => {
    if(operationSelect.get() == ''){
      return 'Informe a Operação'
    } else if(!initialDate){
      return 'Informe a Data Inicial'
    } else {
      return 'Iniciar'
    }
  }

  return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<Layout marginLayout={true}>
        <InternalHeader title={translate('Operation')} />
        
        <InternalContent title={translate('Filters')} expandButton={true} sxFont={{fontWeight: 'bold', fontSize: 18}}>
          <Grid container direction={'row'}>
            <Grid item xs={2} sx={{ padding: 1 }}>
              <FormControl fullWidth size='small' >
                <InputLabel size='small' id='operation'>
                  {translate('Operation')+ '*'}
                </InputLabel>
                <Select
                  labelId='operation'
                  id='operation'
                  value={operationSelect.get() ?? ''}
                  label={translate('Operation')}
                  size='small'
                  onChange={(event: SelectChangeEvent) => {

                    operationSelect.set(event.target.value)
                  }}
                  endAdornment={
                    operationSelect.get() != '' ? (
                    <IconButton onClick={() => operationSelect.set('')} sx={{ marginRight: 2}}>
                      <CloseIcon/>
                    </IconButton>
                    ): false
                  }
                >
                  {operations.get().map((operation) =>(
                      <MenuItem sx={{ mt: 1 }} key={operation.id} value={operation.id}>
                        {operation.name}
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>            
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                <DateTimePicker label={translate(`Initial Date`) + '*'}
                  format="DD/MM/YYYY HH:mm"
                  ampm={false}
                  ampmInClock={false}
                  value={dayjs(initialDate)}
                  onChange={(newValue: Dayjs | null) => setInitialDate(newValue?.toJSON() ?? null)}
                  slotProps={{ textField: {
                    size: 'small',
                    sx: { margin: 1 },
                    inputProps: {
                      placeholder: translate(`dd/mm/yyyy`)
                    }
                  } }}
                />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'end', margin: 0, padding: 0 }} >
              <Tooltip title={tooltipButtonSearch()} placement="bottom">
                <span>
                  <LoadingButton 
                  loading={loading.get()}
                  variant="contained"
                    disabled={operationSelect.get() == '' || !initialDate}
                    onClick={() => {
                      filters.set({
                        operation: operationSelect.get(),
                        initialDate: initialDate ? initialDate : null,
                      })
                      refresh.set(!refresh.get())
                    }}
                  >
                    {translate('Start')}
                  </LoadingButton>
                </span>
              </Tooltip>
            </Grid>
            </Grid>

        </InternalContent>
        
        { filters.operation.get() != '' && 
          filters.initialDate.get() != null ? (
        <InternalContent expandButton={false} sxFont={{fontWeight: 'bold', fontSize: 18}}>
          <Grid container xs={12}>
            <SlideShow componentsRender={[
              {
                component: <CountQualifiers operationId={filters.operation.get()} initialDate={filters.initialDate.get()} endDate={null} refresh={CountQualifiersRefresh.get()} loading={loading}/>,
                refreshFunction: () => CountQualifiersRefresh.set(!CountQualifiersRefresh.get())
              },
              {
                component: <DispatchByTypeOperationPieChart operationId={filters.operation.get()} initialDate={filters.initialDate.get()} endDate={null} refresh={DispatchByTypeOperationPieChartRefresh.get()} loading={loading} />,
                refreshFunction: () => DispatchByTypeOperationPieChartRefresh.set(!DispatchByTypeOperationPieChartRefresh.get())
              },
              {
                component: <DispatchBySubTypeOperationPieChart operationId={filters.operation.get()} initialDate={filters.initialDate.get()} endDate={null} refresh={DispatchBySubTypeOperationPieChartRefresh.get()} loading={loading}/>,
                refreshFunction: () => DispatchBySubTypeOperationPieChartRefresh.set(!DispatchBySubTypeOperationPieChartRefresh.get())
              },
              {
                component: <DispatchByClosedStatusPieChart operationId={filters.operation.get()} initialDate={filters.initialDate.get()} endDate={null} refresh={DispatchByClosedStatusPieChartRefresh.get()} loading={loading}/>,
                refreshFunction: () => DispatchByClosedStatusPieChartRefresh.set(!DispatchByClosedStatusPieChartRefresh.get())
              },
              {
                component: <DispatchByDeviceBarChart operationId={filters.operation.get()} initialDate={filters.initialDate.get()} endDate={null} refresh={DispatchByDeviceBarChartRefresh.get()} loading={loading}/>,
                refreshFunction: () => DispatchByDeviceBarChartRefresh.set(!DispatchByDeviceBarChartRefresh.get())
              },
              {
                component: <DispatchByCategoryOperationPieChart operationId={filters.operation.get()} initialDate={filters.initialDate.get()} endDate={null} refresh={DispatchByCategoryOperationPieChartRefresh.get()} loading={loading}/>,
                refreshFunction: () => DispatchByCategoryOperationPieChartRefresh.set(!DispatchByCategoryOperationPieChartRefresh.get())
              },
              {
                component: <DispatchByDistrictPieChart operationId={filters.operation.get()} initialDate={filters.initialDate.get()} endDate={null} refresh={DispatchByDistrictPieChartRefresh.get()} loading={loading}/>,
                refreshFunction: () => DispatchByDistrictPieChartRefresh.set(!DispatchByDistrictPieChartRefresh.get())
              },
              {
                component: <DispatchByEntryOriginPieChart operationId={filters.operation.get()} initialDate={filters.initialDate.get()} endDate={null} refresh={DispatchByEntryOriginRefresh.get()} loading={loading}/>,
                refreshFunction: () => DispatchByEntryOriginRefresh.set(!DispatchByEntryOriginRefresh.get())
              }
            ]} />            
          </Grid>
        </InternalContent>
        ) : false}
      </Layout>
    </ErrorBoundary>
  )
}
