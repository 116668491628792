import React from 'react';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { AppRouteProps } from '~/routes';

export default function EntryStatusRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    {path: '/entry-status', component: Index, isProtected: true, permissions: ['entry_status_index']},
    {path: '/entry-status/create', component: Create, isProtected: true, permissions: ['entry_status_create']},
    {path: '/entry-status/edit/:id', component: Edit, isProtected: true, permissions: ['entry_status_edit']},

  ];

  return routes;
}
