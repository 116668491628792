import React from 'react';
import { AppRouteProps } from '~/routes';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';

export default function BreedRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    {path: '/breed', component: Index, isProtected: true, permissions: ['breed_index']},
    {path: '/breed/create', component: Create, isProtected: true, permissions: ['breed_create']},
    {path: '/breed/edit/:id', component: Edit, isProtected: true, permissions: ['breed_edit']},

  ];

  return routes;
}