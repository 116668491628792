import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Autocomplete, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, Grid } from '@mui/material';
import { TheaterComedy } from '@mui/icons-material';
import { useLang } from '../../../../hooks/useLang';
import { useAuth } from '~/features/auth/hooks/useAuth';
import { authFetch } from '~/services/fetch';
import useEntryState from '../../stores/EntryState';

interface TliaButtonProps {
  readonly canTlia?: boolean;
  readonly tlia?: (params) => Promise<void>;
}

interface EntryStatusProps {
  readonly id: number;
  readonly name: string;
}

export default function TliaButton(props: TliaButtonProps) {
  const { translate } = useLang()
  const [entryStatusList, setEntryStatusList] = useState<Array<EntryStatusProps>>([{ id: 0, name: ' ' }]);
  const [entryStatus, setEntryStatus] = useState('');
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState<string | null>(null);
  const entryState = useEntryState();
  const {
    location,
    name,
    phone,
    cityId: cityId,
    districtId: districtId,
    typeId: typeId,
    subtypeId: subtypeId,
    entryOriginId,
    latitude,
    longitude,
    address,
  } = entryState.entry();

  const action = () => {
    props.tlia ?
      props.tlia({
        reason: reason,
        entryStatusId: entryStatus,
        name: name.get(),
        phone: phone.get(),
        address_reference: address,
        location: location.get(),
        latitude: latitude.get(),
        longitude: longitude.get(),
        entryOriginId: entryOriginId.get(),
        cityId: cityId.get(),
        districtId: districtId.get(),
        typeId: typeId.get(),
        subtypeId: subtypeId.get(),
      }) : setOpen(false);
    clearStates();
    setOpen(false);
  };

  useEffect(() => {
    authFetch({
      url: '/entry-status/for-discard',
      method: 'POST',
    }).then(({ data }) => {
      setEntryStatusList(data);
    })
  }, [])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    clearStates();
    setOpen(false);
  };

  const clickOutModalClose = () => {
    setOpen(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setEntryStatus(event.target.value);
  };

  

  const clearStates = () => {
    setEntryStatus('');
    setReason(null);
  }

  return (
    <Grid item>
      <IconButton title={'TLIA'} disabled={!props.canTlia} onClick={handleClickOpen}><TheaterComedy /></IconButton>
      <Dialog open={open} onClose={clickOutModalClose}>
        <DialogTitle>TLIA</DialogTitle>
        <DialogContent>

          <FormControl fullWidth size='small' sx={{ mt: 1 }}>
            <InputLabel size='small' sx={{ mt: 1 }} id='entryStatus'>{translate('Entry Status')}</InputLabel>
            <Select
              labelId='entryStatus'
              id='entryStatus'
              value={entryStatus}
              required={true}
              label={translate('Entry Status')}
              size='small'
              sx={{ mt: 1 }}
              onChange={handleChange}
            >
              {entryStatusList.map((status) => <MenuItem sx={{ mt: 1 }} key={status.id} value={status.id}>{status.name}</MenuItem>)}
            </Select>
          </FormControl>

          <TextField
            multiline
            name={'reason'}
            id='reason'
            label={translate('Reason')}
            required={true}
            fullWidth
            size='small'
            autoFocus
            value={!reason ? '' : reason}
            sx={{ mt: 1 }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => { setReason(event.target.value) }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={handleClose}>
            {translate('Cancel')}
          </Button>
          <Button
            variant='contained'
            onClick={action}>
            {translate('Submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>);

}
