import 'react-medium-image-zoom/dist/styles.css'
import React, { Suspense } from "react";
import Zoom from 'react-medium-image-zoom'
import { Grid } from "@mui/material";
import { mountUrlRequest } from "~/services/fetch";



export default function ImageInterestPoint({ images }) {
  
  // const [content, setContent] = useState('');

  // useEffect(() => {
  //   authFetch({url: `/interest-point/get-media/${images.media_path}`, method: 'GET', responseType: "blob"})
  //   .then((response) => setContent(response.data));
  // }, [])

  return (
    <Grid
      width={'100%'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      marginTop={'24px'}
    >
      <Zoom zoomMargin={50}>
        <img
          key={images.id}
          srcSet={mountUrlRequest(`/interest-point/get-media/${images.media_path}`)}
          src={mountUrlRequest(`/interest-point/get-media/${images.media_path}`)}
          loading="lazy"
          width="500"
          style={{
            height: '280px',
            width: '100%',
          }}
        />
      </Zoom>
    </Grid>


  )
}