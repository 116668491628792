import React from 'react';
import { createState, useState } from '@hookstate/core';
import { Broadcasted } from '@hookstate/broadcasted';
import { setAnnouncementReadService } from '~/features/Announcement/services/index';


interface IAnnouncement {
  readonly id: number
  readonly title: string
  readonly description: string
  readonly announcement_date: string
  readonly announcement_end_date: string
}

const announcementDefault = {
  id: null,
  title: '',
  description: '',
  announcement_date: '',
  announcement_end_date: ''
}

const announcementState = createState<IAnnouncement[]>([]);

// eslint-disable-next-line new-cap
announcementState.attach(Broadcasted('announcement-events'));

export default function useAnnouncementState() {
  const announcement = useState(announcementState);
  
  return ({
    announcement: () => announcement,
    announcementDialogCancel: () => announcement.set([]),
    announcementDialogActionRead: (announcementId) => {
      setAnnouncementReadService({announcementId: announcementId}),
      announcement.set([])
    },
  })
}