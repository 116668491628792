import React from 'react'
import { AppRouteProps } from '~/routes'
import DeviceConnections from './pages/DeviceConnections';
import ManagementDownloadPage from './pages/ManagementDownload';

export default function managementRoutes(): readonly AppRouteProps[] {
  return [
    {
      path: '/management/statistics/dispatch', 
      component: ManagementDownloadPage, 
      isProtected: true,
      permissions: ['statistics_dispatch']
    },
    {
      path: '/management/device/connections', 
      component: DeviceConnections, 
      isProtected: true,
      permissions: ['statistics_device_connection']
    },
  ]
}
