import { authFetch } from '~/services/fetch';
import { IDespacthGraphsSummaryRequest } from '../interfaces/IDispacthStatistic';
import { LatLng } from 'leaflet'
import { State } from '@hookstate/core';

interface DispatchTransferServiceProps {
  readonly dispatchId: number | null;
  readonly dispatchGroupId: number | undefined;
  readonly loading?: State<boolean>
}

export async function getDispatchByIdService(id) {
  try {
    const { data } = await authFetch({
      url: '/dispatch/get',
      method: 'POST',
      data: {
        dispatch_id: id,
      },
    })

    return data;
  } catch (error) {
    throw error
  }
}

export async function getContributersDispatchService(id) {
  try {
    const { data } = await authFetch({
      url: '/dispatch/contributers',
      method: 'POST',
      data: {
        dispatch_id: id,
      },
    })

    return data;
  } catch (error) {
    throw error
  }
}

export async function getDispatchOverviewService(id) {
  try {
    const response = await authFetch({
      url: '/dispatch/overview',
      method: 'POST',
      data: {
        dispatch_id: id
      }
    })

    return response.data;

  } catch (error) {
    throw error
  }
}

export async function getDispatchClosingCommentsService({ id, order = 'DESC' }) {
  try {
    const { data } = await authFetch({
      url: '/dispatch/closing-comments',
      method: 'POST',
      data: {
        dispatch_id: id,
        order: order
      }
    })

    if (!data) return [];

    return data;

  } catch (error) {
    throw error
  }
}

export async function getDispatchDashboardTodayService() {
  try {
    const response = await authFetch({
      url: '/dispatch/dashboard/today',
      method: 'GET',
    })

    return response.data;

  } catch (error) {
    throw error
  }
}

export async function getStatisticsDispatchByTypeService({ operationId, initialDate, endDate }) {
  try {
    const { data } = await authFetch({
      url: '/statistics/dispatch-by-type',
      method: 'POST',
      data: {
        operationId: operationId,
        startDate: initialDate,
        finishDate: endDate,
        excludeClosed: false
      }
    })

    return data;

  } catch (error) {
    throw error
  }
}

export async function getStatisticsDispatchBySubTypeService({ operationId, initialDate, endDate }) {
  try {
    const { data } = await authFetch({
      url: '/statistics/dispatch-by-type-subtype',
      method: 'POST',
      data: {
        operationId: operationId,
        startDate: initialDate,
        finishDate: endDate,
        excludeClosed: false
      }
    })

    return data;

  } catch (error) {
    throw error
  }
}

export async function getStatisticsDispatchByClosedStatusService({ operationId, initialDate, endDate }) {
  try {
    const { data } = await authFetch({
      url: '/statistics/dispatch-by-closed-status',
      method: 'POST',
      data: {
        operationId: operationId,
        startDate: initialDate,
        finishDate: endDate,
        excludeClosed: false
      }
    })

    return data;

  } catch (error) {
    throw error
  }
}

export async function getStatisticsDispatchByDistrictService({ operationId, initialDate, endDate }) {
  try {
    const { data } = await authFetch({
      url: '/statistics/dispatch-by-district',
      method: 'POST',
      data: {
        operationId: operationId,
        startDate: initialDate,
        finishDate: endDate,
        excludeClosed: false
      }
    })

    return data;

  } catch (error) {
    throw error
  }
}

export async function getStatisticsDispatchByDeviceService({ operationId, initialDate, endDate }) {
  try {
    const { data } = await authFetch({
      url: '/statistics/dispatch-by-device',
      method: 'POST',
      data: {
        operationId: operationId,
        startDate: initialDate,
        finishDate: endDate,
        excludeClosed: false
      }
    })

    return data;

  } catch (error) {
    throw error
  }
}

export async function getStatisticsDispatchByHoursService({ operationId, initialDate, endDate }) {
  try {
    const { data } = await authFetch({
      url: '/statistics/dispatches-by-hours',
      method: 'POST',
      data: {
        operationId: operationId,
        startDate: initialDate,
        finishDate: endDate,
        excludeClosed: false
      }
    })

    return data;

  } catch (error) {
    throw error
  }
}

export async function getStatisticsDispatchByCategoryOperationService({ operationId, initialDate, endDate }) {
  try {
    const { data } = await authFetch({
      url: '/statistics/dispatch-by-category',
      method: 'POST',
      data: {
        operationId: operationId,
        startDate: initialDate,
        finishDate: endDate,
        excludeClosed: false
      }
    })

    return data;

  } catch (error) {
    throw error
  }
}

export async function getStatisticsDispatchByEntryOriginService({ operationId, initialDate, endDate }) {
  try {
    const { data } = await authFetch({
      url: '/statistics/occurrences-by-entry-origin',
      method: 'POST',
      data: {
        operationId: operationId,
        startDate: initialDate,
        finishDate: endDate,
        excludeClosed: false
      }
    })

    return data;

  } catch (error) {
    throw error
  }
}



export async function setDispatchTransferService({ dispatchId, dispatchGroupId, loading }: DispatchTransferServiceProps) {
  loading?.set(true)
  try {
    const { data } = await authFetch({
      url: '/dispatch/transfer',
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
        dispatch_group_id: dispatchGroupId,
      }
    })

    return data;

  } catch (error) {
    throw error
  }
}

export async function getMarkersDispatchService() {
  try {
    const { data } = await authFetch({
      url: '/dispatch/in-progress',
      method: 'POST',
    });

    const result = data
      .filter((item) => item.occurrence)
      .filter((item) => item.occurrence.latitude != null && item.occurrence.longitude != null)
      .reduce((acc, dispatch) => {
        const {
          id: dispatchId,
          code,
          occurrence,
          type,
          subtype,
          status,
          /* eslint-disable camelcase */
          dispatched_at
        } = dispatch;

        // eslint-disable-next-line functional/immutable-data
        acc[dispatchId] = {
          id: dispatchId,
          markerId: `dispatch-${dispatchId}`,
          code: code,
          location: occurrence.location ?? '',
          position: new LatLng(
            occurrence.latitude,
            occurrence.longitude
          ),
          typeName: type?.name ?? null,
          subtypeName: subtype?.name ?? null,
          /* eslint-disable camelcase */
          dispatchedAt: dispatched_at ?? null,
          status: status.id,
          isCurrent: false,
        }
        
        return acc;
      }, {})

    return result

  } catch (error) {
    throw error
  }
}

export async function getCoordenation(id: string) {
  try {
    const { data } = await authFetch({
      url: `/dispatch/coordination/${id}`,
      method: 'GET',
    })

    return data;

  } catch (error) {
    throw error
  }
}
export async function getDetailsOccurrences(id: string) {
  try {
    const { data } = await authFetch({
      url: `/dispatch/coordination/${id}/get-dispatches`,
      method: 'GET',
    })

    return data;

  } catch (error) {
    throw error
  }
}
export async function getObservations(id: string) {
  try {
    const { data } = await authFetch({
      url: `/dispatch/coordination/${id}/comments`,
      method: 'GET',
    })

    return data;

  } catch (error) {
    throw error
  }
}
export async function getOccurrenceIndicators(id: string) {
  try {
    const { data } = await authFetch({
      url: `/dispatch/coordination/${id}/occurrence-indicators`,
      method: 'GET',
    })

    return data;

  } catch (error) {
    throw error
  }
}
export const getCurrentCoordenation = async () => {
  try {
    const { data } = await authFetch({
      url: '/dispatch/coordination/current-from-user',

      method: 'GET',
    })

    return data;

  } catch (error) {
    throw error
  }


}
export const getDispatchByDispatchGroup = async ({ coordinationId, dispatchGroupIds }: IDespacthGraphsSummaryRequest) => {
  try {
    const { data } = await authFetch({
      url: '/statistics/dispatch-by-dispatch-group',
      method: 'POST',
      data: {
        coordinationId,
        dispatchGroupIds
      }
    })
    return data;
  } catch (error) {
    throw error
  }
}

export const getDispatchByType = async ({ coordinationId, dispatchGroupIds }: IDespacthGraphsSummaryRequest) => {
  try {
    const { data } = await authFetch({
      url: '/statistics/dispatch-by-type',
      method: 'POST',
      data: {
        coordinationId,
        dispatchGroupIds
      }
    })
    return data;
  } catch (error) {
    throw error
  }
}

export const getDispatchByCity = async ({ coordinationId, dispatchGroupIds }: IDespacthGraphsSummaryRequest) => {
  try {
    const { data } = await authFetch({
      url: '/statistics/dispatch-by-city',
      method: 'POST',
      data: {
        coordinationId,
        dispatchGroupIds
      }
    })
    return data;
  } catch (error) {
    throw error
  }
}

export const createCoordinationObservation = async (coordinationId: number, notes: string) => {
  try {
    const { data } = await authFetch({
      url: '/dispatch/coordination/comment/store',
      method: 'POST',
      data: {
        coordination_id: coordinationId,
        notes: notes,
      }
    })
    return data;
  } catch (error) {
    throw error
  }
}


export const getDraftCloseComment = async (dispatchId: number, dispatchDeviceId?: number) => {
  try {
    const data = await authFetch({
      url: '/dispatch/closing-comment/draft',
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
        dispatch_device_id: dispatchDeviceId,
      }
    })
    return data;
  } catch (error) {
    throw (error)
  }
}

export const saveDraft = async (dispatchId: number, comment: string, dispatchDeviceId?: number) => {
  try {
    const { data } = await authFetch({
      url: '/dispatch/closing-comment/save-draft',
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
        comment: comment,
        dispatch_device_id: dispatchDeviceId,
      }
    });
    return data;
  } catch (error) {
    throw error
  }
}

export const saveLogPrintDispatch = async (dispatchId) => {
  try {
    const data = await authFetch({
      url: '/dispatch/print/generate-timeline',
      method: 'POST',
      data: {
        dispatch_id: dispatchId
      }

    });

    return data
  } catch (error) {
    throw error
  }
}

export const saveLogVisualizeQualificators = async (id, url) => {
  try {
    const data = await authFetch({
      url: url,
      method: 'POST',
      data: {
        id: id
      }
    })

    return data
  } catch (error) {
    throw error
  }
}

export const saveLogVisualizeDispatch = async (dispatchId) => {
  try {
    const data = await authFetch({
      url: '/dispatch/generate-view-timeline',
      method: 'POST',
      data: {
        dispatch_id: dispatchId
      }
    })

    return data
  } catch (error) {
    throw error
  }
}

export async function getDispatchProcedures({typeId, subtypeId=null}: {typeId: any, subtypeId: any}) {
  try {
    const response = await authFetch({

      url: '/procedure/config/get-procedure',
      method: 'POST',
      data: {
        type_id: typeId,
        subtype_id: subtypeId,
        related: 1
    }
  })
    
    return response.data;

  } catch (error) {

    throw error
  }
}