import { authFetch } from "~/services/fetch";

export async function makeRequestToGetReportTotalPrankCalls({
    startDate,
    finishDate,
    contactCenterId,
    phone,
    onlyEntry,
    onlyOccurrence,
    syntheticReport,
    exportCSV = false,
    entryOriginId
}) {
    try {
        const response = await authFetch({
            url: '/report/total-prank-calls',
            method: 'POST',
            data: {
                start_date: startDate,
                finish_date: finishDate,
                contact_center_id: contactCenterId,
                phone: phone,
                only_entry: onlyEntry,
                only_occurrence: onlyOccurrence,
                synthetic_report: syntheticReport,
                export_csv: exportCSV,
                entry_origin_id: entryOriginId
            }
        });

        return response.data;

    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}