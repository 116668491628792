import React, { useState } from "react";
import { StateMethods } from "@hookstate/core";
import {Grid, Switch, FormControlLabel } from "@mui/material";
import { useLang } from "~/hooks/useLang";
import  { Method } from "axios";
import { Controller, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import FilterAutocompleteFetch from "~/components/FilterAutocompleteFetch";
import useUserState from "~/features/User/stores/UserState";

type subtitle = {
  readonly type?: 'default' | 'error' | 'success',
  readonly color?: string,
  readonly message?: string
}

type DispatchSearchBoxProps = {
  readonly isOpen: StateMethods<boolean>;
  readonly isValid: boolean;
  readonly notOnlyVisibles?: boolean;
  readonly size?: "small" | "medium" | undefined;
  readonly dispatchValue?: StateMethods<string | null>
}

type CrudAutoCompleteFieldProps = {
  readonly register: any;
  readonly name: string;
  readonly model: any;
  readonly urlFetch?: string;
  readonly control: any;
  readonly placeholder: string;
  readonly optionSelect?: any;
  readonly method?: Method;
  readonly isOpen?: StateMethods<boolean>;
  readonly size?: "small" | "medium" | undefined;
  readonly defaultFetch?: boolean;


  readonly getValues?: UseFormGetValues<any>;
  readonly setFormValue: UseFormSetValue<any>;
}

export default function CrudFetchAutocomplete(props: CrudAutoCompleteFieldProps) {
  const { translate } = useLang();
  const { urlFetch, placeholder, defaultFetch, name, control, model, register, setFormValue } = props;
  const [checked, setChecked] = useState<boolean>(false);
  const { user: userSession } = useUserState();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
        <Controller
          {...register(`${name}`)}
          name={model}
          control={control}
          render={(props) => (
            <Grid item xs={4} sx={{marginBottom: '32px'}}>
              <FilterAutocompleteFetch
                {...props}
                label={placeholder}
                urlFetch={`/${urlFetch}`}
                defaultFetch={checked}
                setCheckedChange={setChecked}
                userDefault={checked ? userSession().value : null}
                setFormValue={setFormValue}
                register={name}
              />
            </Grid>
          )}
        />
        <Grid item xs={8}>
          {defaultFetch &&
            <FormControlLabel
              label={translate('Select me')}
              control={<Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />}
            />}
        </Grid>
    </>

  )
}