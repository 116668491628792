import React from 'react';
import {
  IconButton, Grid
} from '@mui/material';
import { AccessTimeFilled } from '@mui/icons-material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import DispatchTimelineDialog from '~/features/Dispatch/components/DispatchTimeline/components/DispatchTimelineDialog';
interface DispatchTimelineProps {
  readonly canChronology: boolean | undefined;
  readonly chronology: number | string | null | undefined;
}

export default function DispatchTimeline(props: DispatchTimelineProps) {
  const { translate } = useLang();
  const open = useHookstate(false);

  return (
    <>
      <Grid item>
        <IconButton title={translate('See Chronology')} disabled={!props.canChronology} onClick={() => open.set(true)}>
          <AccessTimeFilled />
        </IconButton>
      </Grid>
      {open.get() && <DispatchTimelineDialog open={open} chronology={props.chronology} />}
    </>);

}