import React from 'react';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { AppRouteProps } from '~/routes';

export default function DrugTypeRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    {path: '/drug-type', component: Index, isProtected: true, permissions: ['drug_type_index']},
    {path: '/drug-type/create', component: Create, isProtected: true, permissions: ['drug_type_create']},
    {path: '/drug-type/edit/:id', component: Edit, isProtected: true, permissions: ['drug_type_edit']},

  ];

  return routes;
}
