import React, { useEffect } from 'react';
import { useHookstate } from '@hookstate/core';
import useSystemState from '~/features/System/stores/SystemState';
import { Chip, CircularProgress, Grid, IconButton } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import { getCurrentDispatchDeviceService } from '~/features/Device/services';

export default function SyncDispatchDeviceButton({ id, status, closeThisPopUp }) {
  const { getStatusColors } = useSystemState();
  const { syncDispatchFromId } = useDispatchState();
  const { translate } = useLang();
  const isLoading = useHookstate(true);
  const isAwaiting = useHookstate(false);
  const dispatch = useHookstate(0);
  const dispatchStatus = useHookstate(null);
  const dispatchCode = useHookstate(false);

  const handleSyncDispatch = async () => {
    isAwaiting.set(true);
    syncDispatchFromId( dispatch.get() )
      .finally(() => {
        isAwaiting.set(false);
        closeThisPopUp()
      });
    
  }
  
  useEffect(() => {
    isLoading.set(true)
    getCurrentDispatchDeviceService({deviceId: id}).then((data) => {
      if(data){
        dispatch.set(data.dispatch.id);
        dispatchStatus.set(data.dispatch.status.id);
        dispatchCode.set(data.dispatch.code);
      } else {
        dispatch.set(0);
        dispatchStatus.set(null);
        dispatchCode.set(false);
        
      }      
      isLoading.set(false);
    })
  }, [status])

  return (
    <Grid
      item
      xs={8}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Chip
        disabled={!(status != 1 && status != 10 && status != 11) || isAwaiting.get() || isLoading.get() || !dispatch.get() }
        onClick={handleSyncDispatch}
        sx={{
          width:'100%',
          backgroundColor: dispatchStatus.get() ? getStatusColors(dispatchStatus.get() ?? null).main : null,
          color: dispatchStatus.get() ? getStatusColors(dispatchStatus.get() ?? null).contrastText : null,
          '&:hover': {
            backgroundColor: dispatchStatus.get() ? getStatusColors(dispatchStatus.get() ?? null).dark : null,
            color: dispatchStatus.get() ? getStatusColors(dispatchStatus.get() ?? null).contrastText : null,
          },
        }}
        label={(status != 1 && status != 10 && status != 11) ? (isAwaiting.get() || isLoading.get() ? <CircularProgress size={22}/> : dispatchCode.get()) : translate('Without Dispatch')}
      />
    </Grid>
  )
}