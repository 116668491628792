import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IDispatchCloseStatusClass from '../Interfaces/IDispatchCloseStatusClass';


export default function Edit() {
    const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm<IDispatchCloseStatusClass>();
    const fields = [
        { name: 'id', type: 'hidden' },
        { name: 'name', type: 'string' },
    ];

    return (
        <CrudEdit
            model={'closed-status-class'}
            fields={fields}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            setFormValue={setFormValue}
            messageSuccess={'Closed status class successfully updated!'}
            title={'Closed status class'}
            getValues={getValues}
        />
    )
}
