import React from 'react';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { AppRouteProps } from '~/routes';

export default function DispatchCodeRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    {path: '/dispatch/code', component: Index, isProtected: true, permissions: []},
    {path: '/dispatch/code/create', component: Create, isProtected: true, permissions: []},
    {path: '/dispatch/code/edit/:id', component: Edit, isProtected: true, permissions: []},

  ];

  return routes;
}
