
import React from 'react';
import { authFetch } from '~/services/fetch';
import { Grid, List, Card, CardHeader, ListItem, ListItemText, ListItemIcon, Checkbox, Button, Divider, Paper, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import notify from '~/utils/notify';

interface CrudListTransferFieldProps {
  readonly register: UseFormRegister<any>;
  readonly model: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly showColumnText?: string;
  readonly options: {
    firstListUrl: string,
    secondListUrl: string
  };
  readonly placeholder: string;
  readonly setFormValue: UseFormSetValue<any>;
}
interface IListProps {
  readonly id: number;
  readonly name: string;
  readonly showColumnText: string;
}

// eslint-disable-next-line max-lines-per-function
export default function CrudListTransferField({ register, model, getValues, options, placeholder, setFormValue, showColumnText = 'name' }: CrudListTransferFieldProps) {
  const { translate } = useLang();
  const registeredModelFormField = register(model);

  const [checked, setChecked] = React.useState<Array<IListProps | null>>([]);
  const [left, setLeft] = React.useState<Array<IListProps | null>>([]);
  const [right, setRight] = React.useState<Array<IListProps | null>>([]);


  React.useEffect(() => {

    const fetchData = async () => {
      if (options.firstListUrl != '') {
        const targetUrl = `${options.firstListUrl}`;
        const response = await authFetch({
          url: targetUrl,
          method: 'GET',
        });
        return response.data;
      } else {
        return [];
      }
    }
    fetchData().then((right) => {
      const fetchData = async () => {
        const targetUrl = `${options.secondListUrl}`;
        const response = await authFetch({
          url: targetUrl,
          method: 'GET',
        });

        setLeft(not(response.data, right));
        setRight(right);
        setFormValue(model, right.map((element) => element.id));
      }
      fetchData()
        .catch(console.error);
    })
      .catch(console.error);
  }, []);

  const rightChecked = intersection(checked, right);
  const leftChecked = intersection(checked, left);


  function not(firstList: Array<{ id: number, name: string, showColumnText: string } | null>, secondList: Array<{ id: number, name: string, showColumnText: string } | null>) {
    const secondListName = secondList.map((value) => value?.[showColumnText]);
    return firstList.filter((value) => !secondListName.includes(value?.[showColumnText]));
  }

  function intersection(firstList: Array<{ id: number, name: string, showColumnText: string } | null>, secondList: Array<{ id: number, name: string, showColumnText: string } | null>) {
    const secondListName = secondList.map((value) => value?.[showColumnText]);
    return firstList.filter((value) => secondListName.includes(value?.[showColumnText]));
  }

  function union(firstList: Array<{ id: number, name: string, showColumnText: string } | null>, secondList: Array<{ id: number, name: string, showColumnText: string } | null>) {
    return [...firstList, ...not(secondList, firstList)];
  }

  const handleToggle = (value) => () => {
    const newChecked = [...checked];

    if (!checked.includes(value)) {
      newChecked.push(value);
    } else {
      const temp = newChecked.filter(item => item?.id !== value.id);
      return setChecked(temp);
    }
    return setChecked(newChecked);
  };

  const optionOfChecked = (items: Array<{ id: number, name: string, showColumnText: string } | null>) => intersection(checked, items).length;

  const handleToggleAll = (items: Array<{ id: number, name: string, showColumnText: string } | null>) => () => {
    if (optionOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const newRight = right.concat(leftChecked)
    setRight(newRight);
    setFormValue(model, newRight.map((element) => element?.id));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    const newRight = not(right, rightChecked)
    setLeft(left.concat(rightChecked));
    setRight(newRight);
    setFormValue(model, newRight.map((element) => element?.id))
    setChecked(not(checked, rightChecked));
  };

  const customList = (title: React.ReactNode, items: Array<IListProps | null>) => (
    <Card>
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={optionOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              optionOfChecked(items) !== items.length && optionOfChecked(items) !== 0
            }
            disabled={items.length === 0}
          />
        }
        title={title}
        subheader={`${optionOfChecked(items)}/${items.length} ${translate('Selected')}`}
      />
      <Divider />
      <List
        sx={{
          width: '100%',
          height: 230,
          backgroundColor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((element) => {
          const labelId = `transfer-list-all-item-${element?.id}-label`;

          return (
            <ListItem
              key={element?.id}
              role="listitem"
              button
              onClick={handleToggle(element)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.includes(element)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${element && element[showColumnText]}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid item xs={12}>

      <Paper variant='outlined' sx={{ alignItems: 'center', justifyItems: 'center' }}>
        <Typography sx={{ textAlign: 'center', paddingY: 2 }}>
          {translate(placeholder)}
        </Typography>
        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid item>{customList(translate('Choices'), left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ margin: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
              >
                &gt;
              </Button>
              <Button
                sx={{ margin: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList(translate('Chosen'), right)}</Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}