import { authFetch } from "~/services/fetch";
import { StatusResponse } from "../interfaces";

export async function getStatus(): Promise<readonly StatusResponse[]> {
    try {
        const { data } = await authFetch({
            url: '/status',
            method: 'GET',
        })
        return data;
    } catch (error) {
        throw error;
    }
}