import { authFetch } from "~/services/fetch";
import { OperationUnitResponse } from "../interface";

export async function getOperationUnit(): Promise<readonly OperationUnitResponse[]> {
  try {
    const { data } = await authFetch({
      url: '/operation-unit',
      method: 'GET',
    })
    return data;
  } catch (error) {
    throw error;
  }
}