import React from "react";
import {IconButton, Grid} from '@mui/material';
import {ArrowBackIos} from '@mui/icons-material';
import { useLang } from '~/hooks/useLang';


export default function PreviousNavegationButton({hasPrevious, previous}){
  const { translate } = useLang()
 
  return (
    <Grid item>
      <IconButton title={translate('Previous')} disabled={!hasPrevious} onClick={() => previous ? previous() : false}>
        <ArrowBackIos/>
      </IconButton>
    </Grid>);

}