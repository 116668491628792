import { State } from "@hookstate/core";
import useEntryState from "../stores/EntryState";

interface entrySocketProps {
  readonly message: any;
  readonly translate?: any;
  readonly handleUpdateMyEntries?: any;
  readonly socketUpdateEntry?: any;
  readonly entry?: any;
  readonly actualNotification: () => State<number|null>;
}

export default function entriesSocket({ message, translate, handleUpdateMyEntries, socketUpdateEntry, entry, actualNotification }: entrySocketProps) {
  console.info(`Dispatch socket received: ${message.event_type}`);

  if (message.event_type === 'ENTRY_UPDATED') {
    try {
      const {
        address,
        address_reference: addressReference,
        created_at: createdAt,
        entry_origin_id: entryOriginId,
        id: id,
        latitude,
        longitude,
        location,
        name,
        phone,
        subtype_id: subtypeId,
        subtypename,
        type_id: typeId,
        typename,
        updated_at: updatedAt
      } = message.content

      socketUpdateEntry({
        entry: {
          address,
          addressReference,
          createdAt,
          entryOriginId,
          id,
          latitude,
          longitude,
          location,
          name,
          phone,
          subtypeId,
          subtypename,
          typeId,
          typename,
          updatedAt
        },
      })
    } catch (error) {
      console.log(error);
      console.error(`Erro  ${JSON.stringify(message)} - ${JSON.stringify(error)}`);
    }

  }

}