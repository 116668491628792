import React, { ChangeEvent, useEffect, useState } from 'react';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, TextField, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Autocomplete, Box } from '@mui/material';
import { authFetch } from '~/services/fetch';
import notify from '~/utils/notify';
import { LoadingButton } from '@mui/lab';
import * as zod from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { deviceOutOfService, getOutServiceReason } from './services';
import type { OutOfServiceReasonProps } from './types';


const outOfServiceDispatchVehicleSchema = zod.object({
  outOfServiceReason: zod
    .number({ message: 'Informe um motivo' }),
  comment: zod
    .string({ message: 'Insira um comentário' })
    .min(5, { message: 'Minimo de 5 caracteres' }),
})

type outOfServiceDispatchVehicleData = zod.infer<typeof outOfServiceDispatchVehicleSchema>

// eslint-disable-next-line max-lines-per-function
export default function OutOfServiceDispatchVehicle({ openState, identification, deviceId }) {
  const { translate } = useLang();
  const loadingButton = useHookstate(false)
  const [optionsFilter, setOptionsFilter] = useState<readonly OutOfServiceReasonProps[]>([]);
  const isLoading = useHookstate(false)

  const { register, resetField, handleSubmit, control, watch, setValue, setError, clearErrors, formState: { errors } } = useForm<outOfServiceDispatchVehicleData>({
    resolver: zodResolver(outOfServiceDispatchVehicleSchema),
    criteriaMode: "all",
    defaultValues: {
      comment: '',
    },
  })

  useEffect(() => {
    resetValues()

    if (openState.get()) {
      isLoading.set(true)
      getOutServiceReason().then((response) => {
        setOptionsFilter(response)
      }).finally(() => {
        isLoading.set(false)
      })
    }
  }, [openState.get()]);


  const resetValues = () => {
    resetField('comment')
    resetField('outOfServiceReason')

    clearErrors(['comment', 'outOfServiceReason'])
  };

  const handleCancel = () => {
    openState.set(false);
    resetValues();
  };


  async function onSubmitForm(data: outOfServiceDispatchVehicleData) {
    loadingButton.set(true)
    deviceOutOfService({
      deviceId: deviceId,
      outOfServiceReasonId: data.outOfServiceReason,
      comment: data.comment
    })
      .then(({ status }) => {
        if (status === 200) {
          notify({ message: translate('Device moved to out of service Successfully!'), type: 'success' });
        }
        return handleCancel();
      }).finally(() => {
        loadingButton.set(false)
      });
  }


  return (
    <Dialog open={openState.get()} onClose={handleCancel} fullWidth={true}>
      <DialogTitle >
        {translate('Out of service')} - {identification}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent sx={{ padding: '0px 24px' }}>
          <Controller
            {...register('outOfServiceReason')}
            name={'outOfServiceReason'}
            control={control}
            render={(props) => (
              <Autocomplete
                {...props}
                id='filter-out-service-reason'
                limitTags={2}
                options={optionsFilter}
                value={props.field.value && optionsFilter ? optionsFilter.find(element => element?.id === props.field.value) : null}
                noOptionsText={translate('No options')}
                clearText={translate('Clear')}
                size='small'
                loading={isLoading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={(_, type) => {
                  props.field.onChange(type?.id);
                  // dispatchGroupIdSelected.set(type?.id ? type.id : null)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${translate('Out of service reason')} *`}
                    error={!!errors.outOfServiceReason?.type}
                    margin={'dense'}
                  />
                )} />
            )} />

          <Controller
            {...register('comment')}
            name={'comment'}
            control={control}
            render={({
              field: { onChange } }) => {
              return (
                <TextField
                  id='comment'
                  name={'comment'}
                  fullWidth
                  label={`${translate('Comment')} *`}
                  multiline
                  rows={4}
                  size='small'
                  sx={{ mt: 1 }}
                  error={!!errors.comment?.type}
                  onChange={({ target: { value } }) => {
                    onChange(value)
                    if (setValue) setValue('comment', value)
                  }}
                />
              )
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            padding: "16px 24px",
            gap: 1,
          }}
        >
          <Button variant='outlined' onClick={handleCancel}>
            {translate("Cancel")}
          </Button>
          <LoadingButton
            loading={loadingButton.get()}
            variant='contained'
            type='submit'
          >
            {translate("Confirm")}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
