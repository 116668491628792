import { authFetch } from "~/services/fetch";
import { CurrentCoordination } from "../interface";

export async function getCoordination(): Promise<CurrentCoordination> {
    try {
        const targetUrl = '/dispatch/coordination/current-from-user';

        const { data } = await authFetch({
            url: targetUrl,
            method: 'GET',
        });

        return data;
    } catch (error) {
        throw error;

    }

}
