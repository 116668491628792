import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, ListSubheader, MenuItem, Select, Tooltip } from '@mui/material';
import { useLang } from '~/hooks/useLang';

export type ReportTypeOptionType = {
    readonly id: number,
    readonly name: string
}

interface SelectReportTypeProps {
    readonly valueDefault: string;
    readonly disabled: boolean;
    readonly onReportTypeChange: (value: string) => void;
}

export default function SelectReportType({ disabled, valueDefault, onReportTypeChange }: SelectReportTypeProps) {
    const { translate } = useLang();
    const [value, setValue] = useState(valueDefault);

    useEffect(() => {
        setValue(valueDefault)
    }, [valueDefault])

    return (
        <FormControl disabled={disabled} sx={{ width: 200 }} size='small'>
            <InputLabel id='SelectReportType'>
                {translate('Report Type')}
            </InputLabel>
            <Select
                fullWidth
                labelId='SelectReportType'
                id='SelectReportType'
                value={value}
                label={translate('Report Type')}
                onChange={(event) => {
                    setValue(event.target.value)
                    onReportTypeChange(event.target.value)
                }}
            >
                <ListSubheader>
                    {translate('Dispatch') + ' / ' + translate('Occurrences')}
                </ListSubheader>
                <MenuItem sx={{ mt: 1 }} value={'synthetic'} >
                    {translate('synthetic')}
                </MenuItem>
                <MenuItem sx={{ mt: 1 }} value={'complete'} >
                    {translate('complete')}
                </MenuItem>
                <ListSubheader>
                    {translate('Qualifiers')}
                </ListSubheader>
                <MenuItem sx={{ mt: 1 }} value={'vehicle'} >
                    {translate('By vehicle')}
                </MenuItem>
                <MenuItem sx={{ mt: 1 }} value={'people'} >
                    {translate('By people')}
                </MenuItem>
                <MenuItem sx={{ mt: 1 }} value={'drugs'} >
                    {translate('By drugs')}
                </MenuItem>
                <MenuItem sx={{ mt: 1 }} value={'weapon'} >
                    {translate('By weapon')}
                </MenuItem>
                <MenuItem sx={{ mt: 1 }} value={'object'} >
                    {translate('By object')}
                </MenuItem>
                <MenuItem sx={{ mt: 1 }} value={'organization'} >
                    {translate('By organization')}
                </MenuItem>
                <MenuItem sx={{ mt: 1 }} value={'animal'} >
                    {translate('By animal')}
                </MenuItem>
            </Select>
        </FormControl>
    )
}
