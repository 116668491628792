/* eslint-disable max-lines-per-function */
import React from 'react'
import { State } from '@hookstate/core'
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material'
import { useLang } from '~/hooks/useLang'
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod'
import FilterAutocompleteAgency from '~/components/FilterAutocompleteAgency'
import { Dayjs } from 'dayjs'
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups'
import FilterAutocompleteDevice from '~/components/FilterAutocompleteDevice'
import FilterSwitchCoordination from '~/components/FilterSwitchCoordination'
import { CurrentCoordination } from '~/components/FilterSwitchCoordination/interface'
import FilterSwitchOtherDispatchsGroup from '~/components/FilterSwitchOtherDispatchsGroups'
import FilterAutocompleteOperation from '~/components/FilterAutocompleteOperation'
import SelectConsummatedAndAttempted from '~/components/FilterSelectConsummatedAndAttempted'
import useSystemState from '~/features/System/stores/SystemState'
import { LoadingButton } from '@mui/lab'
import FilterAutocompleteTypes from '~/components/FilterAutoCompleteTypes'
import FilterAutocompleteSubtypesByTypes from '~/components/FilterAutocompleteSubtypesByTypes'
import FilterAutocompleteDispatchGroupSet from '~/components/FilterAutocompleteDispatchGroupSet'
import FilterAutocompleteCategories from '~/components/FilterAutocompleteCategories'
import FilterAutocompleteCities from '~/components/FilterAutocompleteCities'
import Cancel from '@mui/icons-material/Cancel'
import FilterAutocompleteOperationUnit from '~/components/FilterAutocompleteOperationUnit'
import FilterAutocompleteMultipleStatus from '~/components/FilterAutocompleteMutipleStatus'
import FilterAutocompleteClosedStatusClass from '~/components/FilterAutocompleteClosedStatusClass'
import FilterAutocompleteClosedStatus from '~/components/FilterAutocompleteClosedStatus'
import FilterCheckboxQualifiers from '~/components/FilterCheckboxQualifiers'

interface DispatchFiltersDashboardProps {
  readonly loading: State<boolean>
  readonly categoryIds: State<number[]>
  readonly currentCoordination: State<CurrentCoordination | null>
  readonly deviceId: State<number | null>
  readonly groupId: State<readonly number[]>
  readonly agencyId: State<number | null>
  readonly initialPeriod: State<string>
  readonly typeIds: State<number[]>
  readonly subtypeIds?: State<number[]>
  readonly operationId?: State<number | null>
  readonly hidden?: boolean
  readonly lockFields: boolean
  readonly withSolvedThirdParty: State<boolean>
  readonly consummatedAndAttempted: State<string | null>

  readonly searchButton: () => void
  readonly clearFilters: () => void
  readonly setCoordinationDates: (date: boolean) => void

  readonly onStartDateChange: (value: Dayjs | null) => void
  readonly onFinishDateChange: (value: Dayjs | null) => void
  readonly onPeriodChange: (value: string) => void

  readonly statusIds: State<number[]>
  readonly cityIds: State<number[]>
  readonly dispatchGroupSetId: State<number | null>
  readonly location: State<string>
  readonly operationUnitId: State<number | null>
  readonly closedStatusClassId?: State<number | null>
  readonly closedStatusId?: State<number | null>
  readonly qualifiersSelected?: State<number[]>
  readonly clearState?: State<boolean>
}

export default function DispatchFiltersDashboard({
  loading,
  setCoordinationDates,
  lockFields,
  consummatedAndAttempted,
  currentCoordination,
  withSolvedThirdParty,
  deviceId,
  groupId,
  agencyId,
  initialPeriod,
  onFinishDateChange,
  onPeriodChange,
  onStartDateChange,
  typeIds,
  subtypeIds,
  categoryIds,
  operationId,
  hidden,
  clearFilters,
  searchButton,
  statusIds,
  cityIds,
  dispatchGroupSetId,
  location,
  operationUnitId,
  closedStatusClassId,
  closedStatusId,
  qualifiersSelected,
  clearState,
}: DispatchFiltersDashboardProps) {
  const { translate } = useLang()
  const { systemConfig } = useSystemState()
  const closedStatusClassLock = lockFields
    ? lockFields
    : !!statusIds.get()?.length && !statusIds.get()?.includes(8)

  return (
    <Collapse in={hidden}>
      <Grid
        container
        xs={12}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          paddingX: 2,
          paddingBottom: 2,
        }}
      >
        <Grid container direction={'row'}>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteAgency
              disabled={lockFields}
              agencyId={agencyId.get()}
              onAgencyChange={(agency) => {
                agencyId.set(agency)
                groupId.set([])
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteDispatchGroupSet
              disabled={lockFields}
              agencyId={agencyId.get() ?? undefined}
              dispatchGroupSetId={dispatchGroupSetId.get() ?? undefined}
              onGroupDispatchSetChange={(group) => {
                dispatchGroupSetId.set(group)
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteDispatchsGroups
              disabled={lockFields}
              agencyId={agencyId.get() ?? undefined}
              dispatcheGroups={groupId.get() ?? undefined}
              onGroupDispatchChange={(group) => {
                groupId.set(group)
                deviceId.set(null)
              }}
              groupSetId={dispatchGroupSetId?.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteCategories
              disabled={lockFields}
              categoriesId={categoryIds.get()}
              onCategoriesChange={(categories) => {
                categoryIds.set(categories)
                typeIds.set([])
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteTypes
              disabled={lockFields}
              categoryId={categoryIds.get() ?? undefined}
              typeId={typeIds.get()}
              onTypeChange={(type) => {
                typeIds.set(type)
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteSubtypesByTypes
              disabled={lockFields}
              subtypes={subtypeIds?.get() ?? []}
              typeId={typeIds.get()}
              onSubtypesChange={(subtype) => subtypeIds?.set(subtype)}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteCities
              disabled={lockFields}
              onCitiesChange={(city) => {
                cityIds.set(city)
              }}
              citiesId={cityIds.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <OutlinedInput
              fullWidth
              id="outlined-search"
              value={location?.get() ?? null}
              size="small"
              placeholder={translate('Location')}
              onChange={(event) => {
                location?.set(event.target.value)
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle clear search location"
                    onClick={() => {
                      location?.set('')
                    }}
                    disabled={location?.get()?.length ? false : true}
                    edge="end"
                  >
                    <Cancel fontSize="small" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteOperation
              disabled={lockFields}
              onOperationChange={(operation) => {
                operationId?.set(operation)
              }}
              operationId={operationId?.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteOperationUnit
              disabled={lockFields}
              onOperationUnitChange={(operationUnit) => {
                operationUnitId?.set(operationUnit)
                deviceId?.set(null)
              }}
              operationUnitId={operationUnitId?.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteDevice
              disabled={lockFields}
              onDeviceChange={(device) => {
                deviceId.set(device)
              }}
              deviceId={deviceId.get()}
              groupDispatchs={groupId.get() ?? undefined}
              operationUnitId={operationUnitId.get()}
            />
          </Grid>
          <FilterSelectPeriod
            disabled={lockFields}
            onStartDateChange={onStartDateChange}
            onFinishDateChange={onFinishDateChange}
            onPeriodChange={onPeriodChange}
            period={initialPeriod.get()}
            sxPeriod={{ width: '50%', pt: 1, pl: 1 }}
            xsPeriod={2}
            xsDateBetween={2}
          />
          <Grid item xs={2} sx={{ pt: 1, pl: 1 }}>
            <FilterAutocompleteMultipleStatus
              disabled={lockFields}
              onStatusChange={(status) => {
                statusIds?.set(status)
              }}
              statusId={statusIds.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pt: 1, pl: 1 }}>
            <FilterAutocompleteClosedStatusClass
              disabled={closedStatusClassLock}
              closedStatusClassId={closedStatusClassId?.get()}
              onClosedStatusClassChange={(closedStatusClass) => {
                closedStatusClassId?.set(closedStatusClass)
                closedStatusId?.set(null)
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pt: 1, pl: 1 }}>
            <FilterAutocompleteClosedStatus
              disabled={closedStatusClassLock}
              closedStatusClassId={closedStatusClassId?.get()}
              closedStatusId={closedStatusId?.get()}
              onClosedStatusIdChange={(closedStatus) => {
                closedStatusId?.set(closedStatus)
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pt: 1, pl: 1 }}>
            <FilterCheckboxQualifiers
              qualifiersSelected={qualifiersSelected}
              clearState={clearState?.get()}
            />
          </Grid>
          {systemConfig().displayConsummatedFilterInDashboardAndList.get() ===
          '1' ? (
            <Grid item xs={2} sx={{ pt: 1, pl: 1 }}>
              <SelectConsummatedAndAttempted
                disabled={lockFields}
                valueDefault={consummatedAndAttempted.get() ?? ''}
                onReportConsummatedAndAttempetdChange={(type) => {
                  consummatedAndAttempted.set(type)
                }}
                customOption={[
                  { index: 0, value: '0', label: 'Does not apply' },
                  { index: 1, value: '1', label: 'attempted' },
                  { index: 2, value: '2', label: 'consummated' },
                ]}
              />
            </Grid>
          ) : (
            false
          )}

          <Grid item xs={2} sx={{ pl: 2, pt: 1 }}>
            <FilterSwitchCoordination
              onCheckedChange={(checked) => {
                setCoordinationDates(checked)
              }}
              checked={!!currentCoordination.get()}
              onCoordinationChange={(coordination) => {
                currentCoordination.set(coordination)
              }}
            />
          </Grid>

          <Grid item xs={4} sx={{ pl: 2, pt: 1 }}>
            <FilterSwitchOtherDispatchsGroup
              onCheckedChange={() =>
                withSolvedThirdParty.set(!withSolvedThirdParty.value)
              }
              disabled={lockFields}
              checked={withSolvedThirdParty.get()}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            pt: 2,
            gap: 2,
          }}
        >
          <Button variant="outlined" onClick={clearFilters}>
            {translate('Clear Filters')}
          </Button>
          <LoadingButton
            loading={loading.get()}
            variant="contained"
            onClick={searchButton}
          >
            {translate('Search')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Collapse>
  )
}
