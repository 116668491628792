import { authFetch } from "~/services/fetch";

export default async function getVehiclesDispatch(dispatchId: string | number | null) {
  if (!dispatchId) return
  const response = await authFetch({
    url: `/dispatch/devices`,
    method: 'POST',
    data: {
      dispatch_id: parseInt(dispatchId.toString() ?? dispatchId),
    },
  })

  return response
}