import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IDispatchClosedStatus from '../interfaces/IDispatchClosedStatus';
import Layout from '~/components/Layout';



export default function Create() {     
  const {register, handleSubmit, control, setValue:setFormValue} = useForm<IDispatchClosedStatus>();
  const fields = [
      { name: 'name', type: 'string'},
      { name: 'acronym', type: 'string'},
      { name: 'available_for_mobile', type: 'boolean', defaultChecked: true},
      { name: 'required_qualification', type: 'boolean', defaultChecked: true},
      { name: 'can_reopen_dispatch', type: 'boolean', defaultChecked: true},
      { name: 'is_solved_by_third_party', type: 'boolean', defaultChecked: true},
      { name: 'closed_status_class_id', type: 'selectAutoComplete', model: 'closed-status-class', placeholder: 'Classe de status de fechamento'},

  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate model={'dispatch/closed-status'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        messageSuccess={'Dispatch closed status successfully created!'}  
        title={'Add Dispatch closed status'}  
        subheader={'Add new Dispatch closed status'}
        setFormValue={setFormValue}/>
      </Layout>
    )
}