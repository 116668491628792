import React, { useEffect } from 'react';
import ReactDOM from 'react-dom'
import { InsertDriveFile } from '@mui/icons-material';
import StandardModal from '~/components/StandardModal';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';
import { storeScreenSettings } from '~/features/System/services';
import SearchWeapon from '../SearchWeapon';

const modal = document.getElementById('div-modal') as HTMLElement;

export default function SearchWeaponModalDraggable() {
  const { translate } = useLang();
  const { windowManager, loadingButton } = useSystemState();

  const handleStop = (event, dragElement) => {
    event.stopPropagation();

    windowManager()['searchWeapon'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y
    })
  };

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'SearchWeaponModalDraggable',
        isOpen: windowManager()['searchWeapon'].open.get(),
        isMinimized: windowManager()['searchWeapon'].minimized.get(),
        positionX: windowManager()['searchWeapon'].positionX.get(),
        positionY: windowManager()['searchWeapon'].positionY.get()
      })
    }
  }, [
    windowManager()['searchWeapon'].open.get(),
    windowManager()['searchWeapon'].minimized.get(),
    windowManager()['searchWeapon'].positionY.get(),
    windowManager()['searchWeapon'].positionX.get()
  ])

  
 

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!loadingButton.get() &&
        <StandardModal
          title={`${translate("Search Weapons")}`}
          avatar={InsertDriveFile}
          dimensions={{
            width: 400
          }}
          isExpanded={windowManager()['searchWeapon'].minimized}
          handleStop={handleStop}
          position={{
            x: windowManager()['searchWeapon'].positionX?.get() ? Number(windowManager()['searchWeapon'].positionX?.get()) : 250,
            y: windowManager()['searchWeapon'].positionY?.get() ? Number(windowManager()['searchWeapon'].positionY?.get()) : 100
          }}
          closeButton={!(windowManager()['searchWeapon'].disabled.value) ? () => {
            windowManager()['searchWeapon'].open.set(false)
            windowManager()['searchWeapon'].dataSearchWeapon.set([])
          }: false}
        >
          <SearchWeapon />
        </StandardModal>
      }
    </>
    , modal);
}
