import { authFetch } from "~/services/fetch";
import IProcedure from "~/features/Procedure/interfaces/IProcedure";
import IProcedureConfig from "../interfaces/IProcedureConfig";
import { IUpdateProps } from "~/interfaces";

export async function getProcedure(): Promise<IProcedure[]> {
    const response = await authFetch({
        url: `/procedure`,
        method: 'GET'
    });
    return response.data as IProcedure[];
}


export async function getProcedureConfigById(id: IUpdateProps): Promise<IProcedureConfig> {
    const response = await authFetch({
        url: `/procedure/config/${id}`,
        method: 'GET'
    });
    return response.data as IProcedureConfig;
}