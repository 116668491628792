import React from 'react';
import Create from './pages/Create';
import Edit from './pages/Edit';
import Index from './pages/Index';
import ChangePassword from './pages/ChangePassword';
import { AppRouteProps } from '~/routes';

export default function UserRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    { path: '/user', component: Index, isProtected: true, permissions: ['user_index']},
    { path: '/user/create', component: Create, isProtected: true, permissions: ['user_create'] },
    { path: '/user/edit/:id', component: Edit, isProtected: true, permissions: ['user_edit'] },
    { path: '/user/change-password/connected/:login', component: ChangePassword, isProtected: true, permissions: ['user_password_edit'] },
    { path: '/user/change-password/me', component: ChangePassword, isProtected: true, permissions: [] },
  ];

  return routes;
}
