import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IContactCenter from '../interfaces/IContactCenter';

export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue,getValues} = useForm<IContactCenter>();
  const fields = [
      { name: 'id', type: 'hidden' },
      { name: 'name', type: 'string' },
  ];
  
    return(
      <CrudEdit model={'contact-center'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        setFormValue={setFormValue}
        messageSuccess={'Contact Center successfully updated!'}  
        title={'Edit Contact Center'} 
        getValues={getValues}
        />
    )
}
