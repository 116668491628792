/* eslint-disable max-lines-per-function */
import React from 'react';
import { authFetch } from '~/services/fetch';

export async function getDeviceByStatus(agencyId, deviceId, operationId, dispatchGroupId, categoryIds, typeIds, subtypeIds, startDate, endDate, payload, groupSetId, operationUnitId){
  try {
    const { data } = await authFetch({
      url: '/statistics/device-by-status',
      method: 'POST',
      data: {
        agencyId: agencyId,
        deviceId: deviceId, 
        operationId: operationId,
        dispatchGroupIds: dispatchGroupId,
        categoryIds: categoryIds,
        typeIds: typeIds,
        subtypeIds: subtypeIds,
        startDate: startDate,
        finishDate: endDate,
        excludeDisconnected: payload.excludeDisconnected,
        groupSetId: groupSetId,
        location: location,
        operationUnitId: operationUnitId,
      }
    })

    return data;
  } catch (error) {
    throw error
  }
}

export async function getDispatchByClosedStatus(
  agencyId, 
  deviceId, 
  operationId, 
  dispatchGroupId, 
  categoryIds, 
  typeIds, 
  subtypeIds, 
  startDate, 
  endDate, 
  payload,
  consummated,
  statusIds,
  cityIds,
  groupSetId,
  location,
  closedStatusClassId,
  closedStatusId,
  qualifierOptions,
  operationUnitId
  ){
    try {
      const { data } = await authFetch({
        url: '/statistics/dispatch-by-closed-status',
        method: 'POST',
        data: {
          agencyId: agencyId,
          deviceId: deviceId,
          operationId: operationId,
          dispatchGroupIds: dispatchGroupId,
          categoryIds: categoryIds,
          typeIds: typeIds,
          subtypeIds: subtypeIds,
          startDate: startDate,
          finishDate: endDate,
          excludeClosed: payload.exludeClosed,
          withSolvedThirdParty: payload.withSolvedThirdParty,
          consummated: consummated,
          statusIds: statusIds,
          cityIds,
          groupSetId: groupSetId,
          location: location,
          closedStatusClassId: closedStatusClassId,
          closedStatusId: closedStatusId,
          qualifierOptions: qualifierOptions,
          operationUnitId: operationUnitId
        }
      })
  
      return data;
    } catch (error) {
      throw error
    }
  }