import React from 'react';
import { AppRouteProps } from '~/routes';
import Index from './pages/Index';
import Create from './pages/Create';

export default function ProcedureConfigRoutes(): readonly AppRouteProps[] {
  const routes: readonly AppRouteProps[] = [
    {path: '/procedure/config', component: Index, isProtected: true, permissions: ['procedure_configs_index']},
    {path: '/procedure/config/create', component: Create, isProtected: true, permissions: ['procedure_configs_create']},

  ];

  return routes;
}