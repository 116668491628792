import React from 'react';
import { Grid } from '@mui/material';
import ChangePasswordForm from '~/features/User/components/ChangePasswordForm';
import Layout from '~/components/Layout';


export default function ChangePassword() {
  return (
    <Layout marginLayout>
      <Grid
        container
        height={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Grid item xs>
          <ChangePasswordForm />
        </Grid>
      </Grid>
    </Layout>
  );
}