/* eslint-disable max-lines-per-function */
import React from 'react'
import {
  createState,
  useState,
} from '@hookstate/core'
import { authFetch } from '~/services/fetch'
import { IDispacthRegistrationDrug, IDrugDTO } from '../interfaces/IDispacthRegistrationDrug'
import { toDomain, toFetch } from '~/mappers/registration-drug-mapper'

export const drugDefaultState: IDrugDTO = {
  occurrenceId: null,
  drugType: null,
  quantity: 0,
  unitMeasurement: null,
  notes: '',
  circunstance: null,
  destination: null,
}

const defaultRegistrationDrugProps: IDispacthRegistrationDrug = {
  drugs: [],
  drug: drugDefaultState
}

const registrationDispatchDrugState = createState<IDispacthRegistrationDrug>(defaultRegistrationDrugProps);

const useDispatchRegistrationDrugState = () => {
  const registrationDrugDispatch = useState(registrationDispatchDrugState);

  return {
    registrationDrugDispatch: () => registrationDrugDispatch,
    getDrugTypes: async () => {
      const targetUrl = '/drug-type'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;
    },
    getAllAssociatedDrug: async (occurrenceId: string | number | null) => {
      const targetUrl = '/occurrence/associated-drugs/all-from-occurrence'
      const response = await authFetch({
        url: targetUrl,
        data: {
          occurrence_id: occurrenceId,
        }
      });
      return response
        .data
        .map((drug): IDrugDTO => {
          return toDomain(drug)
        })
    },
    sendDrug: async (drug: IDrugDTO) => {
      const targetUrl = '/occurrence/associated-drugs/store'
      return await authFetch({
        url: targetUrl,
        data: toFetch(drug)
      });
    },
    editDrug: async ({ id, ...drug }: IDrugDTO) => {
      const targetUrl = `/occurrence/associated-drugs/${id}`
      await authFetch({
        url: targetUrl,
        method: 'PUT',
        data: toFetch(drug)
      });
    },
    deleteDrug: async (id: string) => {
      const targetUrl = `/occurrence/associated-drugs/${id}`
      await authFetch({
        url: targetUrl,
        method: 'DELETE',
      });
    },
  }
}

export default useDispatchRegistrationDrugState;