import React, { ReactNode } from "react";
import { Grid } from "@mui/material";
import { Content } from "../Content";
import { Theme } from "../Theme";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type LayoutProps = {
    readonly children: ReactNode;
    readonly marginLayout: true | false;
}

export function LayoutList({children, marginLayout}: LayoutProps) {
    return (
        <Theme>
            <Grid item sx={{ width: '100%', flex: 1 }}>
                <Content marginLayout={marginLayout}>
                    <ToastContainer
                        containerId="an id"
                        draggable={false} 
                    />
                    {children}
                </Content>
            </Grid>
        </Theme>
    )
}


