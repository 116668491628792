/* eslint-disable functional/prefer-readonly-type */
/* eslint-disable functional/immutable-data */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useRef } from 'react';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowModel, GridSortDirection, GridSortModel, GridSlots } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import { Button, Chip, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next'
import IListAllDispatch from './interfaces/IListAllDispatch';
import { authFetch } from '~/services/fetch';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import { elapsedTimeWithDate } from '~/utils/dateTime';
import { Star } from '@mui/icons-material';
import { createState, Downgraded, useState, useHookstate, none, State, StateMethods, StateMethodsDestroy } from '@hookstate/core';
import { Broadcasted } from '@hookstate/broadcasted'
import useSystemState from '~/features/System/stores/SystemState';
import { toSnakeCase } from '~/utils/strings';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { LatLng } from 'leaflet';
import { StatusType } from '~/contexts/SocketContext';
import Loading from '../Loading';
import { useListAllDispatchFilters } from '../../features/Dispatch/stores/DispatchDashboardFilterState';
import dayjs from 'dayjs';

type DispatchStatus = { dispatchId: number, status: StatusType };
type ListAllDispatchRow = {
  code: string,
  elapsed_time: string,
  highlight: number,
  id: number,
  location: string,
  notes: string,
  priority: number,
  status: string,
  statusId: number,
  subtype: string,
  type: string | null
}

type ListFilterTypes = { field: string, operator: string, value: any }

type GridRowModelDispatchRow = GridRowModel<ListAllDispatchRow>;

export type ListDispatchTab = 'pending' | 'dispatched' | 'done' | 'on-hold';

const rowsState = createState<GridRowModelDispatchRow[]>([]);
const rowCountState = createState(0);
const pageState = createState<any>(0);
const sortState = createState<GridSortModel>([{ field: 'dispatches.id', sort: 'desc' }]);
const loadingState = createState(false);
const refreshState = createState(false);
const tabState = createState<ListDispatchTab>('pending');

const rowsNoPaginationState = createState<GridRowModelDispatchRow[]>([]);
const rowCountNoPaginationState = createState(0);
const pageNoPaginationState = createState(0);
const sortNoPaginationState = createState<GridSortModel>([{ field: 'dispatches.id', sort: 'desc' }]);
const loadingNoPaginationState = createState(false);
const refreshNoPaginationState = createState(false);
const tabNoPaginationState = createState<ListDispatchTab>('pending');


// Attach Broadcast Plugin
// eslint-disable-next-line new-cap
rowsState.attach(Broadcasted('List-Dispatch-Rows'));
// eslint-disable-next-line new-cap
rowCountState.attach(Broadcasted('List-Dispatch-Rows-Count'));
// eslint-disable-next-line new-cap
pageState.attach(Broadcasted('List-Dispatch-Page'));
// eslint-disable-next-line new-cap
sortState.attach(Broadcasted('List-Dispatch-Sort'));
// eslint-disable-next-line new-cap
loadingState.attach(Broadcasted('List-Dispatch-Loading'));
// eslint-disable-next-line new-cap
tabState.attach(Broadcasted('List-Dispatch-Tab'));
// eslint-disable-next-line new-cap
refreshState.attach(Broadcasted('List-Dispatch-Reflesh'));

export interface IUseListAllDispathState {
  rows: State<ListAllDispatchRow[]>;
  rowCount: StateMethods<number>;
  page: StateMethods<any>;
  sort: State<GridSortModel>;
  loading: StateMethods<boolean>;
  tab: StateMethods<ListDispatchTab>;
  refresh: StateMethods<boolean> & StateMethodsDestroy;
  toggleRefresh: () => void,
  rowsOpt: {
    get: (dispatchId: number) => State<ListAllDispatchRow> | undefined,
    add: (rowData: ListAllDispatchRow) => void,
    delete: (row: State<ListAllDispatchRow>) => void,
    updateRowsByDispatchId: ({ dispatchId, status }: DispatchStatus) => void
  };
}

export const useListAllDispatchState = ({ paginated = true } = {}) => {
  const rows = useState(paginated ? rowsState : rowsNoPaginationState);
  const rowCount = useState(paginated ? rowCountState : rowCountNoPaginationState);
  const page = useState(paginated ? pageState : pageNoPaginationState);
  const sort = useState(paginated ? sortState : sortNoPaginationState);
  const loading = useState(paginated ? loadingState : loadingNoPaginationState);
  const tab = useState(paginated ? tabState : tabNoPaginationState);
  const refresh = useState(paginated ? refreshState : refreshNoPaginationState);

  rows.attach(Downgraded);
  page.attach(Downgraded);
  sort.attach(Downgraded);


  const deleteRow = (row: State<ListAllDispatchRow>) => {
    row.set(none);
  }
  const isValidRow = (tabParam: ListDispatchTab, statusId: number) => {

    if ((statusId !== 1) && (tabParam === 'pending')) {
      return false;
    }

    if ((statusId < 2 || statusId > 7) && tabParam === 'dispatched') {
      return false;
    }

    if ((statusId !== 8) && tabParam === 'done') {
      return false;
    }
    return true;
  }

  const checkForCreate = (rowStatusId: number) => {
    const currentTab = tab.get();

    if (currentTab && isValidRow(currentTab, rowStatusId)) return true;

    return false;
  }
  const handleCreate = (rowData: ListAllDispatchRow) => {
    const canCreate = checkForCreate(rowData.statusId);

    if (!canCreate) throw new Error('This row can not be added to this Tab');

    rows.merge([rowData]);

    return;
  }
  const toggleRefresh = () => {
    refresh.set(!refresh.get());
  }
  const getRow = (dispatchId: number) => {
    return rows.find((row) => { if (row.id.get() === dispatchId) return true; });
  }

  return ({
    rows,
    rowCount,
    page,
    sort,
    loading,
    tab,
    toggleRefresh,
    refresh: refreshState,
    rowsOpt: {
      get: getRow,
      add: handleCreate,
      delete: deleteRow,
      updateRowsByDispatchId: ({ dispatchId, status }: DispatchStatus) => {

        try {
          const newRowState = rows
            .get()
            .map((row) => row.id == dispatchId ? { ...row, status: status.name, statusId: status.id } : row);

          rows.set(newRowState);
          toggleRefresh();
        } catch (err) {
          console.error(err);
        }

      }
    }
  })
}

type ListAllDispatchParams = {
  listAllDispatchState: IUseListAllDispathState,
  targetUrl: string,
  hasPagination?: boolean,
}

export default function ListAllDispatch({ targetUrl, listAllDispatchState, hasPagination = true }: ListAllDispatchParams) {
  const translate = useTranslation().t;
  const { dispatch, syncDispatchFromId } = useDispatchState();
  const { rows, rowCount, page, sort, loading, tab, refresh, toggleRefresh } = listAllDispatchState;
  const listAllDispatchFilters = useListAllDispatchFilters();
  const { filtersOn, dateInitial, dateEnd, dispatchType, dispatchSubtype, dispatchAgencyId, dispatchGroupId } = listAllDispatchFilters;
  const limit = 5;
  const isOpenDialog = useHookstate(false);
  const { getStatusColors, getPriorityColors } = useSystemState();

  useEffect(() => {
    page.set(0);
  }, [targetUrl]);

  const setRow = ({ rowsCount, data, active }) => {
    if (active) {
      if (!data.length) {
        rows.set(data);
      } else {
        rows.set(data.map((row: IListAllDispatch) => {
          return ({
            id: row.id,
            code: row.code,
            // status: `[${row.status.acronym}] ${row.status.name}`,
            status: row.status.acronym,
            statusId: row.status.id,
            dispatch_group: row.dispatch_group ? `${row.dispatch_group.name}` : '-',
            type: row.type ? `[${row.type.code}] ${row.type.name}` : '-',
            subtype: row.subtype ? `[${row.subtype.code}] ${row.subtype.name}` : '-',
            location: row.occurrence.location,
            notes: row.hold ? row.hold?.notes : '-',
            priority: row.priority,
            elapsed_time: elapsedTimeWithDate(row.dispatched_at ? row.dispatched_at : new Date()),
            highlight: row.occurrence.featured,
          })
        }));
      }
    }
    rowCount.set(rowsCount);
  }

  const handleClickAction = (params: GridRenderCellParams<any, any, any>) => {
    const currentDispatchId = dispatch().id.get();
    const paramDispatchId: number | null = params.row.id ?? null;

    // if(currentDispatchId == paramDispatchId) {return;} // stop from searching a currentDispatch

    isOpenDialog.set(true);

    syncDispatchFromId(paramDispatchId)
      .then(({ ok, dispatch }) => {
        const { occurrence, id: dispatchResponseId, code, subtype, type, status, hold } = dispatch;
        const { latitude, longitude, location } = occurrence
      })
      .finally(() => isOpenDialog.set(false));
  }

  const tableMode = "server";
  const columns: GridColDef[] = ([
    { field: 'id' },
    {
      field: "code",
      headerName: translate('Occurrence Number'),
      flex: 0.7,
      renderCell: (params) => {
        return <Button type="submit" variant="contained" onClick={() => handleClickAction(params)}
          sx={{
            backgroundColor: getStatusColors(params.row.statusId).main,
            color: getStatusColors(params.row.statusId).contrastText,
            '&:hover': {
              backgroundColor: getStatusColors(params.row.statusId).dark,
              color: getStatusColors(params.row.statusId).contrastText,
            },
          }}>
          {params.value}
        </Button>

      },
    },
    { field: 'status', headerName: translate('Status'), flex: 0.4, sortable: false, },
    { field: 'dispatch_group', headerName: translate('Dispatch Group'), flex: 1, sortable: false, },
    { field: 'type', headerName: translate('Type'), flex: 1, sortable: false, },
    { field: 'subtype', headerName: translate('Subtype'), flex: 1, sortable: false, },
    { field: 'location', headerName: translate('Location'), flex: 1.5 },
    { field: 'notes', headerName: translate('Comment'), flex: 1.5, sortable: false },
    {
      field: 'priority', headerName: translate('Priority'), flex: 0.4, renderCell: (params) => {
        return <Chip sx={{ textAlign: 'center', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }} label={<>
          <Brightness1Icon sx={{ textAlign: 'center', alignItems: 'center', alignContent: 'center', justifyContent: 'center', color: getPriorityColors(params.row.priority).main }} />
          {params.row.priority}
        </>} />
      },
    },
    { field: 'elapsed_time', headerName: translate('Elapsed Time'), flex: 0.4, align: 'center', sortable: false },
    {
      field: 'highlight',
      headerName: translate('Highlight'),
      flex: 0.3,
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return (params.row.highlight ? <Star color="warning" /> : <></>);
      },
    }
  ]);



  // React.useEffect(() => {
  //   if (isFirstRender.current) return () => { isFirstRender.current = false };

  // }, [page.get(), sort.get()]);

  React.useEffect(() => {
    loading.set(true);

    let active = true;
    const currentTab = tab.get();
    const abort = new AbortController();

    const filter = filtersOn.attach(Downgraded).get();
    const startDate = dateInitial.attach(Downgraded).get();
    const finishDate = dateEnd.attach(Downgraded).get();
    const typeId = dispatchType.attach(Downgraded).get();
    const subtypeId = dispatchSubtype.attach(Downgraded).get();
    const agencyId = dispatchAgencyId.attach(Downgraded).get();
    const groupId = dispatchGroupId.attach(Downgraded).get();

    const filtersApply: ListFilterTypes[] = [];

    if ((startDate !== null) && (finishDate !== null)) {
      filtersApply.push({ field: "dispatches.dispatched_at", operator: "between", value: [dayjs(startDate).isValid() ? dayjs(startDate).format('YYYY-MM-DD HH:mm:ss') : null, dayjs(finishDate).isValid() ? dayjs(finishDate).format('YYYY-MM-DD HH:mm:ss') : null] });
    }
    if (typeId !== null) {
      filtersApply.push({ field: "dispatches.type_id", operator: "equals", value: typeId });
    }
    if (subtypeId !== null) {
      filtersApply.push({ field: "dispatches.subtype_id", operator: "equals", value: subtypeId });
    }
    if (agencyId !== null) {
      filtersApply.push({ field: "dispatches.agency_id", operator: "equals", value: agencyId });
    }
    if (groupId !== null) {
      filtersApply.push({ field: "dispatches.dispatch_group_id", operator: "isAnyOf", value: groupId });
    }

    authFetch({
      url: targetUrl,
      signal: abort.signal,
      data: {
        filters: filter ? filtersApply : [],
        orders: sort.get(),
        page: page.get(),
        limit: limit,
        without_pagination: !hasPagination,
      }
    })
      .then(({ data: { data, rowsCount: rowsCountContent } }) => {
        setRow({ rowsCount: rowsCountContent, active, data });

      }).finally(() => {
        setTimeout(() => loading.set(false), 250);
      });

    return () => {
      active = false;
      abort.abort();
    }
  }, [
    page.attach(Downgraded).value,
    sort.attach(Downgraded).value,
    refresh.get()
  ]);

  return (
    <>
      <DataGrid
        sx={{ minHeight: '250px' }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows.get()}
        columns={columns}
        columnVisibilityModel={{
          id: false,
          notes: targetUrl == '/dispatch/on-hold' ? false : true ,
        }}
        disableColumnMenu
        autoHeight
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: limit } },
        }}
        rowCount={rowCount.get()}
        paginationMode={tableMode}
        filterMode={tableMode}
        sortingMode={tableMode}
        loading={loading.get()}
        onSortModelChange={(onSortChange) => {
          const sorts: { field: string, sort: GridSortDirection }[] = [];

          onSortChange.map((order) => {
            sorts.push({
              field: toSnakeCase(order.field),
              sort: order.sort
            });
          });
          sort.set(sorts);
        }}
        onPaginationModelChange={(pageContent) => { page.set(pageContent.page); }}
        disableColumnSelector
        slots={{ loadingOverlay: LinearProgress as GridSlots['loadingOverlay'] }}
        slotProps={{
          row: {
            style: { cursor: 'pointer' },
          },
        }}
        getRowId={(row) => Number(row.id)}
      />

      <Loading visible={isOpenDialog.get()} />
    </>
  )
}
