import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useHookstate } from '@hookstate/core';
import DispatchClosedDialog from '~/features/Dispatch/components/DispatchClosedButton/components/DispatchClosedDialog';

interface DispatchClosedButtonProps {
  readonly canClose: boolean | undefined;
  readonly closeDispatch: (params: any) => Promise<void> | undefined;
}

export default function DispatchClosedButton(props: DispatchClosedButtonProps) {
  const open = useHookstate(false);

  return (
    <Grid item>
      <IconButton title={'Fechar Despacho'} disabled={!props.canClose} onClick={() => open.set(true)}>
        <Close />
      </IconButton>
      {open.get() && <DispatchClosedDialog open={open} closeDispatch={props.closeDispatch}/>}
    </Grid>);
}
