import { TextField, Divider, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import { useLang } from '~/hooks/useLang';
import InputMask from 'react-input-mask';
import { ChangeEvent } from 'react';
import { History } from '@mui/icons-material';
import { State, useHookstate, useState } from '@hookstate/core';
import { Validation as validation } from '@hookstate/validation';
import { getOnlyNumbersInString } from '~/utils/strings';
import useSystemState from '~/features/System/stores/SystemState';

type PhoneProps = {
  readonly readonly?: boolean;
  readonly statePhone: State<string | null>;
  readonly disabled: boolean;
  readonly onClickButton?: () => void;
  readonly disabledLastCalls?: boolean;
}

export default function PhoneTextField({readonly=false, statePhone, disabled, onClickButton = () => {}, disabledLastCalls = false }: PhoneProps) {
  const { translate } = useLang()
  const { windowManager } = useSystemState();
  const phone = useState(statePhone);
  const phoneValue = phone.get() == null ? '' : `${phone.get()}`;
  const maskCelPhone = '(99) 99999-9999';
  const maskPhone = '(99) 9999-9999';
  const mask = useHookstate(maskCelPhone)

  const defineMask = (number) => {
    if (number <= 10) {
      mask.set(maskPhone)
    } else {
      mask.set(maskCelPhone)
    }
  }

  React.useEffect(() => {
    if (phoneValue.length <= 9) {
      mask.set(maskCelPhone)
    }

    if (phoneValue.length >= 10) {
      windowManager()['EntryLogModal'].open.set(true)
    }
  }, [phoneValue])


  if (!readonly) {  
    return (
      <InputMask
        mask={mask.get()}
        value={phoneValue}
        onBlur={() => defineMask(phoneValue.length)}
        maskChar=""
        onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
          phone.set(getOnlyNumbersInString(changeEvent.target.value))
        }}
        disabled={disabled}
      >
        <TextField
          required={false}
          fullWidth
          size='small'
          id='telephone'
          label={translate('Telephone')}
          name='telephone'
          autoComplete='tel'
          placeholder={translate('Telephone')}
          disabled={disabled}
          InputProps={{
            endAdornment:
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {!disabledLastCalls ?
                  <>
                    <Divider sx={{ height: 28, margin: 0.5 }} orientation='vertical' />
                    <IconButton
                      onClick={() => onClickButton()}
                      color='success'
                      sx={{ padding: '10px' }}
                      aria-label='directions'
                      disabled={phoneValue.length >= 10 ? false : true}
                    >
                      <History />
                    </IconButton>
                  </>
                  : ''}
              </>
          }}
        />
      </InputMask>
    )
  } else {
    return (
      <Typography>{translate('Phone')}: {phoneValue ?? translate('No information')}</Typography>
    )
  }
}