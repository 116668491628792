/* eslint-disable max-lines-per-function */
import React from 'react';
import { DataGrid, GridColDef, GridRowModel, GridSortModel, GridActionsCellItem, GridRenderCellParams, GridEventListener, GridSlots } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import {
  Button, Menu, MenuItem,
  CircularProgress, Dialog, LinearProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import IListAllVehicle from './interfaces/IListAllVehicle';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { authFetch } from '~/services/fetch';
import useSystemState from '~/features/System/stores/SystemState';
import { useHookstate } from '@hookstate/core';
import DisplayDispatchVehicle from '~/components/DisplayDispatchVehicle'
import ConectDispatchVehicle from '~/components/ConectDispatchVehicle';
import { createState, Downgraded, useState } from '@hookstate/core';
import notify from '~/utils/notify';
import { toSnakeCase } from '~/utils/strings';
import OutOfServiceDispatchVehicle from '../OutOfServiceDispatchVehicle';
import ReturnToServiceDispatchVehicle from '../ReturnToServiceDispatchVehicle';
import { parseDataDispatch } from '~/features/Dispatch/utils/parsers';
import { LatLng } from 'leaflet';
import { Broadcasted } from '@hookstate/broadcasted';
import { useListAllDispatchFilters } from '../../features/Dispatch/stores/DispatchDashboardFilterState';
import ConfirmDialog from '../ConfirmDialog';

interface IListAllVehicles {
  readonly id: any,
  readonly identification: any,
  readonly statusId: any,
  readonly status: any,
  readonly dispatchGroup: any,
  readonly dispatchStatus: any,
  readonly dispatchId: any,
  readonly dispatch: any,
  readonly location: any,
}

type ListFilterTypes = { readonly field: string, readonly operator: string, readonly value: any }

export type ListVehicleTab = 'available' | 'dispatched' | 'disconnected' | 'not-in-service';

const rowsState = createState<ReadonlyArray<GridRowModel<IListAllVehicles>>>([]);
const rowCountState = createState(0);
const pageState = createState<any>(0);
const filtersState = createState<ReadonlyArray<any>>([]);
const sortState = createState<GridSortModel>([]);
const loadingState = createState(true);
const refreshState = createState(false);
const tabState = createState<ListVehicleTab>('available');

const rowsNoPaginationState = createState<ReadonlyArray<GridRowModel<IListAllVehicles>>>([]);
const rowCountNoPaginationState = createState(0);
const pageNoPaginationState = createState(0);
const filtersNoPaginationState = createState<ReadonlyArray<any>>([]);
const sortNoPaginationState = createState<GridSortModel>([]);
const loadingNoPaginationState = createState(true);
const refreshNoPaginationState = createState(false);
const tabStateNoPaginationState = createState<ListVehicleTab>('available');

// eslint-disable-next-line new-cap
refreshState.attach(Broadcasted('List-All-Vehicle-refresh'));
// eslint-disable-next-line new-cap
rowsState.attach(Broadcasted('List-All-Vehicle-rows'));

// eslint-disable-next-line new-cap
refreshNoPaginationState.attach(Broadcasted('List-All-Vehicle-refresh-no-pagination'));
// eslint-disable-next-line new-cap
rowsNoPaginationState.attach(Broadcasted('List-All-Vehicle-rows-no-pagination'));

export const useListAllVehicleState = () => {
  const rows = useState(rowsState);
  const rowCount = useState(rowCountState);
  const page = useState<any>(pageState);
  const filters = useState(filtersState);
  const sort = useState(sortState);
  const loading = useState(loadingState);
  const tab = useState(tabState);
  const refresh = useState(refreshState);


  rows.attach(Downgraded);

  const toggleRefresh = () => {
    refresh.set(!refresh.get());
  }

  return ({
    rows,
    rowCount,
    page,
    filters,
    sort,
    loading,
    refresh,
    tab,
    toggleRefresh,
    // update this func to have some useCases for adding,removing, updating row!
    updateListAllVehicle: ({ deviceId, dispatchStatus = undefined }) => {
      const target = rows.value.find((ele) => ele.id == deviceId);
      if (target) {
        toggleRefresh();
      }
    },
    updateListAllVehicles: () => {
      toggleRefresh()
    }
  })
}

export const useListAllVehicleNoPaginationState = () => {
  const rows = useState(rowsNoPaginationState);
  const rowCount = useState(rowCountNoPaginationState);
  const page = useState(pageNoPaginationState);
  const filters = useState(filtersNoPaginationState);
  const sort = useState(sortNoPaginationState);
  const loading = useState(loadingNoPaginationState);
  const tab = useState(tabStateNoPaginationState);
  const refresh = useState(refreshNoPaginationState);


  rows.attach(Downgraded);

  const toggleRefresh = () => {
    refresh.set(!refresh.get());
  }

  return ({
    rows,
    rowCount,
    page,
    filters,
    sort,
    loading,
    refresh,
    tab,
    toggleRefresh,
    // update this func to have some useCases for adding,removing, updating row!
    updateListAllNoPaginationVehicle: ({ deviceId, dispatchStatus = undefined }) => {
      const target = rows.value.find((ele) => ele.id == deviceId);
      if (target) {
        toggleRefresh();
      }
    },
    updateListAllNoPaginationVehicles: () => {
      toggleRefresh()
    }
  })
}

interface ListAllVehicleProps {
  readonly targetUrl: string
  readonly listAllVehicleState: any
  readonly exceptColumns?: readonly string[]
  readonly hasPagination?: boolean
}

export default function ListAllVehicle({ targetUrl, listAllVehicleState, exceptColumns = [], hasPagination = false }: ListAllVehicleProps) {
  const translate = useTranslation().t;
  const { getDispatchById, syncDispatchFromPayload, dispatch } = useDispatchState();
  const { getStatusColors, getDeviceStatusColors } = useSystemState();
  const { rows, rowCount, page, sort, loading, refresh, tab, filters } = listAllVehicleState;
  const listAllDispatchFilters = useListAllDispatchFilters();
  const { filtersOn, dateInitial, dateEnd, dispatchType, dispatchSubtype, dispatchAgencyId, dispatchGroupId } = listAllDispatchFilters;
  const limit = 15;

  const openConectDispatchVehicle = useHookstate(false);
  const identification = useHookstate('');
  const lockTemporaryTracking = useHookstate(false);
  const deviceId = useHookstate<number | null>(null);

  const plate = useHookstate<string | null>(null);
  const openCrewDialog = useHookstate(false);
  const openOutOfServiceDialog = useHookstate(false);
  const openReturnToServiceDialog = useHookstate(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const canConnectDevice = useHookstate(false);
  const canDisconnectDevice = useHookstate(false);
  const canSeeCrew = useHookstate(false);
  const canEditCrew = useHookstate(false);
  const canSetOutOfService = useHookstate(false);
  const canSetReturnToService = useHookstate(false);

  const isOpenDialog = useHookstate(false);
  const openDisconnectDialog = useHookstate(false);

  const labelCommander = useHookstate<string | null>(null);
  const labelDriver = useHookstate<string | null>(null);
  const labelPatrolmans = useHookstate<string | null>(null);


  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleCrewDialog = () => {
    const isOpen = openCrewDialog.get();
    openCrewDialog.set(!isOpen);
  }

  const handleCrewDialog = () => {
    toggleCrewDialog();
    handleClose();
  }

  const handleMenuActions = (row, event?: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget ?? null);
    identification.set(row.identification);
    lockTemporaryTracking.set(row.lockTemporaryTracking);
    deviceId.set(row.id);
    canEditCrew.set(row.statusId === 1);
    canSeeCrew.set(row.statusId !== 10);
    canConnectDevice.set((row.statusId == 10));
    canDisconnectDevice.set((row.statusId == 1))
    canSetOutOfService.set((row.statusId == 1));
    canSetReturnToService.set((row.statusId == 11));
    plate.set(row.plate);
    labelCommander.set(row?.deviceType?.label_commander ?? 'Comandante');
  };

  const handleDisconnectVehicle = () => {
    const deviceClosureId = deviceId.get();

    openDisconnectDialog.set(false);

    if (!deviceClosureId) return handleClose();

    authFetch({
      url: '/auth/logout/device/external',
      method: 'POST',
      data: {
        'device_id': deviceClosureId,
      }
    })
      .then(data => {
        notify({ message: translate('Device desconected Sucessfully!'), type: 'success' });
      })
      .catch(err => console.error(err))
      .finally(() => handleClose());
  };

  const handleReturnToServiceVehicle = () => {
    const deviceReturnToServiceId = deviceId.get();
    if (!deviceReturnToServiceId) return handleClose();

    authFetch({
      url: '/device/back-to-service',
      method: 'POST',
      data: {
        'device_id': deviceReturnToServiceId,
      }
    })
      .then(data => {
        notify({ message: translate('Device returned to service sucessfully!'), type: 'success' });
      })
      .catch(err => console.error(err))
      .finally(() => {
        openReturnToServiceDialog.set(false);
        handleClose();
      });
  };

  const handleImportDispatch = (params: GridRenderCellParams<any, any, any>) => {
    isOpenDialog.set(true);

    getDispatchById(params.row.dispatchId)
      .then((dispatchData) => {
        const dispatchParsed = parseDataDispatch(dispatchData);
        const { occurrence, id, code, type, subtype, status } = dispatchParsed;
        const { latitude, longitude, location } = occurrence;

        if (!id) {
          throw new Error('Trying to use a dispatch without Id');
        }

        syncDispatchFromPayload({
          dispatchParams: { dispatch: dispatchParsed },
          canCreateDispatch: true,
          canSaveDispatch: true
        })
          .then(() => {
            isOpenDialog.set(false);
          })
          .catch(err => console.error(err));
      })
      .catch(err => console.error(err));
  };

  const open = Boolean(anchorEl);
  const id = open ? 'menu-appbar' : '';

  const tableMode = 'server';
  const columns: GridColDef[] = ([
    { field: 'id' },
    { field: 'name', headerName: translate('Identification'), flex: 1, renderCell: (params) => params.row.identification },
    { field: 'task', headerName: translate('Task'), flex: 1.5, sortable: false },
    { field: 'dispatchGroupId', headerName: translate('Dispatch Group'), flex: 1, renderCell: (params) => params.row.dispatchGroup },
    {
      field: 'deviceStatusId',
      headerName: translate('Status'),
      flex: 1,
      renderCell: (params) => (
        <Button type='submit' variant='contained'
          sx={{
            backgroundColor: getDeviceStatusColors(params.row.statusId).main,
            color: getDeviceStatusColors(params.row.statusId).contrastText,
            '&:hover': {
              backgroundColor: getDeviceStatusColors(params.row.statusId).dark,
              color: getDeviceStatusColors(params.row.statusId).contrastText,
            },
          }}>
          {params.row.status}
        </Button>
      )
    },
    { field: 'OutOfServiceMotive', headerName: translate('Out Of Service Reason'), flex: 1, renderCell: (params) => params.row.outOfServiceReason },
    { field: 'statusId', headerName: translate('Status ID'), flex: 1, renderCell: (params) => params.row.statusId },
    { field: 'dispatchId', headerName: translate('Dispatch'), flex: 1 },
    { field: 'dispatchStatus', headerName: translate('Dispatch Status'), flex: 1 },
    {
      field: 'dispatch',
      headerName: translate('Dispatch'),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row.dispatchId) {
          return (
            <Button type='submit' variant='contained' onClick={() => handleImportDispatch(params)}
              sx={{
                backgroundColor: getStatusColors(params.row.dispatchStatus).main,
                color: getStatusColors(params.row.dispatchStatus).contrastText,
                '&:hover': {
                  backgroundColor: getStatusColors(params.row.dispatchStatus).dark,
                  color: getStatusColors(params.row.dispatchStatus).contrastText,
                },
              }}>
              {params.value}
            </Button>
          )
        }

        return '-';
      },
    },
    { field: 'location', headerName: translate('Location'), flex: 1.5, sortable: false },

    { field: 'comment', headerName: translate('Comment'), flex: 1, sortable: false },
    {
      field: 'actions', type: 'actions', flex: 0.2, getActions: ({ id, row }) => [
        <GridActionsCellItem
          key={id}
          icon={<MoreVertIcon />}
          label='export'
          onClick={(event) => handleMenuActions(row, event)}
        />,
      ]
    },
  ]);

  React.useEffect(() => {
    loading.set(true);

    const filter = filtersOn.attach(Downgraded).get();
    const startDate = dateInitial.attach(Downgraded).get();
    const finishDate = dateEnd.attach(Downgraded).get();
    const typeId = dispatchType.attach(Downgraded).get();
    const subtypeId = dispatchSubtype.attach(Downgraded).get();
    const agencyId = dispatchAgencyId.attach(Downgraded).get();
    const groupId = dispatchGroupId.attach(Downgraded).get();

    // eslint-disable-next-line functional/prefer-readonly-type
    const filtersApply: ListFilterTypes[] = [];
    if (agencyId !== null) {
      filtersApply.push({ field: "devices.agency_id", operator: "equals", value: agencyId });
    }
    if (filters.get().length > 0) {
      filtersApply.push(filters.get()[0])
    }

    authFetch({
      url: targetUrl,
      data: {
        filters: filter || filtersApply ? filtersApply : [],
        orders: sort.get(),
        page: page.get(),
        limit: limit,
        without_pagination: hasPagination,
      }
    }).then(({ data: { data, rowsCount: rowsCountContent } }) => {
      loading.set(false);

      if (rowsCountContent > 0) {

        rows.set(data.map((row: IListAllVehicle) => {
          const formatedRow = {
            id: row.id,
            lockTemporaryTracking: row.lock_temporary_tracking,
            identification: row.name ? row.name : (row.vehicle ? (row.vehicle.identification ? row.vehicle.identification : '-') : '-'),
            statusId: row.status ? row.status.id : '-',
            status: row.status ? row.status.name : '-',
            dispatchGroupId: row.dispatch_group ? row.dispatch_group.acronym : '-',
            dispatchStatus: row.currentDispatch != null ? row.currentDispatch.dispatch.status.id : '',
            dispatchId: row.currentDispatch != null ? row.currentDispatch.dispatch.id : '',
            dispatch: row.currentDispatch != null ? row.currentDispatch.dispatch.code : '',
            location: row.currentDispatch != null ? row.currentDispatch.dispatch.occurrence.location : '',
            plate: row.vehicle && row.vehicle.plate != null ? row.vehicle.plate : '',
            outOfServiceReason: row.out_of_service?.reason.name != null ? row.out_of_service.reason.name : '',
            task: row.device_log?.task != null ? row.device_log?.task : '',
            comment: row.out_of_service?.comment ? row.out_of_service?.comment : '',
          }
          return formatedRow;
        }));
      } else {
        rows.set([]);
      }
      rowCount.set(rowsCountContent)
    });
  }, [
    page.attach(Downgraded).value,
    sort.attach(Downgraded).value,
    openConectDispatchVehicle.get(),
    openOutOfServiceDialog.get(),
    refresh.get(),
  ]);

  React.useEffect(() => {
    rows.set([]);
    page.set(0);
  }, [tab.get()]);

  React.useEffect(() => {
    handleClose();
  }, [openConectDispatchVehicle.get(), openOutOfServiceDialog.get()]);


  const filterColumns = () => {
    const newColumn: GridColDef[] = []
    columns.map((value) => {
      if (!exceptColumns.includes(value.field)) {
        newColumn.push(value)
      }
    })
    return newColumn;
  }

  const handleDoubleClick: GridEventListener<'cellDoubleClick'> = (
    params,
  ) => {
    if (params.row.statusId !== 1) {
      return
    }
    handleMenuActions(params)
    handleCrewDialog()
  }

  const handleClickOpenDisconnectDialog = () => {
    openDisconnectDialog.set(true);
  }

  const clickOutDisconnectModalClose = () => {
    openDisconnectDialog.set(false);
  }

  return (
    <>
      <DataGrid
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows.get()}
        columns={filterColumns()}
        columnVisibilityModel={{
          id: false,
          OutOfServiceMotive: targetUrl == '/device/not-in-service' ? false : true,
          statusId: false,
          dispatchId: false,
          dispatchStatus: false,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: hasPagination ? 0 : limit } },
        }}
        disableColumnMenu
        autoHeight
        pagination
        pageSizeOptions={[limit]}
        rowCount={rowCount.get()}
        paginationMode={tableMode}
        filterMode={tableMode}
        sortingMode={tableMode}
        loading={loading.get()}
        disableRowSelectionOnClick
        onCellDoubleClick={handleDoubleClick}
        onSortModelChange={(onSortChange) => {
          sort.set([])
          onSortChange.map((order) => {
            sort.merge([{
              field: toSnakeCase(order.field),
              sort: order.sort
            }])
          })
        }}
        onPaginationModelChange={(pageContent) => page.set(pageContent.page)}
        disableColumnSelector={true}
        slots={{ loadingOverlay: LinearProgress as GridSlots['loadingOverlay'] }}
        slotProps={{
          row: {
            style: { cursor: 'pointer' },
          }
        }}
        sx={{
          '& .danger': { background: '#E0473D' },
          '& .danger: hover': { background: '#C73F36' },
        }}
        getRowId={(row) => Number(row.id)}
        getRowClassName={(params) => (params.row.priority >= 8) ? `danger` : ''}
      />
      <Menu
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        sx={{
          marginTop: '30px'
        }}
      >
        {canConnectDevice.get() && <MenuItem onClick={() => openConectDispatchVehicle.set(true)}>
          {translate('Conectar')}
        </MenuItem>}
        {canSeeCrew.get() && <MenuItem onClick={handleCrewDialog}>
          {translate('See Crew')}
        </MenuItem>}
        {canSetOutOfService.get() && <MenuItem onClick={() => openOutOfServiceDialog.set(true)}>
          {translate('Out of service')}
        </MenuItem>}
        {!canConnectDevice.get() && canDisconnectDevice.get() && <MenuItem onClick={() => handleClickOpenDisconnectDialog()}>
          {translate('Disconnect')}
        </MenuItem>}
        {canSetReturnToService.get() && <MenuItem onClick={() => openReturnToServiceDialog.set(true)}>
          {translate('Return to service')}
        </MenuItem>}
      </Menu>
      <ConectDispatchVehicle lockTemporaryTracking={lockTemporaryTracking.get()} openState={openConectDispatchVehicle} identification={identification.get()} deviceId={deviceId} plate={plate.get()} />
      <DisplayDispatchVehicle lockTemporaryTracking={lockTemporaryTracking.get()} deviceId={deviceId} plate={plate.get()} open={openCrewDialog} deviceCode={identification} canEditCrew={canEditCrew.get()} />
      <OutOfServiceDispatchVehicle openState={openOutOfServiceDialog} identification={identification.get()} deviceId={deviceId.get()} />
      <ReturnToServiceDispatchVehicle openState={openReturnToServiceDialog} handleAgree={handleReturnToServiceVehicle} handleClose={() => { openReturnToServiceDialog.set(false); handleClose(); }} />

      <Dialog
        open={isOpenDialog.get()}
        PaperProps={{
          sx: { background: 'transparent', display: 'flex', alignItems: 'center', overflow: 'visible' }
        }}
      >
        {isOpenDialog.get() ? <CircularProgress disableShrink size={40} /> : null}
      </Dialog>

      <ConfirmDialog
        open={openDisconnectDialog}
        title={translate('Disconnect') + ' ' + translate('Vehicle')}
        content={`${translate('Do you want to disconnect the vehicle')}: ${identification.get()}?`}
        handleClose={clickOutDisconnectModalClose}
        handleAgree={handleDisconnectVehicle}
      />
    </>
  )
}
