import React from 'react';
import { IconButton, Grid } from '@mui/material';
import { DirectionsCar } from '@mui/icons-material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import AddVehicleDialog from './components/AddVehicleDialog';

export default function AddVehicleButton({ canAddVehicles }) {
  const { translate } = useLang();
  const open = useHookstate(false);

  return (
    <Grid item>
      <IconButton
        title={translate('Add Vehicle')}
        disabled={!canAddVehicles}
        onClick={() => open.set(true)}
      >
        <DirectionsCar />
      </IconButton>
      {open.get() && <AddVehicleDialog open={open} />}
    </Grid>);
}
