import React from 'react';
import { createRoot } from 'react-dom/client';
import '@hookstate/devtools';
import './i18n';
import App from './App';
// import { TestComponent } from './test';
import './globalScroll.css';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render( <App /> );