import React, { useEffect, useState } from 'react';
import Layout from '~/components/Layout';
import Map from '~/features/Map/components/Map';
import AreaPlans from '~/features/Map/components/AreaPlans';
import useSystemState from '~/features/System/stores/SystemState';
import { useHookstate } from '@hookstate/core';
import MapMarkersEvents from '~/features/Map/components/MapMarkers/MapMarkersEvents';
import useUserState from '~/features/User/stores/UserState';
import { useLang } from '~/hooks/useLang';

export default function ManagementMap() {
  const { windowDefault, getDeviceStatusColors, windowIsNotVisible, windowIsVisible, windowManager, markersViewVisible, markersViewDefault } = useSystemState();
  const { translate } = useLang();
  const { verifyPermission } = useUserState();
  const viewGroups = useHookstate<string[]>(['interestPoint', 'device', 'dispatch' ]);

  useEffect(() => {
    document.title = translate('Dashboard Map - Dispatch System')
  }, [])



  useEffect(() => {
    verifyPermission(['camera_index']) ? viewGroups.merge(['camera', 'areas']) : false;
    windowDefault([]);
    windowIsVisible(['mapSetting', 'searchStaff'])
    markersViewVisible(['device']);
    markersViewDefault(['device']);
  }, []);



  return (
    <Layout mapViewPort marginLayout={false} draggableComponents={true}>
      <Map>
          <MapMarkersEvents viewGroups={viewGroups.get()} checkedViewGroups={['device', 'dispatch' ]} viewGroupsInCluster={['device']} disableZoomLimit={['device']}/>
      </Map>
    </Layout>
  );
}