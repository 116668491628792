import { Downgraded, createState, useHookstate, useState } from '@hookstate/core';
import { CheckCircleOutline, HighlightOff, PinDrop, Visibility } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Container, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams, GridRowsProp, GridSortModel } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import ConfirmDialog from '~/components/ConfirmDialog';
import InternalContent from '~/components/Layout/components/InternalContent';
import useUserState from '~/features/User/stores/UserState';
import { useLang } from '~/hooks/useLang';
import notify from '~/utils/notify';
import { InterestProps, mediasProps } from '../../interfaces';
import { forceExpire, getAllInterestPoint, getInfoInterestPoint, validateInterestPoint } from '../../services';
import ImageInterestPoint from '../ImageInterestPoint';
import PointsInterestFilters from '../PointsInterestFilters';

const sortState = createState<GridSortModel>([{ field: 'created_at', sort: 'desc' }]);


export const useListAllDispatchState = () => {
  const sort = useState(sortState);
  return ({
    sort,
  })
}

// eslint-disable-next-line max-lines-per-function
export default function ListAllPointInterest() {
  const { translate } = useLang();
  const { verifyPermission } = useUserState();
  const loadingButton = useHookstate<boolean>(false);
  const refreshTable = useHookstate<boolean>(false);
  const expandFilterSearch = useHookstate<boolean>(true);
  const nameOrObservations = useHookstate<string | null>(null);
  const agencyId = useHookstate<number | null>(null);
  const isValidated = useHookstate<boolean | null>(null);
  const isExpired = useHookstate<boolean>(false);
  const isReviewed = useHookstate<boolean>(false);
  const validatedIn = useHookstate<string | null>(null);
  const deletedIn = useHookstate<string | null>(null);
  const typeInterestPointId = useHookstate<number | null>(null);
  const subtypeInterestPointId = useHookstate<number | null>(null);
  const labelButton = useHookstate(translate('Search'));
  const initialPeriodValidated = useHookstate<string>('');
  const initialPeriodExpired = useHookstate<string>('');
  const onlyValidated = useHookstate<boolean | null>(false);
  const notValidated = useHookstate<boolean | null>(false);
  const openModal = useHookstate<boolean>(false)
  const openDialog = useHookstate(false);
  const openDialogValidation = useHookstate(false);
  const [startDateReview, setStartDateReview] = React.useState<string | null>(null);
  const [finishDateReview, setFinishDateReview] = React.useState<string | null>(null);
  const [startDateExpired, setStartDateExpired] = React.useState<string | null>(null);
  const [finishDateExpired, setFinishDateExpired] = React.useState<string | null>(null);
  const InterestPointShowInfo = useHookstate<InterestProps | null>(null)
  const tableMode = "server";
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [rowCount, setRowCount] = React.useState(0);
  const limit = 10;
  const rowSelectedId = useHookstate(null);
  const rowIsDeactivated = useHookstate<boolean | null>(null)
  const id = open ? 'menu-appbar' : '';
  const showImagens = useHookstate(false)
  const mediasInterestPoint = useHookstate<mediasProps[] | []>([])
  const { sort } = useListAllDispatchState();
  // const [orders, setOrders] = React.useState<GridSortModel>([{ field: 'created_at', sort: 'desc' }]);

  const reloadTable = () => {
    refreshTable.set(!refreshTable.get())
  }


  const columns: GridColDef[] = ([
    { field: 'id' },
    { field: 'deleted_at' },
    { field: 'name', headerName: translate('Name'), flex: 1 },
    { field: 'interest_point_type:name', headerName: translate('Interest point type'), flex: 1 },
    { field: 'interest_point_subtype:name', headerName: translate('Interest point subtype'), flex: 1 },
    { field: 'agency:name', headerName: translate('Agency:name'), flex: 1 },
    {
      field: 'status', headerName: translate("Status"), flex: 1, sortable: false, renderCell: (params) => {
        return dayjs(new Date()).isAfter(params.row.expiration_date) ? 'Expirado'
          : dayjs(new Date()).isAfter(params.row.revision_date) ? 'Revisar'
            : params.row.validated_at != null && dayjs(new Date()).isBefore(params.row.revision_date) ? 'Validado'
              : 'Pendente Validação'
      }
    },
    {
      field: 'created_at', headerName: 'Data de criação', type: 'date', flex: 1.2,
      valueFormatter: (value) => {
        return dayjs(value as string).format('DD/MM/YYYY HH:mm');
      },
    },
    {
      field: 'validated_at', headerName: translate('Validated date'), type: 'date', flex: 1.2,
      valueFormatter: (value) => {
        return value != null ? dayjs(value as string).format('DD/MM/YYYY HH:mm') : '';
      },
    },
    {
      field: 'revision_date', headerName: translate('Revision date'), type: 'date', flex: 1.2,
      valueFormatter: (value) => {
        return value != null ? dayjs(value as string).format('DD/MM/YYYY HH:mm') : '';
      },
    },
    {
      field: 'expiration_date', headerName: translate('Expiration date'), type: 'date', flex: 1.2,
      valueFormatter: (value) => {
        return value != null ? dayjs(value as string).format('DD/MM/YYYY HH:mm') : '';
      },
    },

    {
      field: 'actions', type: 'actions', flex: 1, getActions: (params) => [
        <GridActionsCellItem
          key={params.id}
          icon={<CheckCircleOutline />}
          label={translate('Validar')}
          title={translate('Validar')}
          disabled={params.row.validated_at != null && dayjs(new Date()).isBefore(params.row.revision_date)}
          onClick={(event) => handleDialogOpenValidation(event, params)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<Visibility />}
          label={translate('View')}
          title={translate('View')}
          onClick={(event) => getInterestPointMedia(params)}
        />,
        <GridActionsCellItem
          key={params.id}
          icon={<HighlightOff />}
          label={translate('Force Expire')}
          title={translate('Force Expire')}
          onClick={(event) => handleDialogOpen(event, params)}
        />,
      ]
    },
  ])

  const request = React.useCallback(async () => {
    try {
      const interestPointData: any = await getAllInterestPoint({
        notes: nameOrObservations.get(),
        agencyId: agencyId.get(),
        interestPointTypeId: typeInterestPointId.get(),
        interestPointSubtypeId: subtypeInterestPointId.get(),
        expired: isExpired.get(),
        needReview: isReviewed.get(),
        notValidated: notValidated.get(),
        onlyValidated: onlyValidated.get(),
        reviewStartDate: startDateReview,
        reviewFinishDate: finishDateReview,
        expiredStartDate: startDateExpired,
        expiredFinishDate: finishDateExpired,
        orders: sort.get(),
      })

      setRows(interestPointData.data.map((element) => {
        element['interest_point_type:name'] = element.interest_point_type.name
        element['interest_point_subtype:name'] = element.interest_point_subtype?.name
        element['agency:name'] = element.agency?.name

        return element
      }))
      setRowCount(interestPointData.rowsCount);

    } catch (error) {
      loadingButton.set(false);
      console.error(error);
    }
  }, [sort.attach(Downgraded).value, refreshTable.get()])

  const submit = async () => {
    loadingButton.set(true)

    const interestPointData: any = await getAllInterestPoint({
      notes: nameOrObservations.get(),
      agencyId: agencyId.get(),
      interestPointTypeId: typeInterestPointId.get(),
      interestPointSubtypeId: subtypeInterestPointId.get(),
      expired: isExpired.get(),
      needReview: isReviewed.get(),
      notValidated: notValidated.get(),
      onlyValidated: onlyValidated.get(),
      reviewStartDate: startDateReview,
      reviewFinishDate: finishDateReview,
      expiredStartDate: startDateExpired,
      expiredFinishDate: finishDateExpired,
      orders: sort.get(),
    })
      .finally(() => {
        loadingButton.set(false)
      })

    setRows(interestPointData.data.map((element) => {
      element['interest_point_type:name'] = element.interest_point_type.name
      element['interest_point_subtype:name'] = element.interest_point_subtype?.name
      element['agency:name'] = element.agency?.name

      return element;
    }))

    setRowCount(interestPointData.rowsCount);
  }

  const clearFilters = () => {
    nameOrObservations.set(null)
    agencyId.set(null)
    typeInterestPointId.set(null)
    subtypeInterestPointId.set(null)
    isExpired.set(false)
    isReviewed.set(false)
    notValidated.set(null)
    onlyValidated.set(null)
    setStartDateReview(null)
    setFinishDateReview(null)
    setStartDateExpired(null)
    setFinishDateExpired(null)
    initialPeriodValidated.set('')
    initialPeriodExpired.set('')
  }

  useEffect(() => {
    request()
  }, [refreshTable.get(), sort.attach(Downgraded).value])

  const handleOpenModal = () => {
    openModal.set(true);
  };

  const handleCloseModal = () => {
    openModal.set(false)
  };

  const getInterestPointMedia = (params: GridRowParams) => {
    handleOpenModal()
    getInfoInterestPoint({ id: params.row.id }).then((response) => {
      InterestPointShowInfo.set(response)
      const media = InterestPointShowInfo.get()?.medias.map((item) => item).filter((item) => item.media_path != null)

      if (media && media.length > 0) {
        mediasInterestPoint.set(media)
        showImagens.set(true)
      }else{
        showImagens.set(false)
      }
    
  })}

  const handleDialogValidation = () => {
    validateInterestPoint({ id: rowSelectedId.get() }).then((response) => {
      if (response.status === 200) {
        notify({
          message: translate('Point of Interest successfully validated!'),
          type: 'success'
        })
        handleDialogCloseValidation()
        reloadTable()
      }
    })
  }

  const handleDialogClose = () => {
    openDialog.set(false)
  };

  const handleDialogOpen = (event: React.MouseEvent<HTMLElement>, params: GridRowParams) => {
    rowSelectedId.set(params.row.id);
    rowIsDeactivated.set(params.row.deleted_at)
    openDialog.set(true)
  };

  const handleDialogCloseValidation = () => {
    openDialogValidation.set(false)
  };

  const handleDialogOpenValidation = (event: React.MouseEvent<HTMLElement>, params: GridRowParams) => {
    rowSelectedId.set(params.row.id);
    rowIsDeactivated.set(params.row.deleted_at)
    openDialogValidation.set(true)
  };


  const handleForceExpiration = () => {
    forceExpire({ id: rowSelectedId.get() }).then((response) => {
      if (response.status === 200) {
        notify({
          message: translate('Point of Interest successfully deactivated!'),
          type: 'success'
        })
        reloadTable()
      }
    })
    handleDialogClose()
  }

  const clickOutModalClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      openDialog.set(false)
    }
  };


  return (
    <>
      <InternalContent title={`${translate('Filters')}`} sxFont={{fontWeight: 'bold', fontSize: 18}}>
        <PointsInterestFilters
          loadingButton={loadingButton}
          labelButton={labelButton}
          hidden={expandFilterSearch.get()}
          nameOrObservations={nameOrObservations}
          agencyId={agencyId}
          isValidated={isValidated}
          isExpired={isExpired}
          isReviewed={isReviewed}
          validatedIn={validatedIn}
          deletedIn={deletedIn}
          typeInterestPointId={typeInterestPointId}
          subtypeInterestPointId={subtypeInterestPointId}
          initialPeriodValidated={initialPeriodValidated}
          initialPeriodExpired={initialPeriodExpired}
          notValidated={notValidated}
          onlyValidated={onlyValidated}
          clearFilters={() => {
            clearFilters()
            submit()
          }}
          searchButton={() => {
            submit()
          }}

          onStartDateChangeValidated={(value) => {
            labelButton.set(translate('Search'))
            setStartDateReview(value)
          }}
          onFinishDateChangeValidated={(value) => {
            labelButton.set(translate('Search'))
            setFinishDateReview(value)
          }}
          onPeriodChangeValidated={(value) => {
            labelButton.set(translate('Search'))
            initialPeriodValidated.set(value)
          }}

          onStartDateChangeExpired={(value) => {
            labelButton.set(translate('Search'))
            setStartDateExpired(value)
          }}
          onFinishDateChangeExpired={(value) => {
            labelButton.set(translate('Search'))
            setFinishDateExpired(value)
          }}
          onPeriodChangeExpired={(value) => {
            labelButton.set(translate('Search'))
            initialPeriodExpired.set(value)
          }}
        />
      </InternalContent>

      <InternalContent title={`${translate('Points of interest')}`} sxFont={{fontWeight: 'bold', fontSize: 18}} >
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          columnVisibilityModel={{
            id: false,
            deleted_at: false,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: limit } },
        }}
          disableColumnMenu
          autoHeight
          pagination
          filterMode={tableMode}
          sortingMode={tableMode}
          rowCount={rowCount}
          getRowId={(row) => Number(row.id)}
          onSortModelChange={(onSortChange) => {
            sort.set([])
            onSortChange.map((order) => {
              sort.merge([{
                field: order.field,
                sort: order.sort
              }])
            })
          }}
        />

      </InternalContent>
      <Dialog
        fullWidth
        maxWidth={'md'}
        open={openModal.get()}
        onClose={clickOutModalClose}
        disableEscapeKeyDown={true}
      >
        <DialogTitle display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          {translate('Interest point')}
          <IconButton
            aria-label="close"
            onClick={() => handleCloseModal()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflow: 'hidden' }}>
          <DialogContentText>
            {translate('Visualização do Ponto de Interesse')}
          </DialogContentText>

          <Grid item marginBottom={2}>

            <Paper elevation={2}>
              {showImagens.get() ?
                <Carousel
                  NextIcon={<KeyboardArrowRightIcon />}
                  PrevIcon={<KeyboardArrowLeftIcon />}
                  height={'300px'}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                  autoPlay={false}
                  animation={'slide'}
                  navButtonsAlwaysVisible={true}
                  duration={600}
                >
                  {mediasInterestPoint.get().map((element) => (
                    element.media_path ? <ImageInterestPoint key={element.id} images={JSON.parse(JSON.stringify(element))} /> : false
                  ))}
                </Carousel>
                : <Grid
                  item
                  xs={12}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  padding={2}
                  width={'100%'}
                  height={'280px'}
                >
                  <Typography>
                    {translate('Images not registered')}
                  </Typography>
                </Grid>
              }
            </Paper>
          </Grid>
          <Container fixed={false} >
            <Grid container xs={12} sx={{ marginBottom: 2, marginLeft: 0, paddingLeft: 0 }}>
              <Grid container xs={12} paddingBottom={1}>
                <Grid item xs={3}>
                  <Typography variant='subtitle1'><b>{translate('Name')}:</b></Typography>
                  <Typography variant='subtitle2'>{InterestPointShowInfo.get()?.name}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant='subtitle1'><b>{translate('Is criminal')}:</b></Typography>
                  <Typography variant='subtitle2'>{InterestPointShowInfo.get()?.interest_point_type.is_criminal == true ? translate('Yes') : translate('No')}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant='subtitle1'><b>{translate('Reported by')}:</b></Typography>
                  <Typography variant='subtitle2'>
                    {InterestPointShowInfo.get()?.information_user?.representation_name}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container xs={12} paddingBottom={1}>
                <Grid item xs={3}>
                  <Typography variant='subtitle1'><b>{translate('Agency:name')}:</b></Typography>
                  <Typography variant='subtitle2'>{InterestPointShowInfo.get()?.agency?.name}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant='subtitle1'><b>{translate('Area')}:</b></Typography>
                  <Typography variant='subtitle2'>{InterestPointShowInfo.get()?.area?.name}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant='subtitle1'><b>{translate('Type')}:</b></Typography>
                  <Typography variant='subtitle2'>{InterestPointShowInfo.get()?.interest_point_type.name}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant='subtitle1'><b>{translate('Subtype')}:</b></Typography>
                  <Typography variant='subtitle2'>{InterestPointShowInfo.get()?.interest_point_subtype ? InterestPointShowInfo.get()?.interest_point_subtype?.name : translate('Not informed')}</Typography>
                </Grid>
              </Grid>
              <Grid container xs={12} paddingBottom={1}>
                <Grid item xs={3}>
                  <Typography variant='subtitle1'><b>{translate('Created date')}:</b></Typography>
                  <Typography variant='subtitle2'>{InterestPointShowInfo.get()?.created_at ? dayjs(InterestPointShowInfo.get()?.created_at).format('DD/MM/YYYY') : translate('Not informed')}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant='subtitle1'><b>{translate('Validated date')}:</b></Typography>
                  <Typography variant='subtitle2'>{InterestPointShowInfo.get()?.validated_at ? dayjs(InterestPointShowInfo.get()?.validated_at).format('DD/MM/YYYY') : translate('Not validated')}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant='subtitle1'><b>{translate('Revision date')}:</b></Typography>
                  <Typography variant='subtitle2'>{InterestPointShowInfo.get()?.revision_date ? dayjs(InterestPointShowInfo.get()?.revision_date).format('DD/MM/YYYY') : translate('Not informed')}</Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant='subtitle1'><b>{translate('Expiration date')}:</b></Typography>
                  <Typography variant='subtitle2'>{InterestPointShowInfo.get()?.expiration_date ? dayjs(InterestPointShowInfo.get()?.expiration_date).format('DD/MM/YYYY') : translate('Not informed')}</Typography>
                </Grid>
              </Grid>

              <Grid container xs={12} paddingBottom={1}>
                <Grid item xs={12}>
                  <Typography variant='subtitle1'><b>{translate('Location')}:</b></Typography>
                  <Typography variant='subtitle2' paddingRight={1}>{InterestPointShowInfo.get()?.location}</Typography>
                </Grid>
              </Grid>

              <Grid container xs={12} paddingBottom={1} >
                <Grid item xs={12}>
                  <Typography variant='subtitle1'><b>{translate('Notes')}:</b></Typography>
                  <Typography variant='subtitle2' paddingRight={1}>{InterestPointShowInfo.get()?.notes}</Typography>
                </Grid>
              </Grid>

              <Grid sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '16px' }}>
                <Button
                  key={InterestPointShowInfo.get()?.id}
                  variant="contained"
                  endIcon={<PinDrop />}
                  color='primary'
                  onClick={() => window.open(`https://www.google.com.br/maps?q=loc:${InterestPointShowInfo.get()?.latitude?.toFixed(8)},${InterestPointShowInfo.get()?.longitude?.toFixed(8)}`)}
                >
                  Google Maps
                </Button>
              </Grid>
            </Grid>
          </Container>

        </DialogContent>
      </Dialog>
      <ConfirmDialog handleAgree={handleDialogValidation} handleClose={handleDialogCloseValidation} open={openDialogValidation} title={translate('Confirmation')} content={translate('Confirm validation')} />

      <ConfirmDialog handleAgree={handleForceExpiration} handleClose={handleDialogClose} open={openDialog} title={translate('Confirmation')} content={translate('Confirm force expiration')} />
    </>
  )
}