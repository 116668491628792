import type { State } from "@hookstate/core";
import { authFetch } from "~/services/fetch";

interface getCitiesProps {
  readonly cityId: number
  readonly cityName: State<string>
}

interface getDistricProps {
  readonly districtId: number;
  readonly districtName: State<string>
}


export async function getCities({ cityId, cityName }: getCitiesProps) {
  const response = await authFetch({
    url: `/city/${cityId}`,
    method: 'GET',
  })

  if (response.data.name) {
    cityName.set(response.data.name);
  };
}

export async function getDistric({ districtId, districtName }: getDistricProps) {
  const response = await authFetch({
    url: `/district/${districtId}`,
    method: 'GET',
  })

  if (response.data.name) {
    districtName.set(response.data.name);
  };
}