import React from 'react';
import { createState, Downgraded, useState } from '@hookstate/core';
import IStation from '~/features/Station/interfaces/IStation';
import constants from '~/core/constants';

const keyStation = constants.keys.station;

const stationState = createState<IStation | null>(null);

export default function useStationState() {
  const station = useState(stationState);

  return ({
    station: () => {
      if (station.get() === null) {
        const storedValue = localStorage.getItem(keyStation) ?? null;
        if (storedValue) {
          station.set(storedValue ? JSON.parse(storedValue) : null);
        }
      }
      return station
    },
    setStationCookie: React.useCallback((value: IStation) => {
      try {
        localStorage.setItem(keyStation, JSON.stringify(value));
      } catch (error) {
        console.error('Favor habilitar os cookies');
      }
    }, [keyStation]),
  })
}