import React, { useEffect } from 'react';
import Layout from '~/components/Layout';
import TabListDispatch from '~/features/Dispatch/components/TabListDispatch';
import { useLang } from '~/hooks/useLang';

export default function ListDispatchesSocket() {
  const { translate } = useLang();

  useEffect(() => {
    document.title = translate('Dispatch System')
  }, [])
  return (
    <Layout
      marginLayout={false}
      sidebar={false}
      topbar={false}
    >
      <TabListDispatch fullList/>
    </Layout>
  );
}