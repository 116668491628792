import * as React from 'react';
import {Box, Slider, Typography, Grid, Stack} from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';
import { useState } from '@hookstate/core';


export default function FontSizeSlider() {
  const { translate } = useLang()
  const { systemConfig } = useSystemState();
  const systemConfigState = useState(systemConfig())

  const handleChange = (event) => {
    if(systemConfigState.fontSize.get() != event.target.value as number){
      systemConfigState.fontSize.set(event.target.value as number);
    }
  };

  return (
    <Box sx={{ width: 200 }}>
      <Typography sx={{ alignItems: "center"}}>{translate('Font Size')}</Typography>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography sx={{fontSize: 10}}>A</Typography>
        <Slider
          value={systemConfigState.fontSize.get()}
          onChange={handleChange}
          valueLabelDisplay="auto"
          step={1}
          marks
          min={10}
          max={18}
        />
        <Typography sx={{fontSize: 18}}>A</Typography>
      </Stack>
    </Box>
  );
}