import { GridSortModel } from "@mui/x-data-grid";
import { authFetch } from "~/services/fetch";

export interface ISearchListAdvancedDispatch {
    readonly labelButton?: string;
    readonly occurrenceId?: string | null;
    readonly location?: string | null;
    readonly cityId?: number | null;
    readonly groupId?: readonly number[] | null;
    readonly deviceId?: number | null;
    readonly typeId?: number | null;
    readonly subtypeId?: number | null;
    readonly categoryId?: number | null;
    readonly agencyId?: number | null;
    readonly operationId?: number | null;
    readonly withSolvedThirdParty: boolean;
    readonly startDate: string | null;
    readonly finishDate: string | null;
    readonly statusId: number | null;
    readonly page: number;
    readonly orders: GridSortModel;
    readonly limit: number;
    readonly consummated: string | null;
    readonly onlyToClerk: boolean;
    readonly requester?: string | null;
    readonly phone?: string | null;
    readonly dispatchId?: number | null;
    readonly closedStatusClassId?: number | null;
    readonly closedStatusId?: number | null;
    readonly qualifiersSelected?: number[];
    readonly groupSetId?: number | null;
    readonly operationUnitId?: number | null;
}

export async function listAdvancedDispatchs({
    withSolvedThirdParty,
    agencyId,
    categoryId,
    cityId,
    deviceId,
    groupId,
    location,
    operationId,
    subtypeId,
    typeId,
    startDate,
    finishDate,
    statusId,
    page,
    orders,
    limit,
    consummated,
    onlyToClerk,
    requester,
    phone,
    dispatchId,
    occurrenceId,
    closedStatusClassId,
    closedStatusId,
    qualifiersSelected,
    groupSetId,
    operationUnitId
}: ISearchListAdvancedDispatch) {
    try {
        
        const { data } = await authFetch({
            url: '/dispatch',
            method: 'POST',
            data: {
                orders: orders ,
                page: page,
                limit: limit,
                start_date: startDate,
                finish_date: finishDate,
                location: location,
                city_id: cityId,
                agency_id: agencyId,
                dispatch_group_ids: groupId,
                type_id: typeId,
                subtype_id: subtypeId,
                status_id: statusId,
                device_id: deviceId,
                operation_id: operationId,
                category_id: categoryId,
                with_solved_third_party: withSolvedThirdParty,
                consummated: consummated,
                only_to_clerk :onlyToClerk,
                name: requester,
                phone: phone,
                dispatch_id: dispatchId,
                occurrence_id: occurrenceId,
                closed_status_class_id: closedStatusClassId,
                closed_status_id: closedStatusId,
                qualifier_options: qualifiersSelected,
                group_set_id: groupSetId,
                operation_unit_id: operationUnitId,
            }
        })

        return data
    } catch (error) {
        throw error
    }
}