import IListDispatchSocket, { IListDispatchesSocketResponse } from "~/components/ListDispatchesSocket/interfaces/IListDispatchesProps"
import { elapsedTimeWithDate } from "~/utils/dateTime"

export function toDomain(row: IListDispatchSocket): IListDispatchesSocketResponse {
  return {
    id: row.id,
    occurrence_id: row.occurrence ? row.occurrence.id : null,
    code: row.code,
    status: row.status.acronym,
    statusId: row.status.id,
    dispatch_group: row.dispatch_group ? `${row.dispatch_group.name}` : '-',
    type: row.type ? `[${row.type.code}] ${row.type.name}` : '-',
    subtype: row.subtype ? `[${row.subtype.code}] ${row.subtype.name}` : '-',
    location: row.occurrence.location,
    city: row.occurrence.city ? row.occurrence.city.name : '-',
    district: row.occurrence.district ? row.occurrence.district.name : '',
    notes: row.hold ? row.hold?.notes : '-',
    priority: row.priority,
    elapsed_time: row.status_updated_at ? new Date(row.status_updated_at).toJSON() : new Date().toJSON(),
    highlight: row.occurrence.featured,
    dispatched_at: row.dispatched_at,
  }
}
