import React from 'react';
import { Button, IconButton, Dialog, DialogContent, DialogActions, Grid, Typography } from '@mui/material';
import { EmergencyShare } from '@mui/icons-material';
import { useLang } from '../../../../hooks/useLang';
import useEntryState from '../../stores/EntryState';
import { useTheme } from '~/hooks/useTheme';
import useEntryDispatchState from '../../stores/EntryDispatchState';
import { Downgraded, useHookstate, useState } from '@hookstate/core';
import ConfirmDialog from '~/components/ConfirmDialog';
import useSystemState from '~/features/System/stores/SystemState';
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState';
import useEntryCommentState, { CommentDataProps } from '../../stores/EntryCommentState';
import { LoadingButton } from '@mui/lab';
import CodesGeneratedDialog from '../EntryCreateClosedOccurrenceButton/Components/CodesGeneratedDialog';
import DialogRedirect from './components/DialogRedirect';

interface DispatchButtonProps {
  readonly canDispatch?: boolean;
  readonly dispatch?: () => Promise<void>;
  readonly hasLatLng: boolean;
  readonly disabled?: boolean;
}

const defaultCommentsState: readonly CommentDataProps[] = []


export default function DispatchButton(props: DispatchButtonProps) {
  const { translate } = useLang()
  const { darkMode } = useTheme();
  const open = useState(false);
  const openRedirectOccurrence = useState(false);
  const { entryDispatch } = useEntryDispatchState();
  const entryDispatchState = useState(entryDispatch());
  const { entry, validateActionsEntry, loadingsActionsDispatchEntry } = useEntryState();
  const { windowManager } = useSystemState();
  const { syncOccurrenceEditAttendent } = useOccurrenceState();
  const entryIdContinue = useHookstate<number | null>(0)
  const hasDispatchGroup = entryDispatchState.value.length > 0;
  const { allCurrentComments, sortComment } = useEntryCommentState();
  const allCommentsValue = allCurrentComments().attach(Downgraded).value;
  const commentList = useState(defaultCommentsState)
  const hasEntryComment = commentList.get().length <= 0;
  const dispatchesCodesGenerated = useHookstate<string[]>([])
  const openDialogCodes = useHookstate(false);

  React.useEffect(() => {
    commentList.set(allCommentsValue ? allCommentsValue.map(element => element).sort(sortComment) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCommentsValue]);

  const handleClickOpen = () => {

    if (validateActionsEntry()) {
      open.set(true);
    }
  };
  const handleClose = () => {
    open.set(false);
  };
  const action = async () => {
    if (!props.dispatch) return;

    await props.dispatch().then(data => {
      entryIdContinue.set(data['occurrence'])
      dispatchesCodesGenerated.set(data['dispatches'].map(dispatch => dispatch['code']))
    })
    .finally(() => loadingsActionsDispatchEntry.set(false))

    open.set(false)
    openRedirectOccurrence.set(true)

  };
  const continueOccurrence = () => {
    windowManager()['occurrenceList'].open.set(true)
    syncOccurrenceEditAttendent(entryIdContinue.get())
    windowManager()['occurrence'].open.set(true)
    openRedirectOccurrence.set(false)
  }

  const nextEntry = () => {
    windowManager()['occurrence'].open.set(false)
    openRedirectOccurrence.set(false)
  }

  return (
    <Grid item>
      <IconButton title={translate('Send to dispatch')} disabled={!props.canDispatch} onClick={handleClickOpen}>
        <EmergencyShare />
      </IconButton>
      <Dialog open={open.get()} onClose={handleClose}>
        <DialogContent>
          <>
            {hasDispatchGroup || (<Typography sx={{ textAlign: 'center', color: 'error.main', fontWeight: 'bold' }}>{`${translate('No dispatch groups for this entry')}. ${translate('try saving')}!`}</Typography>)}
            {!!hasDispatchGroup && hasEntryComment && (<Typography sx={{ textAlign: 'center', color: 'error.main', fontWeight: 'bold' }}>{`${translate('You have not added a comment to this entry')}!`}</Typography>)}
            {hasDispatchGroup && (props.hasLatLng ? (<Typography sx={{ textAlign: 'center' }}>{translate('Do you wish to send this call to be handled by dispatchers?')}</Typography>) : (<Typography sx={{ textAlign: 'center' }}><strong style={{ color: '#ff3333', display: 'inline-block', borderRadius: '5px', padding: '5px', marginBottom: '15px', backgroundColor: darkMode ? '#242424' : '#d8d8d8' }}>{translate('Atendimento sem Latitude/Longitude')}{'!!'}</strong> <br /> {translate('Deseja despachar mesmo assim?')}</Typography>))}
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='outlined'>{translate('Cancel')}</Button>
          <LoadingButton loading={loadingsActionsDispatchEntry.get()} variant='contained' disabled={!entryDispatchState.value.length} onClick={action}>{`${translate('Send')}!`}</LoadingButton>
        </DialogActions>
      </Dialog>
      <DialogRedirect
        open={openRedirectOccurrence}
        codeOccurrence={`${entryIdContinue.get()}`}
        handleClose={nextEntry}
        handleAgree={continueOccurrence}
        closeLabel={'Next entry'}
        AgreeLabel={'Continue'}
        dispatchesCodesGenerated={dispatchesCodesGenerated}
      />
    </Grid>);

}