import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IDispatchCloseStatusClass from '../Interfaces/IDispatchCloseStatusClass';
import Layout from '~/components/Layout';

export default function Create() {

    const { register, handleSubmit, control, setValue: setFormValue } = useForm<IDispatchCloseStatusClass>();
    const fields = [
        { name: 'name', type: 'string' },
    ];

    return (
        <Layout marginLayout={true}>
            <CrudCreate
                model={'closed-status-class'}
                fields={fields}
                register={register}
                handleSubmit={handleSubmit}
                control={control}
                messageSuccess={'Closed status class successfully created!'}
                title={'Add Closed status class'}
                subheader={'Add a new Closed status class'}
                setFormValue={setFormValue}
            />
        </Layout>
    )
}
