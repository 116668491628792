import React, { useCallback, ChangeEvent, useRef } from 'react';
import { authFetch } from '~/services/fetch';
import { Button, Dialog, TextField, DialogContent, DialogActions, DialogContentText} from '@mui/material';
import { toast } from 'react-toastify';
import useDispatchVehicleState from '~/features/Dispatch/stores/DispatchVehicleState';
import { useHookstate } from '@hookstate/core';
import { useLang } from '~/hooks/useLang';
import { elapsedTime } from '~/utils/dateTime';

// eslint-disable-next-line react/function-component-definition
export const RemoveDeviceButton = ({openState, deviceId, dispatchDeviceId, handleCancel}) => {
  const { translate } = useLang()
  const isConfirmDialog = useHookstate(false);
  const confirmDialogContent = useRef<string>('undefined');
  const reason = useHookstate('');
  const isInvalidRequest = useHookstate(false);
  const { deleteVehicleFromList, updateStatusVehicleListInDispatch } = useDispatchVehicleState();

  const agreeWithDeviceDeletion = (dispatchDeviceId?: number) => {
    if(reason.get() != '' && dispatchDeviceId){
      authFetch({
        url: '/dispatch/remove-dispatch-device',
        data: { 
          dispatch_device_id: dispatchDeviceId,
          reason: reason.get()
        },
        method: 'POST'
      })
        .then(() => {
          updateStatusVehicleListInDispatch({ 
            dispatchDeviceId: dispatchDeviceId, 
            status: {id: 8, name: 'FECHADA', acronym: 'FC'}, 
            dateUpdated: new Date().toJSON() 
          })
          toast.success(translate(`Device was removed successfully !`));
        })
        .catch(console.error)
        .finally(() => {
          closeConfirmDialog();
          resetDialogRef();
          openState.set(false);
          handleCancel();
        })
      } else {
      return isInvalidRequest.set(true);
    }
  }

  const closeConfirmDialog = useCallback(() => {
    isConfirmDialog.set(false);
  }, [])
  const resetDialogRef = useCallback(() => {
    confirmDialogContent.current = 'undefined';
  }, [])

  const openConfirmDialog = useCallback(() => {
    isConfirmDialog.set(true);
  }, [])

  const handleOpenConfirmDialog = (content: string) => {
    confirmDialogContent.current = content;
    openConfirmDialog();
  }

  const handleOpenDialogDeviceDelete = (deviceId: number | undefined) => {
    const confirmContent = `${translate(`Do you want to remove the device from this dispatch`)}?`;
    handleOpenConfirmDialog(confirmContent);
  }

  return (
    <>
      <Button size={'small'} fullWidth sx={{height:'100%'}} variant='contained' onClick={() => handleOpenDialogDeviceDelete(deviceId.get())}>{translate('Free')}</Button>

      <Dialog
        open={isConfirmDialog.get()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {confirmDialogContent.current}
          </DialogContentText>

          <TextField
            multiline
            rows={10}
            error={isInvalidRequest.get()}
            required={true}
            name={'reason'}
            id='reason'
            label={translate('Reason')}
            fullWidth
            size='small'
            value={ !reason.get() ? '' : reason.get() }
            sx={{ mt: 1 }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {reason.set(event.target.value)}}
          />           
        </DialogContent>
        <DialogActions sx={{display:'flex', justifyContent:'space-evenly'}}>
          <Button variant='outlined' onClick={() => closeConfirmDialog()}>{ translate('Cancel') }</Button>
          <Button variant='contained' onClick={() => agreeWithDeviceDeletion(dispatchDeviceId.get())}>
            { translate('Ok') }
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}