import React from "react";
import { Grid } from "@mui/material";
import { State } from '@hookstate/core';
import PhoneTextField from "~/components/PhoneField";
import EntryOriginSelect from "../EntryOriginSelect";
import NameField from "../NameField";

type CallerInputContainerProps = {
  readonly readonly:boolean;
  readonly isValid: boolean;
  readonly isInvalidRequest: boolean;
  readonly name: State<string|null>;
  readonly phone: State<string|null>;
  readonly originId: State<string>;
  readonly openModalLastCalls: State<boolean>;
  readonly userStationOrigin?: string;
}

export default function CallerInputContainer({readonly, isValid, isInvalidRequest, name, phone, originId, openModalLastCalls, userStationOrigin}: CallerInputContainerProps){


  return(
      <Grid container columns={12} rowSpacing={1} sx={{ flexDirection: 'column' }}>
        <Grid item>
          <NameField readonly={readonly} isValid={isValid} name={name} /> 
        </Grid>
        <Grid item>
          <PhoneTextField readonly={readonly} statePhone={phone} disabled={!isValid} onClickButton={() => openModalLastCalls.set(true)}/>
        </Grid>
        <Grid item>
          <EntryOriginSelect readonly={readonly} isValid={isValid} isInvalidRequest={isInvalidRequest} originId={originId} originStation={userStationOrigin} />
        </Grid>
      </Grid>
  )
}
