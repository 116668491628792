/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import { State, useHookstate } from '@hookstate/core';
import {
  Card, CardContent, CardActions, Typography, Divider, CircularProgress, Grid, Container, Box, Button, FormControlLabel, Switch,
  Tooltip
} from '@mui/material';
import { useLang } from '~/hooks/useLang';
import OccurrenceTimelineStatus from '~/features/Occurrence/components/OccurrenceTimelineContent/components/OccurrenceTimelineStatus';
import IOccurrenceTimelineItems from '~/features/Occurrence/components/OccurrenceTimelineContent/interfaces/IOccurrenceTimelineItems';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent } from '@mui/lab';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import { formatDateHour } from '~/utils/format';
import { truncateText } from '~/utils/strings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ComputerIcon from '@mui/icons-material/Computer';
import SortIcon from '@mui/icons-material/Sort';
import dayjs from 'dayjs';
import { authFetch } from '~/services/fetch';
import ForumIcon from '@mui/icons-material/Forum';
import { ConstructionOutlined } from '@mui/icons-material';


interface OccurrenceTimelineContentProps {
  readonly occurrenceId: number | string | null | undefined;
  readonly reloadInfo?: State<boolean>;
  readonly loading?: State<boolean>;
}

export default function OccurrenceTimelineContent({ occurrenceId, reloadInfo, loading }: OccurrenceTimelineContentProps) {
  const { translate } = useLang();
  const occurrenceTimelineItems = useHookstate<readonly IOccurrenceTimelineItems[]>([]);
  const filterComments = useHookstate(true);
  const filterEvents = useHookstate(true);
  const filterPreview = useHookstate(true);
  const sortChronology = useHookstate(false);

  useEffect(() => {
    loading?.set(true)
    authFetch({
      url: `/occurrence/${occurrenceId}/timeline`,
      method: 'POST',

    }).then(({ data }: { readonly data: readonly IOccurrenceTimelineItems[] }) => {
      occurrenceTimelineItems.set(data.map(dataChronology => ({
        ...dataChronology,
        showEdits: false,
        isTruncate: false,
      })));
      loading?.set(false);
    })


  }, [reloadInfo?.get()])

  const changeSortChronology = () => {
    sortChronology.set(!sortChronology.get())
  }
  return (
    <Grid container display={'flex'} alignItems={'center'} width={'100%'}>
      <Grid
        item
        width={'100%'}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '24px'
        }}
      >
        <Grid item>
          <FormControlLabel
            control={<Switch
              checked={filterComments.get()}
              onChange={() => filterComments.set(!filterComments.value)}
            />}
            label={translate('Comments')}
            labelPlacement='start'
          />

          <FormControlLabel
            control={<Switch
              checked={filterEvents.get()}
              onChange={() => filterEvents.set(!filterEvents.value)}
            />}
            label={translate('Events')}
            labelPlacement='start'
          />
          <FormControlLabel
            control={<Switch
              checked={filterPreview.get()}
              onChange={() => filterPreview.set(!filterPreview.value)}
            />}
            label={translate('Views')}
            labelPlacement='start'
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => changeSortChronology()}
            endIcon={<SortIcon />}
          >
            {translate('Sort name', { code: sortChronology.get() ? translate('Growing') : translate('Decreasing') })}
          </Button>
        </Grid>
      </Grid>
      <Grid container sx={{ display: "flex", height: 650, flexDirection: "column", overflow: "scroll", overflowY: "scroll" }}>
        {loading?.get() ?
          <Grid container sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress disableShrink size={50} />
          </Grid>
          :
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.2,
              },
            }}
          >

            {occurrenceTimelineItems.value.map((item, index, array) => {
              if ((item.event_name == 'OCCURRENCE_COMMENT' && filterComments.get())
                || (item.event_name == 'PREVIEW_EVENT' && filterPreview.get())
                || ((item.event_name != 'OCCURRENCE_COMMENT' && item.event_name != 'PREVIEW_EVENT') && filterEvents.get())) {
                return (
                  <TimelineItem key={index}>
                    <TimelineOppositeContent color='text.secondary'>
                      <Typography variant={'body2'}>
                        {formatDateHour(item.event_at).toFormatedDate}
                      </Typography>

                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <OccurrenceTimelineStatus event={item.event_name} />
                      {/* {index != (array.length - 1) ? <TimelineConnector /> : null} */}
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>

                      <Card variant='elevation' elevation={2} sx={{
                        mb: 2,
                        // width: 600
                      }}>
                        <CardContent >
                          <Typography
                            variant={'body2'}
                            style={{
                              wordWrap: "break-word",
                              marginBottom: "0"
                            }}>
                            {occurrenceTimelineItems[index].isTruncate.get() ? item.content : !!item.is_deleted ? `${translate('Comentário excluido')}` : ` ${truncateText(item.content, 200)}`}
                            {item.content.length > 200 && <Typography
                              onClick={() => {
                                occurrenceTimelineItems[index].isTruncate.set(!occurrenceTimelineItems[index].isTruncate.get())
                              }}
                              sx={{ cursor: "pointer" }}
                              color='orange'
                              variant="caption">
                              {' '}
                              {!occurrenceTimelineItems[index].isTruncate.get() ? translate('more') : ''}
                            </Typography>}
                          </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions>
                          {(item.origin && item.origin.name) && (
                            <>
                              <Typography variant={'body2'}>
                                <AccountCircleIcon />
                              </Typography>
                              <Typography title={item.user.representation_name ? item.user.representation_name : ''} variant='body2'>
                                {`${truncateText(item.user.representation_name ? item.user.representation_name : '', 17, '')}`}
                              </Typography>
                              <Typography variant={'body2'}>
                                <ComputerIcon />
                              </Typography>
                              <Typography variant={'body2'}>
                                {item.origin.name}
                              </Typography>
                              {!!item.is_edited && !!item.is_deleted === false &&
                                <Typography variant='body2'>
                                  {`• ${translate('edited')} ${translate('by')} ${item.changed_by_user.representation_name} • ${dayjs(item.date_updated_event).format('DD-MM-YYYY HH:mm')}`}
                                </Typography>
                              }
                              {!!item.is_deleted &&
                                <Typography variant='body2'>
                                  {`• ${translate('deleted')} ${translate('by')} ${item.changed_by_user.representation_name} • ${dayjs(item.date_updated_event).format('DD-MM-YYYY HH:mm')}`}
                                </Typography>
                              }
                            </>
                          )}
                          {item.dispatch_origin &&
                            <>
                              <Tooltip title={translate('Dispatch origin')} placement='bottom'>
                                <Typography>
                                  <ForumIcon />
                                </Typography>
                              </Tooltip>
                              <Typography variant={'body2'}>
                                {item.dispatch_origin}
                              </Typography>
                            </>
                          }
                        </CardActions>
                      </Card>

                      {!!item.showEdits && !!item.is_deleted &&
                        <Card variant='elevation'
                          elevation={2}
                          sx={{ ml: 5, mb: 3, width: 560 }}>
                          <CardContent>
                            <Typography
                              variant={'body2'}
                              style={{
                                wordWrap: "break-word",
                                marginBottom: "0"
                              }}>
                              {item.content}
                            </Typography>
                          </CardContent>
                          <Divider />
                          <CardActions>
                            <Typography variant={'body2'}>
                              <AccountCircleIcon />
                            </Typography>
                            <Typography variant={'body2'}>
                              {item.user.representation_name}
                            </Typography>

                            <Typography variant={'body2'}>
                              <ComputerIcon />
                            </Typography>
                            <Typography variant={'body2'}>
                              {item.origin.name}
                            </Typography>
                            {item.dispatch_origin &&
                              <>
                                <Typography>
                                  <ForumIcon />
                                </Typography>
                                <Typography variant={'body2'}>
                                  {item.dispatch_origin}
                                </Typography>
                              </>
                            }
                          </CardActions>
                        </Card>

                      }

                      {!!item.showEdits && item.previous_content &&
                        item.previous_content.map((content, i) => (
                          <Card key={i} variant='elevation'
                            elevation={2}
                            sx={{ ml: 5, mb: 3, width: 560 }}>
                            <CardContent>
                              <Typography
                                variant={'body2'}
                                style={{
                                  wordWrap: "break-word",
                                  marginBottom: "0"
                                }}>
                                {content.previous_comment}
                              </Typography>
                            </CardContent>
                            <Divider />
                            <CardActions>
                              <Typography variant={'body2'}>
                                <AccountCircleIcon />
                              </Typography>
                              <Typography variant={'body2'}>
                                {item.previous_content && item.previous_content.length - 1 === i ? item.user.representation_name : content.user.representation_name}
                              </Typography>

                              <Typography variant={'body2'}>
                                <ComputerIcon />
                              </Typography>
                              <Typography variant={'body2'}>
                                {`${item.previous_content && item.previous_content.length - 1 === i ? item.origin.name : content.station.name}`}

                              </Typography>
                              {item.dispatch_origin &&
                                <>
                                  <Typography>
                                    <ForumIcon />
                                  </Typography>
                                  <Typography variant={'body2'}>
                                    {item.dispatch_origin}
                                  </Typography>
                                </>
                              }
                            </CardActions>
                          </Card>
                        ))

                      }

                      {!!item.is_edited || !!item.is_deleted ?
                        <Divider textAlign="left">
                          <Typography variant='caption'
                            sx={{ cursor: "pointer", pt: 3 }}
                            onClick={() => {
                              occurrenceTimelineItems[index].showEdits.set(!occurrenceTimelineItems[index].showEdits.get())
                            }}>
                            ━━
                            {' '}
                            {occurrenceTimelineItems[index].showEdits.get() ? translate('Hide historic') : translate('Show historic')}
                          </Typography>
                        </Divider>
                        : <Divider />
                      }

                    </TimelineContent>
                  </TimelineItem>
                )
              }
            }).sort((element1, element2) => element1 && element2 && element1 > element2 ? (sortChronology.get() === false ? -1 : 1) : (sortChronology.get() === false ? 1 : -1))}
          </Timeline>
        }
      </Grid>
    </Grid>
  )
}