import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IDistrict from '../interfaces/IDistrict';
import Layout from '~/components/Layout';


export default function Create() {
  const {register, handleSubmit, control, setValue:setFormValue} = useForm<IDistrict>();
  const fields = [
      { name: 'name', type: 'string'},
      { name: 'cityId', type: 'selectAutoComplete', model: 'city', placeholder:'City' },
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate model={'district'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        messageSuccess={'District successfully created!'}  
        title={'Add district'}  
        subheader={'Add a new district'}
        setFormValue={setFormValue} />
      </Layout>
    )
}
