import { useHookstate } from '@hookstate/core';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getAllSubtypesByTypeInterestPoint } from '~/features/InterestPonitSubtype/services';
import { useLang } from '~/hooks/useLang';

interface FilterAutocompleteSubtypesByTypeInterestPointProps {
  readonly onSubtypesInterestPointChange: (value: any | null) => void;
  readonly subtypesInterestPoint?: number | null;
  readonly typeInterestPointId: number | null;
  readonly error?: boolean;
  readonly disabled?: boolean
}


export default function FilterAutocompleteSubtypesByTypeInterestPoint({
  onSubtypesInterestPointChange,
  subtypesInterestPoint,
  typeInterestPointId,
  disabled,
  error
}: FilterAutocompleteSubtypesByTypeInterestPointProps) {
  const { translate } = useLang();
  const [options, setOptions] = useState<readonly any[]>([]);
  const [value, setValue] = useState<any | null>(null);
  const loading = useHookstate(false)

  useEffect(() => {
    if (typeInterestPointId) {

      getAllSubtypesByTypeInterestPoint(typeInterestPointId).then((SubtypesByTypeInterestPoint) => {
        const resSubtypes = SubtypesByTypeInterestPoint.map(subtype => ({
          ...subtype,
          displayName: `${subtype.name}`
        }))
        setOptions(resSubtypes)
        if (subtypesInterestPoint) {
          const option = resSubtypes.find((option) => option.id == subtypesInterestPoint);
          setValue(option ?? null);
          onSubtypesInterestPointChange(option ?? null);
        } else {
          setValue(null);
          onSubtypesInterestPointChange(null);

        }
      })

    } else {
      setOptions([]);
      setValue(null);
      onSubtypesInterestPointChange(null);
    }
  }, [typeInterestPointId])

  useEffect(() => {
    if (subtypesInterestPoint) {
      const option = options?.find((option) => option.id == subtypesInterestPoint);
      setValue(option ?? null);
    }
    else {
      setValue(null);
      onSubtypesInterestPointChange(null);
    }

  }, [subtypesInterestPoint])

  return (
    <FormControl fullWidth size='small'>
      <Autocomplete
        filterSelectedOptions
        disableCloseOnSelect
        id='filter-subtypes'
        disabled={disabled}
        options={options}
        value={value}
        noOptionsText={translate('No options')}
        size='small'
        // loading={loading.get()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => (option.displayName ?? option.name) ?? ''}
        onChange={(_, subtype) => {
          setValue(subtype ?? null);
          onSubtypesInterestPointChange(subtype ?? null);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error ? error : false}
            label={translate('Interest point subtype')}
          />
        )}
      />
    </FormControl>
  )
}