import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import ISpecie from '../interfaces/ISpecie';

export default function Edit() {
  const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm<ISpecie>();
  const fields = [
    { name: 'id', type: 'hidden' },
    { name: 'name', type: 'string', required: true},
  ];

  return (
    <CrudEdit
      model={'specie'}
      fields={fields}
      register={register}
      handleSubmit={handleSubmit}
      control={control}
      setFormValue={setFormValue}
      messageSuccess={'Specie successfully updated!'}
      title={'Edit Specie'}
      getValues={getValues}
    />
  )
}