import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import ICity from '../interfaces/ICity';

export default function Create() {   
  
  const {register, handleSubmit, control, setValue:setFormValue} = useForm<ICity>();
  const fields = [
      { name: 'idIbge', type: 'string'},
      { name: 'name', type: 'string'},
      { name: 'stateId', type: 'selectAutoComplete', model: 'state', placeholder:'Estado' },
  ];
  
    return(
      <CrudCreate model={'city'} 
      fields={fields} 
      register={register} 
      handleSubmit={handleSubmit} 
      control={control} 
      messageSuccess={'City successfully created!'}  
      title={'Add city'}  
      subheader={'Add a new city'}
      setFormValue={setFormValue} />
    )
}
