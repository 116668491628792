/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Autocomplete,
  FormControl,
  Grid,
} from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useDispatchRegistrationPersonState from '../../../../stores/DispatchRegistrationPersonState';
import useDispatchRegistrationState from '../../../../stores/DispatchRegistrationState';
import IInvolvementType from '~/features/InvolvementType/interfaces/IInvolvementType';
import IPeopleCircunstance from '~/features/PeopleCircunstance/interfaces/IPeopleCircunstance';
import IDestination from '~/features/Destination/interfaces/IDestination';
import IOrganization from '~/features/Organization/interfaces/IOrganization';
import { Downgraded } from '@hookstate/core';
import FieldAutocompleteRegistration from '~/components/FieldAutocompleteRegistration';

interface FormPeopleOcurrenceDataProps {
  readonly disableFields?: boolean
}

export default function FormPeopleOcurrenceData({ disableFields = false }: FormPeopleOcurrenceDataProps) {
  const { translate } = useLang();

  const {
    registrationPersonDispatch,
    getInvolvementTypes,
    getOrganizations,
    getPeopleCircunstances,
    getArrestedValues,
    getPublicAgentValues,
  } = useDispatchRegistrationPersonState();

  const {
    registrationDispatch,
    getDestinations,
  } = useDispatchRegistrationState();

  const { inputErrors } = registrationDispatch();
  const { person } = registrationPersonDispatch();
  const [arrestedValue, setArrestedValue] = useState<string | null>(null)
  const [publicAgentValue, setPublicAgentValue] = useState<string>('Não')
  const [destinationsList, setDestinationsList] = useState<readonly IDestination[]>([]);
  const [involvementTypesList, setInvolvementTypes] = useState<readonly IInvolvementType[]>([]);
  const [organizationsList, setOrganizations] = useState<readonly IOrganization[]>([]);
  const [peopleCircunstancesList, setPeopleCircunstances] = useState<readonly IPeopleCircunstance[]>([]);



  return (
    <FormControl fullWidth size='small'>
      <Grid container direction={'row'}>
        {/* <Grid item xs={3} sx={{ padding: 1 }}>
          <Autocomplete
            disabled={disableFields}
            autoSelect
            id="combo-box-involvement-type"
            options={involvementTypesList}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option: IInvolvementType) => option.name ?? ""}
            size='small'
            value={person.get().involvementType}
            noOptionsText={translate('No options')}
            loadingText={translate('Loading…')}
            openText={translate('Open')}
            onChange={(event: unknown, type) => {
              person.merge({ involvementType: type })
            }}
            onBlur={() => {
              if (!person.get().involvementType) {
                inputErrors.merge({ involvementType: translate('Enter a involvement type') })
              }
            }}
            onClose={() => {
              if (person.get().involvementType) {
                inputErrors.merge({ involvementType: null })
              }
            }}
            // disableClearable={!!person.get().involvementType}
            renderInput={
              (params) => <TextField
                {...params}
                autoFocus
                error={!!inputErrors.get().involvementType}
                onFocus={() => inputErrors.merge({ involvementType: null })}
                required
                label={translate('Involvement Type')}
              />}
          />
        </Grid> */}
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            onValueChange={(type) => person.merge({ involvementType: type })}
            valueId={person.involvementType.get()?.id as string}
            onError={(value) => inputErrors.involvementType.set(value)}
            optionsFetch={getInvolvementTypes}
            label={translate('Involvement Type')}
            error={inputErrors.involvementType.get()}
            isRequired
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            label={translate('Arrested')}
            onValueChange={(arrested) => person.arrested.set(arrested?.id as string)}
            optionsList={getArrestedValues().map((arrestedMap, index) => ({ id: `${index}`, name: arrestedMap }))}
            valueId={`${person.arrested.get()}`}
            isRequired
            disabled={disableFields}
            error={inputErrors.arrested.get()}
            onError={(value) => inputErrors.arrested.set(value)}
          />
        </Grid>

        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            label={translate('People Circunstance')}
            onValueChange={(peopleCircunstance) => person.peopleCircunstance.set(peopleCircunstance)}
            valueId={person.peopleCircunstance.get()?.id as string}
            optionsFetch={getPeopleCircunstances}
            isRequired
            disabled={disableFields}
            error={inputErrors.peopleCircunstance.get()}
            onError={(value) => inputErrors.peopleCircunstance.set(value)}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            label={translate('Destination')}
            onValueChange={(destination) => person.destination.set(destination)}
            valueId={person.destination.get()?.id as string}
            optionsFetch={getDestinations}
            isRequired
            disabled={disableFields}
            error={inputErrors.destination.get()}
            onError={(value) => inputErrors.destination.set(value)}
          />
        </Grid>

        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            label={translate('Public Agent')}
            onValueChange={(publicAgent) => {
              person.publicAgent.set(publicAgent?.id ?? '')
              person.organization.set(null)
            }}
            optionsList={getPublicAgentValues().map((publicAgent, index) => ({ id: `${index}`, name: publicAgent }))}
            valueId={`${person.publicAgent.get()}`}
            isRequired
            disabled={disableFields}
            error={inputErrors.publicAgent.get()}
            onError={(value) => inputErrors.publicAgent.set(value)}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            label={translate('Organization')}
            onValueChange={(organization) => person.organization.set(organization)}
            valueId={person.organization.get()?.id as string}
            optionsFetch={getOrganizations}
            isRequired={person.publicAgent.get() !== '0'}
            disabled={!person.publicAgent.get() || person.publicAgent.get() === '0' || disableFields}
            error={inputErrors.organization.get()}
            onError={(value) => inputErrors.organization.set(value)}
          />
        </Grid>
        <Grid item xs={5.95} sx={{ padding: 1, marginBottom: 1 }}>
          <TextField
            disabled={disableFields}
            id="outlined-multiline-static"
            label={translate('Note')}
            multiline
            fullWidth
            size='small'
            rows={2}
            value={person.get().notes}
            InputProps={{
              onChange: (event) => {
                const notes = event.target.value
                person.merge({
                  notes,
                })
              }
            }}
            sx={{ height: 50 }}
          />
        </Grid>
      </Grid>
    </FormControl>
  );
}