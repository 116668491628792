import axios, { AxiosResponse, CancelTokenSource } from "axios";
import { LatLng } from "leaflet";
import { getCities } from "~/features/City/services/index";
import { getDistricts } from "~/features/District/services";
import { authFetch } from "~/services/fetch";

// When needed add Other Location-Based Functions Here
export interface LocationPrediction {
  readonly description: string,
  readonly main: string,
  readonly secondary: string,
  readonly placeId: string
};

export interface LocationDetail {
  readonly main: string,
  readonly secondary: string,
  readonly city: string,
  readonly state: string,
  readonly country: string,
  readonly latitude: number,
  readonly longitude: number,
  readonly type: string
};

export interface LocationGeocoding {
  readonly placeId: string;
  readonly city: string;
  readonly country: string;
  readonly state: string;
  readonly main: string;
  readonly secondary: string;
  readonly type: string;
  readonly latitude: number;
  readonly longitude: number;
  readonly district: string;
}

export type LocationOptions = {
  readonly latLng: LatLng,
}

export type ProviderType = 'google' | 'geoapify';

type GetReverseGeocodingType = {
  readonly locationPromise: Promise<AxiosResponse<LocationGeocoding | null>>,
  readonly controller: AbortController,
}

export const getLocationDetails = async (location: string) => {
  const { data } = await axios({
    baseURL: 'https://nominatim.openstreetmap.org/',
    url: `search?q=${encodeURI(location)}&format=jsonv2`,
    timeout: 2000,
    method: 'GET',
  });

  return data
}
export const getReverseLocationDetails = (latLng: LatLng) => {
  const opType = 'reverse';
  const cancelToken = axios.CancelToken.source();

  const axiosPromise = axios({
    baseURL: 'https://nominatim.openstreetmap.org/',
    url: `${opType}?format=jsonv2&lat=${latLng.lat}&lon=${latLng.lng}`,
    timeout: 2000,
    cancelToken: cancelToken.token,
    method: 'GET'
  })

  return { axiosPromise, cancelToken }
}
export const getLocationDetailsFromStreet = async (street: string) => {
  const { data } = await axios({
    baseURL: 'https://nominatim.openstreetmap.org/',
    url: `search?country=${process.env.REACT_APP_DESPACHO_BASE_COUNTRY}&state=${process.env.REACT_APP_DESPACHO_BASE_STATE}&street=${encodeURI(street)}&format=jsonv2`,
    timeout: 2000,
    method: 'GET'
  });


  return data;
}
export const getAutoComplete = async (text: string) => {
  const { data } = await axios({
    baseURL: 'https://api.geoapify.com/v1/geocode/',
    url: `autocomplete?text=${encodeURIComponent(text)}&lang=pt&filter=countrycode:br&bias=proximity:-38.5217989,-3.7304512&apiKey=37973c00e7624981a84a568848aa8117`,
    timeout: 5000,
    method: 'GET'
  });

  return data;
};

export const getFirstLatLongFromStreet = async (street: string) => {
  const { displayName, importance, lat, lon } = await getLocationDetailsFromStreet(street);

  return { completAddress: displayName, latitude: lat, longitude: lon, importance };
}
export const getAutoCompleteProperties =
  async (text: string) =>
    (await getAutoComplete(text)).features
      .map((suggestion) => suggestion.properties)
      .filter((suggestion) => suggestion.rank.confidence > 0.1);

export const getCityIdByName = async (name: string) => {
  const cities = await getCities({ filters: [{ field: 'cities.name', operator: 'equals', value: name }] });

  const response = cities.length > 0 ? cities[0]['id'] : null
  return response;
}

export const getDistrictIdByName = async (name: string) => {
  const districts = await getDistricts({ filters: [{ field: 'districts.name', operator: 'contains', value: name }] });
  const response = districts.length > 0 ? districts[0]['id'] : null
  return response;
}

export function getAutoCompleteOptions(term: string, locationOpt: Partial<LocationOptions> = {}): { readonly promise: Promise<readonly LocationPrediction[]>; readonly cancelToken: CancelTokenSource; } {
  const { latLng } = locationOpt;
  const cancelToken = axios.CancelToken.source();

  const promise = new Promise<readonly LocationPrediction[]>((resolve, reject) => {
    authFetch({
      url: `/service/autocomplete/${term}` + (latLng ? `/${latLng.lat}/${latLng.lng}` : ''),
      method: 'GET',
      timeout: 5000,
      cancelToken: cancelToken.token,
    })
      .then(({ data }) => resolve(data))
      .catch(err => reject(err));
  })

  return { promise, cancelToken };
}
export async function getPlaceDetails(placeId: string, locationOpt: Partial<LocationOptions> = {}): Promise<LocationDetail> {
  try {
    const { data } = await authFetch({
      url: `/service/details/${placeId}`,
      method: 'GET'
    });

    return data;
  } catch (err) {
    console.error(`Local ${placeId} não identificado na base`);
    return {
      main: '',
      secondary: '',
      city: '',
      state: '',
      country: '',
      latitude: 0,
      longitude: 0,
      type: ''
    };
  }
}

export function getReverseGeocoding(latLng: LatLng): GetReverseGeocodingType {

  const controller = new AbortController();

  const locationPromise = authFetch({
    url: `/service/reverse-geolocation/${latLng.lat}/${latLng.lng}`,
    method: 'GET',
    signal: controller.signal
  });

  return { locationPromise, controller };
}