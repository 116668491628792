import { AxiosError } from 'axios';
import { authFetch } from '~/services/fetch';
import { IParamsTable, IUpdateProps } from '../../../interfaces';
import IType from '../interfaces/IType';

export async function getTypeById(id: string | number | undefined | null) {
    const response = await authFetch({
        url: `/type/${id}`,
        method: 'GET'
    });
    return response.data;
}

export async function getTypes({
    filters = [],
    limit = 100000,
    orders = [
        { field: 'name', sort: 'asc' }
    ]
}: IParamsTable = {}) {
    const response = await authFetch({
        url: `/type/`,
        method: 'POST',
        data: {
            limit,
            filters,
            orders
        },

    });
    return response.data.data;
}

export async function getAllTypes(): Promise<readonly IType[]> {
    const response = await authFetch({
        url: `/type/`,
        method: 'GET',
    });
    return response.data;
}

export async function getSubtypeForType(type){
    const response = await authFetch({
        url: `/type/${type}/subtypes`,
        method: 'GET',
    });
    return response.data;
}