import React from 'react';
import { AppRouteProps } from '~/routes';
import Activation from './pages/Activation';


export default function ActivationRoutes(): readonly AppRouteProps[]   {
  const routes: AppRouteProps[] = [
    {path: '/activation', component: Activation, isProtected: false}
  ];

  return routes;
}
