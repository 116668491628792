/* eslint-disable max-lines-per-function */
import React from 'react'
import { authFetch } from '~/services/fetch'
import { ITransferCrew } from '../interfaces/ITranferCrew';
import { toFetch } from '~/mappers/vehicle-transfer-crew-mapper';
import axios from 'axios';
import { DeviceCrewResponse } from '~/components/DisplayDispatchVehicle';


export interface IUpdateCrewComposition {
  readonly deviceLogId: number | null;
  readonly crewComposition: {
    readonly commanderCPF?: string | null;
    readonly driverCPF?: string | null;
    readonly patrolmansCPF: readonly (string | null | undefined)[] | null
  };
  readonly deviceLog: {
    readonly deviceId?: number | null;
    readonly task: string;
    readonly km: string;
    readonly phone: string;
    readonly notes: string;
    readonly trackingId: string;
    readonly cityId?: number | null;
    readonly operationId?: number | null
  };
}

interface ISearchComponent {
  readonly cpf?: string | null;
  readonly registration?: string | null;
  readonly occurrenceId?: string | null;
}

const useVehicleState = () => {
  return {
    sendTransferCrew: async (data: ITransferCrew) => {
      const targetUrl = '/device/logs/transfer-crew'
      const response = await authFetch({
        url: targetUrl,
        data: toFetch(data),
      });
      return response.data;
    },
    getCrewComposition: (deviceId: number) => {
      const targetUrl = '/device/logs/crew-composition'
      const cancelToken = axios.CancelToken.source();
      const crewPromise = new Promise<DeviceCrewResponse | null>((resolve, reject) => {
        authFetch({
          url: targetUrl,
          data: { "device_id": deviceId },
          method: 'POST',
          cancelToken: cancelToken.token
        })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(err => { reject(err); })
      })
      return { crewPromise, cancelToken }
    },
    updateCrewComposition: async ({ crewComposition, deviceLog, deviceLogId }: IUpdateCrewComposition) => {
      const targetUrl = '/device/logs/change-composition'
      const response = await authFetch({
        url: targetUrl,
        data: {
          device_id: deviceLog?.deviceId,
          refer_device_log_id: deviceLogId,
          km_initial: deviceLog?.km,
          task: deviceLog?.task,
          commander: crewComposition.commanderCPF,
          phone: deviceLog?.phone,
          driver: crewComposition.driverCPF,
          patrolmans: crewComposition.patrolmansCPF,
          notes: deviceLog.notes,
          tracking_id: deviceLog.trackingId,
          city_id: deviceLog.cityId,
          operation_id: deviceLog.operationId
        }
      })

      return response.data;
    },
    searchComponent: async ({ cpf, registration }: ISearchComponent) => {
      const targetUrl = '/user/find'

      const response = await authFetch({
        url: targetUrl,
        data: {
          cpf,
          registration,
        }
      })
      return response.data;
    }
  }
}

export default useVehicleState;