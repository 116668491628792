import React from "react";
import useDispatchCommentState, { CommentDataProps } from '../../stores/DispatchCommentState'
import { useState, Downgraded, State } from "@hookstate/core";
import { DisplayComments } from "~/components/DisplayComments";

const defaultCommentsState: readonly CommentDataProps[] = []

interface DispatchDisplayCommentsProps {
  readonly dispatchId: number | null
  readonly commentIsCompressed: State<boolean>;
  readonly readonly: boolean;
  readonly moveScrolltoComment?: boolean;
}

export function DispatchDisplayComments({ dispatchId, commentIsCompressed, readonly = false, moveScrolltoComment }: DispatchDisplayCommentsProps) {

  const { allCurrentComments, sortComment, loading, retryConnect, syncPreviousComments } = useDispatchCommentState();
  const commentList = useState(defaultCommentsState)
  const allCommentsValue = allCurrentComments().attach(Downgraded).value;

  React.useEffect(() => {
    commentList.set(allCommentsValue ? allCommentsValue.map(element => element).sort(sortComment) : []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCommentsValue]);

  return (
    <DisplayComments
      isEdit={true}
      isDispatch={true}
      comments={commentList}
      commentIsCompressed={commentIsCompressed}
      readonly={readonly}
      moveScrolltoComment={moveScrolltoComment}
      loading={loading().get()}
      refresh={retryConnect().get()}
      refreshAction={() => dispatchId ? syncPreviousComments(dispatchId) : false}
    />
  )
}


