

import { t as translate } from 'i18next';
import * as React from 'react'
import SelectAutoComplete from '~/components/SelectAutoComplete'
import { getAllSubTypesByType, getSubTypes, getSubtypeById } from '~/features/Subtype/services/index'
import ISubtype from '~/features/Subtype/interfaces/ISubtype';
import { State } from '@hookstate/core';
import FilterAutocompleteSubtypesByType from '~/components/FilterAutocompleteSubtypeByType';
import useEntryState from '../../stores/EntryState';
import { Validation as validation } from '@hookstate/validation';
import { Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';

interface SubtypeSelectFieldProps {
  readonly readonly?: boolean;
  readonly isValid: boolean;
  readonly isInvalidRequest?: boolean;
  readonly serverSide?: boolean;
  readonly typeId: State<number | null>;
  readonly subtypeId: State<number | null>;
  readonly subTypeName: State<string | null>;
  readonly subTypeCode?: State<string | null>;
  readonly showVehicleButton?: State<boolean>;
  readonly showVehicleButtonType?: State<boolean>;
  readonly getTotalSubtypes?: (value: number) => number;
  readonly showVehicleModal?: boolean;
}

export default function SubtypeSelectField({readonly=false, isValid = false, isInvalidRequest = false, typeId, subtypeId, subTypeName, subTypeCode, showVehicleButton, showVehicleButtonType, getTotalSubtypes, showVehicleModal=true}: SubtypeSelectFieldProps){
  const { translate } = useLang();
  const enabledField = isValid && typeId?.get() !== null;
  const { windowManager } = useSystemState();

 
  function onChangeSubType(subtype: ISubtype | null) {
    if (subtype) {
      subtypeId.set(Number(subtype?.id) ?? null);
      subTypeName.set(subtype.name ?? null);
      if (subTypeCode) {
        subTypeCode.set(subtype.code ?? null);
      }
      
      if(showVehicleButton){
        showVehicleButton.set(Boolean(subtype?.ref_to_vehicle))
      }

       
      if (showVehicleButtonType){
        showVehicleButtonType.set(Boolean(subtype?.ref_to_vehicle))
      }

      if(Boolean(subtype?.ref_to_vehicle) && showVehicleModal){
        windowManager()['VehicleModal'].open.set(true)
      } else {
        windowManager()['VehicleModal'].open.set(false)
      }

    } else {
      subtypeId.set(null);
      subTypeName.set(null);
      windowManager()['VehicleModal'].open.set(false)

      if (subTypeCode) {
        subTypeCode.set(null);
      }
      
      if (showVehicleButton && subtype != null) {
        showVehicleButton.set(false)
      }

      if (showVehicleButtonType){
        showVehicleButtonType.set(false)
      }
    }
  }

  if (!readonly) {
  return(
    <FilterAutocompleteSubtypesByType 
      hasType 
      disabled={!enabledField}
      error={enabledField && isInvalidRequest && validation(subtypeId).invalid()}
      typeId={typeId ? typeId.get() : null} 
      subtypeId={subtypeId ? subtypeId.get() : null} 
      onSubtypeChange={onChangeSubType}
      getRowsCount={getTotalSubtypes}
    />
  );
} else {
  return (
    <Typography>{translate('Reference')}: {subTypeName.get() ?? translate('No information')}</Typography>
  )
}
}
