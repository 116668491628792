import React, { useEffect } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { State, useHookstate } from '@hookstate/core';
import { getAssociatedVehicleFromOccurrenceService } from '~/features/Occurrence/services/index';

export interface vehicleHistoryProps {
  readonly id: number,
  readonly plate: string,
  readonly notes: string,
  readonly owner: string,
  readonly owner_phone: string,
  readonly state: {
    readonly id: number,
    readonly name: string,
    readonly UF: string
  },
  readonly vehicle_type: {
    readonly id: number,
    readonly name: string
  },
  readonly vehicle_color: {
    readonly id: number,
    readonly name: string
  },
  readonly vehicle_brand: {
    readonly id: number,
    readonly name: string
  },
  readonly vehicle_model: {
    readonly id: number,
    readonly name: string
  },
  readonly circunstance: {
    readonly id: number,
    readonly name: string
  },
  readonly destination: {
    readonly id: number,
    readonly name: string
  },
}

interface VehiclesRegisteredInTheOccurrencePrintprops {
  readonly VehiclesRegisteredInTheOccurrencePrintResponse: vehicleHistoryProps[]
}

export default function VehiclesRegisteredInTheOccurrencePrint({ VehiclesRegisteredInTheOccurrencePrintResponse }: VehiclesRegisteredInTheOccurrencePrintprops) {
  const { translate } = useLang();

  return (
    <Container fixed={false}>
      <Grid container xs={12} sx={{ marginBottom: 2 }}>
        {VehiclesRegisteredInTheOccurrencePrintResponse.length != 0 ? (
          VehiclesRegisteredInTheOccurrencePrintResponse.map((vehicle) => (
            <Grid container key={vehicle?.id} xs={12} sx={{ margin: 1, border: 1, borderStyle: 'dashed' }}>
              <Grid item xs={3} sx={{ border: 1, borderStyle: 'dashed', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant='subtitle1' sx={{ alignItems: 'center' }}><b>{vehicle?.plate ?? `* ${translate('Uninformed')} *`}</b></Typography>
              </Grid>
              <Grid container xs={9} padding={2}>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'><b>{translate('Plate')}:</b> {vehicle?.plate ?? `* ${translate('Uninformed')} *`} </Typography>
                  <Typography variant='subtitle2'><b>{translate('Model')}:</b> {vehicle?.vehicle_model?.name ?? `* ${translate('Uninformed')} *`} </Typography>
                  <Typography variant='subtitle2'><b>{translate('Circunstance')}:</b> {vehicle?.circunstance?.name ?? `* ${translate('Uninformed')} *`} </Typography>

                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'><b>{translate('Type')}:</b> {vehicle?.vehicle_type?.name ?? `* ${translate('Uninformed')} *`}</Typography>
                  <Typography variant='subtitle2'><b>{translate('Color')}:</b> {vehicle?.vehicle_color?.name ?? `* ${translate('Uninformed')} *`} </Typography>
                  <Typography variant='subtitle2'><b>{translate('Destination')}:</b> {vehicle?.destination?.name ?? `* ${translate('Uninformed')} *`} </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'><b>{translate('Owner')}:</b> {vehicle?.owner ?? `* ${translate('Uninformed')} *`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'><b>{translate('Owner Phone')}:</b> {vehicle?.owner_phone ?? `* ${translate('Uninformed')} *`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'><b>{translate('Notes')}:</b> {vehicle?.notes ?? `* ${translate('Uninformed')} *`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid item xs={12} textAlign={'center'}>
            <Typography variant='subtitle1'><b>{translate('NO INFORMATION REGISTERED')}</b></Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}