import { authFetch } from "~/services/fetch";
import { ClosedStatusResponse } from "../interface";

export async function getAllClosedStatus(): Promise<readonly ClosedStatusResponse[]> {
  try {
    const { data } = await authFetch({
      url: '/dispatch/closed-status',
      method: 'GET',
    })
    return data.sort((a, b) => a.id - b.id);

  } catch (error) {
    throw error;
  }
}