import React from 'react';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { AppRouteProps } from '~/routes';

export default function CamerasRoutes(): readonly AppRouteProps[] {
  const routes: readonly AppRouteProps[] = [
    { path: '/camera', component: Index, isProtected: true, permissions: ['camera_index'] },
    { path: '/camera/create', component: Create, isProtected: true, permissions: ['camera_create'] },
    { path: '/camera/edit/:id', component: Edit, isProtected: true, permissions: ['camera_edit'] },

  ];

  return routes;
}
