import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IOperation from '~/features/Operation/interfaces/IOperation';
import Layout from '~/components/Layout';


export default function Create() {     
  const {register, handleSubmit, control, setValue:setFormValue} = useForm<IOperation>();
  const fields = [
    { name: 'name', type: 'string'},
    { name: 'start_date', type: 'datetime', placeholder: 'Initial Date'},
    { name: 'end_date', type: 'datetime', placeholder: 'End Date'},
    { name: 'active', type: 'boolean'},
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate model={'operation'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        messageSuccess={'Operation successfully created!'}  
        title={'Add Operation'}  
        subheader={'Add a new operation'}
        setFormValue={setFormValue} />
      </Layout>
    )
}
