import React, { useEffect } from 'react';
import { Grid, Typography, IconButton, Tooltip, Chip } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { Replay, Autorenew, EditOff } from '@mui/icons-material';
import ButtonDialog from '~/components/ButtonDialog';
import useOccurrenceState from '../../stores/OccurrenceState';
import { getDispatchesForOccurrenceById, reopenOccurrence } from '~/features/Occurrence/services/index';
import notify from '~/utils/notify';
import { useHookstate } from '@hookstate/core';

import OccurrenceRecallModal, { dataOccurrenceDefault } from '~/features/Occurrence/components/OccurrenceRecallModal';
import SaveButton from '~/components/SaveButton';
import IDispatchOccurrence from '../../interfaces/IDispatchOccurrence';
import { useLocation } from 'react-router-dom';
import useSurveillanceReportingState from '~/features/VideoMonitoring/stores/SurveillanceReportingState';
import OccurrenceCopyButton from '../OccurrenceCopyButton';
import RequestClosureButton from '../ButtonRequestClosure';

interface dataOccurrenceProps {
  readonly type_id: null | string | number;
  readonly subtype_id: null | string | number;
  readonly city_id: null | string | number;
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly location: string | null;
}

export default function ButtonActionsOccurrenceContainer({ occurrenceId }) {
  const { translate } = useLang();
  const { occurrence, occurrenceStatusActions, updateOccurrence, syncOccurrenceEditAttendent } = useOccurrenceState();
  const isDisabled = useHookstate(true);
  const dispatches = useHookstate<readonly IDispatchOccurrence[]>([]);
  const listStatus = useHookstate<readonly IDispatchOccurrence[]>([]);
  const dataOccurrence = useHookstate<dataOccurrenceProps>(dataOccurrenceDefault)
  const recallConfirm = useHookstate(false);
  const openRequester = useHookstate(false)
  const { pathname } = useLocation();
  const isVideoMonitoringPage = pathname === '/video-monitoring'
  const { surveillanceReportingStates } = useSurveillanceReportingState()
  const { searchDispatches, showCheckbox, searchOccurrence } = surveillanceReportingStates();

  const action = () => {
    reopenOccurrence({ id: occurrence().id.get() })
      .then((response) => {
        if (response.status === 200) {
          notify({ message: translate('Occurrence reopen Sucessfully!'), type: 'success' })
          isDisabled.set(true)
          occurrence().closed_at.set(null)
        }
      })
  };

  useEffect(() => {
    if (occurrenceId) {
      getDispatchesForOccurrenceById({ id: occurrenceId })
        .then((response) =>
          dispatches.set(response)
        )
        .then(() => {
          listStatus.set(dispatches?.get().filter(statusDispatch => statusDispatch.status.id === 8))
        })
        .then(() => {
          if (listStatus?.length > 0) {
            isDisabled.set(false)
          } else {
            isDisabled.set(true)
          }
        })
    }
  }, [occurrenceId])

  const openRecall = () => {

    // if (id.get() === null) {
    //   openRequester.set(true);
    // }

    recallConfirm.set(true)
  }

  useEffect(() => {
    dataOccurrence.merge({
      type_id: occurrence().type.id.get(),
      subtype_id: occurrence().subtype.id.get(),
      location: occurrence().location.get(),
      latitude: occurrence().latitude.get(),
      longitude: occurrence().longitude.get(),
      city_id: occurrence().city.id.get(),
    })
  }, [occurrence()])

  return (
    <Grid container padding={1} spacing={1} sx={{ display: 'flex' }}>
      <Grid item>
        <ButtonDialog titleButton={translate('Reopen Occurrence')}
          iconButton={<Replay />}
          disabled={isDisabled.get()}
          action={action}
          dialogTitle={translate('Reopen Occurrence')}
          dialogContent={<Typography>{translate('Do you want to reopen this occurrence?')}</Typography>}
        />
      </Grid>
      <Grid item>
        <IconButton
          title={translate('Recall')}
          disabled={!isDisabled.get()}
          onClick={() => openRecall()}
        >
          <Autorenew />
        </IconButton>
      </Grid>
      <Grid item>
        <OccurrenceCopyButton
          isDisabled={!isDisabled.get()}
        />
      </Grid>
      <Grid>
        <RequestClosureButton
          occurrenceId={occurrence().id}
          closureRequested={occurrence().closure_requested}
          closedAt={occurrence().closed_at}
          isDisabled={!isDisabled.get()}
        />
      </Grid>
      {isVideoMonitoringPage &&
        <Grid item sx={{ mt: 1 }}>
          <Tooltip title={translate('Add Report')}>
            <Chip
              onClick={() => {
                searchDispatches.set(dispatches.get()[0].code)
                searchOccurrence.set(occurrenceId)
              }}
              label={
                <Typography variant="subtitle1" >
                  {translate('Add')}
                </Typography>
              }
            />
          </Tooltip>
        </Grid>}
      {isVideoMonitoringPage &&
        <Grid item sx={{ mt: 1 }}>
          <Tooltip title={translate('Associate to Report')}>

            <Chip
              onClick={() => {
                searchDispatches.set(dispatches.get()[0].code)
                searchOccurrence.set(occurrenceId)
                showCheckbox.set(true)
              }}
              label={
                <Typography variant="subtitle1" >
                  {translate('Associate')}
                </Typography>}
            />
          </Tooltip>
        </Grid>}
      {recallConfirm.get() ?
        <OccurrenceRecallModal
          open={recallConfirm}
          openRequester={openRequester}
          occurrenceId={occurrenceId}
          redirectOccurrence={true}
          dataOccurrence={dataOccurrence}
        /> :
        false
      }
      {occurrenceStatusActions().editingOccurrence.get() ?
        <SaveButton
          canSave={occurrenceStatusActions().editingOccurrence.get()}
          save={() => updateOccurrence()}
        /> :
        false
      }

      {
        occurrenceStatusActions().editingOccurrence.get() ?
          (<Grid item>
            <IconButton
              title={translate('Cancel Edit')}
              onClick={() => {
                syncOccurrenceEditAttendent(occurrence().id.get()),
                  occurrenceStatusActions().editingOccurrence.set(false)
              }}
            >
              <EditOff />
            </IconButton>
          </Grid>
          ) : false
      }

    </Grid>
  )
}