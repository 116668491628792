import React from 'react';
import CountStatsCard from '~/components/CountStatsCard';
import useSystemState from '~/features/System/stores/SystemState';

export default function WidgetListDispatchByStatus({ type, data }) {
  const { getStatusColors, getDeviceStatusColors, getEntryStatusColors } = useSystemState();

  function generateWidget(param) {
    if(param.id && param.name && param.total){
      return (
        type && <CountStatsCard
          key={param.id}
          name={param.name}
          color={type === 'Dispatch' ? getStatusColors(param.id).main : type === 'Entry' ? getEntryStatusColors(param.id).main : getDeviceStatusColors(param.id).main}
          value={param.total ? param?.total : 0} 
        />
      )
    }
    return false
  }

  return (data.map(generateWidget))
}