import React from 'react';
import { SocketContextProvider } from './contexts/SocketContext';
import Routes from './routes';
import { ThemeContextProvider } from './contexts/ThemeContext';
import { CookiesProvider } from 'react-cookie';

function App() {
  return (
    <CookiesProvider>
      <ThemeContextProvider>
          <SocketContextProvider>
            <Routes />
          </SocketContextProvider>
      </ThemeContextProvider>
    </CookiesProvider>
  );
}

export default App;
