import React, { useCallback, useEffect, useState } from 'react';
import useDispatchRegistrationState from '../../stores/DispatchRegistrationState';
import useDispatchState from '../../stores/DispatchState';
import ListRegistrations from '../DispatchRegistrationTab/components/ListRegistrations';
import { useLang } from '~/hooks/useLang';
import { GridActionsCellItem, GridColDef, GridValueGetter } from '@mui/x-data-grid';
import { truncate } from '../../utils/stringsManipulations';
import useDispatchRegistrationVehicleState, { vehicleDefaultState } from '../../stores/DispatchRegistrationVehicleState';
import VehicleForm from './components/VehicleForm';
import { Tooltip } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { saveLogVisualizeQualificators } from '../../services';
import useSocket from '~/hooks/useSocket';
import { IRawVehicle, IVehicleDTO } from '../../interfaces/IDispacthRegistrationVehicle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import LocationDisabledIcon from '@mui/icons-material/LocationDisabled';
import notify from '~/utils/notify';
import { toDomain } from '~/mappers/registration-vehicle-mapper';
import { authFetch } from '~/services/fetch';

interface IVehicle {
  readonly handleClickOpenConfirmAction: (actionName: string) => void;
}

interface IMessageVehicle {
  readonly content: IRawVehicle
  readonly event_type: string
}

// eslint-disable-next-line max-lines-per-function
export default function Vehicle({ handleClickOpenConfirmAction }: IVehicle) {
  const { translate } = useLang();
  const { socket } = useSocket();

  const { dispatch } = useDispatchState();
  const { occurrence, status, id: dispatchIdState } = dispatch();
  const { id: occurrenceIdState } = occurrence;

  const { registrationDispatch } = useDispatchRegistrationState();
  const { addRegistration, loadingRegistrations, registrationRowId, deleteRegistration } = registrationDispatch();

  const { registrationVehicleDispatch, getAllAssociatedVehicle, deleteVehicle } = useDispatchRegistrationVehicleState();
  const { vehicles, vehicle } = registrationVehicleDispatch();
  const [rows, setRows] = useState<readonly IVehicleDTO[]>([])
  const [updateTracking, setUpdateTracking] = useState<boolean>(false)

  const columns: GridColDef[] = [
    {
      field: 'id',
      hideable: true,
    },
    {
      field: 'plate',
      headerName: translate('Plate'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'circunstance',
      headerName: translate('Circunstance'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'destination',
      headerName: translate('Destination'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'integration_created_at',
      headerName: translate('Hora que foi enviada para o tático'),
      flex: 1,
      hideable: true,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'integration_by_user_id',
      headerName: translate('Quem enviou'),
      flex: 1,
      hideable: true,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'integration_canceled_at',
      headerName: translate('Hora que foi cancelada monitoramento'),
      flex: 1,
      hideable: true,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'canceled_by_user_id',
      headerName: translate('Quem cancelou'),
      flex: 1,
      hideable: true,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'notes',
      headerName: translate('Note'),
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return <p>
            ----
          </p>
        }
        return (
          <p>
            <Tooltip title={params.value ?? '----'} placement="bottom">
              <span>
              {params.value ? truncate(params.value, 54) : '----'}
              </span>
            </Tooltip>
          </p>
        )
      }
    },
    {
      field: 'actions', type: 'actions', minWidth: 150 ,flex: 0.8,
      getActions: (params) => [
        status.value.id !== 8 ? <Tooltip key={2} title={translate('Send to tracking')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<LocationSearchingIcon />}
              onClick={() => {
                sendMonitorPlate({ id: params.row.id })
              }}
              disabled={params.row.integrationCreatedAt ? true : false}
              label="Print" />
          </span>
        </Tooltip> : <></>,
        status.value.id !== 8 ? <Tooltip key={2} title={translate('Cancel trackking')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<LocationDisabledIcon />}
              onClick={() => {
                cancelMonitorPlate({ id: params.row.id })
              }}
              disabled={params.row.integrationCreatedAt && !params.row.integrationCanceledAt ? false : true}
              label="Print" />
          </span>
        </Tooltip> : <></>,
        status.value.id === 8 ? <Tooltip key={2} title={translate('View Record')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<RemoveRedEyeIcon />}
              onClick={() => {
                saveLogVisualizeQualificators(params.row.id, '/occurrence/associated-vehicle/generate-view-timeline').then((response) => {
                  if (response.status === 200) {
                    vehicle.set(params.row);
                    addRegistration.set(true)
                  }
                })
              }}
              label="Print" />
          </span>
        </Tooltip> : <></>,
        status.value.id !== 8 ? <Tooltip key={2} title={translate('Edit Record')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<EditIcon />}
              onClick={() => {
                vehicle.set(params.row);
                addRegistration.set(true)
              }}
              label="Print" />
          </span>
        </Tooltip> : <></>,
        status.value.id !== 8 ? <Tooltip key={2} title={translate('Delete Record')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<DeleteIcon />}
              onClick={() => {
                registrationRowId.set(params.row.id);
                handleClickOpenConfirmAction('delete')
              }}
              label="Print" />
          </span>
        </Tooltip> : <></>,

      ]
    },
  ]

  const sendMonitorPlate = ({ id }) => {
    authFetch({
      url: '/occurrence/associated-vehicle/monitor-plate',
      method: 'POST',
      data: {
        id: id
      }
    }).then((response) => {
      if(response.status === 200){
        notify({ message: translate('Plate sent successfully'), type: 'success' })
        setUpdateTracking(!updateTracking)
      }
      return response.data
    })
  }

  const cancelMonitorPlate = ({ id }) => {
    authFetch({
      url: '/occurrence/associated-vehicle/cancel-monitoring',
      method: 'POST',
      data: {
        id: id
      }
    }).then((response) => {
      if (response.status === 200) {
        notify({ message: translate('Tracking canceled successfully'), type: 'success' })
        setUpdateTracking(!updateTracking)
      }
    })
  }

  const handleAdd = () => {
    addRegistration.set(true)
    vehicle.set({
      ...vehicleDefaultState,
      occurrenceId: occurrenceIdState.get(),
    })
  }

  const handleDelete = useCallback(async (id: string) => {
    loadingRegistrations.set(true)
    await deleteVehicle(id)
    notify({ message: translate('Successfully Deleted'), type: 'success' })
    loadingRegistrations.set(false)
  }, [deleteRegistration, deleteVehicle, loadingRegistrations, translate])

  useEffect(() => {
    if (deleteRegistration.get()) {
      handleDelete(registrationRowId.get())
      deleteRegistration.set(false)
    }
  }, [deleteRegistration])

  useEffect(() => {
    loadingRegistrations.set(true);
    getAllAssociatedVehicle(occurrenceIdState.get())
      .then((res) => {
        setRows(res)
        vehicles.set(res)
      })
      .finally(() => {
        loadingRegistrations.set(false)
      })
  }, [updateTracking]);

  const newMessage = useCallback((message: IMessageVehicle) => {
    if (message.event_type === 'OCCURRENCE_ASSOCIATED_VEHICLE_CREATE') {
      if (message.content.dispatch_id !== dispatchIdState.get()) {
        return
      }
      setRows(prev => [...prev, toDomain(message.content)])
    }
    if (message.event_type === 'OCCURRENCE_ASSOCIATED_VEHICLE_DELETE') {
      const filterRows = rows.filter(row => row.id !== message.content.id)
      setRows(filterRows);
    }
    if (message.event_type === 'OCCURRENCE_ASSOCIATED_VEHICLE_CHANGE') {
      const updateRows = rows.map(row => {
        if (row.id === message.content.id) {
          return toDomain(message.content)
        } else {
          return row
        }
      })
      setRows(updateRows);
    }
  }, [rows])

  useEffect(() => {
    socket.on('occurrences', newMessage)
    return () => {
      socket.off('occurrences', newMessage)
    }
  }, [newMessage, socket])


  return (
    addRegistration.get() ? (
      <VehicleForm
        handleClickOpenConfirmAction={handleClickOpenConfirmAction}
      />
    ) : (
      <ListRegistrations
        handleAdd={handleAdd}
        rows={rows}
        columns={columns}
      />
    )
  )
};