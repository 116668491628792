import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IActionTaken from '../interfaces/IActionTaken';
import Layout from '~/components/Layout';

export default function Create() {     
  const {register, handleSubmit, control, setValue:setFormValue} = useForm<IActionTaken>();
  const fields = [
      { name: 'name', type: 'string'},
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate 
          model={'action-taken'} 
          fields={fields} 
          register={register} 
          handleSubmit={handleSubmit} 
          setFormValue={setFormValue} 
          control={control} 
          messageSuccess={'Action taken successfully created!'}  
          title={'Add action taken'}  
          subheader={'Add action taken'}
        />
      </Layout>
    )
}
