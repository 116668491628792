import React from 'react'
import {
  Button,
  Card,
  ListItemButton,
  ListItemText,
  Box,
  Typography,
  Tooltip,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import useUserState from '~/features/User/stores/UserState'
import { MenuProps } from '../../interfaces'
import { useLang } from '~/hooks/useLang'
import IconAdministration from '~/components/IconsSvg/administration'
import OrganizationItem from './traits/OrganizationItem'
import ClassificationItem from './traits/ClassificationItem'
import LocalizationItem from './traits/LocalizationItem'
import QualificationItem from './traits/QualificationItem'

export default function AdministrationMenu({
  activeMenu,
  handleMenuClick,
}: MenuProps) {
  const { translate } = useLang()
  const { verifyPermission } = useUserState()
  const history = useHistory()

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {verifyPermission([
        'agency_index',
        'area_plan_index',
        'station_index',
        'user_index',
        'type_index',
        'subtype_index',
        'entry_origin_index',
        'entry_status_index',
        'dispatch_closed_status_index',
        'device_out_of_service_reason_index',
        'dispatch_group_index',
        'city_index',
        'district_index',
        'vehicle_index',
        'device_index',
        'unit_measurement_index',
        'circunstance_index',
        'destination_index',
        'weapon_kind_index',
        'weapon_type_index',
        'weapon_brand_index',
        'firearm_caliber_index',
        'vehicle_brand_index',
        'vehicle_type_index',
        'vehicle_model_index',
        'vehicle_color_index',
        'ethnicity_index',
        'education_index',
        'document_type_index',
        'involvement_type_index',
        'people_circunstance_index',
        'organization_index',
        'action_taken_index',
        'drug_type_index',
        'object_type_index',
      ]) && (
        <Box>
          <Tooltip title={translate('Administration')} placement="bottom">
            <Button
              className={`main-menu ${
                activeMenu == 'administration' ? 'active' : ''
              }`}
              onClick={() => handleMenuClick('administration')}
            >
              <IconAdministration />
            </Button>
          </Tooltip>
          <Card
            className="submenu-box"
            sx={{
              borderRadius: 0,
              display: activeMenu == 'administration' ? 'block' : 'none',
            }}
          >
            <Typography variant="h4">
              <IconAdministration />
              {translate('Administration')}
            </Typography>

            {verifyPermission(['camera_index']) && (
              <Tooltip
                title={
                  `${translate('Create, read, update and delete')}` +
                  ' ' +
                  `${translate('Cameras')}`
                }
                placement="right"
              >
                <ListItemButton
                  onClick={() => {
                    handleMenuClick('administration')
                    history.push('/camera')
                  }}
                >
                  <ListItemText primary={translate('Cameras')} />
                </ListItemButton>
              </Tooltip>
            )}

            <ClassificationItem handleMenuClick={handleMenuClick} />

            {verifyPermission(['device_index']) && (
              <Tooltip
                title={
                  `${translate('Create, read, update and delete')}` +
                  ' ' +
                  `${translate('Devices')}`
                }
                placement="right"
              >
                <ListItemButton
                  onClick={() => {
                    handleMenuClick('administration')
                    history.push('/device')
                  }}
                >
                  <ListItemText primary={translate('Devices')} />
                </ListItemButton>
              </Tooltip>
            )}

            <LocalizationItem handleMenuClick={handleMenuClick} />

            <OrganizationItem handleMenuClick={handleMenuClick} />

            {/* {verifyPermission(['device_index']) && (
                <Tooltip title={`${translate('Create, read, update and delete')}` + ' ' + `${translate('System Settings')}`} placement="right">
                  <ListItemButton onClick={() => {
                    handleMenuClick('administration')
                    history.push("/system-settings")
                  }}>
                    <ListItemText primary={translate('System Settings')} />
                  </ListItemButton>
                </Tooltip>
              )} */}
            <QualificationItem handleMenuClick={handleMenuClick} />

            {verifyPermission(['vehicle_index']) && (
              <Tooltip
                title={
                  `${translate('Create, read, update and delete')}` +
                  ' ' +
                  `${translate('Vehicles')}`
                }
                placement="right"
              >
                <ListItemButton
                  onClick={() => {
                    handleMenuClick('administration')
                    history.push('/vehicle')
                  }}
                >
                  <ListItemText primary={translate('Vehicles')} />
                </ListItemButton>
              </Tooltip>
            )}
          </Card>
        </Box>
      )}
    </>
  )
}
