import React, { useEffect } from 'react';
import DisplayDispatchVehicle from '~/components/DisplayDispatchVehicle';
import { getDeviceInfoService } from '~/features/Device/services/index';
import DeviceInfoDialog from '~/features/Device/components/DeviceInfoDialog';
import { IconButton } from '@mui/material';
import { useHookstate } from '@hookstate/core';
import { InfoOutlined } from '@mui/icons-material';

export default function IconButtonInfoPopupMarker({markerPoint, motiveState, outServiceTime}){
  const openDeviceComposition = useHookstate(false);
  const labelInfo = useHookstate('Carregando...');

  const openCompositionDevice = () => {
    return openDeviceComposition.set(true)
  }

  useEffect(() => {
    getDeviceInfoService({id: markerPoint?.id}).then((data) => {
      labelInfo.set(!data?.device_log?.task || data?.device_log?.task == '' ? 'Atuação não informada' : data?.device_log?.task);
      markerPoint?.status == 11 ? motiveState.set(data?.out_of_service?.reason?.name ? data?.out_of_service?.reason?.name : null) : false
      markerPoint?.status == 11 ? outServiceTime.set(data?.out_of_service?.out_of_service_date ? data?.out_of_service?.out_of_service_date : null) : false
    })
  }, [markerPoint?.id, markerPoint?.status])

  return (
    <>
      <IconButton title={labelInfo.get()} sx={{margin:'0 !important', padding:'1px'}} disabled={markerPoint?.status == 10} onClick={ openCompositionDevice } size='small' >
        { <InfoOutlined /> }
      </IconButton>
      {openDeviceComposition.get() && <DeviceInfoDialog open={openDeviceComposition} deviceId={markerPoint?.id} devicePlate={markerPoint?.plate} deviceIdentifier={markerPoint?.identifier}/>}
    </>
  )
}