import * as React from "react";
import { Card, CardContent, Typography, Divider, CardMedia, IconButton, Box } from '@mui/material'
import ModalImage from "../ModalImage";
import { CardFooter } from "../Footer";
import { useState } from "react";
import { truncateText } from "~/utils/strings";
import { Download } from "@mui/icons-material";
import { useHookstate } from "@hookstate/core";
import { useLang } from "~/hooks/useLang";
import Zoom from 'react-medium-image-zoom'
import { downloadFile, getTypeFile } from "~/utils/media";


export function Media({ commentData }) {
  const [openImage, setOpenImage] = useState(false);
  const typeDocument = useHookstate<string | null>(null)
  const { translate } = useLang()


  const handleOpen = () => setOpenImage(true);
  const handleClose = () => setOpenImage(false);

  const {
    mediaPath,
    comment,
    fileType,
    fileName,
  } = commentData.get();

  React.useEffect(() => {
    getTypeFile(fileType)
  }, [])


  return (
    <>
      <ModalImage
        handleClose={handleClose}
        handleOpen={handleOpen}
        commentData={commentData}
        open={openImage} />
      <Card variant="elevation" color="background.paper" elevation={2} sx={{ width: '100%' }}>
        <CardContent sx={{ padding: 1 }}>
          <Typography style={{ wordWrap: "break-word", marginBottom: "0" }} fontWeight="400" paragraph={true}>
            {!!fileType && fileType === "png" || fileType === "jpeg" ?
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                width={'100%'}

              >
                <Zoom zoomMargin={50}>
                  <CardMedia
                    component="img"
                    image={mediaPath}
                    alt={comment}
                    sx={{
                      width: '100px',
                    }}
                  />
                </Zoom>
              </Box>
              :
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography>
                  {truncateText(`${fileName ? fileName : translate('File')}`, 30)}
                </Typography>
                <IconButton
                  title={translate('Download file')}
                  onClick={() =>
                    downloadFile({
                      mediaPath: mediaPath,
                      fileType: fileType,
                      typeDocument: typeDocument,
                      isMount: true,
                      fileName: fileName,
                    })
                  }>
                  <Download />
                </IconButton>
              </Box>}
          </Typography>
        </CardContent>

        <Divider />
        <CardFooter commentData={commentData} />
      </Card>
    </>
  );
}
