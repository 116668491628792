import React, { useEffect } from 'react';
import CrudIndex from '~/components/CrudIndex';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';


export default function Index() {
  const { translate } = useLang();

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])
  return (
    <Layout marginLayout={true}>
      <CrudIndex
        model='friendly-type'
        title='Type/SubType Friendly'
        aliasLabel={[
          { column: 'type_id', label: 'Id Tipo' },
          { column: 'type:name', label: 'Nome do Tipo' },
          { column: 'type:code', label: 'Código do tipo' },
          { column: 'subtype:name', label: 'Nome do subtipo' },
          { column: 'subtype:code', label: 'Código do subtipo' },

        ]}
      />
    </Layout>
  )
}
