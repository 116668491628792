import React from 'react';
import {IconButton, Grid} from '@mui/material';
import {Star, StarBorderOutlined} from '@mui/icons-material';
import { useLang } from '~/hooks/useLang';
import { authFetch } from '~/services/fetch';

interface HighlightProps {
  readonly canHighlight?: boolean | undefined;
  readonly dispatchId?: null | number | string;
  readonly highlight?: null | boolean;
  readonly setHighlight?: VoidFunction;
}

export default function HighlightButton(props: HighlightProps) {
  const { translate } = useLang()
  const { canHighlight, highlight, dispatchId, setHighlight } = props;    
   
  const action = () => {              
    const url = highlight ? '/dispatch/remove-feature-occurence' : '/dispatch/add-feature-occurence';
            
      authFetch({
          url: url,
          method: 'POST',
          data: {
            dispatch_id: dispatchId,
          }
      }).then(({ data }) => {
          if(setHighlight)
            setHighlight();
      });      
  };  

  return (
    <Grid item>
      <IconButton title={"Destacar Ocorrência"} disabled={!canHighlight} onClick={action}>
          { highlight ? <Star color='warning'/> : <StarBorderOutlined/>  }  
      </IconButton>
    </Grid>);
}
