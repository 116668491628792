import React from 'react'
import { useHookstate } from '@hookstate/core';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import CrudCreate from '~/components/CrudCreate';
import Layout from '~/components/Layout';
import { SimpleDialogBox } from '~/components/SimpleDialogBox';
import { useLang } from '~/hooks/useLang';
import IStation from '../interfaces/IStation';


export default function Create() {
  const { translate } = useLang()
  const history = useHistory();
  const { register, handleSubmit, control, setValue: setFormValue } = useForm<IStation>();
  const fields = [
    { name: 'name', type: 'string', required: true },
    { name: 'agencyId', type: 'selectAutoComplete', model: 'agency', placeholder: 'Agency:name', required: true},
    { name: 'contactCenterId', type: 'selectAutoComplete', model: 'contact-center', placeholder: 'Contact Center', method: 'GET' },
    { name: 'branchLine', type: 'string', placeholder: 'Branch Line' },
    { name: 'serialHd', type: 'string', placeholder: 'Serial HD' },
    { name: 'macAddress', type: 'string', placeholder: 'MAC Address' },
    { name: 'dispatch_groups', type: 'transferList', options: { firstListUrl: '', secondListUrl: '/dispatch/group' }, placeholder: 'Associated Dispatch Group' }
  ];

  const openDialog = useHookstate(false);
  const dialogBoxTitle = useHookstate("");
  const dialogBoxContent = useHookstate("");

  const handleDialogBoxOpen = () => {
    openDialog.set(true);
  };

  const handleDialogNext = () => {
    openDialog.set(false);
    history.push(`/station`);
  };

  const callAuthenticationBox = (data) => {
    dialogBoxTitle.set(translate('Authentication Key'));
    dialogBoxContent.set(data.authentication_key);
    handleDialogBoxOpen();
  }

  return (
    <Layout marginLayout={true}>
      <CrudCreate model={'station'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Station successfully created!'}
        title={'Create New Work Station'}
        subheader={'Create New Work Station'}
        setFormValue={setFormValue}
        afterSave={(data) => callAuthenticationBox(data)} />
      <SimpleDialogBox handleClose={handleDialogNext} open={openDialog} title={dialogBoxTitle} content={dialogBoxContent} />
    </Layout>
  )
}
