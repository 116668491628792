import React from "react";
import useEntryCommentState from '../../stores/EntryCommentState'
import { useState, Downgraded, State } from "@hookstate/core";
import { DisplayComments } from "~/components/DisplayComments";
import { CommentDataProps } from "~/features/Dispatch/stores/DispatchCommentState";

const defaultCommentsState: readonly CommentDataProps[] = []

interface EntryDisplayCommentsProps {
  readonly commentIsCompressed: State<boolean>;
  readonly moveScrolltoComment?: boolean;
  readonly entryId: number | null;
}

export function EntryDisplayComments({ commentIsCompressed, moveScrolltoComment, entryId }: EntryDisplayCommentsProps) {

  const { allCurrentComments, sortComment, loading, retryConnect, syncPreviousComments } = useEntryCommentState();
  const commentList = useState(defaultCommentsState)
  const allCommentsValue = allCurrentComments().attach(Downgraded).value;

  React.useEffect(() => {
    commentList.set(allCommentsValue ? allCommentsValue.map(element => element).sort(sortComment) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCommentsValue]);

  return (
    <DisplayComments
      isEdit={true}
      isDispatch={false}
      comments={commentList}
      commentIsCompressed={commentIsCompressed}
      moveScrolltoComment={moveScrolltoComment}
      loading={loading().get()}
      refresh={retryConnect().get()}
      refreshAction={() => entryId ? syncPreviousComments(entryId) : false}
    />
  )
}
