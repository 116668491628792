import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';
import StandardModal from '~/components/StandardModal';
import { Tune } from '@mui/icons-material';
import { Grid, IconButton, Button, CircularProgress } from '@mui/material';
import { storeScreenSettings } from '~/features/System/services';
import InputDateTime from '~/components/InputDate';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { Search } from '@mui/icons-material'
import FilterAutocompleteCities from '~/components/FilterAutocompleteCities';
import { Downgraded, useHookstate } from '@hookstate/core';
import { getMarkersDispatchFilteredPrevisionsService, getMarkersPrevisionService } from '../../services/request';
import { useMarkerState } from '../../stores/MapState';
import notify from '~/utils/notify';
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable';
import { authFetch } from '~/services/fetch';
import { useLocation } from 'react-router-dom';

const modal = document.getElementById('div-modal') as HTMLElement;

// eslint-disable-next-line max-lines-per-function
export default function MapFiltersModalDraggable() {
  const { translate } = useLang();
  const { windowManager, loadingButton } = useSystemState();
  const { markersState } = useMarkerState();
  const location = useLocation();

  const dateFinishMax = useHookstate<Dayjs | undefined>(undefined);
  const dateFinishMin = useHookstate<Dayjs | undefined>(undefined);
  const dateStartMax = useHookstate<Dayjs | undefined>(undefined);
  const dateStartMin = useHookstate<Dayjs | undefined>(undefined);
  const loading = useHookstate<boolean>(false)
  const loadingComponent = useHookstate(true)
  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })

  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      if ('MapFiltersModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.MapFiltersModalDraggable)
      }
      loadingComponent.set(false)
    })
  }, [])

  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    event.preventDefault();

    getPositionsDraggable.x_position.set(dragElement.x);
    getPositionsDraggable.y_position.set(dragElement.y);
  };

  const error = useHookstate({
    start_date: false,
    finish_date: false,
    cities: false
  })

  const handleSearchPrevision = () => {
    if (!windowManager()['mapFilters']['valueFilter']['city_ids']?.get()?.length || !windowManager()['mapFilters']['valueFilter']['start_date'].get() || !windowManager()['mapFilters']['valueFilter']['finish_date'].get()) {

      !windowManager()['mapFilters']['valueFilter']['start_date'].get() && error.start_date.set(true)
      !windowManager()['mapFilters']['valueFilter']['finish_date'].get() && error.finish_date.set(true)
      !windowManager()['mapFilters']['valueFilter']['city_ids']?.get()?.length && error.cities.set(true)


      notify({ message: translate('It is necessary to inform the period and at least one city.'), type: 'error' })
    } else {
      loading.set(true)
      getMarkersPrevisionService({
        start_date: windowManager()['mapFilters']['valueFilter']['start_date']?.attach(Downgraded).get(),
        finish_date: windowManager()['mapFilters']['valueFilter']['finish_date']?.attach(Downgraded).get(),
        city: windowManager()['mapFilters']['valueFilter']['city_ids']?.get()
      }).then((response) => {
        markersState()['prediction']?.set(response)
      }).finally(() => {
        dateFinishMax.set(undefined)
        dateFinishMin.set(undefined)
        dateStartMax.set(undefined)
        dateStartMin.set(undefined)
      })
      getMarkersDispatchFilteredPrevisionsService({
        start_date: windowManager()['mapFilters']['valueFilter']['start_date']?.attach(Downgraded).get(),
        finish_date: windowManager()['mapFilters']['valueFilter']['finish_date']?.attach(Downgraded).get(),
        city: windowManager()['mapFilters']['valueFilter']['city_ids']?.get()
      }).then((response) => {
        markersState()['dispatchFiltered']?.set(response)
      }).finally(() => loading.set(false))
    }

  }

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'MapFiltersModalDraggable',
        isOpen: windowManager()['mapFilters'].open.get(),
        isMinimized: getPositionsDraggable.is_window_minimized?.get(),
        positionX: getPositionsDraggable?.x_position?.get(),
        positionY: getPositionsDraggable?.y_position?.get(),
      })
    }
  }, [
    windowManager()['mapFilters'].open.get(),
    getPositionsDraggable.is_window_minimized?.get(),
    getPositionsDraggable?.x_position?.get(),
    getPositionsDraggable?.y_position?.get(),
  ])

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {windowManager()['mapFilters'].open.value && !loadingComponent.get() &&
        <StandardModal
          title={translate('Dispatch Prediction Map')}
          subheader={translate('Select future period')}
          handleStop={handleStop}
          position={{
            x: getPositionsDraggable?.x_position?.get() ? Number(getPositionsDraggable?.x_position?.get()) : 80,
            y: getPositionsDraggable?.y_position?.get() ? Number(getPositionsDraggable?.y_position?.get()) : 141
          }}
          closeButton={!(windowManager()['mapFilters'].disabled.value) ? () => windowManager()['mapFilters'].open.set(false) : false}
          isExpanded={getPositionsDraggable.is_window_minimized}
          dimensions={{
            width: 950
          }}
        >
          <Grid container display={'flex'} flexDirection={'row'} >
            <Grid item xs={6} >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={'pt-br'}
              >
                <InputDateTime
                  sx={{ width: '50%', mt: 1, pr: 1 }}
                  disabled={false}
                  inputFormat='DD/MM/YYYY HH:mm'
                  placeholder={translate('dd/mm/yyyy')}
                  onDateTimeChange={(date) => {
                    if (date) {
                      error.start_date.set(false)
                      dateFinishMax.set(date.set('second', 86400).startOf('minute'))
                      dateFinishMin.set(date)
                      windowManager()['mapFilters']['valueFilter']['finish_date'].set(null)
                    } else {
                      dateFinishMax.set(undefined)
                      dateFinishMin.set(undefined)
                    }

                    windowManager()['mapFilters']['valueFilter']['start_date'].set(date)                    
                  }}
                  label={translate('Initial Date')}
                  required
                  error={error.start_date.get()}
                  dateMin={dateStartMin.attach(Downgraded).get()}
                  dateMax={dateStartMax.attach(Downgraded).get()}
                  disableFuture={false}
                  dateTime={ windowManager()['mapFilters']['valueFilter']['start_date'].attach(Downgraded).get() }
                />
                <InputDateTime
                  sx={{ width: '50%', mt: 1, pr: 1 }}
                  disabled={false}
                  inputFormat='DD/MM/YYYY HH:mm'
                  placeholder={translate('dd/mm/yyyy')}
                  onDateTimeChange={(date) => {
                    if (date) {
                      error.finish_date.set(false)
                      dateStartMax.set(date)
                      dateStartMin.set(date.set('second', -86400).startOf('minute'))
                    } else {
                      dateStartMax.set(undefined)
                      dateStartMin.set(undefined)
                    }

                    windowManager()['mapFilters']['valueFilter']['finish_date'].set(date)
                  }}
                  label={translate('End Date')}
                  required
                  error={error.finish_date.get()}
                  dateMin={dateFinishMin.attach(Downgraded).get()}
                  dateMax={dateFinishMax.attach(Downgraded).get()}
                  disableFuture={false}
                  dateTime={ windowManager()['mapFilters']['valueFilter']['finish_date'].attach(Downgraded).get() }
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={5} sx={{ mt: 1, pr: 1 }}>
              <FilterAutocompleteCities
                citiesId={windowManager()['mapFilters']['valueFilter']['city_ids'].get()}
                onCitiesChange={(city) => {
                  city.length && error.cities.set(false)
                  windowManager()['mapFilters']['valueFilter']['city_ids'].set(city)
                }}
                disabled={false}
                required
                error={error.cities.get()}
              />
            </Grid>
            <Grid item display={'flex'} justifyContent={'right'} sx={{ mr: 0, ml: 'auto', p: 1 }}>
              <Button
                variant="text"
                onClick={() => {
                  windowManager()['mapFilters']['valueFilter']['start_date'].set(null)
                  windowManager()['mapFilters']['valueFilter']['finish_date'].set(null)
                  windowManager()['mapFilters']['valueFilter']['city_ids'].set([])

                  error.cities.set(false)
                  error.finish_date.set(false)
                  error.start_date.set(false)

                  dateFinishMax.set(undefined)
                  dateFinishMin.set(undefined)
                  dateStartMax.set(undefined)
                  dateStartMin.set(undefined)
                }}
                sx={{
                  height: '40px',
                  textTransform: 'none',
                  textDecorationLine: 'underline',
                  color: 'text.primary',
                  ':hover': {
                    bgcolor: 'transparent',
                    color: 'primary.main'
                  }
                }}
              >
                {translate('Clear filters')}
              </Button>
              <IconButton onClick={() => handleSearchPrevision()} disabled={false} title={translate('Search')}>
                {loading.get() ? <CircularProgress size={30}/> : <Search />}
              </IconButton>
            </Grid>
          </Grid>
        </StandardModal>
      }
    </>
    , modal)
}