import { AxiosError } from "axios";
import { IParamsTable } from "~/interfaces/IParamsTable";
import { authFetch } from "~/services/fetch";

export async function getClosedStatusClass(id) {
    try {
        const response = await authFetch({
            url: `/closed-status-class/${id}`,
            method: 'GET',
        });

        return response.data
    } catch (error) {
        const err = error as AxiosError;
        throw err.response?.data?.error || err.response?.data?.message || err.message;
    }

}

export async function deleteClosedStatusClass(id) {
    try {
        await authFetch({
            url: `/closed-status-class/${id}`,
            method: 'DELETE',
        });

    } catch (error) {
        const err = error as AxiosError;
        throw err.response?.data?.error || err.response?.data?.message || err.message;
    }

}

export async function listClosedStatusClass({
    filters = [],
    limit = 100000,
    orders = [
        { field: 'name', sort: 'asc' }
    ]
}: IParamsTable) {
    try {
        const response = await authFetch({
            url: '/closed-status-class',
            method: 'POST',
            data: {
                limit,
                filters,
                orders
            }
        })
        return response.data.data;
    } catch (error) {
        const err = error as AxiosError;
        throw err.response?.data?.error || err.response?.data?.message || err.message;
    }
}