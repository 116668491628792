import React, { useState } from 'react';
interface ImageRendererProps {
    base64?: boolean,
    alt?: string
    src: string,
    height: string,
}

export default function ImageRenderer({ base64 = false, alt = 'Image', src, height }: ImageRendererProps) {
    const [srcState, ] = useState(() => {
        if (base64) return `data:image/png;base64,${src}`;

        return src;
    });


    return <img src={srcState} alt={alt} height={height}/>;
}
