import React from "react";
import {IconButton, Grid} from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useLang } from '~/hooks/useLang';


export default function ListHistoryButton({hasHistory, historyUrl}){
  const { translate } = useLang()
  const ClickOpen = () => {
    window.open(historyUrl, "window-suport, width=auto, height=600, status = no");
  }
  
  return (
    <Grid item>
      <IconButton title={translate('See Window Support')} disabled={!hasHistory} onClick={ClickOpen}>
        <ListAltIcon/>
      </IconButton>
    </Grid>
  );

}