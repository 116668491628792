import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';


export default function Edit() {
    const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm<any>();
    const fields = [
        { name: 'id', type: 'hidden' },
        { name: 'name', type: 'string' },
        {
            name: 'interest_point_type_id',
            type: 'selectAutoComplete',
            model: 'interest-point/type',
            placeholder: 'Interest point type'
        },
    ];

    return (
        <CrudEdit model={'interest-point/subtype'}
            fields={fields}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            setFormValue={setFormValue}
            messageSuccess={'Interest point subtype successfully edited!'}
            title={'Edit Interest point subtype'}
            getValues={getValues}
        />
    )
}



