import React from 'react';
import { IconButton, Grid } from '@mui/material';
import { GroupAdd } from '@mui/icons-material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import EntrySetDispatchGroupDialog from './components/EntrySetDispatchGroupDialog';

interface DispatchCopyButtonProps {
  readonly canSetDispatchGroup: boolean | undefined;
  readonly setManuallyDispatchGroup: (params: any) => Promise<void> | undefined;
}

export default function EntrySetDispatchGroupButton({ ...props }: DispatchCopyButtonProps) {
  const { translate } = useLang()
  const open = useHookstate(false);

  return (
    <Grid item>
      <IconButton title={translate('Add Dispatch Group')} disabled={!props.canSetDispatchGroup} onClick={() => open.set(true)}>
        <GroupAdd />
      </IconButton>
      {open.get() && <EntrySetDispatchGroupDialog open={open} setManuallyDispatchGroup={props.setManuallyDispatchGroup}/>}
    </Grid>);
}