import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLang } from '~/hooks/useLang';

interface SelectConsummatedAndAttemptedProps {
  readonly valueDefault: string | 'status';
  readonly disabled: boolean;
  readonly onChangeGroupBy: (value: string | 'status') => void;
}

export default function SelectGroupBy({ valueDefault, onChangeGroupBy }: SelectConsummatedAndAttemptedProps) {
  const { translate } = useLang();
  const [value, setValue] = useState(valueDefault)

  useEffect(() => {
    setValue(valueDefault)
    onChangeGroupBy(valueDefault)

  }, [valueDefault])

  return (
    <FormControl fullWidth size='small'>
      <InputLabel id='SelecGroupBy'>
        {translate('Group by')}
      </InputLabel>
      <Select
        fullWidth
        labelId='SelecGroupBy'
        id='SelecGroupBy'
        value={value}
        label={translate('Group by')}
        onChange={(event) => {
          setValue(event.target.value)
          onChangeGroupBy(event.target.value || 'status')
        }}
      >
        <MenuItem sx={{ mt: 1 }} value={'status'} >
          {translate('Status')}
        </MenuItem>
        <MenuItem sx={{ mt: 1 }} value={'user'} >
          {translate('User')}
        </MenuItem>
      </Select>
    </FormControl>
  )
}
