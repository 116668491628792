import React, { ChangeEvent, useEffect } from 'react';
import { TextField } from '@mui/material';
import { State, useState } from '@hookstate/core';

type TextFieldEntryFormProps = {
  state: State<string|null>,
  id: string,
  name: string,
  label: string
  autoComplete?: string,
  required: boolean,
  disabled: boolean
}

export default function TextFieldEntryForm(props: TextFieldEntryFormProps) {
  const state = useState(props.state)

  return(
    <TextField
      autoComplete={props.autoComplete}
      name={props.name}
      id={props.id}
      label={props.label}
      required={props.required}
      fullWidth
      size="small"
      autoFocus
      disabled={props.disabled}
      value={ !state.get() ? '' : state.get() }
      onChange={(event: ChangeEvent<HTMLInputElement>) => {state.set(event.target.value)}}
    />
  )
}

