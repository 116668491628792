import { Downgraded, useHookstate } from '@hookstate/core';
import React, { useEffect } from 'react'
import { getStatisticsDispatchByDeviceService } from '~/features/Dispatch/services';
import ColumnChart from '~/components/ColumnChart';

export default function DispatchByDeviceBarChart({operationId, initialDate, endDate, refresh, loading}) {
  const data = useHookstate([]);

  useEffect(() => {
    loading.set(true)
    getStatisticsDispatchByDeviceService({ operationId: operationId, initialDate: initialDate, endDate: endDate }).then((response) => {
        const result = response.map((responseData) => ({ item: responseData.name, value: responseData.total }))
      return data.set(result);
    }).finally(() => {
      loading.set(false)
    })
  }, [refresh]);

  return (
    <ColumnChart data={data.attach(Downgraded).get()} title={'Graph of Occurrences by Device'} sxFont={{ fontWeight: 'bold', fontSize: 16 }} />
  )
}