import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import Layout from '~/components/Layout';
import type { IOccupation } from '../interfaces/IOccupation';

export default function Create() {
    const { register, handleSubmit, control, setValue: setFormValue } = useForm<IOccupation>();
    const fields = [
        { name: 'name', type: 'string' },
    ];

    return (
        <Layout marginLayout={true}>
            <CrudCreate model={'occupation'}
                fields={fields}
                register={register}
                handleSubmit={handleSubmit}
                control={control}
                messageSuccess={'Gender successfully created!'}
                title={'Add occupation'}
                subheader={'Add occupation'}
                setFormValue={setFormValue} />
        </Layout>
    )
}
