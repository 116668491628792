import { State, useHookstate } from "@hookstate/core";
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, List, ListItem, ListItemText, Paper, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect } from "react";
import { useLang } from "~/hooks/useLang";
import { useTheme } from "~/hooks/useTheme";
import IListDispatchSocket, { IMessageObject } from "../interfaces/IListDispatchesProps";
import { authFetch } from "~/services/fetch";
import notify from "~/utils/notify";
import { saveDraft } from "~/features/Dispatch/services";
import useDispatchState from "~/features/Dispatch/stores/DispatchState";
import CloseIcon from '@mui/icons-material/Close';
import useSocket from "~/hooks/useSocket";
import { getDisptachGroupById } from "~/features/DispatchGroup/services";
import { element } from "prop-types";
import { IFilters } from "../services";
import useSystemState from "~/features/System/stores/SystemState";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { LoadingButton } from "@mui/lab";


interface IDispatchNotClosed {
  readonly dispatch: string | null,
  readonly error: string | null
}

interface DispatchClosedProps {
  readonly id: number | null;
  readonly name: string | null;
  readonly acronym: string | null;
}

interface DialogCloseMuiltipleDispatchProps {
  readonly openDialog: State<boolean>;
  readonly onClose: any;
  readonly rowsSelected: State<any>;
  readonly enableCheckBox: State<boolean>;
  readonly enableButtonShowResume: State<boolean>;
  readonly handleLoadDespatches?: (filters?: readonly IFilters[]) => void;
}

interface listResultCloseMultipleDispatchesProps {
  readonly dispatch: string;
  readonly dispatch_group_id: number;
  readonly error: string;
}

// eslint-disable-next-line max-lines-per-function
export default function DialogCloseMuiltipleDispatch({ onClose, openDialog, rowsSelected, enableButtonShowResume, handleLoadDespatches }: DialogCloseMuiltipleDispatchProps) {
  const { translate } = useLang();
  const { darkMode } = useTheme();
  const { socket } = useSocket();
  const { getStatusColors } = useSystemState();
  const { dispatch, openModalResultMultipleCloseDispatches, syncDispatchFromId, getDispatchByCode } = useDispatchState();
  const dispatchErrorClosed = useHookstate<ReadonlyArray<IDispatchNotClosed> | null>(null);
  const dispatchClosedList = useHookstate<ReadonlyArray<DispatchClosedProps>>([]);
  const closedStatus = useHookstate<DispatchClosedProps | null>(null);
  const comment = useHookstate<string>('')
  const listResultCloseMultipleDispatches = useHookstate<listResultCloseMultipleDispatchesProps[]>([])
  const verifyExistCloseDispatch = useHookstate(false)
  const verifyExistErrorDispatch = useHookstate(false)


  const openDialogErrorClosed = useHookstate(false)
  const loading = useHookstate(false);

  const handleClose = () => {
    openDialog.set(false);
  };

  const clickOutModalErroClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      openDialogErrorClosed.set(false);
    }
  };

  const handleCLoseModalError = () => {
    openDialogErrorClosed.set(false)
    dispatchErrorClosed.set(null)
    comment.set('')
    closedStatus.set(null)
    openModalResultMultipleCloseDispatches().set(false)
    enableButtonShowResume.set(false)
  }


  useEffect(() => {
    authFetch({
      url: '/dispatch/closed-status',
      method: 'GET',
    }).then(({ data }) => {
      if (data.length) {
        dispatchClosedList.set(data.sort((a, b) => a.id - b.id));
      }
    })
    openModalResultMultipleCloseDispatches().set(false)
  }, [])

  const handleCloseMultipleDispatchs = (dispatchs) => {
    const ids = dispatchs.map(dispatch => dispatch.id)
    loading.set(true)
    openDialog.set(false);
    

    authFetch({
      url: '/dispatch/close-multiple',
      method: 'POST',
      data: {
        dispatch_ids: ids,
        closed_status_id: closedStatus.get()?.id,
        comment: comment.get()
      }
    }).then((response) => {
      if (response.data.length > 0) {
        dispatchErrorClosed.set(response.data)
        openDialogErrorClosed.set(true)
      }

      if (response.status === 200) {
        notify({
          message: 'O fechamento em lote será processado e você receberá um aviso quando o processo for concluído.',
          type: 'info'
        })
        enableButtonShowResume.set(true)
      }
      comment.set('')
      closedStatus.set(null)
      handleLoadDespatches ? handleLoadDespatches() : false
    }).finally(() => {
      loading.set(false)
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const newMessage = (message: any) => {
    if (message.event_type === 'MULTIPLE_CLOSURE_COMPLETED') {
      listResultCloseMultipleDispatches.set(message.content)
      const notClosed = listResultCloseMultipleDispatches.get()?.filter((element) => element.error === '').map(item => item.dispatch)
      const errorCloses = listResultCloseMultipleDispatches.get()?.filter((element) => element.error.length > 0).map(item => item.dispatch)


      notClosed.length === 0 ? verifyExistCloseDispatch.set(false) : verifyExistCloseDispatch.set(true);
      errorCloses.length === 0 ? verifyExistErrorDispatch.set(false) : verifyExistErrorDispatch.set(true)
    }
  }

  useEffect(() => {
    socket.on('dispatches', newMessage)
    return () => {
      socket.off('dispatches', newMessage)
    }
  }, [newMessage, socket])

  const openDispatch = (value) => {
    getDispatchByCode(value).promise.then((response) => {
      if (response.length == 1) {
        syncDispatchFromId(response[0].id)
      }
    })
  }

  return (
    <>
      <Dialog
        open={openDialog.get()}
        onClose={onClose}
        disableEscapeKeyDown={true}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle>
          {translate('Dispatch Close')}
        </DialogTitle>
        <DialogContent>
          <Typography variant='subtitle1'>
            {translate('Summary of the selected dispatches')}
          </Typography>
          <List
            sx={{
              width: '100%',
              maxHeight: '500px',
              bgcolor: darkMode ? 'transparent' : '#FFF',
              marginTop: 2,
              overflowY: 'auto',
              border: 1,
              borderColor: 'grey.500',
              borderRadius: 1
            }}
          >
            {!dispatchErrorClosed.get() && rowsSelected?.get().map((item) => (
              <>
                <ListItem key={item.id}>
                  <ListItemText
                    sx={{ display: 'inline', maxHeight: '150px', overflow: 'auto', whiteSpace: 'pre-line' }}
                    primary={`${translate('Code')}: ${item.code}`}
                    secondary={<Typography>
                      {`${translate('Dispatch Group')}: ${item.dispatch_group}\n${translate('Type')}: ${item.type}\n${item.subtype.name && item.subtype.name?.length > 2 ? translate('Subtype') + ': ' + item.subtype : ''}`}
                    </Typography>} />
                </ListItem>

                <Divider />
              </>
            ))}
          </List>
          <FormControl
            fullWidth
            size='small'
            sx={{ padding: "8px 0", marginTop: 1 }}
          >
            <Autocomplete
              options={dispatchClosedList.get()}
              noOptionsText={translate('No options')}
              size='small'
              getOptionLabel={(option) => `${option.acronym ? option.acronym + ' ' + option.name : ''}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={closedStatus.get()}
              onChange={(event, value) => {
                closedStatus.set({ id: value?.id ?? null, name: value?.name ?? '', acronym: value?.acronym ?? '' });
              }}
              renderInput={(params) => <TextField {...params}
                label={`${translate('Closed Status')}`} />} />
          </FormControl>
          <TextField
            multiline
            rows={10}
            required={true}
            name={'comment'}
            id='comment'
            label={translate('Comment')}
            fullWidth
            size='small'
            value={comment.get()}
            sx={{ marginTop: 1 }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              comment.set(event.target.value);
            }} />
        </DialogContent>
        <DialogActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingX: "24px" }}>
          <Button
            variant='outlined'
            onClick={handleClose}>
            {translate('Cancel')}
          </Button>
          <LoadingButton
            loading={loading.get()}
            variant='contained'
            onClick={() => handleCloseMultipleDispatchs(rowsSelected.get())}>
            {translate('Submit')}
          </LoadingButton>

        </DialogActions>
      </Dialog>
      <Dialog
        open={openModalResultMultipleCloseDispatches().get() ?? false}
        onClose={clickOutModalErroClose}
        disableEscapeKeyDown={true}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          {translate('Summary of the latest batch dispatch date')}
          <IconButton
            aria-label="close"
            onClick={() => handleCLoseModalError()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {verifyExistCloseDispatch.get() ? translate('Dispatches closed with success') : translate('No Dispatch has been closed')}
          </Typography>
          {verifyExistCloseDispatch.get() &&
            <List
              sx={{
                width: '100%',
                maxHeight: '500px',
                bgcolor: darkMode ? 'transparent' : '#FFF',
                marginTop: 2,
                overflowY: 'auto',
                border: 1,
                borderColor: 'grey.500',
                borderRadius: 1
              }}
            >
              {listResultCloseMultipleDispatches.get()?.filter((element) => element.error === '').map((item, index) => (
                <>
                  <ListItem key={index} >
                    <ListItemText
                      sx={{ display: 'inline', maxHeight: '150px', overflow: 'auto', whiteSpace: 'pre-line' }}
                      primary={
                        <Typography>
                          {`${translate('Code')}: ${item.dispatch}`}
                        </Typography>
                      }
                    >
                      <Divider />
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          }

          <Typography variant="body1" marginTop={2}>
            {verifyExistErrorDispatch.get() ? translate('Despachos that could not be closed') : translate('There were no closing errors')}
          </Typography>
          {verifyExistErrorDispatch.get() &&
            <List
              sx={{
                width: '100%',
                maxHeight: '500px',
                bgcolor: darkMode ? 'transparent' : '#FFF',
                marginTop: 2,
                overflowY: 'auto',
                border: 1,
                borderColor: 'grey.500',
                borderRadius: 1
              }}
            >
              {listResultCloseMultipleDispatches.get()?.filter((element) => element.error.length > 0).map((item, index) => (
                <>
                  <ListItem key={index}>
                    <ListItemText
                      sx={{ display: 'inline', maxHeight: '150px', overflow: 'auto', whiteSpace: 'pre-line' }}
                      primary={
                        // <Button type="submit" variant="contained"
                        //   // onClick={() => handleClickAction(params)}
                        //   sx={{
                        //     backgroundColor: getStatusColors(1).main,
                        //     color: getStatusColors(1).contrastText,
                        //     '&:hover': {
                        //       backgroundColor: getStatusColors(1).dark,
                        //       color: getStatusColors(1).contrastText,
                        //     },
                        //   }}>
                        //   {`${translate('Code')}: ${item.dispatch}`}
                        // </Button>
                        <>
                          {`${translate('Code')}: ${item.dispatch}`}
                          <IconButton onClick={() => openDispatch(item.dispatch)}>
                            <OpenInNewIcon />
                          </IconButton>
                        </>
                      }
                      secondary={
                        <Typography variant="body2" color='error'>
                          {`${translate('Error')}: ${translate(item.error ? item.error : '')}`}
                        </Typography>
                      }
                    />
                    <Divider />
                  </ListItem >
                  <Divider />
                </>
              ))}
            </List>
          }
        </DialogContent>
      </Dialog >
    </>
  )
}