/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from '@mui/material'
import { useLang } from '~/hooks/useLang'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { OptionPeriodProps } from './interface'
import InputDateTime from '../InputDate'
import { useHookstate } from '@hookstate/core'

interface PeriodProps {
  readonly onStartDateChange: (value: Dayjs | null) => void
  readonly onFinishDateChange: (value: Dayjs | null) => void
  readonly onPeriodChange: (value: string) => void
  readonly hidePeriod?: boolean
  readonly period: string
  readonly disabled?: boolean
  readonly xsPeriod?: number
  readonly xsDateBetween?: number
  readonly marginDateBetween?: number
  readonly disableFuture?: boolean
  readonly periodOptions?: OptionPeriodProps[]
  readonly sxDates?: SxProps<Theme>
  readonly sxInitialDate?: SxProps<Theme>
  readonly sxFinalDate?: SxProps<Theme>
  readonly sxPeriod?: SxProps<Theme>
  readonly required?: boolean;
  readonly dateWidth?: string | number;
}

export default function FilterSelectPeriod({
  disabled,
  onPeriodChange,
  period,
  onStartDateChange,
  onFinishDateChange,
  hidePeriod = false,
  xsPeriod = 2,
  xsDateBetween = 2,
  marginDateBetween = 0,
  disableFuture = true,
  periodOptions,
  sxDates,
  sxInitialDate = { pl: 1, pt: 1 },
  sxFinalDate = { pl: 1, pt: 1 },
  sxPeriod = { pl: 1, pt: 1 },
  required = false,
  dateWidth = '100%'
}: PeriodProps) {
  const { translate } = useLang()
  const [value, setValue] = useState<string>(period)
  const [dateInitial, setDateInitial] = useState<Dayjs | null>(null)
  const [dateEnd, setDateEnd] = useState<Dayjs | null>(null)
  const dateTime = dayjs()
  const isDisabled = useHookstate<boolean>(false)

  const options: readonly OptionPeriodProps[] = periodOptions
    ? periodOptions
    : [
        { id: 0, number: 24, type: 'hour', name: translate('Last 24 hours') },
        { id: 1, number: 7, type: 'day', name: translate('Last 7 days') },
        { id: 2, number: 1, type: 'month', name: translate('Last month') },
        { id: 3, number: 1, type: 'year', name: translate('Last year') },
      ]

  function isNumeric(value) {
    return /^-?\d+$/.test(value)
  }

  const setDates = (newValue: string) => {
    const option = options.find((item) => item.id == parseInt(newValue))

    if (option) {
      setValue(newValue)
      setDateInitial(dateTime.subtract(option.number, option.type))
      setDateEnd(dateTime)
    } else {
      const dates = newValue.slice(1).split('&')
      setValue('')
      if (dates.length === 1) {
        setDateInitial(null)
        setDateEnd(null)
      } else {
        setDateInitial(dayjs(dates[0]))
        setDateEnd(dayjs(dates[1]))
      }
    }
  }

  useEffect(() => {
    onStartDateChange(dateInitial)
    onFinishDateChange(dateEnd)
  }, [dateInitial, dateEnd])

  useEffect(() => {
    if (period === 'clear') {
      setDateEnd(null)
      setDateInitial(null)
    }
    if (period === 'value') {
      return
    }
    setDates(period)
  }, [period])

  useEffect(() => {
    if (!isNumeric(value)) {
      isDisabled.set(false)
    } else {
      isDisabled.set(true)
    }
  }, [value])

  return (
    <>
      {!hidePeriod && (
        <Grid item xs={xsPeriod} sx={sxPeriod}>
          <FormControl fullWidth size="small">
            <InputLabel disabled={disabled} size="small" id="selectByPeriod">
              {translate('By Period')}
            </InputLabel>
            <Select
              disabled={disabled}
              labelId="selectByPeriod"
              id="selectByPeriod"
              value={value}
              label={translate('By Period')}
              size="small"
              onChange={(event: SelectChangeEvent) => {
                setDates(event.target.value)
                onPeriodChange(event.target.value)
              }}
            >
              <MenuItem value="">
                <em>{translate('None')}</em>
              </MenuItem>
              {options.map((period) => (
                <MenuItem sx={{ mt: 1 }} key={period.id} value={period.id}>
                  {period.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid
        item
        xs={xsDateBetween}
        sx={{ marginLeft: marginDateBetween, width: '100%', ...sxInitialDate }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={'pt-br'}
        >
          <InputDateTime
            sx={{width: dateWidth}}
            disabled={disabled || isDisabled.get()}
            inputFormat="DD/MM/YYYY HH:mm"
            placeholder={translate('dd/mm/yyyy')}
            dateTime={dateInitial}
            onDateTimeChange={(date) => {
              setDateInitial(date)
              onPeriodChange('value')
            }}
            label={translate('Initial Date')}
            disableFuture={disableFuture}
            dateMax={dateEnd ?? undefined}
            required={required}
          />
          {/* <InputDateTime
            sx={sxFinalDate}
            disabled={disabled || isDisabled.get()}
            inputFormat='DD/MM/YYYY HH:mm'
            placeholder={translate('dd/mm/yyyy')}
            dateTime={dateEnd}
            onDateTimeChange={(date) => {
              onPeriodChange('value')
              setDateEnd(date)
            }}
            label={translate('End Date')}
            disableFuture={disableFuture}
            dateMin={dateInitial ?? undefined}
            required={required}
          /> */}
        </LocalizationProvider>
      </Grid>
      <Grid
        item
        xs={xsDateBetween}
        sx={{ marginLeft: marginDateBetween, ...sxFinalDate }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={'pt-br'}
        >
          <InputDateTime
            sx={{width: dateWidth}}
            disabled={disabled || isDisabled.get()}
            inputFormat="DD/MM/YYYY HH:mm"
            placeholder={translate('dd/mm/yyyy')}
            dateTime={dateEnd}
            onDateTimeChange={(date) => {
              onPeriodChange('value')
              setDateEnd(date)
            }}
            label={translate('End Date')}
            disableFuture={disableFuture}
            dateMin={dateInitial ?? undefined}
            required={required}
          />
        </LocalizationProvider>
      </Grid>
    </>
  )
}
