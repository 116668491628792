import React, { useEffect } from 'react';
import { Grid, Paper, Button, Chip, Dialog, DialogTitle, DialogContent, DialogActions, Typography, CircularProgress, Avatar } from '@mui/material';
import { useLang } from '../../../../hooks/useLang';
import { authFetch } from '~/services/fetch';
import useSystemState from '~/features/System/stores/SystemState';
import useDispatchRelatedState from '~/features/Dispatch/stores/DispatchRelatedState';
import { useHookstate } from '@hookstate/core';
import useDispatchState from '../../stores/DispatchState';
import RefreshIcon from '@mui/icons-material/Refresh';

interface dispatchDestinationProps {
  readonly id: number
  readonly code: string
}

export default function DispatchTagList() {
  const { translate } = useLang()
  const { getStatusColors } = useSystemState();
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = React.useState('');
  const [agency, setAgency] = React.useState('');
  const [dispatchGroup, setDispatchGroup] = React.useState('');
  const [type, setType] = React.useState('');
  const [subtype, setSubtype] = React.useState('');
  const [status, setStatus] = React.useState('');
  const { dispatchRelated, syncRelatedDispatches, loading, retryConnect } = useDispatchRelatedState();
  const { syncDispatchFromId, dispatch } = useDispatchState();
  const dispatchDestination = useHookstate<dispatchDestinationProps | null>(null);

  const handleClickOpen = (code) => {
    authFetch({
      url: `/dispatch/get-by-code`,
      method: 'POST',
      data: {
        code: code
      }
    }).then((response) => {
      dispatchDestination.set(response?.data?.unified?.dispatch_destination ? response?.data?.unified?.dispatch_destination : null)
      setCode(code);
      setAgency(` - ${response.data.agency?.name}`);
      setDispatchGroup(`${response.data.dispatch_group?.name}`);
      setType(`${response.data.type?.name}`);
      setSubtype(response.data.subtype ? `- ${response.data.subtype?.name}` : '');
      setStatus(`[${response.data.status?.acronym}]`)
      setOpen(true);
    })
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper variant='outlined' sx={{ width: '100%', height: 215, overflow: 'auto', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', padding: '16px 4px' }}>
      <Grid container width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} gap={1}>

        {!loading().get() ? !retryConnect().get() ? dispatchRelated().keys.map((key) => (
          <Grid item key={dispatchRelated()[key].code.get()}>
            <Chip
              label={dispatchRelated()[key].code.get()}
              onClick={() => handleClickOpen(dispatchRelated()[key].code.get())}
              sx={{
                backgroundColor: getStatusColors(dispatchRelated()[key].status.id.get()).main,
                color: getStatusColors(dispatchRelated()[key].status.id.get()).contrastText,
                '&:hover': {
                  backgroundColor: getStatusColors(dispatchRelated()[key].status.id.get()).dark,
                  color: getStatusColors(dispatchRelated()[key].status.id.get()).contrastText,
                },
              }}
            />
          </Grid>
        )) :
          <Chip sx={{ marginTop: '25%' }} label={translate("Connection failed! Please try again")} avatar={<Avatar><RefreshIcon /></Avatar>} onClick={() => syncRelatedDispatches(dispatch().get().id)} />
          :
          <CircularProgress sx={{ marginTop: '25%' }} disableShrink />

        }

        <Dialog fullWidth={true} open={open} onClose={handleClose}>
          <DialogTitle>
            {translate('Dispatch')} - {code} - {status}
          </DialogTitle>
          <DialogContent>
            <Typography>{dispatchGroup} {agency}</Typography>
            <br />
            <Typography>{type} {subtype}</Typography>

            {dispatchDestination.get() ? <><br /><Typography>{translate('Unified')} {translate('With')}: <Chip label={dispatchDestination.get()?.code} onClick={() => { syncDispatchFromId(dispatchDestination.get()?.id), setOpen(false) }} /></Typography></> : false}
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              onClick={handleClose}>
              {translate('Close')}
            </Button>
          </DialogActions>
        </Dialog>

      </Grid>
    </Paper>
  );
}