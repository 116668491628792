import React, { useEffect } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useUserState from '~/features/User/stores/UserState';
import { State, useHookstate } from '@hookstate/core';
import useSurveillanceReportingState from '~/features/VideoMonitoring/stores/SurveillanceReportingState';

interface MenuListProps {
  readonly idMenu?: string
  readonly disabledAssociate: number | null
  readonly anchorEl: HTMLElement | null
  readonly handleClose: () => void
  readonly handleEditReport: () => void
  readonly handleAssociateReport: () => void
}

export default function ReportMenuList({ idMenu, anchorEl, handleClose, handleEditReport, handleAssociateReport, disabledAssociate }: MenuListProps) {
  const { translate } = useLang();
  const { verifyPermission } = useUserState();
  const { surveillanceReportingStates } = useSurveillanceReportingState()
  const { showCheckbox } = surveillanceReportingStates()

  return (
    <Menu
      id={idMenu}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={handleClose}
      sx={{
        marginTop: '30px',
      }}
    >
      {verifyPermission(['video_surveillance_edit']) && (<MenuItem onClick={handleEditReport} disabled={ showCheckbox.get() }>
        {translate('Edit')}
      </MenuItem>)}

      {verifyPermission(['video_surveillance_associate_occurrence']) && (<MenuItem onClick={handleAssociateReport} disabled={!disabledAssociate ? false : true}>
        {translate('Associate to Occurrence')}
      </MenuItem>)}

    </Menu>
  )
}