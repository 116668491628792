import { authFetch } from "~/services/fetch";

export async function getDispatchesByStatus ({ withoutPagination = false, filters = [], targetUrl, page = 0, limit = 1000 }) {
  const { data } = await authFetch({
      url: targetUrl,
      method: 'POST',
      data: {
          filters,
          page,
          limit,
          without_pagination: withoutPagination,
          orders: [
              { field: 'dispatched_at', sort: 'desc' }]
      }
  })

  return data;
}