import { IAnimalDTO, IRawAnimal } from "~/features/Dispatch/interfaces/IDispatchRegistrationAnimal";

export function toDomain(animal: IRawAnimal): IAnimalDTO {
    return {
      id: animal.id,
      specieId: animal.specie_id,
      breedId: animal.breed_id,
      animalCircunstance: animal.circunstance,
      occurrenceId: animal.occurrence_id,
      destination: animal.destination,
      notes: animal.notes,
      specie: animal.specie,
      breed: animal.breed

    }
}

export function toFetch(animal: IAnimalDTO) {
    return {
        id: animal.id,
        specie_id: animal.specieId,
        breed_id: animal.breedId,
        circunstance_id: animal.animalCircunstance?.id,
        occurrence_id: animal.occurrenceId,
        destination_id: animal.destination?.id,
        notes: animal.notes
    }

}