import React from 'react';
import { Box, Typography } from '@mui/material';
import packageJson from '../../../../../package.json';

export default function ShowVersionAppMenu() {

	return (
		<Box sx={{
			justifyContent: 'center',
			textAlign: "center",
			position: 'fixed',
			bottom: 0,
			left: 20
		}}
		>
			<Typography>
				<span style={{ fontSize: '11px', fontWeight: 700 }}>
					{`Ver. ` + packageJson.version}
				</span>
			</Typography>
		</Box>
	)
}
