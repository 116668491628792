import { IRawWeapon, IWeaponDTO } from "~/features/Dispatch/interfaces/IDispacthRegistrationWeapon";

export function toDomain(weapon: IRawWeapon): IWeaponDTO {
  return {
    id: weapon.id,
    occurrenceId: weapon.occurrence_id,
    weaponType: weapon.weapon_type,
    weaponKind: weapon.weapon_kind,
    weaponBrand: weapon.weapon_brand,
    model: weapon.model,
    firearmCaliber: weapon.firearm_caliber,
    serialNumber: weapon.serial_number,
    notes: weapon.notes,
    quantity: weapon.quantity,
    circunstance: weapon.circunstance,
    destination: weapon.destination,
  }
}

export function toFetch(weapon: IWeaponDTO) {
  return {
    occurrence_id: weapon.occurrenceId,
    weapon_type_id: weapon.weaponType?.id,
    weapon_kind_id: weapon.weaponKind?.id ?? null,
    weapon_brand_id: weapon.weaponBrand?.id ?? null,
    model: weapon.model || null,
    firearm_caliber_id: weapon.firearmCaliber?.id ?? null,
    serial_number: weapon.serialNumber || null,
    notes: weapon.notes || null,
    quantity: weapon.quantity,
    circunstance_id: weapon.circunstance?.id,
    destination_id: weapon.destination?.id,
  }
}
