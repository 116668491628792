import * as React from 'react'
import { useState, useEffect } from "react"
import filesize from 'filesize';
import { toast } from 'react-toastify';
import { Fab, Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { EntryImportFileFieldList } from '../ImportFileFieldList';
import useEntryFileState from '../../features/Entry/stores/EntryFileState';
import { LoadingButton } from '@mui/lab';
import { useLang } from '~/hooks/useLang';
import './styles.scss';

interface ImportFileFieldProps {
  readonly dispatchId: number | null;
  readonly handleClickImport: () => void;
}

export interface FileProps {
  readonly file: File;
  readonly type: string;
  readonly name: string;
  readonly readableSize: string;
  readonly image: string | null;
}

export interface ImageBase64Props {
  readonly image: string | ArrayBuffer | null;
  readonly file: File;
}

export function ImportFileField({ handleClickImport, dispatchId }: ImportFileFieldProps) {
  const { sendFile } = useEntryFileState();
  const { translate } = useLang();
  const [uploadedFiles, setUploadedFiles] = useState<FileProps>();
  const [isLoading, setIsLoading] = useState(false);
  const [imageBase64, setImageBase64] = useState<ImageBase64Props>();
  const maxSize = 16000000;
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
      'text/csv': [],
      'application/pdf': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/vnd.oasis.opendocument.spreadsheet': [],
      'application/vnd.oasis.opendocument.text': [],
      'text/plain': [],
    },
    maxFiles: 1,
    onDropAccepted: submitFile,
    onDropRejected: rejectFiles,
    multiple: false,
    maxSize
  });

  useEffect(() => {
    if (imageBase64) {
      const image = imageBase64.image as string
      setUploadedFiles({
        file: imageBase64.file,
        image: image.split(',')[1],
        name: imageBase64.file.name,
        readableSize: filesize(imageBase64.file.size),
        type: imageBase64.file.type.split('/')[1]
      })
    }
  }, [imageBase64])

  function rejectFiles(fileRejections: readonly FileRejection[]) {

    fileRejections.forEach(fileRejection => {
      fileRejection.errors.forEach(err => {
        if (err.code === 'file-invalid-type') {
          toast.error(translate('Enter a file of the following types:') + ' pdf, csv, jpg, jpeg, xls, xlsx, doc, docx, ods, odt, txt')
          return
        } else if (err.code === 'file-too-large') {
          toast.error(translate('File is larger than') + ` ${maxSize / 1000000}MB`)
          return
        }
      })
    })

  }

  function submitFile(files: readonly File[]) {
    const file = files[0]
    const reader = new FileReader();
    reader.readAsDataURL(file)
    reader.addEventListener('loadend', () => {
      setImageBase64({ file, image: reader.result });
    })
  }

  async function handleUpload(): Promise<void> {
    if (!uploadedFiles) return;

    const { image, type, name } = uploadedFiles

    setIsLoading(true)
    await sendFile({ dispatchId, type, image, name })
    setIsLoading(false)
    handleClickImport()
  }

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p>
          {translate('Drag and Drop Image Here')}
        </p>
      </div>
      <footer>
        <aside>
          <h4>
            {translate('File')}
          </h4>
          {!!uploadedFiles && <EntryImportFileFieldList file={uploadedFiles} />}

        </aside>
        <Grid container direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item p={2}>

            <LoadingButton
              onClick={handleUpload}
              loading={isLoading}
              loadingPosition="end"
              variant="contained"
              size='small'
            >
              {translate('Save')}
            </LoadingButton>

          </Grid>
          <Fab
            size="small"
            onClick={handleClickImport}
            sx={{
              position: 'absolute',
              top: '-15px',
              right: '-2px',
              transform: 'scale(0.6)',
              color: '#black',
              backgroundColor: '#9e9e9e',
              "&:hover": { backgroundColor: "#6e6e6e" }
            }}
          >
            <ClearIcon />
          </Fab >
        </Grid>
      </footer>

    </section>

  );
}
