import dayjs, { Dayjs } from "dayjs";
import IReport from "../interfaces/IReport";
import { Downgraded, createState, useHookstate } from "@hookstate/core";
import React, { useEffect } from "react";
import { Broadcasted as broadcasted } from '@hookstate/broadcasted'
import { DispatchClosedProps } from "../pages/ReportOccurrence/OccurenceByType";
import { useLang } from "~/hooks/useLang";

export const DEFAULT_TYPE: DispatchClosedProps = { id: 0, name: "", acronym: "" };
export const DEFAULT_TYPE_DISPATCHGROUP = { id: 0 };
export interface TypeOptions {
  readonly id: number;
  readonly name: string;
  readonly code: string;
  readonly priority: number;
  readonly category_id: number;
  readonly ref_to_vehicle: boolean;
}

export interface DispatchGroupOptions {
  readonly id: number;
  readonly name: string;
  readonly acronym: string;
  readonly agency_id: number;
  readonly created_at?: string;
  readonly updated_at?: string;
  readonly deleted_at?: string;
}

export const reportDefault: IReport = {
  header: {
    title: "",
    subtitle: "",
    filters: "",
    className: "basic",
  },
  groups: [{
    label: "",
    count: "true",
    data: [{
      code: "",
      occurrenceDate: ""
    }],
    groups: [],
    details: {
      class: '',
      label: '',
      total: 0,
      value: {}
    }
  }],
  columns: [{
    grid: 0,
    alignment: "",
    id: "",
    label: ""
  }],
  data: [{
    code: "",
    occurrenceDate: ""
  }]
}


const reportState = createState<IReport>(reportDefault)
const loadingReport = createState(false)

reportState.attach(broadcasted('Report-State-Topic'))
loadingReport.attach(broadcasted('Loading-Report'))

export default function useReportState() {
  const dateTime = dayjs();
  const [dateInitial, setDateInitial] = React.useState<Dayjs | null>(null);
  const [dateEnd, setDateEnd] = React.useState<Dayjs | null>(null);
  const isAwaitingTypes = useHookstate(false);
  const isAwaitingDispatchGroups = useHookstate(false);
  const { translate } = useLang();

  const typeOptions = useHookstate<readonly TypeOptions[]>([{
    id: 0,
    name: translate('Select a type'),
    code: "",
    category_id: 0,
    priority: 0,
    ref_to_vehicle: false,
  }]);

  const dispatchGroupOptions = useHookstate<readonly DispatchGroupOptions[]>([{
    id: 0,
    name: translate('Select a Dispatch Group'),
    acronym: '',
    agency_id: 0,
    created_at: '',
    updated_at: '',
    deleted_at: '',
  }])

  const typeState = useHookstate<DispatchClosedProps>(DEFAULT_TYPE);
  const dispatchGroup = useHookstate(DEFAULT_TYPE_DISPATCHGROUP);
  

  return {
    reportData: () => reportState,
    loadingReport: () => loadingReport,
    types: {
      type: {
        current: typeState,
        options: typeOptions,
        isAwaiting: isAwaitingTypes
      },
    },
    dispatchGroups: {
      dispatchGroup: {
        current: dispatchGroup,
        options: dispatchGroupOptions,
        isAwaiting: isAwaitingDispatchGroups,
      },
    },
    date: {
      dateTime,
      initial: dateInitial,
      end: dateEnd,
      setInitial: setDateInitial,
      setEnd: setDateEnd,
    },
  }
}