import React from 'react';
import { BrowserRouter, Redirect, Switch, Route, RouteProps } from 'react-router-dom';
import { AuthContextProvider } from '~/features/auth/contexts/AuthContext';
import ProtectedRoute from './ProtectedRoute';
import NotFound404 from '~/features/System/pages/notFound404';
import routers from './routers';
import useUserState from '~/features/User/stores/UserState';
import WithAxios from './traits/WithAxios';
import RouteManager from './traits/RouteManager';
import Cookies from "universal-cookie";

const cookies = new Cookies();

export type AppRouteProps = RouteProps & {
    readonly isProtected?: boolean;
    readonly permissions?: Array<string>;
    readonly adminOnly?: boolean;
    authorized?: boolean;   
}

function MountedRouter() {
    const { user, verifyPermission } = useUserState();
    const protecteds = routers
    .filter(({isProtected = true}) => isProtected)
    .map((item) => {
      if(item?.adminOnly){
        if(user()?.id.get() && user().id.get() == 1){
          item.authorized = true
        } else {
          item.authorized = false
        }
      } else {
        item.authorized = verifyPermission(item.permissions || [], true);
      }
      return item;
    });
    const without = routers
    .filter(({permissions = [], isProtected = false}) => !isProtected && permissions.length === 0)

    const combineRouters: AppRouteProps[] = [
      ...without, 
      ...protecteds,
      {path: '/', render: () => cookies.get('@Despacho:token') ? <Redirect to={'/home'} /> : <Redirect to={'/login'} />, isProtected: true},
    ];

    return (
      <RouteManager>
        {
          combineRouters.map((item, index) => {
            const {permissions = [], isProtected = false, authorized = true, adminOnly = false, component, ...props} = item;

            return isProtected ? (
              <ProtectedRoute 
                key={index} 
                exact 
                component={authorized ? component : NotFound404} 
                {...props}
              />) : (<Route key={index} exact component={component} {...props}/>)
          })
        }
      </RouteManager>
    );    
}


function Routes() {
  return (
    <BrowserRouter>
        <AuthContextProvider>
          <WithAxios>
            <Switch>
              <MountedRouter />
            </Switch>
          </WithAxios>
        </AuthContextProvider>
    </BrowserRouter>
  );
}

export default Routes;
