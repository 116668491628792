import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IDevice from '../interfaces/IDevice';


export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue,getValues} = useForm<IDevice>({});
  const fields = [
    { name: 'id', type: 'hidden'},
    { name: 'name', type: 'string', required: true, readonly: true },
    { name: 'brand', type: 'string'},
    { name: 'model', type: 'string'},
    { name: 'device_type_id', type: 'selectAutoComplete', model: 'device-type', placeholder: 'Device Type', optionSelect: 'name', required: true },
    { name: 'operation_unit_id', type: 'selectAutoComplete', model: 'operation-unit', placeholder: 'Operation unit'},    
    { name: 'lock_temporary_tracking', type: 'switch'},
  ];
  
    return(
      <CrudEdit 
        model={'device'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        setFormValue={setFormValue}
        messageSuccess={'Device successfully updated!'}  
        title={'Edit Device'}
        getValues={getValues}
      />
    )
}