export function capitalize(str: string){
    const words = str.split(' ');
    const wordsCap = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return wordsCap.join(' ');
}

export function truncateText(text: string, delimiter = 10, stringConcat = '...') {
    if (!text) return null;
    if (text.length >= delimiter) {
        return text.slice(0, delimiter).concat(stringConcat);
    }
    return text;
}

export function toSnakeCase(text) {
    return text.split('').map((character) => {
        if (character == character.toUpperCase()) {
            return '_' + character.toLowerCase();
        } else {
            return character;
        }
    })
        .join('');
}


export function splitCamelCase(text){
    return text.replace(/([A-Z])/g, ' $1')
}

export function getOnlyNumbersInString(text: string) {
    if (!text) return '';
    return text.replace(/[^0-9]/g, '');
}


export function toCamelCase(str: string) {
    if (!str) return '';
    return str.toLowerCase()
        .replace(/([-_][a-z])/g, (group) => {
            return group
                .toUpperCase()
                .replace('-', '')
                .replace('_', '')
        });
}

export function hifenToUnderline(str: string) {
    if (!str) return '';
    return str.replace(/([-_/][a-z])/g, (group) => {
        return group
            .replace('/', '_')
            .replace('-', '_')
    });
}

export function stringToPhoneMask(text: string) {
    if (!text) return '';
    if(text.length == 11){
        const phone = text.match(/(\d{2})(\d{5})(\d{4})/)
        if(phone) return `(${phone[1]}) ${phone[2]}-${phone[3]}`
    }else{
        const phone = text.match(/(\d{2})(\d{4})(\d{4})/)
        if(phone) return `(${phone[1]}) ${phone[2]}-${phone[3]}`
    }

}




