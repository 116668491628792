import React, { useEffect } from 'react'
import { useLang } from '~/hooks/useLang'
import {
  Button,
  Card,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
} from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import Layout from '~/components/Layout'
import Loading from '~/components/Loading'
import ErrorFallback from '~/components/ErrorFallback'
import useReportState from '../../stores/ReportState'
import {
  downloadReportCSV,
  makeRequestToGetReportServiceDevices,
} from '../../services/report'
import notify from '~/utils/notify'
import { useHookstate } from '@hookstate/core'
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups'
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod'
import FilterAutocompleteAgencies from '~/components/FilterAutocompleteAgencies'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import GenerateReportButton from '../../components/GenerateReportButton'
import FilterSwitchOtherDispatchsGroup from '~/components/FilterSwitchOtherDispatchsGroups'
import ExportButtonCSV from '../../components/ButtonExportCSV'
import FilterAutocompleteDispatchGroupSet from '~/components/FilterAutocompleteDispatchGroupSet'

// eslint-disable-next-line max-lines-per-function
export default function DeviceServices() {
  const { translate } = useLang()
  const isLoading = useHookstate(false)
  const expandFilterSearch = useHookstate<boolean>(true)
  const deviceId = useHookstate<number | null>(null)
  const lockFields = useHookstate<boolean>(false)
  const groupId = useHookstate<readonly number[]>([])
  const startDate = useHookstate<string | null>(null)
  const finishDate = useHookstate<string | null>(null)
  const agencyIds = useHookstate<readonly number[]>([])
  const withSolvedThirdParty = useHookstate<boolean>(false)
  const labelButton = useHookstate('')
  const period = useHookstate('')
  const reportName = 'device-services'
  const groupSetId = useHookstate<number | null>(null)

  const { reportData, loadingReport } = useReportState()

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])

  function generateReport() {
    if (startDate && finishDate) {
      loadingReport().set(true)
      isLoading.set(true)
      makeRequestToGetReportServiceDevices({
        startDate: startDate.get(),
        finishDate: finishDate.get(),
        dispatchGroupsId: groupId.get(),
        agencyIds: agencyIds.get(),
        withSolvedThirdParty: withSolvedThirdParty.get(),
        groupSetId: groupSetId.get(),
      })
        .then((response) => {
          reportData().set(response)
          window.open(`/report/result/${reportName}`)
        })
        .finally(() => {
          loadingReport().set(false)
          isLoading.set(false)
        })
    } else {
      notify({
        message: translate('Initial and final date are mandatory'),
        type: 'error',
      })
    }
  }

  const exportCSV = async () => {
    if (startDate && finishDate) {
      isLoading.set(true)
      const dataCSV = await makeRequestToGetReportServiceDevices({
        startDate: startDate.get(),
        finishDate: finishDate.get(),
        dispatchGroupsId: groupId.get(),
        agencyIds: agencyIds.get(),
        withSolvedThirdParty: withSolvedThirdParty.get(),
        exportCSV: true,
        groupSetId: groupSetId.get(),
      }).finally(() => isLoading.set(false))
      downloadReportCSV(
        `${translate(reportName)}-${new Date().getTime()}.csv`,
        dataCSV
      )
    } else {
      notify({
        message: translate('Initial and final date are mandatory'),
        type: 'error',
      })
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true}>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader
                title={`${translate('Report')}: ${translate(
                  `Device Services`
                )}`}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader
            title={`${translate('Filters')}`}
            avatar={
              <IconButton
                onClick={() =>
                  expandFilterSearch.set(!expandFilterSearch.get())
                }
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginRight: 1,
                  padding: 0,
                }}
              >
                {expandFilterSearch.value ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            }
          />

          <Collapse in={expandFilterSearch.get()}>
            <Grid
              container
              xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
              }}
            >
              <Grid container direction={'row'} rowGap={2}>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteAgencies
                    disabled={lockFields.get()}
                    agenciesId={agencyIds.get()}
                    onAgenciesChange={(agency) => {
                      labelButton.set(translate('Search'))
                      agencyIds.set(agency)
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchGroupSet
                    disabled={lockFields.get()}
                    dispatchGroupSetId={groupSetId?.get() ?? undefined}
                    onGroupDispatchSetChange={(group) => {
                      groupSetId?.set(group)
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchsGroups
                    disabled={lockFields.get()}
                    agencyId={agencyIds.get() ?? undefined}
                    dispatcheGroups={groupId.get() ?? undefined}
                    onGroupDispatchChange={(group) => {
                      groupId.set(group)
                      deviceId.set(null)
                      labelButton.set(translate('Search'))
                    }}
                    groupSetId={groupSetId.get()}
                  />
                </Grid>

                <Grid item xs={5} sx={{ pl: 1, pt: 1 }}>
                  <FilterSwitchOtherDispatchsGroup
                    onCheckedChange={() => {
                      labelButton.set(translate('Search'))
                      withSolvedThirdParty.set(!withSolvedThirdParty.value)
                    }}
                    disabled={lockFields.get()}
                    checked={withSolvedThirdParty.get()}
                  />
                </Grid>

                <Grid container direction={'row'}>
                  <FilterSelectPeriod
                    disabled={lockFields.get()}
                    onStartDateChange={(value) =>
                      startDate.set(value?.toJSON() ?? null)
                    }
                    onFinishDateChange={(value) =>
                      finishDate.set(value?.toJSON() ?? null)
                    }
                    onPeriodChange={(value) => {
                      period.set(value)
                    }}
                    period={period.get()}
                    required
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 2,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    agencyIds.set([])
                    groupSetId.set(null)
                    groupId.set([])
                    period.set('')
                    withSolvedThirdParty.set(false)
                  }}
                  sx={{ height: '40px' }}
                >
                  {translate('Clear Filters')}
                </Button>
                <ExportButtonCSV
                  isLoading={isLoading.get()}
                  dataToExport={exportCSV}
                />
                <GenerateReportButton
                  isLoading={isLoading.get()}
                  makeReport={generateReport}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary>
  )
}
