import React from 'react';
import Button from '@mui/material/Button';
import { useLang } from '~/hooks/useLang';
import { Box, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import useDispatchRegistrationState from '~/features/Dispatch/stores/DispatchRegistrationState';
import AddIcon from '@mui/icons-material/Add';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';

interface IListRegistrations {
  readonly handleAdd: (action: boolean) => void;
  readonly columns: GridColDef[];
  readonly rows: GridRowsProp;
}

export default function ListRegistrations({ handleAdd, columns, rows }: IListRegistrations) {
  const { translate } = useLang();

  const { registrationDispatch } = useDispatchRegistrationState();
  const { loadingRegistrations } = registrationDispatch();

  const { dispatch } = useDispatchState();
  const { status } = dispatch();

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
      }}>
        <Button
          disabled={status.value?.id === 8}
          onClick={() => handleAdd(true)}
          variant='contained'
        >
          {translate('Add')}
        </Button>
      </Box>
      <Box sx={{ height: 300, width: '100%', py: 2 }}>
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          columnVisibilityModel={{
            id: false,
          }}
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          disableColumnSelector={true}
          loading={loadingRegistrations.get()}
          slots={{ noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {translate('No records')}
            </Stack>
          ), }}
        />
      </Box>
    </>
  );
}