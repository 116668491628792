import React from 'react';
import {  useState } from '@hookstate/core';
import { IconButton, Grid } from '@mui/material';
import { Autorenew } from '@mui/icons-material';
import useEntryState from '../../stores/EntryState';
import { useLang } from '~/hooks/useLang';
import { AxiosResponse } from 'axios';
import useSystemState from '~/features/System/stores/SystemState';


interface RecallButtonProps {
  readonly canRecall?: boolean;
  readonly recall?: (params) => Promise<AxiosResponse>;
  readonly create?: any;
}

export default function RecallButton(props: RecallButtonProps) {
  const { translate } = useLang()
  const { windowManager } = useSystemState();

  const handleChange = () => {
    windowManager()['entryRecall'].open.set(true)

  };

  return (
    <Grid item>
      <IconButton title={translate('Recall')} disabled={!props.canRecall} onClick={handleChange}>
        <Autorenew />
      </IconButton>
    </Grid>);

}