/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import { Box, Button, Card, ListItemButton, ListItemText, Tooltip, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useLang } from '~/hooks/useLang';
// import useUserState from '~/features/User/stores/UserState';
import useUserState from '~/features/User/stores/UserState';
import { MenuProps } from '../../interfaces';

export default function VideoMonitoringMenu({ activeMenu, handleMenuClick }: MenuProps) {
  const { translate } = useLang();
  const { verifyPermission } = useUserState();
  const history = useHistory();

  return (
    <>
      {verifyPermission(['video_surveillance_index']) && (
        <Box>
          <Tooltip title={translate('Videomonitoring')} placement="bottom">
            <Button
              className={`main-menu ${activeMenu == 'monitoring' ? 'active' : ''}`}
              onClick={() => handleMenuClick('monitoring')}
            >
              <PhotoCameraOutlinedIcon />
            </Button>
          </Tooltip>
          <Card
            className="submenu-box"
            sx={{
              borderRadius: 0,
              display: activeMenu == 'monitoring' ? 'block' : 'none',
            }}
          >
            <Typography variant="h4">
              <PhotoCameraOutlinedIcon />
              {translate('Videomonitoring')}
            </Typography>

            {verifyPermission(['video_surveillance_index']) && (
              <Tooltip title={translate('Video Surveillance Narration')} placement="right">
                <ListItemButton onClick={() => {
                  handleMenuClick('monitoring')
                  history.push({
                    pathname: '/video-monitoring',
                  })
                }}>
                  <ListItemText
                    primary={
                      translate('Video Surveillance Narration')
                    }
                  />
                </ListItemButton>
              </Tooltip>
            )}

          </Card>
        </Box>
      )}
    </>
  )
}
