import React from 'react';
import { AppRouteProps } from '~/routes';
import Create from './pages/Create';
import Index from './pages/Index';
import Edit from './pages/Edit';

export default function OperationUnitRoutes(): readonly AppRouteProps[] {
    const routes: AppRouteProps[] = [
        {
            path: '/operation-unit', component: Index, isProtected: true,
            permissions: ['operation_unit_index'] 
        },
        {
            path: '/operation-unit/create', component: Create, isProtected: true,
             permissions: ['operation_unit_create']
        },
        {
            path: '/operation-unit/edit/:id', component: Edit, isProtected: true,
            permissions: ['operation_unit_edit'] 
        },
    ];

    return routes;
}
