/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import { StateMethods, useHookstate } from '@hookstate/core'
import CloseIcon from '@mui/icons-material/Close';
import { useLang } from '~/hooks/useLang'
import dayjs from 'dayjs'
import IProcedure from '../../interfaces/IProcedure'
import { getProcedureById } from '../../services'
import ReactQuill from 'react-quill'
import { useTheme } from '~/hooks/useTheme'

export interface InfoProps {
    readonly name?: string;
    readonly agency?: {
        readonly name?: string
    };
    readonly identification_key: string | null;
    readonly authentication_key: string | null;
}

export interface ViewProcedureProps {
  readonly open: StateMethods<boolean>
  readonly idProcedure: number | null
  readonly handleClose: () => void
}

export default function ViewProcedure({ open, idProcedure, handleClose}: ViewProcedureProps) {
    const { translate } = useLang()
    const infoProcedure = useHookstate<IProcedure | undefined>(undefined)
    const { darkMode } = useTheme();


    useEffect(() => {
        if(idProcedure != null){
            getProcedureById(idProcedure).then((data) => {
                infoProcedure.set(data)
            })
        }else{
            infoProcedure.set(undefined)
        }
    }, [idProcedure])



    const clickOutModalClose = (event, reason) => {
        if (reason !== 'backdropClick') {
          open.set(false)
        }
      };
      
    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={open.get()}
            onClose={clickOutModalClose}
            disableEscapeKeyDown={true}
        >
            <DialogTitle display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                {translate('Procedure')}
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButton>
                </DialogTitle>
            <DialogContent>
                <Grid container xs={12}>
                { infoProcedure.get() && (
                    <>
                    <Grid container xs={12} paddingBottom={2} direction={'row'}>
                        <Grid item xs={3}>
                            <Typography variant='subtitle1' fontWeight={'bold'}>
                                {translate('Name')}
                                :
                            </Typography>
                            <Typography variant='subtitle2'>
                                {infoProcedure.get()?.name ? infoProcedure.get()?.name : translate('Uninformed')}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                        <Typography variant='subtitle1' fontWeight={'bold'}>
                            {translate('Code')}
                            :
                        </Typography>
                        <Typography variant='subtitle2'>
                            {infoProcedure.get()?.code ? infoProcedure.get()?.code : translate('Uninformed')}
                        </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant='subtitle1' fontWeight={'bold'}>
                                {translate('Created date')}
                                :
                            </Typography>
                            <Typography variant='subtitle2'>
                                {infoProcedure.get()?.created_at ? dayjs(infoProcedure.get()?.created_at).format('DD/MM/YYYY') : translate('Uninformed')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1' fontWeight={'bold'}>
                                {translate('Description')}
                                :
                            </Typography>
                            <ReactQuill
                                value={infoProcedure.get()?.description ? infoProcedure.get()?.description : translate('Uninformed')}
                                readOnly={true}
                                style={{
                                    height: '90%',
                                    color: darkMode ? '#fffe' : '#000'
                                }} 
                                modules={{
                                    toolbar: false
                                }}
                            />
                        </Grid>
                    </Grid>
                    </>
                        )
                }
                </Grid>      
            </DialogContent>
        </Dialog>
    )
}