import { AxiosError } from 'axios';
import { getStorageAnonymous, getStorageStation } from '~/services/auth';
import api from './api';
import useCookie from '~/hooks/useCookie';
import constants from '~/core/constants';


type CredentialsType = {
    readonly login: string;
    readonly password: string;
}

export default async function authLogin({login, password}: CredentialsType) {
    const station = getStorageStation();
    const anonymousKey = getStorageAnonymous();


    const endpoint = anonymousKey ? '/auth/login/without-station' : '/auth/login';
    // const endpoint = '/auth/login';

    const headersDefault = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Station: station,
        'Accept-Language': 'pt-br,pt_br,pt_BR',
      }
    
    
      if (anonymousKey) {
        delete headersDefault['Station'];
        headersDefault['anonymous'] = anonymousKey
      }
    

    try {
        const response = await api(endpoint, {
            method: 'POST',
            headers:headersDefault,
            data: { 
                login, 
                password,
                forceRenove: false
            }
        });
        return {
            token: response.data.token,
            user: response.data.user,
            dateExpire: response.data.dateExpire,
            station: response.data.station
        };
    } catch (error) {
        const err = error as AxiosError;
        throw err.response?.data?.error || err.response?.data?.message || err.message;
    }   
}