import React, { useEffect } from 'react';
import { IconButton, Badge, Grid } from '@mui/material';
import { AccessTimeFilled } from '@mui/icons-material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import ChronologyDeviceLastDispatchDialog from '~/components/ChronologyDeviceLastDispatchButton/components/ChronologyDeviceLastDispatchDialog';

export default function ChronologyDeviceLastDispatchButton({ id }){
  const { translate } = useLang();
  const open = useHookstate(false);

  return (
    <Grid item xs={2}>
        <IconButton
          title={translate('Chronology')}
          onClick={() => open.set(true)}
        >
          <AccessTimeFilled/>
        </IconButton>
        {open.get() && <ChronologyDeviceLastDispatchDialog id={id} open={open} />}
    </Grid>
  )
}