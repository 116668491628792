import React, { useEffect, useState } from 'react';
import GroupsIcon from '@mui/icons-material/Groups';
import { IconButton, Tooltip} from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useDispatchGroupState, { IDispatchGroupView } from '~/features/DispatchGroup/stores/DispatchGroupState';
import { Downgraded, useHookstate } from '@hookstate/core';
import ChangeViewDispatchGroupContent from '../ChangeVeiwDispatchGroupContent';

export default function ChangeViewDispatchGroup() {
  const { dispatchGroupView } = useDispatchGroupState();
  const { translate } = useLang();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openModal = useHookstate(false);
  const localChecked = useHookstate<IDispatchGroupView>({})

  useEffect(() => {
    localChecked.set(dispatchGroupView().attach(Downgraded).get())

  }, [dispatchGroupView().get()])

  const handleOpenModal = () => {
    openModal.set(true);
    setAnchorEl(null);
  }

  const handleCloseModal = (event, reason) => {
    if (reason !== 'backdropClick') {
      openModal.set(false)
    }
  }

  const handleClose = () => {
    openModal.set(false)
    setAnchorEl(null);
  };


  return (
    <>
      <Tooltip title={translate('Dispatch Groups visibles')} placement="bottom">
        <IconButton size="large" onClick={handleOpenModal}>
          <GroupsIcon />
        </IconButton>
      </Tooltip>

      {openModal.get() ?
        <ChangeViewDispatchGroupContent
          handleClose={handleClose}
          handleCloseModal={handleCloseModal}
          openModal={openModal.get()}

        />
        : false}
    </>
  )
}