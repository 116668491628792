import { authFetch } from "~/services/fetch";
import { ProcedureResponse } from "../interface";

export async function getProcedures(procedure): Promise<readonly ProcedureResponse[]> {
  try {
    const { data } = await authFetch({
      url: `/procedure/search/${procedure}`,
      method: 'GET',
    })
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getAllProcedures(): Promise<readonly ProcedureResponse[]> {
  try {
    const { data } = await authFetch({
      url: `/procedure`,
      method: 'GET',
    })
    return data;
  } catch (error) {
    throw error;
  }
}