import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import notify from '~/utils/notify';
import i18n from '../i18n';
import { getStorageToken, getStorageStation, getStorageAnonymous } from './auth';
import { getBaseUrl } from '~/utils/net';
import isReachable from 'is-reachable';

export async function isAuthorizedInUrl({ url, timeout = 5000 }): Promise<boolean> {
  const controller = new AbortController();
  const fetchTimeout = setTimeout(() => controller.abort(), timeout);
  try {
    const canReach =  fetch && (await fetch(url, { method: 'HEAD', signal: controller.signal })).ok;
    if (canReach) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  } finally {
    clearTimeout(fetchTimeout);
  }
}

export const mountUrlRequest = (url: string) => {
    const baseUrl = getBaseUrl();
    const urlRequest = baseUrl + url;
   return urlRequest;
}

export const authFetch = async ({
  url = '',
  method = 'POST',
  headers,
  data,
  timeout = 40000,
  signal = (new AbortController()).signal
}: AxiosRequestConfig) => {
  const token = getStorageToken();
  const station = getStorageStation();
  const anonymousIdentificationKey = getStorageAnonymous();
  const urlRequest = mountUrlRequest(url);
  const headersDefault = {
    ...headers,
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Station: station,
    'Accept-Language': 'pt-br,pt_br,pt_BR',
  }


  if (anonymousIdentificationKey) {
    delete headersDefault['Station'];
    headersDefault['anonymous'] = anonymousIdentificationKey
  }


  try {
    const response = await axios(urlRequest, {
      method,
      headers: {
        ...headersDefault,
      },
      data,
      timeout,
      withCredentials: true,
      signal
    });
    return response;

  } catch (error) {
    const err = error as AxiosError;

    if (err.response?.status == 400 || err.response?.status == 403 || err.response?.status == 409) {
      notify({ 
        autoCloseDuration: err.response?.status == 409 ? 8000 : 5000, 
        message: i18n.t(`${err.response?.data?.error || err.response?.data?.message || err.message || 'Something went wrong! Try again.'}`), 
        type: 'error' 
      });
    } else if (err.response?.status == 500 ) {
      notify({ message: i18n.t('Something went wrong! Try again.'), type: 'error' });
    }

    throw err.response?.data?.error || err.response?.data?.message || err.message;
  }

}
