import { authFetch } from "~/services/fetch";
import { AreaPlanResponse } from "../interface";

export async function getAllAreaPlan(): Promise<readonly AreaPlanResponse[]>{
  try {
    const { data } = await authFetch({
      url: '/area-plan',
      method: 'POST',
    })
    return data.data;
  } catch (error) {
    throw error;
  }
}