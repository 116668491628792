import React, { useEffect } from 'react';
import Layout from '~/components/Layout';
import InternalContent from '~/components/Layout/components/InternalContent';
import InternalHeader from '~/components/Layout/components/InternalHeader';
import { useLang } from '~/hooks/useLang';
import { getAllSystemConfigService, updateAllSystemConfigService } from '~/features/System/services';
import { ISystemConfigAllByGroups } from '../interfaces/ISystemConfig';
import { CardActions, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { StateMethods, useHookstate } from '@hookstate/core';
import LoopIcon from '@mui/icons-material/Loop';

import ButtonForm from '~/components/ButtonForm';
import { useHistory } from 'react-router-dom';
import notify from '~/utils/notify';
import { capitalize, splitCamelCase, toCamelCase } from '~/utils/strings';

interface IGenerateField {
  readonly type: string;
  readonly label: string;
  readonly value: StateMethods<string>;
}

export default function SystemSettings() {
  const { translate } = useLang();
  const processing = useHookstate<boolean>(false);
  const history = useHistory();
  const allSystemConfigs = useHookstate<readonly ISystemConfigAllByGroups[]>([]);

  useEffect(() => {
    document.title = translate('System Settings - Dispatch System')
  }, [])

  const systemConfig = {
    group_dispatch_name: 'string',
    dispatch: 'string',
    map_latitude_defaultatch: 'number',
    map_longitude_default: 'number',
    map_zoom_default: 'number',
    bound_bottom_left_lat: 'number',
    bound_bottom_left_lng: 'number',
    bound_top_right_lat: 'number',
    bound_top_right_lng: 'number',
    emergency_button_type: 'number',
    emergency_button_user: 'number',
    emergency_button_device: 'number',
    max_dispatches_per_device: 'number',
    emergency_type_id: 'number',
    remove_device_closed_status_id: 'number',
    occurrence_search_period_in_hours: 'number',
    required_km: 'boolean',
    required_phone: 'boolean',
    allowed_edit_entry_comment: 'boolean',
    allowed_edit_occurrence_comment: 'boolean',
    allowed_delete_entry_comment: 'boolean',
    allowed_delete_occurrence_comment: 'boolean',
    allowed_comment_on_closed_occurrence: 'boolean',
    display_all_comments_in_print: 'boolean',
    display_requester_data_in_print: 'boolean',
    camera_url_integration: 'string',
  }

  function generateField({ type, label, value }: IGenerateField) {
    const labelChange = capitalize(splitCamelCase(toCamelCase(label)));

    switch (type) {
      case 'string':
        return (
          <TextField
            label={translate(labelChange)}
            name={translate(labelChange)}
            size='small'
            value={value.get()}
            onChange={(event) => {
              value.set(event.target.value)
            }}
          />
        )
      case 'number':
        return (
          <TextField
            fullWidth
            type='number'
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { min: 0 }
            }}
            label={translate(labelChange)}
            name={translate(labelChange)}
            size='small'
            value={value.get()}

            onChange={(event) => {
              value.set(event.target.value)
            }}
          />
        )
      case 'boolean':
        return (<FormControlLabel
          control={
            <Checkbox
              onChange={(_, valueCheck) => {
                if (valueCheck) {
                  value.set('1')
                } else {
                  value.set('0')
                }
              }}
              checked={!!parseInt(value.get())} />
          }
          label={translate(labelChange)} />
        )
      default:
        break;
    }
  }

  const handleSubmit = async () => {
    const allConf = allSystemConfigs
      .get()
      .flatMap(system => system.system_configs)
      .map(config => ({
        id: config.id,
        name: config.name,
        value: config.val,
        required: config.required,
      }))
    updateAllSystemConfigService(allConf).then(() => {
      notify({ message: translate('Saved successfully'), type: 'success' });
    })
  }

  useEffect(() => {
    getAllSystemConfigService().then((systemConfigs) => {
      const insertFildsTypes = systemConfigs.map((config): ISystemConfigAllByGroups => ({
        ...config,
        system_configs: config.system_configs.map(conf => ({
          ...conf,
          val: conf.value,
          fieldType: systemConfig[conf.name]
        }))
      }))
      allSystemConfigs.set(insertFildsTypes)
    })

  }, [])

  return (
    <Layout marginLayout={true}>
      <InternalHeader
        title={translate('System Settings')}
      />
      {allSystemConfigs.map(configs =>
      (<InternalContent expandButton={false} key={configs.id.get()}>
        <Grid item xs={12} sx={{ padding: 3 }}>
          <Typography variant="h6" gutterBottom>
            {translate(capitalize(configs.name.get()))}
          </Typography>
          <Typography variant="caption" gutterBottom>
            {translate(configs.description.get())}
          </Typography>
        </Grid>
        <Grid container xs={12} sx={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          {configs.system_configs.map(({ id, fieldType, name, val }) => (
            <Grid sx={{ p: 3 }} item key={id.get()}>
              {generateField({ type: fieldType.get(), label: name.get(), value: val })}
            </Grid>
          ))}
        </Grid>
      </InternalContent>)

      )}
      <CardActions disableSpacing>
        <ButtonForm type='cancel' onClick={() => { history.push('/') }}>
          {translate('Cancel')}
        </ButtonForm>
        <ButtonForm type='submit' onClick={handleSubmit}>
          <LoopIcon className='load-icon' sx={{ display: processing.get() ? 'block' : 'none' }} />
          {processing.get() ? translate('Saving') : translate('Save')}
        </ButtonForm>
      </CardActions>
    </Layout>
  )
}