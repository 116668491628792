import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Button, Collapse, DialogActions, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useLang } from '~/hooks/useLang';
import useVehicleState from '~/features/Vehicle/stores/VehicleState';
import IDevice from '~/features/Device/interfaces/IDevice';
import useDeviceState from '~/features/Device/stores/DeviceState';
import { toast } from 'react-toastify';
import { State, StateMethods } from '@hookstate/core';
import { DeviceLog } from '..';

interface IErrosProps {
  readonly devices: null | string;
  readonly km: null | string;
}

interface ICollapseReplace {
  readonly showReplaceVehicle: StateMethods<boolean>;
  readonly deviceCode: StateMethods<string>;
  readonly deviceId: StateMethods<number | null>;
  readonly deviceLogState: State<DeviceLog | null>;
}

// eslint-disable-next-line max-lines-per-function
export default function CollapseReplace({
  deviceCode,
  showReplaceVehicle,
  deviceId,
  deviceLogState
}: ICollapseReplace) {
  const { translate } = useLang();
  const { sendTransferCrew } = useVehicleState();
  const { getDevicesDisconnected } = useDeviceState();

  const [inputValue, setInputValue] = useState('');
  const [kmInitial, setKmInitial] = useState("");

  // eslint-disable-next-line functional/prefer-readonly-type
  const [deviceList, setDeviceList] = useState<IDevice[]>([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<IErrosProps>({
    devices: null,
    km: null,
  });
  const loading = open && deviceList.length === 0;

  const someInvalidInput = () => {
    if (!inputValue) {
      setError({
        ...error,
        devices: translate('Inform a device')
      })
      return true;
    }
    if (!kmInitial) {
      setError({
        ...error,
        km: translate('Inform a Km')
      })
      return true;
    }
    return false;
  }
  const [canSendNewDevice, setCanSendNewDevice] = useState(false);


  const loadDevices = useCallback(async () => {
    const devicesDisconnected = await getDevicesDisconnected();
    setDeviceList(devicesDisconnected)
  }, [getDevicesDisconnected])

  useEffect(() => {
    loadDevices()
  }, [])

  return (
    <Collapse in={showReplaceVehicle.get()}
    >
      <DialogActions>
        <Grid container direction='row'
          sx={{
            justifyContent: 'start',
            alignItems: 'center',
            alignContent: 'center',
            padding: '16px 16px 0 16px'
          }}
        >
          <Grid item xs={12} sx={{ marginBottom: '8px' }}>
            <Typography>
              {translate('Inform a new device')}
              {!!error.devices && <span style={{
                color: 'red',
              }}>
                {` (${error.devices})`}
              </span>}
              {!!error.km && <span style={{
                color: 'red',
              }}>
                {` (${error.km})`}
              </span>}
            </Typography>


          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth size='small' >
              <Autocomplete
                id="combo-box-devices"
                inputValue={inputValue}
                onInputChange={async (event, newInputValue) => {
                  setCanSendNewDevice(true)
                  setInputValue(newInputValue);
                  setError({ ...error, devices: null })
                }}
                freeSolo
                options={deviceList.map(device => device.name)}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => {
                  setOpen(false);
                  if (!inputValue) {
                    setError({ ...error, devices: null })
                  }
                }}
                onFocus={() => {
                  if (!inputValue) {
                    setError({ ...error, devices: translate('Inform a device') })
                  }
                }}
                loading={loading}
                size='small'
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error.devices}
                    onFocus={() => setError({ ...error, devices: null })}
                    required
                    // color="warning"
                    label={translate('Device:name')}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sx={{ padding: 1 }}>
            <TextField
              fullWidth
              error={!!error.km}
              onFocus={() => setError({ ...error, km: null })}
              value={kmInitial}
              onChange={(event) => {
                setCanSendNewDevice(true)
                setKmInitial(event.target.value)
              }}
              size='small'
              type='number'
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">
                  km
                </InputAdornment>,
              }}
            />
          </Grid>
          <Grid item
            xs={4}
            sx={{ textAlign: 'end' }}
          >
            <Button
              variant='contained'
              disabled={!canSendNewDevice}
              onClick={() => {
                if (someInvalidInput()) {
                  setCanSendNewDevice(false)
                  return
                }
                setCanSendNewDevice(false)
                const newDevice = deviceList.find(device => device.name === inputValue);
                sendTransferCrew({
                  fromDeviceId: deviceId.get(),
                  toDeviceId: newDevice?.id as number ?? null,
                  toKmInitial: kmInitial,
                }).then(() => {
                  setCanSendNewDevice(false)
                  setInputValue('')
                  setKmInitial('')

                  deviceLogState.set((previousValue) => ({ ...previousValue, km_initial: kmInitial }))
                  deviceId.set(newDevice?.id as number)
                  deviceCode.set(inputValue)
                  showReplaceVehicle.set(false)

                  loadDevices()
                  toast.success(translate('Saved successfully'))
                }).catch(() => {
                  setCanSendNewDevice(true)
                })
              }}
            >
              {translate("Save")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Collapse>
  )
}