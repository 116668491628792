import React, { useEffect, useMemo } from 'react'
import CrudIndex from '~/components/CrudIndex'
import { ActionButtonsProps } from '~/components/CrudIndex/interfaces/ICrudIndex'
import LockResetIcon from '@mui/icons-material/LockReset'
import { useLang } from '~/hooks/useLang'
import ConfirmDialog from '~/components/ConfirmDialog'
import { useHookstate } from '@hookstate/core'
import Layout from '~/components/Layout'
import { restartStation } from '~/features/Station/services'
import userUserState from '~/features/User/stores/UserState'
import { SimpleDialogBox } from '~/components/SimpleDialogBox'
import { Visibility } from '@mui/icons-material'
import ViewMore, { InfoProps } from '../components/ViewMore'

export default function Index() {
  const { translate } = useLang()
  const { verifyPermission } = userUserState()
  const openDialogResetStation = useHookstate(false)
  const openDialogBox = useHookstate(false)
  const dialogBoxTitle = useHookstate('')
  const dialogBoxContent = useHookstate('')

  const idTemp = useHookstate(null)

  const openDialogViewStation = useHookstate(false)

	const station = useHookstate<string | null>(null)
  const lockFields = useHookstate<boolean>(false);
	const loading = useHookstate<boolean>(false);
  const agencyId = useHookstate<number | null>(null);
  const contactCenterId = useHookstate<number | null>(null);
  const isAuthenticated = useHookstate<number | null>(null);
  const forceRefresh = useHookstate<boolean>(false);

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])

  // eslint-disable-next-line functional/prefer-readonly-type
  const extraActions = useMemo((): ActionButtonsProps[] => {
    if (verifyPermission(['station_refresh_authentication_key'])) {
      return [
        {
          action: function (row) {
            openDialogResetStation.set(true)
            idTemp.set(row['id'])
          },
          label: translate('Reset Authentication'),
          iconButton: <LockResetIcon />,
          showInMenu: function (params){
            return params.verification_key && params.identification_key ? false : true
          }
        }, 
      ]
    } else {
      return []
    }
  }, [])

  const priorityActions = useMemo((): ActionButtonsProps[] => {
      return [
        {
          action: function (row) {
            openDialogViewStation.set(true)
            idTemp.set(row['id'])
          },
          label: translate('View'),
          iconButton: <Visibility />
        },
      ]
    
  }, [])


  const handleDialogBoxOpen = () => {
    openDialogBox.set(true)
  }

  const handleDialogBoxClose = () => {
    openDialogBox.set(false)
    window.location.reload()
  }

  function handleVisualizeKey(key: number) {
    handleDialogBoxOpen()
    dialogBoxTitle.set(translate('Authentication Key'))
    dialogBoxContent.set(`Envie este código para o usuário: ${key}`)
  }

  const stationFilter = [
    {
      type: 'name',
      placeholder: 'Name',
      variable: station
    },
    {
      type: 'agency_id',
      variable: agencyId
    },
    {
      type: 'contact_center_id',
      variable: contactCenterId
    },
    {
      type: 'is_authenticated',
      variable: isAuthenticated
    },
  ]

  return (
    <Layout marginLayout={true}>
      <CrudIndex
        model="station"
        title="Work stations"
        exceptColumns={[
          'agency_id',
          'serial_hd',
          'mac_address',
          'is_connected',
          'identification_key',
          'contact_center_id',
          'agency:acronym'
        ]}
        aliasLabel={[
          { column: 'name', label: 'Station Name' },
          { column: 'contact_center:name', label: 'Contact center'},
          { column: 'verification_key', label: 'Authenticated'}
        ]}
        extraActions={extraActions}
        createTitleComplement="Station"
        forceOrderColumns={['name', 'agency:name', 'contact_center:name', 'verification_key']}
        forceBooleanField={['verification_key']}
        loading={loading}
        lockFields={lockFields.get()}
        clearFilters={() => {
          station.set('')
          agencyId.set(null)
          contactCenterId.set(null)
          isAuthenticated.set(null)
          forceRefresh.set(!forceRefresh.get())
        }} 
        filters={stationFilter}
        searchButton={() => forceRefresh.set(!forceRefresh.get())}
        forceRefresh={forceRefresh.get()}
        disableColumnsFilters={true}
        priorityActions={priorityActions}
      />
      <ConfirmDialog
        open={openDialogResetStation}
        title={translate('Reset Authentication Key')}
        content={translate(
          'Do you want to generate a new authentication key for this station?'
        )}
        AgreeLabel={translate('Yes')}
        handleClose={() => openDialogResetStation.set(false)}
        handleAgree={() => {
          restartStation({ stationId: idTemp.get() }).then(
            ({ authenticationKey }) => handleVisualizeKey(authenticationKey)
          )
        }}
      />
      <SimpleDialogBox
        handleClose={handleDialogBoxClose}
        open={openDialogBox}
        title={dialogBoxTitle}
        content={dialogBoxContent}
        closeMessage={translate('Ok')}
      />
      <ViewMore 
        open={openDialogViewStation} 
        handleClose={() => {
          idTemp.set(null)
          openDialogViewStation.set(false)
        }} 
        idStation={idTemp.get()}
      />
    </Layout>
  )
}
