/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import './styles.scss'
import { Message as MessageIcon } from '@mui/icons-material'
import ReactDOM from 'react-dom'
import StandardModal from '../../../../components/StandardModal'
import Message from '../Message'
import { useLang } from '../../../../hooks/useLang'
import { authFetch } from '~/services/fetch'
import { useHookstate } from '@hookstate/core'
import { useLocation } from 'react-router'
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable'
import useSystemState from '~/features/System/stores/SystemState'
import { storeScreenSettings } from '~/features/System/services'
import { Button } from '@mui/material'

const modal = document.getElementById('div-modal') as HTMLElement

type MessageModalDragProps = {
  readonly closeEvent?: () => void
}

export default function MessageModalDraggable({
  closeEvent,
}: MessageModalDragProps) {
  const { translate } = useLang()
  const { windowManager, loadingButton } = useSystemState();
  
  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    windowManager()['MessageModal'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y
    })
  };

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'MessageModalDraggable',
        isOpen: windowManager()['MessageModal'].open.get(),
        isMinimized: windowManager()['MessageModal'].minimized.get(),
        positionX: windowManager()['MessageModal'].positionX.get(),
        positionY: windowManager()['MessageModal'].positionY.get()
      })
    }
  }, [
    windowManager()['MessageModal'].open.get(),
    windowManager()['MessageModal'].minimized.get(),
    windowManager()['MessageModal'].positionY.get(),
    windowManager()['MessageModal'].positionX.get()
  ])

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!loadingButton.get() &&
        <StandardModal
          title={translate('Messages')}
          avatar={MessageIcon}
          dimensions={{
            width: 800,
            height: 470
          }}
          isExpanded={windowManager()['MessageModal'].minimized}
          closeButton={closeEvent ? closeEvent : () => windowManager()['MessageModal'].open.set(false)}
          handleStop={handleStop}
          position={{
            x: windowManager()['MessageModal'].positionX?.get() ? Number(windowManager()['MessageModal'].positionX.get()) : 250,
            y: windowManager()['MessageModal'].positionY?.get() ? Number(windowManager()['MessageModal'].positionY.get()) : 100
          }}
        >
          <Message />
        </StandardModal>
      }
    </>
    , modal
  )
}
