/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react';
import { Autocomplete, Chip, FormControl, TextField, Tooltip } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { Downgraded, useHookstate } from '@hookstate/core';
import { DispatchGroupResponse } from './interface';
import { getDispatchGroup, getDispatchGroupMe } from './service';

interface FilterAutocompleteDispatcheGroupsProps {
    readonly onGroupDispatchChange: (value: number[]) => void;
    readonly dispatcheGroups?: readonly number[];
    readonly disabled?: boolean;
    readonly agencyId?: number | null | readonly number[];
    readonly groupSetId?: number | null;
    readonly variableChange?: number | null;
}

export default function FilterAutocompleteDispatchsGroups({ agencyId, onGroupDispatchChange, dispatcheGroups, disabled = false, groupSetId, variableChange }: FilterAutocompleteDispatcheGroupsProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly DispatchGroupResponse[]>([]);
    const [optionsFilter, setOptionsFilter] = useState<readonly DispatchGroupResponse[]>([]);
    const [value, setValue] = useState<DispatchGroupResponse[] | undefined>([]);
    const loading = useHookstate(false);
    const agencies = Array.isArray(agencyId) ? agencyId : agencyId ? [agencyId] : []
    // const agencies = useHookstate<number[]>(JSON.parse(JSON.stringify()));

    useEffect(() => {
        getDispatchGroupMe().then(dispatchGroup => {
            setOptions(dispatchGroup)
            setOptionsFilter(dispatchGroup)
            setValue([])
        })
    }, [])

    useEffect(() => {
        if (agencies.length && groupSetId) {
            const filter = options.filter(typeMap => agencies.includes(typeMap.agency_id) && typeMap.group_sets.map(item => item.id).includes(groupSetId))
            setOptionsFilter(filter.length ? filter : [])

        } else if (agencies.length) {
            const filter = options.filter(typeMap => agencies.includes(typeMap.agency_id))
            setOptionsFilter(filter.length ? filter : [])

        } else if (groupSetId){
            const filter = options.filter(typeMap => typeMap.group_sets.map(item => item.id).includes(groupSetId))
            setOptionsFilter(filter.length ? filter : [])

        } else {
            setOptionsFilter(options)
        }

    }, [agencyId, groupSetId])

    useEffect(() => {
        if (!dispatcheGroups?.length) {
            setValue([])
        }
    }, [dispatcheGroups?.length])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                filterSelectedOptions
                disableCloseOnSelect
                limitTags={2}
                sx={{ height: 1 }}
                ListboxProps={{ style: { maxHeight: '15rem' } }}
                id='filter-dispatch-group'
                disabled={disabled}
                multiple
                options={optionsFilter}
                value={value}
                noOptionsText={translate('No options')}
                clearText={translate('Clear')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={(_, type) => {
                    setValue(type)
                    onGroupDispatchChange(type.map(type => type.id) ?? [])
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Dispatch Group')}
                    />
                )}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Tooltip title={option.name} key={String(option.id)}>
                            <Chip
                                label={option.name}
                                {...getTagProps({ index })}
                            />
                        </Tooltip>
                    ))
                }
            />
        </FormControl>
    )
}