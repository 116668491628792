import React from 'react';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { AppRouteProps } from '~/routes';

export default function EducationRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    {path: '/education', component: Index, isProtected: true, permissions: ['education_index']},
    {path: '/education/create', component: Create, isProtected: true, permissions: ['education_create']},
    {path: '/education/edit/:id', component: Edit, isProtected: true, permissions: ['education_edit']},

  ];

  return routes;
}
