import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@mui/material';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';
import ListDeviceLogs from '../components/ListDeviceLogs';
import InternalHeader from '~/components/Layout/components/InternalHeader';
import InternalContent from '~/components/Layout/components/InternalContent';
import IDevice from '~/features/Device/interfaces/IDevice';
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod';
import { useHookstate } from '@hookstate/core';
import notify from '~/utils/notify';
import { DeviceLogs } from '../interfaces';
import { getAllDevices } from '~/features/Device/services';
import { searchDevicesLogs } from '../services';
import FilterAutocompleteDevice from '~/components/FilterAutocompleteDevice';
import { Dayjs } from 'dayjs';
import { LoadingButton } from '@mui/lab';

export default function DeviceConnections() {
  const { translate } = useLang();
  const [deviceLogs, setDeviceLogs] = useState<readonly DeviceLogs[]>([]);
  const loading = useHookstate(false)
  const startDate = useHookstate<string | null>(null);
  const finishDate = useHookstate<string | null>(null);
  const deviceId = useHookstate<number | null | undefined>(null);
  const period = useHookstate('0')

  useEffect(() => {
    document.title = translate('Devices Connections - Dispatch System')
  }, [])

  const handleSearch = async () => {
    loading.set(true)
    if (!deviceId.get()) {
      loading.set(false)
      notify({ message: translate('Please enter a valid device'), type: 'error' })
      return;
    }
    if (!startDate.get() || !finishDate.get()) {
      loading.set(false)
      notify({ message: translate('Enter a start and end date'), type: 'error' })
      return;
    }
    const data = await searchDevicesLogs({
      deviceId: deviceId.get(),
      finishDate: finishDate.get(),
      startDate: startDate.get()
    }).finally(() => {
      loading.set(false)
    })
    setDeviceLogs(data);
  }
  return (
    <Layout marginLayout={true}>
      <InternalHeader title={translate(`Device connections`)} />
      <InternalContent title={`${translate('Filters')}`} sxFont={{ fontWeight: 'bold', fontSize: 18 }}>
        <Grid container direction={'row'}>
          <Grid item xs={5} sx={{ padding: 1 }}>
            <FilterAutocompleteDevice onDeviceChange={(device) => { deviceId.set(device) }} />
          </Grid>
          <FilterSelectPeriod
            onStartDateChange={(value) => startDate.set(value?.toJSON() ?? null)}
            onFinishDateChange={(value) => finishDate.set(value?.toJSON() ?? null)}
            onPeriodChange={(value) => { period.set(value) }}
            period={period.get()}
          />
        </Grid>
        <Grid direction={'row'} display={'flex'} justifyContent={'flex-end'} >
          <Grid item>
            <LoadingButton
              loading={loading.get()}
              variant="contained"
              onClick={handleSearch}>
              {translate('Search')}
            </LoadingButton>
          </Grid>
        </Grid>
      </InternalContent>
      <InternalContent title={`${translate('Connections')}`} sxFont={{ fontWeight: 'bold', fontSize: 18 }}>
        <ListDeviceLogs devices={deviceLogs} />
      </InternalContent>
    </Layout>
  );
}
