import React from 'react';
import { Grid, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { UseFormGetValues, UseFormRegister, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

interface CrudTextFieldProps {
  readonly register: UseFormRegister<any>;
  readonly model: string;
  readonly mask?: any | undefined;
  readonly getValues?: UseFormGetValues<any>;
  readonly passwd?: boolean;
  readonly control?: any;
  readonly placeholder?: string;
  readonly required?:boolean;
  readonly readonly?: boolean;
}

export default function CrudTextField(props: CrudTextFieldProps) {
  const { translate } = useLang();
  const { register, model, mask, getValues, passwd = false, control, placeholder, required = false, readonly = false} = props;
  const registeredModelFormField = register(model);
  return (
    <Grid item xs={4}>
      { mask ?
        <Controller
          {...registeredModelFormField }
          name={model}
          control={control}
          defaultValue={(getValues && getValues( model )) ?? ''}
          render={(props) => {
            return (
              <InputMask 
                {...props.field}
                mask={`${mask}`}
                value={props.field.value}
                maskChar="_"
              >
                <TextField 
                  fullWidth
                  label={translate(`${model.charAt(0).toUpperCase() + model.slice(1)}`)}
                  margin='normal'
                  defaultValue={(getValues && getValues( model )) ?? ''}
                  sx={{ mt: 0 }}
                  InputLabelProps={{
                    shrink: (props.field.value ?? ''),
                  }}
                  required={required}
                />
              </InputMask> 
            )}
          }
        />
        :
        <TextField
          {...registeredModelFormField }
          label={translate(`${placeholder ? placeholder.charAt(0).toUpperCase() + placeholder.slice(1) : model.charAt(0).toUpperCase() + model.slice(1)}`)}
          margin='normal'
          fullWidth
          type={passwd ? 'password' : 'text'}
          defaultValue={(getValues && getValues( model )) ?? ''}
          sx={{ mt: 0 }}
          InputLabelProps={{
            shrink: (getValues && !getValues( model )),
          }}
          required={required}
          disabled={readonly}
        />
      }
    </Grid>
  )
}