/* eslint-disable max-lines-per-function */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Card, CardHeader, Collapse, FormControl, Grid, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import ErrorFallback from '~/components/ErrorFallback';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';
import notify from '~/utils/notify';
import useReportState from '../../stores/ReportState';
import { downloadReportCSV, makeRequestTogetReportApproachesByUser } from '../../services/report';
import { useHookstate } from '@hookstate/core';
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FilterAutocompleteAgencies from '~/components/FilterAutocompleteAgencies';
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups';
import GenerateReportButton from '../../components/GenerateReportButton';
import FilterAutocompleteFetch from '~/components/FilterAutocompleteFetch';
import CancelIcon from '@mui/icons-material/Cancel';
import ExportButtonCSV from '../../components/ButtonExportCSV';


export interface DispatchClosedProps {
  readonly id: number;
  readonly name: string;
  readonly acronym: string;
  readonly category_id?: number;
}

export default function ApproachesByUser() {
  const expandFilterSearch = useHookstate<boolean>(true);
  const { translate } = useLang();
  const isLoading = useHookstate<boolean>(false);
  const lockFields = useHookstate<boolean>(false);
  const startDate = useHookstate<string | null>(null);
  const finishDate = useHookstate<string | null>(null);
  const period = useHookstate('0')
  const cpf = useHookstate<string | null>(null)
  const plate = useHookstate<string | null>(null)
  const userId = useHookstate<number | null>(null)
  const agencyIds = useHookstate<readonly number[]>([]);
  const labelButton = useHookstate('');
  const groupId = useHookstate<readonly number[]>([]);
  const resetValeus = useHookstate(false)

  const reportName = 'approaches-by-user'

  const { reportData, loadingReport } = useReportState();

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])

  function generateReport() {
    if (startDate && finishDate) {
      loadingReport().set(true)
      isLoading.set(true)
      makeRequestTogetReportApproachesByUser({
        startDate: startDate.get(),
        finishDate: finishDate.get(),
        agencyIds: agencyIds.get(),
        dispatchGroupsId: groupId.get(),
        cpf: cpf.get(),
        plate: plate.get(),
        userId: userId.get()
      })
        .then(response => {
          reportData().set(response)
          window.open(`/report/result/${reportName}`)
        }).finally(() => {
          loadingReport().set(false)
          isLoading.set(false)
        })
    } else {
      notify({ message: translate('Initial and final date are mandatory'), type: 'error' })
    }
  }

  const exportCSV = async () => {
    if (startDate && finishDate) {
      isLoading.set(true)
      loadingReport().set(true)
      const dataCSV = await makeRequestTogetReportApproachesByUser({
        startDate: startDate.get(),
        finishDate: finishDate.get(),
        agencyIds: agencyIds.get(),
        dispatchGroupsId: groupId.get(),
        cpf: cpf.get(),
        plate: plate.get(),
        userId: userId.get(),
        exportCSV: true
      }).finally(() => {
        isLoading.set(false)
        loadingReport().set(false)
      })
      downloadReportCSV(`${translate(reportName)}-${new Date().getTime()}.csv`, dataCSV)
    } else {
      notify({ message: translate('Initial and final date are mandatory'), type: 'error' })
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true} >
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader title={`${translate('Report')}: ${translate(`Approaches by user`)}`} />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader title={`${translate('Filters')}`}
            avatar={(
              <IconButton
                onClick={() => expandFilterSearch.set(!expandFilterSearch.get())}
                sx={{ display: 'flex', justifyContent: 'end', marginRight: 1, padding: 0 }}>
                {expandFilterSearch.value ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
              </IconButton>
            )}
          />
          <Collapse in={expandFilterSearch.get()}>
            <Grid
              container xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
              }}
            >
              <Grid container direction={'row'} rowGap={2}>

                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteAgencies
                    disabled={lockFields.get()}
                    agenciesId={agencyIds.get()}
                    onAgenciesChange={(agency) => {
                      labelButton.set(translate('Search'))
                      agencyIds.set(agency)
                    }} />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchsGroups
                    disabled={lockFields.get()}
                    agencyId={agencyIds.get() ?? undefined}
                    dispatcheGroups={groupId.get() ?? undefined}
                    onGroupDispatchChange={(group) => {
                      groupId.set(group)
                      // deviceId.set(null)
                      labelButton.set(translate('Search'))
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteFetch
                    label={'User'}
                    urlFetch={'/user/get'}
                    size={"small"}
                    disabled={lockFields.get()}
                    onFetchChange={(user) => user ? userId.set(user) : null}
                    resetValues={resetValeus.get()}
                  />
                </Grid>

                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FormControl variant="standard">
                    <OutlinedInput
                      id="plate"
                      value={plate.get()}
                      size='small'
                      placeholder={translate('Plate')}
                      onChange={(event) => {
                        plate.set(String(event.target.value).toUpperCase())
                      }}
                      inputProps={{
                        maxLength: 7
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle clear"
                            onClick={() => {
                              plate.set('')
                            }}
                            edge="end"
                          >
                            {plate.get()?.length ? <CancelIcon fontSize='small' /> : ''}
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                  </FormControl>
                </Grid>


                <Grid container direction={'row'}>
                  <FilterSelectPeriod
                    disabled={lockFields.get()}
                    onStartDateChange={(value) => startDate.set(value?.toJSON() ?? null)}
                    onFinishDateChange={(value) => finishDate.set(value?.toJSON() ?? null)}
                    onPeriodChange={(value) => { period.set(value) }}
                    period={period.get()}
                  />
                </Grid>
              </Grid>

              <Grid item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 2
                }}>
                <ExportButtonCSV
                  isLoading={isLoading.get()}
                  dataToExport={exportCSV}
                />
                <GenerateReportButton
                  isLoading={isLoading.get()}
                  makeReport={generateReport}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary >
  )
}
