import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import constants from '~/core/constants';
import useCookie from '~/hooks/useCookie';
import { useAuth } from '~/features/auth/hooks/useAuth';


function ProtectedRoute({component: Component, ...rest}) {
  const { user } = useAuth();
  const [identificationKey] = useCookie(constants.keys.cookies.identificationKey);
  const [anonymousKey] = useCookie(constants.keys.cookies.anonymousIdentificationKey);

  if ('render' in rest) return <Route {...rest} />;

  return <Route {...rest} render={(props) => {
    if (identificationKey == null || identificationKey == undefined || identificationKey == false) {
      return <Redirect to={{pathname: '/activation', state: { from: props.location }}}/>
      // return <Redirect to={'/activation'}/>
    } 
    // else if(user.id == null && (identificationKey != null || identificationKey != undefined || identificationKey != false) && (!rest.path.startsWith('/login'))) {
    //   console.log('entrei no 2 if');
    //   // return <Redirect to={{pathname: '/login', state: { from: props.location }}}/>  
    //   return <Redirect to={'/login'}/>  
    // } 

    return <Component {...rest} />;
  }}/>
}


export default ProtectedRoute;
