import { authFetch } from '~/services/fetch';
import { IConfig, ISystemConfigAllByGroups, ISytemConfigSend } from '../interfaces/ISystemConfig';
import { State } from '@hookstate/core';

export async function getSystemConfigService() {
  try {
    const response = await authFetch({
      url: '/system/config',
      method: 'GET',
    })

    return response.data;

  } catch (error) {
    throw error
  }
}

export async function getAllSystemConfigService(): Promise<readonly ISystemConfigAllByGroups[]> {
  try {
    const response = await authFetch({
      url: '/system/config/all-by-groups',
      method: 'GET',
    })

    return response.data;

  } catch (error) {
    throw error
  }
}

export async function updateSystemConfigService({ id, name, required, val }: Omit<IConfig, 'value' | 'fieldType'>): Promise<readonly ISystemConfigAllByGroups[]> {
  try {
    const response = await authFetch({
      url: `/system/config/${id}`,
      method: 'PUT',
      data: {
        name,
        required,
        value: val
      }
    })

    return response.data;

  } catch (error) {
    throw error
  }
}

export async function updateAllSystemConfigService(data: readonly ISytemConfigSend[]): Promise<readonly ISystemConfigAllByGroups[]> {
  try {
    const response = await authFetch({
      url: '/system/config/update-all',
      method: 'POST',
      data,
    })

    return response.data;

  } catch (error) {
    throw error
  }
}



export async function getAllowAnonymousStationConfigService() {
  try {
    const response = await authFetch({
      url: '/system/allow-anonymous-station',
      method: 'GET',
    })

    return response.data;

  } catch (error) {
    throw error
  }
}

export async function getUserSettings(location) {
  const response = await authFetch({
    url: '/screen-setting/get-user-settings',
    method: 'POST',
    data: {
      url: location,
    }
  })
  return response
}

export async function storeScreenSettings({windowName, isOpen, isMinimized, positionX, positionY}){
  authFetch({
    url: '/screen-setting/store',
    method: 'POST',
    data: {
      url: location.pathname,
      window_name: windowName,
      is_window_open: isOpen,
      is_window_minimized: isMinimized,
      x_position: Number(positionX),
      y_position: Number(positionY),
    }
  })
}