import React, { ChangeEvent } from 'react'
import { useLang } from '~/hooks/useLang';
import { Box } from '@mui/system';
import { useHookstate } from '@hookstate/core';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ListAllVehicle, { ListVehicleTab, useListAllVehicleState, useListAllVehicleNoPaginationState } from '../ListAllVehicle';
import { Card, CardContent, IconButton, TextField, CardHeader, Button, Link } from '@mui/material';
import { Search } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '~/components/ErrorFallback';


interface ITabListVehicle {
  readonly isNewTabWindow?: boolean;
}

export default function TabListVehicle({ isNewTabWindow = false }: ITabListVehicle) {
  const { translate } = useLang();
  const listAllVehicleState = useListAllVehicleState();
  const state = useHookstate(listAllVehicleState.tab.get());
  const filters = useHookstate(listAllVehicleState.filters);
  const search = useHookstate('');

  const handleTabVehicle = (event: React.SyntheticEvent, value: ListVehicleTab) => {
    state.set(value);
  };

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.keyCode === 13) {
      applyFilter();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  function applyFilter() {
    filters.set((search.get().length > 0) ? [{ field: "name", operator: "contains", value: search.get() }] : []);
    listAllVehicleState.toggleRefresh();
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Card sx={{ width: '100%', typography: 'body1' }}>
        <Box sx={{
          display: 'flex', flexDirection: 'row', pt: 2
        }}>
          <CardHeader title={translate(`Vehicles`)} />

          {isNewTabWindow &&
            <>
              <Box sx={{ flex: '1 1 auto' }} />
              <Link target='_blank' href={'/dispatch/vehicles/list'}>
                <Button
                  variant="text"
                  color="primary"
                  sx={{ mr: 3 }}
                >
                  <OpenInNewIcon />
                </Button>
              </Link>
            </>
          }
        </Box>

        <CardContent>
          <TabContext value={state.get()}>

            <TextField
              id='search'
              name='search'
              size='small'
              value={search.get()}
              onChange={(event: ChangeEvent<HTMLInputElement>) => { search.set(event.target.value) }}
              onKeyDown={(event) => handleKeyDown(event)}
              label={translate('Enter the vehicle')}
              InputProps={{
                endAdornment:
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={() => {
                    applyFilter()
                  }}>
                    <Search />
                  </IconButton>
              }}
            />
            <TabList onChange={handleTabVehicle}>
              <Tab label={translate('Online')} value='available' />
              <Tab label={translate('Dispatched')} value='dispatched' />
              <Tab label={translate('Disconnected')} value='disconnected' />
              <Tab label={translate('Not in Service')} value='not-in-service' />
            </TabList>

            <TabPanel sx={{ padding: 0 }} value="available">
              <ListAllVehicle
                targetUrl={'/device/available'}
                listAllVehicleState={listAllVehicleState}
                exceptColumns={['dispatch', 'location', 'comment']}
                hasPagination={isNewTabWindow ? true : false}
              />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value="dispatched">
              <ListAllVehicle
                targetUrl={'/device/dispatched'}
                listAllVehicleState={listAllVehicleState}
                exceptColumns={['comment']}
                hasPagination={isNewTabWindow ? true : false}
              />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value="disconnected">
              <ListAllVehicle
                targetUrl={'/device/disconnected'}
                listAllVehicleState={listAllVehicleState}
                exceptColumns={['dispatch', 'location', 'comment', 'task']}
              />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value="not-in-service">
              <ListAllVehicle
                targetUrl={'/device/not-in-service'}
                listAllVehicleState={listAllVehicleState}
                exceptColumns={['dispatch', 'location']}
                hasPagination={isNewTabWindow ? true : false}
              />
            </TabPanel>
          </TabContext>
        </CardContent>
      </Card>
    </ErrorBoundary>
  )
}
