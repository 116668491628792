import { authFetch } from '~/services/fetch';


export async function getLastCallsByPhone({phone, page, limit, filters}) {
  try {
    const response = await authFetch({

      url: '/entry-log/get-by-phone',
      method: 'POST',
      data: {
        phone: phone,
        page: page,
        limit: limit,
        orders: [{field: 'id', sort: 'desc'}],
        filters: filters,
      }
    })
    
    return response.data;

  } catch (error) {

    throw error
  }
}

export async function getTotalPrankCalls({phone}){
  try{
    const response = await authFetch({

      url: '/entry-log/prank-calls-by-phone',
      method: 'POST',
      data: {
        phone: phone,
      }
    })

    return response.data;

  }catch(error){
    throw error
  }
}
