import React from 'react';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { AppRouteProps } from '~/routes';

export default function DeviceOutOfServiceReasonRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    {path: '/device/out-of-service-reason', component: Index, isProtected: true, permissions: ['device_out_of_service_reason_index']},
    {path: '/device/out-of-service-reason/create', component: Create, isProtected: true, permissions: ['device_out_of_service_reason_create']},
    {path: '/device/out-of-service-reason/edit/:id', component: Edit, isProtected: true, permissions: ['device_out_of_service_reason_edit']},

  ];

  return routes;
}
