import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IBreed from '../interfaces/IBreed';

export default function Edit() {
  const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm<IBreed>();
  const fields = [
    { name: 'id', type: 'hidden' },
    { name: 'name', type: 'string', required: true},
    { name: 'specie_id', type: 'selectAutoComplete', model: 'specie', placeholder: 'Specie', method: 'GET', required: true},
  ];

  return (
    <CrudEdit
      model={'breed'}
      fields={fields}
      register={register}
      handleSubmit={handleSubmit}
      control={control}
      setFormValue={setFormValue}
      messageSuccess={'Breed successfully updated!'}
      title={'Edit Breed'}
      getValues={getValues}
    />
  )
}