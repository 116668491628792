/* eslint-disable functional/prefer-readonly-type */
/* eslint-disable max-lines-per-function */
import { useHookstate } from '@hookstate/core'
import { Print } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import BlockIcon from '@mui/icons-material/Block'
import CloseIcon from '@mui/icons-material/Close'
import CommentIcon from '@mui/icons-material/Comment'
import DeleteIcon from '@mui/icons-material/Delete'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridFilterItem,
  GridRowModel,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid'
import { ptBR } from '@mui/x-data-grid/locales';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import dayjs, { Dayjs } from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ButtonRound from '~/components/ButtonRound'
import ConfirmDialog from '~/components/ConfirmDialog'
import Layout from '~/components/Layout'
import InternalContent from '~/components/Layout/components/InternalContent'
import InternalHeader from '~/components/Layout/components/InternalHeader'
import { SendCommentField } from '~/components/SendCommentField'
import userUserState from '~/features/User/stores/UserState'
import { useLang } from '~/hooks/useLang'
import { useTheme } from '~/hooks/useTheme'
import { authFetch } from '~/services/fetch'
import { dateTimeFormat } from '~/utils/dateTime'
import { createCoordinationObservation } from '../services'
import FilterAutocompleteFetch from '~/components/FilterAutocompleteFetch'

interface IObservationsCoordination {
  readonly id: number | null
  readonly notes: string | null
  readonly created_at: string | null
  readonly user: {
    readonly id: number | null
    readonly representation_name: string | null
  }
}

export default function Coordination() {
  const { verifyPermission } = userUserState()
  const { translate } = useLang()
  const forceRefresh = useHookstate(false)
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null)
  const [finishDate, setFinishDate] = React.useState<Dayjs | null>(null)
  const [rows, setRows] = React.useState<readonly GridRowModel[]>([])
  const [rowCount, setRowCount] = React.useState(0)
  const [filters, setFilters] = React.useState<readonly GridFilterItem[]>([])
  const [loading, setLoading] = React.useState(true)
  const [sort, setSort] = React.useState<GridSortModel>([
    { field: 'date_begin', sort: 'desc' },
  ])
  const [page, setPage] = React.useState<any>(0)
  const [rowId, setRowId] = React.useState<any>()
  const [rowDateEnd, setRowDateEnd] = React.useState<Dayjs | null>(null)
  const openDialog = useHookstate(false)
  const openDialogConfirmDelete = useHookstate(false)
  const openDialogClosure = useHookstate(false)
  const dispatchGroupId = useHookstate<{ id: number; name: string } | null>({
    id: 0,
    name: '',
  })
  const dispatchGroupOptions = useHookstate<
    Array<{ id: number; name: string }>
  >([])
  const coordinationId = useHookstate<{
    id: number
    name: string
    representation_name: string
  } | null>({ id: 0, name: '', representation_name: '' })
  const coordinationOptions = useHookstate<
    Array<{ id: number; name: string; representation_name: string }>
  >([])
  const newObservation = useHookstate('')
  const observations = useHookstate<readonly IObservationsCoordination[]>([])
  const currentId = useHookstate<number | null>(0)
  const reloadListObservation = useHookstate(false)
  const observationModalId = useHookstate<number | null>(null)
  const currentCoordinatorModal = useHookstate('')
  const loadingObservationModal = useHookstate(false)
  const { darkMode } = useTheme()
  const resetValues = useHookstate(false)
  const userId = useHookstate<number | null>(null)

  const tableMode = 'server'
  useEffect(() => {
    document.title = translate('Coordination - Dispatch System')
  }, [])


  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = useCallback(
    (row) => {
      setOpenModal(true)
      loadingObservationModal.set(true)
      currentId.set(row.id)
      currentCoordinatorModal.set(row.coordinator)
      requestObservations(row.id)
    },
    [currentId]
  )

  const handleCloseModal = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenModal(false)
      newObservation.set('')
    }
  }

  const clearFilters = () => {
    dispatchGroupId.set({ id: 0, name: '' })
    coordinationId.set({ id: 0, name: '', representation_name: '' })
    setStartDate(null)
    setFinishDate(null)
    resetValues.set(!resetValues.get())
  }

  const limit = 10

  const history = useHistory()
  const handleCreateClick = () => {
    history.push(`/dispatch/coordination/create`)
  }

  const handlePrintClick = (id: string) => {
    window.open(`/coordination/summary/${id}`)
  }

  const columns: GridColDef[] = [
    { field: 'id' },
    {
      field: 'coordinator',
      headerName: translate('Coordinator'),
      flex: 1.0,
      filterable: false,
      sortable: false,
    },
    {
      field: 'date_begin',
      headerName: translate('Initial Date'),
      flex: 1.0,
      filterable: false,
      sortable: false,
    },
    {
      field: 'date_expected_closing',
      headerName: translate('Expected Closing Date'),
      flex: 1.0,
      filterable: false,
      sortable: false,
    },
    {
      field: 'date_end',
      headerName: translate('End Date'),
      flex: 1.0,
      filterable: false,
      sortable: false,
    },
    {
      field: 'created_by_user',
      headerName: translate('Creator'),
      flex: 1.0,
      filterable: false,
      sortable: false,
    },
    {
      field: 'dispatch_groups',
      headerName: translate('Dispatch Group'),
      flex: 1.0,
      filterable: false,
      sortable: false,
    },
    {
      field: 'actions', type: 'actions',
      flex: 0.5,
      getActions: (params: GridRowParams) => {
        return [
          <Tooltip key={2} title={translate('Comments')}>
            <GridActionsCellItem
              icon={<CommentIcon />}
              disabled={params.row.date_end}
              onClick={() => handleOpenModal(params.row)}
              label="Comments"
            />
          </Tooltip>,
          verifyPermission(['dispatch_coordination_delete']) ? (
            <Tooltip
              title={
                !params.row.canDelete
                  ? translate('You cannot delete coordination already started')
                  : translate('Delete a coordination')
              }
            >
              <span>
                <GridActionsCellItem
                  disabled={!params.row.canDelete}
                  key={0}
                  icon={<DeleteIcon />}
                  onClick={() => handleDelete(params.row.id)}
                  label="Delete"
                />
              </span>
            </Tooltip>
          ) : (
            <></>
          ),
          <Tooltip
            key={2}
            title={
              !params.row.date_end
                ? translate('You cannot print an open coordination')
                : translate('Print coordination')
            }
          >
            <span>
              <GridActionsCellItem
                disabled={!params.row.date_end}
                icon={<Print />}
                onClick={() => handlePrintClick(params.row.id)}
                label="Print"
              />
            </span>
          </Tooltip>,
          verifyPermission(['dispatch_coordination_close']) ? (
            <GridActionsCellItem
              key={1}
              icon={<BlockIcon />}
              disabled={!params.row.canClose}
              onClick={() => handleClosure(params.row.id)}
              label="Encerrar"
            />
          ) : (
            <></>
          ),
        ]
      },
    },
  ]

  const styleModal = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: darkMode ? '#242424' : '#FFF',
    // border: '2px solid #000',
    boxShadow: 24,
    padding: 4,
    minHeight: 700,
  }

  const handleDialogConfirmClose = () => {
    openDialogConfirmDelete.set(false)
  }

  const handleDialogConfirmOpen = (id: number) => {
    openDialogConfirmDelete.set(true)
    observationModalId.set(id)
  }

  const handleDialogOpen = () => {
    openDialog.set(true)
    openDialogClosure.set(false)
  }

  const handleDialogClose = () => {
    openDialog.set(false)
    openDialogClosure.set(false)
  }

  const handleDialogClosureOpen = (id: number) => {
    openDialogClosure.set(true)
    openDialog.set(false)
    // setRowDateEnd(null);
    getSuggestionDate(id)
  }

  function handleDelete(id: number) {
    handleDialogOpen()
    setRowId(id)
  }

  function handleClosure(id: number) {
    setRowId(id)
    handleDialogClosureOpen(id)
    // setRowDateEnd(date)
  }

  const requestObservations = (id: number) => {
    try {
      authFetch({
        url: `/dispatch/coordination/${id}/comments`,
        method: 'GET',
      })
        .then((response) => {
          observations.set(response.data)
          if (reloadListObservation.get() === true) {
            reloadListObservation.set(false)
          }
        })
        .finally(() => loadingObservationModal.set(false))
    } catch (error) {
      throw error
    }
  }

  const handleCreateObservation = (id: number, notes: string) => {
    createCoordinationObservation(id, notes)
    reloadListObservation.set(!reloadListObservation.get())
  }

  const handleDeleteObservations = (id: number) => {
    const url = `/dispatch/coordination/comment/${id}`
    authFetch({
      url: url,
      method: 'DELETE',
    }).then((response) => {
      if (response.status === 200) {
        reloadListObservation.set(!reloadListObservation.get())
        handleDialogConfirmClose()
      }
    })
  }

  const getSuggestionDate = (id: number) => {
    authFetch({
      url: `/dispatch/coordination/${id}/get-closed-suggestion-date`,
      method: 'GET',
    }).then((response) => {
      setRowDateEnd(response.data.date_suggestion)
    })
  }

  useEffect(() => {
    if (currentId.get() != 0) {
      requestObservations(Number(currentId.get()))
    }
    newObservation.set('')
  }, [reloadListObservation.get()])

  const handleDialogCloseFunction = () => {
    const url = `/dispatch/coordination/${rowId}`
    authFetch({
      url: url,
      method: 'DELETE',
    }).then(() => {
      setRows(rows.filter((el) => el.id != rowId))
      handleDialogClose()
      setFilters([])
    })
  }

  const handleDialogClosureFunction = () => {
    authFetch({
      url: `/dispatch/coordination/close`,
      method: 'POST',
      data: {
        coordination_id: rowId,
        date_end: rowDateEnd,
      },
    }).then(() => {
      handleDialogClose()
      setFilters([])
      forceRefresh.set(!forceRefresh.get())
    })
  }

  React.useEffect(() => {
    const targetUrl = `/dispatch/coordination`
    setLoading(true)

    authFetch({
      url: targetUrl,
      data: {
        orders: sort,
        page: page,
        limit: limit,
        start_date: startDate,
        finish_date: finishDate,
        dispatch_group_id: dispatchGroupId.get()?.id,
        user_id: coordinationId.get()?.id,
      },
    })
      .then(({ data: { data, rowsCount } }) => {
        setRows(
          data.map((row) => {
            return {
              id: row?.id,
              coordinator: row?.coordinator?.representation_name,
              date_begin: dateTimeFormat(row?.date_begin),
              date_expected_closing: dateTimeFormat(row?.date_expected_closing),
              date_end: dateTimeFormat(row?.date_end),
              created_by_user: row?.created_by_user?.representation_name,
              dispatch_groups: row?.dispatch_groups.map(
                (element) => element.name
              ),
              canDelete: row.canDelete,
              canClose: row.canClose,
            }
          })
        )
        setRowCount(rowsCount)
      })
      .finally(() => setLoading(false))
  }, [forceRefresh.get()])

  React.useEffect(() => {
    authFetch({
      url: `/station/dispatch-groups/me`,
      method: 'GET',
    }).then(({ data }) => {
      const dispatchGroups = data.map((dispatchGroup) => {
        return { id: dispatchGroup.id, name: dispatchGroup.name }
      })
      dispatchGroupOptions.set(dispatchGroups)
    })

    authFetch({
      url: '/user',
      method: 'GET',
    }).then(({ data }) => {
      const coordinations = data
        .map((coordination) => {
          return {
            id: coordination.id,
            name: coordination.name,
            representation_name: coordination.representation_name,
          }
        })
        .filter(
          (value1, idx1, arr1) =>
            arr1.findIndex((value2) => value2.id === value1.id) === idx1
        )
      coordinationOptions.set(coordinations)
    })
  }, [])

  useEffect(() => {
    if(userId.get()){
      const coordinator = coordinationOptions.get().find(item => item.id == userId.get())
      if(coordinator){
        coordinationId.set({ id: coordinator.id , name: coordinator.name, representation_name: coordinator.representation_name })
      }

    } else {
      coordinationId.set({ id: 0, name: '', representation_name: '' })

    }
  }, [userId.get()])

  return (
    <Layout marginLayout={true}>
      <InternalHeader
        title={translate(`Coordination`)}
        action={
          <ButtonRound onClick={handleCreateClick}>
            <AddIcon color="primary" />
          </ButtonRound>
        }
      />
      <InternalContent expandButton={false}>
        <Grid xs={12} container direction={'row'}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={2} sx={{ padding: 1 }}>
              <DateTimePicker
                format="DD/MM/YYYY HH:mm:ss"
                ampm={false}
                ampmInClock={false}
                value={startDate ? dayjs(startDate) : startDate}
                onChange={(value) => {
                  setStartDate(value)
                }}
                slotProps={{ textField: {
                  margin: "normal",
                  id: 'Initial Date',
                  label: translate(`Initial Date`),
                  name: 'Initial Date',
                  fullWidth: true,
                  size: "small",
                  sx: { marginTop: 0 }
                } }}
              />
            </Grid>
            <Grid item xs={2} sx={{ padding: 1 }}>
              <DateTimePicker
                format="DD/MM/YYYY HH:mm:ss"
                ampm={false}
                ampmInClock={false}
                value={finishDate ? dayjs(finishDate) : finishDate}
                onChange={(value) => {
                  setFinishDate(value)
                }}
                slotProps={{ textField: {
                  margin: "normal",
                  id: 'End Date',
                  label: translate(`End Date`),
                  name: 'End Date',
                  fullWidth: true,
                  size: "small",
                  sx: { marginTop: 0 }
                } }}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={2} sx={{ padding: 1 }}>
            <Autocomplete
              options={dispatchGroupOptions.get()}
              noOptionsText={translate('No options')}
              size="small"
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => (
                <TextField {...params} label={translate('Dispatch Group')} />
              )}
              onChange={(event, value) => {
                dispatchGroupId.set({
                  id: value?.id ?? 0,
                  name: value?.name ?? '',
                })
              }}
              value={dispatchGroupId.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ padding: 1 }}>
          <FilterAutocompleteFetch
          label={'Coordinator'}
          urlFetch={'/user/get'}
          size={"small"}
          onFetchChange={(user) => userId.set(user)}
          resetValues={resetValues.get()}
        />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              gap: 2,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                ml: 2,
              }}
              onClick={() => clearFilters()}
            >
              {translate('Clear')}
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              onClick={() => forceRefresh.set(!forceRefresh.get())}
            >
              {translate('Search')}
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid xs={12} container>
          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            pageSizeOptions={[limit]}
            columns={columns}
            columnVisibilityModel={{
              id: false,
            }}
            autoHeight={true}
            initialState={{
              pagination: { paginationModel: { pageSize: limit } },
            }}
            pagination
            rowCount={rowCount}
            paginationMode={tableMode}
            filterMode={tableMode}
            sortingMode={tableMode}
            sortModel={sort}
            loading={loading}
            onPaginationModelChange={(pageContent) => {
              setPage(pageContent.page)
              forceRefresh.set(!forceRefresh.get())
            }}
            onFilterModelChange={(onFilterChange) =>
              setFilters(onFilterChange['items'])
            }
            onSortModelChange={(onSortChange) => setSort(onSortChange)}
            disableColumnSelector={true}
          />
          <ConfirmDialog
            handleAgree={handleDialogCloseFunction}
            handleClose={handleDialogClose}
            open={openDialog}
            title={translate('Confirm delete?')}
          />
          <Dialog open={openDialogClosure.get()} onClose={handleDialogClose}>
            <DialogTitle>{translate('Confirm closure?')}</DialogTitle>
            <DialogContent>
              <Grid xs={12} container>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    format="DD/MM/YYYY HH:mm"
                    ampm={false}
                    ampmInClock={false}
                    value={dayjs(rowDateEnd)}
                    onChange={(value) => {
                      setRowDateEnd(value)
                    }}
                    slotProps={{ textField: {
                      size: "small",
                      id: 'End Date',
                      label: translate(`End Date`),
                      name: 'End Date',
                      fullWidth: true, 
                      sx: { marginTop: 1 }
                    } }}
                    // renderInput={(params) => (
                    //   <TextField
                    //     {...params}
                    //     size="small"
                    //     id={'End Date'}
                    //     label={translate(`End Date`)}
                    //     name={'End Date'}
                    //     fullWidth
                    //     sx={{ marginTop: 1 }}
                    //   />
                    // )}
                  />
                </LocalizationProvider>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => handleDialogClose()}>
                {translate('Cancel')}
              </Button>
              <Button
                variant="contained"
                onClick={() => handleDialogClosureFunction()}
              >
                {translate('Ok')}
              </Button>
            </DialogActions>
          </Dialog>

          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModal}>
              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>
                  {translate('Coordinator') +
                    ': ' +
                    currentCoordinatorModal.get()}
                </Typography>
                <IconButton title={translate('Close')}>
                  <CloseIcon onClick={() => setOpenModal(false)} />
                </IconButton>
              </Box>
              <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {translate('Notes')}
                </Typography>
              </Box>

              <Box
                sx={{
                  mt: 1,
                }}
              >
                <SendCommentField
                  disabled={false}
                  id={currentId.get()}
                  name="Notes"
                  label={translate('Notes')}
                  required={true}
                  handleClickSend={() => console.log('Enviando')}
                  uploadImageOption={false}
                  newComment={() => newObservation}
                  sendComment={() =>
                    handleCreateObservation(
                      Number(currentId.get()),
                      newObservation.get()
                    )
                  }
                />
              </Box>
              <Box>
                <List
                  sx={{
                    width: '100%',
                    maxHeight: '500px',
                    bgcolor: darkMode ? '#242424' : '#FFF',
                    marginTop: 2,
                    overflowY: 'auto',
                  }}
                >
                  {loadingObservationModal.get()
                    ? ''
                    : observations.get().map((observation) => (
                        <>
                          <ListItem
                            alignItems="flex-start"
                            key={observation.id}
                            sx={{ padding: '8px 0' }}
                          >
                            <ListItemText
                              sx={{
                                display: 'inline',
                                maxHeight: '150px',
                                overflow: 'auto',
                                whiteSpace: 'pre-line',
                              }}
                              primary={observation.notes}
                              secondary={
                                <React.Fragment>
                                  <Typography sx={{ display: 'inline' }}>
                                    {observation.user.representation_name}
                                  </Typography>
                                  {' | ' +
                                    dayjs(observation.created_at).format(
                                      'DD-MM-YYYY HH:mm:ss'
                                    )}
                                </React.Fragment>
                              }
                            />
                            <IconButton
                              title={`${translate('Delete notes')}`}
                              size="small"
                              onClick={() =>
                                handleDialogConfirmOpen(Number(observation.id))
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItem>
                          <Divider component="li" />
                        </>
                      ))}
                </List>
              </Box>
            </Box>
          </Modal>
          <ConfirmDialog
            handleAgree={() =>
              handleDeleteObservations(Number(observationModalId.get()))
            }
            handleClose={() => handleDialogConfirmClose()}
            open={openDialogConfirmDelete}
            title={translate('Confirmation')}
            content={translate('Confirm delete?')}
          />
        </Grid>
      </InternalContent>
    </Layout>
  )
}
