import React from "react";
import { useHookstate } from '@hookstate/core';
import {IconButton, Badge, Tooltip} from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import MessageModalDraggable from "../MessageModalDraggable";
import useMessageState from '../../stores/MessageState';
import { useLang } from '~/hooks/useLang';

export default function MessageButton(){
  const isOpen = useHookstate(false);
  const { messagesNotReadCount, syncMessagesNotReadCount } = useMessageState();
  const { translate } = useLang();

  const toggleEvent = () => {
    isOpen.set((oldIsOpen) => !oldIsOpen);
  }

  React.useEffect(() =>{
    syncMessagesNotReadCount();
  }, []);

  return (
    <>
      <Tooltip title={translate('Messages')} placement="bottom">
        <IconButton size="large" onClick={toggleEvent}>
          <Badge badgeContent={messagesNotReadCount().get()} color="primary">
            <MessageIcon/>
          </Badge>
        </IconButton>
      </Tooltip>

      {isOpen.get() && <MessageModalDraggable closeEvent={ toggleEvent } /> }
    </>
      );

}