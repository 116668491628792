import React, {useEffect} from "react";
import useSystemState from '../../stores/SystemState';
import { Downgraded, useHookstate } from "@hookstate/core";
import { Box } from "@mui/material";

export default function WindowDraggableList() {
  const { windowManager } = useSystemState();
  const windowManagerLocal = useHookstate({});

  useEffect(() => {
    windowManagerLocal.set(windowManager())
  }, [])

  return (
    <div>
      {Object.keys(windowManagerLocal.value).map((key) => windowManager()[key].open.attach(Downgraded).get() ? (
        <Box key={key}>
          {windowManager()[key].component.attach(Downgraded).get()}
        </Box >) as JSX.Element : null)}
    </div>
  )
}