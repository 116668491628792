import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core'
import { CardHeader, Grid, IconButton, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import WidgetCreate from '~/components/WidgetCreate'
import { useLang } from '~/hooks/useLang'
import { useDispatchDashboardState } from '../../stores/DispatchDashboardState'
import { IDespacthStatisticGraphProps } from '../../interfaces/IDispacthStatistic'
import {
  getDispatchByStatus,
  getDeviceByStatus,
} from '../DispatchByTotalByStatusCards/services'

type PayloadProps = {
  readonly withSolvedThirdParty?: boolean
  readonly exludeClosed?: boolean
}
type Payload2Props = {
  readonly excludeDisconnected?: boolean
}
type ResponseProps = ReadonlyArray<{
  readonly id: number
  readonly name: string
  readonly total: number
}>

export default function DeviceByTotalByStatusCards({
  loading,
  agencyId,
  deviceId,
  dispatchGroupId,
  category,
  type,
  subtype,
  dateStart,
  dateFinish,
  thirdParty,
  refresh,
  groupSetId,
  operationUnitId,
  expandFilterSearch,
}: IDespacthStatisticGraphProps) {
  const { translate } = useLang()
  const vehicleData = useHookstate<ResponseProps>([])
  const payload = useHookstate<PayloadProps>({
    withSolvedThirdParty: thirdParty,
    exludeClosed: false,
  })
  const payload2 = useHookstate<Payload2Props>({ excludeDisconnected: false })

  useEffect(() => {
    payload.set({ withSolvedThirdParty: thirdParty, exludeClosed: false })
    loading.set(true)
    getDeviceByStatus(
      agencyId,
      deviceId,
      dispatchGroupId,
      category,
      type,
      subtype,
      dateStart,
      dateFinish,
      payload2.get(),
      groupSetId,
      operationUnitId
    )
      .then((response) => {
        const result = response.map((responseData) => ({
          id: responseData.id,
          name: responseData.name,
          total: responseData.total,
        }))
        return vehicleData.set(result)
      })
      .finally(() => {
        loading.set(false)
      })
  }, [refresh])

  return (
    <Grid container xs={12} sx={{ display: 'flex', paddingX: 3 }}>
      <Grid item xs={12} hidden={!expandFilterSearch}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
          {translate('Data by Status')}
        </Typography>
      </Grid>
      <Grid item xs={12} hidden={!expandFilterSearch}>
        <WidgetCreate
          title={'Device:name'}
          data={vehicleData.attach(Downgraded).get()}
          sx={{ marginBottom: '16px', fontSize: 'large' }}
        />
      </Grid>
    </Grid>
  )
}
