/* eslint-disable max-lines-per-function */
import React from 'react'
import {
  createState,
  useState,
} from '@hookstate/core'
import { authFetch } from '~/services/fetch'
import { IDispacthRegistrationWeapon, IWeaponDTO } from '../interfaces/IDispacthRegistrationWeapon'
import { toDomain, toFetch } from '~/mappers/registration-weapon-mapper';

export const weaponDefaultState: IWeaponDTO = {
  occurrenceId: null,
  weaponType: null,
  weaponKind: null,
  weaponBrand: null,
  firearmCaliber: null,
  model: null,
  serialNumber: null,
  notes: null,
  quantity: 0,
  circunstance: null,
  destination: null,
}

const defaultRegistrationWeaponProps: IDispacthRegistrationWeapon = {
  weapons: [],
  weapon: weaponDefaultState,
}

const registrationDispatchWeaponState = createState<IDispacthRegistrationWeapon>(defaultRegistrationWeaponProps);

const useDispatchRegistrationWeaponState = () => {
  const registrationWeaponDispatch = useState(registrationDispatchWeaponState);

  return {
    registrationWeaponDispatch: () => registrationWeaponDispatch,
    getWeaponTypes: async () => {
      const targetUrl = '/weapon-type'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;
    },
    getWeaponKinds: async (id?: number | string) => {
      if (!id) {
        return []
      }
      const targetUrl = `/weapon-type/${id}/kinds`
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;
    },
    getWeaponBrands: async () => {
      const targetUrl = '/weapon-brand'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;
    },
    getWeaponFirearmCalibers: async () => {
      const targetUrl = '/firearm-caliber'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;
    },
    getAllAssociatedWeapon: async (occurrenceId: string | number | null) => {
      const targetUrl = '/occurrence/associated-weapon/all-from-occurrence'
      const response = await authFetch({
        url: targetUrl,
        data: {
          occurrence_id: occurrenceId,
        }
      });

      return response
        .data
        .map((weapon): IWeaponDTO => {
          return toDomain(weapon)
        })
    },
    sendWeapon: async (
      weapon: IWeaponDTO) => {

      const targetUrl = '/occurrence/associated-weapon/store'
      return await authFetch({
        url: targetUrl,
        data: toFetch(weapon)
      });
    },
    editWeapon: async (
      { id, ...weapon }: IWeaponDTO) => {

      const targetUrl = `/occurrence/associated-weapon/${id}`
      await authFetch({
        url: targetUrl,
        method: 'PUT',
        data: toFetch(weapon)
      });
    },
    deleteWeapon: async (id: string) => {
      const targetUrl = `/occurrence/associated-weapon/${id}`
      return await authFetch({
        url: targetUrl,
        method: 'DELETE',
      });
    },
  }
}

export default useDispatchRegistrationWeaponState;