import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';

interface CountStatsCardProps {
  readonly name: string
  readonly color: string
  readonly value: number
}

export default function CountStatsCard(props: CountStatsCardProps){
  return (
    <Grid item xs={2} sm={2} md={2}>
      <Card 
          sx={{
            borderLeft: `5px solid ${props.color}`,
            transition: "0.3s ease-in-out",
            boxShadow: 'none',
            width: '100%',
            '&:hover': {
             borderLeft: `50px solid ${props.color}`,
             transition: "0.3s ease-in-out",
            },
          }}>
        <CardContent>
          <Typography 
            noWrap={true}
          >
            {props.name.toUpperCase()}
          </Typography>
          <Typography 
            variant="h2"
          >
            {props.value ?? 0}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}