import React, { useEffect } from 'react';
import Logo from '~/components/Logo';
import { Grid, Typography, Container, ImageListItem } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useUserState from '~/features/User/stores/UserState';
import { dateTimeFormat } from '~/utils/dateTime';
import { getContributersDispatchService, getCoordenation, getDetailsOccurrences } from '~/features/Dispatch/services/index';
import { State, useHookstate } from '@hookstate/core';
import dayjs from 'dayjs';
import { ICoordenation } from '~/features/Dispatch/pages/Summary';
import { maskCPF } from '~/features/Dispatch/utils/stringsManipulations';

const infoDefault = {
  code: '',
  attendant: {
    id: null,
    name: null,
    registration: null
  },
  coordination: {
    id: null,
    name: null,
    registration: null
  },
  dispatchers: [
    {
      id: null,
      name: null,
      registration: null
    }
  ]
}

interface IHeaderSummaryProps {
  readonly headerSummary: ICoordenation | null;
}

export default function HeaderSummary({ headerSummary }: IHeaderSummaryProps) {
  const { translate } = useLang();
  const { user } = useUserState();
  const generateDocument = new Date();
  const cpf = maskCPF(user().cpf.get() ?? '')

  return (
    <Container fixed={false}>
      <Grid container xs={12}>
        <Grid item xs={3} justifyContent="center" alignContent="center">
          <Grid container xs={12}>
            <Grid item xs={12} maxHeight={'95px'} maxWidth={'95px'}>
              <Logo />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">
            <b>
              {/* {translate('COORDINATION SUMMARY')} */}
              SUMÁRIO DE COORDENAÇÃO
            </b>
          </Typography>

          <br />
          <Typography variant="caption">
            {translate('Coordinator')}
            {': '}
            <b>
              {headerSummary?.coordinator.representation_name ?? `* ${translate('Uninformed')} *`}
            </b>
          </Typography>
          <br />
          <Typography variant="caption">
            {translate('Period')}
            {': '}
            {/* <b>
              13/06/2023 19:00 as 14/06/2023 07:00
            </b> */}
            <b>
              {dayjs(headerSummary?.date_begin).format('DD/MM/YYYY HH:mm')}
              {` as `}
              {headerSummary?.date_end ? dayjs(headerSummary?.date_end).format('DD/MM/YYYY HH:mm') : dayjs(headerSummary?.date_expected_closing).format('DD/MM/YYYY HH:mm')}
            </b>
          </Typography>
          <br />
          <Typography variant="caption">
            {translate('Areas')}
            {': '}
            <b>
              {headerSummary?.dispatch_groups.map(group =>
                `${group.name}${headerSummary?.dispatch_groups.length > 1 ? ', ' : ''}`
              )}
            </b>
          </Typography>
          <br />
        </Grid>
        <Grid item xs={3} textAlign={'right'}>
          <Typography variant="caption" gutterBottom>
            {translate('Document generated in')}
          </Typography>
          <br />
          <Typography variant="caption">
            {dayjs(generateDocument).format('DD/MM/YYYY HH:mm:ss')}
          </Typography>
          <br />
          <Typography variant="caption">
            {translate('User')}
            :
            {' '}
            {user()?.name?.get()}
          </Typography>
          <br />
          <Typography variant="caption">
            {translate('CPF')}
            :
            {' '}
            {cpf}
          </Typography>
          <br />
        </Grid>
      </Grid>
    </Container>
  );
}