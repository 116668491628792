import { State, none, useHookstate } from '@hookstate/core'
import DeleteIcon from '@mui/icons-material/Delete'
import { Avatar, Box, Chip, CircularProgress, IconButton, LinearProgress, Paper, Tooltip } from '@mui/material'
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
} from '@mui/x-data-grid'
import { ptBR } from '@mui/x-data-grid/locales';
import React, { useEffect, useState } from 'react'
import { useLang } from '~/hooks/useLang'
import { authFetch } from '~/services/fetch'
import ConfirmDialog from '../ConfirmDialog'
import useEntryState from '~/features/Entry/stores/EntryState'
import useEntryDispatchState from '~/features/Entry/stores/EntryDispatchState'
import Refresh from '@mui/icons-material/Refresh';
interface DispatchProps {
  readonly id: null | number
  readonly agency_id?: null | number
  readonly area_id?: null | number
  readonly type_id?: null | number
  readonly subtype_id?: null | number
  readonly dispach_group_id?: null | number
  readonly 'area:name'?: string
  readonly 'area:acronym'?: string
  readonly 'agency:name'?: string
  readonly 'agency:acronym'?: string
  readonly 'dispach_group:name'?: string
  readonly 'dispach_group:acronym'?: string
  readonly manual_setted?: boolean
}

type Props = {
  readonly data: State<ReadonlyArray<DispatchProps>>
  readonly loading?: boolean;
  readonly refresh?: boolean;
  readonly refreshAction?: () => void;
}
interface TableProductsProps {
  readonly childToParent: (childData: GridRowSelectionModel) => void
}

export default function DispatchAreaConfigTable({ data, loading = false, refresh = false, refreshAction }: Props) {
  const { translate } = useLang();
  const { loadingsActionsEntry } = useEntryState();
  const { loadingDispatchConfig } = useEntryDispatchState()
  const rowsState = useHookstate(data);
  const openDialog = useHookstate(false);
  const [statusRequest, setStatusRequest] = useState<number>(0)
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([])

  const handleDialogClose = () => {
    openDialog.set(false)
  }

  const handleDialogOpen = () => {
    openDialog.set(true)
  }

  const rows = rowsState.get().map((dispatchArea) => ({
    id: dispatchArea['id'],
    agency: dispatchArea['agency:acronym'],
    dispatchGroup: dispatchArea['dispatch_group:acronym'],
    area: dispatchArea['area:acronym'],
    manual_setted: dispatchArea['manual_setted'],
  }))


  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 25 },
    {
      field: 'agency', headerName: 'Agência', width: 80, editable: true, renderCell: (params) => {
        const findAgency = rowsState.get().find((dispatchArea) => dispatchArea['agency:acronym'] == params.row.agency)
        const agencyName = findAgency ? findAgency['agency:name'] : params.row.agency
        return (
          <Tooltip title={agencyName} placement="bottom">
            <span>
              {params.row.agency}
            </span>
          </Tooltip>
        );
      }
    },
    { field: 'dispatchGroup', headerName: 'Grupo de Despacho', flex: 1 },
    {
      field: 'excluir',
      headerName: '',
      width: 25,
      renderCell: (params) => {
        return (
          <Tooltip
            title={translate('Remove dispatch group')}
            placement="bottom"
          >
            <IconButton
              disabled={params.row.manual_setted === 0}
              onClick={handleDialogOpen}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )
      },
    },
  ]

  const handleRemoveDispatchGroup = () => {
    const getIndex = rows
      .map((row) => row.id)
      .indexOf(Number(rowSelectionModel))

    const url = `/entry-dispatch/${rowSelectionModel}`
    authFetch({
      url: url,
      method: 'DELETE',
    }).then((response) => {
      setStatusRequest(response.status)
    })

    if (statusRequest === 200 || 204) {
      rowsState[getIndex].set(none)
    } else {
    }

    handleDialogClose()
  }

  return (
    <>
      {
        !loading ? !refresh ?
          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            columnVisibilityModel={{
              id: false,
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            loading={loadingDispatchConfig.get()}
            pageSizeOptions={[5]}
            disableColumnSelector={true}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel)
            }}
            sx={{
              boxShadow: 2,
              '& .MuiDataGrid-cell:hover': {
                color: 'primary',
              },
            }}
          />
          :
          <Chip
            sx={{ marginLeft: '0%', marginTop: '50%' }}
            label={translate("Connection failed! Please try again")}
            avatar={<Avatar><Refresh /></Avatar>}
            onClick={() => refreshAction ? refreshAction() : false}
          />
          :
          <Paper
            variant='outlined'
            sx={{
              width: '100%',
              minHeight: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',


            }}>
            <CircularProgress disableShrink />
          </Paper>
      }
      <ConfirmDialog
        handleAgree={handleRemoveDispatchGroup}
        handleClose={handleDialogClose}
        open={openDialog}
        title={translate('Confirmation')}
        content={translate('Confirm delete?')}
      />

    </>
  )
}
