import React from 'react';
import { Grid, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { Controller } from 'react-hook-form';

type CrudSelectFieldProps = {
  readonly register: any;
  readonly name: string;
  readonly control: any;
  readonly options: any;
} 

export default function CrudSelectField(props: CrudSelectFieldProps) {
  const { translate } = useLang();
  const { register, name, control, options } = props; 

  return (
    <Grid item xs={4}>
      <Controller
        { ...register(`${name}`) }
        name={name}
        control={control}
        label={translate(`${name.charAt(0).toUpperCase() + name.slice(1)}`)}
        render={({ field }) => (
        <FormControl fullWidth>
          <InputLabel id="select-crud">
            {translate(`${name.charAt(0).toUpperCase() + name.slice(1)}`)}
          </InputLabel>
          <Select
            {...field}
            id="select-crud"
            fullWidth            
            label={translate(`${name.charAt(0).toUpperCase() + name.slice(1)}`)}
            sx={{ mt: 0 }}
            value={field.value ? field.value : 0}
            placeholder={translate('Select an option')}
          >
          {options.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {typeof option === 'string' ? translate(`${option.charAt(0).toUpperCase() + option.slice(1)}`) : option}
                </MenuItem>
              )
            })}           
          </Select>
        </FormControl>
        )}
      />
    </Grid>
  );  
}
