/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { FormControl, Grid, IconButton } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useDispatchRegistrationWeaponState from '../../../stores/DispatchRegistrationWeaponState';
import useDispatchRegistrationState from '../../../stores/DispatchRegistrationState';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import Buttons from '../../DispatchRegistrationTab/components/Buttons';
import notify from '~/utils/notify';
import FieldAutocompleteRegistration from '~/components/FieldAutocompleteRegistration';
import { useHookstate } from '@hookstate/core';
import { searchWeaponService } from '../../SearchWeapon/services';
import { useSearchWeapon } from '../../SearchWeapon';
import useSystemState from '~/features/System/stores/SystemState';
import SearchIcon from '@mui/icons-material/Search';

interface IFormWeapon {
  readonly handleClickOpenConfirmAction: (action: string) => void
}

interface IErrosProps {
  readonly type: boolean;
  readonly kind: boolean;
  readonly brand: boolean;
  readonly firearmCaliber: boolean;
  readonly plate: boolean;
  readonly serialNumber: boolean;
  readonly circunstance: boolean;
  readonly destination: boolean;
}


export default function WeaponForm({ handleClickOpenConfirmAction }: IFormWeapon) {
  const { dispatch } = useDispatchState();
  const loading = useHookstate<boolean>(false)
  const { occurrence, status } = dispatch();
  const { id: occurrenceIdState, } = occurrence;
  const disableFields = status.value.id === 8
  const { registrationWeaponDispatch, sendWeapon, editWeapon, getWeaponKinds, getWeaponBrands, getWeaponFirearmCalibers, getWeaponTypes } = useDispatchRegistrationWeaponState();
  const { registrationDispatch, getCircunstances, getDestinations } = useDispatchRegistrationState();
  const { addRegistration } = registrationDispatch();
  const serialNumberExternalSearch = useSearchWeapon().serialNumber();
  const resetSearchWeapon = useHookstate(false)

  const { windowManager } = useSystemState();

  const { weapon } = registrationWeaponDispatch();

  const { translate } = useLang();
  const [error, setError] = useState<IErrosProps>({
    type: false,
    kind: false,
    brand: false,
    firearmCaliber: false,
    plate: false,
    serialNumber: false,
    circunstance: false,
    destination: false,
  });

  const someInvalidInput = () => {
    if (!weapon.weaponType.get()) {
      setError({
        ...error,
        type: true
      })
      return true;
    }
    if (!weapon.circunstance.get()) {
      setError({ ...error, circunstance: true })
      return true;
    }
    if (!weapon.destination.get()) {
      setError({ ...error, destination: true })
      return true;
    }
    return false;
  }

  const handleSend = useCallback(async () => {
    loading.set(true)
    await sendWeapon(weapon.get()).finally(() => {
      loading.set(false)
    })
    notify({ message: translate('Successfully created'), type: 'success' })
    addRegistration.set(false)
  }, [addRegistration, sendWeapon, translate, weapon])

  const handleEdit = useCallback(async () => {
    loading.set(true)
    await editWeapon(weapon.get())
    .then(() => {
      notify({ message: translate('Saved successfully'), type: 'success' })
    })
    .finally(() => {
      loading.set(false)
    })
    addRegistration.set(false)
  }, [addRegistration, editWeapon, weapon])

  useEffect(() => {
    if(weapon.serialNumber.get()?.length){
      searchWeaponService({ serialNumber: weapon.serialNumber.get(), page: 0, limit: 10 }).then(({ data }) => {
        if(data.length > 0){
          windowManager().searchWeapon.open.set(true)
          windowManager().searchWeapon.dataSearchWeapon.set(data)
          serialNumberExternalSearch.set(weapon.serialNumber.get() ?? '')
        }else{
          windowManager().searchWeapon.open.set(false)
          windowManager().searchWeapon.dataSearchWeapon.set([])

        }
      })
    }else{
      windowManager().searchWeapon.open.set(false)
      windowManager().searchWeapon.dataSearchWeapon.set([])
    }

  }, [weapon.serialNumber.get(), resetSearchWeapon.get()])

  return (
    <FormControl fullWidth size='small' >
      <Grid container direction='row'>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={weapon.weaponType.get()?.id as string}
            onError={(message) => setError({ ...error, type: message })}
            isRequired
            error={error.type}
            label={translate('Weapon Type')}
            onValueChange={(value) => {
              weapon.set(prev => ({
                ...prev,
                weaponType: {
                  force_one_unit: parseInt(value?.force_one_unit ?? ''),
                  id: parseInt(value?.id ?? ''),
                  name: value?.name ?? ''
                },
                weaponKind: null
              }))

              if (value?.force_one_unit) {
                weapon.quantity.set(1)
              }
            }}
            optionsFetch={getWeaponTypes}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            depends={weapon.weaponType.get()?.id as string}
            valueId={weapon.weaponKind.get()?.id as string}
            label={translate('Weapon Kind')}
            onValueChange={(kind) => {
              weapon.weaponKind.set({
                id: kind?.id ?? '',
                name: kind?.name ?? '',
                weapon_type_id: parseInt(kind?.weapon_type_id ?? '')
              })

            }}
            optionsFetch={getWeaponKinds}
            disabled={disableFields || !weapon.weaponType.get()}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={weapon.weaponBrand.get()?.id as string}
            label={translate('Weapon Brand')}
            onValueChange={(brand) => {
              weapon.weaponBrand.set({
                id: brand?.id ?? '',
                name: brand?.name ?? '',
              })
            }}
            optionsFetch={getWeaponBrands}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={weapon.firearmCaliber.get()?.id as string}
            label={translate('Firearm Caliber')}
            onValueChange={(firearmCaliber) => {
              weapon.firearmCaliber.set({
                id: firearmCaliber?.id ?? '',
                name: firearmCaliber?.name ?? '',
              })
            }}
            optionsFetch={getWeaponFirearmCalibers}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={5} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-model"
            label={translate('Model')}
            value={weapon.model.get()}
            InputProps={{
              onChange: (event) =>
                weapon.model.set(event.target.value),
            }}
          />
        </Grid>
        <Grid item xs={5} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-serial-number"
            label={translate('Serial Number')}
            value={weapon.serialNumber.get()}
            InputProps={{
              onChange: (event) =>
                weapon.serialNumber.set(event.target.value),
              endAdornment: (
                <IconButton disabled={disableFields} onClick={() => {
                  resetSearchWeapon.set(!resetSearchWeapon.get())
                }}>
                  <SearchIcon />
                </IconButton>
              )
            }}
            onKeyDown={(event) => {
              if (event.code === "NumpadEnter" || event.code === "Enter") {
                resetSearchWeapon.set(!resetSearchWeapon.get())
              }
            }}
          />
        </Grid>
        <Grid item xs={2} sx={{ padding: 1 }}>
          <TextField
            fullWidth
            size='small'
            id="outlined-quantity"
            label={translate('Quantity')}
            value={weapon.quantity.get()}
            type="number"
            disabled={weapon.get().weaponType?.force_one_unit === 1 || disableFields}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { min: 0 },
              onChange: (event) =>
                weapon.quantity.set(parseInt(event.target.value)),
            }}
          />
        </Grid>

        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            onError={(message) => setError({ ...error, circunstance: message })}
            isRequired
            error={error.circunstance}
            valueId={weapon.circunstance.get()?.id as string}
            label={translate('Circunstance')}
            onValueChange={(circunstance) => {
              weapon.circunstance.set({
                id: circunstance?.id ?? '',
                name: circunstance?.name ?? '',
              })
            }}
            optionsFetch={getCircunstances}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            onError={(message) => setError({ ...error, destination: message })}
            isRequired
            error={error.destination}
            valueId={weapon.destination.get()?.id as string}
            label={translate('Destinations')}
            onValueChange={(destination) => {
              weapon.destination.set({
                id: destination?.id ?? '',
                name: destination?.name ?? '',
              })
            }}
            optionsFetch={getDestinations}
            disabled={disableFields}
          />

        </Grid>
        <Grid item xs={6} sx={{ padding: 1, marginBottom: 1 }}>
          <TextField
            disabled={disableFields}
            id="outlined-multiline-notes"
            label={translate('Note')}
            multiline
            fullWidth
            rows={4}
            size='small'
            value={weapon.notes.get()}
            InputProps={{
              onChange: (event) => {
                const notes = event.target.value
                weapon.notes.set(notes)
              },
            }}
            sx={{ height: 92 }}
          />
        </Grid>

        <Grid item xs={12}>
          <Buttons
            loading={loading}
            isUpdate={!!weapon.id.get()}
            handleClickOpenConfirmAction={handleClickOpenConfirmAction}
            handleEdit={handleEdit}
            handleSend={handleSend}
            someInvalidInput={someInvalidInput}
            disableFields={disableFields}
          />
        </Grid>
      </Grid>
    </FormControl >
  );
}