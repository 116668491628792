import React, { useEffect } from 'react';
import ReactDOM from 'react-dom'
import { InsertDriveFile } from '@mui/icons-material';
import StandardModal from '~/components/StandardModal';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';
import SearchInvolvement from '../SearchInvolvement';
import { Downgraded, useHookstate } from '@hookstate/core';
import { useLocation } from 'react-router';
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable';
import { authFetch } from '~/services/fetch';
import { storeScreenSettings } from '~/features/System/services';

const modal = document.getElementById('div-modal') as HTMLElement;

export default function SearchInvolvementModalDraggable() {
  const { translate } = useLang();
  const { windowManager, loadingButton } = useSystemState();
  
  const handleStop = (event, dragElement) => {
    event.stopPropagation();

    windowManager()['searchInvolvement'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y
    })
  };

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'SearchInvolvementModalDraggable',
        isOpen: windowManager()['searchInvolvement'].open.get(),
        isMinimized: windowManager()['searchInvolvement'].minimized.get(),
        positionX: windowManager()['searchInvolvement'].positionX.get(),
        positionY: windowManager()['searchInvolvement'].positionY.get()
      })
    }
  }, [
    windowManager()['searchInvolvement'].open.get(),
    windowManager()['searchInvolvement'].minimized.get(),
    windowManager()['searchInvolvement'].positionY.get(),
    windowManager()['searchInvolvement'].positionX.get()
  ])

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!loadingButton.get() &&
        <StandardModal
          title={`${translate('Search Involvement')}`}
          avatar={InsertDriveFile}
          dimensions={{
            width: 500
          }}
          isExpanded={windowManager()['searchInvolvement'].minimized}
          handleStop={handleStop}
          position={{
            x: windowManager()['searchInvolvement'].positionX?.get() ? Number(windowManager()['searchInvolvement'].positionX?.get()) : 250,
            y: windowManager()['searchInvolvement'].positionY?.get() ? Number(windowManager()['searchInvolvement'].positionY?.get()) : 100
          }}
          // initialPosition={initialPosition}
          closeButton={!(windowManager()['searchInvolvement'].disabled.value) ? () =>{ 
            windowManager()['searchInvolvement'].open.set(false)
            windowManager()['searchInvolvement'].dataSearchInvolvement.set([])

          }: false}
        >
          <SearchInvolvement data={windowManager()['searchInvolvement'].dataSearchInvolvement.get()}/>
        </StandardModal>
      }
    </>
    , modal);
}
