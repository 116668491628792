const DEFAULT_STORAGE_KEY = `@Despacho`;

export default {
    keys: {
        theme: `${DEFAULT_STORAGE_KEY}:theme`,
        token: `${DEFAULT_STORAGE_KEY}:token`,
        user: `${DEFAULT_STORAGE_KEY}:user`,
        station: `${DEFAULT_STORAGE_KEY}:station`,
        flashes: `${DEFAULT_STORAGE_KEY}:flashes`,
        cookies: {
            identificationKey: `${DEFAULT_STORAGE_KEY}:ident-key`,
            anonymousIdentificationKey: `${DEFAULT_STORAGE_KEY}:anonymous-ident-key`
        },
        commentIsCompressed: `${DEFAULT_STORAGE_KEY}:commentIsCompressed`,
    }
}
