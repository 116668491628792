import React, { useEffect, useRef } from 'react';
import { CircularProgress, Container, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableRow, Typography } from '@mui/material';
import { PrintRounded } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import HeaderReport from '../components/HeaderReport';
import PagesReport from '../components/PagesReport';
import useReportState, { reportDefault } from '../stores/ReportState';
import { useLang } from '~/hooks/useLang';
import dayjs from 'dayjs';
import { Downgraded, useHookstate } from '@hookstate/core';
import EmptyReport from '../components/EmptyReport';
import IReport from '../interfaces/IReport';

export default function ReportResult() {
  const { reportData, loadingReport } = useReportState();
  const overviewReport = useHookstate<IReport>(reportDefault)
  const { translate } = useLang();
  const componentRef = useRef(null);
  const report = useHookstate(reportData());

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])

  useEffect(() => {
    setTimeout(() => {
      overviewReport.set(JSON.parse(JSON.stringify(report.attach(Downgraded).value)))
    })

  }, [loadingReport().get()])

  

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${translate(overviewReport.header.title.value)} ${dayjs(new Date()).locale('pt-BR').format('DD-MM-YYYYTHH:mm:ssZ[Z]')}`
  });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        overviewReport.get()?.groups?.length > 0 && overviewReport.get()?.data?.length > 0 ?
          <Container>
            <Grid container xs={12} >
              <Grid
                item
                xs={12}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                height={'calc(100vh - 80px)'}
              >
                <CircularProgress
                  disableShrink={true}
                  size={40}
                />
              </Grid>
            </Grid>
          </Container>
          :
          <>
            <IconButton color="primary" aria-label="Imprimir" onClick={() => handlePrint()}>
              <PrintRounded />
              <Typography marginLeft={1} variant='body1'>
                {translate('Print')}
              </Typography>
            </IconButton>

            <div ref={componentRef} className="print-container" style={{ margin: "0", padding: "0" }}>
              <div style={{ height: '95%' }}>
                <Grid
                  container
                  columns={{ xs: 24 }}
                  alignItems={'center'}
                  justifyContent={'center'}
                  flexDirection={'column'}
                  padding={2}
                >
                  <Grid item width={{width: '100%'}}>
                  <HeaderReport header={overviewReport.header.value} />

                  </Grid>
                  <TableContainer>
                    <Table className="print-component" >
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ border: "none" }}>
                            {overviewReport.get()?.groups?.length > 0 || overviewReport.get()?.data?.length > 0 ?
                              <PagesReport
                                className={overviewReport.header.className.value}
                                report={overviewReport}
                              />
                              : <EmptyReport justify='center' />
                            }
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </div>
            </div>
          </>
      }
    </>
  )
}
