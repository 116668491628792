import React from 'react';
import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';
import { useLang } from '~/hooks/useLang';
import { isValidPhone } from '~/features/Dispatch/utils/stringsManipulations';
import { State } from '@hookstate/core';
import { IErrorConectDispatchVehicle, IUserCrew } from '../..';


interface IPhoneField {
  readonly stateName: string;
  readonly memberState: State<IUserCrew> | null;
  readonly error: State<IErrorConectDispatchVehicle>;
  readonly required: boolean;
}

export default function PhoneField({ stateName, memberState, error, required }: IPhoneField) {
  const { translate } = useLang();

  return (
    <InputMask mask="(99) 99999-9999"
      value={memberState?.phone.get() ?? ''}
      onChange={(event) => {
        error.returnError.set('')
        error[stateName].phone.set(null)
        const phone = event.target.value
        memberState?.phone.set(phone)

        if (isValidPhone(phone)) {
          if (required) {
            error[stateName].phone.set(translate('Inform a valid phone'))
          } else {
            error[stateName].phone.set(null)
          }
        }
        else {
          error[stateName].phone.set(null)
        }
      }}

    >
      <TextField fullWidth
        size='small'
        label={translate('Phone')}
        name="phone"
        required={required}
        error={ required ? !!error[stateName].phone.get() : false}
      />
    </InputMask>
  )
}