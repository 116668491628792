import React, { ChangeEvent, useEffect } from 'react'
import { useLang } from '../../../../../../hooks/useLang'
import {
  TextField,
  Grid,
  Button,
  Box,
  FormControlLabel,
  Switch,
} from '@mui/material'
import { toDomain } from '~/mappers/surveillance-reporting-mapper'
import useSurveillanceReportingState from '../../../../stores/SurveillanceReportingState'
import { none, useHookstate } from '@hookstate/core'
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod'
import useUserState from '~/features/User/stores/UserState'
import { LoadingButton } from '@mui/lab'

interface filtersProps {
  readonly content: Array<any>
  readonly fields: {
    readonly camera_name: string | null
    readonly occurrence_id: string | null
    readonly reported_at: Array<string> | null
    readonly notes: string | null
    readonly user_id: number | null
  }
}

const filtersDefault: filtersProps = {
  content: [],
  fields: {
    camera_name: null,
    occurrence_id: null,
    reported_at: [],
    notes: null,
    user_id: null,
  },
}

// eslint-disable-next-line max-lines-per-function
export default function NewSurveillanceReporting() {
  const { translate } = useLang()
  const { user: userSession } = useUserState()
  const { surveillanceReportingStates, listSurveillanceReporting } =
    useSurveillanceReportingState()
  const { reports, switchCheck, loading } = surveillanceReportingStates()
  const startDate = useHookstate<string | null>(null)
  const finishDate = useHookstate<string | null>(null)
  const period = useHookstate<string>('')
  const filters = useHookstate(filtersDefault)

  const handleClearInputs = () => {
    filters.content.set([])
    filters.fields.camera_name.set(null)
    filters.fields.occurrence_id.set(null)
    filters.fields.reported_at.set(null)
    filters.fields.notes.set(null)
    filters.fields.user_id.set(null)
    switchCheck.set(true)

    period.set('')
    startDate.set(null)
    finishDate.set(null)
    nextData()
  }

  const actionFilter = () => {
    filters.content.set([])
    reports.set([])
    Object.keys(filters.fields.value).map((field) => {
      if (
        filters.fields.value[field] != null &&
        filters.fields.value[field] != ''
      ) {
        filters.content.merge([
          {
            field: field === 'camera_name' ? field.replace('_', ':') : field,
            operator:
              field === 'occurrence_id' || field === 'user_id'
                ? 'equals'
                : field === 'reported_at'
                  ? 'between'
                  : field === 'notes'
                    ? 'contains'
                    : field === 'camera_name'
                      ? 'startsWith'
                      : '',
            value: filters.fields.get()[field] ?? null,
          },
        ])
      }
    })
    nextData(true)
  }

  const nextData = (reset = false) => {
    loading.set(true)
    const pageQuery = reset ? 0 : Math.floor(reports.get().length / 10)
    listSurveillanceReporting({
      page: pageQuery,
      limit: 10,
      filters: filters.content.get(),
    }).then(({ data }) => {
      if (reset) {
        const toDomainsReport = data?.map((report) => toDomain(report))
        reports.set(toDomainsReport)
      } else {
        const toDomainsReport = data?.map((report) => toDomain(report))
        reports.merge(toDomainsReport)
      }
      loading.set(false)
    })
  }

  useEffect(() => {
    filters.fields.user_id.set(
      switchCheck.get() ? (userSession().id.get() as number) : null
    )
  }, [])

  return (
    <Grid container columns={12} >
      <Grid
        container
        xs={12}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid container direction={'row'} sx={{ paddingRight: 1 }}>
          <Grid item xs={6} sx={{ paddingLeft: 1 }}>
            <TextField
              size="small"
              fullWidth
              placeholder={translate('Occurrence')}
              value={filters.fields.occurrence_id.get() ?? ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                filters.fields.occurrence_id.set(event.target.value)
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} sx={{ paddingLeft: 1 }}>
            <TextField
              size="small"
              fullWidth
              placeholder={translate('Camera')}
              value={filters.fields.camera_name.get() ?? ''}
              onChange={(event) => {
                filters.fields.camera_name.set(event.target.value)
              }}
              variant="outlined"
            />
          </Grid>
          <FilterSelectPeriod
            onStartDateChange={(value) => {
              startDate.set(value?.toJSON() ?? null)
              if (startDate.get() != null) {
                filters.fields.reported_at[0]?.set(startDate.get())
              }
            }}
            onFinishDateChange={(value) => {
              finishDate.set(value?.toJSON() ?? null)
              if (finishDate.get() != null) {
                filters.fields.reported_at[1]?.set(finishDate.get())
              }
            }}
            onPeriodChange={(value) => {
              period.set(value)
            }}
            period={period.get()}
            xsPeriod={4}
            xsDateBetween={4}
          />

          <Grid item xs={12} pl={1} pt={1}>
            <TextField
              multiline
              rows={2}
              sx={{ width: '100%' }}
              placeholder={translate('Write something')}
              value={filters.fields.notes.get() ?? ''}
              onChange={(event) => filters.fields.notes.set(event.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'flex-end',
          paddingRight: 1,
          paddingTop: 1
        }}
      >
        <Button
          variant="outlined"
          sx={{ mr: 1 }}
          onClick={handleClearInputs}
        >
          {translate('Clear Filters')}
        </Button>
        <LoadingButton
          loading={loading.get()}
          variant="contained"
          onClick={actionFilter}
        >
          {translate('Apply Filters')}
        </LoadingButton>
      </Box>
    </Grid>
  )
}
