import React from 'react'
import {
  Button,
  Collapse,
  Grid,
  Card,
  List,
  ListItemButton,
  ListItemText,
  Box,
  Typography,
  Tooltip,
} from '@mui/material'
import IconAdministration from '~/components/IconsSvg/administration'
import { useLang } from '~/hooks/useLang'
import { ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material'

import { useHistory } from 'react-router-dom'
import useUserState from '~/features/User/stores/UserState'
import { MenuProps } from '~/components/SidebarMenu/interfaces'

export default function InvolvementItem({ handleMenuClick }: MenuProps) {
  const { translate } = useLang()
  const { verifyPermission } = useUserState()
  const [openInvolvementItem, setOpenInvolvementItem] = React.useState(false)

  const history = useHistory()

  const handleClickInvolvementItem = () => {
    setOpenInvolvementItem(!openInvolvementItem)
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {verifyPermission([
        'ethnicity_index',
        'education_index',
        'document_type_index',
        'involvement_type_index',
        'people_circunstance_index',
      ]) && (
        <>
          <Tooltip title={translate('Involvement options')} placement="right">
            <ListItemButton sx={{ pl: 4 }} onClick={handleClickInvolvementItem}>
              <ListItemText primary={translate('Involvement')} />
              {openInvolvementItem ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </Tooltip>
          <Collapse in={openInvolvementItem} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {verifyPermission(['education_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Education')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 8 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/education')
                    }}
                  >
                    <ListItemText primary={translate('Education')} />
                  </ListItemButton>
                </Tooltip>
              )}
              {verifyPermission(['ethnicity_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Ethnicity')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 8 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/ethnicity')
                    }}
                  >
                    <ListItemText primary={translate('Ethnicity')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['gender_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Gender')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 8 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/gender')
                    }}
                  >
                    <ListItemText primary={translate('Gender')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['people_circunstance_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('People Circunstance')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 8 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/people-circunstance')
                    }}
                  >
                    <ListItemText primary={translate('People Circunstance')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['document_type_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Document Type')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 8 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/document-type')
                    }}
                  >
                    <ListItemText primary={translate('Document Type')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['involvement_type_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Involvement Type')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 8 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/involvement-type')
                    }}
                  >
                    <ListItemText primary={translate('Involvement Type')} />
                  </ListItemButton>
                </Tooltip>
              )}
            </List>
          </Collapse>
        </>
      )}
    </>
  )
}
