import React from 'react';

export default function IconEntry() {
  return (
    <svg version="1.1" id="atendimento-icon" width="35px" height="35px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 480.542 480.542">
      <path d="M89.104,219.999h26.626c17.666,51.873,66.863,89.289,124.637,89.289c41.424,0,78.433-19.241,102.577-49.248h34.228
        c6.352,0,11.5-5.148,11.5-11.5v-28.541h5.184c12.474,0,22.621-10.148,22.621-22.622V139.54c0-11.022-7.926-20.224-18.376-22.217
        c-7.547-34.184-26.132-63.235-54.028-84.307C315.476,11.417,279.615,0,240.366,0C160.701,0,97.864,47.184,82.511,117.9
        c-9.268,2.83-16.029,11.459-16.029,21.64v57.837C66.482,209.851,76.63,219.999,89.104,219.999z M89.482,196.999v-57.081h24.789
        c-3.588,11.963-5.525,24.633-5.525,37.748c0,6.567,0.49,13.021,1.423,19.333H89.482z M240.366,286.288
        c-59.894,0-108.621-48.728-108.621-108.622c0-59.894,48.727-108.62,108.621-108.62c59.895,0,108.622,48.727,108.622,108.62
        c0,21.898-6.52,42.297-17.709,59.374h-97.586c-6.351,0-11.5,5.149-11.5,11.5c0,6.352,5.149,11.5,11.5,11.5h77.398
        C292.073,276.391,267.355,286.288,240.366,286.288z M365.671,237.04h-7.857c2.777-5.474,5.188-11.163,7.189-17.041h0.667V237.04z
        M393.476,196.999h-22.91c0.933-6.311,1.423-12.766,1.423-19.333c0-13.115-1.938-25.785-5.525-37.748h27.013V196.999z M240.366,23
        c66.29,0,118.756,37.303,133.954,93.918h-17.217c-21.988-42.08-66.06-70.872-116.737-70.872
        c-50.676,0-94.748,28.792-116.736,70.872h-17.22C121.593,60.338,174.166,23,240.366,23z"/>
      <path d="M358.477,314.352H122.065c-36.506,0-66.206,29.699-66.206,66.205v88.484c0,6.352,5.149,11.5,11.5,11.5
        s11.5-5.148,11.5-11.5v-88.484c0-23.823,19.382-43.205,43.206-43.205h236.412c23.824,0,43.207,19.382,43.207,43.206v88.484
        c0,6.352,5.148,11.5,11.5,11.5c6.352,0,11.5-5.148,11.5-11.5v-88.484C424.684,344.052,394.984,314.352,358.477,314.352z"/>
    </svg>
  );
}
