/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import { Box, Button, Card, Typography, Tooltip } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { MenuProps } from '~/components/SidebarMenu/interfaces';
import useUserState from '~/features/User/stores/UserState';
import ReportOccurrences from './ReportsMenuItens/ReportOccurrences';
import ReportDevices from './ReportsMenuItens/ReportDevices';
import ReportByEntry from './ReportsMenuItens/ReportEntry';
import ReportByApproach from './ReportsMenuItens/ReportApproach';
import ReportByInterestPoint from './ReportsMenuItens/ReportInterestPoint';

export default function ReportMenu({ activeMenu, handleMenuClick }: MenuProps) {
  const { translate } = useLang();
  const { verifyPermission } = useUserState();


  return (
    <>
      {(verifyPermission(['reports_generator'])
      ) && (
          <Box>
            <Tooltip title={translate('Reports')} placement="bottom">
              <Button className={`main-menu ${activeMenu == 'reports' ? 'active' : ''}`} onClick={() => handleMenuClick('reports')}>
                <ArticleIcon />
              </Button>
            </Tooltip>
            <Card className='submenu-box' sx={{ borderRadius: 0, display: activeMenu == 'reports' ? 'block' : 'none' }}>
              <Typography variant='h4'>
                <ArticleIcon />
                {translate('Reports')}
              </Typography>
              <ReportOccurrences handleMenuClick={handleMenuClick} />
              <ReportDevices handleMenuClick={handleMenuClick} />
              <ReportByEntry handleMenuClick={handleMenuClick} />
              <ReportByInterestPoint handleMenuClick={handleMenuClick} />
              {/* <ReportByApproach handleMenuClick={handleMenuClick} /> */}
            </Card>
          </Box>
        )}

    </>
  )
}
