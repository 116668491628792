import React, { useEffect } from 'react';
import { useLang } from '~/hooks/useLang';
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState'
import useOccurrenceComentState from '~/features/Occurrence/stores/OccurrenceCommentState';
import { SendCommentField } from '~/components/SendCommentField';
import { DisplayComments } from '~/components/DisplayComments';
import { Grid } from '@mui/material';
import { useHookstate, Downgraded } from '@hookstate/core';
import { CommentDataProps } from '~/features/Dispatch/stores/DispatchCommentState';
import OccurrenceListDispatches from '~/features/Occurrence/components/OccurrenceListDispatches';
import EventInputContainerOccurrence from '~/features/Occurrence/components/EventInputContainerOccurrence';
import { Validation as validation } from '@hookstate/validation';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '~/components/ErrorFallback';
import ButtonActionsOccurrenceContainer from '~/features/Occurrence/components/ButtonActionsOccurrenceContainer';
import EventInputRequesterOccurrence from '~/features/Occurrence/components/EventInputRequesterOccurrence';
import useSystemState from '~/features/System/stores/SystemState';
import { LatLng } from 'leaflet';
import { useMarkerState } from '~/features/Map/stores/MapState';

export default function OccurrenceForm() {
  const { translate } = useLang();
  const commentIsCompressed = useHookstate(true);
  const { newComment, allCurrentComments, sendComment, syncPreviousComments, loading, retryConnect } = useOccurrenceComentState();
  const { occurrence } = useOccurrenceState();
  const { systemConfig } = useSystemState();
  const { createUniqueMarker, deleteAllUniqueMarker, forceViewMap } = useMarkerState();
  const moveScrolltoComment = useHookstate(false);

  const commentList = useHookstate<readonly CommentDataProps[]>([]);

  const allCurrentCommentsValue = allCurrentComments().attach(Downgraded).value;
  const isValid = validation(occurrence().id).valid()

  React.useEffect(() => {
    commentList.set(allCurrentCommentsValue ? allCurrentCommentsValue.map(element => element).sort(sortComment) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCurrentCommentsValue]);



  React.useEffect(() => {
    syncPreviousComments(occurrence().id.get())
    if (isValid) {
      const params = {
        id: occurrence().id.get(),
        isCurrent: true,
        position: new LatLng(occurrence().latitude.get() ?? 0, occurrence().longitude.get() ?? 0),
      }
      createUniqueMarker({ uniqueReference: true, markerType: 'occurrence', markerIdentifier: params.id, isCurrent: true, ...params as any });
      forceViewMap().position.set(params.position);
    }

    return () => deleteAllUniqueMarker('occurrence')
  }, [occurrence().id.get()])



  function sortComment(commentA: CommentDataProps, commentB: CommentDataProps) {
    const dateA = new Date(commentA.createdAt).getTime()
    const dateB = new Date(commentB.createdAt).getTime()
    return dateB - dateA
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Grid container columns={12} sx={{ margin: 0, alignItems: 'center', justifyContent: 'flex-start' }}>
        <Grid container sx={{ margin: 0 }}>
          <Grid item md={4}>
            <EventInputContainerOccurrence
              isValid={isValid}
              isValidEdit={false}
              canEdit={occurrence().closed_at.get() === null && (systemConfig().allowedEditTypeSubtypeOfOccurrence.get() === '1' || systemConfig().allowedEditAddressOfOccurrence.get() === '1')}
              isInvalidRequest={false}
              id={occurrence().id}
              lat={occurrence().latitude}
              lon={occurrence().longitude}
              typeId={occurrence().type.id}
              typeName={occurrence().type.name}
              typeCode={occurrence().type.code}
              subtypeId={occurrence().subtype.id}
              subTypeName={occurrence().subtype.name}
              subtypeCode={occurrence().subtype.code}
              cityId={occurrence()?.city?.id}
              districtId={occurrence()?.district?.id}
              type={'dispatch'}
              location={occurrence()?.location}
              reference={occurrence()?.address_reference}
              totalSubtypes={occurrence()?.totalSubtypes}
              cityName={occurrence()?.city.name}
              districtName={occurrence()?.district.name}
              totalDistricts={occurrence()?.totalDistricts}
            />
            <EventInputRequesterOccurrence canEdit={occurrence().closed_at.get() === null && systemConfig().allowedEditRequesterDataOfOccurrence.get() === '1'} />

          </Grid>

          <Grid item md={4} sx={{ paddingLeft: 1 }}>
            <Grid sx={{ width: '100%', margin: 0 }} direction={'column'}>
              <Grid item mb={1} md={1} xs={1}>
                <SendCommentField
                  disabled={Boolean(occurrence()?.closed_at.get()) && !(systemConfig().allowedCommentOnClosedOccurrence.get() === '1')}
                  id={occurrence().id.get()}
                  name="comment"
                  label={translate('Comment')}
                  required={true}
                  handleClickSend={() => console.log('Enviando')}
                  newComment={newComment}
                  sendComment={sendComment}
                  uploadImageOption={false}
                  moveScrolltoComment={moveScrolltoComment}
                />
              </Grid>
              <Grid item md={12} height={360} sx={{ overflowY: 'auto', width: '100%' }}>
                <DisplayComments
                  comments={commentList}
                  commentIsCompressed={commentIsCompressed}
                  isEdit={true}
                  isOccurrence={true}
                  moveScrolltoComment={moveScrolltoComment.get()}
                  loading={loading().get()}
                  refresh={retryConnect().get()}
                  refreshAction={() => occurrence().id.get() ? syncPreviousComments(occurrence().id.get()) : false}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={4} sx={{ paddingLeft: 1 }}>
            <OccurrenceListDispatches occurrenceId={occurrence().id.get()} />
          </Grid>

          <Grid item columnSpacing={1} md={12} sx={{ mt: '10px' }}>
            <ButtonActionsOccurrenceContainer
              occurrenceId={occurrence().id.get()}
            />
          </Grid>
        </Grid>

      </Grid>
    </ErrorBoundary>
  )
}