import React from 'react';
import { createState, useState } from '@hookstate/core';
import { authFetch } from '~/services/fetch';

interface FileProps {
  readonly isComment: boolean,
}

interface SendFileProps {
  readonly dispatchId: number | null,
  readonly type: string,
  readonly image: string | null,
  readonly name: string | null,
}

const defaultFileProps: FileProps = {
  isComment: false,
}

const store = createState<FileProps>(Object.create(defaultFileProps));

const useEntryFileState = () => {
  const entryFileState = useState(store);

  const isComment = useState(entryFileState.isComment)

  return ({
    isComment: () => isComment,
    sendFile: async ({ dispatchId, image, type, name }: SendFileProps) => {
      const response = await authFetch({
        url: '/dispatch/send-media',
        method: 'POST',
        data: {
          dispatch_id: dispatchId,
          type,
          file: image,
          file_name: name
        }
      }).catch(err => {
        console.error(err);
      })

      return response;
    },
  })
}

export default useEntryFileState;