import React, { useState } from 'react';
import { Grid, Checkbox, FormGroup, Typography, FormControlLabel } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { Controller } from 'react-hook-form';

export default function CrudCheckBoxField({ register, model, getValues, defaultChecked, control, placeholder }) {
  const { translate } = useLang()
  const [isCheck, setIsCheck] = useState(Boolean(defaultChecked) ? Boolean(defaultChecked) : false);

  return (
    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', justifyContent: 'start' }}>
      <Controller
        {...register(`${model}`)}
        name={model}
        control={control}
        defaultValue={isCheck}
        render={(props) => (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  {...props.field}
                  checked={props.field.value}
                  onChange={(event, value) => {
                    setIsCheck(Boolean(value));
                    return props.field.onChange(Boolean(value));
                  }}
                />}
              label={translate(`${placeholder ? placeholder.charAt(0).toUpperCase() + placeholder.slice(1) : model.charAt(0).toUpperCase() + model.slice(1)}`)}
            />
          </FormGroup>
        )}
      />
    </Grid>
  )
}