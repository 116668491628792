import React, { useEffect } from 'react';
import { DataGrid, GridActionsCellItem, GridRowModel, GridValueFormatter, GridColDef } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import { Grid, Tooltip } from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import { useLang } from '../../../../hooks/useLang';
import useEntryState from '../../stores/EntryState';
import { authFetch } from '~/services/fetch';
import dayjs from 'dayjs';
import { useHookstate } from '@hookstate/core';
import OccurrenceRecallModal, { dataOccurrenceDefault } from '~/features/Occurrence/components/OccurrenceRecallModal';

type OccurrenceRecallTable = {
  readonly data: GridRowModel,
}

interface dataOccurrenceProps {
  readonly type_id: null | string | number;
  readonly subtype_id: null | string | number;
  readonly city_id: null | string | number;
  readonly latitude: number | null;
  readonly longitude: number | null;
  readonly location: string | null;
}

// eslint-disable-next-line max-lines-per-function
export default function OccurrenceRecallTable() {
  const [rows, setRows] = React.useState<readonly GridRowModel[]>([]);
  const open = useHookstate(false);
  const openRequester = useHookstate(false)
  const [occurrenceId, setOccurrenceId] = React.useState<number>(0);
  const dataOccurrence = useHookstate<dataOccurrenceProps>(dataOccurrenceDefault)
  const { translate } = useLang();
  const entryState = useEntryState()
  const { id } = useEntryState().entry();

  const recall = React.useCallback((row: any) => {
    setOccurrenceId(row.id);

    dataOccurrence.set(row)
    open.set(true)
  }, []);

  const columns = React.useMemo(
    () => [
      { field: 'id' },
      { field: 'location', headerName: translate('Location'), flex: 1.5 },
      {
        field: 'entry_at', headerName: 'Data', type: 'date', flex: 1,
        valueFormatter: (value) => {
          return dayjs(value as string).isValid() ? dayjs(value as string).format('DD/MM/YYYY HH:mm:ss') : '-';
        }
      },
      {
        field: 'type', headerName: translate('Type'), flex: 1.5,
        valueFormatter: (value) => {
          if (!value) return '-';

          return value.name
        },
      },
      {
        field: 'subtype', headerName: translate('Subtype'), flex: 1.4,
        valueFormatter: (value) => {
          if (!value) return '-';

          return value.name
        },
      },
      {
        field: 'actions', type: 'actions',
        flex: 0.3,
        getActions: ({id, row}) => [
          <Tooltip title={translate('Bind')} placement="bottom" key={id}>
            <GridActionsCellItem
              key={id}
              icon={<CopyAll />}
              label='export'
              onClick={() => recall(row)}
            />
          </Tooltip>,
        ]
      },
    ],
    [recall, translate],
  );

  useEffect(() => {
    authFetch({
      url: `/occurrence/get-in-radius`,
      method: 'POST',
      data: {
        latitude: entryState.entry().latitude.get(),
        longitude: entryState.entry().longitude.get(),
        orders: [
          { field: 'created_at', sort: 'desc' }
        ],
        limit: 10000
      }
    })
      .then(({ data: { data, rowsCount } }) => {
        setRows(data);
      })
      .catch(err => err);
  }, []);


  return (
    <Grid item md>
      <DataGrid
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns as GridColDef[]}
        columnVisibilityModel={{
          id: false,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        disableColumnSelector={true}
        sx={{
          height: 371,
          boxShadow: 2,
          '& .MuiDataGrid-cell:hover': {
            color: 'primary',
          },
        }}
      />
      {open.get() ?
        <OccurrenceRecallModal
          open={open}
          openRequester={openRequester}
          occurrenceId={occurrenceId}
          redirectOccurrence={true}
          dataOccurrence={dataOccurrence}

        /> :
        false
      }
    </Grid>
  );
}
