import React from "react";
import { Grid } from '@mui/material';
import AddVehicleButtonPopup from "~/components/AddVehicleButtonPopup";
import SyncDispatchDeviceButton from "~/components/SyncDispatchDeviceButton";
import ChronologyDeviceLastDispatchButton from '~/components/ChronologyDeviceLastDispatchButton';

export default function ButtonActionsPopupDeviceContainer(props) {
  return (
    <Grid justifyContent={'space-between'} container spacing={1}>
      <AddVehicleButtonPopup {...props} />
      <SyncDispatchDeviceButton {...props} />
      <ChronologyDeviceLastDispatchButton {...props} />
    </Grid>
  )
}