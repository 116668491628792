import React from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import {
  Button, TextField,
  Paper, Box, Grid, Container, Alert, CircularProgress, ImageListItem, Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel
} from '@mui/material';
import Logo from '../../../components/Logo';
import Copyright from '../../../components/Copyright';
import { useAuth } from '../hooks/useAuth';
import { Theme, configTheme } from '../../../components/Theme';
import bgHome from '../assets/images/bg-home-nova.jpg';
import { useLang } from '../../../hooks/useLang';
import { clearWebAnonymousStation } from '~/features/Station/services';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import constants from '~/core/constants';
import Cookies from 'universal-cookie';
import useCookie from '~/hooks/useCookie';
import { useHookstate } from '@hookstate/core';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const cookies = new Cookies();
interface ILoginForm {
  readonly login: string;
  readonly password: string;
  readonly remember?: boolean;
}

export default function Login({ noStation = false }) {
  const { translate } = useLang()
  const { control, handleSubmit } = useForm<ILoginForm>({ shouldUseNativeValidation: false });
  const { signIn, error: loginError, loading } = useAuth();
  const showPassword = useHookstate(false);

  const [anonymousKey] = useCookie(constants.keys.cookies.anonymousIdentificationKey);

  const history = useHistory();
  const location = useLocation();

  const onSubmit: SubmitHandler<ILoginForm> = async ({ login, password }) => {
    return signIn(login, password);
  }

  const handleClickShowPassword = () => {
    showPassword.set(!showPassword.get())
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };


  React.useEffect(() => {
    document.title = translate('Dispatch System')
    if (!!anonymousKey && matchPath(location.pathname, {
      path: '/login',
      exact: true,
      strict: false
    })) {
      window.location.href = history.createHref({ pathname: '/login/no-station' });
    } else if (!anonymousKey && matchPath(location.pathname, {
      path: '/login/no-station',
      exact: true,
      strict: false
    })) {
      window.location.href = history.createHref({ pathname: '/login' });
    }
  }, []);


  return (
    <Theme>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={gridStyle}
      />
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <Container>
          <Box
            sx={boxStyle1}
          >
            <ImageListItem>
              <Logo />
            </ImageListItem>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              sx={boxStyle2}>
              <Controller
                name='login'
                control={control}
                defaultValue=''
                rules={{ required: true }}
                render={({ field }) =>
                  <TextField
                    {...field}
                    margin="normal"
                    fullWidth
                    id="login"
                    label={('Login')}
                    name="login"
                    autoFocus
                    required
                  />
                }
              />

              <Controller
                name='password'
                control={control}
                defaultValue=''
                rules={{ required: true }}
                render={({ field }) =>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor='display-name'>
                      {translate('Password')}
                    </InputLabel>
                    <OutlinedInput
                      {...field}
                      type={showPassword.get() ? 'text' : 'password'}
                      label={translate('Password')}
                      fullWidth
                      id="password"
                      required
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                }
              />
              <Alert sx={{ display: loginError ? 'flex' : 'none' }} severity="error">
                {loginError ? translate(loginError) : ''}
              </Alert>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                disabled={loading}
                sx={{ mt: 3, mb: 2 }}
              >
                <>
                  {loading ? (<CircularProgress disableShrink size={30} color='secondary' />) : (translate('Entrar'))}
                </>
              </Button>

              {noStation && (
                <Button
                  id='btn-activate-station'
                  type="button"
                  fullWidth
                  variant="text"
                  size="large"
                  onClick={async () => await clearWebAnonymousStation().then(() => {
                    setTimeout(() => history.push('/activation'), 1000);
                  })}
                >
                  {(translate('Activate Station'))}
                </Button>
              )}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Container>
      </Grid>
    </Theme>
  );
}

const gridStyle = {
  backgroundImage: `url(${bgHome})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}

const boxStyle1 = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh'
}

const boxStyle2 = {
  mt: 3,
  width: "400px",
  [configTheme('light', 14).breakpoints.down('md')]: {
    width: "100%",
    paddingLeft: '15px',
    paddingRight: '15px',
  }
}
