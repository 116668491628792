/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import { State, useHookstate } from '@hookstate/core';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    LinearProgress,
    Button,
    IconButton,
    Divider,
    DialogContentText,
} from '@mui/material';
import { CancelTokenSource } from 'axios';
import ReplyIcon from '@mui/icons-material/Reply';

import { useLang } from '~/hooks/useLang';
import IUser from '~/features/User/interfaces/IUser';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import CollapseReplace from './components/CollapseReplace';
import useVehicleState from '~/features/Vehicle/stores/VehicleState';
import ButtonReplaceVehicle from './components/ButtonReplaceVehicle';
import { maskPhoneNumber, truncate } from '~/features/Dispatch/utils/stringsManipulations';
import ConectDispatchVehicle from '../ConectDispatchVehicle';
import { elapsedTime } from '~/utils/dateTime';
import { DeviceType } from '~/features/Device/components/DeviceInfoDialog';

export type Patrol = {
    readonly id: string
    readonly cpf?: string,
    readonly name: string
}
export interface DeviceCrewResponse {
    readonly deviceLog: DeviceLog
    readonly crewComposition: readonly CrewComposition[]
}

export interface DeviceLog {
    readonly id?: number
    readonly device_id?: number
    readonly user_id?: number
    readonly km_initial?: string | null
    readonly phone?: string
    readonly notes?: string;
    readonly tracking_id?: string;
    readonly date_connected?: string
    readonly task?: string;
    readonly user?: {
        readonly registration: string,
        readonly representation_name: string
    },
    readonly device?: {
        readonly name: string
        readonly device_type: DeviceType
    },
    readonly station?: {
        readonly name: string
    },
    readonly dispatch_group?: {
        readonly id: number | null;
        readonly name: string | null;
    } | null,
    readonly city?: {
        readonly id: number,
        readonly name: string
    } | null,
    readonly operation?: {
        readonly id: number,
        readonly name: string
    } | null,
}

export interface CrewComposition {
    readonly id: number
    readonly device_log_id: number
    readonly user: IUser | null
    readonly position: IPosition
}

export interface User {
    readonly id: number
    readonly name: string
    readonly cpf?: string
    readonly registration: string;
}

export interface IPosition {
    readonly id?: number
    readonly name: string
}

type DisplayDeviceProps = {
    readonly deviceCode: State<string>;
    readonly canEditCrew?: boolean;
    readonly open: State<boolean>;
    readonly deviceId: State<number | null>;
    readonly plate?: string | null;
    readonly lockTemporaryTracking?: boolean;
    readonly handleLoadDevices?: () => void;
    readonly readOnly?: boolean;
    readonly labelCommander?: string | null;
    readonly labelDriver?: string | null;
    readonly labelPatrolmans?: string | null;
    readonly labelKmInitial?: string | null;

}

export const sortedCrew = (gettedCrew) => {
    if (!gettedCrew) return null;

    return [...gettedCrew]
        ?.filter((item) => 'position' in item && item)
        ?.sort((a, b) => a.position.id - b.position.id);
}

export default function DisplayDispatchVehicle({ handleLoadDevices, open, deviceCode, deviceId, canEditCrew, lockTemporaryTracking, plate, readOnly = false, labelCommander, labelDriver, labelPatrolmans, labelKmInitial }: DisplayDeviceProps) {
    const { translate } = useLang();
    const { getCrewComposition } = useVehicleState();

    const isAwaiting = useHookstate(true);
    const openShowCrew = useHookstate(open);
    const crewState = useHookstate<readonly CrewComposition[] | null>(null);
    const deviceLogState = useHookstate<DeviceLog | null>(null);
    const showReplaceVehicle = useHookstate(false);
    const openConnectCrew = useHookstate(false);

    const clearState = () => {
        crewState.set(null);
        deviceLogState.set(null);
        showReplaceVehicle.set(false)
    };

    const clearEffect = (cancelToken?: CancelTokenSource) => {
        clearState();
        openConnectCrew.set(false)
        if (cancelToken) cancelToken.cancel();
    }

    const handleShowEdit = () => {
        openConnectCrew.set(!openConnectCrew.get())
    }

    useEffect(() => {
        const deviceIdGetted = deviceId.get();
        const open = openShowCrew.get();

        if (!deviceIdGetted) return;
        if (!open) return;

        isAwaiting.set(true);

        const { cancelToken, crewPromise } = getCrewComposition(deviceIdGetted);

        crewPromise
            .then((crew) => {
                if (crew) {
                    crewState.set(crew.crewComposition);
                    deviceLogState.set(crew.deviceLog);
                    return;
                }
                throw new Error('Don`t found any Crew in this deviceId!');
            })
            .catch((err) => { console.error(err); })
            .finally(() => isAwaiting.set(false));

        return clearEffect(cancelToken);

    }, [openShowCrew.get(), deviceId.get()]);

    const getPosition = {
        'Comandante': deviceLogState.get()?.device?.device_type?.label_commander ? deviceLogState.get()?.device?.device_type.label_commander : 'Comandante',
        'Motorista': deviceLogState.get()?.device?.device_type?.label_driver ? deviceLogState.get()?.device?.device_type.label_driver : 'Motorista',
        'Patrulheiro': deviceLogState.get()?.device?.device_type?.label_patrolmans ? deviceLogState.get()?.device?.device_type.label_patrolmans : 'Patrulheiro',
      }


    return (
        <Dialog
            open={openShowCrew.get()}
            fullWidth
            maxWidth='sm'
        >
            {openConnectCrew.get() && <Button
                size="small"
                variant='text'
                onClick={handleShowEdit}
                sx={{
                    position: 'absolute',
                    top: '15px',
                    left: '15px',
                    '&:hover': { color: 'orange' }
                }}
            >
                <ReplyIcon />
            </Button>}
            <IconButton
                size="small"
                onClick={() => {
                    openShowCrew.set(false)
                }}
                title={translate('Close')}
                sx={{
                    position: 'absolute',
                    right: '10px',
                    top: '15px',
                    "&:hover": { color: "#6e6e6e", background: 'transparent' }
                }}
            >
                <CloseIcon />
            </IconButton  >
            {isAwaiting.get() && (<LinearProgress />)}
            <DialogTitle>
                <Typography variant='h5' sx={{ textAlign: 'center'}}>
                    {`${translate('Crew Composition')} - ${truncate(deviceCode.get() ?? '', 10)}`}
                </Typography>
                <Grid container direction='row' sx={{ textAlign: 'center', pt: 2 }}>
                    {canEditCrew && !openConnectCrew.get() && !readOnly &&
                        <Grid item xs={6}>
                            <ButtonReplaceVehicle
                                showReplaceVehicle={showReplaceVehicle}
                            />
                        </Grid>
                    }
                    {canEditCrew && !openConnectCrew.get() && !readOnly &&
                        <Grid item xs={6}>
                            <Button
                                onClick={() => {
                                    openConnectCrew.set(true)
                                }}
                                variant='contained'
                            >
                                {translate('Edit crew')}
                            </Button >
                        </Grid>
                    }
                </Grid>

            </DialogTitle>
            {openConnectCrew.get() && <ConectDispatchVehicle
                crew={crewState}
                deviceLog={deviceLogState}
                lockTemporaryTracking={lockTemporaryTracking}
                openState={openConnectCrew}
                identification={deviceCode.get()}
                deviceId={deviceId}
                plate={plate}
                handleLoadDevices={handleLoadDevices}
                labelCommander={labelCommander}
                labelDriver={labelDriver}
                labelPatrolmans={labelPatrolmans}

            />}

            {!openConnectCrew.get() && (
                <DialogContent sx={{ minHeight: '200px', placeItems: 'center'}} dividers>

                    <Typography>
                        {translate('Composed on')}
                        {' '}
                        <b>
                            {deviceLogState.get()?.date_connected ? (dayjs(deviceLogState.get()?.date_connected).isValid() ? dayjs(deviceLogState.get()?.date_connected).format('DD/MM/YYYY') : '') : ''}
                        </b>
                        {' '}
                        {translate('at')}
                        {' '}
                        <b>
                            {deviceLogState.get()?.date_connected ? (dayjs(deviceLogState.get()?.date_connected).isValid() ? dayjs(deviceLogState.get()?.date_connected).format('HH:mm') : '') : ''}
                        </b>
                    </Typography>
                    <Typography>
                        {translate('Dispatch Group')}
                        {': '}
                        <b>
                            {`${deviceLogState.get()?.dispatch_group?.name ? deviceLogState.get()?.dispatch_group?.name : translate('Uninformed')}`}
                        </b>
                    </Typography>
                    <Typography>
                        {translate('Task')}
                        {': '}
                        <b>
                            {`${deviceLogState.get()?.task ? deviceLogState.get()?.task : translate('Uninformed')}`}
                        </b>
                    </Typography>

                    <Typography>
                        {labelKmInitial ? labelKmInitial : translate('Initial Kilometer')}
                        {': '}
                        <b>
                            {`${deviceLogState.get()?.km_initial ? deviceLogState.get()?.km_initial : translate('Uninformed')}`}
                        </b>
                    </Typography>

                    <Typography>
                        {translate('License plate')}
                        {': '}
                        <b>
                            {`${plate?.length ? plate : translate('Uninformed')}`}
                        </b>
                    </Typography>

                    <Typography>
                        {translate('Tracking')}
                        {': '}
                        <b>
                            {`${deviceLogState.get()?.tracking_id ? deviceLogState.get()?.tracking_id : translate('Uninformed')}`}
                        </b>
                    </Typography>

                    {deviceLogState.get()?.city && (
                        <Typography>
                        {translate('City')}
                        {': '}
                        <b>
                            {`${deviceLogState.get()?.city?.name}`}
                        </b>
                    </Typography>
                    )}

                    {deviceLogState.get()?.operation && (
                        <Typography>
                        {translate('Operation')}
                        {': '}
                        <b>
                            {`${deviceLogState.get()?.operation?.name}`}
                        </b>
                    </Typography>
                    )}

                    <Typography>
                        {translate('Notes')}
                        {': '}
                        <b>
                            {`${deviceLogState.get()?.notes ? deviceLogState.get()?.notes : translate('Uninformed')}`}
                        </b>
                    </Typography>
                    <Grid container>
                        {!sortedCrew?.length ?
                            (<Grid item xs={12} key={0} >
                                {translate('Nothing to this vehicle')}
                            </Grid>
                            ) : (sortedCrew(crewState.get())?.map((crew, index) => {
                                if ('position' in crew) {
                                    return (
                                        <Grid item key={index} xs={12} marginTop={1} marginBottom={1}>
                                            <Typography sx={{ fontWeight: 'bold' }}>
                                                {`${getPosition[crew.position.name]}`}
                                                {' '}
                                            </Typography>
                                            <Typography>
                                                {crew.user?.representation_name ? `${crew.user?.registration ? `[${crew.user?.registration}]` : ''} ${crew.user?.representation_name}` : ''}
                                            </Typography>
                                            <Typography>
                                                {crew.position.id === 1 && `${translate('Contact')}:`}
                                                {' '}
                                                <b>
                                                    {crew.position.id === 1 &&
                                                        deviceLogState.get()?.phone ?
                                                        `${maskPhoneNumber(deviceLogState.get()?.phone)}` :
                                                        ''}
                                                </b>
                                            </Typography>
                                        </Grid>
                                    )
                                }
                                return false
                            }).filter((elem) => elem !== false)
                            )
                        }
                    </Grid>
                    <DialogContentText align='center'>
                        {translate('This crew has been connected to the device')}
                        {' '}
                        {deviceLogState.get()?.device?.name}
                        {' '}
                        <br />
                        {translate('there is')}
                        {' '}
                        {elapsedTime(deviceLogState.get()?.date_connected ?? '')}
                        {' '}
                        {translate('by')}
                        {' '}
                        {deviceLogState.get()?.station ? deviceLogState.get()?.user?.representation_name : crewState.get()?.find(crew => crew.position.id === 1)?.user?.representation_name}
                        {' '}
                        {deviceLogState.get()?.station ? `${translate('on station')} ${deviceLogState.get()?.station?.name}` : deviceLogState.get()?.device ? `${translate('by device')} ${deviceLogState.get()?.device?.name}` : ''}
                    </DialogContentText>


                </DialogContent>
            )}
            <CollapseReplace
                deviceCode={deviceCode}
                deviceLogState={deviceLogState}
                deviceId={deviceId}
                showReplaceVehicle={showReplaceVehicle} />
        </Dialog>
    );
}
