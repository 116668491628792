/* eslint-disable max-lines-per-function */
import React, { useEffect, useRef } from "react";
import { Downgraded, useState } from "@hookstate/core";
import { LatLng } from "leaflet";
import { useMapEvents, Popup } from "react-leaflet";
import { LocationGeocoding, getDistrictIdByName, getReverseGeocoding } from '~/utils/location';
import { Close, LocationOn, MyLocation } from "@mui/icons-material";
import { IconButton, Typography, Card, CardContent, CardActions } from "@mui/material";
import { Box } from "@mui/system";
import { useLang } from "~/hooks/useLang";
import useDispatchState from "~/features/Dispatch/stores/DispatchState";
import useEntryState from "~/features/Entry/stores/EntryState";
import useSystemState from "~/features/System/stores/SystemState";
import { useMarkerState } from '~/features/Map/stores/MapState';
import { getCities } from '~/features/City/services/index';
import { ErrorBoundary } from 'react-error-boundary';
import useOccurrenceState from "~/features/Occurrence/stores/OccurrenceState";

export default function MapHandlePopUp({readonly=false}) {
  const { translate } = useLang();
  const latLngState = useState<LatLng | null>(null);
  const popUpRef = useRef<any>(null);
  const position = latLngState.attach(Downgraded).value;
  const addressState = useState<LocationGeocoding | null>(null);
  const loading = useState(false);
  const title = useState('');
  const { dispatch, dispatchStatusActions } = useDispatchState();
  const { entry } = useEntryState();
  const { windowManager } = useSystemState();
  const { createMarker, getMarker, updateMarker } = useMarkerState();
  const { occurrence, occurrenceStatusActions } = useOccurrenceState();

  useMapEvents({
    contextmenu: (event) => {
      if (event) {
        if ('latlng' in event) {
          latLngState.set(event?.latlng ?? null);
        }
      }
    },
  });

  const closeThisPopUp = () => {
    return popUpRef.current.close();
  }

  useEffect(() => {
    if (windowManager().entry.open.get() && entry().id.get()) {
      title.set(`Atendimento ${entry().id.get()}`)
    } else if (windowManager().dispatch.open.get() && dispatch().id.get()) {
      title.set(`Despacho ${dispatch().code.get()}`)
    }
  }, [(windowManager().entry.open.get() && entry().id.get()), (windowManager().dispatch.open.get() && dispatch().id.get())])

  useEffect(() => {
    if (position) {
      loading.set(true);
      const { locationPromise, controller } = getReverseGeocoding(position);

      locationPromise
        .then(axiosResponseData => {
          const { data: address, status } = axiosResponseData;

          addressState.set(address);
        })
        .catch(err => console.error(err))
        .finally(() => loading.set(false))


      return () => {
        controller.abort();
      }
    }
  }, [position]);

  const setEditLocation = async ({ onlyCoordinate, location, latitude, longitude, city, district = '' }) => {
    if (windowManager().occurrence.open.get() && occurrence().id.get()){

      occurrenceStatusActions().editingOccurrence.set(true)

      if (!onlyCoordinate) {
        occurrence().location.set(location)

        await getCities().then((response) => {
          const cityObject = response.find(cities => cities.name == city);
          occurrence().city.set(cityObject ?? null);
        })

        let districtName = '';
    
        if (addressState.get()?.district) {
          const curDistrict = addressState.get()?.district;

          if (curDistrict) {
            districtName = curDistrict;
          }
        }
        
        
        if (districtName) {
          getDistrictIdByName(districtName).then(districtIdData => {
            occurrence().district.set({id: districtIdData ?? null, name: districtName})
           });
        }
      }

      occurrence().latitude.set(position ? position.lat : latitude)
      occurrence().longitude.set(position ? position.lng : longitude)

      const params = {
        id: occurrence().id.get(),
        isCurrent: true,
        position: position,
        location: occurrence().location.get(),
        subtypeName: occurrence().subtype.get().name ? occurrence().subtype.get().name : null,
        typeName: occurrence().type.get().name ? occurrence().type.get().name : null
      }
      
      if(Boolean(getMarker({ markerType: 'occurrence', markerIdentifier: occurrence().id.get() }).get())){
        updateMarker({ markerType: 'occurrence', markerIdentifier: occurrence().id.get(), attribute: 'position', value: position})
      } else {
        createMarker({ markerType: 'occurrence', markerIdentifier: occurrence().id.get(), ...params as any })
      }

    } else if (windowManager().entry.open.get() && entry().id.get()) {
      if (!onlyCoordinate) {
        entry().location.set(location);
        await getCities().then((response) => {
          const cityObject = response.find(cities => cities.name == city);
          entry().cityId.set(cityObject?.id ?? null);
        })

        let districtName = '';
        
        if (districtName == '') {
          if (addressState.get()?.district) {
            const curDistrict = addressState.get()?.district;
            if (curDistrict) {
              districtName = curDistrict;
            }
          }
        } 
        
        if (districtName) {
          getDistrictIdByName(districtName).then(districtIdData => {
            entry().districtId.set(districtIdData ?? null)
           });
        }
      }
      entry().latitude.set(position ? position.lat : latitude);
      entry().longitude.set(position ? position.lng : longitude);

      const params = {
        id: entry().id.get(),
        isCurrent: true,
        position: position,
        location: entry().location.get(),
        typeName: entry().typeName.get(),
        subtypeName: entry().subtypeName.get(),
        status: 1,
      }

      createMarker({ markerType: 'entry', markerIdentifier: entry().id.get(), ...params as any })
      

    } else if (windowManager().dispatch.open.get() && dispatch().id.get()) {
      dispatchStatusActions().editingDispatch.set(true)
      if (!onlyCoordinate) {
        dispatch().occurrence.location.set(location)
      }
     
      dispatch().occurrence.latitude.set(position ? position.lat : latitude);
      dispatch().occurrence.longitude.set(position ? position.lng : longitude);      
      
      const params = {
        id: dispatch().id.get(),
        isCurrent: true,
        position: position,
        location: dispatch().occurrence.location.get(),
        code: dispatch().code.get(),
        status: dispatch()?.status?.id.get() ?? 0,
        subtypeName: dispatch()?.subtype?.name ? dispatch()?.subtype?.name : null,
        typeName: dispatch()?.type?.name ? dispatch()?.type?.name : null
      }
      
      if(Boolean(getMarker({ markerType: 'dispatch', markerIdentifier: dispatch().id.get() }).get())){
        updateMarker({ markerType: 'dispatch', markerIdentifier: dispatch().id.get(), attribute: 'position', value: position})
      } else {
        createMarker({ markerType: 'dispatch', markerIdentifier: dispatch().id.get(), ...params as any })
      }
    }
    closeThisPopUp()
  }

  if (!position) return null;

  return (
    <ErrorBoundary FallbackComponent={({ error, resetErrorBoundary }) => {
      console.info('MapHandlePopUp', error);
      return (<></>)
    }}>
      <Popup
        position={position}
        ref={(ref) => {
          popUpRef.current = ref;
        }}
        closeOnEscapeKey
        closeButton={false}
      >
        <Box sx={{ textAlign: 'right' }}>
          <IconButton className='leaflet-popup-close-button' sx={{ margin: '0 !important', padding: '1px' }} onClick={closeThisPopUp} size='small' >
            {<Close />}
          </IconButton>
        </Box>
        <Card sx={{ backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: 'none' }}>
          <CardContent sx={{ padding: 'none', width: '100%' }}>

            { !readonly ? (
              <>
                <Typography variant="h6" >
                  {translate('Use this location?')}
                </Typography>
                <Typography variant="h6" >
                  {title.get()}
                </Typography>
              </>) : false}


            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <MyLocation />
              &nbsp;
              <strong style={{ display: 'inline-block', marginRight: 'auto' }} >
                Lat:
              </strong>
              {' '}
              &nbsp;
              {`${position.lat.toFixed(8)}`}
            </Typography>
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <MyLocation />
              &nbsp;
              <strong style={{ display: 'inline-block', marginRight: 'auto' }} >
                Long:
              </strong>
              {' '}
              &nbsp;
              {`${position.lng.toFixed(8)}`}
            </Typography>
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <LocationOn />
              &nbsp;
              <strong>
                {translate('Location')}
                :
              </strong>
              {' '}
              &nbsp;
            </Typography>
            <Typography >
              {loading.get() ? (`${translate('Awaiting')}...`) : (addressState.get() ? addressState.get()?.main ?? '' : translate('Not Found'))}
            </Typography>
          </CardContent>
          { !readonly ? (
          <CardActions sx={{ padding: '0', display: 'flex', justifyContent: 'space-between' }} >
            <IconButton disabled={Boolean(loading.get() || (windowManager().dispatch.open.get() && (dispatch().status.id.get() == 8)))} onClick={() => setEditLocation({ onlyCoordinate: false, location: addressState.get()?.main ?? null, latitude: position ? position.lat : null, longitude: position ? position.lng : null, city: addressState.get()?.city ?? null })} color='primary' title={`${translate('Use Location')}`}  >
              <LocationOn />
            </IconButton>
            <IconButton disabled={Boolean(loading.get() || (windowManager().dispatch.open.get() && (dispatch().status.id.get() == 8)))} onClick={() => setEditLocation({ onlyCoordinate: true, location: addressState.get()?.main ?? null, latitude: position ? position.lat : null, longitude: position ? position.lng : null, city: addressState.get()?.city ?? null })} color='primary' title={`${translate('Use Only Geographical Coordinates')} (Lat/Lng)`} >
              <MyLocation />
            </IconButton>
          </CardActions> ) : false }
        </Card>
      </Popup>
    </ErrorBoundary>
  )
}
