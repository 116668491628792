import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IAnnouncement from '../interfaces/IAnnouncement';
import Layout from '~/components/Layout';

export default function Create() {
  const {register, handleSubmit, control, setValue:setFormValue} = useForm<IAnnouncement>();
    const fields = [
      { name: 'title', type: 'string'},
      { name: 'description', type: 'string'},
      { name: 'is_change_version', type: 'boolean', placeholder: 'Is Change Version?'},
      { name: 'announcement_date', type: 'datetime', placeholder: 'Announcement Date'},
      { name: 'announcement_end_date', type: 'datetime', placeholder: 'Announcement End Date'},
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate model={'announcement'} 
          fields={fields} 
          register={register} 
          handleSubmit={handleSubmit} 
          control={control} 
          messageSuccess={'Announcement successfully created!'} 
          title={'Add announcement'}
          subheader={'Add a new announcement'}
          setFormValue={setFormValue} />
      </Layout>
    )
}
