import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Autocomplete, TextField, MenuItem, CircularProgress, Box } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useDispatchVehicleState from '~/features/Dispatch/stores/DispatchVehicleState';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import IVehicleList from '~/features/Dispatch/interfaces/IVehicleList';
import SelectAutoComplete from '~/components/SelectAutoComplete';
import { useHookstate } from '@hookstate/core';
import LoadingButton from '@mui/lab/LoadingButton';
import getVehiclesDispatch from '../../services';

interface IVehicle {
  readonly id: number | null,
  readonly identification: string | null,
  readonly status: string | null,
  readonly task: string | null,
  readonly deviceId: number | null,
}


export default function AddVehicleDialog({ open }) {
  const { translate } = useLang();
  const { associateVehicleDispatch, dispatch } = useDispatchState();
  const { id } = dispatch();
  const [vehicle, setVehicle] = useState<IVehicle | null>(null);
  const loading = useHookstate<boolean>(false);
  const vehicleList = useHookstate<IVehicleList[]>([])
  const loadingOption = useHookstate(false)

  useEffect(() => {
    loadingOption.set(true)
    getVehiclesDispatch(id.get()).then((response) => {
      if (response?.data) {
        vehicleList.set(response.data)
      }
    }).finally(() => {
      loadingOption.set(false)
    })
  }, [])

  const handleClose = () => {
    open.set(false);
  };

  const action = () => {
    if (vehicle && vehicle.deviceId) {
      associateVehicleDispatch(vehicle.deviceId, loading).finally(() => {
        handleClose();
      })
    }
  };

  const getVehicles = async () => {
    return (vehicleList.get().map((device: IVehicleList): IVehicle => ({
      id: 'vehicle' in device ? (device['vehicle'] ? Number(device['vehicle']['id']) : null) : null,
      identification: device['name'],
      status: device['status']['name'],
      task: device['device_log'] ? device['device_log']['task'] ? device['device_log']['task'] : null : null,
      deviceId: Number(device['id']) ?? null
    })))
  };
  
  return (
    <Dialog open={open.get()} onClose={handleClose} maxWidth={'xs'} fullWidth>
      <DialogTitle variant='h5' minWidth={"320px"}>
        {translate('Add Vehicle')}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth size='small' sx={{ mt: 1 }}>
          {!loadingOption.get() ?
            <SelectAutoComplete
              fetchData={getVehicles}
              placeholder={translate('Vehicle')}
              serverSide={false}
              setKey={((option: IVehicle) => option?.id)}
              setOptionSelect={(option: IVehicle) => option ? `${option?.identification} ${option?.task ? `- [${option?.task}]` : ''}` : ''}
              showOptionLabel={(option: IVehicle) => option ? `${option?.identification} ${option?.task ? `- [${option?.task}]` : ''}` : ''}
              getValue={(value: IVehicle) => setVehicle(value)}
              defaultValue={null}
              size='small'

            />
            :
            <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <CircularProgress size={30} />
            </Box>
          }
        </FormControl>
      </DialogContent>
      <DialogActions sx={{px: 3, gap: 1}}>
        <Button
          variant='outlined'
          onClick={handleClose}>
          {translate('Cancel')}
        </Button>
        <LoadingButton
          loading={loading.get()}
          variant='contained'
          onClick={action}>
          {translate('Submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}