import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IVehicleModel from '../interfaces/IVehicleModel';


export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue, getValues} = useForm<IVehicleModel>();
  const fields = [
      { name: 'id', type: 'hidden'},
      { name: 'name', type: 'string'},
      { name: 'brand_id', type: 'selectAutoComplete', model: 'vehicle-model', placeholder: 'VehicleBrand:name'},
  ];
  
    return(
      <CrudEdit 
        model={'vehicle-model'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        setFormValue={setFormValue}
        messageSuccess={'Vehicle model successfully updated!'}  
        title={'Edit vehicle model'}
        getValues={getValues} 
      />
    )
}
