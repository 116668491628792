import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IAgency from '../interfaces/IAgency';
import Layout from '~/components/Layout';



export default function Create() {     
  const {register, handleSubmit, control, setValue:setFormValue} = useForm<IAgency>();
    const fields = [
      { name: 'name', type: 'string', required: true},
      { name: 'acronym', type: 'string', required: true },
      { name: 'area_plan_id', type: 'selectAutoComplete', model: 'area-plan', placeholder:'Mapa', required: true },
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate 
          model={'agency'} 
          fields={fields} 
          register={register} 
          handleSubmit={handleSubmit} 
          control={control} 
          messageSuccess={'Agency successfully created!'}  
          title={'Add agency'}  
          subheader={'Add a new agency'}
          setFormValue={setFormValue} />
      </Layout>
    )
}
