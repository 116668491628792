import * as React from 'react'
import { useEffect } from 'react'
import useDispatchCommentState from '../../stores/DispatchCommentState';
import { useLang } from '~/hooks/useLang'
import useSocket from '~/hooks/useSocket';
import { State, useHookstate } from '@hookstate/core';
import { SendCommentField } from '~/components/SendCommentField';

interface DispatchSendCommentFieldProps {
  readonly id: string | number | null;
  readonly disabled: boolean;
  readonly name: string | null;
  readonly label: string | null;
  readonly required: boolean;
  readonly handleClickSend: () => void;
  readonly moveScrolltoComment?: State<boolean>;
}

export function DispatchSendCommentField({ id, disabled, handleClickSend, moveScrolltoComment }: DispatchSendCommentFieldProps) {
  const { translate } = useLang();
  const { newComment, sendComment } = useDispatchCommentState();
  const { socket } = useSocket();
  const loading = useHookstate<boolean>(false)

  useEffect(() => {
    socket.on('message', (message) => {
      const { dispatchId, comment } = JSON.parse(message);
      sendComment({ dispatchId, comment })
        .then(console.log)
        .catch(console.error);
    });
  }, []);

  return (
    <SendCommentField 
    id={id} 
    disabled={disabled || loading.get()} 
    name={"newComment"} 
    label={translate('Write your comment')} 
    required={true} 
    handleClickSend={handleClickSend} 
    newComment={newComment} 
    sendComment={({id, comment}) => sendComment({ dispatchId: id, comment: comment })}
    moveScrolltoComment={moveScrolltoComment}
    />
  )
}
