import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IDispatchClosedStatus from '../interfaces/IDispatchClosedStatus';


export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue,getValues} = useForm<IDispatchClosedStatus>();
  const fields = [
      { name: 'id', type: 'hidden'},
      { name: 'name', type: 'string'},
      { name: 'acronym', type: 'string'},
      { name: 'available_for_mobile', type: 'boolean'},
      { name: 'required_qualification', type: 'boolean'},
      { name: 'can_reopen_dispatch', type: 'boolean'},
      { name: 'is_solved_by_third_party', type: 'boolean'},
      { name: 'closed_status_class_id', type: 'selectAutoComplete', model: 'closed-status-class', placeholder: 'Classe de status de fechamento'},
  ];


    return(
      <CrudEdit 
        model={'dispatch/closed-status'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        setFormValue={setFormValue}
        messageSuccess={'Dispatch closed status successfully updated!'}  
        title={'Edit Dispatch closed status'} 
        getValues={getValues}
      />
    )
}