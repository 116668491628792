import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useAnnouncementState from '../stores/AnnouncementState';
import { getAnnouncementNoReadService } from '~/features/Announcement/services/index';

export default function AnnouncementDialog(){
  const { translate } = useLang();
  const { announcement, announcementDialogCancel, announcementDialogActionRead } = useAnnouncementState();

    React.useEffect(() => {
      getAnnouncementNoReadService().then((response) => {
        announcement().set(response)
      })
    }, [])

  return (
    <Dialog fullWidth={true} open={announcement().get() ? announcement().get()[0]?.id != null : false}>
        <DialogTitle>
          <b>{announcement().get() ? announcement().get()[0]?.title : ''}</b>
        </DialogTitle>
        <DialogContent>
          <pre>{announcement().get() ? announcement().get()[0]?.description : ''}</pre>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={announcementDialogCancel}>
            {translate('Cancel')}
          </Button>
          <Button 
            variant='contained'
            onClick={() => announcementDialogActionRead(announcement().get() ? announcement().get()[0]?.id : false)}
            >
            {translate("Don't show again")}
          </Button>
        </DialogActions>
      </Dialog>
  )
}