import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Divider } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { State } from '@hookstate/core';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, timelineOppositeContentClasses } from '@mui/lab';
import { TimeLineProps } from '../DeviceDispatchListTable';
import useSystemState from '~/features/System/stores/SystemState';
import dayjs from 'dayjs';

interface TimelineDeviceStatusProps {
  readonly openState: State<boolean>
  readonly timeLinePoint: State<TimeLineProps[]>
}


export default function TimelineDeviceStatus({ openState, timeLinePoint }: TimelineDeviceStatusProps) {
  const { translate } = useLang();
  const { getStatusColors } = useSystemState()

  const handleCancel = () => {
    openState.set(false);
  };

  return (
    <Dialog
      open={openState.get()}
      onClose={handleCancel}
      maxWidth={'md'}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginLeft: 4
        }}
      >
        {translate('Chronology device')}
      </DialogTitle>
      <DialogContent>
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.2,
            },
          }}
        >
          {timeLinePoint.get().map((pointStatus) => {
            return (
              <TimelineItem key={pointStatus.id}>
                <TimelineOppositeContent color="text.secondary">
                  <Typography variant='body1'>
                    {dayjs(pointStatus.from).format('DD/MM/YYYY')}
                  </Typography>
                  <Typography variant='body1'>
                    {dayjs(pointStatus.from).format('HH:mm:ss')}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      backgroundColor: getStatusColors(pointStatus.id).main, color: getStatusColors(pointStatus.id).contrastText,
                      '&:hover': { backgroundColor: getStatusColors(pointStatus.id).dark, color: getStatusColors(pointStatus.id).contrastText }
                    }}
                  />
                  {pointStatus.id !== 8 &&
                    <TimelineConnector />
                  }
                </TimelineSeparator>
                <TimelineContent>
                  <Typography variant='body1'>
                    {`${pointStatus.name}`}
                  </Typography>
                  <Typography variant='body1'>
                    {pointStatus.duration ? translate('Duration', { time: pointStatus.duration }) : false}
                  </Typography>
                </TimelineContent>
                <Divider />
              </TimelineItem>
            )
          })}
        </Timeline>
      </DialogContent>
    </Dialog>

  )
}