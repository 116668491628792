import type { State, StateMethods } from '@hookstate/core'
import { mountUrlRequest } from '~/services/fetch'

interface getTypeFileProps {
  readonly typeName: string
  readonly typeDocument: StateMethods<string | null>
}

interface downloadFileProps {
  readonly mediaPath: string
  readonly fileType: string
  readonly typeDocument: State<string | null>
  readonly isMount: boolean
  readonly fileName: string
}

export function getTypeFile({ typeName, typeDocument }: getTypeFileProps) {
  const typeMap = {
    'sheet': 'xlsx',
    'excel': 'xlsx',
    '.spreadsheetml.sheet': 'xlsx',
    'text': 'txt',
    'plain': 'txt',
    'wordprocessingml.document': 'docx',
    'msword': 'docx',
    'pdf': 'pdf',
    'jpeg': 'jpeg',
    'png': 'png',
    'csv': 'csv',
    'jpg': 'jpg',
  }

  if (typeName) {
    for (const key of Object.keys(typeMap)) {
      if (typeName.includes(key)) {
        typeDocument.set(typeMap[key])
        return typeMap[key]
      } 
    }
  }

  return typeDocument
}

export async function downloadFile({
  mediaPath,
  fileType,
  typeDocument,
  isMount,
  fileName
}: downloadFileProps) {
  try {
    const url = isMount ? mediaPath : mountUrlRequest(`/occurrence-comment/get-media/${mediaPath}`)
    const response = await fetch(url)
    const blob = await response.blob()

    const fileExtension = getTypeFile({
      typeName: fileType,
      typeDocument: typeDocument,
    })

    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = fileName ? fileName : `downloaded_file.${fileExtension}`

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
  } catch (error) {
    console.log(error)
  }
}
