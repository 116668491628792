import React from 'react'
import { State, useHookstate } from '@hookstate/core'
import { Validation as validation } from '@hookstate/validation'
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { ptBR } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { DispatchSearchBox } from '~/components/DispatchSearchBox'
import FilterAutocompleteCamera from '~/components/FilterAutocompleteCamera'
import FilterAutocompleteSubtypesByType from '~/components/FilterAutocompleteSubtypeByType'
import FilterAutocompleteType from '~/components/FilterAutocompleteType'
import InputDateTime from '~/components/InputDate'
import { InputSearchWithClear } from '~/components/InputSearchWithClear'
import notify from '~/utils/notify'
import { useLang } from '../../../../../../hooks/useLang'
import useSurveillanceReportingState from '../../../../stores/SurveillanceReportingState'

interface NewSurveillanceReportingProps {
  readonly invalidRequest: State<boolean>
}
// eslint-disable-next-line max-lines-per-function
export default function NewSurveillanceReporting({
  invalidRequest,
}: NewSurveillanceReportingProps) {
  const { translate } = useLang()
  const {
    surveillanceReportingStates,
    sendNewSurveillanceReporting,
    editSurveillanceReporting,
    verifyInputErros,
  } = useSurveillanceReportingState()
  const {
    surveillanceReporting,
    loading,
    save,
    searchDispatches,
    searchOccurrence,
    showCheckbox,
    createOccurrence,
    verifyType,
    verifySubtype,
    totalSubtypes,
    newNarrations,
  } = surveillanceReportingStates()
  const { notes, cameraId, reportedAt, id, occurrenceId, typeId, subtypeId } =
    surveillanceReporting
  const [dateOfReport, setDateOfReport] = useState<Dayjs | null>(null)
  const isSearchDialog = useHookstate(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const latitudeCamera = useHookstate<number | null>(0)
  const longitudeCamera = useHookstate<number | null>(0)
  const enabledField = typeId?.get() !== null

  const handleClearInputs = () => {
    setDateOfReport(null)
    notes.set('')
    searchOccurrence.set('')
    searchDispatches.set('')
    reportedAt.set(null)
    save.set(false)
    occurrenceId.set(null)
    latitudeCamera.set(null)
    longitudeCamera.set(null)
    cameraId.set(null)
    invalidRequest.set(false)
    subtypeId.set(null)
    typeId.set(null)
    verifyType.set(false)
    verifySubtype.set(true)
    // VOLTAR O FOCO PARA O CAMPO DO RELATO
    // inputRef.current?.focus()
  }

  const handleSend = () => {
    loading.set(true)
    if (save.get()) {
      editSurveillanceReporting({
        id: id.get(),
        cameraId: cameraId.get(),
        occurrenceId: occurrenceId.get() || null,
        reportedAt: reportedAt.get(),
        notes: notes.get(),
      })
        .then((response) => {
          if (response?.status === 200) {
            notify({
              message: translate('Narration saved successfully'),
              type: 'success',
            })
            handleClearInputs()
            loading.set(false)
            newNarrations.set(!newNarrations.get())
          }
        })
        .catch(() => {
          loading.set(false)
        })
    } else {
      if (verifyInputErros()) {
        sendNewSurveillanceReporting({
          occurrenceId: !createOccurrence.get() ? occurrenceId.get() : null,
          cameraId: cameraId.get(),
          notes: notes.get(),
          reportedAt: reportedAt.get(),
          toCreateOccurrence: createOccurrence.get(),
          typeId: typeId.get(),
          subtypeId: subtypeId.get(),
          latitude: latitudeCamera.get(),
          longitude: longitudeCamera.get(),
        })
          .then((response) => {
            if (response.status === 200) {
              notify({
                message: translate('Narration saved successfully'),
                type: 'success',
              })
              handleClearInputs()
              loading.set(false)
              newNarrations.set(!newNarrations.get())
            }

          })
          .catch(() => {
            loading.set(false)
          })
      } else {
        invalidRequest.set(true)
      }
      loading.set(false)
    }
  }

  useEffect(() => {
    if (reportedAt.get()) {
      const days = dayjs(reportedAt.get())
      setDateOfReport(days.isValid() ? days : null)
    }
  }, [reportedAt.get()])

  useEffect(() => {
    occurrenceId.set(searchOccurrence.get())
  }, [searchOccurrence.get()])

  useEffect(() => {
    if (!!cameraId.get() || !!searchOccurrence.get()) {
      inputRef.current?.focus()
    }
  }, [cameraId.get(), searchOccurrence.get()])

  useEffect(() => {
    if (createOccurrence.get() === true) {
      searchOccurrence.set('')
      searchDispatches.set('')
    }
  }, [createOccurrence])

  return (
    <Grid container columns={12} sx={{ padding: '0px 8px' }}>
      {!save.get() && (
        <Grid item xs={6} sx={{ pr: 1 }}>
          <DispatchSearchBox
            currentDispatch={(value) => {
              if (value) {
                searchOccurrence.set(
                  value?.occurrence ? String(value?.occurrence.id) : ''
                )
              } else {
                searchOccurrence.set('')
              }
            }}
            dispatchValue={searchDispatches}
            isOpen={isSearchDialog}
            isValid={!createOccurrence.get()}
            size="small"
          />
        </Grid>
      )}
      {!save.get() && (
        <Grid item xs={6}>
          <InputSearchWithClear
            fullWidth
            placeholder={translate('Occurrence')}
            search={searchOccurrence}
            disabled={createOccurrence.get()}
          />
        </Grid>
      )}
      {!showCheckbox.get() && (
        <Grid xs={6} item sx={{ pt: 1 }}>
          <FilterAutocompleteCamera
            sx={{ pr: 1 }}
            cameraId={cameraId.get()}
            onCameraChange={(camera) => {
              cameraId.set(camera?.id ?? null)
              latitudeCamera.set(camera?.latitude ?? null)
              longitudeCamera.set(camera?.longitude ?? null)
            }}
            error={invalidRequest.get() && validation(cameraId).invalid()}
          />
        </Grid>
      )}
      {!showCheckbox.get() && (
        <Grid xs={6} item sx={{ pt: 1 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
            <InputDateTime
              disabled={!!showCheckbox.get()}
              sx={{ width: '100%' }}
              inputFormat="DD/MM/YYYY HH:mm"
              placeholder={translate('dd/mm/yyyy')}
              dateTime={dateOfReport}
              onDateTimeChange={(date) => {
                reportedAt.set(dayjs(date).format('YYYY-MM-DD HH:mm:ss'))
              }}
              label={translate('Report date')}
            />
          </LocalizationProvider>
        </Grid>
      )}
      {!showCheckbox.get() && (
        <Grid item xs={12} sx={{ marginTop: 1.2 }}>
          <TextField
            inputRef={inputRef}
            disabled={!!showCheckbox.get()}
            error={invalidRequest.get() && validation(notes).invalid()}
            multiline
            rows={2}
            sx={{ width: '100%', height: '50%' }}
            placeholder={translate('Write something')}
            value={notes.get() ?? ''}
            onChange={(event) => notes.set(event.target.value)}
            variant="outlined"
          />
        </Grid>
      )}

      {!save.get() && createOccurrence.get() && (
        <Grid
          container
          xs={12}
          direction={'row'}
          justifyContent={'space-between'}
        >
          <Grid item xs={6} sx={{ pt: 1, paddingRight: 1 }}>
            <FilterAutocompleteType
              typeId={typeId.get()}
              onTypeChange={(type) => typeId.set(Number(type?.id) ?? null)}
              error={
                verifyType.get() &&
                invalidRequest.get() &&
                validation(typeId).invalid()
              }
            />
          </Grid>

          <Grid item xs={6} sx={{ pt: 1 }}>
            <FilterAutocompleteSubtypesByType
              disabled={!enabledField}
              error={
                verifySubtype.get() &&
                enabledField &&
                invalidRequest.get() &&
                validation(subtypeId).invalid()
              }
              hasType
              typeId={typeId.get()}
              onSubtypeChange={(subtype) => subtypeId?.set(subtype?.id ?? null)}
              getRowsCount={(value) => {
                if (totalSubtypes) totalSubtypes.set(value)
                return totalSubtypes.get()
              }}
            />
          </Grid>
        </Grid>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 1,
          width: '100%',
          justifyContent: save.get() ? 'flex-end' : 'space-between',
        }}
      >
        {!save.get() && (
          <Box sx={{
            paddingLeft: 1,
          }}>
            <FormControlLabel
              control={
                <Switch
                  checked={createOccurrence.get()}
                  onChange={() => createOccurrence.set(!createOccurrence.get())}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    }
                  }}
                />
              }
              label={translate('Criar ocorrência junto ao relato')}
            />
          </Box>
        )}
        {!showCheckbox.get() && (
          <Box>
            <Button
              variant="outlined"
              sx={{ mr: 1 }}
              onClick={() => {
                handleClearInputs()
              }}
            >
              {translate('Cancel')}
            </Button>
            <Button
              variant="contained"
              disabled={loading.get()}
              onClick={handleSend}
            >
              {save.get() ? translate('Save') : translate('Send')}
            </Button>
          </Box>
        )}
      </Box>
    </Grid>
  )
}
