/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete, FormControl, Grid, InputAdornment } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useDispatchRegistrationObjectState from '../../../stores/DispatchRegistrationObjectState';
import useDispatchRegistrationState from '../../../stores/DispatchRegistrationState';
import { toast } from 'react-toastify';
import { IObjectDTO } from '../../../interfaces/IDispacthRegistrationObject';
import IObjectType from '~/features/ObjectType/interfaces/IObjectType';
import IUnitMeasurement from '~/features/UnitMeasurement/interfaces/IUnitMeasurement';
import ICircunstance from '~/features/Circunstance/interfaces/ICircunstance';
import IDestination from '~/features/Destination/interfaces/IDestination';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import Buttons from '../../DispatchRegistrationTab/components/Buttons';
import notify from '~/utils/notify';
import FieldAutocompleteRegistration from '~/components/FieldAutocompleteRegistration';
import { useHookstate } from '@hookstate/core';

interface IFormObject {
  readonly handleClickOpenConfirmAction: (action: string) => void
}

interface IErrosProps {
  readonly type: boolean;
  readonly circunstance: boolean;
  readonly destination: boolean;
  readonly unitMeasurement: boolean;
  readonly quantity: boolean;
}

export default function ObjectForm({ handleClickOpenConfirmAction }: IFormObject) {
  const loading  = useHookstate<boolean>(false)
  const { dispatch } = useDispatchState();
  const { occurrence, status } = dispatch();
  const { id: occurrenceIdState, } = occurrence;
  const disableFields = status.value.id === 8

  const { registrationObjectDispatch, sendObject, editObject, getObjectTypes } = useDispatchRegistrationObjectState();

  const { registrationDispatch, getCircunstances, getDestinations, getUnitMeasurement } = useDispatchRegistrationState();
  const { addRegistration } = registrationDispatch();

  const { object } = registrationObjectDispatch();
  const { translate } = useLang();

  const [error, setError] = useState<IErrosProps>({
    type: false,
    circunstance: false,
    destination: false,
    unitMeasurement: false,
    quantity: false,
  });

  const someInvalidInput = () => {
    if (!object.objectType) {
      setError({
        ...error,
        type: true
      })
      return true;
    }
    if (!object.unitMeasurement) {
      setError({
        ...error,
        unitMeasurement: true
      })
      return true;
    }
    if (object.unitMeasurement && !object.quantity) {
      setError({
        ...error,
        quantity: true
      })
      return true;
    }
    if (!object.circunstance) {
      setError({ ...error, circunstance: true })
      return true;
    }
    if (!object.destination) {
      setError({ ...error, destination: true })
      return true;
    }
    return false;
  }

  const handleSend = useCallback(async () => {
    loading.set(true)
    await sendObject(object.get()).finally(() => {
      loading.set(false)
    })
    notify({ message: translate('Successfully created'), type: 'success' })
    addRegistration.set(false)
  }, [addRegistration, object, sendObject, translate])

  const handleEdit = useCallback(async () => {
    loading.set(true)
    await editObject(object.get()).finally(() => {
      loading.set(false)
    })
    notify({ message: translate('Saved successfully'), type: 'success' })
    addRegistration.set(false)
  }, [addRegistration, editObject, object, translate])


  useEffect(() => {
    object.set(prev => ({ ...prev, occurrenceId: occurrenceIdState.get() }))
  }, [])

  return (
    <FormControl fullWidth size='small' >
      <Grid container direction='row'>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={object.objectType.get()?.id as string}
            onError={(message) => setError({ ...error, type: message })}
            isRequired
            error={error.type}
            label={translate('Object Type')}
            onValueChange={(value) => {
              object.set(prev => ({ ...prev, objectType: value }))
            }}
            optionsFetch={getObjectTypes}
            disabled={disableFields}
          />

        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={object.unitMeasurement.get()?.id as string}
            onError={(message) => setError({ ...error, unitMeasurement: message })}
            isRequired
            error={error.unitMeasurement}
            label={translate('Unit Measurement')}
            onValueChange={(value) => {
              object.set(prev => ({
                ...prev, unitMeasurement: {
                  id: value?.id ?? '',
                  name: value?.name ?? '',
                  symbol: value?.symbol ?? ''
                }
              }))
            }}
            optionsFetch={getUnitMeasurement}
            disabled={disableFields}
          />

        </Grid>
        <Grid item xs={1.5} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-quantity"
            error={!!error.quantity}
            helperText={error.quantity ?? ''}
            onFocus={() => setError({ ...error, quantity: false })}
            label={translate('Quantity')}
            value={object.quantity.get()}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { min: 0, step: 0.01 },
              endAdornment: <InputAdornment position="end">
                {object.unitMeasurement?.get()?.symbol}
              </InputAdornment>,
              onChange: (event) =>
                object.quantity.set(parseFloat(event.target.value)),
            }}
          />
        </Grid>
        <Grid item xs={2.25} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={object.circunstance.get()?.id as string}
            isRequired
            onError={(message) => setError({ ...error, circunstance: message })}
            error={error.circunstance}
            label={translate('Circunstance')}
            onValueChange={(circunstance) => {
              object.set(prev => ({ ...prev, circunstance }))
            }}
            optionsFetch={getCircunstances}
            disabled={disableFields}
          />

        </Grid>
        <Grid item xs={2.25} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            valueId={object.destination.get()?.id as string}
            isRequired
            onError={(message) => setError({ ...error, destination: message })}
            error={error.destination}
            label={translate('Destination')}
            onValueChange={(destination) => {
              object.set(prev => ({ ...prev, destination }))
            }}
            optionsFetch={getDestinations}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={12} sx={{ padding: 1, marginBottom: 1 }}>
          <TextField
            disabled={disableFields}
            id="outlined-multiline-flexible"
            label={translate('Note')}
            multiline
            fullWidth
            rows={4}
            size='small'
            value={object.notes.get()}
            InputProps={{
              onChange: (event) => {
                const notes = event.target.value
                object.notes.set(notes)
              },
            }}
            sx={{ height: 92 }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Buttons
          loading={loading}
          isUpdate={!!object.id.get()}
          handleClickOpenConfirmAction={handleClickOpenConfirmAction}
          handleEdit={handleEdit}
          handleSend={handleSend}
          someInvalidInput={someInvalidInput}
          disableFields={disableFields}
        />
      </Grid>
    </FormControl >
  );
}