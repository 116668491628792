import React from 'react'
import Index from './pages/Index'
import { AppRouteProps } from '~/routes'

export default function VideoMonitoringRoutes(): readonly AppRouteProps[] {
  const routes: readonly AppRouteProps[] = [
    {
      path: '/video-monitoring',
      component: Index,
      isProtected: true,
      // permissions: ['dispatch_index'],
    },
  ]

  return routes
}
