import React, { useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid, IconButton } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { authFetch } from '~/services/fetch';
import { State, useHookstate } from '@hookstate/core';
import useSystemState from '~/features/System/stores/SystemState';
import DeviceClosedStatusButton from '../DeviceClosedStatusButton';
import DisplayDispatchVehicle from '../DisplayDispatchVehicle';
import { RemoveDeviceButton } from '~/components/RemoveDeviceButton';
import { DirectionsCar as CarIcon } from '@mui/icons-material';
import { useTheme } from '~/hooks/useTheme';

interface IStatus {
  readonly id: number
  readonly name: string
  readonly acronym: string
}
interface IDispatchDevice {
  readonly name: string
}

interface ChangeStatusVehicleProps {
  readonly openState: State<boolean>,
  readonly dispatchId: State<number | null>,
  readonly deviceId: State<number | null>,
  readonly deviceCode: State<string>,
  readonly dispatchDeviceId: State<number | null>,
  readonly statusCurrent: State<{ readonly id: string; readonly name: string; readonly acronym: string; }>
}

export default function ChangeStatusVehicle({ openState, dispatchId, deviceId, deviceCode, dispatchDeviceId, statusCurrent }: ChangeStatusVehicleProps) {
  const { translate } = useLang();
  const { darkMode } = useTheme();
  const { getStatusColors } = useSystemState()
  const statusList = useHookstate<ReadonlyArray<IStatus>>([{ id: 0, name: '', acronym: '' }]);
  const device = useHookstate<IDispatchDevice>({ name: '' });
  const statusIndexLast = useHookstate(0);
  const statusLast = useHookstate<IStatus>({ id: 0, name: '', acronym: '' });
  const isCrewDialog = useHookstate(false);
  const deviceIdState = useHookstate(deviceId);
  const statusCurrentState = useHookstate(statusCurrent);


  useEffect(() => {
    if ((openState.get() == true) && deviceIdState.get()) {
      authFetch({
        url: '/status',
        method: 'GET',
      }).then(({ data }: { readonly data: readonly any[] }) => {
        statusList.set(data);

        const indexLast = (data.findIndex(status => status.id == statusCurrentState.get().id) + 1);
        statusIndexLast.set(indexLast)
        statusLast.set(data[indexLast]);
      });

      authFetch({
        url: `/device/${deviceIdState.get()}`,
        method: 'GET',
      }).then(({ data }) => {
        device.set(data);
      });
    }

  }, [openState.get(), deviceIdState.get()]);


  const handleCancel = () => {
    openState.set(false);
  };

  const nextStatus = () => {
    if (statusIndexLast.get() <= statusList.get().length) {
      action(statusList.get()[statusIndexLast.get()].id);
    }
  };

  const toggleCrewDialog = () => {
    const isCrew = isCrewDialog.get();
    isCrewDialog.set(!isCrew);
  };

  const action = (statusId) => {
    authFetch({
      url: '/dispatch/status/change',
      method: 'POST',
      data: {
        dispatch_id: dispatchId.get(),
        dispatch_device_id: dispatchDeviceId.get(),
        status_id: statusId
      }
    }).then(() => {
      openState.set(false);
      handleCancel();
    })
  };

  return (
    <Dialog sx={{ borderRadius: '5px' }} open={openState.get()} onClose={handleCancel} fullWidth={true} maxWidth={'md'}>
      <DialogTitle display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
        <Typography>
          {`${translate('Device:name')} ${deviceCode.get() ?? ''}`}
        </Typography>
        {(deviceIdState.get() && parseInt(statusCurrentState.get().id) !== 8) && (
          <IconButton
            onClick={toggleCrewDialog}
            title={translate("Composition")}
            sx={{
              backgroundColor: darkMode ? '#363636' : '#d8d8d8',
            }}
          >
            <CarIcon />
          </IconButton>
        )
        }
      </DialogTitle>
      <DialogContent sx={{ width: '100%', height: '20%' }}>
        <Grid container gap={1} alignItems={'center'} sx={{ height: '100%' }}>
          <Grid item xs={4} alignItems={'center'} sx={{ flex: 1, height: '100%' }}>
            {
              (parseInt(statusCurrentState.get().id) !== 8) &&
              (<RemoveDeviceButton deviceId={deviceId} openState={openState} dispatchDeviceId={dispatchDeviceId} handleCancel={handleCancel} />)
            }
          </Grid>

          <Grid item xs alignItems={'center'} sx={{ flex: 1, height: '100%' }}>
            {parseInt(statusCurrentState.get().id) !== 8 && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {statusLast.get() && statusLast.get().id != 8 ? (

                  <Button
                    fullWidth
                    size={'small'}
                    onClick={nextStatus}
                    variant="contained"
                    sx={{
                      height: '100%',
                      backgroundColor: getStatusColors(statusLast.get().id).main, color: getStatusColors(statusLast.get().id).contrastText,
                      '&:hover': { backgroundColor: getStatusColors(statusLast.get().id).dark, color: getStatusColors(statusLast.get().id).contrastText },
                    }}>
                    {statusList.get()[statusIndexLast.get()].name}
                  </Button>
                ) : null}

              </>
            )
            }
          </Grid>
          <Grid item xs={4} alignItems={'center'} sx={{ flex: 1, height: '100%' }}>
            {
              parseInt(statusCurrentState.get().id) !== 8 &&
              (
                <DeviceClosedStatusButton
                  dispatchId={dispatchId.get()}
                  dispatchDeviceId={dispatchDeviceId.get()}
                  handleCancel={handleCancel}
                />
              )
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }} >
        <Button
          onClick={handleCancel}
          variant='outlined'
        >
          {translate("Cancel")}
        </Button>
      </DialogActions>
      <DisplayDispatchVehicle open={isCrewDialog} deviceCode={deviceCode} deviceId={deviceId} />
    </Dialog>
  )
}