import React from 'react';
import { AppRouteProps } from '~/routes';
import Login from './pages/Login';
import LoginNoStation from './pages/LoginNoStation';

export default function AuthRoutes(): readonly AppRouteProps[]   {
  const routes: AppRouteProps[] = [
    { path: "/login", component: Login, isProtected: true },
    { path: "/login/no-station", component: () => <Login noStation />, isProtected: true }
  ];
  return routes;
}