import React, { useEffect } from 'react';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId, GridRowModel, GridRowParams, GridValueFormatter, MuiEvent } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Tooltip } from '@mui/material';
import { formatDateHour } from '../../../../utils/format';
import { AltRoute } from '@mui/icons-material';
import { useLang } from '../../../../hooks/useLang';
import { useAuth } from '~/features/auth/hooks/useAuth';
import { authFetch } from '~/services/fetch';
import useDispatchState from '../../stores/DispatchState';
import { useState } from '@hookstate/core';
import useSystemState from '~/features/System/stores/SystemState';

export default function DispatchDisunification() {
  const [rows, setRows] = React.useState<GridRowModel[]>([]);
  const [open, setOpen] = React.useState(false);
  const [unificationId, setUnificationId] = React.useState<number>(0);
  const [dispatchUnifiedId, setDispatchUnifiedId] = React.useState<number>(0);
  const { translate } = useLang();
  const { windowManager } = useSystemState();
  const dispatchState = useDispatchState();  

  const handleClose = () => {
    setOpen(false);
  };

  const action = () => {
    dispatchState.disunificationDispatch({ unificationId: unificationId, dispatchUnifiedId: dispatchUnifiedId });
    windowManager()['DispatchDisunification'].open.set(false)
    setOpen(false);
  };

  const disunification = React.useCallback(
    (row: any) => () => {
      setUnificationId(row.id);
      setDispatchUnifiedId(row.dispatchUnifiedId)
      setOpen(true);
    }, [],
  );

  const columns = React.useMemo(
    () => [
      { field: 'id', headerName: translate('Id') },
      { field: 'code', headerName: translate('Code'), flex: 1 },
      { field: 'location', headerName: translate('Location'), flex: 1 },
      { field: 'dispatchUnifiedId', headerName: translate('Id') },
      {
        field: 'unified_at', headerName: translate('Date of unification'), flex: 1.2,
        valueGetter: (value) => {
          return formatDateHour(value as string).toFormatedDate;
        },
      },
      { field: 'type', headerName: translate('Type'), flex: 1 },
      { field: 'subtype', headerName: translate('Type'), flex: 1 },
      {
        field: 'actions', type: 'actions',
        flex: 0.3,
        getActions: ({id, row}) => [
          <GridActionsCellItem
            key={id}
            icon={
              <Tooltip title={translate('Disunify')}>
                <AltRoute />
              </Tooltip>
            }
            label='export'
            onClick={disunification(row)}
          />,
        ]
      },
    ],
    [disunification, dispatchState.dispatch().id],
  );

  useEffect(() => {
    authFetch({
      url: `/dispatch/current-unifications`,
      method: 'POST',
      data: {
        dispatch_id: dispatchState.dispatch().id.get()
      }
    })
      .then(({ data: { data, rowsCount } }) => {
        setRows(data.map((row) => {
          return {
            id: row?.id,
            code: row?.dispatch_origin?.code,
            location: row?.dispatch_origin?.occurrence?.location,
            dispatchUnifiedId: row?.dispatch_origin?.id,
            unified_at: row?.unified_at,
            type: row?.dispatch_origin?.type?.name,
            subtype: row?.dispatch_origin?.subtype?.name,
          }
        }));
      })
      .catch(err => err);
  }, [dispatchState.dispatch().id.get()]);


  return (
    <Grid item md>
      <DataGrid
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={rows}
        columns={columns as GridColDef[]}
        columnVisibilityModel={{
          id: false,
          dispatchUnifiedId: false,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        
        pageSizeOptions={[5]}
        disableColumnSelector={true}
        sx={{
          height: 371,
          boxShadow: 2,
          '& .MuiDataGrid-cell:hover': {
            color: 'primary',
          },
        }}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          {translate('Do you want to de-unify the dispatch from the current dispatch?')}
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={handleClose}
          >
            {translate('Cancel')}
          </Button>
          <Button
            variant='contained'
            onClick={action}
          >
            {translate('Submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
