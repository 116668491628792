import React, { useEffect } from 'react';
import Layout from '~/components/Layout';
import DispatchListAdvanced from '../components/DispatchListAdvanced';
import { useLang } from '~/hooks/useLang';


export default function List() {
  const { translate } = useLang();

  useEffect(() => {
    document.title = translate('Dispatch List - Dispatch System')
  }, [])
  return (
    <Layout marginLayout={true}>
      <DispatchListAdvanced />
    </Layout>
  );
}