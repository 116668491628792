import React from 'react';
import useMessageState from '../../../../stores/MessageState';
import { Grid, Button, Typography, Paper } from '@mui/material';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { formatDateHour } from '~/utils/format';

export default function CurrentMessage() {
  const { currentMessage, isWritingMessage, newMessage, currentListMessage } = useMessageState()
  return (
    <Grid container columns={12} direction={'column'} sx={{ height: '100%', width: '100%' }}>
      <Grid item md={1} xs={1} sx={{ marginBottom: 1 }} >
        <Paper variant='outlined' sx={{ width: '100%', height: '100%', padding: 1 }}>
          <Grid container alignItems="center">

            <SupervisorAccountIcon sx={{ marginLeft: 2, marginRight: 2 }} />
            <Grid item xs>
              <Typography gutterBottom color="text.secondary" variant="body2" align="left" component="div">
                De: {currentMessage().sender.representationName.get()}
              </Typography>
              <Typography color="text.secondary" variant="body2" align="left" component="div">
                Para: {currentMessage().receiver.representationName.get()}
              </Typography>
            </Grid>

            <Grid item>
              <Typography gutterBottom color="text.secondary" variant="body2" align="right" component="div">
                {formatDateHour(currentMessage().messageDate.get() as string).toFormatedDate.slice(0, 10)}
              </Typography>
              <Typography color="text.secondary" variant="body2" align="right" component="div">
                {formatDateHour(currentMessage().messageDate.get() as string).toFormatedDate.slice(12, 17)}
              </Typography>
            </Grid>
          </Grid>

        </Paper>
      </Grid>
      <Grid item md sx={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
        <Paper variant='outlined' sx={{ padding: 2, width: '100%', minHeight: '100%', wordWrap: 'break-word' }}>
          <Typography  >{currentMessage().message.get()}</Typography>
        </Paper>
      </Grid>

      { currentListMessage().get() == 'received' ? 
        <Button
        sx={{ position: 'absolute', bottom: 17, right: 190}}
        variant="contained"
        size="small"
        color="primary"
        onClick={() => { 
          isWritingMessage().set(true);
          newMessage().receiver.set({ id: currentMessage().sender.id.get(), name: currentMessage().sender.name.get() })
        }}
      >
        {'Responder'}
      </Button>
      : false } 
      
      <Button
        sx={{ position: 'absolute', bottom: 17, right: 30}}
        variant="contained"
        size="small"
        color="primary"
        onClick={() => { 
          isWritingMessage().set(true);
          newMessage().receiver.set({ id: null, name: '' })
        }}
      >
        {'Nova mensagem'}
      </Button>
    </Grid >
  )
}