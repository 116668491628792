import React from "react";
import { Box, Button, IconButton } from "@mui/material";
import { State } from "@hookstate/core";
import { useLang } from "~/hooks/useLang";
import useUserState from "~/features/User/stores/UserState";
import { IListVehicleSocket } from "../interfaces/IListVehiclesProps";
import { FilterNoneOutlined, LibraryAddCheckOutlined, MobileOff } from "@mui/icons-material";

interface DisconnectMultipleButtonProps {
  readonly tab: string;
  readonly rowsSelected: readonly IListVehicleSocket[];
  readonly enableCheckbox: boolean;
  readonly enableButtonShowResume: boolean;
  readonly openModalResultMultipleDisconnectVehicle: State<boolean>;
  readonly handleClickOpen: () => void;
  readonly handleCheckBox: () => void;
}

export default function DisconnectMultipleButton({ tab, rowsSelected, enableCheckbox, enableButtonShowResume, openModalResultMultipleDisconnectVehicle, handleClickOpen, handleCheckBox }: DisconnectMultipleButtonProps) {
  const { translate } = useLang();
  const { verifyPermission } = useUserState()

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 1,
        marginTop: 2
      }}
    >
      {
        (verifyPermission(['device_disconnect_multiple'])) &&
        tab === 'available' &&
        window.location.pathname === '/dispatch/vehicles/list-full' &&
        <>
          <IconButton
            onClick={() => handleCheckBox()}
            title={enableCheckbox ? translate('Disable Select') : translate('Enable Select')}
            size="large"
          >
            {enableCheckbox ? <LibraryAddCheckOutlined fontSize='small' /> : <FilterNoneOutlined fontSize='small' />}
          </IconButton>
          <IconButton
            title={translate('Disconnect')}
            disabled={!rowsSelected.length || !enableCheckbox}
            onClick={() => handleClickOpen()}
            size="large"
          >
            <MobileOff fontSize='small' />
          </IconButton>
        </>
      }
      {enableButtonShowResume &&
        window.location.pathname === '/dispatch/vehicles/list-full' &&
        <Button
          variant='text'
          onClick={() => openModalResultMultipleDisconnectVehicle.set(true)}
          sx={{
            '&:hover': {
              textDecoration: 'underline',
              background: "none",
            },

          }}
        >
          {translate('Show resume disconnect multiple devices')}
        </Button>
      }
    </Box>
  )
}