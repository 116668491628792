import React, { useEffect } from 'react';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId, GridRowModel, GridRowParams, GridValueFormatter, MuiEvent } from '@mui/x-data-grid';
import { ptBR } from '@mui/x-data-grid/locales';
import { formatDateHour } from '~/utils/format';
import { useTranslation } from 'react-i18next'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import useEntryState from '../../stores/EntryState';
import useEntryCommentState from '../../stores/EntryCommentState';

type ListEntryPopupParams = {
  readonly loading: boolean;
  readonly rows: GridRowModel[];
  readonly onRowClick?: (rows: GridRowParams, 
            event: MuiEvent<React.MouseEvent<HTMLElement>>) => boolean | undefined
}

export default function ListEntryPopupTable({rows, onRowClick = (() => true), loading} : ListEntryPopupParams) {
  const translate = useTranslation().t;
  const entryState = useEntryState();  

  const exportLocation = React.useCallback( (row: any) => {
      entryState.getEntryFromId(row.id);
    }, [] );

  const columns = React.useMemo(
    () => [
    { field: 'actions', type: 'actions',flex: 0.4,
      getActions: ({id, row}) => [
        <GridActionsCellItem
            key={id}
            icon={<FileUploadIcon />}
            label="export"
            onClick={() => exportLocation(row) }
          />,
      ]
    }, 
    { field: 'id', headerName: translate('Code'), flex: 0.4,},
    { field: 'name', headerName: translate('Name'), flex: 1},
    { field: 'location', headerName: translate('Location'), flex: 1.5,},
    { field: 'typename', headerName: translate('Type'), flex: 1,},
    { field: 'subtypename', headerName: translate('Subtype'), flex: 1},
    { field: 'phone', headerName: translate('Phone'), flex: 1,},
    { field: 'is_prank_call', headerName: translate('Prank call')},
    {
      field: 'created_at', headerName: 'Data', flex: 0.8,
      valueGetter: (value) => {
        return formatDateHour(value as string).toFormatedDate;
      },
    },
  ],
  [exportLocation],
  );

  return (
    <DataGrid
      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      rows={rows}
      columns={columns as GridColDef[]}
      columnVisibilityModel={{
        is_prank_call: false,
      }}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      disableColumnMenu
      autoHeight={true}
      onRowClick={onRowClick}
      disableColumnSelector={true}
      loading={loading}
      slotProps={{
        row: {
          style: { cursor: 'pointer'},
        }
      }}
      sx={{
        '& .danger': { background: '#E0473D'},
        '& .danger: hover': { background: '#C73F36'},
      }}
      getRowClassName={(params) => (params.row.is_prank_call) ? `danger`: ''}
    />
  )
}
