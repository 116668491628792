import Cookies from 'universal-cookie';
import IStation from '../interfaces/IStation';
import { AxiosError } from 'axios';
import ICredentialsType from '~/features/Station/interfaces/ICredentialsType';
import api from '~/features/auth/services/api';
import { IUpdateProps } from '~/interfaces';
import { authFetch } from '~/services/fetch';
import constants from '~/core/constants';

const cookies = new Cookies();

export default async function activateStation({ login, password, authenticationKey }: ICredentialsType) {
    try {
        const response = await api('/station/auth', {
            method: 'POST',
            data: {
                login,
                password,
                authenticationKey
            }
        });
        return response.data;
    } catch (error) {
        const err = error as AxiosError;
        throw err.response?.data?.error || err.response?.data?.message || err.message;
    }
}

export async function getStationById(id: IUpdateProps): Promise<IStation> {
    const response = await authFetch({
        url: `/station/${id}`,
        method: 'GET'
    });
    return response.data as IStation;
}

export async function restartStation({ stationId }) {
    try {
        const { data } = await authFetch({
            url: '/station/auth/key/refresh',
            method: 'POST',
            data: {
                station_id: stationId
            }
        })

        return data;

    } catch (error) {
        throw error
    }
}

export async function clearWebAnonymousStation() {

    return Promise.all([
        cookies.remove(constants.keys.token, { path: '/' }),
        cookies.remove(constants.keys.cookies.identificationKey, { path: '/' }),
        cookies.remove(constants.keys.cookies.anonymousIdentificationKey, { path: '/' })
    ]);
}