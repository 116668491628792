import { authFetch } from '~/services/fetch';
import { t as translate } from 'i18next';
import notify from '~/utils/notify';
import { Dayjs } from 'dayjs';

export async function changePasswordService({login, password, repassword, isMe}){
  try {
     const {status} = await authFetch({
      url: isMe ? '/auth/login/change-password/me' : '/auth/login/change-password' ,
      method: 'PUT',
      data: {
          login: login,
          password: password,
          repassword: repassword
      },
    })

    if(status == 200){
      notify({
        message: `${translate('Password Changed Successfully')}!`,
        type: 'success'
      });
    };
    
    return status;

  } catch (error) {
    console.error('Error Change Password');
    throw error
  }
}

export async function getUsers(){
  try {
    const { data } = await authFetch({
      url: '/user',
        method: 'POST'
    })
  
    return data.data;
  } catch (error) {
    throw error
  }
}

export async function searchInvolvement({
  cpf, 
  name,
  motherName,
  otherDocumentTypeId,
  otherDocument, 
  birthDate,
  page, 
  limit
}:{cpf?: string, name?:string | null, motherName?:string | null, otherDocumentTypeId?:number | null, otherDocument?: string | null, birthDate?: string | null, page?:number, limit?:number}) {
  const targetUrl = '/occurrence/associated-people/find-involved'

      const response = await authFetch({
        url: targetUrl,
        data: {
          cpf,
          page: page, 
          limit: limit,
          name: name,
          mother_name: motherName,
          other_document_type_id: otherDocumentTypeId,
          other_document: otherDocument,
          birth_date: birthDate
        }
      })
      return response.data;  
}