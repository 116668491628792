import { authFetch } from '~/services/fetch';
import { parseUserDataMessage } from '../utils/parse';

export async function syncMessageReceived(messageReceived, messagesNotReadCount){
  syncMessagesNotReadCount(messagesNotReadCount);
  
  return await authFetch({
    url: '/message/user',
    data: {
      limit: 10, 
    }
  }).then((response) => {

    const parsedMessage = response.data.data.map((message) => parseUserDataMessage(message))
    messageReceived.set(parsedMessage)
  })
}

export async function syncMessageSent(messageSent){
  return authFetch({
    url: '/message/sent-by-user',
    data: {
      limit: 10, 
    }
  }).then((response) => { 
    const parsedMessage = response.data.data.map((message) => parseUserDataMessage(message))
    messageSent.set(parsedMessage) 
  })
}

export async function syncMessagesNotReadCount(messagesNotReadCount){
  return authFetch({
    url: `/message/user/not-read`,
    method: 'GET',
  }).then((response) => { 
    return messagesNotReadCount.set(response.data.total)
  })
}