import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { getDevices } from '../FilterAutocompleteDevice/service';
import { DeviceResponse } from '../FilterAutocompleteDevice/interface';

interface DevicesProps {
  readonly onDevicesChange: (value: number[]) => void;
  readonly devicesIds?: readonly number[];
  readonly disabled?: boolean;
  readonly groupDispatchId?: number | null | readonly number[];
}

export default function FilterAutocompleteDevices({ onDevicesChange, devicesIds, disabled, groupDispatchId }: DevicesProps) {
  const { translate } = useLang();
  const [options, setOptions] = useState<readonly DeviceResponse[]>([]);
  const [optionsFilter, setOptionsFilter] = useState<readonly DeviceResponse[]>([]);
  const [value, setValue] = useState<DeviceResponse[]>([]);
  const loading = useHookstate(false);

  useEffect(() => {
    getDevices().then(devices => {
      setOptions(devices)
      setOptionsFilter(devices)
      setValue([])
    })
  }, [])

  useEffect(() => {
    if (devicesIds?.length) {
      setValue(optionsFilter?.filter((option) => devicesIds.includes(option.id)))
    } else {
      setValue([])
    }
  }, [devicesIds])

  useEffect(() => {
    setOptionsFilter(options as any)


  }, [options])

  useEffect(() => {
    if (Array.isArray(groupDispatchId) && groupDispatchId.length){
      const filter = options?.filter(deviceMap => groupDispatchId.includes(deviceMap.dispatch_group_id))
      setOptionsFilter(filter?.length ? filter : [])
    } else if (Number.isInteger(groupDispatchId) && groupDispatchId as number > -1) {
      const filter = options?.filter(deviceMap => deviceMap.dispatch_group_id === groupDispatchId)
      setOptionsFilter(filter?.length ? filter : [])
    } else {
      setOptionsFilter(options)
    }
  }, [groupDispatchId, options])

  return (
    <FormControl fullWidth size='small' >
      <Autocomplete
        id='filter-devices'
        disableCloseOnSelect
        filterSelectedOptions
        options={optionsFilter ?? []}
        disabled={disabled}
        value={value}
        multiple
        noOptionsText={translate('No options')}
        clearText={translate('Clear')}
        size='small'
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name ?? ''}
        onChange={(_, type) => {
          setValue(type)
          onDevicesChange(type.map(type => type.id) ?? [])
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate('Devices')}
          />
        )}
      />
    </FormControl>
  )
}