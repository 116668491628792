import React, { useCallback, useEffect, useState } from 'react';
import useDispatchRegistrationPersonState, { personDefaultState } from '../../stores/DispatchRegistrationPersonState';
import useDispatchRegistrationState from '../../stores/DispatchRegistrationState';
import useDispatchState from '../../stores/DispatchState';
import ListRegistrations from '../DispatchRegistrationTab/components/ListRegistrations';
import { useLang } from '~/hooks/useLang';
import { GridActionsCellItem, GridColDef, GridValueGetter } from '@mui/x-data-grid';
import { truncate } from '../../utils/stringsManipulations';
import FormPeople from './components/FormPeople';
import { Tooltip } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { saveLogVisualizeQualificators } from '../../services';
import useSocket from '~/hooks/useSocket';
import { IPersonDTO, IRawPerson } from '../../interfaces/IDispacthRegistrationPerson';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toDomain } from '~/mappers/registration-people-mapper';
import notify from '~/utils/notify';
import { useHookstate } from '@hookstate/core';
interface IPerson {
  readonly handleClickOpenConfirmAction: (actionName: string) => void;
}
interface IMessagePerson {
  readonly content: IRawPerson;
  readonly event_type: string

}
// eslint-disable-next-line max-lines-per-function
export default function Person({ handleClickOpenConfirmAction }: IPerson) {
  const { translate } = useLang();
  const loading = useHookstate<boolean>(false)
  const { socket } = useSocket();
  const { dispatch } = useDispatchState();
  const { occurrence, status, id: dispatchIdState } = dispatch();
  const { id: occurrenceIdState, } = occurrence;

  const { registrationDispatch } = useDispatchRegistrationState();

  const { getAllAssociatedPerson, registrationPersonDispatch, sendPerson, editPerson, deletePerson } = useDispatchRegistrationPersonState();
  const { people, person } = registrationPersonDispatch();

  const { addRegistration, loadingRegistrations, registrationRowId, deleteRegistration } = registrationDispatch();

  const [rows, setRows] = useState<readonly IPersonDTO[]>([])



  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: translate('Id'),
      flex: 0.5,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'name',
      headerName: translate('Name'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'involvementType',
      headerName: translate('Involvement Type'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'peopleCircunstance',
      headerName: translate('People Circunstance'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'destination',
      headerName: translate('Destination'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'notes',
      headerName: translate('Note'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'actions', type: 'actions', flex: 1,
      getActions: (params) => [
        status.value.id === 8 ? <Tooltip key={2} title={translate('View Record')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<RemoveRedEyeIcon />}
              onClick={() => {
                saveLogVisualizeQualificators(params.row.id, '/occurrence/associated-people/generate-view-timeline').then((response) => {
                  if (response.status === 200) {
                    person.set(params.row);
                    addRegistration.set(true)
                  }
                })
              }}
              label="Print" />
          </span>
        </Tooltip> : <></>,
        status.value.id !== 8 ? <Tooltip key={2} title={translate('Edit Record')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<EditIcon />}
              onClick={() => {
                person.set(params.row);
                addRegistration.set(true)
              }}
              label="Print" />
          </span>
        </Tooltip> : <></>,
        status.value.id !== 8 ? <Tooltip key={2} title={translate('Delete Record')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<DeleteIcon />}
              onClick={() => {
                registrationRowId.set(params.row.id);
                handleClickOpenConfirmAction('delete')
              }}
              label="Print" />
          </span>
        </Tooltip> : <></>
      ]
    },
  ]


  const handleAdd = useCallback(() => {
    person.set({ ...personDefaultState, occurrenceId: occurrenceIdState.get() });
    addRegistration.set(true)
  }, [addRegistration, occurrenceIdState, person])

  const handleDelete = useCallback(async (id: string) => {
    loadingRegistrations.set(true)
    await deletePerson(id)
    notify({ message: translate('Successfully Deleted'), type: 'success' })
    loadingRegistrations.set(false);
    deleteRegistration.set(false);
  }, [deletePerson, deleteRegistration, loadingRegistrations, translate])

  const handleSave = useCallback(async () => {
    loading.set(true)
    await sendPerson(person.get()).finally(() => {
      loading.set(false)
    })
    notify({ message: translate('Successfully created'), type: 'success' })
    addRegistration.set(false)
  }, [addRegistration, person, sendPerson, translate])

  const handleEdit = useCallback(async () => {
    loading.set(true)
    await editPerson(person.get()).finally(() => {
      loading.set(false)
    })
    notify({ message: translate('Saved successfully'), type: 'success' })
    addRegistration.set(false)
  }, [addRegistration, editPerson, person, translate])

  const newMessage = useCallback((message: IMessagePerson) => {
    if (message.event_type === 'OCCURRENCE_ASSOCIATED_PEOPLE_CREATE') {
      if (message.content.dispatch_id !== dispatchIdState.get()) {
        return
      }
      setRows(prev => [...prev, toDomain(message.content)])
    }
    if (message.event_type === 'OCCURRENCE_ASSOCIATED_PEOPLE_DELETE') {
      const filterRows = rows.filter(row => row.id !== message.content.id)
      setRows(filterRows);
    }
  }, [rows])

  useEffect(() => {
    socket.on('occurrences', newMessage)
    return () => {
      socket.off('occurrences', newMessage)
    }
  }, [newMessage, socket])

  useEffect(() => {
    loadingRegistrations.set(true);
    if (occurrenceIdState?.get()) {
      getAllAssociatedPerson(occurrenceIdState.get())
        .then((res) => {
          setRows(res)
          people.set(res)
        })
        .finally(() => {
          loadingRegistrations.set(false)
        });
    }
  }, [occurrenceIdState?.get()]);

  useEffect(() => {
    if (deleteRegistration.get()) {
      handleDelete(registrationRowId.get())
    }
  }, [deleteRegistration, handleDelete, registrationRowId])

  return (
    addRegistration.get() ? (
      <FormPeople
        loading={loading}
        handleSave={handleSave}
        handleEdit={handleEdit}
      />
    ) : (
      <ListRegistrations
        handleAdd={handleAdd}
        columns={columns}
        rows={rows}
      />
    )
  )
};