import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import { useHookstate } from '@hookstate/core';
import TransferGroupDispatchDialog from '~/features/Dispatch/components/TransferGroupDispachButton/components/TransferGroupDispatchDialog';

export default function TransferGroupDispachButton({ canTransferGroupDispatch, hasTransferGroupDispach }) {
  const open = useHookstate<boolean>(false);
  const { translate } = useLang()

  return (
    <Grid item>
      <IconButton title={translate('Transfer by Dispatch Group')} disabled={!hasTransferGroupDispach} onClick={() => open.set(true)}>
        <MoveUpIcon />
      </IconButton>
      {open.get() && <TransferGroupDispatchDialog open={open}/>}
    </Grid>
  );
}