import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IType from '../interfaces/ICamera';
import Layout from '~/components/Layout';


export default function Create() {
  const { register, handleSubmit, control, setValue: setFormValue } = useForm<IType>();
  const fields = [
    { name: 'name', type: 'string' },
    { name: 'code', type: 'string' },
    { name: 'priority', type: 'select', options: [1, 2, 3, 4, 5, 6, 7, 8, 9] },
  ];

  return (
    <Layout marginLayout={true}>
      <CrudCreate model={'camera'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Camera successfully created!'}
        title={'Add camera'}
        subheader={'Add camera'}
        setFormValue={setFormValue} />
    </Layout>
  )
}
