import React from 'react';
import { Card, CardContent, CardHeader, IconButton, SxProps } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '~/components/ErrorFallback';
import { useHookstate } from '@hookstate/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface InternalContentProps {
  readonly title?: string,
  readonly subheader?: string,
  readonly expandButton?: boolean,
  readonly children: React.ReactNode,
  readonly alreadyExpand?: boolean,
  readonly sxFont?: object
  readonly bold?: boolean
  readonly actions?: React.ReactNode,
}

export default function InternalContent({ title, subheader, expandButton = true, children, alreadyExpand = true, sxFont={}, bold=false, actions }: InternalContentProps) {
  const expandContent = useHookstate(alreadyExpand);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Card sx={{ boxShadow: 4, marginBottom: 2 }}>

        {expandButton ? (
          <CardHeader subheader={subheader ?? ''} title={`${title ? title : ''}`} avatar={
            <IconButton
              onClick={() => expandContent.set(!expandContent.get())}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                marginRight: 1, padding: 0
                
              }}>
              {expandContent.get() ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
            </IconButton>} action={actions} 
            titleTypographyProps={sxFont}
            />

        ) : null}

        <CardContent hidden={!expandContent.get()}>
          {children}
        </CardContent>
      </Card>
    </ErrorBoundary>
  )
}