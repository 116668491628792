import React from 'react'
import { Grid, FormControl, OutlinedInput, InputAdornment, IconButton, FormHelperText } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { useLang } from "~/hooks/useLang";

export default function FilterKeyword({keyWords, label = 'Keywords'}) {
    const { translate } = useLang();
  
    return (
        
        <FormControl variant="standard" fullWidth>
            <OutlinedInput
            id="outlined-search"
            value={keyWords.get()}
            size='small'
            placeholder={translate(label)}
            onChange={(event) => {
                keyWords.set(event.target.value)
            }}
            endAdornment={
                <InputAdornment position="end">
                <IconButton
                    aria-label="toggle clear search"
                    onClick={() => {
                    keyWords.set('')
                    }}
                    edge="end"
                >
                    {keyWords.get().length ? <CancelIcon fontSize='small' /> : ''}
                </IconButton>
                </InputAdornment>
            }
            />
            <FormHelperText variant="standard">
            {translate('Use ; to separate the keywords')}
            </FormHelperText>
        </FormControl>
      
    )
  }