import IDispatchGroup, { IDispatchGroupBasic } from '~/features/DispatchGroup/interfaces/IDispatchGroup';
import { IParamsTable } from '~/interfaces';
import { IUpdateProps } from '~/interfaces';
import { authFetch } from '~/services/fetch';

export async function getDispatchGroups({
    filters = [],
    limit = 100000,
    orders = []
}: IParamsTable = {}): Promise<readonly IDispatchGroup[]> {
    const response = await authFetch({
        url: `/dispatch/group`,
        method: 'POST',
        data: { 
            limit,
            filters,
            orders
        },
        
    });
    return response.data.data;
    
}

export async function getDisptachGroupById(id: IUpdateProps): Promise<IDispatchGroupBasic> {
    const response = await authFetch({
        url: `/dispatch/group/${id}`,
        method: 'GET' 
    });
    return response.data as IDispatchGroupBasic;
}

export async function getAllDisptachGroup(): Promise<readonly IDispatchGroupBasic[]> {
    const response = await authFetch({
        url: '/dispatch/group/',
        method: 'GET' 
    });
    return response.data;
}

export async function getDisptachGroupByGroupsToTransfer({dispatchGroupId}) {
    try {
        const response = await authFetch({
            url: `/dispatch/group/${dispatchGroupId}/groups-to-transfer`,
            method: 'GET' 
        });
        return response.data;
    } catch (error) {
        throw error
    }
}