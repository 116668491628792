/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { OperationUnitResponse } from './interface';
import { getOperationUnit } from './services';


interface OperationUnitProps {
    readonly onOperationUnitChange: (value: number | null) => void;
    readonly operationUnitId?: number | null;
    readonly disabled?: boolean
}

export default function FilterAutocompleteOperationUnit({ disabled, onOperationUnitChange, operationUnitId }: OperationUnitProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly OperationUnitResponse[]>([]);
    const [value, setValue] = useState<OperationUnitResponse | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        getOperationUnit().then(operationUnit => {
            setOptions(operationUnit)
            setValue(null)
        })
    }, [])

    useEffect(() => {
        if (!operationUnitId) {
            setValue(null)
        } else {
            setValue(options.find(opt => opt.id === operationUnitId) ?? null)
        }
    }, [operationUnitId])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                id='filter-operation-unit'
                limitTags={2}
                disabled={disabled}
                sx={{ height: 2 }}
                options={options}
                value={value}
                noOptionsText={translate('No options')}
                clearText={translate('Clear')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={(_, operationUnit) => {
                    setValue(operationUnit)
                    onOperationUnitChange(operationUnit?.id ?? null)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Operation Unit')}
                    />
                )}
            />
        </FormControl>
    )
}