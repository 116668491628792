import IEntry from '../interfaces/IEntry';

export function formatForSending(entry: IEntry){
  return {
    id: entry.id,
    name: entry.name,
    phone: entry.phone,
    address: entry.address,
    address_reference: entry.reference,
    location: entry.location,
    crossing_street: entry.crossingStreet,
    coordinates: entry.coordinates,
    latitude: entry.latitude,
    longitude: entry.longitude,
    entry_origin_id: entry.entryOriginId,
    state_id: entry.stateId,
    city_id: entry.cityId,
    district_id: entry.districtId,
    created_at: entry.createdAt,
    type_id: entry.typeId,
    subtype_id: entry.subtypeId,
    vehicles: entry.vehicle.circunstanceId ? [
      { 
        plate: entry.vehicle.plate,
        circunstance_id: entry.vehicle.circunstanceId,
        external_key_tracking: entry.vehicle.externalKeyTracking,
      }
    ] : []
  }
};