import { useHookstate } from '@hookstate/core';
import React, { useEffect } from 'react';
import CrudIndex from '~/components/CrudIndex';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';


export default function Index() {  
  const { translate } = useLang();
  const specie = useHookstate<string | null>(null)
  const forceRefresh = useHookstate<boolean>(false);
  const lockFields = useHookstate<boolean>(false);

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])      

  const specieFilter = [
    {
      type: 'name',
      variable: specie,
      placeholder: 'Specie'
    }
  ]


    return(
      <Layout marginLayout={true}>
        <CrudIndex 
          model='specie' 
          title='Species'
          createTitleComplement='Specie'
          viewTitle='Specie'
          canView={true}
          showInViewMore={['name', 'created_at']}
          filters={specieFilter}
          searchButton={() => forceRefresh.set(!forceRefresh.get())}
          forceRefresh={forceRefresh.get()}
          clearFilters={() => {
            specie.set('')
            forceRefresh.set(!forceRefresh.get())
          }} 
          disableColumnsFilters={true}
          lockFields={lockFields.get()}
        />
      </Layout>
    )
}
