import React from 'react';
import { useHookstate } from '@hookstate/core';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import PaletteIcon from '@mui/icons-material/Palette';
import useSystemState from '~/features/System/stores/SystemState'
import { useLang } from '~/hooks/useLang';
import { capitalize, splitCamelCase } from '~/utils/strings';

export default function ColorInfoButton() {
  const { translate } = useLang();
  const { colors } = useSystemState();
  const openState = useHookstate(false);
  const { tlia, ...withoutTlia } = colors.status;

  const handleOpen = () => {
    openState.set(true);
  };

  const handleCancel = () => {
    openState.set(false);
  };

  const itemColor = (text: string, color: string, key = -1, acronym?: string) => {

    return (
      <ListItem key={key}>
        <ListItemButton>
          <ListItemIcon>
            <Box component="span" sx={{ bgcolor: color, width: 20, height: 20, borderRadius: '50%' }} />
          </ListItemIcon>
          <ListItemText 
            primary={`${translate(capitalize(splitCamelCase(text)))}` + `${acronym ? ' | ' + acronym : ''}`} 
          />
        </ListItemButton>
      </ListItem>
    )
  }

  return (
    <>
      <Tooltip title={translate('Color System')} placement="bottom">
        <IconButton type="submit" size="large" onClick={handleOpen}>
          <PaletteIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={openState.get()} onClose={handleCancel} fullWidth={true}>
        <DialogTitle >
          <Typography>
            {translate('Color System')}
          </Typography>
        </DialogTitle>
        <DialogContent>

          <Typography>
            {translate('Status')}
          </Typography>
          {Object.keys(withoutTlia).map((value, index) => itemColor(value, colors.status[value].main, index, colors.status[value].acronym))}

          <Typography>
            {translate('Device Status')}
          </Typography>
          {Object.keys(colors.deviceStatus).map((value, index) => itemColor(value, colors.deviceStatus[value].main, index,  colors.deviceStatus[value].acronym))}

          <Typography>
            {translate('Priority')}
          </Typography>
          {Object.keys(colors.priority).map((value, index) => itemColor(value, colors.priority[value].main, index))}

        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleCancel}>
            {translate('Close')
            }</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}