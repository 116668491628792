/* eslint-disable max-lines-per-function */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from '@mui/material'
import ErrorFallback from '~/components/ErrorFallback'
import Layout from '~/components/Layout'
import Loading from '~/components/Loading'
import { useLang } from '~/hooks/useLang'
import notify from '~/utils/notify'
import { authFetch } from '~/services/fetch'
import useReportState from '../../stores/ReportState'
import {
  downloadReportCSV,
  makeRequestToGetReporDevicesByStatus,
  makeRequestToGetReporTotalConnectedDevices,
} from '../../services/report'
import { useHookstate } from '@hookstate/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups'
import FilterAutocompleteAgencies from '~/components/FilterAutocompleteAgencies'
import FilterSelectMoment from '~/components/FilterAutocompleteMoment'
import GenerateReportButton from '../../components/GenerateReportButton'
import ExportButtonCSV from '../../components/ButtonExportCSV'
import FilterAutocompleteDispatchGroupSet from '~/components/FilterAutocompleteDispatchGroupSet'

export default function TotalConnectedDevices() {
  const { translate } = useLang()
  const isLoading = useHookstate<boolean>(false)
  const expandFilterSearch = useHookstate<boolean>(true)
  const momentDate = useHookstate<string | null>(null)
  const deviceId = useHookstate<number | null>(null)
  const lockFields = useHookstate<boolean>(false)
  const groupId = useHookstate<readonly number[]>([])
  const agencyIds = useHookstate<readonly number[]>([])
  const withOperationUnit = useHookstate<boolean>(false)
  const labelButton = useHookstate('')
  const reportName = 'connected-devices'
  const {
    reportData,
    loadingReport,
    dispatchGroups: { dispatchGroup },
  } = useReportState()
  const groupSetId = useHookstate<number | null>(null)

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])

  useEffect(() => {
    const getDispatchGroups = async () => {
      authFetch({
        url: '/station/dispatch-groups/me',
        method: 'GET',
        data: {},
      }).then((response) => {
        dispatchGroup.options.merge(response.data)
      })
    }

    getDispatchGroups()
  }, [])

  function generateReport() {
    if (momentDate) {
      loadingReport().set(true)
      isLoading.set(true)
      makeRequestToGetReporTotalConnectedDevices({
        momentDate: momentDate.get(),
        agencyIds: agencyIds.get(),
        dispatchGroupsId: groupId.get(),
        withOperationUnit: withOperationUnit.get(),
        groupSetId: groupSetId.get(),
      })
        .then((response) => {
          reportData().set(response)
          window.open(`/report/result/${reportName}`)
        })
        .finally(() => {
          loadingReport().set(false)
          isLoading.set(false)
        })
    } else {
      notify({
        message: translate('Initial and final date are mandatory'),
        type: 'error',
      })
    }
  }

  const exportCSV = async () => {
    if (momentDate) {
      isLoading.set(true)
      const dataCSV = await makeRequestToGetReporTotalConnectedDevices({
        momentDate: momentDate.get(),
        agencyIds: agencyIds.get(),
        dispatchGroupsId: groupId.get(),
        exportCSV: true,
        withOperationUnit: withOperationUnit.get(),
        groupSetId: groupSetId.get(),
      }).finally(() => isLoading.set(false))
      downloadReportCSV(
        `${translate(reportName)}-${new Date().getTime()}.csv`,
        dataCSV
      )
    } else {
      notify({
        message: translate('Initial and final date are mandatory'),
        type: 'error',
      })
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true}>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader
                title={`${translate('Report')}: ${translate(
                  `Total connected devices`
                )}`}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader
            title={`${translate('Filters')}`}
            avatar={
              <IconButton
                onClick={() =>
                  expandFilterSearch.set(!expandFilterSearch.get())
                }
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginRight: 1,
                  padding: 0,
                }}
              >
                {expandFilterSearch.value ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            }
          />
          <Collapse in={expandFilterSearch.get()}>
            <Grid
              container
              xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
              }}
            >
              <Grid container direction={'row'} rowGap={2}>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteAgencies
                    disabled={lockFields.get()}
                    agenciesId={agencyIds.get()}
                    onAgenciesChange={(agency) => {
                      labelButton.set(translate('Search'))
                      agencyIds.set(agency)
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchGroupSet
                    disabled={lockFields.get()}
                    dispatchGroupSetId={groupSetId?.get() ?? undefined}
                    onGroupDispatchSetChange={(group) => {
                      groupSetId?.set(group)
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchsGroups
                    disabled={lockFields.get()}
                    agencyId={agencyIds.get() ?? undefined}
                    dispatcheGroups={groupId.get() ?? undefined}
                    onGroupDispatchChange={(group) => {
                      groupId.set(group)
                      deviceId.set(null)
                      labelButton.set(translate('Search'))
                    }}
                    groupSetId={groupSetId.get()}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterSelectMoment
                    onDateChange={(value) =>
                      momentDate.set(value?.toJSON() ?? null)
                    }
                    disabled={lockFields.get()}
                    date={momentDate.get()}
                    required
                    />
                </Grid>

                <Grid item sx={{ pl: 1, pt: 1 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                      label={translate('Include operating unit information')}
                      control={
                        <Switch
                          checked={withOperationUnit.get()}
                          onChange={() =>
                            withOperationUnit.set(!withOperationUnit.value)
                          }
                        />
                      }
                    />
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      agencyIds.set([])
                      groupSetId.set(null)
                      groupId.set([])
                      momentDate.set(null)
                      withOperationUnit.set(false)
                    }}
                    sx={{ height: '40px' }}
                  >
                    {translate('Clear Filters')}
                  </Button>
                  <ExportButtonCSV
                    isLoading={isLoading.get()}
                    dataToExport={exportCSV}
                  />
                  <GenerateReportButton
                    isLoading={isLoading.get()}
                    makeReport={generateReport}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary>
  )
}
