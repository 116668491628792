import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Divider, Fab, Checkbox, Grid, Fade } from '@mui/material'
import { useState } from "react";
import { State, useHookstate } from "@hookstate/core";
import { CardFooter } from "../Footer";
import { ISurveillanceReportingReponse } from "~/features/VideoMonitoring/interfaces/ISurveillanceReporting";
import { truncate } from '~/features/Dispatch/utils/stringsManipulations';
import { ExpandTextButton } from '~/components/ExpandTextButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useLocation } from 'react-router-dom';
import { useTheme } from '~/hooks/useTheme';


interface ReportCommentProps {
  readonly report: State<ISurveillanceReportingReponse>;
  readonly handleClickMenu: (event: any) => void;
  readonly showCheckbox: boolean;
}

export function ReportComment({ showCheckbox, report, handleClickMenu }: ReportCommentProps) {
  const { darkMode } = useTheme();
  const reportIsCompressed = useHookstate(false);
  const REPORTMAXLENGHT = 190;
  const REPORTLENGHT = report.notes.get()?.length ?? 0
  const { pathname } = useLocation();
  const isVideoMonitoringPage = pathname === '/video-monitoring';


  return (
    <Card
      variant="elevation"
      color="background.paper"
      elevation={2}
      sx={{
        width: '100%',
        cursor: "pointer",        
      }}
      onClick={() => report.checked.set(!report.checked.get())}
      
      >
      <CardContent sx={{ padding: 1 }}>
        {isVideoMonitoringPage && <Fab
          size="medium"
          onClick={handleClickMenu}
          sx={{
            position: 'absolute',
            top: '2px',
            right: '2px',
            transform: 'scale(0.6)',
            color: darkMode ? '#d8d8d8' : '#363636',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          }}>
          {!showCheckbox &&
          <MoreVertIcon fontSize='medium' />
          }
        </Fab >}
        <Grid container direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
          {showCheckbox && <Grid item xs={0.9}>
            <Fade in={showCheckbox}>
              <Checkbox checked={report.checked.get()} disabled={Boolean(report.occurrenceId.get())} onChange={(_, value) => {
                report.set(prev => ({
                  ...prev,
                  checked: value
                }))
               
              }} />
            </Fade>
          </Grid>}
          <Grid item xs={showCheckbox ? 11.1 : 12}>
            <Typography
              style={{
                wordWrap: "break-word",
                marginBottom: "0",
                whiteSpace: 'pre-line'
              }}
              fontWeight="400"
              paragraph={true}
            >
              {!reportIsCompressed.get() ? truncate(report.notes.get() ?? '', REPORTMAXLENGHT) :
                report.notes.get()}
              {REPORTLENGHT > REPORTMAXLENGHT && <ExpandTextButton
                isCompressed={reportIsCompressed.get()}
                handleCompressed={() => reportIsCompressed.set(!reportIsCompressed.get())}
              />}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardFooter 
        report={report.get()}
        />
    </Card >
  );
}
