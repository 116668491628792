import React, { useEffect } from 'react'
import { CardHeader, Grid, IconButton, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useLang } from '~/hooks/useLang'
import Map from '~/features/Map/components/Map'
import HeatMapDispatchLayer from '../../components/HeatMapDispatchLayer'
import { useDispatchDashboardState } from '../../stores/DispatchDashboardState'

export default function DispatchHotMap({
  agencyId,
  deviceId,
  operationId,
  dispatchGroupId,
  category,
  type,
  subtype,
  dateStart,
  dateFinish,
  thirdParty,
  refresh,
  consummated,
  statusIds,
  cityIds,
  groupSetId,
  location,
  closedStatusClassId,
  closedStatusId,
  qualifierOptions,
  expandFilterSearch,
  operationUnitId
}) {
  const { translate } = useLang()

  return (
    <Grid
      container
      xs={12}
      sx={{
        display: 'flex',
        paddingX: 3,
        paddingTop: 3,
        justifyContent: 'space-between',
      }}
    >
      <Grid item xs={12} hidden={!expandFilterSearch}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
          {translate('Dispatch Heat Map')}
        </Typography>
      </Grid>
      <Grid item xs={12} hidden={!expandFilterSearch}>
        <Map enableLayerControl={false}>
          <HeatMapDispatchLayer
            agencyId={agencyId}
            operationId={operationId}
            deviceId={deviceId}
            dispatchGroupId={dispatchGroupId}
            category={category}
            type={type}
            subtype={subtype}
            dateStart={dateStart}
            dateFinish={dateFinish}
            thirdParty={thirdParty}
            refresh={refresh}
            consummated={consummated}
            statusIds={statusIds}
            cityIds={cityIds}
            groupSetId={groupSetId}
            location={location}
            closedStatusClassId={closedStatusClassId}
            closedStatusId={closedStatusId}
            qualifierOptions={qualifierOptions}
            operationUnitId={operationUnitId}
          />
        </Map>
      </Grid>
    </Grid>
  )
}
