import React from "react";
import { IconButton, Grid } from '@mui/material';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { useLang } from '~/hooks/useLang';


export default function ListDispatchesButton({ listFullDispatchUrl }) {
  const { translate } = useLang()
  const ClickOpen = () => {
    window.open(listFullDispatchUrl, "list-despatches, width=auto, height=600, status = no");
  }

  return (
    <Grid item>
      <IconButton title={translate('See List Dispatches')} onClick={ClickOpen}>
        <PlaylistAddCheckIcon />
      </IconButton>
    </Grid>
  );

}