/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import StandardModal from '../../../../components/StandardModal'
import { GridRowParams, MuiEvent } from '@mui/x-data-grid'
import { useLang } from '../../../../hooks/useLang'
import useSystemState from '~/features/System/stores/SystemState'
import { storeScreenSettings } from '~/features/System/services'
import { Typography } from '@mui/material'
import ProcedureList, { ConfigProcedureListProps } from '~/components/ProcedureList'
import { Downgraded, useHookstate } from '@hookstate/core'
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState'
import ResultListDispatches from './components/ResultListDispatches'

const modal = document.getElementById('div-modal') as HTMLElement

export default function DispatchResultSearchesModalDraggable() {
  const { translate } = useLang()
  const { windowManager, loadingButton } = useSystemState()
  const { occurrence } = useOccurrenceState();

  const handleStop = (event, dragElement) => {
    event.stopPropagation()
    windowManager()['dispatchesResultSearches'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y,
    })
  }

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'DispatchResultSearchesModalDraggable',
        isOpen: windowManager()['dispatchesResultSearches'].open.get(),
        isMinimized: windowManager()['dispatchesResultSearches'].minimized.get(),
        positionX: windowManager()['dispatchesResultSearches'].positionX.get(),
        positionY: windowManager()['dispatchesResultSearches'].positionY.get(),
      })
    }
  }, [
    windowManager()['dispatchesResultSearches'].open.get(),
    windowManager()['dispatchesResultSearches'].minimized.get(),
    windowManager()['dispatchesResultSearches'].positionY.get(),
    windowManager()['dispatchesResultSearches'].positionX.get(),
  ])

  if (windowManager()['dispatchesResultSearches'].open.get()) {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() && (
          <StandardModal
            title={
              <Typography variant='subtitle1'>
               { translate('Dispatches')}
              </Typography>
            }
            dimensions={{
              width: 300,
              height: 400
            }}
            handleStop={handleStop}
            position={{
              x: windowManager()['dispatchesResultSearches'].positionX.get()
                ? Number(windowManager()['dispatchesResultSearches'].positionX.get())
                : 80,
              y: windowManager()['dispatchesResultSearches'].positionY.get()
                ? Number(windowManager()['dispatchesResultSearches'].positionY.get())
                : 141,
            }}
            closeButton={() => windowManager()['dispatchesResultSearches'].open.set(false)}
            isExpanded={windowManager()['dispatchesResultSearches'].minimized}
          >
            <ResultListDispatches occurrenceId={occurrence().id.get()} />
          </StandardModal>
        )}
      </>,
      modal
    )
  } else {
    return <></>
  }
}