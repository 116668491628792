import React, { useState, useEffect } from 'react';
import { AccountCircle } from '@mui/icons-material';
import { statusUserColor } from '~/features/User/utils/parse';
import { useLang } from '~/hooks/useLang';
import { IconButton, Menu, MenuItem, Badge, Tooltip, ListItemButton, ListItemIcon, ListItemText, List, ListItem, Collapse, Paper } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useAuth } from '~/features/auth/hooks/useAuth';
import { useHookstate } from '@hookstate/core';
import { getAgencyById } from '~/features/Agency/services/index';
import { IUpdateProps } from '~/interfaces';
import { authFetch } from '~/services/fetch';
import useUserState from '~/features/User/stores/UserState';
import useStationState from '~/features/Station/stores/StationState';
import useDispatchGroupState from '~/features/DispatchGroup/stores/DispatchGroupState';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GroupsIcon from '@mui/icons-material/Groups';
import userUserState from '~/features/User/stores/UserState';
import ChangeViewDispatchGroupContent from '../ChangeVeiwDispatchGroupContent';
import useEntryState from '~/features/Entry/stores/EntryState';
import notify from '~/utils/notify';
import IEntry from '~/features/Entry/interfaces/IEntry';


// eslint-disable-next-line max-lines-per-function
export default function UserButton() {
  const { translate } = useLang();
  const { logout } = useAuth();
  const { handleUpdateMyEntries, getEntryFromId } = useEntryState();
  const history = useHistory();
  const { dispatchGroupView } = useDispatchGroupState();
  const { user, connection } = useUserState();
  const { station } = useStationState();
  const userAgency = useHookstate('');
  const stationLabel = useHookstate('');
  const openColapse = useHookstate(false)
  const openModal = useHookstate(false);
  const { verifyPermission } = userUserState();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'menu-appbar' : '';


  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClickColapse = () => {
    openColapse.set(!openColapse.get());
  };

  const handleChangePasswordClick = () => {
    history.push('/user/change-password/me');
  }

  const getAgencyForUser = async () => {
    const agency = await getAgencyById(user().agency_id.get() as IUpdateProps);
    userAgency.set(agency ? agency['name'] : '');
  }

  const getDispatchGroupForUser = async () => {
    const targetUrl = '/station/dispatch-groups/me';
    const response = await authFetch({
      url: targetUrl,
      method: 'GET',
    })

    // let dispatchGroupViewMap = {}

    // response.data.map((item) => {

    //   if (item) {
    //     dispatchGroupViewMap = Object.assign(dispatchGroupViewMap, {
    //       [item['name']]: {
    //         id: item['id'],
    //         name: item['name'],
    //         is_visible: item['is_visible']
    //       }
    //     })
    //   }
    // });

    const dispatchGroupViewMap = response.data.reduce((acc, item) => {
      // eslint-disable-next-line functional/immutable-data
      acc[item['name']] = {
        id: item['id'],
        name: item['name'],
        is_visible: item['is_visible'],
        agency_id: item['agency']['id']
      }
      
      return acc;
    }, {})


    dispatchGroupView().set(dispatchGroupViewMap)
  }

  useEffect(() => {
    getAgencyForUser()
    getDispatchGroupForUser()
    stationLabel.set(station().get() != null ? station().get()?.name ?? '' : '')
  }, [])


  const handleOpenModal = () => {
    openModal.set(true);
    setAnchorEl(null);
  }

  const handleCloseDialog = () => {
    openModal.set(false)
    setAnchorEl(null);
  };

  const handleCloseModal = (event, reason) => {
    if (reason !== 'backdropClick') {
      openModal.set(false)
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <>
      <Tooltip title={translate('Logout')} placement="bottom">
        <IconButton
          onClick={handleMenu}
          size="large"
        >
          <Badge color={statusUserColor(connection().get())} overlap="circular" variant="dot">
            <AccountCircle />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        sx={{
          marginTop: '30px',
          zIndex: 9999
        }}
      >
        <MenuItem disabled>
          {user()?.name?.get()}
        </MenuItem>
        <MenuItem disabled>
          {`${translate('Station')}: ${stationLabel.get()}`}
        </MenuItem>

        <MenuItem disabled>
          {'Ag. ' + userAgency.get()}
        </MenuItem>
        {(verifyPermission(['dispatch_index'])) && (
          <Paper
            elevation={0}
            sx={{ borderRadius: 0 }}
          >
            <ListItemButton onClick={handleClickColapse}>
              <ListItemText primary={translate('Dispatch Groups visibles')} />
              {openColapse.get() ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={openColapse.get()} timeout="auto" >
              <List sx={{ padding: 0, maxHeight: 290, overflow: 'auto' }}>

                {Object.keys(dispatchGroupView().get()).map((element) => {
                  return (
                    !!dispatchGroupView()[element].is_visible.get() &&
                    <ListItem key={dispatchGroupView()[element].id.get()} sx={{ paddingX: 2, margin: 0 }}>
                      <ListItemText
                        id={`${dispatchGroupView()[element].id.get()}`}
                        primary={element}
                        sx={{ paddingX: 3 }}
                      />
                    </ListItem>
                  )
                })}
                {Object.keys(dispatchGroupView().get()).filter(item => dispatchGroupView()[item].is_visible.get()).length === 0 ?
                  <ListItem sx={{ paddingX: 3 }}>
                    <ListItemText primary={translate('No Dispatch Group visible')} />
                  </ListItem> : false
                }
              </List>
              <ListItemButton onClick={() => handleOpenModal()}>
                <ListItemIcon>
                  <GroupsIcon />
                </ListItemIcon>
                <ListItemText primary={translate('Change View Dispatch Group')} />
              </ListItemButton>
            </Collapse>
          </Paper>
        )}
        <ListItemButton onClick={handleChangePasswordClick} >
          <ListItemText primary={translate('Change Password')} />
        </ListItemButton>

        <ListItemButton disabled divider sx={{ padding: 0 }} />

        <ListItemButton onClick={() => {
          handleUpdateMyEntries().then((myEntries) => {
            if (myEntries.length && station().get()?.id ) {
            const lastEntry = myEntries.slice(-1)
            const idEntry = lastEntry[0].id

              notify({
                autoCloseDuration: 8000,
                message: translate('User has service(s) open. Unable to log out of the system', {number : myEntries.length}),
                clickableMessage: {
                  urlRedirection: '/entry',
                  action: () => getEntryFromId(idEntry),
                  hideButton: false,
                },
                type: 'error'
              })
            } else {
              logout(false)
            }
          })
        }
      
      }>
          <ListItemText primary={translate('Logout')} />
        </ListItemButton>
      </Menu>
      {
        openModal.get() ?
          <ChangeViewDispatchGroupContent
            handleClose={handleCloseDialog}
            handleCloseModal={handleCloseModal}
            openModal={openModal.get()}
          />
          : false
      }
    </>
  )
}