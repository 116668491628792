import React, { useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, Container, Grid, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useParams } from 'react-router-dom';
import HeaderSummary from '../components/Summary/HeaderSummary';
import TabSummary from '../components/Summary/TabSummary';
import IndicatorsContentSummary, { Data, Totals } from '../components/Summary/IndicatorsContentSummary';
import { random } from 'lodash';
import ColumnChart from '~/components/ColumnChart';
import PieChart from '~/components/PieChart';
import DetailsOccurrences from '../components/Summary/DetailsOccurrences';
import Signature from '../components/Summary/Signature';
import RegulatoryObservations from '../components/Summary/RegulatoryObservations';
import { getCoordenation, getDispatchByCity, getDispatchByDispatchGroup, getDispatchByType, getOccurrenceIndicators } from '../services';
import { Downgraded, useHookstate } from '@hookstate/core';

interface paramsProps {
  readonly id: string
}

const cities = [
  { item: 'Campina Grande', value: random(200) },
  { item: 'Santa Rita', value: random(200) },
  { item: 'Bayeux', value: random(200) },
  { item: 'Guarabira', value: random(200) },
  { item: 'Patos', value: random(200) },
  { item: 'Pombal', value: random(200) },
  { item: 'Sousa', value: random(200) },
  { item: 'Cajazeiras', value: random(200) },
  { item: 'Cabedelo', value: random(200) },
  { item: 'Bananeiras', value: random(200) }
]

interface IIndicators {
  readonly city: {
    readonly data: readonly Data[],
    readonly totals: Totals
  }
  readonly operation: {
    readonly data: readonly Data[],
    readonly totals: Totals
  }
}

export interface ICoordenation {
  readonly coordinator: {
    readonly id: number;
    readonly name: string;
    readonly cpf: string;
    readonly rank: {
      readonly name: string | null
    };
    readonly registration: string;
    readonly representation_name: string;
  }
  readonly date_begin: string;
  readonly date_expected_closing: string;
  readonly date_end: string;
  readonly dispatch_groups: readonly {
    readonly id: number,
    readonly name: string;
  }[]
}

interface Chart {
  readonly item: string;
  readonly value: number
}

export default function Summary() {
  const { translate } = useLang();
  const componentRef = useRef(null);
  const { id }: paramsProps = useParams();
  const indicators = useHookstate<IIndicators | null>(null);
  const coordenation = useHookstate<ICoordenation | null>(null);
  const columnChartByType = useHookstate<readonly Chart[]>([])
  const columnChartByCity = useHookstate<readonly Chart[]>([])
  const pieChartByCity = useHookstate<readonly Chart[]>([])

  useEffect(() => {
    document.title = translate('Dispatch System')
  }, [])

  const handlePrint = useReactToPrint({
    // documentTitle: `Súmula nº: ${overview.get()?.code}`,
    content: () => componentRef.current,
    removeAfterPrint: false,

  })

  const handleGetCoordination = async () => {

    await getCoordenation(id).then((response) => {
      coordenation.set(response);
    });

    const dispatchGroupIds = coordenation.get()?.dispatch_groups.map(group => group.id) ?? []

    getDispatchByType({
      coordinationId: parseInt(id),
      dispatchGroupIds
    }).then(response => {
      return columnChartByType.set(response.map((responseData) => ({ item: responseData.name, value: responseData.total })));
    })

    getDispatchByDispatchGroup({
      coordinationId: parseInt(id),
      dispatchGroupIds
    }).then(response => {
      return pieChartByCity.set(response.map((responseData) => ({ item: responseData.name, value: responseData.total })));
    })

    getDispatchByCity({
      coordinationId: parseInt(id),
      dispatchGroupIds
    }).then(response => {
      return columnChartByCity.set(response.map((responseData) => ({ item: responseData.name, value: responseData.total })));
    })
  }

  useEffect(() => {
    getOccurrenceIndicators(id).then((response) => {
      indicators.set(response);
    });
    handleGetCoordination();
  }, [])


  return (
    <div>
      <Button onClick={handlePrint}>
        {'Imprimir'}
      </Button>
      <div ref={componentRef} className="print-container" style={{
        margin: "0",
        padding: "0"
      }}>
        <div className="page-break" />
        <HeaderSummary headerSummary={coordenation.get()} />
        <TabSummary title={'Indicadores por cidade'}>
          <IndicatorsContentSummary groupedBy='Cities' indicator={indicators.get()?.city} />
        </TabSummary>
        <TabSummary title={'Indicadores por operação'}>
          <IndicatorsContentSummary groupedBy='Operation' indicator={indicators.get()?.operation} />
        </TabSummary>
        <Container fixed={false} sx={{ marginTop: 5, textAlign: 'center' }}>
          <Typography variant='caption'>
            OCS - Ocorrências | ADA - Adultos presos | MNA - Menores apreendidos | AMA - Armas apreendidas
          </Typography>
          <br />
          <Typography variant='caption'>
            MUA - Munições apreendidas | DGA - Drogas apreendidas | VGA - Veículos Apreendidos | VER - Veículos Recuperados
          </Typography>
        </Container>
        <TabSummary
          title={'Tipos de ocorrências'}>
          <Container fixed={false}>
            {columnChartByType.get().length ?
              (<ColumnChart data={columnChartByType.attach(Downgraded).get()} title={''} width={800} />) : (
                <Grid item xs={12} textAlign={'center'}>
                  <Typography variant='subtitle1'>
                    <b>
                      {translate('NO INFORMATION REGISTERED')}
                    </b>
                  </Typography>
                </Grid>
              )}
          </Container>
        </TabSummary>
        <div className="page-break" />
        <TabSummary
          title={'Grupos de despachos'}>
          <Container fixed={false}>
            {pieChartByCity.get().length ? (<PieChart isPrint data={pieChartByCity.attach(Downgraded).get()} title={''} width={800} />) : (
              <Grid item xs={12} textAlign={'center'}>
                <Typography variant='subtitle1'>
                  <b>
                    {translate('NO INFORMATION REGISTERED')}
                  </b>
                </Typography>
              </Grid>
            )}
          </Container>
        </TabSummary>
        <TabSummary
          title={'Cidades'}>
          <Container fixed={false}>
            {columnChartByCity.get().length ? (<ColumnChart data={columnChartByCity.attach(Downgraded).get()} title={''} width={800}/>) : (
              <Grid item xs={12} textAlign={'center'}>
                <Typography variant='subtitle1'>
                  <b>
                    {translate('NO INFORMATION REGISTERED')}
                  </b>
                </Typography>
              </Grid>
            )}
          </Container>
        </TabSummary>
        <div className="page-break" />
        <TabSummary
          title={'Detalhes das ocorrências'}>
          <DetailsOccurrences id={id} />
        </TabSummary>
        <div className="page-break" />
        <TabSummary
          title={'Observações da regulação'}>
          <RegulatoryObservations id={id} />
        </TabSummary>
        <Signature coordenation={coordenation.get()} />
      </div>
    </div >
  )
}