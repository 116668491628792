import React, { useCallback, useEffect, useState } from 'react';
import useDispatchRegistrationState from '../../stores/DispatchRegistrationState';
import useDispatchState from '../../stores/DispatchState';
import ListRegistrations from '../DispatchRegistrationTab/components/ListRegistrations';
import { useLang } from '~/hooks/useLang';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { saveLogVisualizeQualificators } from '../../services';
import useSocket from '~/hooks/useSocket';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import notify from '~/utils/notify';
import { useHookstate } from '@hookstate/core';
import FormAnimal from './components/FormAnimal';
import useDispatchRegistrationAnimalState, { animalDefaultState } from '../../stores/DispatchRegistrationAnimalState';
import { toDomain } from '~/mappers/registration-animal-mapper';
import { IAnimalDTO, IRawAnimal } from '../../interfaces/IDispatchRegistrationAnimal';
interface IAnimal {
  readonly handleClickOpenConfirmAction: (actionName: string) => void;
}
interface IMessageAnimal {
  readonly content: IRawAnimal;
  readonly event_type: string

}
// eslint-disable-next-line max-lines-per-function
export default function Animal({ handleClickOpenConfirmAction }: IAnimal) {
  const { translate } = useLang();
  const loading = useHookstate<boolean>(false)
  const { socket } = useSocket();
  const { dispatch } = useDispatchState();
  const { occurrence, status, id: dispatchIdState } = dispatch();
  const { id: occurrenceIdState, } = occurrence;

  const { registrationDispatch } = useDispatchRegistrationState();

  const {getAllAssociatedAnimal, registrationAnimalDispatch, deleteAnimal, editAnimal} = useDispatchRegistrationAnimalState();

  const { animal, animals } = registrationAnimalDispatch();

  const { addRegistration, loadingRegistrations, registrationRowId, deleteRegistration } = registrationDispatch();

  const [rows, setRows] = useState<readonly IAnimalDTO[]>([])



  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: translate('Id'),
      flex: 0.5,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'specie',
      headerName: translate('Specie'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'breed',
      headerName: translate('Breed'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'animalCircunstance',
      headerName: translate('Circunstance'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'destination',
      headerName: translate('Destination'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'notes',
      headerName: translate('Note'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'actions', type: 'actions', flex: 1,
      getActions: (params) => [
        status.value.id === 8 ? <Tooltip key={2} title={translate('View Record')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<RemoveRedEyeIcon />}
              onClick={() => {
                saveLogVisualizeQualificators(params.row.id, '/occurrence/associated-animal/generate-view-timeline').then((response) => {
                  if (response.status === 200) {
                    animal.set(params.row);
                    addRegistration.set(true)
                  }
                })
              }}
              label="Print" />
          </span>
        </Tooltip> : <></>,
        status.value.id !== 8 ? <Tooltip key={2} title={translate('Edit Record')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<EditIcon />}
              onClick={() => {
                animal.set(params.row);
                addRegistration.set(true)
              }}
              label="Print" />
          </span>
        </Tooltip> : <></>,
        status.value.id !== 8 ? <Tooltip key={2} title={translate('Delete Record')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<DeleteIcon />}
              onClick={() => {
                registrationRowId.set(params.row.id);
                handleClickOpenConfirmAction('delete')
              }}
              label="Print" />
          </span>
        </Tooltip> : <></>
      ]
    },
  ]

  const handleAdd = useCallback(() => {
    animal.set({ ...animalDefaultState, occurrenceId: occurrenceIdState.get() });
    addRegistration.set(true)
  }, [addRegistration, occurrenceIdState, animal])

  const handleDelete = useCallback(async (id: string) => {
    loadingRegistrations.set(true)
    await deleteAnimal(id)
    notify({ message: translate('Successfully Deleted'), type: 'success' })
    loadingRegistrations.set(false);
    deleteRegistration.set(false);
  }, [deleteAnimal, deleteRegistration, loadingRegistrations, translate])

  const handleEdit = useCallback(async () => {
    loading.set(true)
    await editAnimal(animal.get()).finally(() => {
      loading.set(false)
    })
    notify({ message: translate('Saved successfully'), type: 'success' })
    addRegistration.set(false)
  }, [addRegistration, editAnimal, animal, translate])

  const newMessage = useCallback((message: IMessageAnimal) => {
    if (message.event_type === 'OCCURRENCE_ASSOCIATED_ANIMAL_CREATE') {
      setTimeout(() => {
        if (message.content.dispatch_id !== dispatchIdState.get()) {
          return
        }
        setRows(prev => [...prev, toDomain(message.content)])
      }, 1000)
    }
    if (message.event_type === 'OCCURRENCE_ASSOCIATED_ANIMAL_DELETE') {
      const filterRows = rows.filter(row => row.id !== message.content.id)
      setRows(filterRows);
    }
    if (message.event_type === 'OCCURRENCE_ASSOCIATED_ANIMAL_CHANGE') {
      const updateRows = rows.map(row => {
        if (row.id === message.content.id) {
          return toDomain(message.content)
        } else {
          return row
        }
      })
      setRows(updateRows);
    }
  }, [rows])

  useEffect(() => {
    socket.on('occurrences', newMessage)
    return () => {
      socket.off('occurrences', newMessage)
    }
  }, [newMessage, socket])

  useEffect(() => {
    loadingRegistrations.set(true);
    if (occurrenceIdState?.get()){
      getAllAssociatedAnimal(occurrenceIdState.get())
      .then((res) => {
        setRows(res)
        animal.set(res)
      })
      .finally(() => {
        loadingRegistrations.set(false)
      });
    }
  }, [occurrenceIdState?.get()]);

  useEffect(() => {
    if (deleteRegistration.get()) {
      handleDelete(registrationRowId.get())
    }
  }, [deleteRegistration, handleDelete, registrationRowId])

  return (
    addRegistration.get() ? (
      <FormAnimal
        loading={loading}
        handleEdit={handleEdit} 
        handleClickOpenConfirmAction={handleClickOpenConfirmAction}      
      />
    ) : (
      <ListRegistrations
        handleAdd={handleAdd}
        columns={columns}
        rows={rows}
      />
    )
  )
};