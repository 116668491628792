import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core';
import { getCountQualifiersOperationService } from '~/features/Operation/services/index';
import CountStatsCard from '~/components/CountStatsCard';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { capitalize } from '~/utils/strings';

export default function CountQualifiers({operationId, initialDate, endDate, refresh, loading}){
  const { translate } = useLang();
  const data = useHookstate({});

  useEffect(() => {
    loading.set(true)
    getCountQualifiersOperationService({ operationId: operationId, initialDate: initialDate, endDate: endDate }).then((response) => {
      data.set(response);
    }).finally(() => {
      loading.set(false)
    })
  }, [refresh]);

  return (
    <Card>
     <CardContent>
      <Typography sx={{ fontWeight: 'bold', fontSize: 16 }} >{translate('Resumo')}</Typography>
      <Grid container direction="row" padding='auto' height={400} spacing={2}>
          {Object.keys(data.value).map((value, index) => {
            return (
                <CountStatsCard key={index} name={translate(capitalize(value))}
                color={(Number(index) % 2 === 0) ? '#616161' : '#41AEAA'} 
                value={data.get()[value]} />)
          })}
        </Grid>
      </CardContent>
    </Card>
  )
}