import React from 'react';
import { IconButton, Grid } from '@mui/material';
import { FileCopy } from '@mui/icons-material';
import { useHookstate } from '@hookstate/core';
import DispatchCopyDialog from './components/DispatchCopyDialog';

interface DispatchCopyButtonProps {
  readonly canCopy: boolean | undefined;
  readonly copyDispatch: (params: any) => Promise<void> | undefined;
}

export default function DispatchCopyButton({ ...props }: DispatchCopyButtonProps) {
  const open = useHookstate(false);

  return (
    <Grid item>
      <IconButton title={'Copiar Despacho'} disabled={!props.canCopy} onClick={() => open.set(true)}>
        <FileCopy />
      </IconButton>
      {open.get() && <DispatchCopyDialog open={open} copyDispatch={props.copyDispatch} />}
    </Grid >);
}
