import { authFetch } from '~/services/fetch'
import { IListSurveillanceReportingByDispatch } from '../interfaces/IListSurveillanceReportinByDispatch'

export async function listSurveillanceReportingByDispatchId({
  dispatchId,
  filters = [],
  page,
  limit = 5,
}: IListSurveillanceReportingByDispatch) {
  try {
    const response = await authFetch({
      url: '/dispatch/video-surveillance-reports',
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
        filters,
        orders: [{ field: 'created_at', sort: 'desc' }],
        page,
        limit,
      },
    })
    return response.data
  } catch (error) {
    console.error(error)
  }
}
