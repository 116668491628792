import React from 'react';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { AppRouteProps } from '~/routes';

export default function VehicleBrandRoutes(): readonly AppRouteProps[] {
  const routes: readonly AppRouteProps[] = [
    {path: '/vehicle-brand', component: Index, isProtected: true, permissions: ['vehicle_brand_index']},
    {path: '/vehicle-brand/create', component: Create, isProtected: true, permissions: ['vehicle_brand_create']},
    {path: '/vehicle-brand/edit/:id', component: Edit, isProtected: true, permissions: ['vehicle_brand_edit']},

  ];

  return routes;
}
