import React, { ReactNode } from 'react';
import { Grid, Typography, Container, Divider } from '@mui/material';

type TabPrintProps = {
  title: string;
  subTitle?: string
  children: ReactNode;
}

export default function TabPrint(props: TabPrintProps){
  return (
    <Container fixed={false} sx={{ marginTop: 5 }}>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Grid container xs={12}>
            <Grid item xs={7}>
              <Typography variant='h6' textAlign={'left'}>{props.title}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant='h6' textAlign={'right'}>{props.subTitle}</Typography>
            </Grid>
          </Grid>
          <Divider sx={{ border: 2, margin: 0 }} variant="middle"/>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          {props.children}
        </Grid>
      </Grid>
    </Container>
  );
}