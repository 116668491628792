import React, { useEffect } from 'react';
import ReactDOM from 'react-dom'
import { ListAlt } from '@mui/icons-material';
import StandardModal from '../../../../components/StandardModal';
import { GridRowModel, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import { useLang } from '../../../../hooks/useLang';
import { none, useHookstate, useState } from '@hookstate/core';
import ListEntryPopupTable from '../ListEntryPopupTable'
import { useAuth } from '~/features/auth/hooks/useAuth';
import { authFetch } from '~/services/fetch';
import { Box } from '@mui/system';
import { Card, CardContent, CardHeader, Divider, Stack, Button } from '@mui/material';
import { Update as UpdateIcon } from '@mui/icons-material';
import useEntryState from '../../stores/EntryState';
import { element } from 'prop-types';
import useSocket from '~/hooks/useSocket';

interface EntryGrid {
  readonly address: string
  readonly address_reference: string
  readonly created_at: string
  readonly entry_origin_id: string
  readonly id: number
  readonly latitude: number
  readonly location: number
  readonly longitude: number
  readonly name: string
  readonly phone: string
  readonly subtype_id: number
  readonly subtypename: string
  readonly type_id: number
  readonly typename: number
}


const modal = document.getElementById('div-modal') as HTMLElement;

type EntryLogParams = {
  readonly onRowClick?: (rows: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement>>) => boolean | undefined
}
export default function ListEntryPopup({ onRowClick }: EntryLogParams) {
  const { translate } = useLang()
  const { socket } = useSocket();
  const { handleUpdateMyEntries } = useEntryState()
  // const rows = useHookstate<readonly EntryGrid[]>([]);
  const [rows, setRows] = React.useState([] as EntryGrid[])
  const loading = useState<boolean>(false);

  const toUpdateEntry = async () => {
    const getMyEntriesUpdated = await handleUpdateMyEntries()
    setRows(getMyEntriesUpdated);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const newMessage = (message: any) => {
    if (message.event_type === 'ENTRY_UPDATED') {
      toUpdateEntry()
    }

    if (message.event_type === 'ENTRY_CREATED') {
      const newEntry = message.content
      rows.push(newEntry)
    }

    if (message.event_type === 'ENTRY_CLOSED') {
      const idEntryDispatch: number = message.content.entry.id
      const idRemove = rows.findIndex(item => item.id === idEntryDispatch)
      rows.splice(idRemove, 1)
    }
  }

  useEffect(() => {
    socket.on('entries', newMessage)
    return () => {
      socket.off('entries', newMessage)
    }
  }, [newMessage, socket])

  const getMyEntries = () => {
    loading.set(true);
    authFetch({
      url: '/entry/get-my-entries'
    }).then((response) => {
      if (response.data.length > 0) {
        setRows(response.data)
      } else {
        setRows([])
      }
      return;
    })
    loading.set(false);
  }

  useEffect(() => {
    getMyEntries()
  }, []);

  return ReactDOM.createPortal((
    <Box>
      <Card>
        <CardHeader className="standard-modal" title={translate('My Calls')} action={
          <Box sx={{ marginRight: 1 }}>
            <Button
              title={translate('Update')}
              type='submit'
              variant='contained'
              disabled={loading.get()}
              sx={{ padding: 1, backgroundColor: 'primary.main' }}
              onClick={toUpdateEntry}><UpdateIcon />
            </Button>
          </Box>
        } />
        <Divider />
        <CardContent>
          <Stack flex={1} spacing={2}>

            <Box>
              <ListEntryPopupTable rows={rows.map((row) => row)} onRowClick={onRowClick} loading={loading.get()} />
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  ), modal);

}


