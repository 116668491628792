import { IPersonDTO, IRawPerson } from "~/features/Dispatch/interfaces/IDispacthRegistrationPerson";
import { removeMask } from "~/features/Dispatch/utils/stringsManipulations";

export function toDomain(person: IRawPerson): IPersonDTO {
  return {
    id: person.id,
    name: person.name,
    cpf: person.cpf,
    otherDocumentType: person.other_document_type,
    otherDocument: person.other_document,
    age: person.age,
    gender: person.gender,
    nickname: person.nickname,
    motherName: person.mother_name,
    fatherName: person.father_name,
    birthDate: person.birth_date,
    phoneNumber: person.phone_number,
    arrested: person.arrested,
    address: person.address,
    addressNumber: person.address_number,
    addressReference: person.address_reference,
    cep: person.cep,
    cityId: person.city_id,
    district: person.district,
    stateId: person.state_id,
    publicAgent: person.is_public_agent,
    ethnicity: person.ethnicity,
    occurrenceId: person.occurrence_id,
    education: person.education,
    occupation: person.occupation,
    peopleCircunstance: person.people_circunstance,
    destination: person.destination,
    organization: person.organization,
    notes: person.notes,
    hasMentalIllness: !!person.has_mental_illness,
    hasPhysicalIllness: !!person.has_physical_illness,
    isLgbt: !!person.is_lgbt,
    involvementType: person.involvement_type,
  }
}

export function toFetch(person: IPersonDTO) {
  return {
    other_document_type_id: person.otherDocument?.id ?? null,
    gender_id: person.gender?.id ?? null,
    involvement_type_id: person.involvementType?.id,
    occurrence_id: person.occurrenceId || null,
    ethnicity_id: person.ethnicity?.id ?? null,
    education_id: person.education?.id ?? null,
    people_circunstance_id: person.peopleCircunstance?.id ?? null,
    organization_id: person.organization?.id ?? null,
    name: person.name || null,
    cpf: removeMask(person.cpf) || null,
    age: !!person.age ? parseInt(`${person.age}`) : null,
    other_document_type: person.otherDocumentType || null,
    nickname: person.nickname || null,
    mother_name: person.motherName || null,
    father_name: person.fatherName || null,
    birth_date: person.birthDate,
    occupation_id: person.occupation?.id ?? null,
    occupation: person.occupation?.name ?? null,
    phone_number: removeMask(person.phoneNumber) || null,
    arrested: parseInt(person.arrested),
    is_public_agent: parseInt(person.publicAgent),
    destination_id: person.destination?.id ?? null,
    notes: person.notes || null,
    has_mental_illness: person.hasMentalIllness,
    has_physical_illness: person.hasPhysicalIllness,
    is_lgbt: person.isLgbt,
    city_id: person.cityId || null,
    state_id: person.stateId || null,
    address: person.address || null,
    address_number: person.addressNumber || null,
    address_reference: person.addressReference || null,
    cep: person.cep || null,
    district: person.district || null,
  }
}
