/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import { IconButton, Tooltip, Box, Typography, Button, Dialog, DialogTitle, DialogActions, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useDispatchGroupState, { IDispatchGroupView } from '~/features/DispatchGroup/stores/DispatchGroupState';
import { useLang } from '~/hooks/useLang';
import { Downgraded, useHookstate } from '@hookstate/core';
import { LoadingButton } from '@mui/lab';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import VisibleGroupTreeView, { RenderTree } from '../VisibleGroupTreeView';


export default function ChangeViewDispatchGroupContent({ handleClose, handleCloseModal, openModal }) {
  const { dispatchGroupView, applyDispatchGroupView, loadingButton } = useDispatchGroupState();
  const { setDefaultDispatch } = useDispatchState()
  const { translate } = useLang();
  const localChecked = useHookstate<IDispatchGroupView>({})
  const dispatchGroupViewLocal = JSON.parse(JSON.stringify(dispatchGroupView().attach(Downgraded).get()))
  const changeAll = useHookstate(false)

  const agencyAndGroups = useHookstate<RenderTree>({
    id: 'root',
    name: translate('ALL'),
    is_visible: false,
    parent: '',
    children: []
  })

  const agencyOurGroupsRequested = useHookstate<RenderTree>({
    id: 'root',
    name: translate('ALL'),
    is_visible: false,
    parent: '',
    children: []
  })

  useEffect(() => {
    localChecked.set(JSON.parse(JSON.stringify(dispatchGroupView().attach(Downgraded).get())))
    loadingButton.set(false)
  }, [])

  const handleCheck = (action: string) => {
    if (action === 'checkAll') {
      Object.keys(localChecked.value).map(element => localChecked[element].merge({ is_visible: 1 }))
      changeAll.set(!changeAll.get())
    } else if (action === 'unCheckAll') {
      Object.keys(localChecked.value).map(element => localChecked[element].merge({ is_visible: 0 }))
      changeAll.set(!changeAll.get())
    } else {
    }
  }

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          {translate('Dispatchs Groups')}
          <IconButton title={translate('Close')} sx={{ padding: 0 }}>
            <CloseIcon onClick={() => handleClose()} />
          </IconButton>
        </Box>
        <Typography variant="subtitle1" component="div" sx={{ fontSize: '12px' }}>
          {`${translate("Total Dispatchs Groups")} ` + `${Object.keys(localChecked.get()).length}`}
        </Typography>

        <Tooltip title={`${Object.keys(dispatchGroupViewLocal).filter(element => dispatchGroupViewLocal[element].is_visible)}`}>
          <Typography variant="subtitle1" component="div" sx={{ fontSize: '12px' }}>
            {`${translate("Total Visible Dispatch Group")}: ${Object.keys(dispatchGroupViewLocal).filter(element => dispatchGroupViewLocal[element].is_visible).length}`}
          </Typography>
        </Tooltip>

        <Tooltip title={`${Object.keys(localChecked.get()).filter(element => localChecked[element].is_visible.get())}`}>
          <Typography variant="subtitle1" component="div" sx={{ fontSize: '12px' }}>
            {`${translate("Selected dispatch group")} ${Object.keys(localChecked.get()).filter(element => localChecked[element].is_visible.get()).length}`}
          </Typography>
        </Tooltip>

      </DialogTitle>
      <Box>
        <DialogContent
          sx={{
            width: '100%',
            padding: 0,
            paddingLeft: 1,
            height: 400,
            overflow: 'auto',
          }}
        >
          {Object.keys(localChecked.value).length ? <VisibleGroupTreeView agencyAndGroups={agencyAndGroups} agencyOurGroupsRequested={agencyOurGroupsRequested} visibleGroup={localChecked} changeAll={changeAll.get()}/> : (
          translate('No records')
        )}
        </DialogContent>
        <DialogActions
          sx={{
            paddingY: 2,
            paddingX: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2
          }}
        >
          <Button
            variant="text"
            onClick={() => handleCheck('checkAll')}
          >
            {translate('Check all')}
          </Button>
          <Button
            variant="text"
            onClick={() => handleCheck('unCheckAll')}
          >
            {translate('Uncheck all')}
          </Button>

          <LoadingButton
            loading={loadingButton.get()}
            onClick={() => {
              dispatchGroupView().set(JSON.parse(JSON.stringify(localChecked.get())))
              applyDispatchGroupView().finally(() => {
                setDefaultDispatch()
                handleClose()
              })
            }
            }
            variant="contained"
          >
            {translate('Save')}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}