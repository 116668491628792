import React, { useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, CircularProgress, Container, Grid } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import HeaderPrint, { HeaderPrintResponseDefault } from '~/features/Dispatch/components/Print/HeaderPrint';
import TabPrint from '~/features/Dispatch/components/Print/TabPrint';
import OverviewContentPrint from '~/features/Dispatch/components/Print/OverviewContentPrint';
import RequestDataPrint from '~/features/Dispatch/components/Print/RequestDataPrint';
import DispatchedVehiclesPrint from '~/features/Dispatch/components/Print/DispatchedVehiclesPrint';
import InvolvedInTheOccurrencePrint, { peopleHistoryProps } from '~/features/Dispatch/components/Print/InvolvedInTheOccurrencePrint';
import VehiclesRegisteredInTheOccurrencePrint, { vehicleHistoryProps } from '~/features/Dispatch/components/Print/VehiclesRegisteredInTheOccurrencePrint';
import GunsRegisteredInTheOccurrencePrint, { gunHistoryProps } from '~/features/Dispatch/components/Print/GunsRegisteredInTheOccurrencePrint';
import DrugsRegisteredInTheOccurrencePrint, { drugHistoryProps } from '~/features/Dispatch/components/Print/DrugsRegisteredInTheOccurrencePrint';
import ObjectsRegisteredInTheOccurrencePrint, { objectsHistoryProps } from '~/features/Dispatch/components/Print/ObjectsRegisteredInTheOccurrencePrint';
import ActiveAgenciesPrint, { agencyHistoryProps } from '~/features/Dispatch/components/Print/ActiveAgenciesPrint';
import HistoryCommentsEntryPrint from '~/features/Dispatch/components/Print/HistoryCommentsEntryPrint';
import ClosedCommentsDispatchPrint from '~/features/Dispatch/components/Print/ClosedCommentsDispatchPrint';
import Signature from '~/features/Dispatch/components/Print/Signature';
import { useParams } from 'react-router-dom';
import { getContributersDispatchService, getDispatchByIdService, getDispatchClosingCommentsService, getDispatchOverviewService, saveLogPrintDispatch } from '~/features/Dispatch/services/index';
import { useHookstate } from '@hookstate/core';
import { object } from 'prop-types';
import { parseDataDispatch } from '../utils/parsers';
import { getAllOccurrenceCommentService, getAllOccurrenceCommentServiceByDeviceId, getAssociatedAnimalFromOccurrenceService, getAssociatedDrugsFromOccurrenceService, getAssociatedObjectsFromOccurrenceService, getAssociatedOrganizationFromOccurrenceService, getAssociatedPeopleFromOccurrenceService, getAssociatedVehicleFromOccurrenceService, getAssociatedWeaponFromOccurrenceService, getContributersDevicesFromOccurrenceService, getUnifiedDispatchFromOccurrenceService, getVideomonitoringReport } from '~/features/Occurrence/services';
import { PostCommentResponse } from '../stores/DispatchCommentState';
import useSystemState from '~/features/System/stores/SystemState';
import RealoadButtonPrint from '../components/Print/RealodButtonPrint';
import useDispatchState, { dispatchDefault } from '../stores/DispatchState';
import { use } from 'i18next';
import VideomonitoringPrint from '../components/Print/VideomonitoringPrint';
import AnimalRegisteredInTheOccurrencePrint, { animalHistoryProps } from '../components/Print/AnimalRegisteredInTheOccurrencePrint';
import UnifiedDispatchPrint, { UnifiedDispatchProps } from '../components/Print/UnifiedDispatchPrint';

interface paramsProps {
  readonly id: string
}

type dispatchPrintResultsProps = {
  readonly Header: boolean | null;
  readonly OverviewContent: boolean | null;
  readonly RequestData: boolean | null;
  readonly HistoryCommentsEntry: boolean | null;
  readonly ClosedCommentsDispatch: boolean | null;
  readonly DispatchedVehicles: boolean | null;
  readonly InvolvedInTheOccurrence: boolean | null;
  readonly VehiclesRegisteredInTheOccurrence: boolean | null;
  readonly GunsRegisteredInTheOccurrence: boolean | null;
  readonly DrugsRegisteredInTheOccurrence: boolean | null;
  readonly ObjectsRegisteredInTheOccurrence: boolean | null;
  readonly ActiveAgencies: boolean | null;
}

export interface overviewProps {
  readonly code: string,
  readonly time_total: string,
  readonly is_consummate: boolean | null
  readonly occurrence: {
    readonly id: number
  },
  readonly closed_status: {
    readonly id: number,
    readonly name: string,
    readonly acronym: string
  },
  readonly time_dispatch: string,
  readonly time_displacement: string,
  readonly time_finished: string,
  readonly total_agencies_involveds: number,
  readonly total_devices: number,
  readonly total_people_involveds: number,
  readonly total_vehicles_involveds: number,
  readonly total_weapons_involveds: number,
  readonly total_drugs_involveds: number,
  readonly type: {
    readonly id: number,
    readonly name: string,
    readonly acronym: string
  },
  readonly subtype: {
    readonly id: number,
    readonly name: string,
    readonly acronym: string
  },
  readonly total_animals_involveds: number,
  readonly total_objects_involveds: number,
}

export interface videomonitorinNarrationProps {
  id: number | string,
  camera: {
    name: string
  },
  user: {
    name: string
  },
  notes: string,
  reported_at: string
}

// eslint-disable-next-line max-lines-per-function
export default function Print() {
  const { translate } = useLang();
  const componentRef = useRef(null);
  const { id }: paramsProps = useParams();
  const overview = useHookstate<overviewProps | null>(null);
  const { systemConfig } = useSystemState();
  const idOccurrence = overview.get()?.occurrence?.id
  const loading = useHookstate(true)
  const showPrint = useHookstate(true)
  const HeaderPrintResponse = useHookstate(HeaderPrintResponseDefault)
  const RequestDataPrintResponse = useHookstate(dispatchDefault)
  const HistoryCommentsEntryPrintResponse = useHookstate<PostCommentResponse['data'][]>([]);
  const ClosedCommentsDispatchPrintResponse = useHookstate<PostCommentResponse['data'][]>([]);
  const DispatchedVehiclesPrintResponse = useHookstate<vehicleHistoryProps[]>([]);
  const InvolvedInTheOccurrencePrintResponse = useHookstate<peopleHistoryProps[]>([]);
  const VehiclesRegisteredInTheOccurrencePrintResponse = useHookstate<Array<vehicleHistoryProps>>([]);
  const GunsRegisteredInTheOccurrencePrintResponse = useHookstate<Array<gunHistoryProps>>([])
  const DrugsRegisteredInTheOccurrencePrintResponse = useHookstate<Array<drugHistoryProps>>([])
  const ObjectsRegisteredInTheOccurrencePrintResponse = useHookstate<Array<objectsHistoryProps>>([])
  const ActiveAgenciesPrintResponse = useHookstate<Array<agencyHistoryProps>>([])
  const VideomonitoringNarrationPrintResponse = useHookstate<videomonitorinNarrationProps[]>([])
  const AnimalRegisteredInTheOccurrencePrintResponse = useHookstate<Array<animalHistoryProps>>([])
  const UnifiedDispatchPrintResponse = useHookstate<Array<UnifiedDispatchProps>>([])

  useEffect(() => {
    document.title = translate('Dispatch System')
  }, [])

  const handlePrint = useReactToPrint({
    documentTitle: `Ocorrência nº: ${overview.get()?.code}`,
    content: () => componentRef.current,
    removeAfterPrint: false
  })

  useEffect(() => {
    saveLogPrintDispatch(id).then((response) => {
      if (response.status === 200) {
        getDispatchOverviewService(id).then((response) => {
          overview.set(response);
        })
      }
    })
  }, [])

  useEffect(() => {
    idOccurrence ? Promise.all([
      getContributersDispatchService(id).then((response) => {
        HeaderPrintResponse.set(response);
      }),
      getDispatchByIdService(id).then((response) => {
        RequestDataPrintResponse.set(parseDataDispatch(response));
      }),
      getAllOccurrenceCommentServiceByDeviceId({ id: id, order: 'ASC' }).then((response) => {
        HistoryCommentsEntryPrintResponse.set(response.map(({ created_at: createdAt, is_deleted: isDeleted, ...data }) => ({ createdAt, isDeleted, ...data })
        ).filter(comment => systemConfig().displayAllCommentsInPrint.get() === '0' ? comment.level == 'public' : comment.closing_comment == 0));
      }),
      getDispatchClosingCommentsService({ id: id, order: 'ASC' }).then((response) => {
        ClosedCommentsDispatchPrintResponse.set(response.map(({ created_at: createdAt, ...data }) => ({ createdAt, ...data })));
      }),
      getContributersDevicesFromOccurrenceService(id).then((response) => {
        DispatchedVehiclesPrintResponse.set(response);
      }),
      getAssociatedPeopleFromOccurrenceService(id).then((response) => {
        InvolvedInTheOccurrencePrintResponse.set(response)
      }),
      getAssociatedVehicleFromOccurrenceService(id).then((response) => {
        VehiclesRegisteredInTheOccurrencePrintResponse.set(response);
      }),
      getAssociatedWeaponFromOccurrenceService(id).then((response) => {
        GunsRegisteredInTheOccurrencePrintResponse.set(response);
      }),
      getAssociatedDrugsFromOccurrenceService(id).then((response) => {
        DrugsRegisteredInTheOccurrencePrintResponse.set(response);
      }),
      getAssociatedObjectsFromOccurrenceService(id).then((response) => {
        ObjectsRegisteredInTheOccurrencePrintResponse.set(response);
      }),
      getAssociatedOrganizationFromOccurrenceService(id).then((response) => {
        ActiveAgenciesPrintResponse.set(response);
      }),
      getVideomonitoringReport(id).then(response => {
        VideomonitoringNarrationPrintResponse.set(response)
      }),
      getAssociatedAnimalFromOccurrenceService(id).then(response => {
        AnimalRegisteredInTheOccurrencePrintResponse.set(response)
      }),
      getUnifiedDispatchFromOccurrenceService(id).then(response => {
        UnifiedDispatchPrintResponse.set(response)
      })
    ]).then(() => {
    }).catch((err) => {
      showPrint.set(false)
      console.log(err)
    }).finally(() => {
      loading.set(false)
    }) : false
  }, [idOccurrence])


  return (
    !loading.get() && showPrint.get() ?
      <div>
        <Button onClick={handlePrint}>{'Imprimir'}</Button>
        <div ref={componentRef} className="print-container" style={{ margin: "0", padding: "0" }}>
          <div className="page-break" />
          <HeaderPrint
            HeaderPrintResponse={HeaderPrintResponse.get()}
          />

          <TabPrint
            title={`${overview.get()?.type?.name ? overview.get()?.type?.name : ''} ${overview.get()?.subtype?.name ? overview.get()?.subtype?.name : ''} ${overview.get()?.is_consummate ? `[${overview.get()?.is_consummate}]` : ''}`}
            subTitle={`${translate('Finalization')}: ${(overview.get()?.closed_status?.name) ?? `* ${translate('Uninformed')} *`}`}>
            <OverviewContentPrint overview={overview} />
          </TabPrint>

          <TabPrint
            title={translate('Request Data')}>
            <RequestDataPrint
              RequestDataPrintResponse={RequestDataPrintResponse.get()}
            />
          </TabPrint>

          <TabPrint
            title={'Abertura do Atendimento'}>
            <HistoryCommentsEntryPrint
              occurrenceId={idOccurrence}
              HistoryCommentsEntryPrintResponse={HistoryCommentsEntryPrintResponse.get()}
            />
          </TabPrint>

          <TabPrint
            title={'Fechamento do Despacho'}>
            <ClosedCommentsDispatchPrint
              ClosedCommentsDispatchPrintResponse={ClosedCommentsDispatchPrintResponse.get()}
            />
          </TabPrint>

          {
            UnifiedDispatchPrintResponse.get().length > 0 && <TabPrint
              title={'Despachos Unificados'}>
              <UnifiedDispatchPrint
                UnifiedDispatchPrintResponse={UnifiedDispatchPrintResponse.get()}
              />
            </TabPrint>
          }

          {
            DispatchedVehiclesPrintResponse.get().length > 0 && <TabPrint
              title={'Viaturas Despachadas'}>
              <DispatchedVehiclesPrint
                DispatchedVehiclesPrintResponse={DispatchedVehiclesPrintResponse.get()}
              />
            </TabPrint>
          }

          {
            InvolvedInTheOccurrencePrintResponse.get().length > 0 && <TabPrint
              title={'Envolvidos na Ocorrência'}>
              <InvolvedInTheOccurrencePrint
                InvolvedInTheOccurrencePrintResponse={InvolvedInTheOccurrencePrintResponse.get()}
              />
            </TabPrint>
          }

          {
            VehiclesRegisteredInTheOccurrencePrintResponse.get().length > 0 && <TabPrint
              title={'Veículos Registrados na Ocorrência'}>
              <VehiclesRegisteredInTheOccurrencePrint
                VehiclesRegisteredInTheOccurrencePrintResponse={VehiclesRegisteredInTheOccurrencePrintResponse.get()}
              />
            </TabPrint>
          }

          {
            GunsRegisteredInTheOccurrencePrintResponse.get().length > 0 && <TabPrint
              title={'Armas Registradas na Ocorrência'}>
              <GunsRegisteredInTheOccurrencePrint
                GunsRegisteredInTheOccurrencePrintResponse={GunsRegisteredInTheOccurrencePrintResponse.get()}
              />
            </TabPrint>
          }

          {
            DrugsRegisteredInTheOccurrencePrintResponse.get().length > 0 && <TabPrint
              title={'Drogas Registradas na Ocorrência'}>
              <DrugsRegisteredInTheOccurrencePrint
                DrugsRegisteredInTheOccurrencePrintResponse={DrugsRegisteredInTheOccurrencePrintResponse.get()}
              />
            </TabPrint>
          }

          {
            ObjectsRegisteredInTheOccurrencePrintResponse.get().length > 0 && <TabPrint
              title={'Objetos Registradas na Ocorrência'}>
              <ObjectsRegisteredInTheOccurrencePrint
                ObjectsRegisteredInTheOccurrencePrintResponse={ObjectsRegisteredInTheOccurrencePrintResponse.get()}
              />
            </TabPrint>
          }

          {
            ActiveAgenciesPrintResponse.get().length > 0 && <TabPrint
              title={translate('Active Agencies')}>
              <ActiveAgenciesPrint
                ActiveAgenciesPrintResponse={ActiveAgenciesPrintResponse.get()}
              />
            </TabPrint>
          }
          {
            AnimalRegisteredInTheOccurrencePrintResponse.get().length > 0 && <TabPrint
              title={'Objetos Registradas na Ocorrência'}>
              <AnimalRegisteredInTheOccurrencePrint
                AnimalRegisteredInTheOccurrencePrintResponse={AnimalRegisteredInTheOccurrencePrintResponse.get()}
              />
            </TabPrint>
          }
          {
            VideomonitoringNarrationPrintResponse.get().length > 0 && <TabPrint
              title={translate('Videomonitoring narrations')}>
              <VideomonitoringPrint
                VideoMonitorinPrintResponse={VideomonitoringNarrationPrintResponse.get()}
              />
            </TabPrint>
          }

          <Signature />
        </div>
      </div> : !showPrint.get() ?
        <RealoadButtonPrint
          message={translate('An error occurred while trying to generate the printout')}
          reloadInfo={() => window.location.reload()}
        />
        :
        <Container>
          <Grid container xs={12} >
            <Grid
              item
              xs={12}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              height={'calc(100vh - 80px)'}
            >
              <CircularProgress />
            </Grid>
          </Grid>
        </Container>
  )
}