/* eslint-disable max-lines-per-function */
import React from 'react'
import {
  createState,
  useState,
} from '@hookstate/core'
import { authFetch } from '~/services/fetch'
import {
  IDispacthRegistrationVehicle,
  IVehicleDTO
} from '../interfaces/IDispacthRegistrationVehicle'
import { toDomain, toFetch } from '~/mappers/registration-vehicle-mapper'


export const vehicleDefaultState = {
  plate: '',
  notes: '',
  owner: '',
  ownerCpf: '',
  ownerPhone: '',
  occurrenceId: '',
  vehicleType: null,
  state: null,
  vehicleColor: null,
  vehicleBrand: null,
  vehicleModel: null,
  circunstance: null,
  destination: null,
  integrationCreatedAt: null,
  integrationByUserId: null,
  integrationCanceledAt: null,
  canceledByUserId: null
}

const defaultRegistrationProps: IDispacthRegistrationVehicle = {
  vehicles: [],
  vehicle: vehicleDefaultState,
}

const registrationVehicleDispatchState = createState<IDispacthRegistrationVehicle>(defaultRegistrationProps);

const useDispatchRegistrationVehicleState = () => {
  const registrationVehicleDispatch = useState(registrationVehicleDispatchState);

  return {
    registrationVehicleDispatch: () => registrationVehicleDispatch,
    getVehicleTypes: async () => {
      const targetUrl = '/vehicle-type'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;
    },
    getVehicleBrands: async () => {
      const targetUrl = '/vehicle-brand'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;

    },
    getVehicleModels: async (id?: string) => {
      if (!id) {
        return []
      }
      const targetUrl = `/vehicle-brand/${id}/models`
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;

    },
    getVehicleColors: async () => {
      const targetUrl = '/vehicle-color'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;

    },
    getAssociatedVehicle: async (id: string) => {
      const targetUrl = `/associated-vehicle/${id}`
      const { data: vehicle } = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      registrationVehicleDispatch
        .vehicle
        .set(toDomain(vehicle));
    },
    getAllAssociatedVehicle: async (occurrenceId: string | number | null): Promise<readonly IVehicleDTO[]> => {
      const targetUrl = '/occurrence/associated-vehicle/all-from-occurrence'
      const response = await authFetch({
        url: targetUrl,
        data: {
          occurrence_id: occurrenceId,
        }
      });

      return response.data.map((vehicle): IVehicleDTO => {
        return toDomain(vehicle)
      })
    },
    sendVehicle: async (vehicle: IVehicleDTO) => {
      const targetUrl = '/occurrence/associated-vehicle/store'
      return await authFetch({
        url: targetUrl,
        data: toFetch(vehicle)
      });
    },
    editVehicle: async ({ id, ...vehicle }: IVehicleDTO) => {
      const targetUrl = `/occurrence/associated-vehicle/${id}`
      await authFetch({
        url: targetUrl,
        method: 'PUT',
        data: toFetch(vehicle)
      });
    },
    deleteVehicle: async (id: string) => {
      const targetUrl = `/occurrence/associated-vehicle/${id}`
      return await authFetch({
        url: targetUrl,
        method: 'DELETE',
      });
    },
  }
}

export default useDispatchRegistrationVehicleState;