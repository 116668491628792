import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { useLang } from '~/hooks/useLang';
import { Card, CardContent, Divider, List, ListItem, ListItemAvatar, ListItemText, Modal, Typography } from '@mui/material';
import Map from '~/features/Map/components/Map';
import { LatLng } from 'leaflet';
import MarkerGenerator from '~/features/Map/components/MarkerGenerator';
import { Crew, DeviceLogs } from '../../interfaces';

interface ListDeviceLogsProps {
  readonly devices: readonly DeviceLogs[],
}

// eslint-disable-next-line max-lines-per-function
export default function ListDeviceLogs({ devices }: ListDeviceLogsProps) {
  const { translate } = useLang();
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [row, setRow] = useState<DeviceLogs>();
  const [mapMarkers, setMapMarkers] = useState<readonly (readonly [number, number])[]>();
  const [midPoint, setMidPoint] = useState<readonly [number, number]>([0, 0])
  const [open, setOpen] = useState(false);

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '90%',
    width: 500,
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const columns: GridColDef[] = ([
    { field: 'userName', headerName: translate('Connected by'), width: 180 },
    {
      field: 'dateConnected',
      headerName: translate('Connected'),
      width: 180
    },
    {
      field: 'dateDisconnected',
      headerName: translate('Disconnected'),
      width: 180
    },
    { field: 'kmInitial', headerName: translate('Initial Kilometer'), width: 180 },
    { field: 'task', headerName: translate('Task'), width: 180 },
    { field: 'phone', headerName: translate('Phone'), width: 180 },
    { field: 'dispatchGroup', headerName: translate('Dispatch Group'), width: 180 },
    { field: 'crew', headerName: translate('Composition'), width: 180 },
  ]);

  const getCrew = (crew: readonly Crew[]) => {
    if (!crew.length || !crew[0]?.user?.name) {
      return translate('Uninformed')
    }
    if (crew.length === 1 && crew[0]?.user?.name) {
      return crew[0].user.name
    }
    return crew[0].user.name + ' ...'
  }

  useEffect(() => {
    const devicesRows = devices.map((
      deviceRaw: DeviceLogs, index) => {

      const {
        user,
        date_connected: dateConnected,
        date_disconnected: dateDisconnected,
        latitude_connected: latitudeConnected,
        longitude_connected: longitudeConnected,
        latitude_disconnected: latitudeDisconnected,
        longitude_disconnected: longitudeDisconnected,
        km_initial: kmInitial,
        task,
        phone,
        device,
        vehicle,
        crew
      } = deviceRaw;
      return {
        id: index,
        userName: vehicle && user ? user.name : translate('Uninformed'),
        dateConnected: dateConnected ? new Date(dateConnected).toLocaleDateString() : translate('Uninformed'),
        locationConnected: latitudeConnected && longitudeConnected ? [latitudeConnected, longitudeConnected] : translate('Uninformed'),
        dateDisconnected: dateDisconnected ? new Date(dateDisconnected).toLocaleDateString() : translate('Uninformed'),
        locationDisconnected: latitudeDisconnected && longitudeDisconnected ? [latitudeDisconnected, longitudeDisconnected] : translate('Uninformed'),
        kmInitial: kmInitial ? kmInitial : translate('Uninformed'),
        task: task ? task : translate('Uninformed'),
        phone: phone ? phone : translate('Uninformed'),
        dispatchGroup: device?.dispatch_group ? device.dispatch_group.name : translate('Uninformed'),
        crew: getCrew(crew)
      }
    })
    setRows(devicesRows)
  }, [devices])

  useEffect(() => {
    if (mapMarkers?.length === 2) {
      const midpointLatitude = (mapMarkers[0][0] + mapMarkers[1][0]) / 2;
      const midpointLongitude = (mapMarkers[0][1] + mapMarkers[1][1]) / 2;
      setMidPoint([midpointLatitude, midpointLongitude])
    } else if (mapMarkers) {
      setMidPoint(mapMarkers[0])
    }
  }, [mapMarkers])

  const handleOnRowSelection = (event) => {
    if (!devices.length) {
      return;
    }
    handleOpen()

    const rowSelected = devices[event[0]];
    const {
      latitude_connected: latitudeConnected,
      longitude_connected: longitudeConnected,
      latitude_disconnected: latitudeDisconnected,
      longitude_disconnected: longitudeDisconnected,
    } = rowSelected;

    const markers = [
      latitudeConnected,
      longitudeConnected,
      latitudeDisconnected,
      longitudeDisconnected
    ].filter((coordinates) => coordinates !== null);

    if (markers.length > 2) {
      setMapMarkers([
        [markers[0], markers[1]],
        [markers[2], markers[3]]
      ])
    } else {
      setMapMarkers([
        [markers[0], markers[1]]
      ])
    }
    setRow(rowSelected);
  }

  return (
    <div style={{ width: '100%' }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <Card style={{ height: '240px' }}>
            <Map position={new LatLng(midPoint[0] ? midPoint[0] : 0, midPoint[1] ? midPoint[1] : 0)}>
              {/* {mapMarkers?.map((marker, index) => (
                <MarkerGenerator
                  key={index}
                  position={new LatLng(marker[0] ? marker[0] : 0, marker[1] ? marker[1] : 0)}
                  status={row?.device?.status?.id || 1}
                  type={'device'}>
                  <div />
                </MarkerGenerator>
              ))} */}

            </Map>
          </Card>
          
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {translate('Device:name')}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {translate('Connected by')}
              {': '}
              {row?.user?.name || translate('Uninformed')}
              <br />
              {translate('Connected on')}
              {': '}
              {row?.date_connected && new Date(row.date_connected).toLocaleString() || translate('Uninformed')}
              <br />
              {translate('Disconnected on')}
              {': '}
              {row?.date_disconnected && new Date(row.date_disconnected).toLocaleString() || translate('Uninformed')}
              <br />
              {translate('Initial Kilometer')}
              {': '}
              {row?.km_initial || translate('Uninformed')}
              <br />
              {translate('Task')}
              {': '}
              {row?.task || translate('Uninformed')}
              <br />
              {translate('Composition')}
              {' '}
              <br />
              <List sx={{
                padding: 0,
                width: '100%',
                maxWidth: 400,
                maxHeight: 300,
                overflow: 'auto',
              }}>

                {row?.crew.length && row?.crew.map(member => (
                  <>
                    <ListItem sx={{ padding: 0, marginTop: 0 }} alignItems="center">
                      <ListItemText
                        sx={{ display: 'inline' }}
                        primary={
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body1"
                          >
                            {member.position?.name || translate('Uninformed')}
                            {' '}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {member.user?.name || translate('Uninformed')}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                )) || translate('Uninformed')}



              </List>
            </Typography>
          </CardContent>
        </Card>
      </Modal>
      <DataGrid
        autoHeight
        onRowSelectionModelChange={handleOnRowSelection}
        rows={rows}
        columns={columns}
      />
    </div>
  )
}
