import React, { ChangeEvent, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, IconButton, Autocomplete, FormControlLabel, Box, RadioGroup, Radio, FormHelperText } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { authFetch } from '~/services/fetch';
import { useHookstate } from '@hookstate/core';
import { getOperationActive } from '~/features/Operation/services/index';
import CloseIcon from '@mui/icons-material/Close';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import { getDraftCloseComment, saveDraft } from '~/features/Dispatch/services';
import notify from '~/utils/notify';
import { LoadingButton } from '@mui/lab';

interface DispatchClosedProps {
  readonly id: number | null;
  readonly name: string | null;
  readonly acronym: string | null;
  readonly require_consummate_information: boolean | null;
}

interface OperationProps {
  readonly id: number;
  readonly name: string;
  readonly active: boolean;
}

interface IErros {
  readonly closedStatus: string;
  readonly operation: string;
  readonly comment: string;
  readonly isConsummated: string;
}
// eslint-disable-next-line max-lines-per-function
export default function DispatchClosedDialog({ open, closeDispatch }) {
  const { translate } = useLang()
  const { dispatch, dispatchStatusActions } = useDispatchState();
  const { canClosePopup } = dispatchStatusActions();
  const dispatchClosedList = useHookstate<ReadonlyArray<DispatchClosedProps>>([]);
  const operationList = useHookstate<ReadonlyArray<OperationProps>>([]);
  const closedStatus = useHookstate<DispatchClosedProps | null>(null);
  const operation = useHookstate('');
  const comment = useHookstate('');
  const defaultErros = {
    closedStatus: '',
    comment: '',
    operation: '',
    isConsummated: ''
  }

  const errors = useHookstate<IErros>(defaultErros);
  const loading = useHookstate(false);
  const loadingDraft = useHookstate(false);
  const valueConsummated = useHookstate<string | null>(dispatch().get().type?.mustInformConsummate ? 'true' : null);
  const isConsummated = useHookstate<boolean | null>(dispatch().get().type?.mustInformConsummate ? true : null);
  const characterValid = useHookstate<number>(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    errors.isConsummated.set('');
    valueConsummated.set((event.target as HTMLInputElement).value);

    if (valueConsummated.get() === 'true') {
      isConsummated.set(true);
    } else if (valueConsummated.get() === 'false') {
      isConsummated.set(false);
    } else {
      isConsummated.set(null);
    }
  };

  useEffect(() => {
    canClosePopup.set(true)
    getDraftCloseComment(Number(dispatch()?.get()?.id))
      .then((response) => {
        comment.set(response.data.comment);
      })

    authFetch({
      url: '/dispatch/closed-status',
      method: 'GET',
    }).then(({ data }) => {
      if (data.length) {
        dispatchClosedList.set(data.sort((a, b) => a.id - b.id));
      }
    })

    getOperationActive().then((data) => {
      operationList.set(data);
    })

  }, [])

  const handleSaveDraft = () => {
    loadingDraft.set(true)
    saveDraft(Number(dispatch()?.get()?.id), comment.get()).then(() => {
      notify({ message: translate('Draft saved successfully'), type: 'success' });
    }).finally(() => {
      loadingDraft.set(false)
    })
  }

  const clearStates = () => {
    comment.set('');
    operation.set('');
    closedStatus.set(null);
    isConsummated.set(null);
    valueConsummated.set(null);
  }

  const handleClose = () => {
    clearStates();
    open.set(false);
  };

  const clickOutModalClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      open.set(false);
    }
  };

  const handleChangeOperation = (event: SelectChangeEvent) => {
    errors.operation.set('')
    operation.set(event.target.value);
  };

  const hasRequiredFields = () => {
    if (!closedStatus.get()) {
      errors.closedStatus.set(translate('Inform a status'))
      loading.set(false)
      return true;
    } else if (isConsummated.get() === null && dispatch().get().type?.mustInformConsummate && closedStatus.get()?.require_consummate_information) {
      errors.isConsummated.set(translate('Select an option'))
      loading.set(false)
      return true;
    } else if (!comment.get() || !handleMinCharacter(comment.get())) {
      errors.comment.set(translate('Enter a comment with a minimum of 10 valid characters'))
      loading.set(false)
      return true;
    } else {
      return false
    }
  }

  const action = () => {
    loading.set(true)
    if (closeDispatch && !hasRequiredFields()) {
      closeDispatch({
        closedStatusId: closedStatus.get()?.id,
        operation: operation.get() != '' ? operation.get() : null,
        comment: comment.get(),
        isConsummated: isConsummated.get() ?? null
      })?.then(() => {
        clearStates();
        open.set(false);
        valueConsummated.set(null)
      }).finally(() => {
        loading.set(false)
      })
    }

  };

  function handleMinCharacter(str: string) {
    characterValid.set(0)
    const regex = /^[A-Za-z]+$/

    str.split('').map(item => {
      if (regex.test(item)) {
        characterValid.set(characterValid.get() + 1)
      } 
    })

    return characterValid.get() >= 10 ? true : false
  }

  return (
    <Dialog
      open={open.get()}
      onClose={clickOutModalClose}
      disableEscapeKeyDown={true}
      maxWidth={'xs'}
      fullWidth
    >
      <DialogTitle minWidth={"320px"} variant='h5'>
        {translate('Dispatch Close')}
      </DialogTitle>
      <DialogContent>

        <FormControl
          fullWidth
          size='small'
          sx={{ padding: "8px 0" }}
        >
          <Autocomplete
            options={dispatchClosedList.get()}
            noOptionsText={translate('No options')}
            size='small'
            getOptionLabel={(option) => `${option.acronym ? option.acronym + ' ' + option.name : ''}`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={closedStatus.get()}
            onChange={(event, value) => {
              errors.closedStatus.set('')
              closedStatus.set({ id: value?.id ?? null, name: value?.name ?? '', acronym: value?.acronym ?? '', require_consummate_information: value?.require_consummate_information ?? false });
            }}
            renderInput={(params) =>
              <TextField {...params}
                error={!!errors.closedStatus.get()}
                label={`${translate('Closed Status')}`}
              />
            }
          />
        </FormControl>

        {operationList.get().length ?
          <FormControl
            fullWidth
            size='small'
            error={!!errors.operation.get()}
          >
            <InputLabel size='small' sx={{ mt: 1 }} id='operation' >
              {translate('Operation')}
            </InputLabel>
            <Select
              labelId='operation'
              id='operation'
              value={operation.get()}

              label={translate('Operation')}
              size='small'
              displayEmpty={true}
              sx={{ mt: 1 }}
              onChange={handleChangeOperation}
              endAdornment={
                operation.get() != '' ? (
                  <IconButton onClick={() => operation.set('')} sx={{ marginRight: 2 }}>
                    <CloseIcon />
                  </IconButton>
                ) : false
              }
            >
              {operationList.get().map((operation, index) => (
                <MenuItem sx={{ mt: 1 }} key={index} value={operation.id}>
                  {`${operation.name}`}
                </MenuItem>
              ))}

            </Select>
          </FormControl>
          : false
        }

        {dispatch().get().type?.mustInformConsummate && closedStatus.get()?.require_consummate_information
          ?
          <FormControl required={dispatch().get().type?.mustInformConsummate} error={!!errors.isConsummated.get()}>
            <RadioGroup
              row
              name="must-inform-consummate"
              value={valueConsummated.get()}
              onChange={handleChange}
            >
              <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 2, marginY: 1 }}>
                <FormControlLabel value={'true'} control={<Radio />} label={translate("consummated")} />
                <FormControlLabel value={'false'} control={<Radio />} label={translate("attempted")} />
                <FormHelperText>
                  {errors.isConsummated.get()}
                </FormHelperText>
              </Box>
            </RadioGroup>
          </FormControl>
          : false
        }

        <TextField
          multiline
          rows={15}
          error={!!errors.comment.get()}
          required={true}
          name={'comment'}
          id='comment'
          label={translate('Comment')}
          fullWidth
          size='small'
          value={!comment.get() ? '' : comment.get()}
          sx={{ mt: 1 }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            handleMinCharacter(event.target.value) && errors.comment.set('')
            comment.set(event.target.value)


          }}
        />
        <FormHelperText sx={{fontSize: 'md', color: 'red'}}>
          {errors.comment.get()}
        </FormHelperText>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingX: "24px" }}>
        <Box>
          <LoadingButton
            loading={loadingDraft.get()}
            variant='text'
            onClick={handleSaveDraft}
            sx={{
              height: '40px',
              textTransform: 'none',
              textDecorationLine: 'underline',
              color: 'text.primary',
              ':hover': {
                bgcolor: 'transparent',
                color: 'primary.main'
              }
            }}
            >
            {translate('Save draft')}
          </LoadingButton>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant='outlined'
            onClick={handleClose}>
            {translate('Cancel')}
          </Button>
          <LoadingButton
            loading={loading.get()}
            variant='contained'
            onClick={action}>
            {translate('Submit')}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}