import { useLang } from "~/hooks/useLang";
import { authFetch } from "~/services/fetch";
import notify from "~/utils/notify";


export async function validateInterestPoint({ id }) {
  const response = await authFetch({
    url: `/interest-point/${id}/validate`,
    method: 'PUT'
  })

  return response
}

export async function deleteInterestPoint({ id }) {
  const response = await authFetch({
    url: `/interest-point/${id}`,
    method: 'DELETE'
  })

  return response
}

export async function getInfoInterestPoint({ id }) {
  const response = await authFetch({
    url: `/interest-point/${id}`,
    method: 'GET'
  })

  return response.data
}

export async function forceExpire ({id}) {
  const response = await authFetch({
    url: '/interest-point/force-expire',
    data: {
      interest_point_id: id
    }
  })

  return response
}


export async function getAllInterestPoint({
  notes,
  interestPointTypeId,
  interestPointSubtypeId,
  agencyId,
  notValidated,
  onlyValidated,
  needReview,
  expired,
  reviewStartDate,
  reviewFinishDate,
  expiredStartDate,
  expiredFinishDate,
  orders,
}) {
  const response = await authFetch({
    url: '/interest-point',
    method: 'POST',
    data: {
      limit: 100000,
      filters: [],
      orders: orders,
      notes: notes,
      interest_point_type_id: interestPointTypeId,
      interest_point_subtype_id: interestPointSubtypeId,
      agency_id: agencyId,
      not_validated: notValidated,
      only_validated: onlyValidated,
      need_review: needReview,
      expired: expired,
      review_start_date: reviewStartDate,
      review_finish_date: reviewFinishDate,
      expired_start_date: expiredStartDate,
      expired_finish_date: expiredFinishDate,

    }
  })

  return response.data
}