import { useHookstate } from "@hookstate/core";
import React, { useEffect } from "react"
import ReactDOM from "react-dom";
import StandardModal from "~/components/StandardModal";
import useSystemState from "~/features/System/stores/SystemState";
import { useLang } from "~/hooks/useLang";
import { InsertDriveFile } from '@mui/icons-material';
import SearchStaff from "../SearchStaff";
import ScreenSettingsDraggableProps from "~/features/Entry/interfaces/IPositionDraggable";
import { authFetch } from "~/services/fetch";
import useScreenSettingsState from "~/stores/ScreenSettingsState";
import { storeScreenSettings } from "~/features/System/services";


const modal = document.getElementById('div-modal') as HTMLElement;


export default function SearchStaffModalDraggable() {
  const { translate } = useLang();
  const { windowManager, loadingButton } = useSystemState();
  
  const handleStop = (event, dragElement) => {
    event.stopPropagation();

    windowManager()['searchStaff'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y
    })
  };

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'SearchStaffModalDraggable',
        isOpen: windowManager()['searchStaff'].open.get(),
        isMinimized: windowManager()['searchStaff'].minimized.get(),
        positionX: windowManager()['searchStaff'].positionX.get(),
        positionY: windowManager()['searchStaff'].positionY.get()
      })
    }
  }, [
    windowManager()['searchStaff'].open.get(),
    windowManager()['searchStaff'].minimized.get(),
    windowManager()['searchStaff'].positionY.get(),
    windowManager()['searchStaff'].positionX.get()
  ])


  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        !loadingButton.get() &&
        <StandardModal
          title={`${translate("Search staffs")}`}
          avatar={InsertDriveFile}
          dimensions={{
            width: 400
          }}
          isExpanded={windowManager()['searchStaff'].minimized}
          handleStop={handleStop}
          position={{
            x: windowManager()['searchStaff'].positionX?.get() ? Number(windowManager()['searchStaff'].positionX?.get()) : 250,
            y: windowManager()['searchStaff'].positionY?.get() ? Number(windowManager()['searchStaff'].positionY?.get()) : 100
          }}

          closeButton={() => windowManager()['searchStaff'].open.set(false)}
        >
          <SearchStaff />
        </StandardModal>
      }
    </>
    , modal);
}