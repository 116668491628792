import React, { useEffect } from 'react';
import Layout from '~/components/Layout';
import InternalContent from '~/components/Layout/components/InternalContent';
import { useLang } from '~/hooks/useLang';
import { Grid, IconButton, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ObserverOccurrenceForClerk from '~/features/Clerk/components/ObserverOccurrenceForClerk';
import AgenciesOccurrenceForClerk from '~/features/Clerk/components/AgenciesOccurrenceForClerk';
import ChronologyOccurrenceForClerk from '~/features/Clerk/components/ChronologyOccurrenceForClerk';
import WhiteboardForClerk from '~/features/Clerk/components/WhiteboardForClerk';
import { Downgraded, useHookstate } from '@hookstate/core';
import { useParams } from 'react-router-dom';
import SyncIcon from '@mui/icons-material/Sync';
import QualificatorsForClerk from '../components/QualificatorsOccurrenceForClerk';
import InternalHeader from '~/components/Layout/components/InternalHeader';
import { authFetch } from '~/services/fetch';
import { getReportClerk } from '../services';

interface requestProps {
  readonly occurrence_id: string,
  readonly dispatch_code_reference: string
}

export default function Create() {
  const {
    occurrence_id: occurrenceId,
    dispatch_code_reference: dispatchCodeReference,
  }: requestProps = useParams();
  const { translate } = useLang();
  const tab = useHookstate('1');
  const reloadOverview = useHookstate(false);
  const reloadAgencies = useHookstate(false);
  const reloadQualificators = useHookstate(false);
  const reloadChronology = useHookstate(false);
  const loading = useHookstate(false);
  const loadingComments = useHookstate(false);
  const clerkReport = useHookstate<string>('');
  const commentsOccurrence = useHookstate<string[] | null>(null);

  useEffect(() => {
    loading.set(true);
    getReportClerk({ occurrenceId: occurrenceId }).then((response) => {
      clerkReport?.set(response.report)
    }).finally(() => {
      loading.set(false)
    })

  }, [])

  useEffect(() => {
    document.title = translate('Clerk - Dispatch System')
  }, [])

  const handleTab = (event: React.SyntheticEvent, value: '1') => {
    tab.set(value);
  };

  useEffect(() => {
    loadingComments.set(true)
    commentsOccurrence.set(null)

    authFetch({
      url: `/occurrence/${occurrenceId}/timeline`,
      method: 'POST',
    }).then((response) => {
      response.data.map((info) => {
        if (info.event_name == 'OCCURRENCE_COMMENT') {
          commentsOccurrence.merge([info.content])
        }
      }
      )
    })
    loadingComments.set(false)
  }, [])

  const reloadInfoForClerk = () => {
    switch (tab.get()) {
      case '1':
        reloadOverview.set(!reloadOverview.get());
        break;
      case '2':
        reloadAgencies.set(!reloadAgencies.get());
        break;
      case '3':
        reloadQualificators.set(!reloadQualificators.get());
        break;
      case '4':
        reloadChronology.set(!reloadChronology.get());
    }
  }

  return (
    <Layout marginLayout={true}>
      <InternalHeader title={`${translate(`Occurrence`)}: ${occurrenceId} - ${translate('Code of dispatch')}: ${dispatchCodeReference}`} />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InternalContent expandButton={false}>
            <Grid item display={'flex'} justifyContent={'space-between'} padding={0}>

              <Grid style={{ height: '80vh', marginLeft: 1, width: '100%' }}>

                <TabContext value={tab.get()}>

                  <TabList onChange={handleTab}>
                    <Tab label={translate('Informações Gerais')} value='1' disabled={loading.get()} />
                    <Tab label={translate('Agências')} value='2' disabled={loading.get()} />
                    <Tab label={translate('Registros')} value='3' disabled={loading.get()} />
                    <Tab label={translate('Cronologia')} value='4' disabled={loading.get()} />
                  </TabList>

                  <TabPanel sx={{ padding: 2 }} value='1'>
                    <ObserverOccurrenceForClerk
                      occurrenceId={Number(occurrenceId)}
                      reloadInfo={reloadOverview}
                      loading={loading}
                    />
                  </TabPanel>

                  <TabPanel sx={{ padding: 2 }} value='2'>
                    <AgenciesOccurrenceForClerk
                      occurrenceId={Number(occurrenceId)}
                      reloadInfo={reloadAgencies}
                      loading={loading}
                    />
                  </TabPanel>

                  <TabPanel sx={{ padding: 2 }} value='3'>
                    <QualificatorsForClerk
                      occurrenceId={Number(occurrenceId)}
                      reloadInfo={reloadQualificators}
                      loading={loading}
                    />
                  </TabPanel>

                  <TabPanel sx={{ padding: 2 }} value='4'>
                    <ChronologyOccurrenceForClerk
                      occurrenceId={Number(occurrenceId)}
                      reloadInfo={reloadChronology}
                      loading={loading}
                    />
                  </TabPanel>
                </TabContext>
              </Grid>
              <Grid textAlign={'right'} height={'48px'}>
                <Grid>
                  <IconButton
                    size="large"
                    title={translate('Updated')}
                    onClick={() => reloadInfoForClerk()}
                  >
                    <SyncIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </InternalContent>
        </Grid>
        <Grid item xs={6}>
          <InternalContent expandButton={false}>
            <Grid style={{ height: '80vh' }}>
              <WhiteboardForClerk
                occurrenceId={Number(occurrenceId)}
                loadingComments={loadingComments.get()}
                comments={commentsOccurrence.attach(Downgraded).get()}
                report={clerkReport.attach(Downgraded).get()}
              />
            </Grid>
          </InternalContent>
        </Grid>
      </Grid>
    </Layout>
  )
}
