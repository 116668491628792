import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core';
import { getEntryByCenter } from '../../services';
import { IEntryStatisticGraphProps } from '../../interfaces/IEntryStatistic';
import PieChart from '~/components/PieChart';

export default function EntryByCenterPieChart({ agencyId, deviceId, typeId, subtypeId, startDate, endDate, exludeClosed = false, showTitle = false, refresh, loading, cityId }: IEntryStatisticGraphProps) {
  const data = useHookstate([]);

  useEffect(() => {
    loading.set(true)
    getEntryByCenter({ agencyId, deviceId, typeId, subtypeId, startDate, endDate, exludeClosed, cityId }).then((response) => {
      const result = response.map((data) => ({ item: data.name, value: data.total }));
      return data.set(result);
    }).finally(() => {
      loading.set(false)
    })
  }, [refresh]);

  return (
    <PieChart data={data.attach(Downgraded).get()} title={`${showTitle ? 'Center' : ''}`} />
  )
}