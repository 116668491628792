import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IAreaPlan from '../interfaces/IAreaPlan';
import Layout from '~/components/Layout';



export default function Create() {
  const { register, handleSubmit, control, setValue: setFormValue } = useForm<IAreaPlan>();
  const fields = [
    { name: 'name', type: 'string' },
  ];

  return (
    <Layout marginLayout={true}>
      <CrudCreate model={'area-plan'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Area plan successfully created!'}
        title={'Add area plan'}
        subheader={'Add area plan'}
        setFormValue={setFormValue} />
    </Layout>
  )
}
