import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IStation from '../interfaces/IStation';
import { useParams } from 'react-router-dom';
import ICrudEditParams from '~/components/CrudEdit/interfaces/ICrudEditParams';

export default function Edit() {
  const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm<IStation>();
  const fields = [
    { name: 'id', type: 'hidden' },
    { name: 'name', type: 'string', required: true, readonly: true },
    { name: 'agency_id', type: 'selectAutoComplete', model: 'agency', placeholder: 'Agency:name', required: true },
    { name: 'contact_center_id', type: 'selectAutoComplete', model: 'contact-center', placeholder: 'Contact Center', method: 'GET' },
    { name: 'branch_line', type: 'string', placeholder: 'Branch Line' },
    { name: 'serial_hd', type: 'string', placeholder: 'Serial HD' },
    { name: 'mac_address', type: 'string', placeholder: 'MAC Address' },
    { name: 'dispatch_groups', type: 'transferList', options: { firstListUrl: `/station/${useParams<ICrudEditParams>().id}/get-dispatch-groups`, secondListUrl: '/dispatch/group' }, placeholder: 'Associated Dispatch Group' }
  ];

  return (
    <CrudEdit
      model={'station'}
      fields={fields}
      register={register}
      handleSubmit={handleSubmit}
      control={control}
      setFormValue={setFormValue}
      messageSuccess={'Station successfully updated!'}
      title={'Edit Work Station'}
      getValues={getValues}
    />
  )
}
