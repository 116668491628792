/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom'
import { InsertDriveFile } from '@mui/icons-material';
import EntryRecall from '../EntryRecall';
import StandardModal from '../../../../components/StandardModal';
import { useLang } from '../../../../hooks/useLang';
import useEntryState from '../../stores/EntryState';
import { useHookstate, useState } from '@hookstate/core';
import { useLocation } from 'react-router';
import useSystemState from '~/features/System/stores/SystemState';
import { authFetch } from '~/services/fetch';
import { storeScreenSettings } from '~/features/System/services';

const modal = document.getElementById('div-modal') as HTMLElement;

export default function EntryRecallModalDraggable() {
  const { translate } = useLang()
  const { windowManager, loadingButton, systemConfig} = useSystemState();

  const handleStop = (event, dragElement) => {
    event.stopPropagation();

    windowManager()['entryRecall'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y
    })
  };

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'EntryRecallModalDraggable',
        isOpen: windowManager()['entryRecall'].open.get(),
        isMinimized: windowManager()['entryRecall'].minimized.get(),
        positionX: windowManager()['entryRecall'].positionX.get(),
        positionY: windowManager()['entryRecall'].positionY.get()
      })
    }
  }, [
    windowManager()['entryRecall'].open.get(),
    windowManager()['entryRecall'].minimized.get(),
    windowManager()['entryRecall'].positionY.get(),
    windowManager()['entryRecall'].positionX.get()
  ])

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        !loadingButton.get() &&
        <StandardModal
          title={`${translate("Last Occurrence")}`}
          subheader={`${translate('Latest occurrences within a radius of 500m and hours', { hours: systemConfig().occurrenceSearchPeriodInHours.get() })}`}
          avatar={InsertDriveFile}
          dimensions={{
            width: 900
          }}
          handleStop={handleStop}
          isExpanded={windowManager()['entryRecall'].minimized}
          position={{
            x: windowManager()['entryRecall'].positionX?.get() ? Number(windowManager()['entryRecall'].positionX.get()) : 250,
            y: windowManager()['entryRecall'].positionY?.get() ? Number(windowManager()['entryRecall'].positionY.get()) : 100
          }}
          closeButton={() => windowManager()['entryRecall'].open.set(false)}
        >
          {windowManager()['entryRecall'].minimized.get() &&
            <EntryRecall />
          }

        </StandardModal>
      }
    </>
    , modal);
}
