import React, { useCallback, useEffect } from 'react';
import { useHookstate } from '@hookstate/core';
import { Grid, Typography } from '@mui/material';
import Layout from '~/components/Layout';
import InternalContent from '~/components/Layout/components/InternalContent';
import InternalHeader from '~/components/Layout/components/InternalHeader';
import CountStatsCard from '~/components/CountStatsCard';
import { useLang } from '~/hooks/useLang';
import userUserState from '~/features/User/stores/UserState';
import { getDispatchDashboardTodayService } from '~/features/Dispatch/services';
import { getEntryDashboardTodayService } from '~/features/Entry/services';
import useSystemState from '~/features/System/stores/SystemState';
import { getSystemConfigService } from '~/features/System/services';
import { toCamelCase } from '~/utils/strings';
import dayjs from 'dayjs';

interface dispatchDashboardProps {
  readonly total_dispatches_openeds: number,
  readonly total_dispatches_attendances: number,
  readonly total_occurrences_on_hold: number,
  readonly total_dispatches_closeds: number,
  readonly total_occurrences_openeds: number,
  readonly total_occurrences_closeds: number,
  readonly total_occurrences_tlia: number,
  readonly total_occurrences_recall: number,
  readonly total_occurrences: number,
  readonly total_dispatches: number
}

interface entryDashboardProps {
  readonly total_entry_openeds: number,
  readonly total_entry_closeds: number,
  readonly total_entry_tlia: number,
  readonly total_entry_recall: number,
  readonly total_entries: number
}

export default function Home() {
  const { translate } = useLang();
  const { verifyPermission } = userUserState();
  const { getStatusColors, systemConfig } = useSystemState();
  const { systemTime: { hour, second, minute, day, month, year } } = systemConfig();

  useEffect(() => {
    document.title = translate('Dispatch System')
  }, [])


  const dataDispatch = useHookstate<dispatchDashboardProps>({
    total_dispatches_openeds: 0,
    total_dispatches_attendances: 0,
    total_occurrences_on_hold: 0,
    total_dispatches_closeds: 0,
    total_occurrences_openeds: 0,
    total_occurrences_closeds: 0,
    total_occurrences_tlia: 0,
    total_occurrences_recall: 0,
    total_occurrences: 0,
    total_dispatches: 0
  })

  const dataEntry = useHookstate<entryDashboardProps>({
    total_entry_openeds: 0,
    total_entry_closeds: 0,
    total_entry_tlia: 0,
    total_entry_recall: 0,
    total_entries: 0
  })

  const getSystemConfigRequest = useCallback(async () => {
    const configs: readonly { readonly name: string; readonly value: string; }[] = await getSystemConfigService()
    const configKeys = systemConfig().keys;
    configs.forEach((config) => {
      if (configKeys.includes(toCamelCase(config.name) as never)) {
        systemConfig()[toCamelCase(config.name)].set(config.value);
      }
    });
  }, []);

  const getEntryDashboardRequest = useCallback(async () => {
    const response = await getEntryDashboardTodayService();
    dataEntry.set(response);
  }, []);

  const getDispatchDashboardRequest = useCallback(async () => {
    const response = await getDispatchDashboardTodayService();
    dataDispatch.set(response);
  }, []);

  useEffect(() => {
    const now = dayjs();
    hour.set(now.hour())
    second.set(now.second())
    minute.set(now.minute())
    day.set(now.date())
    month.set(now.month() + 1)
    year.set(now.year())


    getSystemConfigRequest();


    if(verifyPermission(['entry_index'])) {
      getEntryDashboardRequest();
    }
    if (verifyPermission(['dispatch_index'])) {
      getDispatchDashboardRequest();
    } 

  }, [])

  return (
    <Layout marginLayout={true}>
      <InternalHeader
        subheader={
          <Typography
            sx={{ color: 'orange' }}
          >
            {translate('Data displayed is for the last 12 hours')}
          </Typography>}
        title={translate(`My Panel`)}
      />

      {(verifyPermission(['entry_index'])) && (
        <InternalContent title={translate(`Entry`)}>
          <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 10 }}>
            <CountStatsCard name={'Chamados em aberto'} color={getStatusColors(1).main} value={dataEntry.total_entry_openeds.get()} />
            <CountStatsCard name={'TLIA'} color={getStatusColors(11).main} value={dataEntry.total_entry_tlia.get()} />
            <CountStatsCard name={'Rechamadas'} color={getStatusColors(4).main} value={dataEntry.total_entry_recall.get()} />
            <CountStatsCard name={'Atendimento realizados'} color={getStatusColors(8).main} value={dataEntry.total_entry_closeds.get()} />
            <CountStatsCard name={'Total de atendimentos'} color={getStatusColors(12).main} value={dataEntry.total_entries.get()} />
          </Grid>
        </InternalContent>)
      }

      {(verifyPermission(['dispatch_index'])) && (
        <InternalContent title={translate(`Dispatch`)}>
          <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 10 }}>
            <CountStatsCard name={'Despachos Pendentes'} color={getStatusColors(1).main} value={dataDispatch.total_dispatches_openeds.get()} />
            <CountStatsCard name={'Despachos Em Andamento'} color={getStatusColors(1).dark} value={dataDispatch.total_dispatches_attendances.get()} />
            <CountStatsCard name={'Despachos Retidos'} color={getStatusColors(9).main} value={dataDispatch.total_occurrences_on_hold.get()} />
            <CountStatsCard name={'Despachos Finalizados'} color={getStatusColors(8).main} value={dataDispatch.total_dispatches_closeds.get()} />
            <CountStatsCard name={'Total de Despachos'} color={getStatusColors(12).main} value={dataDispatch.total_dispatches.get()} />
          </Grid>
        </InternalContent>
      )
      }
    </Layout>
  )
}