/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { State, useHookstate } from '@hookstate/core';
import { getDispatchClosingCommentsService } from '~/features/Dispatch/services/index';
import { PostCommentResponse } from '~/features/Dispatch/stores/DispatchCommentState';
import { dateTimeFormat } from '~/utils/dateTime';
import dayjs from 'dayjs';

interface ClosedCommentsDispatchPrintProps {
  readonly ClosedCommentsDispatchPrintResponse: any
}

export default function ClosedCommentsDispatchPrint({ClosedCommentsDispatchPrintResponse }: ClosedCommentsDispatchPrintProps){
  const { translate } = useLang();

  return (
    <Container fixed={false}>
    <Grid container xs={12} sx={{ marginBottom: 2 }}>
      { ClosedCommentsDispatchPrintResponse.length != 0 ? (
        ClosedCommentsDispatchPrintResponse.map((comment) => (
          <Grid container key={comment?.id} xs={12} sx={{ margin: 1 }}>
              <Grid item xs={12} sx={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                <Typography variant='subtitle1'>{comment?.comment ?? `* ${translate('Uninformed')} *`} </Typography>
              </Grid>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: 2 }}>
                <Typography variant='caption' sx={{ alignItems: 'flex-start' }}><b>{translate('User')}: {comment?.user?.representation_name}</b> - {comment?.createdAt && dayjs(comment?.createdAt).isValid() ? dayjs(comment?.createdAt).format('DD/MM/YYYY HH:mm:ss') : ''}</Typography>
              </Grid>
          </Grid>
        ))
      ) : (
      <Grid item xs={12} textAlign={'center'}>
        <Typography variant='subtitle1'><b>{translate('NO INFORMATION REGISTERED')}</b></Typography>
      </Grid>
      )}
    </Grid>
  </Container>
  )
}