import React, { useEffect, useState } from 'react';
import { getLogoBase64 } from './services';
import { useLocalStorage } from '~/hooks/useStorage';
import { getWindowPathName } from '~/utils/path';
import ImageRenderer from '../ImageRender';
import './styles.scss';
import { useHookstate } from '@hookstate/core';
import { useLang } from '~/hooks/useLang';

export type LogoStorageType = { readonly src: string };

export default function Logo({ height = '100%', base64 = true }) {
    const [localValue, setLocalValue] = useLocalStorage<LogoStorageType>('@Despacho:logo');
    const srcState = useHookstate(() => { if (localValue) { return localValue.src; } return ""; });

    const byPassPaths = React.useRef(['/login', '/login/no-station', '/activation']);

    useEffect(() => {
        const currentPath = getWindowPathName();
        const bypass = byPassPaths.current.includes(currentPath);

        if (srcState.get() && !bypass) return;

        const { promise, cancelToken } = getLogoBase64();
        promise
            .then(response => {
                const statusCode = response.status;
                const data = response.data;

                if (statusCode === 204) return;
                if (!data) return; // upload one!

                const payload: LogoStorageType = {
                    src: data
                }
                srcState.set(data);
                setLocalValue(payload);

            })
            .catch(console.error);

        return () => cancelToken.abort();
    }, []);

    if (!srcState.get()) {
        return null
    }
    return (
        <ImageRenderer
            base64={base64}
            height={height}
            src={srcState.get()}
            alt="App Logo"
        />
    )

}