import React, { useEffect } from 'react';
import { Button, Collapse, FormControlLabel, Grid, IconButton, InputAdornment, OutlinedInput, Switch, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { State, useHookstate } from '@hookstate/core';
import FilterAutocompleteAgency from '~/components/FilterAutocompleteAgency';
import FilterAutocompleteTypeInterestPoint from '~/components/FilterAutocompleteTypeInterestPoint';
import FilterAutocompleteSubtypesByTypeInterestPoint from '~/components/FilterAutocompleteSubtypesByTypeInterestPoint';
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod';
import SelectBasic from '~/components/SelectBasic';
import { LoadingButton } from '@mui/lab';
import { useLang } from '~/hooks/useLang';

interface PointsInterestFiltersProps {
  readonly loadingButton: State<boolean>;
  readonly labelButton?: State<string>;
  readonly hidden?: boolean;
  readonly nameOrObservations?: State<string | null>;
  readonly agencyId?: State<number | null>;
  readonly isValidated?: State<boolean | null>;
  readonly isExpired?: State<boolean>;
  readonly isReviewed?: State<boolean>;
  readonly validatedIn: State<string | null>;
  readonly deletedIn: State<string | null>;
  readonly typeInterestPointId: State<number | null>;
  readonly subtypeInterestPointId: State<number | null>;
  readonly initialPeriodValidated: State<string>;
  readonly initialPeriodExpired: State<string>;
  readonly notValidated: State<boolean | null>;
  readonly onlyValidated: State<boolean | null>;

  readonly searchButton?: () => void;
  readonly clearFilters: () => void;
  readonly onStartDateChangeValidated: (value: string | null) => void;
  readonly onFinishDateChangeValidated: (value: string | null) => void;
  readonly onPeriodChangeValidated: (value: string) => void;

  readonly onStartDateChangeExpired: (value: string | null) => void;
  readonly onFinishDateChangeExpired: (value: string | null) => void;
  readonly onPeriodChangeExpired: (value: string) => void;

}

// eslint-disable-next-line max-lines-per-function
export default function PointsInterestFilters({
  loadingButton,
  labelButton,
  hidden,
  nameOrObservations,
  agencyId,
  isExpired,
  isReviewed,
  typeInterestPointId,
  subtypeInterestPointId,
  initialPeriodValidated,
  initialPeriodExpired,
  onlyValidated,
  notValidated,
  onStartDateChangeValidated,
  onFinishDateChangeValidated,
  onPeriodChangeValidated,
  onStartDateChangeExpired,
  onFinishDateChangeExpired,
  onPeriodChangeExpired,
  searchButton,
  clearFilters,
}: PointsInterestFiltersProps) {
  const { translate } = useLang();
  const selectIsValidated = useHookstate<string | null>(null);
  const optionsSelect = useHookstate([
    { id: 1, name: 'Nenhum' },
    { id: 2, name: 'Validado' },
    { id: 3, name: 'Pendente validação' },
    { id: 4, name: 'Requer revisão' },
    { id: 5, name: 'Expirado' },
  ])

  useEffect(() => {

    switch (String(selectIsValidated.get())) {
      case '1':
        selectIsValidated.set(null)
        break;
      case '2':
        onlyValidated.set(true)
        notValidated.set(false)
        isReviewed?.set(false)
        isExpired?.set(false)
        break;
      case '3':
        notValidated.set(true)
        onlyValidated.set(false)
        isReviewed?.set(false)
        isExpired?.set(false)
        break;
      case '4':
        isReviewed?.set(true)
        notValidated.set(false)
        onlyValidated.set(false)
        isExpired?.set(false)
        break;
      case '5':
        isExpired?.set(true)
        isReviewed?.set(false)
        notValidated.set(false)
        onlyValidated.set(false)
        break;
      default:
        isExpired?.set(false)
        isReviewed?.set(false)
        notValidated.set(false)
        onlyValidated.set(false)
        selectIsValidated.set(null)
    }
  }, [selectIsValidated.get()])
  return (
    <Collapse in={hidden}>
      <Grid container xs={12}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid container direction={'column'} display={'flex'} gap={1}>
          <Grid container direction={'row'}>
            <Grid item xs={4} sx={{ pl: 1, pt: 1 }}>
              <OutlinedInput
                fullWidth
                id="outlined-search"
                value={nameOrObservations?.get()}
                size='small'
                placeholder={translate('Name or observations')}
                onChange={(event) => {
                  nameOrObservations?.set(event.target.value)
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle clear name or observation"
                      onClick={() => {
                        nameOrObservations?.set('')
                      }}
                      disabled={nameOrObservations?.get()?.length ? false : true}
                      edge="end"
                    >
                      <CancelIcon fontSize='small' />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
              <FilterAutocompleteAgency
                agencyId={agencyId?.get()}
                onAgencyChange={(agency) =>
                  agencyId?.set(agency)}
              />
            </Grid>
            <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
              <FilterAutocompleteTypeInterestPoint
                typeInterestPointId={typeInterestPointId?.get()}
                disabled={false}
                onTypeInterestPointChange={(typeInterestPoint) => typeInterestPointId?.set(Number(typeInterestPoint?.id) ?? null)}
              />
            </Grid>
            <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
              <FilterAutocompleteSubtypesByTypeInterestPoint
                typeInterestPointId={typeInterestPointId.get()}
                subtypesInterestPoint={subtypeInterestPointId.get() ? subtypeInterestPointId.get() : null}
                disabled={!typeInterestPointId.get()}
                onSubtypesInterestPointChange={(subtypeInterestPoint) =>
                  subtypeInterestPointId?.set(subtypeInterestPoint?.id ?? null)}
              />
            </Grid>
            <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
              <SelectBasic
                state={selectIsValidated}
                options={optionsSelect.get()}
                placeholder={translate('Status')}
                size={'small'}
                disabled={false}
              />
            </Grid>
          </Grid>
          <Grid container width={'100%'} display={'flex'} alignItems={'center'} direction={'row'} xs={12} >
            <Grid
              xs={4}
              display={'flex'}
              direction={'column'}
              alignItems={'flex-start'}
            >
              <Typography paddingLeft={1}>
                {`${translate('Reviewed in')}:`}
              </Typography>
              <Grid item xs={12} display={'flex'} direction={'row'} width={'100%'} >
              <FilterSelectPeriod
                onStartDateChange={(value) => onStartDateChangeValidated(value?.toJSON() ?? null)}
                onFinishDateChange={(value) => onFinishDateChangeValidated(value?.toJSON() ?? null)}
                onPeriodChange={onPeriodChangeValidated}
                period={initialPeriodValidated.get()}
                xsDateBetween={6}
                hidePeriod={true}
                marginDateBetween={0}
                disableFuture={false}
              />
              </Grid>
            </Grid>

            <Grid
              xs={4}
              display={'flex'}
              direction={'column'}
              alignItems={'flex-start'}
            >
              <Typography paddingLeft={1}>
                {translate('Expired in')}
              </Typography>
              <Grid item xs={12} display={'flex'} direction={'row'} width={'100%'} >
              <FilterSelectPeriod
                onStartDateChange={(value) => onStartDateChangeExpired(value?.toJSON() ?? null)}
                onFinishDateChange={(value) => onFinishDateChangeExpired(value?.toJSON() ?? null)}
                onPeriodChange={onPeriodChangeExpired}
                period={initialPeriodExpired.get()}
                xsDateBetween={6}
                hidePeriod={true}
                marginDateBetween={0}
                disableFuture={false}
              />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction={'row'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          pt: 2,
          gap: 2,
        }}
      >
        <Button variant="outlined"
          onClick={() => {
            clearFilters(),
              selectIsValidated.set(null)
          }}>
          {translate('Clear Filters')}
        </Button>
        <LoadingButton
          loading={loadingButton.get()}
          variant="contained"
          onClick={searchButton}
        >
          {labelButton?.get()}
        </LoadingButton>
      </Grid>
    </Collapse >
  )
}