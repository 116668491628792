import IEntry from '../interfaces/IEntry';

export function parseDataEntry(dataEntry): IEntry {
  return {
    id: dataEntry.id || null,
    name: dataEntry.name || null,
    phone: dataEntry.phone || null,
    address: dataEntry.address || null,
    reference: dataEntry.address_reference || null,
    location: dataEntry.location || '',
    crossingStreet: dataEntry.crossing_street || null,
    coordinates: dataEntry.coordinates || null,
    latitude: dataEntry.latitude || null,
    longitude: dataEntry.longitude || null,
    entryOriginId: dataEntry.entry_origin_id || '',
    stateId: dataEntry.state_id || null,
    cityId: dataEntry.city_id || null,
    districtId: dataEntry.district_id || null,
    typeId: dataEntry.type_id || null,
    subtypeId: dataEntry.subtype_id || null,
    subtypeName: dataEntry.subtype_name || null,
    typeName: dataEntry.typeName || null,
    createdAt: dataEntry.created_at || null,
    vehicle: {
      plate: dataEntry?.vehicles[0]?.plate ? dataEntry?.vehicles[0]?.plate : null,
      circunstanceId: dataEntry?.vehicles[0]?.circunstance_id ? dataEntry?.vehicles[0]?.circunstance_id : null,
      externalKeyTracking: dataEntry?.vehicles[0]?.external_key_tracking ? dataEntry?.vehicles[0]?.external_key_tracking : null,
    },
    vehicles: dataEntry?.vehicles,
    totalSubtypes: dataEntry?.totalSubtypes ?? 0,
    totalDistricts: dataEntry?.totalDistricts ?? 0
  }
}