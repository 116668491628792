import React from 'react';
import { StateMethods } from '@hookstate/core';
import { Button, Collapse, Grid } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { InputSearchWithClear } from '~/components/InputSearchWithClear';
import { ListVehicleTab } from '../../interfaces/IListVehiclesProps';

interface VehiclesFiltersProps {
    readonly tab: ListVehicleTab;
    readonly searchVehicle: StateMethods<string>;
    readonly searchTask: StateMethods<string>;
    readonly clearFilters: () => void;
}

export default function VehiclesFilters({ clearFilters, tab, searchVehicle, searchTask }: VehiclesFiltersProps) {
    const { translate } = useLang();

    return (
        <Grid
            container
            direction={'row'}
            sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
            }}>
            <Grid item xs={2} sx={{ pt: 1 }}>
                <InputSearchWithClear sx={{ width: '100%' }} placeholder={translate('Enter a device')} search={searchVehicle} />
            </Grid>
            {tab !== 'disconnected' && <Grid item xs={4} sx={{ pl: 2, pt: 1 }}>
                <InputSearchWithClear sx={{ width: '100%' }} placeholder={translate('Enter a task')} search={searchTask} />
            </Grid>}
            <Grid item xs={3} sx={{ pl: 2, pt: 1 }}>
                <Button variant="outlined" onClick={clearFilters}>
                    {translate('Clear Filters')}
                </Button>
            </Grid>
        </Grid>
    )
}