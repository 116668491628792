/* eslint-disable max-lines-per-function */
import React from 'react';
import { authFetch } from '~/services/fetch';

export async function getDispatchByDevice(agencyId, deviceId, operationId, dispatchGroupId, categoryIds, typeIds, subtypeIds, startDate, endDate, payload, consummated, statusIds, cityIds, groupSetId, location, operationUnitId, closedStatusClassId, closedStatusId, qualifierOptions ){
  try {
    const { data } = await authFetch({
      url: '/statistics/dispatch-by-device',
      method: 'POST',
      data: {
        agencyId: agencyId,
        deviceId: deviceId, 
        operationId: operationId,
        dispatchGroupIds: dispatchGroupId,
        categoryIds: categoryIds,
        typeIds: typeIds,
        subtypeIds: subtypeIds,
        startDate: startDate,
        finishDate: endDate,
        excludeClosed: payload.exludeClosed,
        withSolvedThirdParty: payload.withSolvedThirdParty,
        consummated: consummated,
        statusIds: statusIds,
        cityIds: cityIds,
        groupSetId: groupSetId,
        location: location,
        operationUnitId: operationUnitId,
        closedStatusClassId: closedStatusClassId,
        closedStatusId: closedStatusId,
        qualifierOptions: qualifierOptions,
      }
    })

    return data;
  } catch (error) {
    throw error
  }
}