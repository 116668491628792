import { authFetch } from "~/services/fetch";
import { DeviceTypeResponse } from "../interface";

export async function getDevicesType(): Promise<readonly DeviceTypeResponse[]> {
  try {
    const { data } = await authFetch({
      url: '/device-type/',
      method: 'POST',
    })
    return data.data;
  } catch (error) {
    throw error;
  }
}