import * as React from 'react'
import { TextField, IconButton } from '@mui/material'
import { ChangeEvent, useCallback } from 'react'
import { useLang } from '~/hooks/useLang'
import { AttachFile, Send } from '@mui/icons-material';
import { State } from '@hookstate/core';

interface SendCommentFieldProps {
  readonly id: string | number | null;
  readonly disabled: boolean;
  readonly name: string | null;
  readonly label: string | null;
  readonly required: boolean;
  readonly handleClickSend: () => void;
  readonly newComment: any;
  readonly sendComment: any;
  readonly uploadImageOption?: boolean;
  readonly moveScrolltoComment?: State<boolean>;
  readonly loading?: State<boolean>;
}

export function SendCommentField({ id, disabled, handleClickSend, newComment, sendComment, uploadImageOption = true, moveScrolltoComment, required, loading}: SendCommentFieldProps) {
  const { translate } = useLang();

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.code === 'Enter' && !event.shiftKey) {
      localSendComment();
      moveScrolltoComment?.set(true)
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.shiftKey && event.code === 'Enter') {
      event.preventDefault()
      newComment().merge('\n')
      moveScrolltoComment?.set(true)
    }
  };

  const localSendComment = useCallback(() => {
    if (id) {
      loading?.set(true)
      sendComment({ id: id, comment: newComment().get() }).finally(() => {
        loading?.set(false)
      });
      document.getElementById('newComment')?.focus();
      moveScrolltoComment?.set(true)
    }
  }, [id]);

  const handleClickButton = useCallback(() => {
    if (!!newComment().get()?.length) {
      localSendComment()
    } else {
      handleClickSend()
    }
  }, [id])


  return (
    <TextField
      required={required}
      fullWidth
      maxRows={10}
      multiline={true}
      disabled={disabled}
      size='small'
      value={newComment().get() || ''}
      onChange={(event: ChangeEvent<HTMLInputElement>) => { newComment().set(event.target.value) }}
      onKeyDown={(event) => handleKeyDown(event)}
      id='newComment'
      label={translate('Write your comment')}
      name='newComment'
      InputProps={{
        endAdornment:
          <IconButton disabled={disabled}
            onClick={handleClickButton}
            sx={{ p: '2px' }}>
            {uploadImageOption && !newComment().get()?.length && (
              <AttachFile fontSize='small' />
            )}
            {(!uploadImageOption || !!newComment().get()?.length) && (
              <Send fontSize='small' />
            )}
          </IconButton>
      }}
    />
  )
}
