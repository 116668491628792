import React from 'react'; 0
import { Box, Button, CardActions } from '@mui/material';
import useDispatchRegistrationState from '~/features/Dispatch/stores/DispatchRegistrationState';
import { useLang } from '~/hooks/useLang';
import { State } from '@hookstate/core';
import { LoadingButton } from '@mui/lab';

interface IButtonsProps {
  readonly loading?: State<boolean>
  readonly handleEdit: () => void
  readonly handleSend: () => void
  readonly handleClickOpenConfirmAction: (action: string) => void
  readonly someInvalidInput: () => boolean;
  readonly disableFields: boolean
  readonly isUpdate?: boolean
}

export default function Buttons({
  loading,
  handleEdit,
  handleSend,
  someInvalidInput,
  handleClickOpenConfirmAction,
  disableFields = false,
  isUpdate = false
}: IButtonsProps) {
  const { translate } = useLang();


  return (
    <CardActions sx={{ justifyContent: 'flex-end' }}>
      {isUpdate && (<LoadingButton
        loading={loading?.get()}
        disabled={disableFields}
        color="secondary"
        sx={{
          mr: 1,
        }}
        onClick={() => {
          if (someInvalidInput()) {
            return
          }
          handleEdit()
        }}>
        {translate("Update")}
      </LoadingButton>
      )}

      <Button
        variant="outlined"
        onClick={() => {
          handleClickOpenConfirmAction('cancel')
        }}>
        {translate("Cancel")}
      </Button>

      {!isUpdate &&
        (<LoadingButton
          loading={loading?.get()}
          disabled={disableFields}
          variant="contained"
          onClick={() => {
            if (someInvalidInput()) {
              return
            }
            handleSend()
          }}>
          {translate("Save")}
        </LoadingButton>)}

    </CardActions>
  );
}