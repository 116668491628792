import React from 'react';
import { LoadingButton } from "@mui/lab";
import { useLang } from "~/hooks/useLang";

interface GenerateReportButton {
    readonly isLoading: boolean;
    readonly makeReport: () => void;
}

export default function GenerateReportButton({ isLoading, makeReport }: GenerateReportButton) {
    const { translate } = useLang();

    return (
        <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={() => makeReport()}
        >
            {translate('Generate report')}
        </LoadingButton>
    )
}