import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core'
import { CardHeader, Grid, IconButton, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ColumnChart from '~/components/ColumnChart'
import PieChart from '~/components/PieChart'
import { useLang } from '~/hooks/useLang'
import { useDispatchDashboardState } from '../../stores/DispatchDashboardState'
import { IDespacthStatisticGraphProps } from '../../interfaces/IDispacthStatistic'
import {
  getDeviceByStatus,
  getDispatchByClosedStatus,
} from '../DispatchByTimeAndStatusCharts/services'

type PayloadProps = {
  readonly withSolvedThirdParty?: boolean
  readonly exludeClosed?: boolean
}
type Payload2Props = {
  readonly excludeDisconnected?: boolean
}
type ResponseProps = ReadonlyArray<{
  readonly item: string
  readonly value: number
}>

export default function DeviceByTimeAndStatusCharts({
  loading,
  agencyId,
  deviceId,
  operationId,
  dispatchGroupId,
  category,
  type,
  subtype,
  dateStart,
  dateFinish,
  thirdParty,
  refresh,
  consummated,
  statusIds,
  cityIds,
  groupSetId,
  location,
  operationUnitId,
  closedStatusClassId,
  closedStatusId,
  qualifierOptions,
  expandFilterSearch,
}: IDespacthStatisticGraphProps) {
  const { translate } = useLang()
  const statusData = useHookstate<ResponseProps>([])
  const closedStatusData = useHookstate<ResponseProps>([])
  const payload = useHookstate<PayloadProps>({
    withSolvedThirdParty: thirdParty,
    exludeClosed: false,
  })
  const payload2 = useHookstate<Payload2Props>({ excludeDisconnected: false })
  const statusDataTotal = useHookstate<number>(0)
  const closedStatusDataTotal = useHookstate<number>(0)

  useEffect(() => {
    statusDataTotal.set(0)
    closedStatusDataTotal.set(0)
    payload.set({ withSolvedThirdParty: thirdParty, exludeClosed: false })
    loading.set(true)
    getDeviceByStatus(
      agencyId,
      deviceId,
      operationId,
      dispatchGroupId,
      category,
      type,
      subtype,
      dateStart,
      dateFinish,
      payload2.get(),
      groupSetId,
      operationUnitId
    )
      .then((response) => {
        const result = response.map((responseData) => {
          statusDataTotal.set(statusDataTotal.get() + responseData.total)
          return { item: responseData.name, value: responseData.total }
        })
        return statusData.set(result)
      })
      .finally(() => {
        loading.set(false)
      })

    getDispatchByClosedStatus(
      agencyId,
      deviceId,
      operationId,
      dispatchGroupId,
      category,
      type,
      subtype,
      dateStart,
      dateFinish,
      payload.get(),
      consummated,
      statusIds,
      cityIds,
      groupSetId,
      location,
      closedStatusClassId,
      closedStatusId,
      qualifierOptions,
      operationUnitId
    )
      .then((response) => {
        const result = response.map((responseData) => {
          closedStatusDataTotal.set(
            closedStatusDataTotal.get() + responseData.total
          )
          return { item: responseData.name, value: responseData.total }
        })
        return closedStatusData.set(result)
      })
      .finally(() => {
        loading.set(false)
      })
  }, [refresh])

  return (
    <Grid container xs={12} sx={{ display: 'flex', paddingX: 3, paddingTop: 3 }}>
      <Grid item xs={12} hidden={!expandFilterSearch}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
          {translate('Data by Status')}
        </Typography>
      </Grid>
      <Grid item xs={12} hidden={!expandFilterSearch}>
        <ColumnChart
          data={statusData.attach(Downgraded).get()}
          title={'Device x Status'}
          subtitle={`Total: ${statusDataTotal.get()}`}
        />
      </Grid>
    </Grid>
  )
}
