import React from 'react';
import CrudIndex from '~/components/CrudIndex';
import Layout from '~/components/Layout';

export default function Index() {        
    return(
      <Layout marginLayout={true}>
        <CrudIndex model='announcement' title='Announcement' 
        aliasLabel={[{ column: 'is_change_version', label: 'Is change version?' },
        { column: 'announcement_date', label: 'Announcement date' }]}/>
      </Layout>
    )
}
