import { useHookstate } from '@hookstate/core';
import React, { useEffect } from 'react';
import CrudIndex from '~/components/CrudIndex';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';


export default function Index() {  
  const { translate } = useLang();
  const breed = useHookstate<string | null>(null)
  const specieId = useHookstate<number | null>(null)
  const forceRefresh = useHookstate<boolean>(false);
  const lockFields = useHookstate<boolean>(false);

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])      

  const breedFilter = [
    {
      type: 'name',
      variable: breed,
      placeholder: 'Breed'
    },
    {
      type: 'specie_id',
      variable: specieId
    }
  ]


    return(
      <Layout marginLayout={true}>
        <CrudIndex 
          model='breed' 
          title='Breeds'
          createTitleComplement='Breed'
          viewTitle='Breed'
          canView={true}
          exceptColumns={['id', 'specie_id']}
          aliasLabel={[{column: 'specie:name', label: 'Specie'}]}
          showInViewMore={['name', 'specie:name', 'created_at']}
          filters={breedFilter}
          searchButton={() => forceRefresh.set(!forceRefresh.get())}
          forceRefresh={forceRefresh.get()}
          clearFilters={() => {
            breed.set('')
            specieId.set(null)

            forceRefresh.set(!forceRefresh.get())
          }} 
          disableColumnsFilters={true}
          lockFields={lockFields.get()}
        />
      </Layout>
    )
}
