import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useLang } from '~/hooks/useLang';
import Layout from '~/components/Layout';
import ButtonRound from '~/components/ButtonRound';
import DataTables from '~/components/DataTables';
import ListAllDispatchAdvanced from '~/components/ListAllDispatchAdvanced';
import InternalHeader from '~/components/Layout/components/InternalHeader';

export default function DispatchListAdvanced() {
  const { translate } = useLang();
  return (
    <>
      <InternalHeader title={translate(`Dispatches`)} />
      <ListAllDispatchAdvanced targetUrl={'/dispatch'}/>
    </>

    
  )
}