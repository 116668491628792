import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material'
import { useLang } from '~/hooks/useLang'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import useUserState from '~/features/User/stores/UserState'
import VehicleItem from './VehicleItem'
import WeaponItem from './WeaponItem'
import InvolvementItem from './InvolvementItem'
import OrganItem from './OrganItem'
import DrugItem from './DrugItem'
import ObjectItem from './ObjectItem'
import { MenuProps } from '~/components/SidebarMenu/interfaces'
import AnimalItem from './AnimalItem'

export default function QualificationItem({ handleMenuClick }: MenuProps) {
  const { translate } = useLang()
  const { verifyPermission } = useUserState()
  const [openQualificationItem, setOpenQualificationItem] =
    React.useState(false)

  const history = useHistory()

  const handleClickQualificationItem = () => {
    setOpenQualificationItem(!openQualificationItem)
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {verifyPermission([
        'unit_measurement_index',
        'circunstance_index',
        'destination_index',

        'weapon_kind_index',
        'weapon_type_index',
        'weapon_brand_index',
        'firearm_caliber_index',

        'vehicle_brand_index',
        'vehicle_type_index',
        'vehicle_model_index',
        'vehicle_color_index',

        'ethnicity_index',
        'education_index',
        'document_type_index',
        'involvement_type_index',
        'people_circunstance_index',
        'occupation_index',

        'organization_index',
        'action_taken_index',

        'drug_type_index',

        'object_type_index',
      ]) && (
        <>
          <Tooltip title={translate('Qualification options')} placement="right">
            <ListItemButton onClick={handleClickQualificationItem}>
              <ListItemText primary={translate('Qualifications')} />
              {openQualificationItem ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </Tooltip>
          <Collapse in={openQualificationItem} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <AnimalItem handleMenuClick={handleMenuClick} />

              <WeaponItem handleMenuClick={handleMenuClick} />

              {verifyPermission(['destination_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Destination')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/destination')
                    }}
                  >
                    <ListItemText primary={translate('Destination')} />
                  </ListItemButton>
                </Tooltip>
              )}

              <DrugItem handleMenuClick={handleMenuClick} />

              <InvolvementItem handleMenuClick={handleMenuClick} />

              <ObjectItem handleMenuClick={handleMenuClick} />

              <OrganItem handleMenuClick={handleMenuClick} />

              {verifyPermission(['occupation_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Occupation')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/occupation')
                    }}
                  >
                    <ListItemText primary={translate('Occupation')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['circunstance_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Circunstance')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/circunstance')
                    }}
                  >
                    <ListItemText primary={translate('Circunstance')} />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['unit_measurement_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Unit Measurement')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/unit-measurement')
                    }}
                  >
                    <ListItemText primary={translate('Unit Measurement')} />
                  </ListItemButton>
                </Tooltip>
              )}

              <VehicleItem handleMenuClick={handleMenuClick} />
            </List>
          </Collapse>
        </>
      )}
    </>
  )
}
