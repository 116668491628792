import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IWeaponKind from '../interfaces/IWeaponKind';
import Layout from '~/components/Layout';

export default function Create() {     
  const {register, handleSubmit, control, setValue:setFormValue} = useForm<IWeaponKind>();
  const fields = [
      { name: 'name', type: 'string'},
      { name: 'reapon_type_id', type: 'selectAutoComplete', model: 'weapon-type', placeholder: 'Weapon Type' },
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate model={'weapon-kind'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control}
        messageSuccess={'Weapon kind successfully created!'}  
        title={'Add weapon kind'}  
        subheader={'Add weapon kind'}
        setFormValue={setFormValue} />
      </Layout>
    )
}
