import React from 'react';
import { State, StateMethods } from '@hookstate/core';
import { Button, Collapse, Grid } from '@mui/material';
import FilterAutocompleteAgencies from '~/components/FilterAutocompleteAgencies';
import { useLang } from '~/hooks/useLang';
import FilterAutocompleteType from '~/components/FilterAutocompleteType';
import FilterAutocompleteSubtypesByType from '~/components/FilterAutocompleteSubtypeByType';
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod';
import FilterAutocompleteAgency from '~/components/FilterAutocompleteAgency';
import { Dayjs } from 'dayjs';
import { LoadingButton } from '@mui/lab';
import FilterAutocompleteCity from '~/components/FilterSelectAutocompleteCity';

interface EntryFiltersDashboardProps {
  readonly loading: State<boolean>;
  readonly agencyId: State<number | null>;
  readonly initialPeriod: State<string>;
  readonly typeId: State<number | null>;
  readonly subtypeId?: State<number | null>;
  readonly hidden?: boolean;
  readonly searchButton: () => void;
  readonly clearFilters: () => void;
  readonly onStartDateChange: (value: Dayjs | null) => void;
  readonly onFinishDateChange: (value: Dayjs | null) => void;
  readonly onPeriodChange: (value: string) => void;
  readonly cityId?: State<number | null>;
}

export default function EntryFiltersDashboard({ onPeriodChange, initialPeriod, searchButton, clearFilters, agencyId, hidden, typeId, subtypeId, onStartDateChange, onFinishDateChange, loading, cityId }: EntryFiltersDashboardProps) {
  const { translate } = useLang();

  return (
    <Collapse in={hidden}>
      <Grid container xs={12}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          p: 4
        }}
      >
        <Grid container direction={'row'}>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteAgency agencyId={agencyId.get()} onAgencyChange={(agency) => agencyId.set(agency)} />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteType typeId={typeId.get()} onTypeChange={(type) => typeId.set(Number(type?.id) ?? null)} />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteSubtypesByType hasType typeId={typeId.get()} onSubtypeChange={(subtype) => subtypeId?.set(subtype?.id ?? null)} />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteCity cityId={cityId?.get()} onCityChange={(city) => cityId?.set(city)}/>
          </Grid>
          <FilterSelectPeriod
            onStartDateChange={onStartDateChange}
            onFinishDateChange={onFinishDateChange}
            onPeriodChange={onPeriodChange}
            period={initialPeriod.get()}
          />
        </Grid>
        <Grid
          container
          direction={'row'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            pt: 2,
            gap: 2,
          }}
        >
          <Button variant="outlined"
            onClick={clearFilters}
          >
            {translate('Clear Filters')}
          </Button>
          <LoadingButton 
            loading={loading.get()}
            variant="contained"
            onClick={searchButton}
          >
            {translate('Search')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Collapse>

  )
}