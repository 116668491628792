import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem, Grid, Box, IconButton, RadioGroup, FormControlLabel, FormHelperText, Radio, Autocomplete } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { authFetch } from '~/services/fetch';
import { useHookstate } from '@hookstate/core';
import { getDraftCloseComment, saveDraft } from '~/features/Dispatch/services';
import notify from '~/utils/notify';
import { getOperationActive, getOperationService } from '~/features/Operation/services';
import CloseIcon from '@mui/icons-material/Close';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import { LoadingButton } from '@mui/lab';

interface DispatchClosedProps {
  readonly id: number | null;
  readonly name: string | null;
  readonly acronym: string | null;
  readonly require_consummate_information: boolean | null;
}

interface OperationProps {
  readonly id: number;
  readonly name: string;
  readonly active: boolean;
}

interface IErros {
  readonly closedStatus: string;
  readonly operation: string;
  readonly comment: string;
  readonly isConsummated: string;
}

export const DEFAULT_TYPE: DispatchClosedProps = { id: 0, name: '', acronym: '', require_consummate_information: false }


// eslint-disable-next-line max-lines-per-function
export default function DeviceClosedStatusButton({ dispatchId, dispatchDeviceId, handleCancel }) {
  const { translate } = useLang()
  const { dispatch } = useDispatchState();

  const dispatchClosedStatusList = useHookstate<ReadonlyArray<DispatchClosedProps>>([]);
  const operationList = useHookstate<ReadonlyArray<OperationProps>>([]);
  const closedStatus = useHookstate<DispatchClosedProps | null>(null);
  const operation = useHookstate('');
  const open = useHookstate(false);
  const comment = useHookstate('');
  const isInvalidRequest = useHookstate(false);
  const defaultErros = {
    closedStatus: '',
    comment: '',
    operation: '',
    isConsummated: ''
  }
  const errors = useHookstate<IErros>(defaultErros);
  const loadingDraft = useHookstate(false);
  const loading = useHookstate(false);
  const valueConsummated = useHookstate<string | null>(null);
  const isConsummated = useHookstate<boolean | null>(dispatch().get().type?.mustInformConsummate ? true : null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    errors.isConsummated.set('');
    valueConsummated.set((event.target as HTMLInputElement).value);

    if (valueConsummated.get() === 'true') {
      isConsummated.set(true);
    } else if (valueConsummated.get() === 'false') {
      isConsummated.set(false);
    } else {
      isConsummated.set(null);
    }
  };

  useEffect(() => {
    authFetch({
      url: '/dispatch/closed-status',
      method: 'GET',
    }).then(({ data }) => {
      dispatchClosedStatusList.set(data.sort((a, b) => a.id - b.id));
    })

    getOperationActive().then((data) => {
      operationList.set(data);
    })
  }, [])

  useEffect(() => {
    open.set(false)
  }, [dispatch()?.get()?.id])

  const handleSaveDraft = () => {
    loadingDraft.set(true)
    saveDraft(dispatchId, comment.get(), dispatchDeviceId).then(() => {
      notify({ message: translate('Draft saved successfully'), type: 'success' });
    }).finally(() => {
      loadingDraft.set(false)
    })
    handleClose()
  }

  const clearStates = () => {
    comment.set('');
    operation.set('');
    closedStatus.set(null);
    isConsummated.set(null);
    valueConsummated.set(null);
  }

  const handleClickOpen = () => {
    open.set(true);
    getDraftCloseComment(dispatchId, dispatchDeviceId)
      .then((response) => {
        comment.set(response.data.comment);
      })
    isInvalidRequest.set(false)
    errors.set(defaultErros);
  };

  const handleClose = () => {
    clearStates();
    open.set(false);
  };

  const clickOutModalClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      open.set(false);
    }
  };

  const handleChangeOperation = (event: SelectChangeEvent) => {
    errors.operation.set('')
    operation.set(event.target.value);
  };


  const hasRequiredFields = () => {

    if (!closedStatus.get()) {
      errors.closedStatus.set(translate('Inform a status'))
      return true;
      // } else if (!operation.get()) {
      //   errors.operation.set(translate('Inform a operation'))
      //   return true;
    } else if (valueConsummated.get() === null &&
      dispatch().get().type?.mustInformConsummate &&
      closedStatus.get()?.require_consummate_information) {
      errors.isConsummated.set(translate('Select an option'))
      return true;
    } else if (!comment.get()) {
      errors.comment.set(translate('Inform a comment'))
      return true;
    } else {
      return false
    }
  }


  const action = () => {
    if (comment.get() != '' && closedStatus.get() != null && !hasRequiredFields()) {
      loading.set(true)
      authFetch({
        url: '/dispatch/close',
        method: 'POST',
        data: {
          dispatch_id: dispatchId,
          closed_status_id: closedStatus.get()?.id,
          operation_id: operation.get() != '' ? operation.get() : null,
          comment: comment.get(),
          is_consummate: isConsummated.get() ?? null,
          dispatch_device_id: dispatchDeviceId,
        }
      }).then(() => {
        clearStates();
        open.set(false);
        handleCancel();
        valueConsummated.set(null)
      }).finally(() => {
        loading.set(false)
      });
    } else {
      return isInvalidRequest.set(true);
    }
  };

  return (
    <>
      <Button size={'small'} fullWidth type='submit' variant='contained' onClick={handleClickOpen}>
        {translate('Close')}
      </Button>
      <Dialog
        open={open.get()}
        onClose={clickOutModalClose}
        disableEscapeKeyDown={true}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle>
          {translate('Close service of the vehicle')}
        </DialogTitle>
        <DialogContent>

          <FormControl
            fullWidth
            size='small'
            sx={{ mt: 1 }}
          >
            <Autocomplete
              options={dispatchClosedStatusList.get()}
              noOptionsText={translate('No options')}
              size='small'
              getOptionLabel={(option) => `${option.acronym ? option.acronym + ' ' + option.name : ''}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={closedStatus.get()}
              onChange={(event, value) => {
                errors.closedStatus.set('')
                closedStatus.set({ id: value?.id ?? null, name: value?.name ?? '', acronym: value?.acronym ?? '', require_consummate_information: value?.require_consummate_information ?? false });
              }}
              renderInput={(params) =>
                <TextField {...params}
                  error={!!errors.closedStatus.get()}
                  label={`${translate('Closed Status')}`}
                />
              }
            />
            {/* <InputLabel size='small' sx={{ mt: 1 }} id='closedStatus'>
              {translate('Entry Status')}
            </InputLabel>
            <Select
              labelId='closedStatus'
              id='closedStatus'
              value={closedStatus.get()}
              error={isInvalidRequest.get()}
              required={true}
              label={translate('Entry Status')}
              size='small'
              sx={{ mt: 1 }}
              onChange={handleChange}
            >
              {dispatchClosedStatusList.get().map((status) => (
                <MenuItem sx={{ mt: 1 }} key={status.id} value={status.id}>
                  {`[${status.acronym}] ${status.name}`}
                </MenuItem>
              ))}
            </Select> */}
          </FormControl>

          {operationList.get().length ?
            <FormControl
              fullWidth
              size='small'
              error={!!errors.operation.get()}
            >
              <InputLabel size='small' sx={{ mt: 1 }} id='operation' >
                {translate('Operation')}
              </InputLabel>
              <Select
                labelId='operation'
                id='operation'
                value={operation.get()}

                label={translate('Operation')}
                size='small'
                displayEmpty={true}
                sx={{ mt: 1 }}
                onChange={handleChangeOperation}
                endAdornment={
                  operation.get() != '' ? (
                    <IconButton onClick={() => operation.set('')} sx={{ marginRight: 2 }}>
                      <CloseIcon />
                    </IconButton>
                  ) : false
                }
              >
                {operationList.get().map((operation, index) => (
                  <MenuItem sx={{ mt: 1 }} key={index} value={operation.id}>
                    {`${operation.name}`}
                  </MenuItem>
                ))}

              </Select>
            </FormControl>
            : false
          }

          {dispatch().get().type?.mustInformConsummate && closedStatus.get()?.require_consummate_information
            ?
            <FormControl required={dispatch().get().type?.mustInformConsummate} error={!!errors.isConsummated.get()}>
              <RadioGroup
                row
                name="must-inform-consummate"
                value={valueConsummated.get()}
                onChange={handleChange}
              >
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 2, marginY: 1 }}>
                  <FormControlLabel value={'true'} control={<Radio />} label={translate("consummated")} />
                  <FormControlLabel value={'false'} control={<Radio />} label={translate("attempted")} />
                  <FormHelperText>
                    {errors.isConsummated.get()}
                  </FormHelperText>
                </Box>
              </RadioGroup>
            </FormControl>
            : false
          }

          <TextField
            multiline
            rows={10}
            error={isInvalidRequest.get()}
            required={true}
            name={'comment'}
            id='comment'
            label={translate('Comment')}
            fullWidth
            size='small'
            value={!comment.get() ? '' : comment.get()}
            sx={{ mt: 1 }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => { comment.set(event.target.value) }}
          />

        </DialogContent>
        <DialogActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingX: "24px" }}>
          <Box>
            <LoadingButton
              loading={loadingDraft.get()}
              variant='contained'
              onClick={handleSaveDraft}>
              {translate('Save draft')}
            </LoadingButton>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant='outlined'
              onClick={handleClose}>
              {translate('Cancel')}
            </Button>
            <LoadingButton
              loading={loading.get()}
              variant='contained'
              onClick={action}>
              {translate('Submit')}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>);

}
