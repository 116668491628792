import React from 'react';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { AppRouteProps } from '~/routes';

export default function WeaponKindRoutes(): readonly AppRouteProps[] {
  const routes: readonly AppRouteProps[] = [
    {path: '/weapon-kind', component: Index, isProtected: true, permissions: ['weapon_kind_index']},
    {path: '/weapon-kind/create', component: Create, isProtected: true, permissions: ['weapon_kind_create']},
    {path: '/weapon-kind/edit/:id', component: Edit, isProtected: true, permissions: ['weapon_kind_edit']},

  ];

  return routes;
}
