import { authFetch } from '~/services/fetch';

export async function getDataByPlate(plate) {
	try {
		const { data } = await authFetch({
			url: `/rh/shift/${plate}/today`,
			method: 'GET',
			timeout: 5000000     
		})
			
		return data;
	} catch (error) {console.error("entrou")
		throw error;
	}
}