import { authFetch } from "~/services/fetch";
import { DeviceResponse } from "../interface";

export async function getDevices(): Promise<readonly DeviceResponse[]> {
  try {
    const { data } = await authFetch({
      url: '/device',
      method: 'GET',
    })
    return data;
  } catch (error) {
    throw error;
  }
}