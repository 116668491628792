import { useHookstate } from '@hookstate/core';
import React, { useEffect } from 'react';
import CrudIndex from '~/components/CrudIndex';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';


export default function Index() {  
  const { translate } = useLang();
  const agencyId = useHookstate<number | null>(null);
  const areaPlanId = useHookstate<number | null>(null);
  const forceRefresh = useHookstate<boolean>(false);
  const lockFields = useHookstate<boolean>(false);

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])      

  const agencyFilter = [
    {
      type: 'agency_id',
      variable: agencyId
    }, 
    {
      type: 'area_plan_id',
      variable: areaPlanId
    },
  ]


    return(
      <Layout marginLayout={true}>
        <CrudIndex 
          model='agency' 
          title='Agencies'
          exceptColumns={['area_plan_id']}
          aliasLabel={[{ column: 'area_plan:name', label: 'Area plan' }]}
          createTitleComplement='agency'
          canView={true}
          showInViewMore={['name', 'acronym', 'area_plan:name', 'created_at']}
          filters={agencyFilter}
          searchButton={() => forceRefresh.set(!forceRefresh.get())}
          forceRefresh={forceRefresh.get()}
          clearFilters={() => {
            agencyId.set(null)
            areaPlanId.set(null)
            forceRefresh.set(!forceRefresh.get())
          }} 
          disableColumnsFilters={true}
          lockFields={lockFields.get()}
        />
      </Layout>
    )
}
