import { authFetch } from "~/services/fetch";
import { CategoriesResponse } from "../interfaces";

export async function getAllCategories(): Promise<readonly CategoriesResponse[]> {
  try {
    const { data } = await authFetch({
      url: '/category',
      method: 'GET',
    })
    return data;
  } catch (error) {
    throw error;
  }
}