/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import {
  Button,
  Card,
  ListItemButton,
  ListItemText,
  Typography,
  Box,
  Tooltip,
} from '@mui/material'
import IconDispatch from '~/components/IconsSvg/dispatch'
import { useLang } from '~/hooks/useLang'
import { Link, useHistory } from 'react-router-dom'
import useUserState from '~/features/User/stores/UserState'
import { MenuProps } from '../../interfaces'

export default function DispatchMenu({
  activeMenu,
  handleMenuClick,
}: MenuProps) {
  const { translate } = useLang()
  const { verifyPermission } = useUserState()
  const history = useHistory()

  return (
    <>
      {verifyPermission([
        'dispatch_index',
        'dispatch_list',
        'dispatch_dashboard',
      ]) && (
        <Box>
          <Tooltip title={translate('Dispatch')} placement="bottom">
            <Button
              className={`main-menu ${
                activeMenu == 'dispatch' ? 'active' : ''
              }`}
              onClick={() => handleMenuClick('dispatch')}
            >
              <IconDispatch />
            </Button>
          </Tooltip>
          <Card
            className="submenu-box"
            sx={{
              borderRadius: 0,
              display: activeMenu == 'dispatch' ? 'block' : 'none',
            }}
          >
            <Typography variant="h4">
              <IconDispatch />
              {translate('Dispatch')}
            </Typography>

            {verifyPermission(['dispatch_index']) && (
              <Tooltip
                title={translate('Carry out dispatch')}
                placement="right"
              >
                <ListItemButton
                  onClick={() => {
                    handleMenuClick('dispatch')
                    history.push('/dispatch')
                  }}
                >
                  <ListItemText
                    primary={
                      verifyPermission(['dispatch_read_only'])
                        ? translate('Real-time tracking')
                        : translate('Dispatch')
                    }
                  />
                </ListItemButton>
              </Tooltip>
            )}

            {verifyPermission(['dispatch_list']) && (
              <Tooltip
                title={translate('Search with filters')}
                placement="right"
              >
                <ListItemButton
                  onClick={() => {
                    handleMenuClick('dispatch')
                    history.push('/dispatch/list')
                  }}
                >
                  <ListItemText primary={translate('Dispatch List')} />
                </ListItemButton>
              </Tooltip>
            )}

            {verifyPermission(['dispatch_dashboard']) && (
              <Tooltip
                title={translate('Information about dispatch')}
                placement="right"
              >
                <ListItemButton
                  onClick={() => {
                    handleMenuClick('dispatch')
                    history.push('/dispatch/dashboard')
                  }}
                >
                  <ListItemText primary={translate('Management Dashboard')} />
                </ListItemButton>
              </Tooltip>
            )}
{/* 
            {verifyPermission([]) && (
              <Tooltip title={translate('Historic Map')} placement="right">
                <ListItemButton
                  onClick={() => {
                    handleMenuClick('dispatch')
                    history.push('/dispatch/historic-map')
                  }}
                >
                  <ListItemText primary={translate('Historic Map')} />
                </ListItemButton>
              </Tooltip>
            )} */}

            {verifyPermission(['dispatch_management_map']) && (
              <Tooltip title={translate('Management Map')} placement="right">
                <ListItemButton
                  onClick={() => {
                    handleMenuClick('dispatch')
                    history.push('/dispatch/management-map')
                  }}
                >
                  <ListItemText primary={translate('Management Map')} />
                </ListItemButton>
              </Tooltip>
            )}

            {verifyPermission(['dispatch_prediction']) && (
              <Tooltip title={translate('Prediction Map')} placement="right">
                <ListItemButton
                  onClick={() => {
                    handleMenuClick('dispatch')
                    history.push('/dispatch/prediction-map')
                  }}
                >
                  <ListItemText primary={translate('Prediction Map')} />
                </ListItemButton>
              </Tooltip>
            )}

            {verifyPermission(['dispatch_coordination_index']) && (
              <Tooltip title={translate('Coordination list')} placement="right">
                <ListItemButton
                  onClick={() => {
                    handleMenuClick('dispatch')
                    history.push('/dispatch/coordination')
                  }}
                >
                  <ListItemText primary={translate('Coordination')} />
                </ListItemButton>
              </Tooltip>
            )}
          </Card>
        </Box>
      )}
    </>
  )
}
