/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { getAllAreas } from './services';
import { AreasResponse } from './interface';

interface FilterAutocompleteAreasProps {
  readonly onAreaChange: (value: number[]) => void;
  readonly hideCode?: boolean;
  readonly areaId?: readonly number[] | null;
  readonly disabled?: boolean
}

export default function FilterAutocompleteAreas({ disabled, areaId, onAreaChange, hideCode = false }: FilterAutocompleteAreasProps) {
  const { translate } = useLang();
  const [options, setOptions] = useState<readonly AreasResponse[]>([]);

  const [value, setValue] = useState<AreasResponse[]>([]);
  const loading = useHookstate(false);


  useEffect(() => {
    getAllAreas().then(areas => {
      setOptions(areas)
      setValue([])
    })
  }, [])

  useEffect(() => {
    if (!areaId?.length) {
      setValue([])
    }
  }, [areaId])



  return (
    <FormControl fullWidth size='small' >
      <Autocomplete
        filterSelectedOptions
        disableCloseOnSelect
        limitTags={2}
        sx={{ height: 2 }}
        ListboxProps={{ style: { maxHeight: '15rem' } }}
        id='filter-areas'
        disabled={disabled}
        multiple
        options={options}
        value={value}
        noOptionsText={translate('No options')}
        clearText={translate('Clear')}
        size='small'
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name ?? ''}
        onChange={(_, type) => {
          setValue(type)
          onAreaChange(type.map(type => type.id) ?? [])
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate('Areas')}
            InputLabelProps={{
              sx: {
                fontFamily: 'Arial',
                letterSpacing: 0.5,
                fontWeight: 500
              }
            }}
          />
        )}
      />
    </FormControl>
  )
}