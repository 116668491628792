import React from 'react';
import { Grid } from '@mui/material';
import DispatchUnificationTable from '../DispatchUnificationTable';

export default function DispatchUnification() {

  return (
    <Grid container>
      <DispatchUnificationTable/>
    </Grid>
  );
}
