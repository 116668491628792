import { useHookstate } from '@hookstate/core';
import { Visibility } from '@mui/icons-material';
import React, { useEffect, useMemo } from 'react';
import CrudIndex from '~/components/CrudIndex';
import { ActionButtonsProps } from '~/components/CrudIndex/interfaces/ICrudIndex';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';
import ViewProcedureConfig from '../components/ViewProcedureConfig';

export default function Index() { 
  const { translate } = useLang();

  const idTemp = useHookstate(null);
  const lockFields = useHookstate<boolean>(false);
  const loading = useHookstate<boolean>(false);
  const forceRefresh = useHookstate<boolean>(false);
  const procedureId = useHookstate(null);
  const typeId = useHookstate(null);
  const subtypeId = useHookstate(null);
  const relatedTo = useHookstate(null);

  const openDialogViewProcedureConfig = useHookstate(false)

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])       

  const priorityActions = useMemo((): ActionButtonsProps[] => {
      return [
        {
          action: function (row) {
            openDialogViewProcedureConfig.set(true)
            idTemp.set(row['id'])
          },
          label: translate('View'),
          iconButton: <Visibility />
        }
      ]
  }, [])

  const procedureConfigFilters = [
    {
      type: 'procedure_id',
      variable: procedureId,
    },
    {
      type: 'type_id',
      variable: typeId,
    },
    {
      type: 'subtype_id',
      variable: subtypeId,
      dependent: typeId
    },
    {
      type: 'related_to',
      variable: relatedTo,
    },
  ]

  return(
    <Layout marginLayout={true}>
      <CrudIndex 
      model='procedure/config' 
      title='Procedures Configuration'
      createTitleComplete="Configure Procedure"
      priorityActions={priorityActions}
      loading={loading}
      lockFields={lockFields.get()}
      filters={procedureConfigFilters}
      searchButton={() => forceRefresh.set(!forceRefresh.get())}
      forceRefresh={forceRefresh.get()}
      clearFilters={() => {
        procedureId.set(null)
        typeId.set(null)
        subtypeId.set(null)
        relatedTo.set(null)
        forceRefresh.set(!forceRefresh.get())
      }} 
      disableColumnsFilters={true}
      aliasLabel={[
        { column: 'procedure:name', label: 'Procedure' },
        { column: 'type:name', label: 'Type'},
        { column: 'subtype:name', label: 'Subtype'},
        { column: 'related_to', label: 'Visible To'}
      ]}
      forceOrderColumns={['procedure:name', 'type:name', 'subtype:name', 'related_to']}
      editable={false}
      cellLabel={[{field: 'related_to', value: {0: 'Atendente', 1: 'Despachante', 2: 'Viatura'}}]}
      />
      <ViewProcedureConfig 
        open={openDialogViewProcedureConfig} 
        handleClose={() => {
          idTemp.set(null)
          openDialogViewProcedureConfig.set(false)
        }} 
        idProcedureConfig={idTemp.get()}
      />
    </Layout>
  )
}