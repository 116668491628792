import React from 'react';
import {Switch, FormControlLabel} from '@mui/material';
import { useTheme } from '../../hooks/useTheme'
import {DarkMode, LightMode} from '@mui/icons-material';

export default function ThemeSwitch() {
  const {darkMode, switchTheme} = useTheme();

  return (
      <FormControlLabel
        control={<Switch
          checked={darkMode}
          onChange={switchTheme}
        />}
        label={(darkMode ? (
          <>
            <DarkMode fontSize={'small'} color="primary"/>
            &nbsp;
            {'Modo Escuro'}
          </> ): (
          <>
            <LightMode fontSize={'small'} color="primary"/>
            &nbsp;
            {'Modo Claro'}
          </>
          )
        )}
        labelPlacement="start"
      />
  );
}