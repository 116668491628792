import React from 'react';
import { Button, Tooltip } from '@mui/material';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { useLang } from '~/hooks/useLang';
import { StateMethods } from '@hookstate/core';

interface IButtonReplaceVehicle {
  readonly showReplaceVehicle: StateMethods<boolean>;
}

export default function ButtonReplaceVehicle({ showReplaceVehicle }: IButtonReplaceVehicle) {
  const { translate } = useLang();
  return (

    <Button
      sx={{
        backgroundColor: `${showReplaceVehicle.get() ? '#00544a' : ''}`,
      }}
      onClick={() => showReplaceVehicle.set(previousValue => !previousValue)}
      variant='contained'
    >
      {translate('Replace device')}
    </Button >

  )
}