import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, SxProps, TextField, Theme } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { listClosedStatusClass } from '~/features/ClosedStatusClass/services';
import { ClosedStatusClassResponse } from './interface';

interface FilterAutocompleteClosedStatusClassProps {
    readonly onClosedStatusClassChange: (value: number | null) => void;
    readonly closedStatusClassId?: number | null;
    readonly disabled?: boolean
}

export default function FilterAutocompleteClosedStatusClass({ disabled, closedStatusClassId, onClosedStatusClassChange }: FilterAutocompleteClosedStatusClassProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly ClosedStatusClassResponse[]>([]);

    const [value, setValue] = useState<ClosedStatusClassResponse | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        listClosedStatusClass({filters: []}).then(closedStatusClass => {
            setOptions(closedStatusClass);
        })
    }, [])

    useEffect(() => {
        if (!closedStatusClassId) {
            setValue(null);
        } else {
            const option = options.find((option) => option.id == closedStatusClassId);
            setValue(option ?? null);
        }

    }, [options, closedStatusClassId])

    return (
        <FormControl fullWidth size='small'>
            <Autocomplete
                id='filter-closed-status-class'
                disabled={disabled}
                options={options}
                value={value}
                noOptionsText={translate('No options')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => (option.name) ?? ''}
                onChange={(_, closedStatusClass) => {
                    setValue(closedStatusClass ?? null);
                    onClosedStatusClassChange(closedStatusClass?.id ?? null);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Closed class')}
                    />
                )}
            />
        </FormControl>
    )



}