import { authFetch } from "~/services/fetch";
import { OperationResponse } from "../interface";
import IOperation from "~/features/Operation/interfaces/IOperation";

export async function getOperation(): Promise<readonly IOperation[]> {
    try {
        const { data } = await authFetch({
            url: '/operation',
            method: 'GET',
        })
        return data;
    } catch (error) {
        throw error;
    }
}


export async function getOperationById(id: number) {
    if (!id) return null;
    const response = await authFetch({
        url: `/operation/${id}`,
        method: 'GET' 
    });
    return response.data;
  
}