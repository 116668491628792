import { authFetch } from "~/services/fetch";

export async function searchWeaponService({ serialNumber, page, limit }) {
    try {
      const response = await authFetch({
        url: '/occurrence/associated-weapon/find-weapon',
        method: 'POST',
        data: {
            serial_number: serialNumber,
            page: page,
            limit: limit,
            orders: [{field: "id", sort: "desc"}]
        }
      })
  
      return response.data;
  
    } catch (error) {
      throw error
    }
  }