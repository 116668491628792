import React from 'react';
import { useLang } from "~/hooks/useLang";
import { MenuProps } from "../../interfaces";
import useUserState from "~/features/User/stores/UserState";
import { useHistory } from "react-router-dom";
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import { Box, Button, Card, ListItemButton, ListItemText, Tooltip, Typography } from "@mui/material";

export default function InterestPointMenu({ activeMenu, handleMenuClick }: MenuProps) {
  const { translate } = useLang();
  const { verifyPermission } = useUserState();
  const history = useHistory();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        verifyPermission(['interest_point_index']) && (
          <Box>
            <Tooltip title={translate('Points of interest')} placement="bottom">
              <Button
                className={`main-menu ${activeMenu == 'interest-point' ? 'active' : ''}`}
                onClick={() => handleMenuClick('interest-point')}
              >
                <WhereToVoteIcon />
              </Button>
            </Tooltip>
            <Card
              className="submenu-box"
              sx={{
                borderRadius: 0,
                display: activeMenu == 'interest-point' ? 'block' : 'none',
              }}
            >
              <Typography variant="h4">
                <WhereToVoteIcon />
                {translate('Points of interest')}
              </Typography>

              {verifyPermission(['interest_point_index']) && (
                <Tooltip title={translate('Points of interest')} placement="right">
                  <ListItemButton onClick={() => {
                    handleMenuClick('interest-point')
                    history.push('/interest-point')
                  }}>
                    <ListItemText primary={translate('Points of interest')} />
                  </ListItemButton>
                </Tooltip>
              )} 
            </Card>
          </Box>
        )}
    </>
  )
}