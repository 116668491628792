import { IParamsTable } from '../../../interfaces';
import { authFetch } from '~/services/fetch';

export async function getEntryOrigins({
    filters = [],
    limit = 100000,
    orders = [
        { field: 'name', sort: 'asc' }
    ]
}: IParamsTable = {}) {
    try {
        const response = await authFetch({
            url: `/entry-origin/`,
            method: 'POST',
            data: {
                limit,
                filters,
                orders
            },
        });
        return response.data.data;
    } catch (error) {
        if(error) throw error;
        throw new Error('Erro indefinido');
    }
}
