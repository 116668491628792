/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useLang } from '~/hooks/useLang';
import useDispatchRegistrationPersonState from '~/features/Dispatch/stores/DispatchRegistrationPersonState';
import useDispatchRegistrationState from '~/features/Dispatch/stores/DispatchRegistrationState';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import { Card, CardActions, CardContent, Divider, FormControl, Grid, TextField } from '@mui/material';
import { Downgraded, State } from '@hookstate/core';
import { LoadingButton } from '@mui/lab';
import useDispatchRegistrationAnimalState from '~/features/Dispatch/stores/DispatchRegistrationAnimalState';
import FieldAutocompleteRegistration from '~/components/FieldAutocompleteRegistration';
import Buttons from '../../../DispatchRegistrationTab/components/Buttons';
import notify from '~/utils/notify';

interface IFormAnimals {
  readonly loading: State<boolean>
  readonly handleEdit: () => void;
  readonly handleClickOpenConfirmAction: (action: string) => void
}

export default function FormAnimal({ handleEdit, loading, handleClickOpenConfirmAction }: IFormAnimals) {
  const { translate } = useLang();

  const { dispatch } = useDispatchState();
  const { occurrence, status } = dispatch();
  const { id: occurrenceIdState } = occurrence;

  const disableFields = status.value.id === 8

  const {
    registrationAnimalDispatch,
  } = useDispatchRegistrationAnimalState();
  const { animal } = registrationAnimalDispatch();

  const { registrationDispatch, getCircunstances, getDestinations} = useDispatchRegistrationState();
  const { getSpecies, getBreeds, sendAnimal } = useDispatchRegistrationAnimalState();

  const { inputErrors, addRegistration } = registrationDispatch();

  const someInvalidInput = () => {
    if (!animal.get().specieId) {
      inputErrors.specieId.set(true)
    }
    if (!animal.get().animalCircunstance) {
      inputErrors.animalCircunstance.set(true)
    }


    return Object.values(inputErrors.attach(Downgraded).get()).some(value => !!value)
  }

  useEffect(() => {
    animal.set(prev => ({ ...prev, occurrenceId: occurrenceIdState.get() }))
  }, [])

  const handleSend = useCallback(async () => {
    loading.set(true)
    await sendAnimal(animal.get()).finally(() => {
      loading.set(false)
    })
    notify({ message: translate('Successfully created'), type: 'success' })
    addRegistration.set(false)
  }, [addRegistration, sendAnimal, translate, animal])


  const handleNext = async () => {
    if (someInvalidInput()) {
      return
    }
    handleSend();
    return
  };

  return (
    <FormControl fullWidth size='small'>
      <Grid container direction={'row'}>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            onValueChange={(specie) => { 
              animal.merge({ specieId: specie?.id })
              animal.breedId.set(null)
            }}
            valueId={animal.get()?.specieId as string}
            onError={(value) => inputErrors.specieId.set(value)}
            optionsFetch={getSpecies}
            label={translate('Specie')}
            error={inputErrors.specieId.get()}
            isRequired
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            label={translate('Breed')}
            onValueChange={(breed) => animal.breedId.set(breed?.id as string)}
            optionsFetch={getBreeds}
            valueId={animal.get()?.breedId as string}
            disabled={disableFields}
            filter={animal.get()?.specieId as string}
          />
        </Grid>

        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            label={translate('Circunstance')}
            onValueChange={(animalCircunstance) => animal.animalCircunstance.set({
              id: animalCircunstance?.id ?? '',
              name: animalCircunstance?.name ?? '',
            })}
            valueId={animal.get()?.animalCircunstance?.id as string}
            optionsFetch={getCircunstances}
            isRequired
            disabled={disableFields}
            error={inputErrors.animalCircunstance.get()}
            onError={(value) => inputErrors.animalCircunstance.set(value)}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            label={translate('Destination')}
            onValueChange={(destination) => animal.destination.set({
              id: destination?.id ?? '',
              name: destination?.name ?? '',
            })}
            valueId={animal.destination.get()?.id as string}
            optionsFetch={getDestinations}
            isRequired
            disabled={disableFields}
            error={inputErrors.destination.get()}
            onError={(value) => inputErrors.destination.set(value)}
          />
        </Grid>
        <Grid item xs={12} sx={{ padding: 1, marginBottom: 1 }}>
          <TextField
            disabled={disableFields}
            id="outlined-multiline-static"
            label={translate('Note')}
            multiline
            fullWidth
            size='small'
            rows={2}
            value={animal.get().notes}
            InputProps={{
              onChange: (event) => {
                const notes = event.target.value
                animal.merge({
                  notes,
                })
              }
            }}
            sx={{ height: 50 }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Buttons
          loading={loading}
          isUpdate={!!animal.id.get()}
          handleClickOpenConfirmAction={handleClickOpenConfirmAction}
          handleEdit={handleEdit}
          handleSend={handleSend}
          someInvalidInput={someInvalidInput}
          disableFields={disableFields}
        />
      </Grid>
    </FormControl>
  );
}