import { t as translate } from 'i18next';
import React from 'react';
import TextFieldEntryForm from '../TextFieldEntryForm';
import { Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';


export default function ReferenceField({readonly=false, isValid, reference}){
const { translate } = useLang();

if (!readonly) {
  return (
    <TextFieldEntryForm
    state={reference}
    id='reference'
    name='reference'
    label={translate('Reference')}
    required={false}
    disabled={!isValid}
/>
    )
  } else {
    return (
      <Typography>{translate('Reference')}: {reference.get() ?? translate('No information')}</Typography>
    )
  }

}