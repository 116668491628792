import React from "react";
import {IconButton, Grid} from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { useLang } from '~/hooks/useLang';

export default function SearchAdvancedButton({canSearchAdvanced, searchAdvancedUrl}){
  const { translate } = useLang()
  const ClickOpen = () => {
    window.open(searchAdvancedUrl, "_blank, width=auto, height=600, status = no");
  }
  
  return (
    <Grid item>
      <IconButton title={translate('See Search Advanced')} disabled={!canSearchAdvanced} onClick={ClickOpen}>
        <ManageSearchIcon/>
      </IconButton>
    </Grid>
  );

}