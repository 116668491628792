import React from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import dayjs from 'dayjs';
import { videomonitorinNarrationProps } from '~/features/Dispatch/pages/Print';

interface VideoMonitorinPrintResponse {
  readonly VideoMonitorinPrintResponse: videomonitorinNarrationProps[];
}

export default function VideomonitoringPrint({VideoMonitorinPrintResponse }: VideoMonitorinPrintResponse) {
  const { translate } = useLang();

  return (
    <Container fixed={false}>
      <Grid container xs={12} sx={{ marginBottom: 2 }}>
        <Typography variant='subtitle1'>
        </Typography>
        {VideoMonitorinPrintResponse.length != 0 ? (
          VideoMonitorinPrintResponse.map((comment) => {
            return(
                <Grid container key={comment?.id} xs={12} sx={{ margin: 1 }}>
                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                  <Typography variant='subtitle1'>
                    {comment?.notes ?? `* ${translate('Uninformed')} *`}
                    {' '}
                  </Typography>
                </Grid>
                <Grid item xs={12} flexDirection={'column'} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                  <Typography variant='caption' sx={{ alignItems: 'flex-start' }}>
                    <b>
                      {translate('User')}
                      :
                    </b>
                      {' '}
                      {comment?.user?.name}
                    
                    
                    </Typography>
                    <Typography variant='caption' sx={{ alignItems: 'flex-start' }}>
                      <b>
                    {translate('Camera')}
                    :
                    </b>
                    {' '}
                    {comment?.camera?.name}
                    </Typography>
                    <Typography variant='caption' sx={{ alignItems: 'flex-start' }}>
                    <b>
                    {translate('Reported at')}
                    :
                    </b>
                    {' '}
                    {comment?.reported_at && dayjs(comment?.reported_at).isValid() ? dayjs(comment?.reported_at).format('DD/MM/YYYY HH:mm:ss') : `* ${translate('Uninformed')} *`}
                    </Typography>
                </Grid>
              </Grid>
            )   
          })
        ) : (
          <Grid item xs={12} textAlign={'center'}>
            <Typography variant='subtitle1'>
              <b>
                {translate('NO INFORMATION REGISTERED')}
              </b>
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  )
}