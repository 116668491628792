import React, { useEffect } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { getObservations } from '~/features/Dispatch/services';
import { useHookstate } from '@hookstate/core';
import dayjs from 'dayjs';

interface RegulatoryObservationsProps {
  readonly id: number;
  readonly notes: string;
  readonly created_at: string;
  readonly user: {
    readonly id: number;
    readonly name: string;
    readonly representation_name: string;
  };
};

export default function RegulatoryObservations({ id }) {
  const { translate } = useLang();
  const regulatoryObservations = useHookstate<readonly RegulatoryObservationsProps[] | null>(null);

  useEffect(() => {
    getObservations(id).then((response) => {
      regulatoryObservations.set(response)
    })
  }, [])

  return (
    <Container fixed={false}>
      {regulatoryObservations.get()?.length ? (<Grid container xs={12} sx={{ p: 1, border: 1 }}>
        <Grid item xs={2} >
          <Typography variant='subtitle1'>
            <b>
              DATA E HORA
            </b>
          </Typography>
        </Grid>
        <Grid item xs={2} >
          <Typography variant='subtitle1'>
            <b>
              USUÁRIO
            </b>
          </Typography>
        </Grid>
        <Grid item xs={8} >
          <Typography variant='subtitle1'>
            <b>
              OBSERVAÇÃO
            </b>
          </Typography>
        </Grid>
      </Grid>) : (
        <Grid item xs={12} textAlign={'center'}>
          <Typography variant='subtitle1'>
            <b>
              {translate('NO INFORMATION REGISTERED')}
            </b>
          </Typography>
        </Grid>
      )}
      {regulatoryObservations.get()?.map(row => (
        <Grid container key={row.id} xs={12} sx={{ border: 1, borderTop: 0, display: 'flex', alignItems: 'center', justifyContent: "center" }}>
          <Grid item xs={2} >
            <Typography variant='subtitle1' sx={{ pl: 1, }}>
              {row.created_at ? dayjs(row.created_at).format('DD/MM/YYYY') : 'NI'}
            </Typography>
          </Grid>
          <Grid item xs={2} >
            <Typography variant='subtitle1' sx={{ paddingLeft: 0.5 }}>
              {row.user.representation_name}
            </Typography>
          </Grid>
          <Grid item xs={8} >
            <Typography variant='subtitle1' sx={{ paddingLeft: 0.5 }}>
              {row.notes}
            </Typography>
          </Grid>
        </Grid>
      ))}
      {!!regulatoryObservations.get()?.length && <Typography variant='caption'>
        NI - Não Informado
      </Typography>}
    </Container >
  );
}