import React from 'react';
import Create from './pages/Create';
import Edit from './pages/Edit';
import Index from './pages/Index';
import Dashboard from './pages/Dashboard';
import { AppRouteProps } from '~/routes';

export default function OperationRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    { path: '/operation', component: Index, isProtected: true, permissions: ['operation_index']},
    { path: '/operation/create', component: Create, isProtected: true, permissions: ['operation_create'] },
    { path: '/operation/edit/:id', component: Edit, isProtected: true, permissions: ['operation_edit'] },
    { path: '/operation/dashboard', component: Dashboard, isProtected: true, permissions: ['operation_dashboard'] },
  ];

  return routes;
}
