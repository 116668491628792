import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useListVehiclesSocket from '../stores/ListVehiclesSocketState';

interface MenuListProps {
  readonly id: string
  readonly anchorEl: HTMLElement | null
  readonly handleClose: () => void
  readonly readOnly?: boolean;
}

export default function MenuList({ id, anchorEl, handleClose, readOnly = false }: MenuListProps) {
  const { translate } = useLang();
  const {
    listVehiclesState,
  } = useListVehiclesSocket();
  const {
    openConectDispatchVehicle,
    openOutOfServiceDialog,
    openReturnToServiceDialog,
    canConnectDevice,
    canDisconnectDevice,
    canSeeCrew,
    canSetOutOfService,
    canSetReturnToService,
    openCrewDialog,
    openDisconnectDialog,
    canTransferDevice,
    openTransferDevice,
    openChronologyVehicle,
  } = listVehiclesState();


  return (
    <Menu
      id={id}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClose={handleClose}
      sx={{
        marginTop: '30px'
      }}
    >
      {canConnectDevice.get() && !readOnly &&
        <MenuItem onClick={() => {
          openConectDispatchVehicle.set(true);
          handleClose();
        }}>
          {translate('Conectar')}
        </MenuItem>
      }
      {(canConnectDevice.get() || canDisconnectDevice.get()) && !readOnly &&
        <MenuItem onClick={() => {
          openTransferDevice.set(true);
          handleClose();
        }}>
          {translate('Transfer')}
        </MenuItem>
      }
      {canSeeCrew.get() && <MenuItem onClick={() => {
        openCrewDialog.set(!openCrewDialog.get())
        handleClose()
      }}>
        {translate('See Crew')}
      </MenuItem>}
      {canSetOutOfService.get() && !readOnly && <MenuItem onClick={() => {
        openOutOfServiceDialog.set(true)
        handleClose()
      }}>
        {translate('Out of service')}
      </MenuItem>}
      <MenuItem onClick={() => {
        openChronologyVehicle.set(true)
        handleClose()
      }}>
        {translate('Chronology device')}
      </MenuItem>
      {!canConnectDevice.get() && canDisconnectDevice.get() && !readOnly && <MenuItem onClick={() => {
        openDisconnectDialog.set(true);
        handleClose()
      }}>
        {translate('Disconnect')}
      </MenuItem>}
      {(canSetReturnToService.get() && !readOnly) && <MenuItem onClick={() => {
        openReturnToServiceDialog.set(true)
        handleClose()
      }} >
        {translate('Return to service')}
      </MenuItem>}
    </Menu>
  )
}