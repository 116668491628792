import { IPersonDTO, IRawPerson } from "~/features/Dispatch/interfaces/IDispacthRegistrationPerson";
import { ITransferCrew } from "~/features/Vehicle/interfaces/ITranferCrew";

export function toFetch(data: ITransferCrew) {
  return {
    from_device_id: data.fromDeviceId,
    to_device_id: data.toDeviceId,
    to_km_initial: data.toKmInitial
  }
}
