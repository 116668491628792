import { authFetch } from "~/services/fetch";
import { DispatchGroupSetResponse } from "../interface";

export async function getDispatchGroupSet(): Promise<readonly DispatchGroupSetResponse[]> {
  try {
    const { data } = await authFetch({
      url: '/dispatch/group-set',
      method: 'GET',
    })
    return data;
  } catch (error) {
    throw error;
  }
}