import React from 'react';
import { Divider, Grid, TextField, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { UseFormGetValues, UseFormRegister, Controller, UseFormSetValue } from 'react-hook-form';
import InputMask from 'react-input-mask';
import ReactQuill from 'react-quill';
import { useHookstate } from '@hookstate/core';
import notify from '~/utils/notify';

interface CrudLongTextFieldProps {
  readonly register: UseFormRegister<any>;
  readonly model: string;
  readonly mask?: any | undefined;
  readonly getValues?: UseFormGetValues<any>;
  readonly passwd?: boolean;
  readonly control?: any;
  readonly placeholder?: string;
  readonly required?:boolean;
  readonly readonly?: boolean;
  readonly setFormValue: UseFormSetValue<any>;

}

export default function CrudLongTextField(props: CrudLongTextFieldProps) {
  const { translate } = useLang();
  const { register, model, mask, getValues, passwd = false, control, placeholder, required = false, readonly = false, setFormValue} = props;
  const registeredModelFormField = register(model);
  const reportFormatedSend = useHookstate('');

  return (
    <Grid item xs={12}>
      <Grid style={{ height: '75vh' }}>
      <Controller
          {...registeredModelFormField }
          name={model}
          rules={{ required: {
            value: required,
            message: translate(`Fill the ${props.model} field.`)
          } }}
          control={control}
          defaultValue={(getValues && getValues( model )) ?? ''}
          render={(props) => {
            props.fieldState.error && (!props.field.value || props.field.value == '<p><br></p>') && notify({message: props.fieldState.error.message ?? translate('Fill in this field.'), type: 'error'})
            return (
                <>
                <Typography>
                {translate(placeholder ? placeholder : model)}
                {required ? '*' : ''}
                </Typography>
                <ReactQuill
                  value={props.field.value}
                  onChange={(text) => {
                    if(text == '<p><br></p>'){
                      setFormValue(model, null);
                    }else{
                      setFormValue(model, text);
                      reportFormatedSend.set(text);
                    }
                  } }
                  style={{
                    height: '80%',
                  
                  }} />
                </>
            )}
          }
        />
        
      </Grid>
    </Grid>
  )
}