import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IOperation from '../interfaces/IOperation';


export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue,getValues} = useForm<IOperation>();
  const fields = [
    { name: 'name', type: 'string'},
    { name: 'start_date', type: 'datetime', placeholder: 'Initial Date'},
    { name: 'end_date', type: 'datetime', placeholder: 'End Date'},
    { name: 'active', type: 'boolean'},
    { name: 'id', type: 'hidden' },
  ];
  
    return(
      <CrudEdit model={'operation'} 
      fields={fields} 
      register={register} 
      handleSubmit={handleSubmit} 
      control={control}
      setFormValue={setFormValue}
      messageSuccess={'Operation successfully updated!'}  
      title={'Edit Operation'}  
      getValues={ getValues }
      />
    )
}



