/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState, type HTMLAttributes } from 'react';
import { Autocomplete, FormControl, Paper, TextField, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate, type State } from '@hookstate/core';
import { AgencyResponse } from './interface';
import { getAgencies } from './service';

interface AgencyProps {
    readonly onAgencyChange: (value: number | null) => void;
    readonly agencyId?: number | null;
    readonly disabled?: boolean;
    readonly error?: State<string | null | undefined>;
}

export default function FilterAutocompleteAgency({ disabled, onAgencyChange, agencyId, error }: AgencyProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly AgencyResponse[]>([]);
    const [value, setValue] = useState<AgencyResponse | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        getAgencies().then(agencies => {
            setOptions(agencies)
            setValue(null)
        })
    }, [])

    useEffect(() => {
        if (!agencyId) {
            setValue(null)
        } else {
            setValue(options.find(opt => opt.id === agencyId) ?? null)
        }
    }, [agencyId])


    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                id='filter-agencies'
                limitTags={2}
                disabled={disabled}
                sx={{ height: 2 }}
                options={options}
                value={value}
                noOptionsText={translate('No options')}
                clearText={translate('Clear')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name && option.acronym ? `[${option.acronym}] ${option.name}` : option.name ?? ''}
                onChange={(_, type) => {
                    setValue(type)
                    onAgencyChange(type?.id ?? null)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Agency:name')}
                        error={!!error?.get()}
                        helperText={error?.get()}
                    />
                )}
            />
        </FormControl>
    )
}