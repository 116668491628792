import React from "react";
import { IconButton, Grid } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { DirectionsCar } from '@mui/icons-material';
import useEntryState from '~/features/Entry/stores/EntryState';
import useSystemState from "~/features/System/stores/SystemState";

export default function AddVehicleEntryButton({ canAddVehicles }) {
  const { translate } = useLang();
  const { windowManager } = useSystemState();


  return (
    <Grid item>
      <IconButton
        title={translate('Add Vehicle')}
        disabled={!canAddVehicles}
        onClick={() => windowManager()['VehicleModal'].open.set(!windowManager()['VehicleModal'].open.get())}
      >
        <DirectionsCar />
      </IconButton>
    </Grid>
  )
}