import React, { useState, ReactNode, useEffect } from 'react';
import './styles.scss';
import { Box, Card, CardContent, CardHeader, IconButton, Divider, CardActions, SxProps, Theme, Typography, TypographyPropsVariantOverrides } from '@mui/material';
import { ExpandMore, ExpandLess, Close } from '@mui/icons-material';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { State, useHookstate } from '@hookstate/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type ModalProperties = {
  readonly avatar?: any;
  readonly title: any;
  readonly subheader?: any;
  readonly children: ReactNode;
  readonly dimensions?: ModalDimensions;
  readonly initialPosition?: coordinates;
  readonly position: coordinates;
  readonly autoFocus?: boolean;
  readonly bounds?: string;
  readonly closeButton?: boolean | (() => void);
  readonly isExpanded?: State<boolean>;
  readonly lat?: number | null;
  readonly lng?: number | null;
  readonly handle?: string;
  readonly handleStop?: any;
  readonly sx?: SxProps<Theme>;
  readonly variant?: any;
  readonly sxContent?: SxProps<Theme>;
};

type ModalDimensions = {
  readonly width?: number | string;
  readonly height?: number | string;
};

type coordinates = {
  readonly x: number;
  readonly y: number;
}


export default function StandardModal({
  dimensions = {},
  avatar: Avatar,
  title,
  subheader,
  position,
  bounds = '#root',
  closeButton,
  isExpanded,
  children,
  handleStop,
  handle = ".standard-modal",
  sx,
  variant = 'body1',
  sxContent,
}: ModalProperties) {
  // const [modalIsExpanded, setmodalIsExpanded] = useState(true);
  const [modalIsClosed, setModalIsClosed] = useState(false);
  const isDragging = useHookstate(false)

  const toggleExpandModal = () => {
    return isExpanded?.set(!isExpanded?.get())
  }
  const closeButtonFunc = () => {
    if (typeof closeButton === 'boolean') {
      setModalIsClosed(true);
    }
    if (typeof closeButton === 'function') {
      closeButton();
      // setModalIsClosed(true);
    }
  }
  // function ExpandIconComponent() {
  //   return (
  //     <IconButton onClick={toggleExpandModal} sx={{ zIndex: 9999 }}>
  //       {(Boolean(isExpanded?.get())) ? <ExpandLess /> : <ExpandMore />}
  //     </IconButton>
  //   )
  // }
  // function CloseButtonComponent() {
  //   return (
  //     <IconButton sx={{ margin: '0 !important' }} onClick={closeButtonFunc} >
  //       {<Close />}
  //     </IconButton>
  //   )
  // }

  // if (modalIsClosed) return (null);

  return (
    <Draggable
      bounds={bounds}
      handle={handle}
      onStop={handleStop}
      defaultPosition={{ x: position.x, y: position.y }}
    >
      <Box sx={{ zIndex: 999, position: 'fixed', display: 'inline-block', ...sx }}>
        <Card>
          <CardHeader
            avatar={
              Avatar ? <Avatar /> : null
            }
            sx={{
              cursor: 'move'
            }}
            className="standard-modal"
            action={
              <CardActions>
                <IconButton onClick={toggleExpandModal} sx={{ zIndex: 9999 }}>
                  {(Boolean(isExpanded?.get())) ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
                {Boolean(closeButton) &&
                  <IconButton sx={{ margin: '0 !important' }} onClick={closeButtonFunc} >
                    {<Close />}
                  </IconButton>
                }
              </CardActions>
            }
            title={title}
            subheader={
              subheader ? subheader : ''
            }
            titleTypographyProps={{variant: variant ? variant : 'body1'}}
          />
          <Divider />

          <CardContent
            sx={{
              display: (isExpanded?.get()) ? 'flex' : 'None', ...dimensions,
              "&:last-child": {
                paddingBottom: '16px',
              },
              ...sxContent
            }}
          >
            {children}
          </CardContent>

        </Card>
      </Box>
    </Draggable>
  );
}
