import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { ColumnChart as ColumnChartGraph } from 'bizcharts';

export default function ColumnChart({ data, title, height=400, width=0, subtitle='', sxFont={} }) {
  const { translate } = useLang();

  const dataTratament = data.map((element) => { 
    return { item: String(element.item).replaceAll(' ', '\n'), value: element.value }
  })

  return (
    <Card>
      <CardContent>
        <Typography sx={sxFont}>
          {translate(title)}
        </Typography>
        {subtitle && 
        <Typography variant='subtitle2'>
          {translate(subtitle)}
        </Typography>
        }
        <ColumnChartGraph
          data={dataTratament}
          forceFit={false}
          padding='auto'
          xField='item'
          yField='value'
          width={width != 0 ? width : undefined}
          height={height}
        />
      </CardContent>
    </Card>
  )
}