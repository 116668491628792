import React from 'react';
import { IconButton, CardActions, Tooltip, Typography, Dialog, DialogContent, Button } from '@mui/material';
import { State, useHookstate } from '@hookstate/core';
import { MyLocation, PinDrop } from '@mui/icons-material';

interface EntryModalLocationProps {
  readonly latitude?: number | null;
  readonly longitude?: number | null;
  readonly closeButton?: boolean | (() => void);
  readonly isExpanded?: State<boolean>;
  readonly modalIsExpanded: boolean;}


export default function EntryModalLocation({ latitude, longitude, modalIsExpanded }: EntryModalLocationProps) {
  const modalLocation = useHookstate(false);
  
  return (
    <>
      <CardActions sx={{margin: 0, padding:0}}>
        {modalIsExpanded  && (
          <Tooltip title={`${latitude?.toFixed(8)}, ${longitude?.toFixed(8)}`} placement='top'>
            <IconButton
              onClick={() => modalLocation.set(true)}
              sx={{ margin: '0 !important' }}
              disabled={!Boolean(latitude && longitude)}
              >
              <MyLocation sx={{ display: 'inline' }} />
            </IconButton>
          </Tooltip>
         )}
      </CardActions>
      <Dialog open={modalLocation.get()} onClose={() => modalLocation.set(false)}>
        <DialogContent sx={{ alignItems: 'center', alignContent: 'center' }}>
          <Typography>
            Latitude/Longitude: {latitude?.toFixed(8)}, {longitude?.toFixed(8)}
            </Typography>
          <Button
            variant="contained"
            endIcon={<PinDrop />}
            color='primary'
            onClick={() => window.open(`https://www.google.com.br/maps?q=loc:${latitude?.toFixed(8)},${longitude?.toFixed(8)}`)}
          >
            Google Maps
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}