import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IType from '../interfaces/IType';
import { iconDeviceMarkersOptions } from '~/features/Map/components/MarkerGenerator/components/DeviceMarker/icon';



export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue, getValues} = useForm<IType>();
  const fields = [
      { name: 'id', type: 'hidden'},
      { name: 'name', type: 'string'},
      { name: 'icon', type: 'select', options: iconDeviceMarkersOptions },
      { name: 'label_commander', type: 'string'},
      { name: 'label_driver', type: 'string'},
      { name: 'label_patrolmans', type: 'string'}, 
      { name: 'label_km_initial', type: 'string'}, 
  ];
  
    return(
      <CrudEdit 
        model={'device-type'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        setFormValue={setFormValue}
        messageSuccess={'Device Type successfully updated!'}  
        title={'Edit Device Type'}
        getValues={getValues} 
      />
    )
}
