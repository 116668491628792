import React from 'react'
import { Downgraded, useHookstate } from '@hookstate/core';
import { Grid, Typography } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { Controller } from 'react-hook-form';
import { useLang } from '~/hooks/useLang';
import notify from '~/utils/notify';
import FilterLine from './components/FilterLine';
import { ptBR } from '@mui/x-data-grid/locales';

export default function CrudTableField({ 
  register, 
  model, 
  getValues, 
  control, 
  placeholder, 
  columns, 
  lines, 
  filterModel, 
  rows, 
  required = false,
  validate = () => {return true},
  columnGroup = []
}) {
  const { translate } = useLang();
  const defaultValue = useHookstate([])
  const result = validate()

  function addButton(value){
    const alreadyExist = rows.get().find(item => item.id == value)

    if(!alreadyExist){
      const newRow = lines.find(item => item.id == value)
      rows.merge([newRow])
      defaultValue.set(rows.get())
    }
  }

  return (
    <>
      <Grid item xs={12}>
      <Typography variant='subtitle1'>
      {translate(model)}
      {required ? '*' : ''}
      </Typography>
        {filterModel && <FilterLine filter={filterModel} addButton={addButton}/>}
      </Grid>

    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}>
      <Controller
      {...register(`${model}`)}
      name={model}
      control={control}
      defaultValue={defaultValue.get()}
      rules={{ validate: validate }}
      render={(props) => {
        props.fieldState.error && result != true && notify({message: translate(props.fieldState.error.message ?? 'Fill in this field.'), type: 'error'})
        return (
        <DataGrid 
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        columns={columns} 
        columnVisibilityModel={{
          id: false
        }}
        columnGroupingModel={columnGroup}
        rows={rows.attach(Downgraded).get()} 
        autoHeight 
        sx={{ 
          minHeight: '250px', 
          [`.${gridClasses.columnHeaderTitle}`]: {
            fontWeight: 'bold !important',
          },
        }} 
        hideFooterPagination={true}
        disableColumnMenu
        disableColumnSorting
        />
      )
      }}
      />
    </Grid>
    </>
  )
}