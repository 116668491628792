import { IRawVehicle, IVehicleDTO } from "~/features/Dispatch/interfaces/IDispacthRegistrationVehicle";
import { maskPhoneNumber, removeMask } from "~/features/Dispatch/utils/stringsManipulations";

export function toDomain(vehicle: IRawVehicle): IVehicleDTO {
  return {
    id: vehicle.id,
    vehicleType: vehicle.vehicle_type,
    plate: vehicle.plate,
    owner: vehicle.owner,
    vehicleModel: vehicle.vehicle_model,
    vehicleColor: vehicle.vehicle_color,
    vehicleBrand: vehicle.vehicle_brand,
    notes: vehicle.notes,
    circunstance: vehicle.circunstance,
    destination: vehicle.destination,
    occurrenceId: vehicle.occurrence_id,
    ownerCpf: vehicle.owner_cpf,
    ownerPhone: vehicle.owner_phone ? maskPhoneNumber(vehicle.owner_phone) : '',
    state: vehicle.state,
    integrationCreatedAt: vehicle.integration_created_at,
    integrationByUserId: vehicle.integration_by_user_id,
    integrationCanceledAt: vehicle.integration_canceled_at,
    canceledByUserId: vehicle.canceled_by_user_id,
  }
}

export function toFetch(vehicle: IVehicleDTO) {
  return {
    occurrence_id: vehicle.occurrenceId,
    vehicle_type_id: vehicle.vehicleType?.id,
    circunstance_id: vehicle.circunstance?.id,
    destination_id: vehicle.destination?.id,
    plate: vehicle.plate || null,
    vehicle_brand_id: vehicle.vehicleBrand?.id ?? null,
    vehicle_model_id: vehicle.vehicleModel?.id ?? null,
    vehicle_color_id: vehicle.vehicleColor?.id ?? null,
    uf_id: vehicle.state?.id ?? null,
    notes: vehicle.notes || null,
    owner: vehicle.owner || null,
    owner_cpf: removeMask(vehicle.ownerCpf) || null,
    owner_phone: removeMask(vehicle.ownerPhone) || null,
    integration_created_at: vehicle.integrationCreatedAt,
    integration_by_user_id: vehicle.integrationByUserId,
    integration_canceled_at: vehicle.integrationCanceledAt,
    canceled_by_user_id: vehicle.canceledByUserId,
  }
}
