import React, { useCallback, useEffect } from 'react';
import {
    Box, 
    TextField, 
    Grid, 
    Autocomplete, 
    Typography,
    AutocompletePropsSizeOverrides,
    Theme
} from '@mui/material';
import {useLang} from '~/hooks/useLang';
import { OverridableStringUnion } from '@mui/types';
import { SxProps } from '@mui/system';


import { useHookstate } from '@hookstate/core'

interface SelectAutoCompleteProps{
    readonly id?: string;
    readonly name?: string;
    readonly placeholder?: string;
    readonly serverSide?: boolean;
    readonly setKey?: (opt: any) => any;
    readonly setOptionSelect?: (opt: any) => string;
    readonly showOptionLabel?: (opt: any) => string;
    readonly fetchData: (args?: string) => Promise<any> | any;
    readonly getValue?: ((value?: any) => any);
    readonly defaultValue?: any;
    readonly disabled?: boolean;
    readonly size?: OverridableStringUnion<'small' | 'medium', AutocompletePropsSizeOverrides>;
    readonly sx?: SxProps<Theme>;
    readonly error?: boolean | undefined
}


function SelectAutoComplete({id, name, placeholder, serverSide, setKey, setOptionSelect, showOptionLabel, fetchData, getValue, defaultValue, disabled, size, sx, error }: SelectAutoCompleteProps) {
  const value = useHookstate('');
  const inputValue = useHookstate('');
  // const options = useHookstate([]);
  const [options, setOptions] = React.useState([]);
  const readOnly = useHookstate(false);
  const useClient = useHookstate(false);
  const clientData = useHookstate([]);

  const { translate } = useLang()


  const defaultSearchLength = 2;


  const fetch = (input: string) => {
    if (serverSide) {
      if(input.length >= defaultSearchLength) {
        return fetchData(input)
          .then((response) => response);
      }
      return [];
    }

    if(useClient.get()) {
      return clientData.get();
    }

    return Promise.resolve(fetchData(input))
      .then((value) => {
        clientData.set(value);
        return value;
      })
      .catch((error) => {
        console.error(error);
      })
  };

  useEffect(() => {
    if(defaultValue) {
      
      if(typeof defaultValue == 'function') {
        return defaultValue().then((defaultOption) => value.set(defaultOption));
      } else {
        return value.set(defaultValue);
      }
    } else {
      return value.set(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    let active = true;
    
    Promise.resolve(fetch(inputValue.get()))
    .then((results) => setOptions(results))

    return () => {
      active = false;
    }
  }, [inputValue.get()]);


  useEffect(() => {
    if(!serverSide && typeof fetchData == 'function') Promise.resolve(fetchData()).then(setOptions);
  }, [fetchData]);


  useEffect(() => {
    useClient.set(true);
  }, [clientData]);

  const onChangeValueEvent = async (__, newValue) => {
    const inptValue = JSON.parse(JSON.stringify(newValue));

    value.set(inptValue);  

    if (getValue){
      getValue(inptValue);
    }

    if(inptValue) {
      readOnly.set(true);
    }
  }

  const onInputChangeValueEvent = (__, newValue) => {
    inputValue.set(newValue);
    
    if(!newValue) {
      readOnly.set(false);
    }
  }

  const getOptionLabel = (option) => {
    if(setOptionSelect) {
      return setOptionSelect(option); 
    }

    return option;
  }

  return (
    <Autocomplete
        disabled={!!disabled}
        sx={sx ? sx : {}}
        size={size ? size : 'medium'}
        value={value.get()}
        autoHighlight
        autoComplete
        includeInputInList
        filterSelectedOptions
        options={options}
        noOptionsText={translate('No options')}
        getOptionLabel={getOptionLabel}
        onChange={onChangeValueEvent}
        onInputChange={onInputChangeValueEvent}
        renderInput={(params) => (
          <TextField 
            error={error ? error : false}
            {...params} 
            label={placeholder || ''} 
            fullWidth 
            inputProps={{...params.inputProps, readOnly: readOnly.get() }}
          />
        )}
        isOptionEqualToValue={(option, currentValue) =>
          currentValue === undefined || currentValue === '' || option['id'] === currentValue['id']
        }
      />
  )
}


export default SelectAutoComplete;