/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  Autocomplete,
  FormControl,
  Grid,
} from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useDispatchRegistrationPersonState from '../../../../stores/DispatchRegistrationPersonState';
import useDispatchRegistrationState from '../../../../stores/DispatchRegistrationState';
import { maskCEP, maskNames, removeMask, maskAddress } from '../../../../utils/stringsManipulations';
import { IState } from '~/features/Dispatch/interfaces/IDispacthRegistrationVehicle';
import ICity from '~/features/City/interfaces/ICity';
import FieldAutocompleteRegistration from '~/components/FieldAutocompleteRegistration';

interface FormPeopleAddressProps {
  readonly disableFields?: boolean
}

export default function FormPeopleAddress({ disableFields = false }: FormPeopleAddressProps) {
  const { translate } = useLang();
  const {
    registrationPersonDispatch,
  } = useDispatchRegistrationPersonState();

  const {
    getStates,
    getCities,
    getAddress,
  } = useDispatchRegistrationState();


  const { person } = registrationPersonDispatch();

  const [statesList, setStatesList] = useState<readonly IState[]>([{
    id: '',
    name: '',
    uf: ''
  }]);
  const [citiesList, setCitiesList] = useState<readonly ICity[]>([{
    id: -1,
    name: '',
    state: {
      name: ''
    },
    stateId: -1
  }]);




  return (
    <FormControl fullWidth size='small' >
      <Grid container direction={'row'}>
        <Grid item xs={2.5} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-cep"
            label={translate('CEP')}
            value={person.get().cep}
            InputProps={{
              onChange: async (event) => {
                const cep = event.target.value;
                person.merge({
                  cep: maskCEP(cep)
                })
                if (cep.length === 9) {
                  const addressViaCep = await getAddress(removeMask(cep));

                  if (!addressViaCep.data.erro) {
                    const state = statesList.find(state => state.uf === addressViaCep.data.uf)
                    const cities = await getCities(state?.id);
                    setCitiesList([...citiesList, ...cities]);
                    const city = cities.find(city => city.id === parseInt(addressViaCep.data.ibge))
                    person.merge({
                      district: addressViaCep.data.bairro.toLocaleUpperCase().replace(/[^A-Z_ ]/g, ""),
                      cityId: city?.id,
                      address: addressViaCep.data.logradouro.toLocaleUpperCase().replace(/[^A-Z_ ]/g, ""),
                      stateId: state?.id
                    })
                  }
                } else {
                  person.merge({
                    district: '',
                    address: '',
                    cityId: '',
                    stateId: ''
                  })
                }
              },
            }}
          />
        </Grid>
        <Grid item xs={5} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-address"
            label={translate('Address')}
            focused={!!person.get().address}
            value={person.get().address}
            InputProps={{
              onChange: (event) => person.merge({
                address: maskAddress(event.target.value)
              }),
            }}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-district"
            label={translate('District')}
            focused={!!person.get().district}
            value={person.get().district}
            InputProps={{
              onChange: (event) => person.merge({
                district: maskNames(event.target.value)
              }),
            }}
          />
        </Grid>
        <Grid item xs={1.5} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-age"
            label={translate('Number')}
            value={person.get().addressNumber}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: { min: 0 },
              onChange: (event) =>
                person.merge({
                  addressNumber: event.target.value
                }),
            }}
          />
        </Grid>
        <Grid item xs={6} sx={{ padding: 1 }}>
          <TextField
            disabled={disableFields}
            fullWidth
            size='small'
            id="outlined-address-reference"
            label={translate('Address Reference')}
            value={person.get().addressReference}
            InputProps={{
              onChange: (event) => {
                person.merge({
                  addressReference: maskAddress(event.target.value)
                })
              }
            }}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            label={translate('State')}
            onValueChange={(state) => {
              person.merge({
                stateId: state?.id,
                cityId: null
              })
            }}
            valueId={person.stateId.get() as string}
            optionsFetch={getStates}
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={3} sx={{ padding: 1 }}>
          <FieldAutocompleteRegistration
            label={translate('City')}
            onValueChange={(city) => {
              if (!city) {
                return
              }
              person.merge({
                cityId: city?.id,
              })
            }}
            valueId={person.cityId.get() as string}
            optionsFetch={getCities}
            disabled={!person.stateId.get() || person.stateId.get() === '0' || disableFields}
            depends={person.stateId.get() as string}
          />
        </Grid>
      </Grid>
    </FormControl >
  );
}