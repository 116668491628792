/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import { 
  Button, TextField, 
  Paper, Box, Grid, Alert, Modal, ImageListItem 
} from '@mui/material';
import Logo from '../../../components/Logo';
import Copyright from '../../../components/Copyright';
import { Theme, configTheme } from '../../../components/Theme';
import { useLang } from '../../../hooks/useLang';
import activateStation from '../../Station/services';
import { useAuth } from '../../auth/hooks/useAuth';
import constants from '~/core/constants';
import useCookie from '~/hooks/useCookie';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { getAllowAnonymousStationConfigService } from '~/features/System/services';

const modal = document.getElementById('div-modal') as HTMLElement;

interface IActivationForm {
  readonly authenticationKey: string;
  readonly login: string;
  readonly password: string;
}

export default function Activation() {
  const { translate } = useLang()
  const { control, handleSubmit } = useForm<IActivationForm>({ shouldUseNativeValidation: false  }); 
  const [openActivationDialog, setOpenActivationDialog] = useState<boolean>(true);
  const [activateError, setActivateError] = useState('');
  const [identificationKey, setIdentificationKey] = useCookie(constants.keys.cookies.identificationKey);
  const [anonymousKey, setAnonymousKey] = useCookie(constants.keys.cookies.anonymousIdentificationKey);
  const [verificationKey, setVerificationKey] = useState(''); 
  const [showVerificationKey, setShowVerificationKey] = useState(false);
  const [showAnonymousStationButton, setShowAnonymousStationButton] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!!identificationKey) {
      window.location.href = history.createHref({pathname: '/'}); 
    }
    document.title = translate('Dispatch System')
  },[]);


  useEffect(() => {
    getAllowAnonymousStationConfigService().then((response) => setShowAnonymousStationButton(response === '1' || response === 1));
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => setActivateError(''), 3000);
    return () => clearTimeout(timeout);
  }, [activateError]);

  const handleShowDialogOpen = () => {
    setShowVerificationKey(true);
  };

  const handleShowDialogClose = () => {
    setShowVerificationKey(false);
    window.location.href = history.createHref({pathname: '/'});
  };

  const onSubmit: SubmitHandler<IActivationForm> = async ({login, password, authenticationKey}) => {     
    activateStation({ login, password, authenticationKey })
      .then((response) => {
        if (response) { 
          setIdentificationKey(response.identificationKey);
          setVerificationKey(response.verificationKey);
          handleShowDialogOpen();
        }
      })
      .catch((error) => {
        setActivateError(error);
      });
    return;
  }


  const onAnonymousSubmit = () => {
    const key = 'anonymous';
    setIdentificationKey(key);
    setAnonymousKey(key);
    // setVerificationKey(key);
    handleShowDialogClose();
  }

  return (
    !verificationKey ?
    (ReactDOM.createPortal(
    <Theme>
      <Modal
        open={openActivationDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square >
          <Box sx={ boxStyle1 }>
            <Grid item xs={2}>
              <ImageListItem>
                <Logo/>
              </ImageListItem>
            </Grid>
            <Box 
              component="form" 
              onSubmit={handleSubmit(onSubmit)} 
              sx={boxStyle2}
            >    
              <Controller
                name='authenticationKey'
                control={control}
                defaultValue=''
                  
                render={({field}) =>( 
                  <TextField
                    {...field}
                    margin="normal"
                    fullWidth
                    id="authenticationKey"
                    label={translate('Authentication Key')}
                    name="authenticationKey"
                    autoFocus
                    required
                  />)
                }
                rules={{
                  required: true,
                }}
              />
                
              <Controller
                name='login'
                control={control}
                defaultValue='' 
                render={({field}) => 
                  <TextField
                    {...field}
                    margin="normal"
                    fullWidth
                    id="login"
                    label={('Login')}
                    name="login"
                    required
                  />
                }
                rules={{
                  required: true,
                }}
              />

              <Controller
                name='password'
                control={control}
                defaultValue=''
                render={({field}) => 
                  <TextField
                    {...field}
                    margin="normal"
                    fullWidth
                    label={translate('Password')}
                    type="password"
                    id="password"
                    required
                  />
                }
                rules={{
                  required: true,
                }}
              />      

              <Alert sx={{ display: activateError ? 'flex' : 'none' }} severity="error">
                {translate(activateError)}
              </Alert>
                          
              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                sx={{ mt: 3, mb: 2 }}
              >
                {translate('Activate')}
              </Button>

              {showAnonymousStationButton && (<Button
                id='btn-enter-station-without-activation'
                type="button"
                variant="text"
                size="large"
                fullWidth
                onClick={onAnonymousSubmit}
              >
                {translate('Enter Withtout Station')}
              </Button>)}

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>       
        </Grid> 
        </Modal>
    </Theme> , modal
    ))
    :
      (ReactDOM.createPortal(
       <Theme>
         <Dialog
          open={showVerificationKey}
          onClose={handleShowDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
         >
          <DialogTitle id="alert-dialog-title">
            {translate("Verification Code")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {translate("Please write down your verification code: ") + verificationKey }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleShowDialogClose} autoFocus>
              {translate("Confirmation")}
            </Button>
          </DialogActions>
         </Dialog>      
       </Theme>, modal)
      )
    );
}

const boxStyle1 = {                
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent:'center',            
  height:'100vh'
}

const boxStyle2 = { 
  mt: 3, 
  width:"400px",
  [configTheme('light', 14).breakpoints.down('md')]: {
    width:"100%",
    paddingLeft:'15px',
    paddingRight:'15px',
  }
}
