import React from 'react'
import {
  IconButton,
  Grid,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
} from '@mui/material'
import { useState } from '@hookstate/core'
import { DispatchSearchBox } from '../DispatchSearchBox'
import { SearchRounded } from '@mui/icons-material'
import { useLang } from '~/hooks/useLang'

export function SearchBoxButton({ canSearch = true }) {
  const { translate } = useLang()
  const isSearchDialog = useState(false)

  const toggleDialog = () => {
    const is = isSearchDialog.get()
    const isNot = !is

    isSearchDialog.set(isNot)
  }
  const handleClose = () => {
    toggleDialog()
  }

  return (
    <>
      <Grid item>
        <IconButton
          title={translate('Search Dispatch')}
          disabled={!canSearch}
          onClick={toggleDialog}
        >
          <SearchRounded />
        </IconButton>
      </Grid>
      <Dialog
        onClose={handleClose}
        open={isSearchDialog.get()}
        maxWidth={'xs'}
        fullWidth
      >
        <DialogContent>
          <DispatchSearchBox isOpen={isSearchDialog} isValid={canSearch} />
        </DialogContent>
        <DialogActions sx={{px: 3, gap: 1}}>
          <Button variant="outlined" onClick={toggleDialog}>
            {translate('Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
