import React from 'react'
import {
  IconButton,
  CardActions,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  DialogActions,
  Link,
} from '@mui/material'
import { State, useHookstate } from '@hookstate/core'
import { MyLocation, PinDrop } from '@mui/icons-material'
import { useLang } from '~/hooks/useLang'
import Close from '@mui/icons-material/Close'
interface DispatchModalLocationProps {
  readonly latitude?: number | null
  readonly longitude?: number | null
  readonly closeButton?: boolean | (() => void)
  readonly isExpanded?: State<boolean>
  readonly modalIsExpanded: boolean
}

export default function DispatchModalLocation({
  latitude,
  longitude,
  modalIsExpanded,
}: DispatchModalLocationProps) {
  const modalLocation = useHookstate(false)
  const { translate } = useLang()

  return (
    <>
      <CardActions sx={{ margin: 0, padding: 0 }}>
        {modalIsExpanded && (
          <IconButton
            onClick={() => modalLocation.set(true)}
            title={translate('See Lat/Long')}
            disabled={!Boolean(latitude && longitude)}
            sx={{ margin: '0 !important' }}
          >
            <MyLocation sx={{ display: 'inline' }} />
          </IconButton>
        )}
      </CardActions>
      <Dialog
        open={modalLocation.get()}
        onClose={() => modalLocation.set(false)}
        maxWidth={'xs'}
        fullWidth
      >
        <DialogTitle minWidth={'320px'} variant="h5">
          <Typography
            variant="h5"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {translate('Geographic Coordinates')}
            <IconButton
              title={translate('Close')}
              onClick={() => modalLocation.set(false)}
            >
              <Close />
            </IconButton>
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ alignItems: 'center', alignContent: 'center' }}>
          <Typography>
            Latitude/Longitude: {latitude?.toFixed(8)}, {longitude?.toFixed(8)}
          </Typography>
          <Button
            variant="text"
            color="primary"
            onClick={() =>
              window.open(
                `https://www.google.com.br/maps?q=loc:${latitude?.toFixed(
                  8
                )},${longitude?.toFixed(8)}`
              )
            }
            sx={{
              textTransform: 'none',
              textDecorationLine: 'underline',
              color: 'text.primary',
              ':hover': {
                bgcolor: 'transparent',
                color: 'primary.main',
              },
              fontSize: 16,
              pl: 0,
            }}
          >
            {translate('Open in Google Maps')}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}
