import React, { useEffect } from "react";
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import CircleIcon from '@mui/icons-material/Circle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { IconButton, Box } from "@mui/material";
import { useHookstate } from '@hookstate/core';

interface IComponentRenderSlideShow {
  readonly component: JSX.Element
  readonly durationMs?: number
  readonly refreshFunction: VoidFunction
}

interface ISlideShow {
  readonly componentsRender: readonly IComponentRenderSlideShow[]
}

export default function SlideShow({ componentsRender }: ISlideShow) {
  const actualComponentRender = useHookstate(0);
  const stop = useHookstate(false);
  const timerDefault = 20000;

  useEffect(() => {
    if (stop.get()) {
      const timerStop = setInterval(() => {
        componentsRender[actualComponentRender.get()].refreshFunction()
      }, componentsRender[actualComponentRender.get()].durationMs ? componentsRender[actualComponentRender.get()].durationMs : timerDefault )

      return () => clearInterval(timerStop)
    }
    const timer = setInterval(() => {
      if (actualComponentRender.get() < componentsRender.length -1) {
        actualComponentRender.set(actualComponentRender.get() + 1);
        if (actualComponentRender.get() == componentsRender.length -1){
          componentsRender[0]?.refreshFunction()
        } else {
          componentsRender[actualComponentRender.get() + 1].refreshFunction() 
        }
      } else {
        actualComponentRender.set(0);
        componentsRender.length > 2 ? componentsRender[1].refreshFunction() : false
      }
    }, componentsRender[actualComponentRender.get()].durationMs ? componentsRender[actualComponentRender.get()].durationMs : timerDefault )
    return () => clearInterval(timer);
  }, [actualComponentRender.get(), stop.get()])

  return (
    <Box style={{
      margin: '0 auto',
      overflow: 'hidden',
      maxWidth: '500vw',
      width: '100vw'
    }}>
      <Box style={{
          transform: `translate3d(${-actualComponentRender.get() * 100}%,0,0)`,
          whiteSpace: 'nowrap',
          transition: 'ease 1000ms'
        }}
      >
      {componentsRender.map((componentSlide, index) => {
      return (<Box key={index} style={{
            display: 'inline-block',
            height: '400px',
            width: '100%',
            borderRadius: '40px',
          }}>
            {componentSlide.component}
          </Box>)
      })}
      </Box>
      <Box style={{ display: 'flex', justifyContent : 'center', flexDirection: 'row' }}>
        {componentsRender.map((_, index) => (
          <IconButton key={index} onClick={() => {
            actualComponentRender.set(index)
            componentsRender[index].refreshFunction()
          }} >
            <CircleIcon fontSize="large" sx={{ color: actualComponentRender.get() === index ? '#ff9800' : '#d3d0cb' }} />
          </IconButton>
        ))}
        <IconButton onClick={() => {
          if( stop.get()){
            if (actualComponentRender.get() < componentsRender.length -1) {
              if (actualComponentRender.get() == componentsRender.length -1){
                componentsRender[0]?.refreshFunction()
              } else {
                componentsRender[actualComponentRender.get() + 1].refreshFunction() 
              }
            } else {
              componentsRender.length > 2 ? componentsRender[1].refreshFunction() : false
            }
          }
            stop.set(!stop.get())
          }
        }>
          {stop.get() == true ? <PlayCircleOutlineIcon fontSize="large" color="success" /> : <PauseCircleOutlineIcon fontSize="large" color="error" />}
        </IconButton>
      </Box>
    </Box>
  )
}