/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import { authFetch, mountUrlRequest } from '~/services/fetch';
import {
  IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, LinearProgress,
  Card, CardContent, CardActions, Typography, Divider, Switch, Box, Container,
  Tooltip,
  CardMedia,
  CardActionArea,
  CircularProgress,
} from '@mui/material';
import { useLang } from '~/hooks/useLang';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ComputerIcon from '@mui/icons-material/Computer';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import IUser from '~/features/User/interfaces/IUser';
import { formatDateHour } from '~/utils/format';
import { truncateText } from '~/utils/strings';
import DispatchTimelineStatus from '~/features/Dispatch/components/DispatchTimeline/components/DispatchTimelineStatus';
import CloseIcon from '@mui/icons-material/Close';
import SortIcon from '@mui/icons-material/Sort';
import { useHookstate } from '@hookstate/core';
import TimelineConnector from '@mui/lab/TimelineConnector';
import ForumIcon from '@mui/icons-material/Forum';
import dayjs from 'dayjs';
import ModalImage from '~/components/DisplayComments/components/ModalImage';
import ModalImageTimeLine from '../ModalImageTimeLine';
import { Download } from '@mui/icons-material';
import ImageInterestPoint from '~/features/InterestPoint/Components/ImageInterestPoint';
import Zoom from 'react-medium-image-zoom'
import { downloadFile } from '~/utils/media';

type DispatchTimelineItemsProps = {
  readonly id: number;
  readonly is_edited: boolean;
  readonly is_deleted: boolean;
  readonly showEdits: boolean;
  readonly isTruncate: boolean;
  readonly date_updated_event: string;
  readonly dispatch_origin: string | null;
  readonly changed_by_user: {
    readonly name: string
    readonly representation_name: string;
  }
  readonly previous_content: readonly {
    readonly previous_comment: string;
    readonly created_at: string;
    readonly user: {
      readonly name: string;
      readonly representation_name: string;
    }
    readonly station: {
      readonly name: string
    }
  }[] | null;
  readonly media_content: {
    readonly file_type: string;
    readonly media_path: string;
    readonly file_name: string;
  }
  readonly event_name: string;
  readonly event_at: number | string;
  readonly date_event: string | Date;
  readonly content: string;
  readonly user: IUser;
  readonly origin: {
    readonly name: string;
  }
}

// eslint-disable-next-line max-lines-per-function
export default function DispatchTimelineDialog({ open, chronology }) {
  const { translate } = useLang()
  const dispatchTimelineItems = useHookstate<readonly DispatchTimelineItemsProps[]>([])
  const loading = useHookstate(false);
  const filterComments = useHookstate(true);
  const filterEvents = useHookstate(true);
  const filterPreview = useHookstate(true);
  const sortChronology = useHookstate(false)
  const typeDocument = useHookstate<string | null>(null)

  useEffect(() => {
    loading.set(true)
    authFetch({
      url: `/occurrence/${chronology}/timeline`,
      method: 'POST',

    }).then(({ data }: { readonly data: readonly DispatchTimelineItemsProps[] }) => {
      dispatchTimelineItems.set(data.map(dataChronology => ({
        ...dataChronology,
        showEdits: false,
        isTruncate: false,
      })));
      loading.set(false);
    })


  }, [filterComments.value, filterEvents.value, filterPreview.value])

  const handleClose = () => {
    open.set(false);
  };

  const changeSortChronology = () => {
    sortChronology.set(!sortChronology.get())
  }

  return (
    < Dialog
      open={open.get()}
      maxWidth='lg'
      fullWidth
      scroll='paper'
    >
      <DialogTitle>
        <Typography
          variant='h5'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          {translate('Chronology')}
          <IconButton
            title={translate('Close')}
            onClick={() => open.set(false)}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '16px'
          }}
        >
          <Box
            sx={{
              width: '40%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FormControlLabel
              sx={{ marginLeft: 0 }}
              control={<Switch
                checked={filterComments.get()}
                onChange={() => filterComments.set(!filterComments.value)}
              />}
              label={translate('Comments')}
              labelPlacement='start'
            />

            <FormControlLabel
              sx={{ marginLeft: 0 }}
              control={<Switch
                checked={filterEvents.get()}
                onChange={() => filterEvents.set(!filterEvents.value)}
              />}
              label={translate('Events')}
              labelPlacement='start'
            />
            <FormControlLabel
              sx={{ marginLeft: 0 }}
              control={<Switch
                checked={filterPreview.get()}
                onChange={() => filterPreview.set(!filterPreview.value)}
              />}
              label={translate('Views')}
              labelPlacement='start'
            />
          </Box>
          <Box
            sx={{ paddingRight: '8px' }}
          >
            <Button
              variant="contained"
              onClick={() => changeSortChronology()}
              endIcon={<SortIcon />}
            >
              {translate('Sort name', { code: sortChronology.get() ? translate('Growing') : translate('Decreasing') })}
            </Button>
          </Box>
        </Box>


      </DialogTitle>
      {
        <DialogContent>
          {loading.get() && <LinearProgress color='primary' />}
          {!loading.get() && (
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.2,
                },
              }}
            >

              {dispatchTimelineItems.value.map((item, index, array) => {
                if ((item.event_name == 'OCCURRENCE_COMMENT' && filterComments.get())
                  || (item.event_name == 'PREVIEW_EVENT' && filterPreview.get())
                  || ((item.event_name != 'OCCURRENCE_COMMENT' && item.event_name != 'PREVIEW_EVENT') && filterEvents.get())) {
                  return (
                    <TimelineItem key={index}>
                      <TimelineOppositeContent color='text.secondary'>
                        <Typography variant={'body2'}>
                          {formatDateHour(item.event_at).toFormatedDate}
                        </Typography>

                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <DispatchTimelineStatus event={item.event_name} />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Card variant='elevation' elevation={2} sx={{
                          mb: 2,
                          width: 900,
                        }}>
                          <CardContent>
                            <Typography
                              variant={'body2'}
                              style={{
                                wordWrap: "break-word",
                                marginBottom: "0",
                              }}>

                              {dispatchTimelineItems[index].isTruncate.get() ?
                                item.content :
                                !!item.is_deleted ? `${translate('Comentário excluido')}`
                                  : !item.media_content ? ` ${truncateText(item.content, 200)}` :
                                    item.media_content.file_type === "jpeg" ||
                                      item.media_content.file_type === "jpg" ||
                                      item.media_content.file_type === "png" ?
                                      <Zoom zoomMargin={50}>
                                        <CardMedia
                                          component="img"
                                          image={mountUrlRequest(`/occurrence-comment/get-media/${item.media_content.media_path}`)}
                                          alt={item.content}
                                          sx={{ width: '100px' }}
                                        />
                                      </Zoom>
                                      :
                                      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Typography>
                                          {truncateText(`${item.media_content.file_name}`, 30)}
                                        </Typography>
                                        <IconButton
                                          title={translate('Download file')}
                                          onClick={() =>
                                            downloadFile({
                                              mediaPath: item.media_content.media_path,
                                              fileType: item.media_content.file_type,
                                              typeDocument: typeDocument,
                                              isMount: false,
                                              fileName: item.media_content.file_name
                                            })
                                          }>
                                          <Download />
                                        </IconButton>
                                      </Box>
                              }
                              {item.content.length > 200 && <Typography
                                onClick={() => {
                                  dispatchTimelineItems[index].isTruncate.set(!dispatchTimelineItems[index].isTruncate.get())
                                }}
                                sx={{ cursor: "pointer" }}
                                color='orange'
                                variant="caption">
                                {' '}
                                {!dispatchTimelineItems[index].isTruncate.get() ? translate('more') : ''}
                              </Typography>}
                            </Typography>
                          </CardContent>
                          <Divider />
                          <CardActions>
                            {item.user.name &&
                              <>
                                <Tooltip title={translate('User')} placement='bottom'>
                                  <Typography variant={'body2'}>
                                    <AccountCircleIcon />
                                  </Typography>
                                </Tooltip>
                                <Typography title={item.user.representation_name ? item.user.representation_name : ''} variant='body2'>
                                  {`${truncateText(item.user.representation_name ? item.user.representation_name : '', 17, '')}`}
                                </Typography>
                              </>
                            }

                            {item.origin.name &&
                              <>
                                <Tooltip title={translate('Station')} placement='bottom'>
                                  <Typography variant={'body2'}>
                                    <ComputerIcon />
                                  </Typography>
                                </Tooltip>

                                <Typography variant={'body2'}>
                                  {item.origin.name}
                                </Typography>
                              </>
                            }

                            {item.dispatch_origin &&
                              <>
                                <Tooltip title={translate('Dispatch origin')} placement='bottom'>
                                  <Typography>
                                    <ForumIcon />
                                  </Typography>
                                </Tooltip>
                                <Typography variant={'body2'}>
                                  {item.dispatch_origin}
                                </Typography>
                              </>
                            }
                            {!!item.is_edited && !!item.is_deleted === false &&
                              <Typography variant='body2'>
                                {`• ${translate('edited')} ${translate('by')} ${item.changed_by_user.representation_name} • ${dayjs(item.date_updated_event).format('DD-MM-YYYY HH:mm')}`}
                              </Typography>
                            }
                            {!!item.is_deleted &&
                              <Typography variant='body2'>
                                {`• ${translate('deleted')} ${translate('by')} ${item.changed_by_user.representation_name} • ${dayjs(item.date_updated_event).format('DD-MM-YYYY HH:mm')}`}
                              </Typography>
                            }
                          </CardActions>
                        </Card>

                        {!!item.showEdits && !!item.is_deleted &&
                          <Card variant='elevation'
                            elevation={2}
                            sx={{ ml: 5, mb: 3, width: 860 }}>
                            <CardContent>
                              <Typography
                                variant={'body2'}
                                style={{
                                  wordWrap: "break-word",
                                  marginBottom: "0"
                                }}>
                                {item.content}
                              </Typography>
                            </CardContent>
                            <Divider />
                            <CardActions>
                              {item.origin.name &&
                                <>
                                  <Typography variant={'body2'}>
                                    <AccountCircleIcon />
                                  </Typography>
                                  <Typography title={item.user.representation_name ? item.user.representation_name : ''} variant='body2'>
                                    {`${truncateText(item.user.representation_name ? item.user.representation_name : '', 17, '')}`}
                                  </Typography>
                                </>
                              }

                              {item.origin.name &&
                                <>
                                  <Typography variant={'body2'}>
                                    <ComputerIcon />
                                  </Typography>
                                  <Typography variant={'body2'}>
                                    {item.origin.name}
                                  </Typography>
                                </>
                              }

                              {item.dispatch_origin &&
                                <>
                                  <Typography>
                                    <ForumIcon />
                                  </Typography>
                                  <Typography variant={'body2'}>
                                    {item.dispatch_origin}
                                  </Typography>
                                </>
                              }
                            </CardActions>
                          </Card>
                        }

                        {!!item.showEdits && item.previous_content &&
                          item.previous_content.map((content, i) => (
                            <Card key={i} variant='elevation'
                              elevation={2}
                              sx={{ ml: 5, mb: 3, width: 860 }}>
                              <CardContent>
                                <Typography
                                  variant={'body2'}
                                  style={{
                                    wordWrap: "break-word",
                                    marginBottom: "0"
                                  }}>
                                  {content.previous_comment}
                                </Typography>
                              </CardContent>
                              <Divider />
                              <CardActions>
                                {item.previous_content && item.previous_content.length - 1 === i &&
                                  <>
                                    <Typography variant={'body2'}>
                                      <AccountCircleIcon />
                                    </Typography>
                                    <Typography variant={'body2'}>
                                      {item.previous_content && item.previous_content.length - 1 === i ? item.user.representation_name : content.user.representation_name}
                                    </Typography>
                                  </>
                                }

                                {item.previous_content && item.previous_content.length - 1 === i &&
                                  <>
                                    <Typography variant={'body2'}>
                                      <ComputerIcon />
                                    </Typography>
                                    <Typography variant={'body2'}>
                                      {item.previous_content && item.previous_content.length - 1 === i ? item.origin.name : content.station.name}
                                    </Typography>
                                  </>
                                }

                                {item.dispatch_origin &&
                                  <>
                                    <Typography>
                                      <ForumIcon />
                                    </Typography>
                                    <Typography variant={'body2'}>
                                      {item.dispatch_origin}
                                    </Typography>
                                  </>
                                }
                              </CardActions>
                            </Card>
                          ))
                        }

                        {!!item.is_edited || !!item.is_deleted ?
                          <Divider textAlign="left">
                            <Typography variant='caption'
                              sx={{ cursor: "pointer", pt: 3 }}
                              onClick={() => {
                                dispatchTimelineItems[index].showEdits.set(!dispatchTimelineItems[index].showEdits.get())
                              }}>
                              ━━
                              {' '}
                              {dispatchTimelineItems[index].showEdits.get() ? translate('Hide historic') : translate('Show historic')}
                            </Typography>
                          </Divider>
                          : <Divider />
                        }

                      </TimelineContent>
                    </TimelineItem>
                  )
                }
              }).sort((element1, element2) => element1 && element2 && element1 > element2 ? (sortChronology.get() === false ? -1 : 1) : (sortChronology.get() === false ? 1 : -1))}
            </Timeline>
          )}

        </DialogContent>

      }
    </Dialog >
  )
}