/* eslint-disable max-lines-per-function */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
  Button,
  Card,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
} from '@mui/material'
import ErrorFallback from '~/components/ErrorFallback'
import Layout from '~/components/Layout'
import { useLang } from '~/hooks/useLang'
import notify from '~/utils/notify'
import useReportState from '../../../stores/ReportState'
import { useHookstate } from '@hookstate/core'
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import GenerateReportButton from '../../../components/GenerateReportButton'
import FilterAutocompleteContactCenter from '~/components/FilterAutocompleteContactCenter'
import ExportButtonCSV from '~/features/Report/components/ButtonExportCSV'
import { downloadReportCSV } from '~/features/Report/services/report'
import PhoneTextField from '~/components/PhoneField'
import { makeRequestToGetReportTotalPrankCalls } from './services'
import FilterSwitch from '~/components/FilterSwitch'
import FilterAutocompleteEntryOrigin from '~/components/FilterAutocompleteEntryOrigin'

export default function TotalPrankCalls() {
  const expandFilterSearch = useHookstate<boolean>(true)
  const { translate } = useLang()
  const isLoading = useHookstate<boolean>(false)
  const lockFields = useHookstate<boolean>(false)
  const period = useHookstate('')
  const startDate = useHookstate<string | null>(null)
  const finishDate = useHookstate<string | null>(null)
  const contactCenterId = useHookstate<number | null>(null)
  const phone = useHookstate<string | null>(null)
  const onlyEntry = useHookstate<boolean>(false)
  const onlyOccurrence = useHookstate<boolean>(false)
  const syntheticReport = useHookstate<boolean>(false)
  const both = useHookstate<boolean>(true)
  const reportName = 'total-prank-call'
  const entryOriginId = useHookstate<number | null>(null)

  const { reportData, loadingReport } = useReportState()

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])

  function generateReport() {
    if (startDate && finishDate) {
      isLoading.set(true)
      loadingReport().set(true)
      makeRequestToGetReportTotalPrankCalls({
        startDate: startDate.get(),
        finishDate: finishDate.get(),
        contactCenterId: contactCenterId.get(),
        phone: phone.get(),
        onlyEntry: onlyEntry.get(),
        onlyOccurrence: onlyOccurrence.get(),
        syntheticReport: syntheticReport.get(),
        entryOriginId: entryOriginId.get(),
      })
        .then((response) => {
          setTimeout(() => {
            reportData().set(response)
          })
          window.open(`/report/result/${reportName}`)
        })
        .finally(() => {
          loadingReport().set(false)
          isLoading.set(false)
        })
    } else {
      notify({
        message: translate('Initial and final date are mandatory'),
        type: 'error',
      })
    }
  }

  const exportCSV = async () => {
    if (startDate && finishDate) {
      isLoading.set(true)
      const dataCSV = await makeRequestToGetReportTotalPrankCalls({
        startDate: startDate.get(),
        finishDate: finishDate.get(),
        contactCenterId: contactCenterId.get(),
        phone: phone.get(),
        onlyEntry: onlyEntry.get(),
        onlyOccurrence: onlyOccurrence.get(),
        syntheticReport: syntheticReport.get(),
        exportCSV: true,
        entryOriginId: entryOriginId.get(),
      }).finally(() => isLoading.set(false))
      downloadReportCSV(
        `${translate(reportName)}-${new Date().getTime()}.csv`,
        dataCSV
      )
    } else {
      notify({
        message: translate('Initial and final date are mandatory'),
        type: 'error',
      })
    }
  }

  useEffect(() => {
    if (both.get()) {
      onlyEntry.set(false)
      onlyOccurrence.set(false)
    } else if (onlyEntry.get()) {
      onlyOccurrence.set(false)
      both.set(false)
    } else {
      onlyEntry.set(false)
      both.set(false)
    }
  }, [onlyEntry.get(), onlyOccurrence.get(), both.get()])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true}>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader
                title={`${translate('Report')}: ${translate(
                  `Total prank calls registered`
                )}`}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader
            title={`${translate('Filters')}`}
            avatar={
              <IconButton
                onClick={() =>
                  expandFilterSearch.set(!expandFilterSearch.get())
                }
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginRight: 1,
                  padding: 0,
                }}
              >
                {expandFilterSearch.value ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            }
          />
          <Collapse in={expandFilterSearch.get()}>
            <Grid
              container
              xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
              }}
            >
              <Grid container direction={'row'} rowGap={2}>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteContactCenter
                    contactCenterId={contactCenterId.get()}
                    onContactCenterChange={(contactCenter) => {
                      contactCenterId?.set(contactCenter)
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteEntryOrigin
                    disabled={lockFields.get()}
                    onEntryOriginChange={(entryOrigin) => {
                      entryOriginId?.set(entryOrigin)
                    }}
                    originId={entryOriginId?.get()}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <PhoneTextField
                    statePhone={phone}
                    disabled={false}
                    disabledLastCalls={true}
                  />
                </Grid>
                  <FilterSelectPeriod
                    disabled={lockFields.get()}
                    onStartDateChange={(value) =>
                      startDate.set(value?.toJSON() ?? null)
                    }
                    onFinishDateChange={(value) =>
                      finishDate.set(value?.toJSON() ?? null)
                    }
                    onPeriodChange={(value) => {
                      period.set(value)
                    }}
                    period={period.get()}
                    required
                    />
                <Grid container direction={'row'}>
                  <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                    <FilterSwitch
                      label={translate('Only entry')}
                      onCheckedChange={() => {
                        onlyEntry.set(!onlyEntry.value)
                      }}
                      checked={onlyEntry.get()}
                    />
                  </Grid>
                  <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                    <FilterSwitch
                      label={translate('Only occurrence')}
                      onCheckedChange={() => {
                        onlyOccurrence.set(!onlyOccurrence.value)
                      }}
                      checked={onlyOccurrence.get()}
                    />
                  </Grid>
                  <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                    <FilterSwitch
                      label={translate('Both')}
                      onCheckedChange={() => {
                        both.set(!both.value)
                      }}
                      checked={both.get()}
                    />
                  </Grid>
                  <Grid container direction={'row'}>
                    <Grid item xs={3} sx={{ pl: 1, pt: 1 }}>
                      <FilterSwitch
                        label={translate('Synthetic report')}
                        onCheckedChange={() => {
                          syntheticReport.set(!syntheticReport.value)
                        }}
                        disabled={lockFields.get()}
                        checked={syntheticReport.get()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 2,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    contactCenterId.set(null)
                    entryOriginId.set(null)
                    phone.set(null)
                    period.set('')
                    onlyEntry.set(false)
                    onlyOccurrence.set(false)
                    both.set(true)
                    syntheticReport.set(false)
                  }}
                  sx={{ height: '40px' }}
                >
                  {translate('Clear Filters')}
                </Button>
                <ExportButtonCSV
                  isLoading={isLoading.get()}
                  dataToExport={exportCSV}
                />
                <GenerateReportButton
                  isLoading={isLoading.get()}
                  makeReport={generateReport}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary>
  )
}
