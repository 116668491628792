import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import type { IOccupation } from '../interfaces/IOccupation';



export default function Edit() {
    const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm<IOccupation>();
    const fields = [
        { name: 'id', type: 'hidden' },
        { name: 'name', type: 'string' },
    ];

    return (
        <CrudEdit
            model={'occupation'}
            fields={fields}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            setFormValue={setFormValue}
            messageSuccess={'Occupation successfully updated!'}
            title={'Edit occupation'}
            getValues={getValues}
        />
    )
}
