import React from 'react';
import { IconButton, Grid } from '@mui/material';
import { useLang } from '../../../../hooks/useLang';
import useEntryState from '../../stores/EntryState';
import { Downgraded, useHookstate, useState } from '@hookstate/core';
import useEntryCommentState, { CommentDataProps } from '../../stores/EntryCommentState';
import { AxiosResponse } from 'axios';
import TaskIcon from '@mui/icons-material/Task';
import EntryCreateCloseOccurrenceDialog from './Components/EntryCreateClosedOccurrenceDialog';
import CodesGeneratedDialog from './Components/CodesGeneratedDialog';
import { Validation as validation } from '@hookstate/validation';
import useEntryDispatchState from '../../stores/EntryDispatchState';
import notify from '~/utils/notify';

interface EntryCreateClosedOccurrenceButtonProps {
  readonly canEntryCloseOccurrence?: boolean;
  readonly entryOpenCloseOccurrence?: (params: any) => Promise<AxiosResponse>;
  readonly hasLatLng: boolean;
}

const defaultCommentsState: readonly CommentDataProps[] = []


export default function EntryCreateClosedOccurrenceButton(props: EntryCreateClosedOccurrenceButtonProps) {
  const { translate } = useLang();
  const { validateActionsEntry } = useEntryState();
  const { allCurrentComments, sortComment } = useEntryCommentState();
  const entryDispatchState = useEntryDispatchState().entryDispatch();
  const allCommentsValue = allCurrentComments().attach(Downgraded).value;
  const commentList = useState(defaultCommentsState);
  const openEntryCloseOccurrenceDialog = useHookstate(false);
  const dispatchesCodesGenerated = useHookstate<string[]>([])
  const openDialogCodes = useHookstate(false);


  React.useEffect(() => {
    commentList.set(allCommentsValue ? allCommentsValue.map(element => element).sort(sortComment) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCommentsValue]);

  const handleClickOpen = () => {
    if (entryDispatchState.value.length === 0) {
      notify({
        message: translate('No dispatch groups for this entry'),
        type: 'error'
      })
    }

    if (validateActionsEntry() && entryDispatchState.value.length > 0) {
      openEntryCloseOccurrenceDialog.set(true)
    }
  };


  return (
    <Grid item>
      <IconButton title={translate('Generate closed occurrence')} disabled={props.canEntryCloseOccurrence} onClick={handleClickOpen}>
        <TaskIcon />
      </IconButton>
      {openEntryCloseOccurrenceDialog.get() &&
        <EntryCreateCloseOccurrenceDialog
          open={openEntryCloseOccurrenceDialog}
          entryOpenCloseOccurrence={props.entryOpenCloseOccurrence}
          dispatchesCodesGenerated={dispatchesCodesGenerated}
          openDialogCodes={openDialogCodes}
        />
      }
      <CodesGeneratedDialog
        open={openDialogCodes}
        dispatchesCodesGenerated={dispatchesCodesGenerated}
      />
    </Grid>);
}