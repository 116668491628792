import React, { useEffect } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { State, useHookstate } from '@hookstate/core';
import { getAssociatedDrugsFromOccurrenceService } from '~/features/Occurrence/services/index';

export interface drugHistoryProps {
  readonly id: number,
  readonly quantity: number,
  readonly occurrence_id: number,
  readonly notes: string,
  readonly drug_type: {
    readonly id: number,
    readonly name: string
  },
  readonly unit_measurement: {
    readonly id: number,
    readonly name: string
    readonly symbol: string
  },
  readonly circunstance: {
    readonly id: number,
    readonly name: string
  },
  readonly destination: {
    readonly id: number,
    readonly name: string
  },
}

interface DrugsRegisteredInTheOccurrencePrintProps {
  readonly DrugsRegisteredInTheOccurrencePrintResponse: drugHistoryProps[]
  
}

export default function DrugsRegisteredInTheOccurrencePrint({ DrugsRegisteredInTheOccurrencePrintResponse }: DrugsRegisteredInTheOccurrencePrintProps){
  const { translate } = useLang();


  return (
    <Container fixed={false}>
      <Grid container xs={12} sx={{ marginBottom: 2 }}>
        { DrugsRegisteredInTheOccurrencePrintResponse.length != 0 ? (
          DrugsRegisteredInTheOccurrencePrintResponse.map((drug) => (
            <Grid container key={drug?.id} xs={12} sx={{ margin: 1, border: 1, borderStyle: 'dashed' }}>
              <Grid item xs={3} sx={{border: 1, borderStyle: 'dashed', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant='subtitle1' sx={{ alignItems: 'center' }}><b>{drug?.drug_type?.name ?? `* ${translate('Uninformed')} *`}</b></Typography>
              </Grid>
              <Grid container xs={9} padding={2}>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'><b>{translate('Type')}:</b> {drug?.drug_type?.name ?? `* ${translate('Uninformed')} *`} </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'><b>{translate('Quantity')}:</b> {drug?.quantity} {drug?.unit_measurement?.symbol}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'><b>{translate('Circunstance')}:</b> {drug?.circunstance?.name ?? `* ${translate('Uninformed')} *`} </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='subtitle2'><b>{translate('Destination')}:</b> {drug?.destination?.name ?? `* ${translate('Uninformed')} *`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle2'><b>{translate('Notes')}:</b> {drug?.notes.length ? drug.notes : `* ${translate('Uninformed')} *`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))
        ) : (
        <Grid item xs={12} textAlign={'center'}>
          <Typography variant='subtitle1'><b>{translate('NO INFORMATION REGISTERED')}</b></Typography>
        </Grid>
        )}
      </Grid>
    </Container>
  )
}