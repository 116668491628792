import { authFetch } from "~/services/fetch";
import IProcedure from "../interfaces/IProcedure";
import { IUpdateProps } from "~/interfaces";

export async function getProcedureById(id: IUpdateProps): Promise<IProcedure> {
    const response = await authFetch({
        url: `/procedure/${id}`,
        method: 'GET'
    });
    return response.data as IProcedure;
}