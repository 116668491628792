import React, { useEffect } from "react";
import { State, StateMethods, useHookstate, useState } from "@hookstate/core";
import { SearchRounded } from "@mui/icons-material";
import { Box, Typography, InputBase, CircularProgress } from "@mui/material";
import { useTheme } from "~/hooks/useTheme";
import { FetchAutoComplete, SetFuncOpts } from '../FetchAutoComplete'
import useDispatchState from "~/features/Dispatch/stores/DispatchState";
import { useLang } from "~/hooks/useLang";
import IDispatch from "~/features/Dispatch/interfaces/IDispatch";
import { parseDataDispatch } from "~/features/Dispatch/utils/parsers";

type subtitle = {
    readonly type?: 'default' | 'error' | 'success',
    readonly color?: string,
    readonly message?: string
}

type DispatchSearchBoxProps = {
    readonly isOpen: StateMethods<boolean>;
    readonly isValid: boolean;
    readonly notOnlyVisibles?: boolean;
    readonly size?: "small" | "medium" | undefined;
    readonly dispatchValue?: StateMethods<string | null>;
    readonly currentDispatch?: (value: IDispatch | null) => void;
    readonly isReport?: boolean;
    readonly dispatchId?: State<number | null>;
    readonly placeholder?: string;
}

export function DispatchSearchBox({ currentDispatch, dispatchValue, isOpen, isValid, notOnlyVisibles = false, size = 'medium', isReport, dispatchId, placeholder = 'Fetch Dispatches' }: DispatchSearchBoxProps) {
    const { translate } = useLang();
    const { getDispatchByCode, syncDispatchFromPayload } = useDispatchState();
    const { darkMode } = useTheme();


    const isTextField = useState(true);

    const setFunc = (dispatchOpt: IDispatch, opts: SetFuncOpts | undefined = undefined) => new Promise<{ readonly ended: boolean, readonly value?: string}>((resolve, reject) => {
        // create anothers handlers for errors in sync
        !isReport ?
            syncDispatchFromPayload({
                dispatchParams: { dispatch: parseDataDispatch(dispatchOpt) },
                canCreateDispatch: true,
                canSaveDispatch: true
            })
                .catch(err => { reject(err); })
                .finally(() => resolve({ ended: true }))
            : dispatchId?.set(dispatchOpt.id)
    })

    return (
        <Box
            sx={{
                position: 'relative',
                width: isTextField.get() ? '100%' : '15%',
                borderRadius: isTextField.get() ? '5px' : '50%',
                backgroundColor: 'transparent',
            }}
        >
            <FetchAutoComplete<IDispatch>
                stateValue={dispatchValue}
                idValue={dispatchId?.get()}
                fetchFunc={(value) => getDispatchByCode(value, notOnlyVisibles)}
                setValueInput={(value: IDispatch) => {
                    if (currentDispatch) {
                        if (value) {
                            currentDispatch(JSON.parse(JSON.stringify(value)))
                        } else {
                            currentDispatch(null);
                        }
                    }

                    if(dispatchId?.get() && !value){
                        dispatchId.set(null)
                    }
                    
                    return value ? value.code : ''
                }}
                setFunc={setFunc}
                dialogOpenState={isOpen}
                isValid={isValid}
                optionKey={'code'}
                noOptionsString='No records'
                autoCompleteProps={{
                    getOptionLabel: (dispatch) => {
                        return `${dispatch.code}`
                    },
                    size,
                    placeholder: translate(placeholder),
                }}
                sx={{color: darkMode ? '#fffe' : '#000'}}
                clearField={isReport}
            />
        </Box>
    )
}