import React from 'react';
import { Grid, Typography } from '@mui/material';
import IReportColumn from '../../interfaces/IReportColumn';
import IReportGroup from '../../interfaces/IReportGroup';
import GroupedReport from '../GroupedReport';
import GroupedReportTwoLevels from '../GroupedReportTwoLevels';

export default function GroupedReportThreeLevels({ columns, twoLevelsGroups }:
  {
    readonly twoLevelsGroups: readonly IReportGroup[],
    readonly columns: readonly IReportColumn[]
  }) {
  return (
    <Grid
      container
      marginTop={1}
    >
      {twoLevelsGroups.map((group) => {
        return (
          <Grid
            item
            key={Math.random() + group.count}
            xs={24}
            marginTop={2}
          >
            <Typography
              variant="body2"
              fontWeight={'bold'}
            >
              {group.label}
              {group.count ? ` (Total : ${group.total}) ` : ''}
            </Typography>
            <Grid
              item
              sx={{marginLeft: '24px'}}
            >
              <GroupedReportTwoLevels twoLevelsGroups={group.groups} columns={columns} />
            </Grid>
          </Grid>
        )
      })}

    </Grid>
  )
}