import React from 'react';
import { TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';

export default function NameField({ id, nameState }) {
  const { translate } = useLang();
  return (
    <TextField fullWidth
      value={nameState.get()}
      id={id}
      label={translate('Name')}
      name="name"
      size='small'
      disabled={true}
      required
    />
  )
}