import React, { useEffect } from 'react';
import { State, useHookstate } from '@hookstate/core';
import { CircularProgress, Grid } from '@mui/material';
import ActiveAgenciesPrint, { agencyHistoryProps } from '~/features/Dispatch/components/Print/ActiveAgenciesPrint';
import DrugsRegisteredInTheOccurrencePrint, { drugHistoryProps } from '~/features/Dispatch/components/Print/DrugsRegisteredInTheOccurrencePrint';
import GunsRegisteredInTheOccurrencePrint, { gunHistoryProps } from '~/features/Dispatch/components/Print/GunsRegisteredInTheOccurrencePrint';
import InvolvedInTheOccurrencePrint, { peopleHistoryProps } from '~/features/Dispatch/components/Print/InvolvedInTheOccurrencePrint';
import ObjectsRegisteredInTheOccurrencePrint, { objectsHistoryProps } from '~/features/Dispatch/components/Print/ObjectsRegisteredInTheOccurrencePrint';
import { useLang } from '~/hooks/useLang';
import { getAssociatedDrugsFromOccurrenceServiceForClerk, getAssociatedObjectsFromOccurrenceServiceForClerk, getAssociatedOrganizationFromOccurrenceServiceForClerk, getAssociatedPeopleFromOccurrenceServiceForClerk, getAssociatedVehicleFromOccurrenceServiceForClerk, getAssociatedWeaponFromOccurrenceServiceForClerk } from '../../services';
import TabPrint from '~/features/Dispatch/components/Print/TabPrint';
import VehiclesRegisteredInTheOccurrencePrint, { vehicleHistoryProps } from '~/features/Dispatch/components/Print/VehiclesRegisteredInTheOccurrencePrint';
import RealoadButtonPrint from '~/features/Dispatch/components/Print/RealodButtonPrint';
import ButtonReloadInfoForClerk from '../ButtonReloadInfoForClerk';


interface QualificatorsForClerkProps {
  readonly occurrenceId: string | number
  readonly reloadInfo: State<boolean>;
  readonly loading: State<boolean>;
}

export default function QualificatorsForClerk({ occurrenceId, reloadInfo, loading }: QualificatorsForClerkProps) {
  const { translate } = useLang();
  const showPrint = useHookstate(true);
  const reloadInfoQualificatiors = useHookstate(false);
  const InvolvedInTheOccurrencePrintResponse = useHookstate<peopleHistoryProps[]>([]);
  const VehiclesRegisteredInTheOccurrencePrintResponse = useHookstate<Array<vehicleHistoryProps>>([]);
  const GunsRegisteredInTheOccurrencePrintResponse = useHookstate<Array<gunHistoryProps>>([]);
  const DrugsRegisteredInTheOccurrencePrintResponse = useHookstate<Array<drugHistoryProps>>([]);
  const ObjectsRegisteredInTheOccurrencePrintResponse = useHookstate<Array<objectsHistoryProps>>([]);
  const ActiveAgenciesPrintResponse = useHookstate<Array<agencyHistoryProps>>([]);


  useEffect(() => {
    loading.set(true)

    occurrenceId ? Promise.all([
      getAssociatedPeopleFromOccurrenceServiceForClerk({ occurrenceId: occurrenceId }).then((response) => {
        InvolvedInTheOccurrencePrintResponse.set(response)
      }),
      getAssociatedVehicleFromOccurrenceServiceForClerk({ occurrenceId: occurrenceId }).then((response) => {
        VehiclesRegisteredInTheOccurrencePrintResponse.set(response);
      }),
      getAssociatedWeaponFromOccurrenceServiceForClerk({ occurrenceId: occurrenceId }).then((response) => {
        GunsRegisteredInTheOccurrencePrintResponse.set(response);
      }),
      getAssociatedDrugsFromOccurrenceServiceForClerk({ occurrenceId: occurrenceId }).then((response) => {
        DrugsRegisteredInTheOccurrencePrintResponse.set(response);
      }),
      getAssociatedObjectsFromOccurrenceServiceForClerk({ occurrenceId: occurrenceId }).then((response) => {
        ObjectsRegisteredInTheOccurrencePrintResponse.set(response);
      }),
      getAssociatedOrganizationFromOccurrenceServiceForClerk({ occurrenceId: occurrenceId }).then((response) => {
        ActiveAgenciesPrintResponse.set(response);
      })
    ]).then(() => {
    }).catch((err) => {
      showPrint.set(false)
    }).finally(() => {
      loading.set(false)
    }) : false
  }, [occurrenceId, reloadInfoQualificatiors.get(), reloadInfo.get()])

  return !loading.get() && showPrint.get() ? (
    <Grid container xs={12}>
      <Grid container xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'start'} gap={2} overflow={'auto'} height={'75vh'}>
        <TabPrint
          title={'Envolvidos na Ocorrência'}>
          <InvolvedInTheOccurrencePrint
            InvolvedInTheOccurrencePrintResponse={InvolvedInTheOccurrencePrintResponse.get()}
          />
        </TabPrint>
        <TabPrint
          title={'Veículos Registrados na Ocorrência'}>
          <VehiclesRegisteredInTheOccurrencePrint
            VehiclesRegisteredInTheOccurrencePrintResponse={VehiclesRegisteredInTheOccurrencePrintResponse.get()}
          />
        </TabPrint>

        <TabPrint
          title={'Armas Registradas na Ocorrência'}>
          <GunsRegisteredInTheOccurrencePrint
            GunsRegisteredInTheOccurrencePrintResponse={GunsRegisteredInTheOccurrencePrintResponse.get()}
          />
        </TabPrint>

        <TabPrint
          title={'Drogas Registradas na Ocorrência'}>
          <DrugsRegisteredInTheOccurrencePrint
            DrugsRegisteredInTheOccurrencePrintResponse={DrugsRegisteredInTheOccurrencePrintResponse.get()}
          />
        </TabPrint>

        <TabPrint
          title={'Objetos Registradas na Ocorrência'}>
          <ObjectsRegisteredInTheOccurrencePrint
            ObjectsRegisteredInTheOccurrencePrintResponse={ObjectsRegisteredInTheOccurrencePrintResponse.get()}
          />
        </TabPrint>
        <TabPrint
          title={translate('Active Agencies')}>
          <ActiveAgenciesPrint
            ActiveAgenciesPrintResponse={ActiveAgenciesPrintResponse.get()}
          />
        </TabPrint>

      </Grid>
    </Grid>
  ) : !showPrint.get() ?
    <RealoadButtonPrint
      message='Recarregar informações'
        reloadInfo={() => reloadInfoQualificatiors.set(!reloadInfoQualificatiors.get())}
    />
      : <Grid container sx={{ width: '100%', height: 700, display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
      <Grid item>
        <CircularProgress disableShrink size={50} />
      </Grid>
    </Grid >
}