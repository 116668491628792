import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core'
import { CardHeader, Grid, IconButton, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import WidgetCreate from '~/components/WidgetCreate'
import { useLang } from '~/hooks/useLang'
import { useDispatchDashboardState } from '../../stores/DispatchDashboardState'
import { getDispatchByStatus, getDeviceByStatus } from './services/index'
import { IDespacthStatisticGraphProps } from '../../interfaces/IDispacthStatistic'

type PayloadProps = {
  readonly withSolvedThirdParty?: boolean
  readonly exludeClosed?: boolean
}

type ResponseProps = ReadonlyArray<{
  readonly id: number
  readonly name: string
  readonly total: number
}>

export default function DispatchByTotalByStatusCards({
  loading,
  agencyId,
  deviceId,
  operationId,
  dispatchGroupId,
  category,
  type,
  subtype,
  dateStart,
  dateFinish,
  thirdParty,
  refresh,
  consummated,
  statusIds,
  cityIds,
  groupSetId,
  location,
  operationUnitId,
  closedStatusClassId,
  closedStatusId,
  qualifierOptions,
  expandFilterSearch,
}: IDespacthStatisticGraphProps) {
  const { translate } = useLang()
  const dispatchData = useHookstate<ResponseProps>([])
  const payload = useHookstate<PayloadProps>({
    withSolvedThirdParty: thirdParty,
    exludeClosed: false,
  })
  useEffect(() => {
    payload.set({ withSolvedThirdParty: thirdParty, exludeClosed: false })
    loading.set(true)
    getDispatchByStatus(
      agencyId,
      deviceId,
      operationId,
      dispatchGroupId,
      category,
      type,
      subtype,
      dateStart,
      dateFinish,
      payload.get(),
      consummated,
      statusIds,
      cityIds,
      groupSetId,
      location,
      closedStatusClassId,
      closedStatusId,
      qualifierOptions,
      operationUnitId
    )
      .then((response) => {
        const result = response.map((responseData) => ({
          id: responseData.id,
          name: responseData.name,
          total: responseData.total,
        }))
        return dispatchData.set(result)
      })
      .finally(() => {
        loading.set(false)
      })
  }, [refresh])

  return (
    <Grid container xs={12} sx={{ display: 'flex', paddingX: 3 }}>
      <Grid item xs={12} hidden={!expandFilterSearch}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
          {translate('Indicators by Status')}
        </Typography>
          <WidgetCreate
            title={'Dispatch'}
            data={dispatchData.attach(Downgraded).get()}
            sx={{ marginBottom: '16px', fontSize: 'medium' }}
          />
      </Grid>
    </Grid>
  )
}
