import React from 'react';
import { Grid } from '@mui/material';
import { State, useHookstate } from '@hookstate/core';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { markOffClerkOccurrence, saveReportClerk } from '../../services';
import { LoadingButton } from '@mui/lab';
import notify from '~/utils/notify';
import { useLang } from '~/hooks/useLang';
import userUserState from '~/features/User/stores/UserState';
import ConfirmDialog from '~/components/ConfirmDialog';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
interface WhiteboardForClerkProps {
  readonly occurrenceId: number;
  readonly loadingComments: boolean;
  readonly comments: string[] | null;
  readonly report: string;
}

export default function WhiteboardForClerk({ occurrenceId, loadingComments, comments, report }: WhiteboardForClerkProps) {
  const { translate } = useLang();
  const { verifyPermission } = userUserState();
  const loading = useHookstate(false);
  const openDialog = useHookstate(false);
  const { dispatch } = useDispatchState();
  const { occurrence } = dispatch();
  const reportFormatedSend = useHookstate('');

  const commentsFormated = comments ? comments?.map((comment) =>
    comment.concat('', '<p><ul><li> </li></ul>')).join(" ") :
    '';

  const handleDialogCloseFunction = () => {
    loading.set(true)
    markOffClerkOccurrence({ occurrenceId: occurrenceId }).then((response) => {
      if (response.status === 200) {
        notify({ message: translate('MarkOff successfully!'), type: 'success' });
      }
    }).finally(() => {
      occurrence.toClerk.set(false)
      setTimeout(() => {
        window.close()
      }, 1000)
    })
  };

  const saveReport = () => {

    saveReportClerk({ occurrenceId: occurrenceId, report: reportFormatedSend?.get() }).then((response) => {
      if (response.status === 200) {
        notify({ message: translate('Saved successfully'), type: 'success' });
      }
    })
  }

  const handleDialogClose = () => {
    openDialog.set(false)
    loading.set(false)

  };

  const handleDialogOpen = () => {
    openDialog.set(true)
  };

  return (
    <>
      {!loadingComments &&
        <Grid style={{ height: '75vh' }}>
          <ReactQuill
            value={report && report != "<p><br></p>" ? report : commentsFormated}
            onChange={(text) => reportFormatedSend?.set(text)}
            style={{
              height: '90%',
            }}
          />
        </Grid>
      }
      <Grid container width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2}>
        <Grid item>
          {
            (verifyPermission(['occurrence_remove_clerk'])) && (
              <LoadingButton
                loading={loading.get()}
                onClick={() => handleDialogOpen()}
                variant="outlined"
              >
                {translate('MarkOff')}
              </LoadingButton>
            )
          }
        </Grid>
        <Grid item>
          <LoadingButton
            loading={loading.get()}
            onClick={() => saveReport()}
            variant="contained"
          >
            {translate('Send')}
          </LoadingButton>
        </Grid>
        <ConfirmDialog
          handleAgree={handleDialogCloseFunction}
          handleClose={handleDialogClose}
          open={openDialog}
          loading={loading}
          title={translate('Confirmation')}
          content={translate('Confirm markoff remove of clerk?')}
        />
      </Grid>
    </>
  )
}