import React, { useEffect } from 'react';
import Logo from '~/components/Logo';
import { Grid, Typography, Container, ImageListItem } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import useUserState from '~/features/User/stores/UserState';
import { dateTimeFormat } from '~/utils/dateTime';
import { getContributersDispatchService } from '~/features/Dispatch/services/index';
import { State, useHookstate } from '@hookstate/core';
import dayjs from 'dayjs';

export const HeaderPrintResponseDefault = {
  code: '',
  attendant: {
    id: null,
    name: null,
    representation_name: null,
    registration: null
  },
  coordination: {
    id: null,
    name: null,
    representation_name: null,
    registration: null
  },
  dispatchers: [
    {
      id: null,
      name: null,
      representation_name: null,
      registration: null
    }
  ]
}

interface HeaderPrintProps {
  readonly HeaderPrintResponse: typeof HeaderPrintResponseDefault
}

export default function HeaderPrint({ HeaderPrintResponse }: HeaderPrintProps) {
  const { translate } = useLang();
  const { user } = useUserState();
  const generateDocument = new Date();

  return (
    <Container fixed={false}>
      <Grid container xs={12}>
        <Grid item xs={3} justifyContent="center" alignContent="center">
          <Grid container xs={12}>
            <Grid item xs={12} maxHeight={'95px'} maxWidth={'95px'}>
              <Logo />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2"><b>{translate('SERVICE AND DISPATCH OF OCCURRENCES')}</b></Typography><br />
          <Typography variant="caption">{translate('Occurrence report')} nº: <b>{HeaderPrintResponse?.code}</b></Typography><br />
          <Typography variant="caption">{translate('Coordinator')}: <b>{HeaderPrintResponse?.coordination?.representation_name ?? `* ${translate('Uninformed')} *`}</b></Typography><br />
          <Typography variant="caption">{translate('Controller')}: <b>{HeaderPrintResponse?.dispatchers.map((dispatcher, index) => {
            return (
              HeaderPrintResponse?.dispatchers?.length - 1 != index ? (<>{dispatcher.name}, </>) : <>{dispatcher.name}</>
            )
          })}{HeaderPrintResponse?.dispatchers.length == 0 ? `* ${translate('Uninformed')} *` : null}</b></Typography><br />
          <Typography variant="caption">{translate('Attendant')}: <b>{HeaderPrintResponse?.attendant?.representation_name ?? `* ${translate('Uninformed')} *`}</b></Typography><br />


        </Grid>
        <Grid item xs={3} textAlign={'right'}>
          <Typography variant="caption" gutterBottom>{translate('Document generated in')}</Typography><br />
          <Typography variant="caption">{dayjs(generateDocument).format('DD/MM/YYYY HH:mm:ss')}</Typography><br />
          <Typography variant="caption">{translate('User')}: {user()?.representation_name?.get()}</Typography><br />
          <Typography variant="caption">{translate('CPF')}: {user()?.cpf?.get()}</Typography><br />
        </Grid>
      </Grid>
    </Container>
  );
}