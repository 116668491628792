import React, { useEffect } from 'react';
import Layout from '~/components/Layout';
import useSystemState from '../stores/SystemState';
import { Downgraded, useHookstate } from "@hookstate/core";
import { Box } from '@mui/material';
import useUserState from '~/features/User/stores/UserState';
import { useLang } from '~/hooks/useLang';


type WindowSupportProps = {
  readonly readOnly?: boolean;
}

export default function WindowSupport() {
  const { verifyPermission } = useUserState();
  const { renderComponents } = useSystemState().system()

  const ComponentTop = renderComponents.attach(Downgraded).get().top;
  const ComponentBottom = renderComponents.attach(Downgraded).get().bottom;
  const { translate } = useLang();

  useEffect(() => {
    document.title = translate('Window Support - Dispatch System')
  }, [])



  return (
    <Layout
      marginLayout={false}
      sidebar={false}
      topbar={false}
    >
      <Box sx={{ paddingBottom: 2 }}>
        <ComponentTop />
      </Box>

      <Box sx={{ paddingBottom: 2 }}>
        {/* <ComponentBottom readOnly={verifyPermission(['dispatch_read_only'])} /> */}
        <ComponentBottom />
      </Box>
    </Layout>
  )
}
