import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import Layout from '~/components/Layout';
import ISpecie from '../interfaces/ISpecie';



export default function Create() {     
  const {register, handleSubmit, control, setValue:setFormValue} = useForm<ISpecie>();
    const fields = [
      { name: 'name', type: 'string', required: true},
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate 
          model={'specie'} 
          fields={fields} 
          register={register} 
          handleSubmit={handleSubmit} 
          control={control} 
          messageSuccess={'Specie successfully created!'}  
          title={'Add specie'}  
          subheader={'Add a new specie'}
          setFormValue={setFormValue} />
      </Layout>
    )
}
