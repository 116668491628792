import React from 'react';
import { AppRouteProps } from '~/routes';
import Index from './Pages';
import Create from './Pages/Create';
import Edit from './Pages/Edit';

export default function categoryRoutes(): readonly AppRouteProps[] {
    const routes: AppRouteProps[] = [
        { path: '/category', component: Index, isProtected: true },
        { path: '/category/create', component: Create, isProtected: true },
        { path: '/category/edit/:id', component: Edit, isProtected: true },

    ];

    return routes;
}
