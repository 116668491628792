import React from 'react';
import { useLang } from '~/hooks/useLang';
import { Box } from '@mui/system';
import { useHookstate } from '@hookstate/core';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ListAllDispatch, { ListDispatchTab, useListAllDispatchState } from '../ListAllDispatch';
import { Card, CardContent, IconButton, TextField, CardHeader, Button, Link } from '@mui/material';
import { Search } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '~/components/ErrorFallback';

interface ITabListDispatch {
  readonly isNewTabWindow?: boolean;
  readonly isNoPaginationWindow?: boolean;
}

export default function TabListDispatch({ isNewTabWindow = false, isNoPaginationWindow = false }: ITabListDispatch) {
  const { translate } = useLang();
  const allDispatchState = useListAllDispatchState({ paginated: !isNoPaginationWindow });
  const tab = useHookstate(allDispatchState.tab);

  const handleTabDispatch = (event: React.SyntheticEvent, value: ListDispatchTab) => {
    tab.set(value);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Card sx={{ width: '100%', typography: 'body1' }}>
        <Box sx={{
          display: 'flex', flexDirection: 'row', pt: 2
        }}>
          <CardHeader title={translate(`Dispatches`)} />
          {isNewTabWindow &&
            <>
              <Box sx={{ flex: '1 1 auto' }} />
              <Link target='_blank' href={'/dispatch/dispatches/list'}>
                <Button
                  variant="text"
                  color="primary"
                  sx={{ mr: 3 }}
                >
                  <OpenInNewIcon />
                </Button>
              </Link>
            </>}
        </Box>
        <CardContent>
          <TabContext value={tab.get()}>

            <TabList onChange={handleTabDispatch}>
              <Tab label={translate('Pending')} value='pending' />
              <Tab label={translate('Dispatched')} value='dispatched' />
              <Tab label={translate('Finished')} value='done' />
              <Tab label={translate('Holded')} value='on-hold' />
            </TabList>

            <TabPanel sx={{ padding: 0 }} value="pending">
              <ListAllDispatch
                targetUrl={'/dispatch/pending'}
                listAllDispatchState={allDispatchState}
                hasPagination={!isNoPaginationWindow}
              />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value="dispatched">
              <ListAllDispatch
                targetUrl={'/dispatch/dispatched'}
                listAllDispatchState={allDispatchState}
                hasPagination={!isNoPaginationWindow}
              />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value="done">
              <ListAllDispatch
                targetUrl={'/dispatch/done'}
                listAllDispatchState={allDispatchState}
                hasPagination={true}
              />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value="on-hold">
              <ListAllDispatch
                targetUrl={'/dispatch/on-hold'}
                listAllDispatchState={allDispatchState}
                hasPagination={isNoPaginationWindow ? false : true}
              />
            </TabPanel>
          </TabContext>
        </CardContent>
      </Card>
    </ErrorBoundary>
  )
}
