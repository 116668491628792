import React from 'react';
import { Box, Container, ImageListItem, Typography } from '@mui/material';
import Logo from '~/components/Logo';
import Image404 from '../components/404/Image404';
import { useLang } from '~/hooks/useLang';
import Grid from '@mui/material/Grid';

export default function NotFound404() {
  const { translate } = useLang();
  return (
    <Container maxWidth="md" sx={{ height: '95vh', display: 'grid', placeItems: 'center' }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid item xs={4}>
            <ImageListItem>
              <Logo />
            </ImageListItem>
          </Grid>
          <Typography variant="h1">
            404
          </Typography>
          <Typography variant="h6">
            {translate('The page you’re looking for doesn’t exist.')}
          </Typography>
        </Grid>
        <Grid xs={6}>
          <Image404 />
        </Grid>
      </Grid>
    </Container>
  )
}
