/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { BarChart } from '@mui/icons-material';
import { Box, Button, Card, ListItemButton, ListItemText, Typography, Tooltip } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHistory } from 'react-router-dom';
import { MenuProps } from '~/components/SidebarMenu/interfaces';
import useUserState from '~/features/User/stores/UserState';

function ManagementMenu({ activeMenu, handleMenuClick }: MenuProps) {
    const { translate } = useLang();
    const history = useHistory();
    const { verifyPermission } = useUserState();


    return (
        <>
        {(verifyPermission(['statistics_dispatch', 'statistics_device_connection', 'entry_overview'])
        ) && (
            <Box>
            <Tooltip title={translate('Management')} placement="bottom">
                <Button className={`main-menu ${activeMenu == 'management' ? 'active' : ''}`} onClick={() => handleMenuClick('management')}>
                    <BarChart />
                </Button>
            </Tooltip>
            <Card className='submenu-box' sx={{ borderRadius: 0, display: activeMenu == 'management' ? 'block' : 'none' }}>
                <Typography variant='h4'>
                    <BarChart />
                    {translate('Management')}
                </Typography>

                {
                    verifyPermission(['statistics_dispatch']) && (
                        <Tooltip title={translate('Show statistics')} placement="right">
                            <ListItemButton onClick={() => {
                                handleMenuClick('management')
                                history.push("/management/statistics/dispatch")
                            }}>
                                <ListItemText primary={translate('Dispatch Statistic')} />
                            </ListItemButton>
                        </Tooltip>
                    )
                }

                {
                    verifyPermission(['statistics_device_connection']) && (
                        <Tooltip title={translate('Search connections')} placement="right">
                            <ListItemButton onClick={() => {
                                handleMenuClick('management')
                                history.push("/management/device/connections")
                            }}>
                                <ListItemText primary={translate('Device connections')} />
                            </ListItemButton>
                        </Tooltip>
                    )
                }
            </Card>
        </Box>
        )}
        </>
    )
}

export default ManagementMenu;