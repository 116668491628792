import React from "react";
import { Grid, Typography } from "@mui/material";
import { useLang } from "~/hooks/useLang";

interface EmptyReportProps {
  readonly justify: 'center' | 'left'
}

export default function EmptyReport({justify}: EmptyReportProps) {
  const { translate } = useLang();

  return (
    <Grid 
      container 
      marginTop={1}
      marginLeft={1.5}
    >
      <Grid 
      item
      sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: justify}}
      >
        <Typography>
          {translate('No information')}
        </Typography>
      </Grid>
    </Grid>
  )
}