/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import StandardModal from '../../../../components/StandardModal'
import { GridRowParams, MuiEvent } from '@mui/x-data-grid'
import { useLang } from '../../../../hooks/useLang'
import useSystemState from '~/features/System/stores/SystemState'
import { storeScreenSettings } from '~/features/System/services'
import { Typography } from '@mui/material'
import ProcedureList, { ConfigProcedureListProps } from '~/components/ProcedureList'
import { Downgraded, useHookstate } from '@hookstate/core'

const modal = document.getElementById('div-modal') as HTMLElement

export default function DispatchProcedureModalDraggable() {
  const { translate } = useLang()
  const { windowManager, loadingButton } = useSystemState()
  const configProcedureList = useHookstate<readonly ConfigProcedureListProps[]>([])

  useEffect(() => {
    configProcedureList.set(windowManager()['dispatchProcedure'].procedure?.attach(Downgraded).get() ?? [])
  }, [])

  const handleStop = (event, dragElement) => {
    event.stopPropagation()
    windowManager()['dispatchProcedure'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y,
    })
  }

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'DispatchProcedureModalDraggable',
        isOpen: windowManager()['dispatchProcedure'].open.get(),
        isMinimized: windowManager()['dispatchProcedure'].minimized.get(),
        positionX: windowManager()['dispatchProcedure'].positionX.get(),
        positionY: windowManager()['dispatchProcedure'].positionY.get(),
      })
    }
  }, [
    windowManager()['dispatchProcedure'].open.get(),
    windowManager()['dispatchProcedure'].minimized.get(),
    windowManager()['dispatchProcedure'].positionY.get(),
    windowManager()['dispatchProcedure'].positionX.get(),
  ])

  if (windowManager()['dispatchProcedure'].open.get()) {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() && (
          <StandardModal
            title={
              <Typography variant='h5'>
               { translate('Procedures')}
              </Typography>
            }
            dimensions={{
              width: 600,
              height: 500
            }}
            handleStop={handleStop}
            position={{
              x: windowManager()['dispatchProcedure'].positionX.get()
                ? Number(windowManager()['dispatchProcedure'].positionX.get())
                : 80,
              y: windowManager()['dispatchProcedure'].positionY.get()
                ? Number(windowManager()['dispatchProcedure'].positionY.get())
                : 141,
            }}
            closeButton={() => {
              windowManager()['dispatchProcedure'].open.set(false)
              windowManager()['dispatchProcedure'].alreadyOpened.set(true)
            }}
            isExpanded={windowManager()['dispatchProcedure'].minimized}
          >
            <ProcedureList configProcedureList={configProcedureList} />
          </StandardModal>
        )}
      </>,
      modal
    )
  } else {
    return <></>
  }
}