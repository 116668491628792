import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IVehicle from '../interfaces/IVehicle';


export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue,getValues} = useForm<IVehicle>();
  const fields = [
      { name: 'plate', type: 'string'},
      { name: 'identification', type: 'string' },      
      { name: 'agency_id', type: 'selectAutoComplete', model: 'agency', placeholder:'Agency:name' },      
      { name: 'vehicle_type_id', type: 'selectAutoComplete', model: 'vehicle-type', placeholder: 'VehicleType:name' },
      { name: 'dispatch_group_id', type: 'selectAutoComplete', model: 'dispatch/group', placeholder: 'Dispatch Group', optionSelect: 'acronym'},
      { name: 'device_id', type: 'selectAutoComplete', model: 'device', placeholder: 'Device:name' },
      { name: 'id', type: 'hidden' },
  ];
  
    return(
      <CrudEdit model={'vehicle'} 
      fields={fields} 
      register={register} 
      handleSubmit={handleSubmit} 
      control={control} 
      setFormValue={setFormValue}
      messageSuccess={'Vehicle successfully updated!'}  
      title={'Edit Vehicle'} 
      getValues={ getValues }
      />
    )
}



