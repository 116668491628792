import { AxiosError } from 'axios';
import { IParamsTable } from '../../../interfaces';
import { authFetch } from '~/services/fetch';

export async function getCityById(id: number) {
        if (!id) return null;
        const response = await authFetch({
            url: `/city/${id}`,
            method: 'GET' 
        });
        return response.data;
      
}

export async function getCities({
    filters = [],
    limit = 100000,
    orders = [
        {field: 'name', sort: 'asc'}
    ]
}: IParamsTable = {}) {
        const response = await authFetch({
            url: `/city/`,
            method: 'POST',
            data: { 
                limit,
                filters,
                orders
            },
            
        });
        return response.data.data;
      
}