import React from 'react';
import { useLang } from '~/hooks/useLang';
import { TextField } from '@mui/material';
import { maskPlate } from '~/features/Dispatch/utils/stringsManipulations';

export default function PlateField({ plate }) {
  const { translate } = useLang();
  
    return (
      <TextField
          fullWidth
          size='small'
          id="outlined-plate"
          label={translate('Plate')}
          value={plate?.get() ? plate?.get() : ''}
          InputProps={{
            onChange:
              (event) => {
                const plateMask = maskPlate(event.target.value);
                plate?.set(plateMask);
              },
          }}
        />
    )
}