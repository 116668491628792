import React from 'react';
import { Grid, CircularProgress } from '@mui/material';
import OccurrenceTimelineContent from '~/features/Occurrence/components/OccurrenceTimelineContent'
import { State, useHookstate } from '@hookstate/core';
import ButtonReloadInfoForClerk from '../ButtonReloadInfoForClerk';

interface ChronologyOccurrenceForClerkProps {
  readonly occurrenceId: number | string;
  readonly reloadInfo: State<boolean>;
  readonly loading: State<boolean>;
}

export default function ChronologyOccurrenceForClerk({occurrenceId, reloadInfo, loading }: ChronologyOccurrenceForClerkProps){
  return (
    <Grid container width={'100%'}>
      <Grid item sx={{height: '75vh'}} width={'100%'}>
        <OccurrenceTimelineContent occurrenceId={occurrenceId} reloadInfo={reloadInfo} loading={loading}/>
      </Grid>
    </Grid>
  )
}