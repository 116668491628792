import React, { useEffect } from 'react';
import { Grid, Typography, Slider } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { Controller, UseFormGetValues, UseFormRegister } from 'react-hook-form';
import { useHookstate } from '@hookstate/core';

interface CrudSliderFieldProps {
  readonly register: UseFormRegister<any>;
  readonly model:string;
  readonly control: any;
  readonly getValues?: UseFormGetValues<any>;
  readonly minimum: number;
  readonly maximum: number;
}

export default function CrudSliderField({register, control, model, getValues, minimum, maximum }:CrudSliderFieldProps) {
  const { translate } = useLang();
  const registeredModelFormField = register(model);

  return (
    <Grid item xs={4}>
      <Typography id="slider" gutterBottom>
        {translate(`${model.charAt(0).toUpperCase() + model.slice(1)}`)}
      </Typography>
      <Controller
          {...registeredModelFormField}
          name={model}
          control={control}
          defaultValue={[minimum, maximum]}
          render={(props) => (
            <Slider
              {...props.field}
              onChange={(_, value) => {
                props.field.onChange(value);
              }}
              valueLabelDisplay="auto"
              max={maximum}
              step={1}
            />
          )}
        />
     
    </Grid>
  )
}