/* eslint-disable max-lines-per-function */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Button, Card, CardHeader, Collapse, Grid, IconButton } from '@mui/material';
import ErrorFallback from '~/components/ErrorFallback';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';
import notify from '~/utils/notify';
import { authFetch } from '~/services/fetch';
import useReportState from '../../stores/ReportState';
import { downloadReportCSV, makeRequestToGetReportDispatchesStatusByDate } from '../../services/report';
import { useHookstate } from '@hookstate/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups';
import FilterAutocompleteAgencies from '~/components/FilterAutocompleteAgencies';
import FilterSelectMoment from '~/components/FilterAutocompleteMoment';
import FilterAutocompleteCategory from '~/components/FilterAutocompleteCategory';
import FilterAutocompleteTypes from '~/components/FilterAutoCompleteTypes';
import GenerateReportButton from '../../components/GenerateReportButton';
import ExportButtonCSV from '../../components/ButtonExportCSV';
import FilterAutocompleteCity from '~/components/FilterSelectAutocompleteCity';
import FilterAutocompleteEntryOrigin from '~/components/FilterAutocompleteEntryOrigin';
import FilterAutocompleteDispatchGroupSet from '~/components/FilterAutocompleteDispatchGroupSet';
import { Dayjs } from 'dayjs';

export default function StatusDispatchByDate() {
  const { translate } = useLang();
  const { loadingReport, reportData,
    dispatchGroups: {
      dispatchGroup
    }
  } = useReportState();
  const isLoading = useHookstate<boolean>(false);
  const expandFilterSearch = useHookstate<boolean>(true);
  const momentDate = useHookstate<string | null>(null);
  const deviceId = useHookstate<number | null>(null);
  const lockFields = useHookstate<boolean>(false);
  const categoryId = useHookstate<number | null>(null);
  const groupId = useHookstate<readonly number[]>([]);
  const agencyIds = useHookstate<readonly number[]>([]);
  const typeId = useHookstate<readonly number[]>([]);
  const labelButton = useHookstate('')
  const reportName = 'dispatches-status-by-date'
  const cityId = useHookstate<number | null>(null);
  const entryOriginId = useHookstate<number | null>(null);
  const groupSetId = useHookstate<number | null>(null)

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])


  useEffect(() => {
    const getDispatchGroups = async () => {
      authFetch({
        url: '/station/dispatch-groups/me',
        method: 'GET',
        data: {}
      }).then(response => {
        dispatchGroup.options.merge(response.data)
      })
    }

    getDispatchGroups();
  }, [])

  function generateReport() {
    if (momentDate) {
      loadingReport().set(true)
      isLoading.set(true)
      makeRequestToGetReportDispatchesStatusByDate({
        momentDate: momentDate.get(),
        agencyIds: agencyIds.get(),
        dispatchGroupsIds: groupId.get(),
        categoryId: categoryId.get(),
        typeIds: typeId.get(),
        cityId: cityId.get(),
        entryOriginId: entryOriginId.get(),
        groupSetId: groupSetId.get()
      })
        .then(response => {
          reportData().set(response)
         window.open(`/report/result/${reportName}`)
        }).finally(() => {
          loadingReport().set(false)
          isLoading.set(false)
        })
    } else {
      notify({ message: translate('Initial and final date are mandatory'), type: 'error' })
    }
  }

  const exportCSV = async () => {
    if (momentDate) {
      isLoading.set(true)
      const dataCSV = await makeRequestToGetReportDispatchesStatusByDate({
        momentDate: momentDate.get(),
        agencyIds: agencyIds.get(),
        dispatchGroupsIds: groupId.get(),
        categoryId: categoryId.get(),
        typeIds: typeId.get(),
        exportCSV: true,
        cityId: cityId.get(),
        entryOriginId: entryOriginId.get(),
        groupSetId: groupSetId.get()
      }).finally(() => isLoading.set(false))
      downloadReportCSV(`${translate(reportName)}-${new Date().getTime()}.csv`, dataCSV)
    } else {
      notify({ message: translate('Initial and final date are mandatory'), type: 'error' })
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true} >
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader title={`${translate('Report')}: ${translate(`Dispatches status by date`)}`} />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader title={`${translate('Filters')}`}
            avatar={(
              <IconButton
                onClick={() => expandFilterSearch.set(!expandFilterSearch.get())}
                sx={{ display: 'flex', justifyContent: 'end', marginRight: 1, padding: 0 }}>
                {expandFilterSearch.value ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
              </IconButton>
            )}
          />
          <Collapse in={expandFilterSearch.get()}>
            <Grid
              container xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
              }}
            >
              <Grid container direction={'row'} rowGap={2}>

                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteAgencies
                    disabled={lockFields.get()}
                    agenciesId={agencyIds.get()}
                    onAgenciesChange={(agency) => {
                      labelButton.set(translate('Search'))
                      agencyIds.set(agency)
                    }} />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchGroupSet
                    disabled={lockFields.get()}
                    dispatchGroupSetId={groupSetId?.get() ?? undefined}
                    onGroupDispatchSetChange={(group) => {
                      groupSetId?.set(group)
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchsGroups
                    disabled={lockFields.get()}
                    agencyId={agencyIds.get() ?? undefined}
                    dispatcheGroups={groupId.get() ?? undefined}
                    onGroupDispatchChange={(group) => {
                      groupId.set(group)
                      deviceId.set(null)
                      labelButton.set(translate('Search'))
                    }}
                    groupSetId={groupSetId.get()}
                  />
                </Grid>

                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteCategory
                    disabled={lockFields.get()}
                    categoryId={categoryId.get()}
                    onCategoryChange={(category) => {
                      categoryId.set(category)
                      typeId.set([])
                    }} />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteTypes
                    disabled={lockFields.get()}
                    categoryId={categoryId.get() ?? undefined}
                    typeId={typeId.get()}
                    onTypeChange={(type) => {
                      typeId.set(type)
                    }} />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteCity
                    disabled={lockFields.get()}
                    onCityChange={(city) => {
                      cityId?.set(city)
                      labelButton.set(translate('Search'))
                    }}
                    cityId={cityId?.get()}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteEntryOrigin
                    disabled={lockFields.get()}
                    onEntryOriginChange={(entryOrigin) => { 
                      entryOriginId?.set(entryOrigin)
                      labelButton.set(translate('Search'))
                    }}
                    originId={entryOriginId?.get()}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterSelectMoment
                    onDateChange={(value) => momentDate.set(value?.toJSON() ?? null)}
                    disabled={lockFields.get()}
                    date={momentDate.get()}
                    required
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: 2
                  }}>
                    <Button variant="outlined"
                    onClick={() => {
                      categoryId.set(null)
                      deviceId.set(null)
                      agencyIds.set([])
                      groupSetId.set(null)
                      groupId.set([])
                      entryOriginId.set(null)
                      cityId.set(null)
                      typeId.set([])
                      momentDate.set(null)
                    }}
                    sx={{ height: '40px' }}
                >
                    {translate('Clear Filters')}
                </Button>
                  <ExportButtonCSV
                    isLoading={isLoading.get()}
                    dataToExport={exportCSV}
                  />
                  <GenerateReportButton
                    isLoading={isLoading.get()}
                    makeReport={generateReport}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary >
  )
}