import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import ISubtype from '../interfaces/ISubtype';
import Layout from '~/components/Layout';


export default function Create() {     
  const { register, handleSubmit, control, setValue:setFormValue } = useForm<ISubtype>();
  const fields = [
    { name: 'name', type: 'string'},
    { name: 'code', type: 'string' },
    { name: 'type_id', type: 'selectAutoComplete', model: 'type', placeholder: 'Type' }, 
    { name: 'priority', type: 'select', options: [1, 2, 3, 4, 5, 6, 7, 8, 9] }, 
    { name: 'mandatory_vehicle', type: 'number' }, 
    { name: 'mandatory_involved', type: 'number' }, 
    { name: 'mandatory_weapon', type: 'number' }, 
    { name: 'mandatory_organization', type: 'number' }, 
    { name: 'mandatory_drug', type: 'number' }, 
    { name: 'mandatory_object', type: 'number' }, 
    { name: 'ref_to_vehicle', type: 'switch'},     
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate model={'subtype'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        messageSuccess={'Subtype successfully created!'}  
        title={'Add subtype'}  
        subheader={'Add a new subtype'}
        setFormValue={setFormValue} />
      </Layout>
    )
}
