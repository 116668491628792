import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { ICoordenation } from '~/features/Dispatch/pages/Summary';

interface ISignatureProps {
  readonly coordenation: ICoordenation | null;
}

export default function Signature({ coordenation }: ISignatureProps) {
  return (
    <Container fixed={false}>
      <Grid container xs={12} sx={{ marginTop: 10 }}>
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <Typography sx={{ borderTop: 1, width: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Súmula Recebida por
            {' '}
            {coordenation?.coordinator.representation_name}
            {' '}
            {coordenation?.coordinator.cpf}

          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography sx={{ borderTop: 1, width: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Data e hora:
          </Typography>
        </Grid>
      </Grid>

    </Container>
  )
}