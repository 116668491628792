import { useHookstate } from '@hookstate/core';
import React, { useEffect } from 'react';
import CrudIndex from '~/components/CrudIndex';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';

export default function Index() {
  const { translate } = useLang();

  const forceRefresh = useHookstate<boolean>(false);
  const lockFields = useHookstate<boolean>(false);
  const loading = useHookstate<boolean>(false);
  const dispatchGroupSetName = useHookstate<string | null>(null);

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])

  const dispatchGroupSetFilter = [
    {
      type: 'name',
      placeholder: 'Name',
      variable: dispatchGroupSetName
    },
  ]

  return (
    <Layout marginLayout={true}>
      <CrudIndex
        model='dispatch/group-set'
        title='Dispatch Group Set'
        forceOrderColumns={['name']}
        loading={loading}
        lockFields={lockFields.get()}
        clearFilters={() => {
          dispatchGroupSetName.set('')
          forceRefresh.set(!forceRefresh.get())
        }}
        filters={dispatchGroupSetFilter}
        searchButton={() => forceRefresh.set(!forceRefresh.get())}
        forceRefresh={forceRefresh.get()}
        disableColumnsFilters={true}
        canView={true}
        showInViewMore={['name', 'dispatch_groups', 'created_at']}
        viewTitle='Dispatch Group Set'
        permissionName='group_set'
      />
    </Layout>
  )
}
