/* eslint-disable max-lines-per-function */
import React from 'react'
import {
  createState,
  useState,
} from '@hookstate/core'
import { authFetch } from '~/services/fetch'
import { IDispacthRegistrationObject, IObjectDTO } from '../interfaces/IDispacthRegistrationObject'
import { toDomain, toFetch } from '~/mappers/registration-object-mapper'

export const objectDefaultState: IObjectDTO = {
  occurrenceId: null,
  objectType: null,
  quantity: 0,
  unitMeasurement: null,
  notes: null,
  circunstance: null,
  destination: null,
}

const defaultRegistrationObjectProps: IDispacthRegistrationObject = {
  objects: [],
  object: objectDefaultState
}

const registrationDispatchObjectState = createState<IDispacthRegistrationObject>(defaultRegistrationObjectProps);

const useDispatchRegistrationObjectState = () => {
  const registrationObjectDispatch = useState(registrationDispatchObjectState);

  return {
    registrationObjectDispatch: () => registrationObjectDispatch,
    getObjectTypes: async () => {
      const targetUrl = '/object-type'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data
    },
    getAllAssociatedObject: async (occurrenceId: string | number | null) => {
      const targetUrl = '/occurrence/associated-object/all-from-occurrence'
      const response = await authFetch({
        url: targetUrl,
        data: {
          occurrence_id: occurrenceId,
        }
      });
      return response
        .data
        .map((object): IObjectDTO => {
          return toDomain(object)
        })
    },
    sendObject: async (object: IObjectDTO) => {
      const targetUrl = '/occurrence/associated-object/store'
      return await authFetch({
        url: targetUrl,
        data: toFetch(object)
      });
    },
    editObject: async ({ id, ...object }: IObjectDTO) => {
      const targetUrl = `/occurrence/associated-object/${id}`
      await authFetch({
        url: targetUrl,
        method: 'PUT',
        data: toFetch(object)
      });
    },
    deleteObject: async (id: string) => {
      const targetUrl = `/occurrence/associated-object/${id}`
      return await authFetch({
        url: targetUrl,
        method: 'DELETE',
      });
    },
  }
}

export default useDispatchRegistrationObjectState;