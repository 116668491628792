import React, { ChangeEvent, useEffect } from "react";
import { useLang } from "~/hooks/useLang";
import { useTheme } from "~/hooks/useTheme";
import useListVehiclesSocket from "../stores/ListVehiclesSocketState";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import { State, useHookstate } from "@hookstate/core";
import { LoadingButton } from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';
import { IFilters } from "~/components/ListDispatchesSocket/services";
import { disconnectMultipleVehicles } from "../services";
import notify from "~/utils/notify";
import { element } from "prop-types";


interface DialogDisconnectMultipleDispatchProps {
  readonly openDialog: State<boolean>;
  readonly onClose: any;
  readonly rowsSelected: State<any>;
  readonly enableButtonShowResume: State<boolean>;
  readonly openModalResultMultipleDisconnectVehicle: State<boolean>;
  readonly clearFilters: () => void;
  readonly handleLoadDevices: (filters?: readonly IFilters[]) => void;
}

// eslint-disable-next-line max-lines-per-function
export default function DialogDisconnectMultipleVehicles({ onClose, openDialog, rowsSelected, enableButtonShowResume, openModalResultMultipleDisconnectVehicle, handleLoadDevices, clearFilters }: DialogDisconnectMultipleDispatchProps) {
  const { translate } = useLang();
  const { darkMode } = useTheme();
  const { listVehiclesMultipleDisconnect } = useListVehiclesSocket()
  const openDialogErrorClosed = useHookstate(false);
  const loading = useHookstate(false);
  const dispatchErrorClosed = useHookstate<ReadonlyArray<any> | null>(null);
  const errorCloses = listVehiclesMultipleDisconnect().get().filter((element) => element.error != '').map(item => item.error);
  const closeSuccess = listVehiclesMultipleDisconnect().get().filter((element) => element.error === '').map(item => item.error);


  const handleClose = () => {
    openDialog.set(false);
  };

  const clickOutModalErroClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      openDialogErrorClosed.set(false);
    }
  };

  const handleCLoseModalError = () => {
    openDialogErrorClosed.set(false)
    // dispatchErrorClosed.set(null)
    // comment.set('')
    // closedStatus.set(null)
    openModalResultMultipleDisconnectVehicle.set(false)
    enableButtonShowResume.set(false)
  }

  const handleDisconnectMultipleVehicles = (params) => {
    const ids = params.map(row => row.id)
    loading.set(true)
    openDialog.set(false);

    disconnectMultipleVehicles({ deviceIds: ids }).then((response) => {
      if (response.data.length > 0) {
        dispatchErrorClosed.set(response.data)
        openDialogErrorClosed.set(true)
      }

      if (response.status === 200) {
        notify({
          message: `${translate('The batch disconnection will be processed and you will receive a notice when the process is complete')}`,
          type: 'info'
        })
        enableButtonShowResume.set(true)
        onClose
      }
    }).finally(() => {
      clearFilters()
      handleLoadDevices()
      loading.set(false)
    })

  }

  return (
    <>
      <Dialog
        open={openDialog.get()}
        onClose={onClose}
        disableEscapeKeyDown={true}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle>
          {translate('Disconnect devices')}
        </DialogTitle>
        <DialogContent>
          <Typography variant='subtitle1'>
            {translate('Summary of the selected devices')}
          </Typography>
          <List
            sx={{
              width: '100%',
              maxHeight: '500px',
              bgcolor: darkMode ? 'transparent' : '#FFF',
              marginTop: 2,
              overflowY: 'auto',
              border: 1,
              borderColor: 'grey.500',
              borderRadius: 1
            }}
          >
            {rowsSelected?.get().map((item, index, { length }) => (
              <>
                <ListItem key={item.id}>
                  <ListItemText
                    sx={{ display: 'inline', maxHeight: '150px', overflow: 'auto', whiteSpace: 'pre-line' }}
                    primary={`${translate('Name')}: ${item.identification}`}
                    secondary={
                      <Typography>
                        {`${translate('Commander')}: ${item.commander}\n${translate('Dispatch Group')}: ${item.dispatchGroupId}`}
                      </Typography>} />
                </ListItem>
                {index + 1 != length &&
                  <Divider />
                }
              </>
            ))}
          </List>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingX: "24px" }}>
          <Button
            variant='outlined'
            onClick={handleClose}>
            {translate('Cancel')}
          </Button>
          <LoadingButton
            loading={loading.get()}
            variant='contained'
            // onClick={() => handleCloseMultipleDispatchs(rowsSelected.get())}>
            onClick={() => {
              handleDisconnectMultipleVehicles(rowsSelected.get())
              // enableButtonShowResume.set(true), onClose 
            }}>
            {translate('Submit')}
          </LoadingButton>

        </DialogActions>
      </Dialog>
      <Dialog
        open={openModalResultMultipleDisconnectVehicle.get()}
        onClose={clickOutModalErroClose}
        disableEscapeKeyDown={true}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          {translate('Summary of last batch devices disconnection')}
          <IconButton
            aria-label="close"
            onClick={() => handleCLoseModalError()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {closeSuccess.length > 0 ? translate('Devices disconnected with success') : translate('No devices has been disconnected')}
          </Typography>
          {closeSuccess.length > 0 &&
            <List
              sx={{
                width: '100%',
                maxHeight: '500px',
                bgcolor: darkMode ? 'transparent' : '#FFF',
                marginTop: 2,
                overflowY: 'auto',
                border: 1,
                borderColor: 'grey.500',
                borderRadius: 1,
              }}
            >
              {listVehiclesMultipleDisconnect().get()?.filter((element) => element.error === '').map((item, index, { length }) => (
                <>
                  <ListItem key={index} >
                    <ListItemText
                      sx={{
                        display: 'inline',
                        maxHeight: '150px',
                        overflow: 'auto',
                        whiteSpace: 'pre-line',
                      }}
                      primary={
                        <Typography>
                          {`${translate('Device:name')}: ${item.device ? item.device : translate('Not informed')}`}
                        </Typography>
                      }
                    >
                      <Divider />
                    </ListItemText>
                  </ListItem>
                  {index + 1 != length &&
                    <Divider />
                  }
                </>
              ))}
            </List>
          }

          <Typography variant="body1" marginTop={2}>
            {errorCloses.length > 0 ? translate('Despachos that could not be closed') : translate('There were no closing errors')}
          </Typography>
          {errorCloses.length > 0 &&
            <List
              sx={{
                width: '100%',
                maxHeight: '500px',
                bgcolor: darkMode ? 'transparent' : '#FFF',
                marginTop: 2,
                overflowY: 'auto',
                border: 1,
                borderColor: 'grey.500',
                borderRadius: 1
              }}
            >
              {listVehiclesMultipleDisconnect().get()?.filter((element) => element.error ? element.error.length > 0 : '').map((item, index) => (
                <>
                  <ListItem key={index}>
                    <ListItemText
                      sx={{ display: 'inline', maxHeight: '150px', overflow: 'auto', whiteSpace: 'pre-line' }}
                      primary={
                        // <Button type="submit" variant="contained"
                        //   // onClick={() => handleClickAction(params)}
                        //   sx={{
                        //     backgroundColor: getStatusColors(1).main,
                        //     color: getStatusColors(1).contrastText,
                        //     '&:hover': {
                        //       backgroundColor: getStatusColors(1).dark,
                        //       color: getStatusColors(1).contrastText,
                        //     },
                        //   }}>
                        //   {`${translate('Code')}: ${item.dispatch}`}
                        // </Button>
                        <>
                          {`${translate('Device:name')}: ${item.device ? item.device : translate('Not informed')}`}
                          {/* <IconButton onClick={() => openDispatch(item.dispatch)}>
                            <OpenInNewIcon />
                          </IconButton> */}
                        </>
                      }
                      secondary={
                        <Typography variant="body2" color='error'>
                          {`${translate('Error')}: ${translate(item.error ? item.error : '')}`}
                        </Typography>
                      }
                    />
                    <Divider />
                  </ListItem >
                  <Divider />
                </>
              ))}
            </List>
          }
        </DialogContent>
      </Dialog >
    </>

  )
}