import React from 'react';
import { Collapse, List, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import {
    ExpandLess,
    ExpandMore
} from '@mui/icons-material';

import { useHistory } from 'react-router-dom';
import useUserState from '~/features/User/stores/UserState';
import { MenuProps } from '~/components/SidebarMenu/interfaces';

export default function AnimalItem({ handleMenuClick }: MenuProps) {
    const { translate } = useLang();
    const { verifyPermission } = useUserState();
    const [openAnimalItem, setOpenDrugItem] = React.useState(false);

    const history = useHistory();

    const handleClickDrugItem = () => {
        setOpenDrugItem(!openAnimalItem);
    }



    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            <Tooltip title={translate('Animals options')} placement="right">
                <ListItemButton sx={{ pl: 4 }} onClick={handleClickDrugItem}>
                    <ListItemText primary={translate('Animals')} />
                    {openAnimalItem ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </Tooltip>
            <Collapse in={openAnimalItem} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                    {verifyPermission(['specie_index']) && (
                        <Tooltip title={`${translate('Create, read, update and delete')}` + ' ' + `${translate('Species')}`} placement="right">
                            <ListItemButton sx={{ pl: 8 }} onClick={() => {
                                handleMenuClick('administration')
                                history.push("/specie")
                            }}>
                                <ListItemText primary={translate('Species')} />
                            </ListItemButton>
                        </Tooltip>
                    )}
                    {verifyPermission(['breed_index']) && (
                        <Tooltip title={`${translate('Create, read, update and delete')}` + ' ' + `${translate('Breeds')}`} placement="right">
                            <ListItemButton sx={{ pl: 8 }} onClick={() => {
                                handleMenuClick('administration')
                                history.push("/breed")
                            }}>
                                <ListItemText primary={translate('Breeds')} />
                            </ListItemButton>
                        </Tooltip>
                    )}

                </List>
            </Collapse>
        </>
    );
}