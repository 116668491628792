import React, { useEffect } from 'react';
import { Grid, IconButton, TextField, Fab } from '@mui/material';
import { t as translate } from 'i18next';
import EditIcon from '@mui/icons-material/Edit';
import { useHookstate } from '@hookstate/core';
import { authFetch } from '~/services/fetch';
import TypeSelectField from '~/features/Entry/components/TypeSelectField';
import SubtypeSelectField from '~/features/Entry/components/SubtypeSelectField';
import { LocalityTextField, LocationState } from "~/components/LocalityTextField";
import ReferenceField from '~/features/Entry/components/ReferenceField';
import CitySelectField from '~/features/Entry/components/CitySelectField';
import DistrictSelectField from '~/features/Entry/components/DistrictSelectField';
import useDispatchState from '../../stores/DispatchState';
import FilterAutocompleteDistrict from '~/components/FilterSelectAutocompleteDistrict';
import FilterAutocompleteCity from '~/components/FilterSelectAutocompleteCity';
import useSystemState from '~/features/System/stores/SystemState';
import { getCities, getDistric } from './services';


export default function EventInputContainerDispatch({ readonly = false, isValid, isValidEdit, isInvalidRequest, id, lat, lon, type, location, cityId, districtId, typeId, typeName, typeCode, subtypeId, subTypeName, subtypeCode, reference, canEdit = true, totalSubtypes, totalDistricts }) {
  const { dispatchStatusActions } = useDispatchState();
  const { windowManager } = useSystemState();

  const city = useHookstate('');
  const district = useHookstate('');

  const locationState: LocationState = {
    id,
    cityId,
    latitude: lat,
    longitude: lon,
    locationValue: location,
    type
  }

  useEffect(() => {
    getCities({ cityId: cityId.get(), cityName: city })
    getDistric({ districtId: districtId.get(), districtName: district })
  }, [cityId?.get(), districtId?.get()])



  return (
    <Grid container rowSpacing={1} sx={{ flexDirection: 'column', mb: 1 }}>
      <Grid container columnSpacing={1} sx={{ flexDirection: 'row', mb: 0.5, mt: 1, alignItems: 'center' }}>
        <Grid item xs md>

          {!dispatchStatusActions().editingDispatch.get() ? (
            <Grid container columns={12} rowSpacing={1} sx={{ flexDirection: 'column', mb: 1 }}>
              <Grid item>
                <TextField
                  disabled={true}
                  placeholder={translate('Address')}
                  value={`${translate('Type')}: ${typeName?.get() ? typeCode?.get() + ` - ${typeName?.get()}` : ''}\n${translate('Subtype')}: ${subTypeName?.get() ? subtypeCode?.get() + ` - ${subTypeName?.get()}` : ''}\n\n${translate('Localização')}: ${locationState?.locationValue?.get() ?? ''}\n${translate('Reference')}: ${reference?.get() ?? ''}\n${translate('City')}: ${city?.get() ?? ''}\n${translate('District')}: ${district?.get() ?? ''}`}
                  size='small'
                  fullWidth
                  multiline
                  rows={12}
                  InputProps={{
                    endAdornment:
                      canEdit && !readonly ? <IconButton
                        disabled={!isValid || !isValidEdit}
                        onClick={() => {
                          dispatchStatusActions().editingDispatch.set(true)
                          windowManager()['dispatchProcedure'].open.set(false)
                          windowManager()['dispatchProcedure'].procedure.set([])
                        }}
                        sx={{
                          position: 'absolute',
                          bottom: 25,
                          left: 238,
                          padding: '3px',
                        }}>
                        <EditIcon />
                      </IconButton>
                        : false
                  }}
                />
              </Grid>
            </Grid>
          ) : false}

          {/* / */}
          {dispatchStatusActions().editingDispatch.get() ? (
            <Grid container columns={12} rowSpacing={1} sx={{ flexDirection: 'column', mb: 1 }}>
              <Grid item>
                <TypeSelectField isValid={isValid} isInvalidRequest={typeId.get() === null} typeId={typeId} typeName={typeName} isActive={true} showVehicleModal={false} />
              </Grid>
              <Grid item>
                <SubtypeSelectField isValid={isValid} isInvalidRequest={totalSubtypes.get() > 0 ? totalSubtypes.get() > 0 && subtypeId.get() === null : false} typeId={typeId} subtypeId={subtypeId} subTypeName={subTypeName} subTypeCode={subtypeCode} getTotalSubtypes={(value) => {
                  if (totalSubtypes) {
                    return totalSubtypes.set(value ?? 0);
                  }
                  return 0;
                }}
                  showVehicleModal={false}
                />
              </Grid>

              <Grid item>
                <LocalityTextField isValid={isValid} isInvalidRequest={isInvalidRequest} locationState={locationState}
                />
              </Grid>
              <Grid item>
                <ReferenceField isValid={isValid} reference={reference}
                />
              </Grid>
              <Grid item>
                <FilterAutocompleteCity
                  disabled={!isValid}
                  onCityChange={(city) => {
                    cityId?.set(city)
                  }}
                  cityId={cityId?.get()}
                />
              </Grid>
              <Grid item>
                <FilterAutocompleteDistrict
                  disabled={!isValid}
                  onDistrictChange={(district) => {
                    districtId?.set(district)
                  }}
                  districtId={districtId}
                  cityId={cityId.get()}
                  getRowsCount={(value) => {
                    if (totalDistricts) {
                      return totalDistricts.set(value ?? 0);
                    }
                    return 0;
                  }}
                  isInvalidRequest={totalDistricts.get() > 0 ? totalDistricts.get() > 0 && districtId.get() === null : false}
                />
              </Grid>

            </Grid>
          ) : false}
          {/* / */}

        </Grid>
      </Grid>

    </Grid>
  );
}
