import React from 'react'
import { useHookstate } from '@hookstate/core';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import CrudCreate from '~/components/CrudCreate';
import Layout from '~/components/Layout';
import { SimpleDialogBox } from '~/components/SimpleDialogBox';
import { useLang } from '~/hooks/useLang';
import IProcedure from '../interfaces/IProcedure';


export default function Create() {
  const { translate } = useLang()
  const history = useHistory();
  const { register, handleSubmit, control, setValue: setFormValue } = useForm<IProcedure>();
  const fields = [
    { name: 'name', type: 'string', required: true },
    { name: 'code', type: 'string', required: true},
    { name: 'description', type: 'longtext', required: true },
  ];

  const openDialog = useHookstate(false);
  const dialogBoxTitle = useHookstate("");
  const dialogBoxContent = useHookstate("");

  const handleDialogBoxOpen = () => {
    openDialog.set(true);
  };

  const handleDialogNext = () => {
    openDialog.set(false);
    history.push(`/procedure`);
  };


  return (
    <Layout marginLayout={true}>
      <CrudCreate 
        model={'procedure'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Procedure successfully created!'}
        title={'Create New Procedure'}
        subheader={'Create New Procedure'}
        setFormValue={setFormValue} />
      <SimpleDialogBox handleClose={handleDialogNext} open={openDialog} title={dialogBoxTitle} content={dialogBoxContent} />
    </Layout>
  )
}