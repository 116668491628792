import React, { useEffect } from 'react';
import { createState, useState, useHookstate } from '@hookstate/core';
import { getMarkersDispatchService } from '~/features/Dispatch/services';
import { getMarkersEntryService } from '~/features/Entry/services';
import { getMarkersDeviceAllService } from '~/features/Device/services';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import useEntryState from '~/features/Entry/stores/EntryState';
import { useMap } from 'react-leaflet';
import { getRelativeDraggablePosition } from '~/utils/screen';
import { latLng, LatLng, LatLngBounds } from 'leaflet';
import { none } from '@hookstate/core'
import { createLiveOperation, getAreaPlanService, getMarkersCameraService, getMarkersInterestPointService } from '~/features/Map/services/request';
import useSystemState from '~/features/System/stores/SystemState';
import { Coordinate } from 'bizcharts';

interface ICreateMarkerProps {
  readonly markerType: any,
  readonly markerIdentifier: any,
  readonly id: any,
  readonly code?: any,
  readonly location?: any,
  readonly position?: any,
  readonly typeName?: any,
  readonly subtypeName?: any,
  readonly status?: any,
  readonly isCurrent?: any,
  readonly identifier?: any,
  readonly speed?: any,
  readonly plate?: any,
  readonly date?: any,
  readonly groupId?: any,
  readonly deviceType?: any,
  readonly dispatchedAt?: any,
  readonly uniqueReference?: boolean,
}

interface IBoundCenterActualStateProps {
  lat: number | null,
  lng: number | null,
}

const markersDefault = {
  occurrence: {},
  entry: {},
  dispatch: {},
  device: {},
  camera: {},
  interestPoint: {},
  prediction: {},
  areas: {},
  dispatchFiltered: {}
}

const zoomDefault = 15

const markersLocal = createState(markersDefault);

const forceViewMapState = createState({
  position: new LatLng(0, 0),
  zoom: zoomDefault
});

const boundCenterActualState = createState<IBoundCenterActualStateProps>({
  lat: null,
  lng: null
})

const boundsMapGlobalState = createState<LatLngBounds | null>(null)

export function useMarkerState() {
  const markersState = useState(markersLocal);
  const forceViewMap = useState(forceViewMapState);
  const boundCenterActual = useState(boundCenterActualState);
  const boundsMapGlobal = useState(boundsMapGlobalState);

  const deleteAllUniqueMarkerFunction = (markerType) => {
    Object.keys(markersState[markerType].get()).map((allMarkerID) => {
      if (markersState[markerType][allMarkerID].get()?.uniqueReference) {
        markersState[markerType][allMarkerID].set(none)
      }
    })
  }

  return ({
    markersState: () => markersState,
    forceViewMap: () => forceViewMap,
    boundCenterActual: () => boundCenterActual,
    boundsMapGlobal: () => boundsMapGlobal,
    createMarker: ({ markerType, markerIdentifier, id, code = null, location, position, typeName = null, subtypeName = null, status, isCurrent = false, identifier = null, speed = null, plate = null, date = null, groupId = null, deviceType = null, dispatchedAt = null }: ICreateMarkerProps) => {
      if (document.getElementById('LeafletMapContainer')) {
        return markersState[markerType].merge({
          [markerIdentifier]: {
            id: id,
            markerId: `${markerType}-${id}`,
            code: code,
            location: location,
            position: position,
            typeName: typeName,
            subtypeName: subtypeName,
            status: status,
            isCurrent: isCurrent,
            identifier: identifier,
            speed: speed,
            plate: plate,
            date: date,
            groupId: groupId,
            deviceType: deviceType,
            dispatchedAt: dispatchedAt,
          }
        });
      }
    },
    updateMarker: ({ markerType, markerIdentifier, attribute, value }) => {
      if (document.getElementById('LeafletMapContainer')) {
        try {
          return markersState[markerType][markerIdentifier] && markersState[markerType][markerIdentifier][attribute] ? markersState[markerType][markerIdentifier][attribute].set(value) : false;
        } catch (error) {
          console.error(error)
          throw error
        }
      }
    },
    deleteMarker: ({ markerType, markerIdentifier }) => {
      if (document.getElementById('LeafletMapContainer')) {
        return markersState[markerType][markerIdentifier] ? markersState[markerType][markerIdentifier].set(none) : false
      }
    },
    getMarker: ({ markerType, markerIdentifier }) => {
      if (document.getElementById('LeafletMapContainer')) {
        try {
          const markerState = markersState[markerType];
          if (!markerState) return null;

          return markerState[markerIdentifier];
        } catch (error) {
          console.error(error)
          throw error
        }
      }
    },
    deleteAllUniqueMarker: (markerType) => deleteAllUniqueMarkerFunction(markerType),
    createUniqueMarker: ({ uniqueReference, markerType, markerIdentifier, id, code = null, location, position, typeName = null, subtypeName = null, status, isCurrent = false, identifier = null, speed = null, plate = null, date = null, groupId = null, deviceType = null, dispatchedAt = null }: ICreateMarkerProps) => {
      if (document.getElementById('LeafletMapContainer')) {
        deleteAllUniqueMarkerFunction(markerType);

        return markersState[markerType].merge({
          [markerIdentifier]: {
            id: id,
            markerId: `${markerType}-${id}`,
            code: code,
            location: location,
            position: position,
            typeName: typeName,
            subtypeName: subtypeName,
            status: status,
            isCurrent: isCurrent,
            identifier: identifier,
            speed: speed,
            plate: plate,
            date: date,
            groupId: groupId,
            deviceType: deviceType,
            dispatchedAt: dispatchedAt,
            uniqueReference: uniqueReference,
          }
        });
      }
    }
  })
}

export const validShowArea = ({ position, boundsMap }) => {
  if (position && boundsMap) {
    if (position?.lng <= boundsMap.getNorthEast().lng && position?.lng >= boundsMap.getSouthWest().lng) {
      if (position?.lat <= boundsMap.getNorthEast().lat && position?.lat >= boundsMap.getSouthWest().lat) {
        return true
      }
    }
  }
  return false
}

export default function useMapState() {
  const map = useMap();
  const { dispatch } = useDispatchState();
  const { entry } = useEntryState();
  const markersState = useMarkerState().markersState();
  // const { forceViewMap, boundCenterActual } = useMarkerState();
  const { forceViewMap, boundCenterActual, boundsMapGlobal } = useMarkerState();
  const boundsMap = useHookstate<LatLngBounds | null>(null);
  const zoomMapActual = useHookstate<number>(0);
  const { windowManager } = useSystemState();

  useEffect(() => {
    if (boundsMap.get()) {
      boundCenterActual().lat.set(map.getCenter().lat);
      boundCenterActual().lng.set(map.getCenter().lng);
    }
  }, [boundsMap.get(), zoomMapActual.get()])


  const setViewMap = ({ position, zoom }) => {
    if (document.getElementById('LeafletMapContainer')) {
      const getBoundByDraggablePosition = ({ xAxis, yAxis, bound }) => {
        if (yAxis === 1) {
          if (xAxis === -1) {
            return new LatLngBounds(bound.getSouthWest(), bound.getCenter()).getCenter();
          }
          if (xAxis === 1) {
            return new LatLngBounds(new LatLng(bound.getSouthWest().lat, bound.getCenter().lng), new LatLng(bound.getCenter().lat, bound.getNorthEast().lng)).getCenter();
          }
        } else {
          if (xAxis === -1) {
            return new LatLngBounds(new LatLng(bound.getCenter().lat, bound.getSouthWest().lng), new LatLng(bound.getNorthEast().lat, bound.getCenter().lng)).getCenter();
          }
          if (xAxis === 1) {
            return new LatLngBounds(bound.getCenter(), bound.getNorthEast()).getCenter();
          }
        }
      };
      const [xAxis, yAxis] = document.querySelector('.react-draggable') ? getRelativeDraggablePosition() : [map.getCenter().lat, map.getCenter().lng];
      const mapUpdatedTemp = map.setView(position, zoom, { animate: false })
      const getRectangleBound = getBoundByDraggablePosition({ xAxis: xAxis, yAxis: yAxis, bound: mapUpdatedTemp.getBounds() });
      return map.setView(getRectangleBound ? getRectangleBound : position, zoom, { animate: true })
    }
  }

  const loadMarkersState = useHookstate({
    entry: () => getMarkersEntryService().then((response) => {
      const entryActual = entry().id.get()
      if (entryActual && response[entryActual]) {
        response[entryActual].isCurrent = true;
        setViewMap({ position: response[entryActual].position, zoom: zoomDefault });
        // boundCenterActual().set({lat: map.getCenter().lat, lng: map.getCenter().lng})
      }

      markersState['entry'].set(response)
    }),
    dispatch: () => getMarkersDispatchService().then((response) => {
      const dispatchActual = dispatch().id.get();
      if (dispatchActual && response[dispatchActual]) {
        response[dispatchActual].isCurrent = true;
        setViewMap({ position: response[dispatchActual].position, zoom: zoomDefault });
        // boundCenterActual().set({lat: map.getCenter().lat, lng: map.getCenter().lng})
      }

      markersState['dispatch'].set(response);
    }),
    device: (abort) => getMarkersDeviceAllService({ abort: abort }).then((response) => {
      if (response) {
        markersState['device'].set(response);
      }
    }),
    camera: () => getMarkersCameraService().then((response) => {
      markersState['camera'].set(response);
    }),
    occurrence: () => {
      console.info('Carregando Ocorrencias')
    },
    interestPoint: () => getMarkersInterestPointService().then((response) => {
      markersState['interestPoint'].set(response)
    }),
    prediction: () => console.info('Carregando Predições'),
    dispatchFiltered: () => console.info('Carregando Predições de Despachos'),
    areas: () => {
      // const result = {}
      // getAreaPlanService().then((data) => {
      //   data.map((area) => {
      //     windowManager()['mapSetting']['markersView']['areas']['children'].merge({
      //       [area.name]: {
      //         title: area.name,
      //         show: false,
      //         filterPropName: 'show',
      //         filterCondition: (show) => {
      //           return false
      //         }
      //       }
      //     })
      //     // eslint-disable-next-line functional/immutable-data
      //     result[area.name] = {
      //       id: area.id,
      //       markerIdentifier: area.name,
      //       name: area.name,
      //       color: area.color,
      //       position: area.areas.length < 1 ?
      //         area.areas[0].polygon.coordinates[0].map((position) => position.map((vertices) => ({vertices: vertices, color: area.areas[0].color })))
      //         : area.areas.map((item) => ({vertices: item.polygon.coordinates[0].map((position) => position.map((vertices) => vertices)), color: item.color})),
      //     }
      //   })
      //   markersState['areas'].merge(result)
      // });
        windowManager()['mapSetting']['markersView']['areas'].merge({ hide: true, show: false })
    }
  })

  useEffect(() => {
    if (forceViewMap().position.get().lng != 0 && forceViewMap().position.get().lat != 0) {
      setViewMap({ position: forceViewMap().position.get(), zoom: forceViewMap().zoom.get() });
      forceViewMap().position.set(new LatLng(0, 0));
      forceViewMap().zoom.set(15)
      boundsMap.set(map.getBounds())
      zoomMapActual.set(map.getZoom())
    }
  }, [forceViewMap().position.get()])

  return ({
    map: () => map,
    setViewMap: setViewMap,
    boundsMap: () => boundsMap,
    zoomMapActual: () => zoomMapActual,
    markers: () => markersState,
    validShowArea: () => validShowArea,
    loadMarkersState: (markerType, abort, latitude, longitude) => {
      const markers = loadMarkersState.value;
      return markers[markerType](abort, latitude, longitude)
    },
  })
}
