import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import InputDateTime from '../InputDate';

interface PeriodProps {
    readonly onDateChange: (value: Dayjs | null) => void;
    readonly disabled?: boolean;
    readonly label?: string
    readonly date?: string | null;
    readonly required?: boolean;
}

export default function FilterSelectMoment({ disabled, label = 'Moment of date', onDateChange, date, required = false }: PeriodProps) {
    const { translate } = useLang();
    const [dateSelected, setDateSelected] = useState<Dayjs | null>(null);
    

    useEffect(() => {
        onDateChange(dateSelected)
    }, [dateSelected])

    useEffect(() => {
        if(!date){
            setDateSelected(null)
        }
    }, [date])

    return (
            
            <Grid item>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={'pt-br'}>
                    <InputDateTime
                        disabled={disabled}
                        inputFormat='DD/MM/YYYY HH:mm'
                        placeholder={translate('dd/mm/yyyy')}
                        dateTime={dateSelected}
                        onDateTimeChange={(date) => {
                            setDateSelected(date)
                        }}
                    label={translate(label)}
                    required={required}
                    />
                </LocalizationProvider>
            </Grid>
    )
}