import IMessage from '../interfaces/IMessage';

export function parseUserDataMessage(dataMessage): IMessage {
  return {
    id: dataMessage.id,
    sender: {
      id: dataMessage.sender_user.id,
      name: dataMessage.sender_user.name,
      representationName: dataMessage.sender_user.representation_name
    },
    receiver: {
      id: dataMessage.receiver_user.id,
      name: dataMessage.receiver_user.name,
      representationName: dataMessage.receiver_user.representation_name
    },
    messageDate: dataMessage.created_at,
    message: dataMessage.message,
    readAt: dataMessage.read_at,
    readUser: dataMessage.read_user,
  }
}

export function parseUserDataMessages(dataMessages:readonly unknown[]): readonly IMessage []  {
  const parsedMessages = dataMessages.map(( dataMessage ) => parseUserDataMessage( dataMessage ));
  const filtered = parsedMessages.filter( (element) => { if(element) return element; } ) as readonly IMessage[];

  return filtered; 
}