import React, { useEffect } from 'react';
import ReactDOM from 'react-dom'
import ViewListIcon from '@mui/icons-material/ViewList';
import StandardModal from '~/components/StandardModal';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';
import OccurrenceList from '~/features/Occurrence/components/OccurrenceList';
import { authFetch } from '~/services/fetch';
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable';
import { useHookstate } from '@hookstate/core';
import { useLocation } from 'react-router';
import { storeScreenSettings } from '~/features/System/services';

const modal = document.getElementById('div-modal') as HTMLElement;

export default function OccurrenceListModalDraggable() {
  const { translate } = useLang();
  const { windowManager, systemConfig, loadingButton } = useSystemState();

  const handleStop = (event, dragElement) => {
    event.stopPropagation();

    windowManager()['occurrenceList'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y
    })
  };
  
  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'OccurrenceListModalDraggable',
        isOpen: windowManager()['occurrenceList'].open.get(),
        isMinimized: windowManager()['occurrenceList'].minimized.get(),
        positionX: windowManager()['occurrenceList'].positionX.get(),
        positionY: windowManager()['occurrenceList'].positionY.get()
      })
    }
  }, [
    windowManager()['occurrenceList'].open.get(),
    windowManager()['occurrenceList'].minimized.get(),
    windowManager()['occurrenceList'].positionY.get(),
    windowManager()['occurrenceList'].positionX.get()
  ])

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        !loadingButton.get() &&
        <StandardModal
          title={`${translate('Occurrences')}`}
          subheader={`${translate('Last occurrences hours', { hours: systemConfig().occurrenceSearchPeriodInHours.get() })}`}
          avatar={ViewListIcon}
          dimensions={{
            width: 800,
          }}
          handleStop={handleStop}
            isExpanded={windowManager()['occurrenceList'].minimized}
          position={{
            x: windowManager()['occurrenceList'].positionX?.get() ? Number(windowManager()['occurrenceList'].positionX.get()) : 600,
            y: windowManager()['occurrenceList'].positionY?.get() ? Number(windowManager()['occurrenceList'].positionY.get()) : 100
          }}
          closeButton={!(windowManager()['occurrenceList'].disabled.value) ? () => windowManager()['occurrenceList'].open.set(false) : false}
        >
          <OccurrenceList />

        </StandardModal>
      }
    </>
    , modal);
}