/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';

interface ProcedureConfigRelatedProps {
    readonly onProcedureConfigRelatedChange: (value: number | null) => void;
    readonly procedureConfigRelatedId?: number | null;
    readonly disabled?: boolean
}

interface ProcedureConfigRelatedResponde {
    readonly id: number,
    name: string
}

export default function FilterAutocompleteProcedureConfigRelated({ disabled, onProcedureConfigRelatedChange, procedureConfigRelatedId }: ProcedureConfigRelatedProps) {
    const { translate } = useLang();
    const options = [{id: 0, name: 'Atendente'}, {id: 1, name: 'Despachante'}, {id: 2, name: 'Viatura'}]
    const [value, setValue] = useState<ProcedureConfigRelatedResponde | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        if (!procedureConfigRelatedId) {
            setValue(null)
        } else {
            setValue(options.find(opt => opt.id === procedureConfigRelatedId) ?? null)
        }
    }, [procedureConfigRelatedId])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                id='filter-related-to'
                limitTags={2}
                disabled={disabled}
                sx={{ height: 2 }}
                options={options}
                value={value}
                noOptionsText={translate('No options')}
                clearText={translate('Clear')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={(_, type) => {
                    setValue(type)
                    onProcedureConfigRelatedChange(type?.id ?? null)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Visible To')}
                    />
                )}
            />
        </FormControl>
    )
}