import React, { useEffect } from 'react';
import Layout from '~/components/Layout';
import Map from '../../Map/components/Map';
import MapMarkersEvents from '~/features/Map/components/MapMarkers/MapMarkersEvents';
import useSystemState from '~/features/System/stores/SystemState';
import MapHandlePopUp from '~/features/Map/components/MapHandlers';
import useUserState from '~/features/User/stores/UserState';
import AreaPlans from '~/features/Map/components/AreaPlans';
import { useLang } from '~/hooks/useLang';


export default function Index() {
  const { windowDefault, windowIsNotVisible, windowReadOnly, loadDraggables } = useSystemState();
  const { verifyPermission } = useUserState()
  const { translate } = useLang();

  useEffect(() => {
    document.title = translate('Entry - Dispatch System')
  }, [])


  useEffect(() => {
    loadDraggables([
      'DispatchFormModalDraggable',
      'SearchStaffModalDraggable',
      'VideoMonitoringModalDraggable',
      'VideoMonitoringCardsDraggable',
      'RegistrationModalDraggable',
      'DispatchUnificationModalDraggable',
      'DispatchDisunificationModalDraggable',
    ])
    windowDefault(['entry']);
    windowIsNotVisible([
      'dispatch',
      'occurrence',
      'searchStaff',
      'videoMonitoring',
      'entryRecall',
      'EntryLogModal',
      'VehicleModal',
      'videoMonitoringCards',
      'registration',
      'DispatchDisunification',
      'DispatchUnification',
      'MessageModal',
      'entryProcedure',
      'dispatchProcedure',
      'dispatchesResultSearches',
      'searchWeapon',
      'mapFilters',
      'mapHistoricFilters',
    ]);
    verifyPermission(['entry_read_only']) ? windowReadOnly(['entry']) : false;
  },
    [])


  return (
    <Layout mapViewPort marginLayout={false} draggableComponents={true}>
      <Map>
        <MapHandlePopUp readonly={verifyPermission(['entry_read_only'])} />
        <MapMarkersEvents viewGroups={['occurrence', 'entry', 'areas']} checkedViewGroups={['entry', 'occurrence']} nearMarker={true} />
      </Map>
    </Layout>
  );
}

