import React, { useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Divider, Grid, Box, LinearProgress } from '@mui/material';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator, timelineOppositeContentClasses } from '@mui/lab';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import dayjs from 'dayjs';
import { authFetch } from '~/services/fetch';
import { useHookstate } from '@hookstate/core';


export default function ChronologyDeviceLastDispatchDialog({ id, open }) {
  const { translate } = useLang();
  const { getStatusColors } = useSystemState();
  const { syncDispatchFromId } = useDispatchState();
  const dispatches = useHookstate([]);
  const loading = useHookstate(false);
  const loadingGetLastDispatches = useHookstate(false);


  useEffect(() => {
    loadingGetLastDispatches.set(true)
    if (id && open.get()) {
      authFetch({
        url: `/device/last-dispatches`,
        method: 'POST',
        data: {
          device_id: id,
          limit: 5
        }
      }).then((response) => {
        dispatches.set(response.data);
      }).finally(() => {
        loadingGetLastDispatches.set(false)
      })
    }
  }, [id, open.get()])

  const handleClickAction = (id) => {
    loading.set(true)
    syncDispatchFromId(id).finally(() => {
      loading.set(false)
    })
  }

  const onCloseDialog = () => {
    open.set(false)
    dispatches.set([])
  }


  return (
    <Dialog open={open.get()} onClose={() => onCloseDialog()} maxWidth={'md'}>
      <DialogTitle sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginLeft: 2
      }}>
        {translate('Últimos despachos do dispositivo')}
      </DialogTitle>
      <DialogContent>
        <Timeline sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.2,
          },
        }}>
          {dispatches.get().length ? dispatches.get().map((dispatch, index) => {
            return (
              <TimelineItem key={dispatch['id']}>
                <TimelineOppositeContent color="text.secondary">
                  <Typography variant='body1'>
                    {dayjs(dispatch['dispatched_at']).format('DD/MM/YYYY')}
                  </Typography>
                  <Typography variant='body1'>
                    {dayjs(dispatch['dispatched_at']).format('HH:mm:ss')}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    sx={{
                      backgroundColor: getStatusColors(dispatch['status']['id']).main, color: getStatusColors(dispatch['status']['id']).contrastText,
                      '&:hover': { backgroundColor: getStatusColors(dispatch['status']['id']).dark, color: getStatusColors(dispatch['status']['id']).contrastText }
                    }}
                  />
                  {index !== (dispatches.get().length - 1) &&
                    <TimelineConnector />
                  }
                </TimelineSeparator>
                <TimelineContent>

                  <Button type="submit" variant="contained"
                    onClick={() => handleClickAction(dispatch['id'])}
                    sx={{
                      backgroundColor: getStatusColors(dispatch['status']['id']).main,
                      color: getStatusColors(dispatch['status']['id']).contrastText,
                      '&:hover': {
                        backgroundColor: getStatusColors(dispatch['status']['id']).dark,
                        color: getStatusColors(dispatch['status']['id']).contrastText,
                      },
                    }}>
                    {dispatch['code']}
                  </Button>
                </TimelineContent>
                <Divider />
              </TimelineItem>
            )
          }) : !dispatches.get().length && loadingGetLastDispatches.get() ?
            <LinearProgress /> :
            <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography>
                {translate('Vehicle never engaged')}
              </Typography>
            </Box>
          }
        </Timeline>
      </DialogContent>
    </Dialog>
  )
}