/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo } from 'react';
import CrudIndex from '~/components/CrudIndex';
import { ActionButtonsProps } from '~/components/CrudIndex/interfaces/ICrudIndex';
import { MoveDown, GpsFixed, KeyOff, Key } from '@mui/icons-material';
import { useLang } from '~/hooks/useLang';
import { authFetch } from '~/services/fetch';
import { DeviceTransferDialog } from '~/features/Device/components/DeviceTransferDialog';
import { useHookstate } from '@hookstate/core';
import Layout from '~/components/Layout';
import { DeviceEditFieldsDialog } from '../components/DeviceEditFieldsDialog';
import ConfirmDialog from '~/components/ConfirmDialog';
import notify from '~/utils/notify';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';

export interface CurrentDeviceDialog {
  readonly isOpenEditField?: boolean;
  readonly isOpen: boolean;
  readonly currentDeviceId: number | null;
  readonly currentDeviceName: string | null;
  readonly imei?: string | null;
  readonly trackingId?: string | null;
  readonly lock_temporary_tracking?: boolean;
}

export default function Index() {
  const { translate } = useLang();

  const forceRefresh = useHookstate(false);
  const lockFields = useHookstate<boolean>(false);
  const loading = useHookstate<boolean>(false);
  const agencyId = useHookstate<number | null>(null);
  const device = useHookstate<string | null>(null)
  const tracking = useHookstate<string | null>(null)
  const dispatchGroupId = useHookstate<number | null>(null);
  const deviceTypeId = useHookstate<number | null>(null)
  const active = useHookstate<boolean | null>(null);

  const isEnabled = useHookstate(false)
  const deviceName = useHookstate('')
  const openDialogConfirmDisabled = useHookstate(false);
  const deviceActionId = useHookstate<number | null>(null)
  const operationUnitId = useHookstate<number | null>(null)

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])

  const currentDeviceDialog = useHookstate<CurrentDeviceDialog>({
    isOpen: false,
    isOpenEditField: false,
    currentDeviceId: null,
    currentDeviceName: null,
    imei: null,
    trackingId: null,
    lock_temporary_tracking: true
  });

  const deviceFilter = [
    {
      type: 'name',
      placeholder: 'Name',
      variable: device
    },
    {
      type: 'tracking_id',
      placeholder: 'Tracking',
      variable: tracking
    },
    {
      type: 'device_type_id',
      variable: deviceTypeId
    },
    {
      type: 'agency_id',
      variable: agencyId
    },
    {
      type: 'dispatch_group_id',
      variable: dispatchGroupId,
      dependent: agencyId
    },
    {
      type: 'operation_unit_id',
      variable: operationUnitId
    },
    {
      type: 'active',
      variable: active
    },

  ]

  const disabledUser = (deviceId) => {
    loading.set(true)
    authFetch({
      url: `/device/${deviceId}/activate-deactivate`,
      method: 'POST'
    }).then((response) => {
      if (response.data.active) {
        notify({ message: translate('activated with success!', { crudName: 'Dispositivo' }), type: 'success' })
      } else {
        notify({ message: translate('deactivated with success!', { crudName: 'Dispositivo' }), type: 'success' })
      }
    }).finally(() => {
      openDialogConfirmDisabled.set(false)
      forceRefresh.set(!forceRefresh.get())
      loading.set(false)
    }).catch((error) => {
      console.log(error)
    })
  }

  const handleDialogConfirmDisabled = () => {
    openDialogConfirmDisabled.set(false)
  };

  const handleDialogConfirmOpenDisabled = (userId) => {
    openDialogConfirmDisabled.set(true)
    deviceActionId.set(userId)
  };

  // eslint-disable-next-line functional/prefer-readonly-type
  const extraDeviceActions = useMemo((): ActionButtonsProps[] => {
    return [
      {
        iconButton: (params) => Boolean(params['active'] === 'Sim') ? <ToggleOnIcon /> : < ToggleOffOutlinedIcon />,
        label: (params) => Boolean(params['active'] === 'Sim') ? translate('Deactivate device') : translate('Active device'),
        action: (params) => {
          handleDialogConfirmOpenDisabled(params.id)

          Boolean(params['active'] === 'Sim') ? isEnabled.set(true) : isEnabled.set(false)
          deviceName.set(params['name'])
        },
        showInMenu: function () {
          return false
        },
      },
      {
        action: function (row) {
          const temp = {
            deviceId: row['id'] as number | undefined,
            deviceName: row['name'] as string | undefined,
          }
          if (!temp.deviceId || !temp.deviceName) return;
          currentDeviceDialog.set({
            isOpen: true,
            currentDeviceId: temp.deviceId,
            currentDeviceName: temp.deviceName
          });
        },
        label: translate('Transfer'),
        iconButton: <MoveDown />
      },
      {
        action: function (row) {
          const temp = {
            deviceId: row['id'] as number | undefined,
            deviceName: row['name'] as string | undefined,
            imei: row['imei'] as string | undefined,
            trackingId: row['tracking_id'] as string | undefined,
          }
          if (!temp.deviceId || !temp.deviceName) return;
          currentDeviceDialog.set({
            isOpenEditField: true,
            isOpen: false,
            currentDeviceId: temp.deviceId,
            currentDeviceName: temp.deviceName,
            imei: temp.imei,
            trackingId: temp.trackingId,
          });
        },
        label: translate('Edit IMEI or tracking'),
        iconButton: <GpsFixed />,
        showInMenu: function (params) {
          return params['is_connected'] === 1 ? true : false
        },
      }
    ]
  }, []);
  return (
    <Layout marginLayout={true}>
      <CrudIndex
        model='device'
        title='Devices'
        exceptColumns={['agency_id', 'is_using_temp_tracking', 'device_status_id', 'dispatch_group_id', 'is_connected', 'identification', 'acronym', 'device_type_id', 'operation_unit_id']}
        aliasLabel={[{ column: 'device_type:name', label: 'Device Type' }, {column: 'operation_unit:name', label: 'Operation Unit'}]}
        stringField={['tracking_id']}
        booleanField={['lock_temporary_tracking', 'active', 'is_connected']}
        extraActions={extraDeviceActions}
        forceOrderColumns={['name', 'imei', 'tracking_id', 'device_type:name', 'agency:name', 'dispatchGroup:name', 'operation_unit:name', 'active']}
        createTitleComplement={'device'}
        deleteable={false}
        loading={loading}
        lockFields={lockFields.get()}
        clearFilters={() => {
          device.set('')
          tracking.set('')
          agencyId.set(null)
          dispatchGroupId.set(null)
          deviceTypeId.set(null)
          active.set(null)
          operationUnitId.set(null)

          forceRefresh.set(!forceRefresh.get())
        }}
        filters={deviceFilter}
        searchButton={() => forceRefresh.set(!forceRefresh.get())}
        forceRefresh={forceRefresh.get()}
        disableColumnsFilters={true}
        canView={true}
        showInViewMore={['name',
          'brand',
          'model',
          'imei',
          'tracking_id',
          'operation_unit:name',
          'agency:name',
          'dispatch_group:name',
          'vehicle:plate',
          'device_type:name',
          'lock_temporary_tracking',
          'is_connected',
          'active',
          'created_at'
        ]}
      />
      {currentDeviceDialog.isOpen.get() &&
        <DeviceTransferDialog
          title={translate('Transfer Device')}
          deviceId={currentDeviceDialog.currentDeviceId.get()}
          deviceName={currentDeviceDialog.currentDeviceName.get()}
          isOpen={currentDeviceDialog.isOpen}
          fields={[
            { name: translate('To Be Return At'), model: 'to_be_returned_at', type: 'date' },
            { name: translate('Destiny Name'), model: 'destiny_name' },
            { name: translate('Destiny Group'), model: 'dest_dispatch_group_id', type: 'select', required: true }
          ]}
        />
      }

      {currentDeviceDialog.isOpenEditField.get() &&
        <DeviceEditFieldsDialog
          currentDevice={currentDeviceDialog}
          isOpen={currentDeviceDialog.isOpenEditField}
          forceRefresh={forceRefresh}
        />
      }
      <ConfirmDialog
        handleAgree={() => disabledUser(deviceActionId.get())}
        handleClose={() => handleDialogConfirmDisabled()}
        open={openDialogConfirmDisabled}
        title={translate('Confirmation')}
        content={isEnabled.get() ? translate('Confirm the deactivation of the?', { crudName: 'dispositivo', name: deviceName.get() }) : translate('Confirm the activation?', { crudName: 'dispositivo', name: deviceName.get() })}
        loading={loading}
      />
    </Layout>

  )
}