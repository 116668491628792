import React, { useEffect } from 'react';
import Map from '~/features/Map/components/Map';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';

export default function Index() {
  const { translate } = useLang();

  useEffect(() => {
    document.title = translate('Tactical - Dispatch System')
  }, [])
  
  return (
    <Layout marginLayout={false} sidebar>
      <Map>
      </Map>
    </Layout>
  )
}
