import React from 'react';
import { Button, Collapse, Grid, Card, List, ListItemButton, ListItemText, Box, Typography, Tooltip } from '@mui/material';
import IconAdministration from '~/components/IconsSvg/administration';
import { useLang } from '~/hooks/useLang';
import {
  ExpandLess,
  ExpandMore,
  StarBorder,
} from '@mui/icons-material';

import { useHistory } from 'react-router-dom';
import useUserState from '~/features/User/stores/UserState';
import { MenuProps } from '~/components/SidebarMenu/interfaces';

export default function ObjectItem({ handleMenuClick }: MenuProps) {
  const { translate } = useLang();
  const { verifyPermission } = useUserState();
  const [openObjectItem, setOpenObjectItem] = React.useState(false);

  const history = useHistory();

  const handleClickObjectItem = () => {
    setOpenObjectItem(!openObjectItem);
  }



  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {verifyPermission(['object_type_index']) && (
        <>
          <Tooltip title={translate('Objects options')} placement="right">
            <ListItemButton sx={{ pl: 4 }} onClick={handleClickObjectItem}>
              <ListItemText primary={translate('Objects')} />
              {openObjectItem ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </Tooltip>
          <Collapse in={openObjectItem} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

              {verifyPermission(['object_type_index']) && (
                <Tooltip title={`${translate('Create, read, update and delete')}` + ' ' + `${translate('Object Type')}`} placement="right">
                  <ListItemButton sx={{ pl: 8 }} onClick={() => {
                    handleMenuClick('administration')
                    history.push("/object-type")
                  }}>
                    <ListItemText primary={translate('Object Type')} />
                  </ListItemButton>
                </Tooltip>
              )}

            </List>
          </Collapse>
        </>
      )}
    </>
  );
}
