import React, { ChangeEvent } from "react"
import { State, useHookstate } from "@hookstate/core";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useLang } from "~/hooks/useLang"
import useDispatchState from "~/features/Dispatch/stores/DispatchState";
interface IErros {
  readonly comment: string;
}

interface EntryCreateCloseOccurrenceDialogProps {
  readonly open: State<boolean>;
  readonly entryOpenCloseOccurrence?: (params: any) => Promise<any>;
  readonly dispatchesCodesGenerated: State<string[]>;
  readonly openDialogCodes: State<boolean>
}

// eslint-disable-next-line max-lines-per-function
export default function EntryCreateCloseOccurrenceDialog({ open, openDialogCodes, dispatchesCodesGenerated, entryOpenCloseOccurrence }: EntryCreateCloseOccurrenceDialogProps) {
  const { translate } = useLang();
  const comment = useHookstate('');
  const loading = useHookstate(false);
  const defaultErros = { comment: '' }
  const errors = useHookstate<IErros>(defaultErros);

  const action = async () => {
    loading.set(true)
    if (entryOpenCloseOccurrence && !hasRequiredFields()) {
      const response = await entryOpenCloseOccurrence({ comment: comment.get() }).finally(() => {
        loading.set(false)
      })
      if (response) {
        response.dispatches.map((dispatch) => dispatchesCodesGenerated.merge([dispatch.code]))
        open.set(false);
      }
      openModalCodes()
      clearStates();
    }
  };

  const openModalCodes = () => {
    openDialogCodes.set(true)
  }

  const hasRequiredFields = () => {
    if (!comment.get() || comment.get().length < 5) {
      errors.comment.set(translate('Enter a comment with a minimum of 5 characters'))
      loading.set(false)
      return true;
    } else {
      return false
    }
  }

  const clearStates = () => {
    comment.set('');
  }

  const handleClose = () => {
    clearStates();
    open.set(false);
  };

  const clickOutModalClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      open.set(false);
    }
  };

  return (
    <Dialog
      open={open.get()}
      onClose={clickOutModalClose}
      disableEscapeKeyDown={true}
      fullWidth
      maxWidth='md'
      aria-labelledby="entry-create-close-dialog-title"
      aria-describedby="entry-create-close-dialog-description"
    >
      <DialogTitle>
        {translate('Generate occurrence closed')}
      </DialogTitle>
      <DialogContent>
        <Typography>
          {translate('Closed comment')}
        </Typography>

        <TextField
          multiline
          rows={10}
          error={!!errors.comment.get()}
          required={true}
          name={'comment'}
          id='comment'
          label={translate('Comment')}
          fullWidth
          size='small'
          value={!comment.get() ? '' : comment.get()}
          sx={{ mt: 1 }}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            event.target.value.length >= 5 && errors.comment.set('')
            comment.set(event.target.value)
          }}
        />

      </DialogContent>
      <DialogActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingX: "24px" }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant='outlined'
            onClick={handleClose}>
            {translate('Cancel')}
          </Button>
          <LoadingButton
            loading={loading.get()}
            variant='contained'
            onClick={() => { action() }}>
            {translate('Submit')}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>

  )
}