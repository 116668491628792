import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IEntryOrigin from '../interfaces/IEntryOrigin';
import Layout from '~/components/Layout';


export default function Create() {     
  const { register, handleSubmit, control, setValue:setFormValue } = useForm<IEntryOrigin>();
  const fields = [
    { name: 'name', type: 'string'},
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate model={'entry-origin'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        messageSuccess={'Origin successfully created!'}  
        title={'Add origin'}  
        subheader={'Add origin'}
        setFormValue={setFormValue} />
      </Layout>
    )
}
