import React, { useCallback, useEffect, useState } from 'react';
import useDispatchRegistrationState from '../../stores/DispatchRegistrationState';
import useDispatchState from '../../stores/DispatchState';
import ListRegistrations from '../DispatchRegistrationTab/components/ListRegistrations';
import { useLang } from '~/hooks/useLang';
import { GridActionsCellItem, GridValueGetter, GridColDef } from '@mui/x-data-grid';
import { truncate } from '../../utils/stringsManipulations';
import useDispatchRegistrationDrugState, { drugDefaultState } from '../../stores/DispatchRegistrationDrugState';
import DrugForm from './components/DrugForm';
import { Tooltip } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { saveLogVisualizeQualificators } from '../../services';
import useSocket from '~/hooks/useSocket';
import { IDrugDTO, IRawDrug } from '../../interfaces/IDispacthRegistrationDrug';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import notify from '~/utils/notify';
import { toDomain } from '~/mappers/registration-drug-mapper';

interface IDrug {
  readonly handleClickOpenConfirmAction: (actionName: string) => void;
}


interface IMessageDrug {
  readonly content: IRawDrug
  readonly event_type: string
}


// eslint-disable-next-line max-lines-per-function
export default function Drug({ handleClickOpenConfirmAction }: IDrug) {
  const { translate } = useLang();
  const { socket } = useSocket();

  const { dispatch } = useDispatchState();
  const { occurrence, status, id: dispatchIdState } = dispatch();
  const { id: occurrenceIdState, } = occurrence;

  const {
    registrationDispatch,
  } = useDispatchRegistrationState();
  const {
    addRegistration,
    loadingRegistrations,
    registrationRowId,
    deleteRegistration
  } = registrationDispatch();

  const { registrationDrugDispatch, getAllAssociatedDrug, deleteDrug } = useDispatchRegistrationDrugState();
  const { drugs, drug } = registrationDrugDispatch();
  const [rows, setRows] = useState<readonly IDrugDTO[]>([])

  const columns = ([
    {
      field: 'id',
    },
    {
      field: 'drugType',
      headerName: translate('Drug Type'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'circunstance',
      headerName: translate('Circunstance'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'destination',
      headerName: translate('Destination'),
      flex: 1,
      valueFormatter: (row) => {
        return row ? row['name'] : '---';
      }
    },
    {
      field: 'notes',
      headerName: translate('Note'),
      flex: 1,
      renderCell: (params) => {
        if (!params.value) {
          return <p>
            ----
          </p>
        }
        return <p>
          {truncate(params.value, 49)}
        </p>
      }
    },
    {
      field: 'actions', type: 'actions', flex: 0.5,
      getActions: (params) => [
        status.value.id === 8 ? <Tooltip key={2} title={translate('View Record')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<RemoveRedEyeIcon />}
              onClick={() => {
                saveLogVisualizeQualificators(params.row.id, '/occurrence/associated-drugs/generate-view-timeline').then((response) => {
                  if (response.status === 200) {
                    drug.set(params.row);
                    addRegistration.set(true)
                  }
                })
              }}
              label="Print" />
          </span>
        </Tooltip> : <></>,
        status.value.id !== 8 ? <Tooltip key={2} title={translate('Edit Record')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<EditIcon />}
              onClick={() => {
                drug.set(params.row);
                addRegistration.set(true)
              }}
              label="Print" />
          </span>
        </Tooltip> : <></>,
        status.value.id !== 8 ? <Tooltip key={2} title={translate('Delete Record')}>
          <span>
            <GridActionsCellItem
              key={params.id}
              icon={<DeleteIcon />}
              onClick={() => {
                registrationRowId.set(params.row.id);
                handleClickOpenConfirmAction('delete')
              }}
              label="Print" />
          </span>
        </Tooltip> : <></>,
      ]
    },
  ])

  const handleAdd = () => {
    addRegistration.set(true)
    drug.set({
      ...drugDefaultState,
      occurrenceId: occurrenceIdState.get()
    })
  }

  const handleDelete = useCallback(async (id: string) => {
    loadingRegistrations.set(true)
    await deleteDrug(id)
    notify({ message: translate('Successfully Deleted'), type: 'success' })
    loadingRegistrations.set(false)
    deleteRegistration.set(false)
  }, [deleteRegistration, deleteDrug, loadingRegistrations, translate])

  useEffect(() => {
    if (deleteRegistration.get()) {
      handleDelete(registrationRowId.get())
    }
  }, [deleteRegistration, handleDelete, registrationRowId])

  useEffect(() => {
    loadingRegistrations.set(true);
    getAllAssociatedDrug(occurrenceIdState.get()).then((res) => {
      setRows(res)
      drugs.set(res)
    }).finally(() => {
      loadingRegistrations.set(false)
    });
  }, []);

  const newMessage = useCallback((message: IMessageDrug) => {
    if (message.event_type === 'OCCURRENCE_ASSOCIATED_DRUG_CREATE') {
      if (message.content.dispatch_id !== dispatchIdState.get()) {
        return
      }
      setRows(prev => [...prev, toDomain(message.content)])
    }
    if (message.event_type === 'OCCURRENCE_ASSOCIATED_DRUG_DELETE') {
      const filterRows = rows.filter(row => row.id !== message.content.id)
      setRows(filterRows);
    }
    if (message.event_type === 'OCCURRENCE_ASSOCIATED_DRUG_CHANGE') {
      const updateRows = rows.map(row => {
        if (row.id === message.content.id) {
          return toDomain(message.content)
        } else {
          return row
        }
      })
      setRows(updateRows);
    }
  }, [rows])

  useEffect(() => {
    socket.on('occurrences', newMessage)
    return () => {
      socket.off('occurrences', newMessage)
    }
  }, [newMessage, socket])

  return (
    addRegistration.get() ? (
      <DrugForm
        handleClickOpenConfirmAction={handleClickOpenConfirmAction}
      />
    ) : (
      <ListRegistrations
        handleAdd={handleAdd}
        rows={rows}
        columns={columns as GridColDef[]}
      />
    )
  )
};