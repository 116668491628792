import React, {useState, useEffect} from 'react';
import SelectBasic from '~/components/SelectBasic';
import { getEntryOrigins } from '~/features/EntryOrigin/services/index';
import { Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';

type EntryOriginProps = {
  readonly readonly: boolean;
  readonly id: string | number;
  readonly name: string;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at: string | null;
}

export default function EntryOriginSelect({readonly=false, isValid, isInvalidRequest, originId, originStation, hasValueDefault=true}) {
  const [option, setOption] = useState([]);
  const { translate } = useLang();

  useEffect(() => {
    getEntryOrigins().then((values) => {
      setOption(values);
    })
  },[]);

  useEffect(() => {
    setTimeout(() => {
      if(!originId.get() && hasValueDefault) {
        const defaultOptionSelect = option.find((value: EntryOriginProps) => value.name.toLowerCase() == (originStation.length > 0 ? originStation.toLowerCase() : 'telefone'));
        if(defaultOptionSelect) {
          originId.set(defaultOptionSelect['id'] ? defaultOptionSelect['id'] : '');
        }
      }
    }, 1000);
  }, [originId, option]);

  if (!readonly) {
    return (
      <SelectBasic 
        state = {originId}
        options = {option}
        placeholder={translate('Entry Origin')}
        disabled={!isValid}
        size={'small'}  
        isInvalidRequest={isInvalidRequest}/>
    );
  } else {
    const optionApresentation = option.find((value: EntryOriginProps) => value?.id == originId.get())
    return (
      <Typography>
{translate('Entry Origin')}
:
{' '}
{ optionApresentation ? optionApresentation['name'] : translate('No information')}
</Typography>
    )
  }
};
