/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Grid, IconButton, Typography } from '@mui/material'
import { StateMethods, useHookstate } from '@hookstate/core'
import CloseIcon from '@mui/icons-material/Close';
import { useLang } from '~/hooks/useLang'
import ReactQuill from 'react-quill'
import { useTheme } from '~/hooks/useTheme'
import { getProcedureConfigById } from '../../services'
import IProcedureConfig from '../../interfaces/IProcedureConfig'

export interface ViewProcedureConfigProps {
  readonly open: StateMethods<boolean>
  readonly idProcedureConfig: number | null
  readonly handleClose: () => void
}

export default function ViewProcedureConfig({ open, idProcedureConfig, handleClose}: ViewProcedureConfigProps) {
    const { translate } = useLang()
    const infoProcedureConfig = useHookstate<IProcedureConfig | undefined>(undefined)
    const { darkMode } = useTheme();
    const relatedTo = infoProcedureConfig.get()?.related_to


    useEffect(() => {
        if(idProcedureConfig != null){
            getProcedureConfigById(idProcedureConfig).then((data) => {
                infoProcedureConfig.set(data)
            })
        }else{
            infoProcedureConfig.set(undefined)
        }
    }, [idProcedureConfig])



    const clickOutModalClose = (event, reason) => {
        if (reason !== 'backdropClick') {
          open.set(false)
        }
      };
      

    const labelRelated = {0: 'Atendente', 1: 'Despachante', 2: 'Viatura'}

    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={open.get()}
            onClose={clickOutModalClose}
            disableEscapeKeyDown={true}
        >
            <DialogTitle display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                {translate('Procedure Configuration')}
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButton>
                </DialogTitle>
            <DialogContent>
                <Grid container xs={12}>
                { infoProcedureConfig.get() && (
                    <>
                    <Grid container xs={12} paddingBottom={2} direction={'row'}>
                        <Grid item xs={3}>
                            <Typography variant='subtitle1' fontWeight={'bold'}>
                                {translate('Type')}
                                :
                            </Typography>
                            <Typography variant='subtitle2'>
                                {infoProcedureConfig.get()?.type.name ? infoProcedureConfig.get()?.type.name : translate('Uninformed')}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant='subtitle1' fontWeight={'bold'}>
                                {translate('Subtype')}
                                :
                            </Typography>
                            <Typography variant='subtitle2'>
                                {infoProcedureConfig.get()?.subtype ? infoProcedureConfig.get()?.subtype.name : translate('Uninformed')}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant='subtitle1' fontWeight={'bold'}>
                                {translate('Visible To')}
                                :
                            </Typography>
                            <Typography variant='subtitle2'>
                                {
                                labelRelated[typeof relatedTo == 'number' ? relatedTo : -1 ] ? 
                                labelRelated[typeof relatedTo == 'number' ? relatedTo : -1 ] 
                                : 
                                translate('Uninformed')
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant='subtitle1' fontWeight={'bold'}>
                                {translate('Procedure')}
                                :
                            </Typography>
                            <Typography variant='subtitle2'>
                                {infoProcedureConfig.get()?.procedure.name ? infoProcedureConfig.get()?.procedure.name : translate('Uninformed')}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant='subtitle1' fontWeight={'bold'}>
                                {translate('Procedure Code')}
                                :
                            </Typography>
                            <Typography variant='subtitle2'>
                                {infoProcedureConfig.get()?.procedure.code ? infoProcedureConfig.get()?.procedure.code : translate('Uninformed')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{mt: 2}}>
                            <Typography variant='subtitle1' fontWeight={'bold'}>
                                {translate('Procedure Description')}
                                :
                            </Typography>
                            <ReactQuill
                                value={infoProcedureConfig.get()?.procedure.description ? infoProcedureConfig.get()?.procedure.description : translate('Uninformed')}
                                readOnly={true}
                                style={{
                                    height: '90%',
                                    color: darkMode ? '#fffe' : '#000'
                                }} 
                                modules={{
                                    toolbar: false
                                }}
                            />
                        </Grid>
                    </Grid>
                    </>
                        )
                }
                </Grid>      
            </DialogContent>
        </Dialog>
    )
}