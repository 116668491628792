import React, { useEffect } from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { State, useHookstate } from '@hookstate/core';
import { getOccurrenceById } from '~/features/Occurrence/services';
import dayjs from 'dayjs';
import IOccurrence from '~/features/Occurrence/interfaces/IOccurrence';
import { parseDataOccurrence } from '~/features/Occurrence/utils/parsers';
import ButtonReloadInfoForClerk from '../ButtonReloadInfoForClerk';

interface ObserverOccurrenceForClerkProps {
  readonly occurrenceId: number | string;
  readonly reloadInfo: State<boolean>;
  readonly loading: State<boolean>;
}


export default function ObserverOccurrenceForClerk({ occurrenceId, reloadInfo, loading }: ObserverOccurrenceForClerkProps) {
  const { translate } = useLang();
  const overview = useHookstate<IOccurrence | null>(null);

  useEffect(() => {
    loading.set(true);
    getOccurrenceById({ id: occurrenceId }).then((occurrence) => {
      overview.set(parseDataOccurrence(occurrence));
      loading.set(false);
    });

  }, [reloadInfo.get()])

  return !loading.get() ?
    (<Grid container xs={12}>
      <Grid container xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'start'} gap={2}>
        <Grid container xs={12}>
          <Grid item xs={4}>
            <Typography variant='subtitle1'>
              <b>{translate('Register number')}:</b>
            </Typography>
            <Typography variant='subtitle2'>
              {overview.get()?.id ? overview.get()?.id : `* ${translate('Uninformed')} *`}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant='subtitle1'>
              <b>{translate('Entry date')}</b>
            </Typography>
            <Typography variant='subtitle2'>
              {dayjs(overview.get()?.entry_at).isValid() ? dayjs(overview.get()?.entry_at).format('DD/MM/YYYY HH:mm:ss') : `* ${translate('Uninformed')} *`}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='subtitle1'>
              <b>{translate('Created date Occurrence')}</b>
            </Typography>
            <Typography variant='subtitle2'>
              {dayjs(overview.get()?.created_at).isValid() ? dayjs(overview.get()?.created_at).format('DD/MM/YYYY HH:mm:ss') : `* ${translate('Uninformed')} *`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container xs={12}>
          <Grid item xs={4}>
            <Typography variant='subtitle1'>
              <b>{translate('Location')}:</b>
            </Typography>
            <Typography variant='subtitle2'>
              {overview.get()?.location ? overview.get()?.location : `* ${translate('Uninformed')} *`}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='subtitle1'>
              <b>{translate('City')}:</b>
            </Typography>
            <Typography variant='subtitle2'>
              {overview.get()?.city?.name ? overview.get()?.city?.name : `* ${translate('Uninformed')} *`}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant='subtitle1'>
              <b>{translate('District')}:</b>
            </Typography>
            <Typography variant='subtitle2'>
              {overview.get()?.district?.name ? overview.get()?.district?.name : `* ${translate('Uninformed')} *`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container xs={12}>

          <Grid item xs={4}>
            <Typography variant='subtitle1'>
              <b>{translate('Origin')}:</b>
            </Typography>
            <Typography variant='subtitle2'>
              {overview.get()?.entry_origin?.name ? overview.get()?.entry_origin?.name : `* ${translate('Uninformed')} *`}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant='subtitle1'>
              <b>{translate('Requester Contact')}:</b>
            </Typography>
            <Typography variant='subtitle2'>
              {overview.get()?.phone ?? `* ${translate('Uninformed')} *`}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant='subtitle1'>
              <b>{translate('Requester')}:</b>
            </Typography>
            <Typography variant='subtitle2'>
              {overview.get()?.name ?? `* ${translate('Uninformed')} *`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={4}>
            <Typography variant='subtitle1'>
              <b>{translate('Latitude / Longitude')}:</b>
            </Typography>
            <Typography variant='subtitle2'>
              {overview.get()?.latitude ? overview.get()?.latitude?.toFixed(8) : `* ${translate('Uninformed')} *`}
              {' '}
              /
              {' '}
              {overview.get()?.longitude ? overview.get()?.longitude?.toFixed(8) : `* ${translate('Uninformed')} *`}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant='subtitle1'>
              <b>{translate('Type')}/{translate('Subtype')}:</b>
            </Typography>
            <Typography variant='subtitle2'>
              {`${overview.get()?.type?.name ? overview.get()?.type?.name : `* ${translate('Uninformed')} *`} ${overview.get()?.subtype?.name ? overview.get()?.subtype?.name : ''}`}
            </Typography>
          </Grid>
        </Grid>

      </Grid>
    </Grid>) : <Grid container sx={{ width: '100%', height: 700, display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
      <Grid item>
        <CircularProgress disableShrink size={50} />
      </Grid>
    </Grid>
}