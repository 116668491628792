import React, { ReactNode, useCallback, useEffect } from "react";
import { Grid } from "@mui/material";
import { Content } from "../Content";
import SidebarMenu from "../SidebarMenu";
import { useTheme } from '../../hooks/useTheme'
import { Theme } from "../Theme";
import Topbar from "../Topbar";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useSocket from "~/hooks/useSocket";
import useSystemState from "~/features/System/stores/SystemState";
import { toCamelCase } from "~/utils/strings";
import AnnouncementDialog from "~/features/Announcement/components/AnnouncementDialog";
import { useLocation } from "react-router-dom";

type LayoutProps = {
    readonly children: ReactNode;
    readonly marginLayout?: true | false;
    readonly sidebar?: true | false;
    readonly topbar?: true | false;
    readonly draggableComponents?: boolean;
    readonly mapViewPort?: boolean;
}


interface IMessageConfig {
    readonly content: readonly {
        readonly id: number;
        readonly name: string;
        readonly value: string;
        readonly config_group_id: number;
        readonly required: number;
        readonly created_at: string;
        readonly updated_at: string;
        readonly deleted_at: string;
    }[]
    readonly event_type: string
}

export default function Layout({ children, marginLayout = false, sidebar = true, topbar = true, draggableComponents = false, mapViewPort = false }: LayoutProps) {
    const { darkMode } = useTheme();
    const { socket } = useSocket();
    const { systemConfig } = useSystemState();
    const location = useLocation();

    const newMessage = useCallback((message: IMessageConfig) => {
        if (message.event_type === 'SYSTEM_CONFIG_UPDATED') {
            const configKeys = systemConfig().keys;
            message.content.forEach((config) => {
                if (configKeys.includes(toCamelCase(config.name) as never)) {
                    systemConfig()[toCamelCase(config.name)].set(config.value);
                }
            });
        }
    }, [])

    useEffect(() => {
        socket.on('configurations', newMessage)
        return () => {
            socket.off('configurations', newMessage)
        }
    }, [newMessage, socket])
    
    return (
        <Theme key={'theme'}>
            <AnnouncementDialog />
            {topbar ? <Topbar key={'topbar'} mapViewPort={mapViewPort} draggableComponents={draggableComponents} /> : null}
            <Grid container xs={12} sx={{ width: '100vw' }}>
                <Grid item >
                    {sidebar ? <SidebarMenu /> : null}
                </Grid>
                <Content marginLayout={marginLayout}>
                    {location.pathname != '/dispatch/management-map' &&
                        <ToastContainer
                            containerId="an id"
                            draggable={false}
                            theme={darkMode ? 'colored' : 'light'}
                            style={{ marginTop: "50px" }}
                        />
                    }
                    {children}
                </Content>
            </Grid>
        </Theme>
    )
}
