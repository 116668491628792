import { authFetch } from "~/services/fetch";
import { AreasResponse } from "../interface";

export async function getAllAreas(): Promise<readonly AreasResponse[]> {
  try {
    const { data } = await authFetch({
      url: '/area',
      method: 'GET',
    })
    return data;
  } catch (error) {
    throw error;
  }
}