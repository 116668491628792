import {
  CommentDataProps,
  IRawCommentData,
} from '~/features/Dispatch/stores/DispatchCommentState'
import { mountUrlRequest } from '~/services/fetch'

export function toDomain(commentData: IRawCommentData): CommentDataProps {
  return {
    id: commentData.id,
    dispatchId: commentData.dispatch_id,
    occurrenceId: commentData.occurrence_id,
    entryId: commentData.entry_id,
    user: commentData.user,
    comment: commentData.comment,
    mediaPath: commentData.media_path
      ? `${mountUrlRequest(
          `/occurrence-comment/get-media/${commentData.media_path}`
        )}`
      : '',
    createdAt: commentData.created_at,
    updatedAt: commentData.updated_at,
    deletedAt: commentData.deleted_at,
    isEdited: commentData.is_edited,
    isDeleted: commentData.is_deleted,
    changedByUser: commentData.changed_by_user,
    closingComment: commentData.closing_comment,
    device: commentData.device,
    station: commentData.station,
    fileType: commentData.file_type,
    fileName: commentData.file_name,
  }
}

export function toFetch(commentData: CommentDataProps) {
  return {
    id: commentData.id,
    dispatch_id: commentData.dispatchId,
    occurrence_id: commentData.occurrenceId,
    entry_id: commentData.entryId,
    user: commentData.user,
    comment: commentData.comment,
    media_path: commentData.mediaPath,
    created_at: commentData.createdAt,
    updated_at: commentData.updatedAt,
    delete_at: commentData.deletedAt,
    is_edited: commentData.isEdited,
    is_deleted: commentData.isDeleted,
    changed_by_user: commentData.changedByUser,
    closing_comment: commentData.closingComment,
    device: commentData.device,
    station: commentData.station,
    file_type: commentData.fileType,
    file_name: commentData.fileName,
  }
}
