import React from "react";
import { IconButton, List, ListItem, Paper, Tooltip, CircularProgress, Chip, Avatar } from '@mui/material';
import { Comment } from './components/Comment';
import { Media } from "./components/Media";
import { NoItemComponent } from "../NoItem";
import { CommentDataProps } from "~/features/Dispatch/stores/DispatchCommentState";
import { State, useHookstate } from "@hookstate/core";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { useLang } from "~/hooks/useLang";
import RefreshIcon from '@mui/icons-material/Refresh';

interface DisplayCommentsProps {
  readonly comments: State<readonly CommentDataProps[]>;
  readonly commentIsCompressed: State<boolean>;
  readonly isEdit?: boolean;
  readonly isDispatch?: boolean;
  readonly isOccurrence?: boolean;
  readonly readonly?: boolean;
  readonly moveScrolltoComment?: boolean;
  readonly loading?: boolean;
  readonly refresh?: boolean;
  readonly refreshAction?: () => void;
}

export function DisplayComments({ comments, isEdit = false, isDispatch = false, isOccurrence = false, readonly = false, moveScrolltoComment, loading = false, refresh = false, refreshAction }: DisplayCommentsProps) {
  const { translate } = useLang();
  const sortComments = useHookstate(false);
  const showSortButton = useHookstate(false);

  const changesortComments = () => {
    sortComments.set(!sortComments.get());
  }

  return (
    <Paper
      variant='outlined'
      sx={{
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      onMouseEnter={() => {
        showSortButton.set(true)
      }}
      onMouseLeave={() => showSortButton.set(false)}
    >
      {!loading ? !refresh ? showSortButton.get() && comments.get().length > 1 &&
        <Tooltip title={translate('Sort name', { code: !sortComments.get() ? translate('Order by the oldest date') : translate('Order by date latest') })} placement="top">
          <IconButton
            onClick={() => changesortComments()}
            sx={{
              position: 'absolute',
              zIndex: 1,
              marginTop: '-20px',
              marginLeft: '-15px',
              transform: 'scale(0.6)',
              color: 'white',
              backgroundColor: '#009688',
              "&:hover": { backgroundColor: "#00544a" }
            }}
          >
            <SwapVertIcon />
          </IconButton>
        </Tooltip>
        : false : false
      }

      {!loading ? !refresh ? !comments.get().length ? <NoItemComponent title="No Comments" /> :
        <List dense={true} sx={{ width: '100%' }}>
          {
            comments.map((commentObject) => (
              <ListItem sx={{ padding: '5px 8px' }} key={commentObject.get().id}>
                {
                  !commentObject.get().mediaPath &&
                  <Comment
                    isEdit={isEdit && !readonly}
                    commentData={commentObject}
                    isDispatch={isDispatch}
                    isOccurrence={isOccurrence}
                    moveScrolltoComment={moveScrolltoComment}
                  />
                }
                {
                  !!commentObject.get().mediaPath &&
                  <Media
                    commentData={commentObject}
                  />
                }

              </ListItem>)).sort((element1, element2) => element1 && element2 && element1.props.createdAt > element2.props.createdAt ?
                (sortComments.get() === false ? 1 : -1) :
                (sortComments.get() === false ? -1 : 1))
          }
        </List>
        : <Chip sx={{ marginLeft: '0%', marginTop: '50%' }} label={translate("Connection failed! Please try again")} avatar={<Avatar><RefreshIcon /></Avatar>} onClick={() => refreshAction ? refreshAction() : false} />

        : <CircularProgress sx={{ marginLeft: '0%', marginTop: '50%' }} disableShrink />
      }
    </Paper>
  )
}
