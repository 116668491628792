import { AxiosError } from 'axios';
import { authFetch } from '~/services/fetch';
import { IParamsTable, IUpdateProps } from '../../../interfaces';

export async function getDistrictById(id: IUpdateProps) {
    try {
        if (!id) return null;

        const response = await authFetch({
            url: `/district/${id}`,
            method: 'GET' 
        });
        return response.data;
    } catch (error) {
        const err = error as AxiosError;
        throw err.response?.data?.error || err.response?.data?.message || err.message;
    }   
}

export async function getDistricts({
    filters = [],
    limit = 100000,
    orders = [
        {field: 'name', sort: 'asc'}
    ]
}: IParamsTable = {}) {
    try {
        const response = await authFetch({
            url: `/district/`,
            method: 'POST',
            data: { 
                limit,
                filters,
                orders
            },
            
        });
        return response.data.data;
    } catch (error) {
        const err = error as AxiosError;
        throw err.response?.data?.error || err.response?.data?.message || err.message;
    }   
}