/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { getEntryStatus } from './services';
import { EntryStateResponse } from './interfaces';

interface EntryStatusProps {
    readonly onEntryStatusChange: (value: number | null) => void;
    readonly entryStatus?: number | null;
    readonly disabled?: boolean
}

export default function FilterAutocompleteEntryStatus({ disabled, onEntryStatusChange, entryStatus }: EntryStatusProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly EntryStateResponse[]>([]);
    const [value, setValue] = useState<EntryStateResponse | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        getEntryStatus().then(entryStatus => {
            setOptions(entryStatus)
            setValue(null)
        })
    }, [])

    useEffect(() => {
        if (!entryStatus) {
            setValue(null)
        } else {
            setValue(options.find(opt => opt.id === entryStatus) ?? null)
        }
    }, [entryStatus])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                id='filter-agencies'
                limitTags={2}
                disabled={disabled}
                sx={{ height: 2 }}
                options={options}
                value={value}
                noOptionsText={translate('No options')}
                clearText={translate('Clear')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={(_, type) => {
                    setValue(type)
                    onEntryStatusChange(type?.id ?? null)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Entry Status')}
                    />
                )}
            />
        </FormControl>
    )
}