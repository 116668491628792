import React from 'react';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { AppRouteProps } from '~/routes';

export default function PeopleCircunstanceRoutes(): readonly AppRouteProps[] {
  const routes: readonly AppRouteProps[] = [
    {path: '/people-circunstance', component: Index, isProtected: true, permissions: ['people_circunstance_index']},
    {path: '/people-circunstance/create', component: Create, isProtected: true, permissions: ['people_circunstance_create']},
    {path: '/people-circunstance/edit/:id', component: Edit, isProtected: true, permissions: ['people_circunstance_edit']},

  ];

  return routes;
}
