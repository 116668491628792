import React from 'react';
import { AppRouteProps } from '~/routes';
import Create from './pages/Create';
import Index from './pages/Index';
import Edit from './pages/Edit';

export default function ProcedureRoutes(): readonly AppRouteProps[] {
  const routes: readonly AppRouteProps[] = [
    {path: '/procedure', component: Index, isProtected: true, permissions: ['procedure_index']},
    {path: '/procedure/create', component: Create, isProtected: true, permissions: ['procedure_create']},
    {path: '/procedure/edit/:id', component: Edit, isProtected: true, permissions: ['procedure_edit']},

  ];

  return routes;
}