import { IDrugDTO, IRawDrug } from "~/features/Dispatch/interfaces/IDispacthRegistrationDrug"

export function toDomain(
  drug: IRawDrug): IDrugDTO {
  return {
    id: drug.id,
    occurrenceId: drug.occurrence_id,
    drugType: drug.drug_type,
    quantity: drug.quantity,
    unitMeasurement: drug.unit_measurement,
    notes: drug.notes,
    circunstance: drug.circunstance,
    destination: drug.destination,
  }
}

export function toFetch(drug: IDrugDTO) {
  return {
    drug_type_id: drug.drugType?.id,
    quantity: drug.quantity,
    occurrence_id: drug.occurrenceId,
    notes: drug.notes,
    circunstance_id: drug.circunstance?.id,
    destination_id: drug.destination?.id,
    unit_measurement_id: drug.unitMeasurement?.id,
  }
}
