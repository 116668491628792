import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { getAllAreaPlan } from './services';
import { AreaPlanResponse } from './interface';

interface FilterAutocompleteAreaPlanProps {
    readonly onAreaPlanChange: (value: number | null) => void;
    readonly hideCode?: boolean;
    readonly areaPlanId?: number | null;
    readonly disabled?: boolean
}

export default function FilterAutocompleteAreaPlan({ disabled, areaPlanId, onAreaPlanChange, hideCode = false }: FilterAutocompleteAreaPlanProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly AreaPlanResponse[]>([]);

    const [value, setValue] = useState<AreaPlanResponse | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        getAllAreaPlan().then(areaPlan => {
            setOptions(areaPlan)
            setValue(null)
        })
    }, [])

    useEffect(() => {
        if (!areaPlanId) {
            setValue(null)
        } else {
            setValue(options.find(opt => opt.id === areaPlanId) ?? null)
        }
    }, [areaPlanId])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                id='filter-area-plan'
                disabled={disabled}
                options={options}
                value={value}
                noOptionsText={translate('No options')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={(_, type) => {
                    setValue(type)
                    onAreaPlanChange(type?.id as number ?? null)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Area Plan')}
                    />
                )}
            />
        </FormControl>
    )
}