import React, { ChangeEvent, useEffect } from 'react';
import { useLang } from '~/hooks/useLang';
import { TextField, Grid, IconButton, Typography, Divider, CircularProgress, ListItem, ListItemText } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useHookstate, createState, useState, Downgraded } from '@hookstate/core';
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '~/components/ErrorFallback';
import InfiniteScroll from 'react-infinite-scroll-component';
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState';
import useOccurrenceCommentState from '~/features/Occurrence/stores/OccurrenceCommentState';
import useSystemState from '~/features/System/stores/SystemState';
import { Visibility } from '@mui/icons-material';
import { searchWeaponService } from './services';
import dayjs from 'dayjs';

export interface WeaponListProps {
  readonly id: number
  readonly serial_number: string
  readonly occurrence_id: number
  readonly created_at: string
  readonly circunstance: {
    readonly id: number
    readonly name: string
  }
  readonly destination: {
    readonly id: number
    readonly name: string
  }
  readonly weapon_type: {
    readonly id: number
    readonly name: string
  }
  readonly weapon_kind: {
    readonly id: number
    readonly name: string
  }
  readonly occurrence: {
    readonly id: number
    readonly created_at: string
    readonly type: {
        readonly name: string
        readonly code: string
    },
    readonly subtype: {
        readonly name: string
        readonly code: string
    },
    readonly dispatches: Array<{
      readonly id: number,
      readonly code: string
    }>
  }
}

const serialNumberState = createState('');

export function useSearchWeapon() {
  const serialNumber = useState(serialNumberState);

  return ({
    serialNumber: () => serialNumber
  })
}

export default function SearchWeapon() {
  const { translate } = useLang();
  const { windowManager } = useSystemState();
  
  const serialNumberExternal = useSearchWeapon().serialNumber;
  const serialNumber = useHookstate('');
  const serialNumberSearch = useHookstate('');
  const weapons = useHookstate<readonly WeaponListProps[]>(windowManager().searchWeapon.dataSearchWeapon.attach(Downgraded).get() ?? []);
  const weaponsCount = useHookstate(0);
  const { syncOccurrenceEditAttendent } = useOccurrenceState();
  const { syncPreviousComments } = useOccurrenceCommentState();

  useEffect(() => {
    serialNumber.set(serialNumberExternal().get());
    return () => {
      serialNumberExternal().set('');
    }
  }, []);

  const nextData = async (reset = false) => {
    const pageQuery = reset ? 0 : Math.floor(weapons.get().length / 10);
    searchWeaponService({ serialNumber: serialNumberSearch.get(), page: pageQuery, limit: 10 }).then(({ data, rowsCount }) => {
      if (reset) {
        weapons.set(data);
        weaponsCount.set(rowsCount);
      } else {
        weapons.merge(data);
        weaponsCount.set(rowsCount);
      }
    })
  }

  const onClick = (id) => {
    syncOccurrenceEditAttendent(id);
    syncPreviousComments(id);
    windowManager()['dispatchesResultSearches'].open.set(true);
  }

  useEffect(() => {
    if(!serialNumber.get()?.length){
      windowManager()['dispatchesResultSearches'].open.set(false)
    }
  }, [])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Grid container spacing={2}>
        <Grid container padding={1}>
          <Grid item xs={12} padding={1}>
            <Grid container xs={12} direction="row" spacing={1} padding={1}>
              <Grid item xs={6} spacing={1}>
                <TextField
                  fullWidth
                  label={`${translate('Serial Number')}`}
                  size="small"
                  value={serialNumber.get() ?? ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => { serialNumber.set(event.target.value) }}
                />
              </Grid>
              <Grid item xs={6} spacing={1}>
                <IconButton onClick={() => {
                  serialNumberSearch.set(serialNumber.get());
                  nextData(true)
                }} 
                disabled={serialNumber.get()?.length < 3}>
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />

            <Grid container padding={1}>
              <Grid item xs={12}>
                <InfiniteScroll
                  dataLength={weapons.value.length}
                  next={() => nextData()}
                  hasMore={(weapons.value.length != weaponsCount.get()) && weapons.value.length != 0}
                  height={335}
                  loader={<CircularProgress />}
                >
                  {weapons.value.length != 0 ? (
                    weapons.get()?.map((items) => (
                      <>
                        <ListItem key={items.id}>
                          <ListItemText primary={`OC: ${items?.occurrence_id} | ${items?.occurrence.dispatches?.map((item, index) => `\n\n${item.code}`)}`}
                            secondary={<>
                              <Typography variant={'subtitle2'}>
                                {items?.occurrence.type.name} {items?.occurrence.subtype?.name}
                              </Typography>
                              
                              <Typography variant={'subtitle2'}>
                                {translate('Serial Number')}: {items?.serial_number} 
                              </Typography>
                              <Typography variant={'subtitle2'}>
                                {translate('Weapon Type')}: {items?.weapon_type?.name} 
                                </Typography>
                              <Typography variant={'subtitle2'}>
                                {translate('Weapon Kind')}: {items?.weapon_kind?.name} 
                                </Typography>
                              <Typography variant={'subtitle2'}>
                                {items?.circunstance?.name}
                              </Typography>
                              <Typography variant={'subtitle2'}>
                                Destino Final: {items?.destination?.name}
                              </Typography>
                              <Typography variant={'subtitle2'}>
                                Data da Ocorrência: {dayjs(items?.occurrence.created_at).format('DD/MM/YYYY HH:mm')}
                              </Typography>
                              <Typography variant={'subtitle2'}>
                                Data do Registro: {dayjs(items?.created_at).format('DD/MM/YYYY HH:mm')}
                              </Typography>
                              
                            </>} />
                          <IconButton aria-label="comment" onClick={() => onClick(items?.occurrence_id)} title='Visualizar'>
                            <Visibility />
                          </IconButton>
                        </ListItem>
                        <Divider sx={{ marginTop: 2 }} />
                      </>
                    ))
                  ) : <Grid xs={12} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography>
                      {translate('No Weapons')}
                    </Typography>
                  </Grid>}
                </InfiniteScroll>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}