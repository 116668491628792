import React, { useEffect } from 'react';
import CrudIndex from '~/components/CrudIndex';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';

export default function Index() {
    const { translate } = useLang();

    useEffect(() => {
        document.title = translate('Administration - Dispatch System')
    }, [])
    return (
        <Layout marginLayout={true}>
            <CrudIndex model='occupation' title='Occupation' />
        </Layout>
    )
}
