import React from "react";
import { Grid } from "@mui/material";
import HourField from "../DispatchTimeHourField";
import MinuteField from "../DispatchTimeMinuteField";


export default function TimeInputContainer({isValid, dispatchRetainHours, dispatchRetainMinutes, clear}){
  return(
      <Grid container columns={12} rowSpacing={1} sx={{ flexDirection: 'column' }}>
        {/* <Grid item>
          <HourField isValid={isValid} dispatchRetainHours={dispatchRetainHours} clear={clear}/> 
        </Grid> */}
        <Grid item>
          <MinuteField isValid={isValid} dispatchRetainMinutes={dispatchRetainMinutes} clear={clear}/>
        </Grid>
      </Grid>
  )
}