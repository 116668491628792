import { io } from 'socket.io-client';
import Cookies from 'universal-cookie';
import constants from '~/core/constants';
import { getSocketUrl } from '~/utils/net';

const cookies = new Cookies();

export const getInstanceSocket = (token: string) => {
    return io(getSocketUrl(),
        {
            auth: { token },
            query: { session: cookies.get(constants.keys.cookies.identificationKey) },
            withCredentials: true,
            autoConnect: false,
            transports: ['websocket', 'polling'],
        }
    )
};

export const defaultRooms: readonly string[] = [
    'DESPACHO_NEW_ENTRY'
];