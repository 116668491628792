import React, { ReactElement } from 'react';
import { useLang } from '~/hooks/useLang';
import { State, useHookstate } from '@hookstate/core';
import { IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface IButtonDialog {
  readonly titleButton: string
  readonly iconButton: ReactElement<any, any>
  readonly sxButton?: object
  readonly disabled?: boolean
  readonly action?: () => void | undefined
  readonly dialogTitle?: string
  readonly dialogContent?: ReactElement<any, any>
  readonly dialogActions?: ReactElement<any, any>
  readonly loading?: State<boolean>
}

export default function ButtonDialog({ titleButton, iconButton, sxButton, disabled = false, action, dialogTitle, dialogContent, dialogActions, loading }: IButtonDialog) {
  const { translate } = useLang()
  const open = useHookstate(false);

  const actionClick = () => {
    action ? action() : open.set(false);
    return open.set(false);
  }

  return (
    <>
      <IconButton title={titleButton} onClick={() => open.set(true)} disabled={disabled}>
        {iconButton}
      </IconButton>

      <Dialog open={open.get()} onClose={() => open.set(false)} maxWidth={'xs'} fullWidth >
        <DialogTitle minWidth={"320px"} variant='h5' >
          {dialogTitle}
        </DialogTitle >
        <DialogContent>
          {dialogContent}
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => open.set(false)}
          >
            {translate('Cancel')}
          </Button>
          {dialogActions}
          <LoadingButton
            loading={loading?.get()}
            variant='contained'
            onClick={actionClick}
          >
            {translate('Submit')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}