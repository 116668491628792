import { Downgraded, useHookstate } from '@hookstate/core';
import React, { useEffect } from 'react'
import { getStatisticsDispatchByClosedStatusService } from '~/features/Dispatch/services';
import PieChart from '~/components/PieChart';

export default function DispatchByClosedStatusPieChart({operationId, initialDate, endDate, refresh, loading}) {
  const data = useHookstate([]);

  useEffect(() => {
    getStatisticsDispatchByClosedStatusService({ operationId: operationId, initialDate: initialDate, endDate: endDate }).then((response) => {
        const result = response.map((responseData) => ({ item: responseData.name, value: responseData.total }))
      return data.set(result);
    }).finally(() => {
      loading.set(false)
    })
  }, [refresh]);

  return (
    <PieChart data={data.attach(Downgraded).get()} title={'Graph of Occurrences by Closing Status'} sxFont={{ fontWeight: 'bold', fontSize: 16 }}/>
  )
}