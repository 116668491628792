import React from 'react'
import { Divider, Grid } from '@mui/material'
import TabListMessage from './components/TabListMessage'
import CurrentMessage from './components/CurrentMessage'
import NewMessage from './components/NewMessage'
import useMessageState from '../../stores/MessageState'

export default function Message() {
  const { isWritingMessage } = useMessageState()

  return (
    <Grid container sx={{ margin: 0, }}>
      <Grid item md={12}>
        <Grid
          container
          sx={{ margin: 0, display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid item md={5}>
            <TabListMessage />
          </Grid>
          <Divider
            orientation="vertical" 
            flexItem 
            />
          <Grid item md={6}>
            {isWritingMessage().get() ? <NewMessage /> : <CurrentMessage />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
