import { createState, Downgraded, useHookstate, useState } from '@hookstate/core'
import { Broadcasted as broadcasted } from '@hookstate/broadcasted'
import { Id, toast } from 'react-toastify'
import { useEffect } from 'react'
import { useNotificationCenter } from 'react-toastify/addons/use-notification-center'

export default interface INotification {
  readonly id: number
}

export const notificationDefault: number | null = null
export const clearNotificationDefault: boolean | null = null

const notificationStore = Object.create(notificationDefault)
const clearNotificationsStore = createState<boolean | null>(clearNotificationDefault)

const store = createState<{notification: number | null}>({
  notification: notificationStore
})

clearNotificationsStore.attach(broadcasted('clear-notification-events'))
store.attach(broadcasted('notification-events'))

export default function useNotificationState() {
  const actualNotification = useState<number|null>(store.notification)
  const clearNotifications = useHookstate(clearNotificationsStore)

  useEffect(() => {
    if (actualNotification.get()) {
      toast.dismiss(actualNotification.get() as Id)
    }
  }, [actualNotification.get()])

  const handleClearNotification = () => {
    clearNotifications.set(!clearNotifications.get())
  }


  return {
    actualNotification: () => actualNotification,
    handleClearNotification: () => handleClearNotification(),
    clearNotifications: () => clearNotifications,
  }
}
