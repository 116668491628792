import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IEntryStatus from '../interfaces/IEntryStatus';
import Layout from '~/components/Layout';


export default function Create() {
  const { register, handleSubmit, control, setValue:setFormValue } = useForm<IEntryStatus>();
  const fields = [
    { name: 'name', type: 'string' },
    { name: 'is_recall', type: 'boolean'},
    { name: 'is_prank_call', type: 'boolean'},
    { name: 'order', type: 'number'},
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate model={'entry-status'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        messageSuccess={'Entry Status successfully created!'}  
        title={'Add status'}  
        subheader={'Add status'}
        setFormValue={setFormValue} />
      </Layout>
    )
}
