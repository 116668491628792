import { Box, Button, Grid, ListItem, Typography } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { DropEvent, DropzoneOptions, FileError, FileRejection, useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify';
import { useLang } from '~/hooks/useLang';
import { FileProps } from '~/components/ImportFileField';
import { Override } from '~/interfaces'
import { useHookstate } from '@hookstate/core';
import fileSize from 'filesize';
import { InsertDriveFileRounded, DeleteRounded } from '@mui/icons-material';
import { UseFormGetValues, UseFormRegister, UseFormSetValue, Control } from 'react-hook-form';
import { useTheme } from '~/hooks/useTheme';

interface CrudUploadFieldProps {
    readonly dropZoneOptions?: DropzoneOptions;
    readonly FileIcon?: JSX.Element;
    readonly model: string;
    readonly useForm: () => {
        control?: Control;
        register: UseFormRegister<any>;
        getValues?: UseFormGetValues<any>;
        setFormValue: UseFormSetValue<any>;
    }
}

export type CrudAcceptedFile = Omit<FileProps, 'file' | 'image'>;
interface CrudAcceptFile extends CrudAcceptedFile {
    readonly remove: () => void;
}

function CrudUploadField({
    dropZoneOptions = {
        multiple: false,
        maxFiles: 1
    },
    FileIcon = <InsertDriveFileRounded fontSize='small' sx={{ minWidth: '52px' }} />,
    useForm,
    model
}: CrudUploadFieldProps) {
    const { darkMode } = useTheme();
    const { translate } = useLang();
    const { register, setFormValue, getValues, control } = useForm();
    const registeredUploadField = register(model);

    const toUploadFiles = useHookstate<CrudAcceptFile[]>([]);

    const formData = React.useRef(new FormData());

    const createRemoveFileFunc = (filename: string, fileFormName: string) => {
        return () => {

            formData.current.delete(fileFormName);

            toUploadFiles.set((current) => {
                return current.filter((ele) => ele.name !== filename);
            });

            setFormValue(model, undefined);
        }
    };
    const validator = (file: File): FileError | FileError[] | null => {
        const currentFiles = toUploadFiles.get();

        const hasAlreadyFile = currentFiles.find((ele) => ele.name === file.name)

        if (hasAlreadyFile) {
            const error: FileError = {
                code: 'file-name-already-exist',
                message: 'File already exist with this name'
            }
            return error
        }

        return null;
    };
    const onDropAccepted = useCallback((acceptedFiles: readonly File[]) => {
        acceptedFiles.forEach((acceptedFile) => {
            const reader = new FileReader();

            reader.addEventListener('abort', () => console.error(`File ${acceptedFile.name} reading was aborted`));
            reader.addEventListener('error', () => console.error(`File ${acceptedFile.name} reading was aborted`));
            reader.addEventListener('load', async () => {
                const fileBinary = reader.result;

                const fileIndex = acceptedFiles.findIndex((file, index) => file.name === acceptedFile.name);

                if (!fileBinary) return;

                const fileFormName = `file${fileIndex === 0 ? "" : " ".concat(fileIndex.toString())}`;

                formData.current.append(fileFormName, new File([fileBinary], acceptedFile.name, { type: acceptedFile.type }));

                toUploadFiles.merge([{
                    name: acceptedFile.name,
                    readableSize: fileSize(acceptedFile.size),
                    type: acceptedFile.type,
                    remove: createRemoveFileFunc(acceptedFile.name, fileFormName)
                }]);

                setFormValue(model, formData.current);
            });

            reader.readAsArrayBuffer(acceptedFile);
        });
    }, []);
    const onDropRejected = useCallback((files: readonly FileRejection[]) => {
        files.forEach((file) => {
            file.errors.forEach((err: FileError) => {
                if (err.code === "file-invalid-type") {
                    const errorMessageStringLimit = 17;
                    const baseFileErrorMessage = err.message.substring(0, errorMessageStringLimit);
                    const fileErrorTypes = err.message.substring(errorMessageStringLimit, err.message.length).split(',').join(', ');
                    toast.error(`${translate(baseFileErrorMessage)} ${fileErrorTypes}`);

                    return;
                }
                return toast.error(translate(err.message));;
            });
        });
    }, []);
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
        ...dropZoneOptions,
        maxSize: 2e6,
        onDropAccepted,
        onDropRejected,
        validator
    });

    return (
        <Grid item xs={12}>
            <section style={{ display: 'flex', gap: 5, flexDirection: 'column', justifyContent: 'center' }} className="container">
                <div {...getRootProps({ className: `dropzone` })} >
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <Box><Typography variant={'body2'} style={{ cursor: 'default' }}>{`${translate('Drop the file here')}...`}</Typography></Box> :
                            <Box><Typography variant={'body2'} style={{ cursor: 'pointer' }}>{`${translate('Drop the file or click to select a file')}`}</Typography></Box>
                    }
                </div>
                <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center', }}>
                    {toUploadFiles.get().map((toUploadFile) =>
                    (<ListItem
                        key={toUploadFile.name}
                        sx={{ maxWidth: '500px', display: 'flex', alignItems: 'center' }}
                    >

                        {FileIcon}
                        <Typography
                            variant={'caption'}
                            sx={{ display:'inline-flex', flexGrow: 0.4, marginRight: 'auto' }}
                        >
                            {`${toUploadFile.name} - ${toUploadFile.readableSize}`}
                        </Typography>
                        <Box 
                            sx={{
                                backgroundColor: 'success.dark',
                                borderRadius: '50%',
                                padding: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyItems: 'center'
                            }}
                        >
                            <DeleteRounded fontSize='small' sx={{cursor: 'pointer'}} onClick={toUploadFile.remove} />
                        </Box>
                    </ListItem>)
                    )
                    }
                </Box>
            </section>
        </Grid>
    )
}
export default CrudUploadField;