import React, { useEffect } from 'react';
import Layout from '~/components/Layout';
import Map from '~/features/Map/components/Map';
import useSystemState from '~/features/System/stores/SystemState';
import { useHookstate } from '@hookstate/core';
import MapMarkersEvents from '~/features/Map/components/MapMarkers/MapMarkersEvents';
import useUserState from '~/features/User/stores/UserState';
import { useLang } from '~/hooks/useLang';

export default function PredictionMap() {
  const { windowDefault, windowManager, loadDraggables, windowIsVisible } = useSystemState();
  const { translate } = useLang();
  const { verifyPermission } = useUserState();
  const viewGroups = useHookstate<string[]>(['prediction', 'dispatchFiltered']);

  useEffect(() => {
    document.title = translate('Prediction Map - Dispatch System')
  }, [])


  useEffect(() => {
  //   verifyPermission(['camera_index']) ? viewGroups.merge(['camera']) : false;
    windowDefault(['mapFilters']);
    // loadDraggables(['DispatchFormModalDraggable', 'EntryFormModalDraggable','SearchStaffModalDraggable','VehicleModalDraggable', 'VideoMonitoringModalDraggable'])
  //   markersViewVisible(['device']);
    // markersViewDefault(['device']);
    return () => windowManager()['mapFilters'].open.set(false)
  }, []);



  return (
    <Layout mapViewPort marginLayout={false} draggableComponents={true}>
      <Map>
          <MapMarkersEvents viewGroups={viewGroups.get()} checkedViewGroups={['prediction', 'dispatchFiltered']}/>
      </Map>
    </Layout>
  );
}