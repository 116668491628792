import { GridFilterItem } from '@mui/x-data-grid';
import { authFetch } from '~/services/fetch';
import { IAreaConfig, IFilters, IMultAreaConfig } from '../interfaces/IAreaConfig';

export async function getAllAreas() {
    const response = await authFetch({
        url: '/area/',
        method: 'GET'
    });
    return response.data;
}

export async function sendAreaConfig({ agencyId, areaId, dispatchGroupId, subtypeId, typeId }: IAreaConfig) {
    const targetUrl = '/dispatch/area-config/store';
    return await authFetch({
        url: targetUrl,
        data: {
            agency_id: agencyId,
            area_id: areaId,
            dispatch_group_id: dispatchGroupId,
            type_id: typeId,
            subtype_id: subtypeId,
        }
    })
}

export async function sendMultipleAreaConfig({ agencyId, configTypes }: IMultAreaConfig) {
    const targetUrl = '/dispatch/area-config/store/all-from-agency';
    return await authFetch({
        url: targetUrl,
        data: {
            agency_id: agencyId,
            config_types: configTypes
        }
    })
}

export async function deleteAreaConfig(id: string) {
    const targetUrl = `/dispatch/area-config/${id}`;
    return await authFetch({
        url: targetUrl,
        method: 'DELETE'
    })
}

export async function listAreaConfig(page, limit, filters?: readonly IFilters[]) {
    const targetUrl = '/dispatch/area-config/';
    return await authFetch({
        url: targetUrl,
        data: {
            filters,
            orders: [
                { field: 'area.acronym', sort: 'asc' }
            ],
            page,
            limit,
        }
    })
}

export async function deleteMultipleAreaConfig(configIds) {
    const targetUrl = '/dispatch/area-config/delete-multiple';
    return await authFetch({
        url: targetUrl,
        method: 'POST',
        data: {
            config_ids: configIds
        }
    })
}
