/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Settings } from '@mui/icons-material'
import StandardModal from '../../../../components/StandardModal'
import { GridRowModel, GridRowParams, MuiEvent } from '@mui/x-data-grid'
import EntryLogTable from '../EntryLogTable'
import { useLang } from '../../../../hooks/useLang'
import useEntryState from '../../stores/EntryState'
import { Validation as validation } from '@hookstate/validation'
import { useHookstate, useState } from '@hookstate/core'
import { useAuth } from '~/features/auth/hooks/useAuth'
import { authFetch } from '~/services/fetch'
import LastCallList from '../LastCallsList'
import Index from '~/features/Destination/pages/Index'
import { useLocation } from 'react-router'
import ScreenSettingsDraggableProps from '~/features/System/interfaces/ScreenSettings'
import useSystemState from '~/features/System/stores/SystemState'
import { storeScreenSettings } from '~/features/System/services'
import { Typography } from '@mui/material'

const modal = document.getElementById('div-modal') as HTMLElement

interface LastCallsListProps {
  readonly data: [
    {
      readonly id: number
      readonly name: string
      readonly typename: string
      readonly subtypename: string
      readonly is_prank_call: string
      readonly created_at: Date
      readonly location: string
      readonly typeId: string
      readonly occurrence_id: number | null
      readonly subtypeId: string
      readonly occurrence: {
        readonly id: number
        readonly dispatches: readonly [
          {
            readonly code: number
            readonly id: number
            readonly status: {
              readonly acronym: string
              readonly id: number
              readonly name: string
            }
          }
        ]
      }
    }
  ]
  readonly rowsCount: number
}

type EntryLogParams = {
  onRowClick?: (
    rows: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement>>
  ) => boolean | undefined
}

export default function EntryLogModalDraggable({ onRowClick }: EntryLogParams) {
  const { translate } = useLang()
  const { phone } = useState(useEntryState().entry())
  const entryState = useEntryState()
  const { isOpenModalLastCalls } = entryState.entryStatusActions()
  const rows = useState<LastCallsListProps[]>([])
  const countPrank = useHookstate(0)
  const { windowManager, loadingButton } = useSystemState()
  const totalRows = useHookstate(0);

  // useEffect(() => {
  //   if (validation(phone).valid()) {
  //     authFetch({
  //       url: '/entry-log/get-by-phone',
  //       data: { phone: phone.get() },
  //     })
  //       .then((response) => {
  //         rows.set(response.data.rowsCount)
  //         windowManager()['EntryLogModal'].open.set(true)
  //         response.data.data.map((count) =>
  //           count.is_prank_call
  //             ? countPrank.set((numberPranks) => numberPranks + 1)
  //             : ''
  //         )
  //       })
  //       .catch(console.error)
  //   } else {
  //     windowManager()['EntryLogModal'].open.set(false)
  //     countPrank.set(0)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [phone.get()])

  const handleStop = (event, dragElement) => {
    event.stopPropagation()
    windowManager()['EntryLogModal'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y,
    })
  }

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'EntryLogModalDraggable',
        isOpen: windowManager()['EntryLogModal'].open.get(),
        isMinimized: windowManager()['EntryLogModal'].minimized.get(),
        positionX: windowManager()['EntryLogModal'].positionX.get(),
        positionY: windowManager()['EntryLogModal'].positionY.get(),
      })
    }
  }, [
    windowManager()['EntryLogModal'].open.get(),
    windowManager()['EntryLogModal'].minimized.get(),
    windowManager()['EntryLogModal'].positionY.get(),
    windowManager()['EntryLogModal'].positionX.get(),
  ])

  if (windowManager()['EntryLogModal'].open.get()) {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() && (
          <StandardModal
            title={translate('Last Calls')}
            subheader={
              <>
              <Typography variant='subtitle2'>
                  {translate('Total calls')}: {totalRows.get()}
                </Typography>
                <Typography variant='subtitle2'>
                  {translate('Number of pranks')}: {countPrank.get()}
                </Typography>
              </>
            }
            dimensions={{
              width: 400,
            }}
            avatar={Settings}
            handleStop={handleStop}
            position={{
              x: windowManager()['EntryLogModal'].positionX.get()
                ? Number(windowManager()['EntryLogModal'].positionX.get())
                : 80,
              y: windowManager()['EntryLogModal'].positionY.get()
                ? Number(windowManager()['EntryLogModal'].positionY.get())
                : 141,
            }}
            closeButton={() => windowManager()['EntryLogModal'].open.set(false)}
            isExpanded={windowManager()['EntryLogModal'].minimized}
          >
            <LastCallList phone={phone.get()} totalRows={totalRows} countPrank={countPrank}/>
          </StandardModal>
        )}
      </>,
      modal
    )
  } else {
    return <></>
  }
}
