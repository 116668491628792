import { authFetch } from "~/services/fetch";

export async function makeRequestToGetInterestPoints({
    name,
    areasId,
    notes,
    typeInterestPointId,
    subtypeInterestPointId,
    notValidated,
    onlyValidated,
    isReviewed,
    isExpired,
    startDateCreated,
    finishDateCreated, startDateReview,
    finishDateReview,
    startDateExpired,
    finishDateExpired,

    exportCSV = false,

}) {
    try {
        const response = await authFetch({
            url: `/report/interest-point`,
            method: 'POST',
            data: {
                name: name,
                area_ids: areasId,
                notes: notes,
                interest_point_type_id: typeInterestPointId,
                interest_point_subtype_id: subtypeInterestPointId,
                not_validated: notValidated,
                only_validated: onlyValidated,
                need_review: isReviewed,
                expired: isExpired,
                created_start_date: startDateCreated,
                created_finish_date: finishDateCreated,
                review_start_date: startDateReview,
                review_finish_date: finishDateReview,
                expired_start_date: startDateExpired,
                expired_finish_date: finishDateExpired,
                export_csv: exportCSV
            }
        });

        return response.data
    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}