import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { StateMethods } from '@hookstate/core'

export type ConfirmSimpleDialogProps = {
  readonly open: StateMethods<boolean>
  readonly title: StateMethods<string>
  readonly content: StateMethods<string>
  readonly handleClose: (any?: any[]) => void
  readonly closeMessage?: string
}

export function SimpleDialogBox(props: ConfirmSimpleDialogProps) {
  const translate = useTranslation().t
  return (
    <Dialog
      open={props.open.get()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Typography>{props.title.get()}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.content.get()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()}>
          {props.closeMessage ?? translate('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
