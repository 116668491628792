import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import ICity from '../interfaces/ICity';

export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue,getValues} = useForm<ICity>();
  const fields = [
    { name: 'id', type: 'hidden'},
    { name: 'name', type: 'string'},
    { name: 'stateId', type: 'selectAutoComplete', model: 'state', placeholder:'Estado' },
];
  
    return(
      <CrudEdit 
        model={'city'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        setFormValue={setFormValue}
        messageSuccess={'City successfully updated!'}  
        title={'Edit city'}
        getValues={getValues}
      />
    )
}