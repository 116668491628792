import { authFetch } from '~/services/fetch';

export async function getOperationService() {
  try {
    const { data } = await authFetch({
      url: '/operation',
      method: 'GET'
    })

    return data;
  } catch (error) {
    throw error
  }
}

export async function getOperationActive() {
  try {
    const { data } = await authFetch({
      url: '/operation/active',
      method: 'GET'
    })

    return data
  } catch (error) {
    throw error
  }
}

export async function setOperationService({ dispatchId, operationId }) {
  try {
    const { data } = await authFetch({
      url: '/dispatch/set-operation',
      method: 'POST',
      data: {
        dispatch_id: dispatchId,
        operation_id: operationId
      }
    })

    return data;
  } catch (error) {
    throw error
  }
}

export async function getCountQualifiersOperationService({ operationId, initialDate, endDate }) {
  try {
    const { data } = await authFetch({
      url: '/statistics/count-qualifiers',
      method: 'POST',
      data: {
        operationId: operationId,
        startDate: initialDate,
        finishDate: endDate,
        excludeClosed: false
      }
    })

    return data;
  } catch (error) {
    throw error
  }
}
