import React from 'react'
import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import { useHistory } from 'react-router-dom'
import useUserState from '~/features/User/stores/UserState'
import { useLang } from '~/hooks/useLang'
import { MenuProps } from '~/components/SidebarMenu/interfaces'

export default function LocalizationItem({ handleMenuClick }: MenuProps) {
  const { translate } = useLang()
  const { verifyPermission } = useUserState()
  const [openLocalization, setOpenLocalization] = React.useState(false)

  const history = useHistory()

  const handleClickLocalization = () => {
    setOpenLocalization((old) => !old)
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {verifyPermission(['city_index', 'district_index']) && (
        <>
          <Tooltip title={translate('Localization options')} placement="right">
            <ListItemButton onClick={handleClickLocalization}>
              <ListItemText primary={translate('Localization')} />
              {openLocalization ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </Tooltip>
          <Collapse in={openLocalization} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {verifyPermission(['district_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('District')}`
                  }
                  placement="right"
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText
                      primary={translate('District')}
                      onClick={() => {
                        handleMenuClick('administration')
                        history.push('/district')
                      }}
                    />
                  </ListItemButton>
                </Tooltip>
              )}

              {verifyPermission(['city_index']) && (
                <Tooltip
                  title={
                    `${translate('Create, read, update and delete')}` +
                    ' ' +
                    `${translate('Cities')}`
                  }
                  placement="right"
                >
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => {
                      handleMenuClick('administration')
                      history.push('/city')
                    }}
                  >
                    <ListItemText primary={translate('Cities')} />
                  </ListItemButton>
                </Tooltip>
              )}
            </List>
          </Collapse>
        </>
      )}
    </>
  )
}
