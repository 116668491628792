import React from "react";
import { IconButton, Grid } from '@mui/material';
import CommuteIcon from '@mui/icons-material/Commute';
import { useLang } from '~/hooks/useLang';


export default function ListVehiclesButton({ hasHistory, historyUrl }) {
  const { translate } = useLang()
  const ClickOpen = () => {
    window.open(historyUrl, "list-vehicles, width=auto, height=600, status = no");
  }

  return (
    <Grid item>
      <IconButton title={translate('See List Devices')} disabled={!hasHistory} onClick={ClickOpen}>
        <CommuteIcon />
      </IconButton>
    </Grid>
  );

}