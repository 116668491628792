import React, { ChangeEvent, useEffect } from 'react';
import { TextField } from '@mui/material';
import { State, useState } from '@hookstate/core';

type DispatchTimeFieldFormProps = {
  state: State<number|null>,
  id: string,
  name: string,
  label: string
  autoComplete?: string,
  required: boolean,
  disabled: boolean
}

export default function DispatchTimeFieldForm(props: DispatchTimeFieldFormProps) {
  const state = useState(props.state)

  return(
    <TextField
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]'}}      
      type='number'
      autoComplete={props.autoComplete}
      name={props.name}
      id={props.id}
      label={props.label}
      required={props.required}
      fullWidth
      size="small"
      disabled={props.disabled}
      value={ !state.get() ? '' : state.get() }
      onChange={(event: ChangeEvent<HTMLInputElement>) => {state.set(parseInt(event.target.value))}}
      InputProps={{
        inputProps: { min: 0 },
      }}
    />
  )
}
