import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';
import StandardModal from '~/components/StandardModal';
import { Settings } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { storeScreenSettings } from '~/features/System/services';

const modal = document.getElementById('div-modal') as HTMLElement;

export default function MapSettingModalDraggable() {
  const { translate } = useLang();
  const { windowManager, loadingButton } = useSystemState();  

  const handleStop = (event, dragElement) => {
    event.stopPropagation();

    windowManager()['mapSetting'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y
    })
  };

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'MapSettingModalDraggable',
        isOpen: windowManager()['mapSetting'].open.get(),
        isMinimized: windowManager()['mapSetting'].minimized.get(),
        positionX: windowManager()['mapSetting'].positionX.get(),
        positionY: windowManager()['mapSetting'].positionY.get()
      })
    }
  }, [
    windowManager()['mapSetting'].open.get(),
    windowManager()['mapSetting'].minimized.get(),
    windowManager()['mapSetting'].positionY.get(),
    windowManager()['mapSetting'].positionX.get()
  ])
  
  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {windowManager()['mapSetting'].open.value && !loadingButton.get() &&
        <StandardModal
          title={translate('Map Settings')}
          avatar={Settings}
          handleStop={handleStop}
          position={{
            x:  windowManager()['mapSetting'].positionX?.get() ? Number( windowManager()['mapSetting'].positionX?.get()) : 80,
            y:  windowManager()['mapSetting'].positionY?.get() ? Number( windowManager()['mapSetting'].positionY?.get()) : 141
          }}
          closeButton={!(windowManager()['mapSetting'].disabled.value) ? () => windowManager()['mapSetting'].open.set(false) : false}
          isExpanded={windowManager()['mapSetting'].minimized}
        >
          <Box className='leaflet-control'>
            {Object.keys(windowManager()['mapSetting']['markersView']).filter((sectionSelect) => windowManager()['mapSetting']['markersView'][sectionSelect].hide.get()).map((section, index) => {
              return (
                <Box key={`${section}`} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel
                    label={translate(windowManager()['mapSetting']['markersView'][section].title.get())}
                    control={
                      <Checkbox
                        checked={windowManager()['mapSetting']['markersView'][section].show.get()}
                        onChange={() => {
                          if (windowManager()['mapSetting']['markersView'][section].show.get() && windowManager()['mapSetting']['markersView'][section].get()['children']) {
                            Object.keys(windowManager()['mapSetting']['markersView'][section].get()['children']).map((children) => {
                              windowManager()['mapSetting']['markersView'][section]['children'][children].show.set(false)
                            })
                          }
                          windowManager()['mapSetting']['markersView'][section].show.set(!windowManager()['mapSetting']['markersView'][section].show.get())
                        }}
                        sx={{
                          color: '#41AEAA',
                          '&.Mui-checked': {
                            color: '#41AEAA',
                          },
                        }}
                      />
                    }
                  />
                  {windowManager()['mapSetting']['markersView'][section].get()['children'] ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                      {Object.keys(windowManager()['mapSetting']['markersView'][section]['children']).map((sectionChildren, indexChildren) => {
                        return (<FormControlLabel
                          key={`${sectionChildren}`}
                          label={translate(windowManager()['mapSetting']['markersView'][section]['children'][sectionChildren].title.get())}
                          control={
                            <Checkbox
                              checked={windowManager()['mapSetting']['markersView'][section]['children'][sectionChildren].show.get()}
                              onChange={() => {
                                if (!windowManager()['mapSetting']['markersView'][section]['children'][sectionChildren].show.get()) {
                                  if (!windowManager()['mapSetting']['markersView'][section].show.get()) {
                                    windowManager()['mapSetting']['markersView'][section].show.set(true)
                                  }
                                }
                                windowManager()['mapSetting']['markersView'][section]['children'][sectionChildren].show.set(!windowManager()['mapSetting']['markersView'][section]['children'][sectionChildren].show.get())
                              }}
                              sx={{
                                color: '#41AEAA',
                                '&.Mui-checked': {
                                  color: '#41AEAA',
                                },
                              }}
                            />
                          }
                        />)
                      })}
                    </Box> : <></>}
                </Box>
              )
            })}
          </Box>
        </StandardModal>
      }
    </>
    , modal);
}