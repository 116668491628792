import React from "react";
import {IconButton, Grid} from '@mui/material';
import {Add} from '@mui/icons-material';
import { useLang } from '~/hooks/useLang';


export default function SaveButton({canCreate, create}){
  const { translate } = useLang()
 
  return (
    <Grid item>
      <IconButton title={translate('New Entry')} disabled={!canCreate} onClick={() => create ? create() : false}>
        <Add/>
      </IconButton>
    </Grid>);

}