import React from 'react';
import { useLang } from '../../../../../../hooks/useLang';
import { Grid } from '@mui/material';
import TabListMessageContent from '../TabListMessageContent';
import {
  Reply as ReplyIcon,
  Send as SendIcon
} from '@mui/icons-material';

import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import useMessageState from '../../../../stores/MessageState';

export default function TabListMessage() {
  const { translate } = useLang()
  const { messageReceived, messageSent, syncMessageReceived, syncMessageSent, currentListMessage } = useMessageState()
  const handleTabMessage = (event: React.SyntheticEvent, value: string) => {
    currentListMessage().set(value);
  };

  React.useEffect(() => {
    syncMessageReceived()
    syncMessageSent()
  }, [])

  return (
    <TabContext value={currentListMessage().get()}>
      <Grid container columns={12} direction={'column'}>
        <Grid item>
          <TabList onChange={handleTabMessage} variant="fullWidth">
            <Tab label={translate('Received')} value='received' />
            <Tab label={translate('Sent')} value='sent' />
          </TabList>
        </Grid>
        <Grid item sx={{height: '100%'}}>
          <TabPanel sx={{ padding: 0 }} value="received">
            <TabListMessageContent value="received" listMessageState={messageReceived()} />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value="sent">
            <TabListMessageContent value="sent" listMessageState={messageSent()} />
          </TabPanel>
        </Grid>
      </Grid>
    </TabContext>
  )
}