import React from 'react';
import { Grid, Card, CardContent, Typography, SxProps, Theme } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import WidgetGeneratorFields from '../WidgetGeneratorFields';

interface WidgetCreateProps {
  readonly title: string;
  readonly data: any;
  readonly sx?: SxProps<Theme>;
}

export default function WidgetCreate({ title, data, sx={ marginBottom: '16px' } }: WidgetCreateProps) {
  const { translate } = useLang();

  return (
    <Card>
      <CardContent>
        <Typography
          sx={sx}
        >
          {translate(title)}
        </Typography>
        <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 4, md: 10 }} >
          {data.length > 0 ?
            <WidgetGeneratorFields type={title} data={data} /> :
            <Typography
              sx={{ padding: '16px' }}
            >
              {translate('No data available')}
            </Typography>
          }
        </Grid>
      </CardContent>
    </Card>
  )
}