import Cookies from 'universal-cookie';
import constants from '~/core/constants';


const cookies = new Cookies();

export const DEFAULT_STORAGE_KEY = `@Despacho`;

export const getStorageToken = () => {
    const tokenString = cookies.get(constants.keys.token);
    return tokenString ? tokenString : null;
};

export const getStorageStation = () => {
    const stationString = cookies.get(constants.keys.cookies.identificationKey);
    return stationString ? stationString : null;
};

export const getStorageAnonymous = () => {
    const stationString = cookies.get(constants.keys.cookies.anonymousIdentificationKey);
    return stationString ? stationString : null;
};