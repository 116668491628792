import { useHookstate } from "@hookstate/core";
import { Autocomplete, FormControl, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react"
import ICity from "~/features/City/interfaces/ICity";
import { getCities, getCityById } from "~/features/City/services";
import { useLang } from "~/hooks/useLang";
import { CityResponse } from "./interfaces";
import { Validation as validation } from '@hookstate/validation';

interface SelectCityProps {
  readonly onCityChange: (value: number | null) => void;
  readonly cityId?: number | null;
  readonly disabled?: boolean;
  readonly readonly?: boolean;
  readonly isInvalidRequest?:any;
}

export default function FilterAutocompleteCity({ onCityChange, readonly = false, cityId, disabled, isInvalidRequest }: SelectCityProps) {
  const { translate } = useLang();
  const [options, setOptions] = React.useState<readonly CityResponse[]>([])
  const [value, setValue] = useState<CityResponse | null>(null);
  const loading = useHookstate(false);
  const isFirstRender = React.useRef(true);

  useEffect(() => {
    if (value) {
      if (cityId != (value ? value['id'] : null)) {
        isFirstRender.current = true;
        setValue(null);
      }
    }
  }, [])

  useEffect(() => {
    getCities().then(cities => {
      setOptions(cities)
    })
  }, [])

  useEffect(() => {
    if (!cityId) {
      setValue(null)
    } else {
      getCityById(cityId)
        .then((result) => {
          setValue(result);
        })
      isFirstRender.current = false;
    }
  }, [cityId])

  if (!readonly) {

    return (
      <FormControl fullWidth size='small'>
        <Autocomplete
          id='filter-city'
          options={options}
          disabled={disabled}
          value={value}
          noOptionsText={translate('No options')}
          size='small'
          loading={loading.get()}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option.name ?? ''}
          onChange={(_, city) => {
            setValue(city)
            onCityChange(city?.id ?? null)
          }}
          renderInput={(params) => (
            <TextField
            {...params}
            label={translate('City')}
            error={isInvalidRequest && Boolean(!cityId)}
            />
          )}
        />
      </FormControl>
    )
  } else {
    return (
      <Typography>
        {translate('City')}: {value?.name ?? translate('No information')}
      </Typography>
    )
  }
}