import React from 'react';
import { IconButton, Grid } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';
import { Downgraded } from '@hookstate/core';

export default function EntryShowProcedureButton() {
  const { translate } = useLang()
  const { windowManager } = useSystemState();
  const isDisable = windowManager()['entryProcedure'].procedure?.attach(Downgraded).get()?.length ? false : true

  return (
    <Grid item>
      <IconButton 
      title={translate('See Procedures')} 
      disabled={isDisable} 
      onClick={() => {
        windowManager()['entryProcedure'].open.set(true)
        }}
      >
        <HelpIcon />
      </IconButton>
    </Grid>);
}