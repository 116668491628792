import React from 'react'
import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
} from '@mui/material'
import { useLang } from '~/hooks/useLang'
import { useHookstate } from '@hookstate/core'
import InfoIcon from '@mui/icons-material/Info'
import Close from '@mui/icons-material/Close'

interface DispatchModalInfoProps {
  readonly title: string
  readonly code: string | null
  readonly statusName: string | null
  readonly typeName: string | null
  readonly subtypeName: string | null
  readonly location: string | null
  readonly latitude: number | null
  readonly longitude: number | null
}

export default function DispatchModalInfo({
  title,
  code,
  latitude,
  location,
  longitude,
  statusName,
  subtypeName,
  typeName,
}: DispatchModalInfoProps) {
  const { translate } = useLang()
  const modalResume = useHookstate(false)

  return (
    <>
      <IconButton
        title={translate(translate('See Resume'))}
        sx={{ margin: '0 !important' }}
        onClick={() => modalResume.set(!modalResume.get())}
      >
        {<InfoIcon />}
      </IconButton>
      <Dialog
        open={modalResume.get()}
        onClose={() => modalResume.set(false)}
        maxWidth={'xs'}
        fullWidth
      >
        <DialogTitle minWidth={'320px'} variant="h5">
          <Typography
            variant="h5"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {translate(title)}
            <IconButton
              title={translate('Close')}
              onClick={() => modalResume.set(false)}
            >
              <Close />
            </IconButton>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth size="small" sx={{ mt: 1 }}>
            <Typography>
              {translate('Code')}: {code ?? translate('No information')}
            </Typography>
            <Typography>
              {translate('Status')}: {statusName ?? translate('No information')}
            </Typography>
            <Typography>
              {translate('Type')}: {typeName ?? translate('No information')}
            </Typography>
            <Typography>
              {translate('Subtype')}:{' '}
              {subtypeName ?? translate('No information')}
            </Typography>
            <Typography>
              {translate('Location')}: {location ?? translate('No information')}
            </Typography>
          </FormControl>
          <Button
            variant="text"
            color="primary"
            onClick={() =>
              window.open(
                `https://www.google.com.br/maps?q=loc:${Number(
                  latitude ?? 0
                ).toFixed(8)},${Number(longitude ?? 0).toFixed(8)}`
              )
            }
            sx={{
              textTransform: 'none',
              textDecorationLine: 'underline',
              color: 'text.primary',
              ':hover': {
                bgcolor: 'transparent',
                color: 'primary.main',
              },
              fontSize: 16,
              pl: 0,
            }}
          >
            {translate('Open in Google Maps')}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}
