import React, { useEffect } from 'react'
import { useHookstate } from '@hookstate/core'
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Button,
  Card,
  CardHeader,
  Collapse,
  Grid,
  FormHelperText,
  FormControl,
} from '@mui/material'
import { useLang } from '~/hooks/useLang'
import CancelIcon from '@mui/icons-material/Cancel'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '~/components/ErrorFallback'
import Layout from '~/components/Layout'
import Loading from '~/components/Loading'
import notify from '~/utils/notify'
import { authFetch } from '~/services/fetch'
import useReportState from '../../stores/ReportState'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import FilterSwitch from './SpecificFilters/FilterSwitchGroupLocation'
import { makeRequestToGetReportSearchMultipleComents } from '../../services/report'
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod'
import FilterAutocompleteAgencies from '~/components/FilterAutocompleteAgencies'
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups'
import { LoadingButton } from '@mui/lab'
import GenerateReportButton from '../../components/GenerateReportButton'
import FilterSwitchOtherDispatchsGroup from '~/components/FilterSwitchOtherDispatchsGroups'
import FilterKeyword from '../../components/FilterKeyword'
import FilterAutocompleteCity from '~/components/FilterSelectAutocompleteCity'
import FilterAutocompleteEntryOrigin from '~/components/FilterAutocompleteEntryOrigin'
import FilterAutocompleteDispatchGroupSet from '~/components/FilterAutocompleteDispatchGroupSet'

// eslint-disable-next-line max-lines-per-function
export default function SearchMultipleComments() {
  const { reportData, loadingReport } = useReportState()
  const { translate } = useLang()
  const comments = useHookstate('')
  const result = useHookstate([''])
  const expandFilterSearch = useHookstate<boolean>(true)
  const includeAllWords = useHookstate<boolean>(false)
  const lockFields = useHookstate<boolean>(false)
  const isLoading = useHookstate<boolean>(false)
  const startDate = useHookstate<string | null>(null)
  const finishDate = useHookstate<string | null>(null)
  const agencyIds = useHookstate<readonly number[]>([])
  const deviceId = useHookstate<number | null>(null)
  const groupId = useHookstate<readonly number[]>([])
  const labelButton = useHookstate<string | null>(null)
  const withSolvedThirdParty = useHookstate<boolean>(false)
  const location = useHookstate<string | null>(null)
  const cityId = useHookstate<number | null>(null)
  const entryOriginId = useHookstate<number | null>(null)
  const groupSetId = useHookstate<number | null>(null)

  const period = useHookstate('')
  const reportName = 'search-multiple-coments'

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])

  const makeCommentsRequest = () => {
    result.set(comments.get().split(';'))
  }

  function generateReport() {
    makeCommentsRequest()
    if (comments.value && startDate && finishDate) {
      loadingReport().set(true)
      isLoading.set(true)
      makeRequestToGetReportSearchMultipleComents({
        keyWords: result.get(),
        includeAllWords: includeAllWords.get(),
        startDate: startDate.get(),
        finishDate: finishDate.get(),
        dispatchGroupsId: groupId.get(),
        agencyIds: agencyIds.get(),
        withSolvedThirdParty: withSolvedThirdParty.get(),
        location: location.get(),
        cityId: cityId.get(),
        entryOriginId: entryOriginId.get(),
        groupSetId: groupSetId.get(),
      })
        .then((response) => {
          reportData().set(response)
          window.open(`/report/result/${reportName}`)
        })
        .finally(() => {
          loadingReport().set(false)
          isLoading.set(false)
        })
    } else {
      notify({ message: translate('Keyword is mandatory'), type: 'error' })
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true}>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader
                title={`${translate('Report')}: ${translate(
                  `Search multiple coments`
                )}`}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader
            title={`${translate('Filters')}`}
            avatar={
              <IconButton
                onClick={() =>
                  expandFilterSearch.set(!expandFilterSearch.get())
                }
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginRight: 1,
                  padding: 0,
                }}
              >
                {expandFilterSearch.value ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            }
          />
          <Collapse in={expandFilterSearch.get()}>
            <Grid
              container
              xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
              }}
            >
              <Grid container direction={'column'} rowGap={2}>
                <Grid container direction={'column'} rowGap={2} xs={12}>
                  <Grid item xs={5} sx={{ pl: 1, pt: 1 }}>
                    <FilterKeyword keyWords={comments} />
                  </Grid>

                  <Grid container direction={'row'}>
                    <FilterSelectPeriod
                      disabled={lockFields.get()}
                      onStartDateChange={(value) =>
                        startDate.set(value?.toJSON() ?? null)
                      }
                      onFinishDateChange={(value) =>
                        finishDate.set(value?.toJSON() ?? null)
                      }
                      onPeriodChange={(value) => {
                        period.set(value)
                      }}
                      period={period.get()}
                      required
                    />
                  </Grid>

                  <Grid container direction={'row'} xs={12}>
                    <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                      <FilterAutocompleteAgencies
                        disabled={lockFields.get()}
                        agenciesId={agencyIds.get()}
                        onAgenciesChange={(agency) => {
                          labelButton.set(translate('Search'))
                          agencyIds.set(agency)
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                      <FilterAutocompleteDispatchGroupSet
                        disabled={lockFields.get()}
                        dispatchGroupSetId={groupSetId?.get() ?? undefined}
                        onGroupDispatchSetChange={(group) => {
                          groupSetId?.set(group)
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                      <FilterAutocompleteDispatchsGroups
                        disabled={lockFields.get()}
                        agencyId={agencyIds.get() ?? undefined}
                        dispatcheGroups={groupId.get() ?? undefined}
                        onGroupDispatchChange={(group) => {
                          groupId.set(group)
                          deviceId.set(null)
                          labelButton.set(translate('Search'))
                        }}
                        groupSetId={groupSetId.get()}
                      />
                    </Grid>
                    <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                      <FilterAutocompleteCity
                        disabled={lockFields.get()}
                        onCityChange={(city) => {
                          cityId?.set(city)
                          labelButton.set(translate('Search'))
                        }}
                        cityId={cityId?.get()}
                      />
                    </Grid>
                    <Grid item xs={4} sx={{ pl: 1, pt: 1 }}>
                      <FormControl variant="standard" fullWidth>
                        <OutlinedInput
                          id="location"
                          value={location.get()}
                          size="small"
                          placeholder={translate('Location')}
                          onChange={(event) => {
                            location.set(event.target.value)
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle clear location"
                                onClick={() => {
                                  location.set('')
                                }}
                                edge="end"
                              >
                                {location.get()?.length ? (
                                  <CancelIcon fontSize="small" />
                                ) : (
                                  ''
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                      <FilterAutocompleteEntryOrigin
                        disabled={lockFields.get()}
                        onEntryOriginChange={(entryOrigin) => {
                          entryOriginId?.set(entryOrigin)
                          labelButton.set(translate('Search'))
                        }}
                        originId={entryOriginId?.get()}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction={'row'} xs={12}>
                    <Grid item xs={2.3} sx={{ pl: 2, pt: 1 }}>
                      <FilterSwitch
                        label={translate('Contain all keywords')}
                        onCheckedChange={() => {
                          includeAllWords.set(!includeAllWords.value)
                        }}
                        disabled={lockFields.get()}
                        checked={includeAllWords.get()}
                      />
                    </Grid>

                    <Grid item xs={4} sx={{ pl: 1, pt: 1 }}>
                      <FilterSwitchOtherDispatchsGroup
                        onCheckedChange={() => {
                          labelButton.set(translate('Search'))
                          withSolvedThirdParty.set(!withSolvedThirdParty.value)
                        }}
                        disabled={lockFields.get()}
                        checked={withSolvedThirdParty.get()}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: 2
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      comments.set('')
                      period.set('')
                      deviceId.set(null)
                      agencyIds.set([])
                      groupSetId.set(null)
                      groupId.set([])
                      entryOriginId.set(null)
                      cityId.set(null)
                      location.set('')
                      withSolvedThirdParty.set(false)
                      includeAllWords.set(false)
                    }}
                    sx={{ height: '40px' }}
                  >
                    {translate('Clear Filters')}
                  </Button>
                  <GenerateReportButton
                    isLoading={isLoading.get()}
                    makeReport={generateReport}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary>
  )
}
