import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IVehicleModel from '../interfaces/IVehicleModel';
import Layout from '~/components/Layout';

export default function Create() {     
  const {register, handleSubmit, control, setValue:setFormValue} = useForm<IVehicleModel>();
  const fields = [
      { name: 'name', type: 'string'},
      { name: 'brand_id', type: 'selectAutoComplete', model: 'vehicle-model', placeholder: 'VehicleBrand:name'},
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate model={'vehicle-model'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        messageSuccess={'Vehicle model successfully created!'}  
        title={'Add vehicle model'}  
        subheader={'Add vehicle model'}
        setFormValue={setFormValue} />
      </Layout>
    )
}
