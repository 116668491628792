/* eslint-disable max-lines-per-function */
import React from 'react'
import { Button, Grid } from '@mui/material'
import { State, StateMethods } from '@hookstate/core'
import { useLang } from '~/hooks/useLang'
import { LoadingButton } from '@mui/lab'
import FilterAutocompleteStations from '~/components/FilterAutocompleteStations'
import FilterAutocompleteAgency from '~/components/FilterAutocompleteAgency'
import FilterAutocompleteContactCenter from '~/components/FilterAutocompleteContactCenter'
import FilterAutocompleteStationAuthenticated from '~/components/FilterAutocompleteBoolean'
import FilterAutocompleteProcedure from '~/components/FilterAutocompleteProcedure'
import FilterAutocompleteType from '~/components/FilterAutocompleteType'
import FilterAutocompleteSubtypesByType from '~/components/FilterAutocompleteSubtypeByType'
import FilterAutocompleteProcedureConfigRelated from '~/components/FilterAutocompleteProcedureConfigRelated'
import FilterAutocompleteAreaPlan from '~/components/FilterAutocompleteAreaPlan'
import { InputSearchWithClear } from '~/components/InputSearchWithClear'
import FilterAutocompleteDispatchGroup from '~/components/FilterAutocompleteDispatchGroup'
import FilterAutocompleteDeviceType from '~/components/FilterAutocompleteDeviceType'
import FilterAutocompleteSpecie from '~/components/FilterAutocompleteSpecie'
import FilterAutocompleteOperationUnit from '~/components/FilterAutocompleteOperationUnit'

export interface FilterArrayProps {
    readonly type: string;
    readonly variable: State<any> | StateMethods<any>;
    readonly dependent?: State<any> | StateMethods<any>;
    readonly placeholder?: string;

}

export interface FiltersDashboardProps {
    readonly loading?: State<boolean>
    readonly lockFields?: boolean;
    readonly searchButton?: () => void;
    readonly clearFilters?: () => void;

    readonly filters: FilterArrayProps[] | undefined;
}

export default function FiltersDashboard({
    loading,
    lockFields,
    clearFilters,
    searchButton,
    filters
}: FiltersDashboardProps) {
    const { translate } = useLang()

    function showFilters(type, variable, dependent, placeholder) {
        const typeFilter = {
            'station_ids': <FilterAutocompleteStations stationsId={variable.get()} onStationsChange={(station) => { variable.set(station) }} disabled={lockFields} />,
            'agency_id': <FilterAutocompleteAgency agencyId={variable.get()} onAgencyChange={(agency) => variable.set(agency)} disabled={lockFields} />,
            'contact_center_id': <FilterAutocompleteContactCenter contactCenterId={variable.get()} onContactCenterChange={(contactCenter) => variable.set(contactCenter)} disabled={lockFields} />,
            'is_authenticated': <FilterAutocompleteStationAuthenticated label={'Authenticated Stations'} isChecked={variable.get()} onIsCheckedChange={(isAuthenticated) => variable.set(isAuthenticated)} disabled={lockFields} />,
            'procedure_id': <FilterAutocompleteProcedure procedureId={variable.get()} onProcedureChange={(procedure) => variable.set(procedure)} size={'small'} />,
            'type_id': <FilterAutocompleteType typeId={variable.get()} onTypeChange={(type) => variable.set(Number(type?.id) ?? null)} />,
            'subtype_id': <FilterAutocompleteSubtypesByType hasType typeId={dependent ? dependent.get() : null} subtypeId={variable.get()} onSubtypeChange={(subtype) => variable.set(subtype?.id ?? null)} />,
            'related_to': <FilterAutocompleteProcedureConfigRelated procedureConfigRelatedId={variable.get()} onProcedureConfigRelatedChange={(relatedTo) => variable.set(relatedTo)} disabled={lockFields} />,
            'area_plan_id': <FilterAutocompleteAreaPlan onAreaPlanChange={(areaPlan) => variable.set(areaPlan)} areaPlanId={variable.get()} disabled={lockFields} />,
            'name': <InputSearchWithClear search={variable} placeholder={translate(placeholder)} sx={{ width: '100%', height: '100%' }} />,
            'dispatch_group_id': <FilterAutocompleteDispatchGroup disabled={lockFields} dispatchGroupId={variable.get() ?? undefined} onGroupDispatchChange={(group) => variable.set(group)} agencyId={dependent ? dependent.get() : null} />,
            'tracking_id': <InputSearchWithClear search={variable} placeholder={translate(placeholder)} sx={{ width: '100%', height: '100%' }} />,
            'device_type_id': <FilterAutocompleteDeviceType deviceTypeId={variable.get()} onDeviceTypeChange={(deviceType) => variable.set(deviceType)} disabled={lockFields} />,
            'active': <FilterAutocompleteStationAuthenticated label={'Actived Device'} isChecked={variable.get()} onIsCheckedChange={(isActive) => variable.set(isActive == null ? isActive : isActive == 1 ? true : false)} disabled={lockFields} isBooleanField />,
            'specie_id': <FilterAutocompleteSpecie onSpecieChange={(specie) => variable.set(specie)} specieId={variable.get()} disabled={lockFields} />,
            'operation_unit_id': <FilterAutocompleteOperationUnit onOperationUnitChange={(operationUnit) => variable.set(operationUnit)} operationUnitId={variable.get()} disabled={lockFields} />
        }


        return typeFilter[type]
    }

    return (


        <Grid container
            display={'flex'}
            xs={12}
            sx={{
                pt: 1, 
                pb: 2,
                gap: 2,
            }}>
            {filters?.map((item, index) => {
                return (
                    <Grid item xs={2} key={index}>
                        {showFilters(item.type, item.variable, item.dependent, item.placeholder)}
                    </Grid>
                )
            })}
            <Grid item display={'flex'} justifyContent={'right'} sx={{gap: 2, mr: 0, ml: 'auto'}}>
                <Button variant="outlined"
                    onClick={clearFilters}
                    sx={{ height: '40px' }}
                >
                    {translate('Clear Filters')}
                </Button>
                <LoadingButton
                    loading={loading?.get()}
                    variant="contained"
                    onClick={searchButton}
                    sx={{ height: '40px' }}
                >
                    {translate('Search')}
                </LoadingButton>
            </Grid>

        </Grid>


    )
}