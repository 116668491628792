import React, { ReactNode } from 'react';
import { Button, SxProps, Theme } from '@mui/material';
import './styles.scss';

type Color = { 
    readonly [key: string]: 
    "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined 
}

type ButtonProps = {
    readonly type: "submit" | "cancel",
    readonly children: React.ReactNode,
    readonly disabled?: boolean;
    readonly onClick?: React.MouseEventHandler<HTMLButtonElement>;
    readonly sx?: SxProps<Theme>;
}


export default function ButtonForm(props: ButtonProps) {
    
    return (
        <Button
            {...props}
            type={ props.type == 'submit' ? 'submit' : 'button' }
            variant={props.type == 'submit' ? 'contained' : 'outlined'}
            disabled={props.disabled}
            onClick={props.onClick}
            sx={{
                ...props.sx,
                marginLeft: props.type == 'cancel' ? '0px' : 'auto' 
            }}
        >
            {props.children}
        </Button>
    )
}