import React from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { dispatchDefault } from '~/features/Dispatch/stores/DispatchState';
import { QRCodeCanvas } from 'qrcode.react';
import dayjs from 'dayjs';

interface RequestDataPrintResponseProps {
  readonly RequestDataPrintResponse: typeof dispatchDefault
}

export default function RequestDataPrintResponse({ RequestDataPrintResponse }: RequestDataPrintResponseProps){
  const { translate } = useLang();
    
  return (
    <Container fixed={false}>
      <Grid container xs={12} sx={{ marginBottom: 2 }}>
        <Grid container xs={12}>
        <Grid item xs={4}>
          <Typography variant='subtitle1'>
            <b>
              {translate('Register number')}
              :
            </b>
          </Typography>
          <Typography variant='subtitle2'>
            {RequestDataPrintResponse?.code ?? `* ${translate('Uninformed')} *`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle1'>
            <b>
              Data/Hora do registro:
            </b>
          </Typography>
          <Typography variant='subtitle2'>
            {dayjs(RequestDataPrintResponse?.occurrence?.createdAt).isValid() ? dayjs(RequestDataPrintResponse?.occurrence?.createdAt).format('DD/MM/YYYY HH:mm:ss') : `* ${translate('Uninformed')} *`}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant='subtitle1'>
            <b>
              Data/Hora do fato:
            </b>
          </Typography>
          <Typography variant='subtitle2'>
            {dayjs(RequestDataPrintResponse?.occurrence?.entryAt).isValid() ? dayjs(RequestDataPrintResponse?.occurrence?.entryAt).format('DD/MM/YYYY HH:mm:ss') : `* ${translate('Uninformed')} *`}
          </Typography>
        </Grid>
        </Grid>

        <Grid container xs={12}>
        <Grid item xs={8}>
          <Typography variant='subtitle1'>
            <b>
              {translate('Location')}
              :
            </b>
          </Typography>
          <Typography variant='subtitle2'>
            {RequestDataPrintResponse?.occurrence?.location ? RequestDataPrintResponse?.occurrence?.location : `* ${translate('Uninformed')} *`}
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography variant='subtitle1'>
            <b>
              {translate('Reference')}
              :
            </b>
          </Typography>
          <Typography variant='subtitle2'>
            {RequestDataPrintResponse?.occurrence?.reference && RequestDataPrintResponse?.occurrence?.reference != 'null' ? RequestDataPrintResponse?.occurrence?.reference : `* ${translate('Uninformed')} *`}
          </Typography>
        </Grid>
        </Grid>

      <Grid container xs={8}>
        <Grid item xs={6}>
          <Typography variant='subtitle1'>
            <b>
              {translate('City')}
              :
            </b>
          </Typography>
          <Typography variant='subtitle2'>
            {RequestDataPrintResponse?.occurrence?.city?.name ? RequestDataPrintResponse?.occurrence?.city?.name : `* ${translate('Uninformed')} *`}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant='subtitle1'>
            <b>
              {translate('District')}
              :
            </b>
          </Typography>
          <Typography variant='subtitle2'>
            {RequestDataPrintResponse?.occurrence?.district?.name ?? `* ${translate('Uninformed')} *`}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant='subtitle1'>
            <b>
              {translate('Latitude / Longitude')}
              :
            </b>
          </Typography>
          <Typography variant='subtitle2'>
            {RequestDataPrintResponse?.occurrence?.latitude ? RequestDataPrintResponse?.occurrence?.latitude?.toFixed(8) : `* ${translate('Uninformed')} *`} / {RequestDataPrintResponse?.occurrence?.longitude ? RequestDataPrintResponse?.occurrence?.longitude?.toFixed(8) : `* ${translate('Uninformed')} *`}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant='subtitle1'>
            <b>
              {translate('Origin')}
              :
            </b>
            </Typography>
          <Typography variant='subtitle2'>
            {RequestDataPrintResponse?.occurrence?.entry_origin ? RequestDataPrintResponse?.occurrence?.entry_origin?.name : `* ${translate('Uninformed')} *`}
          </Typography>
          {/* <Typography variant='subtitle2'>{RequestDataPrintResponse?.occurrence?.phone ?? `* ${translate('Uninformed')} *`}</Typography> */}
        </Grid>

        <Grid item xs={6}>
          <Typography variant='subtitle1'>
            <b>
              {translate('Requester Contact')}
              :
            </b>
          </Typography>
          <Typography variant='subtitle2'>
            {RequestDataPrintResponse?.occurrence?.phone ?? `* ${translate('Uninformed')} *`}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography variant='subtitle1'>
            <b>
              {translate('Requester')}
              :
            </b>
          </Typography>
          <Typography variant='subtitle2'>
            {RequestDataPrintResponse?.occurrence?.name ?? `* ${translate('Uninformed')} *`}
            </Typography>
        </Grid>

          <Grid item xs={6}>
            <Typography variant='subtitle1'>
              <b>
                {translate('Original type/subtype')}
                :
              </b>
              </Typography>
            <Typography variant='subtitle2'>
              {`${RequestDataPrintResponse?.occurrence?.entry_type?.name} ${RequestDataPrintResponse?.occurrence?.entry_subtype?.name ? RequestDataPrintResponse?.occurrence?.entry_subtype?.name : ''}`  ?? `* ${translate('Uninformed')} *`}
              </Typography>
          </Grid>

      </Grid>

        <Grid container xs={4}>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              <b>
                {translate('QR Code')}
                :
              </b>
            </Typography>
            <QRCodeCanvas
              value={`Registro : ${RequestDataPrintResponse?.code ?? `* ${translate('Uninformed')} *`}\nLocal : ${RequestDataPrintResponse?.occurrence?.location ? RequestDataPrintResponse?.occurrence?.location : `* ${translate('Uninformed')} *`}\nTipo do Chamado : ${RequestDataPrintResponse?.type?.name ? `${RequestDataPrintResponse?.type?.name} ${RequestDataPrintResponse?.subtype?.name ? RequestDataPrintResponse?.subtype?.name : '' }` : `* ${translate('Uninformed')} *`}\nStatus : ${RequestDataPrintResponse?.status?.name ? RequestDataPrintResponse?.status?.name : `* ${translate('Uninformed')} *`}\nFinalizado : ${RequestDataPrintResponse?.closedStatus?.name ? RequestDataPrintResponse?.closedStatus?.name : `* ${translate('Uninformed')} *`}
            `}
            />
          </Grid>
        </Grid>

      </Grid>
    </Container>
  );
}