import React from 'react';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { AppRouteProps } from '~/routes';

export default function SubtypeRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    {path: '/subtype', component: Index, isProtected: true, permissions: ['subtype_index']},
    {path: '/subtype/create', component: Create, isProtected: true, permissions: ['subtype_create']},
    {path: '/subtype/edit/:id', component: Edit, isProtected: true, permissions: ['subtype_edit']},

  ];

  return routes;
}
