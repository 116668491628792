import React from 'react'
import List from './pages/List'
import Dashboard from './pages/Dashboard'
import Index from './pages/Index'
import Print from './pages/Print'
import Coordination from './pages/Coordination'
import CoordinationCreate from './pages/CoordinationCreate'
import { AppRouteProps } from '~/routes'
import { useParams } from 'react-router-dom'
import ListVehicles from './pages/ListVehicles'
import ListDispatch from './pages/ListDispatch'
import Summary from './pages/Summary'
import ListVehiclesSocket from './pages/ListVehiclesSocket'
import ListDispatchesSocket from './pages/ListDispatchesSocket'
import ManagementMap from './pages/ManagementMap';
import PredictionMap from './pages/PredictionMap';
import HistoricMap from './pages/HistoricMap'

export default function DispatchRoutes(): readonly AppRouteProps[] {
  const routes: readonly AppRouteProps[] = [
    {
      path: '/dispatch',
      component: Index,
      isProtected: true,
      permissions: ['dispatch_index'],
    },
    {
      path: '/dispatch/list',
      component: List,
      isProtected: true,
      permissions: ['dispatch_list'],
    },
    {
      path: '/dispatch/dashboard',
      component: Dashboard,
      isProtected: true,
      permissions: ['dispatch_dashboard'],
    },
    {
      path: '/dispatch/print/:id',
      component: Print,
      isProtected: true,
      permissions: ['dispatch_index'],
    },
    {
      path: '/dispatch/coordination',
      component: Coordination,
      isProtected: true,
      permissions: ['dispatch_coordination_index'],
    },
    {
      path: '/dispatch/coordination/create',
      component: CoordinationCreate,
      isProtected: true,
      permissions: ['dispatch_coordination_create'],
    },
    {
      path: '/coordination/summary/:id',
      component: Summary,
      isProtected: true,
      permissions: ['dispatch_coordination_create'],
    },
    {
      path: '/dispatch/vehicles/list',
      component: ListVehicles,
      isProtected: true,
      permissions: [],
    },
    {
      path: '/dispatch/dispatches/list',
      component: ListDispatch,
      isProtected: true,
      permissions: [],
    },
    {
      path: '/dispatch/dispatches/list-full',
      component: ListDispatchesSocket,
      isProtected: true,
      permissions: [],
    },
    {
      path: '/dispatch/vehicles/list-full',
      component: ListVehiclesSocket,
      isProtected: true,
      permissions: [],
    },
    {
      path: '/dispatch/management-map',
      component: ManagementMap,
      isProtected: true,
      permissions: ['dispatch_management_map'],
    },
    {
      path: '/dispatch/prediction-map',
      component: PredictionMap,
      isProtected: true,
      permissions: ['dispatch_prediction'],
    },
    // {
    //   path: '/dispatch/historic-map',
    //   component: HistoricMap,
    //   isProtected: true,
    //   permissions: [],
    // }
  ]

  return routes
}
