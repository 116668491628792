import React, { useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, SelectChangeEvent, Select, MenuItem, IconButton } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { getOperationService, setOperationService } from '~/features/Operation/services/index';
import IOperation from '~/features/Operation/interfaces/IOperation';
import { useHookstate } from '@hookstate/core';
import CloseIcon from '@mui/icons-material/Close';


export default function ChangeOperationDialog({ open, dispatchId, refresh }) {
  const { translate } = useLang();
  const operation = useHookstate<string>('');
  const operations = useHookstate<readonly IOperation[]>([])

  useEffect(() => {
    getOperationService().then((data) => {
      operations.set(data)
    })
  }, [])

  return (
    <Dialog fullWidth={true} open={open.get()} onClose={() => open.set(false)}>
      <DialogTitle>
        {translate('Operation')}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth size='small' sx={{ mt: 1 }}>
          <InputLabel size='small' id='operation'>
            {translate('Operation')}
          </InputLabel>
          <Select
            labelId='operation'
            id='operation'
            value={operation.get() ?? ''}
            label={translate('Operation')}
            size='small'
            onChange={(event: SelectChangeEvent) => {
              operation.set(event.target.value)
            }}
            endAdornment={
              operation.get() != '' ? (
                <IconButton onClick={() => operation.set('')} sx={{ marginRight: 2 }}>
                  <CloseIcon />
                </IconButton>
              ) : false
            }
          >
            {operations.get().map((operation) => (
              <MenuItem sx={{ mt: 1 }} key={operation.id} value={operation.id}>
                {operation.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ marginRight: 2 }}>
        <Button
          variant='outlined'
          onClick={() => open.set(false)}
          size='small'
        >
          {translate('Cancel')}
        </Button>
        <Button
          variant='contained'
          size='small'
          onClick={() => {
            setOperationService({ dispatchId: dispatchId, operationId: operation.get() == '' ? null : operation.get() })
            open.set(false)
            refresh.set(!refresh.get())
            operation.set('')
          }}>
          {translate('Submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}