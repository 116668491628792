/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useRef } from 'react';
import { Autocomplete, Checkbox, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { State } from '@hookstate/core';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

interface ContactCenterProps {
    readonly qualifiersSelected?: State<number[]>;
    readonly disabled?: boolean
    readonly clearState?: boolean
}

interface optionProps {
    readonly id: number,
    readonly label: string
}

export default function FilterCheckboxQualifiers({ disabled, qualifiersSelected, clearState}: ContactCenterProps) {
    const { translate } = useLang();
    const options: optionProps[] = [
        {id: 0, label: 'Com veículo informado'},
        {id: 1, label: 'Com pessoa informada'},
        {id: 2, label: 'Com droga apreendida'},
        {id: 3, label: 'Com armamento apreendido'},
        {id: 4, label: 'Com objeto apreendido'},
        {id: 5, label: 'Com organização informado'},
        {id: 6, label: 'Com animal informado'},
    ]

    const autocompleteCheckbox = useRef<HTMLInputElement>(null)

    useEffect(() => {
        const clearButton: HTMLElement = autocompleteCheckbox.current?.getElementsByClassName('MuiAutocomplete-clearIndicator')[0] as HTMLElement

        if(clearButton){
            clearButton.click();
        }
        
    }, [clearState])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
            ref={autocompleteCheckbox}
            multiple
            limitTags={1}
            disabled={disabled}
            sx={{ height: 1 }}
            ListboxProps={{ style: { maxHeight: '15rem' } }}
            id='filter-checkbox-qualifiers'
            size='small'
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option, { selected }) => {
                return(
                <li {...props} key={option.id}>
                <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                />
                {option.label}
                </li>
            )}}
            renderInput={(params) => (
                <TextField {...params} label={translate('Qualifiers')} />
            )}
            onChange={(_, qualifiers) => {
                qualifiersSelected?.set(qualifiers.map(qualifier => qualifier.id).sort((firstItem, secondItem) => firstItem - secondItem))
            }}
            />
        </FormControl>
    )
}