import React, { useEffect, useRef } from "react";
import { MapContainer, TileLayer } from 'react-leaflet';
import { useTheme } from "~/hooks/useTheme";
import { useHookstate } from "@hookstate/core";
import MapLayersControl from "../MapLayersControl";
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '~/components/ErrorFallback';
import IMap from "./interfaces/IMap";
import { getCenterService, getMapUrlService } from '~/features/Map/services/request';
import useMapState from "~/features/Map/stores/MapState";
import L from 'leaflet';

function TileLayerMap({ position, darkMode = false }) {
    const mapReferance = useRef(null) as any
    const map = useMapState().map();
    const mapUrl = useHookstate('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png');
    const accessToken = useHookstate('');
    const mapAttribution = `© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>
    <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>`;

    const setViewMap = ({position, zoom}) => {
        return map.setView(position, zoom, {animate: false})
    }

    const getMapUrlFn = () => getMapUrlService({ darkMode }).then(({ url, token }) => {
        if(url != mapUrl.get()) {
            mapUrl.set(url);
            accessToken.set(token ? token : '');
            
            mapReferance.current.setUrl(url);
        }
    });

    useEffect(() => {
        getMapUrlFn();
    }, [darkMode])

    useEffect(() => {
        if(position){
            setViewMap({position, zoom: 15});
        } 
        else {
            getCenterService().then(({ coordinates, zoom }) => {
                setViewMap({position: coordinates, zoom});
            });
        }
    }, []);

    return (
        <TileLayer
            ref={mapReferance}
            url={mapUrl.get()}
            tileSize={512}
            maxZoom={20}
            maxNativeZoom={20}
            eventHandlers={{
                tileerror: getMapUrlFn
            }}
            zoomOffset={-1}
            id={'mapbox/streets-v11'}
            accessToken={accessToken.get()}
            attribution={mapAttribution}
            updateWhenIdle={false}
            updateWhenZooming={false}
        />
    )
}



export default function Map({ children, height = 'calc(100vh - 74px)', position, enableLayerControl = true }: IMap) {
    const { darkMode } = useTheme();

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <MapContainer
                preferCanvas={true}
                renderer={L.canvas()}
                id="LeafletMapContainer"
                tap={false}
                center={position ? position : [0,0]}
                scrollWheelZoom={true}
                zoom={15}
                maxZoom={20}
                doubleClickZoom={true}
                closePopupOnClick={false}
                dragging={true}
                trackResize={false}
                touchZoom={false}
                style={{ height }}
                >
                <TileLayerMap position={position} darkMode={darkMode} />
                {children}
            </MapContainer>
        </ErrorBoundary>
    )
};