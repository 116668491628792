import React from 'react';
import { Grid, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { UseFormGetValues, UseFormRegister, UseFormSetValue, Controller } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { dateTimeFormatServer } from '~/utils/dateTime';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br'

interface CrudDateTimeFieldProps {
  readonly model: string;
  readonly control: any;
  readonly register: UseFormRegister<any>;
  readonly getValues?: UseFormGetValues<any>;
  readonly setFormValue: UseFormSetValue<any>;
  readonly placeholder?: string;
}

export default function CrudDateTimeField({ register, control, model, getValues, setFormValue, placeholder }: CrudDateTimeFieldProps) {
  const { translate } = useLang()
  const registeredModelFormField = register(model);

  return (
    <Grid item xs={4}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
        <Controller
          {...registeredModelFormField}
          name={model}
          control={control}
          defaultValue={(getValues && getValues(model)) ? dayjs(getValues(model)) : null}
          render={(props) => (
          <DateTimePicker label={translate(`${model.charAt(0).toUpperCase() + model.slice(1)}`)}
            format="DD/MM/YYYY HH:mm"
            ampm={false}
            ampmInClock={false}
            value={props.field.value ? dayjs(props.field.value) : null}
            onChange={(value: Dayjs | null) => {
              props.field.onChange(value);
              setFormValue(model, dayjs(value)?.isValid() ? dayjs(value).toDate() : value)
            }}
            slotProps={{ textField: {
              label: placeholder ? translate(placeholder): translate(`${model.charAt(0).toUpperCase() + model.slice(1)}`),
                margin: 'normal',
                fullWidth: true,
                sx:{ mt: 0 },
                inputProps: {
                  placeholder: translate(`dd/mm/yyyy`)
                }
            } }}
          />)}
        />
      </LocalizationProvider>
    </Grid>
  )
}