import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IOperation from '~/features/Operation/interfaces/IOperation';
import Layout from '~/components/Layout';


export default function Create() {
  const { register, handleSubmit, control, setValue: setFormValue } = useForm<any>();
  const fields = [
    { name: 'name', type: 'string' },
    {
      name: 'interest_point_type_id',
      type: 'selectAutoComplete',
      model: 'interest-point/type',
      placeholder: 'Interest point type'
    },
  ];

  return (
    <Layout marginLayout={true}>
      <CrudCreate model={'interest-point/subtype'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Interest point subtype successfully created!'}
        title={'Add Interest point subtype'}
        subheader={'Add a new interest point subtype'}
        setFormValue={setFormValue} />
    </Layout>
  )
}
