import React, { useEffect } from 'react';
import { Grid, Container, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { State, useHookstate } from '@hookstate/core';
import { getAssociatedOrganizationFromOccurrenceService } from '~/features/Occurrence/services/index';

export interface agencyHistoryProps {
  readonly  id: number,
  readonly  occurrence_id: number,
  readonly  organization_id: number,
  readonly  action_taken_id: number,
  readonly  name: string,
  readonly  cpf: string,
  readonly  registration: string,
  readonly  notes: string,
  readonly  created_at: null,
  readonly  updated_at: null,
  readonly  deleted_at: null,
  readonly  organization: {
    readonly  id: number,
    readonly  name: string
  },
  readonly  action_taken: {
    readonly  id: number,
    readonly  name: string
  }
}

interface ActiveAgenciesPrintProps {
  readonly ActiveAgenciesPrintResponse: agencyHistoryProps[];
}

export default function ActiveAgenciesPrint({ ActiveAgenciesPrintResponse }: ActiveAgenciesPrintProps){
  const { translate } = useLang();

  return (
    <Container fixed={false}>
    <Grid container xs={12} sx={{ marginBottom: 2 }}>
      { ActiveAgenciesPrintResponse.length != 0 ? (
        ActiveAgenciesPrintResponse.map((agency) => (
          <Grid container key={agency?.id} xs={12} sx={{ margin: 1, border: 1, borderStyle: 'dashed' }}>
            <Grid item xs={3} sx={{border: 1, borderStyle: 'dashed', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Typography variant='subtitle1' sx={{ alignItems: 'center' }}><b>{agency?.organization?.name ?? `* ${translate('Uninformed')} *`}</b></Typography>
            </Grid>
            <Grid container xs={9} padding={2}>
              <Grid item xs={6}>
                <Typography variant='subtitle2'><b>{translate('Name')}:</b> {agency?.name ?? `* ${translate('Uninformed')} *`} </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2'><b>{translate('CPF')}:</b> {agency?.cpf}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2'><b>{translate('Organization')}:</b> {agency?.organization?.name ?? `* ${translate('Uninformed')} *`} </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='subtitle2'><b>{translate('Action Taken')}:</b> {agency?.action_taken?.name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle2'><b>{translate('Notes')}:</b> {agency?.notes ?? `* ${translate('Uninformed')} *`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))
      ) : (
      <Grid item xs={12} textAlign={'center'}>
        <Typography variant='subtitle1'><b>{translate('NO INFORMATION REGISTERED')}</b></Typography>
      </Grid>
      )}
    </Grid>
  </Container>
  )
}