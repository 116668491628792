import React from 'react';
import Create from './pages/Create';
import { AppRouteProps } from '~/routes';

export default function clerkRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    {
      path: '/clerk/:occurrence_id/:dispatch_code_reference',
      component: Create,
      isProtected: true,
      permissions: []
    },
  ];

  return routes;
}
