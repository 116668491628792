/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import { StateMethods, useHookstate } from '@hookstate/core'
import CloseIcon from '@mui/icons-material/Close';
import { useLang } from '~/hooks/useLang'
import dayjs from 'dayjs'
import { getStationById } from '../../services'
import IStation from '../../interfaces/IStation'

export interface InfoProps {
    readonly name?: string;
    readonly agency?: {
        readonly name?: string
    };
    readonly identification_key: string | null;
    readonly authentication_key: string | null;
}

export interface ViewMoreProps {
  readonly open: StateMethods<boolean>
  readonly idStation: number | null
  readonly handleClose: () => void
}

export default function ViewMore({ open, idStation, handleClose}: ViewMoreProps) {
    const { translate } = useLang()
    const infoStation = useHookstate<IStation | undefined>(undefined)

    useEffect(() => {
        if(idStation != null){
            getStationById(idStation).then((data) => {
                infoStation.set(data)
            })
        }else{
            infoStation.set(undefined)
        }
    }, [idStation])



    const clickOutModalClose = (event, reason) => {
        if (reason !== 'backdropClick') {
          open.set(false)
        }
      };
      
    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={open.get()}
            onClose={clickOutModalClose}
            disableEscapeKeyDown={true}
        >
            <DialogTitle display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                {translate('Work station')}
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose()}
                >
                    <CloseIcon />
                </IconButton>
                </DialogTitle>
            <DialogContent>
                <Grid container xs={12}>
                { infoStation.get() && (
                    <>
                    <Grid container xs={12} paddingBottom={2} direction={'row'}>
                        <Grid container direction={'row'}>
                            <Grid item xs={3}>
                                <Typography variant='subtitle1' fontWeight={'bold'}>
                                    {translate('Authentication Key')}
                                    :
                                </Typography>
                                <Typography variant='subtitle2'>
                                    {infoStation.get()?.identification_key ?  
                                    translate('Authenticated Station') 
                                    : 
                                    infoStation.get()?.authentication_key ? 
                                    infoStation.get()?.authentication_key 
                                    : 
                                    translate('Uninformed')
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction={'row'} sx={{mt: 2}}>
                            <Grid item xs={3}>
                                <Typography variant='subtitle1' fontWeight={'bold'}>
                                    {translate('Name')}
                                    :
                                </Typography>
                                <Typography variant='subtitle2'>
                                    {infoStation.get()?.name ? infoStation.get()?.name : translate('Uninformed')}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                            <Typography variant='subtitle1' fontWeight={'bold'}>
                                {translate('Agency:name')}
                                :
                            </Typography>
                            <Typography variant='subtitle2'>
                                {infoStation.get()?.agency?.name ? infoStation.get()?.agency?.name : translate('Uninformed')}
                            </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant='subtitle1' fontWeight={'bold'}>
                                    {translate('Contact Center')}
                                    :
                                </Typography>
                                <Typography variant='subtitle2'>
                                    {infoStation.get()?.contact_center?.name ? infoStation.get()?.contact_center?.name : translate('Uninformed')}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant='subtitle1' fontWeight={'bold'}>
                                    {translate('Branch Line')}
                                    :
                                </Typography>
                                <Typography variant='subtitle2'>
                                    {infoStation.get()?.branch_line ? infoStation.get()?.branch_line : translate('Uninformed')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction={'row'}>

                            <Grid item xs={3}>
                                <Typography variant='subtitle1' fontWeight={'bold'}>
                                    {translate('Serial HD')}
                                    :
                                </Typography>
                                <Typography variant='subtitle2'>
                                    {infoStation.get()?.serial_hd ? infoStation.get()?.serial_hd : translate('Uninformed')}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant='subtitle1' fontWeight={'bold'}>
                                    {translate('MAC Address')}
                                    :
                                </Typography>
                                <Typography variant='subtitle2'>
                                    {infoStation.get()?.mac_address ? infoStation.get()?.mac_address : translate('Uninformed')}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant='subtitle1' fontWeight={'bold'}>
                                    {translate('Created date')}
                                    :
                                </Typography>
                                <Typography variant='subtitle2'>
                                    {infoStation.get()?.created_at ? dayjs(infoStation.get()?.created_at).format('DD/MM/YYYY') : translate('Uninformed')}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant='subtitle1' fontWeight={'bold'}>
                                    {translate('Connected')}
                                    :
                                </Typography>
                                <Typography variant='subtitle2'>
                                    {infoStation.get()?.is_connected == 1 ? 'Sim' : 'Não'}
                                </Typography>
                            </Grid>     
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1' fontWeight={'bold'}>
                                {translate('Dispatchs Groups')}
                                :
                            </Typography>
                            <Typography variant='subtitle2'>
                                {infoStation.get()?.dispatch_groups.length ? infoStation.get()?.dispatch_groups.map(item => item.name).join(', ') : translate('Uninformed')}
                            </Typography>
                        </Grid>
                    </Grid>
                    </>
                        )
                }
                </Grid>      
            </DialogContent>
        </Dialog>
    )
}