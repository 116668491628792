import React, { useEffect } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { State, useHookstate } from '@hookstate/core';
import { getDispatchesForOccurrenceById } from '~/features/Occurrence/services';
import IDispatchOccurrence from '~/features/Occurrence/interfaces/IDispatchOccurrence';
import ButtonReloadInfoForClerk from '../ButtonReloadInfoForClerk';
interface AgenciesOccurrenceForClerkProps {
  readonly occurrenceId: number | string;
  readonly reloadInfo: State<boolean>;
  readonly loading: State<boolean>;
}

export default function AgenciesOccurrenceForClerk({ occurrenceId, reloadInfo, loading }: AgenciesOccurrenceForClerkProps) {
  const { translate } = useLang();
  const dispachesForOccurrence = useHookstate<IDispatchOccurrence[] | []>([])

  useEffect(() => {
    loading.set(true)
    getDispatchesForOccurrenceById({ id: occurrenceId }).then((response) => {
      dispachesForOccurrence.set(response)
      loading.set(false)
    })
  }, [reloadInfo.get()])

  return !loading.get() ? (
    <Grid container xs={12} >
      <Grid container xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'start'} gap={2} height={700} overflow={'auto'}>
        {dispachesForOccurrence.get().map((dispatch) =>  (
            <Grid container xs={12} key={dispatch.id}>
              <Grid item xs={3}>
                <Typography variant='subtitle1'>
                <b>{translate('Dispatch code')}:</b>
                </Typography>
                <Typography variant='subtitle2'>
                  {dispatch.code ? dispatch.code : `* ${translate('Uninformed')} *`}
                </Typography>
              </Grid>
            <Grid item xs={3}>
              <Typography variant='subtitle1'>
                <b>{translate('Agency:name')}:</b>
              </Typography>
              <Typography variant='subtitle2'>
                {dispatch.agency.name ? `${dispatch.agency.acronym} - ${dispatch.agency.name}` : `* ${translate('Uninformed')} *`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='subtitle1'>
                <b>{translate('Dispatch Group')}:</b>
              </Typography>
              <Typography variant='subtitle2'>
                {dispatch.dispatch_group.name ? `${dispatch.dispatch_group.name}` : `* ${translate('Uninformed')} *`}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='subtitle1'>
                <b>{translate('Status')}:</b>
              </Typography>
              <Typography variant='subtitle2'>
                {dispatch.status.name ? `${dispatch.status.acronym} - ${dispatch.status.name}` : `* ${translate('Uninformed')} *`}
              </Typography>
            </Grid>
            </Grid>
          )
        )}
        {/* <Grid container xs={12}>
          <Grid item xs={4}>
            <Typography variant='subtitle1'><b>{translate('Agencia')}: PM001</b></Typography>
            <Typography variant='subtitle2'>{`* ${translate('Uninformed')} *`}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='subtitle1'><b>{translate('Viaturas')}: 1</b> | <b>{translate('Despacho')}: 139:55:50</b> | <b>{translate('Deslocamento')}: 03:34:38</b> | <b>{translate('Finalização')}: 00:00:13</b> <b>{translate('Total')}: 143:30:41</b></Typography>
          </Grid>
        </Grid>

        <Grid container xs={12}>
          <Grid item xs={4}>
            <Typography variant='subtitle1'><b>{translate('Agencia')}: SM006</b></Typography>
            <Typography variant='subtitle2'>{`* ${translate('Uninformed')} *`}</Typography>
          </Grid>
        </Grid> */}
      </Grid>
    </Grid>
  ) : <Grid container sx={{ width: '100%', height: 700, display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
    <Grid item>
      <CircularProgress disableShrink size={50} />
    </Grid>
  </Grid>
}