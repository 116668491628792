import React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import ThemeSwitch from '~/components/ThemeSwitch';
import FontSizeSlider from '~/components/FontSizeSlider';
import { 
  IconButton, Menu, MenuItem, Tooltip
} from '@mui/material';
import { useLang } from '~/hooks/useLang';

export default function SettingsButton() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { translate } = useLang();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "settings-menu" : undefined;

  return (
  <>
    <Tooltip title={translate('Settings')} placement="bottom">
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls={id}
        aria-haspopup="true"
        onClick={handleMenu}>
        <SettingsIcon/>           
      </IconButton>
    </Tooltip>
    
    <Menu
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        onClose={handleClose}
        sx={{
          marginTop: '30px',
          zIndex: 9999,
        }}
    >
      <MenuItem>
        <ThemeSwitch/>
      </MenuItem>
      <MenuItem>
        <FontSizeSlider/>
      </MenuItem>
    </Menu>   
  </>);
}