import { useHookstate } from '@hookstate/core';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { element } from 'prop-types';
import React, { useEffect, useState } from 'react';
import ISubtype from '~/features/Subtype/interfaces/ISubtype';
import { getAllSubTypes } from '~/features/Subtype/services';
import { useLang } from '~/hooks/useLang';
import { SubtypeResponse } from '../FilterAutocompleteSubtypeByType/interface';
import { displayName } from 'react-quill';

interface FilterAutocompleteSubtypesByTypesprops {
  readonly onSubtypesChange: (value: number[]) => void;
  readonly subtypes: readonly number[];
  readonly typeId: number[];
  readonly error?: boolean;
  readonly disabled?: boolean
  readonly limitedTags?: number
  readonly hideCode?: boolean
}


export default function FilterAutocompleteSubtypesByTypes({ error, typeId, subtypes, disabled, limitedTags = 2, hideCode, onSubtypesChange }: FilterAutocompleteSubtypesByTypesprops) {
  const { translate } = useLang();
  const [options, setOptions] = useState<readonly ISubtype[]>([]);
  const [optionsFilter, setOptionsFilter] = useState<readonly ISubtype[]>([]);
  const [value, setValue] = useState<ISubtype[] | undefined >([]);
  const loading = useHookstate(false)

  useEffect(() => {
    getAllSubTypes().then((subTypesResponse) => {
      const resSubtypes = subTypesResponse.map((subtype) => ({
        ...subtype,
        displayName: hideCode ? `${subtype.name}` : `[${subtype.code}] ${subtype.name}`,
      }))
      setOptions(resSubtypes)
      setOptionsFilter(resSubtypes)
      setValue([])
    })
  }, [])

  useEffect(() => {
    if (Array.isArray(typeId) && typeId.length) {
      const filter = options.filter(element => typeId.includes(Number(element.type_id)))
      setOptionsFilter(filter.length ? filter : [])

    } else {
      setOptionsFilter(options)
    }


  }, [typeId, options])

  useEffect(() => {
    if (subtypes?.length) {
      setValue(optionsFilter.filter(option => subtypes.includes(option.id)))
    } else {
      setValue([])
    }
  }, [subtypes, optionsFilter])

  useEffect(() => {
    if (typeId.length == 0) {
      setValue([]);
      setOptionsFilter([])
      onSubtypesChange([])
    }
  }, [options, typeId])



  return (
    <FormControl fullWidth size='small'>
      <Autocomplete
        id='filter-subtypes'
        multiple
        limitTags={limitedTags}
        disabled={disabled}
        options={optionsFilter}
        value={value}
        filterSelectedOptions
        noOptionsText={translate('No options')}
        clearText={translate('Clear')}
        disableCloseOnSelect
        size='small'
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => (option.displayName ?? option.name) ?? ''}
        onChange={(_, subtype) => {
          setValue(subtype ?? null);
          onSubtypesChange(subtype.map(subtype => subtype.id) ?? []);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error ? error : false}
            label={translate('Subtypes')}
          />
        )}
      />
    </FormControl>
  )
}