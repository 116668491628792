import React from 'react';
import { Grid, InputBaseProps, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { UseFormGetValues, UseFormRegister, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

interface CrudNumberFieldProps {
  readonly register: UseFormRegister<any>;
  readonly model: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly control?: any;
  readonly placeholder?: string;
  readonly min?: number;
  readonly max?: number;
  readonly required?: boolean;
}

export default function CrudNumberField(props: CrudNumberFieldProps) {
  const { translate } = useLang();
  const { register, model, getValues, control, placeholder, min, max = 0, required = false } = props;
  const registeredModelFormField = register(model, {valueAsNumber: true});
  
  const inputProps: InputBaseProps['inputProps'] = { 
    inputMode: 'numeric', 
    pattern: '[0-9]*', 
    min: min ?? 0,  
    type: 'number'
  }

  if (max) {
    inputProps['max'] = max;
  }

  return (
    <Grid item xs={4}>
      { <TextField
          {...registeredModelFormField }
          required={required}
          label={translate(`${placeholder ? placeholder.charAt(0).toUpperCase() + placeholder.slice(1) : model.charAt(0).toUpperCase() + model.slice(1)}`)}
          margin='normal'
          fullWidth
          type='number'
          defaultValue={(getValues && getValues( model )) ?? 0}
          sx={{ mt: 0 }}
          inputProps={inputProps}
          InputLabelProps={{
            shrink: (getValues && !getValues( model )),
          }}
        />
      }
    </Grid>
  )
}