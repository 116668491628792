import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core'
import { CardHeader, Grid, IconButton, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ColumnChart from '~/components/ColumnChart'
import { useLang } from '~/hooks/useLang'
import { useDispatchDashboardState } from '../../stores/DispatchDashboardState'
import { getDispatchByDevice } from './services/index'
import { IDespacthStatisticGraphProps } from '../../interfaces/IDispacthStatistic'

type PayloadProps = {
  readonly withSolvedThirdParty?: boolean
  readonly exludeClosed?: boolean
}
type ResponseProps = ReadonlyArray<{
  readonly item: string
  readonly value: number
}>

export default function DispatchByDeviceCharts({
  agencyId,
  deviceId,
  operationId,
  dispatchGroupId,
  category,
  type,
  subtype,
  dateStart,
  dateFinish,
  thirdParty,
  refresh,
  consummated,
  loading,
  statusIds,
  cityIds,
  groupSetId,
  location,
  operationUnitId,
  closedStatusClassId,
  closedStatusId,
  qualifierOptions,
  expandFilterSearch,
}: IDespacthStatisticGraphProps) {
  const { translate } = useLang()
  const deviceData = useHookstate<ResponseProps>([])
  const payload = useHookstate<PayloadProps>({
    withSolvedThirdParty: thirdParty,
    exludeClosed: false,
  })
  const deviceDataTotal = useHookstate<number>(0)

  useEffect(() => {
    payload.set({ withSolvedThirdParty: thirdParty, exludeClosed: false })
    deviceDataTotal.set(0)
    loading.set(true)

    getDispatchByDevice(
      agencyId,
      deviceId,
      operationId,
      dispatchGroupId,
      category,
      type,
      subtype,
      dateStart,
      dateFinish,
      payload.get(),
      consummated,
      statusIds,
      cityIds,
      groupSetId,
      location,
      operationUnitId,
      closedStatusClassId,
      closedStatusId,
      qualifierOptions
    )
      .then((response) => {
        const result = response.map((responseData) => {
          deviceDataTotal.set(deviceDataTotal.get() + responseData.total)
          return { item: responseData.name, value: responseData.total }
        })
        return deviceData.set(result)
      })
      .finally(() => {
        loading.set(false)
      })
  }, [refresh])

  return (
    <Grid
      container
      xs={12}
      sx={{
        display: 'flex',
        paddingX: 3,
        paddingTop: 3,
        justifyContent: 'space-between',
      }}
    >
      <Grid item xs={12} hidden={!expandFilterSearch}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
          {translate('Dispatch by Device')}
        </Typography>
      </Grid>
      <Grid item xs={12} hidden={!expandFilterSearch}>
        <ColumnChart
          data={deviceData.attach(Downgraded).get()}
          title={'Dispatch x Device'}
          subtitle={`Total: ${deviceDataTotal.get()}`}
          width={1900}
        />
      </Grid>
    </Grid>
  )
}
