import { authFetch } from "~/services/fetch";
import notify from "~/utils/notify";

export async function saveReportClerk({ occurrenceId, report }) {
  try {
    const response = await authFetch({
      url: '/occurrence/store-report',
      method: 'POST',
      data: {
        occurrence_id: occurrenceId,
        report: report,
      }
    })

    return response
  } catch(error) {
    throw error
  }
}

export async function getReportClerk({ occurrenceId }) {
  try {
    const response = await authFetch({
      url: '/occurrence/get-report',
      method: 'POST',
      data: {
        occurrence_id: occurrenceId,
      }
    })

    return response.data
  } catch (error) {
    throw error
  }
}

export async function markClerkOccurrence ({occurrenceId}) {
  try {
    const response = await authFetch({
      url:'/dispatch/set-clerk',
      method: 'POST',
      data: {
        occurrence_id: occurrenceId
      }
    })

    return response
    
  } catch (error){
    throw(error)
  }
}

export async function markOffClerkOccurrence({ occurrenceId }) {
  try {
    const response = await authFetch({
      url: '/dispatch/remove-clerk',
      method: 'POST',
      data: {
        occurrence_id: occurrenceId
      }
    })

    return response

  } catch (error) {
    throw (error)
  }
}

export async function getContributersDevicesFromOccurrenceServiceForClerk({ occurrenceId }) {
  try {
    const response = await authFetch({
      url: '/dispatch/contributers/devices',
      method: 'POST',
      data: {
        occurrence_id: occurrenceId
      }
    })

    return response.data;

  } catch (error) {
    throw error
  }
}

export async function getAssociatedPeopleFromOccurrenceServiceForClerk({ occurrenceId }) {
  try {
    const response = await authFetch({
      url: '/occurrence/associated-people/all-from-occurrence',
      method: 'POST',
      data: {
        occurrence_id: occurrenceId
      }
    })

    return response.data;

  } catch (error) {
    throw error
  }
}

export async function getAssociatedVehicleFromOccurrenceServiceForClerk({ occurrenceId }) {
  try {
    const response = await authFetch({
      url: '/occurrence/associated-vehicle/all-from-occurrence',
      method: 'POST',
      data: {
        occurrence_id: occurrenceId
      }
    })

    return response.data;

  } catch (error) {
    throw error
  }
}

export async function getAssociatedWeaponFromOccurrenceServiceForClerk({ occurrenceId }) {
  try {
    const response = await authFetch({
      url: '/occurrence/associated-weapon/all-from-occurrence',
      method: 'POST',
      data: {
        occurrence_id: occurrenceId
      }
    })

    return response.data;

  } catch (error) {
    throw error
  }
}

export async function getAssociatedDrugsFromOccurrenceServiceForClerk({ occurrenceId }) {
  try {
    const response = await authFetch({
      url: '/occurrence/associated-drugs/all-from-occurrence',
      method: 'POST',
      data: {
        occurrence_id: occurrenceId
      }
    })

    return response.data;

  } catch (error) {
    throw error
  }
}

export async function getAssociatedObjectsFromOccurrenceServiceForClerk({ occurrenceId }) {
  try {
    const response = await authFetch({
      url: '/occurrence/associated-object/all-from-occurrence',
      method: 'POST',
      data: {
        occurrence_id: occurrenceId
      }
    })

    return response.data;

  } catch (error) {
    throw error
  }
}

export async function getAssociatedOrganizationFromOccurrenceServiceForClerk({ occurrenceId }) {
  try {
    const response = await authFetch({
      url: '/occurrence/associated-organization/all-from-occurrence',
      method: 'POST',
      data: {
        occurrence_id: occurrenceId
      }
    })

    return response.data;

  } catch (error) {
    throw error
  }
}