import React, { useEffect } from 'react'
import { Downgraded, useHookstate } from '@hookstate/core';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import Layout from '~/components/Layout';
import { getProcedure } from '../services';
import { Checkbox } from '@mui/material';
import IProcedureConfig from '../interfaces/IProcedureConfig';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete'

interface IConfigProcedureLine {
  readonly id: string | number;
  readonly procedure_name: string;
  readonly id_entry: boolean;
  readonly id_dispatch: boolean;
  readonly id_vehicle: boolean;
}

export default function Create() {
  const { register, handleSubmit, control, setValue: setFormValue } = useForm<IProcedureConfig>();
  const lines = useHookstate<IConfigProcedureLine[]>([])
  const procedureId = useHookstate(null);
  const rows = useHookstate<IConfigProcedureLine[]>([])
  const columnGroup = [{
    groupId: 'visible_group',
    headerName:'Visível para:',
    children: [
      {field: 'id_entry'},
      {field: 'id_dispatch'},
      {field: 'id_vehicle'},
    ]
  }]

  const columns = [
    {field: 'id', hide: true},
    {field: 'procedure_name', headerName: 'Procedimento', flex: 1},
    {field: 'id_entry' , headerName: 'Atendente', flex: 1, renderCell: ({value, row, field}) => {
      return(
      <Checkbox 
      checked={value} 
      onChange={(event, value) => handleChange(value, row, field)}
      />
      )}
    },
    {field: 'id_dispatch', headerName: 'Despachante', flex: 1, renderCell: ({value, row, field}) => {
      return(
      <Checkbox 
      checked={value} 
      onChange={(event, value) => handleChange(value, row, field)}
      />
      )} },
    {field: 'id_vehicle', headerName: 'Viatura',  flex: 1, renderCell: ({value, row, field}) => {
      return(
      <Checkbox 
      checked={value} 
      onChange={(event, value) => handleChange(value, row, field)}
      />
      )}},
    {
      field: 'actions',
      type: 'actions',
      flex: 1,
      getActions: ({row}) => [
          <GridActionsCellItem
          key={2}
          icon={<DeleteIcon />}
          onClick={() => handleDelete(row.id)}
          label="Excluir"
          title="Excluir"
        />
      ],
    }
  ]

  const fields = [ 
    { 
      name: 'changeTypeSubtype', 
      namePrimary: 'type_id', 
      nameSecondary: 'subtype_id', 
      type: 'selectAutoCompleteAgregate', 
      modelPrimary: 'type', 
      placeholderPrimary: 'Type', 
      modelSecondary: 'subtypes', 
      placeholderSecondary: 'Subtype', 
      requiredPrimary: true
    }, 
    { 
      name: 'configs', 
      type: 'table', 
      required: true, 
      lines: lines.get(), 
      columns: columns, 
      filterModel: {type: 'procedure_id', variable: procedureId}, 
      rows: rows, 
      validate: () => {
        if(rows.get().length){
          const selected = rows.attach(Downgraded).get().filter((item) => item.id_entry == true || item.id_dispatch == true || item.id_vehicle == true)
          return selected.length == rows.get().length ? true : 'You must select at least one visible group for each procedure.'
        } else{
          return 'Fill the configs field.'
        } 
      },
      columnGroup: columnGroup,
    },
  ];

  useEffect(() => {
    getProcedure().then((procedures) => {
      lines.set(procedures.map((procedure) => {
        return {id: procedure.id, procedure_name: procedure.name, id_entry: false, id_dispatch: false, id_vehicle: false}
      }))
    })

  }, [])

  function handleChange(checked, row, field){
    const data = rows.get()[rows.get().findIndex((item) => item.id == row.id)]
    rows.set(rows.get().map((row) => row.id == data.id ? {...data, [field] : Boolean(checked)} : row))
    const valueFormated = rows.get().map(item => {
      const groups = [item.id_entry && 0, item.id_dispatch && 1, item.id_vehicle && 2]
      return {procedure_id: item.id, related_to: groups.filter(group => typeof group == 'number')}
    })

    setFormValue('configs', valueFormated)
  }

  function handleDelete(id){
    const rowFiltered = rows.get().filter(row => row.id != id)
    rows.set(rowFiltered)
    const valueFormated = rows.get().map(item => {
      const groups = [item.id_entry && 0, item.id_dispatch && 1, item.id_vehicle && 2]
      return {procedure_id: item.id, related_to: groups.filter(group => typeof group == 'number')}
    })

    setFormValue('configs', valueFormated)
  }


  return (
    <Layout marginLayout={true}>
      <CrudCreate 
        model={'procedure/config'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Procedure Configuration successfully created!'}
        title={'Create New Procedure Configuration'}
        subheader={'Create New Procedure Configuration'}
        setFormValue={setFormValue} />
    </Layout>
  )
}