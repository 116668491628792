/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, Paper, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { getAllCategories } from './services';
import { ClosingStatusResponse } from './interface';

interface SelectClosingStatusProps {
    readonly onClosingStatusChange: (value: number[]) => void;
    readonly closingStatusId?: readonly number[];
    readonly disabled?: boolean;
    readonly closedStatusClassId?: number | null;

}

export default function FilterAutocompleteMultipleClosingStatus({ onClosingStatusChange, disabled, closingStatusId, closedStatusClassId }: SelectClosingStatusProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly ClosingStatusResponse[]>([]);
    const [value, setValue] = useState<ClosingStatusResponse[] | undefined>([]);
    const [optionsFilter, setOptionsFilter] = useState<readonly ClosingStatusResponse[]>([]);
    const loading = useHookstate(false);

    useEffect(() => {
        getAllCategories().then(categories => {
            setOptions(categories)
            setOptionsFilter(categories);
            setValue([])
        })
    }, [])

    useEffect(() => {
        if (!closingStatusId?.length) {
            setValue([])
        }
    }, [closingStatusId])

    useEffect(() => {
        if (closedStatusClassId) {
            if (options) {
                const filter = options.filter(closedStatus => closedStatus.closed_status_class_id === closedStatusClassId) ?? [];
                setOptionsFilter(filter.length ? filter : []);
            } else {
                setOptionsFilter(options ?? []);
            }
        } else {
            setOptionsFilter(options ?? []);
        }

    }, [closedStatusClassId, options])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                filterSelectedOptions
                disableCloseOnSelect
                limitTags={2}
                sx={{ height: 1 }}
                ListboxProps={{ style: { maxHeight: '15rem' } }}
                id='filter-categories'
                multiple
                options={optionsFilter}
                disabled={disabled}
                value={value}
                noOptionsText={translate('No options')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option.acronym ? `[${option.acronym}] ${option.name}` : ''}
                onChange={(_, closingStatus) => {
                    setValue(closingStatus)
                    onClosingStatusChange(closingStatus.map(closing => closing.id) ?? [])
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Closed Status')}
                    />
                )}
            />
        </FormControl>
    )
}