export const getMapContainerSize = () => {
    const leaftletContainerElement = document.querySelector('.leaflet-container');

    if( !leaftletContainerElement ) throw new Error("Not founds Leaftlet Container by it's className");

    const { width, height } = getComputedStyle( leaftletContainerElement );

    return [ parseFloat(width), parseFloat(height) ];
}
export const getApp = () => {
    const rootElement = document.getElementById('root');

    if(!rootElement) throw new Error("Root Element not found using it's Id");

    return rootElement
}
export const getAppSize = (rootElement: HTMLElement ) => {

    const { height, width } = getComputedStyle( rootElement );

    return [parseFloat(width.replace('px','')), parseFloat(height.replace('px',''))];
}
export const getDraggableElementStyles = () => {
    const reactDraggableElement = document.querySelector('.react-draggable');

    if(!reactDraggableElement) throw new Error("React Draggable not found using it's className ");

    return getComputedStyle( reactDraggableElement );
}

export const getDraggableTranslation = (draggableComputedStyles: CSSStyleDeclaration) => {

    let transformStylesArr:readonly any[] = draggableComputedStyles.transform.split(', ');

    transformStylesArr = transformStylesArr?.map((ele,index) => {
            if(index < transformStylesArr.length - 2) return;
    
            return ele.replace(')','');
        })
        .filter(ele => Boolean(ele))
        .map((ele) => {
            if(!ele) return;
            
            return parseFloat(ele);
        });
        
    return transformStylesArr;
}
export type signal = 1 | -1;
export const getRelativeDraggablePosition = ():readonly [bit1: signal, bit2: signal]  => {
    const appSize = getAppSize( getApp() );
    const mapContainerSize = getMapContainerSize();
    const reactDraggableStyles = getDraggableElementStyles();
    const draggableTranslation = getDraggableTranslation( reactDraggableStyles );
    
    const draggableCenterX = ( parseFloat(reactDraggableStyles.width) / 2 ) + draggableTranslation[0];
    const draggableCenterY = ( parseFloat(reactDraggableStyles.height) / 2 ) + draggableTranslation[1];
    const deltaX = appSize[0] - mapContainerSize[0];
    const deltaY = appSize[1] - mapContainerSize[1];

    const valueX: signal = (draggableCenterX - deltaX) > ( mapContainerSize[0] / 2 ) ? 1 : -1;
    const valueY: signal = (draggableCenterY - deltaY) > (mapContainerSize[1] / 2) ? 1 : -1;
    return [valueX, valueY];
}
