import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import Layout from '~/components/Layout';
import useUserState from '~/features/User/stores/UserState';



export default function CoordinationCreate() {
  const { user: userSession } = useUserState();

  const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm(
  //   {
  //   defaultValues: {
  //     'coordinator_user_id': userSession().id.get(),
  //   },
  
  // }
  );

  const fields = [
    { name: 'coordinator_user_id', method: 'POST', type: 'fetchtAutoComplete', urlFetch: 'user/get', model: 'user/get', placeholder: 'Coordinator', optionSelect: 'representation_name', defaultChecked: true},
    { name: 'date_begin', type: 'datetime', placeholder: 'Initial Date' },
    { name: 'date_expected_closing', type: 'datetime', placeholder: 'End Date' },
    { name: 'dispatch_groups', type: 'transferList', options: { firstListUrl: '', secondListUrl: '/station/dispatch-groups/me' }, placeholder: 'Dispatch Group' },
  ];

  return (
    <Layout marginLayout={true}>
      <CrudCreate model={'dispatch/coordination'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Coordination successfully created!'}
        title={'Add Coordination'}
        subheader={'Add new Coordination'}
        setFormValue={setFormValue}
        getValues={getValues}
      />
    </Layout>
  )
}