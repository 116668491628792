import React from 'react';
import { useLang } from '~/hooks/useLang';
import { Typography } from "@mui/material";

type ExpandTextButtonProps = {
    readonly isCompressed: boolean;
    readonly handleCompressed: () => void;
}

export function ExpandTextButton({ handleCompressed, isCompressed }: ExpandTextButtonProps) {
    const { translate } = useLang()

    return (
        <Typography
            onClick={handleCompressed}
            sx={{ cursor: "pointer" }}
            color='orange'
            variant="caption">
            {' '}
            {!isCompressed ? translate('more') : translate('less')}
        </Typography>
    )
}
