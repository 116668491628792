/* eslint-disable */
import React, { useEffect } from 'react';
import useMapState, { useMarkerState } from '~/features/Map/stores/MapState';
import { useMapEvents } from "react-leaflet";
import useSystemState from '~/features/System/stores/SystemState';
import PixiOverlay from '../PixiOverlay';
import markerGenerator from '~/features/Map/components/MarkerGenerator';
import L, { LatLng } from 'leaflet';
import { Downgraded, StateMethods, useHookstate } from '@hookstate/core';
import { roughDBSCAN, getGeographicCenter, removeInternalPoints, sortPoints } from '~/features/Map/components/MapMarkers/MapMarkersRender/cluster';

interface MapMarkersProps {
  readonly viewGroups: string[]
  readonly nearMarker?: boolean;
  readonly checkedViewGroups: readonly string[]
  readonly popupOpen: StateMethods<boolean>
  readonly popupMarker: StateMethods<any>
  readonly viewGroupsInCluster: readonly string[]
  readonly disableZoomLimit: readonly string[]
}

export default function MapMarkersEvents({ viewGroups, nearMarker = false, checkedViewGroups, popupOpen, popupMarker, viewGroupsInCluster, disableZoomLimit }: MapMarkersProps) {
  const { forceViewMap, boundsMapGlobal } = useMarkerState()
  const { map, boundsMap, zoomMapActual, markers, loadMarkersState } = useMapState();
  const { getStatusColors, getDeviceStatusColors, windowManager, markersViewVisible, markersViewDefault, getClusterColors, getInterestPointStatusColors, getIntensityPrevision } = useSystemState();
  const viewGroupLoaded = useHookstate<string[]>([]);
  // const teste = useHookstate<any>(null)


  const markersFreeze = useHookstate({
    occurrence: {},
    entry: {},
    dispatch: {},
    device: {},
    camera: {},
    interestPoint: {},
    prediction: {},
    areas: {},
    dispatchFiltered: {},
  })

  useMapEvents({
    moveend: (event) => {
      if (event) {
        boundsMapGlobal().set(map().getBounds())
        boundsMap().set(map().getBounds())
        zoomMapActual().set(map().getZoom())
      }
    },
  });

  useEffect(() => {
    markersViewDefault(checkedViewGroups);
  }, [])

  useEffect(() => {
    const abort = new AbortController();
    markersViewVisible(viewGroups);
    viewGroups.filter((view) => !(viewGroupLoaded.get().includes(view))).map((groupMarker) => {
      loadMarkersState(groupMarker, abort, map().getBounds().getCenter().lat, map().getBounds().getCenter().lng);
    })
    viewGroupLoaded.set(JSON.parse(JSON.stringify(viewGroups)));
  }, [viewGroups])

  useEffect(() => {
    markersFreeze.set(markers().attach(Downgraded).value)
    const freezeMap = setInterval(function () {
      markersFreeze.set(markers().attach(Downgraded).value)
    }, (1000 * 5));

    return () => clearInterval(freezeMap);
  }, [])




  const markersRender = viewGroups.filter((viewGroup) => windowManager()['mapSetting']['markersView'][viewGroup].show.get()).map(
    function (groupMarker) {
      const maxValuePrevision = groupMarker == 'prediction' ? markersFreeze[groupMarker][Object.keys(markersFreeze[groupMarker]?.get()).sort(function (a, b) { return markersFreeze[groupMarker][b].total?.get() - markersFreeze[groupMarker][a].total?.get() })[0]].total?.get() : 0
      const markersRaw = Object.keys(markersFreeze[groupMarker].get()).filter((markerChildrenFilter) => {
        if (windowManager()['mapSetting']['markersView'][groupMarker].get()['children']) {
          const result = Object.keys(windowManager()['mapSetting']['markersView'][groupMarker].get()['children']).map((groupMarkerChildren) => {
            const childrenDetail = windowManager()['mapSetting']['markersView'][groupMarker].get()['children'][groupMarkerChildren]
            if (childrenDetail.show === true) {
              if (groupMarker == 'areas') {
                return true
              } else {
                return childrenDetail?.filterCondition(markersFreeze[groupMarker][markerChildrenFilter].get()[childrenDetail?.filterPropName])
              }
            } else {
              return false
            }
          })

          return result.includes(true)
        } else {
          return true
        }
      }).filter((markerKey) => {
        const markerUnit = markersFreeze[groupMarker][markerKey].get()

        if (groupMarker == 'areas' || Boolean(markerUnit?.position?.lat) && Boolean(markerUnit?.position?.lng) && markerUnit?.position?.lat != 0 && markerUnit?.position?.lng != 0) {
          if (!((zoomMapActual().get() < 15 && groupMarker == 'camera')) && !((!disableZoomLimit.includes('device') && zoomMapActual().get() < 11 && groupMarker == 'device'))) {
            if (groupMarker == 'dispatch') {
              if (markerUnit.status == 10 || (markerUnit.isCurrent == false && markerUnit.status == 8)) {
                return false
              } else {
                return true
              }
            } else if (groupMarker == 'dispatchFiltered' ) {
              if (markerUnit.status == 10) {
                return false
              } else {
                return true
              }
            } else {
              return true
            }
          }
        } else {
          return false
        }

      }).map((key) => {
        const markerInfo = markersFreeze[groupMarker][key].get();
        const colorStatus = groupMarker != 'device' ? groupMarker == 'interestPoint' ? getInterestPointStatusColors(markerInfo.isCriminal) : groupMarker == 'prediction' ? getIntensityPrevision(markerInfo.total, maxValuePrevision) : getStatusColors(markerInfo.status) : getDeviceStatusColors(markerInfo.status);
        const markerIcon = markerGenerator({ latitude: markerInfo.position.lat, longitude: markerInfo.position.lng, zoom: map().getZoom(), type: groupMarker, isCurrent: markerInfo.isCurrent, statusAcronym: colorStatus.acronym, statusColor: colorStatus.main, statusColorText: colorStatus.contrastText, deviceType: markerInfo['deviceType'] ? markerInfo['deviceType'] : 'car', identifier: markerInfo['identifier'] ? markerInfo['identifier'] : '' });

        return {
          key: markerInfo.markerId,
          id: markerInfo.markerId,
          iconId: groupMarker == 'device' ? `${groupMarker}-${markerInfo['deviceType']}-${markerInfo.status}-${markerInfo.isCurrent}-${markerInfo.identifier}` : groupMarker == 'interestPoint' ? `${groupMarker}-${markerInfo.isCriminal}` : groupMarker == 'prediction' ? `${groupMarker}-${markerInfo.id}-${zoomMapActual().get()}` : `${groupMarker}-${markerInfo.status}-${markerInfo.isCurrent}`,
          customIcon: markerIcon.svg,
          position: groupMarker != 'areas' ? new LatLng(markerInfo.position.lat, markerInfo.position.lng) : null,
          latitude: groupMarker != 'areas' ? markerInfo.position.lat : null,
          longitude: groupMarker != 'areas' ? markerInfo.position.lng : null,
          tooltip: groupMarker == 'areas' ? markerInfo.markerIdentifier : null,
          // tooltipNoClose: groupMarker == 'device' ? true : false,
          circleBase: groupMarker == 'entry' && markerInfo.isCurrent,
          groupMarker: groupMarker,
          areas: groupMarker == 'areas' && windowManager()['mapSetting']['markersView'][groupMarker]['children'][key].show.get() ? sortPoints(removeInternalPoints(markerInfo.position)).map((point) => { return { verticesPoint: point, color: markerInfo.color ? markerInfo.color : '#000000' } }) : null,
          onClick: () => {
            popupOpen.set(groupMarker == 'prediction' ? false : true);
            popupMarker.set({
              position: new LatLng(markerInfo.position.lat, markerInfo.position.lng),
              groupMarker: groupMarker,
              markerInfo: markerInfo,
            });
          },

        }
      })

      let colorAmost = 0
      return zoomMapActual().get() < 12 && viewGroupsInCluster.includes(groupMarker) ? roughDBSCAN(markersRaw, ((-51.6803 * zoomMapActual().get() + 674.0409) / zoomMapActual().get()) / 96, 1).map(function (cluster, index) {

        if (cluster.points.length == 1) {
          return cluster.points
        } else {
          // const markerAggregate = () => {
          //   const firstLatitude = cluster.points[0]['latitude'];
          //   const firstLongitude = cluster.points[0]['longitude'];
          //   return cluster.points.every(obj => obj['latitude'] === firstLatitude && obj['longitude'] === firstLongitude);
          // }

          // if(markerAggregate()){
          //   console.log(markerAggregate())
          //   return {
          //     key: `occurrence-aggregate-${index}`,
          //     id: `occurrence-aggregate-${index}`,
          //     iconId: `occurrence-aggregate`,
          //     customIcon: markerGenerator({type: 'occurrence-aggregate' }).svg,
          //     position: new LatLng(cluster.points[0]['latitude'], cluster.points[0]['longitude']),
          //     latitude: cluster.points[0]['latitude'],
          //     longitude: cluster.points[0]['longitude'],
          //     circleBase: false,
          //     onClick: () => {
          //       console.log('Click')
          //     }
          //   }
          // } else {

          // if(zoomMapActual().get() < 11){           

          const clusterPosition = getGeographicCenter(cluster.points);
          cluster.points.length > colorAmost ? colorAmost = cluster.points.length : false
          let colorIntensity = cluster.points.length >= (colorAmost * 0.6) ? 'high' : cluster.points.length >= (colorAmost * 0.3) ? 'medium' : 'low'
          const colorCluster = getClusterColors('dispatch', colorIntensity)

          return {
            key: `cluster-${index}-${cluster.points.length}`,
            id: `cluster-${index}-${cluster.points.length}`,
            iconId: `cluster-${cluster.points.length}`,
            customIcon: markerGenerator({ type: 'cluster', identifier: cluster.points.length, clusterColorBase: colorCluster['base'], clusterColorShadow: colorCluster['shadow'], clusterColorBrightnes: colorCluster['brightnes'] }).svg,
            position: new LatLng(clusterPosition?.latitude ? clusterPosition?.latitude : 0, clusterPosition?.longitude ? clusterPosition?.longitude : 0),
            latitude: clusterPosition?.latitude,
            longitude: clusterPosition?.longitude,
            circleBase: false,
            polygon: sortPoints(removeInternalPoints(cluster.points)).map((point) => [point.latitude, point.longitude]),
            onClick: () => {
              forceViewMap().zoom.set(12);
              forceViewMap().position.set(new LatLng(clusterPosition?.latitude ? clusterPosition?.latitude : 0, clusterPosition?.longitude ? clusterPosition?.longitude : 0))
            }
          }

          // } 
          // else {
          // return markersRaw
          // }

          // }


        }
      }
      ) : markersRaw
    })

  return (
    <PixiOverlay markers={markersRender.flat().flat()} />
  )
}