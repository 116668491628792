import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { State, StateMethods } from '@hookstate/core'
import { LoadingButton } from '@mui/lab'
import InternalContent from '~/components/Layout/components/InternalContent'
import { useTheme } from '~/hooks/useTheme';
export interface DialogRedirectProps {
    readonly open: StateMethods<boolean>
    readonly title?: string
    readonly codeOccurrence?: string
    readonly handleClose: () => void
    readonly handleAgree: () => void
    readonly closeLabel?: string
    readonly AgreeLabel?: string
    readonly loading?: State<boolean>
    readonly disabled?: boolean
    readonly dispatchesCodesGenerated: State<string[]>;
}

export default function DialogRedirect({ open, title, codeOccurrence, handleClose, handleAgree, closeLabel = 'Cancel', AgreeLabel = 'Ok', loading, disabled, dispatchesCodesGenerated }: DialogRedirectProps) {
    const translate = useTranslation().t
  const { darkMode } = useTheme();

    return (
        <Dialog
            open={open.get()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title && (
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
            )}

            {codeOccurrence && (
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dispatchesCodesGenerated.length &&
                            <InternalContent title={`${translate('Dispatch codes generated')}:`} sxFont={{ variant: 'subtitle1' }}>
                                {dispatchesCodesGenerated.map((dispatch) => (
                                    <Box
                                        key={dispatch.get()}
                                        width={'100%'}
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        gap={2}
                                    >
                                        <Typography>
                                            {dispatch.get()}
                                        </Typography>
                                    </Box>
                                ))}
                            </InternalContent>
                        }
                        <Typography sx={{paddingTop: 1, color: darkMode ? 'white' : 'black'}}>
                            {translate('Do you wish to continue to occurrence')} <b>OC: {codeOccurrence}</b> {translate('dispatched?')}
                        </Typography>

                    </DialogContentText>
                </DialogContent>
            )}

            <DialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    gap: 2,
                    padding: '16px 24px'
                }}
                disableSpacing={true}
            >
                <Button
                    variant="outlined"
                    onClick={() => handleClose()}
                >
                    {translate(closeLabel)}
                </Button>

                <LoadingButton
                    loading={loading?.get()}
                    variant="contained"
                    onClick={() => handleAgree()}
                    autoFocus
                    disabled={disabled}
                >
                    {translate(AgreeLabel)}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}