import React from 'react';
import { createState, Downgraded, useState } from '@hookstate/core';
import { isEqual } from 'lodash';
import IUser from '../interfaces/IUser';
import constants from '~/core/constants';
import useCookie from '~/hooks/useCookie';

const userDefault: IUser = {
  id: null,
  login: null,
  name: null,
  representation_name: null,
  acronym: null,
  password: null,
  repassword: null,
  email: null,
  cpf: null,
  registration: null,
  birth_date: null,
  phone: null,
  map_viewports: [],
  blood_type: 'A+',
  emergency_contact_name: null,
  emergency_contact_phone: null,
  agency_id: null,
  agency: undefined,
  active: false,
  permissions: [],
  password_expiration_date: null,
  need_to_change_password: 0
}

const keyUser = constants.keys.user;

const userState = createState<IUser>(userDefault);

const connectionState = createState<'online' | 'instability' | 'offline'>('offline');

export default function useUserState() {
  const user = useState(userState);
  const connection = useState(connectionState);
  const [commentIsCompressed, setCommentIsCompressed] = useCookie(constants.keys.commentIsCompressed, false);

  return ({
    user: () => {
      if (user.get() === userDefault) {
        const storedValue = localStorage.getItem(keyUser);
        if(storedValue) user.set(storedValue ? JSON.parse(storedValue) : userDefault);
      }
      return user
    },
    connection: () => connection,
    setUserCookie: React.useCallback((value: IUser) => {
      try {
        localStorage.setItem(keyUser, JSON.stringify(value));
      } catch (error) {
        console.error('Favor habilitar os cookies');
      }
    }, [keyUser]),
    commentIsCompressed: commentIsCompressed,
    setCommentIsCompressed: setCommentIsCompressed,
    verifyPermission: (args: string[], isProtected = true) => {
      if (args.length > 0 && isProtected) {
        if (user.permissions && user.permissions.get().length == 0) {
          const storedValue = localStorage.getItem(keyUser);
          const storageUser = storedValue ? JSON.parse(storedValue) : userDefault
          !isEqual(user.attach(Downgraded).get(), storageUser) ? user.set(storageUser) : false;
        }
        const userPermissions = user.permissions ? user.permissions.get() : [];
        return args.some((permission) => userPermissions.includes(permission));
      }
      return true;
    },
    resetUser: () => {
      user.set(userDefault);
      localStorage.clear()
    }

  })
}
