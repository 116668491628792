import React from 'react';
import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';
import { authFetch } from '~/services/fetch';
import { State } from '@hookstate/core';
import { useLang } from '~/hooks/useLang';
import { removeMask } from '~/features/Dispatch/utils/stringsManipulations';
import { IErrorConectDispatchVehicle, IUserCrew } from '../..';

interface ICpfFieldSearchMember {
  readonly stateName: string;
  readonly memberState: State<IUserCrew>;
  readonly error: State<IErrorConectDispatchVehicle>;
  readonly onKeyDown?: (event: any) => void;
}


export default function CpfFieldSearchMember({ onKeyDown, stateName, memberState, error }: ICpfFieldSearchMember) {
  const { translate } = useLang();
  return (
    <InputMask mask="999.999.999-99"
      value={memberState.cpf.get() ?? ''}
      onKeyDown={onKeyDown}
      onChange={(event) => {
        error.returnError.set('')
        error[stateName].cpf.set(null)
        const cpf = event.target.value
        memberState.cpf.set(cpf)
        if (removeMask(cpf).length === 11) {
          error[stateName].cpf.set('')
        } else if (!cpf.length) {
          memberState.set({ ...memberState.get(), cpf: '', registration: '', representation_name: '' })
          error[stateName].cpf.set(translate('Inform a CPF or registration'))
        } else {
          memberState.set({ ...memberState.get(), registration: '', representation_name: '' })
          error[stateName].cpf.set(translate('Invalid CPF'))
        }
      }}
    >
      <TextField fullWidth
        label={'CPF'}
        name="cpf"
        required
        size='small'
        error={!!error[stateName].cpf.get() && !memberState.registration.get()}
      />
    </InputMask>
  )
}