import React from 'react';
import Create from './pages/Create';
import Edit from './pages/Edit';
import Index from './pages/Index';
import { AppRouteProps } from '~/routes';

export default function AnnouncementRoutes(): readonly AppRouteProps[] {
  const routes: AppRouteProps[] = [
    {path: '/announcement', component: Index, isProtected: true, permissions: [], adminOnly: true},
    {path: '/announcement/create', component: Create, isProtected: true, permissions: [], adminOnly: true},
    {path: '/announcement/edit/:id', component: Edit, isProtected: true, permissions: [], adminOnly: true},
  ];

  return routes;
}
