/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { AgencyResponse } from './interface';
import { getAgencies } from './service';

interface AgencyProps {
    readonly onAgenciesChange: (value: number[]) => void;
    readonly agenciesId?: readonly number[];
    readonly disabled?: boolean
}

export default function FilterAutocompleteAgencies({ onAgenciesChange, agenciesId }: AgencyProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly AgencyResponse[]>([]);
    const [value, setValue] = useState<AgencyResponse[] | undefined>([]);
    const loading = useHookstate(false);

    useEffect(() => {
        getAgencies().then(agencies => {
            setOptions(agencies)
            setValue([])
        })
    }, [])

    useEffect(() => {
        if (!agenciesId?.length) {
            setValue([])
        }
    }, [agenciesId])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                filterSelectedOptions
                disableCloseOnSelect
                limitTags={2}
                sx={{ height: 1 }}
                ListboxProps={{ style: { maxHeight: '15rem' } }}
                id='filter-agencies'
                multiple
                options={options}
                value={value}
                noOptionsText={translate('No options')}
                clearText={translate('Clear')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={(_, type) => {
                    setValue(type)
                    onAgenciesChange(type.map(type => type.id) ?? [])
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('Agencies')}
                    />
                )}
            />
        </FormControl>
    )
}