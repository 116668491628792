import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';


export default function Edit() {
    const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm<any>();
    const fields = [
        { name: 'id', type: 'hidden' },
        { name: 'name', type: 'string' },
        { name: 'agency_id', type: 'selectAutoComplete', model: 'agency', placeholder: 'Agency:name' },
    ];

    return (
        <CrudEdit
            model={'operation-unit'}
            fields={fields}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            setFormValue={setFormValue}
            messageSuccess={'Operation unit successfully edited!'}
            title={'Edit Operation unit'}
            getValues={getValues}
        />
    )
}



