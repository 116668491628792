/* eslint-disable max-lines-per-function */
import React from 'react'
import {
  createState,
  useState,
} from '@hookstate/core'
import { authFetch } from '~/services/fetch'
import { IAnimalDTO, IDispacthRegistrationAnimals } from '../interfaces/IDispatchRegistrationAnimal';
import { toDomain, toFetch } from '~/mappers/registration-animal-mapper';

export const animalDefaultState = {
  specieId: null,
  breedId: null,
  occurrenceId: null,
  animalCircunstance: null,
  destination: null,
  notes: '',
  specie: null,
  breed: null,
}

const defaultRegistrationProps: IDispacthRegistrationAnimals = {
  animals: [],
  animal: animalDefaultState,
  specieList: [],
  breedList: [],
  animalsCircunstanceList: [],
}

const registrationAnimalDispatchState = createState<IDispacthRegistrationAnimals>(defaultRegistrationProps);

const useDispatchRegistrationAnimalState = () => {
  const registrationAnimalDispatch = useState(registrationAnimalDispatchState);
  return {
    registrationAnimalDispatch: () => registrationAnimalDispatch,
    getSpecies: async () => {
      const targetUrl = '/specie'
      const response = await authFetch({
        url: targetUrl,
      });

      return response.data.data;
    },
    getBreeds: async () => {
      const targetUrl = '/breed'
      const response = await authFetch({
        url: targetUrl,
      });

      if(registrationAnimalDispatch?.get().animal.specieId){
        return response.data.data.filter(breed => breed.specie_id == registrationAnimalDispatch.animal.get()?.specieId)
      } else {
        return response.data.data;
      }
    },
    getAssociatedAnimal: async (id: string) => {
      const targetUrl = `/associated-animal/${id}`
      const { data: animal } = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      registrationAnimalDispatch
        .animal
        .set(toDomain(animal));
    },
    getAllAssociatedAnimal: async (occurrenceId: number | string | null) => {
      const targetUrl = '/occurrence/associated-animal/all-from-occurrence'
      if(occurrenceId){
        try {
          const response = await authFetch({
            url: targetUrl,
            data: {
              occurrence_id: occurrenceId,
            }
          });
    
          return response.data.map((animal): IAnimalDTO => {
            return toDomain(animal)
          })
        } catch (error) {
          throw error
        }
        
      }
    },
    sendAnimal: async (
      animal: IAnimalDTO) => {

      const targetUrl = '/occurrence/associated-animal/store'
      return await authFetch({
        url: targetUrl,
        data: toFetch(animal)
      });
    },
    editAnimal: async (
      { id, ...animal }: IAnimalDTO) => {

      const targetUrl = `/occurrence/associated-animal/${id}`
      await authFetch({
        url: targetUrl,
        method: 'PUT',
        data: toFetch(animal)
      });
    },
    deleteAnimal: async (id: string) => {
      const targetUrl = `/occurrence/associated-animal/${id}`
      return await authFetch({
        url: targetUrl,
        method: 'DELETE',
      });
    },
  }
}

export default useDispatchRegistrationAnimalState;