/* eslint-disable max-lines-per-function */
import { useHookstate } from '@hookstate/core'
import {
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import InfiniteScroll from 'react-infinite-scroll-component'
import ErrorFallback from '~/components/ErrorFallback'
import { useLang } from '~/hooks/useLang'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import SearchIcon from '@mui/icons-material/Search'
import { getLastCallsByPhone, getTotalPrankCalls } from './services'
import useEntryState from '../../stores/EntryState'
import dayjs from 'dayjs'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'
import AbcIcon from '@mui/icons-material/Abc'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import { formatPhone } from '~/utils/format'
import useSystemState from '~/features/System/stores/SystemState'
import { Autorenew } from '@mui/icons-material'
import OccurrenceRecallModal from '~/features/Occurrence/components/OccurrenceRecallModal'
import { authFetch } from '~/services/fetch'
import { useTheme } from '~/hooks/useTheme';

interface LastCallsListProps {
  readonly id: number
  readonly name: string
  readonly typename: string
  readonly subtypename: string
  readonly is_prank_call: string
  readonly created_at: Date
  readonly location: string
  readonly typeId: string
  readonly occurrence_id: number | null
  readonly subtypeId: string
  readonly occurrence: {
    readonly id: number
    readonly dispatches: readonly [
      {
        readonly code: number
        readonly id: number
        readonly status: {
          readonly acronym: string
          readonly id: number
          readonly name: string
        }
      }
    ]
  }
}

interface filtersProps {
  readonly hide: boolean
  readonly content: ReadonlyArray<any>
  readonly fields: {
    readonly id: string | null
    readonly lastCallsFilter: string | null
    readonly lasCallsGroupFilter: string | null
    readonly typeFilter: string | null
    readonly name: string | null
    readonly phone: string | null
  }
}

const filtersDefault: filtersProps = {
  hide: true,
  content: [],
  fields: {
    id: null,
    lastCallsFilter: null,
    lasCallsGroupFilter: null,
    typeFilter: null,
    name: null,
    phone: null,
  },
}

interface dispatchDestinationProps {
  readonly id: number
  readonly code: string
}

// eslint-disable-next-line max-lines-per-function
export default function LastCallsList({ phone, totalRows, countPrank }) {
  const { translate } = useLang()
  const lastCallsList = useHookstate<readonly LastCallsListProps[]>([])
  const filters = useHookstate(filtersDefault)
  const entry = useEntryState().entry()
  const { getStatusColors, windowManager, loadingButton} = useSystemState()
  const openOccurrenceRecallModal = useHookstate(false)
  const openRequester = useHookstate(false)
  const occurrenceId = useHookstate(0)
  const rowCount = useHookstate(0)
  const dispatchDestination = useHookstate<dispatchDestinationProps | null>(
    null
  )
  const [open, setOpen] = React.useState(false)
  const [code, setCode] = React.useState('')
  const [agency, setAgency] = React.useState('')
  const [dispatchGroup, setDispatchGroup] = React.useState('')
  const [type, setType] = React.useState('')
  const [subtype, setSubtype] = React.useState('')
  const [status, setStatus] = React.useState('')

  const { darkMode } = useTheme();

  const options = [
    {
      id: 1,
      name: 'location',
      buttonName: 'Import Location',
    },
    {
      id: 2,
      name: 'typeOcurrence',
      buttonName: 'Import type/subtype',
    },
    {
      id: 3,
      name: 'requester',
      buttonName: 'Import Requester',
    },
    {
      id: 4,
      name: 'allExports',
      buttonName: 'Import all info',
    },
    {
      id: 5,
      name: 'Recall',
      buttonName: 'Recall',
    },
  ]

  useEffect(() => {
    nextData(true)
  }, [phone])

  const filterCalls = (calls) => {
    calls.filter(element => {
      const dataFind = lastCallsList.get().find(lastCalls => lastCalls.id == element.id)

      return !dataFind
    })

    return calls
  }

  const countTotalPranks = (calls) => {
    getTotalPrankCalls({phone: phone}).then(({total}) => {
      countPrank.set(total)
    })
  }

  const nextData = async (reset = false) => {
    const pageQuery = reset ? 0 : Math.floor(lastCallsList.get().length / 10)
    if (phone?.length === 10 || phone?.length === 11) {
      await getLastCallsByPhone({
        phone: phone,
        page: pageQuery,
        limit: 10,
        filters: filters.content.get(),
      }).then(({ data, rowsCount }) => {
        windowManager()['EntryLogModal'].open.set(true)
        countTotalPranks(data)

        const filterData = filterCalls(data)
        
        if (reset) {
          lastCallsList.set(data)
          rowCount.set(rowsCount)
        } else {
          lastCallsList.merge(filterData)
          rowCount.set(rowsCount)
        }
      }).catch(console.error)
      
      totalRows.set(rowCount.get())
    } else {
      windowManager()['EntryLogModal'].open.set(false)
      countPrank.set(0)
    }
  }

  const actionFilter = () => {
    filters.content.set([])
    Object.keys(filters.fields.value).map((field) => {
      if (
        filters.fields.value[field] != null &&
        filters.fields.value[field] != ''
      ) {
        filters.content.merge([
          {
            field: field,
            operator: 'contains',
            value: filters.fields.value[field],
          },
        ])
      }
    })
    nextData(true)
    filters.hide.set(true)
  }

  const exportData = (dataExport, typeExport: string) => {
    switch (typeExport) {
      case 'location':
        entry.batch((data: typeof entry) => {
          data.location.set(dataExport.location)
          data.address.set(dataExport.address)
          data.latitude.set(dataExport.latitude)
          data.longitude.set(dataExport.longitude)
        })

        break
      case 'typeOcurrence':
        entry.typeId.set(dataExport.type_id)
        entry.subtypeId.set(dataExport.subtype_id)

        break
      case 'requester':
        entry.batch((data: typeof entry) => {
          data.name.set(dataExport.name)
        })
        break

      case 'allExports':
        entry.batch((data: typeof entry) => {
          data.location.set(dataExport.location)
          data.address.set(dataExport.address)
          data.latitude.set(dataExport.latitude)
          data.longitude.set(dataExport.longitude)
          data.name.set(dataExport.name)
        })

        entry.typeId.set(dataExport.type_id)
        entry.subtypeId.set(dataExport.subtype_id)
        break
      default:
    }
  }

  const handleClickOpen = (code) => {
    authFetch({
      url: `/dispatch/get-by-code`,
      method: 'POST',
      data: {
        code: code,
      },
    }).then((response) => {
      dispatchDestination.set(
        response?.data?.unified?.dispatch_destination
          ? response?.data?.unified?.dispatch_destination
          : null
      )
      setCode(code)
      setAgency(` - ${response.data.agency?.name}`)
      setDispatchGroup(`${response.data.dispatch_group?.name}`)
      setType(`${response.data.type?.name}`)
      setSubtype(
        response.data.subtype ? `- ${response.data.subtype?.name}` : ''
      )
      setStatus(`[${response.data.status?.acronym}]`)
      setOpen(true)
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Grid container spacing={2}>
        <Grid container padding={1}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'end',
              marginRight: 1,
              padding: 0,
            }}
          >
            <IconButton
              aria-label="filters"
              onClick={() => filters.hide.set(!filters.hide.get())}
            >
              <Badge
                badgeContent={filters.content.get().length}
                color="primary"
              >
                <FilterAltIcon />
              </Badge>
            </IconButton>
          </Grid>
          <Grid item xs={12} padding={1} hidden={filters.hide.get()}>
            <Typography>Filtros</Typography>
            <Divider />
            <Grid
              container
              xs={12}
              direction="row"
              spacing={1}
              padding={1}
              hidden={filters.hide.get()}
            >
              <Grid item xs={6} spacing={1}>
                <TextField
                  fullWidth
                  label={`${translate('ID')} ${translate('Call')}`}
                  size="small"
                  value={filters.fields.id.get() ?? ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    filters.fields.id.set(event.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={6} spacing={1}>
                <TextField
                  fullWidth
                  label={translate('Requester')}
                  size="small"
                  value={filters.fields.name.get() ?? ''}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    filters.fields.name.set(event.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={6} spacing={1}>
                <Tooltip title={translate('Filter')} placement="bottom">
                  <IconButton onClick={() => actionFilter()}>
                    <SearchIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
        </Grid>

        <Grid container padding={1}>
          <Grid item xs={12}>
            <InfiniteScroll
              dataLength={lastCallsList.value?.length}
              next={() => nextData()}
              hasMore={
                lastCallsList.value?.length != rowCount.get() &&
                lastCallsList.value?.length != 0
              }
              height={335}
              loader={<CircularProgress />}
            >
              {lastCallsList.value.length != 0 ? (
                lastCallsList?.get().map((items) => {
                  return (
                    <>
                      <Divider variant="middle" />
                      <ListItem
                        key={items?.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          sx={{
                            minWidth: '300px',
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              width: '35%',
                              display: 'flex',
                              justifyContent: 'start',
                              right: 50,
                              top: 12,
                              gap: 1                             
                            }}
                          >
                            {items?.is_prank_call ? (
                              <>
                              <Typography> 
                                | 
                              </Typography>
                              <Typography
                                  sx={{
                                    background: darkMode ? '#bf9000' : '#ffd966'
                                  }}
                                >
                                  {translate('Prank call').toLocaleUpperCase()}
                                </Typography>
                              </>
                            ) : (
                              ''
                            )}
                          </Box>
                          <ListItemText
                            primary={`${
                              dayjs(items?.created_at).isValid()
                                ? dayjs(items?.created_at).format(
                                    'DD/MM/YYYY HH:mm:ss'
                                  )
                                : null
                            }`}
                          />
                          <ListItemText
                            primary={`${
                              items?.name
                                ? items?.name
                                : translate('Uninformed')
                            } - ${phone ? formatPhone(phone) : ''} `}
                          />
                          <ListItemText
                            primary={`${
                              items?.typename ? items?.typename : ''
                            }  ${
                              items?.subtypename
                                ? '- ' + items?.subtypename
                                : ''
                            } `}
                          />
                          <ListItemText
                            primary={`${
                              items?.location ? items?.location : ''
                            }`}
                          />
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignItems: 'center',
                              flexDirection: 'row',
                              gap: 1,
                            }}
                          >
                            {items?.occurrence
                              ? items?.occurrence.dispatches?.map((element) => (
                                  <Chip
                                    key={element.id}
                                    label={element?.code}
                                    onClick={() =>
                                      handleClickOpen(element.code)
                                    }
                                    sx={{
                                      backgroundColor: getStatusColors(
                                        element.status?.id
                                      ).main,
                                      color: getStatusColors(element.status?.id)
                                        .contrastText,
                                    }}
                                  />
                                ))
                              : false}
                          </Box>
                        </Box>

                        <Box>
                          <IconButton
                            title={translate(options[0].buttonName)}
                            onClick={() =>
                              exportData(items ? items : '', options[0].name)
                            }
                          >
                            <AddLocationAltIcon />
                          </IconButton>
                          <IconButton
                            title={translate(options[1].buttonName)}
                            onClick={() =>
                              exportData(items ? items : '', options[1].name)
                            }
                          >
                            <CrisisAlertIcon />
                          </IconButton>
                          <IconButton
                            title={translate(options[2].buttonName)}
                            onClick={() =>
                              exportData(items ? items : '', options[2].name)
                            }
                          >
                            <AbcIcon />
                          </IconButton>
                          <IconButton
                            title={translate(options[3].buttonName)}
                            onClick={() =>
                              exportData(items ? items : '', options[3].name)
                            }
                          >
                            <HowToRegIcon />
                          </IconButton>
                          <IconButton
                            title={translate(options[4].buttonName)}
                            disabled={
                              items?.occurrence_id === null
                                ? Boolean(
                                    !items?.occurrence?.dispatches.find(
                                      (element) =>
                                        element.status.name === 'ABERTA'
                                    )
                                  )
                                : false
                            }
                            onClick={() => {
                              openOccurrenceRecallModal.set(true),
                                occurrenceId.set(Number(items.occurrence?.id))
                            }}
                          >
                            <Autorenew />
                          </IconButton>
                        </Box>
                      </ListItem>
                      <Divider variant="middle" />
                    </>
                  )
                })
              ) : (
                <Grid
                  xs={12}
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography>{translate('No Calls')}</Typography>
                </Grid>
              )}
            </InfiniteScroll>
          </Grid>
        </Grid>
        {openOccurrenceRecallModal.get() ? (
          <OccurrenceRecallModal
            open={openOccurrenceRecallModal}
            openRequester={openRequester}
            occurrenceId={occurrenceId.get()}
            redirectOccurrence={true}
          />
        ) : (
          false
        )}
        <Dialog fullWidth={true} open={open} onClose={handleClose}>
          <DialogTitle>
            {translate('Dispatch')} - {code} - {status}
          </DialogTitle>
          <DialogContent>
            <Typography>
              {dispatchGroup} {agency}
            </Typography>
            <br />
            <Typography>
              {type} {subtype}
            </Typography>

            {dispatchDestination.get() ? (
              <>
                <br />
                <Typography>
                  {translate('Unified')} {translate('With')}:{' '}
                  <Chip label={dispatchDestination.get()?.code} />
                </Typography>
              </>
            ) : (
              false
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              {translate('Close')}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </ErrorBoundary>
  )
}
