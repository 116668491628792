/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useHookstate, useState } from '@hookstate/core'
import { EmergencyRecordingOutlined } from '@mui/icons-material'
import StandardModal from '~/components/StandardModal'
import { useLang } from '~/hooks/useLang'
import SurveillanceReportingCards from '../VideoMonitoringCards'
import useSurveillanceReportingState from '../../stores/SurveillanceReportingState'
import { Grid } from '@mui/material'
import { useLocation } from 'react-router'
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable'
import { authFetch } from '~/services/fetch'
import useSystemState from '~/features/System/stores/SystemState'
import { storeScreenSettings } from '~/features/System/services'

const modal = document.getElementById('div-modal') as HTMLElement

export default function VideoMonitoringCardsDraggable() {
  const { translate } = useLang()
  const { windowManager, loadingButton } = useSystemState()

  const handleStop = (event, dragElement) => {
    event.stopPropagation()

    windowManager()['videoMonitoringCards'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y,
    })
  }

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'VideoMonitoringCardsDraggable',
        isOpen: windowManager()['videoMonitoringCards'].open.get(),
        isMinimized: windowManager()['videoMonitoringCards'].minimized.get(),
        positionX: windowManager()['videoMonitoringCards'].positionX.get(),
        positionY: windowManager()['videoMonitoringCards'].positionY.get(),
      })
    }
  }, [
    windowManager()['videoMonitoringCards'].open.get(),
    windowManager()['videoMonitoringCards'].minimized.get(),
    windowManager()['videoMonitoringCards'].positionY.get(),
    windowManager()['videoMonitoringCards'].positionX.get(),
  ])

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!loadingButton.get() && (
        <StandardModal
          title={translate('Videomonitoring narrations')}
          dimensions={{
            width: 800,
          }}
          handleStop={handleStop}
          isExpanded={windowManager()['videoMonitoringCards'].minimized}
          position={{
            x: windowManager()['videoMonitoringCards'].positionX?.get()
              ? Number(windowManager()['videoMonitoringCards'].positionX.get())
              : 300,
            y: windowManager()['videoMonitoringCards'].positionY?.get()
              ? Number(windowManager()['videoMonitoringCards'].positionY.get())
              : 200,
          }}
          closeButton={() =>
            windowManager()['videoMonitoringCards'].open.set(false)
          }
          variant={'h5'}
        >
          <Grid item xs={12} sx={{ overflowY: 'auto', width: '100%' }}>
            <SurveillanceReportingCards onlyCurrentOccurrence />
          </Grid>
        </StandardModal>
      )}
    </>,
    modal
  )
}
