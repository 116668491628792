import React, { useEffect } from 'react';
import CrudIndex from '~/components/CrudIndex';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';

export default function Index() {
    const { translate } = useLang();

    useEffect(() => {
      document.title = translate('Administration - Dispatch System')
    }, [])
    return (
        <Layout marginLayout={true}>
            <CrudIndex
                model='interest-point/type'
                title='Interest point type'
                booleanField={['is_criminal', 'is_visible_to_mobile', 'is_visible_to_dispatch']}
                aliasLabel={[
                    { column: 'is_visible_to_mobile', label: 'Visivel para dispositivo móvel' },
                    { column: 'is_visible_to_dispatch', label: 'Visível para despacho'},
                    { column: 'is_criminal', label: 'É criminal' },
                    { column: 'review_time_in_days', label: 'Tempo de revisão em dias' },
                    { column: 'expiration_time_in_days', label: 'Prazo de validade em dias' },                    
                ]}
            />
        </Layout>
    )
} 