import React, { useEffect } from 'react';
import { createState, State, useState } from '@hookstate/core';
import { useAuth } from '~/features/auth/hooks/useAuth';
import { authFetch } from '~/services/fetch';
import { Broadcasted } from '@hookstate/broadcasted';
import useSocket from '~/hooks/useSocket';
import { toDomain } from '~/mappers/comments-mapper';

export interface CommentProps {
  readonly id?: number;
  readonly entryId: number | null;
  readonly userId?: number | string | null;
  readonly comment: string | null;
}
export interface CommentViewProps {
  readonly props: CommentDataProps,
  readonly isSaved: boolean
}
export interface EntryCommentProps {
  readonly newComment: null | string,
  readonly allCurrentComments: readonly CommentDataProps[]
  readonly loading: boolean
  readonly retryConnect: boolean
}
export interface PostCommentBodySchema {
  readonly entry_id: number | null,
  readonly comment: string | null
}
// export interface PostCommentResponse {
//   readonly id: number,
//   readonly entry_id: number,
//   readonly user_id: number,
//   readonly comment: string,
//   readonly created_at: string,
//   readonly updated_at: string,
//   readonly deleted_at: null | string,
//   readonly user: {
//     readonly name: string,
//   }
// }

interface IStationComment {
  readonly id: number,
  readonly name: string,
}

interface IDeviceComment {
  readonly id: number,
  readonly name: string,
}
export interface CommentData {
  readonly id?: number;
  readonly entryId: number | null;
  readonly userId?: number | string | null;
  readonly comment: string | null;
}

export interface CommentDataProps {
  readonly id: number;
  readonly dispatchId: number;
  readonly occurrenceId: number
  readonly entryId?: number,
  readonly user: IUserComment;
  readonly comment: string;
  readonly mediaPath?: string;
  readonly createdAt: string;
  readonly updatedAt: string | null;
  readonly deletedAt: null | string;
  readonly isEdited?: boolean;
  readonly changedByUser?: IUserComment;
  readonly closingComment?: boolean;
  readonly station?: IStationComment;
  readonly device?: IDeviceComment | null;
}

interface IUserComment {
  readonly id: number | string;
  readonly name: string;
  readonly representation_name: string;
}

export interface IEditCommentResponse {
  readonly id: number;
  readonly comment: string,
  readonly media_path: string,
  readonly is_edited: boolean,
  readonly occurrence_id: number,
  readonly dispatch_id: number,
  readonly created_at: string,
  readonly updated_at: string,
  readonly user: IUserComment,
  readonly closingComment: boolean,
  readonly changed_by_user: {
    readonly id: number,
    readonly name: string
    readonly representation_name: string;
  },
  readonly device: {
    readonly id: number,
    readonly name: string,
  },
  readonly station: {
    readonly id: number,
    readonly name: string,
  }
}

const defaultCommentProps: EntryCommentProps = {
  newComment: '',
  allCurrentComments: [],
  loading: false,
  retryConnect: false,
}

const store = createState<EntryCommentProps>(Object.create(defaultCommentProps));

// eslint-disable-next-line new-cap
store.attach(Broadcasted('entry-comments'));

const useEntryCommentState = () => {
  const entryCommentState = useState(store);

  const newComment = useState(entryCommentState.newComment);
  const allCurrentComments = useState(entryCommentState.allCurrentComments);

  const isAwaiting = useState(false);
  const { socket } = useSocket();

  const loading = useState(entryCommentState.loading)
  const retryConnect = useState(entryCommentState.retryConnect)

  async function saveComment(receivedMessage: CommentProps) {
    const { entryId, userId, comment } = receivedMessage;

    const toDbSchema: PostCommentBodySchema = {
      "entry_id": entryId,
      "comment": comment
    }

    const { data } = await authFetch({
      url: '/entry-comment/store',
      method: 'POST',
      data: toDbSchema
    });

    return toDomain(data);
  }

  async function editComment({ comment, id }: Omit<CommentProps, 'entryId'>): Promise<IEditCommentResponse> {

    const targetUrl = `/entry-comment/${id}`

    const { data } = await authFetch({
      url: targetUrl,
      method: 'PUT',
      data: {
        comment,
      }
    })
    return data;
  }

  function sortComment(commentA: CommentDataProps, commentB: CommentDataProps) {
    const dateA = new Date(commentA.createdAt).getTime()
    const dateB = new Date(commentB.createdAt).getTime()
    return dateB - dateA
  }

  function findEntryComment(commentId: number) {
    return allCurrentComments.findIndex((comment) => comment.get().id == commentId);
  }

  function addComment(commentData: CommentDataProps) {
    if (findEntryComment(commentData.id) < 0) {
      allCurrentComments.merge([commentData]);
    }
  }

  function editedComment({ comment, id, updatedAt, changedByUser, isEdited }: CommentDataProps) {
    const commentIndex = findEntryComment(id);
    if (commentIndex > -1) {
      allCurrentComments[commentIndex].comment.set(comment)
      allCurrentComments[commentIndex].updatedAt.set(updatedAt)
      allCurrentComments[commentIndex].isEdited.set(isEdited)
      allCurrentComments[commentIndex].changedByUser.set(changedByUser)

    }
  }

  return ({
    isAwaiting: isAwaiting,
    loading: () => loading,
    retryConnect: () => retryConnect,
    newComment: () => newComment,
    resetComment: () => {
      newComment.set('');
      allCurrentComments.set([]);
    },
    allCurrentComments: () => allCurrentComments,
    syncPreviousComments: async (entryId: number | null) => {
      retryConnect.set(false)
      loading.set(true)
      newComment.set('');
      allCurrentComments.set([]);

      authFetch({
        url: '/entry-comment/get-comment',
        method: 'POST',
        data: {
          idRef: entryId
        }
      }).then(({ data }) => {
        if (!data) return false
        allCurrentComments.set(data.map((comment) => toDomain(comment)))
        return true
      }).catch(() => retryConnect.set(true)).finally(() => loading.set(false));

    },
    sendEntryComment: async ({ entryId, comment }: CommentData) => {

      const isEmptyString = !comment?.length

      if (isEmptyString) {
        return false
      }


      const result = saveComment(
        {
          entryId: entryId,
          comment: comment
        })
        .then((result) => {
          addComment(result);
          newComment.set('');
        })
        .catch(err => {
          console.error(err)
          return false
        })

      return true
    },
    sendEditEntryComment: async ({ comment, id }: Omit<CommentProps, 'entryId'>) => {
      const commentEdited = await editComment({ id, comment });
      editedComment({
        comment: commentEdited.comment,
        mediaPath: commentEdited.media_path,
        createdAt: commentEdited.created_at,
        dispatchId: commentEdited.dispatch_id,
        occurrenceId: commentEdited.occurrence_id,
        id: commentEdited.id,
        user: commentEdited.user,
        changedByUser: commentEdited.changed_by_user,
        deletedAt: null,
        updatedAt: commentEdited.updated_at,
        isEdited: commentEdited.is_edited,
        closingComment: commentEdited.closingComment,
        device: commentEdited.device,
        station: commentEdited.station,
      });

      newComment.set('');
      return true
    },
    sortComment: (a: CommentDataProps, b: CommentDataProps) => sortComment(a, b),
    findEntryComment: findEntryComment
  }
  )
}

export default useEntryCommentState;