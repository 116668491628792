import React, { useEffect } from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Paper, Box, Typography, Chip, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Avatar, Grid } from '@mui/material';
import { useLang } from "~/hooks/useLang";
import { useHookstate } from '@hookstate/core';
import { getDispatchesForOccurrenceById, getInfoDispatch, getTimeLineDispatch } from '~/features/Occurrence/services/index';
import useSystemState from '~/features/System/stores/SystemState';
import useOccurrenceState from '../../stores/OccurrenceState';
import IDispatchOccurrence, { TimeLineOccurrenceDispatchProps } from '../../interfaces/IDispatchOccurrence';
import useOccurrenceDispatchesRelated from '../../stores/OccurrenceDIspatchesRelated';
import TimelineDispatchesOccurrence from '../OccurrenceList/components/timeLineDispatchesOccurrence';
import IDispatch from '~/features/Dispatch/interfaces/IDispatch';
import { Refresh } from '@mui/icons-material';

interface dispatchDestinationProps {
  readonly id: number
  readonly code: string
}

export default function OccurrenceListDispatches({ occurrenceId }) {
  const { translate } = useLang();
  const { getStatusColors } = useSystemState();
  const { occurrenceDispatchesRelated, syncOccurrenceDispatchesRelated, updatedStatusOccurrenceDispatchesRelated } = useOccurrenceDispatchesRelated();
  const dispatches = useHookstate<readonly IDispatchOccurrence[]>([]);
  const { dispatchesOccurrence, occurrence } = useOccurrenceState();
  const statusUpdated = useHookstate(dispatchesOccurrence());
  const infoDispatch = useHookstate<IDispatch | null>(null);
  const dispatchDestination = useHookstate<dispatchDestinationProps | null>(null);
  const open = useHookstate(false);
  const dispatchIdSelected = useHookstate(null);
  const timeLine = useHookstate<TimeLineOccurrenceDispatchProps[]>([]);
  const loading = useHookstate(false)
  const retryConnect = useHookstate(false)
  const forceRefresh = useHookstate(false)

  useEffect(() => {
    retryConnect.set(false)
    loading.set(true)
    dispatches.set([])

    getDispatchesForOccurrenceById({ id: occurrenceId })
      .then((response) => dispatches.set(response))
      .catch(() => retryConnect.set(true))
      .finally(() => {
        loading.set(false)
      })

  }, [occurrenceId, occurrence().closed_at.get(), forceRefresh.get()])

  useEffect(() => {
    if (updatedStatusOccurrenceDispatchesRelated.get() === true) {
      dispatchesOccurrence().get().map((occurrenceGlobal) =>
        occurrenceGlobal.dispatches.map((dispatchGlobal) =>
          dispatches.filter((dispatchLocal) =>
            dispatchLocal.id.get() === dispatchGlobal.id ? dispatchLocal.status.set(JSON.parse(JSON.stringify(dispatchGlobal.status))) : '')))
      updatedStatusOccurrenceDispatchesRelated.set(false)
    }

  }, [updatedStatusOccurrenceDispatchesRelated.get()])

  useEffect(() => {
    if (syncOccurrenceDispatchesRelated.get() === true) {
      dispatches.set([])
      occurrenceDispatchesRelated().get().map((item) => {
        dispatches.merge(JSON.parse(JSON.stringify([{
          agency: item.agency,
          code: item.code,
          dispatch_group: item.dispatch_group,
          id: item.id,
          status: item.status,
          occurrenceId: item.occurrenceId
        }])))
      })
      syncOccurrenceDispatchesRelated.set(false)
    }
  }, [syncOccurrenceDispatchesRelated.get()])


  const handleClickOpen = ({ dispatchCode }) => {
    getInfoDispatch({ code: dispatchCode }).then((response) => {
      infoDispatch.set(response.data)
      dispatchDestination.set(
        response?.data?.unified?.dispatch_destination
          ? response?.data?.unified?.dispatch_destination
          : null
      )
      dispatchIdSelected.set(response.data.id)

      if (response.status === 200) {
        open.set(true)
      }
    }).finally(() => {
      getTimeLineDispatch({ dispatchId: dispatchIdSelected.get() }).then((response) => {
        timeLine.set(response.data)
      }
      )
    })
  }


  const handleClose = () => {
    open.set(false)
  }

  return (
    <>
      <Paper
        variant='outlined'
        sx={{
          width: '100%',
          height: '418px',
          display: 'flex',
          alignItems: !dispatches.get().length ? 'center' : 'flex-start',
          overflowY: 'auto'
        }} >
        {!loading.get() ? !retryConnect.get() ? !dispatches.get().length ? <Box style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography>
            {translate('No Dispatches')}
          </Typography>
        </Box> :
          <List dense={true} sx={{ width: '100%' }}>
            {
              dispatches.map((dispatch) => (
                <ListItem sx={{ padding: '5px 8px' }} key={dispatch.get()?.id}>
                  <ListItemAvatar sx={{ margin: 1 }}>
                    <Chip
                      label={dispatch.get()?.code}
                      onClick={() => handleClickOpen({ dispatchCode: dispatch.get()?.code })}
                      sx={{
                        backgroundColor: getStatusColors(dispatch.get()?.status?.id).main,
                        color: getStatusColors(dispatch.get()?.status?.id).contrastText,
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={dispatch.get()?.dispatch_group?.name ? dispatch.get()?.dispatch_group?.name : ''}
                    secondary={dispatch.get()?.status?.name ? dispatch.get()?.status?.name : ''}
                  />
                </ListItem>))
            }
          </List>
          :
          <Grid item xs={12} display={'flex'} sx={{ justifyContent: 'center', width: '100%', height: '100%', alignContent: 'center'}}>
            <Chip sx={{ marginLeft: '0%', marginTop: '50%' }} label={translate("Connection failed! Please try again")} avatar={<Avatar><Refresh /></Avatar>} onClick={() => forceRefresh.set(!forceRefresh.get())} />
          </Grid>
          :
          <Grid item xs={12} display={'flex'} sx={{ justifyContent: 'center', width: '100%', height: '100%', alignItems: 'center' }}>
            <CircularProgress sx={{ marginLeft: '0%' }} disableShrink />
          </Grid>
        }
      </Paper>
      <Dialog maxWidth={'xs'} fullWidth={true} open={open.get()} onClose={handleClose}>
        <DialogTitle>
          {translate('Dispatch')} - {infoDispatch.get()?.code} - {infoDispatch.get()?.status.acronym}
          <Typography variant='subtitle2'>
            {infoDispatch.get()?.dispatchGroup?.name} {infoDispatch.get()?.agency?.name}
          </Typography>
          <Typography variant='subtitle2'>
            {infoDispatch.get()?.type?.name} {infoDispatch.get()?.subtype?.name}
          </Typography>
        </DialogTitle>
        <DialogContent>

          {dispatchDestination.get() ? (
            <>
              <br />
              <Typography>
                {translate('Unified')} {translate('With')}:{' '}
                <Chip label={dispatchDestination.get()?.code} />
              </Typography>
            </>
          ) : (
            false
          )}

          <TimelineDispatchesOccurrence timeLinePoint={timeLine} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            {translate('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>

  )
}