/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Grid, IconButton } from '@mui/material';
import { useHookstate } from '@hookstate/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useLang } from '~/hooks/useLang';
import Layout from '~/components/Layout';

import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '~/components/ErrorFallback';
import EntryTimeByCenterColumnChart from '../components/EntryTimeByCenterColumnChart';
import EntryTimeByStationColumnChart from '../components/EntryTimeByStationColumnChart';
import EntryTimeByUserColumnChart from '../components/EntryTimeByUserColumnChart';
import EntryOpenCenterPieChart from '../components/EntryOpenCenterPieChart';
import EntryByCenterPieChart from '../components/EntryByCenterPieChart';
import EntryOpenStationPieChart from '../components/EntryOpenStationPieChart';
import EntryByStationPieChart from '../components/EntryByStationPieChart';
import EntryOpenUserPieChart from '../components/EntryOpenUserPieChart';
import EntryByUserPieChart from '../components/EntryByUserPieChart';
import EntryBySubtypePieChart from '../components/EntryBySubtypePieChart';
import EntryByTypePieChart from '../components/EntryByTypePieChart';
import EntryTotalByStatusWidget from '../components/EntryTotalByStatusWidget';
import EntryFiltersDashboard from '../components/EntryFiltersDashboard';
import dayjs, { Dayjs } from 'dayjs';
import InternalHeader from '~/components/Layout/components/InternalHeader';

function Dashboard() {
  const { translate } = useLang();
  const loading = useHookstate<boolean>(false)
  const agencyId = useHookstate<number | null>(null)
  const typeId = useHookstate<number | null>(null)
  const subtypeId = useHookstate<number | null>(null)
  const initialPeriod = useHookstate('0')
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs().subtract(24, 'hours'));
  const [finishDate, setFinishDate] = useState<Dayjs | null>(dayjs());
  // const {
  //   type,
  //   subtype
  // } = types;
  const expandFilterSearch = useHookstate(true);
  const expandTotalByStatus = useHookstate(true);
  const expandFilterTypes = useHookstate(true);
  const expandFilterUser = useHookstate(true);
  const expandFilterStation = useHookstate(true);
  const expandFilterCenter = useHookstate(true);
  const expandFilterTimeUser = useHookstate(true);
  const expandFilterTimeStation = useHookstate(true);
  const expandFilterTimeCenter = useHookstate(true);

  const refresh = useHookstate(false);

  const cityId = useHookstate<number | null>(null)

  useEffect(() => {
    document.title = translate('Entry Dashboard - Dispatch System')
  }, [])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true}>
        <InternalHeader title={translate(`Management Dashboard: Entry`)} />
      
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader title={`${translate('Filters')}`} avatar={
            <IconButton onClick={() => expandFilterSearch.set(!expandFilterSearch.get())} sx={{ display: 'flex', justifyContent: 'end', marginRight: 1, padding: 0 }}>
              {expandFilterSearch.value ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
            </IconButton>} titleTypographyProps={{ fontWeight: 'bold', fontSize: 18 }}/>

          <EntryFiltersDashboard
          loading={loading}
            clearFilters={() => {
              agencyId.set(null)
              typeId.set(null)
              subtypeId.set(null)
              initialPeriod.set('')
              setFinishDate(null)
              setStartDate(null)
              cityId.set(null)
            }}
            searchButton={() => {
              refresh.set(!refresh.get())
            }}
            hidden={expandFilterSearch.value}
            agencyId={agencyId}
            typeId={typeId}
            subtypeId={subtypeId}
            initialPeriod={initialPeriod}
            onStartDateChange={(value) => setStartDate(value)}
            onFinishDateChange={(value) => setFinishDate(value)}
            onPeriodChange={(value) => initialPeriod.set(value)}
            cityId={cityId}
          />
        </Card>

        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader title={`${translate('Total by Status')}`} avatar={
            <IconButton onClick={() => expandTotalByStatus.set(!expandTotalByStatus.get())} sx={{ display: 'flex', justifyContent: 'end', marginRight: 1, padding: 0 }}>
              {expandTotalByStatus.value ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
            </IconButton>} titleTypographyProps={{ fontWeight: 'bold', fontSize: 18 }}/>

          <Grid container xs={12}>
            <Grid item xs={12} hidden={!expandTotalByStatus.value}>
              <EntryTotalByStatusWidget
                loading={loading}
                refresh={refresh.get()}
                agencyId={agencyId.get() ?? null}
                deviceId={null}
                endDate={finishDate?.toDate() ?? null}
                startDate={startDate?.toDate() ?? null}
                subtypeId={subtypeId.get()}
                typeId={typeId.get()}
                cityId={cityId.get()}
                 />
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader title={`${translate('Type and Subtype')}`} avatar={
            <IconButton onClick={() => expandFilterTypes.set(!expandFilterTypes.get())} sx={{ display: 'flex', justifyContent: 'end', marginRight: 1, padding: 0 }}>
              {expandFilterTypes.value ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
            </IconButton>} titleTypographyProps={{ fontWeight: 'bold', fontSize: 18 }}/>

          <Grid container xs={12}>
            <Grid item xs={6} hidden={!expandFilterTypes.value}>
              <EntryByTypePieChart
                loading={loading}
                refresh={refresh.get()}
                agencyId={agencyId.get()}
                deviceId={null}
                endDate={finishDate?.toDate() ?? null}
                startDate={startDate?.toDate() ?? null}
                subtypeId={subtypeId.get()}
                typeId={typeId.get()} 
                cityId={cityId.get()}
                />
            </Grid>
            <Grid item xs={6} hidden={!expandFilterTypes.value}>
              <EntryBySubtypePieChart
                loading={loading}
                refresh={refresh.get()}
                agencyId={agencyId.get()}
                deviceId={null}
                endDate={finishDate?.toDate() ?? null}
                startDate={startDate?.toDate() ?? null}
                subtypeId={subtypeId.get()}
                typeId={typeId.get()} 
                cityId={cityId.get()}
                />
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader title={`${translate('User')}`} avatar={
            <IconButton onClick={() => expandFilterUser.set(!expandFilterUser.get())} sx={{ display: 'flex', justifyContent: 'end', marginRight: 1, padding: 0 }}>
              {expandFilterUser.value ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
            </IconButton>} titleTypographyProps={{ fontWeight: 'bold', fontSize: 18 }}/>

          <Grid container xs={12}>
            <Grid item xs={6} hidden={!expandFilterUser.value}>
              <EntryByUserPieChart
                loading={loading}
                refresh={refresh.get()}
                agencyId={agencyId.get()}
                deviceId={null}
                endDate={finishDate?.toDate() ?? null}
                startDate={startDate?.toDate() ?? null}
                subtypeId={subtypeId.get()}
                typeId={typeId.get()} 
                cityId={cityId.get()}
                />
            </Grid>
            <Grid item xs={6} hidden={!expandFilterUser.value}>
              <EntryOpenUserPieChart
                loading={loading}
                refresh={refresh.get()}
                agencyId={agencyId.get()}
                deviceId={null}
                endDate={finishDate?.toDate() ?? null}
                startDate={startDate?.toDate() ?? null}
                subtypeId={subtypeId.get()}
                typeId={typeId.get()} 
                cityId={cityId.get()}
                />
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader title={`${translate('Station')}`} avatar={
            <IconButton onClick={() => expandFilterStation.set(!expandFilterStation.get())} sx={{ display: 'flex', justifyContent: 'end', marginRight: 1, padding: 0 }}>
              {expandFilterStation.value ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
            </IconButton>} titleTypographyProps={{ fontWeight: 'bold', fontSize: 18 }}/>

          <Grid container xs={12}>
            <Grid item xs={6} hidden={!expandFilterStation.value}>
              <EntryByStationPieChart
                loading={loading}
                refresh={refresh.get()}
                agencyId={agencyId.get()}
                deviceId={null}
                endDate={finishDate?.toDate() ?? null}
                startDate={startDate?.toDate() ?? null}
                subtypeId={subtypeId.get()}
                typeId={typeId.get()} 
                cityId={cityId.get()}
                />
            </Grid>
            <Grid item xs={6} hidden={!expandFilterStation.value}>
              <EntryOpenStationPieChart
                loading={loading}
                refresh={refresh.get()}
                agencyId={agencyId.get()}
                deviceId={null}
                endDate={finishDate?.toDate() ?? null}
                startDate={startDate?.toDate() ?? null}
                subtypeId={subtypeId.get()}
                typeId={typeId.get()} 
                cityId={cityId.get()}
                />
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader title={`${translate('Center')}`} avatar={
            <IconButton onClick={() => expandFilterCenter.set(!expandFilterCenter.get())} sx={{ display: 'flex', justifyContent: 'end', marginRight: 1, padding: 0 }}>
              {expandFilterCenter.value ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
            </IconButton>} titleTypographyProps={{ fontWeight: 'bold', fontSize: 18 }}/>

          <Grid container xs={12}>
            <Grid item xs={6} hidden={!expandFilterCenter.value}>
              <EntryByCenterPieChart
                loading={loading}
                refresh={refresh.get()}
                agencyId={agencyId.get()}
                deviceId={null}
                endDate={finishDate?.toDate() ?? null}
                startDate={startDate?.toDate() ?? null}
                subtypeId={subtypeId.get()}
                typeId={typeId.get()} 
                cityId={cityId.get()}
                />
            </Grid>
            <Grid item xs={6} hidden={!expandFilterCenter.value}>
              <EntryOpenCenterPieChart
                loading={loading}
                refresh={refresh.get()}
                agencyId={agencyId.get()}
                deviceId={null}
                endDate={finishDate?.toDate() ?? null}
                startDate={startDate?.toDate() ?? null}
                subtypeId={subtypeId.get()}
                typeId={typeId.get()} 
                cityId={cityId.get()}
                />
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader title={`${translate('Entry Time User')}`} avatar={
            <IconButton onClick={() => expandFilterTimeUser.set(!expandFilterTimeUser.get())} sx={{ display: 'flex', justifyContent: 'end', marginRight: 1, padding: 0 }}>
              {expandFilterTimeUser.value ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
            </IconButton>} titleTypographyProps={{ fontWeight: 'bold', fontSize: 18 }}/>

          <Grid container xs={12}>
            <Grid item xs={12} hidden={!expandFilterTimeUser.value}>
              <EntryTimeByUserColumnChart
                loading={loading}
                refresh={refresh.get()}
                agencyId={agencyId.get()}
                deviceId={null}
                endDate={finishDate?.toDate() ?? null}
                startDate={startDate?.toDate() ?? null}
                subtypeId={subtypeId.get()}
                typeId={typeId.get()} 
                cityId={cityId.get()}
                />
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader title={`${translate('Entry Time Station')}`} avatar={
            <IconButton onClick={() => expandFilterTimeStation.set(!expandFilterTimeStation.get())} sx={{ display: 'flex', justifyContent: 'end', marginRight: 1, padding: 0 }}>
              {expandFilterTimeStation.value ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
            </IconButton>} titleTypographyProps={{ fontWeight: 'bold', fontSize: 18 }} />

          <Grid container xs={12}>
            <Grid item xs={12} hidden={!expandFilterTimeStation.value}>
              <EntryTimeByStationColumnChart
                loading={loading}
                refresh={refresh.get()}
                agencyId={agencyId.get()}
                deviceId={null}
                endDate={finishDate?.toDate() ?? null}
                startDate={startDate?.toDate() ?? null}
                subtypeId={subtypeId.get()}
                typeId={typeId.get()} 
                cityId={cityId.get()}
                />
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader title={`${translate('Entry Time Center')}`} avatar={
            <IconButton onClick={() => expandFilterTimeCenter.set(!expandFilterTimeCenter.get())} sx={{ display: 'flex', justifyContent: 'end', marginRight: 1, padding: 0 }}>
              {expandFilterTimeCenter.value ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
            </IconButton>} titleTypographyProps={{ fontWeight: 'bold', fontSize: 18 }}/>

          <Grid container xs={12}>
            <Grid item xs={12} hidden={!expandFilterTimeCenter.value}>
              <EntryTimeByCenterColumnChart
                loading={loading}
                refresh={refresh.get()}
                agencyId={agencyId.get()}
                deviceId={null}
                endDate={finishDate?.toDate() ?? null}
                startDate={startDate?.toDate() ?? null}
                subtypeId={subtypeId.get()}
                typeId={typeId.get()} 
                cityId={cityId.get()}
                />
            </Grid>
          </Grid>
        </Card>

      </Layout>
    </ErrorBoundary>
  )
}

export default Dashboard;