export function getBaseUrl(){
  const servicesHostName =  process.env.REACT_APP_SERVICES_HOST;
  const protocolWeb = window.location.protocol ?? 'http:';
  const productionPort = protocolWeb === 'https:'? '/back' : `:${process.env.REACT_APP_BACKEND_PRODUCTION_PORT}`;

  return servicesHostName === '%current%' ? `${protocolWeb}//${window.location.hostname}${productionPort}` : `${protocolWeb}//${servicesHostName}`; 
}

export function getSocketUrl() {
  const servicesHostName =  process.env.REACT_APP_SOCKET_URL;  
  const protocolWeb = window.location.protocol ?? 'http:';
  const protocolSocket = protocolWeb === 'https:' ? 'wss:' : 'ws:'

  const productionPort = protocolWeb === 'https:' ? '' : `:${process.env.REACT_APP_BACKEND_PRODUCTION_PORT}`;

  return servicesHostName === '%current%' ? `${protocolSocket}//${window.location.hostname}${productionPort}` : `${protocolSocket}//${servicesHostName}`; 
}