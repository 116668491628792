/* eslint-disable max-lines-per-function */
import React from 'react'
import {
  createState,
  useState,
} from '@hookstate/core'
import { authFetch } from '~/services/fetch'
import {
  IDispacthRegistrationPerson, IPersonDTO
} from '../interfaces/IDispacthRegistrationPerson';
import { toDomain, toFetch } from '~/mappers/registration-people-mapper';

export const personDefaultState = {
  name: '',
  cpf: '',
  otherDocumentType: '',
  otherDocument: null,
  age: '',
  gender: null,
  address: '',
  addressNumber: '',
  addressReference: '',
  cep: '',
  cityId: '',
  stateId: '',
  district: '',
  involvementType: null,
  nickname: '',
  motherName: '',
  fatherName: '',
  birthDate: null,
  phoneNumber: '',
  arrested: '',
  publicAgent: '',
  ethnicity: null,
  occurrenceId: null,
  education: null,
  occupation: null,
  peopleCircunstance: null,
  destination: null,
  organization: null,
  notes: '',
  hasMentalIllness: false,
  hasPhysicalIllness: false,
  isLgbt: false,
}

const defaultRegistrationProps: IDispacthRegistrationPerson = {
  people: [],
  person: personDefaultState,
  documentTypelist: [],
  genderlist: [],
  involvementTypelist: [],
  ethnicitylist: [],
  educationlist: [],
  peopleCircunstancelist: [],
  organizationlist: [],
  occupationList: [],
}

const registrationPersonDispatchState = createState<IDispacthRegistrationPerson>(defaultRegistrationProps);

const useDispatchRegistrationPersonState = () => {
  const registrationPersonDispatch = useState(registrationPersonDispatchState);
  return {
    registrationPersonDispatch: () => registrationPersonDispatch,
    getArrestedValues: () => {
      return ['Não houve', 'Flagrante', 'Mandado']
    },
    getPublicAgentValues: () => {
      return ['Não', 'Sim, fora de serviço', 'Sim, em serviço',]
    },
    getDocumentTypes: async () => {
      const targetUrl = '/document-type'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;
    },
    getGenders: async () => {
      const targetUrl = '/gender'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;
    },
    getInvolvementTypes: async () => {
      const targetUrl = '/involvement-type'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;

    },
    getEthnicities: async () => {
      const targetUrl = '/ethnicity'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;

    },
    getSchooling: async () => {
      const targetUrl = '/education'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;

    },
    getOccupation: async () => {
      const targetUrl = '/occupation'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;

    },
    getPeopleCircunstances: async () => {
      const targetUrl = '/people-circunstance'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;

    },
    getOrganizations: async () => {
      const targetUrl = '/organization'
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      return response.data;

    },
    getAssociatedPeople: async (id: string) => {
      const targetUrl = `/associated-people/${id}`
      const { data: person } = await authFetch({
        url: targetUrl,
        method: 'GET',
      });
      registrationPersonDispatch
        .person
        .set(toDomain(person));
    },
    getAllAssociatedPerson: async (occurrenceId: number | string | null) => {
      const targetUrl = '/occurrence/associated-people/all-from-occurrence'
      if(occurrenceId){
        try {
          const response = await authFetch({
            url: targetUrl,
            data: {
              occurrence_id: occurrenceId,
            }
          });
    
          return response.data.map((person): IPersonDTO => {
            return toDomain(person)
          })
        } catch (error) {
          throw error
        }
        
      }
    },
    sendPerson: async (
      person: IPersonDTO) => {

      const targetUrl = '/occurrence/associated-people/store'
      return await authFetch({
        url: targetUrl,
        data: toFetch(person)
      });
    },
    editPerson: async (
      { id, ...person }: IPersonDTO) => {

      const targetUrl = `/occurrence/associated-people/${id}`
      await authFetch({
        url: targetUrl,
        method: 'PUT',
        data: toFetch(person)
      });
    },
    deletePerson: async (id: string) => {
      const targetUrl = `/occurrence/associated-people/${id}`
      return await authFetch({
        url: targetUrl,
        method: 'DELETE',
      });
    },
  }
}

export default useDispatchRegistrationPersonState;