import React from 'react';
import { createState, useState } from '@hookstate/core';

interface IUseListAllDispatchFilters {
  readonly filtersOn: boolean;
  readonly dateInitial: Date | null; 
  readonly dateEnd: Date | null;
  readonly dispatchCategory: string | number | null;
  readonly dispatchType: string | number | null;
  readonly dispatchSubtype: string | number | null;
  readonly dispatchAgencyName: string | number | null;
  readonly dispatchAgencyId: string | number | null;
  readonly dispatchDeviceList: string | number | null;
  readonly dispatchGroupName: ReadonlyArray<string | null> | string | number | null;
  readonly dispatchGroupId: ReadonlyArray<number | null> | string | number | null;
}

const dashboardFilter:IUseListAllDispatchFilters = {
  filtersOn: false,
  dateInitial: null,
  dateEnd: null,
  dispatchCategory: null,
  dispatchType: null,
  dispatchSubtype: null,
  dispatchAgencyName: null,
  dispatchAgencyId: null,
  dispatchDeviceList: null,
  dispatchGroupName: [],
  dispatchGroupId: []
}

const store = createState<IUseListAllDispatchFilters>(dashboardFilter)

export const useListAllDispatchFilters = () => {
  const {
    filtersOn,
    dateInitial,
    dateEnd,
    dispatchCategory,
    dispatchType,
    dispatchSubtype,
    dispatchAgencyName,
    dispatchAgencyId,
    dispatchGroupName,
    dispatchGroupId,
    dispatchDeviceList
  } = useState(store)

  return ({
    filtersOn,
    dateInitial,
    dateEnd,
    dispatchCategory,
    dispatchType,
    dispatchSubtype,
    dispatchAgencyName,
    dispatchAgencyId,
    dispatchGroupName,
    dispatchGroupId,
    dispatchDeviceList,
  })
}

export default useListAllDispatchFilters