/* eslint-disable */
import React, { useEffect } from 'react';
import { Popup } from "react-leaflet";
import { useHookstate } from '@hookstate/core';
import { MarkerPopupContent } from '~/features/Map/components/MarkerPopupContent';
import MapMarkersRender from '~/features/Map/components/MapMarkers/MapMarkersRender';
import { LatLng } from 'leaflet';
import useDispatchCommentState from '~/features/Dispatch/stores/DispatchCommentState';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
interface MapMarkersProps {
  readonly viewGroups: string[];
  readonly nearMarker?: boolean;
  readonly checkedViewGroups: readonly string[];
  readonly viewGroupsInCluster?: readonly string[];
  readonly disableZoomLimit?: readonly string[];
}

export default function MapMarkersEvents({ viewGroups, nearMarker = false, checkedViewGroups, viewGroupsInCluster=[], disableZoomLimit=[] }: MapMarkersProps) {
  const popupOpen = useHookstate(false);
  const popupMarker = useHookstate<any>(null);
  const {canClosePopup} = useDispatchState().dispatchStatusActions()
  
  const closeThisPopUp = () => {
    popupOpen.set(false);
    popupMarker.set(null);
    canClosePopup.set(false)
    return true
  } 
  
  return (
    <>
      <MapMarkersRender viewGroups={viewGroups} nearMarker={nearMarker} checkedViewGroups={checkedViewGroups} popupOpen={popupOpen} popupMarker={popupMarker} viewGroupsInCluster={viewGroupsInCluster} disableZoomLimit={disableZoomLimit}/>
      {popupOpen.get() && 
      <Popup
        offset={[0, -30]}
        minWidth={500}
        maxWidth={800}
        maxHeight={500}
        position={popupMarker['position'].get()}
        closeButton={false}
      >{<MarkerPopupContent type={popupMarker['groupMarker'].get()} {...popupMarker['markerInfo'].get()} readonly={false} closeThisPopUp={closeThisPopUp}/>}</Popup> }
    </>
      
  )
}
