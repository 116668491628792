import React, {useState} from 'react';
import { Grid } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import OccurrenceRecallTable from '~/features/Entry/components/OccurrenceRecallTable';
import { GridRowModel } from "@mui/x-data-grid";

export default function EntryRecall() {

  return (
    <Grid container>
      <OccurrenceRecallTable/>
    </Grid>
  );
}
