/* eslint-disable max-lines-per-function */
import { authFetch } from '~/services/fetch'
import {
  ISurveillanceReporting,
  ISurveillanceReportingState,
} from '../interfaces/ISurveillanceReporting'
// import IListSurveillanceReporting from '../interfaces/IListSurveillanceReporting';
import IAssociateOccurrence from '../interfaces/IAssociateOccurrence'
import { createState, useHookstate, useState } from '@hookstate/core'
import { IListSurveillanceReporting } from '../interfaces/IListSurveillanceReporting'
import { Validation as validation } from '@hookstate/validation'
import notify from '~/utils/notify'
import { useLang } from '~/hooks/useLang'

const defaultSurveillanceReportingProps: ISurveillanceReportingState = {
  reports: [],
  filters: [],
  surveillanceReporting: {
    cameraId: null,
    notes: null,
    occurrenceId: null,
    reportedAt: null,
    typeId: null,
    subtypeId: null,
  },
  loading: false,
  newNarrations: false,
  save: false,
  verifyType: false,
  verifySubtype: false,
  showCheckbox: false,
  switchCheck: true,
  createOccurrence: false,
  searchDispatches: null,
  searchOccurrence: '',
  isOpenModalReportsCards: false,
  totalSubtypes: 0,
}

const surveillanceReportingState = createState<ISurveillanceReportingState>(
  defaultSurveillanceReportingProps
)

export default function useSurveillanceReportingState() {
  const { translate } = useLang()
  const surveillanceReporting = useState(surveillanceReportingState)
  const {
    createOccurrence,
    showCheckbox,
    verifyType,
    verifySubtype,
    totalSubtypes,
  } = surveillanceReporting
  const { notes, cameraId, subtypeId, typeId } =
    surveillanceReporting.surveillanceReporting

  notes.attach(validation)
  cameraId.attach(validation)
  subtypeId.attach(validation)
  typeId.attach(validation)
  createOccurrence.attach(validation)

  validation(notes).validate(
    () => Boolean(notes.get() != null),
    'Se o relato está em branco'
  )
  validation(cameraId).validate(
    () => Boolean(cameraId.get() != null),
    'Se a camera está em branco'
  )
  validation(typeId).validate(
    () => Boolean(createOccurrence.get() && typeId.get() != null),
    'Se o tipo está em branco'
  )
  validation(subtypeId).validate(
    () =>
      Boolean(
        createOccurrence.get() && totalSubtypes.get() > 0
          ? totalSubtypes.get() > 0 && subtypeId.get() != null
          : true
      ),
    'Se precisa informar um subtipo'
  )

  const verifyInputErros = () => {
    if (!showCheckbox.get() && createOccurrence.get()) {
      verifyType.set(true)
      verifySubtype.set(true)
      if (
        validation(notes).valid() &&
        validation(cameraId).valid() &&
        validation(typeId).valid() && 
        validation(subtypeId).valid()
      ) {
        return true
      } else {
        notify({
          message: `${translate(
            'Please check the information and try again!'
          )}`,
          type: 'error',
        })
        return false
      }
    }

    if (showCheckbox.get() && createOccurrence.get()) {
      verifyType.set(true)
      verifySubtype.set(true)
      if (validation(typeId).valid() && validation(subtypeId).valid()) {
        return true
      } else {
        notify({
          message: `${translate(
            'Please check the information and try again!'
          )}`,
          type: 'error',
        })
        return false
      }
    }

    if (!showCheckbox.get() && !createOccurrence.get()) {
      if (validation(notes).valid() && validation(cameraId).valid()) {
        return true
      } else {
        notify({
          message: `${translate(
            'Please check the information and try again!'
          )}`,
          type: 'error',
        })
        return false
      }
    }
  }

  return {
    verifyInputErros: verifyInputErros,
    surveillanceReportingStates: () => surveillanceReporting,
    associateOccurrence: async ({
      occurrenceId,
      surveillanceReportIds,
      toCreateOccurrence,
      typeId,
      subtypeId,
      latitude,
      longitude,
      cameraId,
    }: IAssociateOccurrence) => {
      try {
        const response = await authFetch({
          url: '/video-surveillance/associate-occurrence',
          data: {
            surveillance_report_ids: surveillanceReportIds,
            occurrence_id: occurrenceId,
            to_create_occurrence: toCreateOccurrence,
            camera_id: cameraId,
            type_id: typeId,
            subtype_id: subtypeId,
            latitude: latitude,
            longitude: longitude,
          },
        })
        return response
      } catch (error) {
        console.error(error)
      }
    },
    sendNewSurveillanceReporting: async ({
      cameraId,
      notes,
      occurrenceId,
      reportedAt,
      toCreateOccurrence,
      typeId,
      subtypeId,
      latitude,
      longitude,
    }: ISurveillanceReporting) => {
      try {
        const response = await authFetch({
          url: '/video-surveillance/store',
          data: {
            camera_id: cameraId,
            occurrence_id: occurrenceId,
            notes: notes,
            reported_at: reportedAt,
            to_create_occurrence: toCreateOccurrence,
            type_id: typeId,
            subtype_id: subtypeId,
            latitude: latitude,
            longitude: longitude,
          },
        })
        return response
      } catch (error) {
        throw error
      }
    },
    editSurveillanceReporting: async ({
      id,
      cameraId,
      occurrenceId,
      reportedAt,
      notes,
    }: ISurveillanceReporting) => {
      try {
        const response = await authFetch({
          url: `/video-surveillance/${id}`,
          method: 'PUT',
          data: {
            camera_id: cameraId,
            occurrence_id: occurrenceId,
            notes,
            reported_at: reportedAt,
          },
        })
        return response
      } catch (error) {
        console.error(error)
      }
    },

    listAllSurveillanceReporting: async () => {
      try {
        const response = await authFetch({
          url: '/video-surveillance',
          method: 'GET',
        })
        return response
      } catch (error) {
        throw error
      }
    },
    listSurveillanceReporting: async ({
      filters,
      limit = 10,
      page,
    }: IListSurveillanceReporting) => {
      try {
        const response = await authFetch({
          url: '/video-surveillance',
          method: 'POST',
          data: {
            filters,
            orders: [{ field: 'created_at', sort: 'desc' }],
            page,
            limit,
          },
        })
        return response.data
      } catch (error) {
        console.error(error)
      }
    },
  }
}
