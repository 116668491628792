import React from 'react';
import { Button, Collapse, Grid, Card, List, ListItemButton, ListItemText, Box, Typography, Tooltip } from '@mui/material';
import IconAdministration from '~/components/IconsSvg/administration';
import { useLang } from '~/hooks/useLang';
import {
  ExpandLess,
  ExpandMore,
  StarBorder,
} from '@mui/icons-material';

import { useHistory } from 'react-router-dom';
import useUserState from '~/features/User/stores/UserState';
import { MenuProps } from '~/components/SidebarMenu/interfaces';

export default function WeaponItem({ handleMenuClick }: MenuProps) {
  const { translate } = useLang();
  const { verifyPermission } = useUserState();
  const [openWeaponItem, setOpenWeaponItem] = React.useState(false);

  const history = useHistory();

  const handleClickWeaponItem = () => {
    setOpenWeaponItem(!openWeaponItem);
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {verifyPermission([
        'weapon_kind_index',
        'weapon_type_index',
        'weapon_brand_index',
        'firearm_caliber_index',
      ]) && (
          <>
            <Tooltip title={translate('Weapons options')} placement="right">
              <ListItemButton sx={{ pl: 4 }} onClick={handleClickWeaponItem}>
                <ListItemText primary={translate('Weapons')} />
                {openWeaponItem ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </Tooltip>
            <Collapse in={openWeaponItem} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
              {verifyPermission(['firearm_caliber_index']) && (
                  <Tooltip title={`${translate('Create, read, update and delete')}` + ' ' + `${translate('Firearm Caliber')}`} placement="right">
                    <ListItemButton sx={{ pl: 8 }} onClick={() => {
                      handleMenuClick('administration')
                      history.push("/firearm-caliber")
                    }}>
                      <ListItemText primary={translate('Firearm Caliber')} />
                    </ListItemButton>
                  </Tooltip>
                )}
                {verifyPermission(['weapon_kind_index']) && (
                  <Tooltip title={`${translate('Create, read, update and delete')}` + ' ' + `${translate('Weapon Kind')}`} placement="right">
                    <ListItemButton sx={{ pl: 8 }} onClick={() => {
                      handleMenuClick('administration')
                      history.push("/weapon-kind")
                    }}>
                      <ListItemText primary={translate('Weapon Kind')} />
                    </ListItemButton>
                  </Tooltip>
                )}
                 {verifyPermission(['weapon_brand_index']) && (
                  <Tooltip title={`${translate('Create, read, update and delete')}` + ' ' + `${translate('Weapon Brand')}`} placement="right">
                    <ListItemButton sx={{ pl: 8 }} onClick={() => {
                      handleMenuClick('administration')
                      history.push("/weapon-brand")
                    }}>
                      <ListItemText primary={translate('Weapon Brand')} />
                    </ListItemButton>
                  </Tooltip>
                )}
                {verifyPermission(['weapon_type_index']) && (
                  <Tooltip title={`${translate('Create, read, update and delete')}` + ' ' + `${translate('Weapon Type')}`} placement="right">
                    <ListItemButton sx={{ pl: 8 }} onClick={() => {
                      handleMenuClick('administration')
                      history.push("/weapon-type")
                    }}>
                      <ListItemText primary={translate('Weapon Type')} />
                    </ListItemButton>
                  </Tooltip>
                )}                
              </List>
            </Collapse>
          </>
        )}
    </>
  );
}
