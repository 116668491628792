import React, { useEffect } from 'react'
import { Card, CardHeader, Grid } from '@mui/material'
import Layout from '~/components/Layout'
import { useLang } from '~/hooks/useLang'

import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '~/components/ErrorFallback'
import DispatchCardDashboard from '../components/DispatchCardDashboard'
import DeviceCardDashboard from '../components/DeviceCardDashboard'
import InternalHeader from '~/components/Layout/components/InternalHeader'

export interface DispatchClosedProps {
  readonly id: number
  readonly name: string
  readonly acronym: string
  readonly category_id?: number
}

/* eslint-disable max-lines-per-function */
export default function Dashboard() {
  const { translate } = useLang()

  useEffect(() => {
    document.title = translate('Dispatch Dashboard - Dispatch System')
  }, [])

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true}>
        <InternalHeader title={translate(`Management Dashboard: Dispatch`)} />
        <DispatchCardDashboard />
        <DeviceCardDashboard />
      </Layout>
    </ErrorBoundary>
  )
}
