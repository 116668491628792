import React from 'react';
import { IconButton, Grid } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { useLang } from '~/hooks/useLang';
import useSystemState from '~/features/System/stores/SystemState';

interface RegistrationButtonProps {
  readonly canAddRegistration?: boolean
}

export default function RegistrationButton({ canAddRegistration }: RegistrationButtonProps) {
  const { translate } = useLang();
  const { windowManager } = useSystemState();

  const handleClickOpen = () => {
    windowManager()['registration'].open.set(!windowManager()['registration'].open.get());
  };

  return (
    <Grid item>
      <IconButton
        disabled={!canAddRegistration}
        title={translate('Registration of Qualifiers')}
        onClick={handleClickOpen}
      >
        <PeopleAltIcon fontSize='small' />
      </IconButton>
    </Grid>);

}
