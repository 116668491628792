import { State, createState, useState } from '@hookstate/core';
import { Validation as validation } from '@hookstate/validation'
import IOccurrence from '~/features/Occurrence/interfaces/IOccurrence';
import IOccurrenceState from '~/features/Occurrence/interfaces/IOccurrenceState';
import IOccurrenceStatusActions from '~/features/Occurrence/interfaces/IOccurrenceStatusActions';
import { getDispatchesForOccurrenceById, getOccurrenceById } from '~/features/Occurrence/services/index';
import { updateOccurrenceByIdService } from '~/features/Occurrence/services/index';
import { useLang } from '~/hooks/useLang';
import notify from '~/utils/notify';
import IDispatchOccurrence from '../interfaces/IDispatchOccurrence';
import { parseDataOccurrence } from '../utils/parsers';
import { authFetch } from '~/services/fetch';
import { IOccurrenceUpdateStatusDispatches } from '../interfaces/IOccurrenceUpdateStatusDispatches';
import useDispatchRelatedState from '~/features/Dispatch/stores/DispatchRelatedState';
import { object } from 'prop-types';
import { validPhone } from '~/utils/validators';

export const occurrenceDefault: IOccurrence & IDispatchOccurrence = {
  id: 0,
  location: null,
  address_reference: null,
  name: null,
  phone: null,
  address: null,
  latitude: null,
  longitude: null,
  entry_at: null,
  created_at: null,
  closed_at: null,
  closure_requested: false,
  calls: null,
  type: {
    id: null,
    name: null,
    code: null,
  },
  subtype: {
    id: null,
    name: null,
    code: null,
  },
  operation: {
    id: null,
    name: null,
  },
  state: {
    id: null,
    name: null,
    uf: null,
  },
  city: {
    id: null,
    name: null,
  },
  district: {
    id: null,
    name: null,
  },
  entry_origin: {
    id: null,
    name: null,
  },
  code: null,
  agency: {
    id: null,
    name: null,
    acronym: null
  },
  dispatch_group: {
    id: null,
    name: null,
    acronym: null
  },
  status: {
    id: 0,
    name: null,
    acronym: null
  },
  totalSubtypes: 0,
  dispatches: [],
  totalDistricts: 0 ,
}

export const updateStatusDefault: IOccurrenceUpdateStatusDispatches = {
  id: 0,
  status: {
    id: 0,
    name: null,
    acronym: null
  }
}

export const dispatchesOccurrenceDefaut: IDispatchOccurrence = {
  id: 0,
  code: null,
  occurrenceId: 0,
  agency: {
    id: 0,
    name: null,
    acronym: null,
  },
  dispatch_group: {
    id: 0,
    name: null,
    acronym: null,
  },
  status: {
    id: 0,
    name: null,
    acronym: null,
  }
}

const occurrenceStatusActionsDefault: IOccurrenceStatusActions = {
  editingOccurrence: false
}

const occurrenceStore = Object.create(occurrenceDefault);
const occurrenceStatusActionsStore = Object.create(occurrenceStatusActionsDefault);
const statusDispatchStatusRelatedStore = Object.create(updateStatusDefault);
const dispatchesOccurrenceStore = []


const store = createState<IOccurrenceState & {
  readonly updateStatusDispatch: boolean,
  readonly updateListDispatch: boolean
}>({
  occurrence: occurrenceStore,
  occurrenceStatusActions: occurrenceStatusActionsStore,
  updateStatusDispatch: false,
  updateListDispatch: false,
  statusDispatchRelated: statusDispatchStatusRelatedStore,
  dispatchesOccurrence: dispatchesOccurrenceStore,
})


export default function useOccurrenceState() {
  const { translate } = useLang();
  const occurrence = useState(store.occurrence)
  const occurrenceStatusActions = useState(store.occurrenceStatusActions)
  const updateStatusDispatch = useState(store.updateStatusDispatch)
  const updateListDispatch = useState(store.updateListDispatch)
  const statusDispatchRelated = useState(store.statusDispatchRelated)
  const dispatchesOccurrence = useState(store.dispatchesOccurrence)
  const { id, phone, type, subtype, district, totalDistricts, totalSubtypes } = occurrence

  id.attach(validation)
  validation(id).validate(
    () => Boolean(id.get() != null),
    'Se o ID está valido'
  )

  if (phone) {
    phone.attach(validation)
    validation(phone).validate(
      () => validPhone(phone.get()),
      'Telefone Completo'
    )
  }

  if (type.id) {
    type.id.attach(validation);
    validation(type.id).validate(() => Boolean(type.id.get() != null), 'Se o Tipo está valido');
  }

  if (subtype.id) {
    subtype.id.attach(validation);
    validation(subtype.id).validate(() => (totalSubtypes.get() > 0 ? totalSubtypes.get() > 0 && subtype.id.get() != null : true), 'Se precisa informar um subtipo');
  }

  if (district?.id) {
    district.id.attach(validation);
    validation(district.id).validate(() => (totalDistricts.get() > 0 ? totalDistricts.get() > 0 && district.id.get() != null : true), 'Se precisa informar um subtipo');
  }


  return ({
    occurrence: () => occurrence,
    occurrenceStatusActions: () => occurrenceStatusActions,
    dispatchesOccurrence: () => dispatchesOccurrence,
    updateStatusDispatch: updateStatusDispatch,
    updateListDispatch: () => updateListDispatch,
    statusDispatchRelated: () => statusDispatchRelated,
    syncOccurrenceEditAttendent: (id) => {
      getOccurrenceById({ id: id }).then((response) => {
        occurrence.set(parseDataOccurrence(response));
      })
        .then((response) => {
          getDispatchesForOccurrenceById({ id: id })
            .then((response) => {
              occurrence.dispatches.set(response)
            })
        })
    },
    updateOccurrence: async () => {
      if (validation(type.id).valid() && validation(subtype.id).valid() && validation(district?.id).valid()) {
        updateOccurrenceByIdService({
          id: id.get(),
          addressReference: occurrence?.address_reference.get(),
          location: occurrence?.location?.get(),
          cityId: occurrence?.city?.id?.get(),
          districtId: occurrence?.district?.id?.get(),
          latitude: occurrence?.latitude?.get(),
          longitude: occurrence?.longitude?.get(),
          name: occurrence?.name?.get(),
          phone: occurrence?.phone?.get(),
          typeId: occurrence?.type?.id.get(),
          subtypeId: occurrence?.subtype?.id.get()
        }).then((response) => {
          occurrenceStatusActions.editingOccurrence.set(false)
          occurrence.set(parseDataOccurrence(response));
          notify({
            message: `${translate('Saved successfully!')}`,
            type: 'success',
          })
        })
      } else {
        notify({ message: `${translate('Please check the form information and try again!')}`, type: 'error' });
      }
    },
    copyOccurrence: async ({ dispatchGroupsIds, occurrenceId, loading }) => {
      loading.set(true)
      authFetch({
        url: '/occurrence/copy-dispatch',
        method: 'POST',
        data: {
          occurrence_id: occurrenceId,
          dispatch_group_ids: dispatchGroupsIds
        }
      }).finally(() => {
        loading.set(false)
      })
    },
    socketUpdateCallsOccurrence: ({ callsNumber, occurrenceId }) => {
      if (occurrenceId == id.get()) {
        occurrence.calls.set(callsNumber)
      }
    },
    socketUpdateStatusDispatchRelatedOccurrence: ({ dispatchId, status, occurrenceId }) => {
      dispatchesOccurrence.get().map((occurrence, indexOccurrence) =>
        occurrence.dispatches.filter((dispatch, indexDispatch) =>
          dispatch.id === dispatchId ? (dispatchesOccurrence[indexOccurrence].dispatches[indexDispatch].merge({ status: status })) : ''))
    },
    socketUpdateListDispatchRelatedOccurrence: ({ id, code, dispatchGroup, agency, status, occurrenceId }) => {
      dispatchesOccurrence.map((occurrence) => occurrence.dispatches.merge([{
        agency: agency,
        code: code,
        dispatch_group: dispatchGroup,
        id: id,
        status: status,
        occurrenceId: occurrenceId,
      }]))
    }
  })

}