import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Validation as validation } from '@hookstate/validation';
import { useLang } from '~/hooks/useLang';
import useEntryState from '~/features/Entry/stores/EntryState';
import useEntryDispatchState from '~/features/Entry/stores/EntryDispatchState';
import ButtonActionsContainer from '~/components/ButtonActionsContainer';
import DispatchAreaConfigTable from '~/components/DispatchAreaConfigTable';
import ButtonActionsVerticalContainer from '~/components/ButtonActionsVerticalContainer';

import { EntryDisplayComments } from '../EntryDisplayComments';
import { EntrySendCommentField } from '../EntrySendCommentField';
import CallerInputContainer from '../CallerInput';
import EventInputContainer from '../EventInput';

import useUserState from '~/features/User/stores/UserState';
import useStationState from '~/features/Station/stores/StationState';
import { useState, useHookstate } from '@hookstate/core';
import notify from '~/utils/notify';

import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '~/components/ErrorFallback';

import useMapState, { useMarkerState, validShowArea } from '~/features/Map/stores/MapState';
import { nearOccurrences } from '~/features/Occurrence/services';
import { LatLng } from 'leaflet';
import useSystemState from '~/features/System/stores/SystemState';

import { useMap } from 'react-leaflet';

type EntryModalProps = {
  readonly readonly: boolean
  readonly phoneFullEvent?: (phone: string) => boolean
  readonly phonePartialEvent?: (phone: string) => boolean
};

// eslint-disable-next-line max-lines-per-function
export default function EntryForm(props: EntryModalProps) {
  const { translate } = useLang()
  const entryState = useEntryState();
  const { station } = useStationState();
  const { windowManager } = useSystemState();
  const {
    id,
    reference,
    location,
    name,
    phone,
    cityId: cityId,
    districtId: districtId,
    typeId: typeId,
    subtypeId: subtypeId,
    entryOriginId,
    typeName,
    latitude,
    longitude,
    subtypeName,
    totalSubtypes,
    saved,
    totalDistricts
  } = entryState.entry();

  const { markersState, deleteMarker, forceViewMap } = useMarkerState();
  // const { markersState, deleteMarker, forceViewMap, boundsMapGlobal } = useMarkerState();

  const { canSave, hasPrev, hasNext, isInvalidRequest, canSetDispatchGroup, showVehicleButton } = entryState.entryStatusActions();
  const isValid = validation(id).valid();

  const { entryDispatch, loadingData, retryConnect, getEntryDispatch } = useEntryDispatchState();


  const { commentIsCompressed } = useUserState();
  const commentIsCompressedState = useState<boolean>(commentIsCompressed);
  const stationOrigin = useHookstate('');
  const moveScrolltoComment = useHookstate(false);


  useEffect(() => {
    entryState.getMyLastEntry();
    stationOrigin.set(station().get() != null ? station().get()?.entry_origin ?? '' : '')

    return () => {
      windowManager()['entryProcedure'].open.set(false)
      windowManager()['entryProcedure'].procedure.set([])
      typeId.set(null)
      subtypeId.set(null)
    }
  }, []);

  useEffect(() => {
    const idOrNull = id.ornull;
    if (!idOrNull) return
  }, [id.get()]);

  useEffect(() => {
    if (markersState()) {
      if (isValid) {
        markersState()['occurrence'].set({});
        nearOccurrences({ latitude: latitude.get(), longitude: longitude.get() }).then((data) => {
          const result = {};

          data.filter((occurrence) => occurrence.latitude && occurrence.longitude)
            .map((occurrence, index) => {
              if (occurrence.latitude && occurrence.longitude) {
                result[occurrence.id] = {
                  id: Number(occurrence.id),
                  markerIdentifier: occurrence.id,
                  isCurrent: false,
                  position: new LatLng(occurrence.latitude, occurrence.longitude),
                  typeName: occurrence?.type?.name || null,
                  subtypeName: occurrence?.subtype?.name || null,
                  location: occurrence.location || null,
                  status: 1,
                }
              }
              return false
            })
            .filter((item) => item)

          markersState()['occurrence'].merge(result)
        })
      }

      if (id.get() != null) {
        const entryMarkerActive = Object.entries(markersState()['entry'].get()).filter(([key, value]: any) => value?.isCurrent == true);
        entryMarkerActive.length > 0 ? markersState()['entry'][entryMarkerActive[0][0]] ? markersState()['entry'][entryMarkerActive[0][0]].isCurrent.set(false) : false : false;
        if (id.get()) {
          if (markersState()['entry'][id.get() ?? ''].get()) {
            markersState()['entry'][id.get() ?? ''].isCurrent.set(true);
            // console.log(boundsMapGlobal().get())
            // if(boundsMapGlobal().get() && validShowArea({position: JSON.parse(JSON.stringify(markersState()['entry'][id.get() ?? ''].position.get())), boundsMap: boundsMapGlobal().get()})){
            forceViewMap().zoom.set(15)
            forceViewMap().position.set(JSON.parse(JSON.stringify(markersState()['entry'][id.get() ?? ''].position.get())))
            // }

          }
        }
      }
    }

  }, [id.get(), latitude.get(), longitude.get()])

  const handleEntryDispatch = async () => {
    const response = await entryState.dispatchEntry()
    await deleteMarker({ markerType: 'entry', markerIdentifier: id.get() })
    await entryState.getMyLastEntry();

    notify({
      message: translate('Entry was dispatched with sucess'),
      type: "success",
    });
    return response;
  }

  const handleCreateCloseOccurrence = async (param) => {
    const response = await entryState.entryOpenCloseOccurrence({ comment: param.comment })
    await deleteMarker({ markerType: 'entry', markerIdentifier: id.get() })
    await entryState.getMyLastEntry();

    notify({
      message: translate('Occurrence created and closed with success'),
      type: "success",
    });

    return response?.data;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Grid container columns={12} rowSpacing={2} alignItems={'center'} sx={{ height: '100%', width: '100%' }}>
        <Grid item md={12}>
          <Grid container columns={12} spacing={1} >
            <Grid item md={3.5} sx={{ marginLeft: 0 }}>
              <EventInputContainer
                readonly={props.readonly}
                isValid={isValid}
                isInvalidRequest={isInvalidRequest.get()}
                id={id}
                reference={reference}
                subTypeName={subtypeName}
                typeName={typeName}
                lat={latitude}
                type={'entry'}
                lon={longitude}
                typeId={typeId}
                subtypeId={subtypeId}
                cityId={cityId}
                districtId={districtId}
                location={location}
                showVehicleButton={showVehicleButton}
                totalSubtypes={totalSubtypes}
                openModalEntryProcedure={windowManager()['entryProcedure'].open}
                saved={saved}
                totalDistricts={totalDistricts}
              />
              <CallerInputContainer
                readonly={props.readonly}
                isValid={isValid}
                isInvalidRequest={isInvalidRequest.get()}
                phone={phone}
                name={name}
                originId={entryOriginId}
                openModalLastCalls={windowManager()['EntryLogModal'].open}
                userStationOrigin={stationOrigin.get()}
              />
            </Grid>
            <Grid item md={4} xs={4} sx={{ marginLeft: 0 }}>
              <Grid container columns={12} sx={{ height: '100%', width: '100%', margin: 0 }} direction={'column'}>
                <Grid item sx={{ mb: 1 }}>


                  <EntrySendCommentField
                    readonly={props.readonly}
                    entryId={id.get()}
                    name='comment'
                    label={translate('Comment')}
                    required={false}
                    moveScrolltoComment={moveScrolltoComment}
                  />

                </Grid>
                <Grid item md xs sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
                  <EntryDisplayComments
                    commentIsCompressed={commentIsCompressedState}
                    moveScrolltoComment={moveScrolltoComment.get()}
                    entryId={id.get()}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3.5} sx={{ marginLeft: 0 }}>
              <DispatchAreaConfigTable
                data={entryDispatch()}
                loading={loadingData().get()}
                refresh={retryConnect().get()}
                refreshAction={() => id.get() ? getEntryDispatch(id.get()) : false}
              />
            </Grid>
            <Grid
              item
              md={1}
              sx={{ paddingLeft: 1, flexGrow: 1, flexDirection: 'column' }}
            >
              <ButtonActionsVerticalContainer
                readonly={props.readonly}
                isValid={isValid}
                canSetDispatchGroup={canSetDispatchGroup.get()}
                dispatchGroup={(params) => entryState.setDispatchGroup(params)}
                canAddVehiclesEntry={isValid && showVehicleButton.get()}
                canShowPEntryrocedure={true}
              />
            </Grid>


          </Grid>
        </Grid>

        <Grid item md={8} >
          <ButtonActionsContainer
            readonly={props.readonly}
            isValid={isValid}
            hasHistory={true} historyUrl='/entry/list'
            canCreate={true} create={() => entryState.createEntry()}
            canSave={canSave.get()} 
            save={() => entryState.saveEntry()}
            hasPrevious={hasPrev.get()} previous={() => entryState.previousEntry()}
            hasNext={hasNext.get()} next={() => entryState.nextEntry()}
            canTlia={isValid} tlia={(param) => entryState.tliaEntry(param)}
            canRecall={true} recall={(param) => entryState.recallEntry(param)}
            canDispatch={isValid && saved.get()} 
            dispatch={handleEntryDispatch}
            canEntryOpenCloseOcccurrence={isValid}
            entryOpenCloseOccurrenceDisabled={saved.get()}
            entryOpenCloseOccurrence={async (param) => handleCreateCloseOccurrence(param)}
            hasLatLng={Boolean(latitude.get() && longitude.get())}
          />
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
}
