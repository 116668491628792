import { Box, Typography } from '@mui/material';
import React from 'react';


interface TabPanelProps {
  readonly children?: React.ReactNode;
  readonly dir?: string;
  readonly index: number;
  readonly value: number;
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 1 }}>
          <Typography>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}