import { Dayjs } from 'dayjs';


export const maskCPF = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const maskPhoneNumber = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};

export const maskNames = (value: string) => {
  return value.toLocaleUpperCase().replace(/[^a-zA-Z\u00C0-\u00FF ]+/i, "")
};

export const maskAddress = (value: string) => {
  return value.toLocaleUpperCase().replace(/[^a-z0-9A-Z\u00C0-\u00FF ]+/i, "")
};

export const lengthLimit = (value: string, limit: number) => {
  return value.substring(0, limit)
}

export const removeMask = (value: string | null) => {
  return value?.replace(/\D/g, "") ?? ''
};

export const isValidPhone = (value: string) => {
  return removeMask(value).length < 11;
}

const hasCPFLength = (cpf: string): void | boolean => {
  if (cpf.length > 11 || cpf.length < 11) {
    return false
  }

  return true
}

const allDigitsAreEqual = (digits: string): boolean => {
  for (let i = 0; i < 10; i++) {
    if (digits === Array.from({ length: digits.length + 1 }).join(String(i))) {
      return true
    }
  }

  return false
}

const calcFirstChecker = (firstNineDigits: string): number => {
  let sum = 0

  for (let i = 0; i < 9; ++i) {
    sum += Number(firstNineDigits.charAt(i)) * (10 - i)
  }

  const lastSumChecker = sum % 11
  return lastSumChecker < 2 ? 0 : 11 - lastSumChecker
}
const calcSecondChecker = (cpfWithChecker1: string): number => {
  let sum = 0

  for (let i = 0; i < 10; ++i) {
    sum += Number(cpfWithChecker1.charAt(i)) * (11 - i)
  }

  const lastSumChecker2 = sum % 11
  return lastSumChecker2 < 2 ? 0 : 11 - lastSumChecker2
}

export const validate = (value: string): boolean => {
  if (typeof value !== 'string') {
    return false
  }

  const cleanCPF = String(value).replace(/[\s.-]/g, '')
  const firstNineDigits = cleanCPF.slice(0, 9)
  const checker = cleanCPF.slice(9, 11)

  if (!hasCPFLength(cleanCPF) || allDigitsAreEqual(cleanCPF)) {
    return false
  }

  const checker1 = calcFirstChecker(firstNineDigits)
  const checker2 = calcSecondChecker(`${firstNineDigits}${checker1}`)

  return checker === `${checker1}${checker2}`
}

export const maskPlate = (value: string) => {
  return value
    .toLocaleUpperCase()
    .replace(/^[^A-Z]/, "")
    .replace(/^([A-Z]{1,2})[^A-Z]+?/, "$1")
    .replace(/^([A-Z]{3})\D+?/, "$1")
    .replace(/^([A-Z]{3})(\d)[^A-Z\d]?/, "$1-$2")
    .replace(/^([A-Z]{3})(\d)([A-Z\d])+/, "$1-$2$3")
    .replace(/(-\d)([A-Z\d])\D+?$/, "$1$2")
    .replace(/(-\d)([A-Z\d])(\d)\D+?$/, "$1$2$3")
    .replace(/(-\d)([A-Z\d])(\d{2}).+$/, "$1$2$3");
};

export const maskCEP = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1");
};

export const truncate = (string?: string, limit = 10) => {
  if (!string) {
    return
  }

  if (string.length <= limit) {
    return string;
  }
  return string.slice(0, limit) + " ..."
}

export const calculateAge = (date?: Dayjs) => {

  if (!date || !date.isValid()) {
    return -1;
  }

  const today = new Date();
  const birthDate = new Date(date.format('YYYY-MM-DD'));
  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();

  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}