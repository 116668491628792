import * as React from 'react'
import { TextField, IconButton } from "@mui/material"

import { ChangeEvent, useEffect, useCallback } from "react"
import useEntryCommentState from '../../stores/EntryCommentState';
import useEntryFileState from '../../stores/EntryFileState';
import { useLang } from "~/hooks/useLang"
import { AttachFile, Send } from '@mui/icons-material';
import useSocket from '~/hooks/useSocket';
import { State, useHookstate } from '@hookstate/core';

interface EntrySendCommentFieldProps {
  readonly entryId: number | null;
  readonly name: string | null;
  readonly label: string | null;
  readonly required?: boolean;
  readonly readonly?: boolean;
  readonly isRecall?: boolean;
  readonly moveScrolltoComment?: State<boolean>;
}

export function EntrySendCommentField({ entryId, required, readonly = false, isRecall = false, moveScrolltoComment }: EntrySendCommentFieldProps) {
  const { translate } = useLang()
  const entryState = useEntryCommentState();
  const { sendEntryComment, isAwaiting, newComment } = entryState;
  const { socket } = useSocket();
  const loading = useHookstate<boolean>(false)


  useEffect(() => {
    socket.on("message", (message) => {
      const { entryId, comment } = JSON.parse(message);
      sendEntryComment({ entryId, comment })
        .then(console.log)
        .catch(console.error);
    });
  }, []);

  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.code === 'Enter' && !event.shiftKey) {
      localSendComment();
      moveScrolltoComment?.set(true)
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.shiftKey && event.code === 'Enter') {
      event.preventDefault()
      newComment().merge('\n')
      moveScrolltoComment?.set(true)
    }
  };

  function isDisabled(id, isAwaiting) {
    if (!id || isAwaiting.get() || id === '') return true;
    return false;
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    newComment().set(event.target.value)
  }

  const localSendComment = useCallback(() => {
    loading.set(true)
      sendEntryComment({ entryId: entryId, comment: newComment().get() }).finally(() => {
        newComment().set('')
        loading.set(false)
      });
    document.getElementById("newComment")?.focus();
    moveScrolltoComment?.set(true)
  }, [entryId, newComment, sendEntryComment]);

  return (
    <TextField
      required={required ? required : false}
      fullWidth
      maxRows={10}
      disabled={!isRecall ? isDisabled(entryId, isAwaiting) || readonly : false || loading.get()}
      size="small"
      value={newComment().get() || ''}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      id="newComment"
      label={translate("Write your comment")}
      name="newComment"
      multiline
      InputProps={{
        endAdornment:
          !isRecall &&
          <IconButton
            disabled={isDisabled(entryId, isAwaiting) || readonly || loading.get()}
            onClick={localSendComment}
            sx={{ p: '2px' }} >
            <Send fontSize='small' />
          </IconButton>
      }}
    />
  )
}
