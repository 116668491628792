import React from 'react';
import Index from './Pages';
import { AppRouteProps } from '~/routes';
import Create from './Pages/Create';
import Edit from './Pages/Edit';

export default function InterestPointTypeRoutes(): readonly AppRouteProps[] {
    const routes: AppRouteProps[] = [
        {
            path: '/interest-point/type', component: Index, isProtected: true,
            permissions: ['interest_point_type_index'] 
        },
        {
            path: '/interest-point/type/create', component: Create, isProtected: true,
            permissions: ['interest_point_type_create']
        },
        {
            path: '/interest-point/type/edit/:id', component: Edit, isProtected: true,
            permissions: ['interest_point_type_edit']
        },
    ];

    return routes;
}
