/* eslint-disable react/react-in-jsx-scope */
import { Grid, Typography } from "@mui/material";
import IReportGroup from "../../interfaces/IReportGroup";
import ReportDataBasic from "../ReportDataBasic";
import IReportColumn from "../../interfaces/IReportColumn";
import ReportColumns from "../ReportColumns";
import DetailedDispatchReport from "../Details/Dispatch";
import EmptyReport from "../EmptyReport";

export default function GroupedReport({ groups, columns, isTwoLevels }: { readonly isTwoLevels?: boolean, readonly groups: readonly IReportGroup[], readonly columns: readonly IReportColumn[] }) {  
  return (
    <Grid
      container
      marginTop={1}
    >

      {groups.map((group) => {
        return (
          <Grid
            item
            key={Math.random() + group.count}
            xs={24}
            marginTop={1}
          >
            <Typography
              variant="body2"
              marginTop={1}
              fontWeight={'bold'}
            >
              {group.label}
              {group.details?.class === 'dispatch' || 
              group.details?.class === 'device_log' || group.details?.class == 'entry' ?
                <DetailedDispatchReport
                  details={group.details}
                /> :
                false
              }
              {group.count ? ` (Total : ${group.total}) ` : ''}
            </Typography>
            <ReportColumns indentation={isTwoLevels ? false : true} columns={columns} />
            {group.data.length > 0 ?
              <ReportDataBasic indentation={isTwoLevels ? false : true} data={group.data} columns={columns} />
              : <EmptyReport justify="center" />
            }
          </Grid>
        )
      })}
    </Grid>
  )
}