/* eslint-disable max-lines-per-function */
import React from 'react'
import { Grid } from '@mui/material'
import { State, StateMethods } from '@hookstate/core'
import { useLang } from '~/hooks/useLang'
import { LoadingButton } from '@mui/lab'
import FilterAutocompleteStations from '~/components/FilterAutocompleteStations'
import FilterAutocompleteAgency from '~/components/FilterAutocompleteAgency'
import FilterAutocompleteContactCenter from '~/components/FilterAutocompleteContactCenter'
import FilterAutocompleteProcedure from '~/components/FilterAutocompleteProcedure'

export interface FilterArrayProps {
    readonly type: string;
    readonly variable: State<any> | StateMethods<any>;
}

export interface FiltersDashboardProps {
    readonly loading?: State<boolean>
    readonly lockFields?: boolean;
    readonly addButton?: (value: number | null) => void;
    readonly clearFilters?: () => void;

    readonly filter: FilterArrayProps | undefined;
}

export default function FilterLine({ 
    loading,
    lockFields,
    addButton,
    filter
}: FiltersDashboardProps) {
    const { translate } = useLang()

    function showFilter(type, variable){
        const typeFilter = {
            'station_ids': <FilterAutocompleteStations stationsId={variable.get()} onStationsChange={(station) => {variable.set(station)}} disabled={lockFields} />,
            'agency_id': <FilterAutocompleteAgency agencyId={variable.get()} onAgencyChange={(agency) => variable.set(agency)} disabled={lockFields} />,
            'contact_center_id': <FilterAutocompleteContactCenter contactCenterId={variable.get()} onContactCenterChange={(contactCenter) => variable.set(contactCenter)}  disabled={lockFields} />,
            'procedure_id': <FilterAutocompleteProcedure procedureId={variable.get()} onProcedureChange={(procedure) => variable.set(procedure)} size={'medium'}/>
            
        }

        return typeFilter[type]
    }
      
    return (
        <Grid container direction={'row'} 
        sx={{
            pt: 1,
            pb: 2,
            gap: 1,
        }}>
            
            <Grid item xs={4} sx={{ pr: 1.5 }}>
                {filter && showFilter(filter.type, filter.variable)}
            </Grid>
                
         
            <Grid item xs={3}>
                <LoadingButton 
                    loading={loading?.get()}
                    variant="contained"
                    onClick={() => addButton ? addButton(filter?.variable.get()) : {}}
                    sx={{height: '100%'}}
                    >
                    {translate('Add')}
                </LoadingButton>
            </Grid>
        </Grid>
    )
}