import React from 'react';
import { Card, CardContent, Typography, Button, Chip, IconButton, Stack } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import OccurrenceRecallModal from '~/features/Occurrence/components/OccurrenceRecallModal';
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState';
import useSystemState from '~/features/System/stores/SystemState';
import { useHookstate, useState } from "@hookstate/core";
import Close from '@mui/icons-material/Close';

export default function MarkerPopupContentOccurrence({ id, typeSubtype, location, closeThisPopUp }) {
  const { translate } = useLang();
  const { syncOccurrenceEditAttendent } = useOccurrenceState();
  const { windowManager } = useSystemState();
  const openOccurrenceRecallModal = useHookstate(false);
  const openRedirectOccurrence = useState(false);
  const openRequester = useHookstate(false);

  const continueOccurrence = () => {
    syncOccurrenceEditAttendent(id)
    windowManager().occurrence.open.set(true)
    openRedirectOccurrence.set(false)
  }

  return (
    <Card sx={{ backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: 'none' }}>
      <CardContent sx={{ padding: '1em 0 1em 0', textAlign: "center" }} >
      <Stack direction="row" alignItems="center" gap={2} justifyContent={'flex-end'}>

        <Button
          variant='contained'
          onClick={() => continueOccurrence()}
        >
          {translate('Occurrence')}: {id}
        </Button>
        <IconButton size="small" onClick={() => closeThisPopUp()}>
            <Close fontSize="small" />
          </IconButton>
        </Stack>
        <Typography variant="subtitle1" sx={{ margin: '.6em 0' }}>
          {' '}
          {typeSubtype}
        </Typography>
        <Typography variant="subtitle1" sx={{ margin: '.6em 0' }}>
          {' '}
          {location}
        </Typography>
        <Chip onClick={() => openOccurrenceRecallModal.set(true)}
          label={
            <Typography variant="subtitle1" >
              {translate('Recall')}
            </Typography>}
        />
      </CardContent>
      {openOccurrenceRecallModal.get() ?
        <OccurrenceRecallModal
          open={openOccurrenceRecallModal}
          openRequester={openRequester}
          occurrenceId={id}
          redirectOccurrence={true}
        /> : false}
    </Card>
  )
}