import React from "react";
import {IconButton, Grid} from '@mui/material';
import {ArrowForwardIos} from '@mui/icons-material';
import { useLang } from '~/hooks/useLang';


export default function NextNavegationButton({hasNext, next}){
  const { translate } = useLang()
 
  return (
    <Grid item>
      <IconButton title={translate('Next')} disabled={!hasNext} onClick={() => next ? next() : false}>
        <ArrowForwardIos/>
      </IconButton>
    </Grid>);

}