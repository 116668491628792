import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material";
import React, { ChangeEvent } from "react"
import ButtonDialog from "~/components/ButtonDialog";
import { useLang } from "~/hooks/useLang";
import { Close } from '@mui/icons-material';
import { requestClosureOccurrence } from "../../services";
import notify from "~/utils/notify";
import IOccurrence from "../../interfaces/IOccurrence";
import { State, useHookstate } from "@hookstate/core";
import { LoadingButton } from "@mui/lab";

interface IErros {
  readonly comment: string;
}

interface RequestClosureButtonprops {
  readonly occurrenceId: State<number | null>;
  readonly closureRequested: State<boolean>;
  readonly closedAt: State<null | undefined | Date>;
  readonly isDisabled?: boolean;
}

export default function RequestClosureButton({ occurrenceId, closureRequested, closedAt, isDisabled = false }: RequestClosureButtonprops) {
  const { translate } = useLang();
  const comment = useHookstate('');
  const open = useHookstate(false);
  const loading = useHookstate(false)
  const defaultErros = {
    comment: '',
  }
  const errors = useHookstate<IErros>(defaultErros);


  const action = () => {
    if (hasRequiredFields() === false) {
      loading.set(true)
      requestClosureOccurrence({ occurrenceId: occurrenceId.get(), comment: comment.get() }).then((response) => {
        if (response.status === 200) {
          notify({
            message: `${translate('Notification sent successfully!')}`,
            type: 'success'
          })
          closureRequested.set(true)
          open.set(false)
        }
      }).finally(() => {
        loading.set(false)
      }).catch((error) => {
        notify({
          type: 'error',
          message: translate(error)
        })
      })
    }

  }

  const hasRequiredFields = () => {
    if (!comment.get() || comment.get().length < 5) {
      errors.comment.set(translate('Enter a comment with a minimum of 5 characters'))
      return true;
    } else {
      return false
    }
  }

  return (
    <Grid container padding={1} spacing={1} sx={{ display: 'flex' }}>
      <Grid item>
        <IconButton title={translate('Request closure')} onClick={() => open.set(true)} disabled={isDisabled}>
          {<Close />}
        </IconButton>
        <Dialog fullWidth={true} open={open.get()} onClose={() => open.set(false)}>
          <DialogTitle>
            {translate('Request closure')}
          </DialogTitle>
          <DialogContent>
            <>
              <Typography>
                {translate('Do you want to inform that the incident can be closed? If yes, write a comment next to your request')}
              </Typography>
              <TextField
                multiline
                rows={10}
                error={Boolean(errors.comment.get())}
                required={true}
                name={'comment'}
                id='comment'
                label={translate('Comment')}
                fullWidth
                size='small'
                value={!comment.get() ? '' : comment.get()}
                sx={{ mt: 1 }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  event.target.value.length >= 5
                  comment.set(event.target.value)
                }}
              />
            </>
          </DialogContent>
          <DialogActions>
            <Button
              variant='outlined'
              onClick={() => open.set(false)}
            >
              {translate('Cancel')}
            </Button>
            <LoadingButton
              loading={loading?.get()}
              variant='contained'
              onClick={action}
            >
              {translate('Submit')}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        {/* <ButtonDialog
          titleButton={translate('Request closure')}
          iconButton={<Close />}
          action={action}
          dialogTitle={translate('Request closure')}
          dialogContent={
            <>
              <Typography>
                {translate('Do you want to inform that the incident can be closed? If yes, write a comment next to your request')}
              </Typography>
              <TextField
                multiline
                rows={10}
                error={Boolean(errors.comment.get())}
                required={true}
                name={'comment'}
                id='comment'
                label={translate('Comment')}
                fullWidth
                size='small'
                value={!comment.get() ? '' : comment.get()}
                sx={{ mt: 1 }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  event.target.value.length >= 5
                  comment.set(event.target.value)
                }}
              />
            </>
          }
        /> */}
      </Grid>
    </Grid>
  )
}