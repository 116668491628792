import React, { useEffect } from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Paper, Box, Typography, Chip, Dialog, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';
import { useLang } from "~/hooks/useLang";
import { useHookstate } from '@hookstate/core';
import { getDispatchesForOccurrenceById } from '~/features/Occurrence/services/index';
import useSystemState from '~/features/System/stores/SystemState';
import useOccurrenceDispatchesRelated from '~/features/Occurrence/stores/OccurrenceDIspatchesRelated';
import IDispatchOccurrence from '~/features/Occurrence/interfaces/IDispatchOccurrence';
import useOccurrenceState from '~/features/Occurrence/stores/OccurrenceState';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';

export default function ResultListDispatches({ occurrenceId }) {
  const { translate } = useLang();
  const { getStatusColors } = useSystemState();
  const { occurrenceDispatchesRelated, syncOccurrenceDispatchesRelated, updatedStatusOccurrenceDispatchesRelated } = useOccurrenceDispatchesRelated();
  const { syncDispatchFromId } = useDispatchState();
  const dispatches = useHookstate<readonly IDispatchOccurrence[]>([]);
  const { dispatchesOccurrence, occurrence } = useOccurrenceState();
  const dispatchCode = useHookstate<string | null>(null);
  const dispatchId = useHookstate<number | null>(null);
  const open = useHookstate(false);
  const loading = useHookstate(false)

  useEffect(() => {
    loading.set(true)
    if (occurrenceId) {
      getDispatchesForOccurrenceById({ id: occurrenceId })
        .then((response) => dispatches.set(response))
      .finally(() => {
          loading.set(false)
        })
    }
  }, [occurrenceId, occurrence().closed_at.get() ])

  useEffect(() => {
    if (updatedStatusOccurrenceDispatchesRelated.get() === true) {
      dispatchesOccurrence().get().map((occurrenceGlobal) =>
        occurrenceGlobal.dispatches.map((dispatchGlobal) =>
          dispatches.filter((dispatchLocal) =>
            dispatchLocal.id.get() === dispatchGlobal.id ? dispatchLocal.status.set(JSON.parse(JSON.stringify(dispatchGlobal.status))) : '')))
      updatedStatusOccurrenceDispatchesRelated.set(false)
    }

  }, [updatedStatusOccurrenceDispatchesRelated.get()])

  useEffect(() => {
    if (syncOccurrenceDispatchesRelated.get() === true) {
      dispatches.set([])
      occurrenceDispatchesRelated().get().map((item) => {
        dispatches.merge(JSON.parse(JSON.stringify([{
          agency: item.agency,
          code: item.code,
          dispatch_group: item.dispatch_group,
          id: item.id,
          status: item.status,
          occurrenceId: item.occurrenceId
        }])))
      })
      syncOccurrenceDispatchesRelated.set(false)
    }
  }, [syncOccurrenceDispatchesRelated.get()])


  const handleClickOpen = ( {id, code} ) => {
    dispatchCode.set(code)
    dispatchId.set(id)
    open.set(true)
  }

  const handleClose = () => {
    open.set(false)
  }

  const handleChangeDispatch = () => {
    handleClose()
    syncDispatchFromId(dispatchId.get())

  }

  return (
    <>
      <Paper
        variant='outlined'
        sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          alignItems: !dispatches.get().length ? 'center' : 'flex-start',
          overflowY: 'auto'
        }} >
      {loading.get() ? 
        <Box style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress /> 
        </Box>
        : !dispatches.get().length ? 
        <Box style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography>
            {translate('No Dispatches')}
          </Typography>
        </Box> 
        :
          <List dense={true} sx={{ width: '100%' }}>
            {
              dispatches.map((dispatch) => (
                <ListItem sx={{ padding: '5px 8px' }} key={dispatch.get()?.id}>
                  <ListItemAvatar sx={{ margin: 1 }}>
                    <Chip
                      label={dispatch.get()?.code}
                      onClick={() => handleClickOpen({ id: dispatch.get()?.id, code: dispatch.get()?.code })}
                      sx={{
                        backgroundColor: getStatusColors(dispatch.get()?.status?.id).main,
                        color: getStatusColors(dispatch.get()?.status?.id).contrastText,
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={dispatch.get()?.dispatch_group?.name ? dispatch.get()?.dispatch_group?.name : ''}
                    secondary={dispatch.get()?.status?.name ? dispatch.get()?.status?.name : ''}
                  />
                </ListItem>))
            }
          </List>
        }
      </Paper>
      <Dialog maxWidth={'xs'} fullWidth={true} open={open.get()} onClose={handleClose}>
        <DialogContent>

          {dispatchCode.get() ? (
              <Typography>
                Deseja abrir o despacho {dispatchCode.get()}?
              </Typography>
          ) : (
            false
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {translate('Cancel')}
          </Button>
          <Button variant="contained" onClick={handleChangeDispatch}>
            {translate('Yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>

  )
}
