import { authFetch } from "~/services/fetch";

export function getTimelineDevice({dispatchDeviceId}) {
  try {
    const response = authFetch({
      url: '/dispatch/device/timeline',
      method: 'POST',
      data: {
        dispatch_device_id: dispatchDeviceId
      }
    });

    return response
  } catch (error) {
    throw error
  }

}