import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { getAllAreas } from './services';
import { AreasResponse } from './interface';
import { useHookstate } from '@hookstate/core';

interface FilterAutocompleteAreasProps {
  readonly onAreaChange: (value: number | null) => void;
  readonly hideCode?: boolean;
  readonly areaId?: number | null;
  readonly disabled?: boolean
}

export default function FilterAutocompleteArea({ disabled, areaId, onAreaChange, hideCode = false }: FilterAutocompleteAreasProps) {
  const { translate } = useLang();
  const [options, setOptions] = useState<readonly AreasResponse[]>([]);

  const [value, setValue] = useState<AreasResponse | null>(null);
  const loading = useHookstate(false);

  useEffect(() => {
    getAllAreas().then(areas => {
      setOptions(areas)
      setValue(null)
    })
  }, [])

  useEffect(() => {
    if (!areaId) {
      setValue(null)
    }
  }, [areaId])

  return (
    <FormControl fullWidth size='small' >
      <Autocomplete
        id='filter-area'
        disabled={disabled}
        options={options}
        value={value}
        noOptionsText={translate('No options')}
        size='small'
        loading={loading.get()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name ?? ''}
        onChange={(_, type) => {
          setValue(type)
          onAreaChange(type?.id as number ?? null)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate('Area')}
            InputLabelProps={{
              sx: {
                fontFamily: 'Arial',
                letterSpacing: 0.5,
                fontWeight: 500
              }
            }}
          />
        )}
      />
    </FormControl>
  )
}