import React, { ReactNode } from 'react';
import { TimelineDot } from "@mui/lab";


import MessageIcon from '@mui/icons-material/Message';
import EventIcon from '@mui/icons-material/Event';
import { Print } from '@mui/icons-material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

type TP = {readonly color: string; readonly icon: ReactNode};

type Props = {
  readonly event: string;
}

const payload = {
  'OCCURRENCE_COMMENT': {
    color: 'info.main',
    icon: <MessageIcon fontSize="small"/>
  },
  'DEFAULT': {
    color: 'primary.main',
    icon: <EventIcon fontSize="small"/>
  },
  'DISPATCH_PRINTED': {
    color: 'primary.main',
    icon: <Print fontSize="small"/>
  }
  ,
  'PREVIEW_EVENT': {
    color: 'primary.main',
    icon: <RemoveRedEyeIcon fontSize="small"/>
  }
}


const getDotInfo = (eventName = 'default') => {
  return payload[eventName] || payload['DEFAULT'];
};


export default function DispatchTimelineStatus({event}: Props) {
  
    const dotInfo: TP = getDotInfo(event);
    return (
      <TimelineDot sx={{backgroundColor: dotInfo.color}}>
        {dotInfo.icon}
      </TimelineDot>
    )
}