import IAgency from '../interfaces/IAgency';
import { GridFilterItem } from '@mui/x-data-grid';
import { IUpdateProps } from '~/interfaces';
import { authFetch } from '~/services/fetch';

export async function getAgencyById(id: IUpdateProps): Promise<IAgency> {
    const response = await authFetch({
        url: `/agency/${id}`,
        method: 'GET'
    });
    return response.data as IAgency;
}

export async function getAgencies(filters?: readonly GridFilterItem[]) {
    const response = await authFetch({
        url: `/agency/`,
        method: 'POST',
        data: {
            limit: 100000,
            filters,
            orders: [
                { field: 'name', sort: 'asc' }
            ]
        },
    });
    return response.data.data;
}

export async function getAllAgencies() {
    const response = await authFetch({
        url: `/agency/`,
        method: 'GET',
    });
    return response.data;
}