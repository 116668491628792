import React, { useCallback, useState } from "react";

type useLocalStorageType<T> = readonly [T | undefined, (value: T) => void];

export function useLocalStorage<T>(
    key: string,
    initialValue?: T
  ): useLocalStorageType<T> {
    const [storedValue, setStoredValue] = useState<T | undefined>(() => {
      try {
        const item = window.localStorage.getItem(key);
        return item ? (JSON.parse(item) as T) : initialValue;
      } catch (error) {
        // console.error(error);
        return initialValue;
      }
    });
  
    const setValue = (value: T) => {
      try {
        setStoredValue(value);
        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        // console.error(error);
      }
    };
  
    return [storedValue, setValue];
  }

export function useSessionStorage(key: string, initialValue = '') {
    const [state, setState] = useState(() => {
        try {
            const storedValue = sessionStorage.getItem(key);
            return storedValue ? JSON.parse(storedValue) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = useCallback((value) => {
        try {
            setState(value);
            sessionStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error(error);
        }
    }, [key]);

    return [state, setValue];
}