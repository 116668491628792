import React from 'react';
import Create from './pages/Create';
import Index from './pages/Index';
import { AppRouteProps } from '~/routes';

export default function AreaConfigRoutes(): readonly AppRouteProps[] {
  const routes: readonly AppRouteProps[] = [
    {
      path: '/area-config',
      component: Index,
      isProtected: true,
      permissions: ['agency_index']
    },
    {
      path: '/area-config/create',
      component: Create,
      isProtected: true,
      permissions: ['agency_create']
    },
  ];

  return routes;
}
