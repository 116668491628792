import { getOnlyNumbersInString } from './strings';

export function validPhone(phone:string|null){
  if(phone != null){
    const onlyNumberPhone = getOnlyNumbersInString(phone);
    if(onlyNumberPhone.length === 10 || onlyNumberPhone.length === 11){
      return true;
    }
    return false;
  }
  return false;
}

export function validPlate(plate: string) {
  const regex = /^[a-zA-Z]{3}([-]{1}|)([0-9]{4}|[0-9]{1}[a-zA-Z]{1}[0-9]{2})$/;
  return regex.test(plate);
}