import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IOperation from '~/features/Operation/interfaces/IOperation';
import Layout from '~/components/Layout';


export default function Create() {
    const { register, handleSubmit, control, setValue: setFormValue } = useForm<any>();
    const fields = [
        { name: 'name', type: 'string' },
        { name: 'expiration_time_in_days', type: 'number', placeholder: 'Expiration time in days' },
        { name: 'review_time_in_days', type: 'number', placeholder: 'Review time in days' },
        { name: 'is_visible_to_dispatch', type: 'boolean', placeholder: 'Is visible to dispatch' },
        { name: 'is_visible_to_mobile', type: 'boolean', placeholder: 'Is visible to mobile' },
        { name: 'is_criminal', type: 'boolean', placeholder: 'Is criminal' },

    ];

    return (
        <Layout marginLayout={true}>
            <CrudCreate model={'interest-point/type'}
                fields={fields}
                register={register}
                handleSubmit={handleSubmit}
                control={control}
                messageSuccess={'Interest point type successfully created!'}
                title={'Add Interest point type'}
                subheader={'Add a new interest point type'}
                setFormValue={setFormValue} />
        </Layout>
    )
}
