import { State, useHookstate } from '@hookstate/core'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Card,
  CardContent,
  Divider,
  Fab,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ConfirmDialog from '~/components/ConfirmDialog'
import { ExpandTextButton } from '~/components/ExpandTextButton'
import useDispatchCommentState, {
  CommentDataProps,
} from '~/features/Dispatch/stores/DispatchCommentState'
import useDispatchState from '~/features/Dispatch/stores/DispatchState'
import { truncate } from '~/features/Dispatch/utils/stringsManipulations'
import useEntryCommentState from '~/features/Entry/stores/EntryCommentState'
import useOccurrenceCommentState from '~/features/Occurrence/stores/OccurrenceCommentState'
import useSystemState from '~/features/System/stores/SystemState'
import useUserState from '~/features/User/stores/UserState'
import { useLang } from '~/hooks/useLang'
import { CardFooter } from '../Footer'

interface ICommentProps {
  readonly commentData: State<CommentDataProps>;
  readonly isEdit?: boolean;
  readonly isDispatch?: boolean;
  readonly isOccurrence?: boolean;
  readonly moveScrolltoComment?: boolean;
}

// eslint-disable-next-line max-lines-per-function
export function Comment({ commentData, isEdit = false, isDispatch = false, isOccurrence = false, moveScrolltoComment }: ICommentProps) {
  const { translate } = useLang()
  const { user: userSession } = useUserState()
  const openDialog = useHookstate(false)
  const { systemConfig } = useSystemState()
  const { allowedEditEntryComment, allowedDeleteEntryComment, allowedDeleteOccurrenceComment, allowedEditOccurrenceComment } = systemConfig()

  const allowedEditComment = isDispatch ? !!parseInt(allowedEditOccurrenceComment.get()) : !!parseInt(allowedEditEntryComment.get())
  const allowedDeleteComment = isDispatch ? !!parseInt(allowedDeleteOccurrenceComment.get()) : !!parseInt(allowedDeleteEntryComment.get())

  const { sendEditDispatchComment, sendDeleteDispatchComment } = useDispatchCommentState()
  const { sendEditOccurrenceComment, sendDeleteOccurrenceComment } = useOccurrenceCommentState()
  const { sendEditEntryComment } = useEntryCommentState()

  const { dispatch } = useDispatchState()
  const { id, status } = dispatch()
  // EXIBE O BOTÃO DE EDIÇÃO
  const [showEditButton, setShowEditButton] = useState(false)
  // EXIBE O TEXT FIELD PARA INSERIR O COMENTÁRIO
  const [editComment, setEditComment] = useState(false)
  const [newComment, setNewComment] = useState('')
  const COMMENTMAXLENGHT = 85
  const refToNewComment = useRef<null | HTMLDivElement>(null);
  const { comment, user, id: commentId, station, device, closingComment, isDeleted } = commentData.get()
  const [commentIsCompressed, setCommentIsCompressed] = useState(true)

  useEffect(() => {
    if (moveScrolltoComment){
      refToNewComment?.current?.scrollIntoView({ behavior: "smooth" });
    }
    
  }, [moveScrolltoComment]);
  
  const localSendEditedComment = useCallback(() => {
    const dispatchId = id.get() as number
    if (dispatchId && isDispatch === true) {
      sendEditDispatchComment({ commentId: commentId, comment: newComment })
      setEditComment(false)
    } else if (isOccurrence === true) {
      sendEditOccurrenceComment({ commentId: commentId, comment: newComment })
      setEditComment(false)
    } else {
      sendEditEntryComment({ id: commentId, comment: newComment })
      setEditComment(false)
    }
  }, [id])

  const handleDialogOpen = () => {
    openDialog.set(true)
  }

  const handleDialogClose = () => {
    openDialog.set(false)
  }

  const localSendDeletedComment = (commentId) => {
    const dispatchId = id.get() as number

    if (dispatchId && isDispatch === true) {
      sendDeleteDispatchComment({ commentId: commentId })
    } else if (isOccurrence === true) {
      sendDeleteOccurrenceComment({ commentId: commentId })
    } else {
    }
    handleDialogClose()
  }

  return (
    <Card
      variant="elevation"
      color="background.paper"
      elevation={2}
      sx={{
        width: '100%',
        border: closingComment ? '0.5px solid #9e9e9e' : '',
      }}
    >
      <CardContent
        sx={{ padding: 1 }}
        onMouseEnter={() => {
          setShowEditButton(true)
        }}
        onMouseLeave={() => setShowEditButton(false)}
      >
        {showEditButton &&
          !editComment &&
          // DESPACHO NÃO PODE ESTAR FECHADO
          status.value?.id !== 8 &&
          // SE O COMENTÁRIO JÁ FOI EDITADO
          isEdit &&
          // SE O COMENTÁRIO FOI DELETADO
          !isDeleted &&
          !isOccurrence &&
          allowedEditComment && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              <Fab
                size="small"
                onClick={() => {
                  setEditComment(true)
                  setNewComment(comment)
                }}
                sx={{
                  position: 'absolute',
                  top: '-15px',
                  right: '-2px',
                  transform: 'scale(0.6)',
                  color: 'white',
                  backgroundColor: '#009688',
                  '&:hover': { backgroundColor: '#00544a' },
                }}
              >
                <Tooltip title={translate('Edit Comment')} placement="top">
                  <EditIcon />
                </Tooltip>
              </Fab>
              {isDispatch && !closingComment && allowedDeleteComment && (
                <Fab
                  size="small"
                  onClick={() => handleDialogOpen()}
                  sx={{
                    position: 'absolute',
                    top: '20px',
                    right: '-2px',
                    transform: 'scale(0.6)',
                    color: '#black',
                    backgroundColor: '#9e9e9e',
                    '&:hover': { backgroundColor: '#6e6e6e' },
                  }}
                >
                  <Tooltip
                    title={translate('Delete comment')}
                    placement="bottom"
                  >
                    <DeleteIcon />
                  </Tooltip>
                </Fab>
              )}
            </Box>
          )}
        {showEditButton &&
          !editComment &&
          status.value?.id !== 8 &&
          isEdit &&
          !isDeleted &&
          allowedEditComment &&
          isOccurrence &&
          userSession().id.get() === user.id && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              <Fab
                size="small"
                onClick={() => {
                  setEditComment(true)
                  setNewComment(comment)
                }}
                sx={{
                  position: 'absolute',
                  top: '-15px',
                  right: '-2px',
                  transform: 'scale(0.6)',
                  color: 'white',
                  backgroundColor: '#009688',
                  '&:hover': { backgroundColor: '#00544a' },
                }}
              >
                <Tooltip title={translate('Edit Comment')} placement="top">
                  <EditIcon />
                </Tooltip>
              </Fab>
              {isOccurrence && allowedDeleteComment && (
                <Fab
                  size="small"
                  onClick={() => handleDialogOpen()}
                  sx={{
                    position: 'absolute',
                    top: '20px',
                    right: '-2px',
                    transform: 'scale(0.6)',
                    color: '#black',
                    backgroundColor: '#9e9e9e',
                    '&:hover': { backgroundColor: '#6e6e6e' },
                  }}
                >
                  <Tooltip
                    title={translate('Delete comment')}
                    placement="bottom"
                  >
                    <DeleteIcon />
                  </Tooltip>
                </Fab>
              )}
            </Box>
          )}
        {editComment ? (
          <>
            <TextField
              sx={{ mt: 1 }}
              id="outlined-multiline-flexible"
              label={user.representation_name}
              fullWidth
              multiline
              maxRows={3}
              value={newComment}
              onChange={(event) => {
                setNewComment(event.target.value)
              }}
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (
                  (event.ctrlKey && event.code === 'Enter') ||
                  (event.ctrlKey && event.code === 'NumpadEnter')
                ) {
                  localSendEditedComment()
                }
              }}
            />
            <Fab
              size="small"
              onClick={() => setEditComment(false)}
              sx={{
                position: 'absolute',
                top: '-15px',
                right: '-2px',
                transform: 'scale(0.6)',
                color: '#black',
                backgroundColor: '#9e9e9e',
                '&:hover': { backgroundColor: '#6e6e6e' },
              }}
            >
              <Tooltip title={translate('Close')}>
                <ClearIcon />
              </Tooltip>
            </Fab>
            <Fab
              size="small"
              onClick={() => {
                localSendEditedComment()
              }}
              sx={{
                position: 'absolute',
                bottom: '10px',
                right: '-2px',
                transform: 'scale(0.6)',
                color: 'white',
                backgroundColor: '#009688',
                '&:hover': { backgroundColor: '#00544a' },
              }}
            >
              <Tooltip title={translate('Confirm Edit')}>
                <CheckIcon />
              </Tooltip>
            </Fab>
          </>
        ) : (
          <Typography
            style={{
              wordWrap: 'break-word',
              marginBottom: '0',
              whiteSpace: 'pre-line',
            }}
            fontWeight="400"
            paragraph={true}
            ref={refToNewComment}
          >
            {isDeleted
              ? `• ${translate('Deleted Comment')} •`
              : !commentIsCompressed
              ? truncate(comment, COMMENTMAXLENGHT)
              : comment}

            {/* {!commentIsCompressed || !isDeleted ?
              truncate(comment, COMMENTMAXLENGHT) :
              comment} */}

            {comment && comment.length > COMMENTMAXLENGHT && !isDeleted && (
              <ExpandTextButton
                isCompressed={commentIsCompressed}
                handleCompressed={() =>
                  setCommentIsCompressed(!commentIsCompressed)
                }
              />
            )}
          </Typography>
        )}
      </CardContent>
      {!editComment && (
        <>
          <Divider />
          <CardFooter commentData={commentData} />
          <ListItemText
            secondary={
              closingComment
                ? device === null
                  ? translate('Closing dispatch by station') +
                    ' ' +
                    station?.name
                  : translate('Closing dispatch by device') + ' ' + device?.name
                : ''
            }
            disableTypography
            sx={{
              paddingLeft: 1,
              paddingRight: 1,
              fontSize: '12px',
              color: '#41AEAA',
            }}
          />
        </>
      )}
      <ConfirmDialog
        handleAgree={() => localSendDeletedComment(commentId)}
        handleClose={handleDialogClose}
        open={openDialog}
        title={translate('Confirmation')}
        content={translate('Confirm delete?')}
      />
    </Card>
  )
}
