/* eslint-disable max-lines-per-function */
import React from 'react'
import { authFetch } from '~/services/fetch'
import IDevice from '../interfaces/IDevice';

interface IDeviceResponse {
  readonly rowsCount: number;
  readonly data: readonly IDevice[]
}

interface IGetDevices {
  readonly page?: number;
  readonly limit?: number;
  readonly filters?: readonly {
    readonly field: string,
    readonly operator: string,
    readonly value: any
  }[]
}
const useDeviceState = () => {
  return {
    getDevices: async ({ page, limit }): Promise<IDeviceResponse> => {
      const targetUrl = '/device';
      const response = await authFetch({
        url: targetUrl,
        data: {
          page,
          limit,
        },
      })
      return response.data;
    },
    getDevicesDisconnected: async () => {
      const targetUrl = '/device/disconnected';
      const response = await authFetch({
        url: targetUrl,
        method: 'GET',
      })
      return response.data;
    }

  }
}

export default useDeviceState;