import { ISurveillanceReportingReponse } from "~/features/VideoMonitoring/interfaces/ISurveillanceReporting";

export function toDomain(surveillanceReporting): ISurveillanceReportingReponse {
  return {
    id: surveillanceReporting.id ?? null,
    cameraId: surveillanceReporting.camera_id,
    cameraName: surveillanceReporting.camera?.name,
    notes: surveillanceReporting.notes,
    reportedAt: surveillanceReporting.reported_at,
    createdAt: surveillanceReporting.created_at,
    updatedAt: surveillanceReporting.updated_at,
    deletedAt: surveillanceReporting.deleted_at,
    station: surveillanceReporting.station.name,
    userName: surveillanceReporting.user?.representation_name,
    userId: surveillanceReporting.user?.id,
    occurrenceId: surveillanceReporting.occurrence_id,
    checked: false
  }
}
