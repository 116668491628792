import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IWeaponType from '../interfaces/IWeaponType';
import Layout from '~/components/Layout';

export default function Create() {     
  const {register, handleSubmit, control, setValue:setFormValue} = useForm<IWeaponType>();
  const fields = [
      { name: 'name', type: 'string'},
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate model={'weapon-type'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        messageSuccess={'Weapon type successfully created!'}  
        title={'Add weapon type'}  
        subheader={'Add weapon type'}
        setFormValue={setFormValue} />
      </Layout>
    )
}
