import React, { useEffect } from 'react';
import CrudIndex from '~/components/CrudIndex';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';

export default function Index() {
  const { translate } = useLang();

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])
  return (
    <Layout marginLayout={true}>
      <CrudIndex
        model='vehicle'
        title='Vehicles'
        exceptColumns={['vehicle_type_id', 'agency_id', 'device_id', 'dispatch_group_id', 'is_connected', 'device_status_id', 'brand', 'imei', 'model', 'tracking_id', 'task', 'out_of_service', 'acronym', 'status']}
      />
    </Layout>
  )
}
