import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IProcedure from '../interfaces/IProcedure';

export default function Edit() {
  const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm<IProcedure>();
  const fields = [
    { name: 'id', type: 'hidden' },
    { name: 'name', type: 'string', required: true, readonly: true},
    { name: 'code', type: 'string', required: true, readonly: true},
    { name: 'description', type: 'longtext', required: true },
  ];

  return (
    <CrudEdit
      model={'procedure'}
      fields={fields}
      register={register}
      handleSubmit={handleSubmit}
      control={control}
      setFormValue={setFormValue}
      messageSuccess={'Procedure successfully updated!'}
      title={'Edit Procedure'}
      getValues={getValues}
    />
  )
}