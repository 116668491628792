import React from 'react';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { AppRouteProps } from '~/routes';

export default function CircunstanceTypeRoutes(): readonly AppRouteProps[] {
  const routes: readonly AppRouteProps[] = [
    {path: '/circunstance', component: Index, isProtected: true, permissions: ['circunstance_index']},
    {path: '/circunstance/create', component: Create, isProtected: true, permissions: ['circunstance_create']},
    {path: '/circunstance/edit/:id', component: Edit, isProtected: true, permissions: ['circunstance_edit']},

  ];

  return routes;
}
