import React, { useEffect } from 'react';
import Index from './pages/Index';
import Create from './pages/Create';
import Edit from './pages/Edit';
import { AppRouteProps } from '~/routes';
import { useLang } from '~/hooks/useLang';

export default function EntryOriginRoutes(): readonly AppRouteProps[] {

  const routes: AppRouteProps[] = [
    {path: '/entry-origin', component: Index, isProtected: true, permissions: ['entry_origin_index']},
    {path: '/entry-origin/create', component: Create, isProtected: true, permissions: ['entry_origin_create']},
    {path: '/entry-origin/edit/:id', component: Edit, isProtected: true, permissions: ['entry_origin_edit']},

  ];

  return routes;
}
