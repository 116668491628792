import React from 'react';
import { createState, useState } from '@hookstate/core';
import { authFetch } from '~/services/fetch';
import { Broadcasted } from '@hookstate/broadcasted';
import { toDomain } from '~/mappers/comments-mapper';

export interface CommentProps {
  readonly dispatchId: string | number;
  readonly comment: string | null;
  readonly commentId?: number | null;
}
export interface CommentViewProps {
  readonly props: PostCommentResponse,
  readonly isSaved: boolean
}
export interface DispatchCommentProps {
  readonly newComment: null | string,
  readonly allCurrentComments: readonly PostCommentResponse['data'][]
  readonly loading: boolean
  readonly retryConnect: boolean
}
export interface PostCommentBodySchema {
  readonly dispatch_id: null | string | number,
  readonly comment: string | null
}

interface IUserComment {
  readonly id: number | string;
  readonly name: string;
  readonly representation_name: string;
}

interface IStationComment {
  readonly id: number,
  readonly name: string,
}

interface IDeviceComment {
  readonly id: number,
  readonly name: string,
}

export interface CommentDataProps {
  readonly id: number;
  readonly dispatchId: number;
  readonly occurrenceId: number
  readonly entryId?: number,
  readonly user: IUserComment;
  readonly comment: string;
  readonly mediaPath?: string;
  readonly createdAt: string;
  readonly updatedAt: string | null;
  readonly deletedAt: null | string;
  readonly isEdited?: boolean;
  readonly isDeleted?: boolean;
  readonly changedByUser?: IUserComment;
  readonly closingComment?: boolean;
  readonly station?: IStationComment;
  readonly device?: IDeviceComment | null;
  readonly fileType?: string;
  readonly fileName?: string;
}

export interface IRawCommentData {
  readonly id: number;
  readonly occurrence_id: number;
  readonly closing_comment: boolean;
  readonly entry_id: number;
  readonly dispatch_id: number;
  readonly comment: string;
  readonly is_edited: boolean;
  readonly media_path: string;
  readonly created_at: string;
  readonly updated_at: string;
  readonly deleted_at: null | string;
  readonly is_deleted: boolean;
  readonly user: IUserComment;
  readonly changed_by_user: IUserComment;
  readonly station: IStationComment;
  readonly device?: IDeviceComment | null;
  readonly file_type?: string;
  readonly file_name?: string;
}

export interface PostCommentResponse {
  readonly message: string;
  readonly data: CommentDataProps;
}
export interface CommentData {
  readonly dispatchId: string | number;
  readonly commentId?: number | null;
  readonly comment: string | null;
}

export interface IActionCommentResponse {
  readonly id: number;
  readonly comment: string,
  readonly media_path: string,
  readonly is_edited: boolean,
  readonly is_deleted: boolean,
  readonly occurrence_id: number,
  readonly dispatch_id: number,
  readonly created_at: string,
  readonly updated_at: string,
  readonly user: IUserComment,
  readonly closingComment: boolean,
  readonly changed_by_user: {
    readonly id: number,
    readonly name: string
    readonly representation_name: string;
  },
  readonly device: {
    readonly id: number,
    readonly name: string,
  },
  readonly station: {
    readonly id: number,
    readonly name: string,
  }
}

const defaultCommentProps: DispatchCommentProps = {
  newComment: '',
  allCurrentComments: [],
  loading: false,
  retryConnect: false,
}

const dispatchCommentDefaultState = createState<DispatchCommentProps>(Object.create(defaultCommentProps))
// eslint-disable-next-line new-cap
dispatchCommentDefaultState.attach(Broadcasted('dispatch-comment-events'));

// eslint-disable-next-line max-lines-per-function
const useDispatchCommentState = () => {
  const dispatchCommentState = useState(dispatchCommentDefaultState)

  const newComment = useState(dispatchCommentState.newComment)
  const allCurrentComments = useState(dispatchCommentState.allCurrentComments)
  const loading = useState(dispatchCommentState.loading)
  const retryConnect = useState(dispatchCommentState.retryConnect)

  const isAwaiting = useState(false)

  async function saveComment(receivedMessage: CommentProps) {
    const { dispatchId, comment } = receivedMessage;

    const toDbSchema: PostCommentBodySchema = {
      dispatch_id: dispatchId,
      comment: comment
    }

    const { data } = await authFetch({
      url: '/dispatch/comment/store',
      method: 'POST',
      data: toDbSchema
    });

    return toDomain(data.data);
  }

  async function editComment({ comment, commentId }: Omit<CommentProps, 'dispatchId'>): Promise<IActionCommentResponse> {

    const targetUrl = `/occurrence-comment/${commentId}`

    const { data } = await authFetch({
      url: targetUrl,
      method: 'PUT',
      data: {
        comment,
      }
    })
    return data;
  }

  async function deleteComment({ commentId }: CommentProps): Promise<IActionCommentResponse> {

    const targetUrl = `/occurrence-comment/${commentId}`
    const { data } = await authFetch({
      url: targetUrl,
      method: 'DELETE',

    })
    return data;
  }


  function sortComment(commentA: CommentDataProps, commentB: CommentDataProps) {
    const dateA = new Date(commentA.createdAt).getTime()
    const dateB = new Date(commentB.createdAt).getTime()
    return dateB - dateA;
  }

  function findDispatchComment(commentId: number) {
    return allCurrentComments.findIndex((comment) => comment.get().id == commentId);
  }

  function addComment(commentData: CommentDataProps) {
    if (findDispatchComment(commentData.id) < 0) {
      allCurrentComments.merge([commentData]);
    }
  }

  function editedComment({ comment, id, updatedAt, changedByUser, isEdited, mediaPath, fileType, fileName }: CommentDataProps) {
    const commentIndex = findDispatchComment(id);
    if (commentIndex > -1) {
      allCurrentComments[commentIndex].comment.set(comment)
      allCurrentComments[commentIndex].updatedAt.set(updatedAt)
      allCurrentComments[commentIndex].isEdited.set(isEdited)
      allCurrentComments[commentIndex].changedByUser.set(changedByUser)
      allCurrentComments[commentIndex].mediaPath.set(mediaPath)
      allCurrentComments[commentIndex].fileType.set(fileType)
      allCurrentComments[commentIndex].fileName.set(fileName)
    }
  }

  function deletedComment({ comment, id, updatedAt, changedByUser, isDeleted }: CommentDataProps) {
    const commentIndex = findDispatchComment(id);
    if (commentIndex > -1) {
      allCurrentComments[commentIndex].comment.set(comment)
      allCurrentComments[commentIndex].updatedAt.set(updatedAt)
      allCurrentComments[commentIndex].isDeleted.set(isDeleted)
      allCurrentComments[commentIndex].changedByUser.set(changedByUser)
    }
  }

  function setDefaultComments() {
    allCurrentComments.set([])
  }

  return ({
    newComment: () => newComment,
    allCurrentComments: () => allCurrentComments,
    loading: () => loading,
    retryConnect: () => retryConnect,
    syncPreviousComments: async (dispatchId: string | number | null, order = 'DESC') => {
      retryConnect.set(false)
      loading.set(true)
      newComment.set('');
      setDefaultComments()

      authFetch({
        url: `/dispatch/comments`,
        method: 'POST',
        data: {
          dispatch_id: dispatchId,
          order: order
        }
      }).then(({ data }) => {
        if (!data) return false
        allCurrentComments.set(data.map((comment: IRawCommentData) => toDomain(comment)))
        return true
      }).catch(() => retryConnect.set(true)).finally(() => loading.set(false));


    },
    isAwaiting: isAwaiting,
    sendComment: async ({ dispatchId, comment }: CommentData) => {
      const isEmptyString = !comment?.length;

      if (isEmptyString) {
        return false
      }
      const commentNew = await saveComment({ dispatchId, comment });
      const {
        id: commentId,
        user: senderUser,
        comment: dataComment,
        mediaPath,
        createdAt,
        occurrenceId,
        closingComment: closingComment,
        device: device,
        station: station,
      } = commentNew;

      addComment({
        comment: dataComment,
        mediaPath,
        createdAt,
        occurrenceId: occurrenceId,
        dispatchId: dispatchId as number,
        id: commentId,
        user: senderUser,
        deletedAt: null,
        updatedAt: null,
        closingComment,
        device: device,
        station: station,
      });

      newComment.set(null);
      return true
    },
    sendEditDispatchComment: async ({ comment, commentId }: Omit<CommentProps, 'dispatchId'>) => {
      const commentEdited = await editComment({ commentId, comment });
      editedComment({
        comment: commentEdited.comment,
        mediaPath: commentEdited.media_path,
        createdAt: commentEdited.created_at,
        dispatchId: commentEdited.dispatch_id,
        occurrenceId: commentEdited.occurrence_id,
        id: commentEdited.id,
        user: commentEdited.user,
        changedByUser: commentEdited.changed_by_user,
        deletedAt: null,
        updatedAt: commentEdited.updated_at,
        isEdited: commentEdited.is_edited,
        closingComment: commentEdited.closingComment,
        device: commentEdited.device,
        station: commentEdited.station,
      });

      newComment.set('');
      return true
    },
    sendDeleteDispatchComment: async (commentId) => {
      const commentDeleted = await deleteComment(commentId);
      deletedComment({
        comment: commentDeleted.comment,
        mediaPath: commentDeleted.media_path,
        createdAt: commentDeleted.created_at,
        dispatchId: commentDeleted.dispatch_id,
        occurrenceId: commentDeleted.occurrence_id,
        id: commentDeleted.id,
        user: commentDeleted.user,
        changedByUser: commentDeleted.changed_by_user,
        deletedAt: null,
        updatedAt: commentDeleted.updated_at,
        isEdited: commentDeleted.is_edited,
        isDeleted: commentDeleted.is_deleted,
        closingComment: commentDeleted.closingComment,
        device: commentDeleted.device,
        station: commentDeleted.station,
      });

      newComment.set('');
      return true
    },
    sortComment: (a: CommentDataProps, b: CommentDataProps) => sortComment(a, b),
    addComment: addComment,
    deleteComment: deleteComment,
    findDispatchComment: findDispatchComment,
    setDefaultComments: setDefaultComments,
    editedComment: editedComment,
  }
  )
}

export default useDispatchCommentState;