import React from 'react'
import {
  Card,
  CardActions,
  Typography,
  CardContent,
  IconButton,
  Grid,
  Stack,
} from '@mui/material'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { useLang } from '~/hooks/useLang'
import useEntryState from '~/features/Entry/stores/EntryState'
import Close from '@mui/icons-material/Close'

export default function MarkerPopupContentEntry({
  id,
  location,
  readonly,
  typeSubtype,
  closeThisPopUp,
}) {
  const { translate } = useLang()
  const { getEntryFromId } = useEntryState()

  const handleImportEntry = (entryId: number) => {
    getEntryFromId(entryId)
  }

  return (
    <Card
      sx={{
        backgroundColor: 'transparent',
        backgroundImage: 'none',
        boxShadow: 'none',
      }}
    >
      <CardContent sx={{ padding: '1em 0 1em 0', textAlign: 'center' }}>
        <Stack direction="row" alignItems="center" gap={2} justifyContent={'flex-end'}>
          <Typography
            className="popupText-dark"
            variant="subtitle1"
            sx={{ margin: '.6em 0' }}
          >
            <b>{translate('Entry') + ` ${id}`}</b>{' '}
          </Typography>
          <IconButton size="small" onClick={() => closeThisPopUp()}>
            <Close fontSize="small" />
          </IconButton>
        </Stack>
        <Typography
          className="popupText-dark"
          variant="subtitle1"
          sx={{ margin: '.6em 0' }}
        >
          {' '}
          {typeSubtype}
        </Typography>
        <Typography
          className="popupText-dark"
          variant="subtitle1"
          sx={{ margin: '.6em 0' }}
        >
          {' '}
          {location}
        </Typography>
      </CardContent>
      {!readonly ? (
        <CardActions sx={{ padding: 0, justifyContent: 'center' }}>
          <IconButton
            className="popupButton"
            color="primary"
            sx={{ cursor: 'pointer' }}
            onClick={() => handleImportEntry(id)}
          >
            <FileUploadIcon />
          </IconButton>
        </CardActions>
      ) : (
        false
      )}
    </Card>
  )
}
