import React, { useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Autocomplete, AutocompleteRenderInputParams, TextField, Menu } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate } from '@hookstate/core';
import { getDisptachGroupByGroupsToTransfer } from '~/features/DispatchGroup/services/index';
import { setDispatchTransferService } from '~/features/Dispatch/services/index';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';
import useDispatchGroupState from '~/features/DispatchGroup/stores/DispatchGroupState';
import { LoadingButton } from '@mui/lab';

interface IOptionsProps {
  readonly id: number
  readonly name: string
  readonly acronym: string
}

export default function TransferGroupDispatchDialog({ open }){
  const { translate } = useLang();
  const { dispatch, getMyLastDispatch, syncDispatchFromId } = useDispatchState();
  const dispatchGroupOptions = useHookstate<IOptionsProps[] | []>([]);
  const dispatchGroupId = useHookstate<{ id: number; name: string; acronym: string } | null>({ id: 0, name: '', acronym: '' });
  const { dispatchGroupView } = useDispatchGroupState();
  const loading = useHookstate<boolean>(false)


  useEffect(() => {
    if (dispatch()?.dispatchGroup?.id?.get()) {
      getDisptachGroupByGroupsToTransfer({ dispatchGroupId: dispatch().dispatchGroup?.id?.get() }).then((data) => {
        dispatchGroupOptions.set(data)
      })
    }
  }, [dispatch()?.dispatchGroup?.id?.get()])

  function send() {
    setDispatchTransferService({ dispatchId: dispatch().id?.get(), dispatchGroupId: dispatchGroupId.get()?.id, loading }).then(({ data }) => {
      if (data.stationHasAccess) {
        syncDispatchFromId(data.dispatch);
        open.set(false);

      } else {
        getMyLastDispatch();
        open.set(false);
      }

    }).finally(() => {
      loading.set(false)
    })
    dispatchGroupId.set(null)
  }

  const onCloseTransferDispatch = () => {
    open.set(false)
    dispatchGroupId.set(null)
  }

  const groupVisibles = Object.keys(dispatchGroupView().get()).filter(item => dispatchGroupView()[item].is_visible.get())

  const options = dispatchGroupOptions.get().map((option) => {
    return {
      priority: Boolean(groupVisibles.find((element) => element == option.name)) ? `${translate('Dispatch Groups visibles')}` : `${translate('Others')}`,
      ...option
    }
  })

  return ( 
    <Dialog open={open.get()} onClose={() => onCloseTransferDispatch()} maxWidth={'xs'} fullWidth>
        <DialogTitle minWidth={"320px"} variant='h5'>
          {translate('Transfer Dispatch Group')}
        </DialogTitle>
        <DialogContent>

          <FormControl fullWidth size='small' sx={{ mt: 1 }}>
            <Autocomplete
              options={options.sort((a, b) => -b.priority.localeCompare(a.priority))}
              groupBy={(option) => option.priority}
              noOptionsText={translate('No options')}
              size='small'
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              clearText={translate('Clear')}
              onChange={(event, value) => {
                dispatchGroupId.set({ id: value?.id ?? 0, name: value?.name ?? '', acronym: value?.acronym ?? '' })
              }}
              value={dispatchGroupId.get()}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label={translate('Dispatch Group')}
                />
              }
              renderOption={(props, option) => (
                <MenuItem {...props} sx={{ mt: 1, overflowX: "auto" }} key={option.id} value={option.id}>
                  {`[${option.acronym}] ${option.name}`}
                </MenuItem>
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions sx={{px: 3, gap: 1}}>
          <Button variant='outlined' onClick={() => onCloseTransferDispatch()}>
            {translate('Cancel')}
          </Button>
          <LoadingButton
            loading={loading.get()}
            variant='contained'
            onClick={() => send()}
          >
            {translate('Submit')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
  )
}