import { t  as translate } from 'i18next';
import React from "react";
import TextFieldEntryForm from "../TextFieldEntryForm";
import { Typography } from '@mui/material';
import { useLang } from '~/hooks/useLang';


export default function NameField({readonly=false, isValid, name}){
  const { translate } = useLang();

  if (!readonly) {
    return (
      <TextFieldEntryForm
      state={name}
      id="name"
      name="name"
      label={translate("Requester Name")}
      autoComplete="fullName"
      required={false}
      disabled={!isValid}
    />
    );
  } else {
    return (
      <Typography>{translate('Name')}: {name.get() ?? translate('No information')}</Typography>
    )
  }

}