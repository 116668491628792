import React from 'react';
import { Grid, Typography } from '@mui/material';
import ReportColumns from '../ReportColumns';
import ReportDataBasic from '../ReportDataBasic';

export default function GroupedReportMultipleCharts({ columns, group }:
  {
    readonly group: any,
    readonly columns: any
  }) {

  return (
    <Grid
      container
      marginTop={1}
    >
      {group.map((group) => {
        return (
          <Grid
            item
            key={Math.random() + group.count}
            xs={24}
            marginTop={2}
          >
            <Typography
              variant="body2"
              fontWeight={'bold'}
              marginTop={2}
            >
              {group.label}
              {group.count ? ` (Total : ${group.total}) ` : ''}
            </Typography>
            <Grid
              item
              sx={{ marginLeft: '16px' }}
            >
              {columns.map((columnsIten, indexColumns) =>
                <>
                  <ReportColumns
                    columns={columnsIten.map(element => element)}
                    indentation={false}
                    key={indexColumns}
                  />
                  {group.data.map((element, index) =>
                  indexColumns === index && 
                    <ReportDataBasic
                    columns={columnsIten.map(element => element)}
                    data={element}
                    indentation={false}
                    key={index}
                    />
                    )}

                </>
              )}
            </Grid>
          </Grid>
        )
      })}

    </Grid>
  )
}