/* eslint-disable react/react-in-jsx-scope */
import { Grid, Typography } from "@mui/material";
import IReportColumn from "../../interfaces/IReportColumn";
import { useLang } from "~/hooks/useLang";

export default function ReportColumns({columns, indentation }: { 
  readonly columns: readonly IReportColumn[], 
  readonly indentation?: boolean,
 }) {
  const { translate } = useLang();

  function sumOfGridColumns() {
    const initialSum = 0;
    const sumWithInitial = columns.reduce((acc, currentValue) => acc + currentValue.grid, initialSum)
    return sumWithInitial;
  }

  return (
    // <Container fixed={false}>
    <Grid
      marginLeft={indentation ? 1.5 : 0}
      container
      marginTop={2}
    // border={2}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent={'center'}
        columns={sumOfGridColumns()}
      >
        {columns.map((col, index) => {
            return (
              <Grid
                py={0.5}
                key={col.id}
                item
                xs={col.grid}
                borderLeft={1}
                borderTop={1}
                borderBottom={1}
                // textAlign={col.alignment == 'center' ? 'center' : col.alignment == 'right' ? 'right' : 'left'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: col.alignment,
                  "&:last-child": {
                    borderRight: '1px solid black',
                  }
                }}
              >
                <Typography
                  marginX={1}
                  gridColumn={col.grid}
                  justifyContent={'center'}
                  textAlign={'center'}
                  fontWeight={'bold'}
                  sx={{
                    fontSize: 10,
                  }}
                >
                  {translate(col.label)}

                </Typography>
              </Grid>
            )
          })}
      </Grid>
    </Grid>
    // </Container>
  )
}