import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import { useParams } from 'react-router-dom';
import ICrudEditParams from '~/components/CrudEdit/interfaces/ICrudEditParams';

export default function Edit() {
    const { register, handleSubmit, control, setValue: setFormValue, getValues } = useForm<any>();
    const fields = [
        { name: 'id', type: 'hidden' },
        { name: 'name', type: 'string' },
        {
            name: 'type_subtypes',
            type: 'checkTreeView',
            options: {
                firstListUrl: '/type/with-subtype-info',
                secondListUrl: `/category/${useParams<ICrudEditParams>().id}/get-type-subtype`,
                nameChildren: 'subtypes'
            },
            model: 'category',
            placeholder: 'Category',
        },
    ];

    return (
        <CrudEdit
            model={'category'}
            fields={fields}
            register={register}
            handleSubmit={handleSubmit}
            control={control}
            setFormValue={setFormValue}
            messageSuccess={'Category successfully updated!'}
            title={'Edit Category'}
            getValues={getValues}
        />
    )
}



