import React, { useEffect, useState } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { useLang } from '~/hooks/useLang';

interface FilterSwitchProps {
    readonly label: string;
    readonly onCheckedChange: (value: boolean) => void;
    readonly checked: boolean;
    readonly disabled?: boolean
}

export default function FilterSwitch({ label, onCheckedChange, checked, disabled }: FilterSwitchProps) {
    const { translate } = useLang();
    const [isChecked, setIsChecked] = useState<boolean>(false);

    useEffect(() => {
        setIsChecked(checked)
    }, [checked])


    return (
        <FormControlLabel
            label={translate(label)}
            control={
                <Switch
                    disabled={disabled}
                    checked={isChecked}
                    onChange={(_, checked) => {
                        setIsChecked(checked)
                        onCheckedChange(checked)
                    }}
                />
            }
        />
    )
}