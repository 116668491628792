import axios from 'axios';
import {getBaseUrl} from '~/utils/net';


const api = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
});


export default api;