import { LoadingButton } from "@mui/lab";
import React from "react"
import { useLang } from "~/hooks/useLang";

interface ExportButtonCSVProps {
    readonly isLoading: boolean;
    readonly dataToExport: any;
}

export default function ExportButtonCSV({ isLoading, dataToExport }: ExportButtonCSVProps) {
    const { translate } = useLang();
    return (
        <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={() => dataToExport()}
        >
            {translate('Exportar CSV')}
        </LoadingButton>
    )
}