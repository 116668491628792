interface Point {
  key: any,
  id: any,
  iconId: any,
  customIcon: any,
  position: any,
  circleBase: any,
  onClick: any,
  latitude: number;
  longitude: number;
}

interface Cluster {
    points: Point[];
}


function calculateDistance(point1: Point, point2: Point): number {
  const latDiff = point1.latitude - point2.latitude;
  const lonDiff = point1.longitude - point2.longitude;
  return Math.sqrt(latDiff * latDiff + lonDiff * lonDiff);
}

export function roughDBSCAN(points: Point[], epsilon: number, minPoints: number): Cluster[] {
  const clusters: Cluster[] = [];
  const visited: boolean[] = new Array(points.length).fill(false);

  function expandCluster(point: Point, neighbors: Point[], cluster: Cluster): void {
      cluster.points.push(point);
      visited[points.indexOf(point)] = true;

      for (const neighbor of neighbors) {
          if (!visited[points.indexOf(neighbor)]) {
              visited[points.indexOf(neighbor)] = true;
              const neighborNeighbors = getNeighbors(neighbor, epsilon);
              if (neighborNeighbors.length >= minPoints) {
                  expandCluster(neighbor, neighborNeighbors, cluster);
              }
          }
      }
  }

  function getNeighbors(point: Point, epsilon: number): Point[] {
      const neighbors: Point[] = [];
      for (const otherPoint of points) {
          if (calculateDistance(point, otherPoint) <= epsilon) {
              neighbors.push(otherPoint);
          }
      }
      return neighbors;
  }

  for (const point of points) {
      if (!visited[points.indexOf(point)]) {
          visited[points.indexOf(point)] = true;
          const neighbors = getNeighbors(point, epsilon);
          if (neighbors.length >= minPoints) {
              const cluster: Cluster = { points: [] };
              expandCluster(point, neighbors, cluster);
              clusters.push(cluster);
          }
      }
  }

  return clusters;
}

function toRadians(degrees: number): number {
  return degrees * Math.PI / 180;
}

function toDegrees(radians: number): number {
  return radians * 180 / Math.PI;
}

export function getGeographicCenter(points: Point[]) {
  if (points.length === 0) {
      return null;
  }

  let x = 0;
  let y = 0;
  let z = 0;

  points.forEach(point => {
      const latitude = toRadians(point.latitude);
      const longitude = toRadians(point.longitude);

      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
  });

  const total = points.length;

  x /= total;
  y /= total;
  z /= total;

  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    latitude: toDegrees(centralLatitude),
    longitude: toDegrees(centralLongitude)
  };
}


function degToRad(deg: number): number{
  return deg * (Math.PI / 180);
};

function isPointInPolygon(point: Point, vertices: Point[]): boolean{
  let x = point.longitude;
  let y = point.latitude;
  let inside = false;

  for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
    let xi = vertices[i].longitude;
    let yi = vertices[i].latitude;
    let xj = vertices[j].longitude;
    let yj = vertices[j].latitude;

    let intersect = ((yi > y) !== (yj > y)) &&
                    (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }

  return inside;
};

export function removeInternalPoints(vertices: Point[]): Point[]{
  return vertices.filter((point, index, allPoints) => {
    let reducedPoints = allPoints.slice(0, index).concat(allPoints.slice(index + 1));
    return !isPointInPolygon(point, reducedPoints);
  });
};

function calculateCentroid(points: Point[]){
  let xSum = 0;
  let ySum = 0;
  points.forEach(point => {
    xSum += point.longitude;
    ySum += point.latitude;
  });
  return {
    latitude: ySum / points.length,
    longitude: xSum / points.length,
  };
};

function calculateAngle (center, point): number{
  return Math.atan2(point.latitude - center.latitude, point.longitude - center.longitude);
};

export function sortPoints(points: Point[]){
  const center = calculateCentroid(points);
  return points.slice().sort((a, b) => {
    const angleA = calculateAngle(center, a);
    const angleB = calculateAngle(center, b);
    return angleA - angleB;
  });
};
