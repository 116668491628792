import { FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLang } from '~/hooks/useLang';
import ClearIcon from '@mui/icons-material/Clear';

interface SelectConsummatedAndAttemptedProps {
  readonly valueDefault: string | null;
  readonly disabled: boolean;
  readonly onReportConsummatedAndAttempetdChange: (value: string | null) => void;
  readonly customOption?: readonly {
    readonly index: number,
    readonly value: string,
    readonly label: string
  }[]
}

export default function SelectConsummatedAndAttempted({ valueDefault, onReportConsummatedAndAttempetdChange, customOption }: SelectConsummatedAndAttemptedProps) {
  const { translate } = useLang();
  const [value, setValue] = useState(valueDefault)
  const options = customOption ? customOption : [
    { index: 0, value: '1', label: 'Does not apply' },
    { index: 1, value: '2', label: 'consummated' },
    { index: 2, value: '3', label: 'attempted' }
  ]

  useEffect(() => {
    setValue(valueDefault)
  }, [valueDefault])

  return (
    <FormControl fullWidth size='small'>
      <InputLabel id='SelecConsummatedAndAttempted'>
        {translate('Attempted/Consummated')}
      </InputLabel>
      <Select
        fullWidth
        labelId='SelecConsummatedAndAttempted'
        id='SelecConsummatedAndAttempted'
        defaultValue={''}
        value={value}
        label={translate('Attempted/Consummated')}
        onChange={(event) => {
          setValue(event.target.value)
          onReportConsummatedAndAttempetdChange(event.target.value || null)
        }}
        endAdornment={
          <IconButton
            size='small'
            sx={{ marginRight: 2 }}
            onClick={(e) => {
              setValue(null)
              onReportConsummatedAndAttempetdChange(null)
            }}
          >
            <ClearIcon fontSize='small' />
          </IconButton>
        }
      >
        {
          options.map(option => {
            return (
              <MenuItem sx={{ mt: 1 }} value={option.value} key={option.index}>
                {translate(option.label)}
              </MenuItem>
            )
          })
        }
      </Select>
    </FormControl>
  )
}
